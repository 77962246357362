import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { FlexContainer } from '../../Styled';

export interface IUnderTableContentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  loading?: boolean;
  isEmptyContent?: boolean;
}

const HeaderFlexContainer = styled(FlexContainer)`
  margin-bottom: 20px;
  height: fit-content;
  min-height: 50px;
`;

export function UnderTableContent({
  children,
  style = {},
  loading = false,
  isEmptyContent,
}: IUnderTableContentProps): JSX.Element {
  return (
    <Spin spinning={loading} indicator={<></>}>
      <HeaderFlexContainer
        style={style}
        justifyContent="space-between"
        alignItems="flex-end">
        {isEmptyContent ? null : children}
      </HeaderFlexContainer>
    </Spin>
  );
}
