import React, {
  JSX,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Empty, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  InvoiceItemFormDTO,
  StoreDocumentMappedType,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '@structure';
import styled from 'styled-components';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies, useStoredStoreDocumentList } from '@hooks';
import { debounce } from '@services/helpers';
import { LoadingMore } from '@components/lib/DataDisplay';
import {
  InvoiceItemListSelectState,
  SelectedItemType,
} from './InvoiceItemListSelect';
import { List } from 'immutable';
import {
  StoreProductPriceView,
  StoreRealizationDetailsView,
} from '../../Stores';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';

export interface InvoiceStoreRealizationListSelectProps {
  disabled: boolean;
  selectRef: RefObject<any>;
  onTableKeyboardChange: (e: any) => void;
  handleInitItemForm: (item: SelectedItemType) => void;
  resetSelectedItemState: () => void;
  selectedItem: InvoiceItemFormDTO;
  selectedItemIndex: number;
  itemListKeywords: string;
  updateItemState: (
    value:
      | ((
          value: InvoiceItemListSelectState,
        ) => Partial<InvoiceItemListSelectState>)
      | Partial<InvoiceItemListSelectState>,
  ) => void;
  isInitItemModals: React.MutableRefObject<boolean>;
  residentUuid: string | undefined;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<StoreDocumentModel>>;

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;

  & .ant-select-selection-placeholder {
    white-space: unset !important;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
`;

export function InvoiceStoreRealizationListSelect({
  disabled,
  selectRef,

  selectedItem,
  selectedItemIndex,
  itemListKeywords,

  onTableKeyboardChange,
  handleInitItemForm,
  resetSelectedItemState,
  updateItemState,
  isInitItemModals,
  residentUuid,
}: InvoiceStoreRealizationListSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();

  const [documentList, setDocumentList] =
    useState<List<StoreDocumentModel> | null>(null);

  const {
    documentList: initDocumentList,
    loading: documentListLoading,
    loadingMore,
    isLoadingMore,
    total,
    limit,
    page,

    handleSearchStoreDocuments,
    handleLoadMoreStoreDocuments,
    handleSetStoreDocumentList,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
    limit: 100,
    documentType: StoreDocumentType.OUT,
    mappedDocumentType: StoreDocumentMappedType.INVOICE_OUT,
    resident_uuid: residentUuid,
    documentStatus: StoreDocumentStatus.COMMITTED,
  });

  const loading = documentListLoading || loadingMore;

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const documentList = await handleSearchStoreDocuments({
          ...value,
          companyUuid: defaultCompanyUuid,
        });

        if (documentList && List.isList(documentList?.documents)) {
          updateItemState({
            itemList: documentList?.documents,
            selectedItem: documentList?.documents?.first(),
          });
        }
      }, 1000),
    [handleSearchStoreDocuments, defaultCompanyUuid, updateItemState],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      updateItemState({ itemListKeywords: keywords });

      if (keywords?.length < 3) {
        setTimeout(() => {
          if (!isInitItemModals.current) {
            resetSelectedItemState();
            handleSetStoreDocumentList(
              StoreDocumentMapper.toStoreDocumentListModel(List() as any, 0),
            );
          }
        }, 100);
        return;
      }

      await debounceHandleSearchProducts({
        keywords,
        resident_uuid: residentUuid,
      });
    },
    [
      debounceHandleSearchProducts,
      handleSetStoreDocumentList,
      isInitItemModals,
      resetSelectedItemState,
      residentUuid,
      updateItemState,
    ],
  );

  const onLoadingMore = useCallback(async () => {
    const documentList = await handleLoadMoreStoreDocuments();

    if (documentList && List.isList(documentList?.documents)) {
      updateItemState((prevState) => ({
        itemList: (prevState?.itemList || List()).merge(
          documentList?.documents,
        ) as any,
      }));
    }
  }, [handleLoadMoreStoreDocuments, updateItemState]);

  const columns = [
    // {
    //   ellipsis: true,
    //   title: `№`,
    //   key: 'index',
    //   align: 'center' as any,
    //   className: 'order-list-td order-list--middle-text',
    //   render: (document: StoreDocumentModel, items: any, index: number) => (
    //     <TableIndexField number={(page - 1) * limit + index + 1} />
    //   ),
    // },
    {
      ellipsis: true,
      title: t('Sales invoices'),
      key: 'sales-invoices',
      render: (document: StoreDocumentModel) => (
        <StoreRealizationDetailsView withoutNavigate document={document} />
      ),
    },
    // {
    //   ellipsis: true,
    //   title: t('Created'),
    //   key: 'create-by',
    //   render: (document: StoreDocumentModel) => (
    //     <DetailsItemView<ProfileModel>
    //       item={document?.createdByModel}
    //       fields={{
    //         fullName: {
    //           title: '',
    //         },
    //         uuid: {
    //           description: (
    //             <StyledDescription>
    //               {toDateByFormat(document?.created_at, 'DD.MM.YYYY HH:mm')}
    //             </StyledDescription>
    //           ),
    //         },
    //       }}
    //     />
    //   ),
    // },
    // {
    //   ellipsis: true,
    //   title: t('Client'),
    //   key: 'client',
    //   render: (document: StoreDocumentModel) => (
    //     <DetailsItemView<ClientModel>
    //       item={document?.clientModel}
    //       fields={{
    //         fullNameClient: {
    //           link: 'Go to client details page',
    //           path: `/${Routes.app}/${Routes.clients}/${document?.clientModel?.uuid}`,
    //         },
    //         phone: {description: ''},
    //       }}
    //     />
    //   ),
    // },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      render: (document: StoreDocumentModel) => (
        <StoreProductPriceView document={document} />
      ),
    },
  ];

  useEffect(() => {
    if (List.isList(initDocumentList)) {
      const documentList = initDocumentList?.filter(
        ({
          doc_sum_paid,
          doc_sum_total_with_discount,
          doc_sum_total,
          doc_discount_value,
        }) => {
          const isDiscount = Number(doc_discount_value || 0) > 0;

          const paid = Number(doc_sum_paid) || 0;
          const total =
            (isDiscount
              ? Number(doc_sum_total_with_discount)
              : Number(doc_sum_total)) || 0;

          const isPaid = paid > 0 && paid === total;

          return !isPaid;
        },
      );

      setDocumentList(documentList);
    }
  }, [initDocumentList]);

  return (
    <StyledSelectContainer>
      <StyledSelect
        popupClassName="store-sell-product-select-popup posting-select-popup"
        className="store-sell-product-select"
        ref={selectRef as any}
        loading={loading && documentList !== null}
        disabled={disabled}
        showSearch
        onKeyUp={onTableKeyboardChange}
        searchValue={itemListKeywords}
        dropdownRender={() => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            {documentListLoading && documentList !== null ? (
              <Empty>
                <LoadingOutlined />
              </Empty>
            ) : documentListLoading ||
              !itemListKeywords ||
              itemListKeywords?.length < 3 ? (
              <Empty
                description={t(
                  'To select a sale invoice, enter the search data, at least 3 characters',
                )}
              />
            ) : itemListKeywords && !documentList?.size ? (
              <Empty
                description={t(
                  'It seems that more than one sale invoice was not found for your request',
                )}
              />
            ) : (
              <>
                <StyledTable
                  className="posting-list-form"
                  pagination={false}
                  pageSize={limit}
                  total={total}
                  dataSource={documentList}
                  columns={columns}
                  onRow={(record: StoreDocumentModel, rowIndex: any) => {
                    return {
                      onClick: () => {
                        handleInitItemForm(record);

                        if (selectRef?.current) {
                          selectRef?.current?.blur();
                        }
                      },
                      className: `posting-list-td posting-list-td-${rowIndex} ${
                        record?.uuid === selectedItem?.uuid &&
                        selectedItemIndex === rowIndex
                          ? 'posting-list-td--selected'
                          : ''
                      }`,
                    };
                  }}
                />
                <LoadingMore
                  loading={isLoadingMore}
                  observerCallback={onLoadingMore}
                />
              </>
            )}
          </div>
        )}
        onSearch={onSearch}
        placeholder={t('Enter sale invoice details to search for it')}
      />
    </StyledSelectContainer>
  );
}
