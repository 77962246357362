import * as React from 'react';
import { phoneBigScreenMediaQuery } from '@services/const';

export function useBigPhoneScreenMediaQuery(): boolean {
  const [isBigPhoneScreen, setIsBigPhoneScreen] = React.useState<boolean>(
    phoneBigScreenMediaQuery?.matches,
  );

  const resizePhoneScreen = React.useCallback(
    ({ matches }: MediaQueryListEvent): void => {
      setIsBigPhoneScreen(matches);
    },
    [],
  );

  React.useEffect(() => {
    phoneBigScreenMediaQuery.addEventListener('change', resizePhoneScreen);

    return () => {
      phoneBigScreenMediaQuery.removeEventListener('change', resizePhoneScreen);
    };
  }, [resizePhoneScreen]);

  return isBigPhoneScreen;
}
