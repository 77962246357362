import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { AbonementGroupFormDTO, AbonementGroupModel } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const TariffModelGroupSideWindowLazy = React.lazy(
  () => import('../Show/TariffModelGroupSideWindow'),
);

export interface ITariffModelGroupEditButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (
    value: AbonementGroupFormDTO,
  ) => Promise<AbonementGroupModel | void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  group: AbonementGroupFormDTO;
}

export default function TariffModelGroupEditButton({
  children,
  group,
  onSuccess,
  onCancel,
  ...rest
}: ITariffModelGroupEditButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit tariff model group')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <TariffModelGroupSideWindowLazy
          editMode
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          group={group}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
