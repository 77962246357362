import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps } from 'antd';
import { useModal } from '@components/lib/libV2/hooks';
import {
  PaymentFormDTO,
  PaymentScheduleFormDTO,
  PaymentScheduleType,
} from '@structure';
import {
  AddButton,
  IAddButtonProps,
  Button,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { Routes } from '@services/types';
import { StyledTitle } from '@components/lib/Styled';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { PaymentProps } from '@hooks';
import {
  IPaymentScheduleReturnType,
  PaymentScheduleOperationType,
} from '@services/api/orders';
import { IoMenu } from 'react-icons/io5';

const PaymentScheduleSideWindowLazy = React.lazy(
  () => import('../Show/PaymentScheduleSideWindow'),
);

export interface ICreateSchedulePaymentButtonProps extends IAddButtonProps {
  onSuccess: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentScheduleReturnType>;
  onSuccessRefund?: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentScheduleReturnType>;
  onCancel?: () => void;
  companyUuid: string;
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  from?: Routes.clients | Routes.orders;
  scheduleOperationType?: PaymentScheduleOperationType;
  paymentPrice?: string;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
  $withoutPadding: boolean;
}>`
  cursor: pointer;

  padding: 15px;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ $withoutPadding }) =>
    $withoutPadding &&
    css`
      padding: 0;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const StyledAddButton = styled(AddButton)<{ $showBg?: boolean }>`
  ${({ $showBg }) =>
    $showBg &&
    css`
      background-color: ${({ theme }) => theme.colors.success};

      &&& {
        &:focus,
        &:active,
        &:hover {
          background-color: ${({ theme }) => theme.colors.success};
          opacity: 0.7;
        }
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&& > * {
        color: ${({ theme }) => theme.colors.white};
      }

      &&& {
        &:focus,
        &:active,
        &:hover {
          opacity: 1;
        }
      }
    `}
`;

export const StyledAddDangerButton = styled(AddButton)<{ $showBg?: boolean }>`
  ${({ $showBg }) =>
    $showBg &&
    css`
      --tw-bg-opacity: 1;
      background-color: ${({ theme }) => theme.colorsV2.buttonDangerBgColor};

      &&& {
        &:focus,
        &:active,
        &:hover {
          background-color: ${({ theme }) =>
            theme.colorsV2.buttonDangerActiveBgColor};
        }
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&& > * {
        color: ${({ theme }) => theme.colors.white};
      }

      &&& {
        &:focus,
        &:active,
        &:hover {
          opacity: 1;
        }
      }
    `}
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export function CreateSchedulePaymentButton({
  loading = false,
  onSuccess,
  onSuccessRefund,
  companyUuid,
  onCancel,
  payment,
  from,
  title,
  scheduleOperationType,
  paymentPrice,
  ...rest
}: ICreateSchedulePaymentButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [type, setType] = useState<PaymentScheduleType | null>(
    from === Routes.orders ? PaymentScheduleType.Prepayment : null,
  );

  const {
    handleCancel: paymentScheduleHandleCancel,
    handleOnInit: paymentScheduleHandleOnInit,
    handleSuccess: paymentScheduleHandleSuccess,
    visible: paymentScheduleVisible,
  } = useModal({
    onCancel,
    onSuccess:
      type === PaymentScheduleType.Prepayment ? onSuccess : onSuccessRefund,
  });

  const items: MenuProps['items'] = [
    {
      key: 'Prepayment',
      label: <StyledTitle>{t('Prepayment')}</StyledTitle>,

      icon: <ArrowDownOutlined />,
      onClick: () => {
        setType(PaymentScheduleType.Prepayment);
        paymentScheduleHandleOnInit();
      },
    },
    {
      key: 'Refunds',
      label: <StyledTitle>{t('schedulePayment')}</StyledTitle>,
      icon: <ArrowUpOutlined />,
      onClick: () => {
        setType(PaymentScheduleType.Refunds);
        paymentScheduleHandleOnInit();
      },
    },
  ];

  return (
    <>
      {type && scheduleOperationType === PaymentScheduleOperationType.ALL ? (
        <StyledDropdown
          $withoutPadding={!!title}
          trigger={['hover']}
          disabled={!!loading}
          menu={{ items }}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : title ? (
            <Button type="primary">
              <StyledTitle>{title}</StyledTitle>
            </Button>
          ) : (
            <StyledIconContainer>
              <IoMenu size={20} color={theme.colors.primary} />
            </StyledIconContainer>
          )}
        </StyledDropdown>
      ) : scheduleOperationType === PaymentScheduleOperationType.In ? (
        <StyledAddButton
          $showBg={!!title}
          tooltipTitle={t('Create prepayment')}
          onClick={() => {
            setType(PaymentScheduleType.Prepayment);
            paymentScheduleHandleOnInit();
          }}
          loading={loading}
          title={title}
          {...rest}
        />
      ) : scheduleOperationType === PaymentScheduleOperationType.Out ? (
        <AddButton
          danger={!!title}
          tooltipTitle={t('Create schedulePayment')}
          onClick={() => {
            setType(PaymentScheduleType.Refunds);
            paymentScheduleHandleOnInit();
          }}
          loading={loading}
          title={title}
          {...rest}
        />
      ) : null}

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentScheduleSideWindowLazy
          payment={
            payment({
              payment_comment:
                type === PaymentScheduleType.Prepayment
                  ? t('Advance payment for the order')
                  : t('Payment of funds according to the order'),
              type: type as PaymentScheduleType,
              payment_price: paymentPrice,
            }) as PaymentScheduleFormDTO
          }
          loading={loading as boolean}
          onSuccess={paymentScheduleHandleSuccess}
          onCancel={paymentScheduleHandleCancel}
          visible={paymentScheduleVisible}
          type={type!}
        />
      </Suspense>
    </>
  );
}
