import {
  IRewardModelReturnType,
  IScheduleCalendarDTOProps,
  IProfileDTOProps,
  ScheduleCalendarDTO,
  ProfileDTO,
  ScheduleCalendarMapper,
  ProfileMapper,
  IPaymentDTOProps,
  PaymentDTO,
  PaymentMapper,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export enum RewardDocumentType {
  Rewards = 'rewards',
}

export enum RewardStatusesText {
  Accrued = 'accrued',
  Paid = 'paid',
  Error = 'error',
}

export enum RewardRewardableType {
  Schedule = 'schedule',
  Salary = 'salary',
  Premium = 'premium',
  Penalty = 'penalty',
}

export interface IRewardDTOProps {
  uuid: string;
  date: string;
  hint: string;
  price: string;
  created_at: string;
  updated_at: string;
  status_text: RewardStatusesText;
  rewardable_type: RewardRewardableType;
  rewardable: IScheduleCalendarDTOProps;
  created_by: IProfileDTOProps;
  payment?: IPaymentDTOProps;
  sum_paid: string;
}

export class RewardDTO {
  public uuid: string;
  public date: string;
  public hint: string;
  public price: string;
  public created_at: string;
  public updated_at: string;
  public status_text: RewardStatusesText;
  public rewardable_type: RewardRewardableType;
  public rewardable: ScheduleCalendarDTO;
  public created_by: ProfileDTO;
  public payment?: PaymentDTO;
  public sum_paid: string;

  constructor(
    props: IRewardModelReturnType | IRewardDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.date = props?.date || '';
    this.hint = props?.hint || '';
    this.price = correctPrice(props?.price || 0);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.status_text = props?.status_text || RewardStatusesText.Error;
    this.rewardable_type =
      props?.rewardable_type || RewardRewardableType.Schedule;

    this.rewardable = ScheduleCalendarMapper.toScheduleCalendarDTO(
      props?.rewardable,
    );

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.reward,
            ...withoutRecursion,
          ]);

    this.payment = PaymentMapper.toPaymentDTO(props?.payment || ({} as any));
    this.sum_paid = correctPrice(props?.sum_paid || 0);
  }
}
