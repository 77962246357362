import React, { useMemo, useCallback, useState } from 'react';
import { ORDER_CHANGE_TIME_INITIAL_PARAM } from '@services/api/orders';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import {
  useEmployeeScheduleList,
  useStateEmployeeList,
  useStoredCompanies,
} from '@hooks';
import { ScheduleChangeTimeFormDTO, ServiceModel } from '@structure';
import {
  isFunction,
  memoizeUnaryArity,
  toMomentString,
} from '@services/helpers';
import { IRelatedOrderProps } from '../Pages/OrderListPage';
import { IGetListEmployeeScheduleProps } from './OrderForm';
import { OrderChangeTimeFields } from './OrderChangeTimeFields';
import '@components/lib/Navigation/Wizard.less';

export interface IOrderChangeTimeFormProps
  extends Pick<
    IDefaultFormProps<ScheduleChangeTimeFormDTO, ScheduleChangeTimeFormDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: ScheduleChangeTimeFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  schedule?: ScheduleChangeTimeFormDTO;
  relatedSchedule?: IRelatedOrderProps | undefined;
}

export function OrderChangeTimeForm({
  loading,
  schedule = ORDER_CHANGE_TIME_INITIAL_PARAM,
  relatedSchedule,
  onCancel,
  onSuccess,
  ...rest
}: IOrderChangeTimeFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const [employeeScheduleList, setEmployeeScheduleList] = useState<any>(null);

  const additionalValues = useMemo(
    () => ({
      uuid: schedule?.uuid,
    }),
    [schedule?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    // serviceUuid: service?.uuid,
    limit: 100,
  });

  const {
    refresh: employeeScheduleListRefresh,
    loading: employeeScheduleLoading,
  } = useEmployeeScheduleList({
    employeeUuid: '',
    loadOnInit: false,
    limit: 100,
  });

  const memoizeEmployeeScheduleListRefresh = memoizeUnaryArity(
    employeeScheduleListRefresh,
    new Map<any, any>(),
  );

  const handleGetListEmployeeSchedule = useCallback(
    async ({
      date,
      employeeUuid,
    }: IGetListEmployeeScheduleProps): Promise<void> => {
      const scheduleDate = date ? toMomentString(date, 'YYYY-MM-DD') : null;

      if (isFunction(employeeScheduleListRefresh) && scheduleDate) {
        const schedules: any = await memoizeEmployeeScheduleListRefresh({
          start: scheduleDate,
          end: scheduleDate,
          employeeUuid,
          limit: 100,
        });

        setEmployeeScheduleList(schedules);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultCompanyUuid],
  );

  const handleSuccess = useCallback(
    async (data: any) => {
      if (isFunction(onSuccess)) {
        await onSuccess(data);
        localStorage.removeItem('dndEvent');
      }
    },
    [onSuccess],
  );

  const notifyError = useCallback(
    (apiError: any): void => {
      alert(
        'error',
        t('Order'),
        `${t('An error occurred during change order time')} : ${
          apiError?.message
        }`,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm
      initialValues={{ ...schedule }}
      onCancel={onCancel}
      withContext
      submitButtonText={t('Close the order')}
      additionalValuesRequest={additionalValues}
      editMode={false}
      showNotify={false}
      notifyError={notifyError}
      onSuccess={handleSuccess}
      footerClassName="Wizard-step-footer"
      {...rest}>
      <OrderChangeTimeFields
        relatedSchedule={relatedSchedule}
        schedules={employeeScheduleList}
        handleGetListEmployeeSchedule={handleGetListEmployeeSchedule}
        handleSearchEmployees={(keywords) =>
          handleSearchEmployees({
            keywords,
            showLoading: false,
            limit: 100,
            // serviceUuid: service?.uuid,
          })
        }
        scheduleLoading={employeeScheduleLoading}
        loading={loading}
        employees={employees}
        employeesLoading={employeesLoading}
        // service={service}
      />
    </DefaultForm>
  );
}
