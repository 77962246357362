import { useEffect, useState } from 'react';
import { split, slice, joinArr, compose } from '@services/helpers';
import { useLocation } from 'react-router';

/**
 * Listens to the router and updates the selected activeKey
 * @param initialKey
 * @param history
 * @returns {any[]}
 */

export type UseActiveKeyReturnType = [string];

export function useActiveKey(): UseActiveKeyReturnType {
  const location = useLocation();

  const [activeKey, setActiveKey] = useState<string>(location.pathname);

  useEffect(() => {
    const path = compose<string>(
      joinArr('/'),
      slice(0, 3),
      split('/'),
    )(location.pathname);

    setActiveKey(path);
  }, [location.pathname]);

  return [activeKey];
}
