import { List } from 'immutable';
import { APP_STATE, STATUS_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { StatusModel } from '@structure';

interface IStatusListState {
  statusList: List<StatusModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
  category?: number;
}

interface SetActionList
  extends Pick<
    IStatusListState,
    'statusList' | 'keywords' | 'total' | 'page' | 'category'
  > {
  type: STATUS_LIST.SET_STATUS_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: STATUS_LIST.ADD_STATUS;
  status: StatusModel;
}

interface UpdateStatusFromList {
  type: STATUS_LIST.UPDATE_STATUS;
  status: StatusModel;
}

interface DeleteActionFromList {
  type: STATUS_LIST.DELETE_STATUS;
  statusUuid: string;
}

interface LoadingActionInList {
  type: STATUS_LIST.LOADING_STATUS_LIST;
}

interface ErrorActionInList extends Pick<IStatusListState, 'error'> {
  type: STATUS_LIST.ERROR_STATUS_LIST;
}

interface Handlers {
  [STATUS_LIST.SET_STATUS_LIST]: (
    state: IStatusListState,
    action: SetActionList,
  ) => IStatusListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStatusListState,
    action: SetInitialStateAction,
  ) => IStatusListState;

  [STATUS_LIST.LOAD_MORE_STATUS_LIST]: (
    state: IStatusListState,
    action: SetActionList,
  ) => IStatusListState;

  [STATUS_LIST.ADD_STATUS]: (
    state: IStatusListState,
    action: AddActionToList,
  ) => IStatusListState;

  [STATUS_LIST.UPDATE_STATUS]: (
    state: IStatusListState,
    action: UpdateStatusFromList,
  ) => IStatusListState;

  [STATUS_LIST.DELETE_STATUS]: (
    state: IStatusListState,
    action: DeleteActionFromList,
  ) => IStatusListState;

  [STATUS_LIST.ERROR_STATUS_LIST]: (
    state: IStatusListState,
    value: ErrorActionInList,
  ) => IStatusListState;

  [STATUS_LIST.LOADING_STATUS_LIST]: (
    state: IStatusListState,
    value?: LoadingActionInList,
  ) => IStatusListState;
  DEFAULT: (state: IStatusListState) => IStatusListState;
}

const initState: IStatusListState = {
  statusList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [STATUS_LIST.SET_STATUS_LIST]: (
    state,
    { statusList, keywords, total, page, category },
  ) => ({
    ...state,
    ...{
      statusList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
      category: category || state?.category,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STATUS_LIST.LOAD_MORE_STATUS_LIST]: (state, { statusList }) => ({
    ...state,
    ...{
      statusList:
        List.isList(state.statusList) && List.isList(statusList)
          ? state.statusList.merge(statusList)
          : state.statusList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STATUS_LIST.ADD_STATUS]: (state, { status }) => ({
    ...state,
    ...{
      statusList: List.isList(state.statusList)
        ? state.statusList.unshift(status)
        : List([status]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [STATUS_LIST.UPDATE_STATUS]: (state, { status }) => ({
    ...state,
    ...{
      statusList: List.isList(state.statusList)
        ? state.statusList.map((stateStatus) => {
            if (stateStatus.uuid === status?.uuid) {
              return status;
            }
            return stateStatus;
          })
        : List([status]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STATUS_LIST.DELETE_STATUS]: (state, { statusUuid }) => ({
    ...state,
    ...{
      statusList: List.isList(state.statusList)
        ? state.statusList.filter(compose(not, isEqualByUuid(statusUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [STATUS_LIST.ERROR_STATUS_LIST]: (
    state: IStatusListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STATUS_LIST.LOADING_STATUS_LIST]: (state: IStatusListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStatusListState) => state,
};

export default function StatusList(
  state: any = initState,
  action: any,
): IStatusListState {
  const handler = handlers[action.type as STATUS_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
