import { message } from 'antd';
import { curry, isObject } from '@services/helpers';
import { ApiError } from '@services/types';

export type T = any;

const actionSuccess = (subject = 'Record', action: string, t: T) => {
  message.success(`${subject} ${action} ${t('successfully')}.`);
};

const actionError = (
  subject = 'Record',
  action = 'updating',
  err: ApiError,
  t: T,
): void => {
  const errStr = err ? `: ${isObject(err) ? err.message : err}` : '.';
  message.error(
    `${t('An error has occurred while')} ${action} ${subject}${errStr}`,
  );
};

const createSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('created'), t);
});

const createError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('creating'), err, t);
});

const addSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('added'), t);
});

const addError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('adding'), err, t);
});

const editSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('edited'), t);
});

const editError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('editing'), err, t);
});

const updateSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('updated'), t);
});

const updateError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('updating'), err, t);
});

const deleteSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('deleted'), t);
});

const deleteError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('deleting'), err, t);
});

const removeSuccess = curry((t: T, subject: string): void => {
  actionSuccess(t(subject), t('removed'), t);
});

const removeError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('removing'), err, t);
});

const notifyMessage = curry((t: T, subject: string): void => {
  message.success(t(subject));
});

const notifyErrorMessage = curry(
  (t: T, subject: string, error: ApiError): void => {
    const errStr = error ? `${isObject(error) ? error.message : error}` : '.';
    message.error(`${t(subject)}: ${errStr}`);
  },
);

const authError = curry((t: T, subject: string, err: ApiError): void => {
  actionError(t(subject), t('authorization'), err, t);
});

export default {
  addError,
  addSuccess,
  createError,
  createSuccess,
  deleteError,
  deleteSuccess,
  editError,
  editSuccess,
  updateError,
  updateSuccess,
  removeError,
  removeSuccess,
  notifyMessage,
  notifyErrorMessage,
  authError,
};
