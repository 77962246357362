import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { ApiAnswer, IListSearchProps } from '../types';
import {
  StatusDTO,
  StatusFormDTO,
  StatusMapper,
  IStatusDTOProps,
  IStatusListDTO,
  ExecuteOnSet,
} from '@structure';
import { head } from '@services/helpers';
import { contentApiUrl } from '../const';

export const STATUS_INITIAL_PARAM = new StatusFormDTO();

const { uuid, title, ...STATUS_INITIAL_PARAM_REST } = STATUS_INITIAL_PARAM;

export const STATUS_REQUIRED_FIELD = {
  ...STATUS_INITIAL_PARAM_REST,
};

export const STATS_EXECUTE_ON_SET = [
  {
    uuid: ExecuteOnSet.Payment,
    title: 'Prepayment',
  },
  {
    uuid: ExecuteOnSet.Comment,
    title: ExecuteOnSet.Comment,
  },
  {
    uuid: ExecuteOnSet.ClosePayment,
    title: 'Final payment',
  },
];

export interface IStatusAnswer extends ApiAnswer {
  status: IStatusDTOProps;
}

export interface IStatusListAnswer extends ApiAnswer {
  statuses: IStatusDTOProps[];
}

export interface ICompanyStatusListAnswer extends ApiAnswer, IListSearchProps {
  statuses: IStatusDTOProps[];
}

export interface ICompanyStatusListProps extends IListSearchProps {
  companyUuid: string;
  category?: string;
}

export async function getStatusList({
  offset = 0,
  limit = 10,
  companyUuid,
  category,
}: ICompanyStatusListProps): Promise<IStatusListDTO> {
  const { statuses, total } = await apiGet<
    IListSearchProps & { category?: string },
    IStatusListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/custom-statuses`, {
    offset,
    limit,
    category,
  });

  return StatusMapper.toStatusListDTO(statuses, total || 0);
}

export async function getStatusById(statusUuid: string): Promise<StatusDTO> {
  const { status } = await apiGet<null, IStatusAnswer>(
    `${contentApiUrl}/custom-statuses/${statusUuid}`,
  );

  return StatusMapper.toStatusDTO(status);
}

export async function createStatus(
  {
    execute_on_set,
    category_uuid,
    allowed_statuses_to,
    allowed_statuses_from,
    title,
  }: StatusFormDTO,
  companyUuid: string,
): Promise<StatusDTO> {
  const { status } = await apiPost<Omit<StatusFormDTO, 'uuid'>, IStatusAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/custom-statuses`,
    {
      execute_on_set,
      category_uuid,
      allowed_statuses_to,
      allowed_statuses_from,
      title,
    },
  );

  return StatusMapper.toStatusDTO(status);
}

export async function editStatus({
  uuid: status_uuid,
  execute_on_set,
  category_uuid,
  allowed_statuses_to,
  allowed_statuses_from,
  title,
}: StatusFormDTO): Promise<StatusDTO> {
  const { status } = await apiPut<Omit<StatusFormDTO, 'uuid'>, IStatusAnswer>(
    `${contentApiUrl}/custom-statuses/${status_uuid}`,
    {
      execute_on_set,
      category_uuid,
      allowed_statuses_to,
      allowed_statuses_from,
      title,
    },
  );
  return StatusMapper.toStatusDTO(status);
}

export async function deleteStatus(
  ids: string[] = [],
): Promise<IStatusListDTO> {
  const { statuses } = await apiDelete<
    { ids: string[] },
    ICompanyStatusListAnswer
  >(`${contentApiUrl}/custom-statuses/${head(ids)}`);

  return StatusMapper.toStatusListDTO(statuses, 0);
}
