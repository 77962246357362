import React from 'react';
import styled, { css } from 'styled-components';

export interface IColorViewProps {
  color: string;
  children: React.ReactNode;
}

const StyledColorContainer = styled.div<Pick<IColorViewProps, 'color'>>`
  color: ${({ theme }) => theme.colors.black};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export default function ColorView({
  color,
  children,
}: IColorViewProps): JSX.Element {
  return <StyledColorContainer color={color}>{children}</StyledColorContainer>;
}
