import { Record, List } from 'immutable';
import { IListProps } from './List';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  ClientOperationDTO,
  ClientOperationModel,
  IClientOperationListStats,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IClientOperationListModelProps extends IListProps {
  operations: ClientOperationDTO[];
  client: ClientDTO;
  stats: IClientOperationListStats;
}

export interface IClientOperationListModelReturnType extends IListProps {
  operations: List<ClientOperationModel>;
  client: ClientModel;
  stats: IClientOperationListStats;
}

export class ClientOperationListModel extends Record<IClientOperationListModelReturnType>(
  {
    operations: List(),
    total: 0,
    client: {} as ClientModel,
    stats: { total: '0.00' },
  },
) {
  constructor(
    {
      operations = [],
      client,
      ...props
    }: IClientOperationListModelProps = {} as IClientOperationListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientOperationListModelReturnType = {
      ...props,
      operations: List(
        operations.map(
          (operation) => new ClientOperationModel(operation, withoutRecursion),
        ),
      ),
      client: ClientMapper.toClientModel(client),
    };

    super(options);
  }
}
