import { GENDER, toDateByFormat, DateType } from '@services/helpers';
import { ProfileModel } from '../internal';
import { DATE_SHORT_FORMAT } from '@services/const';
import { toMoment } from '@sportix/sportix-common-modules';

export class ProfileFormDTO {
  public uuid: string;

  public first_name: string;

  public middle_name: string;

  public last_name: string;

  public phone: string;

  public address: string;

  public birthday: DateType;

  public email: string;

  public gender: GENDER;

  constructor(props?: ProfileModel) {
    this.uuid = props?.uuid || '';
    this.first_name = props?.first_name || '';
    this.middle_name = props?.middle_name || '';
    this.last_name = props?.last_name || '';
    this.phone = props?.phone || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday ? toMoment(props?.birthday) : '';
    this.email = props?.email || '';
    this.gender = props?.gender || GENDER.MALE;
  }

  birthdayToString(birthday: DateType) {
    return toDateByFormat(birthday, DATE_SHORT_FORMAT);
  }
}
