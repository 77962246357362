import React, { useCallback, useState } from 'react';
import { ButtonProps } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { EmployeeModel } from '@structure';
import { AddButton } from '@components/lib/DataDisplay';
import { isFunction } from '@services/helpers';

export interface IEmployeeAcceptInviteButtonProps extends ButtonProps {
  tooltipTitle: string;
  title?: string;
  employee: EmployeeModel;
  handleAcceptEmployeeInvite: (
    employee: EmployeeModel,
  ) => Promise<EmployeeModel | void>;
  handleUpdate?: (employee: EmployeeModel) => void;
}

export function EmployeeAcceptInviteButton({
  tooltipTitle,
  type = 'text',
  style,
  title,
  handleAcceptEmployeeInvite,
  employee,
  handleUpdate,
  children,
  ...rest
}: IEmployeeAcceptInviteButtonProps): JSX.Element {
  const theme: any = useTheme();

  const [loading, setLoading] = useState(false);

  const acceptEmployeeInvite = useCallback(async () => {
    setLoading(true);
    const employeeModel = await handleAcceptEmployeeInvite(employee);
    if (employeeModel && isFunction(handleUpdate)) {
      handleUpdate(employeeModel);
    }
    setLoading(false);
  }, [employee, handleAcceptEmployeeInvite, handleUpdate]);

  return (
    <AddButton
      type={type}
      tooltipTitle={tooltipTitle}
      onClick={acceptEmployeeInvite}
      loading={loading}
      color={theme.colors.success}
      {...rest}>
      {children || <CheckOutlined color={theme.colors.success} />}
    </AddButton>
  );
}
