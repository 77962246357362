import { Record, List } from 'immutable';
import { IListProps } from './List';
import { DayOffDTO, DayOffModel } from '../internal';

export interface IDayOffListModelProps extends IListProps {
  days_off: DayOffDTO[];
}

export interface IDayOffListModelReturnType extends IListProps {
  days_off: List<DayOffModel>;
}

export class DayOffListModel extends Record<IDayOffListModelReturnType>({
  days_off: List(),
  total: 0,
}) {
  constructor(
    {
      days_off = [],
      ...props
    }: IDayOffListModelProps = {} as IDayOffListModelProps,
  ) {
    super({
      ...props,
      days_off: List(days_off.map((day) => new DayOffModel(day))),
    });
  }
}
