import {
  IStoreDocumentItemConfiguredPrice,
  IStoreDocumentItemModelReturnType,
  ProductModel,
} from '../internal';
import { correctPrice, head } from '@services/helpers';
import { immerable } from 'immer';

export enum StoreDocumentItemSaleType {
  NOT_FOUND_ERROR = 'not found error',
  LOADING = 'loading',
  SUCCESS = 'success',
  API_ERROR = 'api error',
}

export interface IStoreDocumentItemAdditionalProps {
  editMode: boolean;
  price?: string;
  max_amount?: number;
  base_price?: string;
  item_sale_type?: StoreDocumentItemSaleType;
  item_error_message?: string;
  item_product_returned_amount?: number;
}

export class StoreDocumentItemFormDTO {
  [immerable]? = true;
  public uuid?: string;
  public product_uuid: string;
  public cell_identifier: string;
  public product_title?: string;
  public product_store_name?: string;
  public product_store_balance_total?: number;
  public product_amount: number;
  public price: string;
  public product_price: IStoreDocumentItemConfiguredPrice;
  public product?: ProductModel | null;
  public max_amount?: number;
  public price_currency?: string;
  public base_price?: string;
  public item_sale_type?: StoreDocumentItemSaleType;
  public item_error_message?: string;
  public item_product_returned_amount?: number;

  constructor(
    props: IStoreDocumentItemModelReturnType | ProductModel,
    {
      editMode,
      price,
      max_amount,
      base_price,
      item_error_message,
      item_sale_type,
      item_product_returned_amount = 0,
    }: IStoreDocumentItemAdditionalProps,
  ) {
    this.uuid = props?.uuid || '';

    this.product_uuid = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.product?.uuid
      : (props as ProductModel)?.uuid;

    this.product_title = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.product?.product_title
      : (props as ProductModel)?.product_title;

    this.product_store_name = editMode
      ? head(
          (props as IStoreDocumentItemModelReturnType)?.product
            ?.product_balances || [],
        )?.company_store?.store_name
      : head((props as ProductModel)?.product_balances || [])?.company_store
          ?.store_name;

    this.product_store_balance_total = editMode
      ? head(
          (props as IStoreDocumentItemModelReturnType)?.product
            ?.product_balances || [],
        )?.balance_total
      : head((props as ProductModel)?.product_balances || [])?.balance_total;

    this.cell_identifier =
      (props as IStoreDocumentItemModelReturnType)?.item_cell_identifier || '';
    this.product_amount =
      typeof (props as IStoreDocumentItemModelReturnType)
        ?.item_product_amount === 'number'
        ? (props as IStoreDocumentItemModelReturnType)?.item_product_amount
        : 1;

    this.price = price
      ? price
      : editMode
      ? correctPrice(
          (props as IStoreDocumentItemModelReturnType)?.item_price || 0,
        )
      : '';

    this.base_price = base_price
      ? base_price
      : editMode
      ? correctPrice(
          (props as IStoreDocumentItemModelReturnType)?.item_base_price || 0,
        )
      : '';

    this.product_price = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.item_configured_price || {
          product: [],
          calculated: [],
        }
      : {
          product: (props as ProductModel)?.product_prices,
          calculated: (props as ProductModel)?.product_prices,
        };

    this.product =
      props instanceof ProductModel ? props : (props?.product as any);

    this.max_amount = max_amount;
    this.price_currency =
      (props as IStoreDocumentItemModelReturnType)?.item_price_currency || '';
    this.item_error_message = item_error_message;
    this.item_sale_type = item_sale_type || StoreDocumentItemSaleType.SUCCESS;
    this.item_product_returned_amount =
      (props as IStoreDocumentItemModelReturnType)
        ?.item_product_returned_amount || item_product_returned_amount;
  }
}
