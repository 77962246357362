import { Tag, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useStoredCompanies } from '@hooks';
import { useTranslation } from 'react-i18next';
import { EmployeeRoleTag } from './EmployeeRoleTag';
import { EmployeeBalance } from './EmployeeBalance';
import { FaLock, FaUserSlash } from 'react-icons/fa';
import styled, { css, useTheme } from 'styled-components';
import { EmployeeModel, EmployeeStatuses } from '@structure';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';

export interface IEmployeeHeaderProps {
  employee: EmployeeModel | null;
  extra?: React.ReactNode | React.ReactNode[];
  showPhone?: boolean;
  showBalance?: boolean;
  explainBalance?: boolean;
  showPhoto?: boolean;
  className?: string;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledEmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledEmployeeStatus = styled.span`
  padding-right: 5px;
  display: flex;
`;

const StyledEmployeeHeaderContainer = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
`;

export function EmployeeHeader({
  extra,
  employee,
  className,
  showPhone = false,
  showPhoto = false,
  showBalance = false,
  explainBalance = false,
}: IEmployeeHeaderProps): JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const isEmployeeBlock = useMemo(
    () => employee?.status === 0,
    [employee?.status],
  );

  const hasAuthAccess = useMemo(
    () => !!employee?.is_auth_allowed,
    [employee?.is_auth_allowed],
  );

  const isEmployeeDeleted = useMemo(
    () => employee?.status === EmployeeStatuses.DELETED,
    [employee?.status],
  );

  return (
    <StyledEmployeeHeaderContainer $isVisible={!!employee}>
      <StyledWrapper className={className}>
        <StyledContainer className={className}>
          <StyledEmployeeContainer>
            <StyledTitle
              bold
              fontSize={20}
              color={
                isEmployeeBlock ? theme.colors.error : theme.colors.primary
              }>
              <Tooltip
                title={t(
                  hasAuthAccess
                    ? 'With access to the system'
                    : 'Without access to the system',
                )}>
                {employee?.fullName}
              </Tooltip>
              <StyledDescription> {employee?.title}</StyledDescription>
            </StyledTitle>
            <StyledEmployeeStatus>
              {isEmployeeBlock ? (
                <Tag color="red" icon={<FaLock size={12} />}>
                  &nbsp;{t('Employee is disabled')}
                </Tag>
              ) : null}
              {!hasAuthAccess ? (
                <Tag color="warning" icon={<FaUserSlash size={12} />}>
                  &nbsp;{t('Can not login')}
                </Tag>
              ) : null}
            </StyledEmployeeStatus>
            {showBalance && (
              <EmployeeBalance
                employee={employee}
                explainBalance={explainBalance}
                currency={defaultCompany?.currency_symbol || ''}
              />
            )}
            {showPhone && employee?.phone ? (
              <StyledTitle>{t(employee?.phone || '')}</StyledTitle>
            ) : null}
          </StyledEmployeeContainer>
        </StyledContainer>
        <StyledTitleContainer>
          {employee?.role_permissions?.length ? (
            employee?.role_permissions?.map(({ role }) => (
              <EmployeeRoleTag
                disabled={isEmployeeDeleted}
                key={role}
                value={role}
                label={t(role)}
              />
            ))
          ) : (
            <EmployeeRoleTag
              disabled={isEmployeeDeleted}
              value={employee?.role!}
              label={t(employee?.role)}
            />
          )}
        </StyledTitleContainer>
        {extra || null}
      </StyledWrapper>
    </StyledEmployeeHeaderContainer>
  );
}
