import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  ExpenseModel,
  InvoiceItemFormDTO,
  InvoiceMapper,
  InvoiceModel,
  ProductModel,
  ScheduleCalendarModel,
  ServiceModel,
} from '@structure';
import { ENTER_KEYS } from '@services/const';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Col, Empty, Row } from 'antd';
import { useStateExpenseList, useStoredCompanies } from '@hooks';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { DetailsItemView } from '@components/lib/DataDisplay';
import styled from 'styled-components';
import { List } from 'immutable';
import { listToArray, isFunction } from '@services/helpers';
import { ExpenseAggregatedSumView } from '../../Orders';

import './InvoiceItemOrderForm.less';

export interface InvoiceItemOrderFormProps
  extends Omit<
    IDefaultFormProps<unknown, unknown>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  invoice: InvoiceModel;
  loading?: boolean;
  schedule: ScheduleCalendarModel;
  onSuccess: (value: InvoiceItemFormDTO[]) => Promise<void>;
}

const StyledNumContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<ExpenseModel>>;

const StyledExpenseAggregatedSumView = styled(ExpenseAggregatedSumView)`
  position: absolute;
  bottom: 72px;
  left: 0;
  right: 0;
  padding: 5px 10px 10px 5px;
  border-radius: 0;
  border-bottom-width: 0;
  line-height: 1.1;
`;

const StyledRow = styled(Row)`
  padding-bottom: 150px;
`;

export function InvoiceItemOrderForm({
  loading,
  editMode,
  schedule,
  invoice,
  onCancel,
  onSuccess,
  ...rest
}: InvoiceItemOrderFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompany } = useStoredCompanies();

  const [selectedExpenses, setSelectedExpenses] = useState<
    ExpenseModel[] | null
  >(null);

  const {
    expenseList,
    loading: loadingExpenseList,
    limit,
    total,
    aggregatedSum,

    refresh: refreshExpenseList,
  } = useStateExpenseList({
    scheduleUuid: schedule?.uuid!,
    schedule,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Items'),
        `${
          editMode
            ? t('An error occurred during edit item')
            : t('An error occurred during add items')
        } : ${apiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const onAmountEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLElement) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      return true;
    },

    [],
  );

  const handleSuccess = useCallback(async () => {
    const invoiceItems = (selectedExpenses || []).map((expense) =>
      InvoiceMapper.toInvoiceItemFormDTOFactory(expense, {
        withoutPartialFields: true,
        withoutUuid: true,
      }),
    );

    if (isFunction(onSuccess)) {
      await onSuccess(invoiceItems);
    }
  }, [onSuccess, selectedExpenses]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: (selectedExpenses || []).map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ExpenseModel[],
      ) => {
        setSelectedExpenses(selectedRecords);
      },
    }),
    [selectedExpenses],
  );

  const columns = [
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{t('name-s')}</StyledTitle>
      ),
      key: 'name',
      width: '30%',
      render: (expense: ExpenseModel) =>
        expense?.workable instanceof ProductModel ? (
          <DetailsItemView<ProductModel>
            item={expense?.workable}
            fields={{
              product_title: {
                title: '',
              },
            }}
          />
        ) : expense?.workable instanceof ServiceModel ? (
          <DetailsItemView<ServiceModel>
            item={expense?.workable}
            fields={{
              title: {
                title: '',
              },
            }}
          />
        ) : null,
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{t('tAmount')}</StyledTitle>
      ),
      key: 'amount',
      align: 'right' as any,
      width: '10%',
      render: (expense: ExpenseModel) => expense?.amount,
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{`${t('Price')} ${
          defaultCompany?.currency_symbol
        }`}</StyledTitle>
      ),
      key: 'price',
      align: 'right' as any,
      render: (expense: ExpenseModel) => (
        <StyledNumContainer>{expense?.price}</StyledNumContainer>
      ),
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{`${t('Total')} ${
          defaultCompany?.currency_symbol
        }`}</StyledTitle>
      ),
      key: 'total',

      align: 'right' as any,
      render: (expense: ExpenseModel) => (
        <StyledNumContainer>{expense?.total}</StyledNumContainer>
      ),
    },
  ];

  useEffect(() => {
    if (List.isList(expenseList) && selectedExpenses === null) {
      setSelectedExpenses(listToArray(expenseList));
    }
  }, [expenseList, selectedExpenses]);

  return (
    <>
      <DefaultForm<any, any>
        formKeyboardCodes={['Tab', ...ENTER_KEYS]}
        footerClassName="invoice-order-form"
        onEnterPress={onAmountEnter}
        className="invoice-item-form"
        formKeyboardEndSubmit
        withContext
        onSuccess={handleSuccess}
        editMode={editMode}
        submitButtonText={t('Add')}
        cancelButtonText={t('Close')}
        initialValues={{}}
        showNotify={false}
        onCancel={onCancel}
        notifyError={notifyError}
        {...rest}>
        <StyledRow gutter={12}>
          <Col span={24}>
            <StyledTable
              loading={loadingExpenseList}
              dataSource={expenseList}
              onChange={refreshExpenseList}
              columns={columns}
              total={total}
              pageSize={limit}
              rowSelection={rowSelection}
              renderEmpty={
                <Empty
                  description={t(
                    "It looks like you don't have any services or goods added right now",
                  )}
                />
              }
            />
          </Col>
        </StyledRow>
      </DefaultForm>
      <StyledExpenseAggregatedSumView aggregatedSum={aggregatedSum} />
    </>
  );
}
