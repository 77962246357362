import React from 'react';
import classNames from 'classnames';
import Spin, { SpinProps } from '../General/Spin';

import './ScreenLoader.less';

export default ScreenLoader;

export interface IScreenLoaderProps extends Partial<SpinProps> {
  full?: boolean;
}

export /**
 * @name Loader
 * @desc Loader component: displays a loading icon.
 */
function ScreenLoader({ full, ...rest }: IScreenLoaderProps) {
  return (
    <div
      className={classNames('Loader', {
        'Loader-full': full,
      })}>
      <Spin {...rest} />
    </div>
  );
}
