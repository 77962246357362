import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceMarginFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { IUseStatePriceMarginReturnType } from '@hooks';

const PriceMarginSideWindowLazy = React.lazy(
  () => import('../Show/PriceMarginSideWindow'),
);

export interface IPriceMarginUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStatePriceMarginReturnType['handleUpdatePriceMargin'];
  onCancel?: () => void;
  priceMargin: PriceMarginFormDTO;
  isActiveByDefault: boolean;
}

export function PriceMarginUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  priceMargin,
  isActiveByDefault,
  ...rest
}: IPriceMarginUpdateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit price margin')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <PriceMarginSideWindowLazy
          isActiveByDefault={isActiveByDefault}
          editMode
          priceMargin={priceMargin}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
