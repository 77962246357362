import { useUser } from '@hooks/useUser';
import * as React from 'react';
import { EmployeeMapper } from '@structure';
import { IEntityProps } from '@services/types';
import { EmployeeModel } from '@models/EmployeeModel';
import { EmployeeShiftModel } from '@models/EmployeeShiftModel';
import { EmployeeShiftFormDTO } from '@structure/dto/EmployeeShiftFormDTO';
import {
  closeEmployeeShiftById,
  createEmployeeShiftByCurrentProfile,
} from '@services/api/employeeShiftService';

export interface IUseEmployeeShiftProps extends IEntityProps {
  employee: EmployeeModel;
  employeeShiftUuid: string;
}

interface ICreateEmployeeShiftReturnType {}
interface ICloseEmployeeShiftReturnType {}

export interface IUseEmployeeShiftReturnType {
  error?: null | any;
  loading: boolean;
  entity?: EmployeeShiftModel | null;
  refresh?: (
    value: Partial<IUseEmployeeShiftProps>,
  ) => Promise<EmployeeShiftModel | void>;
  createShiftByEmployee: (
    value: EmployeeShiftFormDTO,
  ) => Promise<ICreateEmployeeShiftReturnType>;
  closeShiftByEmployee: (
    value: EmployeeShiftFormDTO,
  ) => Promise<ICreateEmployeeShiftReturnType>;
  employeeShiftUuid?: string;
}

export function useEmployeeShift(): IUseEmployeeShiftReturnType {
  const { refresh: refreshProfileData } = useUser({ loadingOnInit: false });

  /**
   * @desc Create employee shift by employee
   * @param {EmployeeShiftFormDTO} value
   * @return {Promise<ICreateEmployeeShiftReturnType>}
   **/
  const createShiftByEmployee = React.useCallback(
    async (
      value: EmployeeShiftFormDTO,
    ): Promise<ICreateEmployeeShiftReturnType> => {
      const { shift } = await createEmployeeShiftByCurrentProfile(value);

      const employeeShiftModel =
        EmployeeMapper.toEmployeeShiftModelFromDTO(shift);

      setTimeout(async () => {
        await refreshProfileData(true);
      }, 1500);

      return {
        employeeShift: employeeShiftModel,
      };
    },
    [refreshProfileData],
  );

  /**
   * @desc Close employee shift by its employee
   * @param {EmployeeShiftFormDTO} value
   * @return {Promise<ICloseEmployeeShiftReturnType>}
   **/
  const closeShiftByEmployee = React.useCallback(
    async (
      value: EmployeeShiftFormDTO,
    ): Promise<ICloseEmployeeShiftReturnType> => {
      const { shift } = await closeEmployeeShiftById(value);

      const employeeShiftModel =
        EmployeeMapper.toEmployeeShiftModelFromDTO(shift);

      setTimeout(async () => {
        await refreshProfileData(true);
      }, 1500);

      return {
        employeeShift: employeeShiftModel,
      };
    },
    [refreshProfileData],
  );

  return {
    error: null,
    loading: false,
    closeShiftByEmployee,
    createShiftByEmployee,
  };
}
