import React from 'react';
import Spin from 'antd/es/spin';
import styled from 'styled-components';
import { FlexContainer } from '../Styled/FlexContainer';

export interface ITableHeaderProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  loading?: boolean;
}

const PaymentSearchFlexContainer = styled(FlexContainer)`
  margin-bottom: 20px;
  height: fit-content;
  min-height: 24px;
`;

export default function TableHeader({
  children,
  style = {},
  loading = false,
}: ITableHeaderProps): JSX.Element {
  return (
    <Spin spinning={loading} indicator={<></>}>
      <PaymentSearchFlexContainer
        style={style}
        justifyContent="space-between"
        alignItems="flex-end">
        {children}
      </PaymentSearchFlexContainer>
    </Spin>
  );
}
