import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseStateClientVisitListReturnType } from '@hooks';
import {
  DATE_SPECIFIC_FORMAT,
  eq,
  toDateByFormat,
  toUtcDateInFormat,
} from '@services/helpers';
import {
  ClientAbonementModel,
  ClientVisitMapper,
  ClientVisitModel,
} from '@structure';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import styled, { css } from 'styled-components';
import {
  ClientVisitDeleteButton,
  ClientVisitButton,
  ClientVisitEditButton,
} from '../Buttons';
import { Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export interface ClientVisitListProps
  extends Pick<
    IUseStateClientVisitListReturnType,
    | 'visitList'
    | 'loading'
    | 'limit'
    | 'total'
    | 'page'
    | 'handleCreateClientVisit'
    | 'handleEditClientVisit'
    | 'handleDeleteClientVisits'
    | 'handleRefreshClientVisits'
  > {
  abonement: ClientAbonementModel | null;
  disabled?: boolean;
}

const StyledListLayout = styled.div`
  min-width: auto;
  height: auto;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledTable = styled(Table)<{ $empty: boolean }>`
  min-width: 200px;

  .ant-empty-image > svg {
    width: 100%;
  }

  ${({ $empty }) =>
    $empty &&
    css`
      &&& {
        .ant-table-cell {
          background-color: rgba(0, 0, 0, 0.05);
        }

        .ant-table-placeholder:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    `}
` as React.ComponentType as React.FunctionComponent<
  ITableProps<ClientVisitModel>
>;

export function ClientVisitsList({
  visitList,
  loading,
  limit: pageSize,
  total,
  page,
  handleRefreshClientVisits,
  handleCreateClientVisit,
  handleEditClientVisit,
  handleDeleteClientVisits,

  abonement,
  disabled,
}: ClientVisitListProps): JSX.Element {
  const { t } = useTranslation();

  const isToday = useCallback(
    (createDate: string) =>
      eq(
        toUtcDateInFormat(createDate, DATE_SPECIFIC_FORMAT.MONTH_DATES),
        toUtcDateInFormat(new Date(), DATE_SPECIFIC_FORMAT.MONTH_DATES),
      ),
    [],
  );

  const columns = [
    {
      title: t('Visits'),
      key: 'Visits',
      render: (visit: ClientVisitModel) =>
        `${toDateByFormat(visit?.visit_date, 'YYYY.MM.DD HH:mm')}`,
    },
    {
      title: t('Actions'),
      key: 'client_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (visit: ClientVisitModel) => (
        <TableActionCell>
          <ClientVisitEditButton
            disabled={!isToday(visit?.created_at)}
            type="text"
            visit={ClientVisitMapper.toClientVisitFormDTO(visit, true)}
            data-testid="client-edit-button"
            onSuccess={handleEditClientVisit}
          />
          <ClientVisitDeleteButton
            disabled={!isToday(visit?.created_at)}
            data-testid="client-delete-button"
            visits={[visit]}
            onSuccess={handleDeleteClientVisits}>
            <DeleteOutlined />
          </ClientVisitDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  return (
    <StyledListLayout>
      <StyledButtonContainer>
        <ClientVisitButton
          disabled={disabled}
          onSuccess={handleCreateClientVisit}
          visit={ClientVisitMapper.toClientVisitFormDTO(
            {} as ClientVisitModel,
            false,
            abonement!,
          )}
        />
      </StyledButtonContainer>
      <StyledTable
        // @ts-ignore
        $empty={!visitList?.size}
        renderEmpty={
          <Empty
            description={
              <ClientVisitButton
                disabled={disabled}
                visit={ClientVisitMapper.toClientVisitFormDTO(
                  {} as ClientVisitModel,
                  false,
                  abonement!,
                )}
                onSuccess={handleCreateClientVisit}
              />
            }
          />
        }
        total={total}
        pageSize={pageSize}
        dataSource={visitList}
        onChange={handleRefreshClientVisits}
        columns={columns}
        loading={loading}
        page={page}
      />
    </StyledListLayout>
  );
}
