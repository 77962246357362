import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import { ClientFileType, FileListModel } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getClientFileList } from '@services/api/client';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseClientFileListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  clientUuid: string;
  fileType?: ClientFileType;
}

export interface IUseClientFileListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: FileListModel | null;
  refresh: (
    value: Partial<IUseClientFileListProps>,
  ) => Promise<FileListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  clientUuid: string;
  fileType?: ClientFileType;
}

export function useClientFileList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    clientUuid,
    fileType,
  }: IUseClientFileListProps = {} as IUseClientFileListProps,
): IUseClientFileListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    fileType: listFileType,
    handleUpdate,
  } = useStateReducer<Omit<IUseClientFileListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    clientUuid,
    fileType,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      clientUuid = listClientUuid,
      fileType = listFileType,
    }: Partial<IUseClientFileListProps> = {}): Promise<FileListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          clientUuid,
        });

        const files = await cancellablePromise<FileListModel>(
          getClientFileList({
            clientUuid,
            limit,
            offset,
            keywords,
            format: fileType,
          }),
        );

        handleUpdate({
          entityList: files,
          total: files?.total,
          loading: false,
        });

        return files;
      } catch (e: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
        alert(
          'error',
          t('Files'),
          `${t('An error occurred during files loading')} : ${e?.message}`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listClientUuid,
      listKeywords,
      listLimit,
      listOffset,
      listFileType,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && clientUuid) {
      (async () => {
        await refresh({ clientUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, clientUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    fileType: listFileType,
  };
}
