import { StatusModel } from '../internal';

export class StatusCommentFormDTO {
  public status_uuid: string;

  public comment?: string;

  constructor(props?: StatusModel) {
    this.status_uuid = props?.uuid || '';
    this.comment = '';
  }
}
