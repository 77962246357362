import React, { RefObject, useCallback, useEffect, useRef } from 'react';

export interface IUseSearchInputFocusProps {
  loading: boolean;
}

export interface IUseSearchInputFocusReturnType {
  inputRef: RefObject<HTMLInputElement>;
  focus: () => void;
  daleyFocus: (ms?: number) => void;
}

export function useSearchInputFocus({
  loading = false,
}: IUseSearchInputFocusProps): IUseSearchInputFocusReturnType {
  const inputRef = useRef<HTMLInputElement>(null);

  const focus = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  const daleyFocus = useCallback((ms = 500) => setTimeout(focus, ms), [focus]);

  useEffect(() => {
    if (inputRef?.current && !loading) {
      focus();
    }
  }, [loading, focus]);

  return {
    inputRef,
    focus,
    daleyFocus,
  };
}
