import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import styled, { useTheme } from 'styled-components';
import { correctPrice } from '@services/helpers';
import { FinancialSettingsDTO } from '@structure';
import { Alert, Col, Row, Form, InputNumber, Input } from 'antd';
import { StyledTitle } from '@components/lib/Styled';
import { SearchSelect } from '@components/lib/DataDisplay';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';

import {
  getPeriodMovementData,
  getFinancialReportingData,
  FINANCIAL_RELIABILITY_INITIAL_PARAM,
} from '@services/api/setting';

export interface IPaymentsFormProps
  extends Omit<
    IDefaultFormProps<
      Partial<FinancialSettingsDTO>,
      Partial<FinancialSettingsDTO>
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  payments?: Partial<FinancialSettingsDTO>;
  disabled?: boolean;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default function PaymentsForm({
  loading,
  editMode,
  payments = FINANCIAL_RELIABILITY_INITIAL_PARAM,
  disabled,
  ...rest
}: IPaymentsFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Parameters'),
        `${t('An error occurred during edit parameters')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  const recalculateExampleValue = useCallback(
    (value: any, digits: number) => correctPrice(value, digits),
    [],
  );

  const handleChangeQuantityRound = useCallback(
    (value: any) => {
      instance.setFieldValue(
        'quantity_round_example',
        recalculateExampleValue(payments?.quantity_round_example || 0, value),
      );
    },
    [instance, payments?.quantity_round_example, recalculateExampleValue],
  );

  const handleChangePriceRound = useCallback(
    (value: any) => {
      instance.setFieldValue(
        'price_round_example',
        recalculateExampleValue(payments?.price_round_example || 0, value),
      );
    },
    [instance, payments?.price_round_example, recalculateExampleValue],
  );

  return (
    <DefaultForm
      instance={instance}
      initialValues={{
        ...payments,
        quantity_round_example: recalculateExampleValue(
          payments?.quantity_round_example || 0,
          payments?.quantity_round || 2,
        ),
        price_round_example: recalculateExampleValue(
          payments?.price_round_example || 0,
          payments?.price_round || 2,
        ),
      }}
      showNotify={false}
      notifyError={notifyError}
      disabledSubmitButton={disabled}
      {...rest}>
      {({ loadingSubmit }) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={
                <StyledContainer>
                  <StyledTitle>
                    <StyledTitle $color={theme.colors.error}>* </StyledTitle>
                    {t('Period movement funds')}
                  </StyledTitle>
                  <Alert
                    showIcon
                    type="info"
                    message={t('Date interval')}
                    description={t(
                      'It is used to set the date interval for which the movement of funds is shown in the journal of financial reporting by cash registers',
                    )}
                  />
                </StyledContainer>
              }
              name="period_of_financial_statement"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('Period_movement_funds must be specified.'),
                },
              ]}>
              <SearchSelect
                disable={disabled || loading || loadingSubmit}
                name="period_of_financial_statement"
                placeholder={t('Search a period_of_financial_statement')}
                data={getPeriodMovementData({ t })}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={
                <StyledContainer>
                  <StyledTitle>
                    <StyledTitle $color={theme.colors.error}>* </StyledTitle>
                    {t('Financial reporting period')}
                  </StyledTitle>
                  <Alert
                    showIcon
                    type="info"
                    message={t('Report period')}
                    description={t(
                      'It is used for the procedure of posting payments to registers and updating the balance of cash registers. If the payment was created with the status "Draft" and the period "End of day" was selected, then the created payment will be made at the end of the organization\'s working day',
                    )}
                  />
                </StyledContainer>
              }
              required={false}
              name="period_of_payments_movement"
              rules={[
                {
                  required: true,
                  message: t('Financial reporting period must be specified.'),
                },
              ]}>
              <SearchSelect
                disable={disabled || loading || loadingSubmit}
                name="period_of_payments_movement"
                placeholder={t('Search a financial reporting period')}
                data={getFinancialReportingData({ t })}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('Quantity round for field "Amount"')}
              name="quantity_round"
              tooltip={t(
                'It is important to have an ability to manage the rounding for the quantity parameter',
              )}>
              <FullWidthInputNumber
                onChange={handleChangeQuantityRound}
                min={2}
                max={5}
                placeholder={t('Enter the number of digits after the dot')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('Example')} name="quantity_round_example">
              <Input disabled={true} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('Quantity round for field "Price"')}
              name="price_round"
              tooltip={t(
                'It is important to have an ability to manage the rounding for the price parameter',
              )}>
              <FullWidthInputNumber
                onChange={handleChangePriceRound}
                min={2}
                max={5}
                placeholder={t('Enter the number of digits after the dot')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('Example')} name="price_round_example">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
