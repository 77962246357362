import { Record, List } from 'immutable';
import { IListProps } from './List';
import { AbonementGroupDTO, AbonementGroupModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IAbonementGroupListModelProps extends IListProps {
  groups: AbonementGroupDTO[];
}

export interface IAbonementGroupListModel extends IListProps {
  groups: List<AbonementGroupModel>;
  keywords: string;
  page: number;
}

export interface IAbonementGroupListModelReturnType
  extends IAbonementGroupListModel {}

export class AbonementGroupListModel extends Record<IAbonementGroupListModelReturnType>(
  {
    groups: List(),
    total: 0,
    keywords: '',
    page: 1,
  },
) {
  constructor(
    {
      groups = [],
      ...props
    }: IAbonementGroupListModelProps = {} as IAbonementGroupListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IAbonementGroupListModelReturnType = {
      ...props,
      keywords: '',
      page: 1,
      groups: List(
        groups?.map(
          (group) => new AbonementGroupModel(group, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
