import { Dispatch } from 'redux';
import { FORGOT_PASSWORD_PAGE_STATE } from '../constants';
import { LocalStorageItems } from '@services/const';
import { decryptObject, encryptObject } from '@services/helpers';

export interface IForgotPasswordPageState {
  identifier: string | null;
  approveEnter: boolean | null;
  timeWhenCodeWasSend: Date | null;
  verification: string | null;
  verification_code: string | null;
  loading: boolean;
  isShowWarning: boolean;
}

export const forgotPasswordPageInitialState = {
  identifier: null,
  approveEnter: null,
  timeWhenCodeWasSend: null,
  loading: true,
  verification: null,
  verification_code: null,
  isShowWarning: true,
};

export interface ISetForgotPasswordPageStateParam {
  type: FORGOT_PASSWORD_PAGE_STATE;
}

export interface IUpdateForgotPasswordPageStateParam {
  type: FORGOT_PASSWORD_PAGE_STATE;
  state: Partial<IForgotPasswordPageState>;
}

export interface IResetForgotPasswordPageStateParam {
  type: FORGOT_PASSWORD_PAGE_STATE;
}

export function setForgotPasswordPageState(): (
  dispatch: Dispatch,
) => Promise<ISetForgotPasswordPageStateParam> {
  return async function (dispatch: Dispatch) {
    const cryptoState = localStorage.getItem(
      LocalStorageItems.verificationDataForgotPassword,
    );

    let state = {};

    if (cryptoState) {
      state = decryptObject(cryptoState);
    }

    return dispatch({
      type: FORGOT_PASSWORD_PAGE_STATE.SET_STATE,
      state: state
        ? { ...state, isShowWarning: true }
        : forgotPasswordPageInitialState,
    });
  };
}

export function updateForgotPasswordPageState(
  state: Partial<IForgotPasswordPageState>,
): (dispatch: Dispatch) => Promise<IUpdateForgotPasswordPageStateParam> {
  return async function (dispatch: Dispatch) {
    const encryptForgotPasswordPrevState = localStorage.getItem(
      LocalStorageItems.verificationDataForgotPassword,
    );

    if (encryptForgotPasswordPrevState) {
      const forgotPasswordPrevState = decryptObject(
        encryptForgotPasswordPrevState,
      );

      if (forgotPasswordPrevState) {
        const cryptoNewState = encryptObject({
          ...forgotPasswordPrevState,
          ...state,
        });

        localStorage.setItem(
          LocalStorageItems.verificationDataForgotPassword,
          cryptoNewState,
        );
      }
    } else {
      const cryptoState = encryptObject(state);

      localStorage.setItem(
        LocalStorageItems.verificationDataForgotPassword,
        cryptoState,
      );
    }

    return dispatch({
      type: FORGOT_PASSWORD_PAGE_STATE.UPDATE_STATE,
      state,
    });
  };
}

export function resetForgotPasswordPageState(): (
  dispatch: Dispatch,
) => Promise<IResetForgotPasswordPageStateParam> {
  return async function (dispatch: Dispatch) {
    localStorage.removeItem(LocalStorageItems.verificationDataForgotPassword);

    return dispatch({
      type: FORGOT_PASSWORD_PAGE_STATE.RESET_STATE,
      state: forgotPasswordPageInitialState,
    });
  };
}
