import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { ServiceModel, ServiceParametersFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { ApiError, TIME_LIST_SERVICES } from '@services/types';
import { IUseStateEmployeeReturnType } from '@hooks';

const ServiceParametersSideWindowLazy = React.lazy(
  () => import('../Show/ServiceParametersSideWindow'),
);

export interface IServiceParametersUpdateButtonProps
  extends Omit<IActionButtonProps, 'children'>,
    Pick<IUseStateEmployeeReturnType, 'employeeServiceBonuses'> {
  children?: React.ReactNode;
  onSuccess: (
    value: ServiceParametersFormDTO,
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  onCancel?: () => void;
  parameters: ServiceParametersFormDTO;
  servicePeriod: TIME_LIST_SERVICES;
  service: ServiceModel | null;
}

export default function ServiceParametersUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  parameters,
  servicePeriod,
  service,
  employeeServiceBonuses,

  ...rest
}: IServiceParametersUpdateButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit company service')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ServiceParametersSideWindowLazy
          employeeServiceBonuses={employeeServiceBonuses}
          servicePeriod={servicePeriod}
          parameters={parameters}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          service={service}
        />
      </Suspense>
    </>
  );
}
