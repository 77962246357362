import React, {
  Suspense,
  forwardRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  ImageGallery,
  SuspenseEmpty,
  Button,
} from '@components/lib/DataDisplay';
import { TourProps } from '@components/lib/DataDisplay';
import styled, { useTheme } from 'styled-components';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { List } from 'immutable';
import { listToArray } from '@services/helpers';
import { useStopLoading, useStoredProductPhotoList } from '@hooks';
import { ProductModel } from '@structure';
import { useTranslation } from 'react-i18next';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { Routes } from '@services/types';
import { useNavigate } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';

import './ProductTourView.less';

const TourLazy = React.lazy(() => import('@components/lib/DataDisplay/Tour'));

export interface ProductTourViewProps extends Omit<TourProps, 'children'> {
  product: ProductModel | null;
  leftover?: number;
  price?: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  width: 100%;
  height: 355px;
  overflow: hidden;
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;

  width: 50%;
  height: 100%;
`;

const StyledPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;

  width: 50%;
`;

const Title = styled(StyledTitle)`
  max-width: 90%;
`;

const StyledCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const StyledCodeContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const StyledCloseButton = styled(Button)`
  width: fit-content;
  height: fit-content;
`;

const StyledDescriptionScroll = styled(StyledDescription)`
  position: relative;
  white-space: unset;

  overflow: auto;
  height: 180px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 25px;
`;

const EmptyLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const ProductTourView = forwardRef(function ProductTourView(
  { visible, handleCancel, product, leftover, price }: ProductTourViewProps,
  ref: any,
): React.JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme: any = useTheme();

  const [items, setItems] = useState<ReactImageGalleryItem[]>([
    {
      original: '/images-empty.png',
      thumbnail: '/images-empty.png',
      thumbnailHeight: 20,
      thumbnailWidth: 30,
    },
  ]);

  const {
    photoList,
    loading: productPhotoLoading,
    error: productPhotoError,
  } = useStoredProductPhotoList({
    productUuid: product?.uuid!,
    loadOnInit: !!product?.uuid,
  });

  const loading = useStopLoading({
    loading: productPhotoLoading,
    error: productPhotoError,
    message: 'An error occurred during photos loading',
  });

  useEffect(() => {
    if (List.isList(photoList) && photoList?.size) {
      const items = photoList?.map(({ file_url, file_original_name }) => ({
        original: file_url,
        thumbnail: file_url,
        originalTitle: file_original_name,
        thumbnailTitle: file_original_name,
        thumbnailHeight: 50,
      }));

      setItems(listToArray(items));
    }
  }, [photoList]);

  const handleNavigateToProductPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.goods}/${Routes.products}/${product?.uuid}`,
    );
  }, [navigate, product?.uuid]);

  return (
    <Suspense fallback={<SuspenseEmpty />}>
      <TourLazy visible={visible} handleCancel={handleCancel} ref={ref}>
        <StyledContainer>
          {loading ? (
            <EmptyLayout>
              <StyledLoadingOutlined />
            </EmptyLayout>
          ) : (
            <>
              <StyledPhotoContainer>
                <ImageGallery
                  additionalClass="product-tour-gallery"
                  showBullets
                  showThumbnails
                  showNav
                  showFullscreenButton
                  items={items}
                  showPlayButton={false}
                  iconSize={18}
                />
                <StyledCloseButton onClick={handleCancel} type="text">
                  {t('Close')}
                </StyledCloseButton>
              </StyledPhotoContainer>
              <StyledContent>
                <Title fontWeight={500} fontSize={18}>
                  {product?.product_title}
                </Title>
                <Button onClick={handleNavigateToProductPage} type="default">
                  {t('Go to the product page')}
                </Button>
                {product?.product_barcode || leftover ? (
                  <StyledCodeContainer>
                    {product?.product_barcode ? (
                      <StyledCodeContainerItem>
                        <Title fontWeight={500}>{`${t('Code')}:`}</Title>
                        <StyledDescription fontWeight={400}>
                          {product?.product_barcode}
                        </StyledDescription>
                      </StyledCodeContainerItem>
                    ) : null}

                    {leftover ? (
                      <StyledCodeContainerItem>
                        <Title fontWeight={500}>{`${t('Leftover')}:`}</Title>
                        <StyledDescription fontWeight={400}>
                          {leftover}
                        </StyledDescription>
                      </StyledCodeContainerItem>
                    ) : null}
                  </StyledCodeContainer>
                ) : null}

                {price ? (
                  <Title
                    $color={theme.colors.success}
                    fontWeight={500}
                    fontSize={25}>
                    {price}
                  </Title>
                ) : null}

                {product?.product_description ? (
                  <>
                    <Title fontWeight={500}>{`${t('Characteristics')}:`}</Title>
                    <StyledDescriptionScroll fontWeight={400}>
                      {product?.product_description}
                    </StyledDescriptionScroll>
                  </>
                ) : null}
              </StyledContent>
            </>
          )}
        </StyledContainer>
      </TourLazy>
    </Suspense>
  );
});
