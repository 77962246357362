import { Dispatch } from 'redux';
import { EMPLOYEE_SERVICE_LIST } from '../constants';
import { ServiceListModel, ServiceModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetEmployeeServiceListParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeServiceList: ServiceListModel | null;
  employeeUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddEmployeeServiceToListParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeService: ServiceModel;
  employeeUuid: string;
}

export interface IAddServiceToEmployeeParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeService: ServiceModel;
  employeeUuid: string;
}

export interface IUpdateEmployeeServiceFromListParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeService: ServiceModel;
  employeeUuid: string;
}

export interface IUpdateServiceFromEmployeeParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeService: ServiceModel;
  employeeUuid: string;
}

export interface IDeleteEmployeeServiceFromListParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeService: ServiceModel;
  employeeUuid: string;
}

export interface IDeleteServiceFromListEmployeeParam {
  type: EMPLOYEE_SERVICE_LIST;
  employeeServiceUuid: string;

  employeeUuid: string;
}

export interface IErrorEmployeeServiceListParam {
  type: EMPLOYEE_SERVICE_LIST;
  error: ApiError | undefined;
}

export function setEmployeeServiceList(
  employeeServiceList: ServiceListModel | null,
  employeeUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetEmployeeServiceListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.SET_EMPLOYEE_SERVICE_LIST,
      employeeServiceList,
      employeeUuid,
      keywords,
      total,
      page,
    });
  };
}

export function loadMoreEmployeeServiceList(
  employeeServiceList: ServiceListModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<ISetEmployeeServiceListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.LOAD_MORE_EMPLOYEE_SERVICE_LIST,
      employeeServiceList,
      employeeUuid,
    });
  };
}

export function addServiceToListEmployee(
  employeeService: ServiceModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IAddEmployeeServiceToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.ADD_EMPLOYEE_SERVICE,
      employeeService,
      employeeUuid,
    });
  };
}

export function addServiceToEmployeeList(
  employeeService: ServiceModel,
): (
  dispatch: Dispatch,
) => Promise<Omit<IAddServiceToEmployeeParam, 'employeeUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.ADD_SERVICE_TO_EMPLOYEE,
      employeeService,
    });
  };
}

export function updateEmployeeServiceFromList(
  employeeService: ServiceModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeServiceFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.UPDATE_EMPLOYEE_SERVICE,
      employeeService,
      employeeUuid,
    });
  };
}

export function updateServiceFromEmployeeList(
  employeeService: ServiceModel,
): (
  dispatch: Dispatch,
) => Promise<Omit<IUpdateServiceFromEmployeeParam, 'employeeUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.UPDATE_SERVICE_FOR_EMPLOYEE,
      employeeService,
    });
  };
}

export function deleteEmployeeServiceFromList(
  employeeServiceUuid: string,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteServiceFromListEmployeeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.DELETE_SERVICE_FROM_EMPLOYEE,
      employeeServiceUuid,
      employeeUuid,
    });
  };
}

export function deleteServiceFromEmployeeList(
  employeeServiceUuid: string,
): (
  dispatch: Dispatch,
) => Promise<Omit<IDeleteServiceFromListEmployeeParam, 'employeeUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.DELETE_EMPLOYEE_SERVICE,
      employeeServiceUuid,
    });
  };
}

export function errorEmployeeServiceInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorEmployeeServiceListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_SERVICE_LIST.ERROR_EMPLOYEE_SERVICE_LIST,
      error,
    });
  };
}
