import { Record } from 'immutable';
import {
  ClientMapper,
  ClientModel,
  CompanyMapper,
  CompanyModel,
  CompanyRequisiteDTO,
  ICompanyRequisiteDTOProps,
  InvoiceDTO,
  InvoiceStats,
  InvoiceStatus,
  InvoiceType,
  ProfileMapper,
  ProfileModel,
  StoreDocumentMultiCurrencyDTOProps,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export interface InvoiceModelReturnType {
  uuid: string;
  inv_type: InvoiceType;
  inv_date: string;
  inv_income_number: string;
  inv_internal_number: string;
  inv_company_requisites: CompanyRequisiteDTO;
  inv_client_requisites: CompanyRequisiteDTO;
  inv_multi_currencies: StoreDocumentMultiCurrencyDTOProps[];
  inv_discount_value: string;
  inv_sum_total: string;
  inv_sum_discount: string;
  inv_comment: string;
  inv_discount_type: StorePaymentDocumentDiscountType;
  inv_discount_source: StorePaymentDocumentDiscountSourceType;
  inv_status: InvoiceStatus;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel;
  client: ClientModel;
  company: CompanyModel;
  has_order_operations: boolean;
  has_store_operations: boolean;
}

export const invoiceRequisite: CompanyRequisiteDTO = new CompanyRequisiteDTO(
  {} as ICompanyRequisiteDTOProps,
);

export class InvoiceModel extends Record<InvoiceModelReturnType>({
  uuid: '',
  inv_type: InvoiceType.IN,
  inv_date: '',
  inv_income_number: '',
  inv_internal_number: '',
  inv_company_requisites: invoiceRequisite,
  inv_client_requisites: invoiceRequisite,
  inv_multi_currencies: [],
  inv_discount_value: correctPrice(0),
  inv_sum_total: correctPrice(0),
  inv_sum_discount: correctPrice(0),
  inv_discount_type: StorePaymentDocumentDiscountType.FIXED,
  inv_discount_source: StorePaymentDocumentDiscountSourceType.CUSTOM,
  inv_status: InvoiceStatus.DRAFT,
  created_at: '',
  updated_at: '',
  inv_comment: '',
  created_by: {} as ProfileModel,
  client: {} as ClientModel,
  company: {} as CompanyModel,
  has_order_operations: false,
  has_store_operations: false,
}) {
  constructor(
    props: InvoiceDTO = {} as InvoiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: InvoiceModelReturnType = {
      ...props,

      inv_discount_value:
        props?.inv_discount_type === StorePaymentDocumentDiscountType.FIXED
          ? correctPrice(props?.inv_discount_value || 0)
          : `${props?.inv_discount_value || 0}`,
      inv_sum_total: correctPrice(props?.inv_sum_total || 0),
      inv_sum_discount: correctPrice(props?.inv_sum_discount || 0),

      created_by: ProfileMapper.toProfileModel(
        ProfileMapper.toProfileDTO(props?.created_by),
        [WithoutRecursion.invoice, ...withoutRecursion],
      ),
      client: ClientMapper.toClientModel(
        ClientMapper.toClientDTO(props?.client),
        true,
        [WithoutRecursion.invoice, ...withoutRecursion],
      ),
      company: CompanyMapper.toCompanyModel(
        CompanyMapper.toCompanyDTO(props?.company),
        [WithoutRecursion.invoice, ...withoutRecursion],
      ),
    };

    super(options);
  }
}

export interface InvoiceStatsModelReturnType {
  in_total: number;
  out_total: number;
  sum_in_total: string;
  sum_out_total: string;
}

export class InvoiceStatsModel extends Record<InvoiceStatsModelReturnType>({
  in_total: 0,
  out_total: 0,
  sum_in_total: correctPrice(0),
  sum_out_total: correctPrice(0),
}) {
  constructor(props: InvoiceStats = {} as InvoiceStats) {
    const options: InvoiceStatsModelReturnType = {
      ...props,

      in_total: props?.in_total || 0,
      out_total: props?.out_total || 0,
      sum_in_total: correctPrice(props?.sum_in_total || 0),
      sum_out_total: correctPrice(props?.sum_out_total || 0),
    };

    super(options);
  }
}
