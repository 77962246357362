import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IProductListDTO,
  ProductMapper,
  ProductModel,
  ProductListModel,
  IProductsListStatsProps,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getProductListByCategory } from '@services/api/product';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { StatusError } from '@components/lib/Errors';

export interface IUseProductListByCategoryProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  categoryUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
  minKeywordLength?: number;
  isThrowError?: boolean;
}

export interface IUseProductListByCategoryReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ProductModel> | null;
  refresh: (
    value: Partial<IUseProductListByCategoryProps>,
  ) => Promise<ProductListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  categoryUuid: string;
  stats: IProductsListStatsProps;
  with_balance?: boolean;
  store_uuid?: string;
  parent_category_uuid?: string;
  minKeywordLength?: number;
}

export function useProductListByCategory(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    categoryUuid,
    with_balance,
    store_uuid,
    minKeywordLength = 0,
  }: IUseProductListByCategoryProps = {} as IUseProductListByCategoryProps,
): IUseProductListByCategoryReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    categoryUuid: listUuid,
    stats: listStats,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    minKeywordLength: listMinKeywordLength,
    handleUpdate,
  } = useStateReducer<Omit<IUseProductListByCategoryReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    categoryUuid,
    stats: {} as IProductsListStatsProps,
    with_balance,
    store_uuid,
    loading: loadOnInit,
    minKeywordLength,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      categoryUuid = listUuid,
      with_balance = listWithBalance,
      store_uuid = listStoreUuid,
      minKeywordLength = listMinKeywordLength || 0,
      isThrowError = false,
    }: Partial<IUseProductListByCategoryProps> = {}): Promise<ProductListModel | void> => {
      try {
        handleUpdate({
          loading: keywords?.length >= minKeywordLength ? showLoading : false,
          error: null,
          limit,
          offset,
          keywords,
          categoryUuid,
          with_balance,
          store_uuid,
        });

        if (keywords?.length >= minKeywordLength) {
          const { products, stats, total } =
            await cancellablePromise<IProductListDTO>(
              getProductListByCategory({
                limit,
                offset,
                keywords,
                categoryUuid,
                with_balance,
                store_uuid,
              }),
            );

          const productListModel = ProductMapper.toProductListModel(
            products,
            total,
            false,
            [],
            stats,
          );

          handleUpdate({
            entityList: productListModel?.products,
            total: productListModel?.total,
            loading: false,
            stats,
          });

          return productListModel;
        }
      } catch (e: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during get materials list')} : ${e?.message}`,
        );
        handleUpdate({
          error,
          loading: false,
        });

        if (isThrowError) {
          throw new StatusError(e?.message, e?.status);
        }
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listWithBalance,
      listStoreUuid,
      listMinKeywordLength,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && categoryUuid) {
      (async () => {
        await refresh({ categoryUuid });
      })();
    }

    if (categoryUuid) {
      handleUpdate({ categoryUuid });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, categoryUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    categoryUuid: listUuid,
    stats: listStats,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    minKeywordLength: listMinKeywordLength,
  };
}
