import * as React from 'react';
import { List } from 'immutable';
import {
  useReportList,
  IUseReportListProps,
  IUseReportListReturnType,
} from './useReportList';
import { ReportModel } from '@structure';
import { head, toDateByFormat, last } from '@services/helpers';
import { deleteReport } from '@services/api/report';
import {
  setReportList as storeSetReportList,
  deleteReportFromList as storeDeleteReport,
  loadMoreReportList as storeLoadMoreReportList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Moment } from 'moment/moment';

export interface IUseStateReportListProps extends IUseReportListProps {}

export interface IUseStateReportListReturnType
  extends Omit<IUseReportListReturnType, 'entityList'> {
  reportList: List<ReportModel> | null;
  handleDeleteReports: (ids: string[]) => Promise<void>;
  handleSearchReports: (keywords: string) => Promise<void>;
  handleSearchByDates: (value: [Moment, Moment]) => Promise<void>;
  handleLoadMoreReports: () => Promise<void>;
  handleRefreshReports: (
    value: Partial<IUseReportListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredReportList(
  {
    companyUuid,
    loadOnInit,
    ...rest
  }: IUseStateReportListProps = {} as IUseStateReportListProps,
): IUseStateReportListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedReportListStatus,
    loading: storedReportListLoading,
    reportList,
    total: storeTotal,
    startDate: storeStartDate,
    endDate: storeEndDate,
    keywords: storeKeywords,
    page: storedPage,
    ...storedReportListParams
  } = useSelector(({ reportList }: RootState) => reportList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: reportListLoading,
    total,
    keywords,
    startDate,
    endDate,
    ...reportsParams
  } = useReportList({
    companyUuid,
    loadOnInit: loadOnInit || storedReportListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedReportListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetReportList(entityList, '', total, startDate, endDate));
    }
  }, [dispatch, endDate, entityList, startDate, storedReportListStatus, total]);

  const handleDeleteReports = React.useCallback(
    async (ids: string[]) => {
      await deleteReport(head(ids));

      setTimeout(() => {
        dispatch(storeDeleteReport(head(ids)));
      }, 100);

      alert('success', t('Report'), t('Report delete success'));
    },
    [alert, dispatch, t],
  );

  const handleSearchReports = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);
      const reportListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (reportListModel && List.isList(reportListModel?.reports)) {
        dispatch(
          storeSetReportList(
            reportListModel.reports,
            keywords,
            reportListModel.total,
            startDate,
            endDate,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, endDate, refresh, startDate],
  );

  const handleSearchByDates = React.useCallback(
    async (value: [Moment, Moment]) => {
      setLoadingMore(true);
      const startDate = toDateByFormat(head(value), 'YYYY-MM-DD');
      const endDate = toDateByFormat(last(value), 'YYYY-MM-DD');

      const reportListModel = await refresh({
        offset: 0,
        limit: 10,
        startDate,
        endDate,
        keywords,
      });

      if (reportListModel && List.isList(reportListModel?.reports)) {
        dispatch(
          storeSetReportList(
            reportListModel.reports,
            keywords,
            reportListModel.total,
            startDate,
            endDate,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, keywords, refresh],
  );

  const handleLoadMoreReports = useCallback(async () => {
    if (
      List.isList(reportList) &&
      reportList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const reportListModel = await refresh({
        offset: reportList?.size,
        limit: 10,
        showLoading: false,
      });

      if (reportListModel && List.isList(reportListModel?.reports)) {
        dispatch(storeLoadMoreReportList(reportListModel?.reports));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, reportList, storeTotal]);

  const handleRefreshReports = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseReportListProps> & { page: number }) => {
      const reportListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (reportListModel && List.isList(reportListModel?.reports)) {
        dispatch(
          storeSetReportList(
            reportListModel?.reports,
            '',
            reportListModel?.total,
            startDate,
            endDate,
            page,
          ),
        );
      }
    },
    [dispatch, endDate, refresh, startDate],
  );

  return {
    ...reportsParams,
    ...storedReportListParams,
    keywords: storeKeywords || '',
    reportList,
    total: storeTotal,
    startDate: storeStartDate || '',
    endDate: storeEndDate || '',
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(reportList) && reportListLoading) ||
      !storedReportListLoading,
    handleDeleteReports,
    handleSearchReports,
    handleLoadMoreReports,
    status: storedReportListStatus,
    handleRefreshReports,
    handleSearchByDates,
    page: storedPage || 1,
  };
}
