import { Record } from 'immutable';
import { StatusCategoryDTO, StatusCategoryInternalCode } from '../internal';
import { WithoutRecursion } from '../type';

export interface IStatusCategoryModelReturnType {
  uuid: string;
  title: string;
  description: string;
  colour: string;
  internal_code: StatusCategoryInternalCode;
  created_at: string;
  updated_at: string;
  pos: number;
}

export class StatusCategoryModel extends Record<IStatusCategoryModelReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    colour: '',
    internal_code: StatusCategoryInternalCode.New,
    created_at: '',
    updated_at: '',
    pos: 0,
  },
) {
  constructor(
    props: StatusCategoryDTO = {} as StatusCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStatusCategoryModelReturnType = {
      ...props,
    };

    super(options);
  }
}
