import React from 'react';
import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import Color from 'color';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isThereContent } from '@services/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { IUseStoreDocumentListProps, useStoredCompanies } from '@hooks';
import { StoreDocumentShowOnly, StoreDocumentStatsDTO } from '@structure';
import { IoDocuments } from 'react-icons/io5';
import { HiDocumentPlus, HiDocumentMinus } from 'react-icons/hi2';

export interface IStoreDocumentTypeViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type?: StoreDocumentShowOnly;
  color: string;
  selected?: boolean;
  title: string;
  filterByOptions?: string[];
  stats: StoreDocumentStatsDTO | null;
  onClick: (value: Partial<IUseStoreDocumentListProps>) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledPropsContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 20px;
`;

const iconStyle = css<{ $color: string }>`
  color: ${({ $color }) => $color};

  font-size: 20px;
`;

const StyledIoDocuments = styled(IoDocuments)`
  ${iconStyle}
`;

const StyledHiDocumentPlus = styled(HiDocumentPlus)`
  ${iconStyle}
`;

const StyledHiDocumentMinus = styled(HiDocumentMinus)`
  ${iconStyle}
`;

export function StoreDocumentTypeView({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  filterByOptions,
  ...rest
}: IStoreDocumentTypeViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  let sum;
  let icon;

  if (type === undefined) {
    sum = stats?.sum_total;
    icon = <StyledIoDocuments $color={textColor} />;
  } else if (type === StoreDocumentShowOnly.PAID) {
    sum = stats?.sum_paid;
    icon = <StyledHiDocumentPlus $color={textColor} />;
  } else {
    sum = stats?.sum_unpaid;
    icon = <StyledHiDocumentMinus $color={textColor} />;
  }

  return (
    <DetailsActionItemView
      color={color}
      selected={selected}
      onClick={() => onClick({ show_only: type })}
      {...rest}>
      <StyledContainer>
        <StyledPropsContainer>
          <div style={{ flexGrow: 2, display: 'flex', alignItems: 'end' }}>
            {icon || null}
          </div>
          <div style={{ flexGrow: 0.2 }}>
            {filterByOptions && <>Some test</>}&nbsp;
          </div>
        </StyledPropsContainer>
        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
              {`${defaultCompany?.currency_symbol || ''} ${sum}`}
            </StyledTitle>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
