import * as React from 'react';
import { getProfile } from '@services/api/user';
import { ProfileMapper, ProfileModel } from '@structure';
import { useStateReducer } from '@components/lib/libV2/hooks';

export interface IUseUserProps {
  loadingOnInit?: boolean;
}

export interface IUseUserReturnType {
  error: null | any;
  loading: boolean;
  entity: ProfileModel | null;
  refresh: (
    showLoading: boolean,
    token?: string,
    redirect?: boolean,
  ) => Promise<ProfileModel | void>;
}

export function useUser({
  loadingOnInit = true,
}: IUseUserProps = {}): IUseUserReturnType {
  const { entity, loading, error, handleUpdate } = useStateReducer<
    Omit<IUseUserReturnType, 'refresh'>
  >({
    entity: null,
  });

  const refresh = React.useCallback(
    async (
      showLoading: boolean = true,
      token?: string,
    ): Promise<ProfileModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null });

        const profile = await getProfile(undefined, token);
        const profileModel = ProfileMapper.toProfileModel(profile);

        handleUpdate({
          entity: profileModel,
          loading: false,
        });

        return profileModel;
      } catch (err: any) {
        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (loadingOnInit) {
      (async () => {
        await refresh();
      })();
    }
  }, [loadingOnInit, refresh]);

  return {
    error,
    loading,
    refresh,
    entity,
  };
}
