import React from 'react';
import { Button } from '@components/lib/DataDisplay';
import styled from 'styled-components';
import { SettingOutlined, PlusOutlined } from '@ant-design/icons';
import { useModal } from '@components/lib/libV2/hooks';
import { useTranslation } from 'react-i18next';
import {
  EmployeeOrderBonusList,
  IEmployeeOrderBonusListProps,
} from '../../Employees';
import { IRewardSalaryListProps, RewardSalaryList } from '../List';
import { SideWindow } from '@components/lib/Navigation';
import { StyledTitle } from '@components/lib/Styled';
import { RewardSalaryCreateButton } from '../Buttons';
import { EmployeeMapper, EmployeeSalaryModel } from '@structure';

export interface IRewardSalaryAndBonusesModalProps
  extends IEmployeeOrderBonusListProps,
    IRewardSalaryListProps {
  disabled?: boolean;
  salarySettingsDates: string[];
  daleyFocus: (ms?: number) => void;
}

const StyledModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: auto;
  height: auto;
`;

const StyledSettingOutlined = styled(SettingOutlined)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  height: 100%;
`;

const StyledRewardSalaryCreateButton = styled(RewardSalaryCreateButton)`
  width: fit-content;
`;

const StyledButtonTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  width: 100%;
  height: 100%;
`;

export function RewardSalaryAndBonusesModal({
  salary,
  salarySettingsDates,
  createEmployeeSalary,
  updateEmployeeSalary,
  deleteEmployeeSalary,

  bonuses,
  handleChangeEmployeeBonuses,

  disabled,
  daleyFocus,
}: IRewardSalaryAndBonusesModalProps): React.JSX.Element {
  const { visible, handleCancel, handleOnInit } = useModal({
    onCancel: daleyFocus,
  });
  const { t } = useTranslation();

  return (
    <>
      <StyledButton type="text" onClick={handleOnInit} disabled={disabled}>
        <StyledSettingOutlined />
      </StyledButton>
      <SideWindow
        width="50%"
        title={t('Bonuses and salary')}
        visible={visible}
        onClose={handleCancel}>
        <StyledContainer>
          <StyledModalHeader>
            <StyledTitle fontSize={15} fontWeight={500}>
              {`${t('The salary settings')}:`}
            </StyledTitle>
            <StyledRewardSalaryCreateButton
              type="primary"
              onSuccess={createEmployeeSalary}
              salary={EmployeeMapper.toEmployeeSalaryFormDTO(
                {} as EmployeeSalaryModel,
              )}
              salarySettingsDates={salarySettingsDates}
              title={
                <StyledButtonTitleContainer>
                  <PlusOutlined />
                  <StyledTitle>{t('New record')}</StyledTitle>
                </StyledButtonTitleContainer>
              }
            />
          </StyledModalHeader>

          <RewardSalaryList
            salary={salary}
            createEmployeeSalary={createEmployeeSalary}
            updateEmployeeSalary={updateEmployeeSalary}
            deleteEmployeeSalary={deleteEmployeeSalary}
            salarySettingsDates={salarySettingsDates}
          />
          <StyledTitle fontSize={15} fontWeight={500}>
            {`${t('Bonuses')}:`}
          </StyledTitle>
          <EmployeeOrderBonusList
            bonuses={bonuses}
            handleChangeEmployeeBonuses={handleChangeEmployeeBonuses}
          />
        </StyledContainer>
      </SideWindow>
    </>
  );
}
