import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useKeyboardActions,
  useStopLoading,
  useStoredCompanies,
  useStoredProductCategoryList,
} from '@hooks';
import { textToUpperCase, debounce } from '@services/helpers';
import { useBreadcrumb, useStateReducer } from '@components/lib/libV2/hooks';
import { ProductCategoryMappedType, ProductCategoryType } from '@structure';
import { Routes as RoutesType, RoutesAcl } from '@services/types';
import {
  Button,
  HeaderLeft,
  ListActions,
  LoadingMore,
} from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import styled, { useTheme } from 'styled-components';
import { useKeyboard } from '@contex';
import { TbKeyboardOff, TbKeyboard } from 'react-icons/tb';
import { useStoreSale } from '../Managers';
import { StyledListActionsContainer } from '../../Show';
import useOutsideClickElements from '../../../../hooks/useOutsideClickElements';
import { StoreSaleCategoryCard } from '../Show';
import { Empty, Spin } from 'antd';

export interface IStoreSaleCategoryListGUIPageProps {}

export interface StoreSaleGUIState {
  isShowFilters: boolean;
  isShowStoreWarning: boolean;
  documentStoreName: string;
}

const IGNORED_BLUR_CLASS = ['keyboardContainer'];

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
`;

export function StoreSaleCategoryListGUIPage({}: IStoreSaleCategoryListGUIPageProps): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { keywords, handleChangeKeywords, storeDocument } = useStoreSale();

  const once = useRef(false);
  const onceInitInput = useRef(false);

  const {
    documentStoreName,
    isShowStoreWarning,

    handleUpdate: updateSaleContentState,
  } = useStateReducer<StoreSaleGUIState>({
    isShowFilters: false,
    isShowStoreWarning: false,
    documentStoreName: '',
  });

  const {
    isFocus,
    handleClearKeyboards,
    focus,
    initInput,
    keyboardShow,
    handleToggleKeyboard,
  } = useKeyboard();

  const [withKeyboard, setWithKeyboard] = useState<boolean | undefined>(
    keyboardShow ? false : undefined,
  );

  useOutsideClickElements(() => {
    if (keyboardShow) {
      if (isFocus) {
        setWithKeyboard(undefined);
      } else {
        setWithKeyboard(false);
      }

      // handleClearKeyboards();
      // handleChangeKeywords('');
    }
  }, IGNORED_BLUR_CLASS);

  const {
    categoryList,
    loading: categoryListLoading,
    loadingMore: categoryListLoadingMore,
    error: categoryListError,
    keywords: categoryListKeywords,
    isLoadingMore,

    handleSearchProductCategories: onSearchProductCategories,
    handleLoadMoreProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    limit: 30,
    productCategoryType: ProductCategoryType.LIST,
    mappedProductCategoryType: ProductCategoryMappedType.SALE_LIST,
    show_root_only: true,
  });

  const loading = useStopLoading({
    error: categoryListError,
    loading: categoryListLoading || categoryListLoadingMore,
    message: 'An error occurred during materials categories loading',
  });

  const handleSearchProductCategories = useMemo(
    () =>
      debounce(
        async (keywords: string) => onSearchProductCategories({ keywords }),
        1000,
      ),
    [onSearchProductCategories],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      handleChangeKeywords(keywords);
      await handleSearchProductCategories(keywords);
    },
    [handleChangeKeywords, handleSearchProductCategories],
  );

  useKeyboardActions({
    eventListenerCallBack: onSearch,
    keyboardShow,
  });

  const routes = useBreadcrumb([
    {
      path: `/${RoutesType.stores}/${RoutesType.storeSale}`,
      breadcrumbName: 'Sales',
    },
    {
      path: `/${RoutesType.stores}/${RoutesType.storeSale}/${RoutesType.categories}`,
      breadcrumbName: 'Selection of categories',
    },
  ]);

  const navigateToCategory = useCallback(
    (categoryUuid: string) => {
      navigate(categoryUuid);
      handleChangeKeywords('');
    },
    [handleChangeKeywords, navigate],
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${RoutesType.app}/${RoutesType.stores}/${RoutesType.storeSale}`);
  }, [navigate]);

  useEffect(() => {
    if (!once.current) {
      once.current = true;

      handleChangeKeywords(categoryListKeywords);
    }
  }, [categoryListKeywords, handleChangeKeywords]);

  useEffect(() => {
    if (!onceInitInput.current) {
      onceInitInput.current = true;
      initInput('store-sell-category-page');
    }
  }, [initInput]);

  return (
    <ListLayout
      isFocusKeyboard={isFocus}
      withKeyboard={keyboardShow}
      showWarning={false}
      withPaddingBottom
      aclItem={RoutesAcl[RoutesType.stores]}
      headerLeft={
        <HeaderLeft
          absolute={false}
          onClick={navigateGoBack}
          title={textToUpperCase(t('Sales'))}
        />
      }
      headerTitle={
        <StyledPageHeader>
          <StyledTitle fontSize={20} fontWeight={400}>
            {textToUpperCase(t('Selection of categories'))}
          </StyledTitle>
          {storeDocument?.uuid ? (
            <StyledDescription>{`${t('Document')}: №${
              storeDocument?.doc_local_number
            }`}</StyledDescription>
          ) : null}
        </StyledPageHeader>
      }
      routes={routes}
      headerExtra={
        <StyledListActionsContainer>
          <ListActions
            onFocus={() => {
              if (keyboardShow) {
                setWithKeyboard(false);
              }
            }}
            onBlur={(e) => {
              if (typeof withKeyboard === 'boolean' && keyboardShow) {
                e.preventDefault();
                e.stopPropagation();
                e?.target?.focus();
                return;
              }
            }}
            className="store-sell-category-page"
            withoutPicker
            value={keywords}
            loading={loading}
            handleSearch={async () => {}}
            onChange={async (e) => onSearch(e?.target?.value)}
            inputTooltip={t('Search a category')}
            inputLabel={t('Search a category')}
            withSearchEndContent={
              <Button
                fitContent
                type="text"
                onClick={() => {
                  handleToggleKeyboard();
                  focus();
                  setWithKeyboard(false);
                }}>
                {keyboardShow ? (
                  <TbKeyboard size={20} color={theme.colors.black} />
                ) : (
                  <TbKeyboardOff size={20} color={theme.colors.black} />
                )}
              </Button>
            }
          />
        </StyledListActionsContainer>
      }
      empty={
        categoryList?.size ? null : (
          <Empty description={t('There are no product category created')} />
        )
      }
      loading={loading && !categoryList?.size}>
      <Spin spinning={loading}>
        <StyledContainer>
          {categoryList?.map((category) => (
            <StoreSaleCategoryCard
              key={category?.uuid}
              category={category}
              navigateToCategory={navigateToCategory}
            />
          ))}
        </StyledContainer>
        <LoadingMore
          loading={isLoadingMore}
          observerCallback={handleLoadMoreProductCategories}
        />
      </Spin>
    </ListLayout>
  );
}
