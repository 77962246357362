import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import { InvoiceListModel, InvoiceType, InvoiceStatus } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getInvoiceList } from '@services/api/invoice';
import { SessionStorageItems } from '@services/const';
import { useMemo } from 'react';
import { head, last } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseInvoiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  invoiceType: InvoiceType;
  invoiceStatus?: InvoiceStatus;
  date_start?: string;
  date_end?: string;
  resident_uuid?: string;
  withoutDates?: boolean;
}

export interface IUseInvoiceListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: InvoiceListModel | null;
  refresh: (
    value: Partial<IUseInvoiceListProps>,
  ) => Promise<InvoiceListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  invoiceType: InvoiceType;
  invoiceStatus?: InvoiceStatus;
  date_start?: any;
  date_end?: any;
  resident_uuid?: string;
}

export function useInvoiceList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    invoiceType,
    invoiceStatus,
    date_start,
    date_end,
    resident_uuid,
    withoutDates,
  }: IUseInvoiceListProps = {} as IUseInvoiceListProps,
): IUseInvoiceListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const invoiceRangeDates = useMemo(
    () =>
      withoutDates
        ? []
        : JSON.parse(
            sessionStorage.getItem(
              `${SessionStorageItems.storeDocumentDates}${invoiceType}`,
            ) || JSON.stringify([]),
          ),
    [invoiceType, withoutDates],
  );

  const startDate = invoiceRangeDates ? head(invoiceRangeDates) : '';

  const endDate = invoiceRangeDates ? last(invoiceRangeDates) : '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    invoiceType: listDocumentType,
    date_start: listStartDate,
    date_end: listEndDate,
    invoiceStatus: listDocumentStatus,
    resident_uuid: listResidentUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseInvoiceListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    invoiceType,
    invoiceStatus,
    date_start: date_start || startDate,
    date_end: date_end || endDate,
    resident_uuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      invoiceType = listDocumentType,
      date_start = listStartDate,
      date_end = listEndDate,
      invoiceStatus = listDocumentStatus,
      resident_uuid = listResidentUuid,
    }: Partial<IUseInvoiceListProps> = {}): Promise<InvoiceListModel | void> => {
      try {
        if (!withoutDates) {
          sessionStorage.setItem(
            `${SessionStorageItems.storeDocumentDates}${invoiceType}`,
            JSON.stringify([date_start, date_end]),
          );
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          invoiceType,
          invoiceStatus,
          date_start,
          date_end,
          resident_uuid,
        });

        const invoices = await cancellablePromise<InvoiceListModel>(
          getInvoiceList({
            companyUuid,
            limit,
            offset,
            keywords,
            inv_type: invoiceType,
            inv_status: invoiceStatus,
            date_start,
            date_end,
            inv_resident_uuid: resident_uuid,
          }),
        );

        handleUpdate({
          entityList: invoices,
          total: invoices?.total,
          loading: false,
        });

        return invoices;
      } catch (e: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
        alert(
          'error',
          t('Bills'),
          `${t('An error occurred during bills loading')} : ${e?.message}`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listDocumentStatus,
      listDocumentType,
      listEndDate,
      listKeywords,
      listLimit,
      listOffset,
      listResidentUuid,
      listStartDate,
      t,
      withoutDates,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid && invoiceType) {
      (async () => {
        await refresh({ companyUuid, invoiceType });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    invoiceType: listDocumentType,
    date_start: listStartDate,
    date_end: listEndDate,
    invoiceStatus: listDocumentStatus,
    resident_uuid: listResidentUuid,
  };
}
