import * as React from 'react';
import { List } from 'immutable';
import {
  useProductLeftoversList,
  IUseProductLeftoversListProps,
  IUseProductLeftoversListReturnType,
} from './useProductLeftoversList';
import {
  ProductLeftoversFormDTO,
  ProductListModel,
  ProductMapper,
  ProductModel,
} from '@structure';
import { deleteProduct, updateProductLeftover } from '@services/api/product';
import {
  setProductLeftoversList as storeSetProductLeftoversList,
  deleteProductLeftoversFromList as storeDeleteProduct,
  loadMoreProductLeftoversList as storeLoadMoreProductLeftoversList,
  updateProductLeftoversFromList as storeUpdateProductLeftoversFromList,
  addProductLeftoversToList as storeAddProductLeftoversToList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head, eq } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateProductLeftoversListProps
  extends IUseProductLeftoversListProps {}

export interface IProductLeftoversSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  store_uuid?: string;
  category_uuid?: string;
  product_uuid?: string;
  show_running_out_products?: boolean;
}

export interface AdditionalUpdateProductLeftoverProps {
  storeUuid?: string;
  categoryUuid?: string;
}

export interface IUseStateProductLeftoversListReturnType
  extends Omit<IUseProductLeftoversListReturnType, 'entityList'> {
  productList: List<ProductModel> | null;
  handleDeleteProductLeftovers: (ids: string[]) => Promise<void>;
  handleUpdateProductLeftover: (
    value: ProductLeftoversFormDTO,
    additionalProps?: AdditionalUpdateProductLeftoverProps,
  ) => Promise<ProductListModel>;
  handleSearchProductLeftovers: (
    value: IProductLeftoversSearchProps,
  ) => Promise<void>;
  handleLoadMoreProductLeftovers: () => Promise<void>;
  handleRefreshProductLeftovers: (
    value: Partial<IUseProductLeftoversListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  store_uuid?: string;
  category_uuid?: string;
  product_uuid?: string;
}

export function useStoredProductLeftoversList(
  {
    companyUuid,
    loadOnInit = true,
    store_uuid,
    ...rest
  }: IUseStateProductLeftoversListProps = {} as IUseStateProductLeftoversListProps,
): IUseStateProductLeftoversListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedProductLeftoversListStatus,
    loading: storedProductLeftoversListLoading,
    productList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    stats: storedStats,
    ...storedProductLeftoversListParams
  } = useSelector(
    ({ productLeftoversList }: RootState) => productLeftoversList,
  );

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: productListLoading,
    total,
    stats,
    ...productsParams
  } = useProductLeftoversList({
    companyUuid,
    loadOnInit,
    store_uuid,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.products) &&
      !entityList.equals(entityList?.products)
    ) {
      dispatch(
        storeSetProductLeftoversList(entityList, '', total, storedPage, stats),
      );
    }
  }, [
    dispatch,
    entityList,
    stats,
    storedProductLeftoversListStatus,
    total,
    storedPage,
  ]);

  const handleDeleteProductLeftovers = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteProduct(ids);

        setTimeout(() => {
          dispatch(storeDeleteProduct(head(ids)));
        }, 100);

        alert('success', t('Materials'), t('Materials delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during delete materials')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchProductLeftovers = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      store_uuid,

      category_uuid,
      product_uuid,
      show_running_out_products,
    }: IProductLeftoversSearchProps) => {
      setLoadingMore(true);

      const productListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        store_uuid,
        category_uuid,
        product_uuid,
        show_running_out_products,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductLeftoversList(
            productListModel,
            keywords,
            productListModel.total,
            1,
            productListModel.stats,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreProductLeftovers = useCallback(async () => {
    if (
      List.isList(productList) &&
      productList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const productListModel = await refresh({
        offset: productList?.size,
        limit: 10,
        showLoading: false,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(storeLoadMoreProductLeftoversList(productListModel));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, productList, storeTotal]);

  const handleRefreshProductLeftovers = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      ...rest
    }: Partial<IUseProductLeftoversListProps> & { page: number }) => {
      setLoadingMore(true);
      const productListModel = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductLeftoversList(
            productListModel,
            '',
            productListModel?.total,
            page,
            productListModel.stats,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleUpdateProductLeftover = React.useCallback(
    async (
      value: ProductLeftoversFormDTO,
      { storeUuid, categoryUuid }: AdditionalUpdateProductLeftoverProps = {},
    ): Promise<ProductListModel> => {
      const { products, total } = await updateProductLeftover(value);

      const productListModel = ProductMapper.toProductListModel(
        products,
        total,
      );

      let isProductCreated = productList?.products?.findIndex(
        ({ uuid }) => uuid === value?.uuid,
      );

      isProductCreated =
        typeof isProductCreated === 'number' ? isProductCreated : -1;

      if (!~isProductCreated) {
        productListModel?.products?.forEach((product) => {
          if (storeUuid || categoryUuid) {
            const productStoreUuid = head(product?.product_balances)
              ?.company_store?.uuid;
            const productCategoryUuid = product?.category?.uuid;

            if (
              storeUuid &&
              categoryUuid &&
              eq(storeUuid, productStoreUuid) &&
              eq(categoryUuid, productCategoryUuid)
            ) {
              dispatch(storeAddProductLeftoversToList(product));
            }

            if (storeUuid && eq(storeUuid, productStoreUuid)) {
              dispatch(storeAddProductLeftoversToList(product));
            }

            if (categoryUuid && eq(categoryUuid, productCategoryUuid)) {
              dispatch(storeAddProductLeftoversToList(product));
            }
          } else {
            dispatch(storeAddProductLeftoversToList(product));
          }
        });
      } else {
        productListModel?.products?.forEach((product) =>
          dispatch(storeUpdateProductLeftoversFromList(product)),
        );
      }

      return productListModel;
    },
    [dispatch, productList?.products],
  );

  return {
    ...productsParams,
    ...storedProductLeftoversListParams,
    productList: productList?.products!,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(productList?.products) && productListLoading) ||
      !storedProductLeftoversListLoading,
    handleDeleteProductLeftovers,
    handleSearchProductLeftovers,
    handleLoadMoreProductLeftovers,
    status: storedProductLeftoversListStatus,
    handleRefreshProductLeftovers,
    keywords: storeKeywords || '',
    page: storedPage || 1,
    stats: storedStats || stats,
    handleUpdateProductLeftover,
  };
}
