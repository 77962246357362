import React from 'react';
import {
  unstable_HistoryRouter as Router,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  LoginPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  AuthOfficePage,
  SignupPage,
  TermsPolicyPage,
  EmployeeSignupPage,
  ApproveEnterPage,
  VerificationForm,
  PrivacyAndGDPRSPage,
  TermsAndConditionsPage,
  EmailVerificationPage,
  VerificationInstructionsPage,
} from '../views/Authorization';
import { EmployeeRegistrationPage } from '../views/Employees';
import { Routes as RoutesType } from '@services/types';
import { tokenHandler } from '@services/core/token';
import { getCookie } from '@services/helpers';
import { AuthRouteLayout } from '@components/lib/Layout';
import { InitRoutes } from './InitRoutes';
import { AppRoutes as AppNavigationRoutes } from './AppRoutes';
import { AuthRoutes } from './AuthRoutes';
import history from './history';
import { AccessDeniedPage } from '../views/AccessDenied';

export default AppRoutes;

const access_token_local = tokenHandler.getAccessToken();
const access_token_cookie = getCookie('access_token');

function AppRoutes() {
  const redirectTo =
    access_token_local || access_token_cookie
      ? `/${RoutesType.app}`
      : `/${RoutesType.auth}`;

  return (
    <Router history={history as any}>
      <InitRoutes>
        <Route path="/*" element={<Navigate replace to={redirectTo} />} />

        <Route
          path={`/${RoutesType.auth}`}
          element={<Navigate replace to={RoutesType.authOffice} />}
        />

        {/* ------------------- AUTH ------------------- */}

        <Route
          path={`${RoutesType.auth}/*`}
          element={
            <AuthRoutes>
              <Route
                path={RoutesType.authOffice}
                element={
                  <AuthRouteLayout withBackButton={false}>
                    <AuthOfficePage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.login}
                element={
                  <AuthRouteLayout>
                    <LoginPage />
                  </AuthRouteLayout>
                }
              />

              {/* ------------------- SIGNUP ------------------- */}

              <Route
                path={RoutesType.signup}
                element={
                  <AuthRouteLayout>
                    <SignupPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.emailVerification}
                element={
                  <AuthRouteLayout>
                    <EmailVerificationPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.verificationInstructions}
                element={
                  <AuthRouteLayout>
                    <VerificationInstructionsPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.employeeSignup}
                element={
                  <AuthRouteLayout>
                    <EmployeeSignupPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={`${RoutesType.employees}/mobile-registration`}
                element={
                  <AuthRouteLayout>
                    <EmployeeRegistrationPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.approveEnter}
                element={
                  <AuthRouteLayout>
                    <ApproveEnterPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.verificationForm}
                element={
                  <AuthRouteLayout>
                    <VerificationForm codeLength={6} />
                  </AuthRouteLayout>
                }
              />

              {/* ------------------- TERMS AND POLICY ------------------- */}

              <Route
                path={RoutesType.termsPolicy}
                element={
                  <AuthRouteLayout>
                    <TermsPolicyPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.privacyAndGDPRS}
                element={
                  <AuthRouteLayout>
                    <PrivacyAndGDPRSPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.termsAndConditions}
                element={
                  <AuthRouteLayout>
                    <TermsAndConditionsPage />
                  </AuthRouteLayout>
                }
              />

              {/* ------------------- FORGOT PASSWORD ------------------- */}

              <Route
                path={RoutesType.forgotPassword}
                element={
                  <AuthRouteLayout>
                    <ForgotPasswordPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.resetPassword}
                element={
                  <AuthRouteLayout>
                    <ResetPasswordPage />
                  </AuthRouteLayout>
                }
              />

              <Route
                path={RoutesType.accessDenied}
                element={
                  <AuthRouteLayout>
                    <AccessDeniedPage />
                  </AuthRouteLayout>
                }
              />
            </AuthRoutes>
          }
        />

        {/* ------------------- APP ------------------- */}

        <Route
          path={`/${RoutesType.app}`}
          element={
            <Navigate
              replace
              to={`${RoutesType.dashboard}/${RoutesType.schedules}`}
            />
          }
        />

        <Route
          path={`/${RoutesType.app}/${RoutesType.dashboard}`}
          element={<Navigate replace to={`${RoutesType.schedules}`} />}
        />

        <Route path={`${RoutesType.app}/*`} element={<AppNavigationRoutes />} />
      </InitRoutes>
    </Router>
  );
}
