import { List } from 'immutable';
import {
  ProductDTO,
  ProductFormDTO,
  IProductDTOProps,
  ProductModel,
  ProductListModel,
  ProductCategoryModel,
  StoreModel,
  ProductLeftoversFormDTO,
  IProductLeftoversAdditionalParams,
  IProductFormAdditionalParams,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';
import { ProductType } from '@services/types';

export interface IProductsListStatsProps {
  [ProductType.Product]: string;
  [ProductType.Category]: string;
  product_price_tags_count: number;
}

export interface IProductListDTO {
  products: ProductDTO[];
  total: number;
  stats?: IProductsListStatsProps;
  category?: ProductCategoryModel;
  store?: StoreModel;
  product?: ProductModel;
}

export class ProductMapper {
  public static toProductDTO(
    product: ProductModel | IProductDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProductDTO {
    return new ProductDTO(product, [
      WithoutRecursion.product,
      ...withoutRecursion,
    ]);
  }

  public static toProductListDTO(
    products: List<ProductModel> | IProductDTOProps[],
    total: number,
    stats?: IProductsListStatsProps,
    withoutRecursion: WithoutRecursion[] = [],
    category?: ProductCategoryModel,
    store?: StoreModel,
    product?: ProductModel,
  ): IProductListDTO {
    const productList = List.isList(products)
      ? listToArray<ProductModel>(products)
      : products;

    return {
      products: productList?.map((product) =>
        ProductMapper.toProductDTO(product, [
          WithoutRecursion.product,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
      category,
      store,
      product,
    };
  }

  public static toProductModel(
    productDTO: ProductDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProductModel {
    return new ProductModel(productDTO, [
      WithoutRecursion.product,
      ...withoutRecursion,
    ]);
  }

  public static toProductListModel(
    productDTOs: ProductDTO[],
    total: number,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: IProductsListStatsProps,
    category?: ProductCategoryModel,
    store?: StoreModel,
    product?: ProductModel,
  ): ProductListModel {
    return new ProductListModel(
      { products: productDTOs, total, stats, category, store, product },
      [WithoutRecursion.product, ...withoutRecursion],
    );
  }

  public static toProductFormDTO(
    productModel: ProductModel,
    additionalParams?: IProductFormAdditionalParams,
  ): ProductFormDTO {
    return new ProductFormDTO(productModel, additionalParams);
  }

  public static toProductLeftoversFormDTO(
    productModel: ProductModel,
    additional: IProductLeftoversAdditionalParams,
  ): ProductLeftoversFormDTO {
    return new ProductLeftoversFormDTO(productModel, additional);
  }
}
