import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { divide, isFunction } from '@services/helpers';
import { Row, Col, Input } from 'antd';
import styled, { css } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export interface IVerificationInputProps {
  length: number;
  onFullFill?: (value: string) => Promise<void>;
  onChange?: () => void;
  onFocus?: (value?: number) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputContainer?: React.CSSProperties;
  selectionColor?: string;
  children?: (value: {
    onSubmitVerify: () => string;
    toggleDisabledInput: () => void;
  }) => React.ReactNode;
  loading?: boolean;
}

const StyledInput = styled(Input)<{ isfocus: number; disabled?: boolean }>`
  border-radius: 10px;
  border-width: 1px;
  font-size: 40px;
  height: 65px;
  text-align: center;
  border-color: ${({ theme }) => theme.colors.lightDisable};

  ${({ isfocus }) =>
    isfocus &&
    css`
      border-color: ${({ theme }) => theme.colors.primary};
    `};

  &[disabled] {
    border-color: ${({ theme }) => theme.colors.lightDisable};

    &:hover {
      border-color: ${({ theme }) => theme.colors.lightDisable};
    }
  }
`;

const StyledRow = styled(Row)`
  position: relative;
  margin: 0;
`;

const StyledCol = styled(Col)`
  padding-right: 16px;
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  top: -12.5px;
  right: 0;
  bottom: 0;
  left: -12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
`;

export default function VerificationInput({
  length = 6,
  onFullFill,
  onChange = () => {},
  onFocus,
  onBlur,
  autoFocus = false,
  inputStyle = {},
  children,
  loading,
}: IVerificationInputProps): JSX.Element {
  const inputRef = useRef<any[]>([]);

  const [input, setInput] = useState(Array(length).fill(''));
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const code = input.join('');

    if (input.length !== code.length && !disabled) {
      setDisabled(false);
    }

    if (input.length === code.length && !disabled) {
      if (isFunction(onFullFill)) {
        (async () => {
          try {
            setDisabled(true);
            await onFullFill(code);
            setDisabled(false);
          } catch (e) {
            setInput(Array(length).fill(''));
            setDisabled(false);
          }
        })();
      }
    }
  }, [disabled, input, length, onFullFill]);

  const handleBlur = useCallback((index: number): void => {
    inputRef?.current[index]?.blur();
  }, []);

  const handleFocus = useCallback(
    (index: number): void => {
      if (index > input.length - 1) {
        handleBlur(focusIndex || 0);
        return setFocusIndex(null);
      }

      inputRef.current[index].focus();
    },
    [focusIndex, handleBlur, input.length],
  );

  useEffect(() => {
    const text = input.join('');

    if (
      text.length > 0 &&
      inputRef.current.length &&
      text.length < input.length
    ) {
      if (isFunction(inputRef.current[text.length].focus)) {
        inputRef.current[text.length].focus();
      }
    }
  }, [input, input.length]);

  const focusNext = useCallback(
    async (index: number, value: string): Promise<void> => {
      if (isFunction(onChange)) {
        onChange();
      }
      const localInput = [...input];

      if (value.length > 1) {
        const passText = value.split('').slice(0, input.length);
        localInput.splice(0, passText.length, ...passText);

        setInput(localInput);

        return;
      }

      if (index < inputRef.current.length - 1 && value) {
        handleFocus(index + 1);
      }

      localInput[index] = value;
      setInput(localInput);
    },
    [handleFocus, input, onChange],
  );
  const onFocusInput = useCallback(
    (index: number): void => {
      if (isFunction(onFocus)) {
        onFocus();
      }

      const newInputs = [...input];

      const emptyInput = newInputs.findIndex((value) => !value);

      if (emptyInput !== -1 && emptyInput < index) {
        return handleFocus(emptyInput);
      }

      for (const i in newInputs) {
        // @ts-ignore
        if (i >= index) {
          newInputs[i] = '';
        }
      }

      setFocusIndex(index);
      setInput(newInputs);
    },
    [handleFocus, input, onFocus],
  );

  const focusPrevious = useCallback((key: string, index: number): void => {
    if (key === 'Backspace' && index !== 0) {
      inputRef.current[index - 1].focus();
    }
  }, []);

  const onBlurInput = useCallback((): void => {
    if (isFunction(onBlur)) {
      onBlur();
    }
    setFocusIndex(null);
  }, [onBlur]);

  const onSubmitVerify = useCallback((): string => {
    return input.join('');
  }, [input]);

  const toggleDisabledInput = useCallback(() => {
    setDisabled((prevState) => !prevState);
  }, []);

  const childProps = useMemo(
    () => ({
      onSubmitVerify,
      toggleDisabledInput,
    }),
    [toggleDisabledInput, onSubmitVerify],
  );

  return (
    <StyledRow gutter={0}>
      {input.map((item, index) => (
        <StyledCol key={String(index)} span={Math.floor(divide(24, length))}>
          <StyledInput
            disabled={disabled || loading}
            key={index}
            ref={(ref: any) => {
              inputRef.current[index] = ref;
            }}
            isfocus={Number(focusIndex === index && !(disabled || loading))}
            style={inputStyle}
            onBlur={onBlurInput}
            onFocus={() => onFocusInput(index)}
            onChange={(event) =>
              focusNext(index, event?.target?.value?.replace(/[^0-9]/g, ''))
            }
            value={input[index] ? input[index].toString() : ''}
            onKeyDown={(e: any) => focusPrevious(e.nativeEvent.key, index)}
          />
        </StyledCol>
      ))}
      {loading ? (
        <StyledLoadingContainer>
          <StyledLoadingOutlined />
        </StyledLoadingContainer>
      ) : null}
      {isFunction(children) ? children(childProps) : null}
    </StyledRow>
  );
}
