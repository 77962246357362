import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import {
  PaymentFormDTO,
  PaymentScheduleFormDTO,
  PaymentScheduleType,
  StoreDocumentModel,
} from '@structure';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { PaymentProps } from '@hooks';
import { IPaymentStoreDocumentReturnType } from '@services/api/storeDocument';
import { toDateByFormat } from '@services/helpers';

const PaymentStoreDocumentPostingSideWindowLazy = React.lazy(
  () => import('../Show/PaymentStoreDocumentPostingSideWindow'),
);

export interface ICreateStoreDocumentPostingPaymentButtonProps
  extends IAddButtonProps {
  onSuccess: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentStoreDocumentReturnType>;
  onCancel?: () => void;
  companyUuid: string;
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  document: StoreDocumentModel;
  paymentPrice?: string;
}

export function CreateStoreDocumentPostingPaymentButton({
  loading = false,
  onSuccess,
  companyUuid,
  onCancel,
  payment,
  title,
  document,
  paymentPrice,
  ...rest
}: ICreateStoreDocumentPostingPaymentButtonProps) {
  const { t } = useTranslation();

  const {
    handleCancel: paymentScheduleHandleCancel,
    handleOnInit: paymentScheduleHandleOnInit,
    handleSuccess: paymentScheduleHandleSuccess,
    visible: paymentScheduleVisible,
  } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        danger={!!title}
        tooltipTitle={t('schedulePayment')}
        onClick={paymentScheduleHandleOnInit}
        loading={loading}
        title={title}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentStoreDocumentPostingSideWindowLazy
          payment={
            payment({
              payment_comment: `${t('Payment of arrears invoice')}  №${
                document?.doc_local_number
              } ${t('From').toLowerCase()} ${toDateByFormat(
                document?.doc_date,
                'DD.MM.YYYY HH:mm',
              )}`,
              type: PaymentScheduleType.Refunds,
              payment_price: paymentPrice,
              payment_default_sum: document?.doc_sum_total,
            }) as PaymentScheduleFormDTO
          }
          loading={loading as boolean}
          onSuccess={paymentScheduleHandleSuccess}
          onCancel={paymentScheduleHandleCancel}
          visible={paymentScheduleVisible}
        />
      </Suspense>
    </>
  );
}
