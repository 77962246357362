import { apiGet, apiPost, apiPatch } from '../core/api';
import { contentApiUrl } from '../const';
import { ApiAnswer, IListSearchProps } from '../types';
import { IEventListProps } from '../helpers';
import { EventList } from '../models';
import {
  ISettingsModuleDTOProps,
  SettingsModuleMapper,
  ISettingsModuleListDTO,
  FinancialSettingsDTO,
  IFinancialSettingsDTOProps,
  CompanyMapper,
  IAlphaNameDTOProps,
  SettingsModuleStatuses,
  SettingsModuleDTO,
  IStatusCategoryDTOProps,
  IStatusCategoryListDTO,
  StatusMapper,
} from '@structure';

export interface ISystemEventList extends ApiAnswer, IEventListProps {}

export interface ISystemModuleListAnswer extends ApiAnswer {
  settings_modules: ISettingsModuleDTOProps[];
}

export interface ISystemModuleAnswer extends ApiAnswer {
  settings_module: ISettingsModuleDTOProps;
}

export interface ActiveOrDeactivateModuleAnswer extends ApiAnswer {
  settings: IFinancialSettingsDTOProps;
}

export interface IStatusCategoryAnswer extends ApiAnswer {
  categories: IStatusCategoryDTOProps[];
}

export interface LinkingModuleDependencyAnswer extends ApiAnswer {
  checkout_status: string;
  checkout_url: string;
}

export interface ActiveOrDeactivateModuleProps {
  companyUuid: string;
  moduleUuid: string;
  status: SettingsModuleStatuses;
}

export interface IGetSettingsModuleListProps extends IListSearchProps {
  companyUuid: string;
}

export async function getSystemEventList(): Promise<EventList> {
  const value = await apiGet<any, IEventListProps>(
    `${contentApiUrl}/system/events`,
    null,
  );

  return new EventList(value);
}

export async function getSettingsModuleList({
  keywords = '',
  offset = 10,
  limit = 10,
  companyUuid,
}: IGetSettingsModuleListProps): Promise<ISettingsModuleListDTO> {
  const { settings_modules, total } = await apiGet<
    IListSearchProps,
    ISystemModuleListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/modules`, {
    keywords,
    offset,
    limit,
  });

  return SettingsModuleMapper.toSettingsModuleListDTO(settings_modules, total);
}

export async function getSettingsModuleById(
  companyUuid: string,
  settingsModuleUuid: string,
): Promise<SettingsModuleDTO> {
  const { settings_module } = await apiGet<null, ISystemModuleAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/modules/${settingsModuleUuid}`,
    null,
  );

  return SettingsModuleMapper.toSettingsModuleDTO(settings_module);
}

export async function activeOrDeactivateModule({
  companyUuid,
  moduleUuid,
  status,
}: ActiveOrDeactivateModuleProps): Promise<FinancialSettingsDTO> {
  const { settings } = await apiPatch<
    Pick<ActiveOrDeactivateModuleProps, 'status'>,
    ActiveOrDeactivateModuleAnswer
  >(
    `${contentApiUrl}/companies/${companyUuid}/settings/modules/${moduleUuid}/status`,
    { status },
  );

  return CompanyMapper.toCompanySettingsDTO({
    settings,
    alfa_name_order: {} as IAlphaNameDTOProps,
  }).settings;
}

export async function linkingModuleDependency(
  companyUuid: string,
  dependencyUuid: string,
): Promise<LinkingModuleDependencyAnswer> {
  return await apiPost<null, LinkingModuleDependencyAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/settings/modules/dependencies/${dependencyUuid}/checkout`,
    null,
  );
}

export async function getStatusCategoryList(): Promise<IStatusCategoryListDTO> {
  const { categories, total } = await apiGet<null, IStatusCategoryAnswer>(
    `${contentApiUrl}/system/custom-status-categories`,
    null,
  );

  return StatusMapper.toStatusCategoryListDTO(categories, total);
}
