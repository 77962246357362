import React from 'react';
import { Space } from 'antd';
import { FlexContainer } from '../../Styled';
import { useAppLayout } from '../../Layout/AppLayout';
import { phoneScreen } from '@services/const';
import styled from 'styled-components';

export interface ITableActionCellProps {
  children: React.ReactNode;
  hover?: boolean;
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

const StyledFlexContainer = styled(FlexContainer)<{ $hover: boolean }>`
  width: fit-content;
  height: 100%;
`;

export function TableActionCell({
  children,
  hover = false,
  className = '',
  direction,
}: ITableActionCellProps): React.JSX.Element {
  const { contentWidth } = useAppLayout();

  return (
    <StyledFlexContainer justifyContent="center" $hover={hover}>
      <Space
        className={`table-action-cell ${className}`}
        size={2}
        direction={
          direction || (contentWidth >= phoneScreen ? 'horizontal' : 'vertical')
        }>
        {children}
      </Space>
    </StyledFlexContainer>
  );
}
