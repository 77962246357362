import * as React from 'react';
import { List } from 'immutable';
import {
  useProductPhotoList,
  IUseProductPhotoListProps,
  IUseProductPhotoListReturnType,
} from './useProductPhotoList';
import { PhotoModel } from '@structure';
import { deleteProductPhoto, uploadProductPhoto } from '@services/api/product';
import {
  setPhotoList as storeSetPhotoList,
  addPhotoToList as storeAddPhotoToList,
  deletePhotoFromList as storeDeletePhoto,
  selectPhotoList,
} from '@store/features/photoListSlice';
import { useSelector, useDispatch } from 'react-redux';
import { head, isFunction } from '@services/helpers';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { useStateReducer } from '@components/lib/libV2/hooks';

export interface IUseStateProductPhotoListProps
  extends IUseProductPhotoListProps {}

export interface IUseStateProductPhotoListReturnType
  extends Omit<IUseProductPhotoListReturnType, 'entityList'> {
  photoList: List<PhotoModel> | null;
  handleDeletePhotos: (ids: string[], callback?: any) => Promise<void>;
  handleUploadPhotos: (photos: File[], callback?: any) => Promise<void>;
  handleRefreshPhotos: (
    value: Partial<IUseProductPhotoListProps>,
  ) => Promise<void>;
  loadingMore: boolean;
}

export interface UseStoredPhotoListState {
  storeTotal: number;
  photoList: List<PhotoModel> | null;
  isStarLoading: boolean;
  loadingMore: boolean;
}

export function useStoredProductPhotoList(
  {
    productUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateProductPhotoListProps = {} as IUseStateProductPhotoListProps,
): IUseStateProductPhotoListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    photoList,
    isStarLoading,
    storeTotal,
    loadingMore,
    handleUpdate: updateStatePhotos,
  } = useStateReducer<UseStoredPhotoListState>({
    storeTotal: 0,
    photoList: null,
    isStarLoading: false,
    loadingMore: false,
  });

  const once = useRef(false);

  const {
    status: storedPhotoListStatus,
    loading: storedPhotoListLoading,
    cachedPhotoMap,
    ...storedPhotoListParams
  } = useSelector(selectPhotoList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: photoListLoading,
    ...photosParams
  } = useProductPhotoList({
    productUuid,
    loadOnInit: false,
    ...rest,
  });

  useEffect(() => {
    if (cachedPhotoMap?.size > 0) {
      const storeDocumentList = cachedPhotoMap.get(productUuid);

      if (storeDocumentList) {
        updateStatePhotos({
          isStarLoading: false,
          photoList: storeDocumentList?.photos,
          storeTotal: storeDocumentList?.total || 0,
        });
      } else {
        updateStatePhotos({ isStarLoading: true });
      }
    }
    if (cachedPhotoMap?.size === 0 && !photoList) {
      updateStatePhotos({ isStarLoading: true });
    }
  }, [
    cachedPhotoMap,
    dispatch,
    photoList,
    entityList,
    storedPhotoListStatus,
    updateStatePhotos,
    productUuid,
  ]);

  useEffect(() => {
    if (
      !List.isList(entityList?.photos) &&
      isStarLoading &&
      loadOnInit &&
      productUuid
    ) {
      once.current = true;

      updateStatePhotos({ isStarLoading: false });

      (async () => {
        const entityList = await refresh({
          productUuid,
        });

        if (entityList) {
          dispatch(
            storeSetPhotoList({
              triggerObjectUuid: productUuid,
              photoList: entityList,
            }),
          );
        }
      })();
    }
  }, [
    productUuid,
    dispatch,
    entityList?.photos,
    isStarLoading,
    loadOnInit,
    refresh,
    updateStatePhotos,
  ]);

  const handleDeletePhotos = React.useCallback(
    async (ids: string[], callback?: any) => {
      try {
        await deleteProductPhoto({ productUuid, photoUuid: head(ids) });

        setTimeout(() => {
          dispatch(
            storeDeletePhoto({
              triggerObjectUuid: productUuid,
              photoUuid: head(ids),
            }),
          );
        }, 100);
        alert('success', t('Photo'), t('Photo delete success'));

        if (isFunction(callback)) {
          callback();
        }
      } catch (error: any) {
        alert(
          'error',
          t('Photo'),
          `${t('An error occurred during delete photo')}: ${error?.message}`,
        );
      }
    },
    [productUuid, alert, t, dispatch],
  );

  const handleRefreshPhotos = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      ...rest
    }: Partial<IUseProductPhotoListProps>) => {
      updateStatePhotos({ loadingMore: true });

      const photoListModel = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (photoListModel && List.isList(photoListModel?.photos)) {
        dispatch(
          storeSetPhotoList({
            triggerObjectUuid: productUuid,
            photoList: photoListModel,
          }),
        );
      }
      updateStatePhotos({ loadingMore: false });
    },
    [updateStatePhotos, refresh, dispatch, productUuid],
  );

  const handleUploadPhotos = React.useCallback(
    async (photos: File[]) => {
      updateStatePhotos({ loadingMore: true });
      try {
        const photoListModel = await uploadProductPhoto({
          productUuid,
          photos,
        });

        if (photoListModel && List.isList(photoListModel?.photos)) {
          dispatch(
            storeAddPhotoToList({
              triggerObjectUuid: productUuid,
              photoList: photoListModel,
            }),
          );
        }
        alert('success', t('Photos'), t('Photos added success'));
      } catch (error: any) {
        alert(
          'error',
          t('Photos'),
          `${t('An error occurred during added photos')}: ${error?.message}`,
        );
      } finally {
        updateStatePhotos({ loadingMore: false });
      }
    },
    [updateStatePhotos, productUuid, alert, t, dispatch],
  );

  return {
    ...photosParams,
    ...storedPhotoListParams,
    photoList: photoList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(photoList) && photoListLoading) || storedPhotoListLoading,
    handleDeletePhotos,
    handleRefreshPhotos,
    handleUploadPhotos,
  };
}
