import * as React from 'react';
import { useCallback } from 'react';
import moment from 'moment';
import {
  correctOrderParams,
  createOrder,
  updateOrder,
  deleteOrder,
  closeOrder,
  cancelClosingOrder,
  cancelOrder,
  findAnotherOrderTime,
  changeOrderStatus,
} from '@services/api/orders';
import { IUseDataItemStateReturnedType } from './useDataItemState';
import { IUseStateEntityListReturnedType } from '@components/lib/libV2/hooks';
import { head, isFunction } from '@services/helpers';
import {
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleCalendarFormDTO,
  ScheduleChangeTimeFormDTO,
  StatusCommentFormDTO,
  ScheduleCloseDTO,
  ScheduleCalendarStatsDTO,
} from '@structure';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import useStoredCompanies from './useStoredCompanies';
import { useStoredCashBoxList } from './useStoredCashBoxList';

export interface IUseStateItemScheduleProps<T>
  extends Partial<IUseDataItemStateReturnedType<T>> {
  stateSchedule?: ScheduleCalendarModel;
  setStats?: (stats: ScheduleCalendarStatsDTO) => void;
}

export interface IUseStateSchedulesProps<T>
  extends Partial<IUseStateEntityListReturnedType<T>> {
  stateSchedule?: ScheduleCalendarModel;
  setStats?: (stats: ScheduleCalendarStatsDTO) => void;
}

export type IUseScheduleActionsProps<T> = IUseStateSchedulesProps<T> &
  IUseStateItemScheduleProps<T>;

export interface IUseScheduleActionsReturnType {
  handleUpdateSchedule: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleCreateSchedule: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleCloseSchedule: (value: ScheduleCloseDTO) => Promise<void>;
  handleChangeScheduleTime: (value: ScheduleChangeTimeFormDTO) => Promise<void>;
  handleDeleteSchedule: (ids: string[]) => Promise<void>;
  handleCancelClosing: (scheduleId: string) => Promise<void>;
  handleCancelSchedule: (scheduleId: string) => Promise<void>;
  handleChangeScheduleStatus: (
    status: StatusCommentFormDTO,
  ) => Promise<ScheduleCalendarModel | void>;
}

export default function useScheduleActions({
  handleUpdate,
  handleCreate,
  handleDelete,
  stateSchedule,
  setStats,
}: IUseScheduleActionsProps<ScheduleCalendarModel> = {}): IUseScheduleActionsReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { handleRefreshCashBoxes } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const handleUpdateSchedule = React.useCallback(
    async (value: ScheduleCalendarFormDTO) => {
      const correctValue = correctOrderParams(value as any);

      const { schedule: scheduleCalendarDTO, stats } = await updateOrder(
        correctValue,
      );

      if (isFunction(setStats)) {
        setStats(stats);
      }

      const scheduleCalendarModel =
        ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

      if (isFunction(handleUpdate)) {
        handleUpdate(scheduleCalendarModel);
      }

      alert('success', t('Order'), t('Order edited success'));
    },
    [alert, handleUpdate, setStats, t],
  );

  const handleChangeScheduleTime = React.useCallback(
    async (value: ScheduleChangeTimeFormDTO) => {
      const scheduleCalendarDTO = await findAnotherOrderTime(value);

      const scheduleCalendarModel =
        ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

      if (isFunction(handleUpdate)) {
        handleUpdate(scheduleCalendarModel);
      }

      alert('success', t('Order'), t('Order change time success'));
    },
    [alert, handleUpdate, t],
  );

  const handleCancelSchedule = React.useCallback(
    async (scheduleUuid: string) => {
      try {
        const scheduleCalendarDTO = await cancelOrder(scheduleUuid);

        const scheduleCalendarModel =
          ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

        if (isFunction(handleUpdate)) {
          handleUpdate(scheduleCalendarModel);
        }
        alert(
          'success',
          t('Company schedule cancel'),
          t('Schedule cancel success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Company schedule cancel'),
          `${t('An error occurred during cancel company schedule')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleUpdate, t],
  );

  const handleCreateSchedule = useCallback(
    async (value: ScheduleCalendarFormDTO): Promise<void> => {
      const correctValue = correctOrderParams({
        ...value,
      } as any);

      const { schedule: scheduleCalendarDTO, stats } = await createOrder(
        correctValue,
      );

      const scheduleCalendarModel =
        ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

      const updatedModel = scheduleCalendarModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      if (isFunction(setStats)) {
        setStats(stats);
      }

      if (isFunction(handleCreate)) {
        handleCreate(updatedModel, true);
      }

      alert('success', t('Order'), t('Order created success'));
    },
    [alert, handleCreate, setStats, t],
  );

  const handleDeleteSchedule = React.useCallback(
    async (ids: string[]): Promise<void> => {
      try {
        const { schedule, stats } = await deleteOrder(head(ids));

        if (schedule && isFunction(handleDelete)) {
          handleDelete(ids);
        }

        if (isFunction(setStats)) {
          setStats(stats);
        }

        alert('success', t('Order'), t('Order delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Order'),
          `${t('An error occurred during delete orders')} : ${error?.message}`,
        );
      }
    },
    [alert, handleDelete, setStats, t],
  );

  const handleCloseSchedule = React.useCallback(
    async (value: ScheduleCloseDTO): Promise<void> => {
      const scheduleCalendarDTO = await closeOrder(value);

      const scheduleCalendarModel =
        ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

      if (isFunction(handleUpdate)) {
        handleUpdate(scheduleCalendarModel);
      }

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Order close'), t('Order close created success'));
    },
    [alert, handleRefreshCashBoxes, handleUpdate, t],
  );

  const handleCancelClosing = React.useCallback(
    async (scheduleId: string) => {
      try {
        const scheduleCalendarDTO = await cancelClosingOrder(scheduleId);

        const scheduleCalendarModel =
          ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

        const updatedSchedule = stateSchedule?.set(
          'status_text',
          scheduleCalendarModel?.status_text,
        );

        if (isFunction(handleUpdate) && updatedSchedule) {
          handleUpdate(updatedSchedule);
        }

        alert(
          'success',
          t('Company schedule cancel closing'),
          t('Company schedule cancel closing success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Company schedule cancel'),
          `${t('An error occurred during cancel closing company schedule')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleUpdate, stateSchedule, t],
  );

  const handleChangeScheduleStatus = React.useCallback(
    async (status: StatusCommentFormDTO) => {
      const scheduleCalendarDTO = await changeOrderStatus(
        stateSchedule?.uuid || '',
        status,
      );

      const scheduleCalendarModel =
        ScheduleCalendarMapper.toScheduleCalendarModel(scheduleCalendarDTO);

      if (isFunction(handleUpdate)) {
        handleUpdate(scheduleCalendarModel);
      }

      if (status?.comment) {
        alert('success', t('Order'), t('Order comment created success'));
      } else {
        alert('success', t('Order status'), t('Order status change success'));
      }

      return scheduleCalendarModel;
    },
    [alert, handleUpdate, stateSchedule?.uuid, t],
  );

  return {
    handleUpdateSchedule,
    handleCreateSchedule,
    handleDeleteSchedule,
    handleCloseSchedule,
    handleCancelClosing,
    handleCancelSchedule,
    handleChangeScheduleTime,
    handleChangeScheduleStatus,
  };
}
