import React from 'react';
import { Routes, Route } from 'react-router';
import {
  SettingsModuleListPage,
  SettingsModulePage,
} from '../views/SettingsModuleList';
import { AppRouteLayout } from '@components/lib/Layout';

export function SettingsModuleRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <SettingsModuleListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":moduleId"
        element={
          <AppRouteLayout>
            <SettingsModulePage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
