import React, { useCallback } from 'react';
import { Col, Row, Form } from 'antd';
import { useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useDefaultForm } from '@contex';
import { FileDraggerView } from '../../Orders/Show/FileDraggerView';
import { useTranslation } from 'react-i18next';
import { IUploadClientListFileAnswer } from '@services/api/client';
import { head } from '@services/helpers';

export interface IClientFileImportSelectFieldsProps {
  handleUploadFileList: (
    xlsFile: File,
  ) => Promise<IUploadClientListFileAnswer | void>;
  handleChangeActiveIndex: (index: number) => void;
}

export function ClientFileImportSelectFields({
  handleUploadFileList,
  handleChangeActiveIndex,
}: IClientFileImportSelectFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { valid, handlerUpdateFormState, formData } = useDefaultForm();
  const theme: any = useTheme();

  const onUploadFileList = useCallback(
    async (files: File[]) => {
      const file = head(files);

      if (file) {
        const clientsFields = await handleUploadFileList(file);

        if (clientsFields) {
          const { fields } = clientsFields?.client || {};
          const { header, uuid } = clientsFields?.file || {};

          const clientFields = (fields || []).map(({ title }) => ({
            label: title,
            value: title,
          }));

          const requiredFields = (fields || [])
            ?.filter((field) => field?.required)
            .map((item) => ({
              from: item?.title,
              to: undefined,
              required: true,
            }));

          const selectHeader = (header || []).map((label) => ({
            label,
            value: label,
          }));

          handlerUpdateFormState({
            file: file?.name,
            clientsFields: clientFields,
            fields: requiredFields,
            header: selectHeader,
            clientsValueFields: fields,
            uuid,
          });
          handleChangeActiveIndex(1);
        }
      }
    },
    [handleChangeActiveIndex, handleUploadFileList, handlerUpdateFormState],
  );

  return (
    <>
      {valid ? (
        <Row gutter={20} justify="end">
          <Col span={24}>
            <Form.Item
              label={t('The supported data file format is an XLS file')}
              name="file"
              rules={[
                {
                  required: true,
                  message: t('File must be specified'),
                },
              ]}
              tooltip={t('The supported data file format is an XLS file')}>
              <FileDraggerView
                accept=".xlsx, .xls"
                multiple={false}
                handleUploadFiles={onUploadFileList}
                handleOpenFileList={() => {}}
                stats={{ files: '0' }}
                description={
                  'Clients to be added to the company. The supported data file format is an XLS file'
                }
              />
            </Form.Item>
          </Col>
          {formData?.file ? (
            <Col span={24}>
              <StyledTitle>
                {`${t('File added')}: `}
                <StyledTitle $color={theme.colors.success}>
                  {formData?.file}
                </StyledTitle>
              </StyledTitle>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </>
  );
}
