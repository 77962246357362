import React from 'react';
import { ClientAbonementLatestStatusDTO } from '@structure';
import styled, { useTheme, css } from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { ClientAbonementColors } from '../Pages';

export interface IAbonementLatestStatusViewProps {
  latestStatus: ClientAbonementLatestStatusDTO | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const StyledLine = styled.div<{ $color: string }>`
  width: 7px;
  height: 100%;
  margin-right: 10px;

  ${({ $color }) =>
    $color &&
    css`
      background-color: ${$color};
    `}
`;

export function AbonementLatestStatusView({
  latestStatus,
}: IAbonementLatestStatusViewProps): JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  return (
    <>
      {latestStatus ? (
        <StyledWrapper>
          <StyledLine
            $color={ClientAbonementColors(theme)[latestStatus?.current_status]}
          />

          <StyledContainer>
            <StyledSpace>
              <StyledTitle
                bold
                $color={
                  ClientAbonementColors(theme)[latestStatus?.current_status]
                }>
                {t(latestStatus?.current_status)}
              </StyledTitle>
              {latestStatus?.comment ? (
                <StyledDescription
                  $color={
                    ClientAbonementColors(theme)[latestStatus?.current_status]
                  }>
                  {latestStatus?.comment}
                </StyledDescription>
              ) : null}
            </StyledSpace>
          </StyledContainer>
        </StyledWrapper>
      ) : null}
    </>
  );
}
