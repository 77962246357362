import { Record, List } from 'immutable';
import { IListProps } from './List';
import { NotificationModel } from './NotificationModel';
import { NotificationDTO } from '../internal';

export interface INotificationListModelProps extends IListProps {
  notifications: NotificationDTO[];
  unread: number;
}

export interface INotificationListModelReturnType extends IListProps {
  notifications: List<NotificationModel>;
  unread: number;
}

export class NotificationListModel extends Record<INotificationListModelReturnType>(
  {
    notifications: List(),
    total: 0,
    unread: 0,
  },
) {
  constructor(
    {
      notifications,
      ...props
    }: INotificationListModelProps = {} as INotificationListModelProps,
  ) {
    super({
      ...props,
      notifications: List(
        notifications.map(
          (notification) => new NotificationModel(notification),
        ),
      ),
    });
  }
}
