import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  usePayment,
  IUsePaymentProps,
  IUsePaymentReturnType,
} from './usePayment';
import { PaymentFormDTO, PaymentModel, PaymentMapper } from '@structure';
import { useStateEntity } from '@components/lib/libV2/hooks';
import {
  updateCompanyPayment,
  updatePaymentCashBox,
} from '@services/api/companyPayments';
import { useDropdownAlert } from '@contex';
import useStoredCompanies from './useStoredCompanies';

export interface IUseStatePaymentProps extends IUsePaymentProps {}
export interface IUseStatePaymentReturnType
  extends Omit<IUsePaymentReturnType, 'entity'> {
  payment: PaymentModel | null;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleUpdatePaymentCashBox: (value: PaymentFormDTO) => Promise<void>;
}

export function useStatePayment({
  paymentUuid,
  ...rest
}: IUseStatePaymentProps): IUseStatePaymentReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entity,
    loading: paymentLoading,
    ...paymentParams
  } = usePayment({
    paymentUuid,
    ...rest,
  });

  const {
    entity: payment,
    loading,
    handleUpdate,
  } = useStateEntity<PaymentModel>(entity);

  const handleUpdatePayment = React.useCallback(
    async (value: PaymentFormDTO): Promise<void> => {
      const payment = await updateCompanyPayment(value);

      const paymentModel = PaymentMapper.toPaymentModel(payment);

      handleUpdate(paymentModel);

      alert('success', t('Payment'), t('Payment edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleUpdatePaymentCashBox = React.useCallback(
    async (value: PaymentFormDTO): Promise<void> => {
      const payment = await updatePaymentCashBox(value);

      const paymentModel = PaymentMapper.toPaymentModel(payment);

      handleUpdate(paymentModel);

      alert('success', t('Payment'), t('Payment cash box edited success'));
    },
    [alert, handleUpdate, t],
  );

  return {
    ...paymentParams,
    loading: loading || paymentLoading,
    payment,
    handleUpdatePayment,
    handleUpdatePaymentCashBox,
  };
}
