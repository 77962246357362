import * as React from 'react';
import { List } from 'immutable';
import {
  useProductList,
  IUseProductListProps,
  IUseProductListReturnType,
} from './useProductList';
import { ProductMappedType, ProductType, ProductModel } from '@structure';
import { deleteProduct } from '@services/api/product';
import {
  setProductList as storeSetProductList,
  deleteProductFromList as storeDeleteProduct,
  loadMoreProductList as storeLoadMoreProductList,
  selectProductList,
} from '@store/features/productListSlice';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { isFunction } from '@sportix/sportix-common-modules';
import { setProductCategoryListStats as storeSetProductCategoryListStats } from '@store/features/productCategoryListSlice';
import { useStateReducer } from '@components/lib/libV2/hooks';

export interface IUseStateProductListProps extends IUseProductListProps {
  mappedProductType?: ProductMappedType;
  productType: ProductType;
}

export interface IProductSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  store_uuid?: string;
  withStoreUuid?: boolean;
  parent_category_uuid?: string;
  companyUuid?: string;
}

export interface IUseStateProductListReturnType
  extends Omit<IUseProductListReturnType, 'entityList'> {
  productList: List<ProductModel> | null;
  handleDeleteProducts: (ids: string[], successCallback?: any) => Promise<void>;
  handleSearchProducts: (
    value: IProductSearchProps,
  ) => Promise<List<ProductModel> | void>;
  handleLoadMoreProducts: () => Promise<void>;
  handleRefreshProducts: (
    value: Partial<IUseProductListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  with_balance?: boolean;
  store_uuid?: string;
  parent_category_uuid?: string;
}

export interface UseStoreProductListState {
  loadingMore: boolean;
  storeKeywords: string;
  storePage: number;
  storeTotal: number;
  productList: List<ProductModel> | null;
  isStarLoading: boolean;
  parentCategoryUuid: string;
}

export function useStoredProductList(
  {
    companyUuid,
    loadOnInit = true,
    parent_category_uuid = '',
    productType: initProductType,
    mappedProductType,
    ...rest
  }: IUseStateProductListProps = {} as IUseStateProductListProps,
): IUseStateProductListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    productList,
    loadingMore,
    isStarLoading,
    storePage,
    storeTotal,
    storeKeywords,
    parentCategoryUuid,
    handleUpdate: updateStateProduct,
  } = useStateReducer<UseStoreProductListState>({
    loadingMore: false,
    storeKeywords: '',
    storePage: 1,
    storeTotal: 0,
    productList: null,
    isStarLoading: false,
    parentCategoryUuid: '',
  });

  const {
    status: storedProductListStatus,
    loading: storedProductListLoading,
    stats: storedStats,
    cachedProductMap,
    ...storedProductListParams
  } = useSelector(selectProductList);

  const dispatch = useDispatch<any>();

  const productType: any = mappedProductType || initProductType;

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: productListLoading,
    total,
    stats,
    ...productsParams
  } = useProductList({
    companyUuid,
    loadOnInit: false,
    parent_category_uuid,
    ...rest,
  });

  useEffect(() => {
    const productCategoryListMap = cachedProductMap?.get(productType);

    if (productCategoryListMap!?.size > 0) {
      const productCategoryList =
        productCategoryListMap?.get(parent_category_uuid);

      if (productCategoryList) {
        updateStateProduct({
          isStarLoading: false,
          productList: productCategoryList?.products,
          storePage: productCategoryList?.page || 1,
          storeKeywords: productCategoryList?.keywords || '',
          storeTotal: productCategoryList?.total || 0,
        });
      } else {
        updateStateProduct({ isStarLoading: true });
      }
    }
    if (cachedProductMap?.size === 0 || !productCategoryListMap) {
      updateStateProduct({ isStarLoading: true });
    }
  }, [
    cachedProductMap,
    productList,
    parent_category_uuid,
    updateStateProduct,
    productType,
  ]);

  useEffect(() => {
    if (
      // !List.isList(entityList?.categories) &&
      isStarLoading &&
      loadOnInit &&
      companyUuid
    ) {
      updateStateProduct({ isStarLoading: false, loadingMore: true });

      (async () => {
        const entityList = await refresh({
          companyUuid,
          keywords: '',
        });

        if (entityList) {
          dispatch(
            storeSetProductList({
              page: 1,
              productList: entityList,
              productType: productType,
              keywords: '',
              categoryUuid: parent_category_uuid,
            }),
          );
        }

        updateStateProduct({ loadingMore: false });
      })();
    }
  }, [
    companyUuid,
    dispatch,
    isStarLoading,
    loadOnInit,
    parent_category_uuid,
    productType,
    refresh,
    updateStateProduct,
  ]);

  const handleDeleteProducts = React.useCallback(
    async (ids: string[], successCallback?: any) => {
      try {
        const { stats } = await deleteProduct(ids);

        setTimeout(() => {
          dispatch(storeDeleteProduct({ productUuid: head(ids), stats }));
        }, 100);

        dispatch(storeSetProductCategoryListStats({ stats }));

        alert('success', t('Materials'), t('Materials delete success'));

        if (isFunction(successCallback)) {
          successCallback();
        }
      } catch (error: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during delete materials')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchProducts = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      store_uuid,
      withStoreUuid = false,
      parent_category_uuid = parentCategoryUuid,
    }: IProductSearchProps) => {
      updateStateProduct({ loadingMore: true });
      const productListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        store_uuid: withStoreUuid
          ? store_uuid
          : keywords?.length
          ? store_uuid
          : '',
        parent_category_uuid,
        companyUuid,
      });

      updateStateProduct({ parentCategoryUuid: parent_category_uuid });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductList({
            productList: productListModel,
            keywords,
            page: productListModel.page,
            productType,
            categoryUuid: parent_category_uuid || '',
          }),
        );
        updateStateProduct({ loadingMore: false });

        return productListModel.products;
      }

      updateStateProduct({ loadingMore: false });
    },
    [
      companyUuid,
      dispatch,
      parentCategoryUuid,
      productType,
      refresh,
      updateStateProduct,
    ],
  );

  const handleLoadMoreProducts = useCallback(async () => {
    if (
      List.isList(productList) &&
      productList?.size < storeTotal &&
      !loadingMore
    ) {
      updateStateProduct({ loadingMore: true });
      const productListModel = await refresh({
        offset: productList?.size,
        limit: 10,
        showLoading: false,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeLoadMoreProductList({
            productList: productListModel,
            productType,
            categoryUuid: parent_category_uuid,
          }),
        );
      }

      updateStateProduct({ loadingMore: false });
    }
  }, [
    productList,
    storeTotal,
    loadingMore,
    updateStateProduct,
    refresh,
    dispatch,
    productType,
    parent_category_uuid,
  ]);

  const handleRefreshProducts = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      ...rest
    }: Partial<IUseProductListProps> & { page: number }) => {
      updateStateProduct({ loadingMore: true });
      const productListModel = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductList({
            productList: productListModel,
            keywords: '',
            page,
            productType,
            categoryUuid: parent_category_uuid || '',
          }),
        );
      }
      updateStateProduct({ loadingMore: false });
    },
    [dispatch, parent_category_uuid, productType, refresh, updateStateProduct],
  );

  return {
    ...productsParams,
    ...storedProductListParams,
    productList: productList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(productList) && productListLoading) ||
      storedProductListLoading,
    handleDeleteProducts,
    handleSearchProducts,
    handleLoadMoreProducts,
    status: storedProductListStatus,
    handleRefreshProducts,
    keywords: storeKeywords || '',
    page: storePage || 1,
    stats: storedStats || stats,
  };
}
