import React from 'react';
import { DEPARTMENT_ICONS } from '../helpers';
import { DepartmentModel } from '@structure';
import styled, { useTheme, css } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';

export interface IDepartmentTitleViewProps {
  department: DepartmentModel | null;
}

const StyledTitleContainer = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export function DepartmentTitleView({
  department,
}: IDepartmentTitleViewProps): JSX.Element {
  const theme: any = useTheme();

  const Icon = department ? DEPARTMENT_ICONS[department?.dep_icon] : null;
  return (
    <StyledTitleContainer
      $color={department?.dep_colour || theme.colors.primary}>
      {Icon ? <Icon size={17} /> : null}
      <StyledTitle>{department?.dep_name}</StyledTitle>
    </StyledTitleContainer>
  );
}
