import { List, Map } from 'immutable';
import { APP_STATE, EMPLOYEE_ATTACHED_SERVICE_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ServiceModel } from '@structure';

interface IEmployeeAttachedListState {
  employeeAttachedServiceList: List<ServiceModel> | null;
  cachedEmployeeAttachedServiceList: Map<string, List<ServiceModel>>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<
    IEmployeeAttachedListState,
    'employeeAttachedServiceList' | 'keywords' | 'total'
  > {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST.SET_EMPLOYEE_ATTACHED_SERVICE_LIST;
  employeeUuid: string;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type:
    | EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE
    | EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE_TO_EMPLOYEE;
  employeeAttachedService: ServiceModel;
  employeeUuid: string;
}

interface UpdateEmployeeAttachedFromList {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE;
  employeeAttachedService: ServiceModel;
  employeeUuid: string;
}

interface DeleteActionFromList {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE;
  employeeAttachedServiceUuid: string;
  employeeUuid: string;
}

interface LoadingActionInList {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST.LOADING_EMPLOYEE_ATTACHED_SERVICE_LIST;
}

interface ErrorActionInList extends Pick<IEmployeeAttachedListState, 'error'> {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST.ERROR_EMPLOYEE_ATTACHED_SERVICE_LIST;
}

interface Handlers {
  [EMPLOYEE_ATTACHED_SERVICE_LIST.SET_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
    action: SetActionList,
  ) => IEmployeeAttachedListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IEmployeeAttachedListState,
    action: SetInitialStateAction,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.LOAD_MORE_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
    action: SetActionList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE]: (
    state: IEmployeeAttachedListState,
    action: AddActionToList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE_TO_EMPLOYEE]: (
    state: IEmployeeAttachedListState,
    action: AddActionToList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE]: (
    state: IEmployeeAttachedListState,
    action: UpdateEmployeeAttachedFromList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE_FOR_EMPLOYEE]: (
    state: IEmployeeAttachedListState,
    action: UpdateEmployeeAttachedFromList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE]: (
    state: IEmployeeAttachedListState,
    action: DeleteActionFromList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE_FOR_EMPLOYEE]: (
    state: IEmployeeAttachedListState,
    action: DeleteActionFromList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ERROR_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
    value: ErrorActionInList,
  ) => IEmployeeAttachedListState;

  [EMPLOYEE_ATTACHED_SERVICE_LIST.LOADING_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
    value?: LoadingActionInList,
  ) => IEmployeeAttachedListState;
  DEFAULT: (state: IEmployeeAttachedListState) => IEmployeeAttachedListState;
}

const initState: IEmployeeAttachedListState = {
  employeeAttachedServiceList: null,
  cachedEmployeeAttachedServiceList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [EMPLOYEE_ATTACHED_SERVICE_LIST.SET_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state,
    { employeeAttachedServiceList, employeeUuid, keywords, total },
  ) => {
    return {
      ...state,
      ...{
        employeeAttachedServiceList,
        cachedEmployeeAttachedServiceList: employeeAttachedServiceList
          ? state.cachedEmployeeAttachedServiceList.set(
              employeeUuid,
              employeeAttachedServiceList,
            )
          : state.cachedEmployeeAttachedServiceList,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total: total >= 0 ? total : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [EMPLOYEE_ATTACHED_SERVICE_LIST.LOAD_MORE_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state,
    { employeeAttachedServiceList, employeeUuid },
  ) => {
    const employeeAttachedServiceListById =
      state.cachedEmployeeAttachedServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeAttachedServiceList:
          List.isList(state.employeeAttachedServiceList) &&
          List.isList(employeeAttachedServiceList)
            ? state.employeeAttachedServiceList.merge(
                employeeAttachedServiceList,
              )
            : state.employeeAttachedServiceList,
        cachedEmployeeAttachedServiceList: employeeAttachedServiceList
          ? state.cachedEmployeeAttachedServiceList.set(
              employeeUuid,
              employeeAttachedServiceListById
                ? employeeAttachedServiceListById.merge(
                    employeeAttachedServiceList,
                  )
                : employeeAttachedServiceList,
            )
          : state.cachedEmployeeAttachedServiceList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE]: (
    state,
    { employeeAttachedService },
  ) => {
    return {
      ...state,
      ...{
        cachedEmployeeAttachedServiceList:
          state.cachedEmployeeAttachedServiceList.map((list) =>
            list.unshift(employeeAttachedService),
          ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE_TO_EMPLOYEE]: (
    state,
    { employeeAttachedService, employeeUuid },
  ) => {
    const employeeAttachedServiceListById =
      state.cachedEmployeeAttachedServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        cachedEmployeeAttachedServiceList: employeeAttachedServiceListById
          ? state.cachedEmployeeAttachedServiceList.update(
              employeeUuid,
              (employeeAttachedServiceList): List<ServiceModel> => {
                if (employeeAttachedServiceList) {
                  return employeeAttachedServiceList.push(
                    employeeAttachedService,
                  );
                } else {
                  return List([employeeAttachedService]);
                }
              },
            )
          : Map({ [employeeUuid]: List([employeeAttachedService]) }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE]: (
    state,
    { employeeAttachedService, employeeUuid },
  ) => {
    const employeeAttachedServiceListById =
      state.cachedEmployeeAttachedServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        cachedEmployeeAttachedServiceList: employeeAttachedServiceListById
          ? state.cachedEmployeeAttachedServiceList.update(
              employeeUuid,
              (employeeAttachedServiceList) => {
                if (employeeAttachedServiceList) {
                  return employeeAttachedServiceList.map((service) => {
                    if (service.uuid === employeeAttachedService.uuid) {
                      return employeeAttachedService.update(
                        'employee_service',
                        () => service.employee_service,
                      );
                    } else {
                      return service;
                    }
                  });
                } else {
                  return List();
                }
              },
            )
          : state.cachedEmployeeAttachedServiceList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE_FOR_EMPLOYEE]: (
    state,
    { employeeAttachedService, employeeUuid },
  ) => {
    const employeeAttachedServiceListById =
      state.cachedEmployeeAttachedServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        cachedEmployeeAttachedServiceList: employeeAttachedServiceListById
          ? state.cachedEmployeeAttachedServiceList.update(
              employeeUuid,
              (employeeAttachedServiceList) => {
                if (employeeAttachedServiceList) {
                  return employeeAttachedServiceList.map((service) => {
                    if (service.uuid === employeeAttachedService.uuid) {
                      return service.merge(employeeAttachedService);
                    } else {
                      return service;
                    }
                  });
                } else {
                  return List();
                }
              },
            )
          : state.cachedEmployeeAttachedServiceList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE]: (
    state,
    { employeeAttachedServiceUuid, employeeUuid },
  ) => {
    const employeeAttachedServiceListById =
      state.cachedEmployeeAttachedServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeAttachedServiceList: List.isList(
          state.employeeAttachedServiceList,
        )
          ? state.employeeAttachedServiceList.filter(
              compose(not, isEqualByUuid(employeeAttachedServiceUuid)),
            )
          : null,
        cachedEmployeeAttachedServiceList: employeeAttachedServiceListById
          ? state.cachedEmployeeAttachedServiceList.update(
              employeeUuid,
              (relatedScheduleList) => {
                if (relatedScheduleList) {
                  return relatedScheduleList.filter(
                    compose(not, isEqualByUuid(employeeAttachedServiceUuid)),
                  );
                } else {
                  return List();
                }
              },
            )
          : state.cachedEmployeeAttachedServiceList,
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE_FOR_EMPLOYEE]: (
    state,
    { employeeAttachedServiceUuid },
  ) => {
    return {
      ...state,
      ...{
        employeeAttachedServiceList: List.isList(
          state.employeeAttachedServiceList,
        )
          ? state.employeeAttachedServiceList.filter(
              compose(not, isEqualByUuid(employeeAttachedServiceUuid)),
            )
          : null,
        cachedEmployeeAttachedServiceList:
          state.cachedEmployeeAttachedServiceList.filter((list) => {
            const index = list.findIndex(
              ({ uuid }) => uuid === employeeAttachedServiceUuid,
            );

            if (index) {
              return list.delete(index);
            }

            return list;
          }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [EMPLOYEE_ATTACHED_SERVICE_LIST.ERROR_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [EMPLOYEE_ATTACHED_SERVICE_LIST.LOADING_EMPLOYEE_ATTACHED_SERVICE_LIST]: (
    state: IEmployeeAttachedListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IEmployeeAttachedListState) => state,
};

export default function EmployeeAttachedList(
  state: any = initState,
  action: any,
): IEmployeeAttachedListState {
  const handler =
    handlers[action.type as EMPLOYEE_ATTACHED_SERVICE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
