import React from 'react';
import { MdOutlineEditOff } from 'react-icons/md';
import { HiOutlineLibrary } from 'react-icons/hi';
import { FaUserAlt, FaUsers } from 'react-icons/fa';
import { ClientModel, ClientOrgType } from '@structure';
import { ClientPersonalityType } from '@services/types';

export interface IClientIconProps {
  client: ClientModel | null;
}

export function ClientIcon({ client }: IClientIconProps): JSX.Element {
  if (client?.client_type === ClientPersonalityType.Group) {
    if (client?.client_strict_mode) {
      return <MdOutlineEditOff />;
    }

    return <FaUsers size={16} />;
  } else if (client?.client_org_type === ClientOrgType.PERSON) {
    return <FaUserAlt size={15} />;
  }

  return <HiOutlineLibrary size={18} />;
}
