import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import {
  EMPLOYEE_ROLE_INITIAL_DATA,
  ROLE_FORM_PARAM,
} from '@services/api/employee';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  EMPLOYEE_ROLE_ADMIN_FORM_PARAMS,
  EMPLOYEE_ROLE_CASHIER_FORM_PARAMS,
  EMPLOYEE_ROLE_MANAGER_FORM_PARAMS,
  EmployeeRole,
  EmployeeRoleAdminPermission,
  EmployeeRoleCashierPermission,
  EmployeeRoleFormDTO,
  EmployeeRoleManagerPermission,
  EmployeeRoleType,
} from '@structure';
import { SearchSelect } from '@components/lib/DataDisplay';
import { useDropdownAlert } from '@contex';
import { EmployeeRoleCashierField } from './EmployeeRoleCashierField';
import { EmployeeRoleAdminField } from './EmployeeRoleAdminField';
import { EmployeeRoleManagerField } from './EmployeeRoleManagerField';
import { produce } from 'immer';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { EmployeeRoleTag } from '../Show';

export interface IEmployeeRoleFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: EmployeeRoleFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  employee?: EmployeeRoleFormDTO;
  isEmployeeOwner: boolean;
}

const EmployeeRoleComponentStrategy = {
  [EmployeeRole.CASHIER]: EmployeeRoleCashierField,
  [EmployeeRole.Administrator]: EmployeeRoleAdminField,
  [EmployeeRole.Manager]: EmployeeRoleManagerField,
};

const EmployeeRoleDefaultDataStrategy = {
  [EmployeeRole.CASHIER]: EMPLOYEE_ROLE_CASHIER_FORM_PARAMS,
  [EmployeeRole.Administrator]: EMPLOYEE_ROLE_ADMIN_FORM_PARAMS,
  [EmployeeRole.Manager]: EMPLOYEE_ROLE_MANAGER_FORM_PARAMS,
};

const EmployeeRoleTitles = {
  [EmployeeRole.CASHIER]: 'Cashier settings',
  [EmployeeRole.Administrator]: 'Admin settings',
  [EmployeeRole.Manager]: 'Manager settings',
};

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 20px 20px 0 20px;

  width: 100%;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid #d9d9d9;
`;

export const Title = styled(StyledTitle)`
  position: absolute;
  top: -14px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: ${({ theme }) => theme.background.primary};

  font-size: 16px;
`;

const StyledEmployeeRoleTag = styled(EmployeeRoleTag)`
  margin-right: 3px;
`;

export default function EmployeeRoleForm({
  loading,
  editMode,
  employee = EMPLOYEE_ROLE_INITIAL_DATA,
  isEmployeeOwner,
  ...rest
}: IEmployeeRoleFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const [instance] = Form.useForm();

  const roles: EmployeeRoleType<
    | EmployeeRoleCashierPermission
    | EmployeeRoleAdminPermission
    | EmployeeRoleManagerPermission
  >[] = Form.useWatch('roles', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: employee?.uuid,
    }),
    [employee?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Employee'),
        `${t('An error occurred during edit employee roles')} : ${
          apiError?.message
        }`,
      );
    },
    [t, alert],
  );

  const handleChangeRole = useCallback(
    (roleList: EmployeeRole[]) => {
      const updatedRoles = produce<
        EmployeeRoleType<
          | EmployeeRoleCashierPermission
          | EmployeeRoleAdminPermission
          | EmployeeRoleManagerPermission
        >[]
      >(roles, (draft) => {
        if (roleList.length < draft.length) {
          return draft.filter(({ role }) => roleList.includes(role));
        }

        const draftRoleList = draft.map(({ role }) => role);
        const diff = roleList.filter((role) => !draftRoleList.includes(role));

        diff.forEach((role) => {
          draft.push(EmployeeRoleDefaultDataStrategy[role]);
        });
      });

      instance.setFieldValue('roles', updatedRoles);
    },
    [instance, roles],
  );

  return (
    <DefaultForm
      instance={instance}
      initialValues={{
        ...employee,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      submitButtonText={t('Set a roles')}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Roles')}
              name="role_list"
              tooltip={t('Roles')}
              rules={[
                {
                  required: true,
                  message: t('Roles must be specified.'),
                },
              ]}>
              <SearchSelect
                tagRender={(props: any) => <StyledEmployeeRoleTag {...props} />}
                itemDisabled={({ uuid }: any) =>
                  uuid === EmployeeRole.Administrator && isEmployeeOwner
                }
                isMultiple
                name="role_list"
                placeholder={t('Select a roles')}
                disable={loadingSubmit}
                data={ROLE_FORM_PARAM}
                onChange={handleChangeRole}
              />
            </Form.Item>
          </Col>

          <Form.List name="roles">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  const role = (roles || [])[index]?.role;

                  return role === EmployeeRole.CASHIER ? (
                    <StyledFieldsContainer key={key}>
                      <Title>{`${t(EmployeeRoleTitles[role])}:`}</Title>
                      <Col span={24}>
                        <Form.Item noStyle>
                          <Form.List name={[name, 'permissions']}>
                            {(subFields, subOpt) => {
                              const RoleField: any =
                                EmployeeRoleComponentStrategy[role];

                              return RoleField ? (
                                <RoleField
                                  {...subOpt}
                                  fields={subFields}
                                  loadingSubmit={loadingSubmit}
                                  role={(roles || [])[index]}
                                  fieldName={['roles', name, 'permissions']}
                                />
                              ) : null;
                            }}
                          </Form.List>
                        </Form.Item>
                      </Col>
                    </StyledFieldsContainer>
                  ) : null;
                })}
              </>
            )}
          </Form.List>
        </Row>
      )}
    </DefaultForm>
  );
}
