import React, { useEffect, useState, useRef } from 'react';
import { Spin } from 'antd';
import useCategoryList from '../../../hooks/useCategoryList';
import useCompanyScheduleList from '../../../hooks/useCompanyScheduleList';
import { useNotificationList } from '../../../hooks/useNotificationList';
import { useEmployeeList } from '../../../hooks/useEmployeeList';
import { useServiceList } from '../../../hooks/useServiceList';
import { useCompanyClientList } from '../../../hooks/useCompanyClientsList';
import useScheduleList from '../../../hooks/useScheduleList';
import {
  ILiteralObj,
  forEach,
  memoizeUnaryArity,
  isListToArray,
} from '@services/helpers';

export default DataLoader;

export enum DATA_LOADER {
  CURRENT_USER = 'currentUser',
  NOTIFICATION_LIST = 'notificationList',
  CATEGORY_LIST = 'categoryList',
  SCHEDULES_LIST = 'schedulesList',
  COMPANY_SCHEDULE_LIST = 'companyScheduleList',
  JOB = 'job',
  COMPANY_EMPLOYEES = 'companyEmployees',
  COMPANY_CLIENT = 'companyClients',
  COMPANY_SERVICES = 'companyServices',
  SERVICE_GROUP_SESSIONS = 'serviceGroupSessions',
}

const hooks: ILiteralObj = {};

// hooks[DATA_LOADER.CURRENT_USER] = useProfile;
hooks[DATA_LOADER.NOTIFICATION_LIST] = useNotificationList;
hooks[DATA_LOADER.CATEGORY_LIST] = useCategoryList;
hooks[DATA_LOADER.COMPANY_SCHEDULE_LIST] = useCompanyScheduleList;
hooks[DATA_LOADER.COMPANY_EMPLOYEES] = useEmployeeList;
hooks[DATA_LOADER.COMPANY_CLIENT] = useCompanyClientList;
hooks[DATA_LOADER.COMPANY_SERVICES] = useServiceList;
hooks[DATA_LOADER.SERVICE_GROUP_SESSIONS] = () => {};
hooks[DATA_LOADER.SCHEDULES_LIST] = useScheduleList;

export interface IDataLoaderProps<T> {
  data: any;
  invisible?: any;
  children: React.ReactElement;
}

export interface State {
  name: string;
  result: ILiteralObj;
}

export interface DataItem<T> extends State {
  hook: any;
  hookArgs: T;
}

const buildItems = <T extends ILiteralObj>(
  state: any,
  name: string,
  args: T,
  hooksState: any = hooks,
): DataItem<T> => ({
  ...state,
  hook: hooksState[name],
  hookArgs: args[name] === true ? [] : [args[name]],
});

const handlers: any = {
  DEFAULT: <T extends ILiteralObj>(state: any, name: string, args: T) =>
    buildItems(state, name, args),
};

/**
 * A DataLoader to load data to Show components
 * @param propsHolder
 * @param children
 * @param rest
 * @returns {*}
 * @constructor
 */
function DataLoader<T>({
  data = {},
  invisible,
  children,
  ...rest
}: IDataLoaderProps<T>) {
  const [loading, setLoading] = useState(true);
  const dataItems = useRef<any>([]);
  const cProps = useRef<any>({});

  // debugger
  if (dataItems.current.length === 0) {
    forEach((k: any) => {
      if (Object.values(DATA_LOADER).includes(k as any)) {
        const state: State = { name: k, result: {} };

        const handler = handlers[k] || handlers.DEFAULT;

        dataItems.current = [
          ...isListToArray(dataItems.current),
          handler(state, k, data),
        ];
      }
    }, Object.keys(data));
  }

  // hooks array

  dataItems.current = dataItems.current.map((item: any, i: number) => ({
    ...item,
    result: item.hook
      ? item.hook.apply(
          null,
          dataItems.current[i].hookArgs, // [item.name]
        )
      : null,
  }));

  useEffect(() => {
    dataItems.current.forEach(({ name, result }: any) => {
      // debugger
      if (!cProps.current[`${name}Refresh`]) {
        cProps.current[`${name}Refresh`] =
          memoizeUnaryArity(result?.refresh) || null;
      }

      switch (name) {
        case DATA_LOADER.NOTIFICATION_LIST:
          cProps.current[name] = result.list ? [...result.list] : result.list;
          cProps.current[`${name}TotalUnread`] = result.totalUnread;
          break;
        case DATA_LOADER.CATEGORY_LIST:
          cProps.current[name] = result.list ? [...result.list] : result.list;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        case DATA_LOADER.SCHEDULES_LIST:
          cProps.current[name] = result?.list ? [...result.list] : result?.list;
          break;
        case DATA_LOADER.COMPANY_SCHEDULE_LIST:
          cProps.current[name] = result?.list
            ? [...result?.list]
            : result?.list;
          break;
        case DATA_LOADER.JOB:
          cProps.current[name] = result.record;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        case DATA_LOADER.CURRENT_USER:
          cProps.current[name] = result.currentUser;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        case DATA_LOADER.COMPANY_EMPLOYEES:
          cProps.current[name] = result.list
            ? [...isListToArray(result.list)]
            : result.list;
          break;
        case DATA_LOADER.COMPANY_SERVICES:
          cProps.current[name] = result.list ? [...result.list] : result.list;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        case DATA_LOADER.SERVICE_GROUP_SESSIONS:
          cProps.current[name] = result.list ? [...result.list] : result.list;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        case DATA_LOADER.COMPANY_CLIENT:
          cProps.current[name] = result.list ? [...result.list] : result.list;
          cProps.current[`${name}Loading`] = result.loading;
          break;
        default:
          break;
      }
    });

    const itemsLoading = dataItems.current.filter(
      ({ result }: any) => result?.loading,
    );

    if (itemsLoading.length === 0) {
      cProps.current.loading = false;
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItems.current]);

  return (
    <div className="DataLoader">
      <Spin spinning={!invisible && loading}>
        <div className="DataLoader_content">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, cProps.current),
          )}
        </div>
      </Spin>
    </div>
  );
}
