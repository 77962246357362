import React, {
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';
import { Col, Form, Row, Empty } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { List } from 'immutable';
import {
  isFunction,
  getPercentage,
  compose,
  sub,
  ServiceGroupSession,
  isThereContent,
  greaterThan,
  len,
  neq,
  eq,
  head,
  isEqualByUuid,
  isListToArray,
} from '@services/helpers';
import { SearchSelect } from '@components/lib/DataDisplay';
import { useDefaultForm } from '@contex';
import { useClientServiceTransitionHelper } from '@hooks';
import { isPeriodEqMinOrHour } from '@services/api/services';
import ClientPriceForm from './ClientPriceForm';
import ClientServiceTransitionForm from './ClientServiceTransitionForm';
import { PERSONALITY_TYPE, TIME_LIST_SERVICES } from '@services/types';
import { ServiceModel } from '@structure';

export default withTranslation()(ClientServiceForm);

export interface OnServicesChange {
  isServiceGroup: boolean;
  serviceUuid: string;
  isShowSubscription: boolean;
}

export interface IClientServiceFormProps<T> extends WithTranslation {
  services: List<ServiceModel> | null;
  groupSessions: ServiceGroupSession[];
  onChangeService?: (value: OnServicesChange) => Promise<void | number>;
  isDisabledAllFields?: boolean;
  showTransition?: boolean;
  validateServicesField?: boolean;
}

const SearchSelectStyled = styled(SearchSelect)`
  & .search-select__option--title {
    color: ${({ theme }) => theme.colors.black};
  }
`;

function ClientServiceForm<T>({
  t,
  services,
  groupSessions,
  onChangeService,
  isDisabledAllFields = false,
  showTransition = false,
  validateServicesField = false,
}: IClientServiceFormProps<T>): JSX.Element {
  const {
    valid,
    loadingSubmit,
    getFieldValue,
    handlerUpdateFormState,
    formData,
  } = useDefaultForm();

  const [isEmptyGroupSessions, setIsEmptyGroupSessions] = useState(true);
  const isCheckService = useRef<boolean>(false);

  const setFieldTotal = useCallback(
    (cost: number): void => {
      const discount = getFieldValue('payment_discount');

      const total =
        discount && !Number.isNaN(Number(discount))
          ? compose(sub(cost), getPercentage(cost))(Number(discount))
          : cost;

      handlerUpdateFormState({ total });
    },
    [getFieldValue, handlerUpdateFormState],
  );

  const getTransitionOfServices = useClientServiceTransitionHelper({
    isStartTransition: validateServicesField,
    handleUpdateTotalField: setFieldTotal,
  });

  const setFieldCost = useCallback(
    (cost: number): void => {
      handlerUpdateFormState({ cost });

      setFieldTotal(cost);
    },
    [handlerUpdateFormState, setFieldTotal],
  );

  const servicePersonalityGroup = useCallback(
    async (service: ServiceModel): Promise<void> => {
      if (isFunction(onChangeService)) {
        await onChangeService({
          isServiceGroup: true,
          serviceUuid: service?.uuid,
          isShowSubscription: !isPeriodEqMinOrHour(
            service?.period as TIME_LIST_SERVICES,
          ),
        });
      }

      if (!isCheckService.current) {
        const isGroupSessionField = !!getFieldValue('group_session_uuid');

        handlerUpdateFormState({ isShowPriceFields: isGroupSessionField });
      } else {
        handlerUpdateFormState({ group_session_uuid: '' });
        handlerUpdateFormState({ isShowPriceFields: false });
      }
      isCheckService.current = true;
    },
    [getFieldValue, handlerUpdateFormState, onChangeService],
  );

  const servicePersonalityIndividual = useCallback(
    async (service: ServiceModel): Promise<void> => {
      if (isFunction(onChangeService)) {
        await onChangeService({
          isServiceGroup: false,
          serviceUuid: service?.uuid,
          isShowSubscription: !isPeriodEqMinOrHour(service?.period),
        });
      }

      await getTransitionOfServices();

      handlerUpdateFormState({ group_session_uuid: '' });
      handlerUpdateFormState({ isShowPriceFields: true });
      isCheckService.current = true;
    },
    [getTransitionOfServices, handlerUpdateFormState, onChangeService],
  );

  const conditionalsPersonalityType = {
    [PERSONALITY_TYPE.GROUP]: servicePersonalityGroup,
    [PERSONALITY_TYPE.INDIVIDUAL]: servicePersonalityIndividual,
    DEFAULT: () => {},
  };

  const handleChangeServices = useCallback(
    async (service_uuid: any): Promise<void> => {
      if (List.isList(services)) {
        const service = services.find(isEqualByUuid(service_uuid));

        handlerUpdateFormState({ service_uuid });
        handlerUpdateFormState({ serviceTitle: service?.title });
        setFieldCost(Number(service?.price));

        // @ts-ignore
        conditionalsPersonalityType[service?.personality || 'DEFAULT'](service);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [services, handlerUpdateFormState, setFieldCost],
  );

  const handleChangeGroupSessions = useCallback(
    async (group_session_uuid: any): Promise<void> => {
      const serviceGroupSessions = groupSessions.find(
        isEqualByUuid(group_session_uuid),
      );

      await getTransitionOfServices();

      handlerUpdateFormState({ group_session_uuid });
      handlerUpdateFormState({
        serviceTitle: `${formData?.serviceTitle}/${serviceGroupSessions?.title}`,
      });

      handlerUpdateFormState({ isShowPriceFields: true });
      setFieldCost(Number(serviceGroupSessions?.price));
    },

    [
      formData?.serviceTitle,
      groupSessions,
      handlerUpdateFormState,
      setFieldCost,
      getTransitionOfServices,
    ],
  );

  const handleChangeDiscount = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const cost = getFieldValue('cost');

      handlerUpdateFormState({ payment_discount: value });

      setFieldTotal(Number(cost));
    },
    [getFieldValue, handlerUpdateFormState, setFieldTotal],
  );

  useEffect(() => {
    (async () => {
      if (
        validateServicesField &&
        greaterThan(services?.size, 0) &&
        !isCheckService.current
      ) {
        const service_uuid = getFieldValue('service_uuid');

        if (service_uuid) {
          await handleChangeServices(service_uuid);
        }
      }
    })();
  }, [getFieldValue, validateServicesField, handleChangeServices, services]);

  useEffect(() => {
    if (neq(groupSessions, null)) {
      if (isThereContent(groupSessions)) {
        setIsEmptyGroupSessions(true);
      } else {
        setIsEmptyGroupSessions(false);
      }
    }
  }, [groupSessions]);

  useEffect(() => {
    if (Array.isArray(groupSessions) && eq(len(groupSessions), 1)) {
      const { uuid } = head(groupSessions);

      setTimeout(async () => {
        await handleChangeGroupSessions(uuid);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupSessions]);

  const displayEmptyGroupSessionsContent = isEmptyGroupSessions ? null : (
    <Empty
      description={t(
        'There are no group services for the selected service. Please choose another service',
      )}
    />
  );

  return (
    <>
      {valid ? (
        <Row gutter={20} justify="end">
          <Col span={24}>
            <Form.Item
              label={t('Services')}
              name="service_uuid"
              rules={[
                {
                  required: true,
                  message: t('Services must be specified'),
                },
              ]}
              tooltip={{
                title: t('Company services'),
              }}>
              <SearchSelectStyled
                name="service_uuid"
                disable={isDisabledAllFields}
                placeholder={t('Search a services')}
                data={isListToArray(services as any)}
                onChange={handleChangeServices}
                getOptionValueSecond="price"
              />
            </Form.Item>
          </Col>
          {groupSessions !== null ? (
            <Col span={24}>
              <Form.Item
                label={t('Services group')}
                name="group_session_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Group service sessions must be specified'),
                  },
                ]}
                tooltip={{
                  title: t('Company group service sessions'),
                }}>
                <SearchSelectStyled
                  name="group_session_uuid"
                  disable={isDisabledAllFields}
                  placeholder={t('Search group sessions')}
                  data={groupSessions}
                  getOptionValueTitle={['group_session', 'title']}
                  onChange={handleChangeGroupSessions}
                  notFoundContent={displayEmptyGroupSessionsContent}
                  getOptionValueSecond="price"
                />
              </Form.Item>
            </Col>
          ) : null}

          {formData?.isShowPriceFields ? (
            <>
              {showTransition && <ClientServiceTransitionForm />}
              <ClientPriceForm
                loading={loadingSubmit || isDisabledAllFields}
                handleChangeDiscount={handleChangeDiscount}
              />
            </>
          ) : null}
        </Row>
      ) : null}
    </>
  );
}
