import { IoMdCash } from 'react-icons/io';
import { BsThreeDots } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineDelete } from 'react-icons/ai';
import { HiOutlineReceiptTax } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { DepartmentTitleView } from '../../Departments';
import styled, { useTheme, css } from 'styled-components';
import React, { Suspense, useCallback, useState } from 'react';
import { useModal } from '@components/lib/libV2/hooks';
import { correctPrice } from '@services/helpers';
import { Button, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';
import { PaymentScheduleOperationType } from '@services/api/orders';
import { convertedCashBoxMethodToPayment } from '@services/api/companyPayments';

import {
  CashBoxModel,
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
} from '@structure';

import {
  IUseStateCashBoxListReturnType,
  IUseStatePaymentListReturnType,
  PaymentProps,
  useStoredCompanies,
  useStoredProfile,
} from '@hooks';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import './CashBoxListItem.less';

const PaymentSideWindowLazy = React.lazy(
  () => import('../Show/PaymentSideWindow'),
);

export interface ICashBoxListItemProps
  extends Pick<
      IUseStateCashBoxListReturnType,
      'handleDeleteCashBoxes' | 'handleUpdateDefaultCashBox'
    >,
    Pick<IUseStatePaymentListReturnType, 'handleCreatePayment'> {
  item: CashBoxModel;
  onInitUpdateCashBox: (item: CashBoxModel) => void;
  onInitAdjustBalancesCashBox: (item: CashBoxModel) => void;
  onInitMovingCashBox: (item: CashBoxModel) => void;
  paymentsAllOperations?: boolean;
}

const StyledContainer = styled.div<{ $default?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 10;
  gap: 5px;

  min-height: 85px;
  padding: 10px 5px;

  ${({ $default }) =>
    $default &&
    css`
      font-weight: 600;
    `}

  max-width: 80%;
  height: 100%;
`;

const StyledWrapper = styled.div<{ $isDefault?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  width: 100%;

  border-bottom-color: ${({ theme }) => theme.colors.lightDisable};
  border-bottom-width: 1px;
  border-bottom-style: solid;

  &:last-child {
    border: none;
  }

  ${({ $isDefault }) =>
    !$isDefault &&
    css`
      min-height: 75px;

      &:hover {
        background-color: rgba(64, 64, 65, 0.1);
        cursor: pointer;
      }
    `}
  ${({ $isDefault }) =>
    $isDefault &&
    css`
      background-color: rgba(64, 64, 65, 0.1);
    `}
`;

const StyledDropdown = styled(Dropdown)<{ loading: boolean }>`
  display: flex;
  flex: 1;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ loading }) =>
    loading &&
    css`
      display: block;
    `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

const Description = styled(StyledDescription)`
  max-width: 100%;
  text-overflow: unset;
  white-space: unset;
  text-align: left;
`;

export function CashBoxListItem({
  item,
  onInitUpdateCashBox,
  handleDeleteCashBoxes,
  onInitAdjustBalancesCashBox,
  onInitMovingCashBox,
  handleUpdateDefaultCashBox,
  paymentsAllOperations,
  handleCreatePayment,
}: ICashBoxListItemProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { defaultCompany, defaultCompanyUuid } = useStoredCompanies();
  const { profile } = useStoredProfile();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<PaymentScheduleOperationType>(
    PaymentScheduleOperationType.In,
  );

  const payment: (
    props?: PaymentProps,
  ) => PaymentFormDTO | PaymentScheduleFormDTO = (
    { payment_type }: PaymentProps = {} as PaymentProps,
  ) =>
    ({
      ...PaymentMapper.toPaymentFormDTO(
        {
          payment_type,
          company_cashbox: item,
          payment_method: convertedCashBoxMethodToPayment[
            item?.box_type
          ] as any,
          cashier: profile?.defaultEmployeeModel,
        } as PaymentModel,
        {
          editMode: true,
          disabledCashBox: true,
        },
      ),
    } as PaymentFormDTO);

  const {
    handleCancel: paymentHandleCancel,
    handleOnInit: paymentHandleOnInit,
    handleSuccess: paymentHandleSuccess,
    visible: paymentVisible,
  } = useModal({
    onSuccess: handleCreatePayment,
  });

  const handlePrepayment = useCallback(() => {
    setType(PaymentScheduleOperationType.In);
    paymentHandleOnInit();
  }, [paymentHandleOnInit]);

  const handleRefunds = useCallback(() => {
    setType(PaymentScheduleOperationType.Out);
    paymentHandleOnInit();
  }, [paymentHandleOnInit]);

  const handleDeleteCashBox = useCallback(async () => {
    try {
      setLoading(true);
      await handleDeleteCashBoxes([item?.uuid]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [handleDeleteCashBoxes, item?.uuid]);

  const items: MenuProps['items'] = [
    {
      key: 'Edit',
      label: <StyledTitle>{t('Edit')}</StyledTitle>,

      icon: <MdModeEditOutline size={18} />,
      onClick: () => onInitUpdateCashBox(item),
    },

    {
      key: 'Adjust balances',
      label: <StyledTitle>{t('Adjust balances')}</StyledTitle>,
      icon: <IoMdCash size={18} />,
      onClick: () => onInitAdjustBalancesCashBox(item),
    },
    {
      key: 'Receipts',
      label: <StyledTitle>{t('Receipts')}</StyledTitle>,
      icon: <ArrowDownOutlined />,
      onClick: handlePrepayment,
    },
    {
      key: 'Expenses',
      label: <StyledTitle>{t('Expenses')}</StyledTitle>,
      icon: <ArrowUpOutlined />,
      onClick: handleRefunds,
    },
    {
      key: 'Moving',
      label: <StyledTitle>{t('Moving')}</StyledTitle>,
      icon: <RiExchangeDollarLine size={18} />,
      onClick: () => onInitMovingCashBox(item),
    },
    {
      key: 'Delete',
      label: (
        <StyledTitle $color={theme.colors.error}>{t('Delete')}</StyledTitle>
      ),
      icon: loading ? (
        <LoadingOutlined color={theme.colors.primary} />
      ) : (
        <AiOutlineDelete size={18} color={theme.colors.error} />
      ),
      onClick: handleDeleteCashBox,
    },
  ];
  const isDefault = item?.box_is_default_for_company && !paymentsAllOperations;

  return (
    <>
      <StyledWrapper $isDefault={isDefault}>
        <StyledContainer
          $default={isDefault}
          onClick={
            isDefault ? undefined : () => handleUpdateDefaultCashBox(item)
          }>
          <Description
            fontSize={isDefault ? 20 : 15}
            $color={theme.colors.black}>
            {isDefault ? (
              <FaCheckCircle color={theme.colors.primary} size={12} />
            ) : null}
            {item?.box_title!}
          </Description>
          {item?.box_fiscal_cashbox_id ? (
            <Description $color={theme.colors.primary}>
              <HiOutlineReceiptTax color={theme.colors.primary} size={12} />
              {t('The fiscal cashbox')} / {item?.box_fiscal_cashbox_id}
            </Description>
          ) : null}
          {item?.locationModel?.uuid ? (
            <DepartmentTitleView department={item?.locationModel} />
          ) : null}
          <Description
            $color={
              Number(item?.box_balance) >= 0
                ? theme.colors.success
                : theme.colors.error
            }
            fontSize={isDefault ? 20 : 15}>{`${
            defaultCompany?.currency_symbol
          }${correctPrice(item?.box_balance)}`}</Description>
        </StyledContainer>
        <StyledDropdown
          loading={loading}
          trigger={['click']}
          menu={{ items }}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            <StyledButton type="text">
              <BsThreeDots color={theme.colors.primary} size={16} />
            </StyledButton>
          )}
        </StyledDropdown>
      </StyledWrapper>
      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentSideWindowLazy
          payment={
            payment({
              payment_type: type as any,
            }) as PaymentFormDTO
          }
          companyUuid={defaultCompanyUuid}
          loading={loading as boolean}
          onSuccess={paymentHandleSuccess}
          onCancel={paymentHandleCancel}
          visible={paymentVisible}
        />
      </Suspense>
    </>
  );
}
