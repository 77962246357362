import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import { ICategoryListDTO, CategoryMapper, CategoryModel } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getCategoryList } from '@services/api/category';

export interface IUseCategoryListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUseCategoryListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<CategoryModel> | null;
  refresh: (
    value: Partial<IUseCategoryListProps>,
  ) => Promise<List<CategoryModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords?: string;
}

export default function useCategoryList(
  {
    loadOnInit = true,
    limit = 10,
    offset = 0,
  }: IUseCategoryListProps = {} as IUseCategoryListProps,
): IUseCategoryListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
  } = useStateReducer<Omit<IUseCategoryListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords: '',
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUseCategoryListProps> = {}): Promise<List<CategoryModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const { categories, total } =
          await cancellablePromise<ICategoryListDTO>(
            getCategoryList({ limit, offset, keywords }),
          );

        const categoryListModel = CategoryMapper.toCategoryListModel(
          categories,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: categoryListModel?.categories,
            total: categoryListModel?.total,
            loading: false,
          });

          return categoryListModel?.categories;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listLimit,
      listOffset,
      listKeywords,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
  };
}
