import React, { JSX, RefObject, SetStateAction, useMemo, useRef } from 'react';
import { Empty, Form, Select } from 'antd';
import { LoadingOutlined, ClearOutlined } from '@ant-design/icons';
import {
  PriceMarginModel,
  ProductModel,
  PriceTagModel,
  ProductStatus,
} from '@structure';
import styled, { useTheme, css } from 'styled-components';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import {
  IUseStatePriceTagListReturnType,
  IUseStateProductListReturnType,
  UseStateProductListReturnType,
} from '@hooks';
import { debounce } from '@services/helpers';
import { ProductTableView } from '../../../Stores';
import { StyledDescription } from '@components/lib/Styled';
import { AiFillFilter } from 'react-icons/ai';
import { Button, LoadingMore } from '@components/lib/DataDisplay';
import { SearchProductStatus, SearchProductStatusType } from '@services/types';
import { ENTER_KEYS } from '@services/const';
import { v4 as uuidv4 } from 'uuid';

import '../../../Orders/List/OrderList.less';
import '../../../Stores/Forms/StoreProductListField.less';
import '../../Pages/ProductList.less';

export interface IPriceTagSelectProductListFieldProps
  extends Pick<IUseStateProductListReturnType, 'handleSearchProducts'>,
    Pick<IUseStatePriceTagListReturnType, 'handleClearPriceTagList'>,
    Pick<
      UseStateProductListReturnType,
      'isLoadingMore' | 'handleLoadingMoreProductList'
    > {
  loading: boolean;
  withoutStoreUuid: boolean;
  disabled: boolean;

  selectedProductItem: PriceTagModel | null;
  selectedProductIndex: number;
  onProductSelect: (product: any) => void;
  setStoreProductList: React.Dispatch<SetStateAction<any[]>>;

  storeProductList: ProductModel[];
  productListLoading: boolean;
  productListLoadingMore: boolean;
  productListKeywords: string;

  defaultPriceMargin: PriceMarginModel | null;
  getProductPriceMargin: (
    product: ProductModel,
    priceMargin: PriceMarginModel | null,
  ) => string;

  selectRef: RefObject<any>;
  toggleFilters: () => void;
  visibleFilters: boolean;

  setSelectedProductIndex: React.Dispatch<SetStateAction<number>>;

  handleChangeInputStatus: (
    status: SearchProductStatus,
    isEnterPress?: boolean,
  ) => void;
  inputStatusRef: RefObject<SearchProductStatusType>;
  selectedProductUuid: string;
}

const StyledTable = styled(Table)`
  margin: 0;
` as any;

const StyledProductListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  flex: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

const Description = styled(StyledDescription)<{ $visible: boolean }>`
  opacity: 0;
  transition: all 0.5s;

  ${({ $visible }) =>
    !$visible &&
    css`
      opacity: 1;
      transition: all 0.5s;
    `}
`;

const StyledDraftButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ disabled }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${({ theme }) => theme.colors.primary};
        opacity: 0.7;
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-radius: 7px;

      & * {
        color: ${theme.colors.white};
      }
    `}
`;

const StyledSelect = styled(Select)`
  & .ant-select-selection-placeholder {
    white-space: unset !important;
  }
`;

export function PriceTagSelectProductListField({
  loading,
  withoutStoreUuid,
  disabled,
  toggleFilters,

  selectedProductItem,
  selectedProductIndex,
  onProductSelect,

  storeProductList,
  productListLoading,
  productListLoadingMore,
  handleSearchProducts,
  productListKeywords,
  setStoreProductList,

  defaultPriceMargin,
  getProductPriceMargin,
  selectRef,
  visibleFilters,
  handleClearPriceTagList,
  setSelectedProductIndex,

  handleChangeInputStatus,
  inputStatusRef,
  selectedProductUuid: currentSelectedProductUuid,

  isLoadingMore,
  handleLoadingMoreProductList,
}: IPriceTagSelectProductListFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { loadingSubmit, formData, handlerUpdateFormState } = useDefaultForm();
  const theme: any = useTheme();

  const tableRef = useRef<HTMLTableElement>(null);

  const selectedProductUuid = (formData?.items || [])?.map(
    ({ product }: PriceTagModel) => product?.uuid,
  );

  const columns = useMemo(
    () => [
      {
        title: t('name-s'),
        key: 'name',
        className: 'order-list-td posting-list-td--indent',
        render: (product: ProductModel) => (
          <ProductTableView
            withIndent
            product={product}
            price={getProductPriceMargin(product, defaultPriceMargin)}
            selectedProductUuid={selectedProductUuid}
            loadingProductUuid={currentSelectedProductUuid}
          />
        ),
      },
    ],
    [
      defaultPriceMargin,
      getProductPriceMargin,
      currentSelectedProductUuid,
      selectedProductUuid,
      t,
    ],
  );

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const productList: any = await handleSearchProducts(value);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);

        if (
          productList &&
          productList?.products?.size === 1 &&
          inputStatusRef.current?.isEnterPress
        ) {
          const input = document.body.querySelector('#doc');

          const clickEvent = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'Enter',
          });

          if (input instanceof HTMLElement) {
            setTimeout(() => {
              input.dispatchEvent(clickEvent);
            }, 250);
          }
        }

        handleChangeInputStatus(SearchProductStatus.PRESS_ENTER, false);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);
      }, 1000),
    [handleChangeInputStatus, handleSearchProducts, inputStatusRef],
  );

  return (
    <StyledContainer>
      {disabled ? (
        <StyledDescription
          $color={theme.colors.error}
          style={{ whiteSpace: 'unset' }}>
          {t('To search for goods, you need to select a warehouse')}
        </StyledDescription>
      ) : null}
      <StyledProductListContainer ref={tableRef}>
        <StyledFormItem
          name="doc"
          extra={
            <Description
              style={{ whiteSpace: 'unset' }}
              $visible={visibleFilters}>
              {`${
                formData?.doc_store_name
                  ? `${t('Store')}: "${formData?.doc_store_name}"; `
                  : ''
              }${
                defaultPriceMargin
                  ? `${t('Price margin')}: "${defaultPriceMargin?.price_name}"`
                  : ''
              }`}
            </Description>
          }>
          <StyledSelect
            autoClearSearchValue={false}
            mode="multiple"
            popupClassName="store-sell-product-select-popup posting-select-popup"
            className="store-sell-product-select"
            ref={selectRef as any}
            loading={productListLoadingMore}
            disabled={loadingSubmit || disabled}
            onKeyDown={(e) => {
              if (
                inputStatusRef.current?.status !==
                  SearchProductStatus.END_REQUEST &&
                ENTER_KEYS.includes(e.key)
              ) {
                handleChangeInputStatus(SearchProductStatus.PRESS_ENTER, true);
              }
            }}
            onBlur={async () => {
              setStoreProductList([]);
              setSelectedProductIndex(0);

              await debounceHandleSearchProducts({
                keywords: '',
                ...(withoutStoreUuid
                  ? []
                  : { store_uuid: formData?.doc_store_uuid }),
              });
            }}
            showSearch
            onSearch={async (keywords: string) => {
              handleChangeInputStatus(SearchProductStatus.START_INPUT);

              if (keywords?.length < 3) {
                setStoreProductList([]);
              }

              await debounceHandleSearchProducts({
                keywords,
                ...(withoutStoreUuid
                  ? []
                  : { store_uuid: formData?.doc_store_uuid }),
              });
            }}
            placeholder={t('Enter product details to search for it')}
            dropdownRender={() => (
              <div
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {productListLoadingMore ? (
                  <Empty>
                    <LoadingOutlined />
                  </Empty>
                ) : productListLoading ||
                  !productListKeywords ||
                  productListKeywords?.length < 3 ? (
                  <Empty
                    description={t(
                      'To select a product, enter the search data, at least 3 characters',
                    )}
                  />
                ) : productListKeywords && !storeProductList?.length ? (
                  <Empty
                    description={t(
                      'It seems that more than one product was not found for your request',
                    )}
                  />
                ) : (
                  <>
                    <StyledTable
                      className="posting-list-form"
                      pagination={false}
                      rowKey={() => uuidv4()}
                      pageSize={Infinity}
                      total={storeProductList?.length}
                      dataSource={storeProductList}
                      columns={columns}
                      loading={loading}
                      onRow={(record: ProductModel, rowIndex: number) => ({
                        onClick: () => {
                          if (!currentSelectedProductUuid) {
                            onProductSelect(record);
                          }
                        },
                        className: `posting-list-td posting-list-td-${rowIndex} ${
                          record?.uuid === selectedProductItem?.product?.uuid &&
                          selectedProductIndex === rowIndex
                            ? 'posting-list-td--selected'
                            : ''
                        } ${
                          currentSelectedProductUuid
                            ? currentSelectedProductUuid === record?.uuid
                              ? 'posting-list-td--loading--current'
                              : 'posting-list-td--loading'
                            : ''
                        } ${
                          record?.product_status !== ProductStatus.ENABLE
                            ? 'product-list--disabled'
                            : ''
                        }`,
                      })}
                    />
                    <LoadingMore
                      loading={isLoadingMore}
                      observerCallback={handleLoadingMoreProductList}
                    />
                  </>
                )}
              </div>
            )}
          />
        </StyledFormItem>
        <StyledButton type="text" onClick={toggleFilters}>
          <AiFillFilter size={20} color={theme.colors.primary} />
        </StyledButton>
        {formData?.items?.length ? (
          <StyledDraftButton
            disabled={loadingSubmit}
            type="primary"
            onClick={async () => {
              handlerUpdateFormState({ loadingSubmit: true });
              await handleClearPriceTagList();
              handlerUpdateFormState({
                items: [],
                loadingSubmit: false,
                total: 0,
              });
            }}
            icon={loadingSubmit ? <LoadingOutlined /> : <ClearOutlined />}>
            {t('Clear')}
          </StyledDraftButton>
        ) : null}
      </StyledProductListContainer>
    </StyledContainer>
  );
}
