import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { useDropdownAlert } from '@contex';
import { useStateEmployeeList, useStoredCompanies } from '@hooks';
import { ClientAbonementScheduleFields } from './ClientAbonementScheduleFields';
import { split } from '@services/helpers';
import { ClientAbonementScheduleFormDTO } from '@structure';

export interface IClientAbonementScheduleFormProps
  extends Omit<
    IDefaultFormProps<
      ClientAbonementScheduleFormDTO,
      ClientAbonementScheduleFormDTO
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  schedule?: ClientAbonementScheduleFormDTO;
}

export function ClientAbonementScheduleForm({
  loading,
  editMode,
  schedule,
  onCancel,
  ...rest
}: IClientAbonementScheduleFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: schedule?.uuid,
    }),
    [schedule?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const weekdays = useMemo(
    () => ({
      weekdays: split(',', schedule?.schedule || ''),
    }),
    [schedule?.schedule],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client abonement schedule'),
        `${
          editMode
            ? t('An error occurred during edit client abonement schedule')
            : t('An error occurred during create client abonement schedule')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...schedule,
        ...weekdays,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <ClientAbonementScheduleFields
          employees={employees}
          employeesLoading={employeesLoading}
          handleSearchEmployees={handleSearchEmployees}
          minDate={schedule?.schedule_start_date || ''}
        />
      )}
    </DefaultForm>
  );
}
