import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Form } from 'antd';
import styled, { useTheme } from 'styled-components';
import {
  SERVICE_PARAMETERS_INITIAL_DATA,
  REWARD_TYPE_LIST,
} from '@services/api/services';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  IUseStateEmployeeReturnType,
  useCompanyOrderBonusFieldParams,
  useStoredCompanies,
} from '@hooks';
import {
  CompanyOrderBonusDTO,
  CompanyOrderBonusGroupType,
  RewardType,
  ServiceModel,
  ServiceParametersFormDTO,
} from '@structure';
import { useDropdownAlert } from '@contex';
import { SearchSelect } from '@components/lib/DataDisplay';
import { StyledDescription } from '@components/lib/Styled';
import { TIME_LIST_SERVICES } from '@services/types';
import { correctPrice } from '@services/helpers';
import { Table } from '@components/lib/libV2/DataDisplay';

import './ServiceParametersForm.less';

export interface IServiceParametersFormProps
  extends Omit<
      IDefaultFormProps<ServiceParametersFormDTO, ServiceParametersFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    >,
    Pick<IUseStateEmployeeReturnType, 'employeeServiceBonuses'> {
  loading?: boolean;
  parameters?: ServiceParametersFormDTO;
  servicePeriod: TIME_LIST_SERVICES;
  service: ServiceModel | null;
}

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

export function ServiceParametersForm({
  loading,
  parameters = SERVICE_PARAMETERS_INITIAL_DATA,
  servicePeriod,
  service,
  employeeServiceBonuses,
  ...rest
}: IServiceParametersFormProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompany, settings } = useStoredCompanies();
  const theme: any = useTheme();

  const [instance] = Form.useForm();

  const [defaultCompanyOrderBonuses, setDefaultCompanyOrderBonuses] = useState<
    CompanyOrderBonusDTO[]
  >([]);

  const [rewardType, setRewardType] = useState<RewardType>(
    parameters?.reward_type,
  );

  const { columns, companyOrderBonuses, components, rowSelection } =
    useCompanyOrderBonusFieldParams({
      companyOrderBonuses: defaultCompanyOrderBonuses,
      selectedCompanyOrderBonuses: parameters?.bonuses,
      form: instance,
      type: 'bonuses',
    });

  const additionalValues = useMemo(
    () => ({
      uuid: parameters?.uuid,
    }),
    [parameters?.uuid],
  );

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Service'),
        `${t('An error occurred during edit employee service')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  useEffect(() => {
    if (settings?.settings?.company_order_bonuses) {
      const companyServiceBonuses = (
        settings?.settings?.company_order_bonuses || []
      )?.filter(({ groups }) =>
        groups?.includes(CompanyOrderBonusGroupType.Service),
      );

      const mergedWithEmployeeBonuses = (companyServiceBonuses || []).map(
        (value) => {
          const employeeBonus = employeeServiceBonuses?.find(
            ({ id }) => id === value?.id,
          );

          if (employeeBonus) {
            return employeeBonus;
          }

          return value;
        },
      );

      setDefaultCompanyOrderBonuses(mergedWithEmployeeBonuses);
    }
  }, [employeeServiceBonuses, settings?.settings?.company_order_bonuses]);

  return (
    <DefaultForm<ServiceParametersFormDTO, ServiceParametersFormDTO>
      instance={instance}
      initialValues={parameters}
      additionalValuesRequest={additionalValues}
      notifyError={notifyError}
      showNotify={false}
      {...rest}>
      {({ loadingSubmit, setFieldsValue }) => (
        <>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Price')}
                name="price"
                rules={[
                  {
                    required: true,
                    message: t('Price must be specified.'),
                  },
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message: t('Price must be a number.'),
                  },
                ]}
                extra={
                  <StyledDescription style={{ whiteSpace: 'unset' }}>
                    {`${t('The cost of the service at the price')}: ${
                      defaultCompany?.currency_symbol
                    } ${correctPrice(service?.price || 0)}`}
                  </StyledDescription>
                }>
                <Input
                  autoComplete="off"
                  addonBefore={defaultCompany?.currency_symbol}
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                tooltip={t('Time spent')}
                label={t('Time spent')}
                name="duration"
                rules={[
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message: t('Time spent must be a number.'),
                  },
                ]}>
                <Input
                  addonAfter={t(servicePeriod)}
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                tooltip={t('Reward grid')}
                label={t('Reward grid')}
                name="bonuses">
                <Table<CompanyOrderBonusDTO>
                  components={components}
                  rowClassName={() => 'editable-row parameters-editable-row'}
                  bordered
                  dataSource={companyOrderBonuses}
                  rowKey="id"
                  columns={columns}
                  total={companyOrderBonuses?.length}
                  pageSize={Infinity}
                  rowSelection={rowSelection}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  rewardType === RewardType.Percentage
                    ? t('Percentage of reward')
                    : t('rate')
                }
                rules={[
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message:
                      rewardType === RewardType.Percentage
                        ? t('Percentage of reward must be a number.')
                        : t('Rate must be a number.'),
                  },
                ]}
                extra={
                  <StyledDescription $color={theme.colors.warning}>
                    {t('Not used')}
                  </StyledDescription>
                }
                name="reward_value">
                <Input
                  addonAfter={
                    <StyledSearchSelect
                      popupMatchSelectWidth={false}
                      name="reward_type"
                      getOptionValueTitle="description"
                      placeholder={t(
                        'Select the manager for which the service is manage',
                      )}
                      disable={loading || loadingSubmit}
                      data={REWARD_TYPE_LIST(
                        defaultCompany?.currency_symbol || '',
                      )}
                      onChange={(e: any) => {
                        setFieldsValue({ reward_type: e });
                        setRewardType(e as RewardType);
                      }}
                    />
                  }
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
