import { correctPrice } from '@services/helpers';

export interface IScheduleCalendarStatsDTOProps {
  totalCancelledSum: string;
  totalOverdueSum: string;
  totalSum: string;
  totalUnPaidSum: string;
  totalOverdueCount: number;
  totalUnPaidCount: number;
  totalCount: number;
  totalCancelledCount: number;
}

export class ScheduleCalendarStatsDTO {
  public totalCancelledSum: string;
  public totalOverdueSum: string;
  public totalSum: string;
  public totalUnPaidSum: string;
  public totalOverdueCount: number;
  public totalUnPaidCount: number;
  public totalCount: number;
  public totalCancelledCount: number;

  constructor(props?: IScheduleCalendarStatsDTOProps) {
    this.totalCancelledSum = correctPrice(props?.totalCancelledSum || 0);
    this.totalOverdueSum = correctPrice(props?.totalOverdueSum || 0);
    this.totalSum = correctPrice(props?.totalSum || 0);
    this.totalUnPaidSum = correctPrice(props?.totalUnPaidSum || 0);
    this.totalOverdueCount = props?.totalOverdueCount ?? 0;
    this.totalUnPaidCount = props?.totalUnPaidCount ?? 0;
    this.totalCount = props?.totalCount ?? 0;
    this.totalCancelledCount = props?.totalCancelledCount ?? 0;
  }
}
