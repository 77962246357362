import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SideWindow } from '@components/lib/Navigation';
import { IRewardFormProps, RewardForm } from '../Forms';
import { RewardFormDTO } from '@structure';

export default withTranslation()(RewardSideWindow);

export interface IRewardSideWindowProps
  extends WithTranslation,
    IRewardFormProps {
  onSuccess: (value: RewardFormDTO) => Promise<void>;
  visible: boolean;
  title: string;
}

function RewardSideWindow({
  t,
  onSuccess,
  onCancel,
  visible,
  editMode,
  title,
  ...rest
}: IRewardSideWindowProps) {
  return (
    <SideWindow title={title} visible={visible} onClose={onCancel}>
      <RewardForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </SideWindow>
  );
}
