import { Record, List } from 'immutable';
import { IListProps } from './List';
import {
  ClientAbonementSubscriptionDTO,
  ClientAbonementSubscriptionModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IClientAbonementSubscriptionListModelProps extends IListProps {
  subscriptions: ClientAbonementSubscriptionDTO[];
}

export interface IClientAbonementSubscriptionListModelReturnType
  extends IListProps {
  subscriptions: List<ClientAbonementSubscriptionModel>;
}

export class ClientAbonementSubscriptionListModel extends Record<IClientAbonementSubscriptionListModelReturnType>(
  {
    subscriptions: List(),
    total: 0,
  },
) {
  constructor(
    {
      subscriptions = [],
      ...props
    }: IClientAbonementSubscriptionListModelProps = {} as IClientAbonementSubscriptionListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientAbonementSubscriptionListModelReturnType = {
      ...props,
      subscriptions: List(
        subscriptions.map(
          (subscription) =>
            new ClientAbonementSubscriptionModel(
              subscription,
              withoutRecursion,
            ),
        ),
      ),
    };

    super(options);
  }
}
