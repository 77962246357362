import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DepartmentForm, IDepartmentFormProps } from '../Forms';
import { SideWindow } from '@components/lib/Navigation';
import { DepartmentFormDTO } from '@structure';
import { DEPARTMENT_ICONS } from '../helpers';
import { DEPARTMENT_INITIAL_PARAM } from '@services/api/department';
import styled, { useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';

export interface IDepartmentSideWindowProps extends IDepartmentFormProps {
  onSuccess: (value: DepartmentFormDTO) => Promise<void>;
  visible: boolean;
}

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
`;

export default function DepartmentSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  department = DEPARTMENT_INITIAL_PARAM,
  ...rest
}: IDepartmentSideWindowProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [Icon, setIcon] = useState<any | null>(
    department ? DEPARTMENT_ICONS[department?.dep_icon] : null,
  );

  const [color, setColor] = useState(
    department?.dep_colour || theme.colors.primary,
  );

  const handleUpdateIcon = useCallback((icon: any) => {
    setIcon(icon);
  }, []);

  const handleUpdateColorIcon = useCallback((color: string) => {
    setColor(color);
  }, []);

  return (
    <SideWindow
      title={
        <StyledTitleContainer>
          {React.isValidElement(Icon)
            ? React.cloneElement(Icon, { color, size: 17 } as any)
            : null}
          <StyledTitle>{t('Departments / Locations')}</StyledTitle>
        </StyledTitleContainer>
      }
      visible={visible}
      onClose={onCancel}>
      <DepartmentForm
        department={department}
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
        handleUpdateColorIcon={handleUpdateColorIcon}
        handleUpdateIcon={handleUpdateIcon}
      />
    </SideWindow>
  );
}
