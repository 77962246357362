import * as React from 'react';
import { useReward, IUseRewardProps, IUseRewardReturnType } from './useReward';
import {
  RewardFormDTO,
  RewardModel,
  RewardMapper,
  RewardDTO,
  RewardStatsDTO,
  RewardPaymentFormDTO,
} from '@structure';
import {
  createRewardAwardRecord,
  createRewardFineRecord,
  editReward,
  createRewardPayment,
  getRewardEstimatedAmount,
} from '@services/api/reward';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useCallback, useEffect } from 'react';
import {
  addRewardToList as storeAddRewardToList,
  setReward as storeSetReward,
  updateReward as storeUpdateReward,
  updateRewardFromList as storeUpdateRewardFromList,
  updateRewardStatsFromList as storeUpdateRewardStatsFromList,
} from '@store/actions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { IUseClientVisitListProps } from './useClientVisitList';
import { toDateByFormat } from '@services/helpers';
import useStoredCompanies from './useStoredCompanies';
import { useStoredCashBoxList } from './useStoredCashBoxList';

export interface IUseStateRewardProps extends IUseRewardProps {
  employeeUuid: string;
  path?: (reward: RewardModel) => string;
  handleRefreshReward?: (
    value: Partial<IUseClientVisitListProps> & { page: number },
  ) => Promise<void>;

  keywords: string;
  month: number;
  year: number;
}

export interface IUseStateRewardReturnType
  extends Omit<IUseRewardReturnType, 'entity'> {
  reward: RewardModel | null;
  handleUpdateReward: (value: RewardFormDTO) => Promise<void>;
  handleCreateRewardAwardRecord: (value: RewardFormDTO) => Promise<void>;
  handleCreateRewardFineRecord: (value: RewardFormDTO) => Promise<void>;
  handleCreateRewardPayment: (value: RewardPaymentFormDTO) => Promise<string>;
  handleRefreshReward: () => Promise<RewardModel | void>;
  status: REDUX_STATUS;
  handleUpdateStoredRewardStats: (stats: Partial<RewardStatsDTO>) => void;
}

export function useStoredReward({
  employeeUuid,
  loadOnInit,
  rewardUuid,
  path,
  keywords,
  month,
  year,
  ...rest
}: IUseStateRewardProps): IUseStateRewardReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { handleRefreshCashBoxes } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const [reward, setReward] = React.useState<RewardModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedRewardStatus,
    cachedRewardList,
    ...storedRewardParams
  } = useSelector(({ reward }: RootState) => reward);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...rewardParams } = useReward({
    loadOnInit: loadOnInit || isStarLoading,
    rewardUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedRewardList?.size > 0) {
      const rewardList = cachedRewardList.get(rewardUuid);

      if (rewardList) {
        setIsStartLoading(false);
        setReward(rewardList);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedRewardList?.size === 0 && !reward) {
      setIsStartLoading(true);
    }
  }, [cachedRewardList, dispatch, rewardUuid, reward]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetReward(entity));
      setReward(entity);
    }
  }, [dispatch, entity, isStarLoading, storedRewardStatus]);

  const createStoredReward = useCallback(
    (
      rewardDTO: RewardDTO,
      { total, totalAccrued, employee }: RewardStatsDTO,
    ) => {
      const rewardModel = RewardMapper.toRewardModel(rewardDTO);

      if (
        toDateByFormat(rewardModel?.date, 'MM YYYY') ===
        `${String(month).length === 1 ? `0${month}` : month} ${year}`
      ) {
        dispatch(storeAddRewardToList(rewardModel, employeeUuid));
        dispatch(
          storeUpdateRewardStatsFromList(
            { total, totalAccrued, employee },
            employeeUuid,
          ),
        );
      }
    },
    [dispatch, employeeUuid, month, year],
  );

  const handleUpdateReward = React.useCallback(
    async (value: RewardFormDTO): Promise<void> => {
      const rewardDTO = await editReward(value);

      const rewardModel = RewardMapper.toRewardModel(rewardDTO);

      dispatch(storeUpdateReward(rewardModel));
      dispatch(storeUpdateRewardFromList(rewardModel, employeeUuid));
    },
    [dispatch, employeeUuid],
  );

  const handleCreateRewardAwardRecord = React.useCallback(
    async (value: RewardFormDTO) => {
      const { reward, stats } = await createRewardAwardRecord(value, {
        employeeUuid,
        keywords,
        month,
        year,
      });

      createStoredReward(reward, stats);

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Reward'), t('Create reward award record success'));
    },
    [
      employeeUuid,
      keywords,
      month,
      year,
      createStoredReward,
      alert,
      t,
      handleRefreshCashBoxes,
    ],
  );

  const handleCreateRewardFineRecord = React.useCallback(
    async (value: RewardFormDTO) => {
      const { reward, stats } = await createRewardFineRecord(value, {
        employeeUuid,
        keywords,
        month,
        year,
      });

      createStoredReward(reward, stats);

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Reward'), t('Create reward fine record success'));
    },
    [
      employeeUuid,
      keywords,
      month,
      year,
      createStoredReward,
      alert,
      t,
      handleRefreshCashBoxes,
    ],
  );

  const handleCreateRewardPayment = React.useCallback(
    async (value: RewardPaymentFormDTO): Promise<string> => {
      const stats = await createRewardPayment(value, {
        employeeUuid,
        keywords,
        month,
        year,
      });

      dispatch(storeUpdateRewardStatsFromList(stats, employeeUuid));

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Reward'), t('Create reward payment success'));
      return stats?.totalAccrued;
    },
    [
      employeeUuid,
      keywords,
      month,
      year,
      dispatch,
      alert,
      t,
      handleRefreshCashBoxes,
    ],
  );

  const handleRefreshReward = React.useCallback(async () => {
    const rewardAnswer = await refresh({ showLoading: false, rewardUuid });

    if (rewardAnswer?.reward) {
      dispatch(storeUpdateReward(rewardAnswer?.reward));
      dispatch(storeUpdateRewardFromList(rewardAnswer?.reward, employeeUuid));

      return rewardAnswer?.reward;
    }
  }, [dispatch, employeeUuid, refresh, rewardUuid]);

  const handleUpdateStoredRewardStats = React.useCallback(
    (stats: Partial<RewardStatsDTO>) => {
      dispatch(storeUpdateRewardStatsFromList(stats, employeeUuid));
    },
    [dispatch, employeeUuid],
  );

  return {
    ...rewardParams,
    ...storedRewardParams,
    reward,
    loading: !reward,
    handleUpdateReward,
    handleCreateRewardAwardRecord,
    handleCreateRewardFineRecord,
    status: storedRewardStatus,
    refresh,
    handleRefreshReward,
    handleCreateRewardPayment,
    handleUpdateStoredRewardStats,
  };
}
