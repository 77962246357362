import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/lib/button';
import { ITemplatesParams } from '@services/api/templates';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const TemplateSideWindowLazy = React.lazy(
  () => import('../Show/TemplateSideWindow'),
);

export interface ITemplateCreateButtonProps extends ButtonProps {
  loading: boolean;
  onSuccess: (value: ITemplatesParams) => Promise<void>;
  onCancel?: () => void;
}

export default function TemplateCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: ITemplateCreateButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create Template')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <TemplateSideWindowLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
