import React, { useCallback, useEffect } from 'react';
import history from '../routes/history';
import { StyledTitle } from '@components/lib/Styled';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { isFunction } from '@sportix/sportix-common-modules';

export interface IUseBeforeUnloadProps {
  isBlock: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  message?: string;
  allowedPaths?: string[];
}

export interface IUseBeforeUnloadReturnType {
  contextHolder: React.ReactElement;
}

export function useBeforeUnload({
  isBlock,
  onCancel,
  onOk,
  message = 'Close',
  allowedPaths = [],
}: IUseBeforeUnloadProps) {
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const handleCancelSideWindows = useCallback(
    (onOk: () => void) => {
      modal.confirm({
        title: <StyledTitle>{t(message)}</StyledTitle>,
        style: { minWidth: '40%' },
        okText: t('Yes'),
        cancelText: t('No'),
        onOk,
        onCancel,
      });
    },
    [message, modal, onCancel, t],
  );

  useEffect(() => {
    let unblock: any;

    if (isBlock) {
      unblock = history.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.

        if (
          allowedPaths?.some((path) => tx?.location?.pathname?.startsWith(path))
        ) {
          unblock();
          tx.retry();
          return;
        }

        handleCancelSideWindows(() => {
          if (isFunction(onOk)) {
            onOk();
          }

          unblock();
          tx.retry();
        });
      });
    } else {
      if (typeof unblock === 'function') {
        unblock();
      }
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [allowedPaths, handleCancelSideWindows, isBlock, onOk]);

  return {
    contextHolder,
  };
}
