import { Record, List } from 'immutable';
import { IListProps } from './List';
import { AbonementDTO, AbonementModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IAbonementListModelProps extends IListProps {
  abonements: AbonementDTO[];
}

export interface IAbonementListModelReturnType extends IListProps {
  abonements: List<AbonementModel>;
}

export class AbonementListModel extends Record<IAbonementListModelReturnType>({
  abonements: List(),
  total: 0,
}) {
  constructor(
    {
      abonements = [],
      ...props
    }: IAbonementListModelProps = {} as IAbonementListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IAbonementListModelReturnType = {
      ...props,
      abonements: List(
        abonements.map((abon) => new AbonementModel(abon, withoutRecursion)),
      ),
    };

    super(options);
  }
}
