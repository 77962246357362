import React, { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { useModal } from '@components/lib/libV2/hooks';
import {
  PriceTagPrintFormDTO,
  ProductFormDTO,
  ProductLeftoversFormDTO,
  ProductStatus,
} from '@structure';
import {
  Button,
  IAddButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { IoMenu } from 'react-icons/io5';
import {
  IUseStatePriceTagListReturnType,
  IUseStateProductLeftoversListReturnType,
  IUseStateProductReturnType,
} from '@hooks';
import { MdModeEditOutline } from 'react-icons/md';
import { AiFillPrinter, AiOutlineOrderedList } from 'react-icons/ai';
import { List } from 'immutable';
import { len } from '@services/helpers';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

const LeftoversModalLazy = React.lazy(
  () => import('../../Stores/Leftovers/Show/LeftoversModal'),
);

const PriceTagPrintModalLazy = React.lazy(
  () => import('../PriceTags/Show/PriceTagPrintModal'),
);

export interface IProductMenuButtonProps
  extends IAddButtonProps,
    Pick<
      IUseStateProductLeftoversListReturnType,
      'handleUpdateProductLeftover'
    >,
    Pick<IUseStatePriceTagListReturnType, 'handlePrintPriceTag'> {
  product: ProductFormDTO;
  leftover: ProductLeftoversFormDTO;
  onSuccess: IUseStateProductReturnType['handleUpdateProduct'];
  onCancel?: () => void;
  children?: React.ReactNode;
  print: PriceTagPrintFormDTO;
  status: ProductStatus | undefined;
  handleDeleteProduct: () => Promise<void>;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

export default function ProductMenuButton({
  children,
  product,
  leftover,
  onSuccess,
  onCancel,
  loading,
  disabled,
  handleUpdateProductLeftover,
  print,
  handlePrintPriceTag,
  status,
  handleDeleteProduct: onDeleteProduct,
  ...rest
}: IProductMenuButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [deletedLoading, setDeletedLoading] = useState(false);

  const handleDeleteProduct = useCallback(async () => {
    setDeletedLoading(true);
    await onDeleteProduct();
    setDeletedLoading(false);
  }, [onDeleteProduct]);

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    handleSuccess: productHandleSuccess,
    visible: productVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  const {
    handleCancel: leftoversHandleCancel,
    handleOnInit: leftoversHandleOnInit,
    handleSuccess: leftoversHandleSuccess,
    visible: leftoversVisible,
  } = useModal({
    onCancel,
    onSuccess: handleUpdateProductLeftover,
  });

  const {
    handleCancel: priceTagHandleCancel,
    handleOnInit: priceTagHandleOnInit,
    handleSuccess: priceTagHandleSuccess,
    visible: priceTagVisible,
  } = useModal({
    onCancel,
    onSuccess: handlePrintPriceTag,
  });

  const items: MenuProps['items'] = [
    {
      key: 'product',
      label: <StyledTitle>{t('Edit product')}</StyledTitle>,
      icon: <MdModeEditOutline size={15} />,
      onClick: productHandleOnInit,
    },
    {
      key: 'price tags',
      label: <StyledTitle>{t('Print a price tag')}</StyledTitle>,
      icon: <AiFillPrinter size={15} />,
      onClick: priceTagHandleOnInit,
    },
    ...(status === ProductStatus.ENABLE
      ? [
          {
            key: 'leftovers',
            label: <StyledTitle>{t('Enter balances')}</StyledTitle>,
            icon: <AiOutlineOrderedList size={15} />,
            onClick: leftoversHandleOnInit,
          },
        ]
      : []),
    {
      key: 'delete-product',
      label: (
        <Popconfirm
          title={t('Are you sure you want to delete the product?')}
          okText={t('Yes')}
          cancelText={t('No')}
          onCancel={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }}
          onConfirm={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            await handleDeleteProduct();
          }}>
          <StyledPopconfirmButton
            type="text"
            onClick={async (e: any) => {
              if (e) {
                e?.preventDefault();
                e?.stopPropagation();
              }
            }}>
            {deletedLoading ? (
              <StyledLoadingOutlined />
            ) : (
              <StyledDeleteOutlined />
            )}
            <StyledTitle $color={theme.colors.error}>
              {t('Delete product')}
            </StyledTitle>
          </StyledPopconfirmButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <StyledDropdown
        trigger={['hover']}
        disabled={!!loading || disabled}
        menu={{ items }}
        placement="bottomLeft">
        {loading || deletedLoading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <IoMenu size={20} color={theme.colors.primary} />
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          product={product}
          editMode
          onSuccess={productHandleSuccess}
          visible={productVisible}
          onCancel={productHandleCancel}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <LeftoversModalLazy
          product={leftover}
          onSuccess={leftoversHandleSuccess}
          visible={leftoversVisible}
          onCancel={leftoversHandleCancel}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <PriceTagPrintModalLazy
          print={print!}
          onSuccess={priceTagHandleSuccess}
          visible={priceTagVisible}
          onCancel={priceTagHandleCancel}
        />
      </Suspense>
    </>
  );
}
