import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { forwardRef, useRef } from 'react';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewBottomView } from '@components/lib/Layout';
import { useStoredCompanies, IUseStateInvoiceReturnType } from '@hooks';

export interface IInvoiceBottomViewProps
  extends Pick<
    IUseStateInvoiceReturnType,
    | 'invoice'
    | 'handlePrintInputInvoice'
    | 'handlePrintInvoiceStoreRealization'
    | 'handlePrintInvoice'
  > {}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPaidContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

export const InvoiceBottomView = forwardRef(function InvoiceBottomView(
  { invoice }: IInvoiceBottomViewProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const contentRef = useRef(null);

  return (
    <div ref={ref}>
      <OverviewBottomView ref={contentRef}>
        <StyledPriceContainer>
          <StyledTitle fontSize={14}>{`${t('Total')}: `}</StyledTitle>
          <StyledPaidContainer>
            <StyledPrice>{`${defaultCompany?.currency_symbol || ''} ${
              invoice?.inv_sum_total
            }`}</StyledPrice>
          </StyledPaidContainer>
        </StyledPriceContainer>
      </OverviewBottomView>
    </div>
  );
});
