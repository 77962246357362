import React, { useState, useCallback, useEffect } from 'react';
import { SettingsModuleModel } from '@structure';
import styled, { css } from 'styled-components';
import { Switch } from '@components/lib/DataDisplay';
import { TableLinkCell } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

export interface ISettingsModuleListItemActionsProps {
  settingsModule: SettingsModuleModel;
  handleActivateModule: (settingsModule: SettingsModuleModel) => Promise<void>;
  handleDeactivateModule: (
    settingsModule: SettingsModuleModel,
  ) => Promise<void>;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{ $loading?: boolean }>`
  margin-left: 5px;
  font-size: 18px;
  visibility: hidden;

  ${({ $loading }) =>
    $loading &&
    css`
      visibility: visible;
    `}
`;

export function SettingsModuleListItemActions({
  settingsModule,
  handleDeactivateModule,
  handleActivateModule,
}: ISettingsModuleListItemActionsProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();

  const [isActive, setIsActive] = useState<boolean>(
    !!settingsModule?.module_enabled_for_company,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleActivateOrDeactivate = useCallback(
    async (value: boolean) => {
      try {
        setIsActive(value);
        setLoading(true);

        if (settingsModule) {
          if (value) {
            await handleActivateModule(settingsModule);
          } else {
            await handleDeactivateModule(settingsModule);
          }
        }
        setLoading(false);
      } catch (error) {
        setIsActive(!value);
        setLoading(false);
      }
    },
    [handleActivateModule, handleDeactivateModule, settingsModule],
  );

  useEffect(() => {
    if (typeof settingsModule?.module_enabled_for_company === 'boolean') {
      setIsActive((prevState) =>
        prevState === settingsModule?.module_enabled_for_company
          ? prevState
          : settingsModule?.module_enabled_for_company,
      );
    }
  }, [settingsModule?.module_enabled_for_company]);

  return (
    <>
      {settingsModule?.dependencyListModel?.size === 0 &&
      settingsModule?.module_input_parameters_json?.length === 0 ? (
        <StyledContainer>
          <Switch
            checked={isActive}
            onChange={handleActivateOrDeactivate}
            disabled={!settingsModule || loading}
          />
          <StyledLoadingOutlined $loading={loading} />
        </StyledContainer>
      ) : (
        <TableLinkCell
          state={{ prevState: location.state }}
          tooltip={t('Open a detailed of the module')}
          path={`${settingsModule?.uuid}`}>
          {t('Details')}
        </TableLinkCell>
      )}
    </>
  );
}
