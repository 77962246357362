import { List } from 'immutable';
import {
  ClientGroupSessionDTO,
  IClientsGroupSessionDTOProps,
  ClientGroupSessionModel,
  ClientsGroupSessionListModel,
} from '../internal';
import { listToArray } from '@services/helpers';

export class ClientGroupSessionMapper {
  public static toClientGroupSessionDTO(
    clientGroupSession: ClientGroupSessionModel | IClientsGroupSessionDTOProps,
  ): ClientGroupSessionDTO {
    return new ClientGroupSessionDTO({
      uuid: clientGroupSession?.uuid,
      status: clientGroupSession?.status,
      created_at: clientGroupSession?.created_at,
      updated_at: clientGroupSession?.updated_at,
    });
  }

  public static toClientGroupSessionListDTO(
    clientGroupSessions:
      | List<ClientGroupSessionModel>
      | IClientsGroupSessionDTOProps[],
  ): ClientGroupSessionDTO[] {
    const clientGroupSessionList = List.isList(clientGroupSessions)
      ? listToArray<ClientGroupSessionModel>(clientGroupSessions)
      : clientGroupSessions;

    return clientGroupSessionList?.map((clientGroupSession) =>
      ClientGroupSessionMapper.toClientGroupSessionDTO(clientGroupSession),
    );
  }

  public static toClientGroupSessionModel(
    clientGroupSessionDTO: ClientGroupSessionDTO,
  ): ClientGroupSessionModel {
    return new ClientGroupSessionModel(clientGroupSessionDTO);
  }

  public static toClientGroupSessionListModel(
    clientGroupSessionDTOs: ClientGroupSessionDTO[],
  ): ClientsGroupSessionListModel {
    return new ClientsGroupSessionListModel({
      clients_group_sessions: clientGroupSessionDTOs,
    });
  }
}
