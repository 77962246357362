import * as React from 'react';
import { RefObject, useRef } from 'react';
import { AclLayout, IAclLayoutProps } from '@components/lib/Layout';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT } from '@components/lib/Layout/const';

export interface IListLayoutManagerProps
  extends Pick<IAclLayoutProps, 'aclItem'> {
  children: React.ReactNode;
}

export interface IListLayoutManagerContext {
  contentRef: RefObject<HTMLDivElement>;
  headerRef: RefObject<HTMLDivElement>;
  outsideContentRef: RefObject<HTMLDivElement>;
}

export const ListLayoutManagerContext =
  React.createContext<IListLayoutManagerContext>({
    contentRef: { current: null },
    headerRef: { current: null },
    outsideContentRef: { current: null },
  });

export const useListLayout = () => React.useContext(ListLayoutManagerContext);

const StyledListLayout = styled.div<{ $bottomIndent: number }>`
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: ${HEADER_HEIGHT + 20}px;

  ${({ $bottomIndent }) =>
    $bottomIndent &&
    css`
      padding-bottom: ${$bottomIndent}px;
    `};
`;

export const ListLayoutManager = React.forwardRef(function ListLayoutManager(
  { children, aclItem }: IListLayoutManagerProps,
  ref: any,
): React.JSX.Element {
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const outsideContentRef = useRef<HTMLDivElement>(null);

  const value = React.useMemo(
    () => ({
      headerRef,
      contentRef,
      outsideContentRef,
    }),
    [],
  );

  return (
    <ListLayoutManagerContext.Provider value={value}>
      <AclLayout aclItem={aclItem}>
        <StyledListLayout
          $bottomIndent={
            outsideContentRef?.current?.getBoundingClientRect()?.height || 0
          }
          ref={ref}>
          {children}
        </StyledListLayout>
      </AclLayout>
    </ListLayoutManagerContext.Provider>
  );
});
