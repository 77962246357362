import * as React from 'react';
import { eq } from '@services/helpers';

export default function useConst<T>(initialValue: T | (() => T)): any {
  const ref = React.useRef<{ value: T }>();

  if (eq(ref.current, undefined)) {
    ref.current = {
      value: eq(typeof initialValue, 'function')
        ? (initialValue as Function)()
        : initialValue,
    };
  }

  return ref;
}
