import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { IUseHandlerFinancialReliabilityReturnType } from '@hooks';
import { IFinancialReliabilityProps } from '@services/helpers';

export interface IFinancialStatementsTabProps
  extends Omit<IUseHandlerFinancialReliabilityReturnType, 'refresh'> {
  companyUuid: string;
  settings: IFinancialReliabilityProps;
  loading: boolean;
  smsNotificationPrice: string;
  balance: string;
  handleUpdateBalance: () => void;
  disabled?: boolean;
}

const FinancialStatementSpin = styled(Spin)`
  width: 100%;
`;

export default function FinancialStatementsTab({
  settings,
  handleUpdateFinancialReliability,
  loading,
  companyUuid,
  smsNotificationPrice,
  balance,
  handleUpdateBalance,
  disabled,
}: IFinancialStatementsTabProps): JSX.Element {
  return (
    <>
      {!loading ? (
        // <FinancialReliabilityForm
        //   smsNotificationPrice={smsNotificationPrice}
        //   financialReliability={{
        //     ...FINANCIAL_RELIABILITY_INITIAL_PARAM,
        //     ...settings,
        //   }}
        //   showCancelButton={false}
        //   isResetLoading
        //   onSuccess={handleUpdateFinancialReliability}
        //   loading={loading}
        //   companyUuid={companyUuid}
        //   balance={balance}
        //   handleUpdateBalance={handleUpdateBalance}
        //   disabled={disabled}
        // />
        // <ParametersPage />
        <></>
      ) : (
        <FinancialStatementSpin spinning={loading} />
      )}
    </>
  );
}
