import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/lib/button';
import { IClientSubscriptionParams } from '@services/api/clientSubscription';
import { ActionButton } from '@components/lib/General';
import { SuspenseEmpty } from '@components/lib/DataDisplay';
import { useModal } from '@components/lib/libV2/hooks';

const ClientSubscriptionSideWindowLazy = React.lazy(
  () => import('../Show/ClientSubscriptionSideWindow'),
);

export default withTranslation()(CreateClientSubscriptionButton);

export interface ICreateClientSubscriptionButtonProps
  extends WithTranslation,
    ButtonProps {
  onSuccess: (value: IClientSubscriptionParams) => Promise<void>;
  onCancel?: () => void;
  clientUuid: string;
  companyUuid: string;
}

function CreateClientSubscriptionButton({
  t,
  loading = false,
  onSuccess,
  clientUuid,
  companyUuid,
  onCancel,
  ...rest
}: ICreateClientSubscriptionButtonProps) {
  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <ActionButton
        tooltip={t('Create client service')}
        type="primary"
        loading={loading}
        onClick={handleOnInit}
        data-testid="clients-subscription__create-button"
        {...rest}>
        {t('Order a service')}
      </ActionButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientSubscriptionSideWindowLazy
          companyUuid={companyUuid}
          clientUuid={clientUuid}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
