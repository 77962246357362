import * as React from 'react';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import useScheduleActions, {
  IUseScheduleActionsReturnType,
} from './useScheduleActions';
import { debounce } from '@services/helpers';
import { ScheduleCalendarModel } from '@structure';
import { List } from 'immutable';
import {
  useEmployeeScheduleList,
  IUseEmployeeScheduleListProps,
  IUseEmployeeScheduleListReturnType,
} from './index';

export interface IUseStateEmployeeScheduleListProps
  extends Omit<Partial<IUseEmployeeScheduleListProps>, 'employeeUuid'> {
  employeeUuid: string;
}

export interface IUseStateEmployeeScheduleListReturnType
  extends Omit<IUseEmployeeScheduleListReturnType, 'list'>,
    IUseScheduleActionsReturnType {
  employeeSchedules: List<ScheduleCalendarModel> | null;
  handleSearchSchedule: (value: string) => Promise<void>;
}

export default function useStateEmployeeScheduleList(
  {
    ...scheduleListProps
  }: IUseStateEmployeeScheduleListProps = {} as IUseStateEmployeeScheduleListProps,
): IUseStateEmployeeScheduleListReturnType {
  const {
    list,
    loading,
    refresh,
    total: scheduleListTotal,
    limit: scheduleListLimit,
    offset,
    ...rest
  } = useEmployeeScheduleList({
    ...scheduleListProps,
  });

  const {
    entityList: employeeSchedules,
    loading: stateLoading,
    handleUpdate,
    handleCreate,
    handleDelete,
    total,
  } = useStateEntityList<ScheduleCalendarModel>({
    entityList: list,
    refresh,
    limit: scheduleListLimit,
    offset,
    total: scheduleListTotal,
  });

  const schedulesActions = useScheduleActions({
    handleUpdate,
    handleCreate,
    handleDelete,
  });

  const debounceRefresh = debounce(refresh, 500);

  const handleSearchSchedule = React.useCallback(
    async (keywords: string): Promise<any> => {
      debounceRefresh({ keywords });
    },
    [debounceRefresh],
  );

  return {
    loading: loading || stateLoading,
    employeeSchedules,
    refresh,
    ...schedulesActions,
    handleSearchSchedule,
    limit: scheduleListLimit,
    total,
    offset,
    ...rest,
  };
}
