import { v4 as uuidv4 } from 'uuid';
import { immerable } from 'immer';

export interface ICompanyRequisiteDTOProps {
  uuid?: string;
  title: string;
  code: string;
  account_number: string;
  account_bank: string;
  comment: string;
  enabled: boolean;
}

export class CompanyRequisiteDTO {
  [immerable]? = true;
  public uuid?: string;
  public title: string;
  public code: string;
  public account_number: string;
  public account_bank: string;
  public comment: string;
  public enabled: boolean;

  constructor(props: ICompanyRequisiteDTOProps) {
    this.uuid = props?.uuid || uuidv4();
    this.title = props?.title || '';
    this.code = props?.code || '';
    this.account_number = props?.account_number || '';
    this.account_bank = props?.account_bank || '';
    this.comment = props?.comment || '';
    this.enabled = props?.enabled ?? true;
  }
}
