import React, { useCallback, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from 'immutable';
import { IDefaultFormProps } from '@components/lib/General';
import {
  IClientSubscriptionParams,
  CLIENT_SUBSCRIPTION_INITIAL_PARAM,
  IClientSubscriptionTransitionParam,
  isSubscriptionEditable,
  isSubscriptionPayedShowMessage,
} from '@services/api/clientSubscription';
import { isPeriodEqMinOrHour } from '@services/api/services';
import { WizardFormV2 } from '@components/lib/Navigation';
import {
  memoizeUnaryArity,
  isFunction,
  neq,
  isListToArray,
} from '@services/helpers';
import { ClientSubscriptionTransition } from '@services/models';
import ClientServiceForm, { OnServicesChange } from './ClientServiceForm';
import ClientSubscriptionForm from './ClientSubscriptionForm';
import ClientPaymentForm from './ClientPaymentForm';
import { useServiceList, useStateServiceGroupSessionList } from '@hooks';
import { ServiceGroupSessionModel } from '@structure';

export default withTranslation()(ClientServiceSubscriptionForm);

export interface IClientServiceSubscriptionFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<any, any>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  loading?: boolean;
  clientUuid: string;
  subscription?: IClientSubscriptionParams;
  handleTransition?: (
    value: IClientSubscriptionTransitionParam,
  ) => Promise<ClientSubscriptionTransition>;
  companyUuid: string;
}

function ClientServiceSubscriptionForm({
  t,
  loading,
  clientUuid = '',
  editMode,
  subscription = CLIENT_SUBSCRIPTION_INITIAL_PARAM,
  handleTransition,
  companyUuid,
  ...rest
}: IClientServiceSubscriptionFormProps) {
  const [isShowSubscription, setIsShowSubscription] = useState(false);
  const [isShowTransition, setShowTransition] = useState(false);

  const {
    refresh: serviceGroupSessionsRefresh,
    groupSessions: serviceGroupSessions,
    handleResetGroupSession,
  } = useStateServiceGroupSessionList({
    loadOnInit: false,
    serviceUuid: '',
  });

  const { entityList: serviceList, loading: serviceListLoading } =
    useServiceList({
      companyUuid,
    });

  const isNotServicesUuidEqual = useCallback(
    (serviceUuid: string) => neq(serviceUuid, subscription?.service_uuid),
    [subscription],
  );

  const isNotServicesGroupUuidEqual = useCallback(
    (groupServiceUuid: string) =>
      neq(groupServiceUuid, subscription?.group_session_uuid),
    [subscription],
  );

  const services = useMemo(
    () =>
      editMode && List.isList(serviceList)
        ? serviceList.filter(
            (service): boolean =>
              !isPeriodEqMinOrHour(service?.period) ||
              !isNotServicesUuidEqual(service?.uuid),
          )
        : serviceList,
    [serviceList, editMode, isNotServicesUuidEqual],
  );

  const getGroupSessionByPeriodOrUuid = useCallback(
    (serviceGroup: ServiceGroupSessionModel): boolean =>
      !isPeriodEqMinOrHour(serviceGroup?.service?.period) ||
      !isNotServicesGroupUuidEqual(serviceGroup?.uuid),
    [isNotServicesGroupUuidEqual],
  );

  const groupSessions = useMemo(() => {
    if (editMode && serviceGroupSessions) {
      return isListToArray(
        serviceGroupSessions.filter(getGroupSessionByPeriodOrUuid) as any,
      );
    }
    return serviceGroupSessions;
  }, [editMode, serviceGroupSessions, getGroupSessionByPeriodOrUuid]);

  const additionalValues = useMemo(
    () => ({
      clientUuid,
      uuid: subscription?.uuid,
    }),
    [clientUuid, subscription?.uuid],
  );

  const memoizeHandleTransition =
    memoizeUnaryArity<ClientSubscriptionTransition>(
      isFunction(handleTransition) ? handleTransition : () => {},
      new Map(),
    );

  const onChangeServiceTransition = useCallback(
    async ({
      service_uuid,
      group_session_uuid,
      subscription_start_date,
      subscription_number_of_packages,
    }: Omit<
      IClientSubscriptionTransitionParam,
      'subscriptionUuid'
    >): Promise<ClientSubscriptionTransition | void> => {
      if (
        isFunction(handleTransition) &&
        isNotServicesUuidEqual(service_uuid) &&
        isNotServicesGroupUuidEqual(group_session_uuid)
      ) {
        const value = await memoizeHandleTransition({
          subscriptionUuid: additionalValues?.uuid,
          service_uuid,
          group_session_uuid,
          subscription_start_date,
          subscription_number_of_packages,
        });

        setShowTransition(!!value);

        return value;
      }

      setShowTransition(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      additionalValues?.uuid,
      handleTransition,
      isNotServicesUuidEqual,
      isNotServicesGroupUuidEqual,
    ],
  );

  const onChangeService = useCallback(
    async ({
      isServiceGroup,
      serviceUuid,
      isShowSubscription,
    }: OnServicesChange): Promise<void> => {
      if (isServiceGroup) {
        await serviceGroupSessionsRefresh({
          serviceUuid,
        });
      } else {
        handleResetGroupSession();
      }

      setIsShowSubscription(!isShowSubscription);
    },
    [handleResetGroupSession, serviceGroupSessionsRefresh],
  );

  return (
    <WizardFormV2<any, any>
      notifyFormName={t('Subscription')}
      headerMessage={isSubscriptionPayedShowMessage(
        subscription?.status!,
        t('You must cancel your payment to edit your subscription'),
      )}
      headerMessageType="danger"
      activeKey="Services"
      submitDisabled={!isSubscriptionEditable(subscription?.status!)}
      initialValues={{ ...subscription, onChangeServiceTransition }}
      additionalValuesRequest={additionalValues}
      loading={serviceListLoading}
      isClickableSteps
      doneButtonText={
        editMode ? t('Edit client subscription') : t('Make an subscription')
      }
      {...rest}>
      <WizardFormV2.Step
        key="Services"
        title={t('Services')}
        loading={false}
        checkFieldsOnStepChange={[
          'service_uuid',
          'group_session_uuid',
          'cost',
          'total',
        ]}>
        <ClientServiceForm
          validateServicesField={editMode}
          showTransition={editMode && isShowTransition}
          services={services}
          groupSessions={groupSessions}
          onChangeService={onChangeService}
          isDisabledAllFields={!isSubscriptionEditable(subscription?.status!)}
        />
      </WizardFormV2.Step>
      <WizardFormV2.Step
        hidden={isShowSubscription}
        checkFieldsOnStepChange={[
          'subscription_number_of_packages',
          'subscription_start_date',
        ]}
        key="Subscription"
        title={t('Subscription')}
        loading={false}>
        <ClientSubscriptionForm
          editMode={editMode}
          isDisabledAllFields={!isSubscriptionEditable(subscription?.status!)}
        />
      </WizardFormV2.Step>
      <WizardFormV2.Step
        key="Payment"
        title={t('Payment')}
        loading={false}
        hidden={editMode}>
        <ClientPaymentForm
          isDisabledAllFields={!isSubscriptionEditable(subscription?.status!)}
        />
      </WizardFormV2.Step>
    </WizardFormV2>
  );
}
