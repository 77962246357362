import { useTranslation } from 'react-i18next';
import { Col, Input, Row, InputNumber, Form } from 'antd';
import React, { useCallback, useMemo, useRef } from 'react';
import { GROUP_SESSION_INITIAL_PARAM } from '@services/api/groupSessions';

import {
  WeekdayTimePickerForm,
  IDefaultFormProps,
  DefaultForm,
  DatePicker,
} from '@components/lib/General';

import {
  modifyWeekdays,
  curry,
  findIndex,
  split,
  greaterOrEqualThan,
  isString,
  FunctionArgs,
  isListToArray,
} from '@services/helpers';
import { useEmployeeList, useSize, useStoredCompanies } from '@hooks';
import { SearchSelect, Switch } from '@components/lib/DataDisplay';
import {
  EmployeeModel,
  EmployeeStatuses,
  ServiceGroupSessionFormDTO,
} from '@structure';
import { phoneScreen } from '@services/const';
import styled from 'styled-components';
import { useDropdownAlert } from '@contex';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export interface IGroupSessionFormProps
  extends Omit<
    IDefaultFormProps<ServiceGroupSessionFormDTO, ServiceGroupSessionFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  groupSession?: ServiceGroupSessionFormDTO;
  loading?: boolean;
  companyUuid: string;
}

/**
 * @desc Створенння форми сесії
 * */
export default function GroupSessionForm({
  loading,
  groupSession = GROUP_SESSION_INITIAL_PARAM,
  editMode,
  companyUuid,
  ...rest
}: IGroupSessionFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: groupSession?.uuid,
    }),
    [groupSession?.uuid],
  );

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const { defaultCompany } = useStoredCompanies();

  const { entityList: employees } = useEmployeeList({ companyUuid });

  const weekdays = useMemo(
    () => ({
      weekdays: isString(groupSession?.weekdays)
        ? split(',', groupSession?.weekdays)
        : groupSession?.weekdays,
    }),
    [groupSession?.weekdays],
  );

  /**
   * @desc Встановлення значень для поля - дні тижня
   * @param {string} day
   * @param {string} time
   * @param {string} checked
   * @return {Array}
   * */
  const onChangeDaysOfWeek = curry<any, any>(
    (
      { handlerUpdateFormState },
      { fieldValue, isFixedRange, name, time, ...rest }: any,
    ) => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];

      const index = findIndex<[FunctionArgs<any, boolean>], any>(
        (weekday: any): boolean => greaterOrEqualThan(weekday.indexOf(name), 0),
      )(weekdays);

      const updateWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time,
        ...rest,
      });

      handlerUpdateFormState({ weekdays: updateWeekRange });
    },
  );

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    layout: 'vertical',
  };

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Group session'),
        `${
          editMode
            ? t('An error occurred during edit group session')
            : t('An error occurred during create group session')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  return (
    <DefaultForm<any, any>
      {...formItemLayout}
      withContext
      isResetLoading={false}
      initialValues={{
        ...groupSession,
        ...weekdays,
      }}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      {...rest}>
      {({ loadingSubmit, handlerUpdateFormState }) => (
        <Row gutter={24} ref={contentRef}>
          <Col span={24}>
            <Form.Item label={t('Service')} name="service_title">
              <Input
                data-testid="session-input-service-title"
                className="information-field"
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Manager')}
              name="manager_id"
              rules={[
                {
                  required: true,
                  message: t('Services must be specified'),
                },
              ]}>
              <SearchSelect
                name="manager_id"
                disable={loading || loadingSubmit}
                placeholder={t('Search a Manager')}
                data={isListToArray(employees as any)}
                onChange={(e) => handlerUpdateFormState({ manager_id: e })}
                getOptionValueTitle="fullName"
                itemDisabled={(employee: EmployeeModel) =>
                  ![
                    EmployeeStatuses.Activated,
                    EmployeeStatuses.Confirmed,
                    EmployeeStatuses.Created,
                  ].includes(employee?.status)
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="title"
              tooltip={t('The title of the group session')}
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                  type: 'string',
                },
              ]}>
              <Input
                data-testid="session-input-title"
                disabled={loading}
                placeholder={t('Enter the session title')}
                onChange={(e) =>
                  handlerUpdateFormState({ title: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Price')}
              rules={[
                {
                  required: true,
                  message: t('Price must be specified.'),
                },
                {
                  transform: (value) => Number(value),
                  type: 'number',
                  message: t('Price must be a number.'),
                },
              ]}
              name="price">
              <Input
                autoComplete="off"
                addonBefore={defaultCompany?.currency_symbol}
                data-testid="service-input-price"
                style={{ width: '100%' }}
                disabled={loading}
                onChange={(e) =>
                  handlerUpdateFormState({ price: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={isFullWidth}>
            <Form.Item
              label={t('Start date')}
              name="start_date"
              tooltip={t('The date when a service`s session starts providing')}
              rules={[
                {
                  required: true,
                  message: t('Time must be specified.'),
                },
              ]}>
              <StyledDatePicker
                data-testid="session-input-start-time"
                disabled={loading}
                placeholder={t('Start date')}
                onChange={(e) => handlerUpdateFormState({ start_date: e })}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Days of the week')}
              tooltip={t('Days of week when a service is providing')}
              name="weekdays"
              rules={[
                {
                  required: true,
                  message: t('Days of the week and time must be specified.'),
                },
              ]}>
              <WeekdayTimePickerForm
                invert={false}
                isFixedRange={false}
                editMode={editMode}
                onChange={onChangeDaysOfWeek({ handlerUpdateFormState })}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Duration(hours)')}
              tooltip={t('How many hours this service session could take.')}
              name="duration"
              rules={[
                {
                  required: true,
                  message: t('Duration must be specified.'),
                },
                {
                  type: 'number',
                  min: 0.1,
                  max: 1000000,
                  message: t('Duration should be a correct amount of hours.'),
                },
              ]}>
              <InputNumber
                style={{ width: '100%' }}
                data-testid="session-input-duration"
                disabled={loading}
                placeholder={t('duration')}
                onChange={(e) => handlerUpdateFormState({ duration: e })}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('The amount of participants')}
              name="max_attenders"
              tooltip={t('The maximum amount of participants that is allowed.')}
              rules={[
                {
                  required: true,
                  message: t('Participants must be specified.'),
                },
                {
                  type: 'number',
                  min: 1,
                  message: t(
                    'Amount of participants should be a correct number.',
                  ),
                },
              ]}>
              <InputNumber
                style={{ width: '100%' }}
                data-testid="session-input-participants"
                disabled={loading}
                placeholder={t('Input the maximum number of participants')}
                onChange={(e) => handlerUpdateFormState({ max_attenders: e })}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Description')} name="description">
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 3, maxRows: 5 }}
                data-testid="session-input-description"
                disabled={loading}
                placeholder={t('Enter the session description')}
                onChange={(e) =>
                  handlerUpdateFormState({ description: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Status')}
              name="status"
              valuePropName="checked">
              <Switch
                data-testid="session-input-status"
                loading={loading}
                onChange={(e) => handlerUpdateFormState({ status: e })}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
