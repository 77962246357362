import { EmployeeModel, EmployeeStatuses } from '../internal';
import { GENDER, DateType, toDateByFormat, toMoment } from '@services/helpers';
import { DATE_SHORT_FORMAT } from '@services/const';

export class EmployeeAccessManagementFormDTO {
  public uuid: string;

  public status: EmployeeStatuses;

  public isAuthAllowed: boolean;

  constructor(props?: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.isAuthAllowed = !!props?.is_auth_allowed;
    this.status = props?.status || EmployeeStatuses.Disabled;
  }
}
