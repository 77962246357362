import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  StoreDocumentItemModel,
  InvoiceItemFormDTO,
  InvoiceMapper,
  InvoiceModel,
  StoreDocumentModel,
} from '@structure';
import { ENTER_KEYS } from '@services/const';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Col, Empty, Row } from 'antd';
import { useStateStoreDocumentItemList, useStoredCompanies } from '@hooks';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { TableIndexField } from '@components/lib/DataDisplay';
import styled, { css } from 'styled-components';
import { List } from 'immutable';
import { listToArray, isFunction, correctPrice } from '@services/helpers';
import { StoreDocItemsPriceDisabledView } from '../../Stores';
import { ProductDetailsView } from '../../Products';
import { InvoiceStoreRealizationTotalView } from '../Show';

import './InvoiceItemOrderForm.less';
import '../../Orders/List/OrderList.less';

export interface InvoiceItemStoreDocumentFormProps
  extends Omit<
    IDefaultFormProps<unknown, unknown>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  invoice: InvoiceModel;
  loading?: boolean;
  document: StoreDocumentModel;
  onSuccess: (value: InvoiceItemFormDTO[]) => Promise<void>;
}

const StyledAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  width: 100%;
`;

const StyledLineThroughTitle = styled(StyledTitle)`
  text-decoration: line-through;
`;

const StyledTotalContainer = styled.div<{
  $bold?: boolean;
  $isLineThrough: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 600;
    `}

  ${({ $isLineThrough }) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<StoreDocumentItemModel>>;

const StyledInvoiceStoreRealizationTotalView = styled(
  InvoiceStoreRealizationTotalView,
)`
  position: absolute;
  bottom: 72px;
  left: 0;
  right: 0;
  padding: 5px 10px 10px 5px;
  border-radius: 0;
  border-bottom-width: 0;
  line-height: 1.1;
`;

const StyledRow = styled(Row)`
  padding-bottom: 150px;
`;

export function InvoiceItemStoreDocumentForm({
  loading,
  editMode,
  document,
  invoice,
  onCancel,
  onSuccess,
  ...rest
}: InvoiceItemStoreDocumentFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompany } = useStoredCompanies();

  const [selectedStoreDocumentItems, setSelectedStoreDocumentItems] = useState<
    StoreDocumentItemModel[] | null
  >(null);

  const {
    storeDocumentItemList,
    loading: loadingStoreDocumentItemList,
    limit,
    total,
    page,

    refresh: refreshStoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: document?.uuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Items'),
        `${
          editMode
            ? t('An error occurred during edit item')
            : t('An error occurred during add items')
        } : ${apiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const onAmountEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLElement) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      return true;
    },

    [],
  );

  const handleSuccess = useCallback(async () => {
    const invoiceItems = (selectedStoreDocumentItems || []).map(
      (storeDocumentItem) =>
        InvoiceMapper.toInvoiceItemFormDTOFactory(storeDocumentItem, {
          withoutPartialFields: true,
          withoutUuid: true,
        }),
    );

    if (isFunction(onSuccess)) {
      await onSuccess(invoiceItems);
    }
  }, [onSuccess, selectedStoreDocumentItems]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: (selectedStoreDocumentItems || []).map(
        ({ uuid }) => uuid,
      ),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StoreDocumentItemModel[],
      ) => {
        setSelectedStoreDocumentItems(selectedRecords);
      },
    }),
    [selectedStoreDocumentItems],
  );

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (docItem: StoreDocumentItemModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      render: (docItem: StoreDocumentItemModel) => (
        <ProductDetailsView
          product={docItem?.product as any}
          item_product_returned_amount={docItem?.item_product_returned_amount}
          product_amount={docItem?.item_product_amount}
        />
      ),
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isReturnedAmount = returnedAmount > 0;

        const isLessThen = returnedAmount < docItem?.item_product_amount;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <StyledAmountContainer>
            {isReturnedAmount ? (
              <>
                {isLessThen ? (
                  <>
                    <StyledLineThroughTitle>
                      {docItem?.item_product_amount}
                    </StyledLineThroughTitle>
                    <StyledTitle>
                      {docItem?.item_product_amount - returnedAmount}
                    </StyledTitle>
                  </>
                ) : null}

                {isGrateOrEqualThen ? (
                  <StyledLineThroughTitle>
                    {docItem?.item_product_amount}
                  </StyledLineThroughTitle>
                ) : null}
              </>
            ) : (
              <StyledTitle>{docItem?.item_product_amount}</StyledTitle>
            )}
          </StyledAmountContainer>
        );
      },
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <StoreDocItemsPriceDisabledView
            docItem={docItem}
            isLineThrough={isGrateOrEqualThen}
          />
        );
      },
    },
    {
      title: `${t('Total')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'total',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <>
            <StyledTotalContainer $bold $isLineThrough={isGrateOrEqualThen}>
              {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                Number(docItem?.item_base_price || 0) *
                  (docItem?.item_product_amount || 1),
              )}`}
            </StyledTotalContainer>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      List.isList(storeDocumentItemList) &&
      selectedStoreDocumentItems === null
    ) {
      setSelectedStoreDocumentItems(listToArray(storeDocumentItemList));
    }
  }, [storeDocumentItemList, selectedStoreDocumentItems]);

  return (
    <>
      <DefaultForm<any, any>
        formKeyboardCodes={['Tab', ...ENTER_KEYS]}
        footerClassName="invoice-order-form"
        onEnterPress={onAmountEnter}
        className="invoice-item-form"
        formKeyboardEndSubmit
        withContext
        onSuccess={handleSuccess}
        editMode={editMode}
        submitButtonText={t('Add')}
        cancelButtonText={t('Close')}
        initialValues={{}}
        showNotify={false}
        onCancel={onCancel}
        notifyError={notifyError}
        {...rest}>
        <StyledRow gutter={12}>
          <Col span={24}>
            <StyledTable
              loading={loadingStoreDocumentItemList}
              dataSource={storeDocumentItemList}
              onChange={refreshStoreDocumentItemList}
              columns={columns}
              total={total}
              pageSize={limit}
              rowSelection={rowSelection}
              renderEmpty={
                <Empty
                  description={t(
                    "It looks like you don't have any products added right now",
                  )}
                />
              }
            />
          </Col>
        </StyledRow>
      </DefaultForm>
      <StyledInvoiceStoreRealizationTotalView document={document} />
    </>
  );
}
