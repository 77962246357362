import {
  IProductPrice,
  ProductBarCodeFormat,
  ProductCategoryFormDTO,
  ProductCategoryMapper,
  ProductModel,
} from '../internal';
import { correctPrice } from '@services/helpers';

export interface IProductFormAdditionalParams {
  editMode?: boolean;
  addAnotherProduct?: boolean;
  disabledCategory?: boolean;
  printPriceTag?: boolean;
  showSwitches?: boolean;
  showPriceTag?: boolean;
}

export class ProductFormDTO {
  public uuid: string;
  public title: string;
  public description: string;
  public net_price: string;
  public retail_price: string;
  public code: string;
  public bar_code: string;
  public vendor_code: string;
  public category_uuid: ProductCategoryFormDTO | string | undefined;
  public prices: IProductPrice[];
  public product_max_income_price?: number;
  public add_another_product?: boolean;
  public disabledCategory?: boolean;
  public bar_code_format: ProductBarCodeFormat;
  public min_amount: number;
  public print_price_tag?: boolean;
  public show_switches?: boolean;
  public show_price_tag?: boolean;

  constructor(
    props?: ProductModel,
    additionalParams?: IProductFormAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.product_title || '';
    this.description = props?.product_description || '';
    this.net_price = correctPrice(props?.product_net_price || 0);
    this.retail_price = correctPrice(props?.product_retail_price || 0);
    this.code = props?.product_code || '';
    this.bar_code = props?.product_barcode || '';
    this.vendor_code = props?.product_vendor_code || '';
    this.prices = props?.product_prices || [];
    this.bar_code_format =
      props?.product_bar_code_format || ProductBarCodeFormat.EAN13;
    this.product_max_income_price = props?.product_max_income_price || 0;
    this.min_amount = props?.product_min_amount || 0;
    this.add_another_product = !!additionalParams?.addAnotherProduct;
    this.print_price_tag = additionalParams?.printPriceTag ?? false;
    this.show_switches = additionalParams?.showSwitches ?? true;
    this.show_price_tag = additionalParams?.showPriceTag ?? true;

    this.category_uuid = additionalParams?.editMode
      ? props?.category?.uuid
        ? ProductCategoryMapper.toProductCategoryFormDTO(props?.categoryModel)
        : undefined
      : props?.category?.uuid || undefined;

    this.disabledCategory =
      !!this.category_uuid && !!additionalParams?.disabledCategory;
  }
}
