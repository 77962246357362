import { List } from 'immutable';
import {
  PaymentDTO,
  IPaymentDTOProps,
  PaymentFormDTO,
  PaymentModel,
  PaymentListModel,
  IPaymentScheduleAdditionalParams,
  PaymentScheduleFormDTO,
  IPaymentAdditionalProps,
  ICashBoxDTOProps,
  StoreDocumentModel,
} from '../internal';
import { listToArray } from '@services/helpers';
import { IPaymentScheduleListStats } from '@services/api/orders';

export interface IPaymentListDTO {
  payments: PaymentDTO[];
  total: number;
  stats: IPaymentScheduleListStats;
  document?: StoreDocumentModel;
}

export class PaymentMapper {
  public static toPaymentDTO(
    payment: PaymentModel | IPaymentDTOProps,
  ): PaymentDTO {
    return new PaymentDTO(payment);
  }

  public static toPaymentListDTO(
    payments: List<PaymentModel> | IPaymentDTOProps[],
    total?: number,
    stats?: IPaymentScheduleListStats,
    cashbox?: ICashBoxDTOProps,
    document?: StoreDocumentModel,
  ): IPaymentListDTO {
    const paymentList = List.isList(payments)
      ? listToArray<PaymentModel>(payments)
      : payments;

    return {
      payments: paymentList?.map((payment) =>
        PaymentMapper.toPaymentDTO({
          ...payment,
          company_cashbox: payment?.company_cashbox?.uuid
            ? payment?.company_cashbox
            : ((cashbox || {}) as any),
        }),
      ),
      total: total || 0,
      stats: stats || ({} as IPaymentScheduleListStats),
      document,
    };
  }

  public static toPaymentModel(paymentDTO: PaymentDTO): PaymentModel {
    return new PaymentModel(paymentDTO);
  }

  public static toPaymentListModel(
    paymentDTOs: PaymentDTO[],
    total?: number,
    document?: StoreDocumentModel,
  ): PaymentListModel {
    return new PaymentListModel({ payments: paymentDTOs, total, document });
  }

  public static toPaymentFormDTO(
    paymentModel: PaymentModel,
    paymentAdditionalProps?: IPaymentAdditionalProps,
  ): PaymentFormDTO {
    return new PaymentFormDTO(paymentModel, paymentAdditionalProps);
  }

  public static toPaymentScheduleFormDTO(
    paymentModel: PaymentModel,
    additionalParams: IPaymentScheduleAdditionalParams,
  ): PaymentScheduleFormDTO {
    return new PaymentScheduleFormDTO(paymentModel, additionalParams);
  }
}
