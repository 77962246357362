import React from 'react';
import { useTranslation } from 'react-i18next';
import { SideWindow } from '@components/lib/Navigation';
import { StoreFormDTO } from '@structure';
import { StoreForm, IStoreFormProps } from '../Forms';

export interface IStoreSideWindowProps extends IStoreFormProps {
  onSuccess: (value: StoreFormDTO) => Promise<void>;
  visible: boolean;
}

export default function StoreSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  ...rest
}: IStoreSideWindowProps) {
  const { t } = useTranslation();

  return (
    <SideWindow title={t('Stores')} visible={visible} onClose={onCancel}>
      <StoreForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </SideWindow>
  );
}
