import { Record, List } from 'immutable';
import { IListProps } from './List';
import { IntegrationDTO, IntegrationModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IIntegrationListModelProps extends IListProps {
  integrations: IntegrationDTO[];
  keywords?: string;
  page?: number;
}

export interface IIntegrationListModelReturnType extends IListProps {
  integrations: List<IntegrationModel>;
  keywords?: string;
  page?: number;
}

export class IntegrationListModel extends Record<IIntegrationListModelReturnType>(
  {
    integrations: List(),
    total: 0,
    keywords: '',
    page: 1,
  },
) {
  constructor(
    {
      integrations = [],
      ...props
    }: IIntegrationListModelProps = {} as IIntegrationListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IIntegrationListModelReturnType = {
      ...props,
      integrations: List(
        integrations.map(
          (integration) => new IntegrationModel(integration, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
