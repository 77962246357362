import React, { useCallback, useEffect, useState } from 'react';
import {
  systemSubscriptions,
  EPlanTypeIdentifier,
  PLANS_TRIAL,
} from '@services/helpers';
import { SubscriptionTariffModelListItem } from './SubscriptionTariffModelListItem';
import styled from 'styled-components';
import { PlanModel } from '@structure';
import { IUseStatePlanListReturnType } from '@hooks';

export interface ISubscriptionTariffModelListProps
  extends Pick<
    IUseStatePlanListReturnType,
    'handleSubscriptionActivate' | 'handleSubscriptionTransition'
  > {
  plan: PlanModel | null;
  disabled: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  width: 100%;
  height: 100%;
`;

export function SubscriptionTariffModelList({
  plan,
  handleSubscriptionTransition,
  handleSubscriptionActivate,
  disabled,
}: ISubscriptionTariffModelListProps): JSX.Element {
  const activeSubscriptionId = plan?.identifier;

  const [active, setActive] = useState(activeSubscriptionId);

  const handleActivate = useCallback((id: EPlanTypeIdentifier) => {
    setActive(id);
  }, []);

  useEffect(() => {
    setActive(activeSubscriptionId);
  }, [activeSubscriptionId]);

  return (
    <StyledContainer>
      {systemSubscriptions
        .filter(({ id }) => id !== PLANS_TRIAL)
        .map((systemSubscription) => (
          <SubscriptionTariffModelListItem
            disabled={disabled}
            key={systemSubscription?.id}
            activePlan={activeSubscriptionId as EPlanTypeIdentifier}
            active={active === systemSubscription?.id}
            systemSubscription={systemSubscription}
            plan={plan}
            handleActivate={handleActivate}
            handleSubscriptionTransition={handleSubscriptionTransition}
            handleSubscriptionActivate={handleSubscriptionActivate}
          />
        ))}
    </StyledContainer>
  );
}
