import { List, Record } from 'immutable';
import { GENDER } from '@services/helpers';
import {
  ClientDiscountType,
  ClientDTO,
  ClientGroupSessionMapper,
  ClientGroupSessionModel,
  ClientMapper,
  ClientOrgType,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import { WithoutRecursion } from '../type';
import { ClientPersonalityType } from '@services/types';

export interface IClientModelReturnType {
  id: string;
  uuid: string;
  status: number;
  first_name: string;
  client_group_name: string;
  last_name: string;
  client_strict_mode: boolean;
  middle_name: string;
  client_type: ClientPersonalityType;
  phone: string;
  email: string;
  saldo: string;
  picture_url: string;
  address: string;
  birthday: string;
  gender: GENDER;
  invitation: any[];
  created_at: string;
  updated_at: string;
  profile: ProfileModel | ProfileDTO;
  created_by: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
  clients_group_sessions: List<ClientGroupSessionModel>;
  members: ClientDTO[] | List<ClientModel>;
  client_org_type: ClientOrgType;
  client_org_name: string;
  client_org_code: string;
  client_org_tax_code: string;
  client_org_address: string;
  client_org_contact_person: string;
  client_org_manager: string;
  client_org_bank_name: string;
  client_org_bank_code: string;
  client_org_bank_account_id: string;
  client_is_provisioner: boolean;
  client_is_conflict: boolean;
  client_is_tav_payer: boolean;
  client_discount: string;
  client_discount_goods?: string;
  client_discount_type: ClientDiscountType;
  client_discount_goods_type: ClientDiscountType;
  client_comment: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class ClientModel extends Record<IClientModelReturnType>({
  id: '',
  uuid: '',
  status: 0,
  first_name: '',
  last_name: '',
  middle_name: '',
  client_strict_mode: false,
  client_group_name: '',
  phone: '',
  email: '',
  picture_url: '',
  address: '',
  birthday: '',
  gender: GENDER.MALE,
  client_type: ClientPersonalityType.Individual,
  invitation: [],
  saldo: '0.00',
  created_at: '',
  updated_at: '',
  profile: {} as ProfileModel,
  company: {} as CompanyModel,
  created_by: {} as EmployeeModel,
  clients_group_sessions: List(),
  members: List(),
  client_org_type: ClientOrgType.PERSON,
  client_org_name: '',
  client_org_code: '',
  client_org_contact_person: '',
  client_org_tax_code: '',
  client_org_address: '',
  client_org_manager: '',
  client_org_bank_name: '',
  client_org_bank_code: '',
  client_org_bank_account_id: '',
  client_is_provisioner: false,
  client_is_conflict: false,
  client_is_tav_payer: false,
  client_discount: '0',
  client_discount_type: ClientDiscountType.Fixed,
  client_discount_goods: '0',
  client_discount_goods_type: ClientDiscountType.Fixed,
  client_comment: '',
}) {
  constructor(
    props: ClientDTO = {} as ClientDTO,
    withoutMembers: boolean,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientModelReturnType = {
      ...props,
      clients_group_sessions:
        ClientGroupSessionMapper.toClientGroupSessionListModel(
          props?.clients_group_sessions || [],
        ).clients_group_sessions,

      // @ts-ignore
      profile:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.profile
          : ProfileMapper.toProfileModel(props?.profile as ProfileDTO, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company as CompanyDTO, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),

      members: withoutMembers
        ? []
        : withoutRecursion.indexOf(WithoutRecursion.client) !== -1
        ? props?.members
        : ClientMapper.toClientListModel(
            props?.members,
            props?.members?.length || 0,
            true,
            [WithoutRecursion.profile, ...withoutRecursion],
          ).clients,
    };

    super(options);
  }

  /**
   * @desc Повне Ім'я
   * @return {String}
   */

  get profileModel(): ProfileModel {
    return this.profile instanceof ProfileModel
      ? this.profile
      : ProfileMapper.toProfileModel(this.profile);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }
  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }

  get memberListModel(): List<ClientModel> {
    return List.isList(this.members)
      ? this.members
      : ClientMapper.toClientListModel(
          this?.members,
          this?.members?.length || 0,
          true,
        ).clients;
  }

  get fullNameClient(): string {
    return this?.client_org_type === ClientOrgType.PERSON
      ? `${this.last_name} ${this.first_name}`
      : this.client_org_name;
  }
}
