import { Button } from 'antd';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import {Button} from '../General';

import './BottomPopup.less';
export interface IBottomPopupProps {
  visible: boolean;
  handleClose: () => void;
  handleNavigate: () => void;
  title?: string;
  message?: string;
  buttonTitle?: string;
}

const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

export function BottomPopup({
  visible,
  handleClose,
  handleNavigate,
  buttonTitle,
  title,
  message,
}: IBottomPopupProps): JSX.Element {
  const { t } = useTranslation();

  const handleCloseBottom = useCallback(() => {
    const bottomPopup = document.querySelector('.bottom-popup-message');

    if (bottomPopup) {
      bottomPopup.classList.remove('bottom-popup-message__content-animation');
      bottomPopup.classList.add(
        'bottom-popup-message__content-animation--reverse',
      );
    }

    setTimeout(handleClose, 1400);
  }, [handleClose]);

  return (
    <>
      {visible ? (
        <div className="bottom-popup-message" id="bottom-popup">
          <div className="bottom-popup-message__content bottom-popup-message__content-animation">
            <p className="bottom-popup-message__title">
              {title || t('Login failed')}
            </p>
            <span
              onKeyDown={() => {}}
              tabIndex={0}
              role="button"
              className="bottom-popup-message__button--close"
              onClick={handleCloseBottom}>
              &times;
            </span>

            <p className="bottom-popup-message__text">
              {message ||
                t(
                  "The current User's subscription has expired. Go to the Console and roll over your subscription.",
                )}
            </p>

            <StyledButton
              data-testid="login-form__button--submit"
              type="primary"
              onClick={handleNavigate}
              block>
              {buttonTitle || t('Login to console')}
            </StyledButton>
          </div>
        </div>
      ) : null}
    </>
  );
}
