import React, { forwardRef } from 'react';

import './CalendarWrapper.less';

export interface ICalendarWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  style?: any;
}

export default forwardRef(
  ({ children, style }: ICalendarWrapperProps, ref: any): JSX.Element => (
    <div ref={ref} className="calendar-wrapper" style={style || {}}>
      {children}
    </div>
  ),
);
