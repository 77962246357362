import { SignupForm } from '../Forms';
import { useStateSignup } from '@hooks';
import { Routes } from '@services/types';
import { useDropdownAlert } from '@contex';
import AuthLayout from '../../Show/AuthLayout';
import { AuthPageWrapper } from '../../common';
import { ISignupPageState } from '@store/actions';
import { WarningNavigateButton } from '../../Buttons';
import { useLocation, useNavigate } from 'react-router';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { SignupFormMapper, SignupFormDTO } from '@structure';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useAuthLayout } from '@components/lib/Layout/AuthLayout';
import React, { useCallback, useEffect, useLayoutEffect } from 'react';

export default withTranslation()(SignupPage);

export interface ISignupPageProps extends WithTranslation {}

function SignupPage({ t }: ISignupPageProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    identifier,
    loading,
    approveEnter,
    isShowWarning,

    handleSignup,
    initStateFactory,
    navigateToVerificationFormModal,
    updateStateFactory,
    notifyError,
  } = useStateSignup<ISignupPageState>({
    key: Routes.signup,
  });

  const { alert } = useDropdownAlert();
  const { setOptions } = useAuthLayout();

  const isApproveEnter = typeof approveEnter === 'boolean' && !approveEnter;

  const navigateToApproveEnterScreen = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.approveEnter}`, {
      state: {
        from: location.pathname,
        prevState: location.state,
      },
    });
  }, [location.pathname, location.state, navigate]);

  const navigationFactory = useCallback(() => {
    if (isApproveEnter) {
      return navigateToApproveEnterScreen();
    }

    return navigateToVerificationFormModal();
  }, [
    isApproveEnter,
    navigateToApproveEnterScreen,
    navigateToVerificationFormModal,
  ]);

  const handleShowWarning = useCallback(
    (isShowWarning = true) => {
      updateStateFactory({ isShowWarning });
    },
    [updateStateFactory],
  );

  const navigationGoBack = useCallback(() => {
    navigate(location?.state?.from || `/${Routes.auth}/${Routes.authOffice}`);
    handleShowWarning();
  }, [handleShowWarning, location?.state, navigate]);

  useLayoutEffect(() => {
    setOptions({
      pathname: location.pathname,
      headerLeft: () => <HeaderLeft onClick={navigationGoBack} />,
    });
  }, [location.pathname, navigationGoBack, setOptions]);

  useEffect(() => {
    if (isShowWarning && identifier) {
      alert(
        'warn',
        t('You have an incomplete registration'),
        t('Complete the registration'),
      );
      handleShowWarning(false);
    }
  }, [
    handleShowWarning,
    identifier,
    isShowWarning,
    alert,
    t,
    updateStateFactory,
  ]);

  useEffect(() => {
    if (loading) {
      initStateFactory();
    }
  }, [initStateFactory, loading]);

  return (
    <AuthLayout>
      <>
        <AuthPageWrapper
          title={t('Registration')}
          description={t(
            'In order to start using CRM .ServicitY you have to register new account.',
          )}>
          <SignupForm
            showNotify={false}
            notifyError={notifyError}
            notifyFormName={t('Authorization')}
            signup={SignupFormMapper.toSignupFormDTO(new SignupFormDTO())}
            onSuccess={handleSignup}
          />
          {identifier ? (
            <WarningNavigateButton
              mode="dark"
              onClick={navigationFactory}
              title={
                isApproveEnter
                  ? t('Checked status')
                  : t('Complete preliminary registration')
              }
            />
          ) : null}
        </AuthPageWrapper>
      </>
    </AuthLayout>
  );
}
