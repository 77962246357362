import React, { ChangeEvent, useCallback } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { StyledDescription } from '@components/lib/Styled';
import { DatePicker } from '@components/lib/General';
import styled from 'styled-components';
import { useDefaultForm } from '@contex';
import { useStoredCompanies } from '@hooks';
import { useTranslation } from 'react-i18next';
import { Segmented } from '@components/lib/DataDisplay';
import { FROM_PAYMENT_METHOD } from '@services/api/companyPayments';
import { OPERATION_TYPE } from '@services/types';
import { correctPrice } from '@services/helpers';

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export function ClientAbonementSubscriptionFields() {
  const { t } = useTranslation();
  const { loadingSubmit, handlerUpdateFormState, formData } = useDefaultForm();
  const { defaultCompany } = useStoredCompanies();

  const handleChangePrice = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[a-zA-Z]+/g, '');

      handlerUpdateFormState({
        amount: value,
      });
    },
    [handlerUpdateFormState],
  );

  const handleChangePeriod = useCallback(
    (value: any) => {
      const price = Number(formData?.price || 0);

      handlerUpdateFormState({
        amount:
          Number(value) && value > 0
            ? correctPrice(price * Number(value))
            : correctPrice(price),
        packages: value,
      });
    },
    [formData?.price, handlerUpdateFormState],
  );

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Payment type')}
          name="payment_type"
          rules={[
            {
              required: true,
              message: t('Subscription type must be specified'),
            },
          ]}
          tooltip={{
            title: t('Payment type'),
          }}>
          <Segmented
            options={FROM_PAYMENT_METHOD(OPERATION_TYPE.IN)}
            disabled={loadingSubmit}
            onChange={(payment_type) =>
              handlerUpdateFormState({ payment_type })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Start date')}
          label={t('Start date')}
          name="start_date"
          rules={[
            {
              required: true,
              message: t('Start date must be specified.'),
              type: 'date',
            },
          ]}
          extra={
            <StyledDescription>{t('Abonement start date')}</StyledDescription>
          }>
          <DatePicker
            disabled={loadingSubmit}
            placeholder={t('Start date')}
            onChange={(start_date) => handlerUpdateFormState({ start_date })}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Payment date')}
          label={t('Payment date')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('Subscription date must be specified.'),
              type: 'date',
            },
          ]}>
          <DatePicker
            disabled={loadingSubmit}
            placeholder={t('Payment date')}
            onChange={(payment_date) =>
              handlerUpdateFormState({ payment_date })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Packages')}
          label={t('Packages')}
          name="packages"
          rules={[
            () => ({
              validator(_, packages) {
                if (packages === null) {
                  return Promise.reject(
                    new Error(t('Packages must be specified.')),
                  );
                }

                if (Number(packages) < 1) {
                  return Promise.reject(
                    new Error(t('The packages must be greater than 0')),
                  );
                }

                if (Number(packages) > 100) {
                  return Promise.reject(
                    new Error(t('The packages must be less than 100')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <StyledDescription>
              {t(
                'The number of connected subscription packages in this transaction',
              )}
            </StyledDescription>
          }>
          <FullWidthInputNumber
            type="number"
            data-testid="service-input-duration"
            disabled={loadingSubmit}
            onChange={handleChangePeriod}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Amount')}
          name="amount"
          rules={[
            () => ({
              validator(_, price) {
                const value = price.replace(/[a-zA-Z]+/g, '');

                if (!Number(value)) {
                  return Promise.reject(
                    new Error(t('The amount must be a number')),
                  );
                }

                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(t('The amount must be greater than 0')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <StyledDescription>
              {`${t('Base cost')}: ${defaultCompany?.currency_symbol} ${
                formData?.price
              }`}
            </StyledDescription>
          }>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit}
            onChange={handleChangePrice}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
