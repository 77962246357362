import * as React from 'react';
import { ClientSettlementType } from '@structure';
import { LocalStorageItems } from '@services/const';
import { ListFilterManagerProps } from '../../common/Managers';
import { ClientPersonalityType } from '@services/types';
import { useStateReducer } from '@components/lib/libV2/hooks';

import {
  CompanyClientFilter,
  DEFAULT_CLIENT_FILTER,
} from '@services/api/company';

export interface IClientListFilterManagerProps extends ListFilterManagerProps {}

export interface IClientListFilterManagerContext extends CompanyClientFilter {
  handleUpdateClientListFilter: (value: UpdateClientList) => void;
  handleApplyClientListFilters: () => void;
  handleResetClientListFilters: () => void;
  handleCancelChangeClientListFilter: () => void;
  loading: boolean;
  count: number;
}

export type UpdateClientList = Partial<
  CompanyClientFilter &
    Pick<IClientListFilterManagerContext, 'loading' | 'count'>
>;

export const ClientListFilterManagerContext =
  React.createContext<IClientListFilterManagerContext>({
    provisioner_only: false,
    settlement: ClientSettlementType.ALL,
    client_type: ClientPersonalityType.Individual,
    handleUpdateClientListFilter: (value: UpdateClientList) => {},
    handleApplyClientListFilters: () => {},
    handleResetClientListFilters: () => {},
    handleCancelChangeClientListFilter: () => {},
    loading: false,
    count: 0,
  });

export const useClientListFilter = () =>
  React.useContext<IClientListFilterManagerContext>(
    ClientListFilterManagerContext,
  );

export function ClientListFilterManager({
  children,
}: IClientListFilterManagerProps): React.JSX.Element {
  const {
    provisioner_only,
    settlement,
    client_type,
    loading,
    count,

    handleUpdate,
  } = useStateReducer<UpdateClientList>({
    provisioner_only: false,
    settlement: ClientSettlementType.ALL,
    client_type: ClientPersonalityType.Individual,
    loading: true,
    count: 0,
  });

  const getCount = React.useCallback(
    (value: UpdateClientList) => {
      const count = Object.entries(value)
        .filter(
          (value: [string, any]) =>
            value?.length > 0 && value[0] !== 'client_type',
        )
        .reduce((acc, [_, value]) => {
          acc += Number(!!value);

          return acc;
        }, 0);

      handleUpdate({ count });
    },
    [handleUpdate],
  );

  const handleUpdateClientListFilter = React.useCallback(
    (value: UpdateClientList) => {
      handleUpdate((prevState) => {
        return { ...prevState, ...value };
      });
    },
    [handleUpdate],
  );

  const handleApplyClientListFilters = React.useCallback(() => {
    const clientFilters = {
      provisioner_only,
      settlement,
      client_type,
    };

    const serializationClientFilter = JSON.stringify(clientFilters);

    localStorage.setItem(
      LocalStorageItems.clientListFilter,
      serializationClientFilter,
    );

    getCount({ provisioner_only, settlement, client_type });
  }, [provisioner_only, settlement, client_type, getCount]);

  const handleResetClientListFilters = React.useCallback(() => {
    handleUpdate(DEFAULT_CLIENT_FILTER);

    const serializationClientFilter = JSON.stringify(DEFAULT_CLIENT_FILTER);

    localStorage.setItem(
      LocalStorageItems.clientListFilter,
      serializationClientFilter,
    );

    handleUpdate({ count: 0 });
  }, [handleUpdate]);

  const handleCancelChangeClientListFilter = React.useCallback(() => {
    const serializationClientFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.clientListFilter) || '{}',
    );

    const filters: any = {
      ...DEFAULT_CLIENT_FILTER,
      ...serializationClientFilter,
    };

    handleUpdate((prevState) => ({ ...prevState, ...filters }));
  }, [handleUpdate]);

  React.useEffect(() => {
    const serializationClientFilter: CompanyClientFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.clientListFilter) || '{}',
    );

    const { count = 0, ...filters } = {
      ...DEFAULT_CLIENT_FILTER,
      ...serializationClientFilter,
    };

    const state = { ...filters, count, loading: false };

    handleUpdateClientListFilter(state);

    getCount(filters);
  }, [getCount, handleUpdateClientListFilter]);

  const value: IClientListFilterManagerContext = React.useMemo(
    (): any => ({
      provisioner_only,
      settlement,
      client_type,
      loading,
      count,

      handleUpdateClientListFilter,
      handleApplyClientListFilters,
      handleResetClientListFilters,
      handleCancelChangeClientListFilter,
    }),
    [
      provisioner_only,
      settlement,
      client_type,
      loading,
      count,

      handleUpdateClientListFilter,
      handleApplyClientListFilters,
      handleResetClientListFilters,
      handleCancelChangeClientListFilter,
    ],
  );

  return (
    <ClientListFilterManagerContext.Provider value={value}>
      {children}
    </ClientListFilterManagerContext.Provider>
  );
}
