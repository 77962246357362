import React, { useState } from 'react';
import { ScheduleCalendarModel } from '@structure';
import ClientCalendarPopover from './ClientCalendarPopover';
import { CalendarManager, MODE } from '@contex';
import {
  IUseStateClientAbonementScheduleListReturnType,
  useDefaultCalendarScheduleProps,
  useStoredCompanies,
} from '@hooks';
import { ClientAbonementScheduleWeek } from './ClientAbonementScheduleWeek';
import { Route } from '@components/lib/DataDisplay';
import moment from 'moment';

export interface IClientAbonementScheduleViewProps
  extends Pick<
    IUseStateClientAbonementScheduleListReturnType,
    'scheduleList' | 'handleRefreshClientAbonementSchedules'
  > {
  schedulesLoading: boolean;
  isStartRefreshSchedules: boolean;
  disabled: boolean;
  routes: Route[];
}

export function ClientAbonementScheduleView({
  scheduleList,
  schedulesLoading,
  isStartRefreshSchedules,
  handleRefreshClientAbonementSchedules,
  disabled,
  routes,
}: IClientAbonementScheduleViewProps): JSX.Element {
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const { defaultCompany } = useStoredCompanies();

  const [date, setDate] = useState<any>(moment());

  return (
    <CalendarManager<ScheduleCalendarModel>
      {...defaultCalendarProps}
      withoutStorageMode
      mode={MODE.WEEK}
      schedules={scheduleList as any}
      localStorageName=""
      waitStartRefresh={isStartRefreshSchedules}
      onRefresh={handleRefreshClientAbonementSchedules}
      withoutStorageDate={date}
      SchedulePopover={ClientCalendarPopover}
      scheduleDetails={() => <></>}
      customDayTimeRange={defaultCompany && defaultCompany?.work_schedules}>
      <ClientAbonementScheduleWeek
        scheduleLoading={schedulesLoading}
        setDate={setDate}
        disabled={disabled}
      />
    </CalendarManager>
  );
}
