import * as React from 'react';
import { EventEmitter, isThereContent } from '@services/helpers';
import { useUser } from './useUser';
import useStoredCompanies from './useStoredCompanies';
import useStoredProfile from './useStoredProfile';
import { tokenHandler } from '@services/core/token';
import { Routes } from '@services/types';
import {
  ActiveSubscriptionMapper,
  ActiveSubscriptionModel,
  CompanyMapper,
  CompanySettingsDTO,
  EmployeeRole,
  ICompanySettingsDTOProps,
  ProfileModel,
} from '@structure';
import { useStoredAuthData } from './useStoredAuthData';
import { useNavigate } from 'react-router';
import { getCompanySettings } from '@services/api/setting';
import { EventEmitterItems, LocalStorageItems } from '@services//const';
import { useTranslation } from 'react-i18next';
import { useStoredPlanList } from './useStoredPlanList';
import { useRef } from 'react';

export interface IStoredCompaniesReturnedProps {
  loading: boolean;
}

export default function useStoreAppState(): IStoredCompaniesReturnedProps {
  const navigate = useNavigate();
  const { resetAuthData } = useStoredAuthData();
  const { t } = useTranslation();
  const once = useRef(false);

  const access_token = tokenHandler.getAccessToken();

  const navigateToAuth = React.useCallback(() => {
    tokenHandler.destroyToken();
    resetAuthData();
    navigate(`/${Routes.auth}/${Routes.authOffice}`);
  }, [navigate, resetAuthData]);

  const navigateToDashboard = React.useCallback(() => {
    // navigate(`/${Routes.app}/${Routes.dashboard}`);
  }, []);

  React.useEffect(() => {
    if (!access_token) {
      navigateToAuth();
    }
  }, [access_token, navigateToAuth]);

  const { storeCompanies, setDefaultCompany } = useStoredCompanies();

  const { storeProfile, profile } = useStoredProfile();
  const { settings: storedSettings } = useStoredCompanies({ companyUuid: '' });
  const { planList: storedPlanList, handleRefreshPlans } = useStoredPlanList({
    loadOnInit: false,
  });

  const { refresh, loading } = useUser({
    loadingOnInit: false,
  });

  const storeAppData = React.useCallback(
    async (profile: ProfileModel, settings: CompanySettingsDTO) => {
      storeProfile(profile);
      storeCompanies({
        companies: profile?.companyListModel,
        defaultCompany: profile?.defaultCompanyModel,
        settings,
      });

      document.title = `.ServicitY - [ ${
        profile?.defaultCompanyModel?.title
      } ]: ${t('Corporate accounting system')}`;
    },
    [storeCompanies, storeProfile, t],
  );

  const appRedirect = React.useCallback(
    async (profile: ProfileModel) => {
      if (profile?.uuid) {
        if (!profile?.default_company?.uuid && profile) {
          const companySize = profile?.companyListModel?.size;

          if (companySize) {
            if (companySize === 1) {
              const defaultCompanyModel = profile?.companyListModel?.first();

              if (defaultCompanyModel) {
                const answer = await setDefaultCompany({
                  default_company: defaultCompanyModel?.uuid,
                });

                if (answer?.profile && answer?.settings) {
                  await storeAppData(answer.profile, answer.settings);
                  navigateToDashboard();
                }
              }
              return;
            }

            // (navigation as any).navigate(Routes.CompanyListNavigatorSelect, {
            //   screens: Routes.CompanyListScreen,
            //   params: {
            //     asSelect: true,
            //   },
            // });

            return;
          }

          navigate(`/${Routes.app}/${Routes.companies}/create`);

          return;
        }

        if (storedSettings) {
          await storeAppData(profile, storedSettings);
        } else {
          let settings = CompanyMapper.toCompanySettingsDTO(
            {} as ICompanySettingsDTOProps,
          );

          if (
            profile?.defaultEmployeeModel?.role === EmployeeRole.Administrator
          ) {
            settings = await getCompanySettings(
              profile?.defaultCompanyModel?.uuid,
            );
          }

          await storeAppData(profile, settings);
        }

        resetAuthData();

        const isShowAppIntro = localStorage.getItem(
          LocalStorageItems.isShowAppIntro,
        );

        if (!storedPlanList && !once.current) {
          once.current = true;
          const plans = await handleRefreshPlans();
          if (plans) {
            const plan = plans?.first();

            if (plan?.isSubscriptionDateExpired) {
              navigate(`/${Routes.app}/${Routes.subscriptionExpired}`);
              return;
            }
          }
        }

        if (!isShowAppIntro) {
          navigate(`/${Routes.app}/${Routes.appIntro}`);
          return;
        }

        navigateToDashboard();
      } else {
        navigateToAuth();
      }
    },
    [
      storedSettings,
      resetAuthData,
      storedPlanList,
      navigateToDashboard,
      navigate,
      setDefaultCompany,
      storeAppData,
      handleRefreshPlans,
      navigateToAuth,
    ],
  );

  React.useEffect(() => {
    (async () => {
      if (!isThereContent(profile)) {
        const profileModel = await refresh(true);
        if (profileModel) {
          await appRedirect(profileModel);
        }
      } else {
        await appRedirect(profile);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, refresh]);

  React.useEffect(() => {
    EventEmitter.clear(EventEmitterItems.app_navigate_to_access_denied_page);

    EventEmitter.on(
      EventEmitterItems.app_navigate_to_access_denied_page,
      (pathName: string, accessDeniedPageMessage: string) => {
        const isApp = pathName.includes(Routes.app);

        if (isApp) {
          navigate(`/${Routes.app}/${Routes.accessDenied}`, {
            state: { accessDeniedPageMessage },
          });
        }
      },
    );
  }, [navigate]);

  return {
    loading: isThereContent(profile) ? false : loading,
  };
}
