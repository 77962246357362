import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from '../core/api';
import { head } from '../helpers';
import { ApiAnswer, IListSearchProps } from '../types';
import {
  AbonementDTO,
  AbonementFormDTO,
  AbonementMapper,
  AbonementStatuses,
  IAbonementDTOProps,
  IAbonementListDTO,
  MaxPausedPeriodType,
} from '@structure';
import { contentApiUrl } from '../const';

export const ABONEMENT_INITIAL_PARAM = new AbonementFormDTO();

const {
  uuid,
  company,
  updated_at,
  created_at,
  abon_status,
  abon_status_text,
  ...ABONEMENT_INITIAL_PARAM_REST
} = new AbonementDTO({} as IAbonementDTOProps);

export const ABONEMENT_REQUIRED_FIELD = {
  ...ABONEMENT_INITIAL_PARAM_REST,
};

export interface IAbonementAnswer extends ApiAnswer {
  abonement: IAbonementDTOProps;
}

export interface IAbonementListAnswer extends ApiAnswer {
  abonements: IAbonementDTOProps[];
}

export interface ICompanyAbonementListAnswer
  extends ApiAnswer,
    IListSearchProps {
  abonements: IAbonementDTOProps[];
}

export interface ICompanyAbonementListProps extends IListSearchProps {
  companyUuid: string;
}

export const MAX_PAUSED_PERIOD_TYPE = [
  {
    title: 'Day',
    uuid: MaxPausedPeriodType.Day,
  },
  {
    title: 'Week',
    uuid: MaxPausedPeriodType.Week,
  },
  {
    title: 'Month',
    uuid: MaxPausedPeriodType.Month,
  },
];

export async function getAbonementList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: ICompanyAbonementListProps): Promise<IAbonementListDTO> {
  const { abonements, count } = await apiGet<
    IListSearchProps,
    IAbonementListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/abonements`, {
    offset,
    limit,
    keywords,
  });

  return AbonementMapper.toAbonementListDTO(abonements, count || 0);
}

export async function getAbonementById(
  abonementUuid: string,
): Promise<AbonementDTO> {
  const { abonement } = await apiGet<null, IAbonementAnswer>(
    `${contentApiUrl}/abonements/${abonementUuid}`,
  );

  return AbonementMapper.toAbonementDTO(abonement);
}

export async function createAbonement(
  {
    title,
    service_uuid,
    period_amount,
    price,
    can_timing_be_limited,
    timing,
    can_visiting_be_limited,
    visiting_limit,
    can_move_unused_visiting,
    manager_required,
    manager_uuid,
    comment,
    can_be_paused,
    max_paused_period,
    can_refund_money,
    group_schedule,
    max_paused_period_type,
    session_price,
  }: AbonementFormDTO,
  companyUuid: string,
): Promise<AbonementDTO> {
  const { abonement } = await apiPost<
    Omit<AbonementFormDTO, 'uuid'>,
    IAbonementAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/abonements`, {
    title,
    service_uuid,
    period_amount,
    price,
    can_timing_be_limited,
    timing,
    can_visiting_be_limited,
    visiting_limit,
    can_move_unused_visiting,
    manager_required,
    manager_uuid,
    comment,
    can_be_paused,
    max_paused_period,
    can_refund_money,
    group_schedule,
    max_paused_period_type,
    session_price,
  });

  return AbonementMapper.toAbonementDTO(abonement);
}

export async function editAbonement({
  uuid: abonement_uuid,
  title,
  service_uuid,
  period_amount,
  price,
  can_timing_be_limited,
  timing,
  can_visiting_be_limited,
  visiting_limit,
  can_move_unused_visiting,
  manager_required,
  manager_uuid,
  comment,
  can_be_paused,
  max_paused_period,
  can_refund_money,
  group_schedule,
  max_paused_period_type,
  session_price,
}: Omit<AbonementFormDTO, 'getTimingList'>): Promise<AbonementDTO> {
  const { abonement } = await apiPut<
    Omit<AbonementFormDTO, 'uuid'>,
    IAbonementAnswer
  >(`${contentApiUrl}/abonements/${abonement_uuid}`, {
    title,
    service_uuid,
    period_amount,
    price,
    can_timing_be_limited,
    timing,
    can_visiting_be_limited,
    visiting_limit,
    can_move_unused_visiting,
    manager_required,
    manager_uuid,
    comment,
    can_be_paused,
    max_paused_period,
    can_refund_money,
    group_schedule,
    max_paused_period_type,
    session_price,
  });

  return AbonementMapper.toAbonementDTO(abonement);
}

export async function deleteAbonement(
  ids: string[] = [],
): Promise<IAbonementListDTO> {
  const { abonements } = await apiDelete<
    { ids: string[] },
    ICompanyAbonementListAnswer
  >(`${contentApiUrl}/abonements/${head(ids)}`);

  return AbonementMapper.toAbonementListDTO(abonements, 0);
}

export async function changeAbonementStatus(
  abonementUuid: string,
  status: AbonementStatuses,
): Promise<AbonementDTO> {
  const { abonement } = await apiPatch<
    { status: AbonementStatuses },
    IAbonementAnswer
  >(`${contentApiUrl}/abonements/${abonementUuid}/status`, {
    status,
  });

  return AbonementMapper.toAbonementDTO(abonement);
}
