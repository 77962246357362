import * as React from 'react';
import { SetStateAction, useMemo } from 'react';
import { List } from 'immutable';
import {
  IUseInvoiceItemListProps,
  IUseInvoiceItemListReturnType,
  useInvoiceItemList,
} from './useInvoiceItemList';
import { InvoiceItemFormDTO, InvoiceItemModel, InvoiceModel } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import { IUseProductListProps } from './useProductList';
import {
  createInvoiceItem,
  deleteInvoiceItem,
  editInvoiceItem,
  InvoiceItemListReturnType,
  InvoiceItemReturnType,
} from '@services/api/invoice';
import { updateInvoice as storeUpdateInvoice } from '@store/features/invoiceSlice';
import { updateInvoiceFromList as storeUpdateInvoiceFromList } from '@store/features/invoiceListSlice';
import { useDispatch } from 'react-redux';
import { isFunction } from '@sportix/sportix-common-modules';

export interface UseStateInvoiceItemListProps extends IUseInvoiceItemListProps {
  invoice: InvoiceModel;
  setInvoice?: React.Dispatch<SetStateAction<InvoiceModel | null>>;
}

export interface UseStateInvoiceItemListReturnType
  extends Omit<IUseInvoiceItemListReturnType, 'entityList'> {
  invoiceItemList: List<InvoiceItemModel> | null;
  handleSearchInvoiceItemList: (
    value: Omit<IUseInvoiceItemListProps, 'invoiceUuid'> & {
      invoiceUuid?: string;
    },
  ) => Promise<void>;
  handleLoadingMoreDocumentItemList: (
    value: Omit<IUseInvoiceItemListProps, 'invoiceUuid'>,
  ) => Promise<void>;
  setInvoiceItemList: React.Dispatch<
    SetStateAction<List<InvoiceItemModel> | null>
  >;
  isLoadingMore: boolean;
  handleUpdateInvoiceItem: (
    value: InvoiceItemFormDTO,
  ) => Promise<InvoiceItemReturnType>;
  handleDeleteInvoiceItem: (ids: string[]) => Promise<InvoiceItemReturnType>;
  handleCreateInvoiceItem: (
    items: InvoiceItemFormDTO[],
  ) => Promise<Omit<InvoiceItemListReturnType, 'total'>>;
}

export function useStateInvoiceItemList(
  {
    invoiceUuid,
    invoice,
    setInvoice,
    ...rest
  }: UseStateInvoiceItemListProps = {} as UseStateInvoiceItemListProps,
): UseStateInvoiceItemListReturnType {
  const dispatch = useDispatch<any>();
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: invoiceLoading,
    total: invoiceItemListTotal,
    keywords: invoiceKeywords,
    ...invoicesParams
  } = useInvoiceItemList({
    invoiceUuid,
    ...rest,
  });

  const {
    entityList: invoiceItemList,
    loading: stateLoading,
    setEntityList,
    total,
  } = useStateEntityList<InvoiceItemModel>({
    entityList: entityList?.items!,
    refresh,
    limit,
    offset,
    total: invoiceItemListTotal,
  });

  const size = useMemo(
    () => invoiceItemList?.size || 0,
    [invoiceItemList?.size],
  );
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const loading = invoiceLoading || stateLoading;

  const handleSearchInvoiceItemList = React.useCallback(
    async ({
      keywords,
      offset = 0,
      limit = 10,
      showLoading = true,
      ...rest
    }: Omit<IUseInvoiceItemListProps, 'invoiceUuid'>) => {
      const invoiceItemList = await refresh({
        offset,
        keywords,
        showLoading,
        limit,
        ...rest,
      });

      if (invoiceItemList && invoiceItemList?.items) {
        setEntityList(invoiceItemList?.items);
      }
    },
    [refresh, setEntityList],
  );

  const handleLoadingMoreDocumentItemList = React.useCallback(
    async ({
      keywords = invoiceKeywords,
      showLoading = false,
      limit = 10,
      offset = invoiceItemList?.size,
      ...rest
    }: Omit<IUseProductListProps, 'companyUuid'> = {}) => {
      if (size < total) {
        const apiDocumentItemList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (apiDocumentItemList && apiDocumentItemList?.items) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiDocumentItemList?.items),
          );
        }
      }
    },
    [
      refresh,
      setEntityList,
      size,
      invoiceItemList?.size,
      invoiceKeywords,
      total,
    ],
  );

  const handleUpdateInvoiceItem = React.useCallback(
    async (value: InvoiceItemFormDTO): Promise<InvoiceItemReturnType> => {
      const { item, invoice: invoiceModel } = await editInvoiceItem(
        value,
        invoice?.uuid!,
      );

      const updatedInvoice = invoiceModel;

      if (updatedInvoice) {
        dispatch(storeUpdateInvoice({ invoice: updatedInvoice }));
        dispatch(
          storeUpdateInvoiceFromList({
            invoice: updatedInvoice,
            invoiceType: updatedInvoice?.inv_type,
          }),
        );

        if (isFunction(setInvoice)) {
          setInvoice(updatedInvoice);
        }
      }

      return {
        item: item.update(
          'item_positionable_type',
          () => value?.item_positionable_type,
        ),
        invoice: updatedInvoice!,
      };
    },
    [dispatch, invoice, setInvoice],
  );

  const handleCreateInvoiceItem = React.useCallback(
    async (
      itemsForm: InvoiceItemFormDTO[],
    ): Promise<Omit<InvoiceItemListReturnType, 'total'>> => {
      const { items, invoice: invoiceModel } = await createInvoiceItem(
        itemsForm,
        invoice?.uuid!,
      );

      const updatedInvoice = invoiceModel;

      if (updatedInvoice) {
        dispatch(storeUpdateInvoice({ invoice: updatedInvoice }));
        dispatch(
          storeUpdateInvoiceFromList({
            invoice: updatedInvoice,
            invoiceType: updatedInvoice?.inv_type,
          }),
        );

        if (isFunction(setInvoice)) {
          setInvoice(updatedInvoice);
        }
      }

      return { items, invoice: updatedInvoice! };
    },
    [dispatch, invoice, setInvoice],
  );

  const handleDeleteInvoiceItem = React.useCallback(
    async (ids: string[]): Promise<InvoiceItemReturnType> => {
      const { item, invoice: invoiceModel } = await deleteInvoiceItem(
        ids,
        invoice?.uuid!,
      );

      const updatedInvoice = invoiceModel;

      if (updatedInvoice) {
        dispatch(storeUpdateInvoice({ invoice: updatedInvoice }));
        dispatch(
          storeUpdateInvoiceFromList({
            invoice: updatedInvoice,
            invoiceType: updatedInvoice?.inv_type,
          }),
        );

        if (isFunction(setInvoice)) {
          setInvoice(updatedInvoice);
        }
      }
      return { item, invoice: updatedInvoice! };
    },
    [dispatch, invoice, setInvoice],
  );

  return {
    invoiceItemList,
    offset,
    limit,
    refresh,
    total,
    loading,
    keywords: invoiceKeywords,
    ...invoicesParams,
    handleSearchInvoiceItemList,
    setInvoiceItemList: setEntityList,
    isLoadingMore,
    handleLoadingMoreDocumentItemList,
    handleDeleteInvoiceItem,
    handleCreateInvoiceItem,
    handleUpdateInvoiceItem,
  };
}
