import { useCallback, useEffect, useRef } from 'react';
import { getUserList } from '@services/api/companyUser';
import { ListOptions, UserList, User } from '@services/models';
import { ListOptionsProps, isListToArray } from '@services/helpers';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseUserListProps extends Partial<ListOptionsProps> {
  loadOnInit?: boolean;
  showLoading?: boolean;
  keywords?: string;
}

export interface IUseUserListReturnType {
  error: (Error & { status: number }) | null;
  loading: boolean;
  list: User[];
  refresh: (value: Partial<IUseUserListProps>) => Promise<User[] | void>;
  total: number;
  offset: number;
  limit: number;
}

/**
 * @desc Список Співробітників Компанії
 * */
export default function useUserList(
  {
    offset = 0,
    limit = 10,
    loadOnInit = true,
    keywords,
  }: IUseUserListProps = {} as IUseUserListProps,
): IUseUserListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    offset: listPage,
    limit: listPageSize,
    keywords: listKeywords,
    handleUpdate,
    ...rest
  } = useStateReducer<
    Omit<IUseUserListReturnType, 'refresh'> & {
      keywords?: string;
    }
  >({
    error: null,
    loading: true,
    list: [],
    offset,
    limit,
    total: 0,
    keywords,
  });

  const refresh = useCallback(
    async (
      {
        offset = listPage,
        limit = listPageSize,
        keywords = listKeywords,
        showLoading = true,
        ...rest
      }: Partial<IUseUserListProps> = {} as IUseUserListProps,
    ): Promise<User[] | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          keywords,
        });

        const { users, total } = await cancellablePromise<UserList>(
          getUserList(
            new ListOptions({
              offset,
              limit,
              ...rest,
            }),
            keywords,
          ),
        );

        const list = isListToArray(users);

        if (!didCancel.current) {
          handleUpdate({
            list,
            total,
            loading: false,
          });

          return list;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listKeywords],
  );

  useEffect(() => {
    didCancel.current = false;

    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    ...rest,
  };
}
