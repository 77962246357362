import {
  fromBooleanToNumber,
  toDateByFormat,
  DateType,
  toMoment,
  correctPrice,
} from '@services/helpers';
import { ServiceGroupSessionModel } from '../internal';

export class ServiceGroupSessionFormDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public duration: number;

  public status: number;

  public max_attenders: number;

  public weekdays: string;

  public start_date: DateType;

  public end_date: DateType;

  public price: string;

  public manager_id: string;

  public service_title: string;

  constructor(props?: ServiceGroupSessionModel & any) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.duration = props?.duration || 0;
    this.status = fromBooleanToNumber(props?.status || 0);
    this.max_attenders = props?.max_attenders || 0;
    this.weekdays =
      (Array.isArray(props?.weekdays)
        ? props?.weekdays
        : props?.weekdays?.split(',')) || [];
    this.start_date = ServiceGroupSessionFormDTO.toDateString(
      props?.start_date || '',
    );
    this.end_date = ServiceGroupSessionFormDTO.toDateString(
      props?.end_date || '',
    );
    this.price = props?.price ? correctPrice(props?.price) : '0.00';
    this.manager_id = props?.manager_id || props?.manager?.uuid || '';
    this.service_title = props?.service?.title || '';
  }

  private static toDateString(birthday: string): any {
    return birthday ? toMoment(birthday) : '';
  }

  dateToString(birthday: DateType) {
    return toDateByFormat(birthday, 'YYYY-MM-DD');
  }
}
