import { EmployeeShiftModel } from '@models/EmployeeShiftModel';
import { Dispatch } from 'redux';
import { PROFILE } from '../constants';
import { ProfileModel } from '@structure';

export interface ISetProfile {
  type: PROFILE;
  profile: ProfileModel;
}

export interface ISetProfileDefaultEmployeeActiveShift {
  type: PROFILE;
  employeeShift: EmployeeShiftModel;
}

export interface IErrorProfile {
  type: PROFILE;
  error: Error | undefined;
}

export interface IResetProfileParam {
  type: PROFILE;
}

export function setProfile(
  profile: ProfileModel,
): (dispatch: Dispatch) => Promise<ISetProfile | IErrorProfile> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: PROFILE.LOADING_PROFILE,
    });
    try {
      return dispatch({
        type: PROFILE.SET_PROFILE,
        profile,
      });
    } catch (error: any) {
      return dispatch({
        type: PROFILE.ERROR_PROFILE,
        error: error.message,
      });
    }
  };
}

export function updateProfile(
  profile: ProfileModel,
): (dispatch: Dispatch) => Promise<ISetProfile | IErrorProfile> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: PROFILE.LOADING_PROFILE,
    });
    try {
      return dispatch({
        type: PROFILE.UPDATE_PROFILE,
        profile,
      });
    } catch (error: any) {
      return dispatch({
        type: PROFILE.ERROR_PROFILE,
        error: error.message,
      });
    }
  };
}

export function resetProfile(): (dispatch: Dispatch) => IResetProfileParam {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: PROFILE.RESET_PROFILE,
    });
  };
}
