import React, { forwardRef } from 'react';
import Modal, { ModalProps } from 'antd/es/modal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ICenterModalProps extends ModalProps {
  children: React.ReactNode;
}

const StyledModal = styled(Modal)`
  position: relative;
  & .ant-modal-content {
    padding: 0;
  }

  & .ant-modal-header {
    background: #e6f7f2;
    padding: min(4vh, 2.2em) min(4vw, 2rem);
    display: flex;
    justify-content: center;
    width: 100%;

    .ant-modal-title {
      text-align: center;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #252733;
    }
  }
  & .ant-modal-body {
    //max-height: 75vh;
    overflow-x: hidden;
    padding: min(2vh, 1.5rem) min(3vw, 2rem) 0 min(2vw, 2rem);
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
  }

  & .ant-modal-title {
    font-weight: 500;
  }

  .ant-modal-footer {
    background: #fff;
    box-shadow: 0px -10px 10px 0px rgba(173, 184, 211, 0.12);
    padding: min(3vh, 1.2rem) 2rem;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export default forwardRef(function CenterModal(
  { children, open, ...rest }: ICenterModalProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  return (
    <StyledModal
      cancelText={t('Cancel')}
      okText={t('Ok')}
      centered
      open={open}
      {...rest}>
      <div ref={open ? ref : null}>{children}</div>
    </StyledModal>
  );
});
