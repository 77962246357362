import { List } from 'immutable';
import {
  DayOffDTO,
  IDayOffDTOProps,
  DayOffModel,
  DayOffListModel,
} from '../internal';
import { listToArray } from '@services/helpers';

export interface IDayOffListDTO {
  dayOff: DayOffDTO[];
  total: number;
}

export class DayOffMapper {
  public static toDayOffDTO(dayOff: DayOffModel | IDayOffDTOProps): DayOffDTO {
    return new DayOffDTO({
      uuid: dayOff?.uuid,
      start_date: dayOff?.start_date,
      end_date: dayOff?.end_date,
      comment: dayOff?.comment,
      created_at: dayOff?.created_at,
    });
  }

  public static toDayOffListDTO(
    dayOffs: List<DayOffModel> | IDayOffDTOProps[],
    total: number,
  ): IDayOffListDTO {
    const dayOffList = List.isList(dayOffs)
      ? listToArray<DayOffModel>(dayOffs)
      : dayOffs;

    return {
      dayOff: dayOffList?.map((dayOff) => DayOffMapper.toDayOffDTO(dayOff)),
      total,
    };
  }

  public static toDayOffModel(dayOffDTO: DayOffDTO): DayOffModel {
    return new DayOffModel(dayOffDTO);
  }

  public static toDayOffListModel(
    dayOffDTOs: DayOffDTO[],
    total: number = 0,
  ): DayOffListModel {
    return new DayOffListModel({ days_off: dayOffDTOs, total });
  }
}
