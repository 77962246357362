import React from 'react';
import { Routes, Route } from 'react-router';
import { InvoiceListPage, InvoicePage } from '../views/Invoices';
import { AppRouteLayout } from '@components/lib/Layout';

export function InvoiceRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <InvoiceListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":invoiceId"
        element={
          <AppRouteLayout>
            <InvoicePage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
