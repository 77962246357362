import React from 'react';
import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import Color from 'color';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IUseInvoiceListProps, useStoredCompanies } from '@hooks';
import { InvoiceStatsModel, InvoiceType } from '@structure';
import { HiDocumentArrowUp, HiDocumentArrowDown } from 'react-icons/hi2';
import { isThereContent } from '@services/helpers';

export interface IInvoiceTypeViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type?: InvoiceType;
  color: string;
  selected?: boolean;
  title: string;
  onClick: (value: Partial<IUseInvoiceListProps>) => void;
  stats: InvoiceStatsModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 20px;
`;

const iconStyle = css<{ $color: string }>`
  color: ${({ $color }) => $color};

  font-size: 20px;
`;

const StyledHiDocumentArrowUp = styled(HiDocumentArrowUp)`
  ${iconStyle}
`;

const StyledHiDocumentArrowDown = styled(HiDocumentArrowDown)`
  ${iconStyle}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

export function InvoiceTypeView({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  ...rest
}: IInvoiceTypeViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  let total;
  let icon;
  let sum;
  let totalText;

  if (type === InvoiceType.IN) {
    total = stats?.in_total;
    sum = stats?.sum_in_total;
    icon = <StyledHiDocumentArrowUp $color={textColor} />;
  } else {
    total = stats?.out_total;
    sum = stats?.sum_out_total;
    icon = <StyledHiDocumentArrowDown $color={textColor} />;
  }

  switch (total) {
    case 0:
      totalText =
        type === InvoiceType.IN
          ? t('There are no invoice')
          : t('There are no input invoice');
      break;
    case 1:
      totalText = type === InvoiceType.IN ? t('tInvoice') : t('tInput invoice');
      break;
    case 2:
    case 3:
    case 4:
      totalText =
        type === InvoiceType.IN ? t('ttInvoice') : t('ttInput invoice');
      break;
    default:
      totalText =
        type === InvoiceType.IN ? t('tttInvoice') : t('tttInput invoice');
      break;
  }

  return (
    <DetailsActionItemView
      $minHeight={100}
      color={color}
      selected={selected}
      onClick={() => onClick({ invoiceType: type })}
      {...rest}>
      <StyledContainer>
        {icon || null}
        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitleContainer>
              {sum ? (
                <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
                  {`${defaultCompany?.currency_symbol || ''} ${sum}`}
                </StyledTitle>
              ) : null}
              {typeof total === 'number' || total === '' ? (
                <StyledTitle $color={textColor}>
                  {`${total} ${totalText}`}
                </StyledTitle>
              ) : null}
            </StyledTitleContainer>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
