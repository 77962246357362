import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StoreDocumentMappedType,
  StoreDocumentModel,
  StoreDocumentShowOnly,
  StoreDocumentType,
} from '@structure';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies, useStoredStoreDocumentList } from '@hooks';
import { correctPrice, last, toDateByFormat } from '@services/helpers';
import { TableIndexField } from '@components/lib/DataDisplay';
import { StoreRealizationDetailsView } from '../../Stores';
import styled from 'styled-components';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';

export interface PaymentStoreRealizationTableProps {
  selectedItem: StoreDocumentModel | unknown;
  residentUuid: string;
  handleSelectDestination: (
    payment_destination_object_uuid: string,
    payment_sum: string,
    comment: string,
  ) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export function PaymentStoreRealizationTable({
  selectedItem,
  residentUuid,
  handleSelectDestination,
}: PaymentStoreRealizationTableProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();

  const [selectedStoreDocuments, setSelectedStoreDocuments] = useState<
    StoreDocumentModel[]
  >([]);

  const isSelectOnce = useRef(false);

  const getSum = useCallback((document: StoreDocumentModel) => {
    const isDiscount = Number(document?.doc_discount_value || 0) > 0;

    const paid = Number(document?.doc_sum_paid) || 0;
    const total =
      (isDiscount
        ? Number(document?.doc_sum_total_with_discount)
        : Number(document?.doc_sum_total)) || 0;
    return correctPrice(total - paid);
  }, []);

  const {
    documentList,
    limit,
    total,
    loading,
    loadingMore,
    page,
    resident_uuid,

    handleRefreshStoreDocuments,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.OUT,
    resident_uuid: residentUuid,
    mappedDocumentType: StoreDocumentMappedType.PAYMENT_OUT,
    show_only: StoreDocumentShowOnly.UNPAID,
    withShowOnlyUpdate: false,
    alwaysSendRequest: true,
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedStoreDocuments?.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StoreDocumentModel[],
      ) => {
        const document = last(selectedRecords);

        if (document) {
          isSelectOnce.current = true;
          setSelectedStoreDocuments([document]);
          handleSelectDestination(
            document?.uuid,
            getSum(document),
            `${t('Payment of arrears invoice')}  №${
              document?.doc_local_number
            } ${t('From').toLowerCase()} ${toDateByFormat(
              document?.doc_date,
              'DD.MM.YYYY HH:mm',
            )}`,
          );
        } else {
          setSelectedStoreDocuments([]);
          handleSelectDestination('', correctPrice(0), '');
        }
      },
    }),
    [getSum, handleSelectDestination, selectedStoreDocuments, t],
  );

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      render: (document: StoreDocumentModel) => (
        <StoreRealizationDetailsView document={document} withoutNavigate />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'amount',
      align: 'right' as any,
      render: (document: StoreDocumentModel) => (
        <StyledContainer>{getSum(document)}</StyledContainer>
      ),
    },
  ];

  useEffect(() => {
    if (resident_uuid !== residentUuid) {
      (async () => {
        await handleRefreshStoreDocuments({
          resident_uuid: residentUuid,
          page: 1,
          showLoading: true,
          show_only: StoreDocumentShowOnly.UNPAID,
        });
      })();
    }
  }, [handleRefreshStoreDocuments, residentUuid, resident_uuid]);

  useEffect(() => {
    if (
      selectedItem instanceof StoreDocumentModel &&
      !selectedStoreDocuments?.length &&
      !isSelectOnce.current
    ) {
      setSelectedStoreDocuments([selectedItem]);
      isSelectOnce.current = true;
    }
  }, [selectedStoreDocuments?.length, selectedItem]);

  return (
    <Table<StoreDocumentModel>
      pageSize={limit}
      total={total}
      onChange={(value) =>
        handleRefreshStoreDocuments({
          ...value,
          show_only: StoreDocumentShowOnly.UNPAID,
        } as any)
      }
      rowSelection={rowSelection}
      dataSource={documentList}
      columns={columns}
      loading={loading || loadingMore}
      page={page}
    />
  );
}
