import { apiGet, apiPut } from '../core/api';
import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import { ApiAnswer, IEntityProps, IListSearchProps } from '../types';
import {
  INotificationDTOProps,
  NotificationDTO,
  INotificationListDTO,
  NotificationMapper,
} from '@structure';

export interface INotificationListAnswer extends ApiAnswer {
  notifications: INotificationDTOProps[];
  unread: number;
}

export interface INotificationAnswer extends ApiAnswer {
  notification: INotificationDTOProps;
}

export interface INotificationListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export async function getNotificationList({
  offset = 0,
  limit = 20,
  keywords,
}: INotificationListProps): Promise<INotificationListDTO> {
  const { notifications, total, unread } = await apiGet<
    INotificationListProps,
    INotificationListAnswer
  >(`${contentApiUrl}/notifications`, {
    offset,
    limit,
  });

  return NotificationMapper.toNotificationListDTO(notifications, total, unread);
}

export async function setNotificationReadStatus(
  notificationUuid: string,
): Promise<NotificationDTO> {
  Validate.string({ value: notificationUuid });

  const { notification } = await apiPut<any, INotificationAnswer>(
    `${contentApiUrl}/notifications/read/${notificationUuid}`,
    {},
  );

  return NotificationMapper.toNotificationDTO(notification);
}
