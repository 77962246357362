import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps } from 'antd';
import { useModal } from '@components/lib/libV2/hooks';
import { InvoiceFormDTO, InvoiceType } from '@structure';
import { IEditButtonProps, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { LoadingOutlined } from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { IoMenu } from 'react-icons/io5';
import { IUseStateInvoiceReturnType } from '@hooks';
import { HiDocumentArrowDown, HiDocumentArrowUp } from 'react-icons/hi2';
import { produce } from 'immer';

const InvoiceSideWindowLazy = React.lazy(
  () => import('../Show/InvoiceSideWindow'),
);

export interface IInvoiceMenuButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateInvoiceReturnType['handleCreateInvoice'];
  onCancel?: () => void;
  invoice: InvoiceFormDTO;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export function InvoiceMenuButton({
  children,
  onSuccess,
  onCancel,
  loading,
  disabled,
  invoice,
  title,

  ...rest
}: IInvoiceMenuButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [invoiceForm, setInvoiceForm] = useState<InvoiceFormDTO>(invoice);

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  const items: MenuProps['items'] = [
    {
      key: 'invoice',
      label: <StyledTitle>{t('Invoice')}</StyledTitle>,
      icon: <HiDocumentArrowDown size={15} />,
      onClick: () => {
        const invoiceForm = produce(invoice, (draft) => {
          draft.inv_type = InvoiceType.IN;
        });

        setInvoiceForm(invoiceForm);

        handleOnInit();
      },
    },
    {
      key: 'product',
      label: <StyledTitle>{t('Input invoice')}</StyledTitle>,
      icon: <HiDocumentArrowUp size={15} />,
      onClick: () => {
        const invoiceForm = produce(invoice, (draft) => {
          draft.inv_type = InvoiceType.OUT;
        });

        setInvoiceForm(invoiceForm);

        handleOnInit();
      },
    },
  ];

  return (
    <>
      <StyledDropdown
        trigger={['hover']}
        disabled={!!loading || disabled}
        menu={{ items }}
        placement="bottomLeft">
        {loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : title ? (
          <StyledTitle>{title}</StyledTitle>
        ) : (
          <IoMenu size={20} color={theme.colors.primary} />
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceSideWindowLazy
          editMode
          invoice={invoiceForm}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
