import * as React from 'react';
import { List } from 'immutable';
import { Moment } from 'moment';
import {
  useClientPaymentList,
  IUseClientPaymentListProps,
  IUseClientPaymentListReturnType,
} from './useClientPaymentList';
import { PaymentModel, PaymentFormDTO } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  debounce,
  head,
  last,
  dateToIsoString,
  correctPrice,
} from '@services/helpers';
import { useStatePaymentListActions } from './useStatePaymentListActions';
import { IPaymentScheduleListStats } from '@services/api/orders';
import { useCallback, useEffect, useState } from 'react';
import { OPERATION_TYPE } from '@services/types';
import { IPaymentStatsProps } from './useStatePaymentList';
import { isThereContent } from '@sportix/sportix-common-modules';

export interface IUseStateClientPaymentListProps
  extends IUseClientPaymentListProps {}

export type ClientPaymentDateRangeParam = [Moment, Moment];

export interface IUseStateClientPaymentListReturnType
  extends Omit<IUseClientPaymentListReturnType, 'entityList'> {
  payments: List<PaymentModel> | null;
  handleCreatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (ids: string[]) => Promise<void>;
  handleSearchPayments: (value: any) => Promise<void>;
  handleRefreshPayments: (
    value: Partial<IUseClientPaymentListProps> & { pag?: number },
  ) => Promise<void>;
  handlePickPaymentRange: (value: ClientPaymentDateRangeParam) => Promise<void>;
  page: number;
}

export function useStateClientPaymentList(
  {
    clientUuid,
    ...rest
  }: IUseStateClientPaymentListProps = {} as IUseStateClientPaymentListProps,
): IUseStateClientPaymentListReturnType {
  const [localStats, setLocalStats] = useState<IPaymentScheduleListStats>(
    {} as IPaymentScheduleListStats,
  );

  const [page, setPage] = useState(1);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: paymentListLoading,
    total: paymentListTotal,
    stats,
    ...clientsParams
  } = useClientPaymentList({
    clientUuid,
    ...rest,
  });

  const {
    entityList: payments,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<PaymentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: paymentListTotal,
  });

  const handleUpdateStats = useCallback(
    ({ payment, type }: IPaymentStatsProps) => {
      const isPaymentInType = payment?.payment_type === OPERATION_TYPE.IN;

      const price = Number(payment?.payment_sum || 0);

      if (type === 'create') {
        if (isPaymentInType) {
          setLocalStats((prevState) => {
            const prevTotalInSum = Number(prevState?.totalInSum || 0);

            return {
              ...prevState,
              totalInSum: correctPrice(prevTotalInSum + price),
            };
          });

          return;
        }

        setLocalStats((prevState) => {
          const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

          return {
            ...prevState,
            totalOutSum: correctPrice(prevTotalOutSum + price),
          };
        });
      }

      if (type === 'delete') {
        if (isPaymentInType) {
          setLocalStats((prevState) => {
            const prevTotalInSum = Number(prevState?.totalInSum || 0);

            return {
              ...prevState,
              totalInSum: correctPrice(prevTotalInSum - price),
            };
          });

          return;
        }

        setLocalStats((prevState) => {
          const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

          return {
            ...prevState,
            totalOutSum: correctPrice(prevTotalOutSum - price),
          };
        });
      }

      if (type === 'edit') {
        const prevPayment = payments?.find(
          ({ uuid }) => uuid === payment?.uuid,
        );

        if (prevPayment) {
          const prevPrice = Number(prevPayment?.payment_sum || 0);

          if (isPaymentInType) {
            setLocalStats((prevState) => {
              const prevTotalInSum = Number(prevState?.totalInSum || 0);

              return {
                ...prevState,
                totalInSum: correctPrice(prevTotalInSum - prevPrice + price),
              };
            });

            return;
          }

          setLocalStats((prevState) => {
            const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

            return {
              ...prevState,
              totalOutSum: correctPrice(prevTotalOutSum - prevPrice + price),
            };
          });
        }
      }
    },
    [payments],
  );

  const { handleDeletePayments, handleUpdatePayment, handleCreatePayment } =
    useStatePaymentListActions({
      handleCreate,
      handleDelete,
      handleUpdate,
      handleUpdateStats,
    });

  const handlePickPaymentRange = React.useCallback(
    async (value: ClientPaymentDateRangeParam): Promise<void> => {
      if (value) {
        await refresh({
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });
      } else {
        await refresh({
          date_start: '',
          date_end: '',
          isClearDate: true,
        });
      }
    },
    [refresh],
  );

  const handleSearchPayments = React.useCallback(
    async (keywords: string): Promise<void> => {
      const payments = await refresh({
        keywords,
        date_start: '',
        date_end: '',
        isSearch: true,
      });

      if (payments) {
        setEntityList(payments);
      }
    },
    [refresh, setEntityList],
  );

  const handleRefreshPayments = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      page = 1,
      ...rest
    }: Partial<IUseClientPaymentListProps> & {
      page?: number;
    }): Promise<void> => {
      const payments = await refresh({ offset, limit, ...rest });

      if (payments) {
        setEntityList(payments);
        setPage(page);
      }
    },
    [refresh, setEntityList],
  );

  useEffect(() => {
    if (isThereContent(stats)) {
      setLocalStats(stats);
    }
  }, [stats]);

  return {
    payments,
    offset,
    limit,
    refresh,
    total,
    loading: paymentListLoading || loading,
    ...clientsParams,
    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments,
    handleSearchPayments,
    handlePickPaymentRange,
    stats: localStats,
    handleRefreshPayments,
    page,
  };
}
