import React, { useCallback, Suspense, useState } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  IPriceTagPrintFormDTOProps,
  PriceTagFormDTO,
  PriceTagMapper,
  PriceTagPrintFormDTO,
} from '@structure';
import {
  IUseStatePriceTagListReturnType,
  useDefaultPriceMargin,
  useModal,
  useSearchInputFocus,
  useStateProductList,
  useStoredCompanies,
} from '@hooks';
import { Form } from 'antd';
import { PriceTagFields } from './PriceTagFields';
import { isFunction } from '@services/helpers';
import { SuspenseEmpty } from '@components/lib/DataDisplay';

const PriceTagPrintModalLazy = React.lazy(
  () => import('../Show/PriceTagPrintModal'),
);

export interface IPriceTagFormProps
  extends Omit<
      IDefaultFormProps<PriceTagFormDTO, PriceTagFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
    >,
    Pick<
      IUseStatePriceTagListReturnType,
      | 'handleCreateOrUpdatePriceTag'
      | 'handleDeletePriceTags'
      | 'handleClearPriceTagList'
      | 'handlePrintPriceTagList'
    > {
  loading?: boolean;
  items: PriceTagFormDTO | null;
  contentWidth: number;
}

export function PriceTagForm({
  loading,
  items,
  editMode,
  onCancel,
  contentWidth,
  handleClearPriceTagList: onClearPriceTagList,
  handlePrintPriceTagList,
  handleDeletePriceTags,
  handleCreateOrUpdatePriceTag,

  ...rest
}: IPriceTagFormProps): React.JSX.Element {
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();

  const [print, setPrint] = useState<PriceTagPrintFormDTO | null>(null);

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({
    loading: !!loading,
  });

  const {
    productList,
    loading: productListLoading,
    keywords: productListKeywords,
    isLoadingMore: productIsLoadingMore,

    handleSearchProductList,
    handleLoadingMoreProductList,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
  });

  const {
    priceMarginList,
    loading: priceMarginListLoading,
    handleSearchPriceMargins,
    defaultPriceMargin,
    setDefaultPriceMargin,
    getProductPriceMargin,
  } = useDefaultPriceMargin({
    loadOnInit: true,
    instance,
    fieldName: 'price_margin_uuid',
  });

  const onSuccessPrintPriceTags = useCallback(
    async (value: PriceTagPrintFormDTO) => {
      await handlePrintPriceTagList(value);

      if (isFunction(onCancel)) {
        onCancel();
      }
    },
    [handlePrintPriceTagList, onCancel],
  );

  const { visible, handleSuccess, handleCancel, handleOnInit } = useModal({
    onSuccess: onSuccessPrintPriceTags,
    onCancel: daleyFocus,
  });

  const handleOnSuccess = useCallback(
    (value: PriceTagFormDTO) => {
      const price_margin_uuid = instance.getFieldValue('price_margin_uuid');

      const print = PriceTagMapper.toPriceTagPrintFormDTO({
        ...value,
        price_margin_uuid,
      } as unknown as IPriceTagPrintFormDTOProps);

      setPrint(print);

      handleOnInit();
    },
    [handleOnInit, instance],
  );

  const handleClearPriceTagList = useCallback(async () => {
    await onClearPriceTagList();

    if (isFunction(onCancel)) {
      onCancel();
    }
  }, [onClearPriceTagList, onCancel]);

  const onEnterPress = useCallback(
    (e: KeyboardEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      return false;
    },

    [],
  );

  return (
    <>
      <DefaultForm<PriceTagFormDTO, PriceTagFormDTO>
        formKeyboardCodes={[]}
        onEnterPress={onEnterPress}
        withContext
        isResetLoading
        onCancel={onCancel}
        instance={instance}
        initialValues={items as any}
        editMode={editMode}
        showNotify={false}
        showFooter={false}
        onSuccess={handleOnSuccess}
        {...rest}>
        <PriceTagFields
          loading={!!loading}
          productList={productList}
          productListKeywords={productListKeywords}
          productListLoadingMore={productListLoading}
          productListLoading={productListLoading}
          handleSearchProducts={handleSearchProductList}
          defaultPriceMargin={defaultPriceMargin}
          priceMarginList={priceMarginList}
          priceMarginListLoading={priceMarginListLoading}
          handleSearchPriceMargins={handleSearchPriceMargins}
          contentWidth={contentWidth}
          setDefaultPriceMargin={setDefaultPriceMargin}
          getProductPriceMargin={getProductPriceMargin}
          inputRef={inputRef}
          focus={focus}
          daleyFocus={daleyFocus}
          visible={visible}
          handleClearPriceTagList={handleClearPriceTagList}
          handlePrintPriceTagList={handlePrintPriceTagList}
          handleDeletePriceTags={handleDeletePriceTags}
          handleCreateOrUpdatePriceTag={handleCreateOrUpdatePriceTag}
          isLoadingMore={productIsLoadingMore}
          handleLoadingMoreProductList={handleLoadingMoreProductList}
        />
      </DefaultForm>
      <Suspense fallback={<SuspenseEmpty />}>
        <PriceTagPrintModalLazy
          print={print!}
          onSuccess={handleSuccess as any}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
