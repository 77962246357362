import { LayoutItem } from './types';

/**
 * Layout: Ukrainian
 * Source: boomsya (https://github.com/boomsya)
 */
export default <LayoutItem>{
  layout: {
    default: [
      '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
      '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
      '{tab} й ц у к е н г ш щ з х ї \\',
      '{capslock} ф і в а п р о л д ж є {enter}',
      '{shiftleft} я ч с м и т ь б ю . {shiftright}',
      '{controlleft} {altleft} {metaleft} {space} {metaright} {altright}',
    ],
    shift: [
      '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
      '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
      '{tab} Й Ц У К Е Н Г Ш Щ З Х Ї |',
      '{capslock} Ф І В А П Р О Л Д Ж Є {enter}',
      '{shiftleft} Я Ч С М И Т Ь Б Ю , {shiftright}',
      '{controlleft} {altleft} {metaleft} {space} {metaright} {altright}',
    ],
  },
  display: {
    '{escape}': 'esc ⎋',
    '{tab}': 'tab ⇥',
    '{backspace}': 'backspace ⌫',
    '{enter}': 'enter ↵',
    '{capslock}': 'caps lock ⇪',
    '{shiftleft}': 'shift ⇧',
    '{shiftright}': 'shift ⇧',
    '{controlleft}': 'ctrl ⌃',
    '{controlright}': 'ctrl ⌃',
    '{altleft}': 'alt ⌥',
    '{altright}': 'alt ⌥',
    '{metaleft}': '&#127760;',
    '{metaright}': 'cmd ⌘',
  },
  name: 'ukrainian',
};
