import { STORE_DOCUMENT } from '../constants';
import { StoreDocumentModel } from '@structure';
import { ApiError } from '@services/types';
import { Dispatch } from 'redux';

export interface ISetStoreDocumentParam {
  type: STORE_DOCUMENT;
  document: StoreDocumentModel | null;
}

export interface IUpdateStoreDocumentParam {
  type: STORE_DOCUMENT;
  document: StoreDocumentModel;
}

export interface IResetStoreDocumentParam {
  type: STORE_DOCUMENT;
}

export interface IErrorStoreDocumentParam {
  type: STORE_DOCUMENT;
  error: ApiError | undefined;
}

export function setStoreDocument(
  document: StoreDocumentModel | null,
): (dispatch: Dispatch) => Promise<ISetStoreDocumentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT.SET_STORE_DOCUMENT,
      document,
    });
  };
}

export function updateStoreDocument(
  document: StoreDocumentModel,
): (dispatch: Dispatch) => Promise<IUpdateStoreDocumentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT.UPDATE_STORE_DOCUMENT,
      document,
    });
  };
}

export function resetStoreDocument(): (
  dispatch: Dispatch,
) => Promise<IResetStoreDocumentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT.RESET_STORE_DOCUMENT,
    });
  };
}

export function errorStoreDocument(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStoreDocumentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT.ERROR_STORE_DOCUMENT,
      error,
    });
  };
}
