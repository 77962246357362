import React, { useState } from 'react';
import { IDefaultTabPanelProps } from '@components/lib/General';
import { Routes, RoutesAcl } from '@services/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  IUseStateAbonementGroupReturnType,
  IUseStateClientAbonementScheduleListReturnType,
} from '@hooks';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Route } from '@components/lib/DataDisplay';

export enum TARIFF_GROUP_MODEL_TABS {
  PARTICIPANTS = 'Participants',
}

export interface ITariffModelGroupTabPanelProps
  extends Pick<
      IUseStateClientAbonementScheduleListReturnType,
      | 'scheduleList'
      | 'handleRefreshClientAbonementSchedules'
      | 'handleFormingClientAbonementSchedule'
      | 'isShowClassSchedule'
    >,
    Pick<IUseStateAbonementGroupReturnType, 'group'> {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  routes: Route[];

  schedulesLoading: boolean;
  formingScheduleListLoading: boolean;
  isStartRefreshSchedules: boolean;
}

const StyledLoadingOutlined = styled(LoadingOutlined)`
  &&& {
    color: ${({ theme }) => theme.colors.primary} !important;
    font-size: 20px !important;
  }
`;

export function TariffModelGroupTabPanel({
  children,
  group,
  routes,

  scheduleList,
  schedulesLoading,
  formingScheduleListLoading,
  isStartRefreshSchedules,
  isShowClassSchedule,
  handleRefreshClientAbonementSchedules,
  handleFormingClientAbonementSchedule,
}: ITariffModelGroupTabPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const tabs: any = [
    {
      key: TARIFF_GROUP_MODEL_TABS.PARTICIPANTS,
      title: t(TARIFF_GROUP_MODEL_TABS.PARTICIPANTS),
      disabled: false,
      refresh: () => {},
      navigation: () => {
        navigate(Routes.clientAbonements);
      },
      renderer: () => <></>,
      aclItem: RoutesAcl[Routes.tariffModels],
    },
    // ...(isStartRefreshSchedules
    //   ? [
    //       {
    //         key: ABONEMENT_TABS.SCHEDULES,
    //         defaultActiveKey: ABONEMENT_TABS.SCHEDULES,
    //         title: t(ABONEMENT_TABS.SCHEDULES),
    //         disabled: !isShowClassSchedule,
    //         buttonIcon: formingScheduleListLoading ? (
    //           <StyledLoadingOutlined />
    //         ) : !isShowClassSchedule ? undefined : (
    //           () => null
    //         ),
    //         stopPropagation: visible,
    //         extraHeader: isShowClassSchedule ? (
    //           <ClientAbonementScheduleButton
    //             disabled={formingScheduleListLoading}
    //             schedule={ScheduleCalendarMapper.toClientAbonementScheduleFormDTO(
    //               group as any,
    //               true,
    //             )}
    //             onSuccess={handleFormingClientAbonementSchedule}
    //             editMode
    //             setVisible={setVisible}
    //           />
    //         ) : null,
    //         renderer: () => {
    //           return (
    //             <ClientAbonementScheduleView
    //               scheduleList={scheduleList}
    //               schedulesLoading={schedulesLoading}
    //               handleRefreshClientAbonementSchedules={
    //                 handleRefreshClientAbonementSchedules
    //               }
    //               isStartRefreshSchedules={isStartRefreshSchedules}
    //               disabled={formingScheduleListLoading}
    //               routes={routes}
    //             />
    //           );
    //         },
    //       },
    //     ]
    //   : []),
  ];

  return <>{children({ tabs })}</>;
}
