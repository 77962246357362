import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IStoreDocumentModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IStoreDocumentItemDTOProps,
  IStoreDTOProps,
  IClientDTOProps,
  StoreDTO,
  ClientDTO,
  StoreMapper,
  ClientMapper,
  StoreDocumentItemDTO,
  StoreDocumentMapper,
  IScheduleCalendarDTOProps,
  ScheduleCalendarModel,
  ScheduleCalendarMapper,
  StoreDocumentModel,
  StoreDocumentExtraServiceDTO,
  StoreDocumentExtraServiceDTOProps,
  StoreDocumentMultiCurrencyDTOProps,
  StoreDocumentMultiCurrencyDTO,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export interface StoreDocumentProfitStatsCurrency {
  currency: string;
  sum: string;
  total: string;
}

export interface IStoreDocumentStatsProps {
  currencies: StoreDocumentProfitStatsCurrency[];
  profit_sum: string;
}

export interface IStoreDocumentProfitStatsDTOProps {
  currencies: StoreDocumentProfitStatsCurrency[];
  profit_sum: string;
}

export class StoreDocumentProfitStatsDTO {
  public currencies: StoreDocumentProfitStatsCurrency[];

  public profit_sum: string;

  constructor(props: IStoreDocumentProfitStatsDTOProps) {
    this.currencies = (props?.currencies || []).map(
      ({ currency, sum, total }) => ({
        currency,
        sum: correctPrice(sum || 0),
        total: correctPrice(total || 0),
      }),
    );
    this.profit_sum = correctPrice(props?.profit_sum || 0);
  }
}
