import { Dispatch } from 'redux';
import { REWARD } from '../constants';
import { RewardModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetRewardParam {
  type: REWARD;
  reward: RewardModel | null;
}

export interface IUpdateRewardParam {
  type: REWARD;
  reward: RewardModel;
}

export interface IResetRewardParam {
  type: REWARD;
}

export interface IErrorRewardParam {
  type: REWARD;
  error: ApiError | undefined;
}

export function setReward(
  reward: RewardModel | null,
): (dispatch: Dispatch) => Promise<ISetRewardParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD.SET_REWARD,
      reward,
    });
  };
}

export function updateReward(
  reward: RewardModel,
): (dispatch: Dispatch) => Promise<IUpdateRewardParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD.UPDATE_REWARD,
      reward,
    });
  };
}

export function resetReward(): (
  dispatch: Dispatch,
) => Promise<IResetRewardParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD.RESET_REWARD,
    });
  };
}

export function errorReward(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorRewardParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD.ERROR_REWARD,
      error,
    });
  };
}
