import { useCallback, useEffect, useRef } from 'react';
import { getUserById } from '@services/api/companyUser';
import { User } from '@services/models';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseUserDetailsProps {
  loadOnInit?: boolean;
  showLoading?: boolean;
  userUuid: string;
}

export interface IUseUserDetailsReturnType {
  error: (Error & { status: number }) | null;
  loading: boolean;
  user: User;
  refresh: (value: Partial<IUseUserDetailsProps>) => Promise<User | void>;
}

/**
 * @desc Список Співробітників Компанії
 * */
export default function useUserDetails(
  {
    loadOnInit = true,
    userUuid,
  }: IUseUserDetailsProps = {} as IUseUserDetailsProps,
): IUseUserDetailsReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    userUuid: localUserUuid,
    handleUpdate,
    ...rest
  } = useStateReducer<
    Omit<IUseUserDetailsReturnType, 'refresh'> & {
      userUuid: string;
    }
  >({
    error: null,
    loading: true,
    user: {} as User,
    userUuid,
  });

  const refresh = useCallback(
    async (
      {
        userUuid = localUserUuid,
        showLoading = true,
      }: Partial<IUseUserDetailsProps> = {} as IUseUserDetailsProps,
    ): Promise<User | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
        });

        const user = await cancellablePromise<User>(getUserById({ userUuid }));

        if (!didCancel.current) {
          handleUpdate({
            user,
            loading: false,
          });

          return user;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localUserUuid],
  );

  useEffect(() => {
    didCancel.current = false;

    if (loadOnInit && userUuid) {
      (async () => {
        await refresh({ userUuid });
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, userUuid]);

  return {
    refresh,
    ...rest,
  };
}
