import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { PaymentModel } from '@structure';
import { OverviewBottomView } from '@components/lib/Layout';

export interface IPaymentBottomViewProps {
  payment: PaymentModel | null;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

export const PaymentBottomView = forwardRef(function PaymentBottomView(
  { payment }: IPaymentBottomViewProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  return (
    <OverviewBottomView>
      <StyledPriceContainer>
        <StyledTitle fontSize={14}>{`${t('Payment sum')}: `}</StyledTitle>
        <StyledPrice>
          {`${defaultCompany?.currency_symbol} ${payment?.payment_sum}`}
        </StyledPrice>
      </StyledPriceContainer>
    </OverviewBottomView>
  );
});
