import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import {
  CompanyCreateButton,
  CompanyEditButton,
  CompaniesDeleteButton,
} from '../Buttons';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateCompanyList,
  useStopLoading,
  useStoredCompanies,
  useStoredProfile,
} from '@hooks';
import { textToUpperCase, head } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  Table,
  TableActionCell,
  TableLinkCell,
} from '@components/lib/libV2/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { CompanyModel, CompanyMapper } from '@structure';
import { useAcl } from '@contex';
import {
  HeaderLeft,
  CheckButton,
  ListActions,
} from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ProfileHeader } from '../../Profile';
import styled from 'styled-components';
import { successButtonStyle } from '@components/lib/Styled';
import { PlusOutlined } from '@ant-design/icons';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledCompanyCreateButton = styled(CompanyCreateButton)`
  ${successButtonStyle}
`;

export function CompanyListPage(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useStoredProfile();

  const [selectedCompanies, setSelectedEmployees] = useState<CompanyModel[]>(
    [],
  );

  const { manage } = useAcl(({ employee }) => employee);

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'company-create-event',
    disabled: !manage,
  });

  const { defaultCompanyUuid } = useStoredCompanies();

  const {
    companies,
    loading: companiesLoading,
    error: companiesError,
    defaultCompanyLoading,
    total,
    limit: pageSize,

    refresh,
    handleCreateCompany,
    handleUpdateCompany,
    handelSetDefaultCompany,
    handleDeleteCompanies: onDeleteCompanies,
    handleSearchCompanies,
  } = useStateCompanyList();

  const loading = useStopLoading({
    loading: companiesLoading,
    error: companiesError,
    message: 'An error occurred during companies loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteCompanies = useCallback(
    async (value: string) => {
      await onDeleteCompanies(value);
      focus();
    },
    [focus, onDeleteCompanies],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedCompanies.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: CompanyModel[],
      ) => {
        setSelectedEmployees(selectedRecords);
      },
    }),
    [selectedCompanies],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.profile}`,
      breadcrumbName: 'Profile',
    },
    {
      path: `/${Routes.profile}`,
      breadcrumbName: profile?.fullName || 'Name',
    },
    {
      path: `/${Routes.companies}`,
      breadcrumbName: 'Companies',
    },
  ]);

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (company: CompanyModel) => (
        <TableLinkCell
          tooltip="Open a detailed description of the company"
          path={`${company?.uuid}`}>
          {company.title}
        </TableLinkCell>
      ),
    },
    {
      title: t('Phone'),
      key: 'phone',
      render: (company: CompanyModel) => company?.phone,
    },
    {
      title: t('Email'),
      key: 'email',
      render: (company: CompanyModel) => company?.email,
    },
    {
      align: 'center' as any,
      fixed: 'right' as any,
      title: t('Actions'),
      key: 'actions',
      render: (company: CompanyModel) => (
        <TableActionCell>
          {company?.uuid !== defaultCompanyUuid ? (
            <CheckButton
              tooltipTitle={t('Select the default company')}
              onClick={() => handelSetDefaultCompany(company?.uuid)}
              loading={defaultCompanyLoading}
            />
          ) : null}

          <CompanyEditButton
            disabled={!manage}
            company={CompanyMapper.toCompanyFormDTO(company)}
            onSuccess={handleUpdateCompany}
            onCancel={daleyFocus}
          />

          {company?.uuid !== defaultCompanyUuid ? (
            <CompaniesDeleteButton
              disabled={!manage}
              companies={[company]}
              onSuccess={(list) => handleDeleteCompanies(head(list))}
            />
          ) : null}
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.profile}`);
  }, [navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.companies]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.companies]))}
      headerRight={
        <>
          {selectedCompanies.length ? (
            <CompaniesDeleteButton
              disabled={!manage}
              tooltipTitle={t('Delete companies')}
              companies={selectedCompanies}
              onSuccess={(list) => handleDeleteCompanies(head(list))}
            />
          ) : (
            <CompanyCreateButton
              tooltipTitle={t('Create company')}
              disabled={!manage}
              onSuccess={handleCreateCompany}
              loading={loading}
              className="company-create-event"
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        companies?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any company at the moment.`,
            )}>
            <CompanyCreateButton
              type="primary"
              disabled={!manage}
              onSuccess={handleCreateCompany}
              loading={loading}
              title={t('Add Company')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <StyledHeader>
          <ProfileHeader withoutUpload profile={profile} />

          <ListActions
            inputRef={inputRef}
            withoutPicker={true}
            loading={loading}
            handleSearch={handleSearchCompanies}
            inputTooltip={t('Search companies')}
            inputLabel={t('Search companies')}
            withSearchContent={
              <StyledCompanyCreateButton
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateCompany}
                title={t('Create company')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            }
          />
        </StyledHeader>
      }
      loading={loading && !companies?.size}>
      <Table<CompanyModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={companies}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
