import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useModal } from '@hooks';
import { PlanTariffFormDTO } from '@structure';
import styled, { css } from 'styled-components';
import { isFunction } from '@services/helpers';
import { SuspenseEmpty } from '@components/lib/DataDisplay';

const SubscriptionTariffSideWindowLazy = React.lazy(
  () => import('../Show/SubscriptionTariffSideWindow'),
);

export default withTranslation()(ChangeSubscriptionTariffButton);

export interface IChangeSubscriptionTariffButtonProps
  extends WithTranslation,
    ButtonProps {
  onSuccess: (value: PlanTariffFormDTO) => Promise<void>;
  onCancel?: () => void;
  subscription: PlanTariffFormDTO;
  title: string;
  disabled?: boolean;
  $size?: number;
  autoWidth?: boolean;
  link?: boolean;
}

const StyledActiveButton = styled(Button)<{
  disabled: boolean;
  $size?: number;
  $autoWidth?: boolean;
  $link?: boolean;
}>`
  width: 100%;
  border-color: ${({ theme }) => theme.colors.success};
  background-color: ${({ theme }) => theme.colors.success};

  &&& {
    height: 60px;

    ${({ $size }) =>
      $size &&
      css`
        height: ${$size}px;
      `}
  }

  ${({ $autoWidth }) =>
    $autoWidth &&
    css`
      width: auto;
    `}

  ${({ $link }) =>
    $link &&
    css`
      border-color: ${({ theme }) => theme.colors.link};
      background-color: ${({ theme }) => theme.colors.link};
    `}

  ${({ disabled, $link }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        border-color: ${({ theme }) =>
          $link ? theme.colors.link : theme.colors.success};
        background-color: ${({ theme }) =>
          $link ? theme.colors.link : theme.colors.success};
        opacity: 0.7;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border: none;
    `}
`;

function ChangeSubscriptionTariffButton({
  t,
  loading = false,
  onSuccess,
  subscription,
  onCancel,
  title,
  disabled,
  onClick,
  $size,
  autoWidth,
  link,
}: IChangeSubscriptionTariffButtonProps) {
  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActiveButton
        $link={link}
        $autoWidth={autoWidth}
        $size={$size}
        size="large"
        type="primary"
        onClick={(e: any) => {
          if (isFunction(onClick)) {
            onClick(e);
          }
          handleOnInit();
        }}
        disabled={!!disabled}>
        {title}
      </StyledActiveButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <SubscriptionTariffSideWindowLazy
          subscription={subscription}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
