import { List } from 'immutable';
import {
  WorkScheduleDTO,
  IWorkScheduleDTOProps,
  WorkScheduleModel,
  WorkScheduleListModel,
} from '../internal';
import { listToArray } from '@services/helpers';

export class WorkScheduleMapper {
  public static toWorkScheduleDTO(
    workSchedule: WorkScheduleModel | IWorkScheduleDTOProps,
  ): WorkScheduleDTO {
    return new WorkScheduleDTO({
      uuid: workSchedule?.uuid,
      title: workSchedule?.title,
      description: workSchedule?.description,
      rest_days: workSchedule?.rest_days,
      is_default: workSchedule?.is_default,
      work_days: workSchedule?.work_days,
      pos: workSchedule?.pos,
      status: workSchedule?.status,
      created_at: workSchedule?.created_at,
      updated_at: workSchedule?.updated_at,
    });
  }

  public static toWorkScheduleListDTO(
    workSchedules: List<WorkScheduleModel> | IWorkScheduleDTOProps[],
  ): WorkScheduleDTO[] {
    const workScheduleList = List.isList(workSchedules)
      ? listToArray<WorkScheduleModel>(workSchedules)
      : workSchedules;

    return workScheduleList?.map((workSchedule) =>
      WorkScheduleMapper.toWorkScheduleDTO(workSchedule),
    );
  }

  public static toWorkScheduleModel(
    workScheduleDTO: WorkScheduleDTO,
  ): WorkScheduleModel {
    return new WorkScheduleModel(workScheduleDTO);
  }

  public static toWorkScheduleListModel(
    workScheduleDTOs: WorkScheduleDTO[],
  ): WorkScheduleListModel {
    return new WorkScheduleListModel({ schedules: workScheduleDTOs });
  }
}
