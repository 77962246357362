import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import Button, { IButtonProps } from '../../DataDisplay/Button';

export interface ITableButtonCellProps extends IButtonProps {
  children: React.ReactNode;
  tooltip: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  color: ${({ theme }) => theme.colors.link};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.disabled};
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.darkenLink};
  }
`;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 10%;
`;

export function TableButtonCell({
  children,
  tooltip,
  icon,
  ...rest
}: ITableButtonCellProps) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltip)}>
      <StyledButton {...rest} type="text">
        <StyledSpace>
          {children}
          {icon || <RightOutlined />}
        </StyledSpace>
      </StyledButton>
    </Tooltip>
  );
}
