import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { AbonementGroupModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface ITariffModelGroupDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  groups: AbonementGroupModel[];
}

export default function TariffModelGroupsDeleteButton({
  children,
  groups,
  onSuccess,
  onError,
  title,
  ...rest
}: ITariffModelGroupDeleteButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: groups,
    notifyTitle: 'Tariff model groups',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete tariff model groups')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(groups)} ${
            len(groups)
              ? t('tariff model groups').toLowerCase()
              : t('tariff models group').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
