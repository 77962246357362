import { List, Map } from 'immutable';
import { APP_STATE, PRODUCT_CATEGORY_SUB_CATEGORY_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  ProductCategoryModel,
  ProductCategoryListModel,
  ProductCategoryMapper,
} from '@structure';

interface IProductCategoryProductListState {
  categoryList: List<ProductCategoryModel> | null;
  cachedProductList: Map<string, ProductCategoryListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList
  extends Pick<IProductCategoryProductListState, 'total'> {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.SET_PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  parentCategoryUuid: string;
  categoryList: ProductCategoryListModel;
  keywords?: string;
  page: number;
  parentCategory?: ProductCategoryModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_SUB_CATEGORY;
  category: ProductCategoryModel;
  parentCategoryUuid: string;
}

interface UpdateProductCategoryProductFromList {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_SUB_CATEGORY;
  category: ProductCategoryModel;
  parentCategoryUuid: string;
}

interface DeleteActionFromList {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_SUB_CATEGORY;
  categoryUuid: string;
  parentCategoryUuid: string;
}

interface LoadingActionInList {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
}

interface ErrorActionInList
  extends Pick<IProductCategoryProductListState, 'error'> {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
}

interface Handlers {
  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.SET_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]: (
    state: IProductCategoryProductListState,
    action: SetActionList,
  ) => IProductCategoryProductListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductCategoryProductListState,
    action: SetInitialStateAction,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOAD_MORE_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]: (
    state: IProductCategoryProductListState,
    action: SetActionList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state: IProductCategoryProductListState,
    action: AddActionToList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state: IProductCategoryProductListState,
    action: UpdateProductCategoryProductFromList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state: IProductCategoryProductListState,
    action: DeleteActionFromList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]: (
    state: IProductCategoryProductListState,
    value: ErrorActionInList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]: (
    state: IProductCategoryProductListState,
    value?: LoadingActionInList,
  ) => IProductCategoryProductListState;
  DEFAULT: (
    state: IProductCategoryProductListState,
  ) => IProductCategoryProductListState;
}

const initState: IProductCategoryProductListState = {
  categoryList: null,
  cachedProductList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.SET_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]: (
    state,
    { categoryList, parentCategoryUuid, keywords, page, parentCategory },
  ) => {
    const updatedList = categoryList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1)
      .update('parentCategory', () => parentCategory);

    return {
      ...state,
      ...{
        categoryList: categoryList?.categories || null,
        cachedProductList: categoryList
          ? state.cachedProductList.set(parentCategoryUuid, updatedList)
          : state.cachedProductList,
        status: REDUX_STATUS.SUCCEEDED,
        total: categoryList?.total! >= 0 ? categoryList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOAD_MORE_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]:
    (state, { categoryList, parentCategoryUuid }) => {
      const relaterScheduleListById =
        state.cachedProductList.get(parentCategoryUuid);

      return {
        ...state,
        ...{
          categoryList:
            List.isList(state.categoryList) && List.isList(categoryList)
              ? state.categoryList.merge(categoryList.categories)
              : state.categoryList,
          cachedProductList: categoryList
            ? state.cachedProductList.set(
                parentCategoryUuid,
                relaterScheduleListById
                  ? relaterScheduleListById.update('categories', (categories) =>
                      categories.merge(categoryList.categories),
                    )
                  : categoryList,
              )
            : state.cachedProductList,
          status: REDUX_STATUS.SUCCEEDED,
        },
      };
    },

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state,
    { category, parentCategoryUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedProductList.get(parentCategoryUuid);

    return {
      ...state,
      ...{
        categoryList: List.isList(state.categoryList)
          ? state.categoryList.unshift(category)
          : List([category]),
        cachedProductList: relaterScheduleListById
          ? state.cachedProductList.update(
              parentCategoryUuid,
              (categoryList) => {
                if (categoryList) {
                  return categoryList
                    .update('categories', (categories) =>
                      categories.unshift(category),
                    )
                    .update('total', (total) => (total || 0) + 1);
                } else {
                  return ProductCategoryMapper.toProductCategoryListModel(
                    [category] as any,
                    1,
                  );
                }
              },
            )
          : Map({
              [parentCategoryUuid]:
                ProductCategoryMapper.toProductCategoryListModel(
                  [category] as any,
                  1,
                ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state: IProductCategoryProductListState,
    { category, parentCategoryUuid }: UpdateProductCategoryProductFromList,
  ) => {
    const relaterScheduleListById =
      state.cachedProductList.get(parentCategoryUuid);

    return {
      ...state,
      ...{
        categoryList: List.isList(state.categoryList)
          ? state.categoryList.map((stateProductCategoryProduct) => {
              if (stateProductCategoryProduct.uuid === category?.uuid) {
                return stateProductCategoryProduct.merge(category);
              }
              return stateProductCategoryProduct;
            })
          : List([category]),

        cachedProductList: relaterScheduleListById
          ? state.cachedProductList.update(
              parentCategoryUuid,
              (categoryList) => {
                if (categoryList) {
                  return categoryList.update('categories', (categories) =>
                    categories.map((categoryModel) => {
                      if (categoryModel.uuid === category.uuid) {
                        return categoryModel.merge(category);
                      } else {
                        return categoryModel;
                      }
                    }),
                  );
                } else {
                  return ProductCategoryMapper.toProductCategoryListModel(
                    [],
                    0,
                  );
                }
              },
            )
          : state.cachedProductList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_SUB_CATEGORY]: (
    state,
    { categoryUuid },
  ) => {
    return {
      ...state,
      ...{
        categoryList: List.isList(state.categoryList)
          ? state.categoryList.filter(compose(not, isEqualByUuid(categoryUuid)))
          : null,
        cachedProductList: state.cachedProductList.map((categories) =>
          categories.update('categories', (list) => {
            const index = list.findIndex(({ uuid }) => uuid === categoryUuid);

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]:
    (
      state: IProductCategoryProductListState,
      { error }: ErrorActionInList,
    ) => ({
      ...state,
      ...{
        error,
        status: REDUX_STATUS.FAILED,
      },
    }),

  [PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_SUB_CATEGORY_LIST]:
    (state: IProductCategoryProductListState) => ({
      ...state,
      ...{
        loading: true,
        status: REDUX_STATUS.LOADING,
      },
    }),
  DEFAULT: (state: IProductCategoryProductListState) => state,
};

export default function ProductCategoryProductList(
  state: any = initState,
  action: any,
): IProductCategoryProductListState {
  const handler =
    handlers[action.type as PRODUCT_CATEGORY_SUB_CATEGORY_LIST] ||
    handlers.DEFAULT;
  return handler(state, action);
}
