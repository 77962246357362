import * as React from 'react';
import { List } from 'immutable';
import {
  ApiError,
  IEntityProps,
  IListSearchProps,
  PERSONALITY_TYPE,
} from '@services/types';
import { IServiceListDTO, ServiceMapper, ServiceModel } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getServiceList } from '@services/api/services';

export interface IUseServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  personality?: PERSONALITY_TYPE;
  minKeywordLength?: number;
}

export interface IUseServiceListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ServiceModel> | null;
  refresh: (
    value: Partial<IUseServiceListProps>,
  ) => Promise<List<ServiceModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  personality: PERSONALITY_TYPE;
  minKeywordLength?: number;
}

export function useServiceList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    personality,
    minKeywordLength = 0,
  }: IUseServiceListProps = {} as IUseServiceListProps,
): IUseServiceListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    personality: listPersonality,
    minKeywordLength: listMinKeywordLength,
    handleUpdate,
  } = useStateReducer<Omit<IUseServiceListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    personality: personality || PERSONALITY_TYPE.UNSET,
    minKeywordLength,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      personality = listPersonality,
      minKeywordLength = listMinKeywordLength || 0,
    }: Partial<IUseServiceListProps> = {}): Promise<List<ServiceModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          personality,
          minKeywordLength,
        });

        if (keywords?.length >= minKeywordLength) {
          const { services, total } = await cancellablePromise<IServiceListDTO>(
            getServiceList({
              companyUuid,
              personality,
              limit,
              offset,
              keywords,
            }),
          );

          const serviceListModel = ServiceMapper.toServiceListModel(
            services,
            total,
          );

          if (!didCancel.current) {
            handleUpdate({
              entityList: serviceListModel?.services,
              total: serviceListModel?.total,
              loading: false,
            });

            return serviceListModel?.services;
          }
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listMinKeywordLength,
      listOffset,
      listPersonality,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    personality: listPersonality,
    minKeywordLength: listMinKeywordLength,
  };
}
