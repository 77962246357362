import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IDefaultTabPanelProps } from '@components/lib/General';
import { Routes } from '@services/types';
import { useNavigate } from 'react-router';
import { ProfileDeleteTab } from './ProfileDeleteTab';
import { EmployeeRole, ProfileModel } from '@structure';
import { IoCheckmarkDoneSharp, IoWarningOutline } from 'react-icons/io5';
import { MdBlock } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { ProfileActiveSubscriptionTab } from './ProfileActiveSubscriptionTab';
import { useStoredPlanList } from '@hooks';
import { systemTitles } from '@services/const';

export default withTranslation()(ProfileTabPanel);

export interface IProfileTabPanelProps extends WithTranslation {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & {
      topTabs?: IDefaultTabPanelProps['tabs'];
    },
  ) => React.ReactNode;
  handleDeleteProfile: () => Promise<void>;
  profile: ProfileModel | null;
}

export enum PROFILE_TAB {
  NOTIFICATIONS = 'notifications',
  COMPANIES = 'companies',
  SUBSCRIPTIONS = 'subscriptions',
  ACCOUNT_OPERATION = 'account_operation',
  DELETE_PROFILE = 'Delete profile',
  SUBSCRIPTION = 'Subscription',
}

function ProfileTabPanel({
  t,
  children,
  handleDeleteProfile,
  profile,
}: IProfileTabPanelProps): JSX.Element {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const { planList } = useStoredPlanList({ loadOnInit: false });

  const plan = planList?.first();

  /**
   * @desc Список вкладок для Користувача
   * */
  const profileTabs = [
    {
      key: PROFILE_TAB.COMPANIES,
      title: t('Companies'),
      renderer: () => <></>,
      navigation: () => {
        navigate(Routes.companies);
      },
    },
    {
      key: PROFILE_TAB.NOTIFICATIONS,
      title: t('Notifications'),
      navigation: () => {
        navigate(Routes.notifications);
      },
      renderer: () => <></>,
    },
    // {
    //   key: PROFILE_TAB.SUBSCRIPTIONS,
    //   title: t('Subscriptions'),
    //   refresh: systemSubscriptionListRefresh,
    //   renderer: () => (
    //     <SystemSubscriptionList
    //       handleSubscriptionCancel={handleSubscriptionCancel}
    //       responsive={responsive}
    //       refresh={systemSubscriptionListRefresh}
    //       {...systemSubscriptions}
    //     />
    //   ),
    // },
    {
      key: PROFILE_TAB.ACCOUNT_OPERATION,
      title: t('Account operation'),
      navigation: () => {
        navigate(Routes.operations);
      },
      renderer: () => <></>,
    },
    {
      key: PROFILE_TAB.DELETE_PROFILE,
      title: t(PROFILE_TAB.DELETE_PROFILE),
      renderer: () => (
        <ProfileDeleteTab handleDeleteProfile={handleDeleteProfile} />
      ),
      type: 'error' as any,
    },
  ];

  const profileTopTabs = [
    ...(profile?.isAdmin
      ? [
          {
            key: PROFILE_TAB.SUBSCRIPTIONS,
            defaultActiveKey: PROFILE_TAB.SUBSCRIPTIONS,
            title: plan?.isTrialSubscription
              ? `${t('Tariff plan')}: ${t(
                  (systemTitles as any)[plan?.identifier as any],
                )}`
              : plan?.isSubscriptionExpired
              ? t('Current subscription is expired')
              : `${t('Tariff plan')}: ${t(
                  (systemTitles as any)[plan?.identifier as any],
                )}`,
            index: 0,
            ...(plan?.getFirstSubscription()?.date_end
              ? {
                  description: plan?.isShowSubscriptionExpiredTime
                    ? `${t(
                        'Subscription will expire in ',
                      )} ${plan?.subscriptionExpiredAt(t)}`
                    : plan?.isSubscriptionExpired
                    ? `${t('Expires on')} ${
                        plan?.getFirstSubscription().date_end
                      }`
                    : `${t('The subscription is activated until')}: ${
                        plan?.getFirstSubscription()?.date_end
                      }`,
                }
              : {}),
            buttonIcon: plan?.isTrialSubscription ? (
              <IoWarningOutline size={24} color={theme.colors.white} />
            ) : plan?.isSubscriptionExpired ? (
              <MdBlock size={24} color={theme.colors.white} />
            ) : (
              <IoCheckmarkDoneSharp size={24} color={theme.colors.white} />
            ),
            type: (plan?.isTrialSubscription
              ? 'warning'
              : plan?.isSubscriptionExpired ||
                plan?.isShowSubscriptionExpiredTime
              ? 'error'
              : 'success') as any,
            renderer: () => {
              return (
                <ProfileActiveSubscriptionTab
                  profile={profile}
                  plan={plan || null}
                />
              );
            },
          },
        ]
      : []),
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({ tabs: profileTabs, topTabs: profileTopTabs })}</>;
}
