import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len, eq } from '@services/helpers';
import { ExpenseModel } from '@structure';
import { useDelete } from '@components/lib/libV2/hooks';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IExpensesDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  expenses: ExpenseModel[];
  title?: string;
}

export function ExpensesDeleteButton({
  children,
  expenses = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IExpensesDeleteButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: expenses,
    notifyTitle: 'Services or goods',
  });

  const { reportNS, tReady, defaultNS, i18nOptions, ...restProps }: any = rest;
  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete services or goods')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(expenses)}</b>{' '}
          {eq(len(expenses), 1)
            ? t('services or goods')
            : t('services or goods')}
          ?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
