import React, { useCallback } from 'react';
import { Row } from 'antd';
import styled, { css } from 'styled-components';
import moment from 'moment';
import {
  IMonthFormat,
  ITimeInDay,
  calculateColSpan,
  getHourFromTime,
  split,
  head,
  isDateInThePastFromNow,
  IDayTimeRange,
  sub,
  add,
  greaterOrEqualThan,
  lessOrEqualThan,
} from '@services/helpers';
import CalendarHourCol from './CalendarHourCol';
import CalendarEventCol from './CalendarEventCol';
import { CALENDAR_ROW_HEIGHT } from '../../lib/const';

import './CalendarRow.less';

export interface ICalendarRowProps {
  dates: IMonthFormat[];
  hourInDay: ITimeInDay;
  showTimeLine?: boolean;
  showTimeLimit?: boolean;
  withAddingEvents?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const StyledCalendarRow = styled(Row)<{ height: string }>`
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;

interface TimeLimit {
  weekdayInfo: IDayTimeRange | undefined;
  hourInDay: ITimeInDay;
  isToday: boolean;
}

const StyledContainer = styled.div`
  padding-left: 10px;
  .ant-popover-inner {
    padding: 0;
  }

  @media (max-width: 475px) {
    padding-left: 25px;
  }
`;

export default function CalendarRow({
  dates,
  hourInDay,
  showTimeLine,
  withAddingEvents,
  loading,
  disabled,
}: ICalendarRowProps): JSX.Element {
  const getLastTime = useCallback((time = ''): string => {
    const hour = head(split(':', time));

    return time ? `${hour}:00` : '23:00';
  }, []);

  const isShowTimeLimit = useCallback(
    ({ weekdayInfo, hourInDay, isToday }: TimeLimit): boolean =>
      greaterOrEqualThan(
        sub(getHourFromTime(weekdayInfo?.start), 1),
        getHourFromTime(hourInDay.time),
      ) ||
      lessOrEqualThan(
        add(getHourFromTime(weekdayInfo?.end), 1),
        getHourFromTime(hourInDay.time),
      ) ||
      (isToday &&
        greaterOrEqualThan(
          sub(getHourFromTime(moment().format('HH:mm')), 1),
          getHourFromTime(hourInDay.time),
        )),
    [],
  );

  const isTodayTimeLimit = useCallback(
    ({ weekdayInfo, hourInDay, isToday }: TimeLimit) =>
      isToday &&
      !(
        greaterOrEqualThan(
          sub(getHourFromTime(weekdayInfo?.start), 1),
          getHourFromTime(hourInDay.time),
        ) ||
        lessOrEqualThan(
          add(getHourFromTime(weekdayInfo?.end), 1),
          getHourFromTime(hourInDay.time),
        )
      ) &&
      greaterOrEqualThan(
        sub(getHourFromTime(moment().format('HH:mm')), 1),
        getHourFromTime(hourInDay.time),
      ),
    [],
  );

  return (
    <StyledContainer id="calendar-event-display">
      <StyledCalendarRow
        height={`${CALENDAR_ROW_HEIGHT}px`}
        className="calendar-row">
        <CalendarHourCol span={2} hourInDay={hourInDay} />
        {dates.map(({ events, isToday, weekdayInfo, date }, index) => (
          <CalendarEventCol
            disabled={disabled}
            showTimeLimit={isShowTimeLimit({ weekdayInfo, isToday, hourInDay })}
            isTodayTimeLimit={isTodayTimeLimit({
              weekdayInfo,
              isToday,
              hourInDay,
            })}
            key={String(`${index}`)}
            span={calculateColSpan(22, dates)}
            events={loading ? {} : events}
            hourInDay={hourInDay}
            isToday={isToday}
            showTimeLine={showTimeLine}
            weekdayInfo={weekdayInfo}
            lastTime={getLastTime(weekdayInfo?.end)}
            withAddingEvents={withAddingEvents}
            isPastDate={isDateInThePastFromNow(date)}
            loading={loading}
          />
        ))}
      </StyledCalendarRow>
    </StyledContainer>
  );
}
