import React from 'react';
import { Routes } from 'react-router';
import { useStoreAppState } from '@hooks';
import { Routes as RoutesType } from '@services/types';
import { Route } from 'react-router-dom';
import { CalendarRoutes } from './CalendarRoutes';
import { DashboardPage } from '../views/Dashboard';
import { CompanyRoutesNavigator } from './CompanyRoutesNavigator';
import { ServiceRoutesNavigator } from './ServiceRoutesNavigator';
import { DepartmentRoutesNavigator } from './DepartmentRoutesNavigator';
import { IntegrationRoutesNavigator } from './IntegrationRoutesNavigator';
import {
  AppRouteLayout,
  AuthRouteLayout,
  WithoutLayout,
} from '@components/lib/Layout';
import { TransactionsPage } from '../views/Transactions';
import { OrderRoutesNavigator } from './OrderRoutesNavigator';
import { CategoryListPage } from '../views/Categories';
import { UserListPage, UserPage } from '../views/Users';
import { EmployeeRoutesNavigator } from './EmployeeRoutesNavigator';
import { ClientRoutesNavigator } from './ClientRoutesNavigator';
import { ProfileRoutesNavigator } from './ProfileRoutesNavigator';
import {
  BillOfLadingListPage,
  CounterpartyListPage,
  CounterpartyPage,
  ExpenseInvoiceListPage,
  ExpenseInvoicePage,
  GoodsListPage,
  GoodsPage,
  RevenueInvoiceListPage,
} from '../views/Stoke';
import { AppInfoPage } from '../views/AppInfo';
import {
  PrivacyAndGDPRSPage,
  SubscriptionExpiredPage,
  TermsAndConditionsPage,
} from '../views/Authorization';
import { AppIntroPage } from '../views/AppIntro';
import { PaymentRoutesNavigator } from './PaymentRoutesNavigator';
import { SettingsRoutesNavigator } from './SettingsRoutesNavigator';
import { ReportingRoutesNavigator } from './ReportingRoutesNavigator';
import { TariffModelRoutesNavigator } from './TariffModelRoutesNavigator';
import { StatusRoutesNavigator } from './StatusRoutesNavigator';
import { SubscriptionRoutesNavigator } from './SubscriptionRoutesNavigator';
import { CompanyClientAbonementRoutesNavigator } from './CompanyClientAbonementRoutesNavigator';
import { ProductRoutesNavigator } from './ProductRoutesNavigator';
import { GroupPaymentPage, TemplatePage } from '../views/Settings';
import { StoreRoutesNavigator } from './StoreRoutesNavigator';
import { InvoiceRoutesNavigator } from './InvoiceRoutesNavigator';
import { PriceMarginRoutesNavigator } from './PriceMarginRoutesNavigator';
import { NotFoundPage } from '../views/NotFound';
import { AccessDeniedPage } from '../views/AccessDenied';

export function AppRoutes(): JSX.Element {
  useStoreAppState();

  return (
    <Routes>
      {/* ------------------- SUBSCRIPTION EXPIRED ------------------- */}
      <Route
        path={RoutesType.subscriptionExpired}
        element={
          <AppRouteLayout>
            <SubscriptionExpiredPage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- DASHBOARD ------------------- */}

      <Route
        path={`${RoutesType.dashboard}/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={DashboardPage} />}
      />

      {/* ------------------- COMPANIES ------------------- */}

      <Route
        path={`${RoutesType.companies}/*`}
        element={<CompanyRoutesNavigator />}
      />

      {/* ------------------- Services ------------------- */}

      <Route
        path={`${RoutesType.services}/*`}
        element={<ServiceRoutesNavigator />}
      />

      {/*/!* ------------------- REPORTS ------------------- *!/*/}

      {/*<Route*/}
      {/*  path={RoutesType.reports}*/}
      {/*  element={*/}
      {/*    <AppRouteLayout>*/}
      {/*      <ReportsTabPage />*/}
      {/*    </AppRouteLayout>*/}
      {/*  }*/}
      {/*/>*/}

      {/*<Route*/}
      {/*  path={`reports/:tabsId`}*/}
      {/*  element={*/}
      {/*    <AppRouteLayout>*/}
      {/*      <ReportsTabPage />*/}
      {/*    </AppRouteLayout>*/}
      {/*  }*/}
      {/*/>*/}

      {/* ------------------- SUBSCRIPTIONS ------------------- */}

      <Route
        path={`subscriptions/:subscriptionId/transactions`}
        element={
          <AppRouteLayout>
            <TransactionsPage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- ORDERS ------------------- */}

      <Route
        path={`${RoutesType.orders}/*`}
        element={<OrderRoutesNavigator />}
      />

      {/* ------------------- PAYMENTS ------------------- */}

      <Route
        path={`${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />

      {/* ------------------- SETTINGS ------------------- */}

      <Route
        path={`${RoutesType.settings}/*`}
        element={<SettingsRoutesNavigator />}
      />

      {/* ------------------- CATEGORIES ------------------- */}

      <Route
        path={RoutesType.categories}
        element={
          <AppRouteLayout>
            <CategoryListPage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- USERS ------------------- */}

      <Route
        path={RoutesType.users}
        element={
          <AppRouteLayout>
            <UserListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.users}/show/:userId/:tabsId`}
        element={
          <AppRouteLayout>
            <UserPage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- EMPLOYEES ------------------- */}

      <Route
        path={`${RoutesType.employees}/*`}
        element={<EmployeeRoutesNavigator />}
      />

      {/* ------------------- CLIENTS ------------------- */}

      <Route
        path={`${RoutesType.clients}/*`}
        element={<ClientRoutesNavigator />}
      />

      {/* ------------------- COMPANY CLIENTS ABONEMENTS ------------------- */}

      <Route
        path={`${RoutesType.clientAbonements}/*`}
        element={<CompanyClientAbonementRoutesNavigator />}
      />

      {/* ------------------- PROFILE ------------------- */}

      <Route
        path={`${RoutesType.profile}/*`}
        element={<ProfileRoutesNavigator />}
      />

      {/* ------------------- SUBSCRIPTION ------------------- */}

      <Route
        path={`${RoutesType.subscription}/*`}
        element={<SubscriptionRoutesNavigator />}
      />

      {/* ------------------- PRODUCTS ------------------- */}

      <Route
        path={`${RoutesType.goods}/*`}
        element={<ProductRoutesNavigator />}
      />

      {/* ------------------- REPORTING ------------------- */}

      <Route
        path={`${RoutesType.reports}/*`}
        element={<ReportingRoutesNavigator />}
      />

      {/* ------------------- TARIFF MODELS ------------------- */}

      <Route
        path={`${RoutesType.tariffModels}/*`}
        element={<TariffModelRoutesNavigator />}
      />

      {/* ------------------- GROUP PAYMENTS ------------------- */}

      <Route
        path={RoutesType.groupPayment}
        element={
          <AppRouteLayout>
            <GroupPaymentPage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- TEMPLATES ------------------- */}

      <Route
        path={RoutesType.mailingTemplates}
        element={
          <AppRouteLayout>
            <TemplatePage />
          </AppRouteLayout>
        }
      />

      {/* ------------------- STATUSES ------------------- */}

      <Route
        path={`${RoutesType.customStatuses}/*`}
        element={<StatusRoutesNavigator />}
      />

      {/* ------------------- DEPARTMENTS ------------------- */}

      <Route
        path={`${RoutesType.departments}/*`}
        element={<DepartmentRoutesNavigator />}
      />

      {/* ------------------- INTEGRATIONS ------------------- */}

      <Route
        path={`${RoutesType.integrations}/*`}
        element={<IntegrationRoutesNavigator />}
      />

      {/* ------------------- STORES ------------------- */}

      <Route
        path={`${RoutesType.stores}/*`}
        element={<StoreRoutesNavigator />}
      />

      {/* ------------------- PRICE MARGIN ------------------- */}

      <Route
        path={`${RoutesType.priceMargins}/*`}
        element={<PriceMarginRoutesNavigator />}
      />

      {/* ------------------- INVOICES ------------------- */}

      <Route
        path={`${RoutesType.invoices}/*`}
        element={<InvoiceRoutesNavigator />}
      />

      {/* ------------------- STOKE ------------------- */}

      <Route
        path={`stoke/*`}
        element={
          <Routes>
            {/* BillOfLadingList Pages */}

            <Route
              path={`bill-of-lading`}
              element={
                <AppRouteLayout>
                  <BillOfLadingListPage />
                </AppRouteLayout>
              }
            />

            <Route
              path={`bill-of-lading/:billOfLadingId/:tabsId`}
              element={
                <AppRouteLayout>
                  <BillOfLadingListPage />
                </AppRouteLayout>
              }
            />

            {/* Counterparty Pages */}

            <Route
              path={`counterparties`}
              element={
                <AppRouteLayout>
                  <CounterpartyListPage />
                </AppRouteLayout>
              }
            />
            <Route
              path={`counterparties/:counterpartyId/:tabsId`}
              element={
                <AppRouteLayout>
                  <CounterpartyPage />
                </AppRouteLayout>
              }
            />

            {/* ExpenseInvoice Pages */}

            <Route
              path={`expense-invoices`}
              element={
                <AppRouteLayout>
                  <ExpenseInvoiceListPage />
                </AppRouteLayout>
              }
            />

            <Route
              path={`expense-invoices/:expenseInvoiceId/:tabsId`}
              element={
                <AppRouteLayout>
                  <ExpenseInvoicePage />
                </AppRouteLayout>
              }
            />

            {/* RevenueInvoice Pages */}

            <Route
              path={`revenue-invoices`}
              element={
                <AppRouteLayout>
                  <RevenueInvoiceListPage />
                </AppRouteLayout>
              }
            />

            <Route
              path={`revenue-invoices/:revenueInvoicesId/:tabsId`}
              element={
                <AppRouteLayout>
                  <ExpenseInvoicePage />
                </AppRouteLayout>
              }
            />

            {/* Goods Pages */}

            <Route
              path={`goods`}
              element={
                <AppRouteLayout>
                  <GoodsListPage />
                </AppRouteLayout>
              }
            />
            <Route
              path={`goods/:goodsId/:tabsId`}
              element={
                <AppRouteLayout>
                  <GoodsPage />
                </AppRouteLayout>
              }
            />
          </Routes>
        }
      />

      {/* ------------------- INFO ------------------- */}

      <Route
        path={RoutesType.privacyAndGDPRS}
        element={
          <AuthRouteLayout>
            <PrivacyAndGDPRSPage />
          </AuthRouteLayout>
        }
      />

      <Route
        path={RoutesType.termsAndConditions}
        element={
          <AuthRouteLayout>
            <TermsAndConditionsPage />
          </AuthRouteLayout>
        }
      />

      <Route
        path={RoutesType.appInfo}
        element={
          <AuthRouteLayout>
            <AppInfoPage />
          </AuthRouteLayout>
        }
      />

      <Route
        path={RoutesType.appIntro}
        element={
          <AuthRouteLayout withBackButton={false}>
            <AppIntroPage />
          </AuthRouteLayout>
        }
      />

      {/* ------------------- ACCESS DENIED ------------------- */}

      <Route
        path={RoutesType.accessDenied}
        element={
          <AppRouteLayout>
            <AccessDeniedPage />
          </AppRouteLayout>
        }
      />

      <Route
        path="*"
        element={
          <WithoutLayout>
            <NotFoundPage />
          </WithoutLayout>
        }
      />
    </Routes>
  );
}
