import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { PAYMENT_INITIAL_PARAM } from '@services/api/companyPayments';
import { PaymentFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { PaymentFields } from './PaymentFields';
import { Routes } from '@services/types';

export interface IPaymentFormProps
  extends Omit<
    IDefaultFormProps<PaymentFormDTO, PaymentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  payment?: PaymentFormDTO;
  companyUuid?: string;
  from?: Routes.clients | Routes.orders;
}

export default function PaymentForm({
  loading,
  editMode,
  payment = PAYMENT_INITIAL_PARAM,
  from,
  ...rest
}: IPaymentFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
    }),
    [payment?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Payment'),
        `${
          editMode
            ? t('An error occurred during edit payment')
            : t('An error occurred during create payment')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm<any, any>
      withContext
      initialValues={{
        ...payment,
      }}
      isResetLoading={false}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      {...rest}>
      <PaymentFields from={from} editMode={!!editMode} />
    </DefaultForm>
  );
}
