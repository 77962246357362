import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StoreDocumentFormDTO } from '@structure';
import { IUseStateStoredDocumentReturnType } from '@hooks';

const StoreWriteOffSideWindowLazy = React.lazy(
  () => import('../Show/StoreWriteOffSideWindow'),
);

export interface IStoreWriteOffCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleCreateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
}

export function StoreWriteOffCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  ...rest
}: IStoreWriteOffCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create write off')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreWriteOffSideWindowLazy
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
