import * as React from 'react';
import { List } from 'immutable';
import {
  useTariffModelGroupClientsAbonementList,
  IUseTariffModelGroupClientsAbonementListProps,
  IUseTariffModelGroupClientsAbonementListReturnType,
} from './useTariffModelGroupClientsAbonementList';
import { AbonementGroupModel, ClientAbonementModel } from '@structure';
import { deleteClientAbonement } from '@services/api/client';
import {
  setClientAbonementList as storeSetClientAbonementList,
  deleteClientAbonementFromList as storeDeleteClientAbonement,
  loadMoreClientAbonementList as storeLoadMoreClientAbonementList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateTariffModelGroupClientsAbonementListProps
  extends IUseTariffModelGroupClientsAbonementListProps {}

export interface IUseStateTariffModelGroupClientsAbonementListReturnType
  extends Omit<
    IUseTariffModelGroupClientsAbonementListReturnType,
    'entityList'
  > {
  abonementList: List<ClientAbonementModel> | null;
  handleDeleteClientAbonements: (ids: string[]) => Promise<void>;
  handleSearchClientAbonements: (keywords: string) => Promise<void>;
  handleLoadMoreClientAbonements: () => Promise<void>;
  handleRefreshClientAbonements: (
    value: Partial<IUseTariffModelGroupClientsAbonementListProps> & {
      page: number;
    },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  tariffModelGroup: AbonementGroupModel | null;
}

export function useStoredTariffModelGroupClientsAbonementList(
  {
    groupUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateTariffModelGroupClientsAbonementListProps = {} as IUseStateTariffModelGroupClientsAbonementListProps,
): IUseStateTariffModelGroupClientsAbonementListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [abonementList, setAbonementListList] =
    useState<List<ClientAbonementModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [tariffModelGroup, setTariffModelGroup] =
    useState<AbonementGroupModel | null>(null);

  const {
    status: storedClientAbonementListStatus,
    loading: storedClientAbonementListLoading,
    cachedAbonementList,
    ...storedClientAbonementListParams
  } = useSelector(({ clientAbonementList }: RootState) => clientAbonementList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: abonementListLoading,
    total,
    ...abonementsParams
  } = useTariffModelGroupClientsAbonementList({
    groupUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedAbonementList?.size > 0) {
      const abonementList = cachedAbonementList.get(groupUuid);

      if (abonementList) {
        setIsStartLoading(false);
        setAbonementListList(abonementList?.abonements);
        setTariffModelGroup(abonementList?.company_abonement_group);
        setStorePage(abonementList?.page);
        setStoreKeywords(abonementList?.keywords);
        setStoreTotal(abonementList?.total || 0);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedAbonementList?.size === 0 && !abonementList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedAbonementList, groupUuid, storeTotal, abonementList]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({ groupUuid });

        if (entityList) {
          dispatch(storeSetClientAbonementList(entityList, groupUuid, ''));
        }
      })();
    }
  }, [groupUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteClientAbonements = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteClientAbonement(ids);

        setTimeout(() => {
          dispatch(storeDeleteClientAbonement(head(ids), groupUuid));
        }, 100);
        alert(
          'success',
          t('Client abonement'),
          t('Client abonement delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Client abonement'),
          `${t('An error occurred during delete client abonements')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, groupUuid],
  );

  const handleSearchClientAbonements = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetClientAbonementList(
            abonementListModel,
            groupUuid,
            keywords,
            abonementListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [groupUuid, dispatch, refresh],
  );

  const handleLoadMoreClientAbonements = useCallback(async () => {
    if (
      List.isList(abonementList) &&
      abonementList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: abonementList?.size,
        limit: 10,
        showLoading: false,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeLoadMoreClientAbonementList(abonementListModel, groupUuid),
        );
      }

      setLoadingMore(false);
    }
  }, [abonementList, storeTotal, loadingMore, refresh, dispatch, groupUuid]);

  const handleRefreshClientAbonements = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseTariffModelGroupClientsAbonementListProps> & {
      page: number;
    }) => {
      const abonementListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetClientAbonementList(
            abonementListModel,
            groupUuid,
            storeKeywords,
            abonementListModel?.total,
            page,
          ),
        );
      }
    },
    [groupUuid, dispatch, refresh, storeKeywords],
  );

  return {
    ...abonementsParams,
    ...storedClientAbonementListParams,
    abonementList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(abonementList) && abonementListLoading) ||
      !storedClientAbonementListLoading,
    handleDeleteClientAbonements,
    handleSearchClientAbonements,
    handleLoadMoreClientAbonements,
    status: storedClientAbonementListStatus,
    handleRefreshClientAbonements,
    keywords: storeKeywords || '',
    page: storePage || 1,
    tariffModelGroup,
  };
}
