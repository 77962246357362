import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ButtonSize } from 'antd/lib/button';
import { len } from '@services/helpers';

import './ActionMenu.less';

export default ActionMenu;

export interface IActionMenuItemProps {
  waitForConfirm?: boolean;
  children: React.ReactElement;
}

ActionMenu.Item = ({
  waitForConfirm,
  children,
  ...rest
}: IActionMenuItemProps): JSX.Element => {
  let newChildren;
  if (waitForConfirm) {
    newChildren = React.cloneElement(children, {
      onClick: (e: any, ...args: any) => {
        e.stopPropagation();
        if (children?.props?.onClick) {
          children.props.onClick(e, ...args);
        }
      },
    });
  }
  return <Menu.Item {...rest}>{newChildren || children} </Menu.Item>;
};

export interface IActionMenuProps {
  children: React.ReactNode[];
  visibleItemsCount?: number;
  moreSize?: ButtonSize;
  morebuttonprops?: any;
}

/**
 * @name ActionMenu
 * @desc ActionMenu component.
 */
function ActionMenu({
  children,
  visibleItemsCount = 2,
  moreSize = 'small',
  ...rest
}: IActionMenuProps & any): JSX.Element {
  const [visibleItems, setVisibleItems] = useState<React.ReactNode[]>([]);
  const [hiddenItems, setHiddenItems] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    const childrenArr = len(children) ? children : [children];
    setVisibleItems(childrenArr.slice(0, visibleItemsCount));
    setHiddenItems(childrenArr.slice(visibleItemsCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <Menu mode="horizontal" className="ActionMenu" selectable={false} {...rest}>
      {visibleItems}
      {hiddenItems.length > 0 ? (
        <Menu.Item key="more">
          <Dropdown
            overlay={<Menu>{hiddenItems}</Menu>}
            trigger={['hover']}
            placement="bottomRight"
            overlayStyle={{ zIndex: 1030 }}>
            <Button type="link" size={moreSize}>
              More <DownOutlined />
            </Button>
          </Dropdown>
        </Menu.Item>
      ) : null}
    </Menu>
  );
}
