import {
  RelatedServiceModel,
  ServiceFormDTO,
  ServiceMapper,
} from '../internal';
import { correctPrice } from '@services/helpers';

export class RelatedServiceFormDTO {
  public uuid: string;

  public service_uuid: ServiceFormDTO | string;

  public service_price: string;

  public service_quantity: string;

  public comment: string;

  constructor(props?: RelatedServiceModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.service_uuid = editMode
      ? ServiceMapper.toServiceFormDTO(props?.serviceModel)
      : props?.service?.uuid || '';
    this.service_price = correctPrice(props?.schedule_service_price);
    this.service_quantity = props?.schedule_service_quantity || '1';
    this.comment = props?.schedule_comment || '';
  }
}
