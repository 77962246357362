import {
  IClientOperationModelReturnType,
  IStoreDocumentDTOProps,
  ProfileDTO,
  ProfileMapper,
  StoreDocumentDTO,
  StoreDocumentMapper,
} from '../internal';
import { correctPrice } from '@services/helpers';
import { WithoutRecursion } from '../type';

export enum ClientOperationType {
  STORE_DOCUMENT = 'store_document',
}

export interface IClientOperationDTOProps {
  uuid: string;
  balance_before: number;
  created_at: string;
  updated_at: string;
  operation_comment: string;
  operation_sum: string;
  operation_triggerable_type: ClientOperationType | undefined;
  operation_triggerable_object: IStoreDocumentDTOProps | undefined;
}

export class ClientOperationDTO {
  public uuid: string;

  public balance_before: string;

  public created_at: string;

  public updated_at: string;

  public operation_comment: string;
  public operation_sum: string;
  public operation_triggerable_type: ClientOperationType | undefined;
  public operation_triggerable_object: StoreDocumentDTO | undefined;

  constructor(
    props: IClientOperationDTOProps | IClientOperationModelReturnType,
  ) {
    this.uuid = props?.uuid || '';
    this.balance_before = correctPrice(props?.balance_before || 0);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.operation_comment = props?.operation_comment || '';
    this.operation_sum = correctPrice(props?.operation_sum || 0);
    this.operation_triggerable_type =
      props?.operation_triggerable_type ?? undefined;
    this.operation_triggerable_object = StoreDocumentMapper.toStoreDocumentDTO(
      props?.operation_triggerable_object as IStoreDocumentDTOProps,
      [],
    );
  }
}
