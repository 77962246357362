import React from 'react';
import { useStoredCompanies } from '@hooks';
import {
  EmployeeMapper,
  EmployeeSalaryFormDTO,
  EmployeeSalaryModel,
} from '@structure';
import { List } from 'immutable';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { toDateByFormat } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { Empty, Tooltip } from 'antd';
import { RewardSalaryDeleteButton, RewardSalaryUpdateButton } from '../Buttons';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IRewardSalaryListProps {
  salary: List<EmployeeSalaryModel> | null;
  createEmployeeSalary: (salary: EmployeeSalaryFormDTO) => Promise<void>;
  updateEmployeeSalary: (value: EmployeeSalaryFormDTO) => Promise<void>;
  deleteEmployeeSalary: (value: EmployeeSalaryModel) => Promise<void>;
  salarySettingsDates: string[];
}

const StyledSumContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)``;

export function RewardSalaryList({
  salary,
  salarySettingsDates,
  updateEmployeeSalary,
  deleteEmployeeSalary,
}: IRewardSalaryListProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const columns = [
    {
      ellipsis: true,
      title: t('Applied from date'),
      key: 'date',
      render: (salary: EmployeeSalaryModel) =>
        `${t(
          toDateByFormat(salary?.start_date, 'MMMM')?.toLowerCase(),
        )}/${toDateByFormat(salary?.start_date, 'Y')}`,
    },
    {
      ellipsis: true,
      title: `${t('Salary sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      align: 'right' as any,
      render: (salary: EmployeeSalaryModel) => (
        <StyledSumContainer>
          <StyledTitle>{`${salary?.sum || '0.00'}`}</StyledTitle>
          {salary?.comment ? (
            <Tooltip title={salary?.comment}>
              <StyledInfoCircleOutlined />
            </Tooltip>
          ) : null}
        </StyledSumContainer>
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      width: '80px',
      key: 'service_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (salary: EmployeeSalaryModel) => {
        return (
          <TableActionCell>
            <RewardSalaryUpdateButton
              disabled={!salary?.enabled}
              salary={EmployeeMapper.toEmployeeSalaryFormDTO(salary)}
              onSuccess={updateEmployeeSalary}
              salarySettingsDates={salarySettingsDates}
            />
            <RewardSalaryDeleteButton
              disabled={!salary?.enabled}
              salary={salary}
              onSuccess={deleteEmployeeSalary}
            />
          </TableActionCell>
        );
      },
    },
  ];

  return (
    <Table<EmployeeSalaryModel>
      pageSize={10}
      total={salary?.size || 0}
      dataSource={salary}
      columns={columns}
      loading={false}
      renderEmpty={<Empty description={t('There are no salary created')} />}
      rowKey="start_date"
    />
  );
}
