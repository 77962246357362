import { List, Map } from 'immutable';
import { APP_STATE, CLIENT_ABONEMENT_SUBSCRIPTION_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  ClientAbonementSubscriptionListModel,
  ClientAbonementMapper,
  ClientAbonementSubscriptionModel,
} from '@structure';

interface IClientAbonementSubscriptionListState {
  subscriptionList: List<ClientAbonementSubscriptionModel> | null;
  cachedSubscriptionList: Map<string, ClientAbonementSubscriptionListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
}

interface SetActionList
  extends Pick<
    IClientAbonementSubscriptionListState,
    'keywords' | 'total' | 'page'
  > {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.SET_CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  abonementUuid: string;
  subscriptionList: ClientAbonementSubscriptionListModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ADD_CLIENT_ABONEMENT_SUBSCRIPTION;
  subscription: ClientAbonementSubscriptionModel;
  abonementUuid: string;
}

interface UpdateClientAbonementSubscriptionFromList {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.UPDATE_CLIENT_ABONEMENT_SUBSCRIPTION;

  subscription: ClientAbonementSubscriptionModel;
  abonementUuid: string;
}

interface DeleteActionFromList {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.DELETE_CLIENT_ABONEMENT_SUBSCRIPTION;
  subscriptionUuid: string;
  abonementUuid: string;
}

interface LoadingActionInList {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOADING_CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
}

interface ErrorActionInList
  extends Pick<IClientAbonementSubscriptionListState, 'error'> {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ERROR_CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
}

interface Handlers {
  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.SET_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]: (
    state: IClientAbonementSubscriptionListState,
    action: SetActionList,
  ) => IClientAbonementSubscriptionListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientAbonementSubscriptionListState,
    action: SetInitialStateAction,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOAD_MORE_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]: (
    state: IClientAbonementSubscriptionListState,
    action: SetActionList,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ADD_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state: IClientAbonementSubscriptionListState,
    action: AddActionToList,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.UPDATE_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state: IClientAbonementSubscriptionListState,
    action: UpdateClientAbonementSubscriptionFromList,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.DELETE_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state: IClientAbonementSubscriptionListState,
    action: DeleteActionFromList,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ERROR_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]: (
    state: IClientAbonementSubscriptionListState,
    value: ErrorActionInList,
  ) => IClientAbonementSubscriptionListState;

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOADING_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]: (
    state: IClientAbonementSubscriptionListState,
    value?: LoadingActionInList,
  ) => IClientAbonementSubscriptionListState;
  DEFAULT: (
    state: IClientAbonementSubscriptionListState,
  ) => IClientAbonementSubscriptionListState;
}

const initState: IClientAbonementSubscriptionListState = {
  subscriptionList: null,
  cachedSubscriptionList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
  page: 1,
};

const handlers: Handlers = {
  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.SET_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]: (
    state,
    { subscriptionList, abonementUuid, keywords, page },
  ) => {
    const cachedClientAbonementSubscriptionListListById =
      state.cachedSubscriptionList.get(abonementUuid);

    return {
      ...state,
      ...{
        subscriptionList: subscriptionList?.subscriptions || null,
        cachedSubscriptionList:
          !cachedClientAbonementSubscriptionListListById && subscriptionList
            ? state.cachedSubscriptionList.set(abonementUuid, subscriptionList)
            : state.cachedSubscriptionList,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total:
          subscriptionList?.total! >= 0
            ? subscriptionList?.total!
            : state.total,
        page: page || state?.page,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOAD_MORE_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]:
    (state, { subscriptionList, abonementUuid }) => {
      const relaterScheduleListById =
        state.cachedSubscriptionList.get(abonementUuid);

      return {
        ...state,
        ...{
          subscriptionList:
            List.isList(state.subscriptionList) && List.isList(subscriptionList)
              ? state.subscriptionList.merge(subscriptionList.subscriptions)
              : state.subscriptionList,
          cachedSubscriptionList: subscriptionList
            ? state.cachedSubscriptionList.set(
                abonementUuid,
                relaterScheduleListById
                  ? relaterScheduleListById.update(
                      'subscriptions',
                      (subscriptions) =>
                        subscriptions.merge(subscriptionList.subscriptions),
                    )
                  : subscriptionList,
              )
            : state.cachedSubscriptionList,
          status: REDUX_STATUS.SUCCEEDED,
        },
      };
    },

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ADD_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state,
    { subscription, abonementUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedSubscriptionList.get(abonementUuid);

    return {
      ...state,
      ...{
        subscriptionList: List.isList(state.subscriptionList)
          ? state.subscriptionList.unshift(subscription)
          : List([subscription]),
        cachedSubscriptionList: relaterScheduleListById
          ? state.cachedSubscriptionList.update(
              abonementUuid,
              (subscriptionList) => {
                if (subscriptionList) {
                  return subscriptionList.update(
                    'subscriptions',
                    (subscriptions) => subscriptions.push(subscription),
                  );
                } else {
                  return ClientAbonementMapper.toClientAbonementSubscriptionListModel(
                    [subscription] as any,
                    1,
                  );
                }
              },
            )
          : Map({
              [abonementUuid]:
                ClientAbonementMapper.toClientAbonementSubscriptionListModel(
                  [subscription] as any,
                  1,
                ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.UPDATE_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state: IClientAbonementSubscriptionListState,
    { subscription, abonementUuid }: UpdateClientAbonementSubscriptionFromList,
  ) => {
    const relaterScheduleListById =
      state.cachedSubscriptionList.get(abonementUuid);

    return {
      ...state,
      ...{
        subscriptionList: List.isList(state.subscriptionList)
          ? state.subscriptionList.map((stateClientAbonementSubscription) => {
              if (
                stateClientAbonementSubscription.uuid === subscription?.uuid
              ) {
                return stateClientAbonementSubscription.merge(subscription);
              }
              return stateClientAbonementSubscription;
            })
          : List([subscription]),

        cachedSubscriptionList: relaterScheduleListById
          ? state.cachedSubscriptionList.update(
              abonementUuid,
              (subscriptionList) => {
                if (subscriptionList) {
                  return subscriptionList.update(
                    'subscriptions',
                    (subscriptions) =>
                      subscriptions.map((subscription) => {
                        if (subscription.uuid === subscription.uuid) {
                          return subscription.merge(subscription);
                        } else {
                          return subscription;
                        }
                      }),
                  );
                } else {
                  return ClientAbonementMapper.toClientAbonementSubscriptionListModel(
                    [],
                    0,
                  );
                }
              },
            )
          : state.cachedSubscriptionList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.DELETE_CLIENT_ABONEMENT_SUBSCRIPTION]: (
    state,
    { subscriptionUuid },
  ) => {
    return {
      ...state,
      ...{
        subscriptionList: List.isList(state.subscriptionList)
          ? state.subscriptionList.filter(
              compose(not, isEqualByUuid(subscriptionUuid)),
            )
          : null,
        cachedSubscriptionList: state.cachedSubscriptionList.map(
          (subscriptions) =>
            subscriptions.update('subscriptions', (list) => {
              const index = list.findIndex(
                ({ uuid }) => uuid === subscriptionUuid,
              );

              if (~index) {
                return list.delete(index);
              }

              return list;
            }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ERROR_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]:
    (
      state: IClientAbonementSubscriptionListState,
      { error }: ErrorActionInList,
    ) => ({
      ...state,
      ...{
        error,
        status: REDUX_STATUS.FAILED,
      },
    }),

  [CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOADING_CLIENT_ABONEMENT_SUBSCRIPTION_LIST]:
    (state: IClientAbonementSubscriptionListState) => ({
      ...state,
      ...{
        loading: true,
        status: REDUX_STATUS.LOADING,
      },
    }),
  DEFAULT: (state: IClientAbonementSubscriptionListState) => state,
};

export default function ClientAbonementSubscriptionList(
  state: any = initState,
  action: any,
): IClientAbonementSubscriptionListState {
  const handler =
    handlers[action.type as CLIENT_ABONEMENT_SUBSCRIPTION_LIST] ||
    handlers.DEFAULT;
  return handler(state, action);
}
