import React, { useCallback, useState } from 'react';
import { Empty, Form } from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import { PriceTagModel } from '@structure';
import { useTranslation } from 'react-i18next';
import { useDefaultForm, useDropdownAlert } from '@contex';
import styled, { useTheme } from 'styled-components';
import { ProductDetailsView } from '../../Show';
import { IUseStatePriceTagListReturnType } from '@hooks';
import { Button } from '@components/lib/DataDisplay';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import '../../../Orders/List/OrderList.less';
import './PriceTagTableProductListField.less';

export interface IPriceTagTableProductListFieldProps
  extends Pick<IUseStatePriceTagListReturnType, 'handleDeletePriceTags'> {
  focus: () => void;
  loading?: boolean;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<PriceTagModel>>;

const StyledDeleteButton = styled(Button)`
  &&&&& {
    background-color: transparent;
  }
`;

export function PriceTagTableProductListField({
  focus,
  loading: defaultLoading,
  handleDeletePriceTags,
}: IPriceTagTableProductListFieldProps): React.JSX.Element {
  const { t } = useTranslation();
  const { formData, handlerUpdateFormState, loadingSubmit }: any =
    useDefaultForm();
  const { alert } = useDropdownAlert();
  const theme: any = useTheme();

  const [loading, setLoading] = useState<string>('');

  const handleDeleteDocItem = useCallback(
    async (priceTag: PriceTagModel) => {
      const items = formData?.items?.filter(
        ({ uuid }: PriceTagModel) => uuid !== priceTag?.uuid,
      );

      try {
        setLoading(priceTag?.uuid);
        await handleDeletePriceTags(priceTag?.uuid);
        setLoading('');
      } catch (error: any) {
        setLoading('');
        alert(
          'error',
          t('Store document item'),
          `${t('An error occurred during delete store document item')}: ${
            error?.message
          }`,
        );
        return;
      }

      handlerUpdateFormState({
        items,
        total: formData?.total > 0 ? (formData?.total || 0) - 1 : 0,
      });
    },
    [
      alert,
      formData?.items,
      formData?.total,
      handleDeletePriceTags,
      handlerUpdateFormState,
      t,
    ],
  );

  const columns = [
    {
      title: t('name-s'),
      key: 'name',
      className: 'order-list-td price-tag-list-td--full',
      render: (item: PriceTagModel) => (
        <ProductDetailsView product={item?.productModel} withTour />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td price-tag-list-td',
      render: (item: PriceTagModel) => {
        return (
          <TableActionCell>
            <StyledDeleteButton
              onClick={async () => {
                await handleDeleteDocItem(item);
                focus();
              }}
              danger
              type="text">
              {loading === item?.uuid ? (
                <LoadingOutlined />
              ) : (
                <DeleteOutlined color={theme.colors.error} />
              )}
            </StyledDeleteButton>
          </TableActionCell>
        );
      },
    },
  ];

  return (
    <>
      <Form.Item name="items">
        <StyledTable
          loading={defaultLoading || loadingSubmit}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={formData?.items}
          columns={columns}
          total={formData?.items?.length}
          pageSize={Infinity}
          renderEmpty={
            <Empty
              description={t(
                'It looks like you have not selected any products',
              )}
            />
          }
        />
      </Form.Item>
    </>
  );
}
