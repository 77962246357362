import { Dispatch } from 'redux';
import { List } from 'immutable';
import { SETTINGS_MODULE_LIST } from '../constants';
import { SettingsModuleModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetSettingsModuleListParam {
  type: SETTINGS_MODULE_LIST;
  settingsModuleList: List<SettingsModuleModel>;
  keywords?: string;
  total?: number;
}

export interface IAddSettingsModuleToListParam {
  type: SETTINGS_MODULE_LIST;
  settingsModule: SettingsModuleModel;
}

export interface IUpdateSettingsModuleFromListParam {
  type: SETTINGS_MODULE_LIST;
  settingsModule: SettingsModuleModel;
}

export interface IErrorSettingsModuleListParam {
  type: SETTINGS_MODULE_LIST;
  error: ApiError | undefined;
}

export function setSettingsModuleList(
  settingsModuleList: List<SettingsModuleModel>,
  keywords?: string,
  total?: number,
): (dispatch: Dispatch) => Promise<ISetSettingsModuleListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE_LIST.SET_SETTINGS_MODULE_LIST,
      settingsModuleList,
      keywords,
      total,
    });
  };
}

export function loadMoreSettingsModuleList(
  settingsModuleList: List<SettingsModuleModel>,
): (dispatch: Dispatch) => Promise<ISetSettingsModuleListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE_LIST.LOAD_MORE_SETTINGS_MODULE_LIST,
      settingsModuleList,
    });
  };
}

export function addSettingsModuleToList(
  settingsModule: SettingsModuleModel,
): (dispatch: Dispatch) => Promise<IAddSettingsModuleToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE_LIST.ADD_SETTINGS_MODULE_LIST,
      settingsModule,
    });
  };
}

export function updateSettingsModuleFromList(
  settingsModule: SettingsModuleModel,
): (dispatch: Dispatch) => Promise<IUpdateSettingsModuleFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE_LIST.UPDATE_SETTINGS_MODULE_LIST,
      settingsModule,
    });
  };
}

export function errorSettingsModuleInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorSettingsModuleListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE_LIST.ERROR_SETTINGS_MODULE_LIST,
      error,
    });
  };
}
