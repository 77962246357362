import React from 'react';
import styled from 'styled-components';
import { StyledDescription } from '../Styled/Text';
export interface TableIndexFieldProps {
  number: number;
  fontSize?: number;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export function TableIndexField({
  number,
  fontSize,
}: TableIndexFieldProps): React.JSX.Element {
  return (
    <StyledContainer>
      <StyledDescription fontSize={fontSize}>{`${number}.`}</StyledDescription>
    </StyledContainer>
  );
}
