import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Select, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
import styled, { css } from 'styled-components';
import { List } from 'immutable';
import { WorkScheduleModel } from '@structure';

const { Option } = Select;

const NativeSelect = styled(Select)<{ disabled?: boolean }>`
  min-width: 150px;

  ${({ disabled }) =>
    disabled &&
    css`
      && .ant-select-selector {
        background-color: ${({ theme }) => theme.colors.lightDisable};
        border-color: ${({ theme }) => theme.colors.lightDisable};

        & > span {
          color: ${({ theme }) => theme.colors.lightDisable};
        }
      }
    `}
`;

export default withTranslation()(EmployeeWorkScheduleList);

export interface IEmployeeWorkScheduleListProps
  extends WithTranslation,
    SelectProps<any> {
  loading: boolean;
  workScheduleList: List<WorkScheduleModel>;
}

function EmployeeWorkScheduleList({
  t,
  loading,
  workScheduleList,
  ...rest
}: IEmployeeWorkScheduleListProps) {
  const { reportNS, tReady, defaultNS, i18nOptions, ...restProps }: any = rest;

  return (
    <Tooltip title={t('Choose a work schedule for an employee')}>
      <NativeSelect size="small" {...restProps}>
        {workScheduleList.map(({ uuid, title }) => (
          <Option key={uuid} value={uuid}>
            {t(title)}
          </Option>
        ))}
      </NativeSelect>
    </Tooltip>
  );
}
