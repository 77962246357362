import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultTabPanelProps, TabItem } from '@components/lib/General';
import { findIndex, FunctionArgs, eq } from '@services/helpers';
import { Routes, RoutesAcl } from '@services/types';
import { useLocation, useNavigate } from 'react-router';
import { useSystemModules } from '@hooks';
import { ClientGroupMembersList, IClientGroupMembersListProps } from '../List';

export enum CLIENT_TABS {
  ORDERS = 'orders',
  ABONEMENTS = 'Abonements',
  ORDER_LIST = 'order_list',
  CALENDAR = 'calendar',
  SUBSCRIPTIONS = 'subscriptions',
  FINANCIAL_STATEMENTS = 'financial_statements',
  ORDERED_SERVICES = 'ordered_services',
  PAYMENTS = 'Mutual settlements',
  GROUP_MEMBERS = 'Group members',
  FILES = 'Downloaded content',
}

export interface IClientTabPanelProps extends IClientGroupMembersListProps {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & { calendarIndex: number },
  ) => React.ReactNode;
  timeRange?: string;
  clientFullName: string;
  isGroupType: boolean;
  isIndividualType: boolean;
}

export default function ClientTabPanel({
  children,
  timeRange,
  clientFullName,
  isGroupType,
  isIndividualType,
  handleUpdateClientGroup,
  client,
}: IClientTabPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { modulesMap } = useSystemModules();

  const clientTabs = [
    ...(isGroupType
      ? [
          {
            defaultActiveKey: CLIENT_TABS.GROUP_MEMBERS,
            key: CLIENT_TABS.GROUP_MEMBERS,
            title: t(CLIENT_TABS.GROUP_MEMBERS),
            disabled: false,
            refresh: () => {},
            renderer: () => (
              <ClientGroupMembersList
                client={client}
                handleUpdateClientGroup={handleUpdateClientGroup}
              />
            ),
            aclItem: RoutesAcl[Routes.clientAbonements],
          },
        ]
      : []),
    {
      key: CLIENT_TABS.ORDER_LIST,
      title: t('Orders'),
      disabled: false,
      refresh: () => {},
      navigation: () => {
        navigate(Routes.clientSchedules, {
          state: { timeRange, clientFullName },
        });
      },
      renderer: () => <></>,
    },
    ...(modulesMap?.abonement?.enabled && isIndividualType
      ? [
          {
            key: CLIENT_TABS.ABONEMENTS,
            title: t(CLIENT_TABS.ABONEMENTS),
            disabled: false,
            refresh: () => {},
            navigation: () => {
              navigate(Routes.clientAbonements, { state: { clientFullName } });
            },
            renderer: () => <></>,
            aclItem: RoutesAcl[Routes.clientAbonements],
          },
        ]
      : []),
    {
      key: CLIENT_TABS.PAYMENTS,
      title: t(CLIENT_TABS.PAYMENTS),
      disabled: false,
      refresh: () => {},
      navigation: () => {
        navigate(`${Routes.payments}/${Routes.clients}`, {
          state: { from: location.pathname },
        });
      },
      renderer: () => <></>,
    },
    {
      key: CLIENT_TABS.ORDERS,
      title: t('Service delivery schedule'),
      disabled: false,
      refresh: async () => {},
      navigation: () => {
        navigate(Routes.schedules, {
          state: { timeRange, clientFullName, goBack: location.pathname },
        });
      },
      renderer: () => <></>,
    },
    {
      key: CLIENT_TABS.FILES,
      title: t(CLIENT_TABS.FILES),
      disabled: false,
      refresh: () => {},
      navigation: () => {
        navigate(`${Routes.files}`, {
          state: { from: location.pathname },
        });
      },
      renderer: () => <></>,
    },
  ];

  const calendarIndex = findIndex<
    [FunctionArgs<TabItem, boolean>, TabItem[]],
    number
  >(({ key }: TabItem) => eq(key, CLIENT_TABS.ORDERS), clientTabs);

  return <>{children({ tabs: clientTabs, calendarIndex })}</>;
}
