import { Record, List } from 'immutable';
import { IListProps } from './List';
import { InvitationDTO, InvitationModel } from '../internal';

export interface IInvitationListModelProps extends IListProps {
  invitation: InvitationDTO[];
}

export interface IInvitationListModelReturnType extends IListProps {
  invitation: List<InvitationModel>;
}

export class InvitationListModel extends Record<IInvitationListModelReturnType>(
  {
    invitation: List(),
  },
) {
  constructor(
    {
      invitation,
      ...props
    }: IInvitationListModelProps = {} as IInvitationListModelProps,
  ) {
    super({
      ...props,
      invitation: List(invitation.map((invite) => new InvitationModel(invite))),
    });
  }
}
