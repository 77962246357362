import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewLayout } from '@components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredAbonement,
  useStoredCompanies,
} from '@hooks';
import { ABONEMENT_REQUIRED_FIELD } from '@services/api/abonement';
import { TariffModelTabPanel } from '../Show';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { Routes, RoutesAcl } from '@services/types';
import { AbonementMapper, AbonementModel, AbonementFormDTO } from '@structure';
import { useAcl } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import { TariffModelEditButton } from '../Buttons';
import { HeaderLeft, Switch } from '@components/lib/DataDisplay';
import { Table } from '@components/lib/libV2/DataDisplay';
import { Col, Row, Space } from 'antd';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { Text } from '@components/lib/Format';
import styled, { useTheme } from 'styled-components';
import { fromBooleanToNumber, isThereContent } from '@services/helpers';
import { IOrderRequiredFieldsWithFullName } from '@services/api/orders';
import { ServiceDetailsView, ServicePersonalityView } from '../../Services';
import { EmployeeDetailsView } from '../../Employees';

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const Description = styled(StyledDescription)`
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 5px;
`;

const StyledTable = styled(Table)`
  &&& {
    max-width: 200px;

    .ant-table-cell,
    .ant-table-tbody {
      background-color: ${({ theme }) => theme.background.layout};
    }
  }
`;

export default function TariffModelPage(): JSX.Element {
  const { tariffModelId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { abonement: abonementAccess } = useAcl((acl) => acl);
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();

  const [isAbonementBlock, setIsAbonementBlock] = useState<null | boolean>(
    null,
  );
  const [isAbonementBlockLoading, setIsAbonementBlockLoading] = useState(false);

  const {
    abonement,
    loading: abonementLoading,
    error: abonementError,
    handleUpdateAbonement,
    handleChangeAbonementStatus,
  } = useStoredAbonement({
    abonementUuid: tariffModelId!,
    companyUuid: defaultCompanyUuid,
  });

  const changeAbonementStatus = useCallback(
    async (value: boolean) => {
      if (abonement) {
        setIsAbonementBlockLoading(true);
        setIsAbonementBlock(value);

        const abonementModel = await handleChangeAbonementStatus(
          abonement?.uuid,
          fromBooleanToNumber(value),
        );

        if (!(abonementModel instanceof AbonementModel)) {
          setIsAbonementBlock(!value);
        }

        setIsAbonementBlockLoading(false);
      }
    },
    [abonement, handleChangeAbonementStatus],
  );

  const loading = useStopLoading({
    loading: abonementLoading,
    error: abonementError,
    message: 'An error occurred during abonement loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Tariff models',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}`,
      breadcrumbName: abonement ? abonement?.abon_title : 'Show tariff model',
    },
  ]);

  const changeFields = useCallback(
    (
      {
        manager,
        service,
        abon_price,
        abon_timing_list,
        abon_period_amount,
        abon_title,
        abon_can_refund_money,
        abon_session_price,
        abon_max_paused_period_type,

        ...rest
      }: typeof ABONEMENT_REQUIRED_FIELD,
      abonement: AbonementModel,
    ): IOrderRequiredFieldsWithFullName & any => {
      const timingListSize = Object.keys(abon_timing_list || {}).length;

      const timingList = AbonementFormDTO.getTimingWeekList(abon_timing_list);

      const timingListArr = Object.entries(abon_timing_list || {}).reduce(
        (acc: any, [key, value]) => {
          acc = [
            ...acc,
            { day: t(key), time: `${value?.min} - ${value?.max}` },
          ];
          return acc;
        },
        [],
      );

      const timingListField = timingListSize ? (
        timingList ? (
          <StyledTitle>{`${t('Mon-Sun')}: ${timingList}`}</StyledTitle>
        ) : (
          <StyledTable
            total={timingListArr.length}
            pageSize={timingListArr.length}
            loading={false}
            rowKey="day"
            dataSource={timingListArr}
            columns={[
              {
                width: 'fit-content',
                title: t('Weekday'),
                key: 'Weekday',
                render: (c: any) => c?.day,
              },
              {
                width: 'fit-content',
                title: t('Time'),
                key: 'time',
                render: (c: any) => c?.time,
              },
            ]}
            size="small"
          />
        )
      ) : null;

      return {
        abon_title,
        abon_price: (
          <StyledTitle fontSize={30} $color={theme.colors.success}>
            {`${
              defaultCompany ? `${defaultCompany?.currency_symbol} ` : ''
            }${abon_price}`}
          </StyledTitle>
        ),
        ...(isThereContent(manager)
          ? {
              manager: (
                <EmployeeDetailsView employee={abonement?.managerModel} />
              ),
            }
          : []),
        Service: <ServiceDetailsView service={abonement?.serviceModel} />,
        abon_can_refund_money: (
          <StyledTitle
            $color={
              abonement?.abon_can_refund_money
                ? theme.colors.success
                : theme.colors.error
            }>
            {abonement?.abon_can_refund_money ? t('Allowed') : t('Block')}
          </StyledTitle>
        ),
        validity: `${abon_period_amount} ${t(
          `${abonement?.service?.period}`.toLowerCase(),
        )}`,
        abon_session_price: (
          <StyledTitle $color={theme.colors.success}>
            {`${
              defaultCompany ? `${defaultCompany?.currency_symbol} ` : ''
            }${abon_session_price}`}
          </StyledTitle>
        ),
        abon_max_paused_period_type: t(abon_max_paused_period_type),
        ...rest,
        ...(timingListSize ? { 'Time limit': timingListField } : []),
      };
    },
    [defaultCompany, t, theme.colors.error, theme.colors.success],
  );

  const correctAbonementRequiredFields = {
    ...ABONEMENT_REQUIRED_FIELD,
    abon_timing_list: {
      Sunday: '',
      Monday: '',
      Tuesday: '',
      Wednesday: '',
      Thursday: '',
      Friday: '',
      Saturday: '',
    },
    manager: {
      first_name: '',
      last_name: '',
      middle_name: '',
      phone: '',
      uuid: '',
    },
    service: {
      title: '',
      uuid: '',
    },
  };

  const overviewData = useRequiredFields(
    abonement as any,
    correctAbonementRequiredFields,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack || `/${Routes.app}/${Routes.tariffModels}`,
    );
  }, [location?.state?.goBack, navigate]);

  useEffect(() => {
    if (abonement && isAbonementBlock === null) {
      setIsAbonementBlock(!!abonement?.abon_status);
    }
  }, [abonement, isAbonementBlock]);

  return (
    <TariffModelTabPanel abonement={abonement}>
      {({ tabs }) => (
        <OverviewLayout
          moduleItem="abonement"
          aclItem={RoutesAcl[Routes.tariffModels]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <TariffModelEditButton
              abonement={AbonementMapper.toAbonementFormDTO(abonement!, true)}
              onSuccess={handleUpdateAbonement}
            />
          }
          headerTitle={t('Tariff model')}
          disabled={!abonementAccess?.manage}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabs={tabs}
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitle bold fontSize={20}>
                    {abonement?.abon_title}
                  </StyledTitle>
                  <ServicePersonalityView
                    service={abonement?.serviceModel || null}
                  />
                </Space>
              </Col>
              <Col span={24}>
                <StyledSwitchWrapper>
                  <StyledSwitch
                    onChange={changeAbonementStatus}
                    checked={!!isAbonementBlock}
                    disabled={
                      isAbonementBlockLoading || !abonementAccess?.manage
                    }
                  />
                  <Text>{t('Activated')}</Text>
                </StyledSwitchWrapper>
                <Description>
                  {t(
                    'This option controls the availability of the Tariff model for use by Customers.',
                  )}
                </Description>
              </Col>
            </Row>
          }
        />
      )}
    </TariffModelTabPanel>
  );
}
