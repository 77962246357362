import React from 'react';
import { AbonementModel } from '@structure';
import styled, { useTheme } from 'styled-components';
import { StyledDescription } from '@components/lib/Styled';
import { Routes } from '@services/types';
import { Link } from '@components/lib/DataDisplay';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { textToUpperCase, toDateByFormat } from '@services/helpers';
import { useStoredCompanies } from '@hooks';
import { ServicePersonalityView } from '../../Services';

export interface ITariffModelDetailsViewProps {
  tariffModel: AbonementModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export function TariffModelDetailsView({
  tariffModel,
}: ITariffModelDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { defaultCompany } = useStoredCompanies();
  const theme: any = useTheme();

  return (
    <>
      {tariffModel ? (
        <StyledContainer>
          <StyledSpace>
            <Link
              to={`/${Routes.app}/${Routes.tariffModels}/${tariffModel?.uuid}`}
              tooltip={t('Go to abonement details page')}
              state={{ goBack: location.pathname }}>
              <ServicePersonalityView
                onlyIcon
                service={tariffModel?.serviceModel || null}
              />
              {textToUpperCase(tariffModel?.abon_title)}
            </Link>
            <StyledDescription $color={theme.colors.success}>{`${t(
              'Base cost',
            )}: ${defaultCompany?.currency_symbol} ${
              tariffModel?.abon_price
            }`}</StyledDescription>
            <StyledDescription>{`${t('Period')}: ${
              tariffModel?.abon_period_amount
            } ${t(tariffModel?.serviceModel?.period)}`}</StyledDescription>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
