import React from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'immutable';
import { EditableCell } from '@components/lib/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { Table } from '@components/lib/libV2/DataDisplay';
import { isListToArray } from '@services/helpers';
import { EmployeeServicesEditButtons } from '../Buttons';
import {
  EmployeeMapper,
  ServiceListModel,
  ServiceModel,
  ServiceParametersFormDTO,
} from '@structure';
import { ServiceColorSchemaBox } from '../../Services';
import { ApiError, TIME_LIST_SERVICES } from '@services/types';
import { IUseEmployeeServiceListProps } from '../../../hooks/useEmployeeServiceList';
import { IUseStateEmployeeReturnType, useStoredCompanies } from '@hooks';
import styled from 'styled-components';
import { Tooltip } from 'antd';

export interface IEmployeeServiceListProps
  extends Pick<IUseStateEmployeeReturnType, 'employeeServiceBonuses'> {
  services: List<ServiceModel> | null;
  loading: boolean;
  handleAdd: (
    service: ServiceModel,
    employeeUuid?: string,
  ) => Promise<ApiError | void>;
  rowSelection: any;
  handleDelete: (
    ids: string[],
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  pageSize: number;
  total: number;
  refresh: (
    value: Partial<IUseEmployeeServiceListProps> & { page: number },
  ) => Promise<ServiceListModel | void>;
  selectedServices: ServiceModel[];
  disabled?: boolean;
  handleUpdate: (
    value: ServiceParametersFormDTO,
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  page?: number;
  daleyFocus: (ms?: number) => void;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > span:first-of-type {
    margin-right: 5px;
  }
`;

const Description = styled(StyledDescription)`
  text-decoration: line-through;
`;

export function EmployeeServiceList({
  services,
  loading,
  handleDelete,
  rowSelection,
  handleAdd,
  total,
  pageSize,
  disabled,
  refresh,
  handleUpdate,
  page = 1,
  employeeServiceBonuses,
  daleyFocus,
}: IEmployeeServiceListProps) {
  const { t } = useTranslation();

  const { defaultCompany } = useStoredCompanies();

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      width: '33.333%',
      render: (service: ServiceModel) => (
        <ServiceColorSchemaBox colorSchema={service?.color_schema}>
          {service.title}
        </ServiceColorSchemaBox>
      ),
    },
    {
      title: t('Price'),
      key: 'price',
      width: '33.333%',
      align: 'right' as any,
      render: (service: ServiceModel) => {
        const isDifferentPrice =
          service?.employee_service?.price &&
          service?.price &&
          Number(service?.employee_service?.price) !== Number(service?.price);

        return (
          <>
            {defaultCompany ? (
              <>
                {service?.employee_service?.updated_at && isDifferentPrice ? (
                  <StyledPriceContainer>
                    <Tooltip title={t("The price of an employee's service")}>
                      <StyledTitle>
                        {`${defaultCompany?.currency_symbol} ${
                          service?.employee_service?.price || '0.00'
                        } / `}
                      </StyledTitle>
                    </Tooltip>
                    <Tooltip title={t('The price of the service')}>
                      <Description fontSize={14}>
                        {service?.price || '0.00'}
                      </Description>
                    </Tooltip>
                  </StyledPriceContainer>
                ) : (
                  <StyledTitle>
                    {`${defaultCompany?.currency_symbol} ${
                      service?.price || '0.00'
                    }`}
                  </StyledTitle>
                )}
              </>
            ) : null}
          </>
        );
      },
    },
    {
      align: 'center' as any,
      title: t('Actions'),
      key: 'actions',
      fixed: 'right' as any,
      width: '33.333%',
      render: (service: ServiceModel) => {
        return (
          <EmployeeServicesEditButtons
            handleEdit={handleUpdate}
            item={EmployeeMapper.toEmployeeServiceFormDTO(service)}
            disabled={disabled}
            loadingSubmit={false}
            onDelete={() => handleDelete([service?.uuid])}
            handleAdd={() => handleAdd(service)}
            isOwnService={!!service?.employee_service?.updated_at}
            servicePeriod={service?.period || TIME_LIST_SERVICES.HOUR}
            service={service}
            employeeServiceBonuses={employeeServiceBonuses}
            daleyFocus={daleyFocus}
          />
        );
      },
    },
  ];

  return (
    <Table
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      loading={loading}
      dataSource={isListToArray(services as any)}
      columns={columns}
      rowSelection={rowSelection}
      total={total}
      pageSize={pageSize}
      onChange={refresh}
      page={page}
    />
  );
}
