import { Record, List } from 'immutable';
import { IListProps } from './List';
import { UserDTO, UserModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IUserListModelProps extends IListProps {
  users: UserDTO[];
}

export interface IUserListModelReturnType extends IListProps {
  users: List<UserModel>;
}

export class UserListModel extends Record<IUserListModelReturnType>({
  users: List(),
  total: 0,
}) {
  constructor(
    { users = [], total }: IUserListModelProps = {} as IUserListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IUserListModelReturnType = {
      total,
      users: List(),
    };

    if (Array.isArray(users)) {
      options.users = List(
        users.map((user) => new UserModel(user, withoutRecursion)),
      );
    }

    super({
      ...options,
    });
  }
}
