import { List, Record } from 'immutable';
import { IListProps } from './List';
import {
  InvoiceDTO,
  InvoiceMapper,
  InvoiceModel,
  InvoiceStats,
  InvoiceStatsModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface InvoiceListModelProps extends IListProps {
  invoices: (InvoiceDTO | InvoiceModel)[];
  keywords?: string;
  page?: number;
  stats?: InvoiceStatsModel;
}

export interface InvoiceListModelReturnType extends IListProps {
  invoices: List<InvoiceModel>;
  keywords: string;
  page: number;
  stats?: InvoiceStatsModel;
}

export class InvoiceListModel extends Record<InvoiceListModelReturnType>({
  invoices: List(),
  total: 0,
  keywords: '',
  page: 1,
  stats: InvoiceMapper.toInvoiceStatsModel({} as InvoiceStats),
}) {
  constructor(
    {
      invoices = [],
      ...props
    }: InvoiceListModelProps = {} as InvoiceListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: InvoiceListModelReturnType = {
      ...props,
      keywords: props?.keywords || '',
      page: props?.page || 1,
      invoices: List(
        invoices.map((invoice) =>
          invoice instanceof InvoiceModel
            ? invoice
            : new InvoiceModel(invoice, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
