import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  InvoiceListModel,
  InvoiceMappedType,
  InvoiceMapper,
  InvoiceModel,
  InvoiceStatsModel,
  InvoiceStatus,
  InvoiceType,
  MAPPED_INVOICE_TYPES,
} from '@structure';
import { REDUX_STATUS } from '@services/types';
import { RootState } from '../reducers';
import { List, Map } from 'immutable';
import { eq, neq } from '@services/helpers';

export interface InvoiceListState {
  cachedInvoiceMap: Map<InvoiceType | InvoiceMappedType, InvoiceListModel>;
  invoiceType: InvoiceType | InvoiceMappedType;
  invoiceStatus?: InvoiceStatus;
  loading: boolean;
  status: REDUX_STATUS;
}

export interface SetInvoiceListAction {
  invoiceList: InvoiceListModel;
  invoiceType: InvoiceType | InvoiceMappedType;
  invoiceStatus?: InvoiceStatus;
  keywords: string;
  page?: number;
}

export interface LoadingMoreInvoiceListAction {
  invoiceList: InvoiceListModel;
  invoiceType: InvoiceType | InvoiceMappedType;
  invoiceStatus?: InvoiceStatus;
}

export interface AddInvoiceAction {
  invoice: InvoiceModel;
  invoiceType: InvoiceType | InvoiceMappedType;
}

export interface InvoiceStatsAction {
  stats: InvoiceStatsModel;
}

export interface DeleteInvoiceAction {
  invoiceUuid: string;
  invoiceType: InvoiceType | InvoiceMappedType;
}

const initialState: InvoiceListState = {
  cachedInvoiceMap: Map(),
  loading: true,
  invoiceType: InvoiceType.IN,
  invoiceStatus: InvoiceStatus.UNKNOWN,
  status: REDUX_STATUS.IDLE,
};

export const invoiceListSlice = createSlice({
  name: 'invoiceList',
  initialState,
  reducers: {
    setInvoiceList: (
      state,
      { payload }: PayloadAction<SetInvoiceListAction>,
    ) => {
      const { invoiceList, invoiceType, invoiceStatus, page, keywords } =
        payload;
      const { cachedInvoiceMap } = state;

      if (invoiceList) {
        const updatedInvoiceList: any = invoiceList
          .update('keywords', () => keywords)
          .update('page', (initPage) => page || initPage);

        return {
          ...state,
          cachedInvoiceMap: cachedInvoiceMap.set(
            invoiceType,
            updatedInvoiceList,
          ),
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
          invoiceType,
          invoiceStatus,
        };
      }
    },

    setInitialState: () => {
      return initialState;
    },

    loadMoreInvoiceList: (
      state: any,
      { payload }: PayloadAction<LoadingMoreInvoiceListAction>,
    ) => {
      const { invoiceList, invoiceStatus, invoiceType } = payload;

      const { cachedInvoiceMap }: InvoiceListState = state;

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        invoiceType,
        invoiceStatus,
        cachedInvoiceMap: cachedInvoiceMap.update(invoiceType, (list) => {
          if (
            list &&
            List.isList(list?.invoices) &&
            List.isList(invoiceList?.invoices)
          ) {
            return list.update('invoices', (prevInvoices) =>
              prevInvoices.merge(invoiceList.invoices),
            );
          }

          return list;
        }),
      };
    },

    updateInvoiceStatsInList: (
      state: any,
      { payload }: PayloadAction<InvoiceStatsAction>,
    ) => {
      const { stats } = payload;

      let { cachedInvoiceMap }: InvoiceListState = state;

      const mappedInvoiceInTypes = MAPPED_INVOICE_TYPES[InvoiceType.IN] || [];
      const mappedInvoiceOutTypes = MAPPED_INVOICE_TYPES[InvoiceType.OUT] || [];

      const mappedInvoiceTypes = [
        ...mappedInvoiceInTypes,
        ...mappedInvoiceOutTypes,
      ];

      mappedInvoiceTypes.forEach(
        (invoiceType: InvoiceType | InvoiceMappedType) => {
          cachedInvoiceMap = cachedInvoiceMap?.update(invoiceType, (list) => {
            return list?.update('stats', () => stats);
          });
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedInvoiceMap,
      };
    },

    addInvoiceToList: (
      state: any,
      { payload }: PayloadAction<AddInvoiceAction>,
    ) => {
      const { invoice, invoiceType } = payload;

      let { cachedInvoiceMap }: InvoiceListState = state;

      const mappedInvoiceTypes = MAPPED_INVOICE_TYPES[
        invoiceType as InvoiceType
      ] || [invoiceType];

      mappedInvoiceTypes.forEach(
        (invoiceType: InvoiceType | InvoiceMappedType) => {
          cachedInvoiceMap = cachedInvoiceMap?.update(invoiceType, (list) => {
            if (list && List.isList(list?.invoices)) {
              return list
                .update('invoices', (invoices) => invoices.unshift(invoice))
                .update('total', (total = 0) => total + 1);
            }

            return InvoiceMapper.toInvoiceListModel([invoice], 1);
          });
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedInvoiceMap,
      };
    },

    updateInvoiceFromList: (
      state: any,
      { payload }: PayloadAction<AddInvoiceAction>,
    ) => {
      const { invoice, invoiceType } = payload;

      let { cachedInvoiceMap }: InvoiceListState = state;

      const mappedInvoiceTypes = MAPPED_INVOICE_TYPES[
        invoiceType as InvoiceType
      ] || [invoiceType];

      mappedInvoiceTypes.forEach(
        (invoiceType: InvoiceType | InvoiceMappedType) => {
          cachedInvoiceMap = cachedInvoiceMap?.update(invoiceType, (list) => {
            if (list && List.isList(list?.invoices)) {
              return list.update('invoices', (invoices) =>
                invoices?.map((prevInvoice) => {
                  if (eq(prevInvoice?.uuid, invoice?.uuid)) {
                    return prevInvoice?.merge(invoice);
                  }
                  return prevInvoice;
                }),
              );
            }

            return list;
          });
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedInvoiceMap,
      };
    },

    deleteInvoiceFromList: (
      state: any,
      { payload }: PayloadAction<DeleteInvoiceAction>,
    ) => {
      const { invoiceUuid, invoiceType } = payload;

      let { cachedInvoiceMap }: InvoiceListState = state;

      const mappedInvoiceTypes = MAPPED_INVOICE_TYPES[
        invoiceType as InvoiceType
      ] || [invoiceType];

      let isDelete = false;

      mappedInvoiceTypes.forEach(
        (invoiceType: InvoiceType | InvoiceMappedType) => {
          cachedInvoiceMap = cachedInvoiceMap?.update(invoiceType, (list) => {
            if (list && List.isList(list?.invoices)) {
              return list
                .update('invoices', (invoices) =>
                  invoices.filter(({ uuid }) => {
                    if (neq(invoiceUuid, uuid)) {
                      return true;
                    }

                    isDelete = true;

                    return false;
                  }),
                )
                .update('total', (total = 0) =>
                  total > 0 && isDelete ? total - 1 : total,
                );
            }

            return list;
          });
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedInvoiceMap,
      };
    },
  },
});

export const {
  setInvoiceList,
  setInitialState,
  loadMoreInvoiceList,
  addInvoiceToList,
  updateInvoiceFromList,
  deleteInvoiceFromList,
  updateInvoiceStatsInList,
} = invoiceListSlice.actions;

export const selectInvoiceList = ({ invoiceListReducer }: RootState) =>
  invoiceListReducer;

export default invoiceListSlice.reducer;
