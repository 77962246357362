import { useDropdownAlert } from '@contex';
import { CategoryFormDTO } from '@structure';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { DefaultForm } from '@components/lib/General';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Typography, Form } from 'antd';
import { IFormFooterProps } from '@components/lib/Layout';
import { CATEGORY_INITIAL_DATA } from '@services/api/category';
import FormCheckBox from '@components/lib/DataDisplay/FormCheckBox';

export interface ICategoryFormProps extends IFormFooterProps {
  editMode?: boolean;
  category?: CategoryFormDTO;
  onCancel?: () => void;
  onError?: () => void;
  onSuccess: (value: CategoryFormDTO) => Promise<void>;
  loading?: boolean;
}

export default function CategoryForm({
  loading,
  editMode,
  category = CATEGORY_INITIAL_DATA,
  ...rest
}: ICategoryFormProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: category?.uuid || '',
    }),
    [category?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Category'),
        `${
          editMode
            ? t('An error occurred during edit category')
            : t('An error occurred during create category')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm<CategoryFormDTO, CategoryFormDTO>
      editMode={editMode}
      initialValues={category}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, ...form }) => (
        <>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Name')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t('Category Name must be specified.'),
                  },

                  {
                    max: 150,
                    message: t(
                      'Category Name must contain a maximum of 150 characters.',
                    ),
                  },
                ]}
                tooltip={{
                  title: t('Category heading'),
                  icon: <InfoCircleOutlined />,
                }}>
                <Input
                  data-testid="category-create-input-name"
                  disabled={loading}
                  placeholder={t('Enter the category name')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Description')}
                name="description"
                rules={[
                  {
                    max: 250,
                    message: t(
                      'Category description must contain a maximum of 250 characters.',
                    ),
                  },
                ]}
                tooltip={{
                  title: t('Category description'),
                  icon: <InfoCircleOutlined />,
                }}>
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  data-testid="category-create-input-name"
                  disabled={loading}
                  placeholder={t('Enter the category description')}
                />
              </Form.Item>
            </Col>
          </Row>
          <FormCheckBox
            label={t('Status')}
            name="status"
            isChecked
            {...form}
            tooltipTitle={t('Active category')}>
            {({ checked }) => (
              <Typography.Text type={checked ? undefined : 'danger'}>
                {checked ? t('Active') : t('Inactive')}
              </Typography.Text>
            )}
          </FormCheckBox>
        </>
      )}
    </DefaultForm>
  );
}
