import React, { JSX, RefObject, useCallback, useMemo } from 'react';
import { Empty, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { InvoiceItemFormDTO, InvoiceMapper, ServiceModel } from '@structure';
import styled from 'styled-components';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStateServiceList, useStoredCompanies } from '@hooks';
import { CompanyService, debounce } from '@services/helpers';
import { LoadingMore } from '@components/lib/DataDisplay';
import {
  SelectedItemType,
  InvoiceItemListSelectState,
} from './InvoiceItemListSelect';
import { List } from 'immutable';
import { ServicePersonalityView, ServicesCreateButton } from '../../Services';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';
import { useAcl } from '@contex';

export interface InvoiceServiceListSelectProps {
  disabled: boolean;
  selectRef: RefObject<any>;
  onTableKeyboardChange: (e: any) => void;
  handleInitItemForm: (item: SelectedItemType) => void;
  resetSelectedItemState: () => void;
  selectedItem: InvoiceItemFormDTO;
  selectedItemIndex: number;
  itemListKeywords: string;
  updateItemState: (
    value:
      | ((
          value: InvoiceItemListSelectState,
        ) => Partial<InvoiceItemListSelectState>)
      | Partial<InvoiceItemListSelectState>,
  ) => void;
  handleSearchSelectDaleyFocus: (ms?: number) => void;
  isInitItemModals: React.MutableRefObject<boolean>;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<ServiceModel>>;

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;

  & .ant-select-selection-placeholder {
    white-space: unset !important;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export function InvoiceServiceListSelect({
  disabled,
  selectRef,

  selectedItem,
  selectedItemIndex,
  itemListKeywords,

  onTableKeyboardChange,
  handleInitItemForm,
  resetSelectedItemState,
  updateItemState,

  handleSearchSelectDaleyFocus,
  isInitItemModals,
}: InvoiceServiceListSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();

  const { service } = useAcl((acl) => acl);

  const {
    services: serviceList,
    loading,
    isLoadingMore,
    total,
    limit,

    handleSearchServices,
    handleLoadingMoreServiceList,
    handleSetServiceList,
    handleCreateService,
  } = useStateServiceList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
    limit: 100,
  });

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const services = await handleSearchServices({
          ...value,
          companyUuid: defaultCompanyUuid,
        });

        if (List.isList(services)) {
          updateItemState({
            itemList: services,
            selectedItem: InvoiceMapper.toInvoiceItemFormDTOFactory(
              services?.first(),
            ),
          });
        }
      }, 1000),
    [handleSearchServices, defaultCompanyUuid, updateItemState],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      updateItemState({ itemListKeywords: keywords });

      if (keywords?.length < 3) {
        setTimeout(() => {
          if (!isInitItemModals.current) {
            resetSelectedItemState();
            handleSetServiceList(List());
          }
        }, 100);
        return;
      }

      await debounceHandleSearchProducts({
        keywords,
      });
    },
    [
      debounceHandleSearchProducts,
      handleSetServiceList,
      isInitItemModals,
      resetSelectedItemState,
      updateItemState,
    ],
  );

  const onLoadingMore = useCallback(async () => {
    const serviceList = await handleLoadingMoreServiceList({});

    if (List.isList(serviceList)) {
      updateItemState((prevState) => ({
        itemList: (prevState?.itemList || List()).merge(serviceList) as any,
      }));
    }
  }, [handleLoadingMoreServiceList, updateItemState]);

  const columns = [
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      render: (service: ServiceModel) => (
        <StyledTitleContainer>
          {service?.title}
          <ServicePersonalityView service={service} />
        </StyledTitleContainer>
      ),
    },
    {
      ellipsis: true,
      title: t('Price'),
      key: 'price',
      align: 'right' as any,
      render: (service: CompanyService) =>
        defaultCompany
          ? `${defaultCompany?.currency_symbol} ${service?.price || '0.00'}`
          : '0.00',
    },
  ];

  return (
    <StyledSelectContainer>
      <StyledSelect
        popupClassName="store-sell-product-select-popup posting-select-popup"
        className="store-sell-product-select"
        ref={selectRef as any}
        loading={loading && serviceList !== null}
        disabled={disabled}
        showSearch
        onKeyUp={onTableKeyboardChange}
        searchValue={itemListKeywords}
        dropdownRender={() => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            {loading && serviceList !== null ? (
              <Empty>
                <LoadingOutlined />
              </Empty>
            ) : loading || !itemListKeywords || itemListKeywords?.length < 3 ? (
              <Empty
                description={t(
                  'To select a service, enter the search data, at least 3 characters',
                )}
              />
            ) : itemListKeywords && !serviceList?.size ? (
              <Empty
                description={t(
                  'It seems that more than one service was not found for your request',
                )}
              />
            ) : (
              <>
                <StyledTable
                  className="posting-list-form"
                  pagination={false}
                  pageSize={limit}
                  total={total}
                  dataSource={serviceList}
                  columns={columns}
                  onRow={(record: ServiceModel, rowIndex: any) => {
                    return {
                      onClick: () => {
                        handleInitItemForm(
                          InvoiceMapper.toInvoiceItemFormDTOFactory(record),
                        );

                        if (selectRef?.current) {
                          selectRef?.current?.blur();
                        }
                      },
                      className: `posting-list-td posting-list-td-${rowIndex} ${
                        record?.uuid === selectedItem?.item_positionable_uuid &&
                        selectedItemIndex === rowIndex
                          ? 'posting-list-td--selected'
                          : ''
                      }`,
                    };
                  }}
                />
                <LoadingMore
                  loading={isLoadingMore}
                  observerCallback={onLoadingMore}
                />
              </>
            )}
          </div>
        )}
        onSearch={onSearch}
        placeholder={t('Enter service details to search for it')}
      />
      <ServicesCreateButton
        disabled={!service?.manage}
        companyUuid={defaultCompanyUuid}
        onSuccess={handleCreateService as any}
        onCancel={handleSearchSelectDaleyFocus}
      />
    </StyledSelectContainer>
  );
}
