import React, { useCallback } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoredPlanList, useStopLoading } from '@hooks';
import { textToUpperCase } from '@services/helpers';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { SubscriptionModel } from '@structure';
import { Routes as RoutesType, Routes, RoutesAcl } from '@services/types';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';

export interface ISubscriptionListPageProps {}

export function SubscriptionListPage({}: ISubscriptionListPageProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    planList,
    loading: planListLoading,
    error: planListError,
    total,
  } = useStoredPlanList();

  const plan = planList?.first() || null;

  const subscriptions = plan?.getRestSubscription() || null;

  const loading = useStopLoading({
    loading: planListLoading,
    error: planListError,
    message: 'An error occurred during subscription archive loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.subscription}`,
      breadcrumbName: plan?.identifier ? t(plan?.identifier) : 'tSubscription',
    },
    {
      path: `/${Routes.subscription}/${Routes.subscriptionListPage}`,
      breadcrumbName: 'Archive',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      render: () => t(plan?.identifier),
    },
    {
      ellipsis: true,
      title: t('Date'),
      key: 'date',
      render: (subscription: SubscriptionModel) =>
        `${subscription?.date_start} - ${subscription?.date_end}`,
    },
    {
      ellipsis: true,
      title: t('Note'),
      key: 'note',
      render: (subscription: SubscriptionModel) => subscription?.comment,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.subscription}`);
  }, [navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.profile]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t('Archive'))}
      routes={routes}
      empty={
        subscriptions?.size ? null : (
          <Empty description={t('No subscriptions list found')} />
        )
      }
      loading={loading && !subscriptions?.size}>
      <Table<SubscriptionModel>
        pagination={false}
        pageSize={Infinity}
        total={total}
        onChange={() => {}}
        dataSource={subscriptions}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
