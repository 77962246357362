import {
  EEmployeeShiftStatus,
  IEmployeeShiftModelProps,
} from '@models/EmployeeShiftModel';

export interface IEmployeeShiftFormDTOModel {
  uuid: string;
  num: string;
  comment: string;
  openAt: Date;
  employeeUuid: string;
  status?: EEmployeeShiftStatus;
}

export class EmployeeShiftFormDTO implements IEmployeeShiftFormDTOModel {
  public uuid: string;
  public openAt: Date;
  public comment: string;
  public num: string;
  public employeeUuid: string;
  public status?: EEmployeeShiftStatus;

  constructor(props?: IEmployeeShiftModelProps) {
    this.uuid = props?.uuid || '';
    this.num = props?.shift_number || '';
    this.status = props?.shift_status;
    this.comment = props?.shift_comment || '';
    this.openAt = props?.shift_open_date ?? new Date();
    this.employeeUuid = '';
  }
}
