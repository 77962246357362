import * as React from 'react';
import { IDefaultFormReturnedProps } from '@components/lib/General';
import {
  handlers,
  FORM_ACTIONS,
  FormActions,
  ErrorFields,
} from './DefaultFormActions';
import { ILiteralObj } from '@services/types';
import { isFunction } from '@sportix/sportix-common-modules';

export interface DefaultFromContext<T>
  extends Omit<IDefaultFormReturnedProps, 'formData'> {
  valid: true;
  formData: T;
  handlerUpdateFormState: (
    update: ((value: T) => Partial<T>) | Partial<T>,
  ) => void;
  loadingSubmit: boolean;
}

export const DefaultFormContext = React.createContext<DefaultFromContext<any>>({
  valid: true,
  formData: {},
  handlerUpdateFormState: (value: any) => {},
  loadingSubmit: false,
} as DefaultFromContext<any>);

export interface DefaultFormManagerProps<T> {
  children: React.ReactNode;
  form: Omit<
    IDefaultFormReturnedProps,
    'loadingSubmit' | 'handlerUpdateFormState' | 'formData'
  >;
  initialValues: T;
  loadingSubmit: boolean;
  setLoadingSubmit?: any;
}

export const useDefaultForm = <T extends ILiteralObj>() =>
  React.useContext<DefaultFromContext<T>>(DefaultFormContext);

export default function DefaultFormManager<T>({
  children,
  form,
  initialValues,
  loadingSubmit,
  setLoadingSubmit,
}: DefaultFormManagerProps<T>): JSX.Element {
  const [formInstance] = React.useState(form);
  const [formData, dispatch] = React.useReducer(
    (state: T & ErrorFields, action: FormActions) => {
      const handler = handlers[action.type] || handlers[FORM_ACTIONS.DEFAULT];
      return handler<T & ErrorFields>(state, action as any);
    },
    {
      ...initialValues,
      errorFields: [],
    },
  );

  const handlerUpdateFormState = React.useCallback(
    (update: ((value: T) => Partial<T>) | Partial<T>): void => {
      const { loadingSubmit, ...rest } = isFunction(update)
        ? update({} as any)
        : update;

      if (typeof loadingSubmit === 'boolean') {
        setLoadingSubmit(loadingSubmit);
      }

      dispatch({ type: FORM_ACTIONS.UPDATE, update });

      dispatch({ type: FORM_ACTIONS.FILTER_ERRORS, ...rest });

      formInstance.setFieldsValue(rest);
    },
    [formInstance, setLoadingSubmit],
  );

  return (
    <DefaultFormContext.Provider
      value={{
        ...formInstance,
        loadingSubmit,
        valid: true,
        formData,
        handlerUpdateFormState,
      }}>
      {children}
    </DefaultFormContext.Provider>
  );
}
