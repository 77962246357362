import * as React from 'react';
import { useTranslation } from 'react-i18next';
import notify from '../Feedback/notify';
import { map, FunctionArgs, Curried } from '@services/helpers';
import { ApiError } from '@services/types';

type ErrorNotify = (subject: string, err: ApiError) => void;
type SuccessNotify = (subject: string) => void;
type ErrorNotifyMain = (
  t: any,
  subject: string,
  err: ApiError | string,
) => void;
type SuccessNotifyMain = (t: any, subject: string) => void;

type CarriedNotifyMain = ErrorNotifyMain | SuccessNotifyMain;
type CarriedNotify = ErrorNotify | SuccessNotify;

type CarriedWithoutT = Curried<CarriedNotifyMain[], void>;
type CarriedWithT = Curried<CarriedNotify[], CarriedWithoutT>;

export interface IUseNotifyReturnProps {
  addError: ErrorNotify;
  addSuccess: SuccessNotify;
  createError: ErrorNotify;
  createSuccess: SuccessNotify;
  deleteError: ErrorNotify;
  deleteSuccess: SuccessNotify;
  editError: ErrorNotify;
  editSuccess: SuccessNotify;
  updateError: ErrorNotify;
  updateSuccess: SuccessNotify;
  removeError: ErrorNotify;
  removeSuccess: SuccessNotify;
  notifyMessage: SuccessNotify;
  notifyErrorMessage: ErrorNotify;
  authError: ErrorNotify;
}

export default function useNotify(): IUseNotifyReturnProps {
  const { t } = useTranslation();

  return React.useMemo(
    () =>
      Object.fromEntries<IUseNotifyReturnProps>(
        map<
          [
            FunctionArgs<[string, CarriedWithT], [string, CarriedWithoutT]>,
            [string, CarriedWithoutT][],
          ],
          any
        >(
          ([name, fn]: [string, CarriedWithT]): [string, CarriedWithoutT] => [
            name,
            fn(t),
          ],
          Object.entries(notify),
        ),
      ) as any as IUseNotifyReturnProps,
    [t],
  );
}
