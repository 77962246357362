import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { ActionButton, IActionButtonProps } from '@components/lib/General';
import {
  IClientSubscriptionParams,
  buildSubscriptionFormParams,
  IClientSubscriptionTransitionParam,
} from '@services/api/clientSubscription';
import { ClientSubscriptionTransition } from '@services/models';
import { IClientSubscriptionProps } from '@services/helpers';
import { useMqlShowIcon } from '@hooks';
import { useModal } from '@components/lib/libV2/hooks';
import { SuspenseEmpty } from '@components/lib/DataDisplay';

const ClientSubscriptionSideWindowLazy = React.lazy(
  () => import('../Show/ClientSubscriptionSideWindow'),
);

export default withTranslation()(EditClientSubscriptionButton);

export interface IEditClientSubscriptionButtonProps
  extends WithTranslation,
    Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: IClientSubscriptionParams) => Promise<void>;
  onCancel?: () => void;
  clientUuid: string;
  companyUuid: string;
  children?: React.ReactNode;
  subscription: IClientSubscriptionProps;
  handleTransition?: (
    value: IClientSubscriptionTransitionParam,
  ) => Promise<ClientSubscriptionTransition>;
}

function EditClientSubscriptionButton({
  t,
  children,
  subscription,
  clientUuid,
  onSuccess,
  onCancel,
  companyUuid,
  handleTransition,
  ...rest
}: IEditClientSubscriptionButtonProps): JSX.Element {
  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  const showIcon = useMqlShowIcon();

  return (
    <>
      <ActionButton
        type="primary"
        onClick={handleOnInit}
        key="action"
        tooltip={t('Edit client service')}
        data-testid="clients-subscription__button--update"
        {...rest}>
        {showIcon ? children || <EditOutlined /> : <EditOutlined />}
      </ActionButton>
      <Suspense fallback={<SuspenseEmpty />}>
        <ClientSubscriptionSideWindowLazy
          handleTransition={handleTransition}
          companyUuid={companyUuid}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          editMode
          subscription={buildSubscriptionFormParams(subscription)}
          clientUuid={clientUuid}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
