import * as React from 'react';
import { StatusError } from '../../Errors';
import { isFunction } from '@services/helpers';

export interface IUseModalProps<T> {
  onSuccess?: (value: T) => any;
  onCancel?: () => void;
  onReset?: () => void;
  handleChangeState?: (value: boolean) => void;
}

export interface IUseModalReturnType<T> {
  handleSuccess: (value: T, closeble?: boolean) => Promise<void>;
  handleCancel: () => void;
  handleReset?: () => void;
  handleOnInit: (event?: any) => void;
  visible: boolean;
  loading?: boolean;
}

export default function useModal<T>({
  onCancel,
  onReset,
  onSuccess,
  handleChangeState,
}: IUseModalProps<T> = {}): IUseModalReturnType<T> {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = React.useCallback(() => {
    try {
      if (isFunction(onCancel)) {
        onCancel();
      }

      if (isFunction(handleChangeState)) {
        handleChangeState(false);
      }

      setVisible(false);
    } catch (error: any) {
      throw new StatusError(error?.message, error?.status);
    }
  }, [handleChangeState, onCancel]);

  const handleReset = React.useCallback(() => {
    try {
      if (isFunction(onReset)) {
        onReset();
      }

      if (isFunction(handleChangeState)) {
        handleChangeState(false);
      }

      setVisible(false);
    } catch (error: any) {
      throw new StatusError(error?.message, error?.status);
    }
  }, [handleChangeState, onReset]);

  const handleSuccess = React.useCallback(
    async (value: T, closeble = true): Promise<void> => {
      setLoading(true);
      try {
        let answer;
        if (isFunction(onSuccess)) {
          answer = await onSuccess(value);
        }

        if (isFunction(handleChangeState)) {
          await handleChangeState(false);
        }

        if (closeble) {
          handleCancel();
        }

        return answer;
      } catch (error: any) {
        throw new StatusError(
          error?.response?.message || error?.message,
          error?.response?.status || error?.status,
        );
      } finally {
        setLoading(false);
      }
    },
    [handleCancel, handleChangeState, onSuccess],
  );

  const handleOnInit = React.useCallback(
    async (event: any): Promise<void> => {
      if (isFunction(event?.stopPropagation)) {
        event?.stopPropagation();
      }
      if (isFunction(handleChangeState)) {
        handleChangeState(true);
      }

      setVisible(true);
    },
    [handleChangeState],
  );

  return {
    handleSuccess,
    handleCancel,
    handleReset,
    handleOnInit,
    visible,
    loading,
  };
}
