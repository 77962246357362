import { PriceTagModel } from '../internal';
import { List } from 'immutable';
import { listToArray } from '@services/helpers';

export interface IPriceTagPrintFormDTOProps {
  tag_size: string;
  price_margin_uuid: string;
}

export class PriceTagPrintFormDTO {
  public tag_size: string;
  public price_margin_uuid: string;

  constructor(props?: IPriceTagPrintFormDTOProps) {
    this.tag_size = props?.tag_size || '';
    this.price_margin_uuid = props?.price_margin_uuid || '';
  }
}
