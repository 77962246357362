import React, { useMemo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  DatePicker,
} from '@components/lib/General';
import { CashBoxModel, CashBoxMovingFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import {
  useSize,
  useStateEmployeeList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '@hooks';
import { SearchSelect, Segmented } from '@components/lib/DataDisplay';
import { isListToArray } from '@services/helpers';
import { phoneScreen } from '@services/const';
import { CASH_BOX_PAYMENT_METHOD } from './CashboxForm';
import { CashBoxTitleView } from '../Show/CashBoxTitleView';

export interface ICashBoxMovingFormProps
  extends Omit<
    IDefaultFormProps<CashBoxMovingFormDTO, CashBoxMovingFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  cashBox: CashBoxMovingFormDTO;
}

export function CashBoxMovingForm({
  loading,
  editMode,
  cashBox,
  onCancel,
  ...rest
}: ICashBoxMovingFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();
  const { defaultCompany } = useStoredCompanies();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const type = Form.useWatch('type', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: cashBox?.uuid,
    }),
    [cashBox?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Cash box'),
        `${t('An error occurred during moving')} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...cashBox,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <Row gutter={12} ref={contentRef}>
          <Col span={24}>
            <Form.Item
              label={t('Date')}
              name="date"
              rules={[
                {
                  required: true,
                  message: t('Date must be specified'),
                },
              ]}
              tooltip={t('Date')}>
              <DatePicker
                showTime
                format="YYYY.MM.DD HH:mm"
                disabled={loadingSubmit}
                placeholder={t('Date')}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Forms of payment')}
              name="type"
              tooltip={t('Forms of payment')}>
              <Segmented
                options={CASH_BOX_PAYMENT_METHOD}
                disabled={loadingSubmit}
                onChange={() => {
                  instance.setFieldsValue({ cashbox_destination_uuid: '' });
                  setResetCashBoxState(true);
                  setTimeout(() => {
                    setResetCashBoxState(false);
                  }, 100);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Cash boxes')}
              name="cashbox_destination_uuid"
              rules={[
                {
                  required: true,
                  message: t('Cash boxes must be specified'),
                },
              ]}
              tooltip={t('Cash boxes')}>
              <SearchSelect
                resetState={resetCashBoxState}
                selectFirst={false}
                onSearch={(keywords) =>
                  handleSearchCashBoxes({
                    keywords,
                    limit: 100,
                    showLoading: false,
                  })
                }
                getOptionValueTitle={(cashBox: CashBoxModel) => (
                  <CashBoxTitleView
                    cashBox={cashBox}
                    disable={loadingSubmit || cashBoxListLoading}
                  />
                )}
                name="cashbox_destination_uuid"
                placeholder={t('Select a cash box')}
                disable={loadingSubmit || cashBoxListLoading}
                data={isListToArray(
                  cashBoxList?.filter(
                    ({ box_type, uuid }) =>
                      box_type === type && uuid !== cashBox?.uuid,
                  ) as any,
                )}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Sum')}
              name="sum"
              required
              rules={[
                () => ({
                  validator(_, price) {
                    const value = price;

                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(
                        new Error(t('Sum must be a number')),
                      );
                    }

                    if (!Number(value)) {
                      return Promise.reject(
                        new Error(t('Sum must be specified')),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <Input
                addonBefore={defaultCompany?.currency_symbol}
                disabled={loadingSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Cashier')}
              name="cashier_uuid"
              rules={[
                {
                  required: true,
                  message: t('Cashier must be specified'),
                },
              ]}
              tooltip={t('Cashier')}>
              <SearchSelect
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    limit: 100,
                    showLoading: false,
                  })
                }
                getOptionValueTitle="fullName"
                name="cashier_uuid"
                placeholder={t('Select a cashier')}
                disable={loadingSubmit || employeesLoading}
                data={isListToArray(employees as any)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('note')} name="comment" tooltip={t('note')}>
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 5 }}
                disabled={loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
