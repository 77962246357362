import React from 'react';
import styled from 'styled-components';
import EmptyLayout from '../EmptyLayout';
import { LoadingOutlined } from '@ant-design/icons';
import { useListLayout } from '@contex';
import { HEADER_HEIGHT } from '../const';

export interface IListEmptyProps {
  children: React.ReactNode | React.ReactNode[];
  empty?: React.ReactNode | React.ReactNode[];
  loading: boolean;
}

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

export function ListEmpty({
  children,
  loading,
  empty,
}: IListEmptyProps): React.JSX.Element {
  const { headerRef, outsideContentRef } = useListLayout();

  return (
    <>
      {loading || empty ? (
        <EmptyLayout
          headerHeight={
            (headerRef?.current?.getBoundingClientRect()?.height || 0) +
            HEADER_HEIGHT +
            (outsideContentRef?.current?.getBoundingClientRect()?.height || 0)
          }>
          {loading ? <StyledLoadingOutlined /> : empty}
        </EmptyLayout>
      ) : (
        children
      )}
    </>
  );
}
