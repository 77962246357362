import React from 'react';
import { List } from 'immutable';
import { useAcl } from '@contex';
import { ProductDetailsView } from '../Show';
import { useTranslation } from 'react-i18next';
import { RowSelection } from '@services/types';
import { IUseProductListProps } from '@hooks';
import { DeleteOutlined } from '@ant-design/icons';
import { ProductEditButton, ProductsDeleteButton } from '../Buttons';

import {
  ProductModel,
  ProductMapper,
  ProductStatus,
  ProductFormDTO,
} from '@structure';

import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';

import './ProductList.less';

export interface IProductListProps {
  products: List<ProductModel> | null;
  loading?: boolean;
  currencySymbol?: string;
  priceMargins?: any[];
  limit?: number;
  page?: number;
  onRefresh: (
    value: Partial<IUseProductListProps> & { page: number },
  ) => Promise<void>;
  rowSelection: RowSelection<ProductModel>;
  total: number;
  withoutActions?: boolean;
  daleyFocus?: (ms?: number) => void;
  handleUpdateProduct: (value: ProductFormDTO) => Promise<ProductModel>;
  handleDeleteProducts: (value: string[]) => Promise<void>;
}

export default function ProductList(props: IProductListProps): JSX.Element {
  const { t } = useTranslation();
  const { manage } = useAcl(({ product }) => product);
  const {
    total,
    page,
    loading,
    products,
    limit = 10,
    daleyFocus,
    priceMargins = [],
    currencySymbol = '',
    onRefresh = async () => {},
    rowSelection,
    withoutActions,
    handleUpdateProduct,
    handleDeleteProducts,
  } = props;

  const columnsWithoutActions = [
    {
      key: 'product',
      title: t('Title'),
      width: '65%',
      className: 'product-list-td product-list--title',
      render: (product: ProductModel) => (
        <ProductDetailsView product={product} />
      ),
    },
    {
      title: `${t('Price')}, ${currencySymbol}`,
      key: 'price',
      width: '25%',
      children: priceMargins,
    },
  ];

  const columns: any[] = withoutActions
    ? columnsWithoutActions
    : [
        ...columnsWithoutActions,
        {
          title: '',
          width: 40,
          key: 'product_actions',
          align: 'center' as any,
          fixed: 'right' as any,
          className: 'product-list-td',
          render: (product: ProductModel) => (
            <TableActionCell className="product-list--hidden">
              <ProductEditButton
                disabled={!manage}
                type="text"
                product={ProductMapper.toProductFormDTO(product, {
                  editMode: true,
                })}
                onSuccess={handleUpdateProduct}
                onCancel={daleyFocus}
              />
              <ProductsDeleteButton
                disabled={!manage}
                products={[product]}
                onSuccess={handleDeleteProducts}>
                <DeleteOutlined />
              </ProductsDeleteButton>
            </TableActionCell>
          ),
        },
      ];

  return (
    <Table<ProductModel>
      rowSelection={rowSelection}
      total={total}
      pageSize={limit}
      dataSource={products}
      onChange={onRefresh}
      columns={columns}
      loading={loading}
      page={page}
      sticky={{ offsetHeader: 80 }}
      onRow={(record: ProductModel) => ({
        className:
          record?.product_status !== ProductStatus.ENABLE
            ? 'product-list--disabled-in-list'
            : 'product-list--row',
      })}
    />
  );
}
