import React from 'react';
import { Form, Row, Col } from 'antd';
import styled from 'styled-components';
import { AuthButton } from '../../Buttons';
import { UserOutlined } from '@ant-design/icons';
import { Input } from '@components/lib/DataEntry';
import { ForgotPasswordFormDTO } from '@structure';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';

export default withTranslation()(ForgotPasswordForm);

export interface IForgotPasswordFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<ForgotPasswordFormDTO, ForgotPasswordFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  onCancel?: () => void;
  onSuccess: (value: ForgotPasswordFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  forgotPassword: ForgotPasswordFormDTO;
}

const StyledUserOutlined = styled(UserOutlined)`
  color: ${({ theme }) => theme.colors.disable};
`;

function ForgotPasswordForm({
  forgotPassword,
  t,
  ...rest
}: IForgotPasswordFormProps): JSX.Element {
  return (
    <DefaultForm
      initialValues={{ ...forgotPassword }}
      showFooter={false}
      {...rest}>
      {({ loadingSubmit, submit }) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Email or phone')}
              tooltip={t(
                'The confirmation code will be sent to the entered email or phone. Then you will be able to change the password by specifying the received confirmation code.',
              )}
              name="identifier"
              rules={[
                {
                  required: true,
                  message: t('Please input your Email or Phone!'),
                },
              ]}>
              <Input
                data-testid="forgot-password-form-input-email"
                disabled={loadingSubmit}
                prefix={<StyledUserOutlined />}
                placeholder={t('Enter your email or phone')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <AuthButton
              data-testid="reset-password-form-button-submit"
              loading={loadingSubmit}
              size={'large'}
              type="primary"
              onClick={() => {
                submit();
              }}
              block>
              {t('Send confirmation code')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
