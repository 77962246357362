import { Empty } from 'antd';
import { List } from 'immutable';
import { useAcl } from '@contex';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ProductList from '../List/ProductList';
import { PlusOutlined } from '@ant-design/icons';
import { ProductListFilterManager } from '../Managers';
import { SessionStorageItems } from '@services/const';
import { successButtonStyle } from '@components/lib/Styled';
import { AppHeader } from '@components/lib/Layout/AppHeader';
import { ProductCreateButton, ProductListMenuButton } from '../Buttons';
import { ProductCategoryTreeView, ProductStoreSearchView } from '../Show';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';

import {
  useDefaultPriceMargin,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceTagList,
  useStoredProduct,
  useStoredProductCategory,
  useStoredProductList,
  useStoredStoreList,
} from '@hooks';

import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  IProductsListStatsProps,
  PriceTagMapper,
  PriceTagModel,
  ProductCategoryModel,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductType,
} from '@structure';

import { Routes as RoutesType, Routes, RoutesTitle } from '@services/types';

import {
  HeaderLeft,
  ListActions,
  ListHeader as ListInputHeader,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';

import { ListContent, ListEmpty, ListHeader } from '@components/lib/Layout';

import {
  correctPrice,
  isThereContent,
  listToArray,
  textToUpperCase,
} from '@services/helpers';

import './ProductList.less';
import '../../Orders/List/OrderList.less';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

const PriceTagPrintModalLazy = React.lazy(
  () => import('../PriceTags/Show/PriceTagPrintModal'),
);

export interface IProductListPageProps {
  onUpdateStats: (stats: IProductsListStatsProps) => void;
  onUpdateLoading: (loading: boolean) => void;
}

const StyledProductCreateButton = styled(ProductCreateButton)`
  ${successButtonStyle}
`;

const HeaderSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

const HeaderSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`;

export function ProductListPage({
  onUpdateStats,
  onUpdateLoading,
}: IProductListPageProps): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { manage } = useAcl(({ product }) => product);

  const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([]);
  const [priceMarginFields, setPriceMarginFields] = useState<any[]>([]);

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'product-create-event',
    disabled: !manage,
  });

  const [storeLoading, setStoreLoading] = useState(false);
  const [category, setCategory] = useState<ProductCategoryModel | null>(null);
  const [productUuid, setProductUuid] = useState<string>('');

  const storeUuid =
    sessionStorage.getItem(SessionStorageItems.productStoreUuid) || '';

  const {
    productList,
    loading: productListLoading,
    loadingMore: productListLoadingMore,
    error: productListError,
    limit: pageSize,
    total,
    keywords,
    page,
    stats,
    parent_category_uuid,

    handleSearchProducts,
    handleDeleteProducts: onDeleteProducts,
    handleRefreshProducts,
  } = useStoredProductList({
    companyUuid: defaultCompanyUuid,
    productType: ProductType.DEFAULT,
  });

  const { handleCreateProduct: onCreateProduct, handleUpdateProduct } =
    useStoredProduct({
      companyUuid: defaultCompanyUuid,
      loadOnInit: false,
      productUuid: '',
    });

  const { handleCreateProductCategory } = useStoredProductCategory({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    categoryUuid: '',
  });

  const { handlePrintPriceTag } = useStoredPriceTagList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productUuid,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    limit: 100,
  });

  const { priceMarginList, defaultPriceMargin } = useDefaultPriceMargin({});

  const {
    priceTagList,
    handleClearPriceTagList,
    handleCreatePriceTagList: onCreatePriceTagList,
  } = useStoredPriceTagList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: !!selectedProducts?.length,
    limit: 150,
  });

  const handleCreatePriceTagList = useCallback(
    async (priceTagList: List<PriceTagModel> | null) => {
      if (selectedProducts?.length) {
        const ids = selectedProducts
          ?.map(({ uuid }) => uuid)
          .filter(
            (id) =>
              !~(priceTagList || []).findIndex(
                ({ product }) => product?.uuid === id,
              ),
          );

        if (ids.length) {
          await onCreatePriceTagList(ids);
        }

        navigate(`/${Routes.app}/${Routes.goods}/${Routes.priceTags}`, {
          state: { autoPrint: true },
        });
      }
    },
    [navigate, onCreatePriceTagList, selectedProducts],
  );

  const loading = useStopLoading({
    error: productListError,
    loading: productListLoading || productListLoadingMore,
    message: 'An error occurred during materials loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteProducts = useCallback(
    async (value: string[]) => {
      await onDeleteProducts(value);
      focus();
    },
    [focus, onDeleteProducts],
  );

  const onCategoryChange = useCallback(
    async (parent_category_uuid: string) => {
      sessionStorage.setItem(
        SessionStorageItems.productLeftoversCategoryUuid,
        parent_category_uuid,
      );

      await handleSearchProducts({
        keywords,
        parent_category_uuid,
        store_uuid: storeUuid,
      });
    },
    [handleSearchProducts, keywords, storeUuid],
  );

  const onStoreChange = useCallback(
    async (store_uuid: string) => {
      sessionStorage.setItem(SessionStorageItems.productStoreUuid, store_uuid);
      setStoreLoading(true);

      await handleSearchProducts({
        keywords,
        store_uuid,
        withStoreUuid: true,
        showLoading: true,
        parent_category_uuid,
      });

      setStoreLoading(false);
    },
    [handleSearchProducts, keywords, parent_category_uuid],
  );

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    visible: productVisible,
  } = useModal({
    onCancel: daleyFocus,
    onSuccess: () => {},
  });

  const {
    handleCancel: priceTagHandleCancel,
    handleOnInit: priceTagHandleOnInit,
    visible: priceTagVisible,
    handleSuccess: priceTagHandleSuccess,
  } = useModal({
    onCancel: daleyFocus,
    onSuccess: handlePrintPriceTag,
  });

  const handleCreateProduct = useCallback(
    async (value: ProductFormDTO) => {
      const product = await onCreateProduct(value);

      productHandleCancel();

      if (value?.print_price_tag && product) {
        setProductUuid(product?.uuid);
        setTimeout(priceTagHandleOnInit, 250);
      }

      if (value?.add_another_product) {
        setCategory(product?.categoryModel || null);
        setTimeout(productHandleOnInit, 250);
      }
    },
    [
      onCreateProduct,
      priceTagHandleOnInit,
      productHandleCancel,
      productHandleOnInit,
    ],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.products}`,
      breadcrumbName: 'Goods and materials values',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedProducts.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ProductModel[],
      ) => {
        setSelectedProducts(selectedRecords);
      },
    }),
    [selectedProducts],
  );

  useEffect(() => {
    if (priceMarginFields?.length === 0 && priceMarginList?.size) {
      const fields = priceMarginList?.map(({ price_name, uuid }) => ({
        title: price_name,
        key: price_name,
        align: 'right',
        render: (product: ProductModel) => {
          const price = product?.product_prices?.find(
            ({ price_uuid }) => price_uuid === uuid,
          );

          if (price) {
            return correctPrice(price?.price_value || 0);
          }
        },
      }));

      setPriceMarginFields(listToArray(fields));
    }
  }, [priceMarginFields?.length, priceMarginList, t]);

  useEffect(() => {
    if (isThereContent(stats)) {
      onUpdateStats(stats);
    }
  }, [onUpdateStats, stats]);

  useEffect(() => {
    onUpdateLoading(loading);
  }, [loading, onUpdateLoading]);

  return (
    <ProductListFilterManager>
      <AppHeader
        headerRight={
          <ProductCreateButton
            disabled={!manage}
            loading={loading}
            onSuccess={handleCreateProduct}
            onSuccessCategory={handleCreateProductCategory}
            onCancel={daleyFocus}
          />
        }
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.products]))}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        routes={routes}
      />
      <ListContent>
        <ListHeader>
          <HeaderSearchWrapper>
            <ListInputHeader ref={listRef}>
              <ListActions
                inputRef={inputRef}
                searchText={keywords}
                withoutPicker
                loading={loading}
                handleSearch={(keywords) =>
                  handleSearchProducts({
                    keywords,
                    store_uuid: storeUuid || '',
                    withStoreUuid: true,
                    parent_category_uuid,
                  })
                }
                inputTooltip={t('Search')}
                inputLabel={t('Search')}
                withSearchContent={
                  <StyledProductCreateButton
                    type="primary"
                    disabled={!manage}
                    loading={loading}
                    onSuccess={handleCreateProduct}
                    title={t('Create')}
                    icon={<PlusOutlined />}
                    className="product-create-event"
                    onCancel={daleyFocus}
                  />
                }
                withSearchEndContent={
                  <HeaderSearchContainer>
                    <ProductStoreSearchView
                      loading={loading}
                      onStoreChange={onStoreChange}
                      storeUuid={storeUuid}
                      storeList={storeList}
                      storeListLoading={storeListLoading}
                      handleSearchStores={handleSearchStores}
                    />
                    <ProductCategoryTreeView
                      loading={loading}
                      onCategorySelect={onCategoryChange}
                    />
                  </HeaderSearchContainer>
                }
              />
            </ListInputHeader>
            {selectedProducts?.length ? (
              <ProductListMenuButton
                handleCreatePriceTagList={handleCreatePriceTagList}
                handleClearPriceTagList={handleClearPriceTagList}
                priceTagsCount={selectedProducts?.length}
                priceTagsCountInList={priceTagList?.size || 0}
                priceTagList={priceTagList}
              />
            ) : null}
          </HeaderSearchWrapper>
        </ListHeader>

        <ListEmpty
          loading={loading && !productList?.size}
          empty={
            productList?.size ? null : (
              <Empty
                description={t(
                  `It looks like you don't have any materials at the moment.`,
                )}>
                <ProductCreateButton
                  type="primary"
                  disabled={!manage}
                  loading={loading}
                  onSuccess={handleCreateProduct}
                  onCancel={daleyFocus}>
                  {t('Create material')}
                </ProductCreateButton>
              </Empty>
            )
          }>
          <ProductList
            total={total}
            page={page}
            products={productList}
            onRefresh={handleRefreshProducts}
            loading={loading || storeLoading}
            rowSelection={rowSelection}
            priceMargins={priceMarginFields}
            currencySymbol={defaultCompany?.currency_symbol || ''}
            handleUpdateProduct={handleUpdateProduct}
            handleDeleteProducts={handleDeleteProducts}
          />
        </ListEmpty>
      </ListContent>
      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          loading={loading as boolean}
          onSuccess={handleCreateProduct}
          onCancel={productHandleCancel}
          visible={productVisible}
          product={ProductMapper.toProductFormDTO(
            {
              category,
            } as ProductModel,
            { addAnotherProduct: true, disabledCategory: false },
          )}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <PriceTagPrintModalLazy
          print={PriceTagMapper.toPriceTagPrintFormDTO({
            price_margin_uuid: defaultPriceMargin?.uuid || '',
            tag_size: '60x40',
          })}
          onSuccess={priceTagHandleSuccess}
          visible={priceTagVisible}
          onCancel={priceTagHandleCancel}
        />
      </Suspense>
    </ProductListFilterManager>
  );
}
