import { Record, List } from 'immutable';
import { IListProps } from './List';
import {
  IProductsListStatsProps,
  ProductDTO,
  ProductModel,
  ProductCategoryModel,
  StoreModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IProductListModelProps extends IListProps {
  products: ProductDTO[];
  stats?: IProductsListStatsProps;
  store?: StoreModel;
  product?: ProductModel;
  category?: ProductCategoryModel;
}

export interface IProductListModelReturnType extends IListProps {
  products: List<ProductModel>;
  stats?: IProductsListStatsProps;
  keywords: string;
  page: number;
  store?: StoreModel;
  product?: ProductModel;
  category?: ProductCategoryModel;
}

export class ProductListModel extends Record<IProductListModelReturnType>({
  products: List(),
  total: 0,
  stats: {} as IProductsListStatsProps,
  keywords: '',
  page: 1,
  store: {} as StoreModel,
  product: {} as ProductModel,
  category: {} as ProductCategoryModel,
}) {
  constructor(
    {
      products = [],
      ...props
    }: IProductListModelProps = {} as IProductListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IProductListModelReturnType = {
      ...props,
      keywords: '',
      page: 1,
      products: List(products.map((product) => new ProductModel(product))),
    };

    super(options);
  }
}
