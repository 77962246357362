import React from 'react';
import { StoreDocumentFormDTO, StoreDocumentType } from '@structure';
import { IEditButtonProps } from '@components/lib/DataDisplay';
import { IUseStateStoredDocumentReturnType } from '@hooks';
import { StorePostingUpdateButton } from '../../Posting';
import { StoreRealizationUpdateButton } from '../../Realization';
import { StoreWriteOffUpdateButton } from '../../WriteOff';
import { StoreReturnUpdateButton } from '../../Return';
import { StoreReturnClientUpdateButton } from '../../ReturnClients';
import { StoreMovingUpdateButton } from '../../Moving';

export interface IStoreRelatedUpdateButtonProps
  extends IEditButtonProps,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
  fromList: boolean;
  documentType: StoreDocumentType;
  from?: StoreDocumentType;
}

export function StoreRelatedUpdateButton({
  documentType,
  ...rest
}: IStoreRelatedUpdateButtonProps): React.JSX.Element {
  const crateDocumentButtons = {
    [StoreDocumentType.IN]: <StorePostingUpdateButton {...rest} />,
    [StoreDocumentType.OUT]: <StoreRealizationUpdateButton {...rest} />,
    [StoreDocumentType.ERASE]: <StoreWriteOffUpdateButton {...(rest as any)} />,
    [StoreDocumentType.RETURN]: <StoreReturnUpdateButton {...rest} />,
    [StoreDocumentType.CRETURN]: <StoreReturnClientUpdateButton {...rest} />,
    [StoreDocumentType.MOVE]: <StoreMovingUpdateButton {...(rest as any)} />,
    [StoreDocumentType.SALE]: <></>,
    [StoreDocumentType.PREORDER]: <></>,
  };

  return <>{crateDocumentButtons[documentType]}</>;
}
