import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { StyledDescription } from '@components/lib/Styled';
import {
  EmployeeModel,
  EmployeeStatuses,
  ExpenseCreateFormDTO,
  ExpenseDiscountType,
  ExpenseFormDTO,
  ExpenseWorkableType,
} from '@structure';
import { useDropdownAlert } from '@contex';
import {
  IEmployeeSearchProps,
  useSize,
  useStateEmployeeList,
  useStoredCompanies,
  useStoredServiceEmployeeList,
} from '@hooks';
import styled from 'styled-components';
import { SearchSelect } from '@components/lib/DataDisplay';
import { EXPENSE_DISCOUNT_TYPE } from '@services/api/orders';
import { correctPrice, isListToArray } from '@services/helpers';
import { phoneScreen } from '@services/const';
import { ExpenseFormTotalView } from '../Show/ExpenseFormTotalView';
import { List } from 'immutable';

export interface IExpenseFormProps
  extends Omit<
    IDefaultFormProps<
      ExpenseFormDTO | ExpenseCreateFormDTO,
      ExpenseFormDTO | ExpenseCreateFormDTO
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  expense: ExpenseFormDTO | ExpenseCreateFormDTO;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

const StyledExpenseFormTotalView = styled(ExpenseFormTotalView)`
  line-height: 1.1;
  margin-bottom: 25px;
  padding: 5px 10px 10px 5px;
`;

export function ExpenseForm({
  loading,
  editMode,
  expense,
  onCancel,
  ...rest
}: IExpenseFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const [instance] = Form.useForm();

  const [serviceEmployeeList, setServiceEmployeeList] =
    useState<List<EmployeeModel> | null>(null);

  const discountType = Form.useWatch('discount_type', instance);
  const amount = Form.useWatch('amount', instance);
  const price = Form.useWatch('price', instance);
  const discountValue = Form.useWatch('discount', instance);

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const additionalValues = useMemo(
    () => ({
      uuid: expense?.uuid,
      workable_uuid: expense?.workable_uuid,
      workable_type: expense?.workable_type,
      prevPrice: expense?.prevPrice,
      net_price: expense?.net_price,
    }),
    [
      expense?.net_price,
      expense?.prevPrice,
      expense?.uuid,
      expense?.workable_type,
      expense?.workable_uuid,
    ],
  );

  const {
    employees: defaultEmployees,
    loading: defaultEmployeeLoading,
    handleSearchEmployees: handleSearchDefaultEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    loadOnInit:
      expense?.workable_type === ExpenseWorkableType.Product ||
      !expense?.serviceUuid,
  });

  const {
    serviceEmployeeList: defaultServiceEmployeeList,
    loading: serviceEmployeeListLoading,
    handleSearchServiceEmployees,
  } = useStoredServiceEmployeeList({
    serviceUuid: expense?.serviceUuid!,
    loadOnInit:
      expense?.workable_type === ExpenseWorkableType.Service &&
      !!expense?.serviceUuid,
    service: null,
  });

  const employees =
    expense?.workable_type === ExpenseWorkableType.Service
      ? serviceEmployeeList
      : defaultEmployees;

  const employeeLoading =
    expense?.workable_type === ExpenseWorkableType.Service
      ? serviceEmployeeListLoading
      : defaultEmployeeLoading;

  const handleSearchEmployees =
    expense?.workable_type === ExpenseWorkableType.Service
      ? ({ keywords }: IEmployeeSearchProps) =>
          handleSearchServiceEmployees(keywords)
      : handleSearchDefaultEmployees;

  const handleChangeEmployee = useCallback(
    (employeeUuid: string) => {
      if (List.isList(employees)) {
        const employee = employees.find(
          ({ uuid }) => uuid === employeeUuid,
          [],
        );

        if (employee?.employee_service?.price) {
          instance.setFieldValue('price', employee?.employee_service?.price);
        }
      }
    },
    [employees, instance],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Services or goods'),
        `${
          editMode
            ? t('An error occurred during edit services or goods')
            : t('An error occurred during create services or goods')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  useEffect(() => {
    if (
      expense?.workable_type === ExpenseWorkableType.Service &&
      List.isList(employees) &&
      expense?.manager_uuid
    ) {
      const employeeUuid =
        expense?.manager_uuid instanceof EmployeeModel
          ? expense?.manager_uuid?.uuid
          : expense?.manager_uuid;

      const employee = employees.find(({ uuid }) => uuid === employeeUuid, []);

      if (employee?.employee_service?.price) {
        instance.setFieldValue('price', employee?.employee_service?.price);
      }
    }
  }, [employees, expense?.manager_uuid, expense?.workable_type, instance]);

  useEffect(() => {
    if (List.isList(defaultServiceEmployeeList)) {
      const serviceEmployeeList = defaultServiceEmployeeList?.filter(
        ({ status }) => status !== EmployeeStatuses.DELETED,
      );

      setServiceEmployeeList(serviceEmployeeList);
    }
  }, [defaultServiceEmployeeList]);

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...expense,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, validateFields }) => (
        <Row gutter={12} ref={contentRef}>
          <Col span={24}>
            <Form.Item
              tooltip={t('Performer')}
              label={t('Performer')}
              name="manager_uuid"
              rules={[
                {
                  required: true,
                  message: t('Performer must be specified.'),
                },
              ]}>
              <SearchSelect
                selectFirst
                name="manager_uuid"
                getOptionValueTitle="fullName"
                placeholder={t('Select a performer')}
                disable={loading || loadingSubmit || employeeLoading}
                itemDisabled={(employee: EmployeeModel) =>
                  employee?.status === EmployeeStatuses.DELETED
                }
                data={isListToArray(employees as any)}
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    showLoading: false,
                    limit: 100,
                  })
                }
                onChange={handleChangeEmployee}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              tooltip={t('tAmount')}
              label={t('tAmount')}
              name="amount"
              rules={[
                {
                  required: true,
                  message: t('tAmount must be specified.'),
                },
                {
                  transform: (value) => Number(value),
                  min: 1,
                  type: 'number',
                  message: t('tAmount must not be less than 1'),
                },
              ]}
              extra={
                Number(price) > 0 && Number(amount) > 0 ? (
                  <StyledDescription>{`${
                    defaultCompany?.currency_symbol
                  } ${correctPrice(
                    Number(amount) * Number(price),
                  )}`}</StyledDescription>
                ) : null
              }>
              <FullWidthInputNumber
                type="number"
                disabled={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>

          {/*<Col span={12}>*/}
          {/*  <Form.Item*/}
          {/*    label={t('Net price')}*/}
          {/*    name="net_price"*/}
          {/*    required*/}
          {/*    rules={[*/}
          {/*      ({getFieldValue, setFields}) => ({*/}
          {/*        validator(_, net_price) {*/}
          {/*          const value = net_price;*/}

          {/*          const price = getFieldValue('price');*/}

          {/*          if (Number.isNaN(Number(value))) {*/}
          {/*            return Promise.reject(*/}
          {/*              new Error(t('Net price must be number.')),*/}
          {/*            );*/}
          {/*          }*/}

          {/*          if (Number(value) > Number(price)) {*/}
          {/*            setFields([*/}
          {/*              {*/}
          {/*                name: 'price',*/}
          {/*                errors: [*/}
          {/*                  t('The price cannot be less than net price'),*/}
          {/*                ],*/}
          {/*              },*/}
          {/*            ]);*/}
          {/*          }*/}

          {/*          return Promise.resolve();*/}
          {/*        },*/}
          {/*      }),*/}
          {/*    ]}>*/}
          {/*    <Input*/}
          {/*      addonBefore={defaultCompany?.currency_symbol}*/}
          {/*      disabled={loading || loadingSubmit}*/}
          {/*      placeholder={t('Enter net price')}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Price')}
              name="price"
              required
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, price) {
                    const value = price;

                    // const netPrice = getFieldValue('net_price');

                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(
                        new Error(t('Price must be a number')),
                      );
                    }

                    // if (Number(value) < Number(netPrice)) {
                    //   return Promise.reject(
                    //     new Error(
                    //       t('The price cannot be grate than net price'),
                    //     ),
                    //   );
                    // }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <Input
                autoComplete="off"
                addonBefore={defaultCompany?.currency_symbol}
                data-testid="service-input-price"
                disabled={loading || loadingSubmit}
                onChange={(e) =>
                  instance.setFieldValue('net_price', e?.target?.value)
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              tooltip={t('Discount')}
              label={t('Discount')}
              name="discount"
              rules={
                discountType === ExpenseDiscountType.Fixed
                  ? [
                      {
                        transform: (value) => Number(value),
                        type: 'number',
                        required: true,
                        message: t('Discount must be specified.'),
                      },
                    ]
                  : [
                      {
                        transform: (value) => Number(value),
                        type: 'number',
                        min: 0,
                        max: 100,
                        message: t(
                          'Discount must be a number and bigger than -1 and less 100',
                        ),
                      },
                    ]
              }>
              <Input
                addonAfter={
                  <Form.Item noStyle name="discount_type">
                    <StyledSearchSelect
                      popupMatchSelectWidth={false}
                      name="discount_type"
                      placeholder={''}
                      getOptionValueTitle="description"
                      disable={loadingSubmit}
                      data={EXPENSE_DISCOUNT_TYPE(
                        defaultCompany?.currency_symbol!,
                      )}
                      onChange={async () => {
                        await validateFields(['discount']);
                      }}
                    />
                  </Form.Item>
                }
                type="number"
                disabled={loadingSubmit}
                placeholder={t('Enter discount')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <StyledExpenseFormTotalView
              price={price}
              amount={amount}
              discount_type={discountType}
              discount_value={discountValue}
            />
          </Col>

          <Col span={24}>
            <Form.Item label={t('Note')} name="comment">
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 5 }}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
