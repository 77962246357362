import React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import { CreateStoreDocumentPaymentButton } from '../Buttons';
import {
  IUseStateStoreDocumentPaymentListReturnType,
  PaymentProps,
} from '@hooks';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { textToUpperCase } from '@services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  PaymentFormDTO,
  PaymentModel,
  PaymentScheduleFormDTO,
  StoreDocumentType,
} from '@structure';
import { Routes, RoutesAcl } from '@services/types';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StoreDocumentPaymentBottomView } from '../Show';
import { PaymentScheduleOperationType } from '@services/api/orders';
import { StyledListActionsContainer } from '../Show/ListStyledComponent';
import { StyledTitle } from '@components/lib/Styled';
import { DownOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StoreDocumentPageHeader } from '../../Stores';

export interface StoreDocumentPaymentListProps
  extends IUseStateStoreDocumentPaymentListReturnType {
  navigateGoBack: () => void;
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  routes: any[];
  rowSelection: any;
  columns: any[];
}

const StyledTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<ITableProps<PaymentModel>>;

const ListActionsContainer = styled(StyledListActionsContainer)`
  gap: 10px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

const createButtonTitles = {
  [StoreDocumentType.IN]: 'Advance',
  [StoreDocumentType.SALE]: 'Advance',
  [StoreDocumentType.RETURN]: 'Advance',
  [StoreDocumentType.MOVE]: 'Advance',
  [StoreDocumentType.ERASE]: 'Advance',
  [StoreDocumentType.CRETURN]: 'Advance',
  [StoreDocumentType.PREORDER]: 'Advance',
  [StoreDocumentType.OUT]: 'Make payment',
};

export function StoreDocumentPaymentList({
  document: storeDocument,
  payments,
  loading,
  limit,
  total,
  page,

  handleRefreshPayments,

  handleCreateStoreDocumentPrepayment,
  handleCreateStoreDocumentRefunds,

  navigateGoBack,

  payment,

  routes,
  rowSelection,
  columns,
}: StoreDocumentPaymentListProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();

  const paidSum = Number(storeDocument?.doc_sum_paid || 0);
  const totalSum = Number(storeDocument?.doc_sum_total || 0);

  const isHiddeAdvanceButton = totalSum > 0 && totalSum - paidSum <= 0;

  const isPaid = Number(storeDocument?.doc_sum_paid || 0) > 0;

  return (
    <ListLayout
      isHeaderFlickering
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t('Cash flow'))}
      headerTitleDescription={
        storeDocument?.uuid
          ? `${t('for the document')} №${storeDocument?.doc_local_number}`
          : null
      }
      headerRight={
        <CreateStoreDocumentPaymentButton
          from={Routes.stores}
          payment={payment}
          companyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreateStoreDocumentPrepayment}
          onSuccessRefund={handleCreateStoreDocumentRefunds}
          scheduleOperationType={
            isHiddeAdvanceButton
              ? PaymentScheduleOperationType.Out
              : isPaid
              ? PaymentScheduleOperationType.ALL
              : PaymentScheduleOperationType.In
          }
          document={storeDocument}
        />
      }
      routes={routes}
      empty={
        payments?.size ? null : (
          <Empty description={t('There are no payments history from range.')}>
            <CreateStoreDocumentPaymentButton
              from={Routes.stores}
              type="primary"
              payment={payment}
              companyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreateStoreDocumentPrepayment}
              onSuccessRefund={handleCreateStoreDocumentRefunds}
              title={
                isHiddeAdvanceButton ? (
                  <StyledTitle>{t('Return funds')}</StyledTitle>
                ) : isPaid ? (
                  <StyledButtonContainer>
                    <StyledTitle>{t('Create payment')}</StyledTitle>
                    <DownOutlined />
                  </StyledButtonContainer>
                ) : (
                  <StyledTitle>
                    {t(createButtonTitles[storeDocument?.doc_type])}
                  </StyledTitle>
                )
              }
              scheduleOperationType={
                isHiddeAdvanceButton
                  ? PaymentScheduleOperationType.Out
                  : isPaid
                  ? PaymentScheduleOperationType.ALL
                  : PaymentScheduleOperationType.In
              }
              document={storeDocument}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActionsContainer>
          {storeDocument?.uuid ? (
            <StoreDocumentPageHeader
              withoutPayment
              withoutIndent
              document={storeDocument}
            />
          ) : null}

          {loading ? null : (
            <StyledButtonContainer>
              {isHiddeAdvanceButton ? null : (
                <CreateStoreDocumentPaymentButton
                  from={Routes.stores}
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={handleCreateStoreDocumentPrepayment}
                  onSuccessRefund={handleCreateStoreDocumentRefunds}
                  title={t(createButtonTitles[storeDocument?.doc_type])}
                  scheduleOperationType={PaymentScheduleOperationType.In}
                  icon={<PlusOutlined />}
                  className="store-document-payment-create-event"
                  document={storeDocument}
                />
              )}

              {isPaid ? (
                <CreateStoreDocumentPaymentButton
                  from={Routes.stores}
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={handleCreateStoreDocumentPrepayment}
                  onSuccessRefund={handleCreateStoreDocumentRefunds}
                  title={t('Return funds')}
                  scheduleOperationType={PaymentScheduleOperationType.Out}
                  icon={<MinusOutlined />}
                  document={storeDocument}
                />
              ) : null}
            </StyledButtonContainer>
          )}
        </ListActionsContainer>
      }
      outsideFooterContent={
        storeDocument?.uuid ? (
          <StoreDocumentPaymentBottomView
            document={storeDocument}
            payment={payment}
            loading={loading}
            createScheduleRefunds={handleCreateStoreDocumentRefunds}
          />
        ) : null
      }
      loading={loading && !payments?.size}>
      <StyledTable
        rowSelection={rowSelection}
        total={total}
        pageSize={limit}
        dataSource={payments}
        onChange={handleRefreshPayments}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
