import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IAbonementListDTO,
  AbonementMapper,
  AbonementModel,
  AbonementListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getAbonementList } from '@services/api/abonement';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseAbonementListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
}

export interface IUseAbonementListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<AbonementModel> | null;
  refresh: (
    value: Partial<IUseAbonementListProps>,
  ) => Promise<AbonementListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
}

export function useAbonementList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
  }: IUseAbonementListProps = {} as IUseAbonementListProps,
): IUseAbonementListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseAbonementListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
    }: Partial<IUseAbonementListProps> = {}): Promise<AbonementListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
        });

        const { abonements, total } =
          await cancellablePromise<IAbonementListDTO>(
            getAbonementList({ limit, offset, keywords, companyUuid }),
          );

        const abonementListModel = AbonementMapper.toAbonementListModel(
          abonements,
          total,
        );

        handleUpdate({
          entityList: abonementListModel?.abonements,
          total: abonementListModel?.total,
          loading: false,
        });

        return abonementListModel;
      } catch (e: any) {
        alert(
          'error',
          t('TariffModels'),
          `${t('An error occurred during get abonement list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
  };
}
