import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountForm, IAccountFormProps } from '../Forms';
import { SideWindow } from '@components/lib/Navigation';
import { ICON_NAME, IconTittle } from '@components/lib/DataDisplay';
import { ProfileFormDTO } from '@structure';

export interface IProfileSideWindowProps extends IAccountFormProps {
  visible: boolean;
  onSuccess: (value: ProfileFormDTO) => Promise<void>;
}

export default function ProfileSideWindow({
  visible,
  onSuccess,
  editMode,
  onCancel,
  ...rest
}: IProfileSideWindowProps) {
  const { t } = useTranslation();

  return (
    <SideWindow
      title={
        <IconTittle
          title={editMode ? t('Edit Profile') : t('Creating Profile')}
          iconName={ICON_NAME.EMPLOYEE}
        />
      }
      visible={visible}
      onClose={onCancel}
      data-testid="add-employee-form-side-window">
      <AccountForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </SideWindow>
  );
}
