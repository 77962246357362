import * as React from 'react';
import { IUseDataItemStateReturnedType } from './useDataItemState';
import { IUseStateEntityListReturnedType } from '@components/lib/libV2/hooks';
import { head, isFunction } from '@services/helpers';
import {
  CashBoxModel,
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
} from '@structure';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import {
  createCompanyPayment,
  deleteCompanyPayment,
  updateCompanyPayment,
} from '@services/api/companyPayments';
import useStoredCompanies from './useStoredCompanies';
import {
  IUseStateCashBoxListReturnType,
  useStoredCashBoxList,
} from './useStoredCashBoxList';
import {
  IPaymentStatsProps,
  IUseStatePaymentListReturnType,
} from './useStatePaymentList';

export interface IUseStateItemPaymentProps<T>
  extends Partial<IUseDataItemStateReturnedType<T>> {}

export interface IUseStatePaymentsProps<T>
  extends Partial<IUseStateEntityListReturnedType<T>>,
    Partial<Pick<IUseStateCashBoxListReturnType, 'handleUpdateDefaultCashBox'>>,
    Partial<Pick<IUseStatePaymentListReturnType, 'refresh'>> {
  cashBox?: CashBoxModel | null;
  handleUpdateStats?: (value: IPaymentStatsProps) => void;
}

export type IUsePaymentActionsProps<T> = IUseStatePaymentsProps<T> &
  IUseStateItemPaymentProps<T>;

export interface IUsePaymentActionsReturnType {
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleCreatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (ids: string[]) => Promise<void>;
}

export function useStatePaymentListActions({
  handleUpdate,
  handleCreate,
  handleDelete,
  cashBox,
  handleUpdateDefaultCashBox,
  refresh,
  handleUpdateStats,
}: IUsePaymentActionsProps<PaymentModel> = {}): IUsePaymentActionsReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { defaultCompanyUuid: companyUuid } = useStoredCompanies();
  const { handleRefreshCashBoxes } = useStoredCashBoxList({
    companyUuid,
    loadOnInit: false,
    limit: 100,
  });

  const handleCreatePayment = React.useCallback(
    async (value: PaymentFormDTO) => {
      const payment = await createCompanyPayment(value, companyUuid);
      const paymentModel = PaymentMapper.toPaymentModel(payment);

      // if (
      //   paymentModel?.company_cashbox?.uuid &&
      //   cashBox?.uuid !== paymentModel?.company_cashbox?.uuid &&
      //   isFunction(handleUpdateDefaultCashBox) &&
      //   isFunction(refresh)
      // ) {
      //   refresh({cashbox_uuid: paymentModel?.company_cashbox?.uuid});
      //
      //   handleUpdateDefaultCashBox(paymentModel?.company_cashbox);
      // } else

      if (cashBox?.uuid === paymentModel?.company_cashbox?.uuid) {
        if (isFunction(handleCreate)) {
          handleCreate(paymentModel, true);

          if (isFunction(handleUpdateStats)) {
            handleUpdateStats({ payment: paymentModel, type: 'create' });
          }
        }
      }

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Payment'), t('Payment created success'));
    },
    [
      companyUuid,
      cashBox?.uuid,
      handleRefreshCashBoxes,
      alert,
      t,
      handleCreate,
      handleUpdateStats,
    ],
  );

  const handleUpdatePayment = React.useCallback(
    async (value: PaymentFormDTO) => {
      const payment = await updateCompanyPayment(value);
      const paymentModel = PaymentMapper.toPaymentModel(payment);

      if (
        paymentModel?.company_cashbox?.uuid &&
        cashBox?.uuid !== paymentModel?.company_cashbox?.uuid &&
        isFunction(handleUpdateDefaultCashBox) &&
        isFunction(refresh)
      ) {
        refresh({ cashbox_uuid: paymentModel?.company_cashbox?.uuid });

        handleUpdateDefaultCashBox(paymentModel?.company_cashbox);
      } else {
        if (isFunction(handleUpdate)) {
          handleUpdate(paymentModel);
          if (isFunction(handleUpdateStats)) {
            handleUpdateStats({ payment: paymentModel, type: 'edit' });
          }
        }
      }

      setTimeout(async () => {
        await handleRefreshCashBoxes({ page: 1, limit: 100 });
      }, 300);

      alert('success', t('Payment'), t('Payment edit success'));
    },
    [
      alert,
      cashBox?.uuid,
      handleRefreshCashBoxes,
      handleUpdate,
      handleUpdateDefaultCashBox,
      handleUpdateStats,
      refresh,
      t,
    ],
  );

  const handleDeletePayments = React.useCallback(
    async (ids: string[]) => {
      try {
        const paymentDTO = await deleteCompanyPayment(head(ids));

        if (isFunction(handleDelete)) {
          handleDelete(ids);
        }

        const paymentModel = PaymentMapper.toPaymentModel(paymentDTO);

        if (isFunction(handleUpdateStats)) {
          handleUpdateStats({ payment: paymentModel, type: 'delete' });
        }

        setTimeout(async () => {
          await handleRefreshCashBoxes({ page: 1, limit: 100 });
        }, 300);

        alert('success', t('Payment'), t('Payments delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Payment'),
          `${t('An error occurred during delete payments')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, handleRefreshCashBoxes, handleUpdateStats, t],
  );

  return {
    handleUpdatePayment,
    handleCreatePayment,
    handleDeletePayments,
  };
}
