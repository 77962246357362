import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  StoreDocumentFormDTO,
  StoreDocumentModel,
  StoreDocumentStatus,
} from '@structure';
import { useDropdownAlert } from '@contex';
import {
  useDefaultPriceMargin,
  useStateCompanyClientList,
  useStateProductList,
  useStoredCompanies,
  useStoredStoreList,
} from '@hooks';
import { Form } from 'antd';
import { OrderRealizationFields } from './OrderRealizationFields';
import { encryptObject, isEqualByUuid, isFunction } from '@services/helpers';
import { SessionStorageItems } from '@services/const';
import { produce } from 'immer';

export interface IOrderRealizationFormProps
  extends Omit<
    IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  onSuccess: (
    value: StoreDocumentFormDTO,
    closeble?: boolean,
  ) => Promise<StoreDocumentModel | void>;
}

export function OrderRealizationForm({
  loading,
  document,
  editMode,
  onSuccess,
  ...rest
}: IOrderRealizationFormProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: document?.uuid,
    }),
    [document?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateLocalClient,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({ companyUuid: defaultCompanyUuid, limit: 50 });

  const {
    productList,
    loading: productListLoading,
    keywords: productListKeywords,
    isLoadingMore: productIsLoadingMore,

    handleSearchProductList,
    handleLoadingMoreProductList,
    handleSetProductList,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const {
    priceMarginList,
    loading: priceMarginListLoading,
    handleSearchPriceMargins,
    defaultPriceMargin,
    setDefaultPriceMargin,
  } = useDefaultPriceMargin({
    loadOnInit: true,
    instance,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store sales invoice')
            : t('An error occurred during create store sales invoice')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const saveStoreToSessionStorage = useCallback(
    (doc_store_uuid: string) => {
      const selectedStore = storeList?.find(isEqualByUuid(doc_store_uuid));

      if (selectedStore) {
        const store = {
          uuid: selectedStore?.uuid,
          store_name: selectedStore?.store_name,
        };

        const encryptStoreData = encryptObject(store);

        sessionStorage.setItem(
          SessionStorageItems.realizationOrderFormStoreData,
          encryptStoreData,
        );
      }
    },
    [storeList],
  );

  const handleOnSuccess = useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.uuid = document?.uuid || '';
          draft.doc_items = draft.doc_items?.map(
            ({
              product_uuid,
              product_amount,
              price,
              product_price,
              cell_identifier,
            }) => ({
              product_uuid,
              product_amount,
              price,
              product_price,
              cell_identifier,
            }),
          );

          draft.doc_status = StoreDocumentStatus.ACTIVE;
        });

        await onSuccess(updatedValue);
        await saveStoreToSessionStorage(updatedValue?.doc_store_uuid as string);
      }
    },
    [onSuccess, document?.uuid, saveStoreToSessionStorage],
  );

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={['Tab']}
      isResetLoading
      withContext
      instance={instance}
      initialValues={{
        ...document,
      }}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      submitButtonText={t('tRealization')}
      {...rest}>
      <OrderRealizationFields
        editMode={!!editMode}
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleCreateLocalClient={handleCreateLocalClient}
        handleSearchClients={handleSearchClients}
        storeList={storeList}
        storeListLoading={storeListLoading}
        handleSearchStores={handleSearchStores}
        productList={productList}
        priceMarginListLoading={priceMarginListLoading}
        productListKeywords={productListKeywords}
        productListLoadingMore={productListLoading}
        productListLoading={productListLoading}
        handleSearchProducts={handleSearchProductList}
        priceMarginList={priceMarginList}
        handleSearchPriceMargins={handleSearchPriceMargins}
        setDefaultPriceMargin={setDefaultPriceMargin}
        defaultPriceMargin={defaultPriceMargin}
        isLoadingMore={productIsLoadingMore}
        handleLoadingMoreProductList={handleLoadingMoreProductList}
        document={document}
        handleSetProductList={handleSetProductList}
      />
    </DefaultForm>
  );
}
