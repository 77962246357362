import { List, Record } from 'immutable';
import {
  company,
  verification,
  verificationHelpers as vHelper,
} from '@services/helpers';
import {
  CategoryDTO,
  CompanyDTO,
  EmployeeDTO,
  GroupDTO,
  ProfileDTO,
  ProfileModel,
  CategoryModel,
  EmployeeModel,
  GroupModel,
  SettingModel,
  CategoryMapper,
  EmployeeMapper,
  ProfileMapper,
  GroupMapper,
  SettingMapper,
  IWorkSchedules,
  CompanyStatuses,
  ProductBarCodeFormat,
  CompanyRequisiteDTO,
} from '../internal';

import { WithoutRecursion } from '../type';

export interface ICompanyModelReturnType {
  id: string;
  uuid: string;
  title: string;
  phone: string;
  sms_notification_price: string;
  phone_checked: number;
  email: string;
  email_checked: number;
  description: string;
  cover_url: string;
  logo_url: string;
  qr_code_url: string;
  comment: string;
  address: string;
  address_langitude: string;
  address_latitude: string;
  slogan: string;
  location_country: string;
  location_region: string;
  location_district: string;
  location_city: string;
  joining_url: string;
  status: CompanyStatuses;
  joining_code: number;
  created_at: string;
  updated_at: string;
  balance: string;
  currency_symbol: string;
  notification_language: string;
  created_by: ProfileModel | ProfileDTO;
  categories: List<CategoryModel> | CategoryDTO[];
  employees: List<EmployeeModel> | EmployeeDTO[];
  work_schedules: string;
  requisites: string;
  close_shift_automatically?: boolean;
  work_schedules_list: IWorkSchedules;
  company_settings: List<SettingModel>;
  groups: List<GroupModel> | GroupDTO[];
  bar_code_formats: ProductBarCodeFormat[];
  price_tag_formats: string[];
  requisite_list: CompanyRequisiteDTO[];
}

export type Status = string | number;

/**
 * @class Company
 * @classdesc
 * @property {string} id - backup id
 */
export class CompanyModel extends Record<ICompanyModelReturnType>({
  id: '',
  uuid: '',
  title: '',
  phone: '',
  comment: '',
  sms_notification_price: '',
  phone_checked: verification.STATUS_UNVERIFIED,
  email: '',
  joining_url: '',
  email_checked: verification.STATUS_UNVERIFIED,
  description: '',
  cover_url: '',
  logo_url: '',
  qr_code_url: '',
  address: '',
  address_langitude: '',
  address_latitude: '',
  slogan: '',
  location_country: 'UKR',
  location_region: '',
  location_district: '',
  location_city: '',
  status: CompanyStatuses.Unchecked,
  joining_code: 0,
  created_at: '',
  updated_at: '',
  balance: '0.00',
  work_schedules: '',
  currency_symbol: '',
  notification_language: '',
  requisites: '',
  created_by: {} as ProfileModel,
  work_schedules_list: {} as IWorkSchedules,
  categories: List(),
  employees: List(),
  groups: List(),
  company_settings: List(),
  bar_code_formats: [],
  price_tag_formats: [],
  close_shift_automatically: true,
  requisite_list: [],
}) {
  constructor(
    props: CompanyDTO = {} as CompanyDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICompanyModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.company,
              ...withoutRecursion,
            ]),
      categories:
        withoutRecursion.indexOf(WithoutRecursion.category) !== -1
          ? props?.categories
          : CategoryMapper.toCategoryListModel(props?.categories || [], 0, [
              WithoutRecursion.company,
              ...withoutRecursion,
            ]).categories,
      employees:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.employees
          : EmployeeMapper.toEmployeeListModel(props?.employees || [], 0, [
              WithoutRecursion.company,
              ...withoutRecursion,
            ]).employees,
      groups:
        withoutRecursion.indexOf(WithoutRecursion.group) !== -1
          ? props?.groups
          : GroupMapper.toGroupListModel(props?.groups || [], [
              WithoutRecursion.company,
              ...withoutRecursion,
            ]).groups,
      company_settings: SettingMapper.toSettingListModel(
        props?.company_settings || [],
      ).settings,
    };

    super(options);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get categoryListModel(): List<CategoryModel> {
    return List.isList(this.categories)
      ? this.categories
      : CategoryMapper.toCategoryListModel(this.categories, 0).categories;
  }

  get employeeListModel(): List<EmployeeModel> {
    return List.isList(this.employees)
      ? this.employees
      : EmployeeMapper.toEmployeeListModel(this.employees, 0).employees;
  }

  get groupListModel(): List<GroupModel> {
    return List.isList(this.groups)
      ? this.groups
      : GroupMapper.toGroupListModel(this.groups).groups;
  }
}
