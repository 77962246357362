import React from 'react';
import { List } from 'immutable';
import { FileModel } from '@structure';
import styled from 'styled-components';
import { FileGroupListItem } from './FileGroupListItem';
import { IUseStateFileListReturnType } from '@hooks';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { toDateByFormat } from '@services/helpers';
import { useTranslation } from 'react-i18next';

export interface IFileListItemProps
  extends Pick<IUseStateFileListReturnType, 'handleDeleteFiles'> {
  files: List<FileModel>;
  label: string;
  disabled?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
`;

export function FileListItem({
  files,
  handleDeleteFiles,
  label,
  disabled,
}: IFileListItemProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>{`${t('Attached files')} ${toDateByFormat(
          label,
          'DD.MM.YYYY',
        )}`}</StyledTitle>
        <StyledDescription>{toDateByFormat(label, 'HH:mm')}</StyledDescription>
      </StyledTitleContainer>
      {files.map((file) => (
        <FileGroupListItem
          disabled={disabled}
          key={file?.uuid}
          file={file}
          handleDeleteFiles={handleDeleteFiles}
        />
      ))}
    </StyledContainer>
  );
}
