import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  INotificationListDTO,
  NotificationMapper,
  NotificationModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getNotificationList } from '@services/api/notification';

export interface IUseNotificationListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUseNotificationListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<NotificationModel> | null;
  refresh: (
    value: Partial<IUseNotificationListProps>,
  ) => Promise<List<NotificationModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  unread: number;
}

export function useNotificationList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
  }: IUseNotificationListProps = {} as IUseNotificationListProps,
): IUseNotificationListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    unread: listUnread,
    handleUpdate,
  } = useStateReducer<Omit<IUseNotificationListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    unread: 0,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUseNotificationListProps> = {}): Promise<List<NotificationModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const { notifications, total, unread } =
          await cancellablePromise<INotificationListDTO>(
            getNotificationList({
              limit,
              offset,
              keywords,
            }),
          );

        const notificationListModel =
          NotificationMapper.toNotificationListModel(
            notifications,
            total,
            unread,
          );

        if (!didCancel.current) {
          handleUpdate({
            entityList: notificationListModel?.notifications,
            total: notificationListModel?.total,
            unread,
            loading: false,
          });

          return notificationListModel?.notifications;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    unread: listUnread,
  };
}
