import React from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';
import ActionButton, { IActionButtonProps } from './ActionButton';

export default ConfirmActionButton;

export interface IConfirmActionButtonProps
  extends IActionButtonProps,
    Pick<PopconfirmProps, 'onConfirm' | 'cancelButtonProps' | 'okButtonProps'> {
  confirmTitle?: React.ReactNode | RenderFunction;
  cancelButtonText?: React.ReactNode;
  confirmButtonText?: React.ReactNode;
}

function ConfirmActionButton({
  children,
  confirmTitle,
  onConfirm,
  okButtonProps,
  cancelButtonProps,
  cancelButtonText,
  confirmButtonText,
  danger,
  ...rest
}: IConfirmActionButtonProps): JSX.Element {
  return (
    <Popconfirm
      placement="bottom"
      okType={danger ? 'danger' : 'primary'}
      okText={confirmButtonText || children}
      cancelText={cancelButtonText}
      title={confirmTitle}
      onConfirm={onConfirm}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}>
      <ActionButton danger={danger} {...rest}>
        {children}
      </ActionButton>
    </Popconfirm>
  );
}
