import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from 'antd/lib/button';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FlexContainer } from '@components/lib/Styled';
import { EventSelectButton } from '../Buttons';
import { IVariable, isThereContent } from '@services/helpers';
import { TextFormat } from '@components/lib/Format';

export default withTranslation()(EventsButtonView);

export interface IEventsButtonViewProps
  extends Omit<ButtonProps, 'onClick'>,
    WithTranslation {
  variables: IVariable[];
  onClick: (value: string) => void;
}

const StyledEventSelectButton = styled(EventSelectButton)`
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px !important;
  font-weight: 600;
  background-color: #f6ffed;
  color: #6db94b;

  &:hover {
    background-color: lighten(#f6ffed, 20%) !important;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: 10px;
  min-height: 24px;
`;

function EventsButtonView({
  variables,
  onClick,
  t,
  ...rest
}: IEventsButtonViewProps): JSX.Element {
  return (
    <StyledFlexContainer flexWrap="wrap">
      {isThereContent(variables) ? (
        variables.map((variable) => (
          <StyledEventSelectButton
            onClick={() => onClick(variable?.variable_name)}
            key={variable?.variable_name}
            tooltip={variable?.variable_comment}
            {...rest}>
            {variable?.variable_name}
          </StyledEventSelectButton>
        ))
      ) : (
        <TextFormat>
          {t('To display in auxiliary variables, select the event template')}
        </TextFormat>
      )}
    </StyledFlexContainer>
  );
}
