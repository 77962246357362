import * as React from 'react';
import {
  useDepartment,
  IUseDepartmentProps,
  IUseDepartmentReturnType,
} from './useDepartment';
import {
  DepartmentFormDTO,
  DepartmentModel,
  DepartmentMapper,
} from '@structure';
import { createDepartment, editDepartment } from '@services/api/department';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { useEffect } from 'react';
import {
  addDepartmentToList as storeAddDepartment,
  setDepartment as storeSetDepartment,
  updateDepartment as storeUpdateDepartment,
  updateDepartmentFromList as storeUpdateDepartmentFromList,
  resetDepartment as storeResetDepartment,
} from '@store/actions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateDepartmentProps extends IUseDepartmentProps {
  companyUuid: string;
}

export interface IUseStateDepartmentReturnType
  extends Omit<IUseDepartmentReturnType, 'entity'> {
  department: DepartmentModel | null;
  handleUpdateDepartment: (value: DepartmentFormDTO) => Promise<void>;
  handleCreateDepartment: (value: DepartmentFormDTO) => Promise<void>;
  handleRefreshDepartment: () => Promise<void>;
  handleResetDepartment: () => void;
  status: REDUX_STATUS;
}

export function useStoredDepartment({
  companyUuid,
  loadOnInit,
  departmentUuid,
  ...rest
}: IUseStateDepartmentProps): {
  handleUpdateDepartment: (value: DepartmentFormDTO) => Promise<void>;
  handleResetDepartment: () => void;
  handleCreateDepartment: (value: DepartmentFormDTO) => Promise<void>;
  handleRefreshDepartment: () => Promise<void>;
  refresh: (
    value: Partial<IUseDepartmentProps>,
  ) => Promise<DepartmentModel | void>;
  error: ApiError | null;
  loading: boolean;
  department: DepartmentModel | null;
  departmentUuid: string;
} {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [department, setDepartment] = React.useState<DepartmentModel | null>(
    null,
  );
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const { cachedDepartmentList, status, ...storedDepartmentParams } =
    useSelector(({ department }: RootState) => department);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...departmentParams } = useDepartment({
    loadOnInit: loadOnInit || isStarLoading,
    departmentUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedDepartmentList?.size > 0) {
      const department = cachedDepartmentList.get(departmentUuid);

      if (department) {
        setIsStartLoading(false);
        setDepartment(department);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedDepartmentList?.size === 0 && !department) {
      setIsStartLoading(true);
    }
  }, [cachedDepartmentList, dispatch, departmentUuid, department]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetDepartment(entity));
      setDepartment(entity);
    }
  }, [dispatch, entity, isStarLoading]);

  const handleUpdateDepartment = React.useCallback(
    async (value: DepartmentFormDTO): Promise<void> => {
      const departmentDTO = await editDepartment(value);

      const departmentModel = DepartmentMapper.toDepartmentModel(departmentDTO);

      dispatch(storeUpdateDepartment(departmentModel));
      dispatch(storeUpdateDepartmentFromList(departmentModel));

      setDepartment(departmentModel);
    },
    [dispatch],
  );

  const handleCreateDepartment = React.useCallback(
    async (value: DepartmentFormDTO) => {
      const departmentDTO = await createDepartment(value, companyUuid);

      const departmentModel = DepartmentMapper.toDepartmentModel(departmentDTO);

      dispatch(storeAddDepartment(departmentModel));
    },
    [companyUuid, dispatch],
  );

  const handleResetDepartment = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetDepartment());
  }, [dispatch]);

  const handleRefreshDepartment = React.useCallback(async () => {
    const departmentModel = await refresh({
      showLoading: false,
      departmentUuid,
    });

    if (departmentModel) {
      dispatch(storeUpdateDepartment(departmentModel));
      dispatch(storeUpdateDepartmentFromList(departmentModel));
    }
  }, [departmentUuid, dispatch, refresh]);

  return {
    ...departmentParams,
    ...storedDepartmentParams,
    department,
    loading: !department,
    handleUpdateDepartment,
    handleCreateDepartment,
    handleResetDepartment,
    refresh,
    handleRefreshDepartment,
  };
}
