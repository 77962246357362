import React, { MutableRefObject, useEffect, useRef } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { SearchSelect } from '@components/lib/DataDisplay';
import { listToArray } from '@services/helpers';
import { StoreDocumentStatus } from '@structure';
import { IUseStateStoreListReturnType } from '@hooks';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import { StoreDocumentRefObject } from '../../helpers';

export interface IStoreWriteOffFieldsProps
  extends Pick<
    IUseStateStoreListReturnType,
    'storeList' | 'handleSearchStores'
  > {
  loading: boolean;
  editMode: boolean;
  storeListLoading: boolean;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StoreWriteOffFields({
  loading,
  editMode,

  storeList,
  storeListLoading,
  handleSearchStores,

  documentEditRef,
}: IStoreWriteOffFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { loadingSubmit, handlerUpdateFormState, formData }: any =
    useDefaultForm();

  useEffect(() => {
    if (documentEditRef?.current) {
      const { errorFields, ...rest } = formData;

      documentEditRef.current.document = rest;
    }
  }, [documentEditRef, formData]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          label={t('Store')}
          name="doc_store_uuid"
          tooltip={t('Store')}
          rules={[
            {
              required: true,
              message: t('Store must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_store_uuid"
            disable={loading || storeListLoading || loadingSubmit || editMode}
            placeholder={t('Select a store')}
            data={listToArray(storeList as any)}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({ keywords })}
            resetState={storeListLoading}
            onChange={(doc_store_uuid) =>
              handlerUpdateFormState({ doc_store_uuid })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ doc_comment: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
