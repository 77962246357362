import { SignupForm } from '../Forms';
import { useStateSignup } from '@hooks';
import { Routes } from '@services/types';
import { useDropdownAlert } from '@contex';
import { RootState } from '@store/reducers';
import AuthLayout from '../../Show/AuthLayout';
import { WarningNavigateButton } from '../../Buttons';
import { useLocation, useNavigate } from 'react-router';
import { HeaderText, FullWidthSpace } from '../../Show';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { IEmployeeSignupPageState } from '@store/actions';
import { SignupFormMapper, SignupFormDTO } from '@structure';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useAuthLayout } from '@components/lib/Layout/AuthLayout';
import React, { useCallback, useEffect, useLayoutEffect } from 'react';

export default withTranslation()(EmployeeSignupPage);

export interface IEmployeeSignupPageProps extends WithTranslation {}

function EmployeeSignupPage({ t }: IEmployeeSignupPageProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    identifier,
    approveEnter,
    loading,
    isShowWarning,

    handleSignup,
    initStateFactory,
    navigateToVerificationFormModal,
    updateStateFactory,
    notifyError,
  } = useStateSignup<Omit<IEmployeeSignupPageState, 'timeWhenCodeWasSend'>>({
    key: Routes.employeeSignup,
    getState: (state: RootState) => ({
      identifier: state[Routes.employeeSignup]?.identifier,
      approveEnter: state[Routes.employeeSignup]?.approveEnter,
      loading: state[Routes.employeeSignup]?.loading,
      isShowWarning: state[Routes.employeeSignup]?.isShowWarning,
    }),
  });

  const isApproveEnter = typeof approveEnter === 'boolean' && !approveEnter;

  const { alert } = useDropdownAlert();
  const { setOptions } = useAuthLayout();

  const navigationFactory = useCallback(() => {
    if (identifier && !isApproveEnter) {
      return navigateToVerificationFormModal();
    }

    if (isApproveEnter) {
      navigate(`/${Routes.auth}/${Routes.approveEnter}`, {
        state: {
          loadingOnInit: false,
          from: location.pathname,
        },
      });
    }
  }, [
    identifier,
    isApproveEnter,
    location.pathname,
    navigate,
    navigateToVerificationFormModal,
  ]);

  const handleShowWarning = useCallback(
    (isShowWarning = true) => {
      updateStateFactory({ isShowWarning });
    },
    [updateStateFactory],
  );

  const navigationGoBack = useCallback(() => {
    navigate(location?.state?.from || `/${Routes.auth}/${Routes.authOffice}`);
    handleShowWarning();
  }, [handleShowWarning, location?.state?.from, navigate]);

  useLayoutEffect(() => {
    setOptions({
      pathname: location.pathname,
      headerLeft: () => <HeaderLeft onClick={navigationGoBack} />,
    });
  }, [location.pathname, navigationGoBack, setOptions]);

  useEffect(() => {
    if (isShowWarning && identifier) {
      alert(
        'warn',
        t('You have an incomplete registration'),
        isApproveEnter
          ? t('Check your status')
          : t('Complete the registration'),
      );
      handleShowWarning(false);
    }
  }, [
    handleShowWarning,
    identifier,
    isApproveEnter,
    isShowWarning,
    alert,
    t,
    updateStateFactory,
  ]);

  useEffect(() => {
    if (loading) {
      initStateFactory();
    }
  }, [initStateFactory, loading]);

  return (
    <AuthLayout>
      <FullWidthSpace direction="vertical" size="middle">
        <HeaderText
          title={t('Create a profile')}
          description={t(
            'Fill out all the fields below to create your profile',
          )}
        />
        <SignupForm
          withVerificationCode
          showNotify={false}
          notifyError={notifyError}
          notifyFormName={t('Authorization')}
          signup={SignupFormMapper.toSignupFormDTO(new SignupFormDTO())}
          onSuccess={handleSignup}
        />
        {identifier || isApproveEnter ? (
          <WarningNavigateButton
            onClick={navigationFactory}
            title={
              isApproveEnter
                ? t('Check status')
                : t('Complete preliminary registration')
            }
          />
        ) : null}
      </FullWidthSpace>
    </AuthLayout>
  );
}
