import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import {
  getArrayLength,
  isRecordToObject,
  lessOrEqualThan,
} from '@services/helpers';
import { IUseStateTemplateListReturnType } from '@hooks';
import { Template } from '@services/models';
import { TemplateDeleteButton, TemplateEditButton } from '../Buttons';
import { StyledEditOutlined } from '@components/lib/Styled';
import { useDropdownAlert } from '@contex';

export interface ITemplateListProps
  extends Omit<ITableProps<any>, 'columns' | 'loading' | 'dataSource'>,
    Omit<
      IUseStateTemplateListReturnType,
      'handleCreateTemplate' | 'handleSearchTemplates'
    > {
  disabled?: boolean;
  daleyFocus: (ms?: number) => void;
}

export default function TemplateList({
  templates,
  limit = 7,
  loading,
  refresh = async () => {},
  total,
  handleDeleteTemplates,
  handleUpdateTemplate,
  disabled,
  daleyFocus,
  ...rest
}: ITemplateListProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedTemplates.map(
        ({ template_uuid }) => template_uuid,
      ),
      onChange: (selectedRowKeys: React.Key[], selectedRecords: Template[]) => {
        if (lessOrEqualThan(getArrayLength(selectedRecords), 1)) {
          setSelectedTemplates(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedTemplates, t],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      width: '80%',
      render: (c: Template) => c?.template_title,
    },
    {
      title: t('Actions'),
      key: 'template_actions',
      align: 'center',
      fixed: 'right' as any,
      render: (record: any) => (
        <TableActionCell>
          <TemplateEditButton
            disabled={disabled}
            type="text"
            template={isRecordToObject(record)}
            onSuccess={handleUpdateTemplate}
            onCancel={daleyFocus}>
            <StyledEditOutlined />
          </TemplateEditButton>

          <TemplateDeleteButton
            disabled={disabled}
            template={[isRecordToObject(record)]}
            onSuccess={handleDeleteTemplates}>
            <DeleteOutlined />
          </TemplateDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  return (
    <Table<Template>
      loading={loading}
      rowKey={'template_uuid' as any}
      rowSelection={rowSelection}
      {...rest}
      dataSource={templates}
      columns={columns as any}
      onChange={refresh}
      total={total}
    />
  );
}
