import {
  EmployeeDTO,
  EmployeeMapper,
  IEmployeeDTOProps,
  ClientAbonementDTO,
  ClientAbonementMapper,
  IClientAbonementDTOProps,
  IClientVisitModelReturnType,
  IClientAbonementLatestStatusDTOProps,
} from '../internal';
import { WithoutRecursion } from '../type';
import { dateToIsoString } from '@services/helpers';
export interface IClientVisitDTOProps {
  uuid: string;
  visit_date: string;
  visit_comment: string;
  created_at: string;
  updated_at: string;
  client_abonement: IClientAbonementDTOProps;
  manager: IEmployeeDTOProps;
}

export class ClientVisitDTO {
  public uuid: string;
  public visit_date: string;
  public visit_comment: string;
  public created_at: string;
  public updated_at: string;
  public client_abonement: ClientAbonementDTO;
  public manager: EmployeeDTO;

  constructor(
    props: IClientVisitModelReturnType | IClientVisitDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.visit_date = props?.visit_date || '';
    this.visit_comment = props?.visit_comment || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.client_abonement =
      withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
        ? (props?.client_abonement as ClientAbonementDTO)
        : ClientAbonementMapper.toClientAbonementDTO(
            props?.client_abonement,
            {} as IClientAbonementLatestStatusDTOProps,
            [WithoutRecursion.clientAbonement, ...withoutRecursion],
          ).abonement;
    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);
  }
}
