import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, message } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredProfile,
  useStoredReward,
  useStoredRewardList,
  useStoreEmployee,
} from '@hooks';
import {
  ColorView,
  HeaderLeft,
  ListActions,
} from '@components/lib/DataDisplay';
import {
  isThereContent,
  len,
  lessOrEqualThan,
  toDateByFormat,
  dateToIsoString,
} from '@services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  CompanyOrderBonusDTO,
  EmployeeMapper,
  EmployeeSalaryFormDTO,
  EmployeeSalaryModel,
  RewardMapper,
  RewardModel,
  RewardPaymentFormDTO,
  RewardRewardableType,
  RewardStatusesText,
} from '@structure';
import { Routes, RoutesAcl } from '@services/types';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { useNavigate, useParams } from 'react-router';
import { useAcl } from '@contex';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  RewardDatePicker,
  RewardHeader,
  RewardSalaryAndBonusesModal,
  RewardListBottomView,
  RewardColumnDetailsField,
} from '../Show';
import { RewardCreateButton, RewardDeleteButton } from '../Buttons';
import styled, { useTheme } from 'styled-components';
import './RewardListPage.less';
import { StyledTitle } from '@components/lib/Styled';
import { EmployeeHeader } from '../../Employees';

const DELETE_ACCESS = [
  RewardRewardableType.Penalty,
  RewardRewardableType.Premium,
];

const StyledListSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export function RewardListPage() {
  const { t } = useTranslation();
  const { manage } = useAcl(({ payment_group }) => payment_group);
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const { defaultCompany, defaultCompanyUuid } = useStoredCompanies();
  const { profile } = useStoredProfile();
  const theme: any = useTheme();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'reward-create-event',
    disabled: !manage,
  });

  const [selectedRewards, setSelectedRewards] = useState<RewardModel[]>([]);

  const {
    rewardList,
    loading: rewardsLoading,
    error: rewardsError,
    loadingMore,
    limit,
    total,
    keywords,
    stats,
    month,
    year,
    salarySettingsDates,
    offset,
    page,

    handleDeleteRewards: onDeleteRewards,
    handleSearchRewards,
    handleRefreshRewards,
    handlePrintRewardDocument,
  } = useStoredRewardList({
    employeeUuid: employeeId!,
  });

  const {
    handleCreateRewardAwardRecord,
    handleCreateRewardFineRecord,
    handleCreateRewardPayment,
    handleUpdateStoredRewardStats,
  } = useStoredReward({
    employeeUuid: employeeId!,
    rewardUuid: '',
    loadOnInit: false,
    month,
    year,
    keywords,
  });

  const { handleChangeEmployeeBonuses, handleChangeEmployeeSalary } =
    useStoreEmployee({
      companyUuid: defaultCompanyUuid,
      employeeUuid: employeeId!,
      loadOnInit: false,
    });

  const updateSalary = useCallback(
    (value: EmployeeSalaryFormDTO) => {
      const { employee } = stats || {};
      const { salary_settings } = employee || {};

      const salaryFormDTO = {
        ...value,
        start_date: dateToIsoString(value?.start_date),
      };

      const salary = EmployeeMapper.toEmployeeSalaryListDTO(
        salary_settings || [],
        0,
      ).salary;

      return { salary, salaryFormDTO };
    },
    [stats],
  );

  const createEmployeeSalary = useCallback(
    async (value: EmployeeSalaryFormDTO) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);

        salary.push(salaryFormDTO as any);

        const employeeModel = await handleChangeEmployeeSalary(salary);

        handleUpdateStoredRewardStats({ employee: employeeModel });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  const updateEmployeeSalary = useCallback(
    async (value: EmployeeSalaryFormDTO) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);

        const filteredSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        filteredSalary.push(salaryFormDTO as any);

        const employeeModel = await handleChangeEmployeeSalary(filteredSalary);

        handleUpdateStoredRewardStats({ employee: employeeModel });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  const deleteEmployeeSalary = useCallback(
    async (value: EmployeeSalaryModel) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);

        const salaryDTO = EmployeeMapper.toEmployeeSalaryDTO({
          ...salaryFormDTO,
          enabled: false,
        });

        const filteredSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        filteredSalary.push(salaryDTO);

        const employeeModel = await handleChangeEmployeeSalary(filteredSalary);

        const deletedSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        const updatedEmployee = employeeModel.update(
          'salary_settings',
          () =>
            EmployeeMapper.toEmployeeSalaryListModel(
              deletedSalary,
              deletedSalary?.length,
            ).salary,
        );

        handleUpdateStoredRewardStats({ employee: updatedEmployee });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  const changeEmployeeBonuses = useCallback(
    async (value: CompanyOrderBonusDTO[]) => {
      const employeeModel = await handleChangeEmployeeBonuses(value);

      handleUpdateStoredRewardStats({ employee: employeeModel });

      return employeeModel;
    },
    [handleChangeEmployeeBonuses, handleUpdateStoredRewardStats],
  );

  const createRewardPayment = useCallback(
    async (value: RewardPaymentFormDTO) => {
      const totalAccrued = await handleCreateRewardPayment(value);
      await handleRefreshRewards({
        limit,
        offset,
        year,
        month,
        keywords,
        page,
        totalAccrued,
      });

      return totalAccrued;
    },
    [
      handleCreateRewardPayment,
      handleRefreshRewards,
      keywords,
      limit,
      month,
      offset,
      page,
      year,
    ],
  );

  const loading = useStopLoading({
    loading: rewardsLoading || loadingMore,
    error: rewardsError,
    message: 'An error occurred during rewards loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteRewards = useCallback(
    async (value: string[]) => {
      await onDeleteRewards(value);
      focus();
    },
    [focus, onDeleteRewards],
  );

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedRewards.map(({ uuid }) => uuid),
    onChange: async (
      selectedRowKeys: React.Key[],
      selectedRecords: RewardModel[],
    ): Promise<void> => {
      if (lessOrEqualThan(len(selectedRecords), 1)) {
        setSelectedRewards(selectedRecords);
      } else {
        await message.error(t('You can delete up to 1 at a time'));
      }
    },
  };

  const columns = [
    {
      title: t('Date and time'),
      key: 'date',
      className: 'reward-list-td',
      render: (reward: RewardModel) => (
        <StyledTitle fontSize={15}>
          {toDateByFormat(reward?.date, 'DD.MM.YYYY HH:mm')}
        </StyledTitle>
      ),
    },
    {
      title: t('Details'),
      key: 'source',
      render: (reward: RewardModel) => (
        <RewardColumnDetailsField
          reward={reward}
          employee={stats?.employee || null}
          page={page}
        />
      ),
    },
    {
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || 0}`,
      key: 'bonus',
      align: 'right' as any,
      className: 'reward-list-td',
      render: (reward: RewardModel) => (
        <ColorView
          color={
            Number(reward?.price) >= 0
              ? theme.colors.success
              : theme.colors.error
          }>
          {reward?.price || '0.00'}
        </ColorView>
      ),
    },
    {
      title: t('Actions'),
      key: 'payment_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'reward-list-td',
      render: (reward: RewardModel) => {
        return (
          <TableActionCell>
            <RewardDeleteButton
              rewards={[reward]}
              onSuccess={handleDeleteRewards}
              disabled={
                reward?.status_text === RewardStatusesText.Paid ||
                !DELETE_ACCESS.includes(reward?.rewardable_type) ||
                Number(reward?.sum_paid) > 0
              }
            />
          </TableActionCell>
        );
      },
    },
  ];

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${Routes.employees}/${employeeId}`,
      breadcrumbName: stats?.employee?.fullName || t('Show Employee'),
    },
    {
      path: `/${Routes.employees}/${employeeId}/${Routes.rewards}`,
      breadcrumbName: 'Salary and bonuses',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.employees}/${employeeId}`);
  }, [employeeId, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.rewards]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={t('Salary')}
      headerRight={
        <RewardSalaryAndBonusesModal
          disabled={!rewardList}
          salary={stats?.employee?.salary_settings!}
          bonuses={stats?.employee?.bonus_list!}
          handleChangeEmployeeBonuses={changeEmployeeBonuses}
          updateEmployeeSalary={updateEmployeeSalary}
          deleteEmployeeSalary={deleteEmployeeSalary}
          createEmployeeSalary={createEmployeeSalary}
          salarySettingsDates={salarySettingsDates}
          daleyFocus={daleyFocus}
        />
      }
      routes={routes}
      empty={
        rewardList?.size ? null : (
          <Empty description={t('No charges were found for this period')}>
            <RewardCreateButton
              type="primary"
              payment={RewardMapper.toRewardPaymentFormDTO({} as RewardModel, {
                cashier: profile?.defaultEmployeeModel,
                employee: stats?.employee,
                stats: stats!,
                comment: `${t('Payment of charges')} ${
                  stats?.employee?.fullName
                }`,
              })}
              reward={RewardMapper.toRewardFormDTO({} as RewardModel)}
              loading={loading}
              title={t('Create a record')}
              handleCreateRewardFineRecord={handleCreateRewardFineRecord}
              handleCreateRewardAwardRecord={handleCreateRewardAwardRecord}
              handleCreateRewardPayment={createRewardPayment}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      outsideFooterContent={
        <>
          {isThereContent(stats) ? (
            <RewardListBottomView
              stats={stats}
              month={month}
              year={year}
              handlePrintRewardDocument={handlePrintRewardDocument}
            />
          ) : null}
        </>
      }
      headerExtra={
        <StyledListSearchContainer>
          {isThereContent(stats) ? (
            <EmployeeHeader employee={stats?.employee} showBalance />
          ) : null}

          <ListActions
            inputRef={inputRef}
            withoutPicker
            searchText={keywords}
            loading={loading}
            handleSearch={handleSearchRewards}
            inputTooltip={t('Search rewards')}
            inputLabel={t('Search rewards')}>
            {({ fullWidth }) => (
              <RewardDatePicker
                fullWidth={fullWidth}
                loading={loading}
                month={month}
                year={year}
                handleRefreshRewards={handleRefreshRewards}
              />
            )}
          </ListActions>
        </StyledListSearchContainer>
      }
      loading={loading && !rewardList?.size}>
      <>
        <RewardHeader
          handleCreateRewardAwardRecord={handleCreateRewardAwardRecord}
          handleCreateRewardFineRecord={handleCreateRewardFineRecord}
          handleCreateRewardPayment={createRewardPayment}
          profile={profile}
          stats={stats}
          daleyFocus={daleyFocus}
        />
        <Table<RewardModel>
          rowSelection={rowSelection}
          total={total}
          pageSize={limit}
          dataSource={rewardList}
          onChange={handleRefreshRewards}
          columns={columns}
          loading={loading}
          page={page}
        />
      </>
    </ListLayout>
  );
}
