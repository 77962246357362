import { ApiAnswer } from '@services/types';
import { apiPost } from '@services/core/api';
import { EmployeeMapper } from '@structure';
import { contentApiUrl } from '@services/const';
import { EmployeeShiftModel } from '@models/EmployeeShiftModel';
import { IEmployeeShiftDTOModel } from '@structure/dto/EmployeeShiftDTO';
import { EmployeeShiftFormDTO } from '@structure/dto/EmployeeShiftFormDTO';

export interface IEmployeeShiftReturnType {
  shift: IEmployeeShiftDTOModel;
  status: boolean;
}

interface IEmployeeShiftCreatePayload {
  comment: string;
}

interface IEmployeeShiftClosePayload {
  comment: string;
}

export interface IEmployeeShiftResponse extends ApiAnswer {
  code: number;
  shift: EmployeeShiftModel;
  success: boolean;
}

export async function createEmployeeShiftByCurrentProfile({
  comment,
  employeeUuid,
}: EmployeeShiftFormDTO): Promise<IEmployeeShiftReturnType> {
  const { code, shift, success } = await apiPost<
    IEmployeeShiftCreatePayload,
    IEmployeeShiftResponse
  >(`${contentApiUrl}/employees/${employeeUuid}/shifts`, { comment });

  return {
    shift: EmployeeMapper.toEmployeeShiftDTO(shift),
    status: success,
  };
}

export async function closeEmployeeShiftById({
  comment,
  uuid,
}: EmployeeShiftFormDTO): Promise<IEmployeeShiftReturnType> {
  const { code, shift, success } = await apiPost<
    IEmployeeShiftClosePayload,
    IEmployeeShiftResponse
  >(`${contentApiUrl}/employee-shifts/${uuid}/close`, { comment });

  return {
    shift: EmployeeMapper.toEmployeeShiftDTO(shift),
    status: success,
  };
}
