import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, Row, Button } from 'antd';
import { useDefaultForm } from '@contex';
import { SearchSelect, Segmented } from '@components/lib/DataDisplay';
import DatePicker from '@components/lib/General/DatePicker';
import { Message } from '@components/lib/DataEntry';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  systemSubscriptions,
  PLANS_TRIAL,
  toDateByFormat,
  correctPrice,
  EPlanTypeIdentifier,
} from '@services/helpers';
import {
  SUBSCRIPTION_PERIODS,
  subscriptionPlanRecalculation,
  ISubscriptionPlanRecalculationProps,
  ISystemSubscription,
} from '@services/api/subscriptions';
import { useStoredCompanies } from '@hooks';
import { LoadingOutlined } from '@ant-design/icons';

export default withTranslation()(SubscriptionTariffFormFields);

export interface ISubscriptionTariffFormFieldsProps extends WithTranslation {
  loading: boolean;
  onCancel: (() => void) | undefined;
}

const StyledInput = styled(Input)<{ $bold?: boolean }>`
  color: ${({ theme }) => theme.colors.primary} !important;

  & * {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  & .ant-input-group-addon {
    width: 37px !important;
  }

  ${({ $bold }) =>
    $bold &&
    css`
      & input {
        font-weight: 700;
      }
    `}
`;

const StyledMessageContainer = styled.div`
  padding: 20px;
  background-color: rgb(233, 244, 254);
  border: 1px solid rgb(194, 222, 252);
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  > * {
    margin-left: 10px;
  }
`;

const StyledRow = styled(Row)`
  border-top: ${({ theme }) => theme.colors.borderColor} solid 1px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledSubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.success};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: ${({ theme }) => theme.colors.success};
    opacity: 0.7;
  }
`;

const StyledSearchSelect = styled(SearchSelect)<{ $isSelected: boolean }>`
  &&& {
    width: 33px;

    .ant-select-selector {
      position: relative;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transition: all 1s;

      ${({ $isSelected, theme }) =>
        $isSelected &&
        css`
          & + .ant-select-arrow {
            color: ${theme.colors.white};
          }

          &:before {
            content: ' ';
            position: absolute;
            left: 0;

            width: 33px;
            height: 100%;
            background-color: ${theme.colors.link};
            border-radius: 7px;
          }
        `}
    }
  }
`;

function SubscriptionTariffFormFields({
  t,
  loading,
  onCancel,
}: ISubscriptionTariffFormFieldsProps): JSX.Element {
  const {
    loadingSubmit,
    handlerUpdateFormState,
    formData,
    getFieldValue,
    submit,
  } = useDefaultForm();

  const [recalculateLoading, setRecalculateLoading] = useState(false);

  const { defaultCompany } = useStoredCompanies();

  const [sysSubscriptions, setSysSubscriptions] = useState<
    ISystemSubscription[] | null
  >(null);

  useEffect(() => {
    if (!formData?.id) {
      handlerUpdateFormState({ id: t(formData?.identifier) });

      const filterSystemSubscriptions = systemSubscriptions.filter(
        ({ id }) => id !== formData?.identifier && !(id === PLANS_TRIAL),
      );

      setSysSubscriptions(filterSystemSubscriptions);
    }
  }, [formData?.id, formData?.identifier, handlerUpdateFormState, t]);

  const recalculation = useCallback(
    async (props: Omit<ISubscriptionPlanRecalculationProps, 'uuid'>) => {
      try {
        setRecalculateLoading(true);
        const uuid = formData?.uuid;
        const total = await subscriptionPlanRecalculation({ ...props, uuid });

        handlerUpdateFormState({ sum: correctPrice(total) });
        setRecalculateLoading(false);
      } catch (error) {
        setRecalculateLoading(false);
      }
    },
    [formData?.uuid, handlerUpdateFormState],
  );

  const handleChangePlan = useCallback(
    async (package_name: string) => {
      const start_date = getFieldValue('start_date');
      const amount = getFieldValue('amount');

      handlerUpdateFormState({ package: package_name });
      if (start_date && amount) {
        await recalculation({
          start_date,
          amount,
          package_name,
        });
      }
    },
    [getFieldValue, handlerUpdateFormState, recalculation],
  );

  const handleChangeStartDate = useCallback(
    async (start_date: Date) => {
      const package_name = getFieldValue('package');
      const amount = getFieldValue('amount');

      handlerUpdateFormState({ start_date });

      if (package_name && amount) {
        await recalculation({
          start_date,
          amount,
          package_name,
        });
      }
    },
    [getFieldValue, handlerUpdateFormState, recalculation],
  );

  const handleChangeAmount = useCallback(
    async (amount: number) => {
      const package_name = getFieldValue('package');
      const start_date = getFieldValue('start_date');

      handlerUpdateFormState({ amount });
      if (package_name && start_date) {
        await recalculation({
          start_date,
          amount,
          package_name,
        });
      }
    },
    [getFieldValue, handlerUpdateFormState, recalculation],
  );

  return (
    <>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            tooltip={t('Current tariff plan console')}
            label={t('Current tariff plan console')}
            name="id">
            <StyledInput data-testid="subscription-input-identifier" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            tooltip={t('New tariff plan')}
            label={t('New tariff plan')}
            name="package"
            rules={[
              {
                required: true,
                message: t('New tariff plan must be specified'),
              },
            ]}>
            <SearchSelect
              placeholder={t('Choose a tariff model')}
              data={sysSubscriptions as any}
              name="package"
              getOptionValueProps="id"
              getOptionValueTitle={({ title, price }) =>
                `${t(title.toLowerCase())} / ${
                  title.toLowerCase() === EPlanTypeIdentifier.INDIVIDUAL
                    ? t('Free')
                    : `${price} ${t('UAH')}`
                }.`
              }
              onChange={handleChangePlan}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item tooltip={t('Periods')} label={t('Periods')} name="amount">
            <Segmented
              options={SUBSCRIPTION_PERIODS}
              disabled={loadingSubmit}
              onChange={handleChangeAmount as any}
              addonAfter={
                <Form.Item noStyle name="amount">
                  <StyledSearchSelect
                    $isSelected={formData?.amount === '1'}
                    withOutSearch
                    showValue={false}
                    popupMatchSelectWidth={false}
                    name="amount"
                    placeholder=""
                    disable={loadingSubmit}
                    data={[
                      {
                        uuid: '1',
                        title: 'month',
                      },
                    ]}
                    containerStyle={{ width: 33 }}
                    onChange={handleChangeAmount}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            tooltip={t('Start date')}
            label={t('Start date')}
            required
            name="start_date">
            <StyledDatePicker
              disabled={loading || loadingSubmit}
              onChange={handleChangeStartDate as any}
              placeholder=""
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item tooltip={t('Amount')} label={t('Amount')} name="sum">
            <StyledInput
              $bold
              placeholder={'0.00'}
              style={{ width: '100%' }}
              data-testid="subscription-input-periods"
              addonBefore={
                recalculateLoading ? (
                  <LoadingOutlined />
                ) : (
                  defaultCompany?.currency_symbol
                )
              }
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item tooltip={t('Comment')} label={t('Comment')} name="comment">
            <Input.TextArea
              disabled={loading || loadingSubmit}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={t('Enter comment')}
              onChange={(e) =>
                handlerUpdateFormState({ comment: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {formData.package && formData.sum > 0 ? (
            <StyledMessageContainer>
              <Message
                message={`${t('It is necessary to pay extra')} ${
                  formData.sum
                } ${t('UAH')}.`}
                messageType="success">
                <span>{`${t('Transition to a new tariff')} - ${t(
                  formData.package,
                )} ${t('will operate from')} ${toDateByFormat(
                  formData.start_date,
                  'YYYY-MM-DD',
                )} ${t('according to the tariff')}.`}</span>
              </Message>
            </StyledMessageContainer>
          ) : null}
        </Col>
      </Row>
      <StyledRow gutter={20}>
        <Col span={24}>
          <ButtonWrapper>
            <Button disabled={loadingSubmit} onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <StyledSubmitButton
              disabled={loadingSubmit}
              type="primary"
              htmlType="submit"
              onClick={submit}>
              {Number(formData?.sum) > 0 ? t('Pay') : t('Submit')}
            </StyledSubmitButton>
          </ButtonWrapper>
        </Col>
      </StyledRow>
    </>
  );
}
