import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Menu, Dropdown, Button, Space } from 'antd';
import { UserOutlined, LogoutOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isThereContent } from '@services/helpers';
import { Text } from '../Format';
import { ProfileModel } from '@structure';
import { Routes } from '@services/types';
import { PROFILE_TAB } from '../../../views/Profile';

export default withTranslation()(HeaderProfileMenu);

export interface IHeaderProfileMenuProps extends WithTranslation {
  profile: ProfileModel | null;
  handleLogout: () => Promise<void>;
  isPhoneScreen: boolean;
  isTableScreen: boolean;
}

const StyledMenuButton = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 5px 0;

  &:hover {
    background-color: transparent;
  }
`;

const StyledMenu = styled(Menu)`
  min-width: 150px;
  padding: 0;
`;

const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.appLightDisable};
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 0;
`;

function HeaderProfileMenu({
  t,
  profile,
  handleLogout,
  isPhoneScreen,
  isTableScreen,
}: IHeaderProfileMenuProps): JSX.Element {
  const menu = (
    <StyledMenu data-testid="header__user-menu">
      {isThereContent(profile) && profile?.isFullName && isTableScreen ? (
        <StyledMenuItem key="profile-name">
          <Text>
            {profile?.shortFullName.length > 15
              ? `${profile?.shortFullName.substring(0, 15)}...`
              : profile?.shortFullName}
          </Text>
        </StyledMenuItem>
      ) : null}
      <StyledMenuItem key="profile">
        <StyledLink
          to={`/${Routes.app}/${Routes.profile}/${PROFILE_TAB.NOTIFICATIONS}`}>
          <Space>
            <UserOutlined />
            {t('Profile')}
          </Space>
        </StyledLink>
      </StyledMenuItem>
      <StyledMenuItem key="logout">
        <StyledMenuButton type="text" onClick={handleLogout}>
          <LogoutOutlined /> {t('Logout')}
        </StyledMenuButton>
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={['hover']} placement="bottomRight">
      <Button size="middle" type="default">
        <Space size={15}>
          {isThereContent(profile) && profile?.isFullName && !isTableScreen && (
            <Text>
              {profile?.shortFullName.length > 15
                ? `${profile?.shortFullName.substring(0, 15)}...`
                : profile?.shortFullName}
            </Text>
          )}
          <UserOutlined size={15} />
        </Space>
      </Button>
    </Dropdown>
  );
}
