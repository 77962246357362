import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IntegrationCheckboxForm,
  IntegrationCheckboxFormProps,
} from '../Forms';
import { SideWindow } from '@components/lib/Navigation';
import {
  IntegrationCheckboxAttributesDTO,
  IntegrationFormDTO,
} from '@structure';

export interface IIntegrationCheckboxSideWindowProps
  extends IntegrationCheckboxFormProps {
  onSuccess: (
    value: IntegrationFormDTO<IntegrationCheckboxAttributesDTO>,
  ) => Promise<void>;
  visible: boolean;
  title: string;
}

export default function IntegrationCheckboxSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  title,
  ...rest
}: IIntegrationCheckboxSideWindowProps) {
  const { t } = useTranslation();

  return (
    <SideWindow
      title={title || t('Integration')}
      visible={visible}
      onClose={onCancel}>
      <IntegrationCheckboxForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </SideWindow>
  );
}
