import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Form, { FormInstance } from 'antd/es/form';
import Switch from 'antd/es/switch';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { isFunction, eq, fromBooleanToNumber } from '@services/helpers';
import styled from 'styled-components';
import { StyledTitle } from '../Styled';

export default withTranslation()(FormSwitch);

export interface IFormSwitchProps
  extends WithTranslation,
    Pick<FormInstance, 'getFieldValue' | 'setFieldsValue'> {
  loading?: boolean;
  name?: string | string[];
  label?: string | React.ReactNode;
  tooltipTitle?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  extra?: React.ReactNode;
  value?: boolean;
  title?: string | React.ReactNode;
  span?: number;
  className?: string;
  formItemClassName?: string;
  contentContainerClassName?: string;
  extraOutside?: React.ReactNode;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
`;

const StyledExtraOutsideContainer = styled.div`
  margin-top: -25px;
`;

function FormSwitch({
  t,
  loading,
  getFieldValue,
  setFieldsValue,
  name = 'status',
  tooltipTitle = 'Is Active Services',
  label = 'Active',
  onChange,
  disabled = false,
  extra,
  value,
  title,
  span = 24,
  className,
  formItemClassName,
  contentContainerClassName,
  extraOutside,
}: IFormSwitchProps) {
  const [checked, setChecked] = useState<number>(getFieldValue(name));

  const onSwitchChange = useCallback(
    (e: any): void => {
      if (Array.isArray(name)) {
      } else {
        setFieldsValue({ [name]: e });
        if (isFunction(onChange)) {
          onChange(e);
        }
      }

      setChecked((prevState) => {
        return eq(prevState, 1) || prevState ? 0 : 1;
      });
    },
    [name, onChange, setFieldsValue],
  );

  useEffect(() => {
    if (typeof value === 'boolean') {
      setChecked(fromBooleanToNumber(value));
    }
  }, [value]);

  return (
    <Col span={span} className={className}>
      <StyledContainer className={contentContainerClassName}>
        <Form.Item
          className={formItemClassName}
          label={React.isValidElement(label) ? label : label ? t(label) : ''}
          name={name}
          extra={extra}
          tooltip={
            tooltipTitle
              ? {
                  title: tooltipTitle ? t(tooltipTitle) : '',
                  icon: <InfoCircleOutlined />,
                }
              : null
          }>
          <Switch
            disabled={loading || disabled}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            data-testid="service-input-price"
            loading={loading}
            checked={!!checked}
            onChange={onSwitchChange}
          />
        </Form.Item>
        {React.isValidElement(title) ? (
          title
        ) : title ? (
          <StyledTitle>{title}</StyledTitle>
        ) : null}
      </StyledContainer>
      {extraOutside ? (
        <StyledExtraOutsideContainer>
          {extraOutside}
        </StyledExtraOutsideContainer>
      ) : null}
    </Col>
  );
}
