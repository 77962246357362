import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import { CreatePaymentClientButton } from '../Buttons';
import {
  PaymentProps,
  useKeyboardOpenForm,
  usePaymentListPage,
  useSearchInputFocus,
  useStateClientPaymentList,
  useStopLoading,
  useStoredProfile,
} from '@hooks';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import {
  ifElse,
  isThereContent,
  textToUpperCase,
  toMoment,
} from '@services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAcl } from '@contex';
import {
  StyledButtonContainer,
  StyledListActionsContainer,
} from '../Show/ListStyledComponent';
import { StyledTitle } from '@components/lib/Styled';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { PaymentScheduleOperationType } from '@services/api/orders';
import {
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
} from '@structure';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import styled, { useTheme } from 'styled-components';
import { PaymentListBottomView, PaymentTypeView } from '../Show';
import { ClientHeader } from '../../Clients';
import { StyledDetailsView } from '@components/lib/Styled/DetailsView';
import ListDateRange from '@components/lib/DataDisplay/ListActions/ListDateRange';

export const SegmentedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  width: 100%;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<ITableProps<PaymentModel>>;

const StyledCreatePaymentClientButton = styled(CreatePaymentClientButton)`
  width: fit-content;
  height: 24px;
  padding-right: 10px;
  padding-left: 10px;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 5px;
`;

export function ClientPaymentListPage() {
  const { t } = useTranslation();
  const { manage } = useAcl(({ payment_group }) => payment_group);
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { profile } = useStoredProfile();
  const { defaultCompanyUuid } = useStoredCompanies();
  const location = useLocation();
  const theme: any = useTheme();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'client-payment-create-event',
    disabled: !manage,
  });

  const [type, setType] = useState<PaymentScheduleOperationType>(
    location?.state?.type || PaymentScheduleOperationType.ALL,
  );
  const [typeLoading, setTypeLoading] = useState(false);

  const {
    payments,
    loading: paymentsLoading,
    error: paymentsError,
    limit,
    total,
    date_start,
    date_end,
    keywords,
    stats,
    client,
    page,

    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments,
    handlePickPaymentRange,
    handleSearchPayments,
    handleRefreshPayments,
  } = useStateClientPaymentList({
    clientUuid: clientId!,
    payment_type: type,
  });

  const { rowSelection, columns } = usePaymentListPage({
    type,
    handleUpdatePayment,
    handleDeletePayments,
    stats,
    from: Routes.clients,
  });

  const loading = useStopLoading({
    loading: paymentsLoading || typeLoading,
    error: paymentsError,
    message: 'An error occurred during payments loading',
  });

  const { inputRef, daleyFocus } = useSearchInputFocus({ loading });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: ifElse(
        !!client?.fullNameClient,
        client?.fullNameClient,
        'Show Client',
      ),
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.payments}`,
      breadcrumbName: 'Cash flow',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.clients}/${clientId}`);
  }, [clientId, navigate]);

  const payment: (
    props?: PaymentProps,
  ) => PaymentFormDTO | PaymentScheduleFormDTO = (
    { payment_type, payment_comment }: PaymentProps = {} as PaymentProps,
  ) =>
    ({
      ...PaymentMapper.toPaymentFormDTO(
        {
          payment_comment: `${payment_comment} "${client?.fullNameClient}"`,
          payment_type,
          payment_agent_object: client,
        } as any,
        { cashier: profile?.defaultEmployeeModel },
      ),
    } as PaymentFormDTO);

  const onClientPaymentTypeChange = useCallback(
    async (type: any) => {
      if (defaultCompanyUuid) {
        setTypeLoading(true);
        setType(type);
        window.history.replaceState({}, document.title);
        await handleRefreshPayments({
          payment_type: type,
        });
        setTypeLoading(false);
      }
    },
    [defaultCompanyUuid, handleRefreshPayments],
  );

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.payments]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.payments]))}
      headerRight={
        <CreatePaymentClientButton
          payment={payment}
          companyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreatePayment}
          paymentOperationType={type}
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        payments?.size ? null : (
          <Empty description={t('There are no payments history from range.')}>
            {type === PaymentScheduleOperationType.In ||
            type === PaymentScheduleOperationType.Out ? (
              <CreatePaymentClientButton
                type="primary"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={handleCreatePayment}
                title={
                  type === PaymentScheduleOperationType.In
                    ? t('Create advance')
                    : t('Create schedulePayment')
                }
                paymentOperationType={type}
                onCancel={daleyFocus}
              />
            ) : (
              <CreatePaymentClientButton
                paymentOperationType={type}
                type="default"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={handleCreatePayment}
                title={
                  <StyledButtonContainer>
                    <StyledTitle>{t('Create payment')}</StyledTitle>
                    <DownOutlined />
                  </StyledButtonContainer>
                }
                onCancel={daleyFocus}
              />
            )}
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <RangeContainer>
            <ClientHeader withoutUpload client={client} />
            <ListDateRange
              defaultDateRangeValue={
                date_start && date_end
                  ? [toMoment(date_start), toMoment(date_end)]
                  : undefined
              }
              onChange={handlePickPaymentRange}
              loading={loading}
            />
          </RangeContainer>

          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                type === PaymentScheduleOperationType.ALL,
                type === PaymentScheduleOperationType.In,
                type === PaymentScheduleOperationType.Out,
              ]}>
              <PaymentTypeView
                color={theme.tabs.link}
                type={PaymentScheduleOperationType.ALL}
                title="All"
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.tabs.success}
                type={PaymentScheduleOperationType.In}
                title={t('Advance')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.tabs.error}
                type={PaymentScheduleOperationType.Out}
                title={t('tPayments')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>
          <ListActions
            withoutPicker={true}
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={handleSearchPayments}
            inputTooltip={t('Search payments')}
            inputLabel={t('Search payments')}
            withSearchContent={
              <>
                {type === PaymentScheduleOperationType.In ||
                type === PaymentScheduleOperationType.Out ? (
                  <CreatePaymentClientButton
                    type="primary"
                    payment={payment}
                    companyUuid={defaultCompanyUuid}
                    loading={loading}
                    onSuccess={handleCreatePayment}
                    title={
                      type === PaymentScheduleOperationType.In
                        ? t('Create advance')
                        : t('Create schedulePayment')
                    }
                    paymentOperationType={type}
                    size="middle"
                    icon={<PlusOutlined />}
                    onCancel={daleyFocus}
                    className="client-payment-create-event"
                  />
                ) : (
                  <StyledCreatePaymentClientButton
                    paymentOperationType={type}
                    type="default"
                    payment={payment}
                    companyUuid={defaultCompanyUuid}
                    loading={loading}
                    onSuccess={handleCreatePayment}
                    title={
                      <StyledButtonContainer>
                        <StyledTitle>{t('Create payment')}</StyledTitle>
                        <DownOutlined />
                      </StyledButtonContainer>
                    }
                    size="middle"
                    icon={<PlusOutlined />}
                    onCancel={daleyFocus}
                    className="client-payment-create-event"
                  />
                )}
              </>
            }
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        isThereContent(stats) ? <PaymentListBottomView stats={stats} /> : null
      }
      loading={loading && !payments?.size}>
      <>
        <StyledTable
          rowSelection={rowSelection}
          total={total}
          pageSize={limit}
          dataSource={payments}
          onChange={handleRefreshPayments}
          columns={columns}
          loading={loading}
          page={page}
        />
      </>
    </ListLayout>
  );
}
