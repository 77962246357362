import {
  EmployeeDTO,
  IEmployeeDTOProps,
  IServiceDTOProps,
  ServiceDTO,
  EmployeeMapper,
  ServiceMapper,
  IServiceGroupSessionModelReturnType,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export interface IServiceGroupSessionDTOProps {
  uuid: string;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  weekdays: string;
  max_attenders: number;
  status: number;
  created_at: string;
  updated_at: string;
  service: IServiceDTOProps;
  duration: number;
  manager: IEmployeeDTOProps;
  price: string;
}

export class ServiceGroupSessionDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public start_date: string;

  public end_date: string;

  public weekdays: string;

  public max_attenders: number;

  public status: number;

  public created_at: string;

  public updated_at: string;

  public service: ServiceDTO;

  public duration: number;

  public manager: EmployeeDTO;

  public price: string;

  constructor(
    props: IServiceGroupSessionModelReturnType | IServiceGroupSessionDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.start_date = props?.start_date || '';
    this.end_date = props?.end_date || '';
    this.weekdays = props?.weekdays || '';
    this.max_attenders = props?.max_attenders || 0;
    this.status = props?.status || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.duration = props?.duration || 0;
    this.price = correctPrice(props?.price);

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.groupSession,
            ...withoutRecursion,
          ]);

    this.service =
      withoutRecursion.indexOf(WithoutRecursion.service) !== -1
        ? (props?.service as ServiceDTO)
        : ServiceMapper.toServiceDTO(props?.service, [
            WithoutRecursion.groupSession,
            ...withoutRecursion,
          ]);
  }
}
