('use strict');

import { ProductModel } from '@structure';
import { ConcurrentQueue } from '@services/helpers/ConcurrentQueue';

export class SaleGUIConcurrentQueue extends ConcurrentQueue {
  public processProductUuid: string;
  constructor(concurrency: number) {
    super(concurrency);
    this.processProductUuid = '';
  }

  static channels(concurrency: number): SaleGUIConcurrentQueue {
    return new SaleGUIConcurrentQueue(concurrency);
  }

  next(): void {
    const task: any = this.waiting[0];
    this.processProductUuid =
      task instanceof ProductModel ? task?.uuid : task?.product?.uuid;

    super.next();
  }

  finish(err: Error | null, res?: any): void {
    super.finish(err, res);
    this.processProductUuid = '';
  }

  public removeWaitingItem(productUuid: string) {
    this.waiting = this.waiting.filter(({ uuid }) => productUuid !== uuid);
  }
}
