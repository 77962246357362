import { Record, List } from 'immutable';
import { IListProps } from './List';
import { SystemEventVariableDTO, SystemEventVariableModel } from '../internal';

export interface ISystemEventVariableListModelProps extends IListProps {
  event_variables: SystemEventVariableDTO[];
}

export interface ISystemEventVariableListModelReturnType extends IListProps {
  event_variables: List<SystemEventVariableModel>;
}

export class SystemEventVariableListModel extends Record<ISystemEventVariableListModelReturnType>(
  {
    event_variables: List(),
    total: 0,
  },
) {
  constructor(
    {
      event_variables,
      ...props
    }: ISystemEventVariableListModelProps = {} as ISystemEventVariableListModelProps,
  ) {
    super({
      ...props,
      event_variables: List(
        event_variables
          .sort((a, b) => {
            if (a?.variable_name < b?.variable_name) {
              return -1;
            }
            if (a?.variable_name > b?.variable_name) {
              return 1;
            }
            return 0;
          })
          .map(
            (event_variable) => new SystemEventVariableModel(event_variable),
          ),
      ),
    });
  }
}
