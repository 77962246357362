import { Dispatch } from 'redux';
import { List } from 'immutable';
import { STATUS_CATEGORY_LIST } from '../constants';
import { StatusCategoryModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetStatusCategoryListParam {
  type: STATUS_CATEGORY_LIST;
  categoryList: List<StatusCategoryModel>;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddStatusCategoryToListParam {
  type: STATUS_CATEGORY_LIST;
  category: StatusCategoryModel;
}

export interface IUpdateStatusCategoryFromListParam {
  type: STATUS_CATEGORY_LIST;
  category: StatusCategoryModel;
}

export interface IDeleteStatusCategoryFromListParam {
  type: STATUS_CATEGORY_LIST;
  categoryUuid: string;
}

export interface IErrorStatusCategoryListParam {
  type: STATUS_CATEGORY_LIST;
  error: ApiError | undefined;
}

export function setStatusCategoryList(
  categoryList: List<StatusCategoryModel>,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetStatusCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.SET_STATUS_CATEGORY_LIST,
      categoryList,
      keywords,
      total,
      page,
    });
  };
}

export function loadMoreStatusCategoryList(
  categoryList: List<StatusCategoryModel>,
): (dispatch: Dispatch) => Promise<ISetStatusCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.LOAD_MORE_STATUS_CATEGORY_LIST,
      categoryList,
    });
  };
}

export function addStatusCategoryToList(
  category: StatusCategoryModel,
): (dispatch: Dispatch) => Promise<IAddStatusCategoryToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.ADD_STATUS_CATEGORY,
      category,
    });
  };
}

export function updateStatusCategoryFromList(
  category: StatusCategoryModel,
): (dispatch: Dispatch) => Promise<IUpdateStatusCategoryFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.UPDATE_STATUS_CATEGORY,
      category,
    });
  };
}

export function deleteStatusCategoryFromList(
  categoryUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteStatusCategoryFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.DELETE_STATUS_CATEGORY,
      categoryUuid,
    });
  };
}

export function errorStatusCategoryInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStatusCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_CATEGORY_LIST.ERROR_STATUS_CATEGORY_LIST,
      error,
    });
  };
}
