import React from 'react';
import { ProductCategoryModel } from '@structure';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { Routes } from '@services/types';
import { Link } from '@components/lib/DataDisplay';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { textToUpperCase } from '@services/helpers';
import { useAcl } from '@contex';
import styled from 'styled-components';

export interface IProductCategoryDetailsViewProps {
  category: ProductCategoryModel | null;
  icon?: React.ReactNode;
}

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ProductCategoryDetailsView({
  category,
  icon,
}: IProductCategoryDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { manage } = useAcl(({ product }) => product);

  return (
    <>
      {category ? (
        <>
          {manage ? (
            <Link
              to={`/${Routes.app}/${Routes.goods}/${Routes.categories}/${category?.uuid}`}
              tooltip={t('Go to category details page')}
              state={{ goBack: location.pathname }}>
              <StyledTitleContainer>
                {icon || null}
                {category?.title}
              </StyledTitleContainer>
            </Link>
          ) : (
            <StyledTitle>{category?.title}</StyledTitle>
          )}
          {category?.parent?.uuid ? (
            <StyledDescription>{`${t('Parent category')}: ${
              category?.parent?.title
            }`}</StyledDescription>
          ) : null}
          {category?.description ? (
            <StyledDescription>{category?.description}</StyledDescription>
          ) : null}
        </>
      ) : null}
    </>
  );
}
