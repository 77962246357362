import React, { useCallback, useEffect, useRef, useState } from 'react';
import { listToArray } from '@services/helpers';
import {
  PriceMarginModel,
  PriceTagDTO,
  PriceTagMapper,
  PriceTagModel,
  ProductMapper,
  ProductModel,
  ProductStatus,
} from '@structure';
import {
  IUseDefaultPriceMarginReturnType,
  IUseSearchInputFocusReturnType,
  IUseStatePriceMarginListReturnType,
  IUseStatePriceTagListReturnType,
  IUseStateProductListReturnType,
  UseStateProductListReturnType,
} from '@hooks';
import { useTranslation } from 'react-i18next';
import { useDefaultForm, useDropdownAlert } from '@contex';
import { PriceTagTableProductListField } from './PriceTagTableProductListField';
import { List } from 'immutable';
import styled, { css } from 'styled-components';
import { PriceTagBottomView } from '../Show';
import { Col, Form, Row } from 'antd';
import { PriceTagSelectProductListField } from './PriceTagSelectProductListField';
import { SearchSelect } from '@components/lib/DataDisplay';

import '../../../Stores/Forms/StoreProductListField';
import { SearchProductStatus, SearchProductStatusType } from '@services/types';
import { ENTER_KEYS } from '@services/const';

export interface IPriceTagFieldsProps
  extends Pick<
      IUseStateProductListReturnType,
      'productList' | 'handleSearchProducts'
    >,
    Pick<
      IUseStatePriceMarginListReturnType,
      'priceMarginList' | 'handleSearchPriceMargins'
    >,
    Pick<
      IUseDefaultPriceMarginReturnType,
      'setDefaultPriceMargin' | 'getProductPriceMargin'
    >,
    IUseSearchInputFocusReturnType,
    Pick<
      IUseStatePriceTagListReturnType,
      | 'handleCreateOrUpdatePriceTag'
      | 'handleDeletePriceTags'
      | 'handleClearPriceTagList'
      | 'handlePrintPriceTagList'
    >,
    Pick<
      UseStateProductListReturnType,
      'isLoadingMore' | 'handleLoadingMoreProductList'
    > {
  loading: boolean;
  productListLoading: boolean;
  priceMarginListLoading: boolean;
  productListLoadingMore: boolean;
  productListKeywords: string;
  defaultPriceMargin: PriceMarginModel | null;
  visible: boolean;
  contentWidth: number;
}

const StyledOutsideContainer = styled.div<{ $width: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

const StyledRow = styled(Row)<{ $visible: boolean }>`
  background-color: ${({ theme }) => theme.background.layout};

  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 0;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $visible }) =>
    $visible &&
    css`
      margin-top: -20px;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 7px;
      height: 100px;
      transition: all 0.5s;
    `}
`;

export function PriceTagFields({
  loading,
  contentWidth,

  productList,
  productListLoading,
  productListLoadingMore,
  productListKeywords,
  handleSearchProducts,

  priceMarginList,
  priceMarginListLoading,
  handleSearchPriceMargins,
  setDefaultPriceMargin,

  defaultPriceMargin,
  getProductPriceMargin,

  inputRef,
  focus,
  daleyFocus,
  visible,

  handleCreateOrUpdatePriceTag,
  handleDeletePriceTags,
  handleClearPriceTagList,

  isLoadingMore,
  handleLoadingMoreProductList,
}: IPriceTagFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { formData, handlerUpdateFormState, loadingSubmit, submit }: any =
    useDefaultForm();
  const { alert } = useDropdownAlert();

  const [selectedProductItem, setSelectedProductItem] =
    useState<PriceTagModel | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [storeProductList, setStoreProductList] = useState<ProductModel[]>([]);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [selectedProductUuid, setSelectedProductUuid] = useState('');

  const inputStatusRef = useRef<SearchProductStatusType>({
    status: SearchProductStatus.END_REQUEST,
    isEnterPress: false,
  });

  const handleChangeInputStatus = useCallback(
    (status: SearchProductStatus, isEnterPress: boolean = false) => {
      if (
        inputStatusRef.current.status === SearchProductStatus.PRESS_ENTER &&
        status === SearchProductStatus.START_INPUT
      ) {
        return;
      }

      if (
        inputStatusRef.current.status === SearchProductStatus.END_REQUEST &&
        status === SearchProductStatus.PRESS_ENTER
      ) {
        return;
      }

      inputStatusRef.current.isEnterPress =
        status === SearchProductStatus.PRESS_ENTER
          ? isEnterPress
          : inputStatusRef.current.isEnterPress;
      inputStatusRef.current.status = status;
    },
    [],
  );

  const toggleFilters = useCallback(() => {
    setVisibleFilters((prevState) => !prevState);
    focus();
  }, [focus]);

  const handleAddOrUpdateDocItems = useCallback(
    async (item: ProductModel | PriceTagModel | null = selectedProductItem) => {
      let priceItem: any;

      if (item instanceof ProductModel) {
        priceItem = PriceTagMapper.toPriceTagModel({
          product: ProductMapper.toProductDTO(item),
        } as PriceTagDTO);
        setSelectedProductItem(priceItem);
      } else {
        priceItem = item;
      }

      if (priceItem?.product?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      let items = formData?.items || [];

      const index = items?.findIndex(
        ({ product }: any) => product?.uuid === priceItem?.product?.uuid,
      );

      setSelectedProductUuid(priceItem?.product?.uuid);

      try {
        if (!~index) {
          const item = await handleCreateOrUpdatePriceTag(
            priceItem?.product?.uuid,
            priceItem?.uuid,
          );

          if (item) {
            items = [...items, item];
          }
          handlerUpdateFormState({ items, total: (formData?.total || 0) + 1 });
        } else {
          const item = formData?.items?.find(
            ({ product }: any) => product?.uuid === priceItem?.product?.uuid,
          );

          if (item) {
            await handleDeletePriceTags(item?.uuid, false);

            items = items.filter(
              ({ product }: any) => product?.uuid !== priceItem?.product?.uuid,
            );
          }

          handlerUpdateFormState({ items, total: (formData?.total || 0) - 1 });
        }
      } catch (error: any) {
      } finally {
        setSelectedProductUuid('');
      }
    },
    [
      alert,
      formData?.items,
      formData?.total,
      handleCreateOrUpdatePriceTag,
      handleDeletePriceTags,
      handlerUpdateFormState,
      selectedProductItem,
      t,
    ],
  );

  const handleEnterSelectItem = useCallback(async () => {
    await handleAddOrUpdateDocItems();
  }, [handleAddOrUpdateDocItems]);

  const handleSelectProductItem = useCallback((product: any) => {
    const documentItemFormDTO = PriceTagMapper.toPriceTagModel({
      product: ProductMapper.toProductDTO(product),
    } as PriceTagDTO);

    setSelectedProductItem(documentItemFormDTO);
  }, []);

  const onChangePriceMargin = useCallback(
    (price_margin_uuid: string) => {
      handlerUpdateFormState({ price_margin_uuid });
      daleyFocus(100);

      const defaultPriceMargin = priceMarginList?.find(
        ({ uuid }) => uuid === price_margin_uuid,
      );

      if (defaultPriceMargin) {
        setDefaultPriceMargin(defaultPriceMargin);
      }
    },
    [
      daleyFocus,
      handlerUpdateFormState,
      priceMarginList,
      setDefaultPriceMargin,
    ],
  );

  useEffect(() => {
    if (List.isList(productList) && productListKeywords?.length >= 3) {
      const product = productList?.first();

      if (product && !selectedProductItem) {
        handleSelectProductItem(product);
      }

      setStoreProductList(listToArray(productList));
    }
  }, [
    handleSelectProductItem,
    productList,
    productListKeywords?.length,
    selectedProductItem,
  ]);

  const onTableKeyboardChange = useCallback(
    async (e: KeyboardEvent) => {
      const length = storeProductList?.length - 1;
      const dropdown = document.body.querySelector(
        '.store-sell-product-select-popup',
      );

      if (dropdown instanceof HTMLElement) {
        dropdown.style.opacity = '1';
      }

      if (
        productListKeywords?.length >= 3 &&
        storeProductList?.length &&
        !productListLoading
      ) {
        if (e.key === 'ArrowUp' && selectedProductIndex === 0) {
          const product = storeProductList[length];
          if (product) {
            handleSelectProductItem(product);
            setSelectedProductIndex(length);
          }

          const selected = document.body.querySelector(
            `.posting-list-td-${length}`,
          );

          if (selected instanceof HTMLElement) {
            selected.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }

          return;
        }

        if (e.key === 'ArrowUp' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex - 1];

          if (product) {
            handleSelectProductItem(product);
            setSelectedProductIndex((prevState) => --prevState);
          }

          const selected = document.body.querySelector(
            `.posting-list-td-${selectedProductIndex - 1}`,
          );

          if (selected instanceof HTMLElement) {
            selected.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex === length) {
          const product = storeProductList[0];
          if (product) {
            handleSelectProductItem(product);
            setSelectedProductIndex(0);
          }

          const selected = document.body.querySelector(`.posting-list-td-${0}`);

          if (selected instanceof HTMLElement) {
            selected.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex + 1];
          if (product) {
            handleSelectProductItem(product);
            setSelectedProductIndex((prevState) => ++prevState);
          }

          const selected = document.body.querySelector(
            `.posting-list-td-${selectedProductIndex + 1}`,
          );

          if (selected instanceof HTMLElement) {
            selected.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }

          return;
        }

        if (ENTER_KEYS.includes(e.key)) {
          if (!selectedProductUuid) {
            await handleEnterSelectItem();
          }
        }
      } else {
        if (
          ENTER_KEYS.includes(e.key) &&
          e?.target instanceof HTMLElement &&
          e?.target?.closest('.store-sell-product-select') &&
          formData?.items?.length &&
          inputStatusRef?.current?.status === SearchProductStatus.END_REQUEST
        ) {
          const dropdown = document.body.querySelector(
            '.store-sell-product-select-popup',
          );

          if (dropdown instanceof HTMLElement) {
            dropdown.style.opacity = '0';
          }

          handlerUpdateFormState({
            is_final_save: false,
          });

          submit();
        }
      }
    },
    [
      formData?.items?.length,
      handleEnterSelectItem,
      handleSelectProductItem,
      handlerUpdateFormState,
      productListKeywords?.length,
      productListLoading,
      selectedProductIndex,
      selectedProductUuid,
      storeProductList,
      submit,
    ],
  );

  useEffect(() => {
    document.addEventListener('keydown', onTableKeyboardChange);

    if (visible) {
      document.removeEventListener('keydown', onTableKeyboardChange);
    }

    return () => {
      document.removeEventListener('keydown', onTableKeyboardChange);
    };
  }, [
    onTableKeyboardChange,
    productListKeywords.length,
    productListLoading,
    storeProductList.length,
    visible,
  ]);

  useEffect(() => {
    if (productListKeywords?.length < 3) {
      setSelectedProductIndex((index) => (index === 0 ? index : 0));
      setSelectedProductItem(null);
    }
  }, [productListKeywords?.length]);

  return (
    <>
      <PriceTagSelectProductListField
        withoutStoreUuid
        disabled={false}
        selectRef={inputRef}
        storeProductList={storeProductList}
        loading={loading}
        selectedProductItem={selectedProductItem}
        selectedProductIndex={selectedProductIndex}
        onProductSelect={handleAddOrUpdateDocItems}
        productListLoading={productListLoading}
        productListLoadingMore={productListLoadingMore}
        handleSearchProducts={handleSearchProducts}
        productListKeywords={productListKeywords}
        setStoreProductList={setStoreProductList}
        defaultPriceMargin={defaultPriceMargin}
        getProductPriceMargin={getProductPriceMargin}
        toggleFilters={toggleFilters}
        visibleFilters={visibleFilters}
        handleClearPriceTagList={handleClearPriceTagList}
        setSelectedProductIndex={setSelectedProductIndex}
        handleChangeInputStatus={handleChangeInputStatus}
        inputStatusRef={inputStatusRef}
        selectedProductUuid={selectedProductUuid}
        isLoadingMore={isLoadingMore}
        handleLoadingMoreProductList={handleLoadingMoreProductList}
      />

      <StyledRow gutter={20} $visible={visibleFilters}>
        <Col span={24}>
          <Form.Item
            label={t('Price margin')}
            name="price_margin_uuid"
            tooltip={t('Price margin')}>
            <SearchSelect
              selectFirst={priceMarginList?.size === 1}
              name="price_margin_uuid"
              disable={loading || priceMarginListLoading || loadingSubmit}
              placeholder={t('Select a price margin')}
              data={listToArray(priceMarginList as any)}
              getOptionValueTitle="price_name"
              onSearch={(keywords) => handleSearchPriceMargins({ keywords })}
              resetState={productListLoading}
              onChange={onChangePriceMargin}
            />
          </Form.Item>
        </Col>
      </StyledRow>
      <PriceTagTableProductListField
        focus={focus}
        handleDeletePriceTags={handleDeletePriceTags}
        loading={loading}
      />
      <StyledOutsideContainer $width={contentWidth}>
        <PriceTagBottomView />
      </StyledOutsideContainer>
    </>
  );
}
