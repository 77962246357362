import * as React from 'react';
import { ProductCategoryModel } from '@structure';
import { List } from 'immutable';
import { TreeSelectProps } from 'antd';
import { useState } from 'react';
import { listToArray } from '@sportix/sportix-common-modules';

export interface UseProductCategoryListToSelectTreeProps {
  categories: List<ProductCategoryModel> | null;
}

export interface UseProductCategoryListToSelectTreeReturnType {
  treeData: TreeSelectProps['treeData'];
}

export function useProductCategoryListToSelectTree({
  categories,
}: UseProductCategoryListToSelectTreeProps): UseProductCategoryListToSelectTreeReturnType {
  const [tree, setTree] = useState<TreeSelectProps['treeData']>([]);

  React.useEffect(() => {
    if (List.isList(categories)) {
      const toTreeSelect: any = (categories: ProductCategoryModel[]) =>
        categories.map((category) => {
          return {
            label: category?.title,
            value: category?.uuid,
            ...(Array.isArray(category?.children)
              ? { children: toTreeSelect(category?.children as any) }
              : []),
          };
        });

      setTree(toTreeSelect(listToArray(categories)));
    }
  }, [categories]);

  return {
    treeData: tree,
  };
}
