import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { head } from '../helpers';
import { ApiAnswer, IListSearchProps, IEntityProps } from '../types';
import {
  MaterialAndExpenseMapper,
  MaterialAndExpenseDTO,
  MaterialAndExpenseFormDTO,
  IMaterialAndExpenseDTOProps,
  IMaterialAndExpenseListDTO,
} from '@structure';
import { contentApiUrl } from '../const';

export const MATERIAL_AND_EXPENSE_INITIAL_DATA =
  new MaterialAndExpenseFormDTO();

export interface IMaterialAndExpenseListAnswer extends ApiAnswer {
  schedule_expenses: IMaterialAndExpenseDTOProps[];
  total_sum: string;
}

export interface IMaterialAndExpenseListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IMaterialAndExpenseAnswer {
  schedule_expense: IMaterialAndExpenseDTOProps;
}

export async function getMaterialAndExpenseList({
  scheduleUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IMaterialAndExpenseListProps): Promise<IMaterialAndExpenseListDTO> {
  const { schedule_expenses, total, total_sum } = await apiGet<
    Partial<IListSearchProps>,
    IMaterialAndExpenseListAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/expenses`, {
    limit,
    offset,
  });

  return MaterialAndExpenseMapper.toMaterialAndExpenseListDTO(
    schedule_expenses,
    total,
    total_sum,
  );
}

// export async function getMaterialAndExpenseById(
//   expanseUuid: string,
// ): Promise<MaterialAndExpenseDTO> {
//   const {expanse} = await apiGet<null, IMaterialAndExpenseAnswer>(
//     `${contentApiUrl}/expenses/${expanseUuid}`,
//   );
//
//   return MaterialAndExpenseMapper.toMaterialAndExpenseDTO(expanse);
// }

export async function createMaterialAndExpense(
  { price, name, amount, comment }: MaterialAndExpenseFormDTO,
  scheduleUuid: string,
): Promise<MaterialAndExpenseDTO> {
  const { schedule_expense } = await apiPost<
    Omit<MaterialAndExpenseFormDTO, 'uuid' | 'total'>,
    IMaterialAndExpenseAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/expenses`, {
    price,
    name,
    amount,
    comment,
  });

  return MaterialAndExpenseMapper.toMaterialAndExpenseDTO(schedule_expense);
}

export async function updateMaterialAndExpense({
  price,
  name,
  amount,
  uuid,
  comment,
}: MaterialAndExpenseFormDTO): Promise<MaterialAndExpenseDTO> {
  const { schedule_expense } = await apiPut<
    Omit<MaterialAndExpenseFormDTO, 'uuid' | 'total'>,
    IMaterialAndExpenseAnswer
  >(`${contentApiUrl}/schedule-expenses/${uuid}`, {
    price,
    name,
    amount,
    comment,
  });

  return MaterialAndExpenseMapper.toMaterialAndExpenseDTO(schedule_expense);
}

export async function deleteMaterialsAndExpenses(ids: string[]) {
  const expenseUuid = head(ids);

  return await apiDelete<null, any>(
    `${contentApiUrl}/schedule-expenses/${expenseUuid}`,
  );
}
