import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import { ILiteralObj, EventLayout } from '@services/types';
import {
  isThereContent,
  EventType,
  MutationScheduleEvent,
  buildDisplayLengthOfTime,
  calculateColSpan,
  isObject,
  getRestEventLength,
  eq,
  getArrayLength,
  IDayTimeRange,
  neq,
} from '@services/helpers';
import { useCalendar, useCalendarDnd } from '@contex';
import CalendarEventDisplay from './CalendarEventDisplay';
import CalendarTodayTileLine from './CalendarTodayTileLine';
import { useCalendarEvent } from '@hooks';
import CalendarEventView from './CalendarEventView';

import './CalendarEvent.less';

export default withTranslation()(CalendarEvent);

export interface ICalendarEventProps<T> extends WithTranslation {
  direction?: EventLayout;
  events: EventType | MutationScheduleEvent[];
  time?: string;
  numberOfVisibleEvents?: number;
  visibleShowMore?: boolean;
  onShowMoreClick?: () => void;
  isToday: boolean;
  showTimeLine?: boolean;
  weekdayInfo?: IDayTimeRange;
  lastTime?: string;
  isPastDate?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

function CalendarEvent<T extends ILiteralObj>({
  direction = 'horizontal',
  events: scheduleEvents,
  time,
  numberOfVisibleEvents = 100,
  visibleShowMore = false,
  onShowMoreClick,
  t,
  isToday,
  showTimeLine,
  weekdayInfo,
  lastTime,
  isPastDate,
  loading,
  disabled,
}: ICalendarEventProps<T>): JSX.Element {
  const { scheduleStartProp, mode, SchedulePopover } = useCalendar();
  const { dndEvents, drag, isDragging } = useCalendarDnd();

  const { handleAddEvent } = useCalendarEvent({
    scheduleEvents: scheduleEvents as EventType,
    time,
    weekdayInfo,
    lastTime: lastTime!,
    isToday,
  });

  const [events, setEvents] = useState<any>(null);

  useEffect(() => {
    if (dndEvents && eq(mode, 'day') ? !loading : false) {
      // (eq(mode, 'day') || dndEvents) && eq(mode, 'week') ? false : !loading
      setEvents(dndEvents);
    } else {
      setEvents(scheduleEvents);
    }
  }, [dndEvents, loading, mode, scheduleEvents]);

  const containerRef = useRef(null);

  const isVisibleContent = useCallback(
    (
      func: (value: MutationScheduleEvent, index: number) => React.ReactNode,
    ): React.ReactNode | null =>
      time &&
      isObject(events) &&
      isThereContent(events) &&
      isThereContent((events as EventType)[time])
        ? (events as EventType)[time].map(func)
        : null,
    [events, time],
  );

  const isShowTimeLine =
    showTimeLine && isToday && eq(`${moment().format('HH')}:00`, time);

  const eventWithPopoverView = (
    event: MutationScheduleEvent & any,
  ): JSX.Element => {
    return (
      <CalendarEventDisplay
        isOpenPopover={neq(mode, 'day')}
        content={React.createElement(SchedulePopover, { event } as any)}
        direction={direction}
        event={event}
        lengthOfTime={buildDisplayLengthOfTime(
          event[scheduleStartProp as string],
          event?.schedule_amount,
        )}
        onSuccess={event?.onSuccess}
        order={event?.order}
        isDragging={event?.isDragging}
        mode={mode}
        disabled={disabled}
      />
    );
  };

  return (
    <div ref={containerRef}>
      {isShowTimeLine && <CalendarTodayTileLine />}
      {direction === 'horizontal' ? (
        <Row
          className="calendar-event"
          justify="space-around"
          onClick={() => {
            handleAddEvent();
          }}>
          {isVisibleContent(
            (value: MutationScheduleEvent, index: number): React.ReactNode => (
              <React.Fragment
                key={
                  value?.schedule_amount +
                  value?.schedule_priority +
                  String(index) +
                  value?.services?.uuid
                }>
                <CalendarEventView
                  className={value?.isDragging ? 'dragged-event' : ''}
                  isPastDate={isPastDate}
                  event={value}
                  role={value?.isDragging ? 'DraggableBox' : undefined}
                  ref={value?.isDragging ? drag : undefined}
                  span={calculateColSpan(23, (events as EventType)[time!])}
                  eventStartDate={value[scheduleStartProp as string]}
                  style={{
                    opacity: value?.isDragging && isDragging ? 0 : 1,
                  }}>
                  {eventWithPopoverView(value)}
                </CalendarEventView>
              </React.Fragment>
            ),
          )}
        </Row>
      ) : (
        <>
          {((scheduleEvents as MutationScheduleEvent[]) || []).map(
            (value: MutationScheduleEvent, index) => (
              <React.Fragment
                key={
                  value?.schedule_amount +
                  value?.schedule_priority +
                  String(index)
                }>
                {index <= numberOfVisibleEvents ? (
                  <Row justify="center">
                    <Col className="calendar-event-horizontal--col" span={23}>
                      {eventWithPopoverView(value)}
                    </Col>
                  </Row>
                ) : null}
              </React.Fragment>
            ),
          )}
          {getArrayLength(events) > 0 && visibleShowMore ? (
            <Button
              className="calendar-event-horizontal-button"
              type="text"
              size="small"
              onClick={onShowMoreClick}>
              <span className="calendar-event-horizontal-button--bold">
                {`${t('More')} ${getRestEventLength(
                  events,
                  numberOfVisibleEvents,
                )}`}
              </span>
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
}
