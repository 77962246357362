import React from 'react';
import { Form, Row, Col } from 'antd';
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { Input } from '@components/lib/DataEntry';
import {
  StyledLockOutlined,
  StyledUserLoginOutlined,
} from '@components/lib/Styled';
import { LoginFormDTO } from '@structure';
import { ILiteralObj } from '@services/types';
import { AuthButton } from '../../Buttons';

export default withTranslation()(LoginForm);

export interface ILoginFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<LoginFormDTO, LoginFormDTO>,
      'children' | 'initialValues'
    > {
  onCancel?: () => void;
  onSuccess: (value: LoginFormDTO) => Promise<void | boolean>;
  onError?: () => Promise<void>;
  login: LoginFormDTO;
  additionalValuesRequest?: ILiteralObj;
}

function LoginForm({ login, ...rest }: ILoginFormProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <DefaultForm
      formKeyboardEndSubmit
      initialValues={{ ...login }}
      showFooter={false}
      {...rest}>
      {({ loadingSubmit, submit }) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Email or phone')}
              name="identifier"
              tooltip={t(
                'Email or phone that was specified during the registration process.',
              )}
              rules={[
                {
                  required: true,
                  message: t('Login must be specified'),
                },
              ]}>
              <Input
                data-testid="login-form__input--email"
                disabled={loadingSubmit}
                prefix={<StyledUserLoginOutlined />}
                placeholder={t('Enter your email or phone')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Password')}
              name="password"
              tooltip={t(
                'Do not share your password with anybody. If you lost or forget password try to restore access by using "Forgot Password".',
              )}
              rules={[
                {
                  required: true,
                  message: t('Password must be specified.'),
                },
              ]}>
              <Input
                data-testid="login-form__input--password"
                disabled={loadingSubmit}
                prefix={<StyledLockOutlined />}
                type="password"
                placeholder={t('Enter your password')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <AuthButton
              size={'large'}
              disabled={loadingSubmit}
              data-testid="login-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('login')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
