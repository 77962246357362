import React from 'react';
import styled from 'styled-components';
import { IChild } from '@services/types';
import { StyledDescription, StyledTitle } from '../Styled';
import { toDateByFormat } from '@services/helpers';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(DocumentNumber);

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
`;

const StyledDraftWrapper = styled.span`
  color: #d98c3a;
  font-size: 20px;
  text-transform: uppercase;
`;

export interface DocumentNumberProps extends Partial<IChild>, WithTranslation {
  number: string;
  date?: string;
  color?: string;
  isDraft?: boolean;
}

function DocumentNumber({
  number,
  date,
  color,
  children,
  t,
  isDraft,
}: DocumentNumberProps): JSX.Element {
  return (
    <StyledContainer>
      <StyledTitle bold fontSize={35} $color={color}>
        № {number}
        {isDraft && (
          <StyledDraftWrapper>{` (${t('Draft')})`}</StyledDraftWrapper>
        )}
      </StyledTitle>
      {date ? (
        <StyledDescription fontSize={16}>
          {`${t('From').toLowerCase()} ${toDateByFormat(
            date,
            'DD.MM.YYYY HH:mm',
          )}`}
        </StyledDescription>
      ) : null}
      {children || null}
    </StyledContainer>
  );
}
