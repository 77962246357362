import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CompanyRequisiteDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { SuspenseEmpty } from '@components/lib/DataDisplay';

import {
  AddButton,
  IAddButtonProps,
} from '@components/lib/DataDisplay/AddButton';

const CompanyRequisiteModalLazy = React.lazy(
  () => import('../Show/CompanyRequisiteModal'),
);

const StyledAddButton = styled(AddButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 10px;
`;

export interface ICompanyRequisiteCreateButton extends IAddButtonProps {
  loading: boolean;
  onSuccess: (value: CompanyRequisiteDTO) => Promise<void>;
  onCancel?: () => void;
  title?: string;
}

export function CompanyRequisiteCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: ICompanyRequisiteCreateButton) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton
        tooltipTitle={t('Create requisite')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanyRequisiteModalLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
