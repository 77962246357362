import { Record } from 'immutable';
import { GalleryDTO } from '../internal';

export interface IGalleryModelReturnType {
  uuid: string;
  title: string;
  description: string;
  path_url: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export class GalleryModel extends Record<IGalleryModelReturnType>({
  uuid: '',
  title: '',
  description: '',
  path_url: '',
  status: 0,
  created_at: '',
  updated_at: '',
}) {
  constructor(props = {} as GalleryDTO) {
    const options: IGalleryModelReturnType = {
      ...props,
    };
    super(options);
  }
}
