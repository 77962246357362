import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

export default withTranslation()(AddScheduleToEmployeeButton);

export interface IAddScheduleToEmployeeButtonProps extends WithTranslation {}

function AddScheduleToEmployeeButton({
  t,
}: IAddScheduleToEmployeeButtonProps): JSX.Element {
  return (
    <Tooltip title={t('Create a work schedule for an employee')}>
      <Link to="/work-schedules">
        <Button type="primary" size="small">
          {t('Create Work Schedule')}
        </Button>
      </Link>
    </Tooltip>
  );
}
