import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from '../core/api';
import { head, dateToIsoString } from '../helpers';
import { ApiAnswer, IListSearchProps } from '../types';
import {
  CashBoxAdjustBalancesFormDTO,
  CashBoxDTO,
  CashBoxFormDTO,
  CashBoxMapper,
  ICashBoxDTOProps,
  ICashBoxListDTO,
  MaxPausedPeriodType,
  CashBoxMovingFormDTO,
} from '@structure';
import { contentApiUrl } from '../const';

export const CASH_BOX_INITIAL_PARAM = new CashBoxFormDTO();

const {
  uuid,
  company,
  updated_at,
  created_at,
  ...CASH_BOX_INITIAL_PARAM_REST
} = new CashBoxDTO({} as ICashBoxDTOProps);

export const CASH_BOX_REQUIRED_FIELD = {
  ...CASH_BOX_INITIAL_PARAM_REST,
};

export interface ICashBoxAnswer extends ApiAnswer {
  cashbox: ICashBoxDTOProps;
}

export interface ICashBoxListAnswer extends ApiAnswer {
  cashboxes: ICashBoxDTOProps[];
}

export interface ICompanyCashBoxListAnswer extends ApiAnswer, IListSearchProps {
  cashboxes: ICashBoxDTOProps[];
}

export interface ICompanyCashBoxListProps extends IListSearchProps {
  companyUuid: string;
}

export const MAX_PAUSED_PERIOD_TYPE = [
  {
    title: 'Day',
    uuid: MaxPausedPeriodType.Day,
  },
  {
    title: 'Week',
    uuid: MaxPausedPeriodType.Week,
  },
  {
    title: 'Month',
    uuid: MaxPausedPeriodType.Month,
  },
];

export async function getCashBoxList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: ICompanyCashBoxListProps): Promise<ICashBoxListDTO> {
  const { cashboxes, total } = await apiGet<
    IListSearchProps,
    ICashBoxListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/cashboxes`, {
    offset,
    limit,
    keywords,
  });

  return CashBoxMapper.toCashBoxListDTO(cashboxes, total || 0);
}

export async function getCashBoxById(cashBoxUuid: string): Promise<CashBoxDTO> {
  const { cashbox } = await apiGet<null, ICashBoxAnswer>(
    `${contentApiUrl}/cashboxes/${cashBoxUuid}`,
  );

  return CashBoxMapper.toCashBoxDTO(cashbox);
}

export async function createCashBox(
  {
    can_move_money_to,
    can_move_money_from,
    can_out_money_from,
    title,
    can_in_money_to,
    comment,
    type,
    cashier_uuid,
    notification_recipient_list,
    can_be_closed_automatically,
    cashbox_close_at,
    is_default_for_company,
    fiscal_cashbox_id,
    is_virtual,
    is_locational,
    location_uuid,
    is_email_notification_enabled,
  }: CashBoxFormDTO,
  companyUuid: string,
): Promise<CashBoxDTO> {
  const { cashbox } = await apiPost<
    Omit<CashBoxFormDTO, 'uuid'>,
    ICashBoxAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/cashboxes`, {
    can_move_money_to,
    can_move_money_from,
    can_out_money_from,
    title,
    can_in_money_to,
    comment,
    type,
    cashier_uuid,
    cashbox_close_at,
    notification_recipient_list,
    can_be_closed_automatically,
    is_default_for_company,
    is_email_notification_enabled,
    fiscal_cashbox_id: fiscal_cashbox_id || 'allowEmptyString',
    is_virtual,
    is_locational,
    location_uuid,
  });

  return CashBoxMapper.toCashBoxDTO(cashbox);
}

export async function editCashBox({
  uuid: cashBoxUuid,
  can_move_money_to,
  can_move_money_from,
  can_out_money_from,
  title,
  can_in_money_to,
  comment,
  type,
  cashier_uuid,
  cashbox_close_at,
  notification_recipient_list,
  can_be_closed_automatically,
  is_default_for_company,
  fiscal_cashbox_id,
  is_virtual,
  is_locational,
  location_uuid,
  is_email_notification_enabled,
}: Omit<CashBoxFormDTO, 'getTimingList'>): Promise<CashBoxDTO> {
  const { cashbox } = await apiPut<
    Omit<CashBoxFormDTO, 'uuid'>,
    ICashBoxAnswer
  >(`${contentApiUrl}/cashboxes/${cashBoxUuid}`, {
    can_move_money_to,
    cashbox_close_at,
    can_move_money_from,
    notification_recipient_list,
    can_be_closed_automatically,
    can_out_money_from,
    title,
    can_in_money_to,
    comment,
    type,
    cashier_uuid,
    is_default_for_company,
    is_email_notification_enabled,
    fiscal_cashbox_id: fiscal_cashbox_id || 'allowEmptyString',
    is_virtual,
    is_locational,
    location_uuid,
  });

  return CashBoxMapper.toCashBoxDTO(cashbox);
}

export async function deleteCashBox(
  ids: string[] = [],
): Promise<ICashBoxListDTO> {
  const { cashboxes } = await apiDelete<
    { ids: string[] },
    ICompanyCashBoxListAnswer
  >(`${contentApiUrl}/cashboxes/${head(ids)}`);

  return CashBoxMapper.toCashBoxListDTO(cashboxes, 0);
}

export async function adjustBalancesCashBox({
  uuid: cashBoxUuid,
  sum,
  date,
  cashier_uuid,
  comment,
}: CashBoxAdjustBalancesFormDTO): Promise<CashBoxDTO> {
  const { cashbox } = await apiPatch<
    Omit<CashBoxAdjustBalancesFormDTO, 'uuid'>,
    ICashBoxAnswer
  >(`${contentApiUrl}/cashboxes/${cashBoxUuid}/balance`, {
    sum,
    date: dateToIsoString(date),
    cashier_uuid,
    comment,
  });

  return CashBoxMapper.toCashBoxDTO(cashbox);
}

export async function movingCashBox({
  uuid: cashBoxUuid,
  sum,
  date,
  cashier_uuid,
  comment,
  cashbox_destination_uuid,
}: CashBoxMovingFormDTO): Promise<CashBoxDTO> {
  const { cashbox } = await apiPost<
    Omit<CashBoxMovingFormDTO, 'uuid'>,
    ICashBoxAnswer
  >(`${contentApiUrl}/cashboxes/${cashBoxUuid}/move`, {
    sum,
    date: dateToIsoString(date),
    cashier_uuid,
    comment,
    cashbox_destination_uuid,
  });

  return CashBoxMapper.toCashBoxDTO(cashbox);
}
