import * as React from 'react';
import {
  useAbonementGroup,
  IUseAbonementGroupProps,
  IUseAbonementGroupReturnType,
} from './useAbonementGroup';
import {
  AbonementGroupFormDTO,
  AbonementGroupModel,
  AbonementGroupMapper,
  AbonementGroupStatuses,
  EmployeeModel,
} from '@structure';
import {
  createAbonementGroup,
  editAbonementGroup,
  changeAbonementGroupStatus,
} from '@services/api/abonementGroup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useEffect, useState } from 'react';
import {
  addAbonementGroupToList as storeAddAbonementGroup,
  setAbonementGroup as storeSetAbonementGroup,
  updateAbonementGroup as storeUpdateAbonementGroup,
  updateAbonementGroupFromList as storeUpdateAbonementGroupFromList,
  resetAbonementGroup as storeResetAbonementGroup,
} from '@store/actions';
import { toStringDate } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { isThereContent } from '@sportix/sportix-common-modules';

export interface IUseStateAbonementGroupProps extends IUseAbonementGroupProps {
  groupUuid: string;
  abonementUuid: string;
}

export interface IUseStateAbonementGroupReturnType
  extends Omit<IUseAbonementGroupReturnType, 'entity'> {
  group: AbonementGroupModel | null;
  handleUpdateAbonementGroup: (value: AbonementGroupFormDTO) => Promise<void>;
  handleCreateAbonementGroup: (value: AbonementGroupFormDTO) => Promise<void>;
  handleChangeAbonementGroupStatus: (
    abonementUuid: string,
    status: AbonementGroupStatuses,
  ) => Promise<AbonementGroupModel | void>;
  handleRefreshAbonementGroup: () => Promise<void>;
  handleResetAbonementGroup: () => void;
  status: REDUX_STATUS;
  isHasScheduleList: boolean;
}

export function useStoredAbonementGroup({
  loadOnInit = true,
  abonementUuid,
  groupUuid,
  ...rest
}: IUseStateAbonementGroupProps): IUseStateAbonementGroupReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [group, setAbonementGroup] = React.useState<AbonementGroupModel | null>(
    null,
  );
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [isHasScheduleList, setIsHasScheduleList] = useState(false);

  const {
    status: storedAbonementGroupStatus,
    cachedAbonementGroupList,
    ...storedAbonementGroupParams
  } = useSelector(({ abonementGroup }: RootState) => abonementGroup);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);
  const onceSchedules = React.useRef(false);

  const { entity, refresh, ...groupParams } = useAbonementGroup({
    loadOnInit: loadOnInit || isStarLoading,
    groupUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedAbonementGroupList?.size > 0) {
      const abon = cachedAbonementGroupList.get(groupUuid);

      if (abon) {
        setIsStartLoading(false);
        setAbonementGroup(abon);

        if (!onceSchedules.current) {
          onceSchedules.current = true;
          setIsHasScheduleList(isThereContent(abon?.group_schedule_list));
        }
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedAbonementGroupList?.size === 0 && !group) {
      setIsStartLoading(true);
    }
  }, [cachedAbonementGroupList, dispatch, groupUuid, group]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetAbonementGroup(entity));
      setAbonementGroup(entity);
    }
  }, [dispatch, entity, isStarLoading, storedAbonementGroupStatus]);

  const handleUpdateAbonementGroup = React.useCallback(
    async (value: AbonementGroupFormDTO): Promise<void> => {
      const groupDTO = await editAbonementGroup({
        ...value,
        manager_uuid:
          value?.manager_uuid instanceof EmployeeModel
            ? value?.manager_uuid?.uuid
            : value?.manager_uuid,
      });

      const groupModel = AbonementGroupMapper.toAbonementGroupModel(groupDTO);

      dispatch(storeUpdateAbonementGroup(groupModel));
      dispatch(storeUpdateAbonementGroupFromList(groupModel, abonementUuid));

      setAbonementGroup(groupModel);

      alert(
        'success',
        t('Tariff model group'),
        t('Tariff model group update success'),
      );
    },
    [abonementUuid, alert, dispatch, t],
  );

  const handleCreateAbonementGroup = React.useCallback(
    async (value: AbonementGroupFormDTO) => {
      const groupDTO = await createAbonementGroup(
        {
          ...value,
          manager_uuid:
            value?.manager_uuid instanceof EmployeeModel
              ? value?.manager_uuid?.uuid
              : value?.manager_uuid,
        },
        abonementUuid,
      );

      const groupModel = AbonementGroupMapper.toAbonementGroupModel(groupDTO);

      const updatedModel = groupModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddAbonementGroup(updatedModel, abonementUuid));

      alert(
        'success',
        t('Tariff model group'),
        t('Tariff model group create success'),
      );
    },
    [abonementUuid, alert, dispatch, t],
  );

  const handleResetAbonementGroup = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetAbonementGroup());
  }, [dispatch]);

  const handleRefreshAbonementGroup = React.useCallback(async () => {
    const groupModel = await refresh({ showLoading: false, groupUuid });

    if (groupModel) {
      dispatch(storeUpdateAbonementGroup(groupModel));
      dispatch(storeUpdateAbonementGroupFromList(groupModel, abonementUuid));
    }
  }, [abonementUuid, dispatch, groupUuid, refresh]);

  const handleChangeAbonementGroupStatus = React.useCallback(
    async (
      abonementUuid: string,
      status: AbonementGroupStatuses,
    ): Promise<AbonementGroupModel | void> => {
      try {
        const groupDTO = await changeAbonementGroupStatus(
          abonementUuid,
          status,
        );
        const groupModel = AbonementGroupMapper.toAbonementGroupModel(groupDTO);

        dispatch(storeUpdateAbonementGroup(groupModel));

        alert(
          'success',
          status === AbonementGroupStatuses.Disabled
            ? t('Tariff model group')
            : t('Tariff model group'),
          status === AbonementGroupStatuses.Disabled
            ? t('Tariff model group disabled')
            : t('Tariff model group activated'),
        );

        return groupModel;
      } catch (error: any) {
        alert(
          'error',
          status === AbonementGroupStatuses.Disabled
            ? t('Tariff model group')
            : t('Tariff model group'),
          `${t(
            `An error occurred during ${
              status === AbonementGroupStatuses.Disabled
                ? 'disabled'
                : 'activated'
            } group`,
          )} : ${error?.message}`,
        );
      }
    },
    [alert, dispatch, t],
  );

  return {
    ...groupParams,
    ...storedAbonementGroupParams,
    group,
    loading: !group,
    handleUpdateAbonementGroup,
    handleCreateAbonementGroup,
    status: storedAbonementGroupStatus,
    handleResetAbonementGroup,
    refresh,
    handleRefreshAbonementGroup,
    handleChangeAbonementGroupStatus,
    isHasScheduleList,
  };
}
