import * as React from 'react';
import {
  IUseCompanyScheduleListProps,
  useStateRelatedOrderList,
  useStateScheduleList,
} from './index';
import useStoredCompanies from './useStoredCompanies';
import { useParams } from 'react-router';
import {
  ScheduleCalendarModel,
  ScheduleCalendarParentDTO,
  ScheduleCalendarFormDTO,
  ScheduleCalendarStatsDTO,
} from '@structure';
import { List } from 'immutable';
import { ApiError } from '@services/types';
import { Moment } from 'moment/moment';
import {
  CompanyScheduleFilter,
  GetCompanyScheduleState,
} from '@services/api/company';

export interface IUseOrderListPageActionsProps
  extends Partial<CompanyScheduleFilter> {}

export interface IUseOrderListPageActionsReturnType {
  schedules: List<ScheduleCalendarModel> | null;
  loading: boolean;
  error: null | ApiError;
  limit?: number;
  total: number;
  refresh: (value: any) => Promise<any>;
  start_date: any;
  end_date: any;
  handleCreate: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleUpdate: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleDelete: (value: string[]) => Promise<void>;
  handleSearch: (value: Partial<IUseCompanyScheduleListProps>) => Promise<void>;

  handlePickScheduleRange: (value: [Moment, Moment]) => Promise<void>;
  parent: ScheduleCalendarParentDTO | null;
  stats: ScheduleCalendarStatsDTO;
  state: GetCompanyScheduleState;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

export function useOrderListPageActions({
  loadOnInit,
  ...props
}: IUseOrderListPageActionsProps & {
  loadOnInit?: boolean;
}): IUseOrderListPageActionsReturnType {
  const { orderId } = useParams();
  const { defaultCompanyUuid } = useStoredCompanies();

  const {
    schedules: companySchedules,
    loading: schedulesLoading,
    error: schedulesError,
    limit: schedulesLimit,
    total: schedulesTotal,
    refresh: schedulesRefresh,
    state,
    stats,

    date_start,
    date_end,

    handleCreateSchedule,
    handlePickScheduleRange,
    handleSearchSchedule,
    handleUpdateSchedule,
    handleDeleteSchedule,
    handleUpdateScheduleState,
  } = useStateScheduleList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: !orderId && loadOnInit,
    sort: 'createdAt',
    ...props,
  });

  const {
    schedules: relatedSchedules,
    loading: relatedSchedulesLoading,
    error: relatedSchedulesError,
    limit: relatedSchedulesLimit,
    total: relatedSchedulesTotal,
    refresh: relatedSchedulesRefresh,
    parent,

    handleCreateSchedule: handleCreateScheduleRelatedOrders,
    handleUpdateSchedule: handleUpdateScheduleRelatedOrders,
    handleDeleteSchedule: handleDeleteScheduleRelatedOrders,
    handleSearchRelatedOrders,
  } = useStateRelatedOrderList({
    scheduleUuid: orderId!,
  });

  const schedules = orderId ? relatedSchedules : companySchedules;

  const loading = orderId ? relatedSchedulesLoading : schedulesLoading;

  const error = orderId ? relatedSchedulesError : schedulesError;

  const limit = orderId ? relatedSchedulesLimit : schedulesLimit;

  const total = orderId ? relatedSchedulesTotal : schedulesTotal;

  const refresh = orderId ? relatedSchedulesRefresh : schedulesRefresh;

  const handleCreate = orderId
    ? handleCreateScheduleRelatedOrders
    : handleCreateSchedule;

  const handleUpdate = orderId
    ? handleUpdateScheduleRelatedOrders
    : handleUpdateSchedule;

  const handleDelete = orderId
    ? handleDeleteScheduleRelatedOrders
    : handleDeleteSchedule;

  const handleSearch = orderId
    ? handleSearchRelatedOrders
    : handleSearchSchedule;

  return {
    schedules,
    loading,
    error,
    limit,
    total,
    start_date: date_start,
    end_date: date_end,
    parent,
    state,
    stats,

    refresh,
    handleSearch: handleSearch as any,
    handleDelete,
    handleUpdate,
    handleCreate,
    handlePickScheduleRange,
    handleUpdateScheduleState,
  };
}
