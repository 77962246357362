import * as React from 'react';
import { List } from 'immutable';
import {
  ApiError,
  ClientPersonalityType,
  IEntityProps,
  IListSearchProps,
} from '@services/types';
import {
  IClientListDTO,
  ClientMapper,
  ClientModel,
  ClientSettlementType,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import {
  getCompanyClientList,
  IClientListStatsProps,
} from '@services/api/client';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { isThereContent } from '@sportix/sportix-common-modules';

export interface IUseCompanyClientListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  client_type?: ClientPersonalityType;
  provisioner_only?: boolean;
  settlement?: ClientSettlementType;
}

export interface IUseCompanyClientListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ClientModel> | null;
  refresh: (
    value: Partial<IUseCompanyClientListProps>,
  ) => Promise<List<ClientModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  client_type?: ClientPersonalityType;
  provisioner_only?: boolean;
  settlement?: ClientSettlementType;
  stats: IClientListStatsProps;
}

export function useCompanyClientList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    client_type,
    provisioner_only,
    settlement,
  }: IUseCompanyClientListProps = {} as IUseCompanyClientListProps,
): IUseCompanyClientListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    client_type: listClientType,
    stats: listStats,
    provisioner_only: listProvisionerOnly,
    settlement: listSettlement,
    handleUpdate,
  } = useStateReducer<Omit<IUseCompanyClientListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    client_type,
    stats: {} as IClientListStatsProps,
    provisioner_only,
    settlement,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      client_type = listClientType,
      provisioner_only = listProvisionerOnly,
      settlement = listSettlement,
    }: Partial<IUseCompanyClientListProps> = {}): Promise<List<ClientModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          client_type,
          provisioner_only,
          settlement,
        });

        const { clients, total, stats } =
          await cancellablePromise<IClientListDTO>(
            getCompanyClientList({
              limit,
              offset,
              keywords,
              companyUuid,
              client_type,
              provisioner_only,
              settlement,
            }),
          );

        handleUpdate({
          stats: isThereContent(stats)
            ? stats
            : {
                [ClientPersonalityType.Individual]: '0',
                [ClientPersonalityType.Group]: '0',
              },
        });

        const serviceListModel = ClientMapper.toClientListModel(clients, total);

        handleUpdate({
          entityList: serviceListModel?.clients,
          total: serviceListModel?.total,
          loading: false,
        });

        return serviceListModel?.clients;
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }

        alert(
          'error',
          t('Counterparties'),
          `${t('An error occurred during get client list')} : ${
            error?.message
          }`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      handleUpdate,
      listClientType,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      listProvisionerOnly,
      listSettlement,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    client_type: listClientType,
    stats: listStats,
    provisioner_only: listProvisionerOnly,
    settlement: listSettlement,
  };
}
