import { Record, List } from 'immutable';
import {
  SettingsModuleStatuses,
  SettingsModuleDependencyDTO,
  SettingsModuleDTO,
  SettingsModuleDependencyModel,
  SettingsModuleDependencyMapper,
  ModuleInputParameterDTO,
} from '../internal';

export interface ISettingsModuleModelReturnType {
  uuid: string;
  module_title: string;
  module_markets: string;
  module_description: string;
  module_name: string;
  module_price: string;
  module_period: string;
  module_amount_of_periods: number;
  module_status: SettingsModuleStatuses;
  created_at: string;
  updated_at: string;
  module_enabled_for_company: boolean;
  module_input_parameters_json: ModuleInputParameterDTO[];
  dependencies:
    | List<SettingsModuleDependencyModel>
    | SettingsModuleDependencyDTO[];
}

export class SettingsModuleModel extends Record<ISettingsModuleModelReturnType>(
  {
    uuid: '',
    module_title: '',
    module_markets: '',
    module_description: '',
    module_name: '',
    module_price: '',
    module_period: '',
    module_amount_of_periods: 0,
    module_status: SettingsModuleStatuses.Active,
    created_at: '',
    updated_at: '',
    dependencies: [] as SettingsModuleDependencyDTO[],
    module_enabled_for_company: false,
    module_input_parameters_json: [],
  },
) {
  constructor(props: SettingsModuleDTO = {} as SettingsModuleDTO) {
    const options: ISettingsModuleModelReturnType = {
      ...props,
      dependencies:
        SettingsModuleDependencyMapper.toSettingsModuleDependencyListModel(
          props?.dependencies,
        )?.dependencies,
    };

    super(options);
  }

  get dependencyListModel(): List<SettingsModuleDependencyModel> {
    return List.isList(this.dependencies)
      ? this.dependencies
      : SettingsModuleDependencyMapper.toSettingsModuleDependencyListModel(
          this.dependencies,
        ).dependencies;
  }
}
