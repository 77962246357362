import React from 'react';
import { Routes, Route } from 'react-router';
import {
  ServiceListPage,
  ServicePage,
  ServiceEmployeeListPage,
  GroupSessionListPage,
} from '../views/Services';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';

export function ServiceRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ServiceListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":serviceId"
        element={
          <AppRouteLayout>
            <ServicePage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:serviceId/${RoutesType.groupSessions}`}
        element={
          <AppRouteLayout>
            <GroupSessionListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:serviceId/${RoutesType.serviceEmployees}`}
        element={
          <AppRouteLayout>
            <ServiceEmployeeListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
