import React from 'react';
import {
  useTranslation,
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  ConfirmActionButton,
  IConfirmActionButtonProps,
} from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { eq, len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { GroupPaymentModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IDeleteGroupPaymentButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (ids: string[], force?: boolean) => Promise<void>;
  onError?: () => void;
  groupPayments: GroupPaymentModel[];
}

export default function DeleteGroupPaymentButton({
  children,
  groupPayments,
  onSuccess,
  onError,
  title,
  ...rest
}: IDeleteGroupPaymentButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: groupPayments,
    notifyTitle: 'Group Payment',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete group payment')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(groupPayments)} group payments`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
