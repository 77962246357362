import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  ProductMapper,
  ProductListModel,
  IProductListDTO,
  IProductsListStatsProps,
  ProductCategoryModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getProductCategoryProductList } from '@services/api/product';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseProductCategoryProductListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  categoryUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
}

export interface IUseProductCategoryProductListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: ProductListModel | null;
  refresh: (
    value: Partial<IUseProductCategoryProductListProps>,
  ) => Promise<ProductListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  categoryUuid: string;
  stats: IProductsListStatsProps;
  with_balance?: boolean;
  store_uuid?: string;
}

export function useProductCategoryProductList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    categoryUuid,
    with_balance,
    store_uuid,
  }: IUseProductCategoryProductListProps = {} as IUseProductCategoryProductListProps,
): IUseProductCategoryProductListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    categoryUuid: listUuid,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    stats: listStats,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseProductCategoryProductListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    categoryUuid,
    with_balance,
    store_uuid,
    stats: {} as IProductsListStatsProps,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      categoryUuid = listUuid,
      with_balance = listWithBalance,
      store_uuid = listStoreUuid,
    }: Partial<IUseProductCategoryProductListProps> = {}): Promise<ProductListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          categoryUuid,
          with_balance,
          store_uuid,
        });

        const { products, stats, total, category } =
          await cancellablePromise<IProductListDTO>(
            getProductCategoryProductList({
              limit,
              offset,
              keywords,
              categoryUuid,
              with_balance,
              store_uuid,
            }),
          );

        const categoryListModel = ProductMapper.toProductListModel(
          products,
          total,
          false,
          [],
          stats,
          category,
        );

        handleUpdate({
          entityList: categoryListModel,
          total: categoryListModel?.total,
          loading: false,
          stats,
        });

        return categoryListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during get materials list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listWithBalance,
      listStoreUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && categoryUuid) {
      (async () => {
        await refresh({ categoryUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, categoryUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    categoryUuid: listUuid,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    stats: listStats,
  };
}
