import { Map } from 'immutable';
import { APP_STATE, CASH_BOX } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { CashBoxModel } from '@structure';

interface ICashBoxListState {
  cashBox: CashBoxModel | null;
  cachedCashBoxList: Map<string, CashBoxModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<ICashBoxListState, 'cashBox'> {
  type: CASH_BOX.SET_CASH_BOX;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateCashBox {
  type: CASH_BOX.UPDATE_CASH_BOX;
  cashBox: CashBoxModel;
}

interface LoadingAction {
  type: CASH_BOX.LOADING_CASH_BOX;
}

interface ResetAction {
  type: CASH_BOX.RESET_CASH_BOX;
}

interface ErrorAction extends Pick<ICashBoxListState, 'error'> {
  type: CASH_BOX.ERROR_CASH_BOX;
}

interface Handlers {
  [CASH_BOX.SET_CASH_BOX]: (
    state: ICashBoxListState,
    action: SetAction,
  ) => ICashBoxListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: ICashBoxListState,
    action: SetInitialStateAction,
  ) => ICashBoxListState;

  [CASH_BOX.RESET_CASH_BOX]: (
    state: ICashBoxListState,
    action: ResetAction,
  ) => ICashBoxListState;

  [CASH_BOX.UPDATE_CASH_BOX]: (
    state: ICashBoxListState,
    action: UpdateCashBox,
  ) => ICashBoxListState;

  [CASH_BOX.ERROR_CASH_BOX]: (
    state: ICashBoxListState,
    value: ErrorAction,
  ) => ICashBoxListState;

  [CASH_BOX.LOADING_CASH_BOX]: (
    state: ICashBoxListState,
    value?: LoadingAction,
  ) => ICashBoxListState;
  DEFAULT: (state: ICashBoxListState) => ICashBoxListState;
}

const initState: ICashBoxListState = {
  cashBox: null,
  cachedCashBoxList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [CASH_BOX.SET_CASH_BOX]: (state, { cashBox }) => ({
    ...state,
    ...{
      cashBox,
      cachedCashBoxList: cashBox
        ? state.cachedCashBoxList.set(cashBox?.uuid, cashBox)
        : state.cachedCashBoxList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CASH_BOX.RESET_CASH_BOX]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [CASH_BOX.UPDATE_CASH_BOX]: (state, { cashBox }) => ({
    ...state,
    ...{
      cashBox,
      cachedCashBoxList: state.cachedCashBoxList.update(
        cashBox?.uuid,
        () => cashBox,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [CASH_BOX.ERROR_CASH_BOX]: (
    state: ICashBoxListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CASH_BOX.LOADING_CASH_BOX]: (state: ICashBoxListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: ICashBoxListState) => state,
};

export default function CashBox(
  state: any = initState,
  action: any,
): ICashBoxListState {
  const handler = handlers[action.type as CASH_BOX] || handlers.DEFAULT;
  return handler(state, action);
}
