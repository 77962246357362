import { PERSONALITY_TYPE, TIME_LIST_SERVICES } from '@services/types';
import {
  ServiceModel,
  EmployeeMapper,
  EmployeeFormDTO,
  GroupPaymentMapper,
  GroupPaymentFormDTO,
  EmployeeModel,
  GroupPaymentDTO,
  ServiceMultiSessionable,
  GroupMapper,
} from '../internal';
import { correctPrice } from '@services/helpers';

export class ServiceFormDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public manager_uid: EmployeeModel | string;

  public payment_group_uuid: GroupPaymentDTO | string;

  public period_amount: number;

  public price: string;
  public net_price: string;

  public period: TIME_LIST_SERVICES;

  public personality: PERSONALITY_TYPE;

  public is_multi_sessionable: boolean;
  public multi_sessions: ServiceMultiSessionable[];

  constructor(props?: ServiceModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.manager_uid = editMode
      ? props?.managerModel!
      : props?.managerModel?.uuid || '';
    this.payment_group_uuid = editMode
      ? (props?.payment_group as any)
      : props?.payment_group?.uuid || '';
    this.price = props?.price ? `${props?.price}` : '0.00';
    this.net_price = correctPrice(props?.net_price || 0);
    this.period_amount = props?.period_amount || 1;
    this.period = props?.period || TIME_LIST_SERVICES.HOUR;
    this.personality = props?.personality || PERSONALITY_TYPE.INDIVIDUAL;
    this.is_multi_sessionable = props?.is_multi_sessionable ?? false;
    this.multi_sessions = props?.multi_sessions || [];
  }
}
