import { Record } from 'immutable';
import {
  StatusDTO,
  StatusCategoryDTO,
  StatusMapper,
  ExecuteOnSet,
  StatusCategoryModel,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IStatusModelReturnType {
  uuid: string;
  title: string;
  execute_on_set: ExecuteOnSet | string;
  allowed_statuses_from: string[];
  allowed_statuses_to: string[];
  created_at: string;
  updated_at: string;
  category: StatusCategoryDTO | StatusCategoryModel;
  created_by: ProfileDTO | ProfileModel;
}

export class StatusModel extends Record<IStatusModelReturnType>({
  uuid: '',
  title: '',
  execute_on_set: '',
  allowed_statuses_from: [],
  allowed_statuses_to: [],
  created_at: '',
  updated_at: '',
  category: {} as StatusCategoryModel,
  created_by: {} as ProfileDTO,
}) {
  constructor(
    props: StatusDTO = {} as StatusDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStatusModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.category,
              ...withoutRecursion,
            ]),

      category:
        withoutRecursion.indexOf(WithoutRecursion.category) !== -1
          ? props?.category
          : StatusMapper.toStatusCategoryModel(props?.category, [
              WithoutRecursion.category,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get categoryModel(): StatusCategoryModel {
    return this.category instanceof StatusCategoryModel
      ? this.category
      : StatusMapper.toStatusCategoryModel(this.category);
  }
}
