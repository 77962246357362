import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useDefaultForm } from '@contex';
import { useTranslation } from 'react-i18next';
import {
  IUseStateEmployeeListReturnType,
  IUseStateServiceListReturnType,
  useRequiredFields,
  useStoredCompanies,
} from '@hooks';
import { FormSwitch, SearchSelect } from '@components/lib/DataDisplay';
import { StyledDescription } from '@components/lib/Styled';
import {
  checkWorkSchedule,
  correctPrice,
  curry,
  findItemIndex,
  head,
  ifElse,
  isListToArray,
  isObject,
  joinArr,
  modifyWeekdays,
  split,
} from '@services/helpers';
import { ServiceModel } from '@structure';
import { SERVICES_REQUIRED_FIELDS_FORM } from '@services/api/services';
import { IOrderRequiredFieldsWithFullName } from '@services/api/orders';
import styled from 'styled-components';
import { WeekdayTimePickerForm } from '@components/lib/General';
import { IOnChangeDaysOfWeekSchedule, WorkScheduleLabel } from '../../Company';
import { PERSONALITY_TYPE, TIME_LIST_SERVICES } from '@services/types';
import { MAX_PAUSED_PERIOD_TYPE } from '@services/api/abonement';

export interface ITariffModelFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<IUseStateServiceListReturnType, 'services' | 'handleSearchServices'> {
  employeesLoading: boolean;
  servicesLoading: boolean;
  editMode: boolean;
}

const TariffModelPeriods = [
  TIME_LIST_SERVICES.WEEK,
  TIME_LIST_SERVICES.MONTH,
  TIME_LIST_SERVICES.YEAR,
];

const StyledServiceDescription = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :nth-child(1n + 1) {
    margin-right: 5px;
  }

  & > :last-child {
    margin: 0;
  }
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: fit-content;
`;

export function TariffModelFields({
  services,
  servicesLoading,
  handleSearchServices,
  employees,
  employeesLoading,
  handleSearchEmployees,
  editMode,
}: ITariffModelFieldsProps): JSX.Element {
  const { t } = useTranslation();
  const {
    loadingSubmit,
    handlerUpdateFormState,
    setFieldsValue,
    getFieldValue,
    formData,
  } = useDefaultForm();
  const { defaultCompany } = useStoredCompanies();

  const [selectedService, setSelectedService] = useState<ServiceModel | null>(
    null,
  );
  const [isFixedRange, setIsFixedRange] = useState(
    checkWorkSchedule(formData?.timing ? formData?.timing : ''),
  );

  const [isGroupFixedRange, setIsGroupFixedRange] = useState(
    checkWorkSchedule(formData?.group_schedule ? formData?.group_schedule : ''),
  );

  const [iseResetEmployee, setIsResetEmployee] = useState(false);

  const fixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(formData?.timing || ''),
      split(',', formData?.timing || ''),
      [],
    ),
  );

  const weekRange = useRef(
    ifElse(
      checkWorkSchedule(formData?.timing || ''),
      [],
      split(',', formData?.timing || ''),
    ),
  );

  const groupFixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(formData?.group_schedule || ''),
      split(',', formData?.group_schedule || ''),
      [],
    ),
  );

  const groupWeekRange = useRef(
    ifElse(
      checkWorkSchedule(formData?.group_schedule || ''),
      [],
      split(',', formData?.group_schedule || ''),
    ),
  );

  const handleChangeService = useCallback(
    (serviceUuid: string) => {
      if (services?.size) {
        const service = services?.find(({ uuid }) => uuid === serviceUuid);

        const periods = formData?.period_amount;
        const price = Number(service?.price || 0);

        if (service) {
          setSelectedService(service);
          handlerUpdateFormState({
            service_uuid: service?.uuid,
          });

          if (periods > 0) {
            handlerUpdateFormState({
              price: correctPrice(price * periods),
            });
          } else {
            handlerUpdateFormState({
              price: correctPrice(service?.price || 0),
            });
          }

          setIsResetEmployee(true);
          setTimeout(setIsResetEmployee, 0, false);

          if (formData?.manager_required) {
            handlerUpdateFormState({ manager_uuid: service?.manager?.uuid });
          } else if (service?.personality === PERSONALITY_TYPE.GROUP) {
            handlerUpdateFormState({
              manager_required: true,
              manager_uuid: service?.manager?.uuid,
            });
          }
        }
      }
    },
    [
      formData?.manager_required,
      formData?.period_amount,
      handlerUpdateFormState,
      services,
    ],
  );

  const changeServiceFields = useCallback(
    ({
      price,
      period,
      period_amount,
      ...rest
    }: any): IOrderRequiredFieldsWithFullName & any => {
      return {
        ...rest,
        period,
        period_amount,
        price: `${
          defaultCompany ? `${defaultCompany?.currency_symbol} ` : ''
        } ${price}`,
      };
    },
    [defaultCompany],
  );

  const serviceRequiredFields = useRequiredFields(
    selectedService,
    SERVICES_REQUIRED_FIELDS_FORM,
    changeServiceFields,
  );

  const handleChangePrice = useCallback(
    (event: any) => {
      const value = event.target.value.replace(/[a-zA-Z]+/g, '');

      if (event.target.value) {
        handlerUpdateFormState({
          price: value,
        });
      }
    },
    [handlerUpdateFormState],
  );

  const handleChangePeriods = useCallback(
    (period_amount: any) => {
      const price = Number(selectedService?.price || 0);

      if (period_amount > 0) {
        handlerUpdateFormState({
          period_amount,
          price: correctPrice(period_amount * price),
        });
      } else {
        handlerUpdateFormState({ price: correctPrice(price) });
      }
    },
    [handlerUpdateFormState, selectedService?.price],
  );

  const handleChangeCanVisitingBeLimited = useCallback(
    (value: boolean) => {
      handlerUpdateFormState({
        can_visiting_be_limited: value,
        visiting_limit: formData?.visiting_limit || 1,
      });

      if (!value) {
        handlerUpdateFormState({ visiting_limit: NaN });
      }
    },
    [formData?.visiting_limit, handlerUpdateFormState],
  );

  const handleChangeCanMoveUnusedVisiting = useCallback(
    (value: boolean) => {
      handlerUpdateFormState({
        can_move_unused_visiting: value,
        max_paused_period: formData?.max_paused_period || 1,
      });

      if (!value) {
        handlerUpdateFormState({ can_be_paused: NaN });
      }
    },
    [formData?.max_paused_period, handlerUpdateFormState],
  );

  const handleChangeCanBePaused = useCallback(
    (value: boolean) => {
      handlerUpdateFormState({ can_be_paused: value, max_paused_period: 1 });

      if (!value) {
        handlerUpdateFormState({ max_paused_period: NaN });
      }
    },
    [handlerUpdateFormState],
  );

  const handleChangeManagerRequired = useCallback(
    (value: boolean) => {
      handlerUpdateFormState({
        manager_required: value,
      });

      setIsResetEmployee(true);
      setTimeout(setIsResetEmployee, 0, false);

      if (!value) {
        handlerUpdateFormState({
          manager_uuid: '',
        });
        return;
      }

      handlerUpdateFormState({
        manager_uuid: selectedService?.manager?.uuid,
      });
    },
    [handlerUpdateFormState, selectedService],
  );

  const handleChangeDateRangeView = useCallback((): void => {
    setIsFixedRange((prevState) => {
      return !prevState;
    });
  }, []);

  const handleChangeGroupDateRangeView = useCallback((): void => {
    setIsGroupFixedRange((prevState) => {
      return !prevState;
    });
  }, []);

  const updateWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      fixedRange.current = head(updatedWeekRange) ? updatedWeekRange : [];
      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        timing:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [fixedRange],
  );

  const updateGroupWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      groupFixedRange.current = head(updatedWeekRange) ? updatedWeekRange : [];
      handlerUpdateFormState({
        group_weekdays: updatedWeekRange,
        group_schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [groupFixedRange],
  );

  const updateCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      weekRange.current = updatedWeekRange;

      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        timing:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [weekRange],
  );

  const updateGroupCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      groupWeekRange.current = updatedWeekRange;

      handlerUpdateFormState({
        group_weekdays: updatedWeekRange,
        group_schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [groupWeekRange],
  );

  const onChangeDaysOfWeek = curry<any, any>(
    ({ handlerUpdateFormState }, value: IOnChangeDaysOfWeekSchedule) => {
      if (isFixedRange) {
        updateWeekRange({ time: value?.time, handlerUpdateFormState });
      }

      if (!isFixedRange) {
        updateCustomRange({ ...value, handlerUpdateFormState });
      }
    },
  );

  const onChangeGroupDaysOfWeek = curry<any, any>(
    ({ handlerUpdateFormState }, value: IOnChangeDaysOfWeekSchedule) => {
      if (isGroupFixedRange) {
        updateGroupWeekRange({ time: value?.time, handlerUpdateFormState });
      }

      if (!isGroupFixedRange) {
        updateGroupCustomRange({ ...value, handlerUpdateFormState });
      }
    },
  );

  const handleChangeCanTimingBeLimited = useCallback(
    (value: boolean) => {
      handlerUpdateFormState({ can_timing_be_limited: value });

      if (!value) {
        handlerUpdateFormState({ timing: '', weekdays: [] });
      }
    },
    [handlerUpdateFormState],
  );

  useEffect(() => {
    if (isObject(formData?.service_uuid) && !selectedService) {
      setSelectedService(formData?.service_uuid);
    }
  }, [formData?.service_uuid, selectedService]);

  useEffect(() => {
    if (isFixedRange) {
      handlerUpdateFormState({
        weekdays: fixedRange.current,
        timing: fixedRange.current ? fixedRange.current.join(',') : '',
      });
    } else {
      handlerUpdateFormState({
        weekdays: weekRange.current,
        timing: weekRange.current ? weekRange.current.join(',') : '',
      });
    }
  }, [handlerUpdateFormState, isFixedRange]);

  useEffect(() => {
    if (isGroupFixedRange) {
      handlerUpdateFormState({
        group_weekdays: groupFixedRange.current,
        group_schedule: groupFixedRange.current
          ? groupFixedRange.current.join(',')
          : '',
      });
    } else {
      handlerUpdateFormState({
        group_weekdays: groupWeekRange.current,
        group_schedule: groupWeekRange.current
          ? groupWeekRange.current.join(',')
          : '',
      });
    }
  }, [handlerUpdateFormState, isGroupFixedRange]);

  useEffect(() => {
    if (!selectedService && isObject(formData?.service_uuid)) {
      setSelectedService(formData?.service_uuid);
    }
  }, [formData?.service_uuid, selectedService]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Title')}
          label={t('Title')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
            {
              max: 255,
              message: t('The maximum value can be 255 characters'),
            },
          ]}>
          <Input
            disabled={loadingSubmit}
            placeholder={t('Enter the abonement title')}
            onChange={(e) => handlerUpdateFormState({ title: e.target.value })}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Service')}
          label={t('Service')}
          name="service_uuid"
          extra={
            serviceRequiredFields ? (
              <>
                <StyledDescription style={{ whiteSpace: 'unset' }}>
                  {t(
                    'Prior to the creation of the Tariff Model, services with a period of Week, Month and Year were allowed',
                  )}
                </StyledDescription>
                <StyledServiceDescription>
                  {Object.entries(serviceRequiredFields).map(([key, value]) => (
                    <StyledDescription key={key}>{`${t(key)}: ${t(
                      value,
                    )}`}</StyledDescription>
                  ))}
                </StyledServiceDescription>
              </>
            ) : (
              <StyledDescription style={{ whiteSpace: 'unset' }}>
                {t(
                  'Prior to the creation of the Tariff Model, services with a period of Week, Month and Year were allowed',
                )}
              </StyledDescription>
            )
          }
          rules={[
            {
              required: true,
              message: t('Service must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={false}
            name="service_uuid"
            placeholder={t('Select a service')}
            disable={loadingSubmit || servicesLoading}
            data={isListToArray(
              services?.filter(({ period }) =>
                TariffModelPeriods.includes(period),
              ) as any,
            )}
            onSearch={(keywords) => {
              handleSearchServices({
                keywords,
                showLoading: false,
                limit: 100,
              });
            }}
            onChange={handleChangeService}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t(
            'The base period is a time interval or period of validity specified when the service is created (week, month or year)',
          )}
          label={`${t('Number of base periods')} ${
            selectedService
              ? `[ ${selectedService?.period_amount} ] ${t(
                  selectedService?.period,
                )}`
              : ''
          }`}
          name="period_amount"
          rules={[
            {
              required: true,
              message: t('Time interval must be specified.'),
            },
            {
              min: 1,
              type: 'number',
              message: t('The time interval must not be less than 1'),
            },
          ]}
          extra={
            <StyledDescription style={{ whiteSpace: 'unset' }}>
              {t(
                'The field specifies the number for which term the subscription is connected. Periods can be a week, a month, or a year.',
              )}
            </StyledDescription>
          }>
          <FullWidthInputNumber
            type="number"
            disabled={loadingSubmit}
            onChange={handleChangePeriods}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={t('Price')}
          name="price"
          rules={[
            {
              required: true,
              message: t('Price must be specified.'),
            },
            {
              transform: (value) => Number(value),
              type: 'number',
              message: t('Price must be a number.'),
            },
          ]}>
          <Input
            autoComplete="off"
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit}
            onChange={handleChangePrice}
          />
        </Form.Item>
      </Col>

      {selectedService?.personality === PERSONALITY_TYPE.GROUP && !editMode ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Class schedule in the group')}
            className="company-form__work-schedule"
            label={
              <WorkScheduleLabel
                label={'Class schedule in the group'}
                disabled={loadingSubmit}
                isFixedRange={isGroupFixedRange}
                handleChangeView={handleChangeGroupDateRangeView}
              />
            }
            name="group_weekdays">
            <WeekdayTimePickerForm
              name="group_weekdays"
              fixedTitle={t('Mon-Sun')}
              checkboxDisable={false}
              isRangePicker
              isFixedRange={isGroupFixedRange}
              editMode={true}
              disabled={loadingSubmit}
              onChange={onChangeGroupDaysOfWeek({
                handlerUpdateFormState,
              })}
            />
          </Form.Item>
        </Col>
      ) : null}

      <FormSwitch
        value={formData?.manager_required}
        disabled={
          loadingSubmit ||
          selectedService?.personality === PERSONALITY_TYPE.GROUP
        }
        loading={loadingSubmit}
        name="manager_required"
        label={t('Session with the manager')}
        tooltipTitle={t('Session with the manager')}
        onChange={handleChangeManagerRequired}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        extra={
          <StyledDescription style={{ whiteSpace: 'unset' }}>
            {t('This option indicates the need to engage a personal manager.')}
          </StyledDescription>
        }
      />
      {formData?.manager_required ? (
        <>
          <Col span={24}>
            <Form.Item
              label={t('Cost of employment')}
              name="session_price"
              rules={[
                {
                  required: true,
                  message: t('Cost of employment must be specified.'),
                },
                {
                  transform: (value) => Number(value),
                  type: 'number',
                  message: t('Cost of employment must be a number.'),
                },
              ]}
              extra={
                <StyledDescription style={{ whiteSpace: 'unset' }}>
                  {t('The cost of one session with a manager')}
                </StyledDescription>
              }>
              <Input
                addonBefore={defaultCompany?.currency_symbol}
                disabled={loadingSubmit}
                onChange={(e) =>
                  handlerUpdateFormState({ session_price: e?.target?.value })
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              tooltip={t('Manager')}
              label={t('Manager')}
              name="manager_uuid">
              <SearchSelect
                resetState={iseResetEmployee}
                selectIfOnFirst={false}
                selectFirst={false}
                name="manager_uuid"
                getOptionValueTitle="fullName"
                placeholder={t('Select an employee')}
                disable={
                  loadingSubmit ||
                  employeesLoading ||
                  !formData?.manager_required
                }
                data={isListToArray(employees as any)}
                onChange={(manager_uuid) =>
                  handlerUpdateFormState({ manager_uuid })
                }
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    showLoading: false,
                    limit: 100,
                  })
                }
              />
            </Form.Item>
          </Col>
        </>
      ) : null}

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="can_visiting_be_limited"
        label={t('Restrictions on visits')}
        tooltipTitle={t('Restrictions on visits')}
        onChange={handleChangeCanVisitingBeLimited}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
      />
      {formData?.can_visiting_be_limited ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Maximum number of visits')}
            label={t('Maximum number of visits')}
            name="visiting_limit"
            extra={
              <StyledDescription style={{ whiteSpace: 'unset' }}>
                {t(
                  'This value limits the maximum number of sessions that can be used within the current Subscription.',
                )}
              </StyledDescription>
            }
            rules={
              formData?.can_visiting_be_limited
                ? [
                    {
                      min: 1,
                      type: 'number',
                      message: t('The time interval must not be less than 1'),
                    },
                  ]
                : []
            }>
            <FullWidthInputNumber
              type="number"
              disabled={loadingSubmit || !formData?.can_visiting_be_limited}
              placeholder={'1'}
              onChange={(e) => handlerUpdateFormState({ visiting_limit: e })}
            />
          </Form.Item>
        </Col>
      ) : null}

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="can_move_unused_visiting"
        label={t('Postpone visits')}
        tooltipTitle={t('Postpone visits')}
        onChange={handleChangeCanMoveUnusedVisiting}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        extra={
          <StyledDescription style={{ whiteSpace: 'unset' }}>
            {t(
              'This option allows the automatic transfer of unused sessions to a new Subscription.',
            )}
          </StyledDescription>
        }
      />
      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="can_be_paused"
        label={t('Abonement suspension')}
        tooltipTitle={t('Abonement suspension')}
        onChange={handleChangeCanBePaused}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        extra={
          <StyledDescription style={{ whiteSpace: 'unset' }}>
            {t(
              'This option allows the Clients to suspend the subscription with the fixation of unused resources.',
            )}
          </StyledDescription>
        }
      />
      {formData?.can_be_paused ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Suspension time limit')}
            label={t('Suspension time limit')}
            name="max_paused_period"
            extra={
              <StyledDescription style={{ whiteSpace: 'unset' }}>
                {t(
                  'This option limits the period for which the Client can suspend the subscription',
                )}
              </StyledDescription>
            }
            rules={
              formData?.can_move_unused_visiting
                ? [
                    {
                      transform: (value) => Number(value),
                      min: 1,
                      type: 'number',
                      message: t('The time interval must not be less than 1'),
                    },
                  ]
                : []
            }>
            <Input
              addonAfter={
                <Form.Item noStyle name="max_paused_period_type">
                  <StyledSearchSelect
                    dropdownMatchSelectWidth={false}
                    name="max_paused_period_type"
                    placeholder={t(
                      'Select the manager for which the service is manage',
                    )}
                    getOptionValueTitle="title"
                    disable={loadingSubmit}
                    data={MAX_PAUSED_PERIOD_TYPE}
                    onChange={(max_paused_period_type) => {
                      handlerUpdateFormState({ max_paused_period_type });
                    }}
                  />
                </Form.Item>
              }
              type="number"
              disabled={loadingSubmit || !formData?.can_be_paused}
              onChange={(e) =>
                handlerUpdateFormState({ max_paused_period: e?.target?.value })
              }
              placeholder={'1'}
            />
          </Form.Item>
        </Col>
      ) : null}

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="can_timing_be_limited"
        label={t('Abonement validity schedule')}
        tooltipTitle={t('Abonement validity schedule')}
        onChange={handleChangeCanTimingBeLimited}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        extra={
          <StyledDescription style={{ whiteSpace: 'unset' }}>
            {t('Limit access time')}
          </StyledDescription>
        }
      />
      {formData?.can_timing_be_limited ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Access time')}
            className="company-form__work-schedule"
            label={
              <WorkScheduleLabel
                label={'Access time'}
                disabled={loadingSubmit || !formData?.can_timing_be_limited}
                isFixedRange={isFixedRange}
                handleChangeView={handleChangeDateRangeView}
              />
            }
            extra={
              <StyledDescription style={{ whiteSpace: 'unset' }}>
                {t(
                  "The period of validity of the season ticket is in rak's of the day. For example, from Mon 08:00-17:00...",
                )}
              </StyledDescription>
            }
            name="weekdays">
            <WeekdayTimePickerForm
              fixedTitle={t('Mon-Sun')}
              checkboxDisable={false}
              isRangePicker
              isFixedRange={isFixedRange}
              editMode={true}
              disabled={loadingSubmit || !formData?.can_timing_be_limited}
              onChange={onChangeDaysOfWeek({
                handlerUpdateFormState,
              })}
            />
          </Form.Item>
        </Col>
      ) : null}

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="can_refund_money"
        label={t('Refunds')}
        tooltipTitle={t('Refunds')}
        onChange={(can_refund_money) =>
          handlerUpdateFormState({ can_refund_money })
        }
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        extra={
          <StyledDescription style={{ whiteSpace: 'unset' }}>
            {t(
              'For client subscriptions that are being created, it will be possible to suspend the subscription and return funds to the Client',
            )}
          </StyledDescription>
        }
      />

      <Col span={24}>
        <Form.Item label={t('Note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ comment: e.target.value })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
