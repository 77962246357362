import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Routes } from '@services/types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import useStoredCompanies from '@hooks/useStoredCompanies';
import { correctPrice } from '@sportix/sportix-common-modules';
import { StoreDocumentModel } from '@models/StoreDocumentModel';

export interface IStoreDocScoreStatusViewProps {
  document: StoreDocumentModel | null;
}

const StyledScoreStatusContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const StyledIndebtednessDocument = styled.div`
  color: ${({ theme }) => theme.colors.error};

  a,
  strong {
    font-size: 35px;
    padding-left: 5px;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }
`;

export function StoreDocumentScoreStatusView(
  props: IStoreDocScoreStatusViewProps,
): React.JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { document } = props;
  const { defaultCompany } = useStoredCompanies();

  const isIndebtedness: boolean = useMemo(
    () =>
      (document?.doc_sum_paid || 0) <
      (document?.doc_sum_total_with_discount || 0),
    [document],
  );

  const debitSum: number = useMemo(
    () =>
      correctPrice(
        (document?.doc_sum_total_with_discount || 0) -
          (document?.doc_sum_paid || 0),
      ),
    [document],
  );

  const onDocumentPaymentClick = useCallback(
    (e: any) => {
      e?.preventDefault();
      e?.stopPropagation();

      return navigate(`${Routes.payments}/${Routes.stores}`);
    },
    [navigate],
  );

  return (
    <StyledScoreStatusContainer>
      {isIndebtedness && (
        <StyledIndebtednessDocument>
          {t('Заборгованість')}
          <Link onClick={onDocumentPaymentClick} to={'/'}>
            <strong>
              <span>{defaultCompany?.currency_symbol || ''}</span>
              {debitSum}
            </strong>
          </Link>
        </StyledIndebtednessDocument>
      )}
    </StyledScoreStatusContainer>
  );
}
