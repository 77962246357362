import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  CALENDAR_ACTIONS,
  CalendarActions,
  handlers,
  IInitialState,
  MODE,
} from './CalendarActions';
import {
  addMonthEvents,
  buildCalendarEventToMonthFormat,
  toUtcDateInFormat,
  buildHourInDay,
  getDatesInMonthDisplay,
  getMonth,
  getTimesOfDates,
  getYear,
  IMonthFormat,
  isFunction,
  isObject,
  isThereContent,
  ITimeInDay,
  map,
  ScheduleDateProp,
  DATE_SPECIFIC_FORMAT,
  getUrlPathName,
  ITimeRange,
  ifElse,
  findMinMaxRange,
  getMinMaxFromRanges,
  compose,
  getMonthDates,
  checkWorkSchedule,
  getFromMonthFormatLastDate,
} from '@services/helpers';
import { useLocalStorage } from '@hooks';
import {
  CALENDAR,
  CALENDAR_ENTRY,
  DEFAULT_CALENDAR_TIME_RANGE,
} from '@components/lib/const';
import { DateValue } from '@services/types';
import CalendarDndManager from './CalendarDndManager';
import { ScheduleCalendarFormDTO, ScheduleCalendarModel } from '@structure';
import { List } from 'immutable';
import { Routes, useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

export interface ChooseDay {
  date: Date | string;
}

export type SchedulePopoverType =
  | string
  | React.FC<{ event: ScheduleCalendarModel }>
  | React.ComponentClass<{ event: ScheduleCalendarModel }>;

export type Context = {
  hourInDay: ITimeInDay[];
  mode: MODE;
  monthDates: IMonthFormat[];
  selectedDate: string | Date;
  dispatch: React.Dispatch<CalendarActions>;
  handleUpdate: (value: IHandleUpdateCalendar) => Promise<void>;
  handleUpdateMode: (value: MODE) => void;
  handleUpdateDate: (date: Date) => void;
  handleChooseDay: (value: ChooseDay) => void;
  setDefaultScheduleItem: (defaultScheduleItem: ScheduleCalendarModel) => void;
  addSchedulesToMonthDates: (
    schedules: any,
    dateInMonth?: IMonthFormat[],
  ) => IMonthFormat[];
  scheduleStartProp: ScheduleDateProp;
  scheduleEndProp: ScheduleDateProp;
  SchedulePopover: SchedulePopoverType;
  scheduleDetails: (
    children: (...rest: any[]) => React.ReactNode,
    schedule: ScheduleCalendarModel,
  ) => any;
  defaultScheduleItem: ScheduleCalendarModel;
  withAddingEvents: boolean;
  currentMonthLastDate: DateValue;
  defaultMonthDates: any[];
  loading: boolean;
  isForm: boolean;
  isEdit: boolean;
  limitedByDate: DateValue | undefined;
};

export const CalendarContext = React.createContext<Context>({
  monthDates: [],
  hourInDay: [],
  selectedDate: moment().toString(),
  dispatch: () => [],
  handleUpdate: async () => {},
  handleUpdateMode: () => {},
  handleUpdateDate: () => {},
  handleChooseDay: () => {},
  setDefaultScheduleItem: () => {},
  addSchedulesToMonthDates: () => [],
  mode: MODE.DAY,
  scheduleStartProp: '',
  scheduleEndProp: '',
  SchedulePopover: '',
  scheduleDetails: () => <></>,
  defaultScheduleItem: {} as ScheduleCalendarModel,
  withAddingEvents: false,
  currentMonthLastDate: '',
  defaultMonthDates: [],
  loading: true,
  isForm: false,
  isEdit: false,
  limitedByDate: undefined,
});

export interface ICalendarManagerProps<T> {
  schedules?: List<T>;
  scheduleStartProp?: ScheduleDateProp;
  scheduleEndProp?: ScheduleDateProp;
  onRefresh?: (value: any) => Promise<any>;
  onStateScheduleUpdate?: (value: {
    [key: string]: IMonthFormat[];
  }) => Promise<void>;
  localStorageName: string;
  SchedulePopover: SchedulePopoverType;
  scheduleDetails: (
    children: (...rest: any[]) => any,
    schedule: ScheduleCalendarModel,
  ) => React.ReactNode;
  timeRange?: ITimeRange;
  customDayTimeRange: string | undefined | null;
  withDnDEventsProps?: {
    handleChangeTime?: (time: string) => void;
    onSuccess?: (value: ScheduleCalendarFormDTO) => Promise<void>;
    order?: Partial<ScheduleCalendarFormDTO>;
  };
  withoutStorageDate?: string;
  forceRefresh?: boolean;
  loading?: boolean;
  isClearDnDCache?: boolean;
  isForm?: boolean;
  isEdit?: boolean;
  limitedByDate?: DateValue;
  waitStartRefresh?: boolean;
  mode?: string;
  withoutStorageMode?: boolean;
}

export interface ICalendarManagerChildrenProps<T>
  extends ICalendarManagerProps<T> {
  children: React.ReactNode;
}

export interface IScheduleCalendar<T> {
  [key: string]: T[] | List<T>[];
}

export interface UpdateDates<T> {
  dateInMonth?: IMonthFormat[];
  date: string | Date;
  schedules?: IScheduleCalendar<T> | T[] | List<T>;
  name: string;
}

export interface IHandleUpdateCalendar {
  prevDateInMonth: IMonthFormat[];
  date: Date;
  forceUpdate?: boolean;
  name?: string;
}

export const useCalendar = () => React.useContext<Context>(CalendarContext);

moment.suppressDeprecationWarnings = true;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  //min-width: 685px;
`;

export function CalendarManager<T>({
  children,
  scheduleStartProp = 'scheduled_date',
  scheduleEndProp,
  localStorageName,
  schedules: calendarSchedule,
  onRefresh = async () => {},
  onStateScheduleUpdate = async () => {},
  SchedulePopover,
  scheduleDetails,
  timeRange = DEFAULT_CALENDAR_TIME_RANGE,
  customDayTimeRange = null,
  withDnDEventsProps,
  withoutStorageDate = '',
  forceRefresh = false,
  loading,
  isClearDnDCache,
  isForm = false,
  isEdit = false,
  limitedByDate,
  waitStartRefresh,
  mode = MODE.DAY,
  withoutStorageMode,
}: ICalendarManagerChildrenProps<T>): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const didCancel = useRef<boolean>();

  const isAddEvents = useRef(false);
  const isInitHourInDay = useRef(false);
  const [calendarMode, setCalendarMode] = useLocalStorage(
    withoutStorageMode ? 'unknown' : 'calendarMode',
  );
  const [calendarDate, setCalendarDate] = useLocalStorage(localStorageName);

  const [calendarData, dispatch] = React.useReducer(
    (state: IInitialState, action: CalendarActions) => {
      const handler =
        handlers[action.type] || handlers[CALENDAR_ACTIONS.DEFAULT];
      return handler(state, action as any);
    },
    {
      scheduleStartProp: scheduleStartProp || '',
      scheduleEndProp: scheduleEndProp || '',
      hourInDay: buildHourInDay(),
      selectedDate: !withoutStorageDate
        ? calendarDate || moment().toDate()
        : moment(withoutStorageDate).toDate(),
      mode: calendarMode || mode,
      monthDates:
        (calendarDate || calendarDate === undefined || calendarDate === null) &&
        getDatesInMonthDisplay({
          month: getMonth(moment(calendarDate)) + 1,
          year: getYear(moment(calendarDate)),
        }),
      defaultMonthDates: [],
      defaultScheduleItem: {} as ScheduleCalendarModel,
      withAddingEvents: isThereContent(withDnDEventsProps),
      currentMonthLastDate: '',
      loading: true,
      schedules: null,
    },
  );

  /// START
  const addSchedulesToMonthDates = useCallback(
    (
      schedules: any,
      dateInMonth: IMonthFormat[] = calendarData?.defaultMonthDates,
    ): IMonthFormat[] => {
      const schedulesLikeMonthFormat = buildCalendarEventToMonthFormat(
        typeof scheduleStartProp === 'string' ? scheduleStartProp : '',
        typeof scheduleEndProp === 'string'
          ? scheduleEndProp
          : (scheduleEndProp as any),
        schedules,
      );

      const monthDates = map(addMonthEvents(schedulesLikeMonthFormat))(
        dateInMonth,
      );

      const currentMonthLastDate = getFromMonthFormatLastDate(monthDates);

      if (isThereContent(monthDates)) {
        dispatch({
          type: CALENDAR_ACTIONS.SET_MONTH_DATES,
          monthDates,
        });
      }

      dispatch({
        type: CALENDAR_ACTIONS.SET_LAST_MONTH_DATE,
        currentMonthLastDate,
      });

      return monthDates;
    },
    [calendarData, scheduleEndProp, scheduleStartProp],
  );

  const debounceOnBuildEvents = React.useCallback(
    async (
      dateInMonth: IMonthFormat[],
      start: string,
      end: string,
      currentDate: Date | string,
      scheduleList: any,
      name: string,
    ): Promise<void> => {
      let schedules;
      if (scheduleList) {
        schedules = scheduleList;
      } else {
        schedules = isFunction(onRefresh)
          ? await onRefresh({ start, end } as any)
          : [];
      }

      const monthDates = addSchedulesToMonthDates(schedules, dateInMonth);

      if (isFunction(onStateScheduleUpdate)) {
        await onStateScheduleUpdate({
          [toUtcDateInFormat(currentDate, DATE_SPECIFIC_FORMAT.STORE_DATE)]:
            monthDates,
        });
      }

      const currentMonthLastDate = getFromMonthFormatLastDate(monthDates);

      if (isThereContent(monthDates)) {
        dispatch({
          type: CALENDAR_ACTIONS.SET_MONTH_DATES,
          monthDates,
        });
      }

      dispatch({
        type: CALENDAR_ACTIONS.SET_LAST_MONTH_DATE,
        currentMonthLastDate,
      });
    },
    [addSchedulesToMonthDates, onStateScheduleUpdate, onRefresh],
  );

  const updateEventsDates = React.useCallback(
    async (
      {
        dateInMonth = calendarData?.monthDates,
        date,
        schedules,
        name,
      }: UpdateDates<T> = {} as UpdateDates<T>,
    ): Promise<void> => {
      const { start, end, currentDate } = getTimesOfDates(dateInMonth, date);

      if (
        isObject(schedules) &&
        (schedules as any)[
          toUtcDateInFormat(date, DATE_SPECIFIC_FORMAT.STORE_DATE)
        ]
      ) {
        dispatch({
          type: CALENDAR_ACTIONS.SET_MONTH_DATES,
          schedule: (schedules as any)[
            toUtcDateInFormat(date, DATE_SPECIFIC_FORMAT.STORE_DATE)
          ],
        } as any);
      } else {
        let isStart = true;

        if (typeof waitStartRefresh === 'boolean') {
          isStart = waitStartRefresh;
        }

        if (isStart) {
          await debounceOnBuildEvents(
            dateInMonth,
            start.format('YYYY-MM-DD'),
            end.format('YYYY-MM-DD'),
            currentDate.format('YYYY-MM-DD'),
            schedules,
            name,
          );
        }
      }
    },
    [calendarData?.monthDates, debounceOnBuildEvents, waitStartRefresh],
  );

  const handleUpdateDate = React.useCallback(
    (date: Date): void => {
      dispatch({
        type: CALENDAR_ACTIONS.SET_SELECTED_DATE,
        selectedDate: date,
      });
      if (!withoutStorageDate) {
        setCalendarDate(date);
      }
    },
    [setCalendarDate, withoutStorageDate],
  );

  const setDefaultMonthDates = React.useCallback(
    (defaultMonthDates: IMonthFormat[]): void => {
      dispatch({
        type: CALENDAR_ACTIONS.SET_DEFAULT_MONTH_DATES,
        defaultMonthDates,
      });
    },
    [],
  );

  const handleUpdateMonthDates = React.useCallback(
    async ({
      prevDateInMonth,
      date,
      forceUpdate = false,
      name,
    }: IHandleUpdateCalendar): Promise<void> => {
      const { start, end, currentDate } = getTimesOfDates(
        prevDateInMonth,
        date,
      );

      handleUpdateDate(date);

      if (
        forceUpdate ||
        currentDate.isSameOrBefore(start) ||
        currentDate.isSameOrAfter(end)
      ) {
        const updatedDateInMonth = getMonthDates(date, customDayTimeRange);

        dispatch({
          type: CALENDAR_ACTIONS.SET_MONTH_DATES,
          monthDates: updatedDateInMonth,
        });

        await updateEventsDates({
          dateInMonth: updatedDateInMonth,
          date,
          name: name!,
        });
      }
    },
    [handleUpdateDate, customDayTimeRange, updateEventsDates],
  );

  const handleUpdateCalendarMode = React.useCallback(
    (mode: any): void => {
      if (!withoutStorageMode) {
        setCalendarMode(mode);
        dispatch({ type: CALENDAR_ACTIONS.SET_MODE, mode });
      }
    },
    [setCalendarMode, withoutStorageMode],
  );

  const handleChooseDay = useCallback(
    ({ date }: ChooseDay): void => {
      handleUpdateCalendarMode(MODE.DAY);
      handleUpdateDate(date as Date);
      navigate(
        `${getUrlPathName(location.pathname)}${CALENDAR}/${
          MODE.DAY
        }/${toUtcDateInFormat(date)}`,
      );
    },
    [handleUpdateCalendarMode, handleUpdateDate, navigate, location.pathname],
  );

  const setDefaultScheduleItem = React.useCallback(
    (defaultScheduleItem: ScheduleCalendarModel) => {
      dispatch({
        type: CALENDAR_ACTIONS.SET_DEFAULT_SCHEDULE_ITEM,
        defaultScheduleItem,
      });
    },
    [],
  );

  useEffect(() => {
    didCancel.current = false;

    if (
      !isInitHourInDay.current &&
      timeRange &&
      customDayTimeRange !== null &&
      !didCancel.current
    ) {
      const newTimeRange = ifElse(
        !!customDayTimeRange,
        findMinMaxRange(customDayTimeRange),
        customDayTimeRange,
      );

      dispatch({
        type: CALENDAR_ACTIONS.SET_HOUR_IN_DAY,
        hourInDay: compose<ITimeInDay[]>(
          buildHourInDay,
          getMinMaxFromRanges(
            checkWorkSchedule(customDayTimeRange || ''),
            timeRange,
          ),
        )(newTimeRange),
      });

      isInitHourInDay.current = true;
    }

    return () => {
      didCancel.current = true;
    };
  }, [isInitHourInDay, timeRange, customDayTimeRange]);

  const updateMonthSchedules = useCallback(
    async (calendarSchedule: List<any> | undefined, name: string) => {
      const dateInMonth = ifElse(
        !!customDayTimeRange,
        getMonthDates(
          !withoutStorageDate
            ? calendarDate || moment().toDate()
            : moment(withoutStorageDate).toDate(),
          customDayTimeRange,
        ),
        calendarData?.monthDates,
      );

      await updateEventsDates({
        dateInMonth,
        date: !withoutStorageDate
          ? calendarDate || moment().toDate()
          : moment(withoutStorageDate).toDate(),
        schedules: calendarSchedule,
        name,
      });

      setDefaultMonthDates(dateInMonth);
    },
    [
      calendarData?.monthDates,
      calendarDate,
      customDayTimeRange,
      setDefaultMonthDates,
      updateEventsDates,
      withoutStorageDate,
    ],
  );

  useEffect(() => {
    if (
      calendarData?.schedules &&
      calendarSchedule &&
      calendarSchedule?.size !== calendarData?.schedules?.size
    ) {
      dispatch({
        type: CALENDAR_ACTIONS.SET_SCHEDULES,
        schedules: calendarSchedule as any,
      });

      (async () => {
        await updateMonthSchedules(calendarSchedule, 'schedules');
      })();
    }
  }, [calendarData, calendarSchedule, forceRefresh, updateMonthSchedules]);

  useEffect(() => {
    if (
      calendarSchedule &&
      !calendarData?.schedules &&
      !location.pathname.includes(`${CALENDAR}/${CALENDAR_ENTRY}`)
    ) {
      dispatch({
        type: CALENDAR_ACTIONS.SET_SCHEDULES,
        schedules: calendarSchedule as any,
      });
      dispatch({
        type: CALENDAR_ACTIONS.SET_LOADING,
        loading: !!loading,
      });

      (async () => {
        await updateMonthSchedules(calendarSchedule, 'empty');
      })();
    }
  }, [
    calendarData?.schedules,
    calendarSchedule,
    loading,
    location.pathname,
    updateMonthSchedules,
  ]);

  useEffect(() => {
    didCancel.current = false;

    if (
      !isAddEvents.current &&
      isThereContent(calendarData?.monthDates) &&
      customDayTimeRange !== null
    ) {
      isAddEvents.current = true;
      (async () => {
        await updateMonthSchedules(undefined, 'init');
      })();
    }

    return () => {
      didCancel.current = true;
    };
  }, [calendarData?.monthDates, customDayTimeRange, updateMonthSchedules]);

  return (
    <CalendarContext.Provider
      value={{
        ...calendarData,
        dispatch,
        handleUpdate: handleUpdateMonthDates,
        handleUpdateMode: handleUpdateCalendarMode,
        handleUpdateDate,
        handleChooseDay,
        SchedulePopover,
        scheduleDetails,
        addSchedulesToMonthDates,
        setDefaultScheduleItem,
        isForm,
        isEdit,
        limitedByDate,
      }}>
      {isAddEvents.current ? (
        <DndProvider backend={HTML5Backend}>
          <CalendarDndManager
            {...withDnDEventsProps}
            isClearDnDCache={!!isClearDnDCache}>
            <StyledContent>{children}</StyledContent>
          </CalendarDndManager>
        </DndProvider>
      ) : null}
    </CalendarContext.Provider>
  );
}
