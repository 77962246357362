import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { StoreDocumentFormDTO, StoreDocumentModel } from '@structure';
import { useDropdownAlert } from '@contex';
import {
  IUseStateStoredDocumentReturnType,
  useStateCompanyClientList,
  useStoredCompanies,
  useStoredStoreList,
  useDefaultPriceMargin,
} from '@hooks';
import { Form } from 'antd';
import { StoreRealizationFields } from './StoreRealizationFields';
import { useTheme } from 'styled-components';
import { encryptObject, isEqualByUuid, isFunction } from '@services/helpers';
import { SessionStorageItems } from '@services/const';
import { produce } from 'immer';
import { useWatch } from 'antd/es/form/Form';
import { StoreDocumentRefObject } from '../../helpers';

export interface IStoreRealizationFormProps
  extends Omit<
      IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
    >,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  onSuccess: (
    value: StoreDocumentFormDTO,
    closeble?: boolean,
  ) => Promise<StoreDocumentModel | void>;
  fromList: boolean;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StoreRealizationForm({
  loading,
  document,
  editMode,
  onSuccess,
  onCancel,
  handlePaymentStoreDocument,
  fromList,
  documentEditRef,
  ...rest
}: IStoreRealizationFormProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: document?.uuid,
    }),
    [document?.uuid],
  );

  const doc_extra_services = useWatch('doc_extra_services', instance);

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateLocalClient,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({ companyUuid: defaultCompanyUuid, limit: 50 });

  const {
    priceMarginList,
    loading: priceMarginListLoading,
    handleSearchPriceMargins,
    setDefaultPriceMargin,
  } = useDefaultPriceMargin({
    loadOnInit: true,
    instance,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store sales invoice')
            : t('An error occurred during create store sales invoice')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const saveStoreToSessionStorage = useCallback(
    (doc_store_uuid: string) => {
      const selectedStore = storeList?.find(isEqualByUuid(doc_store_uuid));

      if (selectedStore) {
        const store = {
          uuid: selectedStore?.uuid,
          store_name: selectedStore?.store_name,
        };

        const encryptStoreData = encryptObject(store);

        sessionStorage.setItem(
          SessionStorageItems.realizationFormStoreData,
          encryptStoreData,
        );
      }
    },
    [storeList],
  );

  const handleOnSuccess = useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.doc_extra_services = doc_extra_services;
          draft.doc_multi_currencies = undefined;

          if (!draft?.is_apply_doc_discount) {
            draft.doc_discount_value = undefined;
            draft.doc_discount_source = undefined;
            draft.doc_discount_type = undefined;
          }
        });

        await saveStoreToSessionStorage(value?.doc_store_uuid as string);

        await onSuccess(updatedValue);
      }
    },
    [doc_extra_services, onSuccess, saveStoreToSessionStorage],
  );

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={['Tab']}
      isResetLoading
      withContext
      instance={instance}
      initialValues={document || ({} as StoreDocumentFormDTO)}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      showCancelButton={false}
      submitButtonText={t('Save as draft')}
      submitButtonProps={{ style: { backgroundColor: theme.colors.primary } }}
      onCancel={onCancel}
      {...rest}>
      <StoreRealizationFields
        editMode={!!editMode}
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleCreateLocalClient={handleCreateLocalClient}
        handleSearchClients={handleSearchClients}
        storeList={storeList}
        storeListLoading={storeListLoading}
        handleSearchStores={handleSearchStores}
        priceMarginList={priceMarginList}
        priceMarginListLoading={priceMarginListLoading}
        handleSearchPriceMargins={handleSearchPriceMargins}
        setDefaultPriceMargin={setDefaultPriceMargin}
        doc_extra_services={doc_extra_services}
        documentEditRef={documentEditRef}
      />
    </DefaultForm>
  );
}
