import { Dispatch } from 'redux';
import { SIGNUP_PAGE_STATE } from '../constants';
import { LocalStorageItems } from '@services/const';
import { encryptObject, decryptObject } from '@services/helpers';

export interface ISignupPageState {
  identifier: string | null;
  prevIdentifier: string | null;
  approveEnter: boolean | null;
  timeWhenCodeWasSend?: Date | null;
  loading: boolean;
  isShowWarning: boolean;
}

export const signupPageInitialState = {
  identifier: null,
  prevIdentifier: null,
  approveEnter: null,
  timeWhenCodeWasSend: null,
  loading: true,
  isShowWarning: true,
};

export interface ISetSignupPageStateParam {
  type: SIGNUP_PAGE_STATE;
}

export interface IUpdateSignupPageStateParam {
  type: SIGNUP_PAGE_STATE;
  state: Partial<ISignupPageState>;
}

export interface IResetSignupPageStateParam {
  type: SIGNUP_PAGE_STATE;
}

export function setSignupPageState(): (
  dispatch: Dispatch,
) => Promise<ISetSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    const cryptoState = localStorage.getItem(
      LocalStorageItems.verificationDataSignIn,
    );

    let state = {};

    if (cryptoState) {
      state = decryptObject(cryptoState);
    }

    return dispatch({
      type: SIGNUP_PAGE_STATE.SET_STATE,
      state: state ? { ...state, isShowWarning: true } : signupPageInitialState,
    });
  };
}

export function updateSignupPageState(
  state: Partial<ISignupPageState>,
): (dispatch: Dispatch) => Promise<IUpdateSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    const encryptSignupPrevState = localStorage.getItem(
      LocalStorageItems.verificationDataSignIn,
    );

    if (encryptSignupPrevState) {
      const signupPrevState = decryptObject(encryptSignupPrevState);
      if (signupPrevState) {
        const cryptoNewState = encryptObject({ ...signupPrevState, ...state });

        localStorage.setItem(
          LocalStorageItems.verificationDataSignIn,
          cryptoNewState,
        );
      }
    } else {
      const cryptoState = encryptObject(state);

      localStorage.setItem(
        LocalStorageItems.verificationDataSignIn,
        cryptoState,
      );
    }

    return dispatch({
      type: SIGNUP_PAGE_STATE.UPDATE_STATE,
      state,
    });
  };
}

export function resetSignupPageState(): (
  dispatch: Dispatch,
) => Promise<IResetSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    localStorage.removeItem(LocalStorageItems.verificationDataSignIn);

    return dispatch({
      type: SIGNUP_PAGE_STATE.RESET_STATE,
      state: signupPageInitialState,
    });
  };
}
