import * as React from 'react';
import { List } from 'immutable';
import {
  useCashBoxList,
  IUseCashBoxListProps,
  IUseCashBoxListReturnType,
} from './useCashBoxList';
import { CashBoxListModel, CashBoxModel } from '@structure';
import { deleteCashBox } from '@services/api/cashBox';
import {
  setCashBoxList as storeSetCashBoxList,
  deleteCashBoxFromList as storeDeleteCashBox,
  loadMoreCashBoxList as storeLoadMoreCashBoxList,
  updateCashBoxFromList as storeUpdateCashBoxFromList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateCashBoxListProps extends IUseCashBoxListProps {}

export interface ICashBoxSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateCashBoxListReturnType
  extends Omit<IUseCashBoxListReturnType, 'entityList'> {
  cashBoxList: List<CashBoxModel> | null;
  handleDeleteCashBoxes: (ids: string[]) => Promise<void>;
  handleSearchCashBoxes: (value: ICashBoxSearchProps) => Promise<any>;
  handleUpdateDefaultCashBox: (value: CashBoxModel) => void;
  handleLoadMoreCashBoxes: () => Promise<void>;
  handleRefreshCashBoxes: (
    value: Partial<IUseCashBoxListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  defaultCashBox: CashBoxModel | null;
  isLoadingMore: boolean;
}

export function useStoredCashBoxList(
  {
    companyUuid,
    loadOnInit,
    ...rest
  }: IUseStateCashBoxListProps = {} as IUseStateCashBoxListProps,
): IUseStateCashBoxListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [defaultCashBox, setDefaultCashBox] = useState<CashBoxModel | null>(
    null,
  );

  const {
    status: storedCashBoxListStatus,
    loading: storedCashBoxListLoading,
    cashBoxList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    ...storedCashBoxListParams
  } = useSelector(({ cashBoxList }: RootState) => cashBoxList);

  const size = useMemo(() => cashBoxList?.size || 0, [cashBoxList?.size]);
  const isLoadingMore = useMemo(() => size < storeTotal, [size, storeTotal]);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: cashBoxListLoading,
    total,
    ...cashBoxesParams
  } = useCashBoxList({
    companyUuid,
    loadOnInit:
      loadOnInit && storedCashBoxListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedCashBoxListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetCashBoxList(entityList, '', total));
    }
  }, [dispatch, entityList, storedCashBoxListStatus, total]);

  const handleDeleteCashBoxes = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteCashBox(ids);

        setTimeout(() => {
          dispatch(storeDeleteCashBox(head(ids)));
        }, 100);
        alert('success', t('Cash boxes'), t('Cash boxes delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Cash boxes'),
          `${t('An error occurred during delete cash boxes')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchCashBoxes = React.useCallback(
    async ({ limit = 10, keywords, showLoading }: ICashBoxSearchProps) => {
      setLoadingMore(true);
      const cashBoxListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (cashBoxListModel && List.isList(cashBoxListModel?.cashboxes)) {
        dispatch(
          storeSetCashBoxList(
            cashBoxListModel.cashboxes,
            keywords,
            cashBoxListModel.total,
          ),
        );
        setLoadingMore(false);
        return cashBoxListModel;
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreCashBoxes = useCallback(async () => {
    if (
      List.isList(cashBoxList) &&
      cashBoxList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const cashBoxListModel = await refresh({
        offset: cashBoxList?.size,
        limit: 10,
        showLoading: false,
      });

      if (cashBoxListModel && List.isList(cashBoxListModel?.cashboxes)) {
        dispatch(storeLoadMoreCashBoxList(cashBoxListModel?.cashboxes));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, cashBoxList, storeTotal]);

  const handleRefreshCashBoxes = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseCashBoxListProps> & { page: number }) => {
      const cashBoxListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (cashBoxListModel && List.isList(cashBoxListModel?.cashboxes)) {
        dispatch(
          storeSetCashBoxList(
            cashBoxListModel?.cashboxes,
            '',
            cashBoxListModel?.total,
            page,
          ),
        );
      }
    },
    [dispatch, refresh],
  );

  const handleUpdateDefaultCashBox = useCallback(
    (cashBox: CashBoxModel) => {
      setDefaultCashBox(cashBox);

      const updatedCashBox = cashBox.update(
        'box_is_default_for_company',
        () => true,
      );

      dispatch(storeUpdateCashBoxFromList(updatedCashBox));
    },
    [dispatch],
  );

  useEffect(() => {
    if (List.isList(cashBoxList) && cashBoxList?.size && !defaultCashBox) {
      const defaultBoxIndex = cashBoxList.findIndex(
        ({ box_is_default_for_company }) => box_is_default_for_company,
      );

      if (~defaultBoxIndex) {
        setDefaultCashBox(cashBoxList.get(defaultBoxIndex) || null);
      } else {
        setDefaultCashBox(cashBoxList?.first());
      }
    }
  }, [cashBoxList, defaultCashBox]);

  return {
    ...cashBoxesParams,
    ...storedCashBoxListParams,
    cashBoxList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(cashBoxList) && cashBoxListLoading) ||
      !storedCashBoxListLoading,
    handleDeleteCashBoxes,
    handleSearchCashBoxes,
    handleLoadMoreCashBoxes,
    status: storedCashBoxListStatus,
    handleRefreshCashBoxes,
    keywords: storeKeywords || '',
    page: storedPage || 1,
    defaultCashBox,
    handleUpdateDefaultCashBox,
    isLoadingMore,
  };
}
