import React, { useCallback, useMemo } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import {
  useStateCompanyClientList,
  useStateEmployeeList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '@hooks';
import { ScheduleCloseDTO } from '@structure';
import { CloseOrderFormFields } from './CloseOrderFormFields';

export interface ICloseOrderFormProps
  extends Pick<
    IDefaultFormProps<ScheduleCloseDTO, ScheduleCloseDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: ScheduleCloseDTO) => Promise<void>;
  onError?: () => Promise<void>;
  closeOrder: ScheduleCloseDTO;
  isHasStrictMode?: boolean;
}

/**
 * @desc Створенння співробітника
 * */
export function CloseOrderForm({
  loading,
  editMode,
  closeOrder,
  onCancel,
  isHasStrictMode,
  ...rest
}: ICloseOrderFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: closeOrder?.uuid,
    }),
    [closeOrder?.uuid],
  );

  const {
    employees,
    loading: employeeLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any): void => {
      alert(
        'error',
        t('Order close'),
        `${t('An error occurred during edit order close')} : ${
          apiError?.message
        }`,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm
      initialValues={{
        ...closeOrder,
      }}
      onCancel={onCancel}
      withContext
      submitButtonText={t('Close the order')}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      <CloseOrderFormFields
        loading={!!loading}
        employees={employees}
        employeesLoading={employeeLoading}
        handleSearchEmployees={handleSearchEmployees}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
        clients={clients}
        clientsLoading={clientsLoading}
        handleSearchClients={handleSearchClients}
      />
    </DefaultForm>
  );
}
