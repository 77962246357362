import * as React from 'react';
import { useCallback } from 'react';
import { Routes } from '@services/types';
import { ForgotPasswordFormDTO, ForgotPasswordMapper } from '@structure';
import { restore } from '@services/api/user';
import { useTranslation } from 'react-i18next';
import {
  IStoreVerificationFactoryReturnType,
  useStoreVerificationFactory,
} from './useStoreVerificationFactory';
import { IForgotPasswordPageState } from '@store/actions';
import { useDropdownAlert } from '@contex';
import { useNavigate } from 'react-router';

export interface IUseStateForgotPasswordReturnType
  extends IStoreVerificationFactoryReturnType<IForgotPasswordPageState> {
  handleForgotPassword: (value: ForgotPasswordFormDTO) => Promise<void>;
  notifyError: (error: any) => void;
  navigateToVerificationFormModal: (props?: {
    hiddenResendButton?: boolean;
  }) => void;
  loading: boolean;
}

export function useStateForgotPassword(): IUseStateForgotPasswordReturnType &
  IForgotPasswordPageState {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const navigate = useNavigate();

  const { updateStateFactory, ...verificationState } =
    useStoreVerificationFactory<IForgotPasswordPageState>({
      key: Routes.forgotPassword,
    });

  const navigateToVerificationFormModal = useCallback(
    (props?: { hiddenResendButton?: boolean }) => {
      navigate(`/${Routes.auth}/${Routes.verificationForm}`, {
        state: {
          verificationStateName: Routes.forgotPassword,
          hiddenResendButton: props?.hiddenResendButton,
        },
      });
    },
    [navigate],
  );

  /**
   * @desc Відновлення паролю
   * */
  const handleForgotPassword = React.useCallback(
    async (value: ForgotPasswordFormDTO): Promise<void> => {
      const forgotPasswordDTO = ForgotPasswordMapper.toForgotPasswordDTO(value);

      const { status, verification, message } = await restore(
        forgotPasswordDTO,
      );

      if (status) {
        updateStateFactory({
          verification,
          identifier: forgotPasswordDTO.identifier,
          approveEnter: null,
          timeWhenCodeWasSend: new Date(),
        });

        navigateToVerificationFormModal({ hiddenResendButton: true });

        alert('success', t('Forgot password'), message);
      }
    },
    [alert, t, updateStateFactory, navigateToVerificationFormModal],
  );

  const notifyError = React.useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Forgot password'),
        `${t('An error occurred during forgot password')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return {
    ...(verificationState as IStoreVerificationFactoryReturnType<IForgotPasswordPageState> &
      IForgotPasswordPageState),
    handleForgotPassword,
    notifyError,
    navigateToVerificationFormModal,
  };
}
