import React, { useCallback, useEffect, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useStopLoading,
  useStoredCompanies,
  useStoredIntegrationList,
} from '@hooks';
import { textToUpperCase } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import { List, Map } from 'immutable';
import { IntegrationGroupedList } from '../Show';
import { IntegrationCategoryModel, IntegrationModel } from '@structure';

export type GroupedIntegrationList = Map<
  IntegrationCategoryModel,
  List<IntegrationModel>
> | null;

export function IntegrationListPage(): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { t } = useTranslation();

  const [groupedIntegrationList, setGroupedIntegrationList] =
    useState<GroupedIntegrationList>(null);

  const {
    integrationList,
    loading: loadingIntegrationList,
    error: errorIntegrationList,

    handleAddOrUpdateIntegration,
    handleGetIntegration,
  } = useStoredIntegrationList();

  const loading = useStopLoading({
    loading: loadingIntegrationList,
    error: errorIntegrationList,
    message: 'An error occurred during integrations loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.integrations}`,
      breadcrumbName: RoutesTitle[Routes.integrations],
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  useEffect(() => {
    if (List.isList(integrationList) && !loading) {
      const grouped = integrationList.groupBy(
        (integration) => integration.categoryModel,
      );

      setGroupedIntegrationList(grouped);
    }
  }, [integrationList, loading]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.integrations]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.integrations]))}
      routes={routes}
      empty={
        integrationList?.size ? null : (
          <Empty description={t('There are no integrations found')} />
        )
      }
      loading={loading && !integrationList?.size}>
      <IntegrationGroupedList
        groupedIntegrationList={groupedIntegrationList}
        handleAddOrUpdateIntegration={handleAddOrUpdateIntegration}
        handleGetIntegration={handleGetIntegration}
      />
    </ListLayout>
  );
}
