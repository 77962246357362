import { List, Map } from 'immutable';
import { APP_STATE, FILE_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { FileModel } from '@structure';

interface IFileListState {
  fileList: List<FileModel> | null;
  cachedFileList: Map<string, List<FileModel>>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<IFileListState, 'fileList' | 'keywords' | 'total'> {
  type: FILE_LIST.SET_FILE_LIST;
  scheduleUuid: string;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: FILE_LIST.ADD_FILE;
  file: FileModel;
  scheduleUuid: string;
}

interface UpdateFileFromList {
  type: FILE_LIST.UPDATE_FILE;
  file: FileModel;
  scheduleUuid: string;
}

interface DeleteActionFromList {
  type: FILE_LIST.DELETE_FILE;
  fileUuid: string;
  scheduleUuid: string;
}

interface LoadingActionInList {
  type: FILE_LIST.LOADING_FILE_LIST;
}

interface ErrorActionInList extends Pick<IFileListState, 'error'> {
  type: FILE_LIST.ERROR_FILE_LIST;
}

interface Handlers {
  [FILE_LIST.SET_FILE_LIST]: (
    state: IFileListState,
    action: SetActionList,
  ) => IFileListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IFileListState,
    action: SetInitialStateAction,
  ) => IFileListState;

  [FILE_LIST.LOAD_MORE_FILE_LIST]: (
    state: IFileListState,
    action: SetActionList,
  ) => IFileListState;

  [FILE_LIST.ADD_FILE]: (
    state: IFileListState,
    action: AddActionToList,
  ) => IFileListState;

  [FILE_LIST.UPDATE_FILE]: (
    state: IFileListState,
    action: UpdateFileFromList,
  ) => IFileListState;

  [FILE_LIST.DELETE_FILE]: (
    state: IFileListState,
    action: DeleteActionFromList,
  ) => IFileListState;

  [FILE_LIST.ERROR_FILE_LIST]: (
    state: IFileListState,
    value: ErrorActionInList,
  ) => IFileListState;

  [FILE_LIST.LOADING_FILE_LIST]: (
    state: IFileListState,
    value?: LoadingActionInList,
  ) => IFileListState;
  DEFAULT: (state: IFileListState) => IFileListState;
}

const initState: IFileListState = {
  fileList: null,
  cachedFileList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [FILE_LIST.SET_FILE_LIST]: (
    state,
    { fileList, scheduleUuid, keywords, total },
  ) => ({
    ...state,
    ...{
      fileList,
      cachedFileList: fileList
        ? state.cachedFileList.set(scheduleUuid, fileList)
        : state.cachedFileList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [FILE_LIST.LOAD_MORE_FILE_LIST]: (state, { fileList, scheduleUuid }) => {
    const cacheMaterialListById = state.cachedFileList.get(scheduleUuid);

    return {
      ...state,
      ...{
        fileList:
          List.isList(state.fileList) && List.isList(fileList)
            ? state.fileList.merge(fileList)
            : state.fileList,
        cachedFileList: fileList
          ? state.cachedFileList.set(
              scheduleUuid,
              cacheMaterialListById
                ? fileList.merge(cacheMaterialListById)
                : fileList,
            )
          : state.cachedFileList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [FILE_LIST.ADD_FILE]: (state, { file, scheduleUuid }) => {
    const cacheMaterialListById = state.cachedFileList.get(scheduleUuid);

    return {
      ...state,
      ...{
        fileList: List.isList(state.fileList)
          ? state.fileList.unshift(file)
          : List([file]),
        cachedFileList: cacheMaterialListById
          ? state.cachedFileList.update(
              scheduleUuid,
              (fileList): List<FileModel> => {
                if (fileList) {
                  return fileList.push(file);
                } else {
                  return List([file]);
                }
              },
            )
          : Map({ [scheduleUuid]: List([file]) }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [FILE_LIST.UPDATE_FILE]: (state, { file, scheduleUuid }) => {
    const cacheMaterialListById = state.cachedFileList.get(scheduleUuid);

    return {
      ...state,
      ...{
        fileList: List.isList(state.fileList)
          ? state.fileList.map((stateFile) => {
              if (stateFile.uuid === file?.uuid) {
                return file;
              }
              return stateFile;
            })
          : List([file]),

        cachedFileList: cacheMaterialListById
          ? state.cachedFileList.update(scheduleUuid, (fileList) => {
              if (fileList) {
                return fileList.map((service) => {
                  if (service.uuid === file.uuid) {
                    return file;
                  } else {
                    return service;
                  }
                });
              } else {
                return List();
              }
            })
          : state.cachedFileList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [FILE_LIST.DELETE_FILE]: (state, { fileUuid, scheduleUuid }) => {
    const cacheMaterialListById = state.cachedFileList.get(scheduleUuid);

    return {
      ...state,
      ...{
        fileList: List.isList(state.fileList)
          ? state.fileList.filter(compose(not, isEqualByUuid(fileUuid)))
          : null,
        cachedFileList: cacheMaterialListById
          ? state.cachedFileList.update(scheduleUuid, (relatedScheduleList) => {
              if (relatedScheduleList) {
                return relatedScheduleList.filter(
                  compose(not, isEqualByUuid(fileUuid)),
                );
              } else {
                return List();
              }
            })
          : state.cachedFileList,
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [FILE_LIST.ERROR_FILE_LIST]: (
    state: IFileListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [FILE_LIST.LOADING_FILE_LIST]: (state: IFileListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IFileListState) => state,
};

export default function FileList(
  state: any = initState,
  action: any,
): IFileListState {
  const handler = handlers[action.type as FILE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
