import { DocumentControlPanel } from '@components/lib/General';
import { MenuProps } from 'antd';
import React, { Suspense, useCallback, useMemo } from 'react';
import { OverviewLayout } from '@components/lib/Layout';
import { useStoredCompanies, useRequiredFields, useStopLoading } from '@hooks';
import { REQUIRED_INITIAL_PARAM } from '@services/api/company';
import { useTranslation } from 'react-i18next';
import CompanyPictures from '../Show/CompanyPictures';
import { useAcl } from '@contex';
import CompanyTabPanel from '../Show/CompanyTabPanel';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';
import { CompanyMapper } from '@structure';
import { Routes, RoutesAcl } from '@services/types';
import { useNavigate, useParams } from 'react-router';
import {
  HeaderLeft,
  CommentField,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';

const CompanySideWindowLazy = React.lazy(
  () => import('../Show/CompanySideWindow'),
);

export default function CompanyPage(): JSX.Element {
  const { companyId: defaultCompanyUuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { manage } = useAcl(({ company }) => company);

  const {
    defaultCompany,
    defaultCompanyLoading,
    updateCompany,
    setCompanyLogo,
    setCompanyCover,
    handleUpdateCompanyComment,
    defaultCompanyError,
  } = useStoredCompanies({ companyUuid: defaultCompanyUuid });

  const loading = useStopLoading({
    loading: defaultCompanyLoading,
    error: defaultCompanyError,
    message: 'An error occurred during company loading',
  });

  const routes = useBreadcrumb([]);

  const {
    handleCancel: closeCompanyDialogHandler,
    handleOnInit: openEditDialogHandler,
    handleSuccess: updateCompanyDialogHandler,
    visible,
  } = useModal({
    onCancel: () => {},
    onSuccess: updateCompany,
  });

  const handleSetCompanyLogo = useCallback(
    async (file: File): Promise<string | void> =>
      await setCompanyLogo({ file, uuid: defaultCompanyUuid! }),
    [defaultCompanyUuid, setCompanyLogo],
  );

  const handleSetCompanyCover = useCallback(
    async (file: File): Promise<string | void> =>
      await setCompanyCover({ file, uuid: defaultCompanyUuid! }),
    [defaultCompanyUuid, setCompanyCover],
  );

  const overviewData = useRequiredFields(
    defaultCompany,
    REQUIRED_INITIAL_PARAM,
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.dashboard}`);
  }, [navigate]);

  const printOptions: MenuProps['items'] = [];
  const documentOptions: MenuProps['items'] = useMemo(() => [], []);

  return (
    <>
      <CompanyTabPanel
        timeRange={defaultCompany && defaultCompany?.work_schedules}>
        {({ tabs }) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.companies]}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={openEditDialogHandler}
                isDraft={true}
              />
            }
            disabled={!manage}
            loading={loading}
            data={overviewData}
            routes={routes}
            headerTitle={defaultCompany?.title}
            tabs={tabs}
            header={
              <>
                <CompanyPictures
                  disabled={!manage}
                  onUploadCoverFile={handleSetCompanyCover}
                  onUploadLogoFile={handleSetCompanyLogo}
                  logo={defaultCompany?.logo_url}
                  cover={defaultCompany?.cover_url}
                  qrCode={defaultCompany?.qr_code_url}
                  title={defaultCompany?.title}
                  invite={CompanyMapper.toCompanyInviteFormDTO(defaultCompany!)}
                />
                <CommentField
                  comment={defaultCompany?.comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateCompanyComment}
                  disabled={!manage}
                />
              </>
            }
          />
        )}
      </CompanyTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanySideWindowLazy
          editMode
          visible={visible}
          onCancel={closeCompanyDialogHandler}
          onSuccess={updateCompanyDialogHandler as any}
          company={CompanyMapper.toCompanyFormDTO(defaultCompany!)}
        />
      </Suspense>
    </>
  );
}
