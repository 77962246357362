import { List } from 'immutable';
import {
  SettingsModuleDTO,
  ISettingsModuleDTOProps,
  SettingsModuleModel,
  SettingsModuleListModel,
} from '../internal';
import { listToArray } from '@services/helpers';

export interface ISettingsModuleListDTO {
  settingsModules: SettingsModuleDTO[];
  total: number;
}

export class SettingsModuleMapper {
  public static toSettingsModuleDTO(
    settingsModule: SettingsModuleModel | ISettingsModuleDTOProps,
  ): SettingsModuleDTO {
    return new SettingsModuleDTO(settingsModule);
  }

  public static toSettingsModuleListDTO(
    settingsModules: List<SettingsModuleModel> | ISettingsModuleDTOProps[],
    total: number,
  ): ISettingsModuleListDTO {
    const settingsModulesList = List.isList(settingsModules)
      ? listToArray<SettingsModuleModel>(settingsModules)
      : settingsModules;

    return {
      settingsModules: settingsModulesList?.map((settingsModule) =>
        SettingsModuleMapper.toSettingsModuleDTO(settingsModule),
      ),
      total,
    };
  }

  public static toSettingsModuleModel(
    settingsModuleDTO: SettingsModuleDTO,
  ): SettingsModuleModel {
    return new SettingsModuleModel(settingsModuleDTO);
  }

  public static toSettingsModuleListModel(
    settingsModule: SettingsModuleDTO[],
    total: number,
  ): SettingsModuleListModel {
    return new SettingsModuleListModel({
      settings_modules: settingsModule,
      total,
    });
  }
}
