import React from 'react';
import { Routes, Route } from 'react-router';
import {
  PaymentListPage,
  PaymentPage,
  SchedulePaymentListPage,
  ClientPaymentListPage,
  StoreDocumentPaymentListPage,
} from '../views/Payments';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';

export function PaymentRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <PaymentListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.schedules}`}
        element={
          <AppRouteLayout>
            <SchedulePaymentListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.clients}`}
        element={
          <AppRouteLayout>
            <ClientPaymentListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.stores}`}
        element={
          <AppRouteLayout>
            <StoreDocumentPaymentListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path=":paymentId"
        element={
          <AppRouteLayout>
            <PaymentPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.schedules}/:paymentId`}
        element={
          <AppRouteLayout>
            <PaymentPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.clients}/:paymentId`}
        element={
          <AppRouteLayout>
            <PaymentPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesType.stores}/:paymentId`}
        element={
          <AppRouteLayout>
            <PaymentPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
