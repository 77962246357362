import { Text } from '../Format';
import styled, { css } from 'styled-components';

type Mode = 'light' | 'dark';

export const StyledTitle = styled(Text)<{ mode?: Mode; $color?: string }>`
  ${({ mode }) =>
    mode === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export const StyledDescription = styled(Text)<{
  mode?: Mode;
  $color?: string;
  fontSize?: number;
  $withoutEllipsis?: boolean;
}>`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ mode }) =>
    mode === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
  ${({ $withoutEllipsis }) =>
    $withoutEllipsis &&
    css`
      text-overflow: unset;
      overflow: visible;
      white-space: unset;
    `}
`;

export const StyledEllipsis = styled.div<{
  color?: string;
  align?: 'left' | 'right' | 'center';
}>`
  min-width: 0;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;
