import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Space } from 'antd';
import styled, { css } from 'styled-components';
import { Text } from '../Format';
import { LockOutlined } from '@ant-design/icons';
import { HEADER_HEIGHT } from './const';
import { PADDING_INDENT } from './ListLayout/ListContent';

export default withTranslation()(EmptyLayout);

export interface IEmptyLayoutProps extends WithTranslation {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  height?: number;
  headerHeight?: number;
}

const StyledEmptyContainer = styled.div<{
  $height?: number;
  $headerHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ $headerHeight }) =>
    `calc(100vh - ${$headerHeight || HEADER_HEIGHT}px - ${PADDING_INDENT}px)`};
  cursor: not-allowed;

  ${({ $height, $headerHeight }) =>
    $height &&
    css`
      height: calc(
        100vh - ${$headerHeight || HEADER_HEIGHT}px - ${PADDING_INDENT}px -
          ${$height}px
      );
    `}
`;

const StyledLockOutlined = styled(LockOutlined)`
  font-size: 50px;
  color: ${({ theme }) => theme.colors.lightDisable};
`;

const StyledText = styled(Text)`
  display: block;
  width: 100%;
  font-size: 50px;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightDisable};

  @media (max-width: 475px) {
    font-size: 25px;
  }
`;

function EmptyLayout({
  t,
  children,
  className,
  height,
  headerHeight,
}: IEmptyLayoutProps): React.JSX.Element {
  return (
    <StyledEmptyContainer
      className={className}
      $height={height}
      $headerHeight={headerHeight}>
      {children ? (
        children
      ) : (
        <Space align="center" direction="vertical">
          <StyledLockOutlined />
          <StyledText>{t('Access is denied')}</StyledText>
        </Space>
      )}
    </StyledEmptyContainer>
  );
}
