import {
  EEmployeeShiftStatus,
  IEmployeeShiftModelProps,
} from '@models/EmployeeShiftModel';

export interface IEmployeeShiftDTOProps extends IEmployeeShiftModelProps {}

export interface IEmployeeShiftDTOModel {
  uuid?: string;
  num?: string;
  comment?: string;
  openedAt?: Date;
  status?: EEmployeeShiftStatus;
}

export class EmployeeShiftDTO implements IEmployeeShiftDTOModel {
  constructor(protected props: IEmployeeShiftDTOProps) {}

  get uuid(): string | undefined {
    return this.props.uuid;
  }

  get comment(): string | undefined {
    return this.props.shift_comment;
  }

  get num(): string | undefined {
    return this.props.shift_number;
  }

  get openedAt(): Date | undefined {
    return this.props?.shift_open_date
      ? new Date(this.props.shift_open_date)
      : undefined;
  }

  get status(): EEmployeeShiftStatus | undefined {
    return this.props.shift_status;
  }
}
