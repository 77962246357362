import React from 'react';
import { Routes, Route, useLocation } from 'react-router';
import { AppRouteLayout, MainCalendarLayout } from '@components/lib/Layout';
import {
  CalendarDay,
  CalendarMonth,
  CalendarWeek,
  CalendarYear,
} from '@components/Calendar';
import OrderPage from '../views/Orders/Pages/OrderPage';
import { CalendarManager } from '@contex';
import { CALENDAR_ENTRY, CALENDAR } from '@components/lib/const';
import { getLastSplittingString } from '@services/helpers';
import { Routes as RoutesTypes } from '@services/types';
import { useRedirectToCalendar } from '@hooks';
import { PaymentRoutesNavigator } from './PaymentRoutesNavigator';

export interface ICalendarRoutesProps {
  path?: string;
  ignoreRedirectPath?: string[];
  ignoreRedirect?: boolean;
  CalendarComponent: any;
}

export function CalendarRoutes({
  path,
  ignoreRedirectPath,
  CalendarComponent,
  ignoreRedirect,
}: ICalendarRoutesProps) {
  const location = useLocation();

  const pathName = getLastSplittingString(location.pathname, '/');

  useRedirectToCalendar({
    path: path || RoutesTypes.dashboard,
    isChecked:
      !location.pathname.includes(CALENDAR_ENTRY) &&
      !ignoreRedirectPath?.includes(pathName),
    ignoreRedirect,
  });

  return (
    <Routes>
      <Route
        path={`${CALENDAR}/day/:year/:month/:day`}
        element={
          <AppRouteLayout>
            <CalendarComponent>
              {({ loading, ...props }: any) => (
                <CalendarManager {...props}>
                  <MainCalendarLayout loading={loading}>
                    <CalendarDay />
                  </MainCalendarLayout>
                </CalendarManager>
              )}
            </CalendarComponent>
          </AppRouteLayout>
        }
      />

      <Route
        path={`${CALENDAR}/week/:year/:month/:day`}
        element={
          <AppRouteLayout>
            <CalendarComponent>
              {({ loading, ...props }: any) => (
                <CalendarManager {...props}>
                  <MainCalendarLayout loading={loading}>
                    <CalendarWeek />
                  </MainCalendarLayout>
                </CalendarManager>
              )}
            </CalendarComponent>
          </AppRouteLayout>
        }
      />
      <Route
        path={`${CALENDAR}/month/:year/:month/:day`}
        element={
          <AppRouteLayout>
            <CalendarComponent>
              {({ loading, ...props }: any) => (
                <CalendarManager {...props}>
                  <MainCalendarLayout loading={loading}>
                    <CalendarMonth />
                  </MainCalendarLayout>
                </CalendarManager>
              )}
            </CalendarComponent>
          </AppRouteLayout>
        }
      />
      <Route
        path={`${CALENDAR}/year/:year/:month/:day`}
        element={
          <AppRouteLayout>
            <CalendarComponent>
              {({ loading, ...props }: any) => (
                <CalendarManager {...props}>
                  <MainCalendarLayout loading={loading}>
                    <CalendarYear />
                  </MainCalendarLayout>
                </CalendarManager>
              )}
            </CalendarComponent>
          </AppRouteLayout>
        }
      />

      <Route
        path={`${CALENDAR}/${CALENDAR_ENTRY}/:scheduleId`}
        element={
          <AppRouteLayout>
            <CalendarComponent>
              {(props: any) => (
                <CalendarManager {...props}>
                  <MainCalendarLayout>
                    <OrderPage />
                  </MainCalendarLayout>
                </CalendarManager>
              )}
            </CalendarComponent>
          </AppRouteLayout>
        }
      />

      <Route
        path={`${CALENDAR}/${CALENDAR_ENTRY}/:scheduleId/${RoutesTypes.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />

      <Route
        path={`*`}
        element={
          <AppRouteLayout>
            <CalendarComponent>{() => <></>}</CalendarComponent>
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
