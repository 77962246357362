import { List, Map } from 'immutable';
import { APP_STATE, EMPLOYEE_SERVICE_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  RewardType,
  ServiceListModel,
  ServiceMapper,
  ServiceModel,
} from '@structure';

interface IEmployeeServiceListState {
  employeeServiceList: List<ServiceModel> | null;
  cachedEmployeeServiceList: Map<string, ServiceListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<IEmployeeServiceListState, 'keywords' | 'total'> {
  type: EMPLOYEE_SERVICE_LIST.SET_EMPLOYEE_SERVICE_LIST;
  employeeUuid: string;
  employeeServiceList: ServiceListModel;
  keywords?: string;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type:
    | EMPLOYEE_SERVICE_LIST.ADD_EMPLOYEE_SERVICE
    | EMPLOYEE_SERVICE_LIST.ADD_SERVICE_TO_EMPLOYEE;
  employeeService: ServiceModel;
  employeeUuid: string;
}

interface UpdateEmployeeServiceFromList {
  type: EMPLOYEE_SERVICE_LIST.UPDATE_EMPLOYEE_SERVICE;

  employeeService: ServiceModel;
  employeeUuid: string;
}

interface DeleteActionFromList {
  type:
    | EMPLOYEE_SERVICE_LIST.DELETE_EMPLOYEE_SERVICE
    | EMPLOYEE_SERVICE_LIST.DELETE_SERVICE_FROM_EMPLOYEE;
  employeeServiceUuid: string;
  employeeUuid: string;
}

interface LoadingActionInList {
  type: EMPLOYEE_SERVICE_LIST.LOADING_EMPLOYEE_SERVICE_LIST;
}

interface ErrorActionInList extends Pick<IEmployeeServiceListState, 'error'> {
  type: EMPLOYEE_SERVICE_LIST.ERROR_EMPLOYEE_SERVICE_LIST;
}

interface Handlers {
  [EMPLOYEE_SERVICE_LIST.SET_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
    action: SetActionList,
  ) => IEmployeeServiceListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IEmployeeServiceListState,
    action: SetInitialStateAction,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.LOAD_MORE_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
    action: SetActionList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.ADD_EMPLOYEE_SERVICE]: (
    state: IEmployeeServiceListState,
    action: AddActionToList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.ADD_SERVICE_TO_EMPLOYEE]: (
    state: IEmployeeServiceListState,
    action: UpdateEmployeeServiceFromList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.UPDATE_EMPLOYEE_SERVICE]: (
    state: IEmployeeServiceListState,
    action: UpdateEmployeeServiceFromList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.UPDATE_SERVICE_FOR_EMPLOYEE]: (
    state: IEmployeeServiceListState,
    action: UpdateEmployeeServiceFromList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.DELETE_EMPLOYEE_SERVICE]: (
    state: IEmployeeServiceListState,
    action: DeleteActionFromList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.DELETE_SERVICE_FROM_EMPLOYEE]: (
    state: IEmployeeServiceListState,
    action: DeleteActionFromList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.ERROR_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
    value: ErrorActionInList,
  ) => IEmployeeServiceListState;

  [EMPLOYEE_SERVICE_LIST.LOADING_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
    value?: LoadingActionInList,
  ) => IEmployeeServiceListState;
  DEFAULT: (state: IEmployeeServiceListState) => IEmployeeServiceListState;
}

const initState: IEmployeeServiceListState = {
  employeeServiceList: null,
  cachedEmployeeServiceList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [EMPLOYEE_SERVICE_LIST.SET_EMPLOYEE_SERVICE_LIST]: (
    state,
    { employeeServiceList, employeeUuid, keywords, page },
  ) => {
    const updatedList = employeeServiceList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1);

    return {
      ...state,
      ...{
        employeeServiceList: employeeServiceList?.services || null,
        cachedEmployeeServiceList: employeeServiceList
          ? state.cachedEmployeeServiceList.set(employeeUuid, updatedList)
          : state.cachedEmployeeServiceList,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total:
          employeeServiceList?.total! >= 0
            ? employeeServiceList?.total!
            : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [EMPLOYEE_SERVICE_LIST.LOAD_MORE_EMPLOYEE_SERVICE_LIST]: (
    state,
    { employeeServiceList, employeeUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedEmployeeServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeServiceList:
          List.isList(state.employeeServiceList) &&
          List.isList(employeeServiceList)
            ? state.employeeServiceList.merge(employeeServiceList.services)
            : state.employeeServiceList,
        cachedEmployeeServiceList: employeeServiceList
          ? state.cachedEmployeeServiceList.set(
              employeeUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('services', (services) =>
                    services.merge(employeeServiceList.services),
                  )
                : employeeServiceList,
            )
          : state.cachedEmployeeServiceList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.ADD_EMPLOYEE_SERVICE]: (
    state,
    { employeeService, employeeUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedEmployeeServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeServiceList: List.isList(state.employeeServiceList)
          ? state.employeeServiceList.unshift(employeeService)
          : List([employeeService]),
        cachedEmployeeServiceList: relaterScheduleListById
          ? state.cachedEmployeeServiceList.update(
              employeeUuid,
              (employeeServiceList) => {
                if (employeeServiceList) {
                  return employeeServiceList.update('services', (services) => {
                    return services.push(employeeService);
                  });
                } else {
                  return ServiceMapper.toServiceListModel(
                    [employeeService] as any,
                    1,
                  );
                }
              },
            )
          : Map({
              [employeeUuid]: ServiceMapper.toServiceListModel(
                [employeeService] as any,
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.ADD_SERVICE_TO_EMPLOYEE]: (
    state,
    { employeeService },
  ) => {
    return {
      ...state,
      ...{
        employeeServiceList: List.isList(state.employeeServiceList)
          ? state.employeeServiceList.unshift(employeeService)
          : List([employeeService]),
        cachedEmployeeServiceList: state.cachedEmployeeServiceList.map((list) =>
          list.update('services', (services) =>
            services.unshift(employeeService),
          ),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.UPDATE_EMPLOYEE_SERVICE]: (
    state: IEmployeeServiceListState,
    { employeeService, employeeUuid }: UpdateEmployeeServiceFromList,
  ) => {
    const relaterScheduleListById =
      state.cachedEmployeeServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeServiceList: List.isList(state.employeeServiceList)
          ? state.employeeServiceList.map((stateEmployeeService) => {
              if (stateEmployeeService.uuid === employeeService?.uuid) {
                return stateEmployeeService.merge(employeeService);
              }
              return stateEmployeeService;
            })
          : List([employeeService]),

        cachedEmployeeServiceList: relaterScheduleListById
          ? state.cachedEmployeeServiceList.update(
              employeeUuid,
              (employeeServiceList) => {
                if (employeeServiceList) {
                  return employeeServiceList.update('services', (services) =>
                    services.map((service) => {
                      if (service.uuid === employeeService.uuid) {
                        return service.merge(employeeService);
                      } else {
                        return service;
                      }
                    }),
                  );
                } else {
                  return ServiceMapper.toServiceListModel([], 0);
                }
              },
            )
          : state.cachedEmployeeServiceList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.UPDATE_SERVICE_FOR_EMPLOYEE]: (
    state: IEmployeeServiceListState,
    { employeeService }: UpdateEmployeeServiceFromList,
  ) => {
    return {
      ...state,
      ...{
        cachedEmployeeServiceList: state.cachedEmployeeServiceList.map(
          (services, key) =>
            services.update('services', (list) => {
              const index = list.findIndex(
                ({ uuid }) => uuid === employeeService?.uuid,
              );

              if (~index) {
                return list.update(index, (service) => {
                  if (service) {
                    if (
                      service?.manager?.uuid &&
                      employeeService?.manager?.uuid &&
                      service?.manager?.uuid !== employeeService?.manager?.uuid
                    ) {
                      if (key === employeeService?.manager?.uuid) {
                        return employeeService.update(
                          'employee_service',
                          () =>
                            ({
                              price: employeeService.price,
                              duration: employeeService?.period_amount,
                              reward_value: 0,
                              reward_type:
                                employeeService?.employee_service
                                  ?.reward_type || RewardType.Percentage,
                              updated_at: new Date().toString(),
                            } as any),
                        );
                      }

                      return employeeService;
                    }

                    return employeeService.update(
                      'employee_service',
                      () => service.employee_service,
                    );
                  }

                  return employeeService;
                });
              }

              return list;
            }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.DELETE_SERVICE_FROM_EMPLOYEE]: (
    state,
    { employeeServiceUuid, employeeUuid },
  ) => {
    const employeeServiceListById =
      state.cachedEmployeeServiceList.get(employeeUuid);

    return {
      ...state,
      ...{
        employeeServiceList: List.isList(state.employeeServiceList)
          ? state.employeeServiceList.filter(
              compose(not, isEqualByUuid(employeeServiceUuid)),
            )
          : null,
        cachedEmployeeServiceList: employeeServiceListById
          ? state.cachedEmployeeServiceList.update(
              employeeUuid,
              (employeeScheduleList) => {
                if (employeeScheduleList) {
                  return employeeScheduleList.update('services', (services) =>
                    services.filter(compose(not, isEqualByUuid(employeeUuid))),
                  );
                } else {
                  return ServiceMapper.toServiceListModel([], 0);
                }
              },
            )
          : state.cachedEmployeeServiceList,
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.DELETE_EMPLOYEE_SERVICE]: (
    state,
    { employeeServiceUuid },
  ) => {
    return {
      ...state,
      ...{
        employeeServiceList: List.isList(state.employeeServiceList)
          ? state.employeeServiceList.filter(
              compose(not, isEqualByUuid(employeeServiceUuid)),
            )
          : null,
        cachedEmployeeServiceList: state.cachedEmployeeServiceList.map(
          (services) =>
            services.update('services', (list) => {
              const index = list.findIndex(
                ({ uuid }) => uuid === employeeServiceUuid,
              );

              if (~index) {
                return list.delete(index);
              }

              return list;
            }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [EMPLOYEE_SERVICE_LIST.ERROR_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [EMPLOYEE_SERVICE_LIST.LOADING_EMPLOYEE_SERVICE_LIST]: (
    state: IEmployeeServiceListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IEmployeeServiceListState) => state,
};

export default function EmployeeServiceList(
  state: any = initState,
  action: any,
): IEmployeeServiceListState {
  const handler =
    handlers[action.type as EMPLOYEE_SERVICE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
