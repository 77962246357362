import { Space } from 'antd';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FaMoneyBill1Wave } from 'react-icons/fa6';
import { LoadingOutlined } from '@ant-design/icons';
import { tableScreen } from '@services/const';
import styled, { css, useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { Button } from '@components/lib/DataDisplay';
import { OverviewBottomView } from '@components/lib/Layout';
import { correctPrice, isFunction } from '@services/helpers';
import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { StoreRealizationDocumentBottomPriceView } from '../Realization';
import { StoreDocumentStatus, StoreDocumentType } from '@structure';

import {
  IUseStateStoredDocumentReturnType,
  useSize,
  useStoredCompanies,
} from '@hooks';

export interface IStoreDocumentBottomViewProps
  extends Pick<IUseStateStoredDocumentReturnType, 'document'> {
  buttons?:
    | React.ReactNode
    | React.ReactNode[]
    | ((value: {
        isPhoneScreen: boolean;
      }) => React.ReactNode | React.ReactNode[]);
  handlePaymentStoreDocument: () => void | Promise<void>;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPaidContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  height: fit-content;
  min-width: 44px;

  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    `}
  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({ theme }) => theme.colors.black};
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledRelatedButton = styled(StyledButton)`
  width: 44px;
  height: 44px;
`;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: flex-start;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 5px;
`;

const StyledConductButton = styled(Button)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 5px;

  width: fit-content;
  min-width: 44px;
  min-height: 44px;

  ${({ disabled }) =>
    disabled &&
    css`
      & > button {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 5px;

        width: fit-content;
        min-width: 44px;
        min-height: 44px;
      }
    `}
`;

export const StoreDocumentBottomView = forwardRef(
  function StoreDocumentBottomView(
    {
      document,
      buttons,
      handlePaymentStoreDocument: onPaymentStoreDocument,
    }: IStoreDocumentBottomViewProps,
    ref: any,
  ): JSX.Element {
    const { t } = useTranslation();
    const { defaultCompany } = useStoredCompanies();
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const { width: contentWidth } = useSize(contentRef);
    const theme: any = useTheme();

    const [loadingClose, setLoadingClose] = useState(false);

    const paid = Number(document?.doc_sum_paid || 0);
    const total = Number(document?.doc_sum_total || 0);

    const isPaid = paid > 0 && paid >= total;

    const handlePaymentStoreDocument = useCallback(async () => {
      setLoadingClose(true);
      if (isFunction(onPaymentStoreDocument)) {
        await onPaymentStoreDocument();
      }
      setLoadingClose(false);
    }, [onPaymentStoreDocument]);

    return (
      <div ref={ref}>
        <OverviewBottomView ref={contentRef}>
          <StyledSpace direction="horizontal" size="middle">
            {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              <StyledConductButton
                disabled={!total}
                type="primary"
                onClick={handlePaymentStoreDocument}>
                {loadingClose ? (
                  <LoadingOutlined />
                ) : (
                  <FaMoneyBill1Wave size={16} />
                )}

                {contentWidth >= tableScreen ? (
                  <StyledTitle fontSize={14}>{t('Conduct')}</StyledTitle>
                ) : null}
              </StyledConductButton>
            ) : null}

            {isFunction(buttons)
              ? buttons({ isPhoneScreen: contentWidth >= tableScreen })
              : buttons || null}
          </StyledSpace>
          {document?.doc_type === StoreDocumentType.OUT ? (
            <StoreRealizationDocumentBottomPriceView document={document} />
          ) : document?.doc_is_paymentable ? (
            <StyledPriceContainer>
              <StyledTitle fontSize={14}>{`${t('Paid')}: `}</StyledTitle>
              {isPaid ? (
                <StyledPrice $color={theme.colors.success}>
                  {`${defaultCompany?.currency_symbol} ${correctPrice(total)}`}
                </StyledPrice>
              ) : (
                <StyledPaidContainer>
                  <StyledPrice>
                    {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                      paid,
                    )}`}
                  </StyledPrice>
                  <Title>{`${t('with')} ${correctPrice(total)}`}</Title>
                </StyledPaidContainer>
              )}
              {paid === 0 ? null : paid < total ? (
                <StyledTitle $color={theme.colors.success}>
                  {`${t('Balance due').toLowerCase()} ${
                    defaultCompany?.currency_symbol || ''
                  }, ${correctPrice(total - paid)}`}
                </StyledTitle>
              ) : paid > total ? (
                <StyledTitle $color={theme.colors.error}>
                  {`${t('amount to be refunded')} ${
                    defaultCompany?.currency_symbol || ''
                  }, ${correctPrice(total - paid)}`}
                </StyledTitle>
              ) : null}
            </StyledPriceContainer>
          ) : null}
        </OverviewBottomView>
      </div>
    );
  },
);
