import * as React from 'react';
import {
  AuthDataDTO,
  CompanyMapper,
  CompanySettingsDTO,
  EmployeeRole,
  ICompanySettingsDTOProps,
  ProfileModel,
} from '@structure';
import {
  initAuthData as storeInitAuthData,
  resetAuthData as storeResetAuthData,
  setAuthData as storeSetAuthData,
  setAuthTokens as storeSetAuthTokens,
  resetEmployeeSignupPageState as storeResetEmployeeSignupScreenState,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import {
  ErrorsStatus,
  ILiteralObj,
  REDUX_STATUS,
  Routes,
} from '@services/types';
import { decryptObject, isActivePlan, isThereContent } from '@services/helpers';
import { tokenHandler } from '@services/core/token';
import { getCompanySettings } from '@services/api/setting';
import { useTranslation } from 'react-i18next';
import useStoredProfile from './useStoredProfile';
import { useUser } from './useUser';
import { StatusError } from '@components/lib/Errors';
import { useNavigate } from 'react-router';
import useStoredCompanies from './useStoredCompanies';
import { useDropdownAlert } from '@contex';
import { LocalStorageItems } from '@services/const';

export interface IUseStoredAuthDataReturnType {
  authData: AuthDataDTO | null;
  access_token: string | null;
  refresh_token: string | null;
  authRedirect: (
    data: AuthDataDTO,
    state?: ILiteralObj,
    errorStatus?: ErrorsStatus,
  ) => Promise<ProfileModel | void>;
  redirectToApp: () => Promise<void>;
  updateAuthData: (data: Partial<AuthDataDTO>) => void;
  resetAuthData: () => void;
  setAuthTokens: (
    access: string,
    refresh: string,
  ) => { access_token: string; refresh_token: string } | void;
  handleStoreProfile: (access_token: string) => Promise<ProfileModel | void>;
  updateProfile: (profile: ProfileModel, settings: CompanySettingsDTO) => void;
}

export function useStoredAuthData(): IUseStoredAuthDataReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const navigate = useNavigate();

  const { refresh: refreshProfile } = useUser({ loadingOnInit: false });
  const { storeProfile, profile: storedProfile } = useStoredProfile();
  const { storeCompanies } = useStoredCompanies({ companyUuid: '' });

  const { authData, access_token, refresh_token, status } = useSelector(
    ({ authData }: RootState) => authData,
  );

  const dispatch = useDispatch<any>();

  const updateAuthData = React.useCallback(
    (data: Partial<AuthDataDTO> | null) => {
      dispatch(storeSetAuthData(data as AuthDataDTO));
    },
    [dispatch],
  );

  const updateProfile = React.useCallback(
    (profileModel: ProfileModel, settings: CompanySettingsDTO) => {
      storeProfile(profileModel);
      storeCompanies({
        companies: profileModel?.companyListModel,
        defaultCompany: profileModel?.defaultCompanyModel,
        settings,
      });
    },
    [storeCompanies, storeProfile],
  );

  const handleStoreProfile = React.useCallback(
    async (access: string): Promise<any> => {
      if (!isThereContent(storedProfile) && (access || access_token)) {
        const profileModel = await refreshProfile(
          false,
          access || access_token || '',
        );

        if (profileModel instanceof ProfileModel) {
          let settings = CompanyMapper.toCompanySettingsDTO(
            {} as ICompanySettingsDTOProps,
          );

          if (
            profileModel?.defaultCompanyModel?.uuid &&
            profileModel?.defaultEmployeeModel?.role ===
              EmployeeRole.Administrator
          ) {
            settings = await getCompanySettings(
              profileModel?.defaultCompanyModel?.uuid || '',
              access || access_token || '',
            );
          }

          updateProfile(profileModel, settings);
          return profileModel;
        }
        if ((profileModel as any)?.status && (profileModel as any)?.message) {
          throw new StatusError(
            (profileModel as any)?.message,
            (profileModel as any)?.status,
          );
        }
        return profileModel;
      }

      return storedProfile as ProfileModel;
    },
    [storedProfile, access_token, refreshProfile, updateProfile],
  );

  const setAuthTokens = React.useCallback(
    (
      access: string,
      refresh: string,
    ): { access_token: string; refresh_token: string } | void => {
      if (access && refresh) {
        dispatch(storeSetAuthTokens(access, refresh));
        return { access_token: access, refresh_token: refresh };
      } else {
        if (access_token && refresh_token) {
          return { access_token, refresh_token };
        }
      }
    },
    [access_token, dispatch, refresh_token],
  );

  const resetAuthData = React.useCallback(() => {
    dispatch(storeResetAuthData());
  }, [dispatch]);

  const successNavigationToApp = React.useCallback(
    async (data: AuthDataDTO) => {
      const refresh = data?.refresh_token || refresh_token;
      const access = data?.access_token || access_token;

      if (refresh && access) {
        isActivePlan(data?.user?.plans);
        await tokenHandler.setAuthTokens({
          refresh,
          access,
          is_verify_email: String(data?.user?.status),
        });

        tokenHandler.setRestoreFromCookiesStatus('1');
        navigate(`/${Routes.app}`);
      }
    },
    [access_token, navigate, refresh_token],
  );

  const redirectToApp = React.useCallback(async () => {
    try {
      if (!isThereContent(storedProfile) && access_token) {
        await handleStoreProfile(access_token);

        if (authData) {
          await successNavigationToApp(authData);
        }
        return;
      }

      if (authData && isThereContent(storedProfile)) {
        await successNavigationToApp(authData);
      }
    } catch (e: any) {
      alert(
        'error',
        t('Profile'),
        t(`An error occurred during get profile: ${e?.message}`),
      );
    }
  }, [
    storedProfile,
    access_token,
    authData,
    handleStoreProfile,
    successNavigationToApp,
    alert,
    t,
  ]);

  const authRedirect = React.useCallback(
    async (
      data: AuthDataDTO,
      locationState?: ILiteralObj,
      errorStatus?: ErrorsStatus,
    ) => {
      const tokens = await setAuthTokens(
        data?.access_token,
        data?.refresh_token,
      );

      updateAuthData({ ...data, ...(tokens || {}) });

      const profile = await handleStoreProfile(
        tokens?.access_token || data?.access_token,
      );

      if (data?.success) {
        /**
         * @desc Перевірка статусу користувача для навігації на скрін (Routes.ApproveEnterScreen):
         * 1. Реєстрація співробітника (Routes.EmployeeSignupScreen) - перевірка відбувається
         * після підтвердження коду зі скріну Routes.VerificationFormModal
         * 2. Авторизація (Routes.LoginScreen) - перевірка відбувається після авторизації
         * */
        if (profile?.status === -9) {
          let state: any = {
            identifier: data?.user?.phone,
            loadingOnInit: false,
          };

          if (locationState) {
            state = { ...state, ...locationState };
          }

          navigate(`/${Routes.auth}/${Routes.approveEnter}`, state);

          return profile;
        }

        const serializeEmployeeAuthData = await localStorage.getItem(
          LocalStorageItems.employeeAuthData,
        );

        if (serializeEmployeeAuthData) {
          const employeeAuthData: any = decryptObject(
            serializeEmployeeAuthData,
          );

          if (`+${employeeAuthData?.identifier}` === data?.user?.identifier) {
            dispatch(storeResetEmployeeSignupScreenState());

            await localStorage.removeItem(LocalStorageItems.employeeAuthData);
          }
        }

        await localStorage.removeItem(LocalStorageItems.signupTokens);

        /**
         * @desc Перевірка верифікаційного коду для навігації на скрін
         * відновлення пароля (Routes.ResetPasswordScreen) - перевірка відбувається
         * після підтвердження коду зі скріну Routes.VerificationFormModal
         * */

        if (data?.verification_code) {
          navigate(`/${Routes.auth}/${Routes.resetPassword}`);

          return;
        }

        /**
         * @desc
         * */

        if (!data?.user?.terms_accepted) {
          navigate(`/${Routes.auth}/${Routes.termsPolicy}`);

          return;
        }

        if (profile) {
          await successNavigationToApp(data);
        }
      }
    },
    [
      dispatch,
      handleStoreProfile,
      navigate,
      setAuthTokens,
      successNavigationToApp,
      updateAuthData,
    ],
  );

  React.useEffect(() => {
    if (status === REDUX_STATUS.IDLE) {
      dispatch(storeInitAuthData());
    }
  }, [dispatch, status]);

  return {
    authData,
    access_token,
    refresh_token,
    resetAuthData,
    authRedirect,
    updateAuthData,
    redirectToApp,
    setAuthTokens,
    handleStoreProfile,
    updateProfile,
  };
}
