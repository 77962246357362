import { Routes } from '@services/types';

export const cdnUrl = 'https://projs-static.herokuapp.com/';

export const CALENDAR_ENTRY = 'entry';
export const DASHBOARD = Routes.dashboard;
export const CLIENTS = `${Routes.clients}/show`;
export const EMPLOYEES = Routes.employees;
export const CALENDAR = 'calendar';

export const TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_RANGE = '8:00-23:00';
export const DEFAULT_CALENDAR_TIME_RANGE = { start: 8, end: 22 };
export const WEEKDAY_TIME_RANGE = {
  start: '8:00',
  end: '22:00',
  isHaveRange: false,
};

export const CALENDAR_ROW_HEIGHT = 76;
export const CALENDAR_ROW_HEIGHT_MINUTE = 76 / 60;

export const ADMIN_ROUTES = ['users', 'categories', 'gallery'];
