import * as React from 'react';
import {
  useCashBox,
  IUseCashBoxProps,
  IUseCashBoxReturnType,
} from './useCashBox';
import {
  CashBoxFormDTO,
  CashBoxModel,
  CashBoxMapper,
  CashBoxAdjustBalancesFormDTO,
  CashBoxMovingFormDTO,
} from '@structure';
import {
  adjustBalancesCashBox,
  createCashBox,
  editCashBox,
  movingCashBox,
} from '@services/api/cashBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useEffect } from 'react';
import {
  addCashBoxToList as storeAddCashBox,
  setCashBox as storeSetCashBox,
  updateCashBox as storeUpdateCashBox,
  updateCashBoxFromList as storeUpdateCashBoxFromList,
  resetCashBox as storeResetCashBox,
} from '@store/actions';
import { correctPrice, toStringDate } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { IUseStateCashBoxListReturnType } from './useStoredCashBoxList';
import { isFunction } from '@sportix/sportix-common-modules';
import { List } from 'immutable';

export interface IUseStateCashBoxProps
  extends IUseCashBoxProps,
    Partial<
      Pick<
        IUseStateCashBoxListReturnType,
        'handleUpdateDefaultCashBox' | 'cashBoxList'
      >
    > {
  companyUuid: string;
}

export interface IUseStateCashBoxReturnType
  extends Omit<IUseCashBoxReturnType, 'entity'> {
  cashBox: CashBoxModel | null;
  handleUpdateCashBox: (value: CashBoxFormDTO) => Promise<void>;
  handleCreateCashBox: (value: CashBoxFormDTO) => Promise<void>;
  handleMovingCashBox: (value: CashBoxMovingFormDTO) => Promise<void>;
  handleAdjustBalancesCashBox: (
    value: CashBoxAdjustBalancesFormDTO,
  ) => Promise<void>;
  handleRefreshCashBox: () => Promise<void>;
  handleResetCashBox: () => void;
  status: REDUX_STATUS;
}

export function useStoredCashBox({
  companyUuid,
  loadOnInit,
  cashBoxUuid,
  handleUpdateDefaultCashBox,
  cashBoxList,
  ...rest
}: IUseStateCashBoxProps): IUseStateCashBoxReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [cashBox, setCashBox] = React.useState<CashBoxModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedCashBoxStatus,
    cachedCashBoxList,
    ...storedCashBoxParams
  } = useSelector(({ cashBox }: RootState) => cashBox);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...cashBoxParams } = useCashBox({
    loadOnInit: loadOnInit || isStarLoading,
    cashBoxUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedCashBoxList?.size > 0) {
      const cashBox = cachedCashBoxList.get(cashBoxUuid);

      if (cashBox) {
        setIsStartLoading(false);
        setCashBox(cashBox);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedCashBoxList?.size === 0 && !cashBox) {
      setIsStartLoading(true);
    }
  }, [cachedCashBoxList, dispatch, cashBoxUuid, cashBox]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetCashBox(entity));
      setCashBox(entity);
    }
  }, [dispatch, entity, isStarLoading, storedCashBoxStatus]);

  const handleUpdateCashBox = React.useCallback(
    async (value: CashBoxFormDTO): Promise<void> => {
      const cashBoxDTO = await editCashBox(value);

      const cashBoxModel = CashBoxMapper.toCashBoxModel(cashBoxDTO);

      dispatch(storeUpdateCashBox(cashBoxModel));
      dispatch(storeUpdateCashBoxFromList(cashBoxModel));

      setCashBox(cashBoxModel);

      if (
        isFunction(handleUpdateDefaultCashBox) &&
        cashBoxModel?.box_is_default_for_company
      ) {
        handleUpdateDefaultCashBox(cashBoxModel);
      }

      alert('success', t('Cash box'), t('Cash box update success'));
    },
    [alert, dispatch, handleUpdateDefaultCashBox, t],
  );

  const handleCreateCashBox = React.useCallback(
    async (value: CashBoxFormDTO) => {
      const cashBoxDTO = await createCashBox(value, companyUuid);

      const cashBoxModel = CashBoxMapper.toCashBoxModel(cashBoxDTO);

      const updatedModel = cashBoxModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddCashBox(updatedModel));
      alert('success', t('Cash box'), t('Cash box create success'));
    },
    [alert, companyUuid, dispatch, t],
  );

  const handleResetCashBox = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetCashBox());
  }, [dispatch]);

  const handleRefreshCashBox = React.useCallback(async () => {
    const cashBoxModel = await refresh({ showLoading: false, cashBoxUuid });

    if (cashBoxModel) {
      dispatch(storeUpdateCashBox(cashBoxModel));
      dispatch(storeUpdateCashBoxFromList(cashBoxModel));
    }
  }, [cashBoxUuid, dispatch, refresh]);

  const handleAdjustBalancesCashBox = React.useCallback(
    async (value: CashBoxAdjustBalancesFormDTO): Promise<void> => {
      const cashBoxDTO = await adjustBalancesCashBox(value);

      const cashBoxModel = CashBoxMapper.toCashBoxModel(cashBoxDTO);

      dispatch(storeUpdateCashBox(cashBoxModel));
      dispatch(storeUpdateCashBoxFromList(cashBoxModel));

      setCashBox(cashBoxModel);

      alert('success', t('Cash box'), t('Cash box adjust balances success'));
    },
    [alert, dispatch, t],
  );

  const handleMovingCashBox = React.useCallback(
    async (value: CashBoxMovingFormDTO): Promise<void> => {
      const cashBoxDTO = await movingCashBox(value);

      const cashBoxModel = CashBoxMapper.toCashBoxModel(cashBoxDTO);

      dispatch(storeUpdateCashBox(cashBoxModel));
      dispatch(storeUpdateCashBoxFromList(cashBoxModel));

      setCashBox(cashBoxModel);

      if (List.isList(cashBoxList)) {
        const item = cashBoxList?.find(
          ({ uuid }) => uuid === value?.cashbox_destination_uuid,
        );

        if (item) {
          const updatedItem = item?.update('box_balance', (balance) =>
            correctPrice(Number(balance || 0) + Number(value?.sum || 0)),
          );

          dispatch(storeUpdateCashBoxFromList(updatedItem));
        }
      }

      alert('success', t('Cash box'), t('Cash box moving success'));
    },
    [alert, dispatch, t, cashBoxList],
  );

  return {
    ...cashBoxParams,
    ...storedCashBoxParams,
    cashBox,
    loading: !cashBox,
    handleUpdateCashBox,
    handleCreateCashBox,
    status: storedCashBoxStatus,
    handleResetCashBox,
    refresh,
    handleRefreshCashBox,
    handleAdjustBalancesCashBox,
    handleMovingCashBox,
  };
}
