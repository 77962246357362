import React, { useState, useCallback, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox } from 'antd';
import {
  isFunction,
  buildRangePikerTime,
  buildTimePickerTime,
  getArrayLength,
} from '@services/helpers';
import { DateValue, RangeTime } from '@services/types';
import type { Moment } from 'moment';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';

import './TimePickerWithCheckbox.less';

const { RangePicker } = DatePicker;

export default withTranslation()(TimePickerWithCheckbox);

export interface IOnChangePicker {
  checked: boolean;
  name: string;
  time: string[] | string;
}

export interface ITimePickerWithCheckboxProps extends WithTranslation {
  value: [string, DateValue | RangeTime] | string;
  onChange?: (value: IOnChangePicker) => void;
  isRangePicker?: boolean;
  checkboxDisable?: boolean;
  isFixedRange?: boolean;
  handleGetDefaultTime?: (value: Omit<IOnChangePicker, 'time'>) => any;
  invert?: boolean;
  disabled?: boolean;
}

const TIME_FORMAT = 'HH:mm';

function TimePickerWithCheckbox({
  t,
  value,
  onChange = () => {},
  isRangePicker = false,
  checkboxDisable = false,
  handleGetDefaultTime,
  invert,
  disabled,
}: ITimePickerWithCheckboxProps): JSX.Element {
  const [[nameOfValue = null, time = null], setNameOfWeekday]: any = useState(
    [],
  );

  const [checked, setChecked] = useState<boolean | null>(null);

  const [defaultTime, setDefaultTime] = useState<DateValue | RangeTime>('');

  const onChangeValueTime = useCallback(
    (time: [string, string] | string, isChecked = false): void => {
      const name = Array.isArray(value) ? nameOfValue : value;

      if ((name && time) || !isChecked) {
        if (isFunction(onChange)) {
          onChange({
            name,
            time,
            checked: isChecked,
          });
        }
        setNameOfWeekday([name, time]);
      }
    },
    [value, nameOfValue, onChange],
  );

  const handleSwitchValue = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent): void => {
      setChecked(checked);

      if (!checked) {
        onChangeValueTime([] as any, checked);
        setDefaultTime('');
        setNameOfWeekday(isRangePicker ? [nameOfValue, []] : [nameOfValue]);
      }

      if (checked) {
        onChangeValueTime(time, checked);
        const defaultTime = isFunction(handleGetDefaultTime)
          ? handleGetDefaultTime({ name: nameOfValue, checked })
          : undefined;

        if (defaultTime) {
          setDefaultTime(defaultTime);
        }
      }
    },
    [handleGetDefaultTime, isRangePicker, nameOfValue, onChangeValueTime, time],
  );

  useEffect(() => {
    setNameOfWeekday(
      Array.isArray(value) ? value : isRangePicker ? [value, []] : [value],
    );
  }, [isRangePicker, value]);

  useEffect(() => {
    const isChecked = Array.isArray(time) ? getArrayLength(time) === 2 : !!time;
    setChecked(isChecked);
  }, [checked, time]);

  return (
    <div className="picker">
      <Checkbox
        disabled={checkboxDisable || disabled}
        className="picker-checkbox"
        checked={!!checked}
        onChange={handleSwitchValue}>
        {t(nameOfValue)}
      </Checkbox>

      {isRangePicker ? (
        <RangePicker
          popupClassName={'time-piker-checkbox'}
          showTime
          placeholder={[t('Start'), t('End')]}
          value={
            checked
              ? (buildRangePikerTime(
                  time as RangeTime,
                  defaultTime as any,
                ) as any)
              : undefined
          }
          format={TIME_FORMAT}
          disabled={!checked || disabled}
          onChange={(
            value: Moment | null | [Moment | null, Moment | null],
            dateString: string | [string, string],
          ): void => {
            onChangeValueTime(dateString, !!checked);
          }}
        />
      ) : (
        <TimePicker
          placeholder={t('Select time')}
          value={
            checked
              ? buildTimePickerTime(time, defaultTime as DateValue)
              : undefined
          }
          format={TIME_FORMAT}
          minuteStep={15}
          disabled={!checked || disabled}
          onChange={(
            value: Moment | null | [Moment | null, Moment | null],
            dateString: string | [string, string],
          ): void => {
            onChangeValueTime(dateString, !!checked);
          }}
        />
      )}
    </div>
  );
}
