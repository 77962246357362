import { APP_STATE, SETTINGS_MODULE } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { SettingsModuleModel } from '@structure';

interface ISettingsModuleListState {
  settingsModule: SettingsModuleModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<ISettingsModuleListState, 'settingsModule'> {
  type: SETTINGS_MODULE.SET_SETTINGS_MODULE;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateSettingsModule {
  type: SETTINGS_MODULE.UPDATE_SETTINGS_MODULE;
  settingsModule: SettingsModuleModel;
}

interface LoadingAction {
  type: SETTINGS_MODULE.LOADING_SETTINGS_MODULE;
}

interface ResetAction {
  type: SETTINGS_MODULE.RESET_SETTINGS_MODULE;
}

interface ErrorAction extends Pick<ISettingsModuleListState, 'error'> {
  type: SETTINGS_MODULE.ERROR_SETTINGS_MODULE;
}

interface Handlers {
  [SETTINGS_MODULE.SET_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    action: SetAction,
  ) => ISettingsModuleListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: ISettingsModuleListState,
    action: SetInitialStateAction,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE.RESET_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    action: ResetAction,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE.UPDATE_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    action: UpdateSettingsModule,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE.ERROR_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    value: ErrorAction,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE.LOADING_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    value?: LoadingAction,
  ) => ISettingsModuleListState;
  DEFAULT: (state: ISettingsModuleListState) => ISettingsModuleListState;
}

const initState: ISettingsModuleListState = {
  settingsModule: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [SETTINGS_MODULE.SET_SETTINGS_MODULE]: (state, { settingsModule }) => ({
    ...state,
    ...{
      settingsModule,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [SETTINGS_MODULE.RESET_SETTINGS_MODULE]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
      settingsModule: null,
    },
  }),

  [SETTINGS_MODULE.UPDATE_SETTINGS_MODULE]: (state, { settingsModule }) => ({
    ...state,
    ...{
      settingsModule,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [SETTINGS_MODULE.ERROR_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [SETTINGS_MODULE.LOADING_SETTINGS_MODULE]: (
    state: ISettingsModuleListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: ISettingsModuleListState) => state,
};

export default function SettingsModule(
  state: any = initState,
  action: any,
): ISettingsModuleListState {
  const handler = handlers[action.type as SETTINGS_MODULE] || handlers.DEFAULT;
  return handler(state, action);
}
