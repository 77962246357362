import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IProductCategoryListDTO,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductCategoryListModel,
  IProductsListStatsProps,
  ProductCategoryType,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getProductCategoryList } from '@services/api/product';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseProductCategoryListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  view?: ProductCategoryType;
  parent_category_uuid?: string;
  show_root_only?: boolean;
}

export interface IUseProductCategoryListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: ProductCategoryListModel | null;
  refresh: (
    value: Partial<IUseProductCategoryListProps>,
  ) => Promise<ProductCategoryListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  stats: IProductsListStatsProps;
  view?: ProductCategoryType;
  parent_category_uuid?: string;
  show_root_only?: boolean;
}

export function useProductCategoryList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    view,
    parent_category_uuid,
    show_root_only,
  }: IUseProductCategoryListProps = {} as IUseProductCategoryListProps,
): IUseProductCategoryListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    handleUpdate,
    stats: localStats,
    view: listView,
    parent_category_uuid: listParentCategoryUuid,
    show_root_only: listShowRootOnly,
  } = useStateReducer<Omit<IUseProductCategoryListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    stats: {} as IProductsListStatsProps,
    view,
    parent_category_uuid,
    show_root_only,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
      view = listView,
      parent_category_uuid = listParentCategoryUuid,
      show_root_only = listShowRootOnly,
    }: Partial<IUseProductCategoryListProps> = {}): Promise<ProductCategoryListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          view,
          parent_category_uuid,
          show_root_only,
        });

        const { categories, stats, total, parentCategory } =
          await cancellablePromise<IProductCategoryListDTO>(
            getProductCategoryList({
              limit,
              offset,
              keywords,
              companyUuid,
              view,
              parent_category_uuid,
              show_root_only,
            }),
          );

        const categoryListModel =
          ProductCategoryMapper.toProductCategoryListModel(
            categories,
            total,
            false,
            [],
            stats,
            parentCategory,
          );

        handleUpdate({
          entityList: categoryListModel,
          total: categoryListModel?.total,
          loading: false,
          stats,
        });

        return categoryListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Materials categories'),
          `${t('An error occurred during get materials category list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listView,
      listParentCategoryUuid,
      listShowRootOnly,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
    stats: localStats,
    view: listView,
    parent_category_uuid: listParentCategoryUuid,
    show_root_only: listShowRootOnly,
  };
}
