import { List } from 'immutable';
import { APP_STATE, SETTINGS_MODULE_LIST } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { SettingsModuleModel } from '@structure';

interface ISettingsModuleListState {
  settingsModuleList: List<SettingsModuleModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<
    ISettingsModuleListState,
    'settingsModuleList' | 'keywords' | 'total'
  > {
  type: SETTINGS_MODULE_LIST.SET_SETTINGS_MODULE_LIST;
}
interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: SETTINGS_MODULE_LIST.ADD_SETTINGS_MODULE_LIST;
  settingsModule: SettingsModuleModel;
}

interface UpdateServiceFromList {
  type: SETTINGS_MODULE_LIST.UPDATE_SETTINGS_MODULE_LIST;
  settingsModule: SettingsModuleModel;
}

interface LoadingActionInList {
  type: SETTINGS_MODULE_LIST.LOADING_SETTINGS_MODULE_LIST;
}

interface ErrorActionInList extends Pick<ISettingsModuleListState, 'error'> {
  type: SETTINGS_MODULE_LIST.ERROR_SETTINGS_MODULE_LIST;
}

interface Handlers {
  [SETTINGS_MODULE_LIST.SET_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    action: SetActionList,
  ) => ISettingsModuleListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: ISettingsModuleListState,
    action: SetInitialStateAction,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE_LIST.LOAD_MORE_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    action: SetActionList,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE_LIST.ADD_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    action: AddActionToList,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE_LIST.UPDATE_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    action: UpdateServiceFromList,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE_LIST.ERROR_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    value: ErrorActionInList,
  ) => ISettingsModuleListState;

  [SETTINGS_MODULE_LIST.LOADING_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    value?: LoadingActionInList,
  ) => ISettingsModuleListState;
  DEFAULT: (state: ISettingsModuleListState) => ISettingsModuleListState;
}

const initState: ISettingsModuleListState = {
  settingsModuleList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [SETTINGS_MODULE_LIST.SET_SETTINGS_MODULE_LIST]: (
    state,
    { settingsModuleList, keywords, total },
  ) => ({
    ...state,
    ...{
      settingsModuleList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [SETTINGS_MODULE_LIST.LOAD_MORE_SETTINGS_MODULE_LIST]: (
    state,
    { settingsModuleList },
  ) => ({
    ...state,
    ...{
      settingsModuleList:
        List.isList(state.settingsModuleList) && List.isList(settingsModuleList)
          ? state.settingsModuleList.merge(settingsModuleList)
          : state.settingsModuleList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [SETTINGS_MODULE_LIST.ADD_SETTINGS_MODULE_LIST]: (
    state,
    { settingsModule },
  ) => ({
    ...state,
    ...{
      settingsModuleList: List.isList(state.settingsModuleList)
        ? state.settingsModuleList.unshift(settingsModule)
        : List([settingsModule]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [SETTINGS_MODULE_LIST.UPDATE_SETTINGS_MODULE_LIST]: (
    state,
    { settingsModule },
  ) => ({
    ...state,
    ...{
      settingsModuleList: List.isList(state.settingsModuleList)
        ? state.settingsModuleList.map((stateSettingsModule) => {
            if (stateSettingsModule.uuid === settingsModule?.uuid) {
              return settingsModule;
            }
            return stateSettingsModule;
          })
        : List([settingsModule]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [SETTINGS_MODULE_LIST.ERROR_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [SETTINGS_MODULE_LIST.LOADING_SETTINGS_MODULE_LIST]: (
    state: ISettingsModuleListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: ISettingsModuleListState) => state,
};

export default function SettingsModuleList(
  state: any = initState,
  action: any,
): ISettingsModuleListState {
  const handler =
    handlers[action.type as SETTINGS_MODULE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
