import {
  ClientAbonementModel,
  ClientRefundsType,
  PAYMENT_METHOD,
} from '../internal';
import { correctPrice, toMoment } from '@services/helpers';
import { Moment } from 'moment';

export class ClientRefundsFormDTO {
  public uuid: string;
  public refund_start_date: string | Moment;
  public refund_comment: string;
  public refund_sum: string;
  public refund_cashbox_uuid: string;
  public refund_payment_method: PAYMENT_METHOD;
  public price?: string;
  public refund_visits: number;
  public refund_type: ClientRefundsType;

  constructor(props?: ClientAbonementModel) {
    this.uuid = props?.uuid || '';
    this.refund_start_date = toMoment(new Date());
    this.refund_comment = '';
    this.refund_cashbox_uuid = '';
    this.refund_visits = 0;
    this.refund_sum = '0.00';
    this.refund_type =
      props?.company_abonement?.abon_can_visiting_be_limited ||
      props?.company_abonement?.abon_manager_required
        ? ClientRefundsType.Visit
        : ClientRefundsType.Date;
    this.refund_payment_method = PAYMENT_METHOD.Cash;
    this.price = correctPrice(props?.companyAbonementModel?.abon_price);
  }
}
