import {
  IProfileDTOProps,
  ProfileDTO,
  CompanyDTO,
  ICompanyDTOProps,
  ProfileMapper,
  CompanyMapper,
  IReportModelReturnType,
} from '../internal';
import { WithoutRecursion } from '../type';
import { capitalize } from '@services/helpers';

export enum ReportTypes {
  Orders = 'orders',
  Payments = 'payments',
  Abonements = 'abonements',
}
export enum ReportStatuses {
  Initialized = 'initialized',
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
}

export interface IReportDTOProps {
  uuid: string;
  report_start_date: string;
  report_end_date: string;
  report_number: string;
  report_type: ReportTypes;
  report_status: ReportStatuses;
  report_path_url: string;
  created_at: string;
  updated_at: string;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
}

export class ReportDTO {
  public uuid: string;
  public report_start_date: string;
  public report_number: string;
  public report_title: string;
  public report_end_date: string;
  public report_type: ReportTypes;
  public report_status: ReportStatuses;
  public report_path_url: string;
  public created_at: string;
  public updated_at: string;
  public created_by: IProfileDTOProps;
  public company: ICompanyDTOProps;

  constructor(
    props: IReportModelReturnType | IReportDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.report_number = props?.report_number || '';
    this.report_title = `${capitalize(
      props?.report_type || ReportTypes.Orders,
    )} report`;
    this.report_start_date = props?.report_start_date || '';
    this.report_end_date = props?.report_end_date || '';
    this.report_type = props?.report_type || ReportTypes.Orders;
    this.report_status = props?.report_status || ReportStatuses.Initialized;
    this.report_path_url = props?.report_path_url || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
  }
}
