import React, { Suspense, useCallback, useEffect, useState } from 'react';
import {
  ExpenseCreateFormDTO,
  ExpenseFormDTO,
  ExpenseMapper,
  ExpenseModel,
  IExpenseDTOProps,
  ProductModel,
  ServiceModel,
  StoreDocumentFormDTO,
  StoreDocumentType,
} from '@structure';
import { Col, Empty, Form, Row } from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import { DefaultForm } from '@components/lib/General';
import { SuspenseEmpty } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useAcl, useDropdownAlert } from '@contex';
import {
  IUseStateExpenseListReturnType,
  IUseStateScheduleReturnType,
  useModal,
  useStateGroupPaymentList,
  useStateServiceList,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
} from '@hooks';
import { StyledTitle } from '@components/lib/Styled';
import {
  EditableCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import { List } from 'immutable';
import { ExpenseAggregatedSumView, ExpenseHeader } from '../Show';
import {
  EXPENSE_INITIAL_PARAM,
  ORDER_SCHEDULE_STATUS,
} from '@services/api/orders';
import { ExpensesDeleteButton, ExpenseUpdateButton } from '../Buttons';
import styled from 'styled-components';
import { ProductDetailsView } from '../../Products';
import { ServiceDetailsView } from '../../Services';

export interface IExpenseListProps
  extends Pick<IUseStateScheduleReturnType, 'schedule'>,
    IUseStateExpenseListReturnType {
  isDisabledScheduleAction: boolean;
  handleRefreshSchedule: IUseStateScheduleReturnType['refresh'];
}

const ExpenseSideWindowLazy = React.lazy(
  () => import('../Show/ExpenseSideWindow'),
);

const DISABLED_STATUSES = [
  ORDER_SCHEDULE_STATUS.CANCELED,
  ORDER_SCHEDULE_STATUS.FINISHED,
];

export interface IGroupedExpense {
  label: string;
  uuid: string;
}

const StyledTable = styled(Table)`
  & .expense-empty-cell {
    border-right: none !important;
  }
` as React.ComponentType as React.FC<
  ITableProps<IGroupedExpense | ExpenseModel>
>;

const StyledNumContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export function ExpenseList({
  schedule,
  isDisabledScheduleAction,

  expenseList,
  aggregatedSum,
  loading: loadingExpenseList,
  error: errorExpenseList,
  limit,
  total,

  refresh: refreshExpenseList,
  handleCreateExpense,
  handleUpdateExpense,
  handleDeleteExpenses,
  handleUpdateExpenseAmount,
  handleRefreshSchedule,
}: IExpenseListProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { manage } = useAcl(({ order }) => order);
  const [instance] = Form.useForm();

  const [groupedExpenses, setGroupedExpenses] = useState<
    (IGroupedExpense | ExpenseModel)[] | null
  >(null);
  const [expenseForm, setExpenseForm] = useState<
    ExpenseFormDTO | ExpenseCreateFormDTO
  >(EXPENSE_INITIAL_PARAM);
  const [amountLoading, setAmountLoading] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState('');
  const [resetStateSelect, setResetStateSelect] = useState(false);

  const isActive =
    !DISABLED_STATUSES.includes(schedule?.status_text as any) &&
    !isDisabledScheduleAction;

  const { handleCreateStoreDocument } = useStoredStoreDocument({
    companyUuid: defaultCompanyUuid,
    documentUuid: '',
    documentType: StoreDocumentType.OUT,
  });

  const {
    services,
    loading: loadingServiceList,
    error: errorServiceList,
    keywords: servicesKeywords,

    handleSearchServices,
    handleCreateService,
  } = useStateServiceList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: isActive,
  });

  const { groupPayments } = useStateGroupPaymentList({
    companyUuid: defaultCompanyUuid,
  });

  const firstGroupPaymentUuid = groupPayments?.first()?.uuid || '';

  const { visible, handleCancel, handleOnInit, handleSuccess } = useModal({
    onSuccess: handleCreateExpense,
    onCancel: () => {
      setResetStateSelect(true);
      setTimeout(setResetStateSelect, 0, false);
    },
  });

  const loading = useStopLoading({
    loading: loadingExpenseList,
    error: errorExpenseList,
    message: 'An error occurred during services or goods loading',
  });

  const serviceListLoading = useStopLoading({
    loading: loadingServiceList,
    error: errorServiceList,
    message: 'An error occurred during products loading',
  });

  const handleCreateExpenseStoreDocument = useCallback(
    async (value: StoreDocumentFormDTO) => {
      await handleCreateStoreDocument(value);

      await handleRefreshSchedule({});

      setTimeout(async () => {
        await refreshExpenseList({});
      }, 300);
    },
    [handleCreateStoreDocument, handleRefreshSchedule, refreshExpenseList],
  );

  const handleSetExpenseFormData = useCallback(
    (value: ExpenseFormDTO | ExpenseCreateFormDTO) => {
      setExpenseForm(value);
      handleOnInit();
    },
    [handleOnInit],
  );

  const getStateGroupedExpense = useCallback(
    (row: IExpenseDTOProps | ExpenseModel) => {
      const newData = [...(groupedExpenses || [])];

      const index = (groupedExpenses || []).findIndex(
        (node) => row.uuid === node.uuid,
      );

      const item = ExpenseMapper.toExpenseDTO(newData[index] as any);

      return {
        newData,
        item,
        index,
      };
    },
    [groupedExpenses],
  );

  const defaultColumns = [
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{t('name-s')}</StyledTitle>
      ),
      key: 'name',
      width: '30%',
      className: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? '' : 'expense-empty-cell',
      render: (expense: ExpenseModel | IGroupedExpense) => {
        if (expense instanceof ExpenseModel) {
          return expense?.workable instanceof ProductModel ? (
            <ProductDetailsView
              onlyTitle
              withTour
              price={expense?.price}
              product={expense?.workable}
            />
          ) : expense?.workable instanceof ServiceModel ? (
            <ServiceDetailsView
              onlyTitle
              withTour
              price={expense?.price}
              service={expense?.workable}
            />
          ) : null;
        }

        return <StyledTitle bold>{expense?.label}</StyledTitle>;
      },
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{t('tAmount')}</StyledTitle>
      ),
      key: 'amount',
      className: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? '' : 'expense-empty-cell',
      editable: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel,
      align: 'right' as any,
      rules: [
        () => ({
          validator(_: any, price: string) {
            const value = price;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('The tamount must be greater than 0')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(
                new Error(t('The tamount must be a number')),
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      width: '10%',
      render: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? expense?.amount : null,
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{`${t('Price')} ${
          defaultCompany?.currency_symbol
        }`}</StyledTitle>
      ),
      key: 'price',
      className: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? '' : 'expense-empty-cell',
      align: 'right' as any,
      render: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? (
          <StyledNumContainer>{expense?.price}</StyledNumContainer>
        ) : null,
    },
    {
      title: (
        <StyledTitle style={{ fontWeight: 400 }}>{`${t('Total')} ${
          defaultCompany?.currency_symbol
        }`}</StyledTitle>
      ),
      key: 'total',
      className: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? '' : 'expense-empty-cell',
      align: 'right' as any,
      render: (expense: ExpenseModel | IGroupedExpense) =>
        expense instanceof ExpenseModel ? (
          <StyledNumContainer>{expense?.total}</StyledNumContainer>
        ) : null,
    },
    ...(isDisabledScheduleAction
      ? []
      : [
          {
            ellipsis: true,
            title: '',
            className: (expense: ExpenseModel | IGroupedExpense) =>
              expense instanceof ExpenseModel ? '' : 'expense-empty-cell',
            key: 'actions',
            align: 'center' as any,
            width: 50,
            render: (expense: ExpenseModel | IGroupedExpense) => {
              return expense instanceof ExpenseModel ? (
                <TableActionCell className="table-action-cell-hidden">
                  <ExpenseUpdateButton
                    disabled={!manage || !isActive}
                    expense={ExpenseMapper.toExpenseFormDTO(expense, true)}
                    onSuccess={handleUpdateExpense}
                  />
                  {isActive ? (
                    <ExpensesDeleteButton
                      className="table-action-delete"
                      disabled={!manage || !isActive}
                      expenses={[expense]}
                      onSuccess={(ids) =>
                        handleDeleteExpenses(ids, expense?.total)
                      }
                    />
                  ) : null}
                </TableActionCell>
              ) : null;
            },
          },
        ]),
  ];

  const handleSave = useCallback(
    async (row: IExpenseDTOProps) => {
      try {
        setAmountLoading(true);
        setLoadingItemId(row?.uuid);
        let { newData, item, index } = getStateGroupedExpense(row);

        if (item?.amount !== row?.amount) {
          const expense = await handleUpdateExpenseAmount({
            uuid: row?.uuid,
            amount: row?.amount,
            prevPrice: item?.total,
          });

          if (expense) {
            newData.splice(index, 1, expense);
          }
        }

        setGroupedExpenses(newData);
        setAmountLoading(false);
        setLoadingItemId('');
      } catch (error: any) {
        setAmountLoading(false);
        setLoadingItemId('');
        alert(
          'error',
          t('Services or goods'),
          `${t('An error occurred during edit amount')}: ${error?.message}`,
        );
      }
    },
    [alert, handleUpdateExpenseAmount, getStateGroupedExpense, t],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: ExpenseModel) => {
          return {
            className: (col?.className as any)(record),
          };
        },
      };
    }

    return {
      ...col,
      onCell: (record: ExpenseModel) => {
        return {
          record,
          editable: (col.editable as any)(record),
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          disabled: !isActive,
          loading: amountLoading,
          loadingItemId,
          className: (col?.className as any)(record),
          alignText: col?.align,
        };
      },
    };
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Services or goods'),
        `${t('An error occurred during edit services or goods')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  useEffect(() => {
    if (List.isList(expenseList)) {
      const grouped = expenseList
        .groupBy((value: any) => value?.managerModel.fullName)
        .toArray()
        .flatMap(([label, nodes], index) => [
          {
            label,
            uuid: `${index} ${label}`,
          },
          ...nodes.toArray(),
        ]);

      setGroupedExpenses(grouped);
    }
  }, [expenseList]);

  return (
    <>
      {isDisabledScheduleAction ? null : (
        <ExpenseHeader
          schedule={schedule}
          services={services}
          servicesKeywords={servicesKeywords}
          serviceListLoading={serviceListLoading}
          handleCreateService={handleCreateService}
          handleSearchServices={handleSearchServices}
          handleSetExpenseFormData={handleSetExpenseFormData}
          disabled={!isActive}
          resetStateSelect={resetStateSelect}
          firstGroupPaymentUuid={firstGroupPaymentUuid}
          handleCreateExpenseStoreDocument={handleCreateExpenseStoreDocument}
        />
      )}

      <DefaultForm
        loading={amountLoading}
        instance={instance}
        initialValues={{ amount: 0 }}
        showNotify={false}
        showFooter={false}
        notifyError={notifyError}
        onSuccess={() => {}}>
        {() => {
          return (
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item name="amount" noStyle>
                  <StyledTable
                    loading={loading}
                    bordered
                    dataSource={groupedExpenses}
                    onChange={refreshExpenseList}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    pagination={false}
                    columns={columns as any}
                    total={total}
                    pageSize={limit}
                    renderEmpty={
                      <Empty
                        description={t(
                          "It looks like you don't have any services or goods added right now",
                        )}
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }}
      </DefaultForm>

      <ExpenseAggregatedSumView aggregatedSum={aggregatedSum} />

      <Suspense fallback={<SuspenseEmpty />}>
        <ExpenseSideWindowLazy
          expense={expenseForm}
          onSuccess={handleSuccess as any}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
