import React, { forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { StoreDocumentStatsDTO, StoreDocumentType } from '@structure';
import { OverviewBottomView } from '@components/lib/Layout';
import { correctPrice } from '@services/helpers';

export interface IStoreDocumentListBottomViewProps {
  stats: StoreDocumentStatsDTO;
  documentType: StoreDocumentType;
}

const PAYMENT_DOCUMENT_TYPE = [
  StoreDocumentType.IN,
  StoreDocumentType.OUT,
  StoreDocumentType.CRETURN,
  StoreDocumentType.RETURN,
];

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

export const StoreDocumentListBottomView = forwardRef(
  function StoreDocumentListBottomView(
    { stats, documentType }: IStoreDocumentListBottomViewProps,
    ref: any,
  ): JSX.Element {
    const { t } = useTranslation();
    const { defaultCompany } = useStoredCompanies();
    const theme: any = useTheme();

    return (
      <>
        <div ref={ref}>
          <OverviewBottomView>
            {PAYMENT_DOCUMENT_TYPE.includes(documentType) ? (
              <StyledContainer>
                <StyledTitle fontSize={14}>{`${t('Paid')} / ${t(
                  'Debt',
                )} : `}</StyledTitle>
                <StyledPrice>
                  {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                    stats?.sum_paid || 0,
                  )} / `}
                  <StyledPrice $color={theme.colors.error}>
                    {correctPrice(stats?.sum_unpaid || 0)}
                  </StyledPrice>
                </StyledPrice>
              </StyledContainer>
            ) : (
              <StyledContainer>
                <StyledTitle fontSize={14}>{`${t('Total sum')}: `}</StyledTitle>
                <StyledPrice>
                  {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                    stats?.sum_total || 0,
                  )}`}
                </StyledPrice>
              </StyledContainer>
            )}
          </OverviewBottomView>
        </div>
      </>
    );
  },
);
