import React from 'react';
import styled, { css } from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';

export interface IScheduleStateViewProps {
  title?: string;
  description?: string | React.ReactNode;
}

const StyledWrapper = styled.div<{ $isLineHeight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ $isLineHeight }) =>
    $isLineHeight &&
    css`
      padding: 3px;
      line-height: 1.5;
    `}
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export function ScheduleStateView({
  title,
  description,
  ...rest
}: IScheduleStateViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      <StyledWrapper $isLineHeight={!!description}>
        <StyledTitle fontSize={20}>{t(title)}</StyledTitle>
        {description ? (
          typeof description === 'string' ? (
            <StyledDescription>{description}</StyledDescription>
          ) : (
            description
          )
        ) : null}
      </StyledWrapper>
    </StyledTitleIconContainer>
  );
}
