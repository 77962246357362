import React, { Dispatch, SetStateAction, forwardRef } from 'react';
import { Empty, Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { List } from 'immutable';
import moment from 'moment';
import { UnderTableContent } from './UnderTableContent';
import { useTableChange } from '@hooks';
import { listToArray, isThereContent } from '@services/helpers';
import { ContentDetails } from '../Layout';
import './Table.less';

export interface ITableProps<T>
  extends Omit<TableProps<T>, 'dataSource' | 'onChange' | 'pagination'> {
  className?: string;
  renderEmpty?: React.ReactNode;
  underTableContent?: React.ReactNode;
  underTableContentStyle?: React.CSSProperties;
  pageSize: number;
  total: number;
  dataSource: List<T> | T[] | null;
  rowSelectionOnChange?: (
    selectedRowKeys: React.Key[],
    selectedRecords: T[],
    setSelectedEntities: Dispatch<SetStateAction<T[]>>,
  ) => void;
  fitContent?: boolean;
  onChange?: (value: any) => Promise<any> | void;
  page?: number;
  customPage?: number;
  pagination?: boolean;
  withRef?: boolean;
}

const StyledAntTable = styled(AntTable)<
  { fitContent: boolean; $emptyData: boolean } & any
>`
  margin-bottom: 30px;

  & .ant-table-content > table {
    border: 1px solid rgb(225, 229, 231);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;

    & .table--row:last-child > td {
      border-bottom: none !important;
      & .row-selection--colors {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  ${({ $emptyData }) =>
    $emptyData &&
    css`
      &&& .ant-table-expanded-row-fixed {
        margin: 0 -12px;
      }
    `}

  & .list__row--highlight {
    background-color: rgba(64, 64, 65, 0.15);
  }

  ${({ fitContent }) =>
    fitContent &&
    css`
      min-height: 0;
    `}
`;

export const Table = forwardRef(function Table<T>(
  {
    renderEmpty,
    loading,
    underTableContent,
    underTableContentStyle,
    size = 'middle',
    pageSize,
    total,
    onChange,
    rowKey = 'uuid',
    dataSource,
    fitContent,
    page,
    customPage,
    pagination = true,
    columns,
    withRef,
    ...rest
  }: ITableProps<T>,
  ref: any,
): React.JSX.Element {
  const { t } = useTranslation();

  const { currentPage, handleTableChange } = useTableChange({
    pageSize,
    callback: onChange,
    total,
    page,
  });

  return (
    <>
      {underTableContent && (
        <UnderTableContent style={underTableContentStyle} loading={!!loading}>
          <ContentDetails hidden>{underTableContent}</ContentDetails>
        </UnderTableContent>
      )}
      <StyledAntTable
        ref={withRef ? ref : undefined}
        fitContent={fitContent}
        locale={{
          emptyText: loading ? (
            <Empty description={`...${t('Loading')}`} />
          ) : (
            renderEmpty || <Empty description={t('No Data')} />
          ),
        }}
        pagination={
          pagination
            ? {
                current: customPage || currentPage,
                pageSize,
                hideOnSinglePage: total <= 10,
                total,
              }
            : typeof pagination === 'boolean'
            ? pagination
            : {
                hideOnSinglePage: total <= 10,
                pageSize: Infinity,
              }
        }
        onChange={handleTableChange}
        columns={columns}
        loading={loading}
        size={size}
        rowKey={rowKey}
        scroll={{ x: true }}
        $emptyData={!isThereContent(listToArray(dataSource as any))}
        dataSource={listToArray(dataSource as any)}
        rowClassName={(entity: any, index: number) => {
          if (entity?.created_at) {
            const now = moment(new Date());
            const end = moment(entity?.created_at);
            const second = now.diff(end, 'second');

            if (index === 0 && second === 0) {
              return 'list__row--highlight';
            }
          }

          return 'table--row';
        }}
        {...rest}
      />
    </>
  );
}) as <T>(
  props: ITableProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;
