import { Record } from 'immutable';
import { IntegrationCategoryDTO } from '../internal';
import { WithoutRecursion } from '../type';

export interface IIntegrationCategoryModelReturnType {
  uuid: string;
  title: string;
  description: string;
  help_html: string;
  created_at: string;
  updated_at: string;
}

export class IntegrationCategoryModel extends Record<IIntegrationCategoryModelReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    help_html: '',
    created_at: '',
    updated_at: '',
  },
) {
  constructor(
    props: IntegrationCategoryDTO = {} as IntegrationCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IIntegrationCategoryModelReturnType = {
      ...props,
    };

    super(options);
  }
}
