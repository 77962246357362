import * as React from 'react';
import {
  useStoreDocument,
  IUseStoreDocumentProps,
  IUseStoreDocumentReturnType,
} from './useStoreDocument';
import {
  StoreDocumentFormDTO,
  StoreDocumentModel,
  StoreDocumentMapper,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
  AggregatedSumDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemModel,
  StorePaymentDocumentDiscountType,
} from '@structure';
import {
  createStoreDocument,
  editStoreDocument,
  paymentStoreDocument,
  createStoreDocumentItem,
  editStoreDocumentItem,
  deleteStoreDocumentItem,
  getStoreDocumentForPrint,
  PaymentStoreDocumentQueryParams,
  getStoreDocumentCheckForPrint,
  updateStoreDocumentComment,
  editStoreDocumentSalePriceMargin,
} from '@services/api/storeDocument';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { ApiError, Routes } from '@services/types';
import { SetStateAction, useEffect } from 'react';
import {
  addStoreDocumentToList as storeAddStoreDocument,
  setStoreDocument as storeSetStoreDocument,
  updateStoreDocument as storeUpdateStoreDocument,
  updateStoreDocumentFromList as storeUpdateStoreDocumentFromList,
  resetStoreDocument as storeResetStoreDocument,
  updateStoreDocumentStatsFromList as storeUpdateStoreDocumentStatsFromList,
} from '@store/actions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { EventEmitter, head, printScheduleDocument } from '@services/helpers';
import { useStoredStoreDocumentList } from './useStoredStoreDocumentList';
import { List } from 'immutable';
import { useStoredCashBoxList } from './useStoredCashBoxList';
import { StatusError } from '@components/lib/Errors';
import { produce } from 'immer';
import { EventEmitterItems } from '@services/const';

export interface IUseStateStoreDocumentProps extends IUseStoreDocumentProps {
  companyUuid: string;
  documentType: StoreDocumentType;
  withoutUpdatePayment?: boolean;
}

export interface ICreateStoreDocumentReturnType {
  document: StoreDocumentModel;
  aggregatedSum: AggregatedSumDTO;
}

export interface DocumentItemReturnType {
  documentItem: StoreDocumentItemModel;
  document?: StoreDocumentModel;
}

export interface IUseStateStoredDocumentReturnType
  extends Omit<IUseStoreDocumentReturnType, 'entity'> {
  handleUpdateStoreDocument: (
    value: StoreDocumentFormDTO,
  ) => Promise<StoreDocumentModel | void>;
  handleResetStoreDocument: () => void;
  handleCreateStoreDocument: (
    value: StoreDocumentFormDTO,
    initCompanyUuid?: string,
  ) => Promise<ICreateStoreDocumentReturnType | void>;
  handleCreateOrUpdateStoreDocument: (
    value: StoreDocumentFormDTO,
  ) => Promise<StoreDocumentModel | void>;
  handlePaymentStoreDocument: (
    value: StorePaymentDocumentFormDTO,
    queryParams?: PaymentStoreDocumentQueryParams,
  ) => Promise<StoreDocumentModel | void>;
  handleRefreshStoreDocument: () => Promise<void>;

  handleUpdateStoreDocumentItem: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;
  handleCreateStoreDocumentItem: (
    value: StoreDocumentItemFormDTO,
    initCompanyUuid?: string,
  ) => Promise<DocumentItemReturnType | void>;
  handleCreateOrUpdateStoreDocumentItem: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;

  handleDeleteStoreDocumentItem: (
    ids: string[],
    documentUuid?: string,
  ) => Promise<Pick<DocumentItemReturnType, 'document'> | void>;
  handlePrintStoreDocument: () => Promise<void>;
  handlePrintStoreDocumentCheck: (documentUuid?: string) => Promise<void>;

  refresh: (
    value: Partial<IUseStoreDocumentProps>,
  ) => Promise<StoreDocumentModel | void>;
  error: ApiError | null;
  loading: boolean;
  document: StoreDocumentModel | null;
  documentUuid: string;
  setStoreDocument: React.Dispatch<SetStateAction<StoreDocumentModel | null>>;
  handleUpdateStoreDocumentComment: (comment: string) => Promise<void>;
  handleUpdateStoreDocumentSalePriceMargin: (
    documentUuid: string,
    priceMarginUuid: string,
  ) => Promise<StoreDocumentModel | void>;
}

export function useStoredStoreDocument({
  companyUuid,
  loadOnInit = true,
  documentUuid,
  documentType,
  withoutUpdatePayment,
  ...rest
}: IUseStateStoreDocumentProps): IUseStateStoredDocumentReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { handleRefreshCashBoxes } = useStoredCashBoxList({
    companyUuid,
    loadOnInit: false,
  });

  const [document, setStoreDocument] =
    React.useState<StoreDocumentModel | null>(null);
  const [isStartLoading, setIsStartLoading] = React.useState<boolean>(false);

  const { cachedStoreDocumentList, status, ...documentStoreDocumentParams } =
    useSelector(({ storeDocument }: RootState) => storeDocument);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...documentParams } = useStoreDocument({
    loadOnInit: loadOnInit && isStartLoading,
    documentUuid,
    ...rest,
  });

  const { documentList } = useStoredStoreDocumentList({
    companyUuid: '',
    loadOnInit: false,
    documentType,
  });

  React.useEffect(() => {
    if (documentUuid) {
      if (cachedStoreDocumentList?.size > 0) {
        const storeDocument = cachedStoreDocumentList.get(documentUuid);

        if (storeDocument && !storeDocument.equals(document)) {
          setIsStartLoading(false);
          setStoreDocument(storeDocument);
        }

        if (!storeDocument) {
          setIsStartLoading(true);
        }
      }
      if (cachedStoreDocumentList?.size === 0 && !document) {
        setIsStartLoading(true);
      }
    }
  }, [cachedStoreDocumentList, document, documentUuid]);

  useEffect(() => {
    if (entity && isStartLoading && !once.current && loadOnInit) {
      dispatch(storeSetStoreDocument(entity));
      setStoreDocument(entity);
    }
  }, [loadOnInit, dispatch, entity, isStartLoading]);

  const handleUpdateStoreDocument = React.useCallback(
    async (value: StoreDocumentFormDTO): Promise<StoreDocumentModel | void> => {
      const { document: documentDTO, stats } = await editStoreDocument({
        ...value,
        doc_type: documentType,
      });

      const documentModel =
        StoreDocumentMapper.toStoreDocumentModel(documentDTO);

      dispatch(storeUpdateStoreDocument(documentModel));
      if (List.isList(documentList)) {
        dispatch(
          storeUpdateStoreDocumentFromList(
            documentModel,
            documentModel?.doc_type,
          ),
        );
      }
      dispatch(storeUpdateStoreDocumentStatsFromList(stats, documentType));

      setStoreDocument(documentModel);

      return documentModel;
    },
    [dispatch, documentList, documentType],
  );

  const handleCreateStoreDocument = React.useCallback(
    async (
      value: StoreDocumentFormDTO,
      initCompanyUuid = companyUuid,
    ): Promise<ICreateStoreDocumentReturnType> => {
      const { document, aggregatedSum, stats } = await createStoreDocument(
        { ...value, doc_type: value.doc_type || documentType },
        initCompanyUuid,
      );

      const documentModel = StoreDocumentMapper.toStoreDocumentModel(document);

      if (List.isList(documentList)) {
        dispatch(storeAddStoreDocument(documentModel, documentModel?.doc_type));
      }

      setStoreDocument(documentModel);

      dispatch(storeUpdateStoreDocumentStatsFromList(stats, documentType));

      return {
        document: documentModel,
        aggregatedSum,
      };
    },
    [companyUuid, dispatch, documentList, documentType],
  );

  const handleResetStoreDocument = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetStoreDocument());
  }, [dispatch]);

  const handleRefreshStoreDocument = React.useCallback(async () => {
    const documentModel = await refresh({ showLoading: false, documentUuid });

    if (documentModel) {
      dispatch(storeUpdateStoreDocument(documentModel));
      dispatch(
        storeUpdateStoreDocumentFromList(
          documentModel,
          documentModel?.doc_type,
        ),
      );
    }
  }, [documentUuid, dispatch, refresh]);

  const handlePaymentStoreDocument = React.useCallback(
    async (
      value: StorePaymentDocumentFormDTO,
      queryParams?: PaymentStoreDocumentQueryParams,
    ): Promise<any> => {
      const updatedValue = produce(value, (draft) => {
        const paymentPrice = Number(draft?.payment_price);
        const paymentDiscountValue = Number(
          draft?.payment_doc_discount_value || 0,
        );

        if (paymentDiscountValue > 0) {
          const discountRemaining =
            draft?.payment_doc_discount_type ===
            StorePaymentDocumentDiscountType.FIXED
              ? paymentPrice - (paymentPrice - paymentDiscountValue)
              : draft?.payment_doc_discount_type ===
                StorePaymentDocumentDiscountType.PERCENTAGE
              ? paymentPrice * (paymentDiscountValue / 100)
              : 0;

          draft.payment_price = paymentPrice - discountRemaining;
        }
      });

      const { document: documentDTO, stats } = await paymentStoreDocument(
        updatedValue,
        queryParams,
      );

      if (document && !withoutUpdatePayment) {
        const updatedDocument = document
          ?.update('doc_sum_paid', () => documentDTO?.doc_sum_paid)
          ?.update('doc_status_text', () => documentDTO?.doc_status_text)
          ?.update(
            'doc_comment',
            (doc_comment) => documentDTO?.doc_comment || doc_comment,
          )
          .update(
            'doc_sum_total_with_discount',
            () => documentDTO?.doc_sum_total_with_discount,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              documentDTO?.doc_discount_value || doc_discount_value,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              documentDTO?.doc_sum_services || doc_sum_services,
          );

        dispatch(storeUpdateStoreDocument(updatedDocument));
        dispatch(
          storeUpdateStoreDocumentFromList(
            updatedDocument,
            updatedDocument?.doc_type,
          ),
        );

        // if (isThereContent(stats)) {
        //   dispatch(
        //     storeUpdateStoreDocumentStatsFromList(
        //       stats,
        //       updatedDocument?.doc_type,
        //     ),
        //   );
        // }

        setStoreDocument(updatedDocument);

        setTimeout(async () => {
          await handleRefreshCashBoxes({ page: 1, limit: 100 });
        }, 300);

        return updatedDocument;
      }
    },
    [dispatch, document, handleRefreshCashBoxes, withoutUpdatePayment],
  );

  const handleCreateOrUpdateStoreDocument = React.useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (!value?.uuid) {
        const { document } = await handleCreateStoreDocument(value);

        return document;
      }
      return handleUpdateStoreDocument(value);
    },
    [handleCreateStoreDocument, handleUpdateStoreDocument],
  );

  const handleUpdateStoreDocumentItem = React.useCallback(
    async (
      value: StoreDocumentItemFormDTO,
    ): Promise<DocumentItemReturnType> => {
      const { item, document: storeDocument } = await editStoreDocumentItem(
        value,
        document?.uuid!,
      );

      const documentItemModel: any =
        StoreDocumentMapper.toStoreDocumentItemModel(item);

      const updatedDocument = document
        ?.update('doc_items', (doc_items) =>
          doc_items?.map((item) => {
            if (item?.uuid === documentItemModel?.uuid) {
              return documentItemModel;
            }

            return item;
          }, []),
        )
        .update('doc_sum_paid', () => item?.store_document?.doc_sum_paid)
        .update('doc_sum_total', () => item?.store_document?.doc_sum_total)
        .update(
          'doc_sum_total_with_discount',
          () => item?.store_document?.doc_sum_total_with_discount,
        )
        .update(
          'doc_sum_services',
          (doc_sum_services) =>
            item?.store_document?.doc_sum_services || doc_sum_services,
        )
        .update(
          'doc_discount_value',
          (doc_discount_value) =>
            item?.store_document?.doc_discount_value || doc_discount_value,
        )
        .update('stats', () => storeDocument?.stats);

      if (updatedDocument) {
        dispatch(storeUpdateStoreDocument(updatedDocument));
        dispatch(
          storeUpdateStoreDocumentFromList(
            updatedDocument,
            updatedDocument?.doc_type,
          ),
        );

        setStoreDocument(updatedDocument);
      }

      return { documentItem: documentItemModel, document: updatedDocument };
    },
    [dispatch, document],
  );

  const handleCreateStoreDocumentItem = React.useCallback(
    async (
      value: StoreDocumentItemFormDTO,
    ): Promise<DocumentItemReturnType> => {
      const { item, document: storeDocument } = await createStoreDocumentItem(
        value,
        document?.uuid!,
      );

      const documentItemModel: any =
        StoreDocumentMapper.toStoreDocumentItemModel(item);

      const updatedDocument = document
        ?.update('doc_items', (doc_items: any) => [...doc_items, item])
        .update('doc_sum_paid', () => item?.store_document?.doc_sum_paid)
        .update('doc_sum_total', () => item?.store_document?.doc_sum_total)
        .update(
          'doc_sum_total_with_discount',
          () => item?.store_document?.doc_sum_total_with_discount,
        )
        .update(
          'doc_sum_services',
          (doc_sum_services) =>
            item?.store_document?.doc_sum_services || doc_sum_services,
        )
        .update(
          'doc_discount_value',
          (doc_discount_value) =>
            item?.store_document?.doc_discount_value || doc_discount_value,
        )
        .update('stats', () => storeDocument?.stats);

      if (updatedDocument) {
        dispatch(storeUpdateStoreDocument(updatedDocument));
        dispatch(
          storeUpdateStoreDocumentFromList(
            updatedDocument,
            updatedDocument?.doc_type,
          ),
        );

        setStoreDocument(updatedDocument);
      }

      return { documentItem: documentItemModel, document: updatedDocument };
    },
    [dispatch, document],
  );

  const handleDeleteStoreDocumentItem = React.useCallback(
    async (ids: string[], documentUuid?: string) => {
      const { document: updatedDocumentModel } = await deleteStoreDocumentItem(
        ids,
        documentUuid || document?.uuid!,
      );

      if (document) {
        const updatedDocument = document
          ?.update('doc_items', (doc_items: any) =>
            doc_items?.filter((item: any) => item?.uuid !== head(ids)),
          )
          ?.update('doc_sum_paid', () => updatedDocumentModel?.doc_sum_paid)
          .update('doc_sum_total', () => updatedDocumentModel?.doc_sum_total)
          .update(
            'doc_sum_total_with_discount',
            () => updatedDocumentModel?.doc_sum_total_with_discount,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              updatedDocumentModel?.doc_sum_services || doc_sum_services,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              updatedDocumentModel?.doc_discount_value || doc_discount_value,
          )
          .update('stats', () => updatedDocumentModel?.stats);

        if (updatedDocument) {
          dispatch(storeUpdateStoreDocument(updatedDocument));
          dispatch(
            storeUpdateStoreDocumentFromList(
              updatedDocument,
              updatedDocument?.doc_type,
            ),
          );

          setStoreDocument(updatedDocument);
        }
        return { document: updatedDocument };
      }

      return { document: updatedDocumentModel };
    },
    [dispatch, document],
  );

  const handleCreateOrUpdateStoreDocumentItem = React.useCallback(
    async (value: StoreDocumentItemFormDTO) => {
      if (!value?.uuid) {
        return handleCreateStoreDocumentItem(value);
      }
      return handleUpdateStoreDocumentItem(value);
    },
    [handleCreateStoreDocumentItem, handleUpdateStoreDocumentItem],
  );

  const handlePrintStoreDocument =
    React.useCallback(async (): Promise<void> => {
      try {
        const html = await getStoreDocumentForPrint(documentUuid);

        alert('success', t('Document'), t('Get document success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Document'),
          `${t('An error occurred during get document')} : ${error?.message}`,
        );
      }
    }, [alert, documentUuid, t]);

  const handlePrintStoreDocumentCheck = React.useCallback(
    async (uuid?: string): Promise<void> => {
      try {
        const html = await getStoreDocumentCheckForPrint(uuid || documentUuid);

        alert('success', t('Document'), t('Get document success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Document'),
          `${t('An error occurred during get document')} : ${error?.message}`,
        );
      }
    },
    [alert, documentUuid, t],
  );

  const handleUpdateStoreDocumentComment = React.useCallback(
    async (comment: string): Promise<void> => {
      try {
        const documentDTO = await updateStoreDocumentComment(
          comment,
          documentUuid,
        );

        const documentModel =
          StoreDocumentMapper.toStoreDocumentModel(documentDTO);

        dispatch(storeUpdateStoreDocument(documentModel));

        if (List.isList(documentList)) {
          dispatch(
            storeUpdateStoreDocumentFromList(
              documentModel,
              documentModel?.doc_type,
            ),
          );
        }

        setStoreDocument(documentModel);

        alert('success', t('Store'), t('Store document successfully updated'));
      } catch (error: any) {
        alert(
          'error',
          t('Store'),
          `${t('An error occurred during edit store document')} : ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, documentList, documentUuid, t],
  );

  const handleUpdateStoreDocumentSalePriceMargin = React.useCallback(
    async (storeDocumentUuid = documentUuid, priceMarginUuid: string) => {
      const documentDTO = await editStoreDocumentSalePriceMargin(
        storeDocumentUuid,
        priceMarginUuid,
      );

      const documentModel =
        StoreDocumentMapper.toStoreDocumentModel(documentDTO);

      dispatch(storeUpdateStoreDocument(documentModel));
      dispatch(
        storeUpdateStoreDocumentFromList(
          documentModel,
          documentModel?.doc_type,
        ),
      );

      setStoreDocument(documentModel);

      return documentModel;
    },
    [dispatch, documentUuid],
  );

  useEffect(() => {
    EventEmitter.clear(EventEmitterItems.update_store_sale_document);

    EventEmitter.on(
      EventEmitterItems.update_store_sale_document,
      (
        item: StoreDocumentItemModel,
        updatedDocumentModel: StoreDocumentModel,
        isCreated?: boolean,
      ) => {
        const updatedDocument = document
          ?.update('doc_items', (doc_items: any) =>
            isCreated
              ? [...doc_items, item]
              : doc_items?.map((docItem: any) => {
                  if (docItem?.uuid === item?.uuid) {
                    return item;
                  }

                  return item;
                }),
          )
          .update('doc_sum_paid', () => item?.store_document?.doc_sum_paid)
          .update('doc_sum_total', () => item?.store_document?.doc_sum_total)
          .update(
            'doc_sum_total_with_discount',
            () => item?.store_document?.doc_sum_total_with_discount,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              item?.store_document?.doc_sum_services || doc_sum_services,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              item?.store_document?.doc_discount_value || doc_discount_value,
          )
          .update('stats', () => updatedDocumentModel?.stats);

        if (updatedDocument) {
          dispatch(storeUpdateStoreDocument(updatedDocument));
          dispatch(
            storeUpdateStoreDocumentFromList(
              updatedDocument,
              updatedDocument?.doc_type,
            ),
          );

          setStoreDocument(updatedDocument);
        }
      },
    );
  }, [dispatch, document]);

  useEffect(() => {
    EventEmitter.clear(EventEmitterItems.delete_store_sale_document);

    EventEmitter.on(
      EventEmitterItems.delete_store_sale_document,
      (ids: string[], updatedDocumentModel: StoreDocumentModel) => {
        const updatedDocument = document
          ?.update('doc_items', (doc_items: any) =>
            doc_items?.filter((item: any) => item?.uuid !== head(ids)),
          )
          ?.update('doc_sum_paid', () => updatedDocumentModel?.doc_sum_paid)
          .update('doc_sum_total', () => updatedDocumentModel?.doc_sum_total)
          .update(
            'doc_sum_total_with_discount',
            () => updatedDocumentModel?.doc_sum_total_with_discount,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              updatedDocumentModel?.doc_sum_services || doc_sum_services,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              updatedDocumentModel?.doc_discount_value || doc_discount_value,
          )
          .update('stats', () => updatedDocumentModel?.stats);

        if (updatedDocument) {
          dispatch(storeUpdateStoreDocument(updatedDocument));
          dispatch(
            storeUpdateStoreDocumentFromList(
              updatedDocument,
              updatedDocument?.doc_type,
            ),
          );

          setStoreDocument(updatedDocument);
        }
      },
    );
  }, [dispatch, document]);

  return {
    ...documentParams,
    ...documentStoreDocumentParams,
    document,
    loading: !document,
    handleUpdateStoreDocument,
    handleCreateStoreDocument,
    handleResetStoreDocument,
    refresh,
    handleRefreshStoreDocument,
    handlePaymentStoreDocument,
    handleCreateOrUpdateStoreDocument,
    handleCreateOrUpdateStoreDocumentItem,
    handleCreateStoreDocumentItem,
    handleUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
    handleUpdateStoreDocumentSalePriceMargin,
    setStoreDocument,
  };
}
