import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Form } from 'antd';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import {
  ITemplatesParams,
  TEMPLATES_INITIAL_PARAMS,
  TEMPLATES_TYPE_LIST_FORM,
} from '@services/api/templates';
import { SearchSelect } from '@components/lib/DataDisplay';
import { useSystemEventList } from '@hooks';
import {
  ITemplateProps,
  IVariable,
  find,
  isThereContent,
  eq,
  FunctionArgs,
} from '@services/helpers';
import { Event } from '@services/models';
import { ApiError } from '@services/types';
import { EventsButtonView } from '../Show';
import { useDropdownAlert } from '@contex';

export interface ITemplateFormProps
  extends Omit<
    IDefaultFormProps<ITemplatesParams | ITemplateProps, ITemplatesParams>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  template?: ITemplatesParams | ITemplateProps;
}

export default function TemplateForm({
  loading,
  editMode,
  template = TEMPLATES_INITIAL_PARAMS,
  ...rest
}: ITemplateFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const isSelectEventVariables = useRef(false);
  const [eventVariables, setEventVariables] = useState<IVariable[]>([]);
  const [textTemplateCursorPosition, setTextTemplateCursorPosition] =
    useState(0);

  const { list: events, loading: eventsLoading } = useSystemEventList();

  const additionalValues = useMemo(
    () => ({
      template_uuid: template?.template_uuid,
    }),
    [template?.template_uuid],
  );

  const handleSetEventVariable = useCallback(
    (event_key: string, events: Event[]): void => {
      const event = find<[FunctionArgs<Event, boolean>, Event[]], Event>(
        (event: Event) => eq(event?.event_key, event_key),
        events,
      );

      setEventVariables(event?.event_variables);
    },
    [],
  );

  const handleChangeTemplateEvent = useCallback(
    (
      event_key: string,
      handlerUpdateFormState: (value: any) => void,
      events: Event[],
    ): void => {
      handleSetEventVariable(event_key, events);

      handlerUpdateFormState({ template_event: event_key });
    },
    [handleSetEventVariable],
  );

  const handleSelectVariable = useCallback(
    (
      value: string,
      handlerUpdateFormState: (value: any) => void,
      formData: any,
    ) => {
      const template_body = (formData?.template_body || '').split('');

      template_body.splice(textTemplateCursorPosition, 0, `{% ${value} %}`);

      handlerUpdateFormState({ template_body: template_body.join('') });
    },
    [textTemplateCursorPosition],
  );

  useEffect(() => {
    if (
      isThereContent(events) &&
      !eventsLoading &&
      !isSelectEventVariables.current &&
      template?.template_event
    ) {
      handleSetEventVariable(template?.template_event, events);
      isSelectEventVariables.current = true;
    }
  }, [events, eventsLoading, handleSetEventVariable, template?.template_event]);

  const notifyError = useCallback(
    (apiError: ApiError) => {
      alert(
        'error',
        t('Mailing template'),
        `${
          editMode
            ? t('An error occurred during edit mailing template')
            : t('An error occurred during create mailing template')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  const onBlurTemplateCursorPositionChange = useCallback((event: any) => {
    const inputElement = event.target;

    const cursorPosition = inputElement.selectionStart;

    setTextTemplateCursorPosition(cursorPosition || 0);
  }, []);

  return (
    <DefaultForm
      initialValues={{
        ...template,
      }}
      withContext
      isResetLoading={false}
      editMode={editMode}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, handlerUpdateFormState, formData }) => (
        <Row gutter={20} justify="end">
          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="template_title"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}
              tooltip={{
                title: t('The name of notification template'),
              }}>
              <Input
                data-testid="template-form__input--title"
                placeholder={t('Enter a title')}
                disabled={loadingSubmit}
                onChange={(e) =>
                  handlerUpdateFormState({ template_title: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Type')}
              name="template_type"
              rules={[
                {
                  required: true,
                  message: t('Type must be specified'),
                },
              ]}
              tooltip={{
                title: t('The type of notification'),
              }}>
              <SearchSelect
                name="template_type"
                disable={loadingSubmit}
                placeholder={t('Search a type')}
                data={TEMPLATES_TYPE_LIST_FORM(t)}
                onChange={(e) => handlerUpdateFormState({ template_type: e })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('The trigger')}
              name="template_event"
              rules={[
                {
                  required: true,
                  message: t('Template event must be specified'),
                },
              ]}
              tooltip={{
                title: t(
                  'The event that triggers the creating and sending the notification',
                ),
              }}>
              <SearchSelect
                name="template_event"
                disable={loadingSubmit || eventsLoading}
                getOptionValueTitle="event_title"
                getOptionValueProps="event_key"
                placeholder={t('Search a template event')}
                data={events}
                onChange={(e) =>
                  handleChangeTemplateEvent(
                    e as string,
                    handlerUpdateFormState,
                    events,
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Template text')}
              tooltip={{
                title: t('Text'),
              }}>
              <>
                <EventsButtonView
                  disabled={loadingSubmit || eventsLoading}
                  variables={eventVariables}
                  onClick={(e: any) =>
                    handleSelectVariable(e, handlerUpdateFormState, formData)
                  }
                />
                <Input.TextArea
                  onBlur={onBlurTemplateCursorPositionChange}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  disabled={loadingSubmit}
                  placeholder={t('Enter a text Template')}
                  onChange={(e) =>
                    handlerUpdateFormState({ template_body: e.target.value })
                  }
                  value={formData?.template_body}
                />
              </>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Comment')}
              name="template_comment"
              tooltip={{
                title: t('Admin comment for template'),
              }}>
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 5 }}
                disabled={loadingSubmit}
                placeholder={t('Enter a comment')}
                onChange={(e) =>
                  handlerUpdateFormState({ template_comment: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
