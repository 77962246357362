import { Record } from 'immutable';
import {
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
  PriceTagDTO,
  ProductModel,
  ProductDTO,
  ProductMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IPriceTagModelReturnType {
  uuid: string;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel | ProfileDTO;
  product: ProductModel | ProductDTO;
}

export class PriceTagModel extends Record<IPriceTagModelReturnType>({
  uuid: '',
  created_at: '',
  updated_at: '',
  created_by: {} as ProfileModel,
  product: {} as ProductModel,
}) {
  constructor(
    props: PriceTagDTO = {} as PriceTagDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IPriceTagModelReturnType = {
      ...props,

      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      product:
        withoutRecursion.indexOf(WithoutRecursion.product) !== -1
          ? props?.product
          : ProductMapper.toProductModel(props?.product, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get productModel(): ProductModel {
    return this.product instanceof ProductModel
      ? this.product
      : ProductMapper.toProductModel(this.product);
  }
}
