import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderLeft, DetailsView } from '@components/lib/DataDisplay';
import {
  useRequiredFields,
  useStoredStore,
  useStopLoading,
  useStoredCompanies,
} from '@hooks';
import { STORE_REQUIRED_FIELD } from '@services/api/store';
import { OverviewLayout } from '@components/lib/Layout';
import { StyledTitle } from '@components/lib/Styled';
import { StoreTabPanel, StoreSettingsView } from '../Show';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { Routes, RoutesAcl } from '@services/types';
import {
  ClientModel,
  CompanyModel,
  EmployeeStatuses,
  ProfileModel,
  StoreMapper,
  StoreModel,
} from '@structure';
import { useAcl } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import { StoreUpdateButton } from '../Buttons';
import { Col, Row, Space } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Table } from '@components/lib/libV2/DataDisplay';
import { EmployeeDetailsView } from '../../Employees';
import { DepartmentDetailsView } from '../../Departments';

export function StorePage() {
  const { storeId, tabsId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { defaultCompanyUuid } = useStoredCompanies();

  const { store: storeAccess } = useAcl((acl) => acl);

  const {
    store,
    loading: storeLoading,
    error: storeError,
    handleUpdateStore,
  } = useStoredStore({
    storeUuid: storeId!,
    companyUuid: defaultCompanyUuid,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}`,
      breadcrumbName: 'Stores',
    },
    {
      path: `/${Routes.stores}/${storeId}`,
      breadcrumbName: store?.store_name || 'Show store',
    },
  ]);

  const changeFields = useCallback(
    (
      { store_settings, ...rest }: typeof STORE_REQUIRED_FIELD,
      store: StoreModel,
    ) => {
      return {
        ...(store_settings
          ? {
              store_settings: (
                <StoreSettingsView store_settings={store?.store_settings} />
              ),
            }
          : []),
        ...rest,
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    store as any,
    STORE_REQUIRED_FIELD,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.stores}`);
  }, [location?.state?.goBack, navigate]);

  return (
    <StoreTabPanel store={store}>
      {({ tabs }) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.stores]}
          headerTitle={t('Store')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <StoreUpdateButton
              store={StoreMapper.toStoreFormDTO(store!, { editMode: true })}
              onSuccess={handleUpdateStore}
            />
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitle bold fontSize={20}>
                    {store?.store_name}
                  </StyledTitle>
                </Space>
              </Col>
              <Col span={24}>
                <DetailsView
                  colors={[
                    undefined,
                    store?.manager?.status === EmployeeStatuses.DELETED
                      ? theme.colors.lightError
                      : undefined,
                  ]}
                  titles={['Department', 'Manager']}>
                  {store?.companyDepartmentModel?.uuid ? (
                    <DepartmentDetailsView
                      department={store?.companyDepartmentModel}
                    />
                  ) : null}
                  {store?.manager?.uuid ? (
                    <EmployeeDetailsView employee={store?.managerModel} />
                  ) : null}
                </DetailsView>
              </Col>
            </Row>
          }
          disabled={!storeAccess?.manage}
          editTooltip={t('Edit Store')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabsDefaultActiveKey={tabsId}
          tabs={tabs}
        />
      )}
    </StoreTabPanel>
  );
}
