import * as React from 'react';
import { List } from 'immutable';
import {
  usePriceMarginList,
  IUsePriceMarginListProps,
  IUsePriceMarginListReturnType,
} from './usePriceMarginList';
import { PriceMarginModel } from '@structure';
import { deletePriceMargin } from '@services/api/priceMargin';
import {
  setPriceMarginList as storeSetPriceMarginList,
  deletePriceMarginFromList as storeDeletePriceMargin,
  loadMorePriceMarginList as storeLoadMorePriceMarginList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStatePriceMarginListProps
  extends IUsePriceMarginListProps {}

export interface IPriceMarginSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStatePriceMarginListReturnType
  extends Omit<IUsePriceMarginListReturnType, 'entityList'> {
  priceMarginList: List<PriceMarginModel> | null;
  handleDeletePriceMargins: (ids: string[]) => Promise<void>;
  handleSearchPriceMargins: (value: IPriceMarginSearchProps) => Promise<void>;
  handleLoadMorePriceMargins: () => Promise<void>;
  handleRefreshPriceMargins: (
    value: Partial<IUsePriceMarginListProps> & { page: number },
  ) => Promise<void>;
  loadingMore: boolean;
  page: number;
}

export function useStoredPriceMarginList(
  {
    companyUuid,
    loadOnInit = true,
    ...rest
  }: IUseStatePriceMarginListProps = {} as IUseStatePriceMarginListProps,
): IUseStatePriceMarginListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedPriceMarginListStatus,
    loading: storedPriceMarginListLoading,
    priceMarginList,
    ...storedPriceMarginListParams
  } = useSelector(({ priceMarginList }: RootState) => priceMarginList);

  const storeTotal = priceMarginList?.total || 0;
  const storeKeywords = priceMarginList?.keywords || '';
  const storedPage = priceMarginList?.page || 1;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: priceMarginListLoading,
    ...priceMarginsParams
  } = usePriceMarginList({
    companyUuid,
    loadOnInit:
      loadOnInit && storedPriceMarginListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.prices) &&
      storedPriceMarginListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetPriceMarginList(entityList));
    }
  }, [dispatch, entityList, storedPriceMarginListStatus]);

  const handleDeletePriceMargins = React.useCallback(
    async (ids: string[]) => {
      try {
        await deletePriceMargin(ids);

        setTimeout(() => {
          dispatch(storeDeletePriceMargin(head(ids)));
        }, 100);
        alert('success', t('Price margin'), t('Price margin delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('PriceMargin'),
          `${t('An error occurred during delete price margin')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchPriceMargins = React.useCallback(
    async ({ limit = 10, keywords, showLoading }: IPriceMarginSearchProps) => {
      setLoadingMore(true);
      const priceMarginListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (priceMarginListModel && List.isList(priceMarginListModel?.prices)) {
        const updatedPriceMarginList = priceMarginListModel.update(
          'keywords',
          () => keywords,
        );

        dispatch(storeSetPriceMarginList(updatedPriceMarginList));
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMorePriceMargins = useCallback(async () => {
    if (
      List.isList(priceMarginList) &&
      priceMarginList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const priceMarginListModel = await refresh({
        offset: priceMarginList?.size,
        limit: 10,
        showLoading: false,
      });

      if (priceMarginListModel && List.isList(priceMarginListModel?.prices)) {
        dispatch(storeLoadMorePriceMarginList(priceMarginListModel));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, priceMarginList, storeTotal]);

  const handleRefreshPriceMargins = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUsePriceMarginListProps> & { page: number }) => {
      const priceMarginListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (priceMarginListModel && List.isList(priceMarginListModel?.prices)) {
        const updatedPriceMarginList = priceMarginListModel.update(
          'page',
          () => page,
        );

        dispatch(storeSetPriceMarginList(updatedPriceMarginList));
      }
    },
    [dispatch, refresh],
  );

  return {
    ...priceMarginsParams,
    ...storedPriceMarginListParams,
    priceMarginList: priceMarginList?.prices || null,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(priceMarginList?.prices) && priceMarginListLoading) ||
      !storedPriceMarginListLoading,
    handleDeletePriceMargins,
    handleSearchPriceMargins,
    handleLoadMorePriceMargins,
    handleRefreshPriceMargins,
    keywords: storeKeywords,
    page: storedPage,
  };
}
