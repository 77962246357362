import React from 'react';
import { Routes, Route } from 'react-router';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';
import { SettingTabPage, SETTING_TABS } from '../views/Settings';
import { SettingsModuleRoutesNavigator } from './SettingsModuleRoutesNavigator';

export function SettingsRoutesNavigator() {
  return (
    <Routes>
      {/*<Route*/}
      {/*  path=":settingId"*/}
      {/*  element={*/}
      {/*    <AppRouteLayout>*/}
      {/*      <SettingTabPage />*/}
      {/*    </AppRouteLayout>*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        path={`${SETTING_TABS.PARAMETERS}/${RoutesType.modules}/*`}
        element={<SettingsModuleRoutesNavigator />}
      />
    </Routes>
  );
}
