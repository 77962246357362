import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { FileUploader } from '@components/lib/Layout/common';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';
import {
  fileUploadStructure,
  ifElse,
  firstLetterToUppercase,
  getFirstLatter,
  correctPrice,
} from '@services/helpers';
import { ProfileModel } from '@structure';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { AccountTopUpButton } from '../Buttons';
import { IProfileBalanceParam } from '@services/api/user';

export interface IOverviewHeaderProps {
  profile: ProfileModel | null;
  handleUploadImage?: (file: File) => Promise<string>;
  disabled?: boolean;
  extra?: React.ReactNode | React.ReactNode[];
  withoutUpload?: boolean;
  className?: string;
  handleUpdateBalance?: (value: IProfileBalanceParam) => Promise<void>;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledContainer = styled.div<{ $withoutUpload: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${({ $withoutUpload }) =>
    !$withoutUpload &&
    css`
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    `}
`;

const StyledProfileContainer = styled.div<{ $withoutUpload: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${({ $withoutUpload }) =>
    !$withoutUpload &&
    css`
      margin-left: 20px;
    `}
`;

const StyledProfileBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

const StyledButtonContainer = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `}
`;

export function ProfileHeader({
  disabled,
  handleUploadImage,
  extra,
  withoutUpload = false,
  className,
  profile,
  handleUpdateBalance,
}: IOverviewHeaderProps): JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  return (
    <StyledButtonContainer $isVisible={!!profile}>
      <StyledWrapper className={className}>
        <StyledContainer className={className} $withoutUpload={withoutUpload}>
          {withoutUpload ? null : (
            <FileUploader
              title={getFirstLatter(
                firstLetterToUppercase(profile?.fullName?.trim()!),
              )}
              disabled
              onUpload={handleUploadImage}
              file={ifElse(
                !!profile?.picture_url,
                fileUploadStructure(profile?.picture_url as any),
                '',
              )}
            />
          )}

          <StyledProfileContainer $withoutUpload={withoutUpload}>
            <StyledTitle fontSize={20} bold>
              {profile?.fullName}
            </StyledTitle>
            <StyledProfileBalanceContainer>
              <StyledDescription
                fontSize={15}
                $color={
                  Number(profile?.balance) >= 0
                    ? theme.colors.success
                    : theme.colors.error
                }>
                {`${t('Balance')}(${
                  defaultCompany?.currency_symbol || 0
                }): ${correctPrice(profile?.balance)}`}
              </StyledDescription>
              {Number(profile?.balance) > 0 || Number(profile?.balance) < 0 ? (
                <StyledInfoCircleOutlined
                  $color={
                    Number(profile?.balance) >= 0
                      ? theme.colors.success
                      : theme.colors.error
                  }
                />
              ) : null}
            </StyledProfileBalanceContainer>

            <StyledTitle>{t(profile?.phone || '')}</StyledTitle>
          </StyledProfileContainer>
        </StyledContainer>
        {extra || null}
      </StyledWrapper>
      {withoutUpload ? null : (
        <AccountTopUpButton onSuccess={handleUpdateBalance!} />
      )}
    </StyledButtonContainer>
  );
}
