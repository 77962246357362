import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UploadProps } from 'antd/lib/upload';
import { greaterOrEqualThan, len } from '@services/helpers';
import { Text } from '../../Format';
import styled from 'styled-components';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

export default withTranslation()(FileUploadWithModal);

export interface IFileUploadWithModalProps
  extends WithTranslation,
    UploadProps {
  className?: string;
  uploaderClassName?: string;
  title?: string;
  loading?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lightDisable};
  overflow: hidden;
`;

const StyledUpload = styled(Upload)`
  overflow: hidden;

  && .ant-upload.ant-upload-select,
  &&&& .ant-upload-list-item.ant-upload-list-item-done {
    border: none;

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & .ant-upload-picture-card-wrapper {
    overflow: hidden;
  }

  & .ant-upload-list-item-info:before {
    content: '';
  }

  & .ant-upload,
  .ant-upload-list-item {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: transparent;
    padding: 0;
    border: none;
    overflow: hidden;
  }

  &&& .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
`;

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 30px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 40px;
`;

function FileUploadWithModal({
  t,
  className,
  fileList,
  title,
  uploaderClassName,
  loading,
  ...rest
}: IFileUploadWithModalProps) {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any): Promise<void> => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  return (
    <StyledContainer className={className}>
      <StyledUpload
        className={uploaderClassName}
        listType="picture-circle"
        accept="image/*"
        onPreview={handlePreview}
        fileList={fileList}
        {...rest}>
        {loading ? (
          <StyledLoadingOutlined />
        ) : greaterOrEqualThan(len(fileList as []), 1) ? null : (
          <StyledTitle>{title || <UploadOutlined />}</StyledTitle>
        )}
        <></>
      </StyledUpload>

      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </StyledContainer>
  );
}
