import React from 'react';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import Color from 'color';
import { InvoiceStatus } from '@structure';
import { useTranslation } from 'react-i18next';
import { firstLetterToUppercase } from '@sportix/sportix-common-modules';

export interface InvoiceStatusTagProps {
  status: InvoiceStatus;
  title?: string;
  icon?: React.ReactNode;
}

const StyledTag = styled(Tag)<{ $color: string }>`
  ${({ $color }) =>
    $color &&
    css`
      border-color: ${Color($color).alpha(0.5).darken(0.3) as any};
      background-color: ${Color($color).alpha(0.9) as any};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export function InvoiceStatusTag({
  status,
  icon,
  title,
  ...rest
}: InvoiceStatusTagProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const statusColor = {
    [InvoiceStatus.DRAFT]: theme.colors.warning,
    [InvoiceStatus.SENT]: theme.colors.link,
    [InvoiceStatus.COMMITTED]: theme.colors.success,
    [InvoiceStatus.UNKNOWN]: theme.colors.primary,
    [InvoiceStatus.ACTIVE]: theme.colors.link,
  };

  const categoryIcon = {
    [InvoiceStatus.DRAFT]: <EditOutlined />,
    [InvoiceStatus.SENT]: <ClockCircleOutlined />,
    [InvoiceStatus.COMMITTED]: <CheckCircleOutlined />,
    [InvoiceStatus.UNKNOWN]: <FileUnknownOutlined />,
    [InvoiceStatus.ACTIVE]: <CheckCircleOutlined />,
  };

  return (
    <StyledTag
      {...rest}
      $color={statusColor[status || InvoiceStatus.UNKNOWN]}
      icon={categoryIcon[status || InvoiceStatus.UNKNOWN]}>
      {firstLetterToUppercase(t(title || status || 'Unknown'))}
      {icon || null}
    </StyledTag>
  );
}
