import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import {
  ClientAbonementSubscriptionFormDTO,
  ClientAbonementModel,
} from '@structure';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import styled, { useTheme, css } from 'styled-components';

const ClientAbonementSubscriptionSideWindowLazy = React.lazy(
  () => import('../Show/ClientAbonementSubscriptionSideWindow'),
);

export interface IClientAbonementSubscriptionButtonProps
  extends IAddButtonProps {
  onSuccess: (
    value: ClientAbonementSubscriptionFormDTO,
  ) => Promise<ClientAbonementModel | void>;
  onCancel?: () => void;
  subscription: ClientAbonementSubscriptionFormDTO;
  title?: string;
  tooltipTitle?: string;
  editMode?: boolean;
}

const StyledAddButton = styled(AddButton)<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.success};

  &&& > * {
    color: ${({ theme }) => theme.colors.success};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        border-color: ${({ theme }) => theme.colors.success};
        opacity: 0.7;
      }
    `}
`;

export default function ClientAbonementSubscriptionButton({
  loading = false,
  onSuccess,
  onCancel,
  subscription,
  title,
  tooltipTitle,
  editMode,
  disabled,
  type,
  color,
  children,
  ...rest
}: IClientAbonementSubscriptionButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton
        type={disabled ? 'primary' : type || 'default'}
        tooltipTitle={tooltipTitle || t('Activate client abonement')}
        onClick={handleOnInit}
        loading={loading}
        disabled={disabled}
        {...rest}>
        {children || (
          <StyledTitle
            $color={
              disabled ? theme.colors.disable : color || theme.colors.success
            }>
            {title || t('Activate')}
          </StyledTitle>
        )}
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientAbonementSubscriptionSideWindowLazy
          subscription={subscription}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          editMode={editMode}
        />
      </Suspense>
    </>
  );
}
