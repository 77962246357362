import * as React from 'react';
import { List } from 'immutable';
import moment from 'moment';
import {
  useNotificationList,
  IUseNotificationListProps,
  IUseNotificationListReturnType,
} from './useNotificationList';
import { NotificationModel, NotificationMapper } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import { setNotificationReadStatus } from '@services/api/notification';

export interface IUseStateNotificationListProps
  extends IUseNotificationListProps {}

export interface IUseStateNotificationListReturnType
  extends Omit<IUseNotificationListReturnType, 'entityList'> {
  notifications: List<NotificationModel> | null;
  handleSetReadNotification: (notificationUuid: string) => Promise<void>;
  handleSearchNotifications: (value: any) => Promise<void>;
}

export function useStateNotificationList(
  {
    ...rest
  }: IUseStateNotificationListProps = {} as IUseStateNotificationListProps,
): IUseStateNotificationListReturnType {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: notificationListLoading,
    total: notificationListTotal,
    ...clientsParams
  } = useNotificationList({
    ...rest,
  });

  const {
    entityList: notifications,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<NotificationModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: notificationListTotal,
  });

  const handleSetReadNotification = React.useCallback(
    async (notificationUuid: string): Promise<void> => {
      const notification = await setNotificationReadStatus(notificationUuid);
      const notificationModel =
        NotificationMapper.toNotificationModel(notification);

      const updatedModel = notificationModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);
    },
    [handleCreate],
  );

  const handleSearchNotifications = React.useCallback(
    async (keywords: string) => {
      const counterparty = await refresh({ offset: 0, limit: 10, keywords });

      if (counterparty) {
        setEntityList(counterparty);
      }
    },
    [refresh, setEntityList],
  );

  return {
    notifications,
    offset,
    limit,
    refresh,
    total,
    loading: notificationListLoading || loading,
    ...clientsParams,
    handleSearchNotifications,
    handleSetReadNotification,
  };
}
