import React, { useCallback, useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useStoreService,
  useStateServiceGroupSessionList,
  useStopLoading,
  useStoredCompanies,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '@hooks';
import { eq, len, textToUpperCase } from '@services/helpers';
import { ListLayout } from '@components/lib/Layout';
import {
  GroupSessionCreateButton,
  GroupSessionDeleteButton,
  GroupSessionUpdateButton,
} from '../Buttons';
import {
  ServiceGroupSessionModel,
  ServiceGroupSessionMapper,
} from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { useAcl } from '@contex';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import styled from 'styled-components';
import { successButtonStyle } from '@components/lib/Styled';

const StyledGroupSessionCreateButton = styled(GroupSessionCreateButton)`
  ${successButtonStyle}
`;

export function GroupSessionListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const { t } = useTranslation();
  const { manage } = useAcl(({ service }) => service);
  const { defaultCompanyUuid } = useStoredCompanies();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'service-session-create-event',
    disabled: !manage,
  });

  const [selectedServices, setSelectedServices] = useState<
    ServiceGroupSessionModel[]
  >([]);

  const {
    service,
    loading: serviceLoading,
    error: serviceError,
  } = useStoreService({
    companyUuid: defaultCompanyUuid,
    serviceUuid: serviceId!,
  });

  const {
    groupSessions,
    refresh,
    loading: groupSessionsLoading,
    error: groupSessionError,
    total,
    limit,

    handleCreateServiceGroupSession,
    handleUpdateServiceGroupSession,
    handleDeleteServiceGroupSessions: onDeleteServiceGroupSessions,
    handleSearchServiceGroupSessions,
  } = useStateServiceGroupSessionList({
    serviceUuid: serviceId!,
  });

  const loading = useStopLoading({
    loading: serviceLoading || groupSessionsLoading,
    error: serviceError || groupSessionError,
    message: 'An error occurred during services loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteServiceGroupSessions = useCallback(
    async (value: string[]) => {
      await onDeleteServiceGroupSessions(value);
      focus();
    },
    [focus, onDeleteServiceGroupSessions],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedServices.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ServiceGroupSessionModel[],
      ) => {
        setSelectedServices(selectedRecords);
      },
    }),
    [selectedServices],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.services}`,
      breadcrumbName: 'Services',
    },
    {
      path: `/${Routes.services}/${serviceId}`,
      breadcrumbName: location?.state?.serviceTitle || 'Show Service',
    },
    {
      path: `/${Routes.services}/${serviceId}/${Routes.services}`,
      breadcrumbName: 'Group sessions',
    },
  ]);

  const columns = [
    {
      title: t('Name'),
      key: 'name',
      render: (service: ServiceGroupSessionModel) => service?.title,
    },
    {
      ellipsis: true,
      title: t('Price'),
      key: 'price',
      render: (service: ServiceGroupSessionModel) => service?.price,
    },
    {
      title: t('Actions'),
      key: 'service_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (service: ServiceGroupSessionModel) => (
        <TableActionCell>
          <GroupSessionUpdateButton
            disabled={!manage}
            groupSession={ServiceGroupSessionMapper.toServiceGroupSessionFormDTO(
              service,
            )}
            onSuccess={handleUpdateServiceGroupSession}
            companyUuid={defaultCompanyUuid}
            onCancel={daleyFocus}
          />
          <GroupSessionDeleteButton
            disabled={!manage}
            groupSessions={[service]}
            onSuccess={handleDeleteServiceGroupSessions}>
            <DeleteOutlined />
          </GroupSessionDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.services}/${serviceId}`);
  }, [navigate, serviceId]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.services]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.groupSessions]))}
      headerRight={
        <>
          {selectedServices?.length ? (
            <GroupSessionDeleteButton
              disabled={!manage || eq(len(selectedServices), 0)}
              groupSessions={selectedServices}
              onSuccess={handleDeleteServiceGroupSessions}
            />
          ) : (
            <GroupSessionCreateButton
              onSuccess={handleCreateServiceGroupSession}
              loading={groupSessionsLoading}
              service={service}
              disabled={!manage}
              serviceUuid={serviceId!}
              companyUuid={defaultCompanyUuid}
              className="service-session-create-event"
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        groupSessions?.size ? null : (
          <Empty
            description={t(
              "It looks like you don't have any group sessions at the moment.",
            )}>
            <GroupSessionCreateButton
              type="primary"
              onSuccess={handleCreateServiceGroupSession}
              loading={groupSessionsLoading}
              service={service}
              disabled={!manage}
              serviceUuid={serviceId!}
              companyUuid={defaultCompanyUuid}
              title={t('Add group session')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={handleSearchServiceGroupSessions}
          inputTooltip={t('Search service group sessions')}
          inputLabel={t('Search service group sessions')}
          withSearchContent={
            <StyledGroupSessionCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              companyUuid={defaultCompanyUuid}
              onSuccess={handleCreateServiceGroupSession}
              title={t('Create')}
              icon={<PlusOutlined />}
              service={service}
              serviceUuid={serviceId!}
              onCancel={daleyFocus}
            />
          }
        />
      }
      loading={loading && !groupSessions?.size}>
      <Table<ServiceGroupSessionModel>
        pageSize={limit}
        total={total}
        onChange={refresh}
        rowSelection={rowSelection}
        dataSource={groupSessions}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
