import React from 'react';
import { Col, Form, Input } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';

export default withTranslation()(ClientServiceTransitionForm);

export interface IClientServiceTransitionFormProps extends WithTranslation {}

function ClientServiceTransitionForm({
  t,
}: IClientServiceTransitionFormProps): JSX.Element {
  const { getFieldValue } = useDefaultForm();

  const moveToPersonalAccount = getFieldValue('moveToPersonalAccount');

  return (
    <>
      <Col span={moveToPersonalAccount ? 12 : 24}>
        <Form.Item
          label={t('Balance from previous subscription')}
          name="balanceFromPreviousSubscription"
          extra={t('Balance from previous subscription after change service')}
          tooltip={{
            title: t('Balance from previous subscription'),
          }}>
          <Input
            data-testid="client-services-previous-balance"
            disabled
            addonAfter={t('UAH')}
          />
        </Form.Item>
      </Col>
      {moveToPersonalAccount ? (
        <Col span={moveToPersonalAccount ? 12 : 24}>
          <Form.Item
            label={t('Move to personal account')}
            name="moveToPersonalAccount"
            extra={t('Move to personal account after change service')}
            tooltip={{
              title: t('Transfer money to a personal account'),
            }}>
            <Input
              data-testid="client-services-move-to-account"
              disabled
              addonAfter={t('UAH')}
            />
          </Form.Item>
        </Col>
      ) : null}
    </>
  );
}
