import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/lib/button';
import { ORDER_INITIAL_PARAM } from '@services/api/orders';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { isFunction } from '@services/helpers';
import { useCalendarDnd } from '@contex';
import { ScheduleCalendarParentDTO, ScheduleCalendarFormDTO } from '@structure';
import { useStoredProfile } from '@hooks';

const OrderSideWindowLazy = React.lazy(() => import('../Show/OrderSideWindow'));

export interface ICreateOrderButtonProps extends ButtonProps {
  onSuccess?: (value: ScheduleCalendarFormDTO) => Promise<void>;
  onCancel?: () => void;
  companyUuid: string;
  children?: React.ReactNode;
  order?: Partial<ScheduleCalendarFormDTO>;
  disabledClient?: boolean;
  tooltipTitle?: string | null;
  relatedOrder?: ScheduleCalendarParentDTO | null;
}

export default function CreateOrderButton({
  loading = false,
  onSuccess,
  companyUuid,
  onCancel,
  order = {} as ScheduleCalendarFormDTO,
  disabledClient,
  tooltipTitle,
  relatedOrder,
  onClick,
  ...rest
}: ICreateOrderButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { dndCache, handleUpdateDnDData } = useCalendarDnd();
  const { profile } = useStoredProfile();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  const state = relatedOrder
    ? {
        scheduled_date: relatedOrder?.scheduled_date,
        parent_uuid: relatedOrder?.uuid,
        parent_number: relatedOrder?.schedule_number,
        parent_date: relatedOrder?.scheduled_date,
        client: { uuid: relatedOrder?.client?.uuid },
      }
    : {};

  return (
    <>
      <AddButton
        tooltipTitle={tooltipTitle === null ? null : t('Create company order')}
        onClick={
          isFunction(onSuccess)
            ? (e: any) => {
                if (isFunction(onClick)) {
                  onClick(e);
                }
                handleOnInit();
              }
            : undefined
        }
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <OrderSideWindowLazy
          order={{
            ...ORDER_INITIAL_PARAM,
            ...order,
            ...state,
            worker_uuid: profile?.defaultEmployeeModel,
          }}
          companyUuid={companyUuid}
          loading={!!loading}
          onSuccess={async (value) => {
            await handleSuccess(value);
            dndCache.clear();
            handleUpdateDnDData({ dndEvents: null as any });
          }}
          onCancel={handleCancel}
          visible={visible}
          disabledClient={disabledClient}
          isHasStrictMode={false}
        />
      </Suspense>
    </>
  );
}
