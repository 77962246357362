import React, { useCallback } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { multiply, compose, correctPrice } from '@services/helpers';
import { useDefaultForm } from '@contex';
import { useStoredCompanies } from '@hooks';
import styled, { css } from 'styled-components';

const StyledTotalInput = styled(Input)<{ $loadingSubmit: boolean }>`
  &&& .ant-input {
    font-size: 27px;
    color: ${({ theme }) => theme.colors.text_colors.secondary};
  }

  &&& .ant-input-group-addon {
    font-size: 27px;
    color: ${({ theme }) => theme.colors.text_colors.secondary};
  }

  ${({ $loadingSubmit }) =>
    $loadingSubmit &&
    css`
      &&& .ant-input {
        color: ${({ theme }) => theme.ant.colorTextDisabled};
      }

      &&& .ant-input-group-addon {
        color: ${({ theme }) => theme.ant.colorTextDisabled};
      }
    `}
`;
export default function MaterialAndExpenseFields(): JSX.Element {
  const { t } = useTranslation();

  const { loadingSubmit, setFieldsValue, getFieldValue } = useDefaultForm();

  const { defaultCompany } = useStoredCompanies();

  const handleUpdateAmount = useCallback(
    (amount: string): void => {
      const price = getFieldValue('price');

      if (amount && !Number.isNaN(Number(amount))) {
        setFieldsValue({
          total: compose(
            correctPrice,
            multiply(Number.parseFloat(`${amount}`)),
          )(Number.parseFloat(price)),
        });
      }
    },
    [getFieldValue, setFieldsValue],
  );

  const handleUpdatePrice = useCallback(
    (price: string): void => {
      const amount = getFieldValue('amount');

      if (price && !Number.isNaN(Number(price))) {
        setFieldsValue({
          total: compose(
            correctPrice,
            multiply(Number.parseFloat(`${price}`)),
          )(Number.parseFloat(amount)),
        });
      }
    },
    [getFieldValue, setFieldsValue],
  );

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          label={t('name-s')}
          name="name"
          rules={[
            {
              required: true,
              message: t('Name must be specified.'),
            },
          ]}>
          <Input disabled={loadingSubmit} placeholder={t('Enter name')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={t('Price')}
          name="amount"
          rules={[
            {
              required: true,
              message: t('Amount must be specified.'),
            },
            {
              transform: (value) => Number(value),
              type: 'number',
              message: t('Must be a number'),
            },
          ]}>
          <Input
            disabled={loadingSubmit}
            placeholder={t('Enter amount')}
            onChange={(e) => handleUpdateAmount(e.target.value)}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Number of units')}
          name="price"
          rules={[
            {
              required: true,
              message: t('Number of units must be specified'),
            },
            {
              transform: (value) => Number(value),
              type: 'number',
              message: t('Must be a number'),
            },
          ]}>
          <Input
            autoComplete="off"
            disabled={loadingSubmit}
            placeholder={t('Enter related service quantity')}
            onChange={(e) => handleUpdatePrice(e.target.value)}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Total')} name="total">
          <StyledTotalInput
            addonBefore={defaultCompany?.currency_symbol}
            disabled
            $loadingSubmit={loadingSubmit}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
