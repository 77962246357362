import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Empty, Form, Select } from 'antd';
import {
  PriceMarginModel,
  ProductMapper,
  ProductModel,
  StoreDocumentItemFormDTO,
  StoreDocumentItemModel,
  StoreDocumentMapper,
} from '@structure';
import { StoreDocItemsField } from './StoreDocItemsField';
import styled from 'styled-components';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useDefaultForm, useDropdownAlert } from '@contex';
import { correctPrice, head, isFunction } from '@services/helpers';
import { useModal } from '@components/lib/libV2/hooks';
import { ProductTableView } from '../Show';
import { StyledTitle } from '@components/lib/Styled';

import './StoreProductListField.less';
import '../../Orders/List/OrderList.less';
import { ENTER_KEYS } from '@services/const';
import { DocumentItemReturnType } from '@hooks';

const StoreProductAmountModal = React.lazy(
  () => import('../Show/StoreProductAmountModal'),
);

export interface IStoreDocItemProductListFieldProps {
  loading: boolean;
  editMode: boolean;
  defaultPriceMargin: PriceMarginModel | null;
  onSuccess?: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;
  onDelete?: (ids: string[]) => Promise<void>;
  default_doc_items: StoreDocumentItemFormDTO[];
}

const StyledTable = styled(Table)`
  margin: 0;
` as any;

const StyledProductListCol = styled(Col)`
  background: ${({ theme }) => theme.background.layout};
  padding: 5px;
  border-radius: 7px;
`;

const StyledProductListContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

export function StoreDocItemProductListField({
  loading,
  editMode,

  defaultPriceMargin,
  onSuccess,
  onDelete: handleDelete,

  default_doc_items,
}: IStoreDocItemProductListFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { loadingSubmit, formData, handlerUpdateFormState } = useDefaultForm();
  const { alert } = useDropdownAlert();

  const tableRef = useRef<HTMLTableElement>(null);
  const selectRef = useRef<HTMLInputElement>(null);

  const [selectedProductItem, setSelectedProductItem] =
    useState<StoreDocumentItemFormDTO | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [storeProductList, setStoreProductList] = useState<
    ProductModel[] | null
  >(null);

  const getProductPriceMargin = useCallback(
    (product: ProductModel, priceMargin: PriceMarginModel | null) => {
      if (!priceMargin) {
        return correctPrice(product?.product_prices[0]?.price_value || 0);
      }

      const price = product?.product_prices?.find(
        ({ price_uuid }) => price_uuid === priceMargin?.uuid,
      );

      return correctPrice(price?.price_value || 0);
    },
    [],
  );

  const onDelete = useCallback(
    async (ids: string[]) => {
      if (isFunction(handleDelete)) {
        await handleDelete(ids);
      }

      const item = default_doc_items?.find(
        ({ product }) => product?.uuid === head(ids),
      );

      if (item && item?.product !== null) {
        setStoreProductList((prevState) => [
          ...(prevState || []),
          ProductMapper?.toProductModel(item.product as any).update(
            'product_balances',
            (product_balances) => {
              const balance = product_balances?.shift();

              return [
                { ...balance, balance_total: item?.product_amount },
                ...product_balances,
              ] as any;
            },
          ),
        ]);
      }
    },
    [default_doc_items, handleDelete],
  );

  const handledSuccess = useCallback(
    async (
      docItem: StoreDocumentItemFormDTO,
    ): Promise<StoreDocumentItemFormDTO | void> => {
      if (isFunction(onSuccess)) {
        try {
          const documentItemModel = await onSuccess(docItem);

          const { documentItem: item } = documentItemModel || {};

          if (item) {
            const itemForm = StoreDocumentMapper.toStoreDocumentItemFormDTO(
              item,
              {
                editMode: true,
                price: '0.00',
              },
            );

            alert(
              'success',
              t('Store document item'),
              t('Document item edit success'),
            );
            return itemForm;
          }
        } catch (error: any) {
          alert(
            'error',
            t('Store document item'),
            `${
              editMode
                ? t('An error occurred during edit document item')
                : t('An error occurred during create document item')
            } : ${error?.message}`,
          );
        }
      }
    },
    [alert, editMode, onSuccess, t],
  );

  const handleAddOrUpdateDocItems = useCallback(
    async (docItem: StoreDocumentItemFormDTO) => {
      let docItems = formData?.doc_items || [];

      const index = docItems?.findIndex(
        ({ product_uuid }: any) => product_uuid === docItem?.product_uuid,
      );

      if (isFunction(onSuccess)) {
        const updatedItem = await handledSuccess(docItem);

        if (updatedItem) {
          docItem = updatedItem;
        } else {
          return;
        }
      }

      if (~index) {
        docItems = docItems.map((item: any) => {
          if (item?.product_uuid === docItem?.product_uuid) {
            return docItem;
          }
          return item;
        });
      } else {
        docItems = [...docItems, docItem];
      }

      handlerUpdateFormState({ doc_items: docItems });

      selectRef?.current?.focus();

      setStoreProductList((prevState) =>
        (prevState || [])?.filter(({ uuid }) => uuid !== docItem?.uuid),
      );
    },
    [formData?.doc_items, handledSuccess, handlerUpdateFormState, onSuccess],
  );

  const {
    visible: amountVisible,
    handleSuccess: amountHandleSuccess,
    handleCancel: amountHandleCancel,
    handleOnInit: amountHandleOnInit,
  } = useModal({
    onSuccess: handleAddOrUpdateDocItems,
  });

  const handleInitModal = useCallback(() => {
    amountHandleOnInit();

    setSelectedProductIndex(0);
  }, [amountHandleOnInit]);

  const handleSetSelectedProductItem = useCallback(
    (product: any) => {
      setSelectedProductItem(
        StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          price: getProductPriceMargin(product, defaultPriceMargin),
          max_amount:
            (head(product?.product_balances) as any)?.balance_total ||
            undefined,
        }),
      );
    },
    [defaultPriceMargin, getProductPriceMargin],
  );

  const onProductSelect = useCallback(
    (record: any) => {
      handleSetSelectedProductItem(record);

      handleInitModal();
    },
    [handleInitModal, handleSetSelectedProductItem],
  );

  const handleEditDocItem = useCallback(
    (docItem: StoreDocumentItemFormDTO) => {
      setSelectedProductItem(docItem);

      handleInitModal();
    },
    [handleInitModal],
  );

  const columns = useMemo(
    () => [
      {
        title: t('name-s'),
        key: 'name',
        className: 'order-list-td',
        render: (product: ProductModel) => (
          <ProductTableView
            product={product}
            price={getProductPriceMargin(product, defaultPriceMargin)}
          />
        ),
      },
      {
        title: t('tAmount'),
        key: 'amount',
        align: 'right' as any,
        render: (product: ProductModel) => (
          <StyledTitle fontSize={18}>
            {head(product?.product_balances)?.balance_total}
          </StyledTitle>
        ),
      },
    ],
    [defaultPriceMargin, getProductPriceMargin, t],
  );

  useEffect(() => {
    if (default_doc_items?.length && storeProductList === null) {
      const product = default_doc_items[0]?.product;

      if (product) {
        handleSetSelectedProductItem(
          ProductMapper.toProductModel(product as any).update(
            'product_balances',
            (product_balances) => {
              const balance = product_balances?.shift();

              return [
                {
                  ...balance,
                  balance_total: default_doc_items[0]?.product_amount,
                },
                ...product_balances,
              ] as any;
            },
          ),
        );
      }

      const items = default_doc_items?.map(({ product, product_amount }) => {
        const productModel = ProductMapper.toProductModel(product as any);

        return productModel.update('product_balances', (product_balances) => {
          const balance = product_balances?.shift();

          return [
            { ...balance, balance_total: product_amount },
            ...product_balances,
          ] as any;
        });
      });

      setStoreProductList(items);
    }
  }, [default_doc_items, handleSetSelectedProductItem, storeProductList]);

  const onTableKeyboardChange = useCallback(
    (e: KeyboardEvent) => {
      if (storeProductList?.length) {
        const length = storeProductList?.length - 1;

        if (e.key === 'ArrowUp' && selectedProductIndex === 0) {
          const product = storeProductList[length];
          if (product) {
            handleSetSelectedProductItem(product);
            setSelectedProductIndex(() => length);
          }
          return;
        }

        if (e.key === 'ArrowUp' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex - 1];

          if (product) {
            handleSetSelectedProductItem(product);
            setSelectedProductIndex((prevState) => --prevState);
          }
          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex === length) {
          const product = storeProductList[0];
          if (product) {
            handleSetSelectedProductItem(product);
            setSelectedProductIndex(() => 0);
          }
          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex + 1];
          if (product) {
            handleSetSelectedProductItem(product);
            setSelectedProductIndex((prevState) => ++prevState);
          }
          return;
        }

        if (ENTER_KEYS.includes(e.key)) {
          handleInitModal();
        }
      }
    },

    [
      handleInitModal,
      handleSetSelectedProductItem,
      selectedProductIndex,
      storeProductList,
    ],
  );

  useEffect(() => {
    if (storeProductList?.length) {
      document.addEventListener('keydown', onTableKeyboardChange);

      return () => {
        document.removeEventListener('keydown', onTableKeyboardChange);
      };
    }
  }, [onTableKeyboardChange, storeProductList?.length]);

  return (
    <>
      <StyledProductListContainer ref={tableRef}>
        <StyledProductListCol span={24}>
          <Form.Item
            required
            label={isFunction(onSuccess) ? undefined : t('List of products')}
            name="doc"
            tooltip={isFunction(onSuccess) ? undefined : t('List of products')}>
            <Select
              ref={selectRef as any}
              loading={false}
              disabled={loadingSubmit}
              showSearch={false}
              placeholder={t('Enter product details to search for it')}
              notFoundContent={
                <>
                  {!storeProductList?.length ? (
                    <Empty
                      description={t(
                        'It seems that more than one product was not found for your request',
                      )}
                    />
                  ) : (
                    <StyledTable
                      className="posting-list-form"
                      pagination={false}
                      pageSize={Infinity}
                      total={storeProductList?.length}
                      dataSource={storeProductList}
                      columns={columns}
                      loading={loading}
                      onRow={(record: ProductModel, rowIndex: number) => ({
                        onClick: () => {
                          onProductSelect(record);
                        },
                        className: `posting-list-td ${
                          record?.uuid === selectedProductItem?.product_uuid &&
                          selectedProductIndex === rowIndex
                            ? 'posting-list-td--selected'
                            : ''
                        }`,
                      })}
                    />
                  )}
                </>
              }
            />
          </Form.Item>

          <StoreDocItemsField
            handleEditDocItem={handleEditDocItem}
            handleDeleteItem={onDelete}
            handleEditItem={handledSuccess}
            editMode={false}
          />
        </StyledProductListCol>
      </StyledProductListContainer>

      <StoreProductAmountModal
        product={selectedProductItem as any}
        visible={amountVisible}
        onSuccess={amountHandleSuccess}
        onCancel={amountHandleCancel}
      />
    </>
  );
}
