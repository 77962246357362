import React from 'react';
import styled, { css } from 'styled-components';
import RNImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';

export interface ImageGallery extends ReactImageGalleryProps {
  iconSize?: number;
}

import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button } from './index';

const StyledNavButton = styled(Button)<{ $isRight?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  height: fit-content;
  z-index: 4;

  ${({ $isRight }) =>
    $isRight &&
    css`
      right: 0;
    `};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;

    & * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`;

const StyledFullScreenButton = styled(Button)`
  position: absolute;
  width: fit-content;
  height: fit-content;
  z-index: 4;
  padding: 20px;

  right: 0;
  bottom: 0;

  &&&:hover,
  &&&:focus {
    background-color: transparent;

    & * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`;

const iconStyle = css<{ size: number }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ size }) => `${size}px`};
`;

const StyledLeftOutlined = styled(LeftOutlined)`
  ${iconStyle}
`;

const StyledRightOutlined = styled(RightOutlined)`
  ${iconStyle}
`;

const StyledFullscreenExitOutlined = styled(FullscreenExitOutlined)`
  ${iconStyle};
  color: ${({ theme }) => theme.colors.white};
`;

const StyledFullscreenOutlined = styled(FullscreenOutlined)`
  ${iconStyle}
`;

export function ImageGallery({
  iconSize = 25,
  ...rest
}: ImageGallery): React.JSX.Element {
  return (
    <RNImageGallery
      {...rest}
      renderLeftNav={(onClick, disabled) => (
        <StyledNavButton onClick={onClick} disabled={disabled} type="text">
          <StyledLeftOutlined size={iconSize} />
        </StyledNavButton>
      )}
      renderRightNav={(onClick, disabled) => (
        <StyledNavButton
          $isRight
          onClick={onClick}
          disabled={disabled}
          type="text">
          <StyledRightOutlined size={iconSize} />
        </StyledNavButton>
      )}
      renderFullscreenButton={(onClick, isFullscreen) => (
        <StyledFullScreenButton onClick={onClick} type="text">
          {isFullscreen ? (
            <StyledFullscreenExitOutlined size={25} />
          ) : (
            <StyledFullscreenOutlined size={iconSize} />
          )}
        </StyledFullScreenButton>
      )}
    />
  );
}
