import React, { useMemo, useState } from 'react';
import {
  ColorsSchemaType,
  IDefaultTabPanelProps,
} from '@components/lib/General';
import {
  IUseStateClientAbonementScheduleListReturnType,
  IUseStateClientAbonementSubscriptionListReturnType,
  IUseStateClientVisitListReturnType,
} from '@hooks';
import { useTranslation } from 'react-i18next';
import { ClientAbonementSubscriptionsList, ClientVisitsList } from '../List';
import {
  ClientAbonementLatestStatusDTO,
  ClientAbonementMapper,
  ClientAbonementModel,
  ScheduleCalendarMapper,
} from '@structure';
import { IoWarningOutline } from 'react-icons/io5';
import { MdBlock } from 'react-icons/md';
import styled, { useTheme, css } from 'styled-components';
import { ScheduleCalendarProps, toDateByFormat } from '@services/helpers';
import { Row, Col } from 'antd';
import {
  ClientAbonementSubscriptionButton,
  ClientAbonementScheduleButton,
} from '../Buttons';
import { StyledTitle } from '@components/lib/Styled';
import { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import { ClientAbonementScheduleView } from './ClientAbonementScheduleView';
import { IBreadcrumbProps, Route } from '@components/lib/DataDisplay';

export enum ABONEMENT_TABS {
  ITERATION = 'Activity',
  SUBSCRIPTIONS_ALERTS = 'Subscriptions',
  STATUS = 'Status',
  SCHEDULES = 'Class schedules',
}

export interface IClientAbonementTabPanelProps
  extends Pick<
      IUseStateClientAbonementSubscriptionListReturnType,
      | 'subscriptionList'
      | 'handleRefreshClientAbonementSubscriptions'
      | 'handleCreateClientAbonementSubscription'
    >,
    Pick<
      IUseStateClientVisitListReturnType,
      | 'visitList'
      | 'handleRefreshClientVisits'
      | 'handleCreateClientVisit'
      | 'handleEditClientVisit'
      | 'handleDeleteClientVisits'
    >,
    Pick<
      IUseStateClientAbonementScheduleListReturnType,
      | 'scheduleList'
      | 'handleRefreshClientAbonementSchedules'
      | 'handleFormingClientAbonementSchedule'
      | 'isShowClassSchedule'
    > {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & {
      topTabs?: IDefaultTabPanelProps['tabs'];
      headerTabs?: IDefaultTabPanelProps['tabs'];
    },
  ) => React.ReactNode;
  abonement: ClientAbonementModel | null;
  latestStatus: ClientAbonementLatestStatusDTO | null;
  disabled: boolean;
  isShowSchedules: boolean;
  routes: Route[];

  subscriptionListLimit: number;
  subscriptionListPage: number;
  subscriptionListLoading: boolean;

  visitListLimit: number;
  visitListLoading: boolean;
  visitTotal: number;
  visitPage: number;

  schedulesLoading: boolean;
  formingScheduleListLoading: boolean;
  isStartRefreshSchedules: boolean;
}

const StyledClientAbonementSubscriptionButton = styled(
  ClientAbonementSubscriptionButton,
)<{ disabled: boolean }>`
  width: fit-content;
  padding: 0;
  margin: 0;
  border: none;

  &&& > * {
    color: #00b9ff;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &&& > * {
        color: ${({ theme }) => theme.colors.white};
        border: none;
      }
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 0.7;
      }
    `}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  &&& {
    color: ${({ theme }) => theme.colors.primary} !important;
    font-size: 20px !important;
  }
`;

export function ClientAbonementTabPanel({
  children,
  abonement,
  latestStatus,
  disabled,
  routes,

  subscriptionList,
  subscriptionListLimit,
  subscriptionListPage,
  subscriptionListLoading,
  handleCreateClientAbonementSubscription,
  handleRefreshClientAbonementSubscriptions,

  visitList,
  visitListLoading,
  visitListLimit,
  visitTotal,
  visitPage,
  handleCreateClientVisit,
  handleEditClientVisit,
  handleDeleteClientVisits,
  handleRefreshClientVisits,

  scheduleList,
  schedulesLoading,
  formingScheduleListLoading,
  isStartRefreshSchedules,
  isShowClassSchedule,
  handleRefreshClientAbonementSchedules,
  handleFormingClientAbonementSchedule,
}: IClientAbonementTabPanelProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [visible, setVisible] = useState(false);

  const topTabs = [
    {
      key: ABONEMENT_TABS.ITERATION,
      defaultActiveKey: ABONEMENT_TABS.ITERATION,
      title: t(ABONEMENT_TABS.ITERATION),
      index: 0,
      renderer: () => {
        return (
          <Row gutter={20}>
            <Col>
              <ClientAbonementSubscriptionsList
                handleRefreshClientAbonementSubscriptions={
                  handleRefreshClientAbonementSubscriptions
                }
                loading={subscriptionListLoading}
                limit={subscriptionListLimit}
                page={subscriptionListPage}
                subscriptionList={subscriptionList}
                handleCreateClientAbonementSubscription={
                  handleCreateClientAbonementSubscription
                }
                abonement={abonement}
                disabled={disabled}
              />
            </Col>
            <Col>
              <ClientVisitsList
                visitList={visitList}
                loading={visitListLoading}
                limit={visitListLimit}
                total={visitTotal}
                page={visitPage}
                handleCreateClientVisit={handleCreateClientVisit}
                handleRefreshClientVisits={handleRefreshClientVisits}
                handleEditClientVisit={handleEditClientVisit}
                handleDeleteClientVisits={handleDeleteClientVisits}
                abonement={abonement}
                disabled={!subscriptionList?.size || disabled}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const headerTabs = [
    ...(abonement?.isSubscriptionWillDateExpired ||
    abonement?.isSubscriptionDateExpired
      ? [
          {
            key: ABONEMENT_TABS.SUBSCRIPTIONS_ALERTS,
            defaultActiveKey: ABONEMENT_TABS.SUBSCRIPTIONS_ALERTS,
            title: abonement?.isSubscriptionWillDateExpired
              ? t('Subscription is expiring')
              : t('Subscription is expired'),
            index: 0,
            description: abonement?.isSubscriptionWillDateExpired ? (
              `${t(
                'Subscription will expire in ',
              )} ${abonement?.subscriptionExpiredAt(t)}`
            ) : (
              <StyledClientAbonementSubscriptionButton
                disabled={disabled}
                type={disabled ? 'primary' : 'text'}
                onSuccess={handleCreateClientAbonementSubscription}
                subscription={ClientAbonementMapper.toClientAbonementSubscriptionFormDTO(
                  abonement!,
                )}
                editMode>
                <StyledTitle>
                  {`${t('Expires on')}${
                    toDateByFormat(
                      abonement?.abon_end_date,
                      'YYYY-MM-DD HH:mm',
                    ).includes('Invalid')
                      ? ''
                      : ` ${toDateByFormat(
                          abonement?.abon_end_date,
                          'YYYY-MM-DD HH:mm',
                        )}`
                  }, ${t(
                    'in order to activate the subscription to the season ticket, follow the link',
                  )}`}
                  {'   '}
                  <RightOutlined />
                </StyledTitle>
              </StyledClientAbonementSubscriptionButton>
            ),
            buttonIcon: abonement?.isSubscriptionWillDateExpired ? (
              <IoWarningOutline size={24} color={theme.colors.white} />
            ) : (
              <MdBlock size={24} color={theme.colors.white} />
            ),
            type: (abonement?.isSubscriptionWillDateExpired
              ? 'warning'
              : 'error') as ColorsSchemaType,
            renderer: undefined as any,
          },
        ]
      : []),
  ];

  const abonementTabs: any = [
    ...(isStartRefreshSchedules
      ? [
          {
            key: ABONEMENT_TABS.SCHEDULES,
            defaultActiveKey: ABONEMENT_TABS.SCHEDULES,
            title: t(ABONEMENT_TABS.SCHEDULES),
            disabled: !isShowClassSchedule,
            buttonIcon: formingScheduleListLoading ? (
              <StyledLoadingOutlined />
            ) : !isShowClassSchedule ? undefined : (
              () => null
            ),
            stopPropagation: visible,
            extraHeader: isShowClassSchedule ? (
              <ClientAbonementScheduleButton
                disabled={formingScheduleListLoading}
                schedule={ScheduleCalendarMapper.toClientAbonementScheduleFormDTO(
                  abonement!,
                  true,
                )}
                onSuccess={handleFormingClientAbonementSchedule}
                editMode
                setVisible={setVisible}
              />
            ) : null,
            renderer: () => {
              return (
                <ClientAbonementScheduleView
                  scheduleList={scheduleList}
                  schedulesLoading={schedulesLoading}
                  handleRefreshClientAbonementSchedules={
                    handleRefreshClientAbonementSchedules
                  }
                  isStartRefreshSchedules={isStartRefreshSchedules}
                  disabled={formingScheduleListLoading}
                  routes={routes}
                />
              );
            },
          },
        ]
      : []),
  ];

  return <>{children({ tabs: abonementTabs, topTabs, headerTabs })}</>;
}
