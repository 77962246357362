import React from 'react';
import styled from 'styled-components';

const StyledPastDatesView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.disable};
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

export default function PastDates(): JSX.Element {
  return <StyledPastDatesView />;
}
