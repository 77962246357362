import { correctPrice } from '@services/helpers';

export enum ClientRefundsType {
  Date = 'date',
  Visit = 'visit',
}

export interface IClientRefundsDTOProps {
  sum: string;
  unused_days: number;
  unused_visits: number;
  type: ClientRefundsType;
}

export class ClientRefundsDTO {
  public sum: string;
  public unused_days: number;
  public unused_visits: number;
  public type: ClientRefundsType;

  constructor(props: IClientRefundsDTOProps) {
    this.sum = correctPrice(props?.sum || 0);
    this.unused_days = props?.unused_days || 0;
    this.unused_visits = props?.unused_visits || 0;
    this.type = props?.type || ClientRefundsType.Date;
  }
}
