import React, { useCallback } from 'react';
import { Empty, Form, Col } from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import { UnitPackaging } from '@structure';
import { useTranslation } from 'react-i18next';
import {
  EditableCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import { useDefaultForm } from '@contex';
import styled from 'styled-components';
import { DeleteButton, Button } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { TextFormat } from '@components/lib/Format';
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined } from '@ant-design/icons';

export interface IProductCategoryUnitPackagingFieldProps {
  editMode?: boolean;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<UnitPackaging>>;

const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

export function ProductCategoryUnitPackagingField({
  editMode,
}: IProductCategoryUnitPackagingFieldProps): React.JSX.Element {
  const { t } = useTranslation();
  const { formData, handlerUpdateFormState, loadingSubmit }: any =
    useDefaultForm();

  const validateListBaseUnitCoefficient =
    formData?.unit_packaging?.map((_: any, index: number) => [
      'unit_packaging',
      index,
      'base_unit_coefficient',
    ]) || [];

  const validateListUnit =
    formData?.unit_packaging?.map((_: any, index: number) => [
      'unit_packaging',
      index,
      'unit',
    ]) || [];

  const validateListBaseCostCoefficient =
    formData?.unit_packaging?.map((_: any, index: number) => [
      'unit_packaging',
      index,
      'base_cost_coefficient',
    ]) || [];

  const handleDeleteDocItem = useCallback(
    (packaging: UnitPackaging) => {
      const filteredDocItems = formData?.unit_packaging
        ?.filter(({ uuid }: UnitPackaging) => uuid !== packaging?.uuid)
        .map((packaging: UnitPackaging, index: number) => ({
          ...packaging,
          index,
        }));

      handlerUpdateFormState({ unit_packaging: filteredDocItems });
    },
    [formData?.unit_packaging, handlerUpdateFormState],
  );

  const handleAddUnitPackage = useCallback(() => {
    handlerUpdateFormState({
      unit_packaging: [
        ...(formData?.unit_packaging || []),
        {
          unit: '',
          base_unit_coefficient: 0,
          base_cost_coefficient: 0,
          uuid: uuidv4(),
          index: formData?.unit_packaging?.length,
        },
      ],
    });
  }, [formData?.unit_packaging, handlerUpdateFormState]);

  const defaultColumns = [
    {
      title: t('Title'),
      key: 'unit',
      width: '30%',
      parent: 'unit_packaging',
      autoFocus: !editMode,
      editable: !loadingSubmit,
      validateList: [...validateListUnit, 'unit'],
      rules: [
        () => ({
          validator(_: any, unit: string) {
            const value = unit;

            if (!value) {
              return Promise.reject(new Error(t('Title must be specified.')));
            }

            return Promise.resolve();
          },
        }),
      ],

      render: (packaging: UnitPackaging) => packaging?.unit,
    },
    {
      title: t('Measurement factor'),
      key: 'base_unit_coefficient',
      width: '30%',
      parent: 'unit_packaging',
      editable: !loadingSubmit,
      validateList: [
        ...validateListBaseUnitCoefficient,
        'base_unit_coefficient',
      ],
      rules: [
        () => ({
          validator(_: any, base_unit_coefficient: string) {
            const value = base_unit_coefficient;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('Measurement factor must be greater than 1')),
              );
            }

            if (
              Number.isNaN(Number(value)) &&
              base_unit_coefficient !== undefined
            ) {
              return Promise.reject(
                new Error(t('Measurement factor must be a number')),
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      align: 'right' as any,
      render: (packaging: UnitPackaging) => packaging?.base_unit_coefficient,
    },
    {
      title: t('Cost factor'),
      key: 'base_cost_coefficient',
      width: '30%',
      parent: 'unit_packaging',
      editable: !loadingSubmit,
      validateList: [
        ...validateListBaseCostCoefficient,
        'base_cost_coefficient',
      ],
      align: 'right' as any,
      rules: [
        () => ({
          validator(_: any, base_unit_coefficient: string) {
            const value = base_unit_coefficient;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('Cost facto must be greater than 1')),
              );
            }

            if (
              Number.isNaN(Number(value)) &&
              base_unit_coefficient !== undefined
            ) {
              return Promise.reject(
                new Error(t('Cost facto must be a number')),
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      render: (packaging: UnitPackaging) => packaging?.base_cost_coefficient,
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      width: '10%',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (packaging: UnitPackaging) => {
        return (
          <TableActionCell>
            <DeleteButton
              tooltipTitle={t('Delete packaging')}
              confirmTitle={
                <TextFormat breakWord>
                  {t('Are you sure you want to delete')} {t('packaging')}?
                </TextFormat>
              }
              onConfirm={() => handleDeleteDocItem(packaging)}
              cancelButtonText={t('Cancel')}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const handleSave = useCallback(
    async (row: UnitPackaging) => {
      const newData = [...(formData?.unit_packaging || [])];
      const index = newData.findIndex(
        (item) => (row as any).uuid === item.uuid,
      );

      const item = newData[index];

      newData.splice(index, 1, {
        ...item,
        unit: row?.unit,
        base_unit_coefficient: row?.base_unit_coefficient,
        base_cost_coefficient: row?.base_cost_coefficient,
      });

      handlerUpdateFormState({ unit_packaging: [...newData] });
    },
    [formData?.unit_packaging, handlerUpdateFormState],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col, index) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: UnitPackaging) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          alignText: col?.align,
          validateList: col?.validateList,
          parent: col?.parent,
          autoFocus: col?.autoFocus,
          cellIndex: index,
        };
      },
    };
  });

  return (
    <StyledCol span={24}>
      <Form.Item label={t('Packaging')} name="unit_packaging">
        <StyledTable
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={formData?.unit_packaging}
          columns={columns as any}
          total={formData?.unit_packaging?.length}
          pageSize={Infinity}
          renderEmpty={
            <Empty
              description={t('It looks like you have not added any packaging')}
            />
          }
        />
      </Form.Item>

      <Button
        disabled={loadingSubmit}
        icon={<PlusOutlined />}
        type="dashed"
        onClick={handleAddUnitPackage}>
        <StyledTitle>{t('Add another value')}</StyledTitle>
      </Button>
    </StyledCol>
  );
}
