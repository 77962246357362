import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
import { ActionButton } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { EmployeeRoleFormDTO } from '@structure';
import styled, { useTheme, css } from 'styled-components';
import { MdModeEditOutline } from 'react-icons/md';
import { StyledTitle } from '@components/lib/Styled';
import { SuspenseEmpty } from '@components/lib/DataDisplay';

const EmployeeRoleSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeRoleSideWindow'),
);

export interface IEmployeeRoleButton extends ButtonProps {
  loading: boolean;
  onSuccess: (value: EmployeeRoleFormDTO) => Promise<void>;
  onCancel?: () => void;
  employee: EmployeeRoleFormDTO;
  isEmployeeOwner: boolean;
}

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  width: fit-content;
  height: fit-content;
  padding: 0;
`;

const StyledActionButton = styled(ActionButton)<{ disabled?: boolean }>`
  width: fit-content;
  height: fit-content;

  &&& {
    padding: 0;
    margin: 0;
  }

  &&:hover,
  &&:active,
  &&:focus {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.7;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &&:hover,
      &&:active,
      &&:focus {
        opacity: 1;
      }

      &&& > button {
        height: 34px;
      }
    `}
`;

export default function EmployeeRoleButton({
  loading = false,
  onSuccess,
  onCancel,
  employee,
  isEmployeeOwner,
  ...rest
}: IEmployeeRoleButton) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActionButton
        tooltip={t('Employee role')}
        type="text"
        onClick={handleOnInit}
        {...rest}>
        {loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <StyledTextContainer>
            <MdModeEditOutline size={14} color={theme.colors.success} />
            <StyledTitle $color={theme.colors.success}>
              {t('update')}
            </StyledTitle>
          </StyledTextContainer>
        )}
      </StyledActionButton>
      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeRoleSideWindowLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          employee={employee}
          isEmployeeOwner={isEmployeeOwner}
        />
      </Suspense>
    </>
  );
}
