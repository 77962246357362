import React, { Suspense, useState } from 'react';
import {
  IProductBalances,
  ProductLeftoversFormDTO,
  ProductListModel,
  ProductMapper,
  ProductModel,
  ProductStatus,
  StoreDTO,
  StoreMapper,
} from '@structure';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toDateByFormat } from '@services/helpers';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StyledDescription } from '@components/lib/Styled';
import { StoreDetailsView } from '../../Stores';
import { SuspenseEmpty, TableActionCell } from '@components/lib/DataDisplay';
import { useModal } from '@components/lib/libV2/hooks';
import { LeftoverChangeButton } from '../../Stores/Leftovers/Buttons';
import { useAcl } from '@contex';

import '../../Orders/List/OrderList.less';

const LeftoversModalLazy = React.lazy(
  () => import('../../Stores/Leftovers/Show/LeftoversModal'),
);

export interface IProductBalancesViewProps {
  product_balances: IProductBalances[];
  handleUpdateProductLeftover: (
    value: ProductLeftoversFormDTO,
  ) => Promise<ProductListModel | void>;
  product: ProductModel | null;
}

const StyledStoreContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
  width: 100%;
` as React.ComponentType as React.FunctionComponent<
  ITableProps<IProductBalances>
>;

const StyledAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export function ProductBalancesView({
  product_balances,
  handleUpdateProductLeftover,
  product,
}: IProductBalancesViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { manage } = useAcl(({ product }) => product);

  const [leftover, setLeftover] = useState<ProductLeftoversFormDTO | null>(
    null,
  );

  const {
    handleCancel: leftoversHandleCancel,
    handleOnInit: leftoversHandleOnInit,
    handleSuccess: leftoversHandleSuccess,
    visible: leftoversVisible,
  } = useModal({
    onSuccess: handleUpdateProductLeftover,
  });

  const columns = [
    {
      title: t('Store'),
      key: 'store',
      render: (productBalances: IProductBalances) => (
        <StyledStoreContainer>
          <StoreDetailsView
            store={StoreMapper.toStoreModel(
              productBalances?.company_store as StoreDTO,
            )}
            withoutGap
          />
          <StyledDescription>{`${t('Update date')}: ${toDateByFormat(
            productBalances?.updated_at,
            'DD.MM.YYYY HH:mm',
          )}`}</StyledDescription>
        </StyledStoreContainer>
      ),
    },
    {
      title: t('tAmount'),
      key: 'amount',
      className: 'order-list-td',
      alight: 'right' as any,
      render: (productBalances: IProductBalances) => (
        <StyledAmountContainer>
          {productBalances?.balance_total || 0}
        </StyledAmountContainer>
      ),
    },
    ...(product?.product_status === ProductStatus.ENABLE && manage
      ? [
          {
            title: t('Actions'),
            key: 'action',
            align: 'center' as any,
            className: 'order-list-td',
            render: (productBalances: IProductBalances) => (
              <TableActionCell>
                <LeftoverChangeButton
                  handleInitLeftover={() => {
                    setLeftover({
                      ...ProductMapper.toProductLeftoversFormDTO(product!, {
                        withAddedStore: false,
                      }),
                      store_balances: [
                        {
                          store_uuid: productBalances?.company_store?.uuid,
                          balance: productBalances?.balance_total,
                          store_name:
                            productBalances?.company_store?.store_name,
                        },
                      ],
                    } as ProductLeftoversFormDTO);

                    leftoversHandleOnInit();
                  }}
                />
              </TableActionCell>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <StyledTable
        total={Infinity}
        pageSize={Infinity}
        dataSource={product_balances?.sort((a, b) => {
          if (a?.company_store?.store_name > b.company_store?.store_name) {
            return 1;
          }
          if (a?.company_store?.store_name < b?.company_store?.store_name) {
            return -1;
          }

          return 0;
        })}
        columns={columns}
        loading={false}
        page={1}
        pagination={false}
      />
      <Suspense fallback={<SuspenseEmpty />}>
        <LeftoversModalLazy
          product={leftover!}
          onSuccess={leftoversHandleSuccess}
          visible={leftoversVisible}
          onCancel={leftoversHandleCancel}
        />
      </Suspense>
    </>
  );
}
