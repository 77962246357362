import { Record, List } from 'immutable';
import { IListProps } from './List';
import {
  IProductsListStatsProps,
  PriceTagDTO,
  PriceTagModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IPriceTagListModelProps extends IListProps {
  items: PriceTagDTO[];
  keywords?: string;
  page?: number;
  stats?: IProductsListStatsProps;
}

export interface IPriceTagListModelReturnType extends IListProps {
  items: List<PriceTagModel>;
  keywords?: string;
  page?: number;
  stats?: IProductsListStatsProps;
}

export class PriceTagListModel extends Record<IPriceTagListModelReturnType>({
  items: List(),
  total: 0,
  keywords: '',
  page: 1,
  stats: {} as IProductsListStatsProps,
}) {
  constructor(
    {
      items = [],
      ...props
    }: IPriceTagListModelProps = {} as IPriceTagListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IPriceTagListModelReturnType = {
      ...props,
      items: List(
        items.map((store) => new PriceTagModel(store, withoutRecursion)),
      ),
    };

    super(options);
  }
}
