import { Record } from 'immutable';
import {
  IFinancialInformationType,
  IFinancialInformationDTOProps,
} from '../internal';

export interface IFinancialInformationReturnType {
  date: string;
  total: number;
  currency: string;
  types: {
    cashbox: IFinancialInformationType;
    bank: IFinancialInformationType;
    account: IFinancialInformationType;
  };
}

export class FinancialInformationModel extends Record<IFinancialInformationReturnType>(
  {
    date: '',
    total: 0,
    currency: '',
    types: {
      cashbox: {},
      bank: {},
      account: {},
    } as any,
  },
) {
  constructor(
    props: IFinancialInformationDTOProps = {} as IFinancialInformationDTOProps,
  ) {
    super(props);
  }
}
