export interface IEmployeeCustomScheduleFormDTOModel {
  isCustomScheduleActive: boolean;
  customSchedule: string;
}

export interface IEmployeeCustomScheduleModelProps {
  isCustomScheduleActive: boolean;
  customSchedule: string;
}

export class EmployeeCustomScheduleFormDTO
  implements IEmployeeCustomScheduleFormDTOModel
{
  public isCustomScheduleActive: boolean;
  public customSchedule: string;

  constructor(props?: IEmployeeCustomScheduleModelProps) {
    this.isCustomScheduleActive = props?.isCustomScheduleActive || false;
    this.customSchedule = props?.customSchedule || '';
  }
}
