import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Menu, Dropdown, Badge, Button } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { authUrl } from '@services/const';
import { logout } from '@services/api/user';
import { PROFILE_TAB } from '../../../views/Profile';

import './UserMenu.less';
import { Routes } from '@services/types';
import { tokenHandler } from '@services/core/token';
import { useNavigate } from 'react-router';

export default withTranslation()(UserMenu);

const UserIcon = styled(UserOutlined)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.app};
`;

const StyledLogoutButton = styled(Button)`
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export interface IUserMenuProps extends WithTranslation {}

/**
 * @name UserMenu
 * @desc UserMenu component.
 */
function UserMenu({
  t,
  notifications = 0,
  user,
}: IUserMenuProps & any): JSX.Element {
  const navigate = useNavigate();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await logout();

      tokenHandler.destroyToken();

      navigate(`/${Routes.auth}/${Routes.login}`);
    } catch (e: any) {}
  }, [navigate]);

  const menu = (
    <Menu
      data-testid="user-menu-menu"
      style={{ minWidth: 150 }}
      className="UserMenu">
      <Menu.Item key="profile">
        <Badge offset={[10, 0]} count={notifications}>
          <StyledLink
            to={`/${Routes.app}/profile/${PROFILE_TAB.NOTIFICATIONS}`}>
            {t('Profile')}
          </StyledLink>
        </Badge>
      </Menu.Item>
      <Menu.Item key="logout">
        <StyledLogoutButton type="text" onClick={handleLogout}>
          {t('Logout')}
        </StyledLogoutButton>
      </Menu.Item>
    </Menu>
  );

  return user ? (
    <Dropdown overlay={menu} trigger={['hover']} placement="bottomRight">
      <Button
        size="middle"
        type="primary"
        data-testid="user-menu-dropdown-trigger"
        className="ant-dropdown-link">
        <span data-testid="user-menu-user-name">
          <Badge offset={[8, -2]} count={notifications}>
            <UserIcon />
          </Badge>
        </span>
      </Button>
    </Dropdown>
  ) : user === false ? (
    <a href={`${authUrl}/auth/login`}>Log In</a>
  ) : (
    <LoadingOutlined
      data-testid="user-menu-loading"
      className="UserMenu-loading"
    />
  );
}
