import * as React from 'react';
import useDataItemState from './useDataItemState';
import {
  ScheduleCalendarModel,
  ScheduleCalendarFormDTO,
  ScheduleCalendarMapper,
  ScheduleChangeTimeFormDTO,
  ScheduleDocumentType,
  ScheduleMessageFormDTO,
  IScheduleStatsDTO,
  StatusCommentFormDTO,
  ScheduleCloseDTO,
} from '@structure';
import {
  updateOrderComment,
  getScheduleDocument,
  formingScheduleMessage,
  sendScheduleMessage,
} from '@services/api/orders';
import useScheduleDetails, {
  IUseScheduleDetailsProps,
  IUseScheduleDetailsReturnType,
} from './useScheduleDetails';
import useScheduleActions from './useScheduleActions';
import { StatusError } from '@components/lib/Errors';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { isThereContent, printScheduleDocument } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';

export interface IUseStateScheduleProps extends IUseScheduleDetailsProps {}

export interface IUseStateScheduleReturnType
  extends IUseScheduleDetailsReturnType {
  handleUpdateSchedule: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleChangeScheduleStatus: (
    status: StatusCommentFormDTO,
  ) => Promise<ScheduleCalendarModel | void>;
  handleCloseSchedule: (value: ScheduleCloseDTO) => Promise<void>;
  handleCancelClosing: (scheduleId: string) => Promise<void>;
  handleCancelSchedule: (scheduleId: string) => Promise<void>;
  handleUpdateScheduleComment: (comment: string) => Promise<void>;
  handleChangeScheduleTime: (value: ScheduleChangeTimeFormDTO) => Promise<void>;
  handleUpdate: (item: ScheduleCalendarModel) => void;
  handlePrintScheduleDocument: (type: ScheduleDocumentType) => Promise<void>;
  handleFormingScheduleMessage: (
    templateUuid: string,
  ) => Promise<string | void>;
  handleSendScheduleMessage: (value: ScheduleMessageFormDTO) => Promise<void>;
  handleUpdateStats: (count: number) => void;
}

export default function useStateSchedule({
  scheduleUuid,
  ...rest
}: IUseStateScheduleProps): IUseStateScheduleReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [localStats, setLocalStats] = useState<IScheduleStatsDTO>({
    files: '0',
  });

  /**
   * @desc Деталі клієнта
   * */
  const { schedule, stats, ...scheduleDetails } = useScheduleDetails({
    scheduleUuid,
    ...rest,
  });

  const { data: stateSchedule, handleUpdate } =
    useDataItemState<ScheduleCalendarModel>(schedule);

  const scheduleActions = useScheduleActions({
    handleUpdate,
    stateSchedule,
  });

  const handleUpdateScheduleComment = React.useCallback(
    async (comment: string): Promise<void> => {
      try {
        await updateOrderComment(scheduleUuid, comment);

        const updatedScheduleModel = schedule?.update('comment', () => comment);

        handleUpdate(updatedScheduleModel);

        alert('success', t('Order'), t('Order edited success'));
      } catch (error: any) {
        alert(
          'error',
          t('Order'),
          `${t('An error occurred during edit order')} : ${error?.message}`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, handleUpdate, schedule, scheduleUuid, t],
  );

  const handlePrintScheduleDocument = React.useCallback(
    async (documentType: ScheduleDocumentType): Promise<void> => {
      try {
        const html = await getScheduleDocument(scheduleUuid, documentType);

        alert('success', t('Document'), t('Get document success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Document'),
          `${t('An error occurred during get document')} : ${error?.message}`,
        );
      }
    },
    [alert, scheduleUuid, t],
  );

  const handleFormingScheduleMessage = React.useCallback(
    async (templateUuid: string): Promise<string | void> => {
      try {
        const message = await formingScheduleMessage(
          scheduleUuid,
          templateUuid,
        );

        // alert(
        //   'success',
        //   t('Schedule message'),
        //   t('Forming schedule message success'),
        // );
        return message;
      } catch (error: any) {
        alert(
          'error',
          t('Schedule message'),
          `${t('An error occurred during forming schedule message')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, scheduleUuid, t],
  );

  const handleSendScheduleMessage = React.useCallback(
    async (value: ScheduleMessageFormDTO): Promise<void> => {
      await sendScheduleMessage(value);

      alert(
        'success',
        t('Schedule message'),
        t('Send schedule message success'),
      );
    },
    [alert, t],
  );

  const handleUpdateStats = useCallback((count: number) => {
    setLocalStats((prevState) => {
      const files = Number(prevState?.files || 0);

      return { files: String(files + count) };
    });
  }, []);

  useEffect(() => {
    if (isThereContent(stats)) {
      setLocalStats(stats);
    }
  }, [stats]);

  return {
    ...scheduleActions,
    schedule: stateSchedule,
    handleUpdateScheduleComment,
    ...scheduleDetails,
    loading: !isThereContent(stateSchedule),
    handleUpdate,
    handlePrintScheduleDocument,
    handleFormingScheduleMessage,
    handleSendScheduleMessage,
    stats: localStats,
    handleUpdateStats,
  };
}
