import React from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import { eq } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';

export interface EditableCellProps<T>
  extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  formItemName: string;
  title: any;
  inputType: 'number' | 'text';
  record: T;
  index: number;
  children: React.ReactNode;
  autoFocus?: boolean;
}

export default function EditableCell<T>({
  editing,
  formItemName,
  title,
  inputType,
  record,
  index,
  children,
  autoFocus = false,
  ...restProps
}: EditableCellProps<T>) {
  const { t } = useTranslation();

  const { defaultCompany } = useStoredCompanies();

  const inputNode = eq(inputType, 'number') ? (
    <Input
      addonBefore={defaultCompany?.currency_symbol}
      autoFocus={autoFocus}
    />
  ) : (
    <Input />
  );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={formItemName}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              transform: (value) => Number(value),
              type: 'number',
              message: t('Must be a number.'),
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
