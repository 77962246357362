import React from 'react';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { AlphaNameStatuses, ModuleInputParameterDTO } from '@structure';
import styled, { useTheme, css } from 'styled-components';
import {
  getDiffDaysFromNow,
  textToUpperCase,
  toDateByFormat,
} from '@services/helpers';
import { useStoredCompanies } from '@hooks';
import { useTranslation } from 'react-i18next';
import { SettingsModuleAlphaNameModal } from '../Show';
import { ColorLine } from '@components/lib/DataDisplay';

export interface ISettingsModuleAlphaNameItemProps {
  inputParameter: ModuleInputParameterDTO;
  isLinking: boolean;
  isPhone?: boolean;
}

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
`;

const StyledTextWrapper = styled.div<{ $withSmallPadding?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 25px;
  height: 100%;

  ${({ $withSmallPadding }) =>
    $withSmallPadding &&
    css`
      padding: 10px 5px;
    `}
`;

export const ERROR_DAYS = 3;
export const WARNING_DAYS = 10;

export const diffDate = (expire_at: string) =>
  expire_at ? getDiffDaysFromNow(expire_at) : 0;

export const statusColor = (theme: any) => ({
  [AlphaNameStatuses.Activated]: theme.colors.warning,
  [AlphaNameStatuses.Paid]: theme.colors.link,
  [AlphaNameStatuses.Completed]: theme.colors.success,
  [AlphaNameStatuses.Rejected]: theme.colors.error,
  [AlphaNameStatuses.Expired]: theme.colors.error,
  [AlphaNameStatuses.Unknown]: theme.colors.primary,
});

const StyledStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const diffColor = (
  diffDate: number,
  theme: any,
  status: AlphaNameStatuses,
) =>
  diffDate <= ERROR_DAYS && diffDate < WARNING_DAYS
    ? theme.error
    : diffDate <= WARNING_DAYS
    ? theme.warning
    : statusColor(theme)[status];

export function SettingsModuleAlphaNameItem({
  inputParameter,
  isLinking,
  isPhone,
}: ISettingsModuleAlphaNameItemProps): JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { settings } = useStoredCompanies();

  return (
    <StyledContainer>
      {isLinking &&
      settings?.settings?.company_alfa_name_order_status ===
        AlphaNameStatuses.Completed ? (
        <ColorLine color={theme.colors.success} />
      ) : null}
      <StyledTextWrapper $withSmallPadding={isPhone}>
        <StyledTextContainer>
          <StyledTitle $color={isLinking ? undefined : theme.colors?.disable}>
            {textToUpperCase(inputParameter?.params?.text)}
          </StyledTitle>
        </StyledTextContainer>
        {inputParameter?.params?.hint ? (
          <StyledDescription
            $withoutEllipsis
            $color={isLinking ? undefined : theme.colors?.disable}>
            {inputParameter?.params?.hint}
          </StyledDescription>
        ) : null}

        {settings?.settings?.company_alfa_name_expire_at ? (
          <StyledDescription
            $withoutEllipsis
            $color={
              isLinking
                ? diffColor(
                    diffDate(settings?.settings?.company_alfa_name_expire_at),
                    theme,
                    settings?.settings?.company_alfa_name_order_status ||
                      statusColor(theme)[AlphaNameStatuses.Unknown],
                  )
                : theme.colors?.disable
            }>
            {`${t('Expired at')}: ${toDateByFormat(
              settings?.settings?.company_alfa_name_expire_at,
              'YYYY-MM-DD HH:mm',
            )}`}
          </StyledDescription>
        ) : null}
        {settings?.settings?.company_alfa_name_order_status ? (
          <StyledStatusContainer>
            <StyledDescription
              $withoutEllipsis
              $color={
                isLinking
                  ? statusColor(theme)[
                      settings?.settings?.company_alfa_name_order_status
                    ] || statusColor(theme)[AlphaNameStatuses.Unknown]
                  : theme.colors?.disable
              }>
              {`${t(settings?.settings?.company_alfa_name_order_status)} / `}
            </StyledDescription>
            <SettingsModuleAlphaNameModal isLinking={isLinking} />
          </StyledStatusContainer>
        ) : null}
      </StyledTextWrapper>
    </StyledContainer>
  );
}
