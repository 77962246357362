import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EPlanTypeIdentifier,
  getDiffDaysFromNow,
  isFunction,
} from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { PlanMapper, PlanModel, ProfileModel } from '@structure';
import { IDefaultTabPanelProps } from '@components/lib/General';
import { IoCheckmarkDoneSharp, IoWarningOutline } from 'react-icons/io5';
import { MdBlock } from 'react-icons/md';
import { IUseStatePlanListReturnType, useStoredProfile } from '@hooks';
import { Routes } from '@services/types';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router';
import { SubscriptionTariffModelList } from './SubscriptionTariffModelList';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ChangeSubscriptionTariffButton, SubscriptionButton } from '../Buttons';
import moment from 'moment';
import { systemTitles } from '@services/const';

export interface ISubscriptionTabPanelProps
  extends Pick<
    IUseStatePlanListReturnType,
    'handleSubscriptionActivate' | 'handleSubscriptionTransition'
  > {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & {
      topTabs?: IDefaultTabPanelProps['tabs'];
    },
  ) => React.ReactNode;
  plan: PlanModel | null;
  profile: ProfileModel | null;
  paymentLoading: boolean;
}

export enum SUBSCRIPTION_TABS {
  ARCHIVE = 'Archive',
  TARIFF_PLANS = 'Tariff plans',
  SUBSCRIPTIONS = 'subscriptions',
}

const StyledLoadingOutlined = styled(LoadingOutlined)`
  &&& {
    font-size: 20px !important;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.white};
`;

const TWO_WEEKS = 14;

export function SubscriptionTabPanel({
  children,
  plan,
  handleSubscriptionActivate,
  handleSubscriptionTransition,
  paymentLoading,
}: ISubscriptionTabPanelProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();

  const isMoreThanTwoWeeks = plan
    ? getDiffDaysFromNow(
        moment(plan?.getFirstSubscription()?.date_end, 'DD.MM.YYYY').toDate(),
      ) >= 14
    : false;

  const tabs = [
    {
      defaultActiveKey: SUBSCRIPTION_TABS.TARIFF_PLANS,
      key: SUBSCRIPTION_TABS.TARIFF_PLANS,
      title: t(SUBSCRIPTION_TABS.TARIFF_PLANS),
      buttonIcon: paymentLoading ? <StyledLoadingOutlined /> : undefined,
      renderer: () => (
        <SubscriptionTariffModelList
          plan={plan}
          handleSubscriptionActivate={handleSubscriptionActivate}
          handleSubscriptionTransition={handleSubscriptionTransition}
          disabled={paymentLoading}
        />
      ),
    },
    ...(!plan?.isEmptySubscriptionList()
      ? [
          {
            key: SUBSCRIPTION_TABS.ARCHIVE,
            title: t(SUBSCRIPTION_TABS.ARCHIVE),
            navigation: () => {
              navigate(Routes.subscriptionListPage);
            },
            renderer: () => <></>,
          },
        ]
      : []),
  ];

  const topTabs = [
    {
      key: SUBSCRIPTION_TABS.SUBSCRIPTIONS,
      defaultActiveKey: SUBSCRIPTION_TABS.SUBSCRIPTIONS,
      title: `${t('Tariff plan')}: ${
        plan?.isTrialSubscription
          ? t((systemTitles as any)[plan?.identifier])
          : plan?.isSubscriptionExpired
          ? t('Current subscription is expired')
          : t((systemTitles as any)[plan?.identifier as any])
      }`,
      index: 0,
      description: plan?.isShowSubscriptionExpiredTime
        ? `${t('Subscription will expire in ')} ${plan?.subscriptionExpiredAt(
            t,
          )}`
        : plan?.isSubscriptionExpired
        ? `${t('Expires on')} ${plan?.getFirstSubscription().date_end}`
        : `${t('The subscription is activated until')}: ${
            plan?.getFirstSubscription()?.date_end
          }`,
      buttonIcon: isMoreThanTwoWeeks ? (
        <StyledInfoCircleOutlined />
      ) : plan?.isTrialSubscription ? (
        <IoWarningOutline size={24} color={theme.colors.white} />
      ) : plan?.isSubscriptionExpired ? (
        <MdBlock size={24} color={theme.colors.white} />
      ) : (
        <IoCheckmarkDoneSharp size={24} color={theme.colors.white} />
      ),
      type: (isMoreThanTwoWeeks
        ? 'success'
        : plan?.isTrialSubscription
        ? 'warning'
        : plan?.isSubscriptionExpired || plan?.isShowSubscriptionExpiredTime
        ? 'error'
        : 'success') as any,
      extraHeader: (
        <>
          {plan?.identifier === EPlanTypeIdentifier.TRIAL ||
          plan?.identifier === EPlanTypeIdentifier.INDIVIDUAL ? (
            <ChangeSubscriptionTariffButton
              link
              autoWidth
              $size={40}
              disabled={paymentLoading}
              title={t('Go to')}
              subscription={PlanMapper.toPlanTariffFormDTO(plan!)}
              onSuccess={handleSubscriptionTransition as any}
            />
          ) : (
            <SubscriptionButton
              autoWidth
              $size={40}
              title={t('To pay')}
              subscription={PlanMapper.toPlanFormDTO(plan!)}
              onSuccess={handleSubscriptionActivate as any}
              disabled={paymentLoading}
            />
          )}
        </>
      ),
      renderer: undefined as any,
    },
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({ tabs, topTabs })}</>;
}
