import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { List } from 'immutable';
import {
  filter,
  isNotEqualById,
  every,
  FunctionArgs,
  add,
  sub,
  eq,
} from '@services/helpers';
import { ILiteralObj } from '@services/types';

export interface IUseStateEntityListProps<T> extends ILiteralObj {
  entityList: List<T> | null;
  initialState?: List<T> | null;
  refresh?: (...args: any) => Promise<any>;
  offset?: number;
  limit?: number;
  total: number;
  loading?: boolean;
}

export interface IUseStateEntityListReturnedType<T> {
  entityList: List<T> | null;
  handleDelete: (id: string[], prop?: string | string[]) => void;
  handleCreate: (item: T, insertInStart?: boolean) => void;
  handleUpdate: (item: T, identifier?: string | string[]) => void;
  setEntityList: Dispatch<SetStateAction<List<T> | null>>;
  loading: boolean;
  total: number;
  once?: boolean;
}

export default function useStateEntityList<T>({
  entityList: data,
  initialState = null,
  refresh,
  limit,
  offset,
  total: entityListTotal,
  loading: initLoading,
  once = false,
  ...rest
}: IUseStateEntityListProps<T>): IUseStateEntityListReturnedType<T> {
  const [entityList, setEntityList] = React.useState<List<T> | null>(
    initialState,
  );
  const [total, setTotal] = React.useState(0);
  const [entityListDidntFit, setEntityListDidntFit] = React.useState<List<T>>(
    List(),
  );

  const onceSetItem = useRef<boolean>(!!once);

  React.useEffect(() => {
    if (entityListTotal) {
      setTotal((prevState) =>
        prevState !== entityListTotal ? entityListTotal : prevState,
      );
    }
  }, [entityListTotal]);

  const [loading, setLoading] = React.useState<boolean>(initLoading || !!data);

  const removeClassNameForNewEntity = React.useCallback(() => {
    const row = document.querySelector('.list__row--highlight');

    if (row) {
      row.classList.remove('list__row--highlight');
    }
  }, []);

  const getUuid = useCallback(
    (item: any, identifier: string | string[]): string =>
      Array.isArray(identifier)
        ? identifier.reduce((acc, curr) => acc[curr], item)
        : item[identifier],
    [],
  );

  const addToEntityListDidntFit = React.useCallback((item: T): void => {
    setEntityListDidntFit((prevState) => prevState.push(item));
  }, []);

  const removeFromEntityListDidntFit = React.useCallback((): void => {
    setEntityListDidntFit((prevState) => prevState.shift());
  }, []);

  const handleDelete = React.useCallback(
    (ids: string[], key: string | string[] = 'uuid'): void => {
      const item = entityListDidntFit.first();

      if (item) {
        removeFromEntityListDidntFit();
      }

      setEntityList((prevState: List<T> | null): List<T> => {
        const filterEntityList = filter<
          [FunctionArgs<T, boolean>, any],
          List<T>
        >(
          (i: T) => every(isNotEqualById(getUuid(i, key)), ids),
          prevState || List([]),
        );

        if (item) {
          return filterEntityList.push(item);
        }

        return filterEntityList;
      });

      setTotal((prevState) => sub(prevState, 1));
    },
    [entityListDidntFit, getUuid, removeFromEntityListDidntFit],
  );

  const handleCreate = React.useCallback(
    (item: T, insertInStart: boolean = false): void => {
      setEntityList((prevState: List<T> | null): List<T> => {
        const prevStateEntityList = prevState || List([]);

        if (prevState?.size === limit && insertInStart) {
          const updatedList = prevStateEntityList.pop();
          const lastItem = prevState!.last();

          addToEntityListDidntFit(lastItem as T);

          return insertInStart
            ? updatedList.unshift(item)
            : updatedList?.push(item);
        }

        if (prevState?.size === limit && !insertInStart) {
          addToEntityListDidntFit(item as T);

          return prevState as any;
        }

        return insertInStart
          ? prevStateEntityList.unshift(item)
          : prevStateEntityList?.push(item);
      });

      setTotal((prevState) => add(prevState, 1));

      setTimeout(removeClassNameForNewEntity, 5000);
    },
    [addToEntityListDidntFit, limit, removeClassNameForNewEntity],
  );

  const handleUpdate = React.useCallback(
    (item: any, identifier: string | string[] = 'uuid'): void => {
      setEntityList((prevState: List<T> | null): List<T> => {
        const prevStateEntityList = prevState || List([]);

        const index = prevStateEntityList.findIndex((prevStateItem) =>
          eq(getUuid(prevStateItem, identifier), getUuid(item, identifier)),
        );

        if (~index) {
          return prevStateEntityList.update(index, () => item);
        }

        return prevStateEntityList;
      });
    },
    [getUuid],
  );

  const addEntities = React.useCallback((entities: List<T>) => {
    setEntityList((prevState) => {
      if (prevState) {
        return prevState.concat(entities);
      }
      return entities;
    });
  }, []);

  React.useEffect(() => {
    if (data && !onceSetItem.current) {
      setEntityList(data);
      setLoading(false);
    }
  }, [data]);

  return {
    entityList,
    setEntityList,
    handleDelete,
    handleCreate,
    handleUpdate,
    loading,
    total,
  };
}
