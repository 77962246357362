import { List, Map } from 'immutable';
import { APP_STATE, CLIENT_ABONEMENT_SCHEDULE_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  ScheduleCalendarListModel,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
} from '@structure';

interface IClientAbonementScheduleListState {
  scheduleList: List<ScheduleCalendarModel> | null;
  cachedScheduleList: Map<string, ScheduleCalendarListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList
  extends Pick<IClientAbonementScheduleListState, 'total'> {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.SET_CLIENT_ABONEMENT_SCHEDULE_LIST;
  abonementUuid: string;
  scheduleList: ScheduleCalendarListModel;
  keywords?: string;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.ADD_CLIENT_ABONEMENT_SCHEDULE;
  schedule: ScheduleCalendarModel;
  abonementUuid: string;
}

interface UpdateClientAbonementScheduleFromList {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.UPDATE_CLIENT_ABONEMENT_SCHEDULE;
  schedule: ScheduleCalendarModel;
  abonementUuid: string;
}

interface DeleteActionFromList {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.DELETE_CLIENT_ABONEMENT_SCHEDULE;
  scheduleUuid: string;
  abonementUuid: string;
}

interface LoadingActionInList {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.LOADING_CLIENT_ABONEMENT_SCHEDULE_LIST;
}

interface ErrorActionInList
  extends Pick<IClientAbonementScheduleListState, 'error'> {
  type: CLIENT_ABONEMENT_SCHEDULE_LIST.ERROR_CLIENT_ABONEMENT_SCHEDULE_LIST;
}

interface Handlers {
  [CLIENT_ABONEMENT_SCHEDULE_LIST.SET_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
    action: SetActionList,
  ) => IClientAbonementScheduleListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientAbonementScheduleListState,
    action: SetInitialStateAction,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.LOAD_MORE_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
    action: SetActionList,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.ADD_CLIENT_ABONEMENT_SCHEDULE]: (
    state: IClientAbonementScheduleListState,
    action: AddActionToList,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.UPDATE_CLIENT_ABONEMENT_SCHEDULE]: (
    state: IClientAbonementScheduleListState,
    action: UpdateClientAbonementScheduleFromList,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.DELETE_CLIENT_ABONEMENT_SCHEDULE]: (
    state: IClientAbonementScheduleListState,
    action: DeleteActionFromList,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.ERROR_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
    value: ErrorActionInList,
  ) => IClientAbonementScheduleListState;

  [CLIENT_ABONEMENT_SCHEDULE_LIST.LOADING_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
    value?: LoadingActionInList,
  ) => IClientAbonementScheduleListState;
  DEFAULT: (
    state: IClientAbonementScheduleListState,
  ) => IClientAbonementScheduleListState;
}

const initState: IClientAbonementScheduleListState = {
  scheduleList: null,
  cachedScheduleList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [CLIENT_ABONEMENT_SCHEDULE_LIST.SET_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state,
    { scheduleList, abonementUuid, keywords, page },
  ) => {
    return {
      ...state,
      ...{
        scheduleList: scheduleList?.schedules || null,
        cachedScheduleList: scheduleList
          ? state.cachedScheduleList.set(abonementUuid, scheduleList)
          : state.cachedScheduleList,
        status: REDUX_STATUS.SUCCEEDED,
        total: scheduleList?.total! >= 0 ? scheduleList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_ABONEMENT_SCHEDULE_LIST.LOAD_MORE_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state,
    { scheduleList, abonementUuid },
  ) => {
    const relaterScheduleListById = state.cachedScheduleList.get(abonementUuid);

    return {
      ...state,
      ...{
        scheduleList:
          List.isList(state.scheduleList) && List.isList(scheduleList)
            ? state.scheduleList.merge(scheduleList.schedules)
            : state.scheduleList,
        cachedScheduleList: scheduleList
          ? state.cachedScheduleList.set(
              abonementUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('schedules', (schedules) =>
                    schedules.merge(scheduleList.schedules),
                  )
                : scheduleList,
            )
          : state.cachedScheduleList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_ABONEMENT_SCHEDULE_LIST.ADD_CLIENT_ABONEMENT_SCHEDULE]: (
    state,
    { schedule, abonementUuid },
  ) => {
    const relaterScheduleListById = state.cachedScheduleList.get(abonementUuid);

    return {
      ...state,
      ...{
        scheduleList: List.isList(state.scheduleList)
          ? state.scheduleList.unshift(schedule)
          : List([schedule]),
        cachedScheduleList: relaterScheduleListById
          ? state.cachedScheduleList.update(abonementUuid, (scheduleList) => {
              if (scheduleList) {
                return scheduleList
                  .update('schedules', (schedules) =>
                    schedules.unshift(schedule),
                  )
                  .update('total', (total) => (total || 0) + 1);
              } else {
                return ScheduleCalendarMapper.toScheduleCalendarListModel(
                  [schedule] as any,
                  1,
                );
              }
            })
          : Map({
              [abonementUuid]:
                ScheduleCalendarMapper.toScheduleCalendarListModel(
                  [schedule] as any,
                  1,
                ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [CLIENT_ABONEMENT_SCHEDULE_LIST.UPDATE_CLIENT_ABONEMENT_SCHEDULE]: (
    state: IClientAbonementScheduleListState,
    { schedule, abonementUuid }: UpdateClientAbonementScheduleFromList,
  ) => {
    const relaterScheduleListById = state.cachedScheduleList.get(abonementUuid);

    return {
      ...state,
      ...{
        scheduleList: List.isList(state.scheduleList)
          ? state.scheduleList.map((stateClientAbonementSchedule) => {
              if (stateClientAbonementSchedule.uuid === schedule?.uuid) {
                return stateClientAbonementSchedule.merge(schedule);
              }
              return stateClientAbonementSchedule;
            })
          : List([schedule]),

        cachedScheduleList: relaterScheduleListById
          ? state.cachedScheduleList.update(abonementUuid, (scheduleList) => {
              if (scheduleList) {
                return scheduleList.update('schedules', (schedules) =>
                  schedules.map((scheduleModel) => {
                    if (scheduleModel.uuid === schedule.uuid) {
                      return scheduleModel.merge(schedule);
                    } else {
                      return scheduleModel;
                    }
                  }),
                );
              } else {
                return ScheduleCalendarMapper.toScheduleCalendarListModel(
                  [],
                  0,
                );
              }
            })
          : state.cachedScheduleList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_ABONEMENT_SCHEDULE_LIST.DELETE_CLIENT_ABONEMENT_SCHEDULE]: (
    state,
    { scheduleUuid },
  ) => {
    return {
      ...state,
      ...{
        scheduleList: List.isList(state.scheduleList)
          ? state.scheduleList.filter(compose(not, isEqualByUuid(scheduleUuid)))
          : null,
        cachedScheduleList: state.cachedScheduleList.map((schedules) =>
          schedules.update('schedules', (list) => {
            const index = list.findIndex(({ uuid }) => uuid === scheduleUuid);

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [CLIENT_ABONEMENT_SCHEDULE_LIST.ERROR_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT_ABONEMENT_SCHEDULE_LIST.LOADING_CLIENT_ABONEMENT_SCHEDULE_LIST]: (
    state: IClientAbonementScheduleListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientAbonementScheduleListState) => state,
};

export default function ClientAbonementScheduleList(
  state: any = initState,
  action: any,
): IClientAbonementScheduleListState {
  const handler =
    handlers[action.type as CLIENT_ABONEMENT_SCHEDULE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
