import * as React from 'react';
import { AclMapper, IAclModelMap, AclModel } from '@structure';
import { useStoredProfile } from '@hooks';
import { isThereContent } from '@services/helpers';
import { aclFallback } from '@services/const';

export default AclManager;

export interface IAclManagerProps {
  children: React.ReactNode;
}

export interface IAclManagerContext {
  acl: IAclModelMap;
}

export const AclManagerContext = React.createContext<IAclManagerContext>({
  acl: AclMapper.toAclMapModel({} as IAclModelMap),
});

export const useAcl = <T extends IAclModelMap | AclModel | Object>(
  context: (acl: IAclModelMap) => T,
) => {
  const { acl } = React.useContext(AclManagerContext);

  return context(acl);
};

export function AclManager({ children }: IAclManagerProps): JSX.Element {
  const { profile } = useStoredProfile();

  const value = React.useMemo(
    () =>
      ({
        acl: isThereContent(profile?.acl)
          ? { ...AclMapper.toAclMapModel(aclFallback), ...profile?.acl }
          : AclMapper.toAclMapModel(aclFallback),
      } as any),
    [profile?.acl],
  );

  return (
    <AclManagerContext.Provider value={value}>
      {children}
    </AclManagerContext.Provider>
  );
}
