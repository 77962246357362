import React from 'react';
import { PAYMENT_METHOD } from '@structure';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { FaMoneyBillAlt, FaRegCreditCard } from 'react-icons/fa';
import { StyledTitle } from '@components/lib/Styled';
import { firstLetterToUppercase } from '@services/helpers';

export interface IPaymentMethodViewProps {
  method: PAYMENT_METHOD;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

export function PaymentMethodView({
  method,
}: IPaymentMethodViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {method === PAYMENT_METHOD.Account ? (
        <AiOutlineUser size={18} />
      ) : method === PAYMENT_METHOD.Bank ? (
        <FaRegCreditCard size={18} />
      ) : (
        <FaMoneyBillAlt size={18} />
      )}
      <StyledTitle>{firstLetterToUppercase(t(method))}</StyledTitle>
    </StyledContainer>
  );
}
