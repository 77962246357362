import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppErrorBoundary } from '../Errors';
import AuthLayout from './AuthLayout';

export default withTranslation()(AuthRouteLayout);

export interface IAuthRouteLayoutProps extends WithTranslation {
  children: any;
  withBackButton?: boolean;
}

function AuthRouteLayout({ children, withBackButton }: IAuthRouteLayoutProps) {
  return (
    <AppErrorBoundary>
      <AuthLayout withBackButton={withBackButton}>{children}</AuthLayout>
    </AppErrorBoundary>
  );
}
