import React from 'react';
import { DepartmentModel } from '@structure';
import styled, { useTheme } from 'styled-components';
import { DepartmentTitleView } from './DepartmentTitleView';
import { StyledDescription } from '@components/lib/Styled';

export interface IDepartmentDetailsViewProps {
  department: DepartmentModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

export function DepartmentDetailsView({
  department,
}: IDepartmentDetailsViewProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <>
      {department ? (
        <StyledContainer>
          <StyledSpace>
            <DepartmentTitleView department={department} />
            {/*<Link*/}
            {/*  to={`/${Routes.app}/${Routes.departments}/${department?.uuid}`}*/}
            {/*  tooltip={t('Open a detailed description of the department')}*/}
            {/*  state={{goBack: location.pathname}}>*/}
            {/*  {department?.dep_name}*/}
            {/*</Link>*/}
            {department?.dep_address ? (
              <StyledDescription $color={theme.colors.success}>
                {department?.dep_address}
              </StyledDescription>
            ) : null}
            {department?.dep_description ? (
              <StyledDescription>{`${department?.dep_description}`}</StyledDescription>
            ) : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
