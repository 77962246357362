import { Record, List } from 'immutable';
import { IListProps } from './List';
import { SystemEventDTO, SystemEventModel } from '../internal';

export interface ISystemEventListModelProps extends IListProps {
  events: SystemEventDTO[];
}

export interface ISystemEventListModelReturnType extends IListProps {
  events: List<SystemEventModel>;
}

export class SystemEventListModel extends Record<ISystemEventListModelReturnType>(
  {
    events: List(),
    total: 0,
  },
) {
  constructor(
    {
      events,
      ...props
    }: ISystemEventListModelProps = {} as ISystemEventListModelProps,
  ) {
    super({
      ...props,
      events: List(
        events
          .sort((a, b) => {
            if (a?.event_title < b?.event_title) {
              return -1;
            }
            if (a?.event_title > b?.event_title) {
              return 1;
            }
            return 0;
          })
          .map((event) => new SystemEventModel(event)),
      ),
    });
  }
}
