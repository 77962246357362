import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(GoodsPage);

export interface IGoodsPageProps extends WithTranslation {}

function GoodsPage({}: IGoodsPageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{ fontSize: 30 }}>Goods Page</p>
    </div>
  );
}
