import {
  EmployeeDTO,
  EmployeeMapper,
  IEmployeeDTOProps,
  AbonementStatuses,
  IClientDTOProps,
  IAbonementDTOProps,
  IProfileDTOProps,
  ProfileDTO,
  AbonementDTO,
  ClientDTO,
  ClientMapper,
  AbonementMapper,
  ProfileMapper,
  IClientAbonementModelReturnType,
  IAbonementGroupDTOProps,
  AbonementGroupDTO,
  AbonementGroupMapper,
  IAbonementTimingList,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export enum ClientAbonementStatuses {}

export enum ClientAbonementStatusesText {
  Initiated = 'initiated',
  Active = 'active',
  Paused = 'paused',
  Terminated = 'terminated',
  Blocked = 'blocked',
  Transffered = 'transffered',
  Error = 'error',
  ReturnedMoney = 'returned_money',
}
export enum ClientAbonementScheduleStatusesText {
  Init = 'init',
}

export interface IClientAbonementDTOProps {
  uuid: string;
  abon_card_number: string;
  abon_start_date: string;
  abon_end_date: string;
  abon_final_price: string;
  abon_schedule_end_date: string;
  abon_schedule_start_date: string;
  abon_schedule_comment: string;
  abon_schedule_list: IAbonementTimingList;
  abon_discount: number;
  abon_send_notification: boolean;
  abon_comment: string;
  abon_status: AbonementStatuses;
  abon_schedule_status_text: ClientAbonementScheduleStatusesText;
  abon_status_text: ClientAbonementStatusesText;
  created_at: string;
  updated_at: string;
  client: IClientDTOProps;
  company_abonement: IAbonementDTOProps;
  company_abonement_group: IAbonementGroupDTOProps;
  manager: IEmployeeDTOProps;
  created_by: IProfileDTOProps;
}

export class ClientAbonementDTO {
  public uuid: string;
  public abon_title: string;
  public abon_card_number: string;
  public abon_start_date: string;
  public abon_end_date: string;
  public abon_final_price: string;
  public abon_discount: number;
  public abon_send_notification: boolean;
  public abon_comment: string;
  public abon_status: AbonementStatuses;
  public abon_schedule_status_text: ClientAbonementScheduleStatusesText;
  public abon_status_text: ClientAbonementStatusesText;
  public abon_schedule_end_date: string;
  public abon_schedule_start_date: string;
  public abon_schedule_comment: string;
  public abon_schedule_list: IAbonementTimingList;
  public created_at: string;
  public updated_at: string;
  public client: ClientDTO;
  public company_abonement: AbonementDTO;
  public company_abonement_group: AbonementGroupDTO;
  public created_by: ProfileDTO;
  public manager: EmployeeDTO;

  constructor(
    props: IClientAbonementModelReturnType | IClientAbonementDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.abon_title = `${props?.company_abonement?.abon_title || ''} ${
      ClientMapper.toClientModel((props?.client || {}) as ClientDTO)
        .fullNameClient
    }`;
    this.abon_card_number = props?.abon_card_number || '';
    this.abon_start_date = props?.abon_start_date || '';
    this.abon_end_date = props?.abon_end_date || '';
    this.abon_schedule_end_date = props?.abon_schedule_end_date || '';
    this.abon_schedule_start_date = props?.abon_schedule_start_date || '';
    this.abon_schedule_comment = props?.abon_schedule_comment || '';
    this.abon_schedule_list = props?.abon_schedule_list || {};
    this.abon_final_price = correctPrice(props?.abon_final_price || 0);
    this.abon_discount = props?.abon_discount || 0;
    this.abon_send_notification =
      typeof props?.abon_send_notification === 'boolean'
        ? props?.abon_send_notification
        : false;
    this.abon_comment = props?.abon_comment || '';
    this.abon_status =
      typeof props?.abon_status === 'number'
        ? props?.abon_status
        : AbonementStatuses.Disabled;
    this.abon_status_text =
      props?.abon_status_text || ClientAbonementStatusesText.Initiated;
    this.abon_schedule_status_text =
      props?.abon_schedule_status_text ||
      ClientAbonementScheduleStatusesText.Init;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.client =
      withoutRecursion.indexOf(WithoutRecursion.client) !== -1
        ? (props?.client as ClientDTO)
        : ClientMapper.toClientDTO(props?.client, false, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);
    this.company_abonement =
      withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
        ? (props?.company_abonement as AbonementDTO)
        : AbonementMapper.toAbonementDTO(props?.company_abonement, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);

    this.company_abonement_group =
      withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
        ? (props?.company_abonement_group as AbonementGroupDTO)
        : AbonementGroupMapper.toAbonementGroupDTO(
            props?.company_abonement_group,
            [WithoutRecursion.clientAbonement, ...withoutRecursion],
          );
    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);
    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);
  }
}
