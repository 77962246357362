import styled from 'styled-components';
import { AuthLayout } from '../../Show';

export const StyledAuthLayout = styled(AuthLayout)`
  width: 50vw;
  padding: 20px 0;
  @media (max-width: 1200px) {
    width: 50vw;
  }

  @media (max-width: 767px) {
    width: 65vw;
  }

  @media (max-width: 680px) {
    width: 90vw;
  }

  @media (max-width: 475px) {
    width: 90vw;
  }
`;
