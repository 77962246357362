import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  ProductMapper,
  ProductModel,
  StoreDocumentFormDTO,
  StoreDocumentItemDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemListModel,
  StoreDocumentItemModel,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '@structure';
import {
  DocumentItemReturnType,
  IUseStateStoredDocumentReturnType,
  IUseStoreDocumentItemListProps,
  useDefaultPriceMargin,
  useStateProductList,
  useStateStoreDocumentItemList,
  UseStateStoreDocumentItemListReturnType,
  useStoredCompanies,
  useStoredProduct,
} from '@hooks';
import { Form } from 'antd';
import { StoreDocumentPageFields } from './StoreDocumentPageFields';
import { Button } from '@components/lib/DataDisplay';
import styled, { css } from 'styled-components';
import { isFunction } from '@services/helpers';
import { List } from 'immutable';
import { useWatch } from 'antd/es/form/Form';

export interface IStoreDocumentFormProps
  extends Omit<
      IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
    >,
    Pick<IUseStateStoredDocumentReturnType, 'handleDeleteStoreDocumentItem'> {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  onSuccess: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;
  storeDocumentItemList: List<StoreDocumentItemModel> | null;
  storeDocumentItemListRefresh: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  storeDocumentItemListLimit: number;
  storeDocumentItemListTotal: number;
  storeDocumentItemListPage: number;
  withEditablePrice?: boolean;
  productWithBalance?: boolean;
}

const StyledButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ disabled }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${({ theme }) => theme.colors.primary};
        opacity: 0.7;
      }
    `}
`;

const DEFAULT_DOC_ITEMS_TYPE = [
  StoreDocumentType.RETURN,
  StoreDocumentType.CRETURN,
];

export function StoreDocumentPageForm({
  loading,
  document,
  editMode,
  onSuccess,
  onCancel,
  handleDeleteStoreDocumentItem,
  storeDocumentItemList,
  storeDocumentItemListRefresh,
  storeDocumentItemListLimit,
  storeDocumentItemListTotal,
  storeDocumentItemListPage,
  withEditablePrice,
  productWithBalance = true,
  ...rest
}: IStoreDocumentFormProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();

  const [relatedProductList, setRelatedProductList] =
    useState<List<ProductModel> | null>(null);

  const selectedDocItems: StoreDocumentItemDTO[] = useWatch(
    'doc_items',
    instance,
  );

  const {
    productList,
    loading: productListLoading,
    keywords: productListKeywords,
    isLoadingMore: productIsLoadingMore,
    loadingMore: productListLoadingMore,

    handleSearchProductList,
    handleLoadingMoreProductList,
    handleSetProductList,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    with_balance: productWithBalance,
    minKeywordLength: 3,
  });

  const relatedDocUuid =
    document?.doc_related_document_uuid instanceof StoreDocumentModel
      ? document?.doc_related_document_uuid?.uuid
      : document?.doc_related_document_uuid;

  const isShowRelatedDocumentItems =
    !!relatedDocUuid && DEFAULT_DOC_ITEMS_TYPE.includes(document?.doc_type);

  const {
    storeDocumentItemList: relatedStoreDocumentItemList,
    loading: relatedDocumentItemsLoading,
    keywords: relatedDocumentItemsKeywords,
    isLoadingMore: relatedDocumentItemsIsLoadingMore,

    handleSearchStoreDocumentItemList,
    handleLoadingMoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: relatedDocUuid,
    loadOnInit: isShowRelatedDocumentItems,
    limit: 100,
  });

  const { defaultPriceMargin } = useDefaultPriceMargin({
    loadOnInit: true,
  });

  const { handleCreateProduct } = useStoredProduct({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productUuid: '',
  });

  useEffect(() => {
    if (relatedStoreDocumentItemList?.size) {
      const productList = relatedStoreDocumentItemList
        ?.map(({ product, item_product_amount }) => {
          const docItem: any = (selectedDocItems || [])?.find(
            ({ product: itemProduct }) => itemProduct?.uuid === product?.uuid,
          );

          if (docItem && docItem?.product_amount >= item_product_amount) {
            return null;
          }

          const productModel =
            product instanceof ProductModel
              ? product
              : ProductMapper.toProductModel(product as any);

          return productModel.update('product_balances', (product_balances) => {
            const balance = product_balances?.shift();

            return [
              { ...balance, balance_total: item_product_amount },
              ...product_balances,
            ] as any;
          });
        })
        .filter(Boolean);

      setRelatedProductList(productList as any);
    }
  }, [relatedStoreDocumentItemList, selectedDocItems]);

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={['Tab']}
      withContext
      isResetLoading
      onCancel={onCancel}
      instance={instance}
      initialValues={document}
      onSuccess={() => {}}
      editMode={editMode}
      showNotify={false}
      showFooter={false}
      customButton={(loading, submit, handlerUpdateFormState) =>
        document?.doc_status === StoreDocumentStatus.COMPLETED ||
        (editMode &&
          document?.doc_status !== StoreDocumentStatus.DRAFT) ? null : (
          <StyledButton
            loading={loading}
            type="primary"
            htmlType="submit"
            onClick={() => {
              if (isFunction(handlerUpdateFormState)) {
                handlerUpdateFormState({
                  doc_status: StoreDocumentStatus.DRAFT,
                });
              }

              if (isFunction(submit)) {
                submit();
              }
            }}>
            {t('Save as draft')}
          </StyledButton>
        )
      }
      {...rest}>
      <StoreDocumentPageFields
        withoutCreateProduct={!isShowRelatedDocumentItems}
        isShowRelatedDocumentItems={isShowRelatedDocumentItems}
        editMode={
          !!editMode && document?.doc_status !== StoreDocumentStatus.DRAFT
        }
        loading={!!loading}
        withoutStoreUuid={document?.doc_type === StoreDocumentType.IN}
        productList={
          isShowRelatedDocumentItems ? relatedProductList : productList
        }
        productListKeywords={
          isShowRelatedDocumentItems
            ? relatedDocumentItemsKeywords
            : productListKeywords
        }
        productListLoadingMore={
          isShowRelatedDocumentItems
            ? relatedDocumentItemsLoading
            : productListLoadingMore
        }
        productListLoading={
          isShowRelatedDocumentItems
            ? relatedDocumentItemsLoading
            : productListLoading
        }
        handleSearchProducts={
          isShowRelatedDocumentItems
            ? handleSearchStoreDocumentItemList
            : handleSearchProductList
        }
        handleLoadingMoreProductList={
          isShowRelatedDocumentItems
            ? handleLoadingMoreDocumentItemList
            : handleLoadingMoreProductList
        }
        isLoadingMore={
          isShowRelatedDocumentItems
            ? relatedDocumentItemsIsLoadingMore
            : productIsLoadingMore
        }
        defaultPriceMargin={defaultPriceMargin}
        store={document?.doc_store_uuid}
        onSuccess={onSuccess}
        handleDeleteStoreDocumentItem={handleDeleteStoreDocumentItem}
        handleCreateProduct={handleCreateProduct}
        storeDocumentItemList={storeDocumentItemList}
        storeDocumentItemListRefresh={storeDocumentItemListRefresh}
        storeDocumentItemListLimit={storeDocumentItemListLimit}
        storeDocumentItemListTotal={storeDocumentItemListTotal}
        storeDocumentItemListPage={storeDocumentItemListPage}
        withEditablePrice={withEditablePrice}
        productWithBalance={productWithBalance}
        document={document}
        handleSetProductList={handleSetProductList}
      />
    </DefaultForm>
  );
}
