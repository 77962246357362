import React from 'react';
import { EmployeeRoleCashierPermission, EmployeeRoleType } from '@structure';
import { useTranslation } from 'react-i18next';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';

export interface EmployeeRoleAdminFieldProps extends FormListOperation {
  fields: FormListFieldData[];
  loadingSubmit: boolean;
  role: EmployeeRoleType<EmployeeRoleCashierPermission>;
}

export function EmployeeRoleAdminField({
  fields,
  add,
  remove,
  loadingSubmit,
  role,
}: EmployeeRoleAdminFieldProps) {
  const { t } = useTranslation();

  const permissions: EmployeeRoleCashierPermission[] = role?.permissions;

  return <></>;
}
