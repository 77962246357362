import React, { forwardRef } from 'react';
import { IUseModalReturnType } from '../libV2/hooks/useModal';
import { Tour as ANTDTour, TourProps as ANTDTourProps } from 'antd';

import './Tour.less';

export interface TourProps
  extends Pick<IUseModalReturnType<any>, 'visible' | 'handleCancel'> {
  children: React.ReactNode | React.ReactNode[];
}

export const Tour = forwardRef(function Tour(
  { visible, handleCancel, children }: TourProps,
  ref: any,
): React.ReactNode {
  const steps: ANTDTourProps['steps'] = [
    {
      title: children,
      target: () => ref?.current,
    },
  ];

  return (
    <ANTDTour
      open={visible}
      onClose={handleCancel}
      mask={false}
      type="default"
      steps={steps}
      indicatorsRender={() => null}
    />
  );
});

export default Tour;
