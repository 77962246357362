import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { ClientVisitFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const ClientVisitSideWindowLazy = React.lazy(
  () => import('../Show/ClientVisitSideWindow'),
);

export interface IClientVisitEditButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: ClientVisitFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  visit: ClientVisitFormDTO;
}

export default function ClientVisitEditButton({
  children,
  visit,
  onSuccess,
  onCancel,
  ...rest
}: IClientVisitEditButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit visit')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientVisitSideWindowLazy
          editMode
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visit={visit}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
