import * as React from 'react';
import { ProfileMapper, ProfileModel } from '@structure';
import { getProfile } from '@services/api/user';
import { ErrorsStatus, Routes } from '@services/types';
import { useStoredAuthData } from './useStoredAuthData';
import { useReSendTimeout, useStateSignup, useStoredProfile } from './index';
import { IEmployeeSignupPageState } from '@store/actions';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { decryptObject, getPassedSecondsFrom } from '@services/helpers';
import {
  LocalStorageItems,
  verificationReSendCodeTimeout,
} from '@services/const';
import { useDropdownAlert } from '@contex';

export interface IUseApproveEntryProps {
  loadingOnInit?: boolean;
}

export interface IUseApproveEntryReturnType {
  loading: boolean;
  identifier: string | null;
  disabled: boolean;
  isShowLoginForm: boolean;
  seconds: number;
  handleCheckStatus: () => Promise<void>;
  resetEmployeeSignupData: () => void;
}

export function useApproveEntry({
  loadingOnInit,
}: IUseApproveEntryProps): IUseApproveEntryReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [isShowLoginForm, setIsShowLoginForm] = React.useState(false);

  const { storeProfile } = useStoredProfile();
  const { authRedirect, authData } = useStoredAuthData();
  const once = useRef(false);

  const {
    timeWhenCodeWasSend,
    loading,
    identifier,

    updateStateFactory,
    resetStateFactory,
    initStateFactory,
  } = useStateSignup<
    Pick<
      IEmployeeSignupPageState,
      'timeWhenCodeWasSend' | 'loading' | 'identifier'
    >
  >({
    key: Routes.employeeSignup,
    getState: (state) => ({
      timeWhenCodeWasSend: state[Routes.employeeSignup]?.timeWhenCodeWasSend,
      loading: state[Routes.employeeSignup]?.loading,
      identifier: state[Routes.employeeSignup]?.identifier,
    }),
  });

  const { seconds, disabled, startInterval, refresh } = useReSendTimeout({
    timeWhenCodeWasSend,
    loading,
  });

  const resetEmployeeSignupData = React.useCallback(async () => {
    resetStateFactory();
    localStorage.removeItem(LocalStorageItems.employeeAuthData);
  }, [resetStateFactory]);

  const isRedirect = React.useCallback(
    async (profileModel: ProfileModel) => {
      if (profileModel?.status === -9) {
        alert(
          'warn',
          t('Check status'),
          t('Your request is awaiting confirmation from the administrator'),
        );
        return;
      }

      if (profileModel.uuid) {
        storeProfile(profileModel);

        if (
          authData &&
          (authData?.user?.phone === profileModel?.phone ||
            authData?.user?.identifier === profileModel?.phone)
        ) {
          await authRedirect(authData);
          await resetEmployeeSignupData();
        } else {
          setIsShowLoginForm(true);
          setTimeout(() => {
            alert('warn', t('Entry'), t('Please log in to continue'));
          }, 500);
        }
      }
    },
    [authData, authRedirect, alert, resetEmployeeSignupData, storeProfile, t],
  );

  const profileRedirect = useCallback(
    async (token: string) => {
      const profileDTO = await getProfile(undefined, token);
      const profileModel = ProfileMapper.toProfileModel(profileDTO);

      await isRedirect(profileModel);
    },
    [isRedirect],
  );

  const handleCheckStatus = React.useCallback(async () => {
    startInterval();

    try {
      const serializeEmployeeAuthData = localStorage.getItem(
        LocalStorageItems.employeeAuthData,
      );

      updateStateFactory({ timeWhenCodeWasSend: new Date() });

      if (serializeEmployeeAuthData) {
        const employeeAuthData: any = decryptObject(serializeEmployeeAuthData);
        await profileRedirect(employeeAuthData?.access_token);
      } else {
        if (authData?.access_token) {
          await profileRedirect(authData?.access_token);
          return;
        }

        setIsShowLoginForm(true);
        setTimeout(() => {
          alert('warn', t('Entry'), t('Please log in to continue'));
        }, 500);
      }
    } catch (error: any) {
      if (error?.response?.status === ErrorsStatus.Forbidden) {
        setIsShowLoginForm(true);
        setTimeout(() => {
          alert('warn', t('Entry'), t('Please log in to continue'));
        }, 500);
      }
    }
  }, [
    authData?.access_token,
    alert,
    profileRedirect,
    startInterval,
    t,
    updateStateFactory,
  ]);

  useEffect(() => {
    if (!loading && loadingOnInit && !once.current) {
      if (timeWhenCodeWasSend) {
        const seconds = getPassedSecondsFrom(timeWhenCodeWasSend);
        if (seconds < Number(verificationReSendCodeTimeout)) {
          refresh();
        } else {
          setTimeout(async () => {
            await handleCheckStatus();
          }, 500);
        }
      } else {
        setTimeout(async () => {
          await handleCheckStatus();
        }, 500);
      }
      once.current = true;
    }
  }, [loading, timeWhenCodeWasSend, handleCheckStatus, refresh, loadingOnInit]);

  useEffect(() => {
    if (loading) {
      initStateFactory();
    }
  }, [initStateFactory, loading]);

  return useMemo(
    () => ({
      loading,
      isShowLoginForm,
      disabled,
      handleCheckStatus,
      identifier,
      seconds,
      resetEmployeeSignupData,
    }),
    [
      disabled,
      handleCheckStatus,
      identifier,
      isShowLoginForm,
      loading,
      resetEmployeeSignupData,
      seconds,
    ],
  );
}
