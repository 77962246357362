import { Dispatch } from 'redux';
import { ABONEMENT_GROUP } from '../constants';
import { AbonementGroupModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetAbonementGroupParam {
  type: ABONEMENT_GROUP;
  group: AbonementGroupModel | null;
}

export interface IUpdateAbonementGroupParam {
  type: ABONEMENT_GROUP;
  group: AbonementGroupModel;
}

export interface IResetAbonementGroupParam {
  type: ABONEMENT_GROUP;
}

export interface IErrorAbonementGroupParam {
  type: ABONEMENT_GROUP;
  error: ApiError | undefined;
}

export function setAbonementGroup(
  group: AbonementGroupModel | null,
): (dispatch: Dispatch) => Promise<ISetAbonementGroupParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP.SET_ABONEMENT_GROUP,
      group,
    });
  };
}

export function updateAbonementGroup(
  group: AbonementGroupModel,
): (dispatch: Dispatch) => Promise<IUpdateAbonementGroupParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP.UPDATE_ABONEMENT_GROUP,
      group,
    });
  };
}

export function resetAbonementGroup(): (
  dispatch: Dispatch,
) => Promise<IResetAbonementGroupParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP.RESET_ABONEMENT_GROUP,
    });
  };
}

export function errorAbonementGroup(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorAbonementGroupParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP.ERROR_ABONEMENT_GROUP,
      error,
    });
  };
}
