import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { CLIENT_VISIT_INITIAL_PARAM } from '@services/api/client';
import { ClientVisitFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { useStateEmployeeList, useStoredCompanies } from '@hooks';
import { ClientVisitFields } from './ClientVisitFields';

export interface IClientVisitFormProps
  extends Omit<
    IDefaultFormProps<ClientVisitFormDTO, ClientVisitFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  visit?: ClientVisitFormDTO;
}

export function ClientVisitForm({
  loading,
  editMode,
  visit = CLIENT_VISIT_INITIAL_PARAM,
  onCancel,
  ...rest
}: IClientVisitFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: visit?.uuid,
    }),
    [visit?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client visit'),
        `${
          editMode
            ? t('An error occurred during edit client visit')
            : t('An error occurred during create visit registration')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...visit,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <ClientVisitFields
          employees={employees}
          employeesLoading={employeesLoading}
          handleSearchEmployees={handleSearchEmployees}
        />
      )}
    </DefaultForm>
  );
}
