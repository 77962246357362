import React from 'react';
import { CalendarMode } from '@structure';
import styled from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { FieldTimeOutlined, CalendarOutlined } from '@ant-design/icons';

export interface ICalendarModeViewProps {
  title: string;
  mode: CalendarMode;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

const StyledFieldTimeOutlined = styled(FieldTimeOutlined)`
  font-size: 15px;
`;
const StyledCalendarOutlined = styled(CalendarOutlined)`
  font-size: 15px;
`;

export function CalendarModeView({
  title,
  mode,
  ...rest
}: ICalendarModeViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {mode === CalendarMode.Calendar ? (
        <StyledCalendarOutlined />
      ) : (
        <StyledFieldTimeOutlined />
      )}
      <StyledWrapper>
        <StyledTitle fontSize={13}>{t(title)}</StyledTitle>
      </StyledWrapper>
    </StyledTitleIconContainer>
  );
}
