import React from 'react';
import OrderScheduleDetail from '../Show/OrderScheduleDetail';
import { CompanyCalendarPopover } from '../../Dashboard/Show';
import { useDefaultCalendarScheduleProps, useStoredCompanies } from '@hooks';
import { AclLayout } from '@components/lib/Layout';
import { Routes, RoutesAcl } from '@services/types';
import { ICalendarManagerProps } from '@contex';
import { ScheduleCalendarModel } from '@structure';

export interface IOrderCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export default function OrderDetails({
  children,
}: IOrderCalendarPageProps): JSX.Element {
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const { defaultCompany } = useStoredCompanies();

  return (
    <AclLayout aclItem={RoutesAcl[Routes.orders]}>
      {children({
        ...defaultCalendarProps,
        localStorageName: '',
        withoutStorageDate: '',
        SchedulePopover: CompanyCalendarPopover,
        scheduleDetails: (children, schedule) => (
          // @ts-ignore
          <OrderScheduleDetail schedule={schedule}>
            {({ routes, navigateGoBack }) =>
              children({ routes, navigateGoBack })
            }
          </OrderScheduleDetail>
        ),
        customDayTimeRange: (defaultCompany &&
          defaultCompany?.work_schedules) as any,
      })}
    </AclLayout>
  );
}
