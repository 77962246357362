import React from 'react';
import { IntegrationModel } from '@structure';
import { List } from 'immutable';
import { IntegrationItemView } from './IntegrationItemView';
import { IUseStateIntegrationListReturnType } from '@hooks';

export interface IntegrationListViewProps
  extends Pick<
    IUseStateIntegrationListReturnType,
    'handleAddOrUpdateIntegration' | 'handleGetIntegration'
  > {
  integrations: List<IntegrationModel>;
}

export function IntegrationListView({
  integrations,
  ...rest
}: IntegrationListViewProps): React.JSX.Element {
  return (
    <>
      {integrations.map((integration) => (
        <IntegrationItemView
          key={integration?.uuid}
          integration={integration}
          {...rest}
        />
      ))}
    </>
  );
}
