import * as React from 'react';
import { getPassedSecondsFrom } from '@services/helpers';
import { verificationReSendCodeTimeout } from '@services/const';
import { useRef } from 'react';

export interface IUseReSendTimeoutProps {
  timeWhenCodeWasSend: null | Date;
  loading: boolean;
  loadingOnInit?: boolean;
}

export interface IUseReSendTimeoutReturnType {
  seconds: number;
  disabled: boolean;
  startInterval: () => void;
  refresh: () => void;
}

export function useReSendTimeout({
  timeWhenCodeWasSend,
  loading,
  loadingOnInit = true,
}: IUseReSendTimeoutProps): IUseReSendTimeoutReturnType {
  const [seconds, setSeconds] = React.useState<number>(
    Number(verificationReSendCodeTimeout),
  );
  const [disabled, setDisabled] = React.useState(false);
  const interval = useRef<NodeJS.Timeout | null>(null);

  const startInterval = React.useCallback(() => {
    setDisabled(true);

    if (!interval?.current) {
      interval.current = setInterval(() => {
        setSeconds((prevState) => {
          if (Number(prevState) === 0) {
            if (interval?.current) {
              clearInterval(interval.current);
            }
            setDisabled(false);
            return Number(verificationReSendCodeTimeout);
          }
          return Number(prevState) - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval?.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  const refresh = React.useCallback(() => {
    if (timeWhenCodeWasSend && !loading) {
      const seconds = getPassedSecondsFrom(timeWhenCodeWasSend);

      if (seconds < Number(verificationReSendCodeTimeout)) {
        setSeconds(Number(verificationReSendCodeTimeout) - seconds);
        setDisabled(true);
        startInterval();
      }
    }
  }, [loading, startInterval, timeWhenCodeWasSend]);

  React.useEffect(() => {
    if (loadingOnInit) {
      refresh();
    }
  }, [loading, loadingOnInit, refresh, startInterval]);

  return {
    seconds,
    disabled,
    startInterval,
    refresh,
  };
}
