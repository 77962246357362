import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import useLocalStorage from './useLocalStorage';
import {
  lessThan,
  greaterThan,
  getDaysInMonth,
  checkUrlParam,
  toUtcDateInFormat,
  getRootKey,
  getUrlMatchParam,
  eq,
  add,
} from '@services/helpers';
import { CALENDAR } from '@components/lib/const';
import { Routes } from '@services/types';

export interface IUseRedirectToCalendarProps {
  path: string;
  isChecked?: boolean;
  ignoreRedirect?: boolean;
}

export default function useRedirectToCalendar({
  path,
  isChecked = true,
  ignoreRedirect = false,
}: IUseRedirectToCalendarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [mode, year, month, day] = getUrlMatchParam(location.pathname);
  const [calendarDate] = useLocalStorage('calendarDate');
  const [calendarMode] = useLocalStorage('calendarMode');

  // TODO: виправити на константи
  const checkMode = React.useCallback(
    () => !(eq(mode, 'day') || eq(mode, 'month') || eq(mode, 'week')),
    [mode],
  );

  const checkDay = React.useCallback((): boolean => {
    const continueCheck = checkUrlParam(day);

    if (continueCheck) {
      return true;
    }

    const daysInMonth = getDaysInMonth({
      month: add(moment().month(), 1),
      year: moment().year(),
    });

    return lessThan(Number(day), 1) || greaterThan(Number(day), daysInMonth);
  }, [day]);

  const checkMonth = React.useCallback((): boolean => {
    const continueCheck = checkUrlParam(month);

    if (continueCheck) {
      return true;
    }

    return lessThan(Number(month), 1) || greaterThan(Number(month), 12);
  }, [month]);

  const checkYear = React.useCallback((): boolean => {
    const continueCheck = checkUrlParam(year, 4);

    if (continueCheck) {
      return true;
    }
    return lessThan(Number(year), 1000) || greaterThan(Number(year), 3000);
  }, [year]);

  React.useEffect(() => {
    if (isChecked && !ignoreRedirect) {
      if (checkDay() || checkMonth() || checkYear() || checkMode()) {
        const localStorageDate = toUtcDateInFormat(calendarDate);
        const nowDate = toUtcDateInFormat(moment());
        navigate(
          `${CALENDAR}/${calendarMode || 'day'}/${localStorageDate || nowDate}`,
          { state: location?.state },
        );
      }
    }
  }, [
    checkMode,
    isChecked,
    checkDay,
    checkMonth,
    checkYear,
    calendarDate,
    path,
    calendarMode,
    ignoreRedirect,
    navigate,
    location?.state,
  ]);
}
