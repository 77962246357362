import React from 'react';
import { Text } from '../Format';
import Button, { IButtonProps } from './Button';
import { isFunction } from '@services/helpers';
import styled, { css, useTheme } from 'styled-components';

export type BadgeType =
  | 'warning'
  | 'error'
  | 'primary'
  | 'default'
  | 'success'
  | 'disabled'
  | 'link';

export interface IMarkedBadgeProps
  extends Omit<IButtonProps, 'type' | 'children'> {
  title?: string;
  type?: BadgeType;
  children?: React.ReactNode;
  dotColor?: string;
  direction?: 'left' | 'right' | 'twoWay';
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Dot = styled.div<{ color?: string }>`
  background-color: ${({ theme }) => theme.background.primary};
  width: 6px;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

const StyledText = styled(Text)`
  background-color: red;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledMarkedBadge = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.background.primary};
  padding-left: 5px;
  padding-right: 10px;
  justify-content: center;

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
`;

const styledDirection = css<{ bgColor?: string }>`
  justify-content: center;
  height: auto;

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
`;

const StyledLeft = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 5px;
  ${styledDirection};
`;

const StyledRight = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-right: 5px;
  ${styledDirection};
`;

const StyledButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  opacity: 1;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
    opacity: 0.7;
  }
`;

export function MarkedBadge({
  title,
  type = 'primary',
  children,
  dotColor,
  direction = 'left',
  color,
  onPress,
  disabled,
  ...rest
}: IMarkedBadgeProps): JSX.Element {
  const theme: any = useTheme();

  const bgColors = {
    warning: theme.colors.warning,
    error: theme.colors.error,
    success: theme.colors.success,
    primary: theme.colors.primary,
    default: theme.colors.primary,
    link: theme.colors.link,
    disabled: theme.colors.disable,
  };

  const colors = {
    warning: theme.colors.white,
    error: theme.colors.white,
    success: theme.colors.white,
    primary: theme.colors.white,
    default: theme.colors.white,
    link: theme.colors.white,
    disabled: theme.colors.disable,
  };

  return (
    <StyledButton
      type="text"
      disabled={disabled || !isFunction(onPress)}
      onClick={onPress}
      style={{ height: 'auto', width: 'auto' }}
      {...rest}>
      {title ? (
        <StyledContainer>
          <StyledText color={colors[type] || theme.text.primary}>
            {title}
          </StyledText>
        </StyledContainer>
      ) : null}
      {children ? (
        <StyledContainer>
          {direction === 'left' || direction === 'twoWay' ? (
            <StyledLeft bgColor={color || bgColors[type]}>
              <Dot color={dotColor} />
            </StyledLeft>
          ) : null}
          <StyledMarkedBadge bgColor={color || bgColors[type]}>
            {React.isValidElement(children)
              ? React.cloneElement(children, {
                  ...children?.props,
                  style: {
                    ...((children as any)?.props?.style || {}),
                    ...{
                      color: color
                        ? theme.text.white
                        : (colors as any)[type] || theme.text.primary,
                    },
                    ...(direction === 'twoWay' ? { textAlign: 'center' } : {}),
                  },
                })
              : null}
          </StyledMarkedBadge>
          {direction === 'right' || direction === 'twoWay' ? (
            <StyledRight bgColor={color || bgColors[type]}>
              <Dot color={dotColor} />
            </StyledRight>
          ) : null}
        </StyledContainer>
      ) : null}
    </StyledButton>
  );
}

// const styles = StyleSheet.create({
//   button: {

//   },
// });
