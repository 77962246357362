import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Input, Form } from 'antd';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { ClientOperationFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { useStoredCompanies } from '@hooks';

export interface IClientBalanceOperationFormProps
  extends Omit<
    IDefaultFormProps<ClientOperationFormDTO, ClientOperationFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  balance: ClientOperationFormDTO;
}

export function ClientBalanceOperationForm({
  loading,
  editMode,
  balance,
  onCancel,
  instance,
  ...rest
}: IClientBalanceOperationFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompany } = useStoredCompanies();

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client balance'),
        `${t('An error occurred during adjustment client balance')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...balance,
      }}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('Sum')}
                name="sum"
                required
                rules={[
                  () => ({
                    validator(_, sum) {
                      const value = sum;

                      if (Number.isNaN(Number(value))) {
                        return Promise.reject(
                          new Error(t('Sum must be a number')),
                        );
                      }

                      if (!value) {
                        return Promise.reject(
                          new Error(t('Sum must be specified.')),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}>
                <Input
                  addonBefore={defaultCompany?.currency_symbol}
                  data-testid="service-input-sum"
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Note')}
                name="comment"
                rules={[
                  {
                    required: true,
                    message: t('Note must be specified'),
                  },
                ]}>
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  disabled={loading || loadingSubmit}
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
