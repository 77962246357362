import React, { SetStateAction, useCallback, useEffect } from 'react';
import moment from 'moment';
import { CalendarHeaderView } from '@components/lib/Layout';
import { CalendarWeek } from '@components/Calendar';
import { useCalendar } from '@contex';
import { buildMonthChange } from '@services/helpers';
import { useCalendarSpecifications } from '@hooks';

export interface IClientAbonementScheduleWeekProps {
  scheduleLoading: boolean;
  disabled: boolean;
  setDate: React.Dispatch<SetStateAction<any>>;
}

export function ClientAbonementScheduleWeek({
  scheduleLoading,
  disabled,
  setDate,
}: IClientAbonementScheduleWeekProps): React.JSX.Element {
  const { selectedDate, handleUpdate, monthDates } = useCalendar();
  const { allSpecification } = useCalendarSpecifications();

  const handleChangeDate = useCallback(
    async (date: Date) => {
      const newDate = moment(date).locale(
        window.localStorage.getItem('language') || 'en',
      );

      await handleUpdate({
        prevDateInMonth: monthDates,
        date: newDate as any,
        name: 'button',
      });
    },
    [handleUpdate, monthDates],
  );

  const handleSelectToday = useCallback(async (): Promise<void> => {
    await handleUpdate({
      prevDateInMonth: monthDates,
      date: moment().toDate(),
    });

    setDate(moment());
  }, [handleUpdate, monthDates, setDate]);

  const { prev, next } = buildMonthChange(selectedDate as Date, 'week');

  const handleSelectPrevDate = useCallback(async () => {
    setDate(prev);
    await handleChangeDate(prev);
  }, [handleChangeDate, prev, setDate]);

  const handleSelectNextDate = useCallback(async (): Promise<void> => {
    setDate(next);
    await handleChangeDate(next);
  }, [handleChangeDate, next, setDate]);

  return (
    <>
      <CalendarHeaderView
        showTitle={false}
        showCalendarMode={false}
        showCalendarPicker={false}
        handleSelectToday={handleSelectToday}
        handleSelectPrevDate={handleSelectPrevDate}
        handleSelectNextDate={handleSelectNextDate}
        selectedDate={moment(new Date(selectedDate))}
        handleChangeDate={handleChangeDate}
        loading={scheduleLoading}
        specifications={allSpecification}
        disabled={disabled}
      />
      <CalendarWeek />
    </>
  );
}
