import { List } from 'immutable';
import {
  RuleLimitationDTO,
  IRuleLimitationDTOProps,
  RuleLimitationModel,
  RuleLimitationListModel,
  SystemRuleMapper,
} from '../internal';
import { listToArray } from '@services/helpers';

export class RuleLimitationMapper {
  public static toRuleLimitationDTO(
    ruleLimitation: RuleLimitationModel | IRuleLimitationDTOProps,
  ): RuleLimitationDTO {
    return new RuleLimitationDTO({
      system_rule_limit: ruleLimitation?.system_rule_limit || 0,
      system_rule: SystemRuleMapper.toSystemRuleDTO(
        ruleLimitation?.system_rule,
      ),
    });
  }

  public static toRuleLimitationListDTO(
    ruleLimitation: List<RuleLimitationModel> | IRuleLimitationDTOProps[],
  ): RuleLimitationDTO[] {
    const ruleLimitationList = List.isList(ruleLimitation)
      ? listToArray<RuleLimitationModel>(ruleLimitation)
      : ruleLimitation;

    return ruleLimitationList?.map((employee) =>
      RuleLimitationMapper.toRuleLimitationDTO(employee),
    );
  }

  public static toRuleLimitationModel(
    ruleLimitationDTO: RuleLimitationDTO,
  ): RuleLimitationModel {
    return new RuleLimitationModel(ruleLimitationDTO);
  }

  public static toRuleLimitationListModel(
    ruleLimitationDTOs: RuleLimitationDTO[],
  ): RuleLimitationListModel {
    return new RuleLimitationListModel({
      rule_limitations: ruleLimitationDTOs,
    });
  }
}
