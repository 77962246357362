import * as React from 'react';
import moment from 'moment';
import { getPeriod } from '@services/api/services';
import { TIME_LIST_SERVICES } from '@services/types';

export interface useDefaultCalendarScheduleReturnProps {
  scheduleStartProp: string;
  scheduleEndProp: (value: any) => string | Date;
  customDayTimeRange: string;
}

export default function useDefaultCalendarScheduleProps(): useDefaultCalendarScheduleReturnProps {
  const scheduleEndPropFunc = React.useCallback(
    ({ scheduled_date }: any): Date => {
      return moment(scheduled_date)
        .add(1 as any, getPeriod(TIME_LIST_SERVICES.HOUR))
        .toDate();
    },
    [],
  );

  return {
    scheduleStartProp: 'scheduled_date',
    scheduleEndProp: scheduleEndPropFunc,
    customDayTimeRange: '',
  };
}
