import React, {
  forwardRef,
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Empty, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  PriceMarginModel,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductStatus,
  StoreDocumentItemFormDTO,
  StoreDocumentMapper,
} from '@structure';
import styled, { css, useTheme } from 'styled-components';
import { Table } from '@components/lib/libV2/DataDisplay';
import { Button, LoadingMore } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import {
  useStateProductList,
  useStoredCompanies,
  useStoredProduct,
} from '@hooks';
import { debounce } from '@services/helpers';
import { List } from 'immutable';
import {
  StyledDescription,
  StyledTitle,
  successButtonStyle,
} from '@components/lib/Styled';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ProductCreateButton, ProductDetailsView } from '../../../Products';
import { SearchProductStatus, SearchProductStatusType } from '@services/types';
import { ENTER_KEYS } from '@services/const';
import { v4 as uuidv4 } from 'uuid';
import { useDropdownAlert } from '@contex';

import '../../Forms/StoreProductListField.less';
import '../../../Orders/List/OrderList.less';
import '../../../Products/Pages/ProductList.less';

export interface IProductSearchSelectProps {
  loading: boolean;
  defaultPriceMargin: PriceMarginModel | null;
  onProductSelect: (product: ProductModel) => Promise<void>;
  daleyFocus: (ms?: number) => void;
  getProductPriceMargin: (
    product: ProductModel,
    priceMargin: PriceMarginModel | null,
  ) => string;
  productListTotal: number;
  storeUuid?: string;
}

const StyledTable = styled(Table)`
  margin: 0;
` as any;

const StyledProductListContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;

  flex: 1;
`;

const StyledSelect = styled(Select)<{ disabled: boolean }>`
  flex: 1;

  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

const StyledButton = styled(Button)`
  position: relative;
  top: -7px;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  font-size: 12px;
`;

const StyledProductCreateButton = styled(ProductCreateButton)`
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
`;

const StyledCreateButton = styled(Button)`
  ${successButtonStyle};
  margin-bottom: 15px;
`;

export const ProductSearchSelect = forwardRef(function ProductSearchSelect(
  {
    loading,

    defaultPriceMargin,
    onProductSelect,
    daleyFocus,
    getProductPriceMargin,
    productListTotal,
    storeUuid,
  }: IProductSearchSelectProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const theme: any = useTheme();
  const { alert } = useDropdownAlert();

  const {
    productList,
    loading: productListLoading,
    keywords: productListKeywords,
    isLoadingMore: productIsLoadingMore,

    handleSearchProductList,
    handleLoadingMoreProductList,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
  });

  const { handleCreateProduct } = useStoredProduct({
    companyUuid: defaultCompanyUuid,
    productUuid: '',
    loadOnInit: false,
  });

  const tableRef = useRef<HTMLTableElement>(null);
  const inputStatusRef = useRef<SearchProductStatusType>({
    status: SearchProductStatus.END_REQUEST,
    isEnterPress: false,
  });

  const [selectedProductItem, setSelectedProductItem] =
    useState<StoreDocumentItemFormDTO | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [storeProductList, setStoreProductList] = useState<ProductModel[]>([]);
  const [isSelectedProduct, setIsSelectedProduct] = useState(false);

  const handleChangeInputStatus = useCallback(
    (status: SearchProductStatus, isEnterPress: boolean = false) => {
      if (
        inputStatusRef.current.status === SearchProductStatus.PRESS_ENTER &&
        status === SearchProductStatus.START_INPUT
      ) {
        return;
      }

      if (
        inputStatusRef.current.status === SearchProductStatus.END_REQUEST &&
        status === SearchProductStatus.PRESS_ENTER
      ) {
        return;
      }

      inputStatusRef.current.isEnterPress =
        status === SearchProductStatus.PRESS_ENTER
          ? isEnterPress
          : inputStatusRef.current.isEnterPress;
      inputStatusRef.current.status = status;
    },
    [],
  );

  const handleSetSelectedProductItem = useCallback(
    (product: any) => {
      setSelectedProductItem(
        StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          price: getProductPriceMargin(product, defaultPriceMargin),
        }),
      );
    },
    [defaultPriceMargin, getProductPriceMargin],
  );

  const onProductCreate = useCallback(
    async (value: ProductFormDTO) => {
      const product = await handleCreateProduct(value);

      if (product) {
        await onProductSelect(product);
        handleSetSelectedProductItem(product);
      }
    },
    [handleCreateProduct, handleSetSelectedProductItem, onProductSelect],
  );

  const handleProductSelect = useCallback(
    async (record: any) => {
      if (record && record?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      setIsSelectedProduct(!!record);

      handleSetSelectedProductItem(record);
      await onProductSelect(record);
    },
    [alert, handleSetSelectedProductItem, onProductSelect, t],
  );

  const columns = useMemo(
    () => [
      {
        title: t('name-s'),
        key: 'name',
        className: 'order-list-td',
        render: (product: ProductModel) => (
          <ProductDetailsView product={product} withoutNavigate />
        ),
      },
    ],
    [t],
  );

  useEffect(() => {
    const storeProductList: ProductModel[] = [];

    if (List.isList(productList) && productListKeywords?.length >= 3) {
      productList?.forEach((product, index) => {
        if (index === 0 && !selectedProductItem) {
          handleSetSelectedProductItem(product);
        }

        if (
          Array.isArray(product?.product_balances) &&
          product?.product_balances?.length
        ) {
          product?.product_balances?.forEach((store, storeIndex) => {
            const updatedProduct = product
              .update('product_balances', () => [store])
              .update('product_code', () => `${index}${storeIndex}`);
            storeProductList.push(updatedProduct);
          });
        } else {
          storeProductList.push(product);
        }
      });

      setStoreProductList(storeProductList);
    }
  }, [handleSetSelectedProductItem, productList, productListKeywords?.length, selectedProductItem]);

  useEffect(() => {
    if (productListKeywords?.length < 3 && !isSelectedProduct) {
      setSelectedProductIndex((index) => (index === 0 ? index : 0));
      setSelectedProductItem(null);
    }
  }, [productListKeywords?.length, isSelectedProduct]);

  const onTableKeyboardChange = useCallback(
    (e: KeyboardEvent) => {
      const length = storeProductList?.length - 1;

      if (e.key === 'ArrowUp' && selectedProductIndex === 0) {
        const product = storeProductList[length];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex(() => length);
        }
        const selected = document.body.querySelector(
          `.posting-list-td-${length}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowUp' && selectedProductIndex <= length) {
        const product = storeProductList[selectedProductIndex - 1];

        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex((prevState) => --prevState);
        }

        const selected = document.body.querySelector(
          `.posting-list-td-${selectedProductIndex - 1}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowDown' && selectedProductIndex === length) {
        const product = storeProductList[0];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex(() => 0);
        }

        const selected = document.body.querySelector(`.posting-list-td-${0}`);

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowDown' && selectedProductIndex <= length) {
        const product = storeProductList[selectedProductIndex + 1];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex((prevState) => ++prevState);
        }

        const selected = document.body.querySelector(
          `.posting-list-td-${selectedProductIndex + 1}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (ENTER_KEYS.includes(e.key)) {
        const product = storeProductList[selectedProductIndex];
        handleProductSelect(product);
      }
    },
    [
      handleProductSelect,
      handleSetSelectedProductItem,
      selectedProductIndex,
      storeProductList,
    ],
  );

  useEffect(() => {
    if (
      productListKeywords?.length >= 3 &&
      storeProductList?.length &&
      !productListLoading
    ) {
      document.addEventListener('keydown', onTableKeyboardChange);

      return () => {
        document.removeEventListener('keydown', onTableKeyboardChange);
      };
    }
  }, [onTableKeyboardChange, productListKeywords?.length, productListLoading, storeProductList?.length]);

  const handleTriggerCreateProduct = useCallback(() => {
    const productCreateButton = document.body.querySelector(
      `.create-product-button`,
    );

    if (productCreateButton) {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
      });

      productCreateButton?.dispatchEvent(clickEvent);
    }
  }, []);

  const debounceRefresh = useMemo(
    () =>
      debounce(async (value: any) => {
        const productList: any = await handleSearchProductList(value);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);

        if (
          productList &&
          productList?.products?.size === 1 &&
          inputStatusRef.current?.isEnterPress
        ) {
          const select = document.body.querySelector('.product-search-select');

          if (select instanceof HTMLElement) {
            const input = select.querySelector(
              '.ant-select-selection-search-input',
            );

            const clickEvent = new KeyboardEvent('keydown', {
              bubbles: true,
              cancelable: true,
              key: 'Enter',
            });

            if (input instanceof HTMLElement) {
              setTimeout(() => {
                input.dispatchEvent(clickEvent);
              }, 250);
            }
          }
        }

        handleChangeInputStatus(SearchProductStatus.PRESS_ENTER, false);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);
      }, 1000),
    [handleChangeInputStatus, handleSearchProductList, inputStatusRef],
  );

  return (
    <>
      <StyledProductListContainer ref={tableRef}>
        <StyledSelect
          popupClassName="posting-select-popup"
          className="product-search-select"
          allowClear
          showSearch
          onBlur={() => {
            setStoreProductList([]);
          }}
          clearIcon={
            <StyledButton
              type="text"
              onClick={() => {
                handleProductSelect(undefined);
                setSelectedProductIndex((index) => (index === 0 ? index : 0));
                setSelectedProductItem(null);
              }}>
              <StyledCloseCircleOutlined />
            </StyledButton>
          }
          ref={ref as any}
          loading={productListLoading}
          disabled={loading}
          onKeyDown={(e) => {
            if (
              inputStatusRef.current?.status !==
                SearchProductStatus.END_REQUEST &&
              ENTER_KEYS.includes(e.key)
            ) {
              handleChangeInputStatus(SearchProductStatus.PRESS_ENTER, true);
            }
          }}
          onSearch={async (keywords) => {
            handleChangeInputStatus(SearchProductStatus.START_INPUT);
            if (keywords?.length < 3) {
              setStoreProductList([]);
            }
            await debounceRefresh({
              keywords,
              store_uuid: storeUuid,
            });
          }}
          placeholder={t('Search a product')}
          value={selectedProductItem?.product_title}
          dropdownRender={() => (
            <div
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {productListLoading ? (
                <Empty>
                  <LoadingOutlined />
                </Empty>
              ) : productListLoading ||
                !productListKeywords ||
                productListKeywords?.length < 3 ? (
                <Empty
                  description={t(
                    'To select a product, enter the search data, at least 3 characters',
                  )}
                />
              ) : productListKeywords && !storeProductList?.length ? (
                <Empty
                  description={t(
                    'It seems that more than one product was not found for your request',
                  )}>
                  <StyledCreateButton onClick={handleTriggerCreateProduct}>
                    <StyledTitle $color={theme.colors.white}>
                      {t('Add product')}
                    </StyledTitle>
                  </StyledCreateButton>
                </Empty>
              ) : (
                <>
                  <StyledTable
                    className="posting-list-form"
                    pagination={false}
                    pageSize={Infinity}
                    rowKey={() => uuidv4()}
                    total={storeProductList?.length}
                    dataSource={storeProductList}
                    columns={columns}
                    loading={loading}
                    onRow={(record: ProductModel, rowIndex: number) => ({
                      onClick: () => {
                        handleProductSelect(record);
                      },
                      className: `posting-list-td posting-list-td-${rowIndex} ${
                        record?.uuid === selectedProductItem?.product_uuid &&
                        selectedProductIndex === rowIndex
                          ? 'posting-list-td--selected'
                          : ''
                      } ${
                        record?.product_status !== ProductStatus.ENABLE
                          ? 'product-list--disabled'
                          : ''
                      }`,
                    })}
                  />
                  <LoadingMore
                    loading={productIsLoadingMore}
                    observerCallback={handleLoadingMoreProductList}
                  />
                </>
              )}
            </div>
          )}
        />
        {productListTotal > 0 ? (
          <StyledDescription>{`${t('Found')} ${productListTotal} ${t(
            'productsT',
          )}`}</StyledDescription>
        ) : null}
      </StyledProductListContainer>
      <StyledProductCreateButton
        className="create-product-button"
        type="primary"
        disabled={false}
        loading={loading}
        onSuccess={onProductCreate}
        product={ProductMapper.toProductFormDTO({
          product_title: productListKeywords,
        } as ProductModel)}
        icon={<PlusOutlined />}
        onCancel={daleyFocus}
      />
    </>
  );
});
