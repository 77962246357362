import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { Switch } from '@components/lib/DataDisplay';
import { useSize, useStateEmployeeSchedule } from '@hooks';
import { StyledDescription } from '@components/lib/Styled';
import EmployeeWorkScheduleSelect from '../Show/EmployeeWorkScheduleSelect';
import {
  EmployeeCancelInviteButton,
  EmployeeAcceptInviteButton,
} from './index';

import {
  EmployeeModel,
  EmployeeStatuses,
  EmployeeUserDataFormDTO,
  EmployeeAccessManagementFormDTO,
} from '@structure';

export interface IEmployeeActionButtonsProps {
  employee: EmployeeModel | null;
  manage?: boolean;
  loading: boolean;
  handleSetEmployeePassword: (value: EmployeeUserDataFormDTO) => Promise<void>;
  handleBlockEmployee: (
    employee: EmployeeAccessManagementFormDTO,
  ) => Promise<EmployeeModel | void>;
  handleAcceptEmployeeInvite: (
    employee: EmployeeModel,
  ) => Promise<EmployeeModel | void>;
  handleCancelEmployeeInvite: (
    employee: EmployeeModel,
  ) => Promise<EmployeeModel | void>;
  employeeId?: string;
  disabled?: boolean;
}

const StyledRow = styled(Row)`
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  & > :first-child {
    margin-right: 10px;
  }
`;

const StyledEmployeeAcceptInviteButton = styled(EmployeeAcceptInviteButton)`
  width: 100%;
  border-color: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.success};

  &&:hover,
  &&:active,
  &&:focus {
    border-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.success};
    opacity: 0.7;
  }
`;
const StyledEmployeeCancelInviteButton = styled(EmployeeCancelInviteButton)`
  width: 100%;
`;

const Description = styled(StyledDescription)`
  width: 100%;
  text-align: right;
  margin-top: 5px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const StyledSwitchContainer = styled(StyledCol)<{ $isWrap?: boolean }>`
  ${({ $isWrap }) =>
    $isWrap &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > :first-child {
        margin-bottom: 15px;
      }
    `}
`;

const StyledEmployeeWorkScheduleSelect = styled(EmployeeWorkScheduleSelect)<{
  $isFullWidth: boolean;
}>`
  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

export function EmployeeActionButtons({
  employee,
  manage,
  loading,
  handleSetEmployeePassword,
  handleBlockEmployee,
  handleAcceptEmployeeInvite,
  handleCancelEmployeeInvite,
  employeeId,
  disabled,
}: IEmployeeActionButtonsProps): JSX.Element {
  const { t } = useTranslation();
  const [isEmployeeBlock, setIsEmployeeBlock] = useState(false);
  const [isEmployeeBlockLoading, setIsEmployeeBlockLoading] = useState(false);

  const headerRef = useRef(null);
  const { width: headerWidth } = useSize(headerRef);

  const { workScheduleList, ...restWorkSchedule } = useStateEmployeeSchedule({
    employeeUuid: employeeId!,
    workScheduleList: employee?.work_schedule || null,
  });

  // const isActivated = [
  //   EmployeeStatuses.Activated,
  //   EmployeeStatuses.Created,
  //   EmployeeStatuses.Confirmed,
  // ].includes(employee?.status as EmployeeStatuses);

  // const handleLocalBlockEmployee = useCallback(
  //   async (value: boolean) => {
  //     if (employee) {
  //       try {
  //         setIsEmployeeBlockLoading(true);
  //         await handleBlockEmployee(employee?.uuid, fromBooleanToNumber(value));
  //         setIsEmployeeBlock(value);
  //
  //         setIsEmployeeBlockLoading(false);
  //       } catch (apiError: any) {
  //         setIsEmployeeBlockLoading(false);
  //       }
  //     }
  //   },
  //   [employee, handleBlockEmployee],
  // );

  useEffect(() => {
    if (employee) {
      setIsEmployeeBlock(employee?.status !== 0);
    }
  }, [employee]);

  return (
    <StyledRow gutter={10} ref={headerRef}>
      {employee?.status !== EmployeeStatuses.WaitingForConfirmation ? (
        <>
          {/*<Col span="auto">*/}
          {/*  <EmployeeUserDataButton*/}
          {/*    key="EmployeeUserDataButton"*/}
          {/*    disabled={!manage || disabled}*/}
          {/*    employee={EmployeeMapper.toEmployeeUserDataFormDTO(employee!)}*/}
          {/*    onSuccess={handleSetEmployeePassword}*/}
          {/*    loading={loading}*/}
          {/*  />*/}
          {/*</Col>*/}
          {/*<StyledSwitchContainer span={24} $isWrap={headerWidth < 350}>*/}
          {/*<StyledEmployeeWorkScheduleSelect*/}
          {/*  $isFullWidth={headerWidth < 350}*/}
          {/*  disabled={!manage || disabled}*/}
          {/*  workScheduleUuid={workScheduleList?.first()?.uuid || ''}*/}
          {/*  workScheduleList={workScheduleList}*/}
          {/*  {...restWorkSchedule}*/}
          {/*/>*/}
          {/*<StyledSwitchWrapper>*/}
          {/*  <StyledSwitch*/}
          {/*    onChange={handleLocalBlockEmployee}*/}
          {/*    checked={isEmployeeBlock}*/}
          {/*    disabled={isEmployeeBlockLoading || !manage || disabled}*/}
          {/*  />*/}
          {/*  <Text>{t('Active')}</Text>*/}
          {/*</StyledSwitchWrapper>*/}
          {/*</StyledSwitchContainer>*/}
          {/*{isActivated ? (*/}
          {/*  <Description>*/}
          {/*    {t(*/}
          {/*      'This option blocks the possibility for the Employee to enter the application and create an Order',*/}
          {/*    )}*/}
          {/*  </Description>*/}
          {/*) : null}*/}
        </>
      ) : (
        <>
          <Col
            span={
              employee?.status === EmployeeStatuses.WaitingForConfirmation
                ? 12
                : 24
            }>
            <StyledEmployeeAcceptInviteButton
              type="default"
              disabled={!manage || disabled}
              tooltipTitle={t('Confirm the request')}
              employee={employee!}
              handleAcceptEmployeeInvite={handleAcceptEmployeeInvite}>
              {t('Confirm the request')}
            </StyledEmployeeAcceptInviteButton>
          </Col>

          {employee?.status === EmployeeStatuses.WaitingForConfirmation ? (
            <Col span={12}>
              <StyledEmployeeCancelInviteButton
                type="default"
                employee={employee!}
                disabled={!manage || disabled}
                tooltipTitle={t('Reject the request')}
                handleCancelEmployeeInvite={handleCancelEmployeeInvite}>
                {t('Reject the request')}
              </StyledEmployeeCancelInviteButton>
            </Col>
          ) : null}
          <StyledDescription style={{ marginTop: 10, textAlign: 'center' }}>
            {t(
              'This employee registered from the application himself and sent a request to be added to the list of Company Employees. You can either add the Employee by confirming the request, or reject it.',
            )}
          </StyledDescription>
        </>
      )}
    </StyledRow>
  );
}
