import { List } from 'immutable';
import { APP_STATE, STATUS_CATEGORY_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { StatusCategoryModel } from '@structure';

interface IStatusCategoryListState {
  categoryList: List<StatusCategoryModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
}

interface SetActionList
  extends Pick<
    IStatusCategoryListState,
    'categoryList' | 'keywords' | 'total' | 'page'
  > {
  type: STATUS_CATEGORY_LIST.SET_STATUS_CATEGORY_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: STATUS_CATEGORY_LIST.ADD_STATUS_CATEGORY;
  category: StatusCategoryModel;
}

interface UpdateStatusCategoryFromList {
  type: STATUS_CATEGORY_LIST.UPDATE_STATUS_CATEGORY;
  category: StatusCategoryModel;
}

interface DeleteActionFromList {
  type: STATUS_CATEGORY_LIST.DELETE_STATUS_CATEGORY;
  categoryUuid: string;
}

interface LoadingActionInList {
  type: STATUS_CATEGORY_LIST.LOADING_STATUS_CATEGORY_LIST;
}

interface ErrorActionInList extends Pick<IStatusCategoryListState, 'error'> {
  type: STATUS_CATEGORY_LIST.ERROR_STATUS_CATEGORY_LIST;
}

interface Handlers {
  [STATUS_CATEGORY_LIST.SET_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
    action: SetActionList,
  ) => IStatusCategoryListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStatusCategoryListState,
    action: SetInitialStateAction,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.LOAD_MORE_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
    action: SetActionList,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.ADD_STATUS_CATEGORY]: (
    state: IStatusCategoryListState,
    action: AddActionToList,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.UPDATE_STATUS_CATEGORY]: (
    state: IStatusCategoryListState,
    action: UpdateStatusCategoryFromList,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.DELETE_STATUS_CATEGORY]: (
    state: IStatusCategoryListState,
    action: DeleteActionFromList,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.ERROR_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
    value: ErrorActionInList,
  ) => IStatusCategoryListState;

  [STATUS_CATEGORY_LIST.LOADING_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
    value?: LoadingActionInList,
  ) => IStatusCategoryListState;
  DEFAULT: (state: IStatusCategoryListState) => IStatusCategoryListState;
}

const initState: IStatusCategoryListState = {
  categoryList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [STATUS_CATEGORY_LIST.SET_STATUS_CATEGORY_LIST]: (
    state,
    { categoryList, keywords, total, page },
  ) => ({
    ...state,
    ...{
      categoryList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STATUS_CATEGORY_LIST.LOAD_MORE_STATUS_CATEGORY_LIST]: (
    state,
    { categoryList },
  ) => ({
    ...state,
    ...{
      categoryList:
        List.isList(state.categoryList) && List.isList(categoryList)
          ? state.categoryList.merge(categoryList)
          : state.categoryList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STATUS_CATEGORY_LIST.ADD_STATUS_CATEGORY]: (state, { category }) => ({
    ...state,
    ...{
      categoryList: List.isList(state.categoryList)
        ? state.categoryList.unshift(category)
        : List([category]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [STATUS_CATEGORY_LIST.UPDATE_STATUS_CATEGORY]: (state, { category }) => ({
    ...state,
    ...{
      categoryList: List.isList(state.categoryList)
        ? state.categoryList.map((stateStatusCategory) => {
            if (stateStatusCategory.uuid === category?.uuid) {
              return category;
            }
            return stateStatusCategory;
          })
        : List([category]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STATUS_CATEGORY_LIST.DELETE_STATUS_CATEGORY]: (state, { categoryUuid }) => ({
    ...state,
    ...{
      categoryList: List.isList(state.categoryList)
        ? state.categoryList.filter(compose(not, isEqualByUuid(categoryUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [STATUS_CATEGORY_LIST.ERROR_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STATUS_CATEGORY_LIST.LOADING_STATUS_CATEGORY_LIST]: (
    state: IStatusCategoryListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStatusCategoryListState) => state,
};

export default function StatusCategoryList(
  state: any = initState,
  action: any,
): IStatusCategoryListState {
  const handler =
    handlers[action.type as STATUS_CATEGORY_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
