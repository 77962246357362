import React from 'react';
import { StoreDocumentItemDTO, StoreDocumentItemModel } from '@structure';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface IStoreDocumentAmountTableFieldProps {
  documentItem: StoreDocumentItemDTO | StoreDocumentItemModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export function StoreDocumentAmountTableField({
  documentItem,
}: IStoreDocumentAmountTableFieldProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const diff =
    documentItem?.item_product_amount -
    documentItem?.item_product_returned_amount;

  return (
    <>
      {documentItem?.item_product_returned_amount > 0 ? (
        <StyledWrapper>
          <StyledTitle>{diff}</StyledTitle>
          <StyledContainer>
            <StyledDescription $color={theme.colors.error}>
              {`${t('Returned')} ${
                documentItem?.item_product_returned_amount
              } ${t('with')}`}
            </StyledDescription>
            <StyledDescription $color={theme.colors.error}>
              {documentItem?.item_product_amount}
            </StyledDescription>
          </StyledContainer>
        </StyledWrapper>
      ) : (
        <StyledTitle>{documentItem?.item_product_amount}</StyledTitle>
      )}
    </>
  );
}
