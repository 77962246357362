import { useCallback, useEffect, useRef } from 'react';
import { getTemplateList, TEMPLATES_TYPE } from '@services/api/templates';
import { Template, TemplateList } from '@services/models';
import { ListOptionsProps, isListToArray } from '@services/helpers';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseTemplateListProps extends Partial<ListOptionsProps> {
  loadOnInit?: boolean;
  showLoading?: boolean;
  companyUuid: string;
  type?: TEMPLATES_TYPE;
  keywords?: string;
}

export interface IUseTemplateListReturnType {
  error: Error | null;
  loading: boolean;
  list: Template[];
  refresh: (
    value: Partial<IUseTemplateListProps>,
  ) => Promise<Template[] | void>;
  total: number;
  offset: number;
  limit: number;
  order: string;
  keywords: string;
  filters: string[];
}

/**
 * @desc Список Співробітників Компанії
 * */
export default function useTemplateList(
  {
    offset = 0,
    order = 'created_at DESC',
    limit = 20,
    filters = [],
    loadOnInit = true,
    companyUuid,
    type,
    keywords = '',
  }: IUseTemplateListProps = {} as IUseTemplateListProps,
): IUseTemplateListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    offset: listPage,
    order: listOrder,
    limit: listPageSize,
    filters: listFilters,
    companyUuid: listCompanyUuid,
    handleUpdate,
    type: listType,
    keywords: listKeywords,
    ...rest
  } = useStateReducer<
    Omit<IUseTemplateListReturnType, 'refresh'> & {
      companyUuid: string;
      type?: TEMPLATES_TYPE;
    }
  >({
    error: null,
    loading: true,
    list: [],
    offset,
    limit,
    order,
    filters,
    total: 0,
    companyUuid,
    type,
    keywords,
  });

  const refresh = useCallback(
    async (
      {
        offset = listPage,
        order = listOrder,
        limit = listPageSize,
        filters = listFilters,
        companyUuid = listCompanyUuid,
        showLoading = true,
        type = listType,
        keywords = listKeywords,
        ...rest
      }: Partial<IUseTemplateListProps> = {} as IUseTemplateListProps,
    ): Promise<Template[] | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          order,
          filters,
          keywords,
        });

        const { templates, total } = await cancellablePromise<TemplateList>(
          getTemplateList({
            companyUuid,
            type,
            offset,
            keywords,
            limit,
          }),
        );

        const list = isListToArray(templates);

        if (!didCancel.current) {
          handleUpdate({
            list,
            total,
            loading: false,
          });

          return list;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listCompanyUuid],
  );

  useEffect(() => {
    didCancel.current = false;

    if (companyUuid) {
      handleUpdate({ companyUuid });
    }

    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    order: listOrder,
    filters,
    keywords: listKeywords,
    ...rest,
  };
}
