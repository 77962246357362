import React from 'react';

export interface EditableRowProps {
  index: number;
}

export function EditableRow({
  index,
  ...props
}: EditableRowProps): JSX.Element {
  return <tr {...props} />;
}
