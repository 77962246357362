import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { StoreDocumentFormDTO } from '@structure';
import { IUseStateStoredDocumentReturnType } from '@hooks';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

const OrderRealizationSideWindowLazy = React.lazy(
  () => import('../Show/OrderRealizationSideWindow'),
);

export interface IOrderRealizationCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleCreateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
}

const StyledAddButton = styled(AddButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: #d9d9d9;

  &&&:hover,
  &&&:active,
  &&&:focus {
    border-color: ${({ theme }) => theme.colors.link};
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
  }

  color: ${({ theme }) => theme.colors.primary};
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: rgb(194 193 193);
  font-size: 12px;
`;

export function OrderRealizationCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  ...rest
}: IOrderRealizationCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton onClick={handleOnInit} loading={loading} {...rest}>
        <StyledTitle>
          {t('Select a product or material from the list')}
        </StyledTitle>
        <StyledDownOutlined />
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <OrderRealizationSideWindowLazy
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
