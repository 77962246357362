import { Space } from 'antd';
import { StyledTitle } from '../Styled/Text';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { MdOutlineMenuOpen } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { useOutsideClickHandler, useSize } from '@hooks';
import Button from '@components/lib/DataDisplay/Button';
import React, { useRef, useState, useCallback, useEffect } from 'react';

export interface ICommentFieldProps {
  comment: string;
  handleUpdateComment: (comment: string) => Promise<void>;

  disabled?: boolean;
  withoutIndent?: boolean;
}

const DEFAULT_ITEM_HEIGHT = 65;
const CONTAINER_INDENT = 15;

const StyledContainer = styled.div<{
  $collapse: boolean;
  $withoutIndent: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.background.comment};
  margin-top: 20px;
  border-radius: 7px;
  padding: 15px;
  transition: all 0.5s;
  margin-bottom: 10px;

  ${({ $collapse }) =>
    $collapse &&
    css`
      transition: all 0.5s;
      padding-bottom: 40px;
    `}

  ${({ $withoutIndent }) =>
    $withoutIndent &&
    css`
      margin-top: 0;
    `}
`;

const StyledContent = styled.div<{
  $height: string | number;
  $isFirstRender: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  transition: height 0.5s;
  cursor: pointer;
  margin-top: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ $height, $isFirstRender }) =>
    $height &&
    css`
      height: ${typeof $height === 'number' ? `${$height}px` : $height};
      transition: ${$isFirstRender ? 'none' : 'height 0.5s'};
      align-items: ${typeof $height === 'number'
        ? $height >= DEFAULT_ITEM_HEIGHT
          ? 'flex-start'
          : 'center'
        : 'center'};
    `}
`;

const StyledButton = styled(Button)<{ $collapse: boolean }>`
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 50px;
  border: none;
  transition: all 0.5s;

  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.background.layout} 60%,
    transparent 100%
  );

  &&&:active,
  &&&:focus {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.background.layout} 60%
    );
  }

  &&&:hover {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.background.layout} 30%,
      transparent 100%
    );
  }

  ${({ $collapse }) =>
    $collapse &&
    css`
      height: auto;
      bottom: 5px;
      transition: all 0.5s;
    `}
`;

const StyledMdOutlineMenuOpen = styled(MdOutlineMenuOpen)`
  height: fit-content;
  margin-right: 10px;
  font-size: 25px;
`;

const StyledTextArea = styled.textarea<{
  $height?: string | number;
  $width: number;
}>`
  display: block;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  transition: all 0.5s;
  border-radius: 7px;
  padding: 5px;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

const StyledText = styled.pre`
  font-family: 'Helvetica Neue';

  && {
    margin: 0;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
`;

const buttonStyles = css`
  width: auto;
  height: auto;
`;

const StyledChangeButton = styled(Button)`
  ${buttonStyles};
  margin-left: 10px;
  box-shadow: none;

  &&:focus,
  &&:hover,
  &&:active {
    opacity: 0.7;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
`;

const StyledActionButton = styled(Button)`
  ${buttonStyles};
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.appLightDisable};
  border-radius: 7px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

export function CommentField({
  handleUpdateComment,
  comment,
  disabled,
  withoutIndent,
}: ICommentFieldProps): JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [showMore, setShowMore] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [value, setValue] = useState(
    comment === t('Please enter note') ? '' : comment,
  );
  const [prevTextHeight, setPrevTextHeight] = useState(0);
  const [prevEditableTextHeight, setPrevEditableTextHeight] = useState(0);

  const textRef = useRef<null>(null);
  const contentRef = useRef<null>(null);
  const textAreaRef = useRef<null>(null);
  const containerRef = useRef<null>(null);

  const { height: textHeight } = useSize(textRef);
  const { width: contentWidth } = useSize(contentRef);

  const isContentBig = textHeight > DEFAULT_ITEM_HEIGHT;

  const [contentHeight, setContentHeight] = useState<string | number>(0);

  const handleCollapse = useCallback(() => {
    if (Number(contentHeight) > DEFAULT_ITEM_HEIGHT) {
      setContentHeight(DEFAULT_ITEM_HEIGHT);
      setShowMore(false);
      return;
    }

    setContentHeight(textHeight);
    setShowMore(true);
  }, [contentHeight, textHeight]);

  const handelEditText = useCallback(() => {
    if (value !== comment && comment !== t('Please enter note')) {
      setValue(comment);
    }

    setIsEditable(true);
    setPrevTextHeight(textHeight);

    setContentHeight(
      Math.max(textHeight, DEFAULT_ITEM_HEIGHT, prevEditableTextHeight) +
        CONTAINER_INDENT,
    );
  }, [comment, prevEditableTextHeight, t, textHeight, value]);

  const handelCancelEditText = useCallback(
    (event: any) => {
      if (event) {
        event?.stopPropagation();
      }

      setIsEditable(false);
      setShowMore(false);
      setContentHeight(Math.min(DEFAULT_ITEM_HEIGHT, prevTextHeight));
    },
    [prevTextHeight],
  );

  useOutsideClickHandler(containerRef, handelCancelEditText);

  const onTextChange = useCallback((event: any) => {
    event.stopPropagation();
    setValue(event?.target?.value);

    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';

    setContentHeight(event.target.scrollHeight + CONTAINER_INDENT);
    setPrevEditableTextHeight(event.target.scrollHeight + CONTAINER_INDENT);
  }, []);

  const onFocus = useCallback(
    (event: any) => {
      event.target.style.height = 'auto';

      event.currentTarget.setSelectionRange(
        event.currentTarget.value.length,
        event.currentTarget.value.length,
      );

      event.target.style.height =
        Math.max(
          textHeight,
          DEFAULT_ITEM_HEIGHT,
          prevEditableTextHeight - CONTAINER_INDENT,
        ) + 'px';
    },
    [prevEditableTextHeight, textHeight],
  );

  const onSuccess = useCallback(
    async (event: any) => {
      event?.stopPropagation();
      try {
        setLoading(true);
        await handleUpdateComment(value);
        handelCancelEditText(event);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [handelCancelEditText, handleUpdateComment, value],
  );

  useEffect(() => {
    if (contentHeight === 0 && textHeight) {
      setContentHeight(isContentBig ? DEFAULT_ITEM_HEIGHT : 'auto');

      setTimeout(() => {
        setIsFirstRender(false);
      }, 500);
    }
  }, [contentHeight, isContentBig, textHeight]);

  return (
    <StyledContainer
      ref={containerRef}
      $collapse={showMore}
      $withoutIndent={!!withoutIndent}>
      <StyledHeader>
        <StyledMdOutlineMenuOpen color={theme.colors.text_colors.secondary} />
        <StyledTitle fontSize={20} $color={theme.colors.text_colors.secondary}>
          {t('note')}
        </StyledTitle>
        {isEditable || disabled ? null : (
          <StyledChangeButton type="link" size="small" onClick={handelEditText}>
            <EditOutlined />
            {t('Change')}
          </StyledChangeButton>
        )}
      </StyledHeader>
      <StyledContent
        disabled={disabled}
        ref={contentRef}
        $isFirstRender={isFirstRender}
        $height={
          typeof contentHeight === 'number'
            ? contentHeight + CONTAINER_INDENT
            : contentHeight
        }
        onClick={disabled ? undefined : handelEditText}>
        <div ref={textRef}>
          {isEditable && !disabled ? (
            <StyledTextArea
              id="textarea"
              ref={textAreaRef}
              // onBlur={handelCancelEditText}
              autoFocus
              onFocus={onFocus}
              $width={contentWidth}
              value={value}
              placeholder={t('Please enter note')}
              onChange={onTextChange}
              onClick={(event) => event?.stopPropagation()}
            />
          ) : (
            <>
              <StyledText>{comment}</StyledText>
            </>
          )}
        </div>
      </StyledContent>
      {isContentBig && !isEditable ? (
        <StyledButton $collapse={showMore} type="text" onClick={handleCollapse}>
          <StyledTitle>
            {showMore ? t('Hide full description') : t('Show full description')}
          </StyledTitle>
        </StyledButton>
      ) : null}
      {isEditable ? (
        <StyledButtonsContainer>
          <Space size="middle" direction="horizontal">
            <StyledActionButton type="primary" onClick={onSuccess}>
              {t('Save')}
            </StyledActionButton>
            <StyledActionButton type="text" onClick={handelCancelEditText}>
              {t('Cancel')}
            </StyledActionButton>
          </Space>
        </StyledButtonsContainer>
      ) : null}
      {loading ? (
        <LoadingContainer>
          <StyledLoadingOutlined />
        </LoadingContainer>
      ) : null}
    </StyledContainer>
  );
}
