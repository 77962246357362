import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { MdOutlineCreditCard } from 'react-icons/md';
import styled, { useTheme, css } from 'styled-components';
import { Link } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { DocumentNumber } from '@components/lib/General';
import { StoreDocumentModel, StoreDocumentStatus } from '@structure';
import { StoreDocumentScoreStatusView } from 'views/Stores/Show/StoreDocumentScoreStatusView';

export interface IStoreDocumentPageHeaderProps {
  document: StoreDocumentModel | null;
  withoutIndent?: boolean;
  withoutPayment?: boolean;
  withoutScore?: boolean;
}

const StyledWrapper = styled.div<{ $withoutIndent: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({ $withoutIndent }) =>
    !$withoutIndent &&
    css`
      padding-left: 24px;
      padding-right: 24px;
    `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  width: fit-content;
  height: fit-content;
  margin-left: auto;
  padding: 10px;

  background-color: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.disabled};
  border-radius: 7px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.link};
  }
`;

export function StoreDocumentPageHeader({
  document,
  withoutIndent,
  withoutPayment = false,
  withoutScore = true,
}: IStoreDocumentPageHeaderProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledWrapper $withoutIndent={!!withoutIndent}>
      <DocumentNumber
        isDraft={document?.doc_status_text === StoreDocumentStatus.DRAFT}
        number={`${document?.doc_local_number}`}
        date={document?.doc_date}
      />
      {!withoutScore && <StoreDocumentScoreStatusView document={document} />}
      {document?.doc_is_paymentable && !withoutPayment ? (
        <StyledLink
          withoutRight
          to={`${Routes.payments}/${Routes.stores}`}
          tooltip={t('Go to payments details page')}
          state={{ goBack: location.pathname }}>
          <MdOutlineCreditCard size={18} />
          <StyledTitle fontSize={16}>{t('Payments')}</StyledTitle>
        </StyledLink>
      ) : null}
    </StyledWrapper>
  );
}
