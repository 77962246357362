import React from 'react';
import { isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IDefaultTabPanelProps, TabItem } from '@components/lib/General';
import { StoreDocumentModel } from '@structure';
import { useNavigate } from 'react-router';

export enum StoreReturnTabs {}

export interface IStoreReturnTabPanelProps {
  document: StoreDocumentModel | null;
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function StoreReturnTabPanel({
  children,
  document,
}: IStoreReturnTabPanelProps): React.JSX.Element {
  const navigate = useNavigate();

  const storeTabs: TabItem[] = [];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({ tabs: storeTabs })}</>;
}
