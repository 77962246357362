import * as React from 'react';
import {
  usePriceMargin,
  IUsePriceMarginProps,
  IUsePriceMarginReturnType,
} from './usePriceMargin';
import {
  PriceMarginFormDTO,
  PriceMarginModel,
  PriceMarginMapper,
} from '@structure';
import { createPriceMargin, editPriceMargin } from '@services/api/priceMargin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { useEffect } from 'react';
import {
  addPriceMarginToList as storeAddPriceMargin,
  setPriceMargin as storeSetPriceMargin,
  updatePriceMargin as storeUpdatePriceMargin,
  updatePriceMarginFromList as storeUpdatePriceMarginFromList,
  resetPriceMargin as storeResetPriceMargin,
} from '@store/actions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStatePriceMarginProps extends IUsePriceMarginProps {
  companyUuid: string;
}

export interface IUseStatePriceMarginReturnType
  extends Omit<IUsePriceMarginReturnType, 'entity'> {
  priceMargin: PriceMarginModel | null;
  handleUpdatePriceMargin: (value: PriceMarginFormDTO) => Promise<void>;
  handleCreatePriceMargin: (value: PriceMarginFormDTO) => Promise<void>;
  handleRefreshPriceMargin: () => Promise<void>;
  handleResetPriceMargin: () => void;
  status: REDUX_STATUS;
}

export function useStoredPriceMargin({
  companyUuid,
  loadOnInit,
  priceMarginUuid,
  ...rest
}: IUseStatePriceMarginProps): {
  handleUpdatePriceMargin: (value: PriceMarginFormDTO) => Promise<void>;
  handleResetPriceMargin: () => void;
  handleCreatePriceMargin: (value: PriceMarginFormDTO) => Promise<void>;
  handleRefreshPriceMargin: () => Promise<void>;
  refresh: (
    value: Partial<IUsePriceMarginProps>,
  ) => Promise<PriceMarginModel | void>;
  error: ApiError | null;
  loading: boolean;
  priceMargin: PriceMarginModel | null;
  priceMarginUuid: string;
} {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [priceMargin, setPriceMargin] = React.useState<PriceMarginModel | null>(
    null,
  );
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const { cachedPriceMarginList, status, ...storedPriceMarginParams } =
    useSelector(({ priceMargin }: RootState) => priceMargin);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...priceMarginParams } = usePriceMargin({
    loadOnInit: loadOnInit || isStarLoading,
    priceMarginUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedPriceMarginList?.size > 0) {
      const priceMargin = cachedPriceMarginList.get(priceMarginUuid);

      if (priceMargin) {
        setIsStartLoading(false);
        setPriceMargin(priceMargin);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedPriceMarginList?.size === 0 && !priceMargin) {
      setIsStartLoading(true);
    }
  }, [cachedPriceMarginList, dispatch, priceMarginUuid, priceMargin]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetPriceMargin(entity));
      setPriceMargin(entity);
    }
  }, [dispatch, entity, isStarLoading]);

  const handleUpdatePriceMargin = React.useCallback(
    async (value: PriceMarginFormDTO): Promise<void> => {
      const priceMarginDTO = await editPriceMargin(value);

      const priceMarginModel =
        PriceMarginMapper.toPriceMarginModel(priceMarginDTO);

      dispatch(storeUpdatePriceMargin(priceMarginModel));
      dispatch(storeUpdatePriceMarginFromList(priceMarginModel));

      setPriceMargin(priceMarginModel);
    },
    [dispatch],
  );

  const handleCreatePriceMargin = React.useCallback(
    async (value: PriceMarginFormDTO) => {
      const priceMarginDTO = await createPriceMargin(value, companyUuid);

      const priceMarginModel =
        PriceMarginMapper.toPriceMarginModel(priceMarginDTO);

      dispatch(storeAddPriceMargin(priceMarginModel));
    },
    [companyUuid, dispatch],
  );

  const handleResetPriceMargin = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetPriceMargin());
  }, [dispatch]);

  const handleRefreshPriceMargin = React.useCallback(async () => {
    const priceMarginModel = await refresh({
      showLoading: false,
      priceMarginUuid,
    });

    if (priceMarginModel) {
      dispatch(storeUpdatePriceMargin(priceMarginModel));
      dispatch(storeUpdatePriceMarginFromList(priceMarginModel));
    }
  }, [priceMarginUuid, dispatch, refresh]);

  return {
    ...priceMarginParams,
    ...storedPriceMarginParams,
    priceMargin,
    loading: !priceMargin,
    handleUpdatePriceMargin,
    handleCreatePriceMargin,
    handleResetPriceMargin,
    refresh,
    handleRefreshPriceMargin,
  };
}
