import * as React from 'react';
import { useDrag, useDragLayer, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useEffect, useState } from 'react';
import { DRAG_ITEM_TYPE } from '@services/types';
import { snapToGridTime, fromTimeToMinute } from '@services/helpers';

export interface IUseDragCalendarEventProps {
  time?: string;
  isDrag?: boolean;
}

export interface IUseDragCalendarEventReturnType {
  isDragging: boolean;
  drag: any;
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
  eventTime: any;
}

export default function useDragCalendarEvent({
  time,
}: IUseDragCalendarEventProps): IUseDragCalendarEventReturnType {
  const [eventTime, setEventTime] = useState<any>(null);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DRAG_ITEM_TYPE.BOX,
      collect: (monitor) => {
        // console.log(
        //   monitor.getInitialSourceClientOffset(),
        //   monitor.getSourceClientOffset(),
        // );

        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [],
  );

  const { initialOffset, currentOffset } = useDragLayer((monitor) => {
    return {
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    };
  });

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialOffset && currentOffset && isDragging) {
      const minutes = fromTimeToMinute(time!);

      const { time: eventTime }: any = snapToGridTime(
        initialOffset,
        currentOffset,
        minutes,
      );

      setEventTime(eventTime);
    }
  }, [currentOffset, initialOffset, isDragging, time]);

  return {
    isDragging,
    drag,
    initialOffset,
    currentOffset,
    eventTime,
  };
}
