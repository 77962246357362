import { FunctionArgs } from '@sportix/sportix-common-modules';
import { findIndex, curry, compose, ifElse } from './index';

export const findKeyIndex = curry<any, any>((key: string, items: any[]) =>
  findIndex<[FunctionArgs<any, boolean>], number>((s: any) => s.key === key)(
    items,
  ),
);

export const getIndexByKey = curry<any, any>((items: any[], key: string) =>
  compose<any>(
    (value: any) => ifElse(value, value, 0),
    findKeyIndex(key),
  )(items),
);
