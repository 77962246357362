import { Empty } from 'antd';
import { ServiceList } from '../List';
import { useAcl } from '@contex';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ServiceModel } from '@structure';
import { PlusOutlined } from '@ant-design/icons';
import { ListLayout } from '@components/lib/Layout';
import { textToUpperCase } from '@services/helpers';
import React, { useCallback, useMemo, useState } from 'react';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { successButtonStyle } from '@components/lib/Styled';
import { ServicesCreateButton, ServicesDeleteButton } from '../Buttons';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';

import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateServiceList,
  useStopLoading,
  useStoredCompanies,
} from '@hooks';

import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';

export interface IServiceListPageProps {}

const StyledRowCellContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: inherit;
`;

const StyledServicesCreateButton = styled(ServicesCreateButton)`
  ${successButtonStyle}
`;

export default function ServiceListPage({}: IServiceListPageProps): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { manage } = useAcl(({ service }) => service);
  const { t } = useTranslation();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'service-create-event',
    disabled: !manage,
  });

  const [selectedServices, setSelectedServices] = useState<ServiceModel[]>([]);

  const {
    services,
    limit,
    total,
    refresh: refreshServiceList,
    loading: loadingServiceList,
    error: errorServiceList,
    handleCreateService,
    handleDeleteServices: onDeleteServices,
    handleUpdateService,
    handleSearchServices,
  } = useStateServiceList({ companyUuid: defaultCompanyUuid });

  const loading = useStopLoading({
    loading: loadingServiceList,
    error: errorServiceList,
    message: 'An error occurred during services loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteServices = useCallback(
    async (value: string[]) => {
      await onDeleteServices(value);
      focus();
    },
    [focus, onDeleteServices],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.services}`,
      breadcrumbName: 'Services',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedServices.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ServiceModel[],
      ) => {
        setSelectedServices(selectedRecords);
      },
      renderCell: (
        checked: boolean,
        service: ServiceModel,
        index: number,
        originNode: React.ReactNode,
      ) => (
        <StyledRowCellContainer className="row-selection--colors">
          {originNode}
        </StyledRowCellContainer>
      ),
    }),
    [selectedServices],
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.services]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.services]))}
      headerRight={
        <>
          {selectedServices?.length ? (
            <ServicesDeleteButton
              disabled={!manage}
              services={selectedServices}
              onSuccess={handleDeleteServices}
            />
          ) : (
            <StyledServicesCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              companyUuid={defaultCompanyUuid}
              onSuccess={handleCreateService as any}
              title={t('Create')}
              icon={<PlusOutlined />}
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        services?.size ? null : (
          <Empty description={t('There are no services created')}>
            <ServicesCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              companyUuid={defaultCompanyUuid}
              onSuccess={handleCreateService as any}
              title={t('Create service')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchServices({ keywords })}
          inputTooltip={t('Search services')}
          inputLabel={t('Search services')}
          withSearchContent={null}
        />
      }
      loading={loading && !services?.size}>
      <ServiceList
        services={services}
        loading={loading}
        onRefresh={refreshServiceList}
        rowSelection={rowSelection}
        limit={limit}
        total={total}
        handleUpdate={handleUpdateService}
        daleyFocus={daleyFocus}
        handleDelete={handleDeleteServices}
      />
    </ListLayout>
  );
}
