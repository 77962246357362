import * as React from 'react';
import { Routes } from '@services/types';
import { ResetPasswordDTO } from '@structure';
import { resetPassword } from '@services/api/user';
import { useTranslation } from 'react-i18next';
import {
  IUseStoredAuthDataReturnType,
  useStoredAuthData,
} from './useStoredAuthData';
import { IResetPasswordPageProps } from '../views/Authorization';
import {
  IStoreVerificationFactoryReturnType,
  useStoreVerificationFactory,
  IUseStoreVerificationFactoryProps,
} from './useStoreVerificationFactory';
import { IForgotPasswordPageState } from '@store/actions';
import { useDropdownAlert } from '@contex';

export interface IUseStateResetPasswordProps
  extends Pick<
    IUseStoreVerificationFactoryProps<IForgotPasswordPageState>,
    'getState'
  > {}

export interface IUseStateResetPasswordReturnType<T>
  extends Pick<IUseStoredAuthDataReturnType, 'authData'>,
    IStoreVerificationFactoryReturnType<T> {
  handleResetPassword: (value: ResetPasswordDTO) => Promise<void>;
  notifyError: (error: any) => void;
  loading: boolean;
}

export function useStateResetPassword(
  { getState }: IUseStateResetPasswordProps = {} as IUseStateResetPasswordProps,
): IUseStateResetPasswordReturnType<IForgotPasswordPageState> &
  IForgotPasswordPageState {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { authRedirect, updateAuthData, authData } = useStoredAuthData();

  const { updateStateFactory, resetStateFactory, ...verificationState } =
    useStoreVerificationFactory<IForgotPasswordPageState>({
      key: Routes.forgotPassword,
      getState,
    });

  /**
   * @desc Встановлення нового пароля
   * */
  const handleResetPassword = React.useCallback(
    async (value: ResetPasswordDTO): Promise<void> => {
      if (authData?.verification_code || verificationState?.verification_code) {
        const authDataDTO = await resetPassword({
          ...value,
          verification_code:
            authData?.verification_code ||
            verificationState?.verification_code ||
            '',
        });

        resetStateFactory();

        updateAuthData({ verification_code: '' });

        // const {identifier: prevIdentifier} = await LoginParams.getLoginParams();
        //
        // if (verificationState?.identifier === prevIdentifier) {
        //   await LoginParams.setPassword(value?.password);
        // }

        await authRedirect(authDataDTO);

        alert('success', t('Reset password'), t('Reset password success'));
      }
    },
    [
      authData?.verification_code,
      verificationState?.verification_code,
      resetStateFactory,
      updateAuthData,
      authRedirect,
      alert,
      t,
    ],
  );

  const notifyError = React.useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Reset password'),
        `${t('An error occurred during reset password')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return {
    ...(verificationState as IStoreVerificationFactoryReturnType<IForgotPasswordPageState> &
      IForgotPasswordPageState),
    handleResetPassword,
    notifyError,
    authData,
    updateStateFactory,
    resetStateFactory,
  };
}
