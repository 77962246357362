import { Dispatch } from 'redux';
import { STATUS } from '../constants';
import { StatusModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetStatusParam {
  type: STATUS;
  status: StatusModel | null;
}

export interface IUpdateStatusParam {
  type: STATUS;
  status: StatusModel;
}

export interface IResetStatusParam {
  type: STATUS;
}

export interface IErrorStatusParam {
  type: STATUS;
  error: ApiError | undefined;
}

export function setStatus(
  status: StatusModel | null,
): (dispatch: Dispatch) => Promise<ISetStatusParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS.SET_STATUS,
      status,
    });
  };
}

export function updateStatus(
  status: StatusModel,
): (dispatch: Dispatch) => Promise<IUpdateStatusParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS.UPDATE_STATUS,
      status,
    });
  };
}

export function resetStatus(): (
  dispatch: Dispatch,
) => Promise<IResetStatusParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS.RESET_STATUS,
    });
  };
}

export function errorStatus(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStatusParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS.ERROR_STATUS,
      error,
    });
  };
}
