import * as React from 'react';
import { useCallback } from 'react';
import {
  CheckboxAttributesCashBox,
  IntegrationCheckboxAttributesDTO,
  IntegrationFormDTO,
} from '@structure';
import {
  EditableCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { TableActionCell } from '@components/lib/libV2/DataDisplay';
import { DeleteButton, EditButton } from '@components/lib/DataDisplay';
import { TextFormat } from '@components/lib/Format';
import { produce } from 'immer';

import '../views/Orders/List/OrderList.less';

export interface IUseIntegrationCheckboxFieldParamsProps {
  form: FormInstance;
  handleUpdateCashBox: (value: CheckboxAttributesCashBox) => void;
  handleDeleteCashBox: (value: CheckboxAttributesCashBox) => void;
  disabled?: boolean;
}

export function useIntegrationCheckboxFieldParams({
  form,
  handleUpdateCashBox,
  handleDeleteCashBox,
  disabled,
}: IUseIntegrationCheckboxFieldParamsProps) {
  const { t } = useTranslation();

  const defaultColumns = [
    {
      title: t('Title'),
      key: 'cashbox_name',
      editable: true,
      rules: [
        {
          required: true,
          message: t('Title must be specified.'),
        },
      ],
      render: (cashBox: CheckboxAttributesCashBox) => cashBox?.cashbox_name,
    },
    // {
    //   title: t('License key'),
    //   key: 'cashbox_license_key',
    //   editable: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: t('License key must be specified.'),
    //     },
    //   ],
    //   align: 'right' as any,
    //   render: (cashBox: CheckboxAttributesCashBox) =>
    //     cashBox?.cashbox_license_key,
    // },
    // {
    //   title: t('Fiscal number'),
    //   key: 'cashbox_id',
    //   align: 'right' as any,
    //   render: (cashBox: CheckboxAttributesCashBox) => cashBox?.cashbox_id,
    // },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'integration_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (cashBox: CheckboxAttributesCashBox) => {
        return (
          <TableActionCell>
            <EditButton
              tooltipTitle={t('Edit material')}
              onClick={() => handleUpdateCashBox(cashBox)}
              disabled={false}
            />
            <DeleteButton
              tooltipTitle={t('Delete material')}
              confirmTitle={
                <TextFormat breakWord>
                  {t('Are you sure you want to delete')} {t('material')}?
                </TextFormat>
              }
              onConfirm={() => handleDeleteCashBox(cashBox)}
              cancelButtonText={t('Cancel')}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const handleSave = useCallback(
    async ({
      attributes: at,
      status,
      ...rest
    }: IntegrationFormDTO<IntegrationCheckboxAttributesDTO>) => {
      const attributes = form.getFieldValue('attributes');

      const newData = [...(attributes?.cashboxes || [])];
      const index = newData.findIndex((item) => rest.uuid === item.uuid);
      const item = newData[index];

      const rowDTO = {
        ...item,
        ...rest,
      };

      const updatedData = produce<IntegrationCheckboxAttributesDTO>(
        attributes,
        (draft) => {
          draft.cashboxes = produce(newData, (draft) => {
            draft[index] = rowDTO;
          });
        },
      );

      form.setFieldsValue({
        attributes: updatedData,
      });
    },
    [form],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: CheckboxAttributesCashBox) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          alignText: col?.align,
        };
      },
    };
  });

  return {
    columns,
    components,
  };
}
