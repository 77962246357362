import React, { useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IOrderFormProps, OrderForm } from '../Forms';
import { ScheduleCalendarFormDTO } from '@structure';
import { CenterModal } from '@components/lib/DataDisplay';
import { useModal } from '@components/lib/libV2/hooks';
import { Modal } from 'antd';
import { StyledTitle } from '@components/lib/Styled';

export default withTranslation()(OrderSideWindow);

export interface IOrderSideWindowProps
  extends WithTranslation,
    IOrderFormProps {
  onSuccess: (value: ScheduleCalendarFormDTO) => Promise<void>;
  visible: boolean;
  companyUuid: string;
}

function OrderSideWindow({
  t,
  onSuccess,
  visible,
  editMode,
  companyUuid,
  onCancel,
  ...rest
}: IOrderSideWindowProps) {
  const [modal, contextHolder] = Modal.useModal();

  const {
    handleCancel: handleCancelCloseModal,
    handleSuccess: handleSuccessCloseModal,
  } = useModal({
    onSuccess: onCancel,
  });

  const handleCancelSideWindows = useCallback(() => {
    modal.confirm({
      title: (
        <StyledTitle>
          {t(
            'Are you going to close the current, unfilled form, or do you really want to close the form?',
          )}
        </StyledTitle>
      ),
      style: { minWidth: '40%' },
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: handleSuccessCloseModal,
      onCancel: handleCancelCloseModal,
    });
  }, [modal, t, handleSuccessCloseModal, handleCancelCloseModal]);

  return (
    <>
      {visible ? (
        <CenterModal
          onCancel={(e) => {
            e.stopPropagation();
            handleCancelSideWindows();
          }}
          footer={null}
          title={editMode ? t('Order') : t('New order')}
          open={visible}
          data-testid="order-form-side-window">
          <OrderForm
            asModal
            companyUuid={companyUuid}
            editMode={editMode}
            onSuccess={onSuccess}
            onCancel={handleCancelSideWindows}
            {...rest}
          />
        </CenterModal>
      ) : null}

      {contextHolder}
    </>
  );
}
