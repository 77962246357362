import React from 'react';
import { ServiceModel } from '@structure';
import { PERSONALITY_TYPE, ClientPersonalityType } from '@services/types';
import { FaUserAlt, FaUsers } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';

export interface IServicePersonalityViewProps {
  service: ServiceModel | null;
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  onlyIcon?: boolean;
}

const StyledWrapper = styled.div<{ $isLineHeight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ $isLineHeight }) =>
    $isLineHeight &&
    css`
      padding: 3px;
      line-height: 1.5;
    `}
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ServicePersonalityView({
  service,
  title,
  description,
  icon,
  onlyIcon = false,
  ...rest
}: IServicePersonalityViewProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? (
        icon
      ) : service?.personality === PERSONALITY_TYPE.GROUP ||
        (service as any)?.personality === ClientPersonalityType.Group ? (
        <FaUsers size={description ? 20 : 18} />
      ) : (
        <FaUserAlt size={description ? 18 : 16} />
      )}
      {onlyIcon ? null : (
        <StyledWrapper $isLineHeight={!!description}>
          <StyledTitle fontSize={13}>
            {t(title || service?.personality)}
          </StyledTitle>
          {description ? (
            <StyledDescription>{description}</StyledDescription>
          ) : null}
        </StyledWrapper>
      )}
    </StyledTitleIconContainer>
  );
}
