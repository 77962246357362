import * as React from 'react';
import { useEffect } from 'react';
import { List } from 'immutable';
import {
  IUseRewardListProps,
  IUseRewardListReturnType,
  useRewardList,
} from './useRewardList';
import { RewardDocumentType, RewardModel, RewardStatsDTO } from '@structure';
import { deleteReward, getRewardDocument } from '@services/api/reward';
import {
  deleteRewardFromList as storeDeleteReward,
  loadMoreRewardList as storeLoadMoreRewardList,
  setRewardList as storeSetRewardList,
  updateRewardStatsFromList as storeUpdateRewardStatsFromList,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { useStateReducer } from '@components/lib/libV2/hooks';
import {
  head,
  isListToArray,
  printScheduleDocument,
  toDateByFormat,
} from '@services/helpers';

export interface IUseStateRewardListProps extends IUseRewardListProps {}

export interface IStateRewardList {
  rewardList: List<RewardModel> | null;
  loadingMore: boolean;
  page: number;
  stats: RewardStatsDTO | null;
  total: number;
  keywords: string;
  salarySettingsDates: string[];
}

export interface IUseStateRewardListReturnType
  extends Omit<IUseRewardListReturnType, 'entityList' | 'stats'>,
    IStateRewardList {
  handleDeleteRewards: (ids: string[]) => Promise<void>;
  handleSearchRewards: (keywords: string) => Promise<void>;
  handleLoadMoreRewards: () => Promise<void>;
  handleRefreshRewards: (
    value: Partial<IUseRewardListProps> & {
      page: number;
      totalAccrued?: string;
    },
  ) => Promise<void>;
  handlePrintRewardDocument: (
    documentType?: RewardDocumentType,
  ) => Promise<void>;
  status: REDUX_STATUS;
}

export function useStoredRewardList(
  {
    employeeUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateRewardListProps = {} as IUseStateRewardListProps,
): IUseStateRewardListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    loadingMore,
    rewardList,
    keywords: storeKeywords,
    page: storePage,
    total: storeTotal,
    stats,
    salarySettingsDates,

    handleUpdate,
  } = useStateReducer<IStateRewardList>({
    loadingMore: false,
    rewardList: null,
    keywords: '',
    total: 0,
    page: 1,
    stats: null,
    salarySettingsDates: [],
  });

  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedRewardListStatus,
    loading: storedRewardListLoading,
    cachedRewardList,
    ...storedRewardListParams
  } = useSelector(({ rewardList }: RootState) => rewardList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: rewardListLoading,
    total,
    month,
    year,
    ...rewardsParams
  } = useRewardList({
    employeeUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedRewardList?.size > 0) {
      const rewardList = cachedRewardList.get(employeeUuid);

      if (rewardList) {
        setIsStartLoading(false);

        handleUpdate({
          rewardList: rewardList?.rewards,
          page: rewardList?.page || 0,
          keywords: rewardList?.keywords || '',
          total: rewardList?.total || 0,
          stats: rewardList?.stats || null,
        });
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedRewardList?.size === 0 && !rewardList) {
      setIsStartLoading(true);
    }
  }, [cachedRewardList, employeeUuid, handleUpdate, rewardList]);

  React.useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({ employeeUuid });

        if (entityList) {
          dispatch(storeSetRewardList(entityList, employeeUuid, ''));
        }
      })();
    }
  }, [employeeUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteRewards = React.useCallback(
    async (ids: string[]) => {
      try {
        const { stats } = await deleteReward(ids, {
          employeeUuid,
          keywords: storeKeywords,
          month,
          year,
        });

        setTimeout(() => {
          dispatch(storeDeleteReward(head(ids), employeeUuid));
        }, 100);

        dispatch(storeUpdateRewardStatsFromList(stats, employeeUuid));

        alert('success', t('Reward'), t('Reward delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Reward'),
          `${t('An error occurred during delete rewards')}: ${error?.message}`,
        );
      }
    },
    [employeeUuid, storeKeywords, month, year, dispatch, alert, t],
  );

  const handleSearchRewards = React.useCallback(
    async (keywords: string) => {
      handleUpdate({ loadingMore: true });
      const rewardListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (rewardListModel && List.isList(rewardListModel?.rewards)) {
        dispatch(
          storeSetRewardList(
            rewardListModel,
            employeeUuid,
            keywords,
            rewardListModel.total,
          ),
        );
      }
      handleUpdate({ loadingMore: false });
    },
    [handleUpdate, refresh, dispatch, employeeUuid],
  );

  const handleLoadMoreRewards = React.useCallback(async () => {
    if (
      List.isList(rewardList) &&
      rewardList?.size < storeTotal &&
      !loadingMore
    ) {
      handleUpdate({ loadingMore: true });

      const rewardListModel = await refresh({
        offset: rewardList?.size,
        limit: 10,
        showLoading: false,
      });

      if (rewardListModel && List.isList(rewardListModel?.rewards)) {
        dispatch(storeLoadMoreRewardList(rewardListModel, employeeUuid));
      }

      handleUpdate({ loadingMore: false });
    }
  }, [
    dispatch,
    employeeUuid,
    handleUpdate,
    loadingMore,
    refresh,
    rewardList,
    storeTotal,
  ]);

  const handleRefreshRewards = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = true,
      page,
      month,
      year,
      totalAccrued,
    }: Partial<IUseRewardListProps> & {
      page: number;
      totalAccrued?: string;
    }) => {
      const rewardListModel = await refresh({
        offset,
        limit,
        showLoading,
        month,
        year,
        totalAccrued,
      });

      if (rewardListModel && List.isList(rewardListModel?.rewards)) {
        dispatch(
          storeSetRewardList(
            rewardListModel,
            employeeUuid,
            storeKeywords,
            rewardListModel?.total,
            page,
          ),
        );
      }
    },
    [employeeUuid, dispatch, refresh, storeKeywords],
  );

  useEffect(() => {
    if (List.isList(stats?.employee?.salary_settings)) {
      const salarySettingsDates = stats?.employee?.salary_settings?.map(
        ({ start_date }) => toDateByFormat(start_date, 'MM.YYYY'),
      );

      handleUpdate({
        salarySettingsDates: isListToArray(salarySettingsDates as any),
      });
    }
  }, [handleUpdate, stats?.employee?.salary_settings]);

  const handlePrintRewardDocument = React.useCallback(
    async (
      documentType: RewardDocumentType = RewardDocumentType.Rewards,
    ): Promise<void> => {
      try {
        const html = await getRewardDocument(employeeUuid, {
          month,
          year,
          documentType,
        });

        alert('success', t('Document'), t('Get document success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Document'),
          `${t('An error occurred during get document')} : ${error?.message}`,
        );
      }
    },
    [alert, employeeUuid, month, t, year],
  );

  return {
    ...rewardsParams,
    ...storedRewardListParams,
    rewardList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(rewardList) && rewardListLoading) ||
      !storedRewardListLoading,
    handleDeleteRewards,
    handleSearchRewards,
    handleLoadMoreRewards,
    status: storedRewardListStatus,
    handleRefreshRewards,
    keywords: storeKeywords || '',
    page: storePage || 1,
    stats,
    salarySettingsDates,
    month,
    year,
    handlePrintRewardDocument,
  };
}
