import { AuthRouteLayout } from '../Show';
import { AuthPageWrapper } from '../common';
import { useNavigate } from 'react-router';
import { ForgotPasswordForm } from './Forms';
import AuthLayout from '../Show/AuthLayout';
import { Routes } from '@services/types';
import { ForgotPasswordTabPanel } from './Show';
import { useDropdownAlert } from '@contex';
import { useStateForgotPassword } from '@hooks';
import React, { useCallback, useRef, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ForgotPasswordDTO, ForgotPasswordMapper } from '@structure';
import { WarningNavigateButton } from '../Buttons';

export default withTranslation()(ForgotPasswordPage);

export interface IForgotPasswordPageProps extends WithTranslation {}

/**
 * @name ForgotPasswordPage
 * @desc ForgotPasswordPage component.
 */
function ForgotPasswordPage({ t }: IForgotPasswordPageProps) {
  const navigate = useNavigate();

  const {
    loading,
    identifier,
    approveEnter,

    handleForgotPassword,
    notifyError,
    navigateToVerificationFormModal,
  } = useStateForgotPassword();

  const { alert } = useDropdownAlert();

  const isApproveEnter = typeof approveEnter === 'boolean' && !approveEnter;

  const once = useRef(false);

  const navigationToResetPasswordScreen = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.resetPassword}`);
  }, [navigate]);

  const navigationFactory = useCallback(() => {
    if (isApproveEnter) {
      return navigationToResetPasswordScreen();
    }

    return navigateToVerificationFormModal({ hiddenResendButton: true });
  }, [
    isApproveEnter,
    navigateToVerificationFormModal,
    navigationToResetPasswordScreen,
  ]);

  useEffect(() => {
    if (!loading && !once.current) {
      if (identifier) {
        alert(
          'warn',
          isApproveEnter
            ? t('You have not finished changing your password')
            : t('You have not completed restoring access to your account'),
          isApproveEnter
            ? t('Finish password recovery')
            : t('Finish restoring access to the account'),
        );
      }
      once.current = true;
    }
  }, [approveEnter, identifier, isApproveEnter, loading, alert, t]);

  return (
    <AuthLayout>
      <ForgotPasswordTabPanel>
        {({ routes }) => (
          <AuthRouteLayout
            header={
              <>
                <AuthPageWrapper
                  title={t('Restore access')}
                  description={t(
                    'If you forgot password and lost access to the system, this form can help you to restore it. All you need to do is just specify the phone OR email which has been used to create your account.',
                  )}>
                  <ForgotPasswordForm
                    showNotify={false}
                    notifyError={notifyError}
                    notifyFormName={t('Forgot password')}
                    forgotPassword={ForgotPasswordMapper.toForgotPasswordFormDTO(
                      new ForgotPasswordDTO(),
                    )}
                    onSuccess={handleForgotPassword}
                  />
                  {identifier ? (
                    <WarningNavigateButton
                      onClick={navigationFactory}
                      title={
                        isApproveEnter
                          ? t('Finish password recovery')
                          : t('Finish restoring access to the account')
                      }
                    />
                  ) : null}
                </AuthPageWrapper>
              </>
            }
            routes={routes}
          />
        )}
      </ForgotPasswordTabPanel>
    </AuthLayout>
  );
}
