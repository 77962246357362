import { Map } from 'immutable';
import { APP_STATE, REPORT } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ReportModel } from '@structure';

interface IReportListState {
  report: ReportModel | null;
  cachedReportList: Map<string, ReportModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IReportListState, 'report'> {
  type: REPORT.SET_REPORT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface LoadingAction {
  type: REPORT.LOADING_REPORT;
}

interface ResetAction {
  type: REPORT.RESET_REPORT;
}

interface ErrorAction extends Pick<IReportListState, 'error'> {
  type: REPORT.ERROR_REPORT;
}

interface Handlers {
  [REPORT.SET_REPORT]: (
    state: IReportListState,
    action: SetAction,
  ) => IReportListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IReportListState,
    action: SetInitialStateAction,
  ) => IReportListState;

  [REPORT.RESET_REPORT]: (
    state: IReportListState,
    action: ResetAction,
  ) => IReportListState;

  [REPORT.ERROR_REPORT]: (
    state: IReportListState,
    value: ErrorAction,
  ) => IReportListState;

  [REPORT.LOADING_REPORT]: (
    state: IReportListState,
    value?: LoadingAction,
  ) => IReportListState;
  DEFAULT: (state: IReportListState) => IReportListState;
}

const initState: IReportListState = {
  report: null,
  cachedReportList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [REPORT.SET_REPORT]: (state, { report }) => ({
    ...state,
    ...{
      report,
      cachedReportList: report
        ? state.cachedReportList.set(report?.uuid, report)
        : state.cachedReportList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [REPORT.RESET_REPORT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [REPORT.ERROR_REPORT]: (state: IReportListState, { error }: ErrorAction) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [REPORT.LOADING_REPORT]: (state: IReportListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IReportListState) => state,
};

export default function Report(
  state: any = initState,
  action: any,
): IReportListState {
  const handler = handlers[action.type as REPORT] || handlers.DEFAULT;
  return handler(state, action);
}
