import React from 'react';
import { InvoiceModel } from '@structure';
import { StyledTitle } from '@components/lib/Styled';
import styled, { useTheme } from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';

export interface InvoicePriceViewProps {
  invoice: InvoiceModel | null;
  isWarning?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{
  $error?: boolean;
}>`
  color: ${({ theme, $error }) =>
    $error ? theme.colors.error : theme.colors.success};
`;

const LineThroughTitle = styled(StyledTitle)`
  text-decoration: line-through;
`;

export function InvoicePriceView({
  invoice,
  isWarning,
}: InvoicePriceViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { defaultCompany } = useStoredCompanies();

  return (
    <>
      {invoice ? (
        <StyledContainer>{invoice?.inv_sum_total}</StyledContainer>
      ) : null}
    </>
  );
}
