import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AuditOutlined } from '@ant-design/icons';
import { Col, Form, Input, InputNumber, Row } from 'antd';

import {
  DefaultForm,
  IDefaultFormProps,
  PhoneInputComponent as PhoneInput,
} from '@components/lib/General';
import {
  CLIENT_DISCOUNT_TYPE,
  CLIENT_LEGAL_INITIAL_PARAM,
} from '@services/api/client';
import {
  ClientDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientOrgType,
  ClientDiscountType,
} from '@structure';
import { useDropdownAlert } from '@contex';
import { useSize, useStoredCompanies } from '@hooks';

import {
  Collapse,
  Segmented,
  FormSwitch,
  SearchSelect,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { ClientOrgTypeView } from '../Show';
import styled from 'styled-components';
import { CiDeliveryTruck } from 'react-icons/ci';
import { FaHandshakeSlash } from 'react-icons/fa';

export interface IClientLegalFormProps
  extends Omit<
    IDefaultFormProps<ClientLegalFormDTO, ClientLegalFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  client?: ClientLegalFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
  provider?: boolean;
}

export const CLIENT_ORG_TYPE = [
  {
    uuid: ClientOrgType.PERSON,
    title: (
      <ClientOrgTypeView
        client={ClientMapper.toClientModel({
          client_org_type: ClientOrgType.PERSON,
        } as ClientDTO)}
        title="Phys. person"
      />
    ),
  },
  {
    uuid: ClientOrgType.ORGANIZATION,
    title: (
      <ClientOrgTypeView
        client={ClientMapper.toClientModel({
          client_org_type: ClientOrgType.ORGANIZATION,
        } as ClientDTO)}
        title="Legal entity"
      />
    ),
  },
];

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 15px 20px;

  width: 100%;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid ${({ theme }) => theme.colors.disabled};
`;

export const Title = styled(StyledTitle)`
  position: absolute;
  top: -14px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: ${({ theme }) => theme.background.primary};

  font-size: 16px;
`;

export const StyledIconContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export function ClientLegalForm({
  loading,
  editMode,
  client = CLIENT_LEGAL_INITIAL_PARAM,
  isFullForm = true,
  autoFocus,
  onCancel,
  instance,
  provider,
  ...rest
}: IClientLegalFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const inputRef = useRef<any>(null);
  const { defaultCompany } = useStoredCompanies();
  const [mount, setMount] = useState(false);

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  //const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const discountType = Form.useWatch('client_discount_type', instance);
  const discountGoodsType = Form.useWatch(
    'client_discount_goods_type',
    instance,
  );

  const client_is_provisioner = Form.useWatch(
    'client_is_provisioner',
    instance,
  );

  const additionalValues = useMemo(
    () => ({
      uuid: client?.uuid,
    }),
    [client?.uuid],
  );

  const clientDiscountTypes = useMemo(
    () => CLIENT_DISCOUNT_TYPE(defaultCompany?.currency_symbol!),
    [defaultCompany?.currency_symbol],
  );

  useEffect(() => {
    if (autoFocus) {
      setMount(true);
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoFocus && mount && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [autoFocus, mount]);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client'),
        `${
          editMode
            ? t('An error occurred during edit client')
            : t('An error occurred during create client')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  useEffect(() => {
    if (provider) {
      instance.setFieldValue('client_is_provisioner', true);
    }
  }, [instance, provider]);

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...client,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, getFieldValue, setFieldsValue, validateFields }) => (
        <Row gutter={24} ref={contentRef}>
          <Col span={24}>
            <Form.Item
              tooltip={t(
                'The different types for counterparties have different properties and functions.',
              )}
              label={t('Counterparty type')}
              name="client_org_type"
              rules={[
                {
                  required: true,
                  message: t('Counterparty type must be specified.'),
                },
              ]}>
              <Segmented
                options={CLIENT_ORG_TYPE}
                disabled={loading || loadingSubmit || editMode}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Name of the legal entity')}
              name="client_org_name"
              rules={[
                {
                  required: true,
                  message: t('Name of the legal entity must be specified.'),
                },
              ]}>
              <Input
                size="large"
                data-testid="client-create-input-last-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the client legal name')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Contact person phone')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Client Phone must be specified.'),
                },
              ]}>
              <PhoneInput
                size="large"
                placeholder={t('Enter the client phone')}
                id="field-phone"
                containerClass="field-phone-container"
                searchClass="field-phone-search"
                inputClass="ant-input field-phone"
                buttonClass="field-phone-flag"
                loading={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Collapse
              title={(isExpanded) =>
                isExpanded ? t('Hide') : t('Additional parameters')
              }
              height={1300}>
              <Row>
                <Col span={24}>
                  <Form.Item label={t('Email')} name="email">
                    <Input
                      size="large"
                      data-testid="client-create-input-email"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the client email')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('Contact person')}
                    name="client_org_contact_person">
                    <Input
                      size={'large'}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter a contact person')}
                    />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    tooltip={t(
                      'The type of discount that applies to provided services.',
                    )}
                    label={t('Discount on service')}
                    name="client_discount"
                    rules={
                      discountType === ClientDiscountType.Fixed
                        ? [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              required: false,
                              message: t('Discount must be specified.'),
                            },
                          ]
                        : [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              min: 0,
                              max: 100,
                              required: false,
                              message: t(
                                'Discount must be a number and bigger than -1 and less 100',
                              ),
                            },
                          ]
                    }>
                    <Input
                      addonAfter={
                        <Form.Item noStyle name="client_discount_type">
                          <StyledSearchSelect
                            popupMatchSelectWidth={false}
                            name="client_discount_type"
                            placeholder={''}
                            getOptionValueTitle="description"
                            disable={loadingSubmit}
                            data={clientDiscountTypes}
                            onChange={async () => {
                              try {
                                await validateFields(['client_discount']);
                              } catch (error) {}
                            }}
                          />
                        </Form.Item>
                      }
                      size="large"
                      type="number"
                      disabled={loadingSubmit}
                      placeholder={'0'}
                    />
                  </Form.Item>
                </Col>

                <Col span={11} offset={2}>
                  <Form.Item
                    tooltip={t(
                      'The types of discount that applies to the goods.',
                    )}
                    label={t('Goods discount')}
                    name="client_discount_goods"
                    rules={
                      discountGoodsType === ClientDiscountType.Fixed
                        ? [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              required: false,
                              message: t(
                                'Discount on goods must be specified.',
                              ),
                            },
                          ]
                        : [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              min: 0,
                              max: 100,
                              required: false,
                              message: t(
                                'Discount must be a number and bigger than -1 and less 100',
                              ),
                            },
                          ]
                    }>
                    <Input
                      addonAfter={
                        <Form.Item noStyle name="client_discount_goods_type">
                          <StyledSearchSelect
                            popupMatchSelectWidth={false}
                            name="client_discount_goods_type"
                            placeholder={''}
                            getOptionValueTitle="description"
                            disable={loadingSubmit}
                            data={clientDiscountTypes}
                            onChange={async () => {
                              try {
                                await validateFields(['client_discount_goods']);
                              } catch (error) {}
                            }}
                          />
                        </Form.Item>
                      }
                      size="large"
                      type="number"
                      disabled={loadingSubmit}
                      placeholder={'0'}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} style={{ marginBottom: 10 }}>
                  <StyledTitle
                    style={{
                      fontWeight: 'bold',
                      textDecorationThickness: 0.5,
                    }}
                    fontSize={15}>
                    {t('Details of the client company')}
                  </StyledTitle>
                </Col>

                <Col span={11}>
                  <Form.Item
                    tooltip={t('EDRPOU_tooltip')}
                    label={t('EDRPOU')}
                    name="client_org_code">
                    <FullWidthInputNumber
                      size={'large'}
                      type="number"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter an EDRPOU')}
                      onChange={(client_org_code) => {
                        setFieldsValue({
                          client_org_code: `${client_org_code}`,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={11} offset={2}>
                  <Form.Item
                    tooltip={t('TIN_tooltip')}
                    label={t('TIN')}
                    name="client_org_tax_code">
                    <FullWidthInputNumber
                      size={'large'}
                      type="number"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter a TIN')}
                      onChange={(client_org_tax_code) => {
                        setFieldsValue({
                          client_org_tax_code: `${client_org_tax_code}`,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('Legal address')}
                    name="client_org_address">
                    <Input
                      size={'large'}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter a legal address')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Director')} name="client_org_manager">
                    <Input
                      size={'large'}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter a director')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} style={{ marginBottom: 10 }}>
                  <StyledTitle
                    style={{
                      fontWeight: 'bold',
                      textDecorationThickness: 0.5,
                    }}
                    fontSize={15}>
                    {t('Bank details')}
                  </StyledTitle>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('Name of the bank')}
                    name="client_org_bank_name">
                    <Input
                      size={'large'}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the name of the bank')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('MFI')} name="client_org_bank_code">
                    <FullWidthInputNumber
                      size={'large'}
                      type="number"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter a MFI')}
                      onChange={(client_org_bank_code) => {
                        setFieldsValue({
                          client_org_bank_code: `${client_org_bank_code}`,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    tooltip={t('Bank account in IBAN format')}
                    label={t('Bank account')}
                    name="client_org_bank_account_id">
                    <Input
                      size={'large'}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the bank account')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Note')} name="client_comment">
                    <Input.TextArea
                      allowClear
                      size="large"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter your note')}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <FormSwitch
                    loading={loadingSubmit || loading}
                    disabled={loadingSubmit || provider}
                    name="client_is_provisioner"
                    label={
                      <StyledIconContainer>
                        <CiDeliveryTruck size={18} />
                        <StyledTitle>{t('Provider')}</StyledTitle>
                      </StyledIconContainer>
                    }
                    tooltipTitle={t('Provider')}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    value={provider ? client_is_provisioner : undefined}
                  />
                </Col>
                <Col span={12}>
                  <FormSwitch
                    loading={loadingSubmit || loading}
                    disabled={loadingSubmit}
                    name="client_is_tav_payer"
                    label={
                      <StyledIconContainer>
                        <AuditOutlined size={18} />
                        <StyledTitle>
                          {t('A payer of additional value tax')}
                        </StyledTitle>
                      </StyledIconContainer>
                    }
                    tooltipTitle={t(
                      'When this client applies to a store document additional value will be added to the item price',
                    )}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                  />
                </Col>

                <Col span={24}>
                  <FormSwitch
                    loading={loadingSubmit || loading}
                    disabled={loadingSubmit}
                    name="client_is_conflict"
                    label={
                      <StyledIconContainer>
                        <FaHandshakeSlash size={18} />
                        <StyledTitle>{t('A conflicted client')}</StyledTitle>
                      </StyledIconContainer>
                    }
                    tooltipTitle={t('A conflicted client')}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                  />
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
