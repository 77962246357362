import { PRICE_TAG_LIST } from '../constants';
import { PriceTagListModel, PriceTagModel } from '@structure';
import { ApiError } from '@services/types';
import { Dispatch } from 'redux';

export interface ISetPriceTagListParam {
  type: PRICE_TAG_LIST;
  priceTagList: PriceTagListModel;
}

export interface IAddPriceTagToListParam {
  type: PRICE_TAG_LIST;
  priceTag: PriceTagModel;
}

export interface IUpdatePriceTagFromListParam {
  type: PRICE_TAG_LIST;
  priceTag: PriceTagModel;
}

export interface IDeletePriceTagFromListParam {
  type: PRICE_TAG_LIST;
  priceTagUuid: string;
}

export interface IErrorPriceTagListParam {
  type: PRICE_TAG_LIST;
  error: ApiError | undefined;
}

export function setPriceTagList(
  priceTagList: PriceTagListModel,
): (dispatch: Dispatch) => Promise<ISetPriceTagListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.SET_PRICE_TAG_LIST,
      priceTagList,
    });
  };
}

export function loadMorePriceTagList(
  priceTagList: PriceTagListModel,
): (dispatch: Dispatch) => Promise<ISetPriceTagListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.LOAD_MORE_PRICE_TAG_LIST,
      priceTagList,
    });
  };
}

export function addPriceTagToList(
  priceTag: PriceTagModel,
): (dispatch: Dispatch) => Promise<IAddPriceTagToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.ADD_PRICE_TAG,
      priceTag,
    });
  };
}

export function updatePriceTagFromList(
  priceTag: PriceTagModel,
): (dispatch: Dispatch) => Promise<IUpdatePriceTagFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.UPDATE_PRICE_TAG,
      priceTag,
    });
  };
}

export function deletePriceTagFromList(
  priceTagUuid: string,
): (dispatch: Dispatch) => Promise<IDeletePriceTagFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.DELETE_PRICE_TAG,
      priceTagUuid,
    });
  };
}

export function errorPriceTagInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorPriceTagListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_TAG_LIST.ERROR_PRICE_TAG_LIST,
      error,
    });
  };
}
