import { List } from 'immutable';
import {
  CompanyDTO,
  CompanyFormDTO,
  ICompanyDTOProps,
  CompanyListModel,
  CompanyModel,
  ICompanySettingsDTOProps,
  CompanySettingsDTO,
  CompanyInviteFormDTO,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface ICompanyListDTO {
  companies: CompanyDTO[];
  total: number;
}

export class CompanyMapper {
  public static toCompanyDTO(
    company: CompanyModel | ICompanyDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): CompanyDTO {
    return new CompanyDTO(company, [
      WithoutRecursion.company,
      ...withoutRecursion,
    ]);
  }

  public static toCompanyListDTO(
    companies: List<CompanyModel> | ICompanyDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ICompanyListDTO {
    const companyList = List.isList(companies)
      ? listToArray<CompanyModel>(companies)
      : companies;

    return {
      companies: companyList?.map((company) =>
        CompanyMapper.toCompanyDTO(company, [
          WithoutRecursion.company,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toCompanyModel(
    companyDTO: CompanyDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): CompanyModel {
    return new CompanyModel(companyDTO, [
      WithoutRecursion.company,
      ...withoutRecursion,
    ]);
  }

  public static toCompanyListModel(
    companyDTOs: CompanyDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): CompanyListModel {
    return new CompanyListModel({ companies: companyDTOs, total }, [
      WithoutRecursion.company,
      ...withoutRecursion,
    ]);
  }

  public static toCompanyFormDTO(companyModel: CompanyModel): CompanyFormDTO {
    return new CompanyFormDTO(companyModel);
  }

  public static toCompanySettingsDTO(
    settings: ICompanySettingsDTOProps,
  ): CompanySettingsDTO {
    return new CompanySettingsDTO(settings);
  }

  public static toCompanyInviteFormDTO(
    company: CompanyModel,
  ): CompanyInviteFormDTO {
    return new CompanyInviteFormDTO(company);
  }
}
