import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Input } from 'antd';
import { EMPLOYEE_USER_DATA_INITIAL_DATA } from '@services/api/employee';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  StyledRedoOutlined,
  StyledUserLoginOutlined,
} from '@components/lib/Styled';
import { EmployeeUserDataFormDTO } from '@structure';
import { makePassword } from '@services/helpers';
import { useDropdownAlert } from '@contex';

export interface IEmployeeUserDataFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: EmployeeUserDataFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  employee?: EmployeeUserDataFormDTO;
}

/**
 * @desc Створенння співробітника
 * */
export default function EmployeeUserDataForm({
  loading,
  editMode,
  employee = EMPLOYEE_USER_DATA_INITIAL_DATA,
  ...rest
}: IEmployeeUserDataFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [isEditablePassword, setIsEditablePassword] = useState(false);

  const additionalValues = useMemo(
    () => ({
      uuid: employee?.uuid,
    }),
    [employee?.uuid],
  );

  const handleGeneratePassword = useCallback((setFieldValue: any) => {
    const password = makePassword(8);

    setFieldValue({ password });
    setIsEditablePassword(true);
  }, []);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Employee change password'),
        `${t('An error occurred during edit employee password')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm
      initialValues={{
        ...employee,
      }}
      notifyError={notifyError}
      showNotify={false}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      disabledSubmitButton={!isEditablePassword}
      submitButtonText={t('Set a password')}
      {...rest}>
      {({ loadingSubmit, setFieldsValue }) => (
        <>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Identifier')}
                name="login"
                tooltip={t('Identifier')}
                rules={[
                  {
                    required: true,
                    message: t('Identifier must be specified.'),
                    type: 'string',
                  },
                ]}>
                <Input
                  disabled={loadingSubmit || !!employee?.login}
                  placeholder={t('Enter the employee identifier')}
                  prefix={<StyledUserLoginOutlined />}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('Password')}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('Password must be specified.'),
                  },
                ]}>
                <Input
                  data-testid="login-form__input--password"
                  disabled={loadingSubmit || !isEditablePassword}
                  suffix={
                    <StyledRedoOutlined
                      onClick={() => handleGeneratePassword(setFieldsValue)}
                    />
                  }
                  type={isEditablePassword ? 'text' : 'password'}
                  placeholder={t('Enter your password')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
