import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len, eq } from '@services/helpers';
import { DepartmentModel } from '@structure';
import { useDelete } from '@components/lib/libV2/hooks';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IDepartmentDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  departments: DepartmentModel[];
  title?: string;
}

export function DepartmentDeleteButton({
  children,
  departments = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IDepartmentDeleteButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: departments,
    notifyTitle: 'Department',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete departments / locations')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(departments)}</b>{' '}
          {eq(len(departments), 1) ? t('department') : t('departments')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
