import * as React from 'react';
import {
  ILocalizationDTOProps,
  LocalizationMapper,
  LocalizationDTO,
} from '@structure';
import { head, compose, toLowerCase } from '@services/helpers';
import ct from 'countries-and-timezones';
import { useState } from 'react';

export function useLocalization(): LocalizationDTO {
  const [locale, setLocale] = useState(
    LocalizationMapper.toLocalizationDTO({} as ILocalizationDTOProps),
  );

  React.useEffect(() => {
    const locale = LocalizationMapper.toLocalizationDTO(
      {} as ILocalizationDTOProps,
    );
    if (Intl) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
      if (timezone) {
        locale.timezone = timezone;

        locale.region_code = compose<string>(
          toLowerCase,
          head,
        )(ct.getTimezone(timezone)?.countries);
      }

      if (navigator) {
        locale.language_code = navigator.language;
      }
    }

    setLocale(locale);
  }, []);

  return locale;
}
