import React, { useCallback, useRef, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { DatePicker } from '@components/lib/General';
import {
  DetailsView,
  IDetailsViewItemProps,
  SearchSelect,
  Segmented,
} from '@components/lib/DataDisplay';
import { isListToArray } from '@services/helpers';
import { FROM_PAYMENT_METHOD_WITH_IN_DEBT } from '../../Payments/Forms/PaymentScheduleFields';
import { convertedPaymentMethodToCashBox } from '@services/api/companyPayments';
import { CashBoxModel, PAYMENT_METHOD } from '@structure';
import {
  IUseStateCashBoxListReturnType,
  IUseStateCompanyClientListReturnType,
  IUseStateEmployeeListReturnType,
  useStoredCompanies,
} from '@hooks';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import { List } from 'immutable';
import { ClientDetailsView } from '../../Clients';
import styled from 'styled-components';
import { CashBoxTitleView } from '../../Payments/Show/CashBoxTitleView';

export interface ICloseOrderFormFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    >,
    Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients'
    > {
  loading: boolean;
  employeesLoading: boolean;
  cashBoxListLoading: boolean;
  clientsLoading: boolean;
}

const StyledClientCol = styled(Col)`
  margin-bottom: 20px;
`;

export function CloseOrderFormFields({
  loading,

  employees,
  employeesLoading,
  handleSearchEmployees,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,

  clients,
}: ICloseOrderFormFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { loadingSubmit, formData, handlerUpdateFormState }: any =
    useDefaultForm();
  const { defaultCompany } = useStoredCompanies();

  const [resetEmployeeState, setResetEmployeeState] = useState(false);
  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const once = useRef(false);

  const handleChangePaymentMethod = useCallback(
    (schedule_payment_method: any) => {
      handlerUpdateFormState({ schedule_payment_method });

      if (typeof formData?.schedule_payment_method === 'string') {
        handlerUpdateFormState({
          schedule_payment_cashbox_uuid: '',
        });

        if (once.current) {
          handlerUpdateFormState({
            schedule_payment_cashier_uuid: '',
          });
          setResetEmployeeState(true);
          setResetCashBoxState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
            setResetCashBoxState(false);
          }, 100);
        }
      }
    },
    [formData?.schedule_payment_method, handlerUpdateFormState],
  );

  const handleChangeCashBox = useCallback(
    (schedule_payment_cashbox_uuid: string) => {
      handlerUpdateFormState({ schedule_payment_cashbox_uuid });

      if (List.isList(cashBoxList)) {
        const cashBox = cashBoxList?.find(
          ({ uuid }) => uuid === schedule_payment_cashbox_uuid,
        );

        if (cashBox) {
          once.current = true;
          handlerUpdateFormState({
            schedule_payment_cashier_uuid: cashBox?.cashier?.uuid || '',
          });
          setResetEmployeeState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
          }, 100);
        }
      }
    },
    [cashBoxList, handlerUpdateFormState],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Client',
      className: 'client-details__view',
    },
  ];

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Date and time')}
          name="schedule_payment_date"
          rules={[
            {
              required: true,
              message: t('Date and time must be specified'),
            },
          ]}
          tooltip={t('Date and time')}>
          <DatePicker
            showTime
            format={'DD.MM.YYYY HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(payment_date) =>
              handlerUpdateFormState({ payment_date })
            }
          />
        </Form.Item>
      </Col>

      {formData?.client?.uuid ? (
        <StyledClientCol span={24}>
          <DetailsView items={detailViewItems}>
            <ClientDetailsView client={formData?.client} />
          </DetailsView>
        </StyledClientCol>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Total due')} name="schedule_payment_sum" required>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled
            onChange={(e) =>
              handlerUpdateFormState({ payment_price: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      {Number(formData?.schedule_payment_sum) > 0 ? (
        <>
          <Col span={24}>
            <Form.Item
              label={t('Form of payment')}
              name="schedule_payment_method"
              rules={[
                {
                  required: true,
                  message: t('Form of payment must be specified'),
                },
              ]}
              tooltip={t('Form of payment')}>
              <Segmented
                options={FROM_PAYMENT_METHOD_WITH_IN_DEBT}
                disabled={loadingSubmit}
                onChange={handleChangePaymentMethod}
              />
            </Form.Item>
          </Col>

          {formData?.schedule_payment_method ===
          PAYMENT_METHOD.Account ? null : (
            <Col span={24}>
              <Form.Item
                label={t('Cash boxes')}
                name="schedule_payment_cashbox_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Cash boxes must be specified'),
                  },
                ]}
                tooltip={t('Cash boxes')}>
                <SearchSelect
                  resetState={resetCashBoxState}
                  selectFirst={false}
                  onSearch={(keywords) =>
                    handleSearchCashBoxes({
                      keywords,
                      limit: 100,
                      showLoading: false,
                    })
                  }
                  getOptionValueTitle={(cashBox: CashBoxModel) => (
                    <CashBoxTitleView
                      cashBox={cashBox}
                      disable={loadingSubmit || cashBoxListLoading}
                    />
                  )}
                  name="schedule_payment_cashbox_uuid"
                  placeholder={t('Select a cash box')}
                  disable={loadingSubmit || cashBoxListLoading}
                  data={isListToArray(
                    cashBoxList?.filter(
                      ({ box_type, box_can_out_money_from }) => {
                        return (
                          box_type ===
                            convertedPaymentMethodToCashBox[
                              formData?.schedule_payment_method as PAYMENT_METHOD
                            ] && box_can_out_money_from
                        );
                      },
                    ) as any,
                  )}
                  onChange={handleChangeCashBox}
                />
              </Form.Item>
            </Col>
          )}
        </>
      ) : null}

      <Col span={24}>
        <Form.Item
          label={t('Cashier')}
          name="schedule_payment_cashier_uuid"
          rules={[
            {
              required: true,
              message: t('Cashier must be specified'),
            },
          ]}
          tooltip={t('Cashier')}>
          <SearchSelect
            resetState={resetEmployeeState}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle="fullName"
            name="schedule_payment_cashier_uuid"
            placeholder={t('Select a cashier')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(schedule_payment_cashier_uuid) =>
              handlerUpdateFormState({ schedule_payment_cashier_uuid })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="schedule_comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ payment_comment: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
