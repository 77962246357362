import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Form, ColorPicker } from 'antd';
import { useDefaultForm } from '@contex';
import {
  checkWorkSchedule,
  curry,
  findItemIndex,
  head,
  ifElse,
  joinArr,
  modifyWeekdays,
  split,
} from '@services/helpers';
import { IOnChangeDaysOfWeekSchedule, WorkScheduleLabel } from '../../Company';
import { WeekdayTimePickerForm } from '@components/lib/General';
import { FormSwitch } from '@components/lib/DataDisplay';
import { isFunction } from '@sportix/sportix-common-modules';
import { DEPARTMENT_ICONS } from '../helpers';
import { DepartmentIcon } from '@structure';

export interface IDepartmentFormFieldsProps {
  loading: boolean;
  handleUpdateIcon?: (icon: any) => void;
  handleUpdateColorIcon?: (color: string) => void;
}

export function DepartmentFormFields({
  loading,
  handleUpdateIcon,
  handleUpdateColorIcon,
}: IDepartmentFormFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const {
    loadingSubmit,
    handlerUpdateFormState,
    formData,
    getFieldValue,
    setFieldsValue,
  } = useDefaultForm();

  const [isFixedRange, setIsFixedRange] = useState(
    checkWorkSchedule(formData?.dep_schedule ? formData?.dep_schedule : ''),
  );

  const fixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(formData?.dep_schedule || ''),
      split(',', formData?.dep_schedule || ''),
      [],
    ),
  );

  const weekRange = useRef(
    ifElse(
      checkWorkSchedule(formData?.dep_schedule || ''),
      [],
      split(',', formData?.dep_schedule || ''),
    ),
  );

  const handleChangeDateRangeView = useCallback((): void => {
    setIsFixedRange((prevState) => {
      return !prevState;
    });
  }, []);

  const updateWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      fixedRange.current = head(updatedWeekRange) ? updatedWeekRange : [];
      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        dep_schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [fixedRange],
  );

  const updateCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      weekRange.current = updatedWeekRange;

      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        dep_schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [weekRange],
  );

  const onChangeDaysOfWeek = curry<any, any>(
    ({ handlerUpdateFormState }, value: IOnChangeDaysOfWeekSchedule) => {
      if (isFixedRange) {
        updateWeekRange({ time: value?.time, handlerUpdateFormState });
      }

      if (!isFixedRange) {
        updateCustomRange({ ...value, handlerUpdateFormState });
      }
    },
  );

  const onChangeDepIsDefault = useCallback(
    (dep_is_default: boolean) => {
      handlerUpdateFormState({ dep_is_default });

      if (isFunction(handleUpdateIcon)) {
        if (dep_is_default) {
          handleUpdateIcon(DEPARTMENT_ICONS[DepartmentIcon.DEFAULT]);
          handlerUpdateFormState({ dep_icon: DepartmentIcon.DEFAULT });
        } else {
          handleUpdateIcon(DEPARTMENT_ICONS[DepartmentIcon.REGULAR]);
          handlerUpdateFormState({ dep_icon: DepartmentIcon.REGULAR });
        }
      }
    },
    [handleUpdateIcon, handlerUpdateFormState],
  );

  useEffect(() => {
    if (isFixedRange) {
      handlerUpdateFormState({
        weekdays: fixedRange.current,
        dep_schedule: fixedRange.current ? fixedRange.current.join(',') : '',
      });
    } else {
      handlerUpdateFormState({
        weekdays: weekRange.current,
        dep_schedule: weekRange.current ? weekRange.current.join(',') : '',
      });
    }
  }, [handlerUpdateFormState, isFixedRange]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          label={t('Title')}
          name="dep_name"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}>
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the title')}
            onChange={(e) =>
              handlerUpdateFormState({ dep_name: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Address')}
          name="dep_address"
          rules={[
            {
              required: true,
              message: t('Address must be specified.'),
            },
          ]}>
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the address')}
            onChange={(e) =>
              handlerUpdateFormState({ dep_address: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="dep_description">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ dep_description: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Work schedule')}
          className="company-form__work-schedule"
          label={
            <WorkScheduleLabel
              label={t('Work Schedule')}
              disabled={loadingSubmit}
              isFixedRange={isFixedRange}
              handleChangeView={handleChangeDateRangeView}
            />
          }
          rules={[
            {
              validator: () => {
                if (!formData?.dep_schedule) {
                  return Promise.reject(
                    new Error(t('Work schedule must be specified.')),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          name="weekdays">
          <WeekdayTimePickerForm
            fixedTitle={t('Mon-Sun')}
            checkboxDisable={false}
            isRangePicker
            isFixedRange={isFixedRange}
            editMode={true}
            disabled={loadingSubmit}
            onChange={onChangeDaysOfWeek({
              handlerUpdateFormState,
            })}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Location color')} name="dep_colour">
          <ColorPicker
            showText
            size="large"
            onChangeComplete={(color) => {
              handlerUpdateFormState({ dep_colour: `#${color.toHex()}` });
              if (isFunction(handleUpdateColorIcon)) {
                handleUpdateColorIcon(`#${color.toHex()}`);
              }
            }}
            disabled={loading || loadingSubmit}
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit}
        name="dep_is_default"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Default location')}
        onChange={onChangeDepIsDefault}
      />
    </Row>
  );
}
