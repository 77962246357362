import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { PaymentModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IDeletePaymentButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  payments: PaymentModel[];
}

export default function DeletePaymentButton({
  children,
  payments,
  onSuccess,
  onError,
  title,
  ...rest
}: IDeletePaymentButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: payments,
    notifyTitle: 'Payment',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company payment')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(payments)} ${
            len(payments)! > 1 ? t('payments') : t('payment')
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
