import React, { useEffect } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router';
import { compose, split, last } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { usePreLoadCookie, useStoreAppState } from '@hooks';
import ScreenLoader from '@components/lib/Layout/ScreenLoader';
import { Routes as RoutesType } from '@services/types';

export interface IInitRoutesProps {
  children: React.ReactNode | React.ReactNode[];
}

export function InitRoutes({ children }: IInitRoutesProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const isNavigateToApp = usePreLoadCookie();

  useEffect(() => {
    if (location?.pathname?.includes(RoutesType.auth)) {
      if (isNavigateToApp) {
        navigate(`/${RoutesType.app}`);
      }
    }
  }, [isNavigateToApp, location?.pathname, navigate]);

  useEffect(() => {
    if (location?.search?.includes('lang=')) {
      const lang = compose<string>(last, split('='))(location?.search);

      (i18n as any).changeLanguage(lang);
    }
  }, [i18n, location]);

  if (location?.pathname?.includes(RoutesType.auth) && isNavigateToApp) {
    return <ScreenLoader full size={50} />;
  }

  return <Routes>{children}</Routes>;
}
