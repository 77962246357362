import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { StoreDocumentFormDTO, StoreDocumentModel } from '@structure';
import { useDropdownAlert } from '@contex';
import {
  IUseStateStoredDocumentReturnType,
  useStateCompanyClientList,
  useStoredCompanies,
  useStoredStoreList,
} from '@hooks';
import { Form } from 'antd';
import { StorePostingFields } from './StorePostingFields';
import { useTheme } from 'styled-components';
import { encryptObject, isEqualByUuid, isFunction } from '@services/helpers';
import { ENTER_KEYS, SessionStorageItems } from '@services/const';
import { useWatch } from 'antd/es/form/Form';
import { produce } from 'immer';
import { StoreDocumentRefObject } from '../../helpers';

export interface IStorePostingFormProps
  extends Omit<
      IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
    >,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  onSuccess: (
    value: StoreDocumentFormDTO,
    closeble?: boolean,
  ) => Promise<StoreDocumentModel | void>;
  fromList: boolean;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StorePostingForm({
  loading,
  document,
  editMode,
  onSuccess,
  handlePaymentStoreDocument,
  onCancel,
  fromList,
  documentEditRef,
  ...rest
}: IStorePostingFormProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const doc_extra_services = useWatch('doc_extra_services', instance);
  const doc_multi_currencies = useWatch('doc_multi_currencies', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: document?.uuid,
    }),
    [document?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateLocalClient,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
    provisioner_only: true,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({ companyUuid: defaultCompanyUuid, limit: 50 });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store posting')
            : t('An error occurred during create store posting')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const saveStoreToSessionStorage = useCallback(
    (doc_store_uuid: string) => {
      const selectedStore = storeList?.find(isEqualByUuid(doc_store_uuid));

      if (selectedStore) {
        const store = {
          uuid: selectedStore?.uuid,
          store_name: selectedStore?.store_name,
        };

        const encryptStoreData = encryptObject(store);

        sessionStorage.setItem(
          SessionStorageItems.postingFormStoreData,
          encryptStoreData,
        );
      }
    },
    [storeList],
  );

  const handleOnSuccess = useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.doc_extra_services = doc_extra_services;
          draft.doc_multi_currencies = doc_multi_currencies;
          draft.doc_related_document_uuid = '';
        });

        await saveStoreToSessionStorage(value?.doc_store_uuid as string);

        await onSuccess(updatedValue);
      }
    },
    [
      doc_extra_services,
      doc_multi_currencies,
      onSuccess,
      saveStoreToSessionStorage,
    ],
  );

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={[...ENTER_KEYS]}
      withContext
      isResetLoading
      onCancel={onCancel}
      instance={instance}
      initialValues={document}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      showCancelButton={false}
      submitButtonText={t('Save as draft')}
      submitButtonProps={{ style: { backgroundColor: theme.colors.primary } }}
      {...rest}>
      <StorePostingFields
        editMode={!!editMode}
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleCreateLocalClient={handleCreateLocalClient}
        handleSearchClients={handleSearchClients}
        storeList={storeList}
        storeListLoading={storeListLoading}
        handleSearchStores={handleSearchStores}
        doc_extra_services={doc_extra_services}
        doc_multi_currencies={doc_multi_currencies}
        documentEditRef={documentEditRef}
      />
    </DefaultForm>
  );
}
