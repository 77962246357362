import React from 'react';
import styled, { css } from 'styled-components';
import { WithTranslation } from 'react-i18next';
import { useAuthLayout } from '@components/lib/Layout/AuthLayout';
import AuthRouteItem, { IAuthRouteItemProps } from './AuthRouteItem';

export interface IAuthRouteLayoutProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
  header?: React.ReactNode | React.ReactNode[];
  extraContent?: React.ReactNode | React.ReactNode[];
  outsideFooterContent?: React.ReactNode;
}

const StyledContainer = styled.div<{
  $withPaddingBottom: boolean;
}>`
  width: 100%;
  //height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ $withPaddingBottom }) =>
    $withPaddingBottom &&
    css`
      padding-bottom: 150px;
    `};
`;

const StyledOutsideContainer = styled.div<{ $width: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

export function AuthRouteLayout({
  routes,
  header,
  extraContent,
  outsideFooterContent,
}: IAuthRouteLayoutProps): JSX.Element {
  const { contentWidth } = useAuthLayout();

  return (
    <StyledContainer $withPaddingBottom={!!outsideFooterContent}>
      {header || null}
      {extraContent || null}
      {routes.map((item) => (
        <AuthRouteItem {...item} key={item.key} />
      ))}
      {outsideFooterContent ? (
        <StyledOutsideContainer $width={contentWidth}>
          {React.cloneElement(outsideFooterContent as any, {})}
        </StyledOutsideContainer>
      ) : null}
    </StyledContainer>
  );
}
