import React, {
  JSX,
  RefObject,
  Suspense,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  InvoiceItemFormDTO,
  InvoiceItemPositionableType,
  InvoiceMapper,
  InvoiceModel,
  InvoiceStatus,
  ProductModel,
  ScheduleCalendarModel,
  ServiceModel,
  StoreDocumentModel,
  ProductStatus,
} from '@structure';
import styled from 'styled-components';
import { useInvoiceManager } from '../Managers';
import { useModal, useStateReducer } from '@components/lib/libV2/hooks';
import { ENTER_KEYS } from '@services/const';
import { List } from 'immutable';
import { InvoiceServiceListSelect } from './InvoiceServiceListSelect';
import { InvoiceProductListSelect } from './InvoiceProductListSelect';
import { InvoiceStoreRealizationListSelect } from './InvoiceStoreRealizationListSelect';
import { InvoiceOrderListSelect } from './InvoiceOrderListSelect';
import { InvoiceStatusView } from './InvoiceStatusView';
import { Segmented, SuspenseEmpty } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

const InvoiceItemModalLazy = React.lazy(
  () => import('../Show/InvoiceItemModal'),
);

const InvoiceItemOrderWindowLazy = React.lazy(
  () => import('../Show/InvoiceItemOrderWindow'),
);

const InvoiceItemStoreDocumentWindowLazy = React.lazy(
  () => import('../Show/InvoiceItemStoreDocumentWindow'),
);

export interface InvoiceItemListSelectProps {
  disabled: boolean;
  selectRef: RefObject<any>;
  handleSearchSelectDaleyFocus: (ms?: number) => void;
  children?: React.ReactNode | React.ReactNode[];
  invoice: InvoiceModel | null;
}

export type SelectedItemType =
  | InvoiceItemFormDTO
  | ScheduleCalendarModel
  | StoreDocumentModel;

export interface InvoiceItemListSelectState {
  itemList:
    | List<ServiceModel>
    | List<ProductModel>
    | List<ScheduleCalendarModel>
    | List<StoreDocumentModel>;

  itemListKeywords: string;
  itemListLoading: boolean;
  itemLoadingMore: boolean;
  itemTitle: string;
  selectedItem: SelectedItemType | null;
  selectedItemIndex: number;
}

export const INVOICE_POSITIONABLE_VIEW = [
  {
    uuid: InvoiceItemPositionableType.SERVICE,
    title: (
      <InvoiceStatusView title="Services" status={InvoiceStatus.UNKNOWN} />
    ),
  },
  {
    uuid: InvoiceItemPositionableType.PRODUCT,
    title: (
      <InvoiceStatusView
        title="Goods and materials"
        status={InvoiceStatus.SENT}
      />
    ),
  },
  {
    uuid: InvoiceItemPositionableType.STORE,
    title: (
      <InvoiceStatusView
        title="Sales invoices"
        status={InvoiceStatus.COMMITTED}
      />
    ),
  },
  {
    uuid: InvoiceItemPositionableType.ORDER,
    title: (
      <InvoiceStatusView title="Orders" status={InvoiceStatus.COMMITTED} />
    ),
  },
];

const InvoiceListSelectStrategy = {
  [InvoiceItemPositionableType.SERVICE]: InvoiceServiceListSelect,
  [InvoiceItemPositionableType.PRODUCT]: InvoiceProductListSelect,
  [InvoiceItemPositionableType.STORE]: InvoiceStoreRealizationListSelect,
  [InvoiceItemPositionableType.ORDER]: InvoiceOrderListSelect,
  [InvoiceItemPositionableType.CUSTOM]: null,
};

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  flex: 1;
  margin-bottom: 24px;
`;

const StyledSelectItemContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 300px;
`;

export function InvoiceItemListSelect({
  disabled,
  selectRef,
  handleSearchSelectDaleyFocus,
  invoice,

  children,
}: InvoiceItemListSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { invoiceItemList, handleAddOrUpdateInvoiceItems } =
    useInvoiceManager();

  const [itemPositionableType, setItemPositionableType] =
    useState<InvoiceItemPositionableType>(InvoiceItemPositionableType.SERVICE);

  const isInitItemModals = useRef(false);

  const {
    itemList,
    selectedItem,
    selectedItemIndex,
    itemListKeywords,
    itemListLoading,
    itemTitle,

    handleUpdate: updateItemState,
  } = useStateReducer<InvoiceItemListSelectState>({
    selectedItem: null,
    selectedItemIndex: 0,
    itemList: List() as any,
    itemListKeywords: '',
    itemListLoading: false,
    itemLoadingMore: false,
    itemTitle: '',
  });

  const resetSelectedItemState = useCallback(() => {
    updateItemState({
      selectedItemIndex: 0,
      selectedItem: null,
      itemList: List() as any,
      itemTitle: '',
    });
  }, [updateItemState]);

  const {
    visible: itemVisible,
    handleSuccess: itemHandleSuccess,
    handleCancel: itemHandleCancel,
    handleOnInit: itemHandleOnInit,
  } = useModal({
    onSuccess: handleAddOrUpdateInvoiceItems,
    onCancel: () => {
      resetSelectedItemState();
      isInitItemModals.current = false;
    },
  });

  const {
    visible: itemOrderVisible,
    handleSuccess: itemOrderHandleSuccess,
    handleCancel: itemOrderHandleCancel,
    handleOnInit: itemOrderHandleOnInit,
  } = useModal({
    onSuccess: handleAddOrUpdateInvoiceItems,
    onCancel: () => {
      resetSelectedItemState();
      isInitItemModals.current = false;
    },
  });

  const {
    visible: itemDocumentVisible,
    handleSuccess: itemDocumentHandleSuccess,
    handleCancel: itemDocumentHandleCancel,
    handleOnInit: itemDocumentHandleOnInit,
  } = useModal({
    onSuccess: handleAddOrUpdateInvoiceItems,
    onCancel: () => {
      resetSelectedItemState();
      isInitItemModals.current = false;
    },
  });

  const handleInitItemForm = useCallback(
    (item: SelectedItemType | null = selectedItem) => {
      updateItemState({ selectedItem: item });

      if (item instanceof ScheduleCalendarModel) {
        updateItemState({ itemTitle: item?.schedule_title });
        isInitItemModals.current = true;
        itemOrderHandleOnInit();
        return;
      }

      if (item instanceof StoreDocumentModel) {
        updateItemState({ itemTitle: `№${item?.doc_local_number}` });
        isInitItemModals.current = true;
        itemDocumentHandleOnInit();
        return;
      }

      if (
        item?.item_positionable_type === InvoiceItemPositionableType.PRODUCT &&
        item?.item_positionable_object instanceof ProductModel
      ) {
        if (
          item?.item_positionable_object?.product_status !==
          ProductStatus.ENABLE
        ) {
          alert('error', t('Product'), t('Operations with goods are blocked.'));
          handleSearchSelectDaleyFocus();
          return;
        }
      }

      updateItemState({ itemTitle: item?.item_title });

      isInitItemModals.current = true;
      itemHandleOnInit();
    },
    [
      alert,
      itemDocumentHandleOnInit,
      itemHandleOnInit,
      itemOrderHandleOnInit,
      selectedItem,
      t,
      updateItemState,
      handleSearchSelectDaleyFocus,
    ],
  );

  const handleSelectItem = useCallback(
    (
      item:
        | ServiceModel
        | ProductModel
        | ScheduleCalendarModel
        | StoreDocumentModel,
    ) => {
      if (
        item instanceof ScheduleCalendarModel ||
        item instanceof StoreDocumentModel
      ) {
        return updateItemState({
          selectedItem: item,
        });
      }

      const selectedItem = InvoiceMapper.toInvoiceItemFormDTOFactory(item);

      updateItemState({ selectedItem });
    },
    [updateItemState],
  );

  const scrollToItem = useCallback((index: number) => {
    const selected = document.body.querySelector(`.posting-list-td-${index}`);

    if (selected instanceof HTMLElement) {
      selected.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, []);

  const onTableKeyboardChange = useCallback(
    async (e: any) => {
      const length = itemList.size - 1;

      if (itemListKeywords?.length >= 3 && itemList?.size && !itemListLoading) {
        if (e.key === 'ArrowUp' && selectedItemIndex === 0) {
          const item = itemList.get(length);

          if (item) {
            handleSelectItem(item);
            updateItemState({ selectedItemIndex: length });
            scrollToItem(length);
          }

          return;
        }

        if (e.key === 'ArrowUp' && selectedItemIndex <= length) {
          const item = itemList.get(selectedItemIndex - 1);

          if (item) {
            handleSelectItem(item);
            updateItemState((prevState) => ({
              selectedItemIndex: prevState.selectedItemIndex - 1,
            }));
            scrollToItem(selectedItemIndex - 1);
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedItemIndex === length) {
          const item = itemList.get(0);
          if (item) {
            handleSelectItem(item);
            updateItemState({ selectedItemIndex: 0 });
            scrollToItem(0);
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedItemIndex <= length) {
          const item = itemList.get(selectedItemIndex + 1);
          if (item) {
            handleSelectItem(item);
            updateItemState((prevState) => ({
              selectedItemIndex: prevState.selectedItemIndex + 1,
            }));
            scrollToItem(selectedItemIndex + 1);
          }

          return;
        }

        if (ENTER_KEYS.includes(e.key)) {
          // if (!loadingProductUuid) {
          handleInitItemForm();

          if (selectRef?.current) {
            selectRef?.current?.blur();
          }
          // }
        }
      } else {
        if (
          ENTER_KEYS.includes(e.key) &&
          e?.target instanceof HTMLElement &&
          e?.target?.closest('.store-sell-product-select') &&
          invoiceItemList?.size &&
          // !disabledProductUuid &&
          // !loadingProductUuid &&
          !e?.target?.value
        ) {
          e?.preventDefault();
          e?.stopPropagation();
          selectRef?.current?.blur();
        }
      }
    },
    [
      itemList,
      itemListKeywords?.length,
      itemListLoading,
      selectedItemIndex,
      handleSelectItem,
      updateItemState,
      scrollToItem,
      handleInitItemForm,
      selectRef,
      invoiceItemList?.size,
    ],
  );

  const InvoiceListSelect = InvoiceListSelectStrategy[itemPositionableType];

  return (
    <>
      <StyledSelectContainer>
        <Segmented
          options={INVOICE_POSITIONABLE_VIEW}
          // disabled={loading}
          value={itemPositionableType}
          onChange={(type: any) => {
            setItemPositionableType(type);
            handleSearchSelectDaleyFocus();
          }}
        />
        {InvoiceListSelect ? (
          <StyledSelectItemContainer>
            <InvoiceListSelect
              disabled={false}
              selectRef={selectRef}
              onTableKeyboardChange={onTableKeyboardChange}
              handleInitItemForm={handleInitItemForm}
              resetSelectedItemState={resetSelectedItemState}
              selectedItem={selectedItem as InvoiceItemFormDTO}
              selectedItemIndex={selectedItemIndex}
              itemListKeywords={itemListKeywords}
              updateItemState={updateItemState}
              handleSearchSelectDaleyFocus={handleSearchSelectDaleyFocus}
              isInitItemModals={isInitItemModals}
              residentUuid={invoice?.client?.uuid}
            />
          </StyledSelectItemContainer>
        ) : null}

        {children || null}
      </StyledSelectContainer>

      {itemVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <InvoiceItemModalLazy
            item={selectedItem as InvoiceItemFormDTO}
            visible={itemVisible}
            onSuccess={itemHandleSuccess}
            onCancel={itemHandleCancel}
            invoice={invoice!}
            title={itemTitle}
          />
        </Suspense>
      ) : null}

      {itemOrderVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <InvoiceItemOrderWindowLazy
            schedule={selectedItem as ScheduleCalendarModel}
            visible={itemOrderVisible}
            onSuccess={itemOrderHandleSuccess}
            onCancel={itemOrderHandleCancel}
            invoice={invoice!}
            title={itemTitle}
          />
        </Suspense>
      ) : null}

      {itemDocumentVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <InvoiceItemStoreDocumentWindowLazy
            document={selectedItem as StoreDocumentModel}
            visible={itemDocumentVisible}
            onSuccess={itemDocumentHandleSuccess}
            onCancel={itemDocumentHandleCancel}
            invoice={invoice!}
            title={itemTitle}
          />
        </Suspense>
      ) : null}
    </>
  );
}
