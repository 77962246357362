import { List } from 'immutable';
import {
  StoreDocumentDTO,
  IStoreDocumentDTOProps,
  StoreDocumentModel,
  StoreDocumentListModel,
  StoreDocumentFormDTO,
  IStoreDocumentAdditionalParams,
  StoreDocumentItemModel,
  IStoreDocumentItemDTOProps,
  StoreDocumentItemDTO,
  StoreDocumentItemListModel,
  IStoreDocumentItemModelReturnType,
  StoreDocumentItemFormDTO,
  ProductModel,
  IStoreDocumentItemAdditionalProps,
  IStorePaymentDocumentFormAdditionalParams,
  StorePaymentDocumentFormDTO,
  StoreModel,
  ProductCategoryModel,
  IStoreDocumentStatsDTOProps,
  StoreDocumentStatsDTO,
  StoreDocumentShowOnly,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IStoreDocumentListDTO {
  documents: StoreDocumentDTO[];
  total: number;
  keywords?: string;
  page?: number;
  document?: StoreDocumentModel;
  stats?: StoreDocumentStatsDTO;
  show_only?: StoreDocumentShowOnly;
  resident_uuid?: string;
}

export interface IStoreDocumentItemListDTO {
  storeDocumentItems: StoreDocumentItemDTO[];
}

export class StoreDocumentMapper {
  public static toStoreDocumentDTO(
    storeDocument: StoreDocumentModel | IStoreDocumentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
    count_of_related_documents?: number,
  ): StoreDocumentDTO {
    return new StoreDocumentDTO(storeDocument, [
      WithoutRecursion.storeDocument,
      ...withoutRecursion,
    ]);
  }

  public static toStoreDocumentItemDTO(
    storeDocumentItem: StoreDocumentItemModel | IStoreDocumentItemDTOProps,
  ): StoreDocumentItemDTO {
    return new StoreDocumentItemDTO(storeDocumentItem, [
      WithoutRecursion.storeDocument,
    ]);
  }

  public static toStoreDocumentStatsDTO(
    stats: IStoreDocumentStatsDTOProps,
  ): StoreDocumentStatsDTO {
    return new StoreDocumentStatsDTO(stats);
  }

  public static toStoreDocumentListDTO(
    documents: List<StoreDocumentModel> | IStoreDocumentDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    document?: StoreDocumentModel,
    stats?: StoreDocumentStatsDTO,
    show_only?: StoreDocumentShowOnly,
    resident_uuid?: string,
  ): IStoreDocumentListDTO {
    const storeDocumentList = List.isList(documents)
      ? listToArray<StoreDocumentModel>(documents)
      : documents;

    return {
      documents: storeDocumentList?.map((storeDocument) =>
        StoreDocumentMapper.toStoreDocumentDTO(storeDocument, [
          WithoutRecursion.storeDocument,
          ...withoutRecursion,
        ]),
      ),
      document,
      total,
      stats,
      show_only,
      resident_uuid,
    };
  }

  public static toStoreDocumentItemListDTO(
    storeDocumentItems: StoreDocumentItemModel[] | IStoreDocumentItemDTOProps[],
  ): IStoreDocumentItemListDTO {
    return {
      storeDocumentItems: storeDocumentItems?.map((storeDocumentItem) =>
        StoreDocumentMapper.toStoreDocumentItemDTO(storeDocumentItem),
      ),
    };
  }

  public static toStoreDocumentModel(
    storeDocumentDTO: StoreDocumentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): StoreDocumentModel {
    return new StoreDocumentModel(storeDocumentDTO, [
      WithoutRecursion.storeDocument,
      ...withoutRecursion,
    ]);
  }

  public static toStoreDocumentItemModel(
    storeDocumentItemDTO: StoreDocumentItemDTO,
  ): StoreDocumentItemModel {
    return new StoreDocumentItemModel(storeDocumentItemDTO);
  }

  public static toStoreDocumentListModel(
    storeDocumentDTOs: StoreDocumentDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    document?: StoreDocumentModel,
    stats?: StoreDocumentStatsDTO,
    show_only?: StoreDocumentShowOnly,
    resident_uuid?: string,
  ): StoreDocumentListModel {
    return new StoreDocumentListModel(
      {
        documents: storeDocumentDTOs,
        total,
        document,
        stats,
        show_only,
        resident_uuid,
      },
      [WithoutRecursion.storeDocument, ...withoutRecursion],
    );
  }

  public static toStoreDocumentItemListModel(
    storeDocumentItemDTOs: StoreDocumentItemDTO[],
    total: number = 0,
    document?: StoreDocumentModel,
  ): StoreDocumentItemListModel {
    return new StoreDocumentItemListModel({
      doc_items: storeDocumentItemDTOs,
      total,
      document,
    });
  }

  public static toStoreDocumentFormDTO(
    storeDocumentModel: StoreDocumentModel,
    additionalParams: IStoreDocumentAdditionalParams,
  ): StoreDocumentFormDTO {
    return new StoreDocumentFormDTO(storeDocumentModel, additionalParams);
  }

  public static toStoreDocumentItemFormDTO(
    storeDocumentItemModel: IStoreDocumentItemModelReturnType | ProductModel,
    additionalProps: IStoreDocumentItemAdditionalProps,
  ): StoreDocumentItemFormDTO {
    return new StoreDocumentItemFormDTO(
      storeDocumentItemModel,
      additionalProps,
    );
  }

  public static toStorePaymentDocumentFormDTO(
    storeDocument: StoreDocumentModel,
    additionalProps: IStorePaymentDocumentFormAdditionalParams,
  ): StorePaymentDocumentFormDTO {
    return new StorePaymentDocumentFormDTO(storeDocument, additionalProps);
  }
}
