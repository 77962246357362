import { List, Map } from 'immutable';
import { STORE_DOCUMENT_LIST, APP_STATE } from '../constants';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  MAPPED_STORE_DOCUMENT_TYPES,
  StoreDocumentListModel,
  StoreDocumentMappedType,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatsDTO,
  StoreDocumentType,
} from '@structure';

interface IStoreDocumentListState {
  cachedStoreDocumentList: Map<
    StoreDocumentType | StoreDocumentMappedType,
    StoreDocumentListModel
  >;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetActionList {
  type: STORE_DOCUMENT_LIST.SET_STORE_DOCUMENT_LIST;
  storeDocumentList: StoreDocumentListModel;
  documentType: StoreDocumentType | StoreDocumentMappedType;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: STORE_DOCUMENT_LIST.ADD_STORE_DOCUMENT;
  storeDocument: StoreDocumentModel;
  documentType: StoreDocumentType | StoreDocumentMappedType;
}

interface UpdateStoreDocumentFromList {
  type: STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT;
  storeDocument: StoreDocumentModel;
  documentType: StoreDocumentType | StoreDocumentMappedType;
}
interface UpdateStoreDocumentStatsFromList {
  type: STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT;
  stats: StoreDocumentStatsDTO;
  documentType: StoreDocumentType;
}

interface DeleteActionFromList {
  type: STORE_DOCUMENT_LIST.DELETE_STORE_DOCUMENT;
  storeDocumentUuid: string;
  documentType: StoreDocumentType | StoreDocumentMappedType;
}

interface LoadingActionInList {
  type: STORE_DOCUMENT_LIST.LOADING_STORE_DOCUMENT_LIST;
}

interface ErrorActionInList extends Pick<IStoreDocumentListState, 'error'> {
  type: STORE_DOCUMENT_LIST.ERROR_STORE_DOCUMENT_LIST;
}

interface Handlers {
  [STORE_DOCUMENT_LIST.SET_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
    action: SetActionList,
  ) => IStoreDocumentListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStoreDocumentListState,
    action: SetInitialStateAction,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.LOAD_MORE_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
    action: SetActionList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.ADD_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: AddActionToList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: UpdateStoreDocumentFromList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT_STATS]: (
    state: IStoreDocumentListState,
    action: UpdateStoreDocumentStatsFromList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.DELETE_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: DeleteActionFromList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.ERROR_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
    value: ErrorActionInList,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT_LIST.LOADING_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
    value?: LoadingActionInList,
  ) => IStoreDocumentListState;
  DEFAULT: (state: IStoreDocumentListState) => IStoreDocumentListState;
}

const initState: IStoreDocumentListState = {
  cachedStoreDocumentList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [STORE_DOCUMENT_LIST.SET_STORE_DOCUMENT_LIST]: (
    state,
    { storeDocumentList, documentType },
  ) => {
    return {
      ...state,
      ...{
        cachedStoreDocumentList: storeDocumentList
          ? state.cachedStoreDocumentList.set(documentType, storeDocumentList)
          : state.cachedStoreDocumentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STORE_DOCUMENT_LIST.LOAD_MORE_STORE_DOCUMENT_LIST]: (
    state,
    { storeDocumentList, documentType },
  ) => {
    return {
      ...state,
      ...{
        cachedStoreDocumentList: storeDocumentList
          ? state.cachedStoreDocumentList.update(documentType, (list) => {
              if (
                list &&
                List.isList(list?.documents) &&
                List.isList(storeDocumentList?.documents)
              ) {
                return list.update('documents', (documents) =>
                  documents.merge(storeDocumentList.documents),
                );
              }

              return list;
            })
          : state.cachedStoreDocumentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_DOCUMENT_LIST.ADD_STORE_DOCUMENT]: (
    state,
    { storeDocument, documentType },
  ) => {
    const mappedDocumentTypes = MAPPED_STORE_DOCUMENT_TYPES[
      documentType as StoreDocumentType
    ] || [documentType];

    let cachedStoreDocumentList = state.cachedStoreDocumentList;

    mappedDocumentTypes.forEach(
      (documentType: StoreDocumentType | StoreDocumentMappedType) => {
        cachedStoreDocumentList = cachedStoreDocumentList?.update(
          documentType,
          (list) => {
            if (list && List.isList(list?.documents)) {
              return list
                .update('documents', (documents) =>
                  documents.unshift(storeDocument),
                )
                .update('total', (t = 0) => t + 1);
            }

            return StoreDocumentMapper.toStoreDocumentListModel(
              [StoreDocumentMapper.toStoreDocumentDTO(storeDocument)],
              1,
            );
          },
        );
      },
    );

    return {
      ...state,
      ...{
        cachedStoreDocumentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    { storeDocument, documentType }: UpdateStoreDocumentFromList,
  ) => {
    const mappedDocumentTypes = MAPPED_STORE_DOCUMENT_TYPES[
      documentType as StoreDocumentType
    ] || [documentType];

    let cachedStoreDocumentList = state.cachedStoreDocumentList;

    mappedDocumentTypes.forEach(
      (documentType: StoreDocumentType | StoreDocumentMappedType) => {
        cachedStoreDocumentList = cachedStoreDocumentList?.update(
          documentType,
          (list) => {
            if (list && List.isList(list?.documents)) {
              return list?.update('documents', (documents) =>
                documents?.map((stateStoreDocument) => {
                  if (stateStoreDocument?.uuid === storeDocument?.uuid) {
                    return stateStoreDocument?.merge(storeDocument);
                  }
                  return stateStoreDocument;
                }),
              );
            }

            return list;
          },
        );
      },
    );

    return {
      ...state,
      ...{
        cachedStoreDocumentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT_STATS]: (
    state: IStoreDocumentListState,
    {
      stats = {} as StoreDocumentStatsDTO,
      documentType,
    }: UpdateStoreDocumentStatsFromList,
  ) => {
    return {
      ...state,
      ...{
        cachedStoreDocumentList: state.cachedStoreDocumentList.update(
          documentType,
          (list) => {
            return list?.update('stats', (storeStats) => ({
              ...storeStats,
              ...stats,
            }));
          },
        ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_DOCUMENT_LIST.DELETE_STORE_DOCUMENT]: (
    state,
    { storeDocumentUuid, documentType },
  ) => {
    const mappedDocumentTypes = MAPPED_STORE_DOCUMENT_TYPES[
      documentType as StoreDocumentType
    ] || [documentType];

    let cachedStoreDocumentList = state.cachedStoreDocumentList;

    mappedDocumentTypes.forEach(
      (documentType: StoreDocumentType | StoreDocumentMappedType) => {
        cachedStoreDocumentList = cachedStoreDocumentList?.update(
          documentType,
          (list) => {
            if (list && List.isList(list?.documents)) {
              return list
                .update('documents', (documents) =>
                  documents.filter(({ uuid }) => uuid !== storeDocumentUuid),
                )
                .update('total', (t = 0) => t - 1);
            }

            return StoreDocumentMapper.toStoreDocumentListModel([], 0);
          },
        );
      },
    );

    return {
      ...state,
      ...{
        cachedStoreDocumentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_DOCUMENT_LIST.ERROR_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STORE_DOCUMENT_LIST.LOADING_STORE_DOCUMENT_LIST]: (
    state: IStoreDocumentListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStoreDocumentListState) => state,
};

export default function StoreDocumentList(
  state: any = initState,
  action: any,
): IStoreDocumentListState {
  const handler =
    handlers[action.type as STORE_DOCUMENT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
