import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { StoreFormDTO } from '@structure';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const StoreSideWindowLazy = React.lazy(() => import('../Show/StoreSideWindow'));

export interface IStoreCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: StoreFormDTO) => Promise<void>;
  onCancel?: () => void;
  store: StoreFormDTO;
}

export function StoreCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  store,
  ...rest
}: IStoreCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create store')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreSideWindowLazy
          store={store}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
