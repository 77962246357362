import { ClientAbonementModel, PAYMENT_METHOD } from '../internal';
import { correctPrice, isDateLessThanNow, toMoment } from '@services/helpers';
import { Moment } from 'moment';

export class ClientAbonementSubscriptionFormDTO {
  public uuid: string;
  public start_date: string | Moment;
  public payment_date: string | Moment;
  public packages: number;
  public amount?: string;
  public price?: string;
  public payment_type: PAYMENT_METHOD;

  constructor(props: ClientAbonementModel) {
    this.uuid = props?.uuid || '';
    this.packages = 1;
    this.amount = correctPrice(props?.abon_final_price || 0);
    this.price = correctPrice(props?.abon_final_price || 0);
    this.start_date = this.getStartDate(props?.abon_end_date);
    this.payment_date = toMoment(new Date(), 'YYYY-MM-DD');
    this.payment_type = PAYMENT_METHOD.Cash;
  }

  private getStartDate(date: string) {
    if (date) {
      const addOneDay = toMoment(date).add(1, 'days');

      if (isDateLessThanNow(addOneDay)) {
        return toMoment(new Date(), 'YYYY-MM-DD');
      }

      return addOneDay;
    }

    return toMoment(new Date(), 'YYYY-MM-DD');
  }
}
