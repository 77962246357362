import * as React from 'react';
import { getCompanyById } from '@services/api/company';
import { isThereContent, neq } from '@services/helpers';
import { CompanyMapper, CompanyModel } from '@structure';

export interface IUseCompanyProps {
  defaultCompany: CompanyModel | null;
  defaultCompanyUuid: string;
  searchCompanyUuid?: string;
  defaultCompanyLoading: boolean;
}

export interface IUseCompanyReturnType {
  defaultCompany: CompanyModel | null;
  loading: boolean;
}

export function useCompany({
  defaultCompany,
  defaultCompanyLoading,
  searchCompanyUuid,
  defaultCompanyUuid,
}: IUseCompanyProps): IUseCompanyReturnType {
  const [searchCompany, setSearchCompany] = React.useState<CompanyModel | null>(
    null,
  );
  const [searchCompanyLoading, setSearchCompanyLoading] = React.useState(false);

  const getCompanyByUuid = React.useCallback(async (uuid: string) => {
    try {
      setSearchCompanyLoading(true);

      const company = await getCompanyById(uuid);

      const companyModel = CompanyMapper.toCompanyModel(company);

      setSearchCompany(companyModel);
      setSearchCompanyLoading(false);
    } catch (e: any) {
      setSearchCompanyLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (
      searchCompanyUuid &&
      defaultCompanyUuid &&
      neq(searchCompanyUuid, defaultCompanyUuid)
    ) {
      (async () => {
        await getCompanyByUuid(searchCompanyUuid);
      })();
    }
  }, [defaultCompanyUuid, getCompanyByUuid, searchCompanyUuid]);

  return {
    defaultCompany: isThereContent(searchCompany)
      ? searchCompany
      : defaultCompany,
    loading: defaultCompanyLoading || searchCompanyLoading,
  };
}
