import { Map } from 'immutable';
import { STORE_DOCUMENT, APP_STATE } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { StoreDocumentModel } from '@structure';

interface IStoreDocumentListState {
  document: StoreDocumentModel | null;
  cachedStoreDocumentList: Map<string, StoreDocumentModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IStoreDocumentListState, 'document'> {
  type: STORE_DOCUMENT.SET_STORE_DOCUMENT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateStoreDocument {
  type: STORE_DOCUMENT.UPDATE_STORE_DOCUMENT;
  document: StoreDocumentModel;
}

interface LoadingAction {
  type: STORE_DOCUMENT.LOADING_STORE_DOCUMENT;
}

interface ResetAction {
  type: STORE_DOCUMENT.RESET_STORE_DOCUMENT;
}

interface ErrorAction extends Pick<IStoreDocumentListState, 'error'> {
  type: STORE_DOCUMENT.ERROR_STORE_DOCUMENT;
}

interface Handlers {
  [STORE_DOCUMENT.SET_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: SetAction,
  ) => IStoreDocumentListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStoreDocumentListState,
    action: SetInitialStateAction,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT.RESET_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: ResetAction,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT.UPDATE_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    action: UpdateStoreDocument,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT.ERROR_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    value: ErrorAction,
  ) => IStoreDocumentListState;

  [STORE_DOCUMENT.LOADING_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    value?: LoadingAction,
  ) => IStoreDocumentListState;
  DEFAULT: (state: IStoreDocumentListState) => IStoreDocumentListState;
}

const initState: IStoreDocumentListState = {
  document: null,
  cachedStoreDocumentList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [STORE_DOCUMENT.SET_STORE_DOCUMENT]: (state, { document }) => ({
    ...state,
    ...{
      document,
      cachedStoreDocumentList: document
        ? state.cachedStoreDocumentList.set(document?.uuid, document)
        : state.cachedStoreDocumentList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STORE_DOCUMENT.RESET_STORE_DOCUMENT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [STORE_DOCUMENT.UPDATE_STORE_DOCUMENT]: (state, { document }) => ({
    ...state,
    ...{
      document,
      cachedStoreDocumentList: state.cachedStoreDocumentList.update(
        document?.uuid,
        () => document,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STORE_DOCUMENT.ERROR_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STORE_DOCUMENT.LOADING_STORE_DOCUMENT]: (
    state: IStoreDocumentListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStoreDocumentListState) => state,
};

export default function StoreDocument(
  state: any = initState,
  action: any,
): IStoreDocumentListState {
  const handler = handlers[action.type as STORE_DOCUMENT] || handlers.DEFAULT;
  return handler(state, action);
}
