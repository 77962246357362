import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { GroupPaymentFlowType, GroupPaymentModel } from '@structure';

import {
  DownOutlined,
  GlobalOutlined,
  LockOutlined,
  UpOutlined,
} from '@ant-design/icons';

export interface IPaymentGroupTitleProps {
  group: GroupPaymentModel | null;
}

const StyledTitleContainer = styled.div<{
  $color?: string;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

const StyledTitleMainWrapper = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const StyledTitleSecondWrapper = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 300;
`;

const StyledSecondItemBlock = styled.div`
  padding-right: 5px;
`;

export function GroupPaymentTitle({
  group,
}: IPaymentGroupTitleProps): JSX.Element {
  const { t } = useTranslation();
  const isPaymentFlowIncome: boolean =
    group?.payment_type === GroupPaymentFlowType.INCOME;

  return (
    <StyledTitleContainer $color={undefined}>
      <StyledTitleMainWrapper
        style={group?.is_system ? { color: '#675647' } : {}}>
        {group?.title} {group?.is_system && <LockOutlined />}
      </StyledTitleMainWrapper>
      <StyledTitleSecondWrapper>
        {isPaymentFlowIncome ? (
          <StyledSecondItemBlock style={{ color: '#00bf7d' }}>
            <DownOutlined style={{ paddingRight: '5px' }} />
            {t('Income flow')}
          </StyledSecondItemBlock>
        ) : (
          <StyledSecondItemBlock style={{ color: '#b51963' }}>
            <UpOutlined style={{ paddingRight: '5px' }} />
            {t('Outcome flow')}
          </StyledSecondItemBlock>
        )}
        {group?.is_system && (
          <StyledSecondItemBlock style={{ color: '#5928ed' }}>
            <GlobalOutlined style={{ paddingRight: '5px' }} />
            {t('System payment group')}
          </StyledSecondItemBlock>
        )}
      </StyledTitleSecondWrapper>
    </StyledTitleContainer>
  );
}
