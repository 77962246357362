import { Record, List } from 'immutable';
import { IListProps } from './List';
import {
  AbonementGroupModel,
  AbonementModel,
  ClientAbonementDTO,
  ClientAbonementModel,
  ClientModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IClientAbonementListModelProps extends IListProps {
  abonements: ClientAbonementDTO[];
  payload?: ClientModel | AbonementModel | AbonementGroupModel | unknown;
}

export interface IClientAbonementListModel extends IListProps {
  abonements: List<ClientAbonementModel>;
  keywords: string;
  page: number;
}

export interface ICompanyClientAbonementListModelReturnType
  extends IClientAbonementListModel {
  client: ClientModel;
}

export interface ICompanyAbonementClientAbonementListModelReturnType
  extends IClientAbonementListModel {
  company_abonement: AbonementModel;
}

export interface ITariffModelGroupClientAbonementListModelReturnType
  extends IClientAbonementListModel {
  company_abonement_group: AbonementModel;
}

export type ClientAbonementListModelReturnType<T> = T extends AbonementModel
  ? ICompanyAbonementClientAbonementListModelReturnType
  : T extends ClientModel
  ? ICompanyClientAbonementListModelReturnType
  : T extends AbonementGroupModel
  ? ITariffModelGroupClientAbonementListModelReturnType
  : IClientAbonementListModel;

export class ClientAbonementListModel<
  T extends AbonementModel | ClientModel | unknown,
> extends Record<any>({
  abonements: List(),
  total: 0,
  keywords: '',
  page: 1,
  company_abonement: {},
  client: {},
  company_abonement_group: {},
}) {
  constructor(
    {
      abonements = [],
      payload,
      ...props
    }: IClientAbonementListModelProps = {} as IClientAbonementListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ClientAbonementListModelReturnType<any> = {
      ...props,
      ...(payload instanceof AbonementModel
        ? { company_abonement: payload }
        : payload instanceof ClientModel
        ? { client: payload }
        : payload instanceof AbonementGroupModel
        ? { company_abonement_group: payload }
        : {}),
      keywords: '',
      page: 1,
      abonements: List(
        abonements.map(
          (abon) => new ClientAbonementModel(abon, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
