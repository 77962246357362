import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { DatePicker } from '@components/lib/General';
import { Button, FormSwitch, SearchSelect } from '@components/lib/DataDisplay';
import { listToArray } from '@services/helpers';
import {
  ClientDiscountType,
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  DEFAULT_MULTI_CURRENCY,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
  CompanyRequisiteDTO,
  InvoiceType,
  InvoiceFormDTO,
} from '@structure';
import {
  IUseStateCompanyClientListReturnType,
  useSize,
  useStoredCompanies,
} from '@hooks';
import { phoneScreen } from '@services/const';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import { ClientCreateButton } from '../../Clients';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { STORE_PAYMENT_DISCOUNT_TYPE } from '@services/api/storeDocument';
import { InvoiceRefObject } from '../helpers';

export interface IStoreInvoiceFieldsProps
  extends Pick<
    IUseStateCompanyClientListReturnType,
    | 'clients'
    | 'handleSearchClients'
    | 'handleCreateClient'
    | 'handleCreateClientLegal'
  > {
  loading: boolean;
  editMode: boolean;
  clientsLoading: boolean;
  inv_multi_currencies: any;

  requisites: CompanyRequisiteDTO[];

  invoiceEditRef: React.MutableRefObject<InvoiceRefObject>;
  initInvoice: InvoiceFormDTO;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 20px 20px 0 20px;

  width: 100%;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid #d9d9d9;
`;

export const Title = styled(StyledTitle)`
  position: absolute;
  top: -14px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: ${({ theme }) => theme.background.primary};

  font-size: 16px;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

export function StoreInvoiceFields({
  loading,
  editMode,

  clients,
  clientsLoading,
  handleSearchClients,

  handleCreateClient,
  handleCreateClientLegal,
  inv_multi_currencies,

  requisites,
  invoiceEditRef,
  initInvoice,
}: IStoreInvoiceFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const {
    loadingSubmit,
    formData,
    handlerUpdateFormState,
    getFieldValue,
    setFieldsValue,
    validateFields,
  }: any = useDefaultForm();

  const { defaultCompany } = useStoredCompanies();

  const [client, setClient] = useState<ClientModel | null>(
    formData?.inv_client instanceof ClientModel ? formData?.inv_client : null,
  );

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;
  const onceClient = useRef(false);

  const handleUpdateClientDiscount = useCallback(
    (client: any) => {
      if (client) {
        setClient(client);

        if (
          client?.client_discount &&
          Number(formData?.doc_discount_value || 0) <= 0
        ) {
          handlerUpdateFormState({
            doc_discount_value: client?.client_discount,
            doc_discount_type: client?.client_discount_type,
            doc_discount_source: StorePaymentDocumentDiscountSourceType.CLIENT,
          });
        }
      }
    },
    [formData?.doc_discount_value, handlerUpdateFormState],
  );

  const handleQuicklyCreateClient = useCallback(
    async ({ t, ...value }: any): Promise<void> => {
      let client;

      if (value?.client_org_type === ClientOrgType.PERSON) {
        client = await handleCreateClient(value);
      } else {
        client = await handleCreateClientLegal(value as any);
      }

      handleUpdateClientDiscount(client);

      handlerUpdateFormState({
        inv_client: {
          ...value,
          uuid: client?.uuid,
        },
      });
    },
    [
      handleCreateClient,
      handleCreateClientLegal,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
    ],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      const client = clients?.find(({ uuid }) => uuid === e);

      handleUpdateClientDiscount(client);

      if (
        formData?.inv_client?.uuid !== e ||
        formData?.inv_client instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          const client = clients?.find(({ uuid }) => uuid === e);

          if (client) {
            let inv_client;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              inv_client = ClientMapper.toClientFormDTO(client);
            } else {
              inv_client = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({ inv_client });
          }
          return;
        }

        handlerUpdateFormState({ inv_client: { uuid: e } });
      }
    },
    [
      clients,
      formData?.inv_client,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
    ],
  );

  const handleChangeIsApplyPaymentDocDiscount = useCallback(
    (is_apply_inv_discount: boolean) => {
      if (is_apply_inv_discount) {
        handlerUpdateFormState({
          is_apply_inv_discount,
          inv_discount_type:
            client?.client_discount && client?.client_discount_type
              ? client?.client_discount_type
              : StorePaymentDocumentDiscountType.PERCENTAGE,
          inv_discount_value: client?.client_discount
            ? client?.client_discount
            : '0.00',
          inv_discount_source:
            client?.client_discount === formData?.inv_discount_value
              ? StorePaymentDocumentDiscountSourceType.CLIENT
              : StorePaymentDocumentDiscountSourceType.CUSTOM,
        });
      } else {
        handlerUpdateFormState({
          inv_discount_value: initInvoice.inv_discount_value,
          inv_discount_type: initInvoice.inv_discount_type,
          inv_discount_source: initInvoice.inv_discount_source,
          is_apply_inv_discount,
        });
      }
    },
    [
      client?.client_discount,
      client?.client_discount_type,
      formData?.inv_discount_value,
      handlerUpdateFormState,
      initInvoice.inv_discount_source,
      initInvoice.inv_discount_type,
      initInvoice.inv_discount_value,
    ],
  );

  const handleChangeDiscount = useCallback(
    (e: any) => {
      const inv_discount_value = e?.target?.value;

      if (client) {
        if (client?.client_discount !== inv_discount_value) {
          handlerUpdateFormState({
            inv_discount_source: StorePaymentDocumentDiscountSourceType.CUSTOM,
          });
        } else {
          handlerUpdateFormState({
            inv_discount_source: StorePaymentDocumentDiscountSourceType.CLIENT,
          });
        }
      }

      handlerUpdateFormState({
        inv_discount_value: e?.target?.value,
      });
    },
    [client, handlerUpdateFormState],
  );

  const isIncomeInvoice = formData?.inv_type === InvoiceType.IN;

  useEffect(() => {
    if (invoiceEditRef?.current) {
      const { errorFields, ...rest } = formData;

      invoiceEditRef.current.invoice = {
        ...rest,
        inv_multi_currencies: inv_multi_currencies || [],
      };
    }
  }, [formData, invoiceEditRef, inv_multi_currencies, initInvoice]);

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={isIncomeInvoice ? isFullWidth : 24}>
        <Form.Item
          label={t('Date')}
          name="inv_date"
          rules={[
            {
              required: true,
              message: t('Date be specified.'),
            },
          ]}>
          <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(inv_date) => handlerUpdateFormState({ inv_date })}
          />
        </Form.Item>
      </Col>

      {isIncomeInvoice ? (
        <Col span={isFullWidth}>
          <Form.Item label={t('Income number')} name="inv_income_number">
            <Input
              disabled={loading || loadingSubmit}
              placeholder={t('Enter the income number')}
              onChange={(e) =>
                handlerUpdateFormState({ inv_income_number: e?.target?.value })
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Client')} name="inv_client" tooltip={t('Client')}>
          <SearchSelect
            asObject={true}
            selectFirst={clients?.size === 1}
            name="inv_client"
            disable={loading || clientsLoading || loadingSubmit}
            placeholder={t('Select a client')}
            data={listToArray(clients as any)}
            valuePropName="uuid"
            getOptionValueTitle="fullNameClient"
            onSearch={(keywords) =>
              handleSearchClients({ keywords, showLoading: false }) as any
            }
            resetState={clientsLoading}
            onChange={handleChangeClient}
            addonAfter={
              <ClientCreateButton
                provider
                disabled={loading || loadingSubmit}
                onSuccess={handleQuicklyCreateClient}
                autoFocus>
                <PlusOutlined />
              </ClientCreateButton>
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Requisites of the company')}
          name="inv_company_requisites"
          tooltip={t('Requisites of the company specified in the invoice')}>
          <SearchSelect
            allowClear
            selectIfOnFirst
            selectFirst={false}
            name="inv_company_requisites"
            disable={loading || loadingSubmit}
            placeholder={t('Select a requisite')}
            data={requisites}
            onChange={(inv_company_requisites) =>
              handlerUpdateFormState({ inv_company_requisites })
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit}
        name="is_inv_multi_currencies"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Multi-currency document')}
        onChange={(is_inv_multi_currencies) => {
          handlerUpdateFormState({ is_inv_multi_currencies });

          if (is_inv_multi_currencies) {
            handlerUpdateFormState({
              inv_multi_currencies: DEFAULT_MULTI_CURRENCY,
            });
          } else {
            handlerUpdateFormState({ inv_multi_currencies: [] });
          }
        }}
      />

      {formData?.is_inv_multi_currencies ? (
        <StyledFieldsContainer>
          <Title>{`${t('Additional currencies')}:`}</Title>
          <Col span={24}>
            <Form.List name="inv_multi_currencies">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} align="top" key={field?.key}>
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Currency')}
                            name={[field.name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: t('Currency must be specified.'),
                              },
                            ]}>
                            <Input
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a currency')}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            required
                            label={t('tRate')}
                            name={[field.name, 'rate']}
                            rules={[
                              () => ({
                                validator(_, sum) {
                                  const value = sum;

                                  if (Number.isNaN(Number(value))) {
                                    return Promise.reject(
                                      new Error(t('Rate must be a number')),
                                    );
                                  }

                                  if (!value) {
                                    return Promise.reject(
                                      new Error(t('Rate must be specified.')),
                                    );
                                  }

                                  if (Number(value) <= 0) {
                                    return Promise.reject(
                                      new Error(
                                        t('Rate must be bigger than 0'),
                                      ),
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <FullWidthInputNumber
                              onFocus={(e) => e?.target?.select()}
                              type="number"
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a rate')}
                            />
                          </Form.Item>
                        </Col>

                        {inv_multi_currencies?.length > 1 ? (
                          <StyledDeleteCol span={2}>
                            <StyledDeleteOutlined
                              onClick={() => remove(field.name)}
                            />
                          </StyledDeleteCol>
                        ) : null}
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({ title: undefined, rate: undefined })}
                      block
                      icon={<PlusOutlined />}>
                      {t('Add currency')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </StyledFieldsContainer>
      ) : null}

      <FormSwitch
        span={formData?.is_apply_inv_discount ? 12 : 24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit || loading}
        name="is_apply_inv_discount"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Apply discount')}
        onChange={handleChangeIsApplyPaymentDocDiscount}
        value={!!formData?.is_apply_inv_discount}
      />

      {formData?.is_apply_inv_discount ? (
        <Col span={12}>
          <Form.Item
            tooltip={t('Discount')}
            label={null}
            name="inv_discount_value"
            rules={
              formData?.inv_discount_type === ClientDiscountType.Fixed
                ? [
                    {
                      transform: (value) => Number(value),
                      type: 'number',
                      required: false,
                      message: t('Discount must be specified.'),
                    },
                  ]
                : [
                    {
                      transform: (value) => Number(value),
                      type: 'number',
                      min: 0,
                      max: 100,
                      required: false,
                      message: t(
                        'Discount must be a number and bigger than -1 and less 100',
                      ),
                    },
                  ]
            }
            extra={
              <StyledDescription style={{ whiteSpace: 'unset' }}>
                {t(
                  'The discount is applied exclusively to the cost of goods and material values Bof the invoice.',
                )}
              </StyledDescription>
            }>
            <Input
              addonAfter={
                <Form.Item noStyle name="inv_discount_type">
                  <StyledSearchSelect
                    popupMatchSelectWidth={false}
                    name="inv_discount_type"
                    placeholder={''}
                    getOptionValueTitle="description"
                    disable={loadingSubmit}
                    data={STORE_PAYMENT_DISCOUNT_TYPE(
                      defaultCompany?.currency_symbol!,
                    )}
                    onChange={async (inv_discount_type) => {
                      handlerUpdateFormState({ inv_discount_type });
                      try {
                        await validateFields(['inv_discount_value']);
                      } catch (error) {}
                    }}
                  />
                </Form.Item>
              }
              type="number"
              disabled={loadingSubmit}
              placeholder={t('Enter discount')}
              onChange={handleChangeDiscount}
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Note')} name="inv_comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ inv_comment: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
