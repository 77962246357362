import { Map } from 'immutable';
import { APP_STATE, CLIENT } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ClientModel } from '@structure';

interface IClientListState {
  client: ClientModel | null;
  cachedClientList: Map<string, ClientModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IClientListState, 'client'> {
  type: CLIENT.SET_CLIENT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateClient {
  type: CLIENT.UPDATE_CLIENT;
  client: ClientModel;
}

interface LoadingAction {
  type: CLIENT.LOADING_CLIENT;
}

interface ResetAction {
  type: CLIENT.RESET_CLIENT;
}

interface ErrorAction extends Pick<IClientListState, 'error'> {
  type: CLIENT.ERROR_CLIENT;
}

interface Handlers {
  [CLIENT.SET_CLIENT]: (
    state: IClientListState,
    action: SetAction,
  ) => IClientListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientListState,
    action: SetInitialStateAction,
  ) => IClientListState;

  [CLIENT.RESET_CLIENT]: (
    state: IClientListState,
    action: ResetAction,
  ) => IClientListState;

  [CLIENT.UPDATE_CLIENT]: (
    state: IClientListState,
    action: UpdateClient,
  ) => IClientListState;

  [CLIENT.ERROR_CLIENT]: (
    state: IClientListState,
    value: ErrorAction,
  ) => IClientListState;

  [CLIENT.LOADING_CLIENT]: (
    state: IClientListState,
    value?: LoadingAction,
  ) => IClientListState;
  DEFAULT: (state: IClientListState) => IClientListState;
}

const initState: IClientListState = {
  client: null,
  cachedClientList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [CLIENT.SET_CLIENT]: (state, { client }) => ({
    ...state,
    ...{
      client,
      cachedClientList: client
        ? state.cachedClientList.set(client?.uuid, client)
        : state.cachedClientList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT.RESET_CLIENT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [CLIENT.UPDATE_CLIENT]: (state, { client }) => ({
    ...state,
    ...{
      client,
      cachedClientList: state.cachedClientList.update(
        client?.uuid,
        () => client,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [CLIENT.ERROR_CLIENT]: (state: IClientListState, { error }: ErrorAction) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT.LOADING_CLIENT]: (state: IClientListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientListState) => state,
};

export default function Client(
  state: any = initState,
  action: any,
): IClientListState {
  const handler = handlers[action.type as CLIENT] || handlers.DEFAULT;
  return handler(state, action);
}
