import { Routes, AclKey, GENDER } from './types';
import { EPlanTypeIdentifier } from './helpers';

export const authUrl =
  process.env.REACT_APP_AUTH_URL || 'http://localhost:3000';

export const boardUrl =
  process.env.REACT_APP_BOARD_URL || 'http://localhost:3000/app';

export const defaultUrl = process.env.REACT_APP_DEFAULT_URL || boardUrl;

export const profileUrl =
  process.env.REACT_APP_PROFILE_URL || 'http://localhost:3000';

export const userServiceUrl =
  process.env.REACT_APP_USER_AUTH_SERVICE_URL ||
  'https://sportix-auth-api.herokuapp.com/api';

export const userProfileServiceUrl =
  process.env.REACT_APP_USER_PROFILE_SERVICE_URL ||
  'https://sportix-api.herokuapp.com/api';

export const contentApiUrl =
  process.env.REACT_APP_CONTENT_SERVICE_URL ||
  'https://sportix-api.herokuapp.com/api';

export const billingServiceUrl =
  process.env.REACT_APP_BILLING_SERVICE_URL || 'https://sportix-api.com/api';

export const authSiteUrl =
  process.env.AUTH_SITE_URL || 'https://servicity.in.ua';

export const authLogoUrl =
  process.env.AUTH_LOGIN_LOGO || '/servicity-logo-white-ua.svg';

export const authLogoTitle = process.env.AUTH_SITE_TITLE || 'Servicity.in.ua';

export const consoleAuthUrl =
  process.env.REACT_APP_CONSOLE_AUTH_URL ||
  'https://console.servicity.in.ua/app/subscription';

export const domainAuthUrl =
  process.env.DOMAIN_AUTH_APP_URL || 'https://servicity.in.ua/#auth';

export const domainUrl = process.env.DOMAIN_APP_URL || 'servicity.in.ua';

export const footerAppName =
  process.env.REACT_APP_FOOTER_APP_NAME || '.ServicitY';

export const googleAnalyticsTrackingId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'your tracking id';

export const appId =
  process.env.REACT_APP_ID || 'com.sergeydonchenko.servicity';

export const contactSupport =
  process.env.REACT_APP_CONTACT_SUPPORT || 'https://servicity.in.ua';

export const termsOfService =
  process.env.REACT_APP_TERMS_OF_SERVICE || 'https://servicity.in.ua';

export const facebookUrl =
  process.env.REACT_APP_FACEBOOK_URL || 'https://www.facebook.com/';

export const twitterUrl =
  process.env.REACT_APP_TWITTER_URL || 'https://twitter.com/';

export const appStoreLink =
  process.env.REACT_APP_APP_STORE_LINK || 'https://www.apple.com/ua/app-store/';

export const googlePlayLink =
  process.env.REACT_APP_GOOLE_PLAY_LINK ||
  'https://play.google.com/store/apps?hl=ru&gl=US&pli=1';

export const verificationReSendCodeTimeout =
  process.env.REACT_APP_VERIFICATION_RESEND_CODE_TIMEOUT || 30;

export const domainApp = process.env.DOMAIN_APP || 'https://servicity.in.ua';

export const cryptoSecret =
  process.env.REACT_APP_CRYPTO_SECRET || 'secret_servicity';

export const subscriptionExpiredDays =
  process.env.REACT_APP_SUBSCRIPTION_EXPIRED_DAYS || 21;

export const clientSubscriptionExpiredDays =
  process.env.REACT_APP_CLIENT_SUBSCRIPTION_EXPIRED_DAYS || 5;

export enum LocalStorageItems {
  xAppInfo = 'x-app-info',
  xDeviceInfo = 'x-device-info',
  accessToken = 'access_token',
  refreshToken = 'refresh_token',
  language = 'language',
  verificationDataForgotPassword = 'e6d2865edd7f0e05506627926841cee4',
  verificationDataSignIn = '65aec0bdf1bba5b8fc9f28c997205cbe',
  verificationDataEmployeeSignup = '70e40b74c0e9f1f51e7838e4e5e9ab32',
  approveEnterData = '732b34b3b730f582dfdbc9abb7251487',
  authData = '8c5f202f92996344d202039871f5cf8e',
  employeeAuthData = 'a1b2eaad0539084e8c3e7e518f5d49ce',
  isShowAppIntro = '99079ed418af7c6f0e5c5f8338bdaa58',
  orderRangeDates = 'de05ae913386dcf83642dd999c5193bf',
  signupTokens = '9901da2a15dcdc88d6146302e5b98c0d',
  alphaNameFormPeriod = '187c83e451f4b27bd88281ceaae508a6',
  saleFormStoreData = '4dd90723e0e0e1f18ddf773a61d30452',
  saleConductFormMethodData = 'b37465ffae9d8caf037deb30afa3f029',
  saleConductFormCashboxData = 'edc75e7232afc4ca8c0b16fbf1995a0a',
  priceTagFormStoreData = 'bc84d970ed267887b0728606b58b96a0',
  productCategoryView = '93c860669b42eb38e59ec2573856eb75',
  leftoverPreorder = '00594754a95b3b3673aa23f742c0fd18',
  accessDeniedPageMessage = '70ee9c711548987bf485d4e8c2e913b1',
  saleCashBoxUuid = '0150c69b76efcc7c3692996ec49ed5e9',
  orderListFilter = '9298957c3d1c2060ac5fc4f394b6f343',
  clientListFilter = '9298957c3d1c2060ac5fc4f394b6f344',
  productListFilter = 'b8ee4b43604b0d2f15e49ed33ff669d8',
  companySalesFormData = 'ded9caad998f9abee2db473328e47705',
}

export enum SessionStorageItems {
  clientOrderRangeDates = '7bf5af179c6b7306a0668aafb78e88e1',
  paymentRangeDates = 'ae15c034a3d55ca137f368b5447c2b1e',
  clientPaymentRangeDates = '39aa8def1e54c9e1949ac48bd17f476b',
  schedulePaymentRangeDates = '83c54b260127a73cdec439f1356b6b48',
  realizationFormStoreData = 'b80e174fe8e70719475e08217c751779',
  realizationOrderFormStoreData = 'edbacd4785e73a2131cf89e9f0dc6582',
  postingFormStoreData = '77ba2254e898387a20de0d2730a136a4',
  preorderFormStoreData = '77ba2254e898387a20de0d2730a136a4',
  productStoreUuid = 'b29f7587f7caef7ea7956645c75c5a1c',
  storeDocumentDates = '9def524f30af611d4c6c6508597c9a90',
  storeDocumentSaleOutDates = '9def524f30af611d4c6c6508597c9a90',
  productLeftoversStoreUuid = 'c58420cbbc2db35bd0350a6f31f50a6b',
  productLeftoversCategoryUuid = '1bd6d8af256c292bdfa57cf6128371b3',
  productLeftoversshowRunningOutProducts = 'ae9e8a339bfbc2042f38c9cf28e2f06e',
  storePostingShowWithCreditsOnly = '0fae0b789abf9d5801556b38677571d1',
  storeDocumentResidentUuid = 'f613d08244baba1a807aa5933a36fc42',
  invoiceResidentUuid = '41799042002a5da355c11f6e5f270a93',

  productStoreDocumentShowWithCreditsOnly = '88eab515ca089aa81115d85b7c52646f',
  storeRealizationShowWithCreditsOnly = 'ee88372dda86249e899a9b702a7a9c79',
  storeDocumentProductModalCurrency = '4bd7b5eb034af2b6b03ed308cb7a9391',
  invoiceModalCurrency = 'fd5da8ffea2878a4a48d26722115d784',
  paymentsAllOperations = 'e5fc8206d505185723a8ce1657002a0e',
}

const { matchMedia } = window;

export const desktopScreen = 1200;
export const smallTableScreen = 1024;
export const bigTableScreen = 960;
export const tableScreen = 768;
export const bigPhoneScreen = 580;
export const phoneScreen = 480;

export const desktopScreenMediaQuery = matchMedia(
  `(max-width: ${desktopScreen}px)`,
);
export const smallTableScreenMediaQuery = matchMedia(
  `(max-width: ${smallTableScreen}px)`,
);

export const bigTableScreenMediaQuery = matchMedia(
  `(max-width: ${bigTableScreen}px)`,
);

export const tableScreenMediaQuery = matchMedia(
  `(max-width: ${tableScreen}px)`,
);

export const phoneBigScreenMediaQuery = matchMedia(
  `(max-width: ${bigPhoneScreen}px)`,
);
export const phoneScreenMediaQuery = matchMedia(
  `(max-width: ${phoneScreen}px)`,
);

export const AcceptLanguageItem: any = {
  ua: 'uk-UA,uk;q=0.5',
  en: 'en-US;q=0.7,en;q=0.6',
};

export const DATE_SHORT_FORMAT = 'YYYY-MM-DD';

export const EventEmitterItems = {
  navigate_to_profile_page: 'Navigate to profile page',
  app_navigate_to_access_denied_page: 'Navigate to access denied page from app',
  auth_navigate_to_access_denied_page:
    'Navigate to access denied page from auth',
  update_store_sale_document: 'Update store sale document',
  delete_store_sale_document: 'Delete store sale document',
};

export const ignoreRouteRedirect = [
  Routes.waitingForCompanyCreate,
  `${Routes.companies}/create`,
];

export const API_CALL_PAYMENT_TIMEOUT_DELAY = 30000;
export const API_CALL_PAYMENT_TIMEOUT_CLEAR = 360001;

export const aclFallback = {
  [AclKey.order]: {
    read: true,
    manage: true,
  },
  [AclKey.client]: {
    read: true,
    manage: true,
  },
  [AclKey.company]: {
    read: true,
    manage: true,
  },
  [AclKey.service]: {
    read: true,
    manage: true,
  },
  [AclKey.employee]: {
    read: true,
    manage: true,
  },
  [AclKey.calendar]: {
    read: true,
    manage: true,
  },
  [AclKey.settings]: {
    read: true,
    manage: true,
  },
  [AclKey.template]: {
    read: true,
    manage: true,
  },
  [AclKey.payment_group]: {
    read: true,
    manage: true,
  },
  [AclKey.notifications]: {
    read: true,
    manage: true,
  },
  [AclKey.reports]: {
    read: true,
    manage: true,
  },
  [AclKey.abonement]: {
    read: true,
    manage: true,
  },
  [AclKey.status]: {
    read: true,
    manage: true,
  },
  [AclKey.product]: {
    read: true,
    manage: true,
  },
  [AclKey.department]: {
    read: true,
    manage: true,
  },
  [AclKey.store]: {
    read: true,
    manage: true,
  },
  [AclKey.priceMargin]: {
    read: true,
    manage: true,
  },
  [AclKey.payment]: {
    read: true,
    manage: true,
  },
  [AclKey.integration]: {
    read: true,
    manage: true,
  },
  [AclKey.orderStats]: {
    read: true,
    manage: true,
  },
  [AclKey.invoices]: {
    read: true,
    manage: true,
  },
  [AclKey.employee_salary_settings]: {
    read: true,
    manage: false,
  },
};
export const GENDER_SEGMENTED = [
  {
    title: GENDER.MALE,
    uuid: GENDER.MALE,
  },
  {
    title: GENDER.FEMALE,
    uuid: GENDER.FEMALE,
  },
];

export const systemTitles = {
  [EPlanTypeIdentifier.TRIAL]: 'Trial',
  [EPlanTypeIdentifier.INDIVIDUAL]: 'individual',
  [EPlanTypeIdentifier.STARTER]: 'tStarter',
  [EPlanTypeIdentifier.PROFESSIONAL]: 'tProf',
  [EPlanTypeIdentifier.BUSINESS]: 'Business',
};

export const EAN13_UA = 482;

export const CLEARED_LOCAL_STORAGE_ITEMS = [
  LocalStorageItems.saleFormStoreData,
  LocalStorageItems.priceTagFormStoreData,
];

export const CLEARED_SESSION_STORAGE_ITEMS = [
  SessionStorageItems.realizationFormStoreData,
  SessionStorageItems.realizationOrderFormStoreData,
  SessionStorageItems.postingFormStoreData,
  SessionStorageItems.productStoreUuid,
  SessionStorageItems.productLeftoversStoreUuid,
  SessionStorageItems.productLeftoversCategoryUuid,
];

export const ENTER_KEYS = ['Enter', 'NumpadEnter'];
