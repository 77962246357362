import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { AbonementModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface ITariffModelDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  abonements: AbonementModel[];
}

export default function TariffModelsDeleteButton({
  children,
  abonements,
  onSuccess,
  onError,
  title,
  ...rest
}: ITariffModelDeleteButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: abonements,
    notifyTitle: 'Tariff models',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete tariff model')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(abonements)} ${
            len(abonements)
              ? t('tariff model').toLowerCase()
              : t('tariff models').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
