import * as React from 'react';
import { getCookie } from '@services/helpers';
import { tokenHandler } from '@services/core/token';

export default function usePreLoadCookie(): boolean {
  const access_token_cookie = getCookie('access_token');
  const refresh_token_cookie = getCookie('refresh_token');
  const is_verify_email_cookie = getCookie('is_verify_email');
  const is_active_plan_cookie = getCookie('is_active_plan');

  const restoreFromCookiesStatus = tokenHandler.getRestoreFromCookiesStatus();

  React.useEffect(() => {
    if (
      access_token_cookie &&
      refresh_token_cookie &&
      is_verify_email_cookie &&
      restoreFromCookiesStatus !== '-1'
    ) {
      (async () => {
        tokenHandler.setAuthTokens({
          refresh: refresh_token_cookie,
          access: access_token_cookie,
          is_verify_email: is_verify_email_cookie || '',
        });

        tokenHandler.setActivePlan(is_active_plan_cookie || '');
      })();
    }
  }, [
    access_token_cookie,
    is_active_plan_cookie,
    is_verify_email_cookie,
    refresh_token_cookie,
    restoreFromCookiesStatus,
  ]);

  return !!(
    access_token_cookie &&
    is_verify_email_cookie === '1' &&
    is_active_plan_cookie === 'true'
  );
}
