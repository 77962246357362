import React, { useCallback, useEffect, useState } from 'react';
import { IProductPrice, PriceMarginModel } from '@structure';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { correctPrice, listToArray } from '@services/helpers';
import { IUseStatePriceMarginListReturnType, useStoredCompanies } from '@hooks';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { List } from 'immutable';

export interface IProductPricesViewProps
  extends Pick<IUseStatePriceMarginListReturnType, 'priceMarginList'> {
  product_prices: IProductPrice[];
}

export interface IProductPricesDTO {
  priceMargin: PriceMarginModel;
  price: IProductPrice;
  uuid: string;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
  width: 100%;
` as React.ComponentType as React.FunctionComponent<
  ITableProps<IProductPricesDTO>
>;

export function ProductPricesView({
  product_prices,
  priceMarginList,
}: IProductPricesViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const [productPricesDTOs, setProductPricesDTOs] = useState<
    IProductPricesDTO[]
  >([]);

  const columns = [
    {
      title: t('Markup type'),
      key: 'markup type',
      render: (productPricesDTO: IProductPricesDTO) =>
        productPricesDTO?.priceMargin?.price_name,
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      alight: 'right' as any,
      render: (productPricesDTO: IProductPricesDTO) => (
        <StyledPriceContainer>
          {correctPrice(productPricesDTO?.price?.price_value || 0)}
        </StyledPriceContainer>
      ),
    },
  ];

  const getProductPricesDTOs = useCallback(
    (priceMarginList: List<PriceMarginModel>, prices: IProductPrice[]) => {
      const productPriceListDTOs = priceMarginList?.map((priceMargin) => {
        const price = prices?.find(
          ({ price_uuid }) => priceMargin?.uuid === price_uuid,
        );

        if (price) {
          return {
            priceMargin,
            price,
            uuid: priceMargin?.uuid,
          };
        }

        return {
          priceMargin,
          price: {
            price_uuid: priceMargin?.uuid,
            price_value: correctPrice(0),
          },
          uuid: priceMargin?.uuid,
        };
      });

      setProductPricesDTOs(listToArray(productPriceListDTOs));
    },
    [],
  );

  useEffect(() => {
    if (priceMarginList?.size) {
      getProductPricesDTOs(priceMarginList, product_prices);
    }
  }, [
    getProductPricesDTOs,
    priceMarginList,
    productPricesDTOs?.length,
    product_prices,
  ]);

  return (
    <StyledTable
      total={Infinity}
      pageSize={Infinity}
      dataSource={productPricesDTOs}
      columns={columns}
      loading={false}
      page={1}
      pagination={false}
    />
  );
}
