import React from 'react';
import { Text } from '../Format';
import { ButtonProps, Tooltip } from 'antd';
import { BsCheck2All } from 'react-icons/bs';
import { LoadingOutlined } from '@ant-design/icons';
import styled, { useTheme } from 'styled-components';
import { StyledIconButton } from '../Styled/Buttons';

export interface ICheckButtonProps extends ButtonProps {
  title?: string;
  tooltipTitle?: string;
}

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

export function CheckButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  loading,
  disabled,
  ...props
}: ICheckButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <StyledIconButton
        type={type}
        $useCustomStyle={type !== 'text'}
        style={style}
        loading={!!(title && loading)}
        disabled={disabled || !!(title && loading)}
        {...props}>
        {title ? (
          <Text>{title}</Text>
        ) : loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <BsCheck2All size={18} color={theme.colors.primary} />
        )}
      </StyledIconButton>
    </StyledTooltip>
  );
}
