import * as React from 'react';
import { List } from 'immutable';
import {
  useInvoiceList,
  IUseInvoiceListProps,
  IUseInvoiceListReturnType,
} from './useInvoiceList';
import {
  InvoiceMappedType,
  InvoiceMapper,
  InvoiceModel,
  InvoiceStats,
  InvoiceStatsModel,
} from '@structure';
import { deleteInvoice } from '@services/api/invoice';
import {
  setInvoiceList as storeSetInvoiceList,
  deleteInvoiceFromList as storeDeleteInvoice,
  loadMoreInvoiceList as storeLoadMoreInvoiceList,
  selectInvoiceList,
  updateInvoiceStatsInList as storeUpdateInvoiceStatsInList,
} from '@store/features/invoiceListSlice';
import { useSelector, useDispatch } from 'react-redux';
import { dateToIsoString, head, last, isFunction } from '@services/helpers';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Moment } from 'moment/moment';
import { useStateReducer } from '@components/lib/libV2/hooks';

export interface IUseStateInvoiceListProps extends IUseInvoiceListProps {
  mappedInvoiceType?: InvoiceMappedType;
  withoutDates?: boolean;
  alwaysSendRequest?: boolean;
}

export interface IUseStateInvoiceListReturnType
  extends Omit<IUseInvoiceListReturnType, 'entityList'> {
  invoiceList: List<InvoiceModel> | null;
  handleDeleteInvoices: (ids: string[], callback?: any) => Promise<void>;
  handleSearchInvoices: (value: Partial<IUseInvoiceListProps>) => Promise<void>;
  handleLoadMoreInvoices: () => Promise<void>;
  handleRefreshInvoices: (
    value: Partial<IUseInvoiceListProps> & { page: number },
  ) => Promise<void>;
  handlePickInvoiceRange: (value: [Moment, Moment]) => Promise<void>;
  loadingMore: boolean;
  page: number;
  stats: InvoiceStatsModel | null;
}

export interface UseStoredInvoiceListState {
  loadingMore: boolean;
  storeKeywords: string;
  storePage: number;
  storeTotal: number;
  invoiceList: List<InvoiceModel> | null;
  isStarLoading: boolean;
  stats: InvoiceStatsModel | null;
}

export function useStoredInvoiceList(
  {
    companyUuid,
    invoiceType: initInvoiceType,
    mappedInvoiceType,
    loadOnInit = true,
    invoiceStatus: initInvoiceStatus,
    alwaysSendRequest,
    ...rest
  }: IUseStateInvoiceListProps = {} as IUseStateInvoiceListProps,
): IUseStateInvoiceListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    invoiceList,
    loadingMore,
    isStarLoading,
    storePage,
    storeTotal,
    storeKeywords,
    handleUpdate: updateStateInvoices,
    stats,
  } = useStateReducer<UseStoredInvoiceListState>({
    loadingMore: false,
    storeKeywords: '',
    storePage: 1,
    storeTotal: 0,
    invoiceList: null,
    isStarLoading: false,
    stats: null,
  });

  const once = useRef(false);

  const {
    status: storedInvoiceListStatus,
    loading: storedInvoiceListLoading,
    cachedInvoiceMap,
    invoiceType: storeInvoiceType,
    invoiceStatus: storeInvoiceStatus,
    ...storedInvoiceListParams
  } = useSelector(selectInvoiceList);

  const invoiceType: any = (InvoiceMappedType as any)[storeInvoiceType]
    ? initInvoiceType
    : storeInvoiceType || initInvoiceType;
  const invoiceStatus: any = storeInvoiceStatus || initInvoiceStatus;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: invoiceListLoading,
    date_end,
    date_start,
    ...invoicesParams
  } = useInvoiceList({
    companyUuid,
    invoiceType,
    invoiceStatus,
    loadOnInit: false,
    ...rest,
  });

  useEffect(() => {
    if (cachedInvoiceMap?.size > 0) {
      const storeDocumentList = cachedInvoiceMap.get(
        mappedInvoiceType || invoiceType,
      );

      if (storeDocumentList) {
        updateStateInvoices({
          isStarLoading: false,
          invoiceList: storeDocumentList?.invoices,
          storePage: storeDocumentList?.page || 1,
          storeKeywords: storeDocumentList?.keywords || '',
          storeTotal: storeDocumentList?.total || 0,
          stats: storeDocumentList?.stats,
        });
      } else {
        updateStateInvoices({ isStarLoading: true });
      }
    }
    if (cachedInvoiceMap?.size === 0 && !invoiceList) {
      updateStateInvoices({ isStarLoading: true });
    }
  }, [
    cachedInvoiceMap,
    dispatch,
    invoiceList,
    invoiceType,
    entityList,
    mappedInvoiceType,
    storedInvoiceListStatus,
    updateStateInvoices,
  ]);

  useEffect(() => {
    if (
      (!List.isList(entityList?.invoices) &&
        isStarLoading &&
        loadOnInit &&
        companyUuid) ||
      (alwaysSendRequest && loadOnInit && companyUuid && !once?.current)
    ) {
      once.current = true;

      updateStateInvoices({ isStarLoading: false });

      (async () => {
        const entityList = await refresh({
          invoiceType,
          companyUuid,
        });

        if (entityList) {
          dispatch(
            storeSetInvoiceList({
              invoiceType: mappedInvoiceType || invoiceType,
              invoiceList: entityList,
              keywords: '',
            }),
          );
        }
      })();
    }
  }, [
    alwaysSendRequest,
    companyUuid,
    dispatch,
    entityList?.invoices,
    invoiceType,
    isStarLoading,
    loadOnInit,
    mappedInvoiceType,
    refresh,
    updateStateInvoices,
  ]);

  const handleDeleteInvoices = React.useCallback(
    async (ids: string[], callback?: any) => {
      try {
        const { stats } = await deleteInvoice(ids);

        setTimeout(() => {
          dispatch(
            storeDeleteInvoice({
              invoiceType: mappedInvoiceType || invoiceType,
              invoiceUuid: head(ids),
            }),
          );

          if (stats) dispatch(storeUpdateInvoiceStatsInList({ stats }));
        }, 100);
        alert('success', t('Bills'), t('Bill delete success'));

        if (isFunction(callback)) {
          callback();
        }
      } catch (error: any) {
        alert(
          'error',
          t('Bills'),
          `${t('An error occurred during delete bill')}: ${error?.message}`,
        );
      }
    },
    [alert, dispatch, invoiceType, mappedInvoiceType, t],
  );

  const handleSearchInvoices = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      date_start: start,
      date_end: end,
      invoiceType: initInvoiceType = invoiceType,
      invoiceStatus: initInvoiceStatus = invoiceStatus,
      ...rest
    }: Partial<IUseInvoiceListProps>) => {
      updateStateInvoices({ loadingMore: true });

      const invoiceListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        date_start: start === null ? start : date_start,
        date_end: end === null ? end : date_end,
        invoiceType: initInvoiceType,
        invoiceStatus: initInvoiceStatus,
        ...rest,
      });

      if (invoiceListModel && List.isList(invoiceListModel?.invoices)) {
        dispatch(
          storeSetInvoiceList({
            invoiceType: initInvoiceType,
            invoiceList: invoiceListModel,
            invoiceStatus: initInvoiceStatus,
            keywords: keywords || '',
          }),
        );
      }
      updateStateInvoices({ loadingMore: false });
    },
    [
      invoiceType,
      invoiceStatus,
      updateStateInvoices,
      refresh,
      date_start,
      date_end,
      dispatch,
    ],
  );

  const handleLoadMoreInvoices = useCallback(async () => {
    if (
      List.isList(invoiceList) &&
      invoiceList?.size < storeTotal &&
      !loadingMore
    ) {
      updateStateInvoices({ loadingMore: true });
      const invoiceListModel = await refresh({
        offset: invoiceList?.size,
        limit: 10,
        showLoading: false,
      });

      if (invoiceListModel && List.isList(invoiceListModel?.invoices)) {
        dispatch(
          storeLoadMoreInvoiceList({
            invoiceType: mappedInvoiceType || invoiceType,
            invoiceList: invoiceListModel,
          }),
        );
      }

      updateStateInvoices({ loadingMore: false });
    }
  }, [
    invoiceList,
    storeTotal,
    loadingMore,
    updateStateInvoices,
    refresh,
    dispatch,
    mappedInvoiceType,
    invoiceType,
  ]);

  const handleRefreshInvoices = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      ...rest
    }: Partial<IUseInvoiceListProps> & { page: number }) => {
      updateStateInvoices({ loadingMore: true });

      const invoiceListModel = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (invoiceListModel && List.isList(invoiceListModel?.invoices)) {
        dispatch(
          storeSetInvoiceList({
            invoiceType: mappedInvoiceType || invoiceType,
            invoiceList: invoiceListModel,
            keywords: storeKeywords,
            page,
          }),
        );
      }
      updateStateInvoices({ loadingMore: false });
    },
    [
      dispatch,
      invoiceType,
      mappedInvoiceType,
      refresh,
      storeKeywords,
      updateStateInvoices,
    ],
  );

  const handlePickInvoiceRange = React.useCallback(
    async (value: [Moment, Moment]) => {
      updateStateInvoices({ loadingMore: true });
      let invoiceListModel;

      if (value) {
        invoiceListModel = await refresh({
          offset: 0,
          limit,
          keywords: storeKeywords,
          showLoading: true,
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });
      } else {
        invoiceListModel = await refresh({
          offset: 0,
          limit,
          keywords: storeKeywords,
          showLoading: true,
          date_start: null as any,
          date_end: null as any,
        });
      }

      if (invoiceListModel && List.isList(invoiceListModel?.invoices)) {
        dispatch(
          storeSetInvoiceList({
            invoiceType: mappedInvoiceType || invoiceType,
            invoiceList: invoiceListModel,
            keywords: storeKeywords,
          }),
        );
      }
      updateStateInvoices({ loadingMore: false });
    },
    [
      dispatch,
      invoiceType,
      limit,
      mappedInvoiceType,
      refresh,
      storeKeywords,
      updateStateInvoices,
    ],
  );

  return {
    ...invoicesParams,
    ...storedInvoiceListParams,
    invoiceList: invoiceList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(invoiceList) && invoiceListLoading) ||
      storedInvoiceListLoading,
    handleDeleteInvoices,
    handleSearchInvoices,
    handleLoadMoreInvoices,
    handleRefreshInvoices,
    keywords: storeKeywords,
    page: storePage,
    handlePickInvoiceRange,
    date_start,
    date_end,
    invoiceType: invoiceType as any,
    invoiceStatus,
    stats,
  };
}
