import { Record } from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  FileDTO,
  FileTypes,
  FileStatusesText,
  ScheduleCalendarDTO,
  ScheduleCalendarModel,
  ScheduleCalendarMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IFileModelReturnType {
  uuid: string;
  file_original_name: string;
  file_url: string;
  fileable_type: FileTypes;
  file_status_text: FileStatusesText;
  created_at: string;
  updated_at: string;
  fileable: ScheduleCalendarDTO | ScheduleCalendarModel;
  created_by: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class FileModel extends Record<IFileModelReturnType>({
  uuid: '',
  file_original_name: '',
  file_url: '',
  fileable_type: FileTypes.Schedule,
  file_status_text: FileStatusesText.Enabled,
  created_at: '',
  updated_at: '',
  fileable: {} as ScheduleCalendarModel,
  company: {} as CompanyModel,
  created_by: {} as EmployeeModel,
}) {
  constructor(
    props: FileDTO = {} as FileDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IFileModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.file,
              ...withoutRecursion,
            ]),
      fileable:
        withoutRecursion.indexOf(WithoutRecursion.scheduleCalendar) !== -1
          ? props?.fileable
          : ScheduleCalendarMapper.toScheduleCalendarModel(props?.fileable, [
              WithoutRecursion.file,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company as CompanyDTO, [
              WithoutRecursion.file,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }
  get fileableModel(): ScheduleCalendarModel {
    return this.fileable instanceof ScheduleCalendarModel
      ? this.fileable
      : ScheduleCalendarMapper.toScheduleCalendarModel(this.fileable);
  }
}
