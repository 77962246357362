import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import { InvoiceItemListModel } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import {
  getInvoiceItemList,
  InvoiceItemListReturnType,
} from '@services/api/invoice';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseInvoiceItemListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  invoiceUuid: string;
  page?: number;
}

export interface IUseInvoiceItemListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: InvoiceItemListModel | null;
  refresh: (
    value: Partial<IUseInvoiceItemListProps>,
  ) => Promise<InvoiceItemListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  invoiceUuid: string;
  page: number;
}

export function useInvoiceItemList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    invoiceUuid,
  }: IUseInvoiceItemListProps = {} as IUseInvoiceItemListProps,
): IUseInvoiceItemListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    invoiceUuid: listCompanyUuid,
    page: listPage,
    handleUpdate,
  } = useStateReducer<Omit<IUseInvoiceItemListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    invoiceUuid,
    page: 1,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      invoiceUuid = listCompanyUuid,
      page = listPage,
    }: Partial<IUseInvoiceItemListProps> = {}): Promise<InvoiceItemListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          invoiceUuid,
          page,
        });

        const { items } = await cancellablePromise<InvoiceItemListReturnType>(
          getInvoiceItemList({
            invoiceUuid,
            limit,
            offset,
            keywords,
          }),
        );

        handleUpdate({
          entityList: items,
          total: items?.total,
          loading: false,
        });

        return items;
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during get invoice items')} : ${
            error?.message
          }`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      listPage,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && invoiceUuid) {
      (async () => {
        await refresh({ invoiceUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, invoiceUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    invoiceUuid: listCompanyUuid,
    page: listPage,
  };
}
