import * as React from 'react';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  EmployeeModel,
  ProductModel,
  ProductStatus,
  StoreDocumentDTO,
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemListModel,
  StoreDocumentItemModel,
  StoreDocumentItemSaleType,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
  StorePaymentDocumentPostActionType,
} from '@structure';
import {
  IUseDefaultPriceMarginReturnType,
  IUseProductListProps,
  IUseStoreDocumentItemListProps,
  useBeforeUnload,
  useDefaultPriceMargin,
  useProductList,
  useStateStoreDocumentItemList,
  useStoredCompanies,
  useStoredProfile,
  useStoredStoreDocument,
  useStoredStoreSaleCashBoxList,
  UseStoredStoreSaleCashBoxListReturnType,
} from '@hooks';
import {
  EventEmitter,
  head,
  listToArray,
  toDateByFormat,
  last,
} from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import { useStateReducer } from '@components/lib/libV2/hooks';
import { List } from 'immutable';
import { useDropdownAlert } from '@contex';
import { ConcurrentQueue } from '@services/helpers/ConcurrentQueue';
import {
  createStoreDocumentItem,
  editStoreDocumentItem,
} from '@services/api/storeDocument';
import { EventEmitterItems, LocalStorageItems } from '@services/const';
import { convertedCashBoxMethodToPayment } from '@services/api/companyPayments';
import { Routes } from '@services/types';
import { SaleGUIConcurrentQueue } from '../SaleGUIConcurrentQueue';

export interface IStoreSaleManagerProps {
  children: React.ReactNode;
}

export interface IStoreSaleManagerContext
  extends Pick<
      IUseDefaultPriceMarginReturnType,
      'defaultPriceMargin' | 'getProductPriceMargin' | 'setDefaultPriceMargin'
    >,
    Pick<
      UseStoredStoreSaleCashBoxListReturnType,
      'cashBoxList' | 'handleLoadMoreCashBoxes' | 'handleSearchCashBoxes'
    > {
  draftDocumentCallbacks: MutableRefObject<{
    onCreate: (document: StoreDocumentModel) => void;
    onUpdate: (document: StoreDocumentModel) => void;
    onDelete: (documentUuid: string) => void;
    resetDraftValue: () => void;
    isInit: boolean;
  }>;

  keywords: string;
  concurrentQueue: MutableRefObject<ConcurrentQueue>;
  concurrentQueueDelete: MutableRefObject<ConcurrentQueue>;
  concurrentQueueGUI: MutableRefObject<SaleGUIConcurrentQueue>;
  concurrentQueueDeleteGUI: MutableRefObject<SaleGUIConcurrentQueue>;
  document: StoreDocumentFormDTO;
  storeDocument: StoreDocumentModel | null;
  documentPayment: StorePaymentDocumentFormDTO;
  storeDocumentItemList: StoreDocumentItemFormDTO[];
  handleSelectDaftDocument: (documentUuid: string) => Promise<void>;
  handleSelectDaftDocumentFromModal: (
    document: StoreDocumentModel,
  ) => Promise<void>;
  documentItemsLoading: boolean;
  storeDocumentItemListLimit: number;
  storeDocumentItemListTotal: number;
  storeDocumentItemListPage: number;
  handleDeleteStoreDocumentItem: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<void>;
  handleDeleteStoreDocumentItemGUI: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<void>;
  handleChangeDocument: (value: Partial<StoreDocumentFormDTO>) => void;
  storeDocumentItemListRefresh: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  loadingSubmit: boolean;
  handleAddOrUpdateDocumentItems: (
    value: StoreDocumentItemFormDTO,
    withProductAmount?: boolean,
  ) => Promise<void>;
  handleUpdateStoreDocumentSalePriceMargin: (
    priceMarginUuid: string,
  ) => Promise<void>;
  loadingProductUuid: string;
  disabledProductUuid: string;
  handleSaveAsDraftDocument: () => void;
  handlePaymentStoreDocument: (
    value: StorePaymentDocumentFormDTO,
  ) => Promise<void>;
  autoFocus: boolean;
  loadingSaveDraftDocument: boolean;
  handleDisableAutoFocus: () => void;
  handleAddOrUpdateItemGUI: (product: ProductModel) => void;
  handleEnableAutoFocus: () => void;
  onDeleteStoreDocumentFromState: (value: StoreDocumentItemFormDTO) => void;
  onAddStoreDocumentFromState: (value: StoreDocumentItemFormDTO) => void;
  cashBoxListLoading: boolean;
  cashBoxListIsLoadingMore: boolean;
  cashBoxListLoadingMore: boolean;
  handleUpdateSaleCashBox: (cashBoxUuid: string) => void;
  cashBoxValue: string;
  handleChangeKeywords: (keywords: string) => void;
  deletedStoreDocumentUuid: string;
}

export interface StoreSaleManagerState {
  documentForm: StoreDocumentFormDTO;
  deletedStoreDocumentUuid: string;
  loadingSubmit: boolean;
  loadingProductUuid: string;
  disabledProductUuid: string;
  autoFocus: boolean;
  loadingSaveDraftDocument: boolean;
  cashBoxValue: string;
  cashBoxMethod: string;
  keywords: string;
}

const storeDocumentItemErrorTypes = [
  StoreDocumentItemSaleType.NOT_FOUND_ERROR,
  StoreDocumentItemSaleType.API_ERROR,
];

export const StoreSaleManagerContext =
  React.createContext<IStoreSaleManagerContext>({
    concurrentQueue: {
      current: ConcurrentQueue.channels(1).process(async () => {}),
    },
    concurrentQueueDelete: {
      current: ConcurrentQueue.channels(1).process(async () => {}),
    },
    concurrentQueueGUI: {
      current: SaleGUIConcurrentQueue.channels(1).process(async () => {}),
    },
    concurrentQueueDeleteGUI: {
      current: SaleGUIConcurrentQueue.channels(1).process(async () => {}),
    },
    keywords: '',
    defaultPriceMargin: null,
    getProductPriceMargin: () => {
      return '';
    },
    setDefaultPriceMargin: () => {},
    onDeleteStoreDocumentFromState: () => {},
    onAddStoreDocumentFromState: () => {},
    draftDocumentCallbacks: {
      current: {
        onCreate: (value: StoreDocumentModel) => {},
        onUpdate: (value: StoreDocumentModel) => {},
        onDelete: (documentUuid: string) => {},
        resetDraftValue: () => {},
        isInit: false,
      },
    },
    document: StoreDocumentMapper.toStoreDocumentFormDTO(
      {} as StoreDocumentModel,
      { editMode: false },
    ),
    storeDocument: null,
    documentPayment: StoreDocumentMapper.toStorePaymentDocumentFormDTO(
      {} as StoreDocumentModel,
      {
        cashier: {} as EmployeeModel,
        comment: '',
        payment_post_action: {
          action:
            StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
        },
      },
    ),
    storeDocumentItemList: [],
    documentItemsLoading: false,
    loadingSaveDraftDocument: false,
    storeDocumentItemListLimit: 100,
    storeDocumentItemListTotal: 0,
    storeDocumentItemListPage: 0,

    handleSelectDaftDocument: async () => {},
    handleAddOrUpdateItemGUI: async () => {},
    handleSelectDaftDocumentFromModal: async () => {},
    handleDeleteStoreDocumentItem: async () => {},
    handleDeleteStoreDocumentItemGUI: async () => {},
    storeDocumentItemListRefresh: async () => {},
    handleAddOrUpdateDocumentItems: async () => {},
    handlePaymentStoreDocument: async () => {},
    handleUpdateStoreDocumentSalePriceMargin: async () => {},
    handleChangeDocument: () => {},
    handleDisableAutoFocus: () => {},
    handleEnableAutoFocus: () => {},
    loadingSubmit: false,
    loadingProductUuid: '',
    disabledProductUuid: '',
    handleSaveAsDraftDocument: () => {},
    autoFocus: true,
    cashBoxList: null,
    cashBoxListLoading: true,
    cashBoxListIsLoadingMore: false,
    cashBoxListLoadingMore: false,
    handleLoadMoreCashBoxes: async () => {},
    handleSearchCashBoxes: async () => {},
    handleUpdateSaleCashBox: () => {},
    cashBoxValue: '',
    handleChangeKeywords: () => {},
    deletedStoreDocumentUuid: '',
  });

export const useStoreSale = () => React.useContext(StoreSaleManagerContext);

export const StoreSaleManager = function StoreSaleManager({
  children,
}: IStoreSaleManagerProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { profile } = useStoredProfile();
  const { alert } = useDropdownAlert();

  const [storeDocumentItemListForm, setStoreDocumentItemListForm] = useState<
    StoreDocumentItemFormDTO[]
  >([]);

  const storeDocumentItemListFormRef = useRef<StoreDocumentItemFormDTO[]>([]);
  const storeDocumentRef = useRef<StoreDocumentModel | null>();
  const draftIntervalTimer = useRef<any>(null);
  const storeRef = useRef<string>('');

  useMemo(() => {
    const serializeStoreData = localStorage.getItem(
      LocalStorageItems.saleFormStoreData,
    );

    const storeModel = serializeStoreData?.includes('store_name')
      ? JSON.parse(serializeStoreData)
      : null;

    storeRef.current = storeModel?.uuid;

    return storeModel;
  }, []);

  const {
    documentForm,
    loadingSubmit,
    loadingProductUuid,
    disabledProductUuid,
    autoFocus,
    loadingSaveDraftDocument,
    cashBoxValue,
    cashBoxMethod,
    keywords,
    deletedStoreDocumentUuid,

    handleUpdate,
  } = useStateReducer<StoreSaleManagerState>({
    documentForm: StoreDocumentMapper.toStoreDocumentFormDTO(
      { doc_type: StoreDocumentType.OUT } as StoreDocumentModel,
      { editMode: true },
    ),
    loadingSubmit: false,
    loadingProductUuid: '',
    disabledProductUuid: '',
    autoFocus: true,
    loadingSaveDraftDocument: false,
    cashBoxValue: head<string>(
      (localStorage.getItem(LocalStorageItems.saleCashBoxUuid) || '').split(
        '&',
      ),
    ),
    cashBoxMethod: last<string>(
      (localStorage.getItem(LocalStorageItems.saleCashBoxUuid) || '').split(
        '&',
      ),
    ),
    keywords: '',
    deletedStoreDocumentUuid: '',
  });

  const draftDocumentCallbacks = React.useRef({
    onCreate: (value: StoreDocumentModel) => {},
    onUpdate: (value: StoreDocumentModel) => {},
    onDelete: (documentUuid: string) => {},
    resetDraftValue: () => {},
    isInit: false,
  });

  const {
    document: storeDocument,
    handleCreateStoreDocument,
    handlePaymentStoreDocument: onPaymentStoreDocument,
    refresh,
    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem: onDeleteStoreDocumentItem,
    setStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentSalePriceMargin:
      onUpdateStoreDocumentSalePriceMargin,
  } = useStoredStoreDocument({
    companyUuid: defaultCompanyUuid,
    documentUuid: '',
    documentType: StoreDocumentType.OUT,
    loadOnInit: false,
    withoutUpdatePayment: true,
  });

  const {
    storeDocumentItemList,
    documentUuid: storeDocumentItemListDocumentUuid,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
    setStoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: '',
    limit: 100,
    loadOnInit: false,
  });

  const { refresh: productListRefresh } = useProductList({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
    minKeywordLength: 3,
  });

  const {
    defaultPriceMarginRef,
    defaultPriceMargin,
    getProductPriceMargin,
    setDefaultPriceMargin,
  } = useDefaultPriceMargin({
    loadOnInit: true,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    loadingMore: cashBoxListLoadingMore,
    isLoadingMore: cashBoxListIsLoadingMore,
    handleLoadMoreCashBoxes,
    handleSearchCashBoxes,
  } = useStoredStoreSaleCashBoxList();

  const handleSelectDaftDocumentFromModal = React.useCallback(
    async (document: StoreDocumentModel) => {
      await storeDocumentItemListRefresh({
        documentUuid: document?.uuid,
      });

      setStoreDocument(document);
    },
    [setStoreDocument, storeDocumentItemListRefresh],
  );

  const handleChangeDocument = React.useCallback(
    (value: Partial<StoreDocumentFormDTO>) => {
      if (documentForm) {
        const updatedDocument = produce(documentForm, (draft: any) => {
          Object.entries(value).forEach(([key, value]) => {
            draft[key] = value;
          });
        });

        handleUpdate({ documentForm: updatedDocument });
      }
    },
    [documentForm, handleUpdate],
  );

  const documentPayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        storeDocument || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel?.uuid,
          comment: `${t(
            'Realization of the goods according to the invoice',
          )} №${storeDocument?.doc_local_number} ${t('From')} ${toDateByFormat(
            storeDocument?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
          payment_cashbox_uuid: cashBoxValue,
          payment_method: cashBoxMethod as any,
          onlyTotal: true,
        },
      ),
    [
      storeDocument,
      profile?.defaultEmployeeModel?.uuid,
      t,
      cashBoxValue,
      cashBoxMethod,
    ],
  );

  useEffect(() => {
    if (storeDocument && storeDocument?.uuid !== documentForm?.uuid) {
      handleUpdate({
        documentForm: StoreDocumentMapper.toStoreDocumentFormDTO(
          storeDocument ||
            ({ doc_type: StoreDocumentType.OUT } as StoreDocumentModel),
          { editMode: true },
        ),
      });
    }
  }, [storeDocument, documentForm, handleUpdate]);

  useEffect(() => {
    if (List.isList(storeDocumentItemList)) {
      const storeDocumentItems = listToArray(
        (storeDocumentItemList || [])?.map((item: any) =>
          StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
            editMode: true,
          }),
        ),
      );

      setStoreDocumentItemListForm(storeDocumentItems);
      storeDocumentItemListFormRef.current = storeDocumentItems;
    }
  }, [handleUpdate, storeDocumentItemList]);

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${t('An error occurred while interacting with the product')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  const addFirstProduct = useCallback(
    async (value: StoreDocumentItemFormDTO) => {
      handleUpdate({
        loadingProductUuid: value?.product_uuid,
        disabledProductUuid: value?.product_uuid,
      });

      const updatedValue = produce(documentForm, (draft) => {
        draft.doc_items = [value].map(
          ({
            product_uuid,
            product_amount,
            price,
            product_price,
            cell_identifier,
          }) => ({
            product_uuid,
            product_amount,
            price,
            product_price,
            cell_identifier,
          }),
        );

        draft.doc_extra_services = undefined;
        draft.doc_multi_currencies = undefined;
        draft.doc_type = StoreDocumentType.OUT;
      });

      setStoreDocumentItemListForm((prevState) => {
        const state = [value, ...prevState];

        storeDocumentItemListFormRef.current = state;

        return state;
      });

      try {
        const storeDoc = await handleCreateStoreDocument(updatedValue);

        if (storeDoc?.document) {
          setStoreDocument(storeDoc?.document);
          draftDocumentCallbacks.current.onCreate(storeDoc?.document);

          const firstDocItem = List.isList(storeDoc?.document?.doc_items)
            ? storeDoc?.document?.doc_items?.first()
            : head(storeDoc?.document?.doc_items);

          if (firstDocItem) {
            const storeDocumentItem =
              StoreDocumentMapper.toStoreDocumentItemFormDTO(firstDocItem, {
                editMode: true,
              });

            setStoreDocumentItemListForm((prevState) => {
              const state = prevState.map((item) => {
                if (item?.product_uuid === storeDocumentItem?.product_uuid) {
                  return storeDocumentItem;
                }
                return item;
              });

              storeDocumentItemListFormRef.current = state;

              return state;
            });
          }

          return storeDoc?.document;
        }
      } catch (error) {
        notifyError(error);
        setStoreDocumentItemListForm((prevState) => {
          const state = prevState.filter(
            ({ product_uuid }) => product_uuid !== value?.product_uuid,
          );

          storeDocumentItemListFormRef.current = state;

          return state;
        });
      } finally {
        handleUpdate({ loadingProductUuid: '', disabledProductUuid: '' });
      }
    },
    [
      documentForm,
      handleCreateStoreDocument,
      handleUpdate,
      notifyError,
      setStoreDocument,
    ],
  );

  const addOrUpdateStoreDocumentItem = useCallback(
    async (value: StoreDocumentItemFormDTO, withProductAmount?: boolean) => {
      setTimeout(async () => {
        handleUpdate({ disabledProductUuid: value?.product_uuid });

        setStoreDocumentItemListForm((prevState) => {
          const index = prevState.findIndex(
            ({ product }) => product?.uuid === value?.product_uuid,
          );

          if (~index) {
            const state = prevState.map((item) => {
              if (item?.product?.uuid === value?.product_uuid) {
                return withProductAmount
                  ? value
                  : { ...value, product_amount: item?.product_amount + 1 };
              }
              return item;
            });

            storeDocumentItemListFormRef.current = state;

            return state;
          } else {
            const state = [value, ...prevState];

            storeDocumentItemListFormRef.current = state;

            return state;
          }
        });

        try {
          const index = storeDocumentItemListFormRef.current.findIndex(
            ({ product }) => product?.uuid === value?.product_uuid,
          );

          if (~index) {
            value = produce(value, (draft) => {
              draft.uuid = storeDocumentItemListFormRef.current[index]?.uuid;
            });
          } else {
            value = produce(value, (draft) => {
              draft.uuid = '';
            });
          }

          const documentItemModel = await handleCreateOrUpdateStoreDocumentItem(
            {
              ...value,
              product_amount:
                !~index || withProductAmount
                  ? value?.product_amount
                  : storeDocumentItemListFormRef.current[index]
                      ?.product_amount + 1,
            },
          );

          const { documentItem, document } = documentItemModel || {};

          if (document) {
            draftDocumentCallbacks?.current?.onUpdate(document);
          }

          if (documentItem) {
            setStoreDocumentItemListForm((prevState) => {
              const state = prevState.map((item) => {
                if (item?.product?.uuid === value?.product_uuid) {
                  return StoreDocumentMapper.toStoreDocumentItemFormDTO(
                    documentItem,
                    { editMode: true },
                  );
                }
                return item;
              });

              storeDocumentItemListFormRef.current = state;

              return state;
            });
          }
        } catch (error) {
          notifyError(error);
          setStoreDocumentItemListForm((prevState) => {
            const index = prevState?.findIndex(
              ({ product }) => product?.uuid === value?.product_uuid,
            );

            if (~index) {
              const amount = prevState[index]?.product_amount;

              if (amount > 1) {
                const state = prevState.map((item) => {
                  if (item?.product?.uuid === value?.product_uuid) {
                    return {
                      ...item,
                      product_amount: item?.product_amount - 1,
                    };
                  }
                  return item;
                });

                storeDocumentItemListFormRef.current = state;
                return state;
              } else {
                const state = prevState.filter(
                  ({ product_uuid }) => product_uuid !== value?.product_uuid,
                );

                storeDocumentItemListFormRef.current = state;

                return state;
              }
            } else {
              const state = prevState.filter(
                ({ product_uuid }) => product_uuid !== value?.product_uuid,
              );

              storeDocumentItemListFormRef.current = state;

              return state;
            }
          });
        } finally {
          handleUpdate({ disabledProductUuid: '' });
        }
      }, 0);
    },
    [handleCreateOrUpdateStoreDocumentItem, handleUpdate, notifyError],
  );

  const handleAddOrUpdateDocumentItems = useCallback(
    async (
      value: StoreDocumentItemFormDTO,
      withProductAmount?: boolean,
    ): Promise<any> => {
      if (!documentForm?.uuid) {
        return await addFirstProduct(value);
      }

      await addOrUpdateStoreDocumentItem(value, withProductAmount);
    },
    [addFirstProduct, addOrUpdateStoreDocumentItem, documentForm?.uuid],
  );

  const onAddOrUpdateProduct = useCallback(
    async (
      value: Partial<IUseProductListProps>,
      product: ProductModel | undefined,
    ) => {
      const companyUuid = JSON.parse(
        localStorage.getItem('defaultCompanyUuid') || "''",
      );

      const addOrUpdate = (item: StoreDocumentItemFormDTO) => {
        setStoreDocumentItemListForm((prevState) => {
          const index = prevState?.findIndex(
            (prevItem) =>
              prevItem?.product?.uuid === item?.product_uuid ||
              prevItem?.product?.uuid === item?.product?.product_barcode ||
              prevItem?.product?.uuid === item?.product?.product_title,
          );

          let state;

          if (~index) {
            state = produce(prevState, (draft) => {
              (draft as any)[index] = {
                ...item,
                product_amount: draft[index]?.product_amount + 1,
                item_sale_type:
                  item?.item_sale_type === StoreDocumentItemSaleType.LOADING
                    ? StoreDocumentItemSaleType.SUCCESS
                    : item?.item_sale_type,
              };
            });
          } else {
            state = [item, ...prevState];
          }

          storeDocumentItemListFormRef.current = state;

          return state;
        });
      };

      if (product) {
        let docItem = StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          price: getProductPriceMargin(product, defaultPriceMarginRef.current),
        });

        if (docItem?.product?.product_status !== ProductStatus.ENABLE) {
          alert('error', t('Product'), t('Operations with goods are blocked.'));
          return;
        }

        if (!storeDocumentRef.current?.uuid) {
          const updatedValue = produce(
            StoreDocumentMapper.toStoreDocumentFormDTO(
              {} as StoreDocumentModel,
              { editMode: true },
            ),
            (draft) => {
              draft.doc_items = [docItem].map(
                ({
                  product_uuid,
                  product_amount,
                  price,
                  product_price,
                  cell_identifier,
                }) => ({
                  product_uuid,
                  product_amount,
                  price,
                  product_price,
                  cell_identifier,
                }),
              );

              draft.doc_store_uuid = value?.store_uuid || '';

              draft.doc_extra_services = undefined;
              draft.doc_multi_currencies = undefined;
              draft.doc_type = StoreDocumentType.OUT;
            },
          );

          const storeDoc = await handleCreateStoreDocument(
            updatedValue,
            companyUuid,
          );

          if (storeDoc?.document) {
            setStoreDocument(storeDoc?.document);

            storeDocumentRef.current = storeDoc?.document;
            draftDocumentCallbacks.current.onCreate(storeDoc?.document);

            const firstDocItem = List.isList(storeDoc?.document?.doc_items)
              ? storeDoc?.document?.doc_items?.first()
              : head(storeDoc?.document?.doc_items);

            if (firstDocItem) {
              const storeDocumentItem =
                StoreDocumentMapper.toStoreDocumentItemFormDTO(firstDocItem, {
                  editMode: true,
                });

              addOrUpdate(storeDocumentItem);
            }
          }
        } else {
          const index = storeDocumentItemListFormRef.current.findIndex(
            ({ product }) => product?.uuid === docItem?.product_uuid,
          );

          const updatedItem = produce(docItem, (draft) => {
            if (~index) {
              draft.uuid = storeDocumentItemListFormRef.current[index]?.uuid;
              draft.product_amount =
                storeDocumentItemListFormRef.current[index]?.product_amount + 1;
            } else {
              draft.uuid = '';
            }
          });

          try {
            if (!updatedItem?.uuid) {
              const { item, document: storeDocument } =
                await createStoreDocumentItem(
                  updatedItem,
                  storeDocumentRef.current?.uuid,
                );

              EventEmitter.emit(
                EventEmitterItems.update_store_sale_document,
                item,
                storeDocument,
                true,
              );

              addOrUpdate(
                StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
                  editMode: true,
                }),
              );
            } else {
              const { item, document: storeDocument } =
                await editStoreDocumentItem(
                  updatedItem,
                  storeDocumentRef.current?.uuid,
                );

              const value = StoreDocumentMapper.toStoreDocumentItemFormDTO(
                item,
                {
                  editMode: true,
                },
              );

              EventEmitter.emit(
                EventEmitterItems.update_store_sale_document,
                item,
                storeDocument,
              );

              addOrUpdate(value);
            }
          } catch (error: any) {
            const item = StoreDocumentMapper.toStoreDocumentItemFormDTO(
              {
                uuid: value?.keywords,
                product: {
                  uuid: value?.keywords,
                  product_title: value?.keywords,
                  product_barcode: value?.keywords,
                },
              } as StoreDocumentItemModel,
              {
                editMode: true,
                item_sale_type: StoreDocumentItemSaleType.API_ERROR,
                item_error_message: error?.message,
              },
            );
            addOrUpdate(item);
          }
        }
      } else {
        const item = StoreDocumentMapper.toStoreDocumentItemFormDTO(
          {
            uuid: value?.keywords,
            product: {
              uuid: value?.keywords,
              product_title: value?.keywords,
              product_barcode: value?.keywords,
            },
          } as StoreDocumentItemModel,
          {
            editMode: true,
            item_sale_type: StoreDocumentItemSaleType.NOT_FOUND_ERROR,
            item_error_message: 'Product not found',
          },
        );

        addOrUpdate(item);
      }
    },
    [
      alert,
      defaultPriceMarginRef,
      getProductPriceMargin,
      handleCreateStoreDocument,
      setStoreDocument,
      t,
    ],
  );

  const onSearchProductAndAddOrUpdateDocItems = useCallback(
    async (value: Partial<IUseProductListProps>) => {
      const mockItem = StoreDocumentMapper.toStoreDocumentItemFormDTO(
        {
          uuid: value?.keywords,
          item_product_amount: 0,
          product: {
            uuid: value?.keywords,
            product_title: value?.keywords,
          },
        } as StoreDocumentItemModel,
        {
          editMode: true,
          item_sale_type: StoreDocumentItemSaleType.LOADING,
        },
      );

      setStoreDocumentItemListForm((prevState) => {
        const index = prevState?.findIndex(
          (prevItem) =>
            prevItem?.product?.product_barcode === mockItem?.product_uuid,
        );

        let state;

        if (~index) {
          state = produce(prevState, (draft) => {
            (draft as any)[index] = {
              ...draft[index],
              item_sale_type: StoreDocumentItemSaleType.LOADING,
            };
          });
        } else {
          state = [mockItem, ...prevState];
        }

        storeDocumentItemListFormRef.current = state;

        return state;
      });

      const companyUuid = JSON.parse(
        localStorage.getItem('defaultCompanyUuid') || "''",
      );

      const productList = await productListRefresh({
        ...value,
        isThrowError: true,
        companyUuid,
      });

      if (productList && List.isList(productList?.products)) {
        const product = productList.products.first();

        await onAddOrUpdateProduct(value, product);
      }
    },
    [onAddOrUpdateProduct, productListRefresh],
  );

  const concurrentQueue = useRef(
    ConcurrentQueue.channels(1).process(onSearchProductAndAddOrUpdateDocItems),
  );

  const concurrentQueueGUI = useRef(
    SaleGUIConcurrentQueue.channels(1).process((product: ProductModel) =>
      onAddOrUpdateProduct({ store_uuid: storeRef?.current }, product),
    ),
  );

  const handleAddOrUpdateItemGUI = useCallback(
    (product: ProductModel | undefined) => {
      const mockItem = StoreDocumentMapper.toStoreDocumentItemFormDTO(
        {
          uuid: product?.product_title,
          item_product_amount: 0,
          product: {
            uuid: product?.product_title,
            product_title: product?.product_title,
          },
        } as StoreDocumentItemModel,
        {
          editMode: true,
          item_sale_type: StoreDocumentItemSaleType.LOADING,
        },
      );

      setStoreDocumentItemListForm((prevState) => {
        const index = prevState?.findIndex(
          (prevItem) =>
            prevItem?.product?.product_title === mockItem?.product_uuid,
        );

        let state;

        if (~index) {
          state = produce(prevState, (draft) => {
            (draft as any)[index] = {
              ...draft[index],
              item_sale_type: StoreDocumentItemSaleType.LOADING,
            };
          });
        } else {
          state = [mockItem, ...prevState];
        }

        storeDocumentItemListFormRef.current = state;

        return state;
      });

      concurrentQueueGUI.current.add(product);
    },
    [],
  );

  const handleDeleteStoreDocumentItemQueue = useCallback(
    async (value: StoreDocumentItemFormDTO) => {
      try {
        if (!storeDocumentItemErrorTypes.includes(value?.item_sale_type!)) {
          const itemModel = await onDeleteStoreDocumentItem(
            [value?.uuid!],
            storeDocumentRef.current?.uuid,
          );

          const { document } = itemModel || {};

          if (document) {
            EventEmitter.emit(
              EventEmitterItems.delete_store_sale_document,
              [value?.uuid!],
              document,
            );
          }
        }
      } catch (error) {
        notifyError(error);
        setStoreDocumentItemListForm((prevState) => [value, ...prevState]);
      }
    },
    [notifyError, onDeleteStoreDocumentItem],
  );

  const concurrentQueueDelete = useRef(
    ConcurrentQueue.channels(1).process(handleDeleteStoreDocumentItemQueue),
  );

  const concurrentQueueDeleteGUI = useRef(
    SaleGUIConcurrentQueue.channels(1).process(
      handleDeleteStoreDocumentItemQueue,
    ),
  );

  const onDeleteStoreDocumentFromState = useCallback(
    (value: StoreDocumentItemFormDTO) => {
      setStoreDocumentItemListForm((prevState) => {
        const state = prevState.filter(({ uuid }) => uuid !== value?.uuid);

        storeDocumentItemListFormRef.current = state;

        return state;
      });
    },
    [],
  );

  const onAddStoreDocumentFromState = useCallback(
    (value: StoreDocumentItemFormDTO) => {
      setStoreDocumentItemListForm((prevState) => {
        const state = [value, ...prevState];

        storeDocumentItemListFormRef.current = state;

        return state;
      });
    },
    [],
  );

  const handleDeleteStoreDocumentItem = useCallback(
    async (value: StoreDocumentItemFormDTO) => {
      onDeleteStoreDocumentFromState(value);
      concurrentQueueDelete.current.add(value);
    },
    [onDeleteStoreDocumentFromState],
  );

  const handleDeleteStoreDocumentItemGUI = useCallback(
    async (value: StoreDocumentItemFormDTO) => {
      onDeleteStoreDocumentFromState(value);
      concurrentQueueDeleteGUI.current.add(value);
    },
    [onDeleteStoreDocumentFromState],
  );

  const handleSelectDaftDocument = React.useCallback(
    async (documentUuid: string) => {
      handleUpdate({ loadingSaveDraftDocument: true });

      concurrentQueue.current.clear();
      concurrentQueueDelete.current.clear();
      concurrentQueueDeleteGUI.current.clear();
      concurrentQueueGUI.current.clear();
      if (draftIntervalTimer.current) clearInterval(draftIntervalTimer.current);

      if (
        concurrentQueue.current.inProcess ||
        concurrentQueueDelete.current.inProcess ||
        concurrentQueueDeleteGUI.current.inProcess ||
        concurrentQueueGUI.current.inProcess
      ) {
        draftIntervalTimer.current = setInterval(
          handleSelectDaftDocument,
          500,
          documentUuid,
        );
        return;
      }

      const document = await refresh({ documentUuid });

      await storeDocumentItemListRefresh({
        documentUuid,
      });

      if (document) {
        setStoreDocument(document);
      }
      handleUpdate({ loadingSaveDraftDocument: false });
    },
    [handleUpdate, refresh, setStoreDocument, storeDocumentItemListRefresh],
  );

  const handleSaveAsDraftDocument = useCallback(async () => {
    handleUpdate({ loadingSaveDraftDocument: true });

    concurrentQueue.current.clear();
    concurrentQueueDelete.current.clear();
    concurrentQueueDeleteGUI.current.clear();
    concurrentQueueGUI.current.clear();
    if (draftIntervalTimer.current) clearInterval(draftIntervalTimer.current);

    if (
      concurrentQueue.current.inProcess ||
      concurrentQueueDelete.current.inProcess ||
      concurrentQueueDeleteGUI.current.inProcess ||
      concurrentQueueGUI.current.inProcess
    ) {
      draftIntervalTimer.current = setInterval(handleSaveAsDraftDocument, 100);
      return;
    }

    const document = StoreDocumentMapper.toStoreDocumentModel({
      company_store: {
        uuid: documentForm?.doc_store_uuid,
      },
      doc_type: StoreDocumentType.OUT,
    } as StoreDocumentDTO);

    setStoreDocument(document);
    setStoreDocumentItemList(List());
    setStoreDocumentItemListForm([]);

    draftDocumentCallbacks?.current?.resetDraftValue();
    handleUpdate({ loadingSaveDraftDocument: false });
  }, [
    documentForm?.doc_store_uuid,
    handleUpdate,
    setStoreDocument,
    setStoreDocumentItemList,
  ]);

  const handlePaymentStoreDocument = useCallback(
    async (value: StorePaymentDocumentFormDTO) => {
      draftDocumentCallbacks?.current?.onDelete(documentForm?.uuid);

      handleUpdate({ deletedStoreDocumentUuid: documentForm?.uuid });

      await handleSaveAsDraftDocument();

      onPaymentStoreDocument(value, {
        with_document: true,
        with_stats: true,
      })
        .then(() => {
          if (value?.is_print_check) {
            return handlePrintStoreDocumentCheck(documentForm?.uuid);
          }
        })
        .catch(() => handleUpdate({ deletedStoreDocumentUuid: '' }));
    },
    [
      documentForm?.uuid,
      handlePrintStoreDocumentCheck,
      handleSaveAsDraftDocument,
      handleUpdate,
      onPaymentStoreDocument,
    ],
  );

  const handleUpdateStoreDocumentSalePriceMargin = useCallback(
    async (priceMarginUuid: string) => {
      if (storeDocument) {
        try {
          handleUpdate({ loadingSubmit: true });

          const document = await onUpdateStoreDocumentSalePriceMargin(
            storeDocument?.uuid,
            priceMarginUuid,
          );

          if (document) {
            await storeDocumentItemListRefresh({
              documentUuid: storeDocument?.uuid,
            });
          }

          handleUpdate({ loadingSubmit: false });
        } catch (error: any) {
          alert(
            'error',
            t('Store'),
            `${t('An error occurred during edit price margin')} : ${
              error?.message
            }`,
          );
        }
      }
    },
    [
      alert,
      handleUpdate,
      onUpdateStoreDocumentSalePriceMargin,
      storeDocument,
      storeDocumentItemListRefresh,
      t,
    ],
  );

  const handleDisableAutoFocus = useCallback(() => {
    handleUpdate({ autoFocus: false });
  }, [handleUpdate]);

  const handleEnableAutoFocus = useCallback(() => {
    handleUpdate({ autoFocus: true });
  }, [handleUpdate]);

  const handleUpdateSaleCashBox = useCallback(
    (cashBoxUuid: string) => {
      const cashBox = cashBoxList?.find(({ uuid }) => uuid === cashBoxUuid);

      if (cashBox) {
        localStorage.setItem(
          LocalStorageItems.saleCashBoxUuid,
          `${cashBoxUuid}&${
            convertedCashBoxMethodToPayment[cashBox?.box_type]
          }`,
        );

        handleUpdate({
          cashBoxValue: cashBoxUuid,
          cashBoxMethod: convertedCashBoxMethodToPayment[cashBox?.box_type],
        });
      }
    },
    [cashBoxList, handleUpdate],
  );

  const handleChangeKeywords = useCallback(
    (keywords: string) => {
      handleUpdate({ keywords });
    },
    [handleUpdate],
  );

  useEffect(() => {
    if (storeDocument) {
      storeDocumentRef.current = storeDocument;
      draftDocumentCallbacks.current.onUpdate(storeDocument);
    }
  }, [storeDocument]);

  const { contextHolder } = useBeforeUnload({
    isBlock:
      !!storeDocumentItemList?.size ||
      (List.isList(storeDocument?.doc_items)
        ? !!storeDocument?.doc_items?.size
        : !!storeDocument?.doc_items?.length),
    message: 'Form not completed, do you really want to switch?',
    onOk: useCallback(() => {
      concurrentQueue.current.clear();
      concurrentQueueDelete.current.clear();
      concurrentQueueDeleteGUI.current.clear();
      concurrentQueueGUI.current.clear();
    }, [concurrentQueue, concurrentQueueDelete]),
    allowedPaths: [`/${Routes.app}/${Routes.stores}/${Routes.storeSale}`],
  });

  const value = React.useMemo(
    () => ({
      draftDocumentCallbacks,
      document: documentForm,
      documentPayment,
      storeDocumentItemList: storeDocumentItemListForm,
      handleSelectDaftDocument,
      documentItemsLoading:
        documentItemsLoading && !!storeDocumentItemListDocumentUuid,
      storeDocumentItemListLimit,
      storeDocumentItemListTotal,
      handleDeleteStoreDocumentItem,
      handleChangeDocument,
      storeDocumentItemListRefresh,
      loadingSubmit,
      handleAddOrUpdateDocumentItems,
      loadingProductUuid,
      handleSaveAsDraftDocument,
      handlePaymentStoreDocument,
      autoFocus,
      handleDisableAutoFocus,
      handleEnableAutoFocus,
      disabledProductUuid,
      storeDocumentItemListPage,
      handleSelectDaftDocumentFromModal,
      handleUpdateStoreDocumentSalePriceMargin,
      storeDocument,
      concurrentQueue,
      concurrentQueueDelete,
      loadingSaveDraftDocument,
      getProductPriceMargin,
      defaultPriceMargin,
      setDefaultPriceMargin,
      cashBoxList,
      cashBoxListLoading,
      cashBoxListIsLoadingMore,
      cashBoxListLoadingMore,
      handleLoadMoreCashBoxes,
      handleSearchCashBoxes,
      handleUpdateSaleCashBox,
      cashBoxValue,
      keywords,
      handleChangeKeywords,
      concurrentQueueGUI,
      onDeleteStoreDocumentFromState,
      handleAddOrUpdateItemGUI,
      concurrentQueueDeleteGUI,
      handleDeleteStoreDocumentItemGUI,
      onAddStoreDocumentFromState,
      deletedStoreDocumentUuid,
    }),
    [
      documentForm,
      documentPayment,
      storeDocumentItemListForm,
      handleSelectDaftDocument,
      documentItemsLoading,
      storeDocumentItemListDocumentUuid,
      storeDocumentItemListLimit,
      storeDocumentItemListTotal,
      handleDeleteStoreDocumentItem,
      handleChangeDocument,
      storeDocumentItemListRefresh,
      loadingSubmit,
      handleAddOrUpdateDocumentItems,
      loadingProductUuid,
      handleSaveAsDraftDocument,
      handlePaymentStoreDocument,
      autoFocus,
      handleDisableAutoFocus,
      handleEnableAutoFocus,
      disabledProductUuid,
      storeDocumentItemListPage,
      handleSelectDaftDocumentFromModal,
      handleUpdateStoreDocumentSalePriceMargin,
      storeDocument,
      loadingSaveDraftDocument,
      getProductPriceMargin,
      defaultPriceMargin,
      setDefaultPriceMargin,
      cashBoxList,
      cashBoxListLoading,
      cashBoxListIsLoadingMore,
      handleLoadMoreCashBoxes,
      handleSearchCashBoxes,
      cashBoxListLoadingMore,
      handleUpdateSaleCashBox,
      cashBoxValue,
      keywords,
      handleChangeKeywords,
      concurrentQueueGUI,
      onDeleteStoreDocumentFromState,
      handleAddOrUpdateItemGUI,
      concurrentQueueDeleteGUI,
      handleDeleteStoreDocumentItemGUI,
      onAddStoreDocumentFromState,
      deletedStoreDocumentUuid,
    ],
  );

  return (
    <StoreSaleManagerContext.Provider value={value}>
      <div className="sale-manager-container">{children}</div>
      {contextHolder}
    </StoreSaleManagerContext.Provider>
  );
};
