import React, { forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { OverviewBottomView } from '@components/lib/Layout';
import { correctPrice } from '@services/helpers';
import { IPaymentScheduleListStats } from '@services/api/orders';

export interface IPaymentListBottomViewProps {
  stats: IPaymentScheduleListStats;
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

export const PaymentListBottomView = forwardRef(function PaymentListBottomView(
  { stats }: IPaymentListBottomViewProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();
  const theme: any = useTheme();

  return (
    <>
      <div ref={ref}>
        <OverviewBottomView>
          <StyledContainer>
            <StyledTitle fontSize={14}>{`${t('Cash flow')}: `}</StyledTitle>
            <StyledPrice>
              {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                stats?.totalInSum || 0,
              )} / `}
              <StyledPrice $color={theme.colors.error}>
                {correctPrice(stats?.totalOutSum || 0)}
              </StyledPrice>
            </StyledPrice>
          </StyledContainer>
        </OverviewBottomView>
      </div>
    </>
  );
});
