import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { ITemplatesParams } from '@services/api/templates';
import { ITemplateProps } from '@services/helpers';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const TemplateSideWindowLazy = React.lazy(
  () => import('../Show/TemplateSideWindow'),
);

export interface ITemplateEditButtonProps extends IActionButtonProps {
  template: ITemplateProps;
  onSuccess: (value: ITemplatesParams) => Promise<void>;
  onCancel?: () => void;
}

export default function TemplateEditButton({
  children,
  template,
  onSuccess,
  onCancel,
  ...rest
}: ITemplateEditButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit Template')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <TemplateSideWindowLazy
          template={template}
          editMode
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
