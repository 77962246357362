import React, { useCallback, useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useStateMaterialAndExpenseList,
  useStopLoading,
  useStoredCompanies,
} from '@hooks';
import { MaterialAndExpenseMapper, MaterialAndExpenseModel } from '@structure';
import {
  MaterialAndExpenseCreateButton,
  MaterialsAndExpensesDeleteButton,
  MaterialAndExpenseUpdateButton,
} from '../Buttons';
import { MATERIAL_AND_EXPENSE_INITIAL_DATA } from '@services/api/materialAndExpense';
import {
  len,
  lessOrEqualThan,
  textToUpperCase,
  correctPrice,
} from '@services/helpers';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { ORDER_SCHEDULE_STATUS } from '@services/api/orders';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { ListLayout } from '@components/lib/Layout';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { useAcl, useDropdownAlert } from '@contex';
import { StyledDescription } from '@components/lib/Styled';
import styled from 'styled-components';

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
`;

export function MaterialAndExpenseListPage(): JSX.Element {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { manage } = useAcl(({ order }) => order);
  const { alert } = useDropdownAlert();

  const { defaultCompany } = useStoredCompanies();

  const [selectedMaterialAndExpense, setSelectedMaterialAndExpense] = useState<
    MaterialAndExpenseModel[]
  >([]);

  const disabledActions = !(
    location?.state?.scheduleStatus === ORDER_SCHEDULE_STATUS.ACTIVE ||
    location?.state?.scheduleStatus === ORDER_SCHEDULE_STATUS.REQUESTED ||
    location?.state?.scheduleStatus === ORDER_SCHEDULE_STATUS.IN_PROGRESS
  );

  const {
    materialsAndExpenses,
    refresh,
    loading: materialAndExpenseLoading,
    error: materialAndExpenseError,
    total,
    limit: pageSize,
    sumTotal,

    handleUpdateMaterialAndExpense,
    handleCreateMaterialAndExpense,
    handleDeleteMaterialAndExpenses,
  } = useStateMaterialAndExpenseList({
    scheduleUuid: orderId!,
  });

  const loading = useStopLoading({
    loading: materialAndExpenseLoading,
    error: materialAndExpenseError,
    message: 'An error occurred during materials and expenses loading',
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedMaterialAndExpense.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: MaterialAndExpenseModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedMaterialAndExpense(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedMaterialAndExpense, t],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.orders}`,
      breadcrumbName: t('Orders'),
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      breadcrumbName: `#${location?.state?.scheduleNumber || ''}-${t(
        location?.state?.scheduleStatus || '',
      ).toUpperCase()}`,
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.materialsAndExpenses}`,
      breadcrumbName: 'Materials and expenses',
    },
  ]);

  const columns = [
    {
      title: t('name-s'),
      key: 'name',
      render: (materialAndExpense: MaterialAndExpenseModel) =>
        materialAndExpense?.name,
    },
    {
      ellipsis: true,
      title: t('Price'),
      key: 'price',
      render: (materialAndExpense: MaterialAndExpenseModel) =>
        `${defaultCompany?.currency_symbol} ${materialAndExpense?.amount}`,
    },

    {
      ellipsis: true,
      title: t('Number of units'),
      key: 'Number of units',
      render: (materialAndExpense: MaterialAndExpenseModel) =>
        materialAndExpense?.price,
    },

    {
      ellipsis: true,
      title: t('Total'),
      key: 'total',
      render: (materialAndExpense: MaterialAndExpenseModel) =>
        `${defaultCompany?.currency_symbol} ${materialAndExpense?.total}`,
    },

    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (materialAndExpense: MaterialAndExpenseModel) => (
        <TableActionCell>
          <MaterialAndExpenseUpdateButton
            materialAndExpense={MaterialAndExpenseMapper.toMaterialAndExpenseFormDTO(
              materialAndExpense,
            )}
            onSuccess={handleUpdateMaterialAndExpense}
            disabled={disabledActions || !manage}
            materialAndExpenseUuid={location?.state?.materialAndExpenseUuid}
          />
          <MaterialsAndExpensesDeleteButton
            disabled={disabledActions || !manage}
            materialsAndExpenses={[materialAndExpense]}
            onSuccess={handleDeleteMaterialAndExpenses}
          />
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.from ||
        `/${Routes.app}/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      {
        state: location?.state?.prevState
          ? location?.state?.prevState
          : location?.state,
      },
    );
  }, [location?.state, navigate, orderId]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.orders]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        t(RoutesTitle[RoutesType.materialsAndExpenses]),
      )}
      headerTitleDescription={
        <StyledHeaderContainer>
          <StyledDescription>{`№ ${textToUpperCase(
            location?.state?.scheduleNumber,
          )}`}</StyledDescription>
          {Number(sumTotal) > 0 ? (
            <StyledDescription>{`${total} / ${
              defaultCompany?.currency_symbol
            } ${correctPrice(sumTotal)}`}</StyledDescription>
          ) : null}
        </StyledHeaderContainer>
      }
      isHeaderFlickering={!!location?.state?.scheduleNumber}
      headerRight={
        <>
          {selectedMaterialAndExpense.length ? (
            <MaterialsAndExpensesDeleteButton
              disabled={disabledActions || !manage}
              materialsAndExpenses={selectedMaterialAndExpense}
              onSuccess={handleDeleteMaterialAndExpenses}
            />
          ) : (
            <MaterialAndExpenseCreateButton
              onSuccess={handleCreateMaterialAndExpense}
              loading={loading}
              materialAndExpense={{ ...MATERIAL_AND_EXPENSE_INITIAL_DATA }}
              disabled={disabledActions || !manage}
              materialAndExpenseUuid={location?.state?.materialAndExpenseUuid}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        materialsAndExpenses?.size ? null : (
          <Empty description={t('No materials and expenses found')}>
            <MaterialAndExpenseCreateButton
              type="primary"
              onSuccess={handleCreateMaterialAndExpense}
              loading={loading}
              materialAndExpense={{ ...MATERIAL_AND_EXPENSE_INITIAL_DATA }}
              disabled={disabledActions || !manage}
              materialAndExpenseUuid={location?.state?.materialAndExpenseUuid}
              title={t('Create material and expense')}
            />
          </Empty>
        )
      }
      loading={loading && !materialsAndExpenses?.size}>
      <Table<MaterialAndExpenseModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={materialsAndExpenses}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
