import React, { useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Empty } from 'antd';
import styled from 'styled-components';
import { IUseStateTemplateListReturnType } from '@hooks';
import { TemplateList, TemplateCreateButton } from '../../Templates';
import { isThereContent } from '@services/helpers';
import { TEMPLATES_TYPE_LIST, TEMPLATES_TYPE } from '@services/api/templates';
import SearchSelect from '@components/lib/DataDisplay/SearchSelect';

export default withTranslation()(TemplateTab);

const StyledSearchSelect = styled(SearchSelect)`
  min-width: 150px;
`;

export interface ITemplateTabProps
  extends IUseStateTemplateListReturnType,
    WithTranslation {
  disabled?: boolean;
  total: number;
  pageSize: number;
}

function TemplateTab({
  handleCreateTemplate,
  loading,
  t,
  templates,
  refresh,
  limit,
  disabled,
  ...rest
}: ITemplateTabProps): JSX.Element {
  const handleChangeTemplateType = useCallback(
    async (type: TEMPLATES_TYPE): Promise<void> => {
      await refresh({ offset: 0, limit, type });
    },
    [refresh, limit],
  );

  return (
    <TemplateList
      daleyFocus={() => {}}
      underTableContent={[
        isThereContent(templates) ? (
          <StyledSearchSelect
            withoutForm
            key="template__search-select"
            defaultValue={TEMPLATES_TYPE.ALL}
            size="small"
            disable={loading}
            placeholder={t('Search a template type')}
            data={TEMPLATES_TYPE_LIST(t)}
            onChange={handleChangeTemplateType}
          />
        ) : null,
        <TemplateCreateButton
          key="create-template"
          disabled={loading || disabled}
          loading={loading}
          onSuccess={handleCreateTemplate}
        />,
      ]}
      renderEmpty={
        <Empty description={t('There are no templates')}>
          <TemplateCreateButton
            type="primary"
            disabled={loading || disabled}
            loading={loading}
            onSuccess={handleCreateTemplate}
            title={t('Create Template')}
          />
        </Empty>
      }
      limit={limit}
      refresh={refresh}
      templates={templates}
      loading={loading}
      disabled={disabled}
      {...rest}
    />
  );
}
