import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IGroupPaymentListDTO,
  GroupPaymentMapper,
  GroupPaymentModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getGroupPaymentList } from '@services/api/groupPayment';

export interface IUseGroupPaymentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;

  active?: boolean;
}

export interface IUseGroupPaymentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<GroupPaymentModel> | null;
  refresh: (
    value: Partial<IUseGroupPaymentListProps>,
  ) => Promise<List<GroupPaymentModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
}

export function useGroupPaymentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
  }: IUseGroupPaymentListProps = {} as IUseGroupPaymentListProps,
): IUseGroupPaymentListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseGroupPaymentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
    }: Partial<IUseGroupPaymentListProps> = {}): Promise<List<GroupPaymentModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
        });

        const { groups, total } =
          await cancellablePromise<IGroupPaymentListDTO>(
            getGroupPaymentList({ companyUuid, limit, offset, keywords }),
          );

        const groupListModel = GroupPaymentMapper.toGroupPaymentListModel(
          groups,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: groupListModel?.groups,
            total: groupListModel?.total,
            loading: false,
          });

          return groupListModel?.groups;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
  };
}
