import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledDescription } from '../Styled/Text';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
export interface LoadingMoreProps {
  loading: boolean;
  observerCallback: any;
}

const StyledContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledObserverContainer = styled.div`
  position: absolute;
  top: -25px;
  height: 50px;
  background-color: transparent;
  width: 100%;
  z-index: -1;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)``;

const options = {
  rootMargin: '0px',
  threshold: 0.1,
};

export function LoadingMore({
  loading,
  observerCallback,
}: LoadingMoreProps): JSX.Element {
  const { t } = useTranslation();

  const [isOnScreen, setIsOnScreen] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const targetObserveElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(async ([entry]) => {
      if (entry?.isIntersecting) {
        setIsOnScreen(true);
      } else {
        setIsOnScreen(false);
      }
    }, options);
  }, [observerCallback]);

  useEffect(() => {
    if (observer?.current && targetObserveElement?.current) {
      observer.current!.observe(targetObserveElement.current);
    }

    return () => {
      if (observer.current) {
        observer.current!.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isOnScreen) {
      setIsOnScreen(false);
      (async () => {
        await observerCallback();
      })();
    }
  }, [isOnScreen, observerCallback]);

  return (
    <StyledContainer>
      <StyledObserverContainer ref={targetObserveElement} />

      {loading ? (
        <StyledLoadingOutlined />
      ) : (
        <StyledDescription style={{ whiteSpace: 'unset' }}>
          {t('There are no items to download')}
        </StyledDescription>
      )}
    </StyledContainer>
  );
}
