import { PriceTagModel } from '../internal';
import { List } from 'immutable';
import { listToArray } from '@services/helpers';

export class PriceTagFormDTO {
  public items: PriceTagModel[];
  public tag_size: string;
  public total: number;

  constructor(items: List<PriceTagModel>, total: number) {
    this.items = items ? listToArray(items) : [];
    this.tag_size = '60x40';
    this.total = total || 0;
  }
}
