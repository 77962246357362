import { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Col, Input, Row, Form, Alert } from 'antd';
import React, { useMemo, useCallback } from 'react';
import { isListToArray } from '@services/helpers';
import { StyledTitle } from '@components/lib/Styled';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { GROUP_PAYMENT_INITIAL_PARAM } from '@services/api/groupPayment';

import {
  Segmented,
  FormSwitch,
  SearchSelect,
} from '@components/lib/DataDisplay';

import {
  CompanyModel,
  GroupPaymentFlowType,
  GroupPaymentFormDTO,
  PAYMENT_GROUP_TYPE,
} from '@structure';

export interface IGroupPaymentFormProps
  extends Omit<
    IDefaultFormProps<GroupPaymentFormDTO, GroupPaymentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  groupPayment?: GroupPaymentFormDTO;
  companyList: List<CompanyModel>;
}

export default function GroupPaymentForm({
  loading,
  editMode,
  groupPayment = GROUP_PAYMENT_INITIAL_PARAM,
  companyList,
  ...rest
}: IGroupPaymentFormProps) {
  const { alert } = useDropdownAlert();
  const [instance] = Form.useForm();
  const { t } = useTranslation();
  const paymentGroup = Form.useWatch<GroupPaymentFlowType>(
    'group_payment_type',
    instance,
  );

  const isSystemPaymentGroup: boolean = useMemo(
    () => groupPayment?.group_is_system,
    [groupPayment?.group_is_system],
  );

  const isPaymentGroupOUTSelected: boolean = useMemo(
    () => paymentGroup === GroupPaymentFlowType.OUTCOME,
    [paymentGroup],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: groupPayment?.uuid,
    }),
    [groupPayment?.uuid],
  );

  const companies = useMemo(() => companyList, [companyList]);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        'Group payment',
        `${
          editMode
            ? t('An error occurred during edit group payment')
            : t('An error occurred during create group payment')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      initialValues={{
        ...groupPayment,
      }}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      {...rest}>
      {({ loadingSubmit, getFieldValue, setFieldsValue }) => (
        <>
          <Row gutter={20} justify="end">
            {isSystemPaymentGroup && (
              <Col span={24} style={{ paddingBottom: '10px' }}>
                <Alert
                  showIcon
                  type="warning"
                  message={t('The system payment group')}
                  description={t(
                    'Current group created by the system and uses in the system processes. That is why it can not be changed or deleted.',
                  )}
                />
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label={t('Title')}
                name="group_title"
                rules={[
                  {
                    required: true,
                    message: t('Groups title must be specified.'),
                  },
                  {
                    max: 150,
                    message: t(
                      'Title must contain a maximum of 150 characters.',
                    ),
                  },
                ]}
                tooltip={{
                  title: t(
                    'Payment group title: it is how a group will be displayed in the system',
                  ),
                }}>
                <Input
                  data-testid="groups_title"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter the title')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Company')}
                name="group_companies"
                tooltip={{
                  title: t('Company the payment group is part of'),
                }}>
                <SearchSelect
                  disable
                  isMultiple
                  name="group_companies"
                  placeholder={t('Search a Company')}
                  data={isListToArray(companies as any)}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('Payment flow type')}
                name="group_payment_type"
                rules={[
                  {
                    required: true,
                    message: t('Payment flow type must be specified'),
                  },
                ]}
                tooltip={t('The flow which this group is going to manage of')}>
                <Segmented
                  options={PAYMENT_GROUP_TYPE}
                  disabled={loading || loadingSubmit || isSystemPaymentGroup}
                />
              </Form.Item>
            </Col>
            {isPaymentGroupOUTSelected && (
              <Col span={24}>
                <FormSwitch
                  loading={loadingSubmit || loading}
                  disabled={loading || loadingSubmit || isSystemPaymentGroup}
                  name="group_is_cost_maker"
                  label={
                    <StyledTitle>
                      {t('Can affect the product price')}
                    </StyledTitle>
                  }
                  tooltipTitle={t(
                    'The payments within this group could formulate the self-price for the related product or service',
                  )}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                />
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label={t('Description')}
                name="group_description"
                rules={[
                  {
                    required: true,
                    message: t('Group description must be specified.'),
                  },
                ]}
                tooltip={{
                  title: t('Description of the payment group'),
                }}>
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  data-testid="group-payment-desc"
                  disabled={loading || loadingSubmit || isSystemPaymentGroup}
                  placeholder={t('Enter the group payment description')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
