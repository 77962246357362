import { Record } from 'immutable';
import {
  ClientOperationDTO,
  ClientOperationType,
  IStoreDocumentDTOProps,
  StoreDocumentDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IClientOperationModelReturnType {
  uuid: string;
  balance_before: string;
  created_at: string;
  updated_at: string;
  operation_comment: string;
  operation_sum: string;
  operation_triggerable_type: ClientOperationType | undefined;
  operation_triggerable_object: StoreDocumentModel;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class ClientOperationModel extends Record<IClientOperationModelReturnType>(
  {
    uuid: '',
    balance_before: '',
    created_at: '',
    updated_at: '',
    operation_comment: '',
    operation_sum: '',
    operation_triggerable_type: undefined,
    operation_triggerable_object: {} as StoreDocumentModel,
  },
) {
  constructor(
    props: ClientOperationDTO = {} as ClientOperationDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientOperationModelReturnType = {
      ...props,
      operation_triggerable_object: StoreDocumentMapper.toStoreDocumentModel(
        props?.operation_triggerable_object as StoreDocumentDTO,
      ),
    };

    super(options);
  }
}
