import { Record } from 'immutable';
import { isThereContent } from '@services/helpers';
import {
  GENDER,
  MomentDate,
  IProfileSubscriptionProps,
  CompanyProps,
} from '../helpers/index';
import { ProfileSubscription, Company } from '.';

export enum USER_STATUS_TEST {
  ENABLE = 'enabled',
  DISABLE = 'disabled',
}

export interface IUserProps {
  uuid: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  address: string;
  birthday: string;
  picture_url: string;
  status_text: USER_STATUS_TEST;
  card_number: string;
  login: string;
  password: string;
  confirm_password: string;
  created_at?: string;
  updated_at?: string;
  phone_approved: number;
  email_approved: number;
  status: number;
  subscription_discount: number;
  goods_discount: number;
  gender: GENDER;
  profile_subscriptions: IProfileSubscriptionProps[];
  default_company?: CompanyProps;
}

export interface IUserReturnType
  extends Omit<IUserProps, 'profile_subscriptions' | 'default_company'> {
  profile_subscriptions: ProfileSubscription[];
  default_company: Company;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class User extends Record<IUserReturnType>({
  uuid: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  email: '',
  address: '',
  birthday: '',
  picture_url: '',
  status_text: USER_STATUS_TEST.ENABLE,
  card_number: '',
  login: '',
  password: '',
  confirm_password: '',
  subscription_discount: 0,
  phone_approved: 0,
  email_approved: 0,
  status: 0,
  goods_discount: 0,
  gender: GENDER.MALE,
  profile_subscriptions: [],
  default_company: {} as Company,
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
}) {
  constructor(
    {
      profile_subscriptions,
      default_company,
      ...props
    }: IUserProps = {} as IUserProps,
  ) {
    const options: any = {};

    if (Array.isArray(profile_subscriptions)) {
      options.profile_subscriptions = profile_subscriptions.map(
        (profile_subscription) => new ProfileSubscription(profile_subscription),
      );
    }

    if (isThereContent(default_company)) {
      options.default_company = new Company(default_company);
    }

    super({ ...props, ...options });
  }

  /**
   * @desc Повне Ім'я
   * @return {String}
   */

  get fullName(): string {
    return `${this.last_name} ${this.first_name}`;
  }
}
