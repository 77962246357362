import React, { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps } from 'antd';
import { useModal } from '@components/lib/libV2/hooks';
import { RewardFormDTO, RewardPaymentFormDTO } from '@structure';
import {
  Button,
  IAddButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import {
  MinusOutlined,
  ArrowUpOutlined,
  LoadingOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { IUseStateRewardReturnType } from '@hooks';

const RewardSideWindowLazy = React.lazy(
  () => import('../Show/RewardSideWindow'),
);
const RewardPaymentSideWindowLazy = React.lazy(
  () => import('../Show/RewardPaymentSideWindow'),
);

export interface IRewardCreateButtonProps
  extends IAddButtonProps,
    Pick<
      IUseStateRewardReturnType,
      | 'handleCreateRewardAwardRecord'
      | 'handleCreateRewardFineRecord'
      | 'handleCreateRewardPayment'
    > {
  onCancel?: () => void;
  reward: RewardFormDTO;
  payment: RewardPaymentFormDTO;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
  $withoutPadding: boolean;
}>`
  cursor: pointer;

  padding: 15px;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ $withoutPadding }) =>
    $withoutPadding &&
    css`
      padding: 0;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

enum RecordType {
  Fine,
  Premium,
}

const StyledArrowUpOutlined = styled(ArrowUpOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;
const StyledMinusOutlined = styled(MinusOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;
const StyledPlusOutlined = styled(PlusOutlined)`
  color: ${({ theme }) => theme.colors.link};
`;

export function RewardCreateButton({
  loading = false,
  onCancel,
  reward,
  payment,
  title,
  handleCreateRewardFineRecord,
  handleCreateRewardAwardRecord,
  handleCreateRewardPayment,
  ...rest
}: IRewardCreateButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [formTitle, setFormTitle] = useState('');
  const [recordType, setRecordType] = useState<RecordType>(RecordType.Premium);

  const {
    handleCancel: paymentHandleCancel,
    handleOnInit: paymentHandleOnInit,
    handleSuccess: paymentHandleSuccess,
    visible: paymentVisible,
  } = useModal({
    onCancel,
    onSuccess: handleCreateRewardPayment,
  });

  const {
    handleCancel: recordHandleCancel,
    handleOnInit: recordHandleOnInit,
    handleSuccess: recordHandleSuccess,
    visible: recordVisible,
  } = useModal({
    onCancel,
    onSuccess:
      recordType === RecordType.Premium
        ? handleCreateRewardAwardRecord
        : handleCreateRewardFineRecord,
  });

  const handleAwardRecord = useCallback(() => {
    setFormTitle(t('Add premium'));
    setRecordType(RecordType.Premium);
    recordHandleOnInit();
  }, [recordHandleOnInit, t]);

  const handleFineRecord = useCallback(() => {
    setFormTitle(t('Add a fine'));
    setRecordType(RecordType.Fine);
    recordHandleOnInit();
  }, [recordHandleOnInit, t]);

  const items: MenuProps['items'] = [
    {
      key: 'payment',
      label: <StyledTitle>{t('Add payment')}</StyledTitle>,

      icon: <StyledArrowUpOutlined />,
      onClick: paymentHandleOnInit,
    },
    {
      key: 'award',
      label: (
        <StyledTitle $color={theme.colors.link}>{t('Add premium')}</StyledTitle>
      ),

      icon: <StyledPlusOutlined />,
      onClick: handleAwardRecord,
    },
    {
      key: 'fine',
      label: (
        <StyledTitle $color={theme.colors.error}>{t('Add a fine')}</StyledTitle>
      ),
      onClick: handleFineRecord,
      icon: <StyledMinusOutlined />,
    },
  ];

  return (
    <>
      <StyledDropdown
        $withoutPadding={!!title}
        trigger={['hover']}
        disabled={!!loading}
        menu={{ items }}
        placement="bottomLeft">
        {loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : title ? (
          <Button type="primary">
            <StyledTitle>{title}</StyledTitle>
            <DownOutlined color={theme.colors.primary} />
          </Button>
        ) : (
          <StyledIconContainer>
            <PlusOutlined />
            <DownOutlined color={theme.colors.primary} />
          </StyledIconContainer>
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardSideWindowLazy
          loading={loading as boolean}
          onSuccess={recordHandleSuccess}
          onCancel={recordHandleCancel}
          visible={recordVisible}
          reward={reward}
          title={formTitle}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardPaymentSideWindowLazy
          loading={loading as boolean}
          onSuccess={paymentHandleSuccess}
          onCancel={paymentHandleCancel}
          visible={paymentVisible}
          payment={payment}
        />
      </Suspense>
    </>
  );
}
