import React, { useCallback } from 'react';
import { ICalendarManagerProps } from '@contex';
import { ScheduleCalendarModel } from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { CompanyCalendarPopover, CompanyScheduleDetail } from '../../Dashboard';
import { SingleLayout } from '@components/lib/Layout';
import {
  useDefaultCalendarScheduleProps,
  useCompanyScheduleList,
  useStoredCompanies,
} from '@hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { textToUpperCase } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '@components/lib/libV2/hooks';

export interface ICompanyCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export function CompanyCalendarPage({
  children,
}: ICompanyCalendarPageProps): JSX.Element {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies({ companyUuid: companyId });

  const defaultCalendarProps = useDefaultCalendarScheduleProps();

  const {
    list: companySchedules,
    refresh: refreshCompanySchedules,
    loading,
  } = useCompanyScheduleList({
    companyUuid: companyId!,
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.app}/${Routes.companies}/${companyId}/${Routes.schedules}`,
      breadcrumbName: t('Calendar'),
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${companyId}`);
  }, [companyId, navigate]);

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.companies]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        defaultCompany?.title || t(RoutesTitle[RoutesType.settings]),
      )}
      routes={routes}>
      {children({
        ...defaultCalendarProps,
        localStorageName: 'dashboardCalendarDate',
        schedules: companySchedules,
        onRefresh: (rest) =>
          refreshCompanySchedules({
            ...rest,
            companyUuid: companyId,
          }),
        SchedulePopover: CompanyCalendarPopover,
        scheduleDetails: (children, schedule) => (
          // @ts-ignore
          <CompanyScheduleDetail schedule={schedule}>
            {({ routes, navigateGoBack }) =>
              children({ routes, navigateGoBack })
            }
          </CompanyScheduleDetail>
        ),
        customDayTimeRange: location?.state?.timeRange,
        loading,
      })}
    </SingleLayout>
  );
}
