import { Map } from 'immutable';
import { APP_STATE, SERVICE } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ServiceModel } from '@structure';

interface IServiceListState {
  service: ServiceModel | null;
  cachedServiceList: Map<string, ServiceModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IServiceListState, 'service'> {
  type: SERVICE.SET_SERVICE;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateService {
  type: SERVICE.UPDATE_SERVICE;
  service: ServiceModel;
}

interface LoadingAction {
  type: SERVICE.LOADING_SERVICE;
}

interface ResetAction {
  type: SERVICE.RESET_SERVICE;
}

interface ErrorAction extends Pick<IServiceListState, 'error'> {
  type: SERVICE.ERROR_SERVICE;
}

interface Handlers {
  [SERVICE.SET_SERVICE]: (
    state: IServiceListState,
    action: SetAction,
  ) => IServiceListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IServiceListState,
    action: SetInitialStateAction,
  ) => IServiceListState;

  [SERVICE.RESET_SERVICE]: (
    state: IServiceListState,
    action: ResetAction,
  ) => IServiceListState;

  [SERVICE.UPDATE_SERVICE]: (
    state: IServiceListState,
    action: UpdateService,
  ) => IServiceListState;

  [SERVICE.ERROR_SERVICE]: (
    state: IServiceListState,
    value: ErrorAction,
  ) => IServiceListState;

  [SERVICE.LOADING_SERVICE]: (
    state: IServiceListState,
    value?: LoadingAction,
  ) => IServiceListState;
  DEFAULT: (state: IServiceListState) => IServiceListState;
}

const initState: IServiceListState = {
  service: null,
  cachedServiceList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [SERVICE.SET_SERVICE]: (state, { service }) => ({
    ...state,
    ...{
      service,
      cachedServiceList: service
        ? state.cachedServiceList.set(service?.uuid, service)
        : state.cachedServiceList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [SERVICE.RESET_SERVICE]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
      service: null,
    },
  }),

  [SERVICE.UPDATE_SERVICE]: (state, { service }) => ({
    ...state,
    ...{
      service,
      status: REDUX_STATUS.SUCCEEDED,
      cachedServiceList: state.cachedServiceList.update(
        service?.uuid,
        () => service,
      ),
    },
  }),

  [SERVICE.ERROR_SERVICE]: (
    state: IServiceListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [SERVICE.LOADING_SERVICE]: (state: IServiceListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IServiceListState) => state,
};

export default function Service(
  state: any = initState,
  action: any,
): IServiceListState {
  const handler = handlers[action.type as SERVICE] || handlers.DEFAULT;
  return handler(state, action);
}
