import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Select } from 'antd';
import { listToArray } from '@services/helpers';
import {
  IUseStatePriceMarginListReturnType,
  IUseStateStoreListReturnType,
} from '@hooks';
import { useTranslation } from 'react-i18next';
import { PriceMarginModel } from '@structure';

export interface ILeftoversPriceMarginViewProps
  extends Pick<
    IUseStatePriceMarginListReturnType,
    'priceMarginList' | 'handleSearchPriceMargins'
  > {
  loading: boolean;
  onPriceMarginChange: (priceMargin: PriceMarginModel) => void;
  priceMarginListLoading: boolean;
  priceMarginUuid: string;
}

const StyledSelect = styled(Select)<{ disabled: boolean }>`
  width: 300px;
  
  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

export function LeftoversPriceMarginView({
  loading,
  priceMarginList,
  priceMarginListLoading,
  handleSearchPriceMargins,
  onPriceMarginChange,
  priceMarginUuid,
}: ILeftoversPriceMarginViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);

  const once = useRef(false);

  const handlePriceMarginChange = useCallback(
    async (priceMarginUuid: any = '') => {
      setValue(priceMarginUuid || undefined);

      const priceMargin = priceMarginList?.find(
        ({ uuid }) => uuid === priceMarginUuid,
      );

      if (priceMargin) {
        onPriceMarginChange(priceMargin);
      }
    },
    [onPriceMarginChange, priceMarginList],
  );

  useEffect(() => {
    if (!once.current) {
      if (priceMarginUuid) {
        setValue(priceMarginUuid);
      }
    }
  }, [priceMarginUuid]);

  return (
    <StyledSelect
      showSearch
      loading={loading || priceMarginListLoading}
      disabled={priceMarginListLoading || loading}
      onSearch={(keywords) => handleSearchPriceMargins({ keywords })}
      placeholder={t('Select a price margin')}
      options={listToArray(priceMarginList as any) as any}
      fieldNames={{ label: 'price_name', value: 'uuid' }}
      onChange={handlePriceMarginChange}
      value={value}
      size="middle"
    />
  );
}
