import React, { useCallback, useEffect, useState } from 'react';
import { useCalendar } from '@contex';
import CalendarWeekDayHeader from './CalendarWeekDayHeader';
import CalendarRow from './CalendarRow';
import {
  getWeeksOfMonthDate,
  slice,
  greaterThan,
  len,
  isThereContent,
  getHourFromTime,
  head,
  last,
  ITimeInDay,
  buildHourInDay,
} from '@services/helpers';
import CalendarWrapper from './CalendarWrapper';

export default function CalendarWeek(): JSX.Element {
  const {
    monthDates,
    selectedDate,
    hourInDay,
    handleChooseDay,
    currentMonthLastDate,
  } = useCalendar();
  const [selectWeek, setSelectWeek] = useState([] as any);
  const [localHourInDay, setLocalHourInDay] = useState<ITimeInDay[] | null>(
    null,
  );

  useEffect(() => {
    if (monthDates) {
      const weekNumber = getWeeksOfMonthDate(selectedDate, monthDates);

      const selectWeekFormat = slice(
        weekNumber,
        weekNumber + 7,
      )([...monthDates]);

      if (greaterThan(len(selectWeekFormat), 0)) {
        setLocalHourInDay(hourInDay);
        const eventsTimeArray = selectWeekFormat
          .flatMap((week) => {
            return Object.keys(week?.events || {});
          })
          .map((time) => getHourFromTime(time));

        if (greaterThan(len(eventsTimeArray), 0)) {
          const firstTime = Math.min(...eventsTimeArray);
          const lastTime = Math.max(...eventsTimeArray);
          const fistHourInDay = getHourFromTime(head(hourInDay)?.time);
          const lastHourInDay = getHourFromTime(last(hourInDay)?.time);

          if (firstTime < fistHourInDay || lastTime > lastHourInDay) {
            const updatedHourInDay = buildHourInDay({
              start: firstTime < fistHourInDay ? firstTime : fistHourInDay,
              end: lastTime > lastHourInDay ? lastTime : lastHourInDay,
            });
            setLocalHourInDay(updatedHourInDay);
          }
        }

        setSelectWeek(selectWeekFormat);
      }
    }
  }, [currentMonthLastDate, hourInDay, monthDates, selectedDate]);

  const handleChoose = useCallback(
    (date: Date | string): void => {
      handleChooseDay({ date });
    },
    [handleChooseDay],
  );

  return (
    <CalendarWrapper>
      <CalendarWeekDayHeader
        dates={selectWeek}
        handleChooseDate={handleChoose}
      />
      {isThereContent(selectWeek) &&
        localHourInDay &&
        localHourInDay.map((value) => (
          <CalendarRow
            showTimeLimit
            dates={selectWeek}
            key={value?.time}
            hourInDay={value}
            showTimeLine
          />
        ))}
    </CalendarWrapper>
  );
}
