import { List } from 'immutable';
import { isListToArray } from '@services/helpers';
import {
  CompanyModel,
  GroupPaymentModel,
  GroupPaymentFlowType,
} from '../internal';

export class GroupPaymentFormDTO {
  public uuid: string;

  public group_title: string;

  public group_payment_type: GroupPaymentFlowType;

  public group_is_cost_maker: boolean;

  public group_is_system: boolean;

  public group_description: string;

  public group_companies: CompanyModel[];

  constructor(props?: GroupPaymentModel & { defaultCompanyUuid?: string }) {
    this.uuid = props?.uuid || '';
    this.group_title = props?.title || '';
    this.group_is_system = props?.is_system || false;
    this.group_is_cost_maker = props?.is_cost_maker || false;
    this.group_payment_type =
      props?.payment_type || GroupPaymentFlowType.INCOME;
    this.group_description = props?.description || '';
    this.group_companies = props?.defaultCompanyUuid
      ? ([props?.defaultCompanyUuid as string] as any)
      : this.getCompaniesUuid((props?.companies as any) || []);
  }

  private getCompaniesUuid(companies: List<CompanyModel>): string[] {
    return isListToArray(companies.map(({ uuid }) => uuid));
  }
}
