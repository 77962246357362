import { correctPrice } from '@services/helpers';

export enum ModuleInputParameterFiledType {
  Button = 'button',
}

export enum ModuleInputParameterActionType {
  AlphaName = 'mdl_sms_mng_alfa',
}

export interface IModuleInputParams {
  text: string;
  hint: string;
}

export interface IModuleInputParameterDTOProps {
  field: ModuleInputParameterFiledType;
  params: IModuleInputParams;
  action: ModuleInputParameterActionType;
  price: string;
}

export class ModuleInputParameterDTO {
  public field: ModuleInputParameterFiledType;
  public params: IModuleInputParams;
  public action: ModuleInputParameterActionType;
  public price: string;

  constructor(props: IModuleInputParameterDTOProps) {
    this.field = props?.field || ModuleInputParameterFiledType.Button;
    this.params = props?.params || {
      text: '',
      hint: '',
    };
    this.action = props?.action || ModuleInputParameterActionType.AlphaName;
    this.price = correctPrice(props?.price || 0);
  }
}
