import * as React from 'react';
import useDataState from './useDataState';
import useTemplateList, {
  IUseTemplateListReturnType,
  IUseTemplateListProps,
} from './useTemplateList';
import {
  createTemplate,
  updateTemplate,
  deleteTemplate,
  ITemplatesParams,
  TEMPLATES_TYPE,
} from '@services/api/templates';
import { Template } from '@services/models';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { IGroupPaymentProps } from './useStateGroupPaymentList';

export interface IUseStateTemplateListProps extends IUseTemplateListProps {}

export interface ITemplateTypes {
  title: TEMPLATES_TYPE;
  uuid: TEMPLATES_TYPE;
}

export interface ITemplateProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateTemplateListReturnType
  extends Omit<IUseTemplateListReturnType, 'list'> {
  templates: Template[];
  handleCreateTemplate: (value: ITemplatesParams) => Promise<void>;
  handleUpdateTemplate: (value: ITemplatesParams) => Promise<void>;
  handleDeleteTemplates: (ids: string[]) => Promise<void>;
  handleSearchTemplates: (value: ITemplateProps) => Promise<void>;
}

export default function useStateTemplateList({
  companyUuid,
  ...rest
}: IUseStateTemplateListProps): IUseStateTemplateListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    list: templates,
    loading,
    refresh,
    ...templatesParams
  } = useTemplateList({
    ...rest,
    companyUuid,
  });

  const {
    data: stateTemplates,
    loading: loadingListTemplates,
    handleCreate,
    handleUpdate,
    handleDelete,
    setData,
  } = useDataState<Template>(templates);

  const handleCreateTemplate = React.useCallback(
    async (value: ITemplatesParams): Promise<void> => {
      const template = await createTemplate({
        ...value,
        companyUuid,
      });

      handleCreate(template);

      alert(
        'success',
        t('Mailing template'),
        t('Mailing template created success'),
      );
    },
    [companyUuid, handleCreate, alert, t],
  );

  const handleDeleteTemplates = React.useCallback(
    async (ids: string[]): Promise<void> => {
      try {
        const { success } = await deleteTemplate(ids);

        if (success) {
          handleDelete(ids, 'template_uuid');
        }
        alert(
          'success',
          t('Mailing template'),
          t('Mailing template delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Mailing template'),
          `${t('An error occurred during delete mailing templates')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, t],
  );

  const handleUpdateTemplate = React.useCallback(
    async (value: ITemplatesParams): Promise<void> => {
      const template = await updateTemplate({
        ...value,
      });

      handleUpdate(template, 'template_uuid');
      alert(
        'success',
        t('Mailing template'),
        t('Mailing template edited success'),
      );
    },
    [alert, handleUpdate, t],
  );

  const handleSearchTemplates = React.useCallback(
    async ({
      keywords,
      showLoading = true,
      limit = 10,
    }: IGroupPaymentProps) => {
      const counterparty = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (counterparty) {
        setData(counterparty);
      }
    },
    [refresh, setData],
  );

  return {
    ...templatesParams,
    loading: loading || loadingListTemplates,
    templates: stateTemplates,
    handleCreateTemplate,
    handleDeleteTemplates,
    handleUpdateTemplate,
    refresh,
    handleSearchTemplates,
  };
}
