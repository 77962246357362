import React from 'react';
import { StyledTitle } from '@components/lib/Styled';
import { Link } from '@components/lib/DataDisplay';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { MdOutlineCreditCard } from 'react-icons/md';
import { IOrderTitleViewProps, OrderTitleView } from './OrderTitleView';

export interface IOrderHeaderProps extends IOrderTitleViewProps {}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  width: fit-content;
  height: fit-content;
  margin-left: auto;
  padding: 10px;

  background-color: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.disabled};
  border-radius: 7px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.link};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  padding-left: 24px;
  padding-right: 24px;
`;

export function OrderHeader({
  schedule,
  allowedStatusesTo,
  loading,
  handleChangeScheduleStatus,
  handleCreateSchedulePrepayment,
  handleCloseSchedule,
}: IOrderHeaderProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  return (
    <StyledContainer>
      <OrderTitleView
        schedule={schedule}
        allowedStatusesTo={allowedStatusesTo}
        loading={loading}
        handleChangeScheduleStatus={handleChangeScheduleStatus}
        handleCreateSchedulePrepayment={handleCreateSchedulePrepayment}
        handleCloseSchedule={handleCloseSchedule}
      />
      <StyledLink
        withoutRight
        to={`${Routes.payments}/${Routes.schedules}`}
        tooltip={t('Go to payments details page')}
        state={{ goBack: location.pathname }}>
        <MdOutlineCreditCard size={18} />
        <StyledTitle fontSize={16}>{t('Payments')}</StyledTitle>
      </StyledLink>
    </StyledContainer>
  );
}
