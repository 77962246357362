import { Record } from 'immutable';
import {
  ServiceModel,
  RelatedServiceDTO,
  ServiceDTO,
  ServiceMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IRelatedServiceModelReturnType {
  uuid: string;
  schedule_service_quantity: string;
  schedule_service_price: string;
  schedule_service_price_total: string;
  schedule_comment: string;
  created_at: string;
  updated_at: string;
  service: ServiceDTO | ServiceModel;
}

export class RelatedServiceModel extends Record<IRelatedServiceModelReturnType>(
  {
    uuid: '',
    schedule_service_quantity: '',
    schedule_service_price: '',
    schedule_service_price_total: '',
    schedule_comment: '',
    created_at: '',
    updated_at: '',
    service: {} as ServiceModel,
  },
) {
  constructor(
    props: RelatedServiceDTO = {} as RelatedServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IRelatedServiceModelReturnType = {
      ...props,

      service:
        withoutRecursion.indexOf(WithoutRecursion.service) !== -1
          ? props?.service
          : ServiceMapper.toServiceModel(props?.service, [
              WithoutRecursion.service,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get serviceModel(): ServiceModel {
    return this.service instanceof ServiceModel
      ? this.service
      : ServiceMapper.toServiceModel(this.service);
  }
}
