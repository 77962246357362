import React, { useCallback, useMemo } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import {
  useStateEmployeeList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '@hooks';
import {
  EmployeeModel,
  PAYMENT_METHOD,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
} from '@structure';
import { StorePaymentDocumentFormFields } from './StorePaymentDocumentFormFields';
import { Form } from 'antd';

export interface IStorePaymentDocumentFormProps
  extends Pick<
    IDefaultFormProps<StorePaymentDocumentFormDTO, StorePaymentDocumentFormDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: StorePaymentDocumentFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  payment: StorePaymentDocumentFormDTO;
}

/**
 * @desc Створенння співробітника
 * */
export function StorePaymentDocumentForm({
  loading,
  editMode,
  payment,
  onCancel,
  ...rest
}: IStorePaymentDocumentFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();

  const paymentMethod = Form.useWatch('payment_method', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
      payment_post_action: payment?.payment_post_action,
    }),
    [payment?.payment_post_action, payment?.uuid],
  );

  const {
    employees,
    loading: employeeLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any): void => {
      alert(
        'error',
        t('Payment'),
        `${t('An error occurred during create document payment')} : ${
          apiError?.message
        }`,
      );
    },
    [t, alert],
  );

  const cashierUuid =
    payment?.payment_manager_uuid instanceof EmployeeModel
      ? payment?.payment_manager_uuid?.uuid
      : payment?.payment_manager_uuid;

  return (
    <DefaultForm
      instance={instance}
      initialValues={{
        ...payment,
      }}
      onCancel={onCancel}
      withContext
      submitButtonText={
        payment?.doc_type === StoreDocumentType.IN &&
        paymentMethod === PAYMENT_METHOD.Account
          ? t('Save')
          : t('Pay')
      }
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      <StorePaymentDocumentFormFields
        loading={!!loading}
        employees={employees}
        employeesLoading={employeeLoading}
        handleSearchEmployees={handleSearchEmployees}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
        cashierUuid={cashierUuid}
        defaultComment={
          payment?.doc_type === StoreDocumentType.IN
            ? payment?.payment_comment
            : ''
        }
      />
    </DefaultForm>
  );
}
