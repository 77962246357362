import * as React from 'react';
import { Alert, Col, Form, Row } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { EmployeeAccessManagementFormDTO } from '@structure';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { EMPLOYEE_AVAILABILITY_INITIAL_DATA } from '@services/api/employee';
import { FormSwitch } from '@components/lib/DataDisplay';
import styled from 'styled-components';

export interface IEmployeeAccessManagementFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onError?: () => Promise<void>;
  employee?: EmployeeAccessManagementFormDTO;
  onSuccess: (value: EmployeeAccessManagementFormDTO) => Promise<void>;
}

const StyledSwitchContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;

  & > :first-child {
    margin-right: 10px;
  }
`;

export default function EmployeeAvailabilityManagementForm({
  loading,
  editMode,
  employee = EMPLOYEE_AVAILABILITY_INITIAL_DATA,
  ...rest
}: IEmployeeAccessManagementFormProps) {
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();
  const [instance] = Form.useForm();
  const additionalValues = useMemo(
    () => ({
      uuid: employee?.uuid,
    }),
    [employee?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Employee'),
        `${t(
          'An error occurred during the employee availability management',
        )} : ${apiError?.message}`,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm
      instance={instance}
      initialValues={{
        ...employee,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      submitButtonText={t('Set availability')}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, getFieldValue, setFieldsValue }) => (
        <>
          <Row gutter={20}>
            <Col span={24} style={{ marginBottom: 10 }}>
              <Alert
                showIcon
                type="info"
                message={t('Availability')}
                description={t(
                  'This option manages ability for the employee to get access to the organization and organization resources',
                )}
              />
            </Col>
          </Row>

          <FormSwitch
            loading={loadingSubmit || loading}
            disabled={loadingSubmit}
            name="status"
            label={t('Ability to be used in the system')}
            tooltipTitle={t(
              'This option manages an ability to be used in orders and other company processes',
            )}
            value={!!employee?.status}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />

          <FormSwitch
            loading={loadingSubmit || loading}
            disabled={loadingSubmit}
            name="isAuthAllowed"
            label={t('Ability to authorize in the system')}
            tooltipTitle={t(
              'This option manages an ability to authorize in the system',
            )}
            value={!!employee?.isAuthAllowed}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />
        </>
      )}
    </DefaultForm>
  );
}
