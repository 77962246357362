import React from 'react';
import { Text } from '../Format';
import { StyledIconButton } from '../Styled/Buttons';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { ButtonProps, Tooltip, Popconfirm } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';

export interface IDeleteButtonProps
  extends ButtonProps,
    Pick<PopconfirmProps, 'onConfirm' | 'cancelButtonProps' | 'okButtonProps'> {
  title?: string;
  tooltipTitle?: string;
  confirmTitle?: React.ReactNode | RenderFunction;
  cancelButtonText?: React.ReactNode;
  confirmButtonText?: React.ReactNode;
  children?: React.ReactNode;
  placement?: PopconfirmProps['placement'];
}

const StyledDeleteButton = styled(StyledIconButton)<{
  $useCustomStyle: boolean;
}>`
  ${({ $useCustomStyle }) =>
    !$useCustomStyle &&
    css`
      &&&&& {
        background-color: transparent;
        z-index: 2;
      }
    `}
`;

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

export function DeleteButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  confirmTitle,
  onConfirm,
  okButtonProps,
  cancelButtonProps,
  cancelButtonText,
  confirmButtonText,
  danger,
  loading,
  children,
  placement = 'leftTop',
  disabled,
  ...props
}: IDeleteButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <Popconfirm
        placement={placement}
        okType={'danger'}
        okText={confirmButtonText}
        cancelText={cancelButtonText}
        title={confirmTitle}
        onConfirm={onConfirm}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}>
        <StyledDeleteButton
          danger
          type={type}
          $useCustomStyle={type !== 'text'}
          style={style}
          loading={!!(title && loading)}
          disabled={disabled || !!(title && loading)}
          {...props}>
          {title ? (
            <Text>{title}</Text>
          ) : loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            children || <DeleteOutlined color={theme.colors.error} />
          )}
        </StyledDeleteButton>
      </Popconfirm>
    </StyledTooltip>
  );
}
