import { correctPrice } from '@services/helpers';

export enum CompanyOrderBonusId {
  OrderCreation = 'order_creation',
  OrderCompletion = 'order_completion',
  OrderManagement = 'order_management',
  OrderExecution = 'order_execution',
  OrderMaterial = 'order_material',
}

export enum CompanyOrderBonusGroupType {
  Employee = 'employee',
  Service = 'service',
}

export enum CompanyOrderBonusType {
  Fixed = 'fixed',
  Percent = 'percent',
}

export interface ICompanyOrderBonusDTOProps {
  id: CompanyOrderBonusId | string;
  title: string;
  type: CompanyOrderBonusType;
  value: string;
  groups: CompanyOrderBonusGroupType[];
  description: string;
}

export class CompanyOrderBonusDTO {
  public id: CompanyOrderBonusId | string;
  public title: string;
  public type: CompanyOrderBonusType;
  public value: string;
  public groups: CompanyOrderBonusGroupType[];

  public description: string;

  constructor(props?: ICompanyOrderBonusDTOProps) {
    this.id = props?.id || '';
    this.title = props?.title || '';
    this.value = correctPrice(props?.value || 0);
    this.type = props?.type || CompanyOrderBonusType.Percent;
    this.groups = props?.groups || [];
    this.description = props?.description || '';
  }
}
