import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { ABONEMENT_GROUP_INITIAL_PARAM } from '@services/api/abonementGroup';
import { AbonementGroupFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { TariffModelGroupFields } from './TariffModelGroupFields';
import { useStateEmployeeList, useStoredCompanies } from '@hooks';
import { split } from '@services/helpers';

export interface ITariffModelGroupFormProps
  extends Omit<
    IDefaultFormProps<AbonementGroupFormDTO, AbonementGroupFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  group?: AbonementGroupFormDTO;
}

export function TariffModelGroupForm({
  loading,
  editMode,
  group = ABONEMENT_GROUP_INITIAL_PARAM,
  onCancel,
  ...rest
}: ITariffModelGroupFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const weekdays = useMemo(
    () => ({
      weekdays: split(',', group?.schedule),
    }),
    [group?.schedule],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: group?.uuid,
    }),
    [group?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Tariff model group'),
        `${
          editMode
            ? t('An error occurred during edit tariff model group')
            : t('An error occurred during create tariff model group')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...group,
        ...weekdays,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <TariffModelGroupFields
          employees={employees}
          employeesLoading={employeesLoading}
          handleSearchEmployees={handleSearchEmployees}
        />
      )}
    </DefaultForm>
  );
}
