import { Moment } from 'moment';
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from '../core/api';
import { contentApiUrl } from '../const';
import { ClientsPersonalAccountList, Payment } from '../models';
import Validate from '../validate/Validate';
import {
  add,
  dateToIsoString,
  EmployeeProps,
  getArrayLength,
  head,
  IClientsPersonalAccountListProps,
  ifElse,
  sub,
  toDateByFormat,
  toMoment,
} from '../helpers';
import {
  ApiAnswer,
  ClientPersonalityType,
  DateValue,
  DeletePrams,
  IEntityProps,
  IListSearchProps,
  OPERATION_TYPE,
} from '../types';
import {
  ClientAbonementDTO,
  ClientAbonementFormDTO,
  ClientAbonementListDTO,
  ClientAbonementMapper,
  ClientAbonementScheduleFormDTO,
  ClientAbonementSubscriptionFormDTO,
  ClientDiscountType,
  ClientDTO,
  ClientFileType,
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOperationFormDTO,
  ClientPaymentFormDTO,
  ClientRefundsDTO,
  ClientRefundsFormDTO,
  ClientRefundsType,
  ClientSettlementType,
  ClientVisitDTO,
  ClientVisitFormDTO,
  ClientVisitMapper,
  ClientVisitModel,
  FileListModel,
  FileMapper,
  IClientAbonementDTO,
  IClientAbonementDTOProps,
  IClientAbonementLatestStatusDTOProps,
  IClientAbonementSubscriptionDTOProps,
  IClientAbonementSubscriptionListDTO,
  IClientDTOProps,
  IClientListDTO,
  IClientOperationDTOProps,
  IClientOperationListDTO,
  IClientOperationListStats,
  IClientRefundsDTOProps,
  IClientVisitDTOProps,
  IClientVisitListDTO,
  IFileDTOProps,
  IPaymentListDTO,
  IScheduleCalendarDTOProps,
  IScheduleCalendarListDTO,
  IScheduleCalendarStatsDTOProps,
  PaymentDTO,
  PaymentMapper,
  ScheduleCalendarMapper,
} from '@structure';
import { IPaymentListAnswer } from './companyPayments';
import { PaymentScheduleOperationType } from './orders';
import { GetCompanyScheduleState } from './company';

export interface IClientPaymentParams extends ClientPaymentFormDTO {
  clientUuid?: string;
}

export const CLIENT_INITIAL_PARAM = new ClientFormDTO();
export const CLIENT_LEGAL_INITIAL_PARAM = new ClientLegalFormDTO();
export const CLIENT_GROUP_INITIAL_PARAM = new ClientGroupFormDTO();
export const CLIENT_REFUND_INITIAL_PARAM = new ClientRefundsFormDTO();
export const CLIENT_VISIT_INITIAL_PARAM = new ClientVisitFormDTO(
  {} as ClientVisitModel,
  false,
);

const { uuid, birthday, gender, ...CLIENT_INITIAL_PARAM_REST } =
  CLIENT_INITIAL_PARAM;

const { uuid: lUuid, ...CLIENT_LEGAL_INITIAL_PARAM_REST } =
  CLIENT_LEGAL_INITIAL_PARAM;

export const CLIENT_REQUIRED_FIELD = {
  ...CLIENT_INITIAL_PARAM_REST,
  birthday: (value: string) =>
    value ? CLIENT_INITIAL_PARAM.birthdayToString(value) : '',
};
export const CLIENT_LEGAL_REQUIRED_FIELD = {
  ...CLIENT_LEGAL_INITIAL_PARAM_REST,
};
export const CLIENT_PAYMENT_INITIAL_PARAM = new ClientPaymentFormDTO();

export const PAYMENT_METHOD = ['CASHBOX', 'ACCOUNT', 'BANK'];

export const CLIENT_PAYMENT_TYPE = [
  {
    title: 'Receipts',
    uuid: OPERATION_TYPE.IN,
  },
  {
    title: 'Expenses',
    uuid: OPERATION_TYPE.OUT,
    disabled: true,
  },
];

export const CLIENT_ABONEMENT_INITIAL_PARAM = new ClientAbonementFormDTO();

export const CLIENT_DISCOUNT_TYPE: (currencySymbol: string) => {
  uuid: ClientDiscountType;
  title: ClientDiscountType;
  description: string;
}[] = (currencySymbol) => [
  {
    uuid: ClientDiscountType.Percentage,
    title: ClientDiscountType.Percentage,
    description: '%',
  },
  {
    uuid: ClientDiscountType.Fixed,
    title: ClientDiscountType.Fixed,
    description: currencySymbol,
  },
];

const {
  uuid: cUuid,
  updated_at,
  created_at,
  abon_status,
  abon_status_text,
  created_by,
  abon_end_date,
  abon_title,
  manager,
  company_abonement_group,
  abon_schedule_start_date,
  abon_schedule_comment,
  abon_schedule_end_date,
  abon_schedule_status_text,
  abon_schedule_list,
  ...CLIENT_ABONEMENT_INITIAL_PARAM_REST
} = new ClientAbonementDTO({} as IClientAbonementDTOProps);

export const CLIENT_ABONEMENT_REQUIRED_FIELD = {
  ...CLIENT_ABONEMENT_INITIAL_PARAM_REST,
};

export const CLIENT_REFUNDS_TYPE = [
  {
    title: 'By date',
    uuid: ClientRefundsType.Date,
  },
  {
    title: 'By visits',
    uuid: ClientRefundsType.Visit,
  },
];

export interface IClientAbonementAnswer extends ApiAnswer {
  abonement: IClientAbonementDTOProps;
}

export interface ICalculateRefundsClientAbonementAnswer extends ApiAnswer {
  refund: IClientRefundsDTOProps;
}

export interface IClientVisitAnswer extends ApiAnswer {
  visiting: IClientVisitDTOProps;
}

export interface IClientAbonementSubscriptionAnswer extends ApiAnswer {
  subscriptions: IClientAbonementSubscriptionDTOProps[];
  abonement: IClientAbonementDTOProps;
}

export interface IClientAbonementSubscriptionReturnType {
  subscriptions: IClientAbonementSubscriptionListDTO;
  abonement: IClientAbonementDTO;
}

export interface IClientAbonementListAnswer extends ApiAnswer {
  abonements: IClientAbonementDTOProps[];
  client: IClientDTOProps;
}

export interface IClientVisitListAnswer extends ApiAnswer {
  visits: IClientVisitDTOProps[];
  abonement: IClientAbonementDTOProps;
}

export interface IClientAbonementSubscriptionListAnswer extends ApiAnswer {
  subscriptions: IClientAbonementSubscriptionDTOProps[];
}

export interface ICompanyClientAbonementListAnswer
  extends ApiAnswer,
    IListSearchProps {
  abonements: IClientAbonementDTOProps[];
  client: IClientDTOProps;
}

export interface ICompanyClientAbonementListProps extends IListSearchProps {
  clientUuid: string;
}

export interface IClientVisitListProps extends IListSearchProps {
  abonementUuid: string;
}

export interface IClientAbonementScheduleListProps extends IListSearchProps {
  abonementUuid: string;
  date_start: DateValue;
  date_end: DateValue;
}

export interface IClientBalanceOperationsListProps extends IListSearchProps {
  clientUuid: string;
  created_at?: string;
}

export interface IClientAbonementScheduleListAnswer extends ApiAnswer {
  schedules: IScheduleCalendarDTOProps[];
}

export type IInvitationType = {
  uuid: string;
  status: number;
  created_at: string;
  updated_at: string;
};

export interface IInvitation {
  invitation: IInvitationType;
  template: string;
}

export interface IInvitationById {
  invitation: IInvitationType;
  employee: EmployeeProps;
  redirect_to_login: boolean;
}

export interface IClientAnswer extends ApiAnswer {
  client: IClientDTOProps;
}

export interface IClientListAnswer extends ApiAnswer {
  user_profiles: IClientDTOProps[];
}

export interface IClientListStatsProps {
  [ClientPersonalityType.Individual]: string;
  [ClientPersonalityType.Group]: string;
}

export interface ICompanyClientListAnswer extends ApiAnswer, IListSearchProps {
  clients: IClientDTOProps[];
  stats: IClientListStatsProps;
}

export interface ICompanyClientListProps extends IListSearchProps {
  companyUuid: string;
  client_type?: ClientPersonalityType;
  provisioner_only?: boolean;
  settlement?: ClientSettlementType;
}

export interface IClientAbonementSubscriptionListProps
  extends IListSearchProps {
  abonementUuid: string;
}

export interface ICompanyClientSearch extends ApiAnswer {
  rows: IClientDTOProps[];
}

export interface IClientPaymentListParam
  extends Partial<IListSearchProps>,
    IEntityProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  clientUuid: string;
  payment_type?: PaymentScheduleOperationType;
}

export const getSaldo = (value: Payment): number => {
  const balanceBefore = value?.paymentable?.balance_before;
  if (value?.payment_type === 'IN') {
    return add(balanceBefore, value?.payment_sum);
  }
  return sub(balanceBefore, value?.payment_sum);
};

/* ------------------- CLIENT LIST ------------------- */

export async function getClientList({
  offset = 0,
  limit = 10,
}: IListSearchProps): Promise<IClientListDTO> {
  const { user_profiles, count } = await apiGet<
    IListSearchProps,
    IClientListAnswer
  >(`${contentApiUrl}/clients`, {
    offset,
    limit,
  });

  return ClientMapper.toClientListDTO(user_profiles, count);
}

export async function getCompanyClientList({
  companyUuid,
  offset = 0,
  limit = 10,
  keywords,
  client_type = ClientPersonalityType.Individual,
  provisioner_only,
  settlement,
}: ICompanyClientListProps): Promise<IClientListDTO> {
  Validate.string({ value: companyUuid });

  const { clients, total, stats } = await apiGet<
    Omit<ICompanyClientListProps, 'companyUuid'>,
    ICompanyClientListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/clients`, {
    offset,
    limit,
    keywords,
    client_type: client_type?.toLowerCase() as any,
    provisioner_only:
      client_type === ClientPersonalityType.Individual &&
      provisioner_only === true
        ? provisioner_only
        : undefined,
    settlement:
      client_type === ClientPersonalityType.Individual &&
      settlement !== ClientSettlementType.ALL
        ? settlement
        : undefined,
  });

  return ClientMapper.toClientListDTO(clients, total, false, [], stats);
}

export async function searchClients({
  keywords = '',
  offset = 0,
  limit = 10,
}: Omit<ICompanyClientListProps, 'companyUuid'>): Promise<IClientListDTO> {
  const { rows, count } = await apiGet<
    Omit<ICompanyClientListProps, 'companyUuid'>,
    ICompanyClientSearch
  >(`${contentApiUrl}/search/clients`, {
    keywords,
    offset,
    limit,
  });

  return ClientMapper.toClientListDTO(rows, count);
}

export async function getClientById(clientUuid: string): Promise<ClientDTO> {
  const { client } = await apiGet<null, IClientAnswer>(
    `${contentApiUrl}/clients/${clientUuid}`,
  );

  return ClientMapper.toClientDTO(client);
}

export async function createClient(
  value: ClientFormDTO,
  companyUuid: string,
): Promise<ClientDTO> {
  const {
    first_name,
    last_name,
    middle_name,
    email,
    phone,
    address,
    gender,
    birthday,
    client_org_type,
    client_discount,
    client_discount_goods,
    client_comment,
    client_discount_type,
    client_discount_goods_type,
    client_is_provisioner,
  } = value;

  const { client } = await apiPost<
    Omit<ClientFormDTO, 'uuid' | 'birthdayToString' | 'fullName'>,
    IClientAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/clients`, {
    first_name,
    last_name,
    email,
    phone,
    address,
    gender,
    middle_name,
    birthday: birthday ? value.birthdayToString(birthday) : '',
    client_org_type,
    client_discount,
    client_comment,
    client_discount_goods,
    client_discount_type,
    client_is_provisioner,
    client_discount_goods_type,
  });

  return ClientMapper.toClientDTO(client);
}

export async function createClientLegal(
  {
    client_is_conflict,
    client_org_bank_account_id,
    client_org_bank_code,
    client_org_bank_name,
    client_org_tax_code,
    client_org_address,
    client_org_manager,
    client_is_provisioner,
    client_is_tav_payer,
    client_org_name,
    client_org_contact_person,
    client_org_code,
    client_org_type,
    client_discount,
    client_discount_goods,
    client_comment,
    client_discount_type,
    client_discount_goods_type,
    email,
    phone,
  }: ClientLegalFormDTO,
  companyUuid: string,
): Promise<ClientDTO> {
  const { client } = await apiPost<
    Omit<ClientLegalFormDTO, 'uuid'>,
    IClientAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/clients`, {
    client_is_conflict,
    client_is_tav_payer,
    client_org_contact_person,
    client_org_bank_account_id,
    client_org_bank_code,
    client_org_bank_name,
    client_org_tax_code,
    client_org_address,
    client_org_manager,
    client_is_provisioner,
    client_org_name,
    client_org_code,
    client_org_type,
    client_discount,
    client_comment,
    client_discount_goods,
    client_discount_type,
    client_discount_goods_type,
    email,
    phone,
  });

  return ClientMapper.toClientDTO(client);
}

export async function editClient(value: ClientFormDTO): Promise<ClientDTO> {
  const {
    uuid: client_uuid,
    first_name,
    last_name,
    email,
    phone,
    address,
    gender,
    birthday,
    middle_name,
    client_org_type,
    client_discount,
    client_comment,
    client_discount_goods,
    client_discount_type,
    client_is_provisioner,
    client_is_conflict,
    client_discount_goods_type,
  } = value;

  const { client } = await apiPut<
    Omit<ClientFormDTO, 'birthdayToString' | 'uuid' | 'fullName'>,
    IClientAnswer
  >(`${contentApiUrl}/clients/${client_uuid}`, {
    first_name,
    last_name,
    phone,
    email,
    address,
    gender,
    middle_name,
    client_is_conflict,
    birthday: birthday ? value.birthdayToString(birthday) : '',
    client_org_type,
    client_discount,
    client_comment,
    client_discount_goods,
    client_discount_type,
    client_discount_goods_type,
    client_is_provisioner,
  });
  return ClientMapper.toClientDTO(client);
}

export async function editClientLegal({
  uuid: client_uuid,
  client_is_conflict,
  client_is_tav_payer,
  client_org_bank_account_id,
  client_org_bank_code,
  client_org_bank_name,
  client_org_tax_code,
  client_org_address,
  client_org_manager,
  client_is_provisioner,
  client_org_name,
  client_org_code,
  client_org_type,
  client_discount,
  client_discount_goods,
  client_comment,
  client_discount_type,
  client_org_contact_person,
  client_discount_goods_type,
  email,
  phone,
}: ClientLegalFormDTO): Promise<ClientDTO> {
  const { client } = await apiPut<
    Omit<ClientLegalFormDTO, 'uuid'>,
    IClientAnswer
  >(`${contentApiUrl}/clients/${client_uuid}`, {
    client_is_conflict,
    client_org_bank_account_id,
    client_org_bank_code,
    client_is_tav_payer,
    client_org_contact_person,
    client_org_bank_name,
    client_org_tax_code,
    client_org_address,
    client_org_manager,
    client_is_provisioner,
    client_org_name,
    client_org_code,
    client_org_type,
    client_discount,
    client_comment,
    client_discount_goods,
    client_discount_type,
    client_discount_goods_type,
    email,
    phone,
  });
  return ClientMapper.toClientDTO(client);
}

export async function deleteClient(
  ids: string[] = [],
): Promise<IClientListDTO> {
  const isContainIds = ifElse(getArrayLength(ids) > 0, { ids }, {});

  const { clients } = await apiDelete<
    DeletePrams | Object,
    ICompanyClientListAnswer
  >(`${contentApiUrl}/clients`, isContainIds);

  return ClientMapper.toClientListDTO(clients, 0);
}

/* ------------------- CLIENT GROUP ------------------- */

export async function createClientGroup(
  { client_group_name, client_uuids }: ClientGroupFormDTO,
  companyUuid: string,
): Promise<ClientDTO> {
  const { client } = await apiPost<
    Omit<ClientGroupFormDTO, 'uuid'>,
    IClientAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/client-groups`, {
    client_group_name,
    client_uuids,
  });

  return ClientMapper.toClientDTO(client);
}

export async function editClientGroup({
  uuid: client_uuid,
  client_group_name,
  client_uuids,
}: ClientGroupFormDTO): Promise<ClientDTO> {
  const { client } = await apiPut<
    Omit<ClientGroupFormDTO, 'uuid'>,
    IClientAnswer
  >(`${contentApiUrl}/clients/${client_uuid}/groups`, {
    client_group_name,
    client_uuids,
  });
  return ClientMapper.toClientDTO(client);
}

export interface IClientOrderListProps extends IListSearchProps {
  clientUuid: string;
  start?: DateValue;
  end?: DateValue;
  state: GetCompanyScheduleState;
}

export interface IClientScheduleListAnswer extends ApiAnswer {
  schedules: IScheduleCalendarDTOProps[];
  stats: { totals: IScheduleCalendarStatsDTOProps };
}

export async function getClientOrderList({
  clientUuid,
  offset = 0,
  limit = 10,
  start,
  end,
  keywords,
  sort,
  state,
}: IClientOrderListProps): Promise<IScheduleCalendarListDTO> {
  Validate.string({ value: clientUuid });

  const { schedules, total, stats } = await apiGet<
    Partial<IListSearchProps> & {
      date_start?: string;
      date_end?: string;
      state: any;
    },
    IClientScheduleListAnswer
  >(`${contentApiUrl}/clients/${clientUuid}/schedules`, {
    date_start: start ? toDateByFormat(start, 'YYYY-MM-DD') : '',
    date_end: end ? toDateByFormat(end, 'YYYY-MM-DD') : '',
    limit,
    offset,
    keywords,
    sort,
    state,
  });

  return ScheduleCalendarMapper.toScheduleCalendarListDTO(
    schedules,
    total,
    [],
    ScheduleCalendarMapper.toScheduleCalendarStatsDT(stats?.totals),
  );
}

/**
 * @name sentAnInvitationToClient
 * @desc Формування запрошення для клієнта
 * @param {String} client_uuid
 * @throws {ApiError}
 */
export async function sentAnInvitationToClient({
  clientUuid,
}: Pick<IClientOrderListProps, 'clientUuid'>): Promise<IInvitation> {
  Validate.string({ value: clientUuid });

  return await apiPost(
    `${contentApiUrl}/clients/${clientUuid}/invitations`,
    null,
  );
}

/**
 * @name getClientInvitation
 * @desc Отримуємо деталі запрошення кліента по Uuid
 * @param {String} invitationUuid
 * @throws {ApiError}
 */
export async function getClientInvitation(
  invitationUuid: string,
): Promise<IInvitationById> {
  Validate.string({ value: invitationUuid });

  return await apiGet(`${contentApiUrl}/invitations/${invitationUuid}`);
}

/**
 * @name getClientPersonalAccount
 * @desc Отримуємо список операції по персональному рахунку
 * @param {String} clientsUuid
 * @throws {ApiError}
 */

export interface GetClientPersonalAccount
  extends IClientsPersonalAccountListProps,
    ApiAnswer {}

export async function getClientPersonalAccount({
  clientUuid,
}: Pick<IClientOrderListProps, 'clientUuid'>) {
  Validate.string({ value: clientUuid });

  const { clientAccountOperations, count } = await apiGet<
    null,
    GetClientPersonalAccount
  >(`${contentApiUrl}/clients/${clientUuid}/personal-accounts`);

  return new ClientsPersonalAccountList({
    clientAccountOperations,
    total: count,
  } as any);
}

export interface OperationParam extends Partial<ApiAnswer> {
  payment: Payment;
}

/**
 * @name operationsWithBalance
 * @desc Поповнення/Списання балансу
 * @param {String} client_uuid
 * @throws {ApiError}
 */
export async function operationsWithBalance({
  clientUuid,
  payment_date,
  payment_sum,
  payment_comment,
  payment_method,
  payment_type,
  payment_group_uuid,
}: IClientPaymentParams): Promise<PaymentDTO> {
  Validate.string({ value: clientUuid });

  const { payment } = await apiPost<
    Omit<ClientPaymentFormDTO, 'payment_type' | 'payment_document'>,
    any
  >(
    `${contentApiUrl}/clients/${clientUuid}/personal-accounts/${payment_type.toLowerCase()}`,
    {
      payment_date: payment_date.toString(),
      payment_sum,
      payment_comment,
      payment_method,
      payment_group_uuid,
    },
  );

  return PaymentMapper.toPaymentDTO(payment);
}

/**
 * @desc Оновлення опису/коментару для Контрагента
 * @param {string} comment
 * @param {string} clientUUID
 */
export async function updateClientComment(
  comment: string,
  clientUUID: string,
): Promise<ClientDTO> {
  const { client } = await apiPatch<{ comment: string }, any>(
    `${contentApiUrl}/clients/${clientUUID}/details`,
    { comment },
  );

  return ClientMapper.toClientDTO(client);
}

export async function getClientPaymentList({
  clientUuid,
  date_start,
  date_end,
  keywords,
  offset = 0,
  limit = 10,
  payment_type = PaymentScheduleOperationType.ALL,
}: IClientPaymentListParam): Promise<{
  payments: IPaymentListDTO;
  client: ClientDTO;
}> {
  const { payments, count, stats, client } = await apiGet<
    IListSearchProps & {
      date_start: any;
      date_end: any;
      payment_type: PaymentScheduleOperationType;
    },
    IPaymentListAnswer & { client: IClientDTOProps }
  >(`${contentApiUrl}/clients/${clientUuid}/payments`, {
    offset,
    limit,
    keywords,
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DD') : '',
    date_start: date_start ? toDateByFormat(date_start, 'YYYY-MM-DD') : '',
    payment_type,
  });

  return {
    payments: PaymentMapper.toPaymentListDTO(payments, count, stats),
    client: ClientMapper.toClientDTO(client),
  };
}

/* ------------------- CLIENT ABONEMENT LIST ------------------- */

export async function getClientAbonementList({
  offset = 0,
  limit = 10,
  keywords,
  clientUuid,
}: ICompanyClientAbonementListProps): Promise<
  ClientAbonementListDTO<ClientModel>
> {
  const { abonements, client, count } = await apiGet<
    IListSearchProps,
    IClientAbonementListAnswer
  >(`${contentApiUrl}/clients/${clientUuid}/abonements`, {
    offset,
    limit,
    keywords,
  });

  return ClientAbonementMapper.toClientAbonementListDTO(
    abonements,
    count || 0,
    ClientMapper.toClientModel(client as ClientDTO),
  );
}

export async function getClientAbonementById(
  abonementUuid: string,
): Promise<IClientAbonementDTO> {
  const { abonement, latest_status } = await apiGet<
    null,
    IClientAbonementAnswer & {
      latest_status: IClientAbonementLatestStatusDTOProps;
    }
  >(`${contentApiUrl}/client-abonements/${abonementUuid}`);

  return ClientAbonementMapper.toClientAbonementDTO(abonement, latest_status);
}

export async function createClientAbonement(
  {
    company_abonement_uuid,
    discount,
    final_price,
    manager_uuid,
    start_date,
    comment,
    card_number,
    send_notification,
    abonement_group_uuid,
  }: ClientAbonementFormDTO,
  clientUuid: string,
): Promise<ClientAbonementDTO> {
  const { abonement } = await apiPost<
    Omit<ClientAbonementFormDTO, 'uuid'>,
    IClientAbonementAnswer
  >(`${contentApiUrl}/clients/${clientUuid}/abonements`, {
    company_abonement_uuid,
    discount,
    final_price,
    manager_uuid,
    start_date: dateToIsoString(start_date),
    comment,
    card_number,
    send_notification,
    abonement_group_uuid,
  });

  return ClientAbonementMapper.toClientAbonementDTO(
    abonement,
    {} as IClientAbonementLatestStatusDTOProps,
  ).abonement;
}

export async function editClientAbonement({
  uuid: abonement_uuid,
  company_abonement_uuid,
  discount,
  final_price,
  manager_uuid,
  start_date,
  comment,
  card_number,
  send_notification,
  abonement_group_uuid,
}: ClientAbonementFormDTO): Promise<ClientAbonementDTO> {
  const { abonement } = await apiPut<
    Omit<ClientAbonementFormDTO, 'uuid'>,
    IClientAbonementAnswer
  >(`${contentApiUrl}/client-abonements/${abonement_uuid}`, {
    company_abonement_uuid,
    discount,
    final_price,
    manager_uuid,
    start_date: dateToIsoString(start_date),
    comment,
    card_number,
    send_notification,
    abonement_group_uuid,
  });

  return ClientAbonementMapper.toClientAbonementDTO(
    abonement,
    {} as IClientAbonementLatestStatusDTOProps,
  ).abonement;
}

export async function deleteClientAbonement(
  ids: string[] = [],
): Promise<ClientAbonementListDTO<ClientModel>> {
  const { abonements, client } = await apiDelete<
    { ids: string[] },
    ICompanyClientAbonementListAnswer
  >(`${contentApiUrl}/client-abonements/${head(ids)}`);

  return ClientAbonementMapper.toClientAbonementListDTO(
    abonements,
    0,
    ClientMapper.toClientModel(client as ClientDTO),
  );
}

export async function changeClientAbonementNotify(
  abonementUuid: string,
  send_notification: boolean,
): Promise<ClientAbonementDTO> {
  const { abonement } = await apiPatch<
    { send_notification: boolean },
    IClientAbonementAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/send-notification`, {
    send_notification,
  });

  return ClientAbonementMapper.toClientAbonementDTO(
    abonement,
    {} as IClientAbonementLatestStatusDTOProps,
  ).abonement;
}

/* ------------------- CLIENT REFUNDS ------------------- */

export async function refundsClientAbonement({
  uuid,
  refund_start_date,
  refund_comment,
  refund_sum,
  refund_payment_method,
  refund_type,
  refund_visits,
  refund_cashbox_uuid,
}: ClientRefundsFormDTO): Promise<IClientAbonementDTO> {
  const { abonement, latest_status } = await apiPost<
    Omit<ClientRefundsFormDTO, 'uuid'>,
    IClientAbonementAnswer & {
      latest_status: IClientAbonementLatestStatusDTOProps;
    }
  >(`${contentApiUrl}/client-abonements/${uuid}/refund`, {
    refund_start_date,
    refund_comment,
    refund_sum,
    refund_payment_method,
    refund_type,
    refund_visits,
    refund_cashbox_uuid,
  });

  return ClientAbonementMapper.toClientAbonementDTO(abonement, latest_status);
}

export interface ICalculationRefundsClientAbonementProps {
  start_date: string;
  refund_type: ClientRefundsType;
  refund_visits?: number;
}

export async function calculationRefundsClientAbonement(
  {
    start_date,
    refund_type,
    refund_visits,
  }: ICalculationRefundsClientAbonementProps,
  abonementUuid: string,
): Promise<ClientRefundsDTO> {
  const { refund } = await apiGet<
    ICalculationRefundsClientAbonementProps,
    ICalculateRefundsClientAbonementAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/refund`, {
    start_date: toMoment(start_date).toISOString() as string,
    refund_type,
    refund_visits,
  });

  return ClientAbonementMapper.toClientRefundDTO(refund);
}

/* ------------------- CLIENT ABONEMENT SUBSCRIPTION LIST ------------------- */

export async function getClientAbonementSubscriptionList({
  offset = 0,
  limit = 10,
  keywords,
  abonementUuid,
}: IClientAbonementSubscriptionListProps): Promise<IClientAbonementSubscriptionListDTO> {
  const { subscriptions, count } = await apiGet<
    IListSearchProps,
    IClientAbonementSubscriptionListAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/subscriptions`, {
    offset,
    limit,
    keywords,
  });

  return ClientAbonementMapper.toClientAbonementSubscriptionListDTO(
    subscriptions,
    count || 0,
  );
}

export async function createClientAbonementSubscriptionLit(
  {
    start_date,
    payment_date,
    payment_type,
    packages,
  }: ClientAbonementSubscriptionFormDTO,
  abonementUuid: string,
): Promise<IClientAbonementSubscriptionReturnType> {
  const { subscriptions, abonement } = await apiPost<
    Omit<ClientAbonementSubscriptionFormDTO, 'uuid' | 'amount'>,
    IClientAbonementSubscriptionAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/subscriptions`, {
    start_date: dateToIsoString(start_date),
    payment_date: dateToIsoString(payment_date),
    payment_type,
    packages,
  });

  return {
    subscriptions: ClientAbonementMapper.toClientAbonementSubscriptionListDTO(
      subscriptions,
      subscriptions?.length,
    ),
    abonement: ClientAbonementMapper.toClientAbonementDTO(abonement),
  };
}

/* ------------------- CLIENT VISIT LIST ------------------- */

export async function getClientVisitList({
  offset = 0,
  limit = 10,
  keywords,
  abonementUuid,
}: IClientVisitListProps): Promise<IClientVisitListDTO> {
  const { visits, abonement, count } = await apiGet<
    IListSearchProps,
    IClientVisitListAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/visits`, {
    offset,
    limit,
    keywords,
  });

  const { abonement: abonementDTO, latest_status } =
    ClientAbonementMapper.toClientAbonementDTO(
      abonement,
      {} as IClientAbonementLatestStatusDTOProps,
    );

  return ClientVisitMapper.toClientVisitListDTO(
    visits,
    ClientAbonementMapper.toClientAbonementModel(abonementDTO, latest_status)
      .abonement,
    count || 0,
  );
}

export async function getClientVisitById(
  visitUuid: string,
): Promise<ClientVisitDTO> {
  const { visiting } = await apiGet<null, IClientVisitAnswer>(
    `${contentApiUrl}/visits/${visitUuid}`,
  );

  return ClientVisitMapper.toClientVisitDTO(visiting);
}

export async function createClientVisit(
  { date, comment, manager_uuid }: ClientVisitFormDTO,
  clientUuid: string,
): Promise<ClientVisitDTO> {
  const { visiting } = await apiPost<
    Omit<ClientVisitFormDTO, 'uuid'>,
    IClientVisitAnswer
  >(`${contentApiUrl}/client-abonements/${clientUuid}/visits`, {
    date: dateToIsoString(date),
    comment,
    manager_uuid,
  });

  return ClientVisitMapper.toClientVisitDTO(visiting);
}

export async function editClientVisit({
  uuid: visit_uuid,
  date,
  comment,
  manager_uuid,
}: ClientVisitFormDTO): Promise<ClientVisitDTO> {
  const { visiting } = await apiPut<
    Omit<ClientVisitFormDTO, 'uuid'>,
    IClientVisitAnswer
  >(`${contentApiUrl}/visits/${visit_uuid}`, {
    date: dateToIsoString(date),
    comment,
    manager_uuid,
  });

  return ClientVisitMapper.toClientVisitDTO(visiting);
}

export async function deleteClientVisit(
  ids: string[] = [],
): Promise<ClientVisitDTO> {
  const { visiting } = await apiDelete<{ ids: string[] }, IClientVisitAnswer>(
    `${contentApiUrl}/visits/${head(ids)}`,
  );

  return ClientVisitMapper.toClientVisitDTO(visiting);
}

/* ------------------- CLIENT ABONEMENT SCHEDULE ------------------- */

export async function formingClientAbonementScheduleClasses(
  {
    schedule_start_date,
    schedule_end_date,
    schedule,
    manager_uuid,
    comment,
  }: ClientAbonementScheduleFormDTO,
  abonementUuid: string,
): Promise<IClientAbonementDTO> {
  const { abonement, latest_status } = await apiPatch<
    Omit<ClientAbonementScheduleFormDTO, 'uuid' | '_date'>,
    IClientAbonementAnswer & {
      latest_status: IClientAbonementLatestStatusDTOProps;
    }
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/schedules`, {
    schedule_start_date,
    schedule_end_date,
    schedule,
    manager_uuid,
    comment,
  });

  return ClientAbonementMapper.toClientAbonementDTO(abonement, latest_status);
}

export async function getClientAbonementScheduleList({
  offset = 0,
  limit = 10,
  keywords,
  abonementUuid,
  date_start,
  date_end,
}: IClientAbonementScheduleListProps): Promise<IScheduleCalendarListDTO> {
  const { schedules, total } = await apiGet<
    IListSearchProps & { date_start: string; date_end: string },
    IClientAbonementScheduleListAnswer
  >(`${contentApiUrl}/client-abonements/${abonementUuid}/schedules`, {
    offset,
    limit,
    keywords,
    date_start: date_start ? toDateByFormat(date_start, 'YYYY-MM-DD') : '',
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DD') : '',
  });

  return ScheduleCalendarMapper.toScheduleCalendarListDTO(schedules, total);
}

/* ------------------- CLIENT BALANCE OPERATIONS ------------------- */

export interface IClientOperationListAnswer extends Omit<ApiAnswer, 'total'> {
  operations: IClientOperationDTOProps[];
  client: IClientDTOProps;
  stats: IClientOperationListStats;
}

export async function getClientBalanceOperations({
  offset = 0,
  limit = 10,
  clientUuid,
}: IClientBalanceOperationsListProps): Promise<IClientOperationListDTO> {
  const { operations, client, count, stats } = await apiGet<
    IListSearchProps,
    IClientOperationListAnswer
  >(`${contentApiUrl}/clients/${clientUuid}/personal-accounts`, {
    offset,
    limit,
  });

  return ClientMapper.toClientOperationListDTO(
    operations,
    count,
    [],
    client as ClientDTO,
    stats,
  );
}

export async function clientAdjustmentOfBalances(
  clientUuid: string,
  operation: ClientOperationFormDTO,
): Promise<ClientDTO> {
  const { client } = await apiPatch<ClientOperationFormDTO, IClientAnswer>(
    `${contentApiUrl}/clients/${clientUuid}/balance`,
    operation,
  );

  return ClientMapper.toClientDTO(client);
}

/* -------------------  UPLOAD CLIENT LIST FILE ------------------- */

export interface IUploadClientListFileAnswer {
  client: {
    fields: {
      name: string;
      title: string;
      required: boolean;
    }[];
  };
  file: {
    header: string[];
    rows: number;
    uuid: string;
  };
}

export interface ISendFieldsMappingProps {
  fields: {
    from: string;
    to: string;
  }[];
  uuid: string;
}

export async function uploadClientListFile(
  xlsFile: File,
): Promise<IUploadClientListFileAnswer> {
  const formData = new FormData();

  formData.append('file', (xlsFile as any)?.originFileObj);

  const { client, file } = await apiPost<any, IUploadClientListFileAnswer>(
    `${contentApiUrl}/clients/import`,
    formData,
    {},
    {},
    true,
  );

  return { client, file };
}

export async function sendFieldsMapping({
  fields,
  uuid,
}: ISendFieldsMappingProps): Promise<any> {
  return await apiPost<Omit<ISendFieldsMappingProps, 'uuid'>, any>(
    `${contentApiUrl}/clients/import/${uuid}/create`,
    { fields },
  );
}

/* ------------------- CLIENT FILE LIST ------------------- */

export interface ClientFileListProps extends IListSearchProps {
  clientUuid: string;
  format?: ClientFileType;
}

export interface ClientFileListAnswer extends ApiAnswer, IListSearchProps {
  client: IClientDTOProps;
  files: IFileDTOProps[];
}

export async function getClientFileList({
  clientUuid,
  offset = 0,
  limit = 10,
  keywords,
  format,
}: ClientFileListProps): Promise<FileListModel> {
  const { files, client, total } = await apiGet<
    Omit<ClientFileListProps, 'clientUuid'>,
    ClientFileListAnswer
  >(`${contentApiUrl}/clients/${clientUuid}/files`, {
    offset,
    limit,
    keywords,
    format,
  });

  return FileMapper.toFileListModel(
    FileMapper.toFileListDTO(files, total).files,
    total,
    [],
    client,
  );
}
