import React, { useCallback, useMemo } from 'react';
import { Space, Image } from 'antd';
import {
  CopyOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UserOutlined,
  ClockCircleOutlined,
  LikeOutlined,
  RightOutlined,
  AppleOutlined,
  AndroidOutlined,
} from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Text } from '@components/lib/Format';
import styled, { css } from 'styled-components';
import {
  compose,
  head,
  last,
  split,
  textToUpperCase,
  debounceV2,
  toLowerCase,
} from '@services/helpers';
import { useLocation } from 'react-router-dom';
import { useNotify } from '@components/lib/libV2/hooks';
import { appStoreLink, googlePlayLink } from '@services/const';
import platform from 'platform';
import {
  EmployeeRegistrationItem,
  IEmployeeRegistrationItemProps,
} from '../Show';
import { EmployeeRegistrationButton } from '../Buttons';

export default withTranslation()(EmployeeRegistrationPage);

export interface IEmployeeRegistrationPageProps extends WithTranslation {}

const StyledContent = styled(Space)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.app};
`;

const textStyle = css`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 22px;
`;

const StyledTitle = styled(Text)`
  ${textStyle};
`;

const StyledHighlightedText = styled(StyledTitle)`
  background-color: rgba(64, 64, 65, 0.2);
  border-radius: 10px;
  padding: 5px;
`;

const StyledOl = styled.ol`
  min-width: 50vw;
  max-width: 80vw;
  padding-right: 15px;
  padding-left: 15px;
`;

const StyledInviteCodeText = styled(Text)`
  ${textStyle};
  max-width: 95%;
  font-size: 25px;
  font-weight: 700;
  word-break: break-all;
`;

const StyledCopyOutlined = styled(CopyOutlined)`
  ${textStyle};
`;

function EmployeeRegistrationPage({
  t,
}: IEmployeeRegistrationPageProps): JSX.Element {
  const { search } = useLocation();
  const { notifyMessage } = useNotify();

  const joining_code = useMemo(
    () => compose(last, split('='), head, split('&'))(search),
    [search],
  );

  const handleCopyCode = debounceV2(async () => {
    if (typeof joining_code === 'string') {
      await navigator?.clipboard?.writeText(joining_code);
      notifyMessage(t('Success copy!!!'));
    }
  }, 1000);

  const navigateToAppStore = useCallback(() => {
    window.open(appStoreLink, '_blank');
  }, []);
  const navigateToGooglePlay = useCallback(() => {
    window.open(googlePlayLink, '_blank');
  }, []);

  const instruction: Omit<IEmployeeRegistrationItemProps, 'index'>[] = useMemo(
    () => [
      {
        icon: <AppstoreOutlined />,
        title: t('Download and install the app'),
        extraContent: (
          <>
            {toLowerCase(platform?.os?.family || '') === 'android' ? null : (
              <EmployeeRegistrationButton
                onClick={navigateToAppStore}
                icon={<AppleOutlined />}
                title="App Store"
                endIcon={<RightOutlined />}
              />
            )}
            &nbsp;
            {toLowerCase(platform?.os?.family || '') === 'ios' ? null : (
              <EmployeeRegistrationButton
                onClick={navigateToGooglePlay}
                icon={<AndroidOutlined />}
                title="Google Play"
                endIcon={<RightOutlined />}
              />
            )}
          </>
        ),
      },
      {
        icon: <UserOutlined />,
        title: (
          <>
            {t('Select')}&nbsp;
            <StyledHighlightedText>
              {t('"Employee Registration"')}
            </StyledHighlightedText>
            &nbsp;
            {t('when opening the application')}
          </>
        ),
      },
      {
        icon: <UnorderedListOutlined />,
        title: (
          <>
            {t('Fill out the form with personal data and enter the code')}
            &nbsp;
            <StyledHighlightedText>{t('"Invitation"')}</StyledHighlightedText>
            :&nbsp;
          </>
        ),
        extraContent: (
          <>
            {joining_code ? (
              <EmployeeRegistrationButton
                title={
                  <StyledInviteCodeText>
                    {joining_code as string}
                  </StyledInviteCodeText>
                }
                onClick={handleCopyCode}
                endIcon={<StyledCopyOutlined />}
              />
            ) : null}
          </>
        ),
      },
      {
        icon: <ClockCircleOutlined />,
        title: (
          <>
            {t('Wait for')}&nbsp;
            <StyledHighlightedText>{t('confirmation')}</StyledHighlightedText>
            &nbsp;
            {t('from the Company Administrator')}
          </>
        ),
      },
      { icon: <LikeOutlined />, title: t('Good luck') },
    ],
    [handleCopyCode, joining_code, navigateToAppStore, navigateToGooglePlay, t],
  );

  return (
    <StyledContent direction="vertical">
      <Image
        src="/servicity-logo.svg"
        alt="logo"
        width="20vw"
        preview={false}
      />
      <StyledTitle>
        {textToUpperCase(
          t('Employee registration at the invitation of the Company'),
        )}
      </StyledTitle>
      <StyledOl>
        {instruction.map((item, index) => (
          <EmployeeRegistrationItem {...item} index={index + 1} />
        ))}
      </StyledOl>
    </StyledContent>
  );
}
