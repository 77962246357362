import { Map } from 'immutable';
import { STORE, APP_STATE } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { StoreModel } from '@structure';

interface IStoreListState {
  store: StoreModel | null;
  cachedStoreList: Map<string, StoreModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IStoreListState, 'store'> {
  type: STORE.SET_STORE;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateStore {
  type: STORE.UPDATE_STORE;
  store: StoreModel;
}

interface LoadingAction {
  type: STORE.LOADING_STORE;
}

interface ResetAction {
  type: STORE.RESET_STORE;
}

interface ErrorAction extends Pick<IStoreListState, 'error'> {
  type: STORE.ERROR_STORE;
}

interface Handlers {
  [STORE.SET_STORE]: (
    state: IStoreListState,
    action: SetAction,
  ) => IStoreListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStoreListState,
    action: SetInitialStateAction,
  ) => IStoreListState;

  [STORE.RESET_STORE]: (
    state: IStoreListState,
    action: ResetAction,
  ) => IStoreListState;

  [STORE.UPDATE_STORE]: (
    state: IStoreListState,
    action: UpdateStore,
  ) => IStoreListState;

  [STORE.ERROR_STORE]: (
    state: IStoreListState,
    value: ErrorAction,
  ) => IStoreListState;

  [STORE.LOADING_STORE]: (
    state: IStoreListState,
    value?: LoadingAction,
  ) => IStoreListState;
  DEFAULT: (state: IStoreListState) => IStoreListState;
}

const initState: IStoreListState = {
  store: null,
  cachedStoreList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [STORE.SET_STORE]: (state, { store }) => ({
    ...state,
    ...{
      store,
      cachedStoreList: store
        ? state.cachedStoreList.set(store?.uuid, store)
        : state.cachedStoreList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STORE.RESET_STORE]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [STORE.UPDATE_STORE]: (state, { store }) => ({
    ...state,
    ...{
      store,
      cachedStoreList: state.cachedStoreList.update(store?.uuid, () => store),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STORE.ERROR_STORE]: (state: IStoreListState, { error }: ErrorAction) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STORE.LOADING_STORE]: (state: IStoreListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStoreListState) => state,
};

export default function Store(
  state: any = initState,
  action: any,
): IStoreListState {
  const handler = handlers[action.type as STORE] || handlers.DEFAULT;
  return handler(state, action);
}
