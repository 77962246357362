import React from 'react';
import { EmployeeModel } from '@structure';
import { isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IDefaultTabPanelProps } from '@components/lib/General';

export enum EMPLOYEE_TABS {
  SERVICE = 'service',
  DAY_OFFS = 'days_off',
  SCHEDULES = 'schedules',
  SALARY = 'Salary and bonuses',
}

export interface IEmployeeTabPanelProps {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & { calendarIndex: number },
  ) => React.ReactNode;
  employee: EmployeeModel | null;
  disabled?: boolean;
}

export default function EmployeeTabPanel({
  children,
}: IEmployeeTabPanelProps): JSX.Element {
  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({ tabs: [], calendarIndex: 0 })}</>;
}
