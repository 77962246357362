import {
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IMaterialAndExpenseModelReturnType,
} from '../internal';
import { correctPrice } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IMaterialAndExpenseDTOProps {
  uuid: string;
  name: string;
  amount: number;
  price: string;
  total: string;
  comment: string;
  created_at: string;
  updated_at: string;

  created_by: IProfileDTOProps;
}

export class MaterialAndExpenseDTO {
  public uuid: string;
  public name: string;
  public amount: string;
  public price: string;
  public total: string;
  public comment: string;
  public created_at: string;
  public updated_at: string;

  public created_by: IProfileDTOProps;

  constructor(
    props: IMaterialAndExpenseModelReturnType | IMaterialAndExpenseDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.name = props?.name || '';
    this.amount = correctPrice(props?.amount || 1);
    this.price = props?.price || '1';
    this.total = correctPrice(props?.total || 1);
    this.comment = props?.comment || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
  }
}
