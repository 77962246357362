import { correctPrice, toDateByFormat } from '@services/helpers';

export interface ISubscriptionDTOProps {
  id: string;
  date_start: string;
  date_end: string;
  comment: string;
  created_at: string;
  price: string;
  periods: number;
  discount: number;
  status: number;
}

export class SubscriptionDTO {
  public id: string;

  public date_start: string;

  public date_end: string;

  public comment: string;

  public created_at: string;

  public price: string;

  public periods: number;

  public discount: number;

  public status: number;

  constructor(props?: ISubscriptionDTOProps) {
    this.id = props?.id || '';
    this.date_start = props?.date_start
      ? toDateByFormat(props?.date_start, 'YYYY-MM-DD HH:mm')
      : '';
    this.date_end = props?.date_end
      ? toDateByFormat(props?.date_end, 'YYYY-MM-DD HH:mm')
      : '';
    this.comment = props?.comment || '';
    this.created_at = props?.created_at || '';
    this.price = correctPrice(props?.price);
    this.periods = props?.periods ?? 0;
    this.discount = props?.discount ?? 0;
    this.status = props?.status ?? 0;
  }
}
