import React from 'react';
import { TextFormat } from '../Format';
import styled, { css } from 'styled-components';
import { STATUS } from '@services/types';
import { FlexContainer } from '../Styled/FlexContainer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getStringPattern, SUBSCRIPTION_STATUS } from '@services/helpers';

import {
  CheckCircleOutlined,
  StopOutlined,
  ExclamationCircleOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons';

export default withTranslation()(Status);

export interface IStatusProps extends WithTranslation {
  status: STATUS | SUBSCRIPTION_STATUS;
  className?: string;
  statusPattern?: string;
}

interface IStatus {
  status?: any;
}

const statusStyle = css<IStatus>`
  color: ${({ theme }) => theme.colors.primary};
  ${({ status }) =>
    (status === STATUS.ACTIVE ||
      status === SUBSCRIPTION_STATUS.READY ||
      status === SUBSCRIPTION_STATUS.PAYED ||
      status === SUBSCRIPTION_STATUS.COMPLETED) &&
    css`
      color: ${({ theme }) => theme.colors.success};
    `}
  ${({ status }) =>
    status === STATUS.UNDEFINED &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}
  ${({ status }) =>
    status === STATUS.ERROR &&
    css`
      color: ${({ theme }) => theme.colors.error};
    `}
  ${({ status }) =>
    (status === STATUS.REQUESTED ||
      status === SUBSCRIPTION_STATUS.DRAFT ||
      status === SUBSCRIPTION_STATUS.WAITING_FOR_PAYMENT) &&
    css`
      color: ${({ theme }) => theme.colors.warning};
    `}
`;

const StatusFlexContainer = styled(FlexContainer)`
  height: fit-content;
`;

const iconStyle = css`
  ${statusStyle};
  font-size: 0.9em;
  margin-right: 10px;
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledStopOutlined = styled(StopOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined,
)<IStatus>`
  ${iconStyle}
`;

const StyledCloudSyncOutlined = styled(CloudSyncOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledTextFormat = styled(TextFormat)<IStatus>`
  font-size: 0.9em;
  ${statusStyle}
`;

function Status({
  status,
  className,
  t,
  statusPattern = '',
}: IStatusProps): JSX.Element {
  const statusIcon = {
    [STATUS.ACTIVE]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledCheckCircleOutlined status={status} />
    ),
    [STATUS.ERROR]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledStopOutlined status={status} />
    ),
    [STATUS.REQUESTED]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledCloudSyncOutlined status={status} />
    ),
    [STATUS.UNDEFINED]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledExclamationCircleOutlined status={status} />
    ),
    [SUBSCRIPTION_STATUS.COMPLETED]: ({
      status,
    }: Pick<IStatusProps, 'status'>) => (
      <StyledCheckCircleOutlined status={status} />
    ),
    [SUBSCRIPTION_STATUS.READY]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledCheckCircleOutlined status={status} />
    ),
    [SUBSCRIPTION_STATUS.PAYED]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledCheckCircleOutlined status={status} />
    ),
    [SUBSCRIPTION_STATUS.DRAFT]: ({ status }: Pick<IStatusProps, 'status'>) => (
      <StyledCloudSyncOutlined status={status} />
    ),
    [SUBSCRIPTION_STATUS.WAITING_FOR_PAYMENT]: ({
      status,
    }: Pick<IStatusProps, 'status'>) => (
      <StyledCloudSyncOutlined status={status} />
    ),
  };

  const [firstPatter, secondPattern] = getStringPattern(statusPattern);

  return (
    <StatusFlexContainer
      className={className}
      alignItems="center"
      flexDirection="row">
      <StyledTextFormat status={status}>{firstPatter}</StyledTextFormat>
      <TextFormat>
        {statusIcon[status || STATUS.UNDEFINED]({
          status: status || STATUS.UNDEFINED,
        })}
      </TextFormat>
      <StyledTextFormat status={status}>{t(status)}</StyledTextFormat>
      <StyledTextFormat status={status}>{secondPattern}</StyledTextFormat>
    </StatusFlexContainer>
  );
}
