import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { StoreDocumentModel } from '@structure';
import { correctPrice } from '@services/helpers';
import { StyledDescription } from '@components/lib/Styled';
import useStoredCompanies from '../../../../hooks/useStoredCompanies';

export interface StoreDocumentShortSummaryViewProps {
  document: StoreDocumentModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const StyledPrice = styled.strong`
  font-weight: bold;
  font-size: 20px;
`;

export function StoreDocumentShortSummaryView({
  document,
}: StoreDocumentShortSummaryViewProps): React.JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const extraServicesSumTotal = useMemo(
    () =>
      (document?.doc_extra_services || [])?.reduce((acc, curr) => {
        acc += Number(curr?.price || 0);
        return acc;
      }, 0),
    [document?.doc_extra_services],
  );

  const sumTotal = Number(document?.doc_sum_total || 0) - extraServicesSumTotal;
  const isShowWarningDescription = sumTotal > 0;

  return (
    <StyledContainer>
      <StyledDescription $color={theme.colors.black}>
        {isShowWarningDescription ? (
          <>
            {`${t('In the amount of')}: ${
              defaultCompany?.currency_symbol || ''
            }`}
            <StyledPrice> {correctPrice(sumTotal)}</StyledPrice>
          </>
        ) : (
          t('There are no products')
        )}
      </StyledDescription>
      {document?.doc_extra_services?.length ? (
        <StyledDescription $color={theme.colors.greyBlackberry}>
          {t('Additional services')}: {defaultCompany?.currency_symbol || ''}
          <strong>{correctPrice(document?.doc_sum_services)}</strong>
        </StyledDescription>
      ) : null}
    </StyledContainer>
  );
}
