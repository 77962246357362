import {
  CashBoxModel,
  ClientModel,
  EmployeeModel,
  GroupPaymentDTO,
  GroupPaymentMapper,
  GroupPaymentModel,
  InvoiceModel,
  PAYMENT_AGENT_TYPE,
  PAYMENT_DESTINATION,
  PAYMENT_METHOD,
  PaymentModel,
  ScheduleCalendarModel,
  StoreDocumentModel,
} from '../internal';
import { OPERATION_TYPE } from '@services/types';
import { DateType, toMoment } from '@services/helpers';
import { immerable } from 'immer';

export interface IPaymentAdditionalProps {
  editMode?: boolean;
  cashier?: EmployeeModel;
  disabledCashBox?: boolean;
}

export class PaymentFormDTO {
  [immerable]? = true;
  public uuid: string;
  public payment_date: DateType;
  public payment_sum: string;
  public payment_group_uuid: string | GroupPaymentDTO;
  public payment_cashbox_uuid: string | CashBoxModel;
  public payment_cashier_uuid: string | EmployeeModel;
  public payment_comment: string;
  public payment_agent_uuid: string | ClientModel | EmployeeModel;
  public payment_destination_object_uuid?:
    | string
    | ScheduleCalendarModel
    | StoreDocumentModel
    | InvoiceModel;
  public payment_destination: PAYMENT_DESTINATION;
  public payment_method: PAYMENT_METHOD;
  public payment_agent_type: PAYMENT_AGENT_TYPE;
  public payment_type: OPERATION_TYPE;
  public disabledCashBox?: boolean;

  constructor(
    props?: PaymentModel,
    {
      editMode = false,
      cashier,
      disabledCashBox = false,
    }: IPaymentAdditionalProps = {},
  ) {
    this.uuid = props?.uuid || '';
    this.payment_date = props?.payment_date
      ? PaymentFormDTO.toDate(props?.payment_date || '')
      : PaymentFormDTO.toDate(new Date() as any);
    this.payment_sum = props?.payment_sum || '0.00';
    this.payment_comment = props?.payment_comment || '';
    this.payment_method = props?.payment_method || PAYMENT_METHOD.Cash;
    this.payment_agent_type =
      props?.payment_agent_type || PAYMENT_AGENT_TYPE.Client;
    this.payment_destination =
      props?.payment_trigger || PAYMENT_DESTINATION.Account;
    this.payment_group_uuid =
      editMode && props?.payment_group?.uuid
        ? GroupPaymentMapper.toGroupPaymentDTO(
            props?.payment_group as GroupPaymentModel,
          )
        : props?.payment_group?.uuid || '';

    this.payment_cashier_uuid = editMode ? props?.cashier || '' : cashier || '';

    this.payment_cashbox_uuid = editMode
      ? props?.company_cashbox?.uuid
        ? props?.company_cashbox
        : ''
      : props?.company_cashbox?.uuid || '';

    this.payment_agent_uuid =
      this.paymentAgentUuidFactory(props?.payment_agent_object) || '';

    this.payment_destination_object_uuid = this.paymentDestinationObjectFactory(
      props?.payment_trigger_object,
    );

    this.payment_type = props?.payment_type || OPERATION_TYPE.IN;
    this.disabledCashBox = disabledCashBox;
  }

  private static toDate(payment_date: string): any {
    return payment_date ? toMoment(payment_date) : '';
  }

  private paymentAgentUuidFactory(
    paymentAgentObject: ClientModel | EmployeeModel | undefined,
    editMode?: boolean,
  ) {
    if (paymentAgentObject instanceof ClientModel) {
      return paymentAgentObject;
    }

    if (paymentAgentObject instanceof EmployeeModel) {
      if (editMode) {
        return paymentAgentObject;
      }

      return paymentAgentObject?.uuid;
    }

    return '';
  }

  private paymentDestinationObjectFactory(
    paymentDestinationObject:
      | ScheduleCalendarModel
      | StoreDocumentModel
      | InvoiceModel
      | string
      | undefined,
  ) {
    if (
      paymentDestinationObject instanceof ScheduleCalendarModel ||
      paymentDestinationObject instanceof StoreDocumentModel ||
      paymentDestinationObject instanceof InvoiceModel
    ) {
      return paymentDestinationObject?.uuid ? paymentDestinationObject : '';
    }

    return paymentDestinationObject || '';
  }
}
