import { Record } from 'immutable';
import {
  ProfileModel,
  CompanyModel,
  ProfileDTO,
  CompanyDTO,
  ProfileMapper,
  CompanyMapper,
  ReportTypes,
  ReportStatuses,
  ReportDTO,
} from '../internal';
import { WithoutRecursion } from '../type';
import { capitalize } from '@services/helpers';

export interface IReportModelReturnType {
  uuid: string;
  report_start_date: string;
  report_number: string;
  report_title: string;
  report_end_date: string;
  report_type: ReportTypes;
  report_status: ReportStatuses;
  report_path_url: string;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
}

export class ReportModel extends Record<IReportModelReturnType>({
  uuid: '',
  report_start_date: '',
  report_number: '',
  report_end_date: '',
  report_title: '',
  report_type: ReportTypes.Orders,
  report_status: ReportStatuses.Initialized,
  report_path_url: '',
  created_at: '',
  updated_at: '',
  created_by: {} as ProfileModel,
  company: {} as CompanyModel,
}) {
  constructor(
    props: ReportDTO = {} as ReportDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IReportModelReturnType = {
      ...props,

      report_title: `${capitalize(
        props?.report_type || ReportTypes.Orders,
      )} report`,

      // @ts-ignore
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by as ProfileDTO, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company as CompanyDTO, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  /**
   * @desc Повне Ім'я
   * @return {String}
   */

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }
}
