import { Col, MenuProps, Popconfirm, Row } from 'antd';
import { useAcl } from '@contex';
import { StoreDetailsView } from '../../Show';
import { useTranslation } from 'react-i18next';
import { HiOutlineDocument } from 'react-icons/hi2';
import { StoreReturnClientTabPanel } from '../Show';
import { ClientDetailsView } from '../../../Clients';
import styled, { useTheme } from 'styled-components';
import { toDateByFormat } from '@services/helpers';
import { productStoreDocumentRoutes } from '../../../Products';
import { Routes, RoutesAcl } from '@services/types';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewLayout } from '@components/lib/Layout';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { DocumentControlPanel } from '@components/lib/General';

import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';

import {
  Button,
  HeaderLeft,
  DetailsView,
  CommentField,
  SuspenseEmpty,
  IDetailsViewItemProps,
} from '@components/lib/DataDisplay';

import {
  useStopLoading,
  useStoredProfile,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
  useStateStoreDocumentItemList,
} from '@hooks';

import {
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
  StoreDocExtraServicesView,
} from '../../Show';

import {
  StoreDocumentType,
  StoreDocumentModel,
  StoreDocumentMapper,
  StorePaymentDocumentFormDTO,
  StorePaymentDocumentPostActionType,
  StoreDocumentStatus,
} from '@structure';

const CloseDocumentSideWindowLazy = React.lazy(
  () => import('../../Show/StorePaymentDocumentSideWindow'),
);

const StoreReturnClientSideWindowLazy = React.lazy(
  () => import('../Show/StoreReturnClientSideWindow'),
);

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

export function StoreReturnClientPage() {
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = useStoredProfile();
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [deletedLoading, setDeletedLoading] = useState(false);
  const { documentId, productId } = useParams();
  const { store: storeAccess } = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,
    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.CRETURN,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const storePayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        document || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel,
          comment: `${t(
            'Reimbursement of funds to the client for returned goods on the invoice',
          )} №${document?.doc_local_number} ${t('From')} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
        },
      ),
    [document, profile?.defaultEmployeeModel, t],
  );

  const {
    handleCancel: storePaymentHandleCancel,
    visible: storePaymentVisible,
    handleSuccess: storePaymentHandleSuccess,
    handleOnInit: storePaymentHandleOnInit,
  } = useModal<StorePaymentDocumentFormDTO>({
    onSuccess: async (value) => {
      await handlePaymentStoreDocument(value, { with_document: true });
    },
  });

  const {
    handleCancel: storeEditHandleCancel,
    handleOnInit: storeDocumentEditOnInit,
    handleSuccess: storeDocumentEditSuccess,
    visible: storeDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateStoreDocument,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store return client loading',
  });

  const { handleDeleteStoreDocuments: onDeleteStoreDocuments } =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.CRETURN,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeReturnClient}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    setDeletedLoading(true);
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
    setDeletedLoading(false);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeReturnClient}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeReturnClient}`,
            breadcrumbName: 'Invoices for return to the client',
          },
          {
            path: `/${Routes.stores}/${Routes.storeReturnClient}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show return client',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = useMemo(
    () => [
      {
        title: 'Client',
        className: 'client-details__view',
      },
      {
        title: 'Store',
        className: 'store-details__view',
      },
    ],
    [],
  );

  const printOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'Print document',
        icon: <HiOutlineDocument size={15} color={theme.colors.black} />,
        label: <StyledTitle>{t('Print document')}</StyledTitle>,
        onClick: handlePrintStoreDocument,
      },
    ],
    [t, theme.colors.black, handlePrintStoreDocument],
  );

  // @ts-ignore
  const documentOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'delete-product',
        label: (
          <Popconfirm
            title={t('Are you sure you want to delete the document?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onCancel={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            onConfirm={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();

              await handleDeleteStoreDocuments();
            }}>
            <StyledPopconfirmButton
              type="text"
              onClick={async (e: any) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              }}>
              {deletedLoading ? (
                <StyledLoadingOutlined />
              ) : (
                <StyledDeleteOutlined />
              )}
              <StyledTitle $color={theme.colors.error}>
                {t('Delete draft')}
              </StyledTitle>
            </StyledPopconfirmButton>
          </Popconfirm>
        ),
      },
    ],
    [t, deletedLoading, theme.colors.error, handleDeleteStoreDocuments],
  );

  return (
    <>
      <StoreReturnClientTabPanel document={document}>
        {({ tabs }) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Invoice for return to the client')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={storeDocumentEditOnInit}
                isDraft={
                  document?.doc_status_text === StoreDocumentStatus.DRAFT
                }
              />
              // document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              //   <StoreReturnClientMenuButton
              //     fromList={false}
              //     handlePaymentStoreDocument={handlePaymentStoreDocument}
              //     document={StoreDocumentMapper.toStoreDocumentFormDTO(
              //       document!,
              //       {
              //         editMode: true,
              //         client: document?.clientModel,
              //       },
              //     )}
              //     onSuccess={handleUpdateStoreDocument}
              //     handleDeleteStoreDocuments={handleDeleteStoreDocuments}
              //   />
              // ) : null
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader document={document} />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                // handlePrintStoreDocument={handlePrintStoreDocument}
                handlePaymentStoreDocument={storePaymentHandleOnInit}
                // handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit return to client')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StoreReturnClientTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <CloseDocumentSideWindowLazy
          title={`${t('Closing posting')}`}
          payment={storePayment!}
          loading={loading}
          onSuccess={storePaymentHandleSuccess}
          onCancel={storePaymentHandleCancel}
          visible={storePaymentVisible}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreReturnClientSideWindowLazy
          editMode
          document={
            StoreDocumentMapper.toStoreDocumentFormDTO(document!, {
              editMode: true,
            }) || null
          }
          onSuccess={storeDocumentEditSuccess}
          visible={storeDocumentVisible}
          onCancel={storeEditHandleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
