import { Record, List } from 'immutable';
import { IListProps } from './List';
import { MailingDTO, MailingModel } from '../internal';

export interface IMailingListModelProps extends IListProps {
  mailings: MailingDTO[];
}

export interface IMailingListModelReturnType extends IListProps {
  mailings: List<MailingModel>;
}

export class MailingListModel extends Record<IMailingListModelReturnType>({
  mailings: List(),
  total: 0,
}) {
  constructor(
    {
      mailings = [],
      ...props
    }: IMailingListModelProps = {} as IMailingListModelProps,
  ) {
    super({
      ...props,
      mailings: List(mailings.map((mailing) => new MailingModel(mailing))),
    });
  }
}
