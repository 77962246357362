import { PriceMarginModel } from '../internal';
export class PriceMarginFormDTO {
  public uuid: string;

  public price_name: string;

  public price_description: string;

  public price_margin: number;

  public price_default: boolean;

  constructor(props?: PriceMarginModel) {
    this.uuid = props?.uuid || '';
    this.price_name = props?.price_name || '';
    this.price_description = props?.price_description || '';
    this.price_margin = props?.price_margin || 0;
    this.price_default =
      typeof props?.price_is_default === 'boolean'
        ? props?.price_is_default
        : false;
  }
}
