import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TreeSelect } from 'antd';
import { listToArray } from '@services/helpers';
import {
  useProductCategoryListToSelectTree,
  useStoredCompanies,
  useStoredProductCategoryList,
} from '@hooks';
import { useTranslation } from 'react-i18next';
import { ProductCategoryMappedType, ProductCategoryType } from '@structure';

export interface IProductCategoryTreeViewProps {
  loading: boolean;
  disabled?: boolean;
  categoryUuid?: string;
  onCategorySelect: (value: string) => void;
}

const StyledTreeSelect = styled(TreeSelect)<{ disabled: boolean }>`
  width: 150px;

  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

export function ProductCategoryTreeView({
  loading,
  categoryUuid,
  onCategorySelect,
  disabled,
}: IProductCategoryTreeViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();

  const [value, setValue] = useState<string | undefined>(undefined);

  const once = useRef(false);

  const {
    categoryList,
    loading: categoryListLoading,
    handleSearchProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    productCategoryType: ProductCategoryType.TREE,
    mappedProductCategoryType: ProductCategoryMappedType.PRODUCT_TREE,
  });

  const { treeData } = useProductCategoryListToSelectTree({
    categories: categoryList,
  });

  const handleCategoryChange = useCallback(
    async (categoryUuid: any = '') => {
      setValue(categoryUuid || undefined);
      await onCategorySelect(categoryUuid);
    },
    [onCategorySelect],
  );

  useEffect(() => {
    if (!once.current) {
      once.current = true;
      if (categoryUuid) {
        setValue(categoryUuid);
      }
    }

    if (once.current) {
      if (!categoryUuid) {
        setValue(undefined);
      }
    }
  }, [categoryUuid]);

  return (
    <StyledTreeSelect
      popupMatchSelectWidth={220}
      showSearch
      treeDefaultExpandAll
      filterTreeNode={false}
      allowClear
      popupClassName="parent-node"
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      loading={loading || categoryListLoading}
      disabled={loading || !!disabled}
      onSearch={(keywords) => handleSearchProductCategories({ keywords })}
      onSelect={() => handleSearchProductCategories({ keywords: '' })}
      placeholder={t('Category')}
      treeData={treeData}
      onChange={handleCategoryChange}
      size="middle"
      value={value}
    />
  );
}
