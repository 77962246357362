import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form } from 'antd';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { CompanyOrderBonusDTO, FinancialSettingsDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useCompanyOrderBonusFieldParams } from '@hooks';

import '@components/lib/DataDisplay/FormEditableTable/Editable.less';

export interface ICompanyOrderBonusFormProps
  extends Omit<
    IDefaultFormProps<
      Partial<FinancialSettingsDTO>,
      Partial<FinancialSettingsDTO>
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  settings: Partial<FinancialSettingsDTO>;
  disabled?: boolean;
}

export function CompanyOrderBonusForm({
  loading,
  editMode,
  settings,
  disabled,
  ...rest
}: ICompanyOrderBonusFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const [instance] = Form.useForm();

  const { columns, companyOrderBonuses, components } =
    useCompanyOrderBonusFieldParams({
      companyOrderBonuses: settings?.company_order_bonuses || [],
      form: instance,
      type: 'company_order_bonuses',
    });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Parameters'),
        `${t('An error occurred during edit parameters')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm
      instance={instance}
      initialValues={settings}
      showNotify={false}
      notifyError={notifyError}
      disabledSubmitButton={disabled}
      {...rest}>
      {() => {
        return (
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                tooltip={t('Amount of reward')}
                label={t('Amount of reward')}
                name="company_order_bonuses">
                <Table<CompanyOrderBonusDTO>
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={companyOrderBonuses}
                  rowKey="id"
                  columns={columns}
                  total={companyOrderBonuses?.length}
                  pageSize={Infinity}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </DefaultForm>
  );
}
