import * as React from 'react';
import { List } from 'immutable';
import { useDropdownAlert } from '@contex';
import { RootState } from '@store/reducers';
import { useTranslation } from 'react-i18next';
import { REDUX_STATUS } from '@services/types';
import { StatusError } from '@components/lib/Errors';
import { useDispatch, useSelector } from 'react-redux';

import useService, {
  IUseServiceProps,
  IUseServiceReturnType,
} from './useService';

import {
  EmployeeModel,
  ServiceStatuses,
  ServiceFormDTO,
  ServiceMapper,
  ServiceModel,
  RewardType,
} from '@structure';

import {
  blockService,
  createService,
  updateService,
  updateServiceComment,
} from '@services/api/services';

import {
  addServiceToEmployeeList as storeAddServiceToEmployeeList,
  resetService as storeResetService,
  setService as storeSetService,
  updateEmployee as storeUpdateEmployee,
  updateService as storeUpdateService,
  updateServiceFromEmployeeList as storeUpdateServiceFromEmployeeList,
  updateEmployeeServiceFromList as storeUpdateEmployeeServiceFromList,
  addAttachedServiceToEmployeeList as storeAddAttachedServiceToEmployeeList,
  updateEmployeeAttachedServiceFromList as storeUpdateEmployeeAttachedServiceFromList,
} from '@store/actions';

export interface IUseStateServiceProps extends IUseServiceProps {
  companyUuid: string;
}

export interface IUseStateServiceReturnType
  extends Omit<IUseServiceReturnType, 'entity'> {
  service: ServiceModel | null;
  handleUpdateService: (value: ServiceFormDTO) => Promise<ServiceModel>;
  handleCreateService: (value: ServiceFormDTO) => Promise<ServiceModel>;
  handleUpdateServiceComment: (comment: string) => Promise<void>;
  handleBlockService: (
    serviceUuid: string,
    status: ServiceStatuses,
  ) => Promise<ServiceModel | void>;
  updateEmployee: (
    employeeUuid: string,
    serviceModel: ServiceModel,
    isDelete?: boolean,
  ) => void;
  handleResetService: () => void;
  handleRefreshService: () => Promise<ServiceModel | void>;
  status: REDUX_STATUS;
}

export function useStoreService({
  companyUuid,
  loadOnInit,
  serviceUuid,
  ...rest
}: IUseStateServiceProps): IUseStateServiceReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [service, setService] = React.useState<ServiceModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedServiceStatus,
    cachedServiceList,
    ...storedServiceParams
  } = useSelector(({ service }: RootState) => service);

  const { cachedEmployeeList } = useSelector(
    ({ employee }: RootState) => employee,
  );

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const { entity, refresh, ...serviceParams } = useService({
    loadOnInit: loadOnInit || isStarLoading,
    serviceUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedServiceList?.size > 0 && !service) {
      const service = cachedServiceList.get(serviceUuid);

      if (service) {
        setIsStartLoading(false);
        setService(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedServiceList?.size === 0 && !service) {
      setIsStartLoading(true);
    }
  }, [cachedServiceList, dispatch, serviceUuid, service]);

  React.useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetService(entity));
      setService(entity);
    }
  }, [dispatch, entity, isStarLoading, storedServiceStatus]);

  const setEmployeeServices = React.useCallback(
    (cachedEmployee: EmployeeModel, serviceModel: ServiceModel) => {
      return cachedEmployee.update('services', (services) => {
        if (List.isList(services)) {
          const cachedService = services.find(
            ({ uuid }) => uuid === serviceModel?.uuid,
          );

          if (cachedService) {
            return services?.map((storedService) => {
              if (storedService?.uuid === cachedService?.uuid) {
                return serviceModel;
              }
              return storedService;
            }, []);
          }

          return services.push(serviceModel);
        } else {
          return ServiceMapper.toServiceListModel([
            ServiceMapper.toServiceDTO(ServiceModel as any),
          ]).services;
        }
      });
    },
    [],
  );

  const handleUpdateServiceComment = React.useCallback(
    async (comment: string): Promise<void> => {
      try {
        const serviceDTO = await updateServiceComment(comment, serviceUuid);
        const serviceModel = ServiceMapper.toServiceModel(serviceDTO);

        dispatch(storeUpdateService(serviceModel));

        setService(serviceModel);

        alert(
          'success',
          t('Service'),
          t('The service comment has been successfully updated'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Service'),
          `${t('An error occurred during edit service item')} : ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, serviceUuid, t],
  );

  const deleteEmployeeService = React.useCallback(
    (cachedEmployee: EmployeeModel, serviceUuid: string) => {
      return cachedEmployee.update('services', (services) => {
        if (List.isList(services)) {
          return services.filter(({ uuid }) => uuid !== serviceUuid);
        }
        return services;
      });
    },
    [],
  );

  const updateEmployee = React.useCallback(
    (employeeUuid: string, serviceModel: ServiceModel, isDelete?: boolean) => {
      const cachedEmployee = cachedEmployeeList.find(
        ({ uuid }) => uuid === employeeUuid,
      );

      if (cachedEmployee) {
        if (isDelete) {
          const employeeModel = deleteEmployeeService(
            cachedEmployee,
            serviceModel?.uuid,
          );

          dispatch(storeUpdateEmployee(employeeModel));
        } else {
          const employeeModel = setEmployeeServices(
            cachedEmployee,
            serviceModel,
          );

          dispatch(storeUpdateEmployee(employeeModel));
        }
      }
    },
    [cachedEmployeeList, deleteEmployeeService, dispatch, setEmployeeServices],
  );

  const handleUpdateService = React.useCallback(
    async (value: ServiceFormDTO): Promise<ServiceModel> => {
      const manager_uid =
        typeof value?.manager_uid === 'string'
          ? value?.manager_uid
          : value?.manager_uid?.uuid;

      const service = await updateService(
        {
          ...value,
          manager_uid,
          payment_group_uuid:
            typeof value?.payment_group_uuid === 'string'
              ? value?.payment_group_uuid
              : value?.payment_group_uuid?.uuid,
        },
        companyUuid,
      );

      const serviceModel = ServiceMapper.toServiceModel(service);

      dispatch(storeUpdateService(serviceModel));
      dispatch(storeUpdateServiceFromEmployeeList(serviceModel));
      dispatch(
        storeUpdateEmployeeAttachedServiceFromList(serviceModel, manager_uid),
      );

      setService(serviceModel);

      return serviceModel;
    },
    [companyUuid, dispatch],
  );

  const handleCreateService = React.useCallback(
    async (value: ServiceFormDTO): Promise<ServiceModel> => {
      const service = await createService(value, companyUuid);
      const serviceModel = ServiceMapper.toServiceModel(service);

      const updatedModel = serviceModel.set('employee_service', {
        price: serviceModel?.price,
        duration: service?.period_amount,
        reward_value: 0,
        reward_type: RewardType.Percentage,
        updated_at: new Date().toString(),
      } as any);

      dispatch(storeAddServiceToEmployeeList(serviceModel));
      dispatch(
        storeUpdateEmployeeServiceFromList(
          updatedModel,
          value.manager_uid as string,
        ),
      );
      dispatch(
        storeAddAttachedServiceToEmployeeList(
          updatedModel,
          value?.manager_uid as string,
        ),
      );

      return serviceModel;
    },
    [companyUuid, dispatch],
  );

  const handleResetService = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetService());
  }, [dispatch]);

  const handleRefreshService =
    React.useCallback(async (): Promise<ServiceModel | void> => {
      const serviceModel = await refresh({ showLoading: false, serviceUuid });

      if (serviceModel) {
        setService(serviceModel);
        dispatch(storeUpdateService(serviceModel));

        return serviceModel;
      }
    }, [dispatch, refresh, serviceUuid]);

  const handleBlockService = React.useCallback(
    async (
      serviceUuid: string,
      status: ServiceStatuses,
    ): Promise<ServiceModel | void> => {
      try {
        const serviceDTO = await blockService(serviceUuid, status);
        const serviceModel = ServiceMapper.toServiceModel(serviceDTO);

        dispatch(storeUpdateService(serviceModel));

        alert(
          'success',
          status === ServiceStatuses.Blocked
            ? t('Service lock')
            : t('Service unlock'),
          status === ServiceStatuses.Blocked
            ? t('Service lock success')
            : t('Service unlock success'),
        );

        return serviceModel;
      } catch (error: any) {
        alert(
          'error',
          status === ServiceStatuses.Blocked
            ? t('Service lock')
            : t('Service unlock'),
          `${t(
            `An error occurred during ${
              status === ServiceStatuses.Blocked ? 'lock' : 'unlock'
            } service`,
          )} : ${error?.message}`,
        );
      }
    },
    [alert, dispatch, t],
  );

  return {
    ...serviceParams,
    ...storedServiceParams,
    service,
    loading: !service,
    handleUpdateService,
    handleCreateService,
    handleUpdateServiceComment,
    status: storedServiceStatus,
    handleResetService,
    updateEmployee,
    refresh,
    handleRefreshService,
    handleBlockService,
  };
}
