import React from 'react';
import { Col, Form } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { List } from 'immutable';
import { isListToArray } from '@services/helpers';
import { SearchSelect } from '@components/lib/DataDisplay';
import { ClientModel } from '@structure';

export default withTranslation()(FinancialEmployeeUuidField);

export interface IFinancialEmployeeUuidFieldProps extends WithTranslation {
  clients: List<ClientModel> | null;
  disable?: boolean;
  label?: string;
  ruleMessage?: string;
  tooltip?: string;
  name?: string;
}

function FinancialEmployeeUuidField({
  t,
  clients,
  disable = false,
  label,
  ruleMessage,
  tooltip,
  name,
}: IFinancialEmployeeUuidFieldProps): JSX.Element {
  return (
    <Col span={24}>
      <Form.Item
        tooltip={tooltip || ''}
        label={label || t('Keep records on behalf of')}
        name={name || 'financial_client_uuid'}
        rules={[
          {
            required: true,
            message: ruleMessage || t('Keep records on behalf of'),
          },
        ]}>
        <SearchSelect
          selectFirst
          name={name || 'financial_client_uuid'}
          placeholder={t('Select client')}
          data={isListToArray(clients as any)}
          getOptionValueProps="uuid"
          getOptionValueTitle="fullNameClient"
          getOptionValueSecond="phone"
          disable={disable}
        />
      </Form.Item>
    </Col>
  );
}
