import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  ScheduleCalendarLatestStatusModel,
  ScheduleCalendarModel,
  StatusCategoryInternalCode,
  StatusCategoryModel,
  StatusModel,
} from '@structure';
import { List } from 'immutable';
import {
  StatusProgressItemProps,
  StatusProgress,
} from '@components/lib/DataDisplay';
import { listToArray } from '@services/helpers';
import { STATUS_CATEGORY_ICONS, StatusListSelect } from '../../Statuses';
import styled from 'styled-components';
import {
  useOutsideClickHandler,
  useStateSchedule,
  useStateSchedulePaymentList,
} from '@hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { StatusError } from '@components/lib/Errors';

export interface OrderStatusesProgressProps {
  categoryList: List<StatusCategoryModel> | null;
  activeStatus: ScheduleCalendarLatestStatusModel;
  statusList: List<StatusModel> | null;
  schedule: ScheduleCalendarModel;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

const ALLOWED_STATUSES_FOR_PROGRESS = [
  StatusCategoryInternalCode.New,
  StatusCategoryInternalCode.InProgress,
  StatusCategoryInternalCode.Completed,
  StatusCategoryInternalCode.Closed,
];

const StyledStatusListSelect = styled(StatusListSelect)`
  position: absolute;
  bottom: 0;
  visibility: hidden;

  &&& {
    .ant-select-selection-item {
      padding: 0 !important;
      padding-inline-end: 0 !important;
    }

    .ant-select-selector {
      padding: 0px !important;
    }
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: fit-content;
`;

const StyledProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const DISABLED_STATUSES = [
  StatusCategoryInternalCode.Closed,
  StatusCategoryInternalCode.Canceled,
];

export function OrderStatusesProgress({
  categoryList,
  statusList,
  activeStatus,
  schedule,
  handleUpdateScheduleState,
}: OrderStatusesProgressProps): React.JSX.Element {
  const [allowedStatusCategories, setAllowedStatusCategories] = useState<
    StatusProgressItemProps[] | null
  >(null);

  const [activeIndex, setActiveIndex] = useState(-1);
  const [allowedStatusesTo, setAllowedStatusesTo] =
    useState<List<StatusModel> | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const contentRef = useRef(null);

  const disabled =
    loading ||
    DISABLED_STATUSES.includes(
      activeStatus?.custom_status?.category?.internal_code,
    );

  const listAllowedStatusesTo =
    schedule?.latestStatusModel?.customStatusModel?.allowed_statuses_to;

  const { handleCreateSchedulePrepayment } = useStateSchedulePaymentList({
    loadOnInit: false,
    scheduleUuid: schedule?.uuid,
  });

  const { handleChangeScheduleStatus, handleCloseSchedule } = useStateSchedule({
    scheduleUuid: schedule?.uuid,
  });

  useEffect(() => {
    if (List.isList(categoryList) && !allowedStatusCategories?.length) {
      const allowedStatusCategories = categoryList
        .filter(({ internal_code }) =>
          ALLOWED_STATUSES_FOR_PROGRESS.includes(internal_code),
        )
        .map(({ title, colour }, index) => ({
          color: colour,
          position: index,
        }));

      setAllowedStatusCategories(listToArray(allowedStatusCategories));
    }
  }, [allowedStatusCategories?.length, categoryList]);

  useEffect(() => {
    if (List.isList(categoryList) && activeStatus) {
      const index = categoryList
        ?.filter(({ internal_code }) =>
          ALLOWED_STATUSES_FOR_PROGRESS.includes(internal_code),
        )
        .findIndex(
          ({ internal_code }) =>
            internal_code ===
            activeStatus?.custom_status?.category?.internal_code,
        );

      setActiveIndex(index);
    }
  }, [activeStatus, categoryList]);

  useEffect(() => {
    if (listAllowedStatusesTo?.length && List.isList(statusList)) {
      const allowedStatusesToList = statusList.filter(({ uuid }) =>
        listAllowedStatusesTo.includes(uuid),
      );

      setAllowedStatusesTo(allowedStatusesToList);
    }
  }, [listAllowedStatusesTo, statusList]);

  const handleChangeStatus = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  useOutsideClickHandler(
    contentRef,
    () => {
      setOpen(false);
    },
    '',
    ['order-status-progress'],
  );

  return (
    <StyledContainer ref={contentRef}>
      <StyledProgressContainer>
        <StatusProgress
          title={activeStatus?.custom_status?.title}
          color={activeStatus?.custom_status?.category?.colour}
          icon={
            STATUS_CATEGORY_ICONS[
              String(activeStatus?.custom_status?.category?.internal_code)
            ]
          }
          statuses={allowedStatusCategories}
          activeIndex={activeIndex}
          onClick={disabled ? undefined : handleChangeStatus}
          disabled={disabled}
        />
        {loading ? <LoadingOutlined /> : null}
      </StyledProgressContainer>
      <StyledStatusListSelect
        popupClassName="order-status-progress"
        open={open}
        schedule={schedule}
        allowedStatusesTo={allowedStatusesTo}
        selectedStatus={schedule?.latestStatusModel?.customStatusModel!}
        loading={false}
        handleChangeScheduleStatus={async (value) => {
          try {
            setLoading(true);
            setOpen(false);
            const schedule = await handleChangeScheduleStatus(value);

            if (schedule) {
              handleUpdateScheduleState(schedule);
            }

            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            throw new StatusError(error?.message, error?.status);
          }
        }}
        handleCreateSchedulePrepayment={handleCreateSchedulePrepayment}
        handleCloseSchedule={handleCloseSchedule}
      />
    </StyledContainer>
  );
}
