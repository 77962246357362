import { Layout } from 'antd';
import AppMenu from './AppMenu';
import { AppSiderHeader } from './common';
import styled, { css } from 'styled-components';
import { AppSideFooter } from './AppSideFooter';
import { ProfileModel } from '@structure';
import { useDropdownAlert } from '@contex';
import { isMobile } from '@services/helpers';

import React, {
  useCallback,
  useMemo,
  createContext,
  useContext,
  useEffect,
  MutableRefObject,
} from 'react';

import {
  useBigPhoneScreenMediaQuery,
  usePhoneScreenMediaQuery,
  useLocalStorage,
  useSize,
} from '@hooks';

export interface IAppLayoutProps {
  defaultCompanyUuid: string;
  loading: boolean;
  profile: ProfileModel;
  companyTitle: string;
  headerTitle: string;
  children: React.ReactNode | React.ReactNode[];
}

export interface IAppLayoutContext {
  contentWidth: number;
  contentRef: MutableRefObject<HTMLDivElement>;
}

export const AppLayoutContext = createContext<IAppLayoutContext>({
  contentWidth: 0,
  contentRef: { current: null as any },
});

var vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty('--vh', vh + 'px');

export const useAppLayout = () => useContext(AppLayoutContext);

const StyledAppLayout = styled(Layout)<{ $isMobile: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.background.layout};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100dvw;
      height: 100dvh;
      height: calc(var(--vh) * 100);
    `}
`;

const StyledSider = styled(Layout.Sider)<{
  $isHiddenContent: boolean;
  $isBigPhone: boolean;
  collapsed: boolean;
}>`
  z-index: 2;
  background-color: ${({ theme }) => theme.background.sidebar};
  padding-right: 15px;
  padding-left: 15px;
  overflow: hidden;

  ${({ $isBigPhone, collapsed }) =>
    $isBigPhone &&
    !collapsed &&
    css`
      &&& {
        z-index: 99999999999;
      }
    `}

  ${({ $isHiddenContent }) =>
    $isHiddenContent &&
    css`
      padding: 0;
    `}
`;

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  width: 100%;
  height: 100%;
`;

const StyledSiderMenuContent = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  width: 100%;
  height: 100%;
`;

const StyledContent = styled(Layout.Content)<{ $isMobile: boolean }>`
  background-color: ${({ theme }) => theme.background.primary};
  overflow-y: auto;
  overflow-x: hidden;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: fit-content;
    `}
`;

export default function AppLayout({
  children,
  defaultCompanyUuid,
  companyTitle,
  headerTitle,
}: IAppLayoutProps) {
  const { setDropdownOptions } = useDropdownAlert();

  const contentRef = React.useRef<any>(null);
  const { width: contentWidth } = useSize(contentRef);
  const isBigPhone = useBigPhoneScreenMediaQuery();
  const isPhone = usePhoneScreenMediaQuery();

  const [navCollapsed, setNavCollapsed] = useLocalStorage(
    'sider-collapsed',
    false,
  );

  const onNavCollapsed = useCallback(() => {
    setNavCollapsed((prev: any) => !prev);
  }, [setNavCollapsed]);

  const context = useMemo(
    () => ({
      contentWidth,
      contentRef,
    }),
    [contentWidth],
  );

  useEffect(() => {
    if (contentWidth) {
      setDropdownOptions({
        init: true,
        style: {
          width: contentWidth,
        },
      });
    }
  }, [contentWidth, setDropdownOptions]);

  return (
    <AppLayoutContext.Provider value={context}>
      <StyledAppLayout hasSider={true} $isMobile={!!isMobile.any()}>
        <StyledSider
          $isHiddenContent={isPhone && navCollapsed}
          collapsible={navCollapsed}
          width={isBigPhone ? '100%' : 300}
          breakpoint="md"
          collapsed={navCollapsed}
          // onCollapse={onNavCollapsed}
          collapsedWidth={isPhone ? 50 : undefined}
          $isBigPhone={isBigPhone}
          trigger={null}>
          <StyledSiderContent>
            <StyledSiderMenuContent>
              <AppSiderHeader
                isBigPhone={isBigPhone}
                headerTitle={headerTitle}
                collapsed={navCollapsed}
                onCollapse={onNavCollapsed}
              />
              <AppMenu
                companyTitle={companyTitle}
                collapsed={navCollapsed}
                companyUuid={defaultCompanyUuid}
              />
            </StyledSiderMenuContent>

            <AppSideFooter collapsed={navCollapsed} />
          </StyledSiderContent>
        </StyledSider>

        <Layout>
          <StyledContent
            ref={contentRef}
            $isMobile={!!isMobile.any()}
            className="app-content__observe">
            {children || <span>no content</span>}
          </StyledContent>
          {/*<StyledFooter>*/}
          {/*  <AppFooter />*/}
          {/*</StyledFooter>*/}
        </Layout>
      </StyledAppLayout>
    </AppLayoutContext.Provider>
  );
}
