import { Record, List } from 'immutable';
import { IListProps } from './List';
import { EmployeeDTO, EmployeeModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IEmployeeListModelProps extends IListProps {
  employees: EmployeeDTO[];
}

export interface IEmployeeListModelReturnType extends IListProps {
  employees: List<EmployeeModel>;
  keywords?: string;
  page?: number;
}

export class EmployeeListModel extends Record<IEmployeeListModelReturnType>({
  employees: List(),
  total: 0,
  page: 1,
  keywords: '',
}) {
  constructor(
    {
      employees = [],
      ...props
    }: IEmployeeListModelProps = {} as IEmployeeListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      ...props,
      employees: List(
        employees.map(
          (employee) => new EmployeeModel(employee, withoutRecursion),
        ),
      ),
    });
  }
}
