import React, { useMemo, useCallback, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import {
  IntegrationFormDTO,
  IntegrationCheckboxAttributesDTO,
  CheckboxAttributesCashBox,
} from '@structure';
import { useDropdownAlert } from '@contex';
import { useIntegrationCheckboxFieldParams, useModal } from '@hooks';
import { Col, Empty, Form, Row } from 'antd';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { SuspenseEmpty, Button, FormSwitch } from '@components/lib/DataDisplay';
import { produce } from 'immer';
import styled from 'styled-components';
import { StyledTitle, successButtonStyle } from '@components/lib/Styled';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { isFunction, fromBooleanToNumber } from '@services/helpers';

const IntegrationCheckboxItemModalLazy = React.lazy(
  () => import('../Show/IntegrationCheckboxItemModal'),
);

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<CheckboxAttributesCashBox>>;

export interface IntegrationCheckboxFormProps
  extends Omit<
    IDefaultFormProps<
      IntegrationFormDTO<IntegrationCheckboxAttributesDTO>,
      IntegrationFormDTO<IntegrationCheckboxAttributesDTO>
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  attributes: IntegrationFormDTO<IntegrationCheckboxAttributesDTO>;
}

const StyledButton = styled(Button)`
  ${successButtonStyle};
  border-radius: 7px;
  width: fit-content;

  & > * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
`;

export function IntegrationCheckboxForm({
  loading,
  attributes: defaultAttributes,
  editMode,
  onSuccess,
  ...rest
}: IntegrationCheckboxFormProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const [instance] = Form.useForm();

  const attributes = Form.useWatch('attributes', instance);
  const disabledCashBoxes = (
    defaultAttributes?.attributes?.cashboxes || []
  ).map(({ uuid }) => uuid!);

  const [selectedCashBox, setSelectedCashBox] = useState<
    CheckboxAttributesCashBox | undefined
  >(undefined);

  const additionalValues = useMemo(
    () => ({
      uuid: defaultAttributes?.uuid,
    }),
    [defaultAttributes?.uuid],
  );

  const handleAddOrUpdateCashBox = useCallback(
    ({ t, ...value }: CheckboxAttributesCashBox & { t: any }) => {
      const attributes = instance.getFieldValue('attributes');

      let updatedAttributes;

      if (value?.uuid) {
        updatedAttributes = produce<IntegrationCheckboxAttributesDTO>(
          attributes,
          (draft) => {
            draft.cashboxes = draft.cashboxes?.map((item) => {
              if (item?.uuid === value?.uuid) {
                return value;
              }
              return item;
            });
          },
        );
      } else {
        updatedAttributes = produce<IntegrationCheckboxAttributesDTO>(
          attributes,
          (draft) => {
            draft.cashboxes = [
              ...draft.cashboxes,
              { ...value, uuid: uuidv4() },
            ];
          },
        );
      }

      instance.setFieldsValue({ attributes: updatedAttributes });

      setSelectedCashBox(undefined);
    },
    [instance],
  );

  const handleDeleteCashBox = useCallback(
    (value: CheckboxAttributesCashBox) => {
      const attributes = instance.getFieldValue('attributes');

      const updatedAttributes = produce<IntegrationCheckboxAttributesDTO>(
        attributes,
        (draft) => {
          draft.cashboxes = draft.cashboxes.filter(
            ({ uuid }) => uuid !== value?.uuid,
          );
        },
      );

      instance.setFieldsValue({ attributes: updatedAttributes });
    },
    [instance],
  );

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onSuccess: handleAddOrUpdateCashBox,
    onCancel: () => setSelectedCashBox(undefined),
  });

  const handleUpdateCashBox = useCallback(
    (value: CheckboxAttributesCashBox) => {
      setSelectedCashBox(value);
      handleOnInit();
    },
    [handleOnInit],
  );

  const { columns, components } = useIntegrationCheckboxFieldParams({
    form: instance,
    handleUpdateCashBox,
    handleDeleteCashBox,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Integration'),
        `${
          editMode
            ? t('An error occurred during edit integration')
            : t('An error occurred during create integration')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const handleSuccessForm = useCallback(
    async (value: IntegrationFormDTO<IntegrationCheckboxAttributesDTO>) => {
      const updatedValue = produce<
        IntegrationFormDTO<IntegrationCheckboxAttributesDTO>
      >(value, (draft) => {
        draft.attributes.cashboxes = draft.attributes.cashboxes.map(
          ({ uuid, ...rest }) => ({ ...rest }),
        );

        draft.status = fromBooleanToNumber(draft?.status as any);
      });

      if (isFunction(onSuccess)) {
        await onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  return (
    <>
      <DefaultForm<
        IntegrationFormDTO<IntegrationCheckboxAttributesDTO>,
        IntegrationFormDTO<IntegrationCheckboxAttributesDTO>
      >
        instance={instance}
        initialValues={{
          ...defaultAttributes,
        }}
        onSuccess={handleSuccessForm}
        additionalValuesRequest={additionalValues}
        editMode={editMode}
        notifyError={notifyError}
        showNotify={false}
        {...rest}>
        {({ loadingSubmit, getFieldValue, setFieldsValue }) => {
          return (
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  tooltip={t('Cash boxes')}
                  label={t('Cash boxes')}
                  name={['attributes', 'cashboxes']}>
                  <StyledTable
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={attributes?.cashboxes}
                    columns={columns}
                    total={attributes?.cashboxes?.length}
                    pageSize={Infinity}
                    renderEmpty={
                      <Empty description={t('There are no cash boxes added')}>
                        <StyledButton
                          disabled={loadingSubmit}
                          icon={<PlusOutlined />}
                          onClick={handleOnInit}>
                          <StyledTitle>{t('Add cash box')}</StyledTitle>
                        </StyledButton>
                      </Empty>
                    }
                    footer={
                      attributes?.cashboxes?.length
                        ? () => (
                            <StyledFooter>
                              <StyledButton
                                disabled={loadingSubmit}
                                icon={<PlusOutlined />}
                                onClick={handleOnInit}>
                                <StyledTitle>{t('Add cash box')}</StyledTitle>
                              </StyledButton>
                            </StyledFooter>
                          )
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <FormSwitch
                span={24}
                loading={loadingSubmit || loading}
                disabled={loadingSubmit}
                name="status"
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                label={null}
                title={t('Activated')}
              />
            </Row>
          );
        }}
      </DefaultForm>
      <Suspense fallback={<SuspenseEmpty />}>
        <IntegrationCheckboxItemModalLazy
          onSuccess={handleSuccess as any}
          onCancel={handleCancel}
          cashBox={selectedCashBox}
          visible={visible}
          disabledCashBoxes={disabledCashBoxes}
          attributes={attributes}
        />
      </Suspense>
    </>
  );
}
