import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import { IntegrationAssociationDTO, IntegrationCategoryType } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import {
  getIntegrationListByCategory,
  IntegrationListByCategoryReturnType,
} from '@services/api/integration';

export interface IUseIntegrationListByCategoryProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  categoryType: IntegrationCategoryType;
}

export interface IUseIntegrationListByCategoryReturnType<T> {
  error: null | ApiError;
  loading: boolean;
  entityList: IntegrationAssociationDTO<T>[] | null;
  refresh: (
    value: Partial<IUseIntegrationListByCategoryProps>,
  ) => Promise<IntegrationAssociationDTO<T>[] | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  categoryType: IntegrationCategoryType;
}

export function useIntegrationListByCategory<T>(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    categoryType,
    companyUuid,
  }: IUseIntegrationListByCategoryProps = {} as IUseIntegrationListByCategoryProps,
): IUseIntegrationListByCategoryReturnType<T> {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    categoryType: listCategoryType,
    companyUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseIntegrationListByCategoryReturnType<T>, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    categoryType,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      categoryType = listCategoryType,
    }: Partial<IUseIntegrationListByCategoryProps> = {}): Promise<
      IntegrationAssociationDTO<T>[] | void
    > => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          categoryType,
        });

        const { associations, total } = await cancellablePromise<
          IntegrationListByCategoryReturnType<T>
        >(
          getIntegrationListByCategory({
            limit,
            offset,
            keywords,
            companyUuid,
            categoryType,
          }),
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: associations,
            total: total,
            loading: false,
          });

          return associations;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCategoryType,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid && categoryType) {
      (async () => {
        await refresh({ companyUuid, categoryType });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid, categoryType]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    categoryType: listCategoryType,
  };
}
