import React, { useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CreateCompanyForm } from '../Forms';
import { useStoredAuthData, useStoredCompanies } from '@hooks';
import { AuthLayout, HeaderText } from '../../Authorization/Show';
import { Space } from 'antd';
import styled from 'styled-components';
import { Routes } from '@services/types';
import { ICreateCompanyParam } from '@services/api/company';
import { useDropdownAlert } from '@contex';
import { useNavigate } from 'react-router';

export default withTranslation()(CreateCompanyPage);

export interface ICreateCompanyPageProps extends WithTranslation {}

const StyledSpace = styled(Space)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
`;

function CreateCompanyPage({ t }: ICreateCompanyPageProps): JSX.Element {
  const navigate = useNavigate();

  const { authData } = useStoredAuthData();
  const { alert } = useDropdownAlert();

  const { createCompany } = useStoredCompanies({
    companyUuid: '',
  });

  const handleSuccess = useCallback(
    async ({ title }: ICreateCompanyParam) => {
      const identifier = authData?.user?.phone;

      const data = identifier?.includes('@')
        ? { email: identifier }
        : { phone: identifier };

      if (identifier) {
        const companyModel = await createCompany({ title, ...data });

        alert('success', t('Company'), t('Your first company create success'));

        if (companyModel?.uuid) {
          navigate(
            `/${Routes.app}/${Routes.companies}/${Routes.waitingForCompanyCreate}`,
            {
              state: {
                companyUuid: companyModel?.uuid,
              },
            },
          );
        }
      }
    },
    [alert, authData?.user?.phone, createCompany, navigate, t],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Company'),
        `${t('An error occurred during create company')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <AuthLayout>
      <StyledSpace direction="vertical" size="large">
        <HeaderText
          title={t('Create Company')}
          withoutLogo={false}
          upperCase={false}
        />
        <CreateCompanyForm
          notifyError={notifyError}
          handleSuccess={handleSuccess}
        />
      </StyledSpace>
    </AuthLayout>
  );
}
