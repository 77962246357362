import { Record } from 'immutable';
import {
  PhotoDTO,
  PhotoStatus,
  PhotoType,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface PhotoModelReturnType {
  uuid: string;
  file_original_name: string;
  file_url: string;
  fileable_type: PhotoType;
  file_status_text: PhotoStatus;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel;
}

export class PhotoModel extends Record<PhotoModelReturnType>({
  uuid: '',
  file_original_name: '',
  file_url: '',
  fileable_type: PhotoType.PRODUCT,
  file_status_text: PhotoStatus.ENABLE,
  created_at: '',
  updated_at: '',
  created_by: {} as ProfileModel,
}) {
  constructor(
    props: PhotoDTO = {} as PhotoDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: PhotoModelReturnType = {
      ...props,

      created_by: ProfileMapper.toProfileModel(
        ProfileMapper.toProfileDTO(props?.created_by),
        [WithoutRecursion.invoice, ...withoutRecursion],
      ),
    };

    super(options);
  }
}
