import { ForgotPasswordDTO, ForgotPasswordFormDTO } from '../internal';

export class ForgotPasswordMapper {
  public static toForgotPasswordDTO(
    forgotPasswordFormDTO: ForgotPasswordFormDTO,
  ): ForgotPasswordDTO {
    return new ForgotPasswordDTO({
      identifier: forgotPasswordFormDTO?.identifier,
    });
  }

  public static toForgotPasswordFormDTO(
    forgotPasswordDTO: ForgotPasswordDTO,
  ): ForgotPasswordFormDTO {
    return new ForgotPasswordFormDTO({
      identifier: forgotPasswordDTO?.identifier,
    });
  }
}
