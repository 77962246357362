import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { useModal } from '@components/lib/libV2/hooks';
import { ProfileFormDTO } from '@structure';

const ProfileSideWindowLazy = React.lazy(
  () => import('../Show/ProfileSideWindow'),
);

export interface IProfileEditButtonProps extends IEditButtonProps {
  profile: ProfileFormDTO;
  onSuccess: (value: ProfileFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export default function ProfileEditButton({
  children,
  profile,
  onSuccess,
  onCancel,
  ...rest
}: IProfileEditButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit profile')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ProfileSideWindowLazy
          user={profile}
          editMode
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
