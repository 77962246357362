import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateTemplateList,
  useStoredCompanies,
} from '@hooks';
import { TemplateList, TemplateCreateButton } from '../../Templates';
import { textToUpperCase } from '@services/helpers';
import { ListLayout } from '@components/lib/Layout';
import { useAcl } from '@contex';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { successButtonStyle } from '@components/lib/Styled';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { useLocation, useNavigate } from 'react-router';
import { GroupPaymentMapper } from '@structure';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledTemplateCreateButton = styled(TemplateCreateButton)`
  ${successButtonStyle}
`;

export function TemplatePage(): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ template }) => template);
  const location = useLocation();
  const navigate = useNavigate();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'template-create-event',
    disabled: !manage,
  });

  const {
    templates,
    loading,
    limit,
    refresh,
    handleCreateTemplate,
    handleSearchTemplates,
    handleDeleteTemplates: onDeleteTemplates,
    ...rest
  } = useStateTemplateList({
    companyUuid: defaultCompanyUuid,
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteTemplates = useCallback(
    async (value: string[]) => {
      await onDeleteTemplates(value);
      focus();
    },
    [focus, onDeleteTemplates],
  );

  // const handleChangeTemplateType = useCallback(
  //   async (type: TEMPLATES_TYPE): Promise<void> => {
  //     await refresh({offset: 0, limit, type});
  //   },
  //   [refresh, limit],
  // );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.mailingTemplates}`,
      breadcrumbName: 'notification templates',
    },
  ]);

  const from = location?.state?.prevState
    ? location?.state?.prevState?.from
    : location?.state?.from;

  const navigateGoBack = useCallback(() => {
    navigate(
      from ? from : `/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`,
      {
        state: { prevState: location?.state },
      },
    );
  }, [defaultCompanyUuid, from, location?.state, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.mailingTemplates]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.mailingTemplates]))}
      headerRight={
        <StyledTemplateCreateButton
          disabled={loading || !manage}
          onSuccess={handleCreateTemplate}
          loading={false}
          type="primary"
          title={t('Create')}
          icon={<PlusOutlined />}
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        templates?.length ? null : (
          <Empty description={t('There are no templates')}>
            <StyledTemplateCreateButton
              disabled={loading || !manage}
              onSuccess={handleCreateTemplate}
              loading={false}
              type="primary"
              title={t('Create')}
              icon={<PlusOutlined />}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      loading={loading && !templates?.length}
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={false}
          handleSearch={(keywords) => handleSearchTemplates({ keywords })}
          inputTooltip={t('Search templates')}
          inputLabel={t('Search templates')}
          withSearchContent={null}
        />
      }>
      <TemplateList
        limit={limit}
        refresh={refresh}
        templates={templates}
        loading={loading}
        disabled={!manage}
        pageSize={limit}
        handleDeleteTemplates={handleDeleteTemplates}
        daleyFocus={daleyFocus}
        {...rest}
      />
    </ListLayout>
  );
}
