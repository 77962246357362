import React from 'react';
import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import Color from 'color';
import { ProductType } from '@services/types';
import { RiPencilRulerLine } from 'react-icons/ri';
import { TbCategory2 } from 'react-icons/tb';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isThereContent } from '@services/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { IProductsListStatsProps } from '@structure';

export interface IProductTypeViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type: ProductType;
  color: string;
  selected?: boolean;
  title: string;
  stats: IProductsListStatsProps;
  onClick: (type: ProductType) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 20px;
`;

export function ProductTypeView({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  ...rest
}: IProductTypeViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  return (
    <DetailsActionItemView
      color={color}
      selected={selected}
      onClick={() => onClick(type)}
      {...rest}>
      <StyledContainer>
        {type === ProductType.Product ? (
          <RiPencilRulerLine color={textColor} size={20} />
        ) : (
          <TbCategory2 color={textColor} size={23} />
        )}

        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
              {isThereContent(stats)
                ? Number(stats[type]) > 0
                  ? `${stats[type]}`
                  : undefined
                : undefined}
            </StyledTitle>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
