import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IProductListDTO,
  ProductMapper,
  ProductModel,
  ProductListModel,
  IProductsListStatsProps,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getProductList } from '@services/api/product';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { StatusError } from '@components/lib/Errors';

export interface IUseProductListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
  parent_category_uuid?: string;
  minKeywordLength?: number;
  isThrowError?: boolean;
}

export interface IUseProductListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: ProductListModel | null;
  refresh: (
    value: Partial<IUseProductListProps>,
  ) => Promise<ProductListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  stats: IProductsListStatsProps;
  with_balance?: boolean;
  store_uuid?: string;
  parent_category_uuid?: string;
  minKeywordLength?: number;
}

export function useProductList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    with_balance,
    store_uuid,
    parent_category_uuid,
    minKeywordLength = 0,
  }: IUseProductListProps = {} as IUseProductListProps,
): IUseProductListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    stats: listStats,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    parent_category_uuid: listParentCategoryUuid,
    minKeywordLength: listMinKeywordLength,
    handleUpdate,
  } = useStateReducer<Omit<IUseProductListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    stats: {} as IProductsListStatsProps,
    with_balance,
    store_uuid,
    parent_category_uuid,
    loading: loadOnInit,
    minKeywordLength,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
      with_balance = listWithBalance,
      store_uuid = listStoreUuid,
      parent_category_uuid = listParentCategoryUuid,
      minKeywordLength = listMinKeywordLength || 0,
      isThrowError = false,
    }: Partial<IUseProductListProps> = {}): Promise<ProductListModel | void> => {
      try {
        handleUpdate({
          loading: keywords?.length >= minKeywordLength ? showLoading : false,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          with_balance,
          store_uuid,
          parent_category_uuid,
        });

        if (keywords?.length >= minKeywordLength) {
          const { products, stats, total } =
            await cancellablePromise<IProductListDTO>(
              getProductList({
                limit,
                offset,
                keywords,
                companyUuid,
                with_balance,
                store_uuid,
                parent_category_uuid,
              }),
            );

          const productListModel = ProductMapper.toProductListModel(
            products,
            total,
            false,
            [],
            stats,
          );

          handleUpdate({
            entityList: productListModel,
            total: productListModel?.total,
            loading: false,
            stats,
          });

          return productListModel;
        }
      } catch (e: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during get materials list')} : ${e?.message}`,
        );
        handleUpdate({
          error,
          loading: false,
        });

        if (isThrowError) {
          throw new StatusError(e?.message, e?.status);
        }
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listWithBalance,
      listStoreUuid,
      listParentCategoryUuid,
      listMinKeywordLength,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    if (companyUuid) {
      handleUpdate({ companyUuid });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
    stats: listStats,
    with_balance: listWithBalance,
    store_uuid: listStoreUuid,
    parent_category_uuid: listParentCategoryUuid,
    minKeywordLength: listMinKeywordLength,
  };
}
