import { DEPARTMENT } from '../constants';
import { DepartmentModel } from '@structure';
import { ApiError } from '@services/types';
import { Dispatch } from 'redux';

export interface ISetDepartmentParam {
  type: DEPARTMENT;
  department: DepartmentModel | null;
}

export interface IUpdateDepartmentParam {
  type: DEPARTMENT;
  department: DepartmentModel;
}

export interface IResetDepartmentParam {
  type: DEPARTMENT;
}

export interface IErrorDepartmentParam {
  type: DEPARTMENT;
  error: ApiError | undefined;
}

export function setDepartment(
  department: DepartmentModel | null,
): (dispatch: Dispatch) => Promise<ISetDepartmentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT.SET_DEPARTMENT,
      department,
    });
  };
}

export function updateDepartment(
  department: DepartmentModel,
): (dispatch: Dispatch) => Promise<IUpdateDepartmentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT.UPDATE_DEPARTMENT,
      department,
    });
  };
}

export function resetDepartment(): (
  dispatch: Dispatch,
) => Promise<IResetDepartmentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT.RESET_DEPARTMENT,
    });
  };
}

export function errorDepartment(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorDepartmentParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT.ERROR_DEPARTMENT,
      error,
    });
  };
}
