import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { useStoredProfile } from '@hooks';
import { useDropdownAlert } from '@contex';
import React, { useCallback, useMemo } from 'react';
import { logout } from '@services/api/user';
import { ProfileShiftManager } from '../../Profile';
import { setInitialAppState } from '@store/actions';
import { tokenHandler } from '@services/core/token';
import { Divider, MenuProps, Space, theme as antTheme } from 'antd';
import { HeaderDropdown } from '@components/lib/DataDisplay';
import { ProfileInfo } from '@components/lib/DataEntity/Profile';
import { StyledHeaderDropdownMenuButton } from '@components/lib/Styled';

import {
  UserOutlined,
  LogoutOutlined,
  HistoryOutlined,
  SettingOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons';

import {
  CLEARED_LOCAL_STORAGE_ITEMS,
  CLEARED_SESSION_STORAGE_ITEMS,
} from '@services/const';

const { useToken } = antTheme;

const ProfileMenuWrapper = styled.div`
   {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px;
  }
`;

const StyledHeaderDropdownMenuExitButton = styled(
  StyledHeaderDropdownMenuButton,
)`
  color: ${({ theme }) => theme.colors.error};

  &:hover {
    color: darken(${({ theme }) => theme.colors.error}, 10%) !important;
  }
`;

export function UserHeaderDropdownMenu(): React.JSX.Element {
  const { token } = useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alert } = useDropdownAlert();
  const dispatch = useDispatch<any>();
  const { profile } = useStoredProfile();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const handleNavigateProfile = useCallback(
    (e: any) => {
      e.preventDefault();
      navigate(`/${Routes.app}/${Routes.profile}`);
    },
    [navigate],
  );

  const handleNavigateSubscription = useCallback(
    (e: any) => {
      e.preventDefault();
      navigate(`/${Routes.app}/${Routes.subscription}`);
    },
    [navigate],
  );

  const handleNavigateAccountSettings = useCallback(
    (e: any) => {
      e.preventDefault();
      navigate(`/${Routes.app}/${Routes.operations}`);
    },
    [navigate],
  );

  const handleNavigateActivityLogs = useCallback(
    (e: any) => {
      e.preventDefault();
      navigate(`/${Routes.app}/${Routes.operations}`);
    },
    [navigate],
  );

  const handleLogout = useCallback(
    async (e: any) => {
      try {
        e.preventDefault();

        CLEARED_LOCAL_STORAGE_ITEMS.forEach((item) =>
          localStorage.removeItem(item),
        );

        CLEARED_SESSION_STORAGE_ITEMS.forEach((item) =>
          sessionStorage.removeItem(item),
        );

        tokenHandler.destroyToken();

        navigate(`/${Routes.auth}`);

        await logout();

        setTimeout(() => {
          dispatch(setInitialAppState());
        }, 500);
      } catch (error: any) {
        alert(
          'error',
          t('Logout'),
          `${t('An error occurred when exiting the application')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, navigate, t],
  );

  const userMenuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'profile_key',
        label: (
          <StyledHeaderDropdownMenuButton onClick={handleNavigateProfile}>
            {t('Profile')}
          </StyledHeaderDropdownMenuButton>
        ),
        icon: <UserOutlined />,
      },
      {
        key: 'account_settings_key',
        label: (
          <StyledHeaderDropdownMenuButton
            onClick={handleNavigateAccountSettings}>
            {t('Account Settings')}
          </StyledHeaderDropdownMenuButton>
        ),
        icon: <SettingOutlined />,
        disabled: true,
      },
      {
        key: 'activity_log_key',
        label: (
          <StyledHeaderDropdownMenuButton onClick={handleNavigateActivityLogs}>
            {t('Activity Log')}
          </StyledHeaderDropdownMenuButton>
        ),
        icon: <HistoryOutlined />,
        disabled: true,
      },
      {
        key: 'subscription',
        label: (
          <StyledHeaderDropdownMenuButton onClick={handleNavigateSubscription}>
            {t('tSubscription')}
          </StyledHeaderDropdownMenuButton>
        ),
        icon: <CloudSyncOutlined />,
      },
    ],
    [
      t,
      handleNavigateProfile,
      handleNavigateActivityLogs,
      handleNavigateSubscription,
      handleNavigateAccountSettings,
    ],
  );

  return (
    <HeaderDropdown
      items={userMenuItems}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <ProfileMenuWrapper>
            <ProfileInfo profile={profile} withEmail={true} />
            <ProfileShiftManager profile={profile} />
          </ProfileMenuWrapper>
          <Divider style={{ margin: 0 }} />
          {React.cloneElement(menu as React.ReactElement, {
            style: menuStyle,
          })}
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: `8px 8px 8px 15px` }}>
            <StyledHeaderDropdownMenuExitButton onClick={handleLogout}>
              <LogoutOutlined />
              {t('Logout')}
            </StyledHeaderDropdownMenuExitButton>
          </Space>
        </div>
      )}>
      <a onClick={(e) => e.preventDefault()}>
        <ProfileInfo withTitle useShortName profile={profile} />
      </a>
    </HeaderDropdown>
  );
}
