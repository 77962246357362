import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { IUseStateStoredDocumentReturnType, useStoredCompanies } from '@hooks';
import { correctPrice } from '@services/helpers';

export interface IStoreRealizationDocumentBottomPriceViewProps
  extends Pick<IUseStateStoredDocumentReturnType, 'document'> {}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPaidContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const Title = styled(StyledTitle)<{ $withDiscount?: boolean }>`
  margin-bottom: 5px;

  ${({ $withDiscount }) =>
    $withDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

export function StoreRealizationDocumentBottomPriceView({
  document,
}: IStoreRealizationDocumentBottomPriceViewProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();
  const theme: any = useTheme();

  const paid = Number(document?.doc_sum_paid || 0);
  const total = Number(document?.doc_sum_total || 0);
  const totalWithDiscount = Number(document?.doc_sum_total_with_discount || 0);

  const docDiscount =
    document?.doc_discount_value || (document as any)?.doc_sum_discount;

  const isShowDiscount =
    Number(docDiscount || 0) > 0 && totalWithDiscount < total;

  const totalDiscountForPaid = isShowDiscount ? totalWithDiscount : total;

  const isPaid = paid > 0 && paid >= totalDiscountForPaid;

  return (
    <>
      {document?.doc_is_paymentable ? (
        <StyledPriceContainer>
          <StyledTitle fontSize={14}>{`${t('Paid')}: `}</StyledTitle>
          {isPaid ? (
            <StyledPrice $color={theme.colors.success}>
              {`${defaultCompany?.currency_symbol} ${correctPrice(
                totalDiscountForPaid,
              )}`}
            </StyledPrice>
          ) : (
            <StyledPaidContainer>
              <StyledPrice>
                {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  paid,
                )}`}
              </StyledPrice>
              <Title $withDiscount={isShowDiscount}>{`${t(
                'with',
              )} ${correctPrice(total)}`}</Title>
              {isShowDiscount ? (
                <Title $color={theme.colors.success}>{`${correctPrice(
                  totalWithDiscount,
                )}`}</Title>
              ) : null}
            </StyledPaidContainer>
          )}
          {paid === 0 || isPaid ? null : paid < total ? (
            <StyledTitle $color={theme.colors.success}>
              {`${t('Balance due').toLowerCase()} ${
                defaultCompany?.currency_symbol || ''
              }, ${correctPrice(totalDiscountForPaid - paid)}`}
            </StyledTitle>
          ) : paid > total ? (
            <StyledTitle $color={theme.colors.error}>
              {`${t('amount to be refunded')} ${
                defaultCompany?.currency_symbol || ''
              }, ${correctPrice(totalDiscountForPaid - paid)}`}
            </StyledTitle>
          ) : null}
        </StyledPriceContainer>
      ) : null}
    </>
  );
}
