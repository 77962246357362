import React, { useCallback } from 'react';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import {
  StoreDocumentItemFormDTO,
  StoreDocumentMultiCurrencyDTO,
} from '@structure';
import { useStoredCompanies, useStoredPriceMarginList } from '@hooks';
import { StoreProductFields } from './StoreProductFields';
import { ENTER_KEYS, SessionStorageItems } from '@services/const';
import { produce } from 'immer';
import { isFunction } from '@sportix/sportix-common-modules';
import { useTranslation } from 'react-i18next';

export interface IStoreProductFormProps
  extends Omit<
    IDefaultFormProps<StoreDocumentItemFormDTO, StoreDocumentItemFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  product: StoreDocumentItemFormDTO;
  doc_multi_currencies: StoreDocumentMultiCurrencyDTO[];
}

export function StoreProductForm({
  loading,
  editMode,
  product,
  onCancel,
  doc_multi_currencies,
  onSuccess,
  ...rest
}: IStoreProductFormProps) {
  const { defaultCompanyUuid } = useStoredCompanies();
  const { t } = useTranslation();

  const price_currency =
    doc_multi_currencies?.length > 0
      ? sessionStorage.getItem(
          SessionStorageItems.storeDocumentProductModalCurrency,
        )
      : null;

  const { priceMarginList, loading: priceMarginListLoading } =
    useStoredPriceMarginList({
      companyUuid: defaultCompanyUuid,
    });

  const notifyError = useCallback((apiError: any) => {}, []);

  const onAmountEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLElement) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      return true;
    },

    [],
  );

  const handleSuccess = useCallback(
    (value: StoreDocumentItemFormDTO) => {
      const updatedValue = produce(value, (draft) => {
        draft.price_currency =
          draft.price_currency === '₴'
            ? 'allowEmptyString'
            : draft.price_currency;
      });

      if (isFunction(onSuccess)) {
        onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={['Tab', ...ENTER_KEYS]}
      onEnterPress={onAmountEnter}
      className="store-product-form"
      formKeyboardEndSubmit
      withContext
      onSuccess={handleSuccess}
      editMode={editMode}
      cancelButtonText={t('Close')}
      initialValues={{
        ...product,
        price_currency: product?.price_currency || price_currency || '₴',
      }}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      <StoreProductFields
        loading={!!loading}
        priceMarginList={priceMarginList}
        priceMarginListLoading={priceMarginListLoading}
        doc_multi_currencies={doc_multi_currencies}
      />
    </DefaultForm>
  );
}
