import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { useModal } from '@components/lib/libV2/hooks';
import {
  ProductFormDTO,
  ProductCategoryFormDTO,
  ProductModel,
} from '@structure';
import {
  IAddButtonProps,
  AddButton,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { useTheme } from 'styled-components';

const ProductCategorySideWindowLazy = React.lazy(
  () => import('../Show/ProductCategorySideWindow'),
);

type GroupSuccess = (
  value: ProductCategoryFormDTO,
) => Promise<ProductModel | void>;

export interface IProductCategoryCreateButtonProps extends IAddButtonProps {
  onSuccess?: GroupSuccess;
  onCancel?: () => void;
  category?: ProductCategoryFormDTO;
}

export default function ProductCategoryCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  category,
  ...rest
}: IProductCategoryCreateButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const {
    handleCancel: categoryHandleCancel,
    handleOnInit: categoryHandleOnInit,
    handleSuccess: categoryHandleSuccess,
    visible: categoryVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create category')}
        onClick={categoryHandleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductCategorySideWindowLazy
          loading={loading as boolean}
          onSuccess={categoryHandleSuccess}
          onCancel={categoryHandleCancel}
          visible={categoryVisible}
          category={category}
        />
      </Suspense>
    </>
  );
}
