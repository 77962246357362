import { useStoredCompanies } from '@hooks';
import { isWorkDayForTheCompany, toDateByFormat } from '@services/helpers';
import { isFunction } from '@sportix/sportix-common-modules';
import { Button } from 'antd';
import styled from 'styled-components';
import { FaDotCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import { EmployeeMapper, IWorkSchedules, ProfileModel } from '@structure';
import { useEmployeeShift } from '@hooks/useEmployeeShift';
import { SuspenseEmpty } from '@components/lib/DataDisplay';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { EmployeeShiftFormDTO } from '@structure/dto/EmployeeShiftFormDTO';

export interface ProfileShiftManagerProps {
  profile: ProfileModel;
  onProfileShiftStatusChanged?: () => void;
}

const EmployeeOpenShiftSideWindowLazy = React.lazy(
  () => import('views/Employees/Show/EmployeeOpenShiftSideWindow'),
);

const EmployeeCloseShiftSideWindowLazy = React.lazy(
  () => import('views/Employees/Show/EmployeeCloseShiftSideWindow'),
);

const StyledCloseShiftButton = styled(Button)`
  border: 1px solid rgb(177, 60, 60);
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.error};
  &&&:hover,
  &&&:active,
  &&&:focus {
    color: #f9f9f9;
    border: 1px solid #751c18;
  }
`;

const StyledUnWorkableCompanyTimeWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 5px;
  padding: 5px;
`;

const StyledClosedShiftWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.warning};
  border-radius: 5px;
  padding: 5px;
  text-align: center;
`;

const StyledShiftManagerWrapper = styled.div`
   {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
`;

const StyledShiftButtonContent = styled.div`
   {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledEmployeeOpenedShift = styled.div`
   {
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: ${({ theme }) => theme.colorsV2.employeeOpenedShift};
  }
`;

export function ProfileShiftManager(
  props: ProfileShiftManagerProps,
): React.JSX.Element {
  const { profile, onProfileShiftStatusChanged } = props || {};
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();
  const { createShiftByEmployee, closeShiftByEmployee } = useEmployeeShift();
  const activeEmployeeShift = useMemo(
    () => profile?.defaultEmployeeModel?.active_shift,
    [profile?.defaultEmployeeModel?.active_shift],
  );
  const [shiftOpenStatus, setShiftOpenStatus] = useState(
    activeEmployeeShift?.isOpened(),
  );

  const isShiftCloseAutomatically = useMemo(
    () => !!defaultCompany?.close_shift_automatically,
    [defaultCompany?.close_shift_automatically],
  );

  const handleStartEmployeeShift = useCallback(
    async (value: EmployeeShiftFormDTO) => {
      await createShiftByEmployee(value);
      setShiftOpenStatus(true);
      if (isFunction(onProfileShiftStatusChanged)) {
        onProfileShiftStatusChanged();
      }
    },
    [onProfileShiftStatusChanged, createShiftByEmployee],
  );

  const handleCloseEmployeeShift = useCallback(
    async (value: EmployeeShiftFormDTO) => {
      await closeShiftByEmployee(value);
      setShiftOpenStatus(false);
      if (isFunction(onProfileShiftStatusChanged)) {
        onProfileShiftStatusChanged();
      }
    },
    [onProfileShiftStatusChanged, closeShiftByEmployee],
  );

  const {
    handleCancel: handleEmployeeStartShiftCancel,
    handleSuccess: handleEmployeeStartShiftSuccess,
    handleOnInit: showEmployeeOpenShiftDialog,
    visible: employeeShiftDialogVisibility,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleStartEmployeeShift,
  });

  const {
    handleCancel: handleEmployeeCloseShiftCancel,
    handleSuccess: handleEmployeeCloseShiftSuccess,
    handleOnInit: showEmployeeCloseShiftDialog,
    visible: employeeCloseShiftDialogVisibility,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleCloseEmployeeShift,
  });

  const onOpenShiftBtnClick = useCallback(() => {
    showEmployeeOpenShiftDialog();
  }, [showEmployeeOpenShiftDialog]);

  const onCloseEmployeeShiftBtnClick = useCallback(() => {
    showEmployeeCloseShiftDialog();
  }, [showEmployeeCloseShiftDialog]);

  const isWorkableDayAtCompany = useMemo(() => {
    const currDate = new Date();
    return isWorkDayForTheCompany(
      currDate.getDay(),
      defaultCompany?.work_schedules_list as IWorkSchedules,
    );
  }, [defaultCompany?.work_schedules_list]);

  if (!isWorkableDayAtCompany) {
    return (
      <StyledUnWorkableCompanyTimeWrapper>
        {t("It's not workable day at the company")}
      </StyledUnWorkableCompanyTimeWrapper>
    );
  } else if (activeEmployeeShift?.isClosed()) {
    return (
      <StyledClosedShiftWrapper>
        {t('Employee shift has been closed')}
      </StyledClosedShiftWrapper>
    );
  }

  return (
    <StyledShiftManagerWrapper>
      {shiftOpenStatus ? (
        <StyledEmployeeOpenedShift>
          {isShiftCloseAutomatically ? (
            <>{t('The shift will be closed automatically')}</>
          ) : (
            <StyledCloseShiftButton
              type="default"
              onClick={onCloseEmployeeShiftBtnClick}>
              <StyledShiftButtonContent>
                <FaDotCircle />
                &nbsp;
                {t('Close shift #{{shiftId}} from {{shiftDate}}', {
                  shiftId: activeEmployeeShift?.shift_number || '--',
                  shiftDate: toDateByFormat(
                    activeEmployeeShift?.shift_open_date,
                    'DD.MM.YYYY HH:mm',
                  ),
                })}
              </StyledShiftButtonContent>
            </StyledCloseShiftButton>
          )}
        </StyledEmployeeOpenedShift>
      ) : (
        <Button
          className="ant-btn-primary-system"
          type="primary"
          onClick={onOpenShiftBtnClick}>
          <StyledShiftButtonContent>
            <FaDotCircle />
            &nbsp;{t('Open shift')}
          </StyledShiftButtonContent>
        </Button>
      )}

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeOpenShiftSideWindowLazy
          employee={EmployeeMapper.toEmployeeDTO(profile.defaultEmployeeModel)}
          onSuccess={handleEmployeeStartShiftSuccess}
          visible={employeeShiftDialogVisibility}
          onCancel={handleEmployeeStartShiftCancel}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeCloseShiftSideWindowLazy
          employee={EmployeeMapper.toEmployeeDTO(profile.defaultEmployeeModel)}
          onSuccess={handleEmployeeCloseShiftSuccess}
          visible={employeeCloseShiftDialogVisibility}
          onCancel={handleEmployeeCloseShiftCancel}
        />
      </Suspense>
    </StyledShiftManagerWrapper>
  );
}
