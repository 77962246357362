import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderLeft, Switch } from '@components/lib/DataDisplay';
import { useStopLoading } from '@hooks';
import { OverviewLayout } from '@components/lib/Layout';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { useStoredSettingsModule } from '@hooks';
import { Routes, RoutesAcl } from '@services/types';
import { useAcl } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Col, Row } from 'antd';
import { Text } from '@components/lib/Format';
import styled from 'styled-components';
import {
  SettingsModuleInputParameterList,
  SettingsModuleDependencyList,
} from '../Lists';
import { LoadingOutlined } from '@ant-design/icons';
import { SETTING_TABS } from '../../Settings';

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const Description = styled(StyledDescription)`
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 5px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  margin-right: 10px;
`;

export function SettingsModulePage() {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { manage } = useAcl(({ settings }) => settings);
  const [isActive, setIsActive] = useState<boolean | undefined>(undefined);

  const {
    settingsModule,
    loading: settingsModuleLoading,
    loadingActivateOrDeactivate,
    loadingDependencyUuid,
    error: settingsModuleError,

    handleLinkinModuleDependency,
    handleActivateModule,
    handleDeactivateModule,
  } = useStoredSettingsModule({
    settingsModuleUuid: moduleId!,
  });

  const loading = useStopLoading({
    loading: settingsModuleLoading,
    error: settingsModuleError,
    message: 'An error occurred during service loading',
  });

  const handleActivateOrDeactivate = useCallback(
    async (value: boolean) => {
      try {
        setIsActive(value);

        if (settingsModule) {
          if (value) {
            await handleActivateModule(settingsModule);
            return;
          }

          await handleDeactivateModule(settingsModule);
        }
      } catch (error) {
        setIsActive(!value);
      }
    },
    [handleActivateModule, handleDeactivateModule, settingsModule],
  );

  useEffect(() => {
    if (isActive === undefined && settingsModule) {
      setIsActive(settingsModule?.module_enabled_for_company);
    }
  }, [isActive, settingsModule]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.settings}/${SETTING_TABS.PARAMETERS}/${Routes.modules}`,
      breadcrumbName: 'Modules',
    },
    {
      path: `/${Routes.settings}/${SETTING_TABS.PARAMETERS}/${Routes.modules}/${moduleId}`,
      breadcrumbName: settingsModule ? settingsModule?.module_title : 'Module',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.settings}/${SETTING_TABS.PARAMETERS}/${Routes.modules}`,
      {
        state: location?.state?.prevState || location?.state,
      },
    );
  }, [location?.state, navigate]);

  return (
    <OverviewLayout
      aclItem={RoutesAcl[Routes.settings]}
      headerTitle={t('Module')}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      header={
        <>
          <Row gutter={10}>
            <Col span={24}>
              <StyledTitle bold fontSize={20}>
                {settingsModule?.module_title}
              </StyledTitle>
              <Description>{settingsModule?.module_description}</Description>
            </Col>
            <Col span={24}>
              <StyledSwitchWrapper>
                {loadingActivateOrDeactivate ? <StyledLoadingOutlined /> : null}
                <StyledSwitch
                  onChange={handleActivateOrDeactivate}
                  checked={isActive}
                  disabled={
                    !settingsModule || loadingActivateOrDeactivate || !manage
                  }
                />
                <Text>{t('Connected')}</Text>
              </StyledSwitchWrapper>
            </Col>
          </Row>
        </>
      }
      loading={loading}
      data={null}
      routes={routes}
      tabs={[]}
      footerContentExtra={
        <>
          {settingsModule?.module_input_parameters_json?.length ? (
            <SettingsModuleInputParameterList
              moduleInputParameters={
                settingsModule?.module_input_parameters_json
              }
              isLinking={
                !!settingsModule?.module_enabled_for_company &&
                !loadingActivateOrDeactivate
              }
            />
          ) : null}
          {settingsModule?.dependencyListModel?.size ? (
            <SettingsModuleDependencyList
              dependencyList={settingsModule?.dependencyListModel || null}
              handleLinkinModuleDependency={handleLinkinModuleDependency}
              isLinking={
                !!settingsModule?.module_enabled_for_company &&
                !loadingActivateOrDeactivate
              }
              loadingDependencyUuid={loadingDependencyUuid}
            />
          ) : null}
        </>
      }
    />
  );
}
