import React from 'react';
import styled, { css } from 'styled-components';
import { fromBooleanToNumber } from '@services/helpers';

export interface INestedLayoutProps {
  children: React.ReactNode;
  withShadow?: boolean;
  className?: string;
  withoutBackground?: boolean;
}

const ShadowContainer = styled.div<{ withoutBackground?: number }>`
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 24px 2px rgb(0, 0, 0) inset;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  border: 3px solid black;

  ${({ withoutBackground }) =>
    withoutBackground &&
    css`
      box-shadow: none;
      background-color: transparent;
      border: none;
    `}
`;

export default function NestedLayout({
  children,
  withShadow = true,
  className,
  withoutBackground = false,
}: INestedLayoutProps): JSX.Element {
  return (
    <>
      {withShadow ? (
        <ShadowContainer
          className={className}
          withoutBackground={fromBooleanToNumber(withoutBackground)}>
          {children}
        </ShadowContainer>
      ) : (
        children
      )}
    </>
  );
}
