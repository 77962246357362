import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import { apiDelete, apiGet, apiPost, apiPut, apiPatch } from '../core/api';
import { ifElse, toLowerCase, eq } from '../helpers';
import {
  ApiAnswer,
  DeletePrams,
  TIME_LIST_SERVICES,
  PERSONALITY_TYPE,
  IListSearchProps,
  IEntityProps,
} from '../types';
import {
  ServiceMapper,
  ServiceDTO,
  ServiceFormDTO,
  IServiceDTOProps,
  ServiceModel,
  IServiceListDTO,
  IEmployeeListDTO,
  EmployeeMapper,
  RewardType,
  ServiceParametersFormDTO,
} from '@structure';
import { IEmployeeListAnswer } from './employee';

export interface IServiceListAnswer extends ApiAnswer {
  services: IServiceDTOProps[];
}

export interface IServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  personality: PERSONALITY_TYPE | string;
}

export interface IServiceAnswer {
  service: IServiceDTOProps;
}

export interface IServiceEmployeeListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  serviceUuid: string;
}

export type Personality = {
  uuid: PERSONALITY_TYPE;
  title: string;
};

export const SERVICES_INITIAL_DATA: ServiceFormDTO = new ServiceFormDTO();
export const SERVICE_PARAMETERS_INITIAL_DATA: ServiceParametersFormDTO =
  new ServiceParametersFormDTO();
const {
  uuid,
  period,
  personality,
  period_amount,
  is_multi_sessionable,
  ...SERVICES_REQUIRED_REST
} = SERVICES_INITIAL_DATA;

const { price } = SERVICES_INITIAL_DATA;

export const SERVICES_REQUIRED_FIELDS_FORM = {
  price,
  personality,
  period_amount,
  period,
};

export const SERVICES_REQUIRED_FIELDS = {
  ...SERVICES_REQUIRED_REST,
};

export const TIME_LIST: TIME_LIST_SERVICES[] = [
  TIME_LIST_SERVICES.MINUTE,
  TIME_LIST_SERVICES.HOUR,
  TIME_LIST_SERVICES.DAY,
  TIME_LIST_SERVICES.WEEK,
  TIME_LIST_SERVICES.MONTH,
  TIME_LIST_SERVICES.YEAR,
];

export const REWARD_TYPE_LIST: (currencySymbol: string) => {
  uuid: RewardType;
  title: RewardType;
  description: string;
}[] = (currencySymbol) => [
  {
    uuid: RewardType.Percentage,
    title: RewardType.Percentage,
    description: '%',
  },
  {
    uuid: RewardType.Price,
    title: RewardType.Price,
    description: currencySymbol,
  },
];

export const SERVICES_PERSONALITY: (t: any) => Personality[] = (t: any) => [
  { uuid: PERSONALITY_TYPE.INDIVIDUAL, title: t('Individual') },
  { uuid: PERSONALITY_TYPE.GROUP, title: t('Group') },
];

export const getPeriod = (period: string): string => {
  switch (period) {
    case TIME_LIST_SERVICES.DAY:
      return toLowerCase(TIME_LIST_SERVICES.DAY);
    case TIME_LIST_SERVICES.MINUTE:
      return toLowerCase(TIME_LIST_SERVICES.MINUTE);
    case TIME_LIST_SERVICES.MONTH:
      return toLowerCase(TIME_LIST_SERVICES.MONTH);
    case TIME_LIST_SERVICES.YEAR:
      return toLowerCase(TIME_LIST_SERVICES.YEAR);
    case TIME_LIST_SERVICES.WEEK:
      return toLowerCase(TIME_LIST_SERVICES.WEEK);
    default:
      return toLowerCase(TIME_LIST_SERVICES.HOUR);
  }
};

export const isPeriodEqMinOrHour = (value: TIME_LIST_SERVICES): boolean =>
  eq(value, TIME_LIST_SERVICES.MINUTE) || eq(value, TIME_LIST_SERVICES.HOUR);

export const isServicePersonalityGroup = (
  personality: PERSONALITY_TYPE | string | undefined,
) => eq(personality, PERSONALITY_TYPE.GROUP);

export const isServicePersonalityIndividual = (
  service: ServiceModel,
): boolean => eq(service?.personality, PERSONALITY_TYPE.INDIVIDUAL);

export async function getServiceList({
  companyUuid,
  limit = 10,
  offset = 0,
  personality,
  keywords,
}: IServiceListProps): Promise<IServiceListDTO> {
  const { services, count } = await apiGet<
    Partial<IListSearchProps & { personality: PERSONALITY_TYPE | string }>,
    IServiceListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/services`, {
    limit,
    offset,
    personality,
    keywords,
  });

  return ServiceMapper.toServiceListDTO(services, count);
}

/* ------------------- SERVICE COMMENT ------------------- */
export async function updateServiceComment(
  comment: string,
  serviceUuid: string,
): Promise<ServiceDTO> {
  const { service } = await apiPatch<{ comment: string }, IServiceAnswer>(
    `${contentApiUrl}/services/${serviceUuid}/details`,
    { comment },
  );

  return ServiceMapper.toServiceDTO(service);
}

export async function getServiceById(serviceUuid: string): Promise<ServiceDTO> {
  const { service } = await apiGet<null, IServiceAnswer>(
    `${contentApiUrl}/services/${serviceUuid}`,
  );

  return ServiceMapper.toServiceDTO(service);
}

export async function createService(
  {
    title,
    price,
    description,
    period,
    personality,
    period_amount,
    manager_uid,
    payment_group_uuid,
    net_price,
    is_multi_sessionable,
    multi_sessions,
  }: ServiceFormDTO,
  companyUuid: string,
): Promise<ServiceDTO> {
  const { service } = await apiPost<
    Omit<ServiceFormDTO, 'companyUuid' | 'uuid'>,
    IServiceAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/services`, {
    title,
    price,
    period_amount,
    description,
    manager_uid,
    period,
    personality,
    payment_group_uuid,
    net_price,
    is_multi_sessionable,
    multi_sessions: multi_sessions?.length ? multi_sessions : (null as any),
  });

  return ServiceMapper.toServiceDTO(service);
}

export async function updateService(
  {
    title,
    price,
    description,
    period,
    personality,
    period_amount,
    manager_uid,
    uuid,
    payment_group_uuid,
    net_price,
    is_multi_sessionable,
    multi_sessions,
  }: ServiceFormDTO,
  companyUuid: string,
): Promise<ServiceDTO> {
  Validate.string({ value: title });

  const { service } = await apiPut<
    Omit<ServiceFormDTO, 'companyUuid' | 'uuid'>,
    IServiceAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/services/${uuid}`, {
    title,
    price,
    period_amount,
    description,
    manager_uid,
    period,
    personality,
    payment_group_uuid,
    net_price,
    is_multi_sessionable,
    multi_sessions: multi_sessions?.length ? multi_sessions : (null as any),
  });

  return ServiceMapper.toServiceDTO(service);
}

export async function deleteServices(ids: string[]) {
  Validate.arrayOfStrings({ value: ids });

  return await apiDelete<DeletePrams, any>(`${contentApiUrl}/services`, {
    ids,
  });
}

export async function blockService(
  serviceUuid: string,
  status: number,
): Promise<ServiceDTO> {
  const { service } = await apiPatch<{ status: number }, IServiceAnswer>(
    `${contentApiUrl}/services/${serviceUuid}/status`,
    { status },
  );

  return ServiceMapper.toServiceDTO(service);
}

export async function getServiceEmployeeList({
  serviceUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IServiceEmployeeListProps): Promise<IEmployeeListDTO> {
  const isKeywords = ifElse(!!keywords, `&keywords=${keywords}`, '');

  const { employees, total } = await apiGet<null, IEmployeeListAnswer>(
    `${contentApiUrl}/services/${serviceUuid}/employees?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return EmployeeMapper.toEmployeeListDTO(employees, total);
}
