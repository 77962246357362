import React from 'react';
import styled, { css } from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { PaymentScheduleOperationType } from '@services/api/orders';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export interface IPaymentScheduleOperationTypeViewProps {
  type: PaymentScheduleOperationType;
  icon?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
}

const StyledWrapper = styled.div<{ $isLineHeight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ $isLineHeight }) =>
    $isLineHeight &&
    css`
      padding: 3px;
      line-height: 1.5;
    `}
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function PaymentScheduleOperationTypeView({
  type,
  title,
  description,
  icon,
  ...rest
}: IPaymentScheduleOperationTypeViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? (
        icon
      ) : type === PaymentScheduleOperationType.Out ? (
        <ArrowUpOutlined />
      ) : type === PaymentScheduleOperationType.In ? (
        <ArrowDownOutlined />
      ) : (
        <CheckOutlined />
      )}
      <StyledWrapper $isLineHeight={!!description}>
        <StyledTitle fontSize={13}>{t(title)}</StyledTitle>
        {description ? (
          typeof description === 'string' ? (
            <StyledDescription>{description}</StyledDescription>
          ) : (
            description
          )
        ) : null}
      </StyledWrapper>
    </StyledTitleIconContainer>
  );
}
