import * as React from 'react';
import { List } from 'immutable';
import {
  ApiError,
  IEntityProps,
  IListSearchProps,
  PERSONALITY_TYPE,
} from '@services/types';
import { IEmployeeListDTO, EmployeeMapper, EmployeeModel } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getEmployeeList } from '@services/api/employee';

export interface IUseEmployeeListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  serviceUuid?: string;
  personality?: PERSONALITY_TYPE;
}

export interface IUseEmployeeListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<EmployeeModel> | null;
  refresh: (
    value: Partial<IUseEmployeeListProps>,
  ) => Promise<List<EmployeeModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  serviceUuid?: string;
}

export function useEmployeeList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    serviceUuid,
  }: IUseEmployeeListProps = {} as IUseEmployeeListProps,
): IUseEmployeeListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    serviceUuid: listServiceUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseEmployeeListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    serviceUuid: serviceUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      serviceUuid = listServiceUuid,
    }: Partial<IUseEmployeeListProps> = {}): Promise<List<EmployeeModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
        });

        const { employees, total } = await cancellablePromise<IEmployeeListDTO>(
          getEmployeeList({
            companyUuid,
            limit,
            offset,
            keywords,
            serviceUuid,
          }),
        );

        const serviceListModel = EmployeeMapper.toEmployeeListModel(
          employees,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: serviceListModel?.employees,
            total: serviceListModel?.total,
            loading: false,
          });

          return serviceListModel?.employees;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      listServiceUuid,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        handleUpdate({ companyUuid });

        await refresh({ companyUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
  };
}
