import React, {
  JSX,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Empty, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  InvoiceItemFormDTO,
  InvoiceMapper,
  ProductModel,
  ProductStatus,
} from '@structure';
import styled from 'styled-components';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStateProductList, useStoredCompanies } from '@hooks';
import { correctPrice, debounce, listToArray } from '@services/helpers';
import { LoadingMore } from '@components/lib/DataDisplay';
import {
  SelectedItemType,
  InvoiceItemListSelectState,
} from './InvoiceItemListSelect';
import { List } from 'immutable';
import { ProductCreateButton, ProductDetailsView } from '../../Products';
import { useAcl } from '@contex';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';
import { useInvoiceManager } from '../Managers';

export interface InvoiceProductListSelectProps {
  disabled: boolean;
  selectRef: RefObject<any>;
  onTableKeyboardChange: (e: any) => void;
  handleInitItemForm: (item: SelectedItemType) => void;
  resetSelectedItemState: () => void;
  selectedItem: InvoiceItemFormDTO;
  selectedItemIndex: number;
  itemListKeywords: string;
  updateItemState: (
    value:
      | ((
          value: InvoiceItemListSelectState,
        ) => Partial<InvoiceItemListSelectState>)
      | Partial<InvoiceItemListSelectState>,
  ) => void;

  handleSearchSelectDaleyFocus: (ms?: number) => void;
  isInitItemModals: React.MutableRefObject<boolean>;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<ProductModel>>;

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;

  & .ant-select-selection-placeholder {
    white-space: unset !important;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
`;

const StyledProductCreateButton = styled(ProductCreateButton)`
  height: 100%;
`;

export function InvoiceProductListSelect({
  disabled,
  selectRef,

  selectedItem,
  selectedItemIndex,
  itemListKeywords,

  onTableKeyboardChange,
  handleInitItemForm,
  resetSelectedItemState,
  updateItemState,

  handleSearchSelectDaleyFocus,
  isInitItemModals,
}: InvoiceProductListSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();

  const { manage } = useAcl(({ product }) => product);
  const { priceMarginList } = useInvoiceManager();

  const [priceMarginFields, setPriceMarginFields] = useState<any[]>([]);

  const {
    productList,
    loading,
    isLoadingMore,
    total,
    limit,

    handleSearchProductList,
    handleLoadingMoreProductList,
    handleSetProductList,
    handleCreateProduct,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
    limit: 100,
  });

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const productList = await handleSearchProductList({
          ...value,
          companyUuid: defaultCompanyUuid,
        });

        if (productList && List.isList(productList?.products)) {
          updateItemState({
            itemList: productList?.products,
            selectedItem: InvoiceMapper.toInvoiceItemFormDTOFactory(
              productList?.products?.first(),
            ),
          });
        }
      }, 1000),
    [handleSearchProductList, defaultCompanyUuid, updateItemState],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      updateItemState({ itemListKeywords: keywords });

      if (keywords?.length < 3) {
        setTimeout(() => {
          if (!isInitItemModals.current) {
            resetSelectedItemState();
            handleSetProductList(List());
          }
        }, 100);
        return;
      }

      await debounceHandleSearchProducts({
        keywords,
      });
    },
    [
      debounceHandleSearchProducts,
      handleSetProductList,
      isInitItemModals,
      resetSelectedItemState,
      updateItemState,
    ],
  );

  const onLoadingMore = useCallback(async () => {
    const productList = await handleLoadingMoreProductList({});

    if (List.isList(productList)) {
      updateItemState((prevState) => ({
        itemList: (prevState?.itemList || List()).merge(productList) as any,
      }));
    }
  }, [handleLoadingMoreProductList, updateItemState]);

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (product: ProductModel) => (
        <ProductDetailsView withoutNavigate product={product} />
      ),
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      children: priceMarginFields,
    },
  ];

  useEffect(() => {
    if (priceMarginFields?.length === 0 && priceMarginList?.size) {
      const fields = priceMarginList?.map(({ price_name, uuid }) => ({
        title: price_name,
        key: price_name,
        align: 'right',
        render: (product: ProductModel) => {
          const price = product?.product_prices?.find(
            ({ price_uuid }) => price_uuid === uuid,
          );

          if (price) {
            return correctPrice(price?.price_value || 0);
          }
        },
      }));

      setPriceMarginFields(listToArray(fields));
    }
  }, [priceMarginFields?.length, priceMarginList, t]);

  return (
    <StyledSelectContainer>
      <StyledSelect
        popupClassName="store-sell-product-select-popup posting-select-popup"
        className="store-sell-product-select"
        ref={selectRef as any}
        loading={loading}
        disabled={disabled}
        showSearch
        onKeyUp={onTableKeyboardChange}
        searchValue={itemListKeywords}
        dropdownRender={() => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            {loading && productList !== null ? (
              <Empty>
                <LoadingOutlined />
              </Empty>
            ) : loading || !itemListKeywords || itemListKeywords?.length < 3 ? (
              <Empty
                description={t(
                  'To select a product, enter the search data, at least 3 characters',
                )}
              />
            ) : itemListKeywords && !productList?.size ? (
              <Empty
                description={t(
                  'It seems that more than one product was not found for your request',
                )}
              />
            ) : (
              <>
                <StyledTable
                  className="posting-list-form"
                  pagination={false}
                  pageSize={limit}
                  total={total}
                  dataSource={productList}
                  columns={columns}
                  onRow={(record: ProductModel, rowIndex: any) => {
                    return {
                      onClick: () => {
                        handleInitItemForm(
                          InvoiceMapper.toInvoiceItemFormDTOFactory(record),
                        );

                        if (selectRef?.current) {
                          selectRef?.current?.blur();
                        }
                      },
                      className: `posting-list-td posting-list-td-${rowIndex} ${
                        record?.uuid === selectedItem?.item_positionable_uuid &&
                        selectedItemIndex === rowIndex
                          ? 'posting-list-td--selected'
                          : ''
                      } ${
                        record?.product_status !== ProductStatus.ENABLE
                          ? 'product-list--disabled'
                          : ''
                      }`,
                    };
                  }}
                />
                <LoadingMore
                  loading={isLoadingMore}
                  observerCallback={onLoadingMore}
                />
              </>
            )}
          </div>
        )}
        onSearch={onSearch}
        placeholder={t('Enter product details to search for it')}
      />
      <StyledProductCreateButton
        disabled={!manage}
        // loading={loading}
        onSuccess={handleCreateProduct}
        onCancel={handleSearchSelectDaleyFocus}
      />
    </StyledSelectContainer>
  );
}
