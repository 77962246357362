import * as React from 'react';
import { List } from 'immutable';
import { Moment } from 'moment';
import {
  usePaymentList,
  IUsePaymentListProps,
  IUsePaymentListReturnType,
} from './usePaymentList';
import { PaymentModel, PaymentFormDTO, CashBoxModel } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  debounce,
  head,
  last,
  dateToIsoString,
  correctPrice,
} from '@services/helpers';
import { useStatePaymentListActions } from './useStatePaymentListActions';
import { IUseStateCashBoxListReturnType } from './useStoredCashBoxList';
import { useCallback, useEffect, useState } from 'react';
import { IPaymentScheduleListStats } from '@services/api/orders';
import { isThereContent } from '@sportix/sportix-common-modules';
import { OPERATION_TYPE } from '@services/types';

export interface IUseStatePaymentListProps
  extends Omit<IUsePaymentListProps, 'cashbox_uuid'>,
    Partial<
      Pick<IUseStateCashBoxListReturnType, 'handleUpdateDefaultCashBox'>
    > {
  cashBox: CashBoxModel | null;
}

export type PaymentDateRangeParam = [Moment, Moment];

export interface IPaymentStatsProps {
  payment: PaymentModel;
  type: 'create' | 'edit' | 'delete';
}

export interface IUseStatePaymentListReturnType
  extends Omit<IUsePaymentListReturnType, 'entityList'> {
  payments: List<PaymentModel> | null;
  handleCreatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (ids: string[]) => Promise<void>;
  handleSearchPayments: (value: any) => Promise<void>;
  handleRefreshPayments: (
    value: Partial<IUsePaymentListProps> & { page?: number },
  ) => Promise<void>;
  handlePickPaymentRange: (value: PaymentDateRangeParam) => Promise<void>;
  page: number;
}

export function useStatePaymentList(
  {
    companyUuid,
    cashBox,
    handleUpdateDefaultCashBox,
    ...rest
  }: IUseStatePaymentListProps = {} as IUseStatePaymentListProps,
): IUseStatePaymentListReturnType {
  const [localStats, setLocalStats] = useState<IPaymentScheduleListStats>(
    {} as IPaymentScheduleListStats,
  );
  const [page, setPage] = useState(1);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: paymentListLoading,
    total: paymentListTotal,
    cashbox_uuid,
    stats,
    ...clientsParams
  } = usePaymentList({
    companyUuid,
    cashbox_uuid: cashBox?.uuid!,
    ...rest,
  });

  const {
    entityList: payments,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<PaymentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: paymentListTotal,
  });

  const handleUpdateStats = useCallback(
    ({ payment, type }: IPaymentStatsProps) => {
      const isPaymentInType = payment?.payment_type === OPERATION_TYPE.IN;

      const price = Number(payment?.payment_sum || 0);

      if (type === 'create') {
        if (isPaymentInType) {
          setLocalStats((prevState) => {
            const prevTotalInSum = Number(prevState?.totalInSum || 0);

            return {
              ...prevState,
              totalInSum: correctPrice(prevTotalInSum + price),
            };
          });

          return;
        }

        setLocalStats((prevState) => {
          const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

          return {
            ...prevState,
            totalOutSum: correctPrice(prevTotalOutSum + price),
          };
        });
      }

      if (type === 'delete') {
        if (isPaymentInType) {
          setLocalStats((prevState) => {
            const prevTotalInSum = Number(prevState?.totalInSum || 0);

            return {
              ...prevState,
              totalInSum: correctPrice(prevTotalInSum - price),
            };
          });

          return;
        }

        setLocalStats((prevState) => {
          const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

          return {
            ...prevState,
            totalOutSum: correctPrice(prevTotalOutSum - price),
          };
        });
      }

      if (type === 'edit') {
        const prevPayment = payments?.find(
          ({ uuid }) => uuid === payment?.uuid,
        );

        if (prevPayment) {
          const prevPrice = Number(prevPayment?.payment_sum || 0);

          if (isPaymentInType) {
            setLocalStats((prevState) => {
              const prevTotalInSum = Number(prevState?.totalInSum || 0);

              return {
                ...prevState,
                totalInSum: correctPrice(prevTotalInSum - prevPrice + price),
              };
            });

            return;
          }

          setLocalStats((prevState) => {
            const prevTotalOutSum = Number(prevState?.totalOutSum || 0);

            return {
              ...prevState,
              totalOutSum: correctPrice(prevTotalOutSum - prevPrice + price),
            };
          });
        }
      }
    },
    [payments],
  );

  const { handleDeletePayments, handleUpdatePayment, handleCreatePayment } =
    useStatePaymentListActions({
      handleCreate,
      handleDelete,
      handleUpdate,
      cashBox,
      handleUpdateDefaultCashBox,
      refresh,
      handleUpdateStats,
    });

  const handlePickPaymentRange = React.useCallback(
    async (value: PaymentDateRangeParam): Promise<void> => {
      if (value) {
        await refresh({
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });
      } else {
        await refresh({
          date_start: null as any,
          date_end: null as any,
          isClearDate: true,
        });
      }
    },
    [refresh],
  );

  const handleSearchPayments = React.useCallback(
    async (keywords: string): Promise<void> => {
      const payments = await refresh({
        keywords,
        date_start: '',
        date_end: '',
      });

      if (payments) {
        setEntityList(payments);
      }
    },
    [refresh, setEntityList],
  );

  const handleRefreshPayments = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      page = 1,
      ...rest
    }: Partial<IUsePaymentListProps> & { page?: number }): Promise<void> => {
      const payments = await refresh({
        offset,
        limit,
        ...rest,
      });

      if (payments) {
        setEntityList(payments);
        setPage(page);
      }
    },
    [refresh, setEntityList],
  );

  useEffect(() => {
    if (isThereContent(stats)) {
      setLocalStats(stats);
    }
  }, [stats]);

  return {
    payments,
    offset,
    limit,
    refresh,
    total,
    loading: paymentListLoading || loading,
    ...clientsParams,
    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments,
    handleSearchPayments,
    handlePickPaymentRange,
    cashbox_uuid,
    stats: localStats,
    handleRefreshPayments,
    page,
  };
}
