import { Record } from 'immutable';
import {
  CategoryDTO,
  ProfileDTO,
  ProfileModel,
  ProfileMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export const CATEGORY_STATUS_ACTIVE = 1;

export interface ICategoryModelReturnType {
  pos: string;
  status: number;
  uuid: string;
  title: string;
  description: string;
  created_by: ProfileModel | ProfileDTO;
  updated_at: string;
  created_at: string;
}

export class CategoryModel extends Record<ICategoryModelReturnType>({
  pos: '',
  status: 0,
  uuid: '',
  title: '',
  description: '',
  created_by: {} as ProfileModel,
  updated_at: '',
  created_at: '',
}) {
  constructor(
    props: CategoryDTO = {} as CategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const option: ICategoryModelReturnType = {
      pos: props?.pos,
      status: props?.status,
      uuid: props?.uuid,
      title: props?.title,
      description: props?.description,
      updated_at: props?.updated_at,
      created_at: props?.created_at,

      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.category,
              ...withoutRecursion,
            ]),
    };

    super(option);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
}
