import React from 'react';
import SearchSelect, { ISearchSelectProps } from '../SearchSelect';
import { useTranslation } from 'react-i18next';
import Label from '../Label';
import styled from 'styled-components';

export interface SearchSelectProps extends Omit<ISearchSelectProps, 'ref'> {}

const StyledSearchSelect = styled(SearchSelect)`
  width: 185px;
`;

export interface IListSearchSelectProps extends SearchSelectProps {
  label?: string;
  tooltipTitle?: string;
  loading?: boolean;
  fullWidth?: boolean;
  value?: string | null;
}

export function ListSearchSelect({
  label,
  tooltipTitle,
  loading,
  fullWidth,
  disable,
  value,
  ...rest
}: IListSearchSelectProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Label label={label} tooltipTitle={tooltipTitle} fullWidth={fullWidth}>
      <StyledSearchSelect
        size="small"
        {...rest}
        defaultValue={value}
        withoutForm
        disable={!!loading || disable}
      />
    </Label>
  );
}
