import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { StyledTitle } from '@components/lib/Styled';
import { useModal } from '@components/lib/libV2/hooks';
import {
  CHECKBOX_ATTRIBUTES,
  IntegrationAssociationDTO,
  IntegrationCheckboxAttributesDTO,
  IntegrationFormDTO,
  IntegrationMapper,
  IntegrationModel,
  IntegrationService,
} from '@structure';
import { Button, SuspenseEmpty } from '@components/lib/DataDisplay';
import styled from 'styled-components';
import {
  LoadingOutlined,
  LockOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { IUseStateIntegrationListReturnType } from '@hooks';
import { useDropdownAlert } from '@contex';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { isFunction } from '@sportix/sportix-common-modules';

const IntegrationCheckboxSideWindowLazy = React.lazy(
  () => import('../Show/IntegrationCheckboxSideWindow'),
);

export interface IntegrationCreateButtonProps<T>
  extends Omit<IActionButtonProps, 'children'>,
    Pick<IUseStateIntegrationListReturnType, 'handleGetIntegration'> {
  onSuccess: (value: IntegrationFormDTO<T>) => Promise<void>;
  onCancel?: () => void;
  integration: IntegrationModel;
}

const StyledButton = styled(Button)`
  width: fit-content;
  height: fit-content;
`;

const IntegrationComponentStrategy = {
  [IntegrationService.CHECKBOX]: IntegrationCheckboxSideWindowLazy,
};

const IntegrationStrategy = {
  [IntegrationService.CHECKBOX]: (
    association: IntegrationAssociationDTO<IntegrationCheckboxAttributesDTO>,
  ) =>
    produce<IntegrationAssociationDTO<IntegrationCheckboxAttributesDTO>>(
      association,
      (draft) => {
        draft.attributes.cashboxes = draft.attributes.cashboxes.map(
          (cashBox) => ({ ...cashBox, uuid: uuidv4() }),
        );
      },
    ),
};

export function IntegrationSettingButton<T>({
  onSuccess,
  onCancel,
  integration,
  handleGetIntegration,
}: IntegrationCreateButtonProps<T>): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<IntegrationFormDTO<T> | null>(
    null,
  );

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  const IntegrationAttributesStrategy = useMemo(
    () => ({
      [IntegrationService.CHECKBOX]: CHECKBOX_ATTRIBUTES as T,
    }),
    [],
  );

  const handleInitAttributes = useCallback(async () => {
    setLoading(true);
    try {
      let { association } = await handleGetIntegration<T>(integration?.uuid);

      if (association) {
        const updater = IntegrationStrategy[integration.service];

        if (isFunction(updater)) {
          association = updater(association as any) as any;
        }
      }

      setAttributes(
        IntegrationMapper.toIntegrationFormDTO<T>(association, {
          defaultAttributes:
            IntegrationAttributesStrategy[integration?.service],
        }),
      );

      handleOnInit();
    } catch (error: any) {
      alert(
        'error',
        t('Integration'),
        t('An error occurred during get integration'),
      );
    } finally {
      setLoading(false);
    }
  }, [
    IntegrationAttributesStrategy,
    alert,
    handleGetIntegration,
    handleOnInit,
    integration.service,
    integration?.uuid,
    t,
  ]);

  const renderIntegrationForm = () => {
    const IntegrationForm = IntegrationComponentStrategy[integration?.service];
    return attributes && IntegrationForm ? (
      <IntegrationForm
        attributes={attributes as any}
        onSuccess={handleSuccess as any}
        visible={visible}
        onCancel={handleCancel}
        title={integration?.title}
      />
    ) : null;
  };

  const disabled = !IntegrationAttributesStrategy[integration?.service];

  return (
    <>
      <StyledButton
        disabled={disabled}
        icon={
          loading ? (
            <LoadingOutlined />
          ) : disabled ? (
            <LockOutlined />
          ) : (
            <SettingOutlined />
          )
        }
        onClick={handleInitAttributes}>
        <StyledTitle fontSize={15}>{t('Adjust')}</StyledTitle>
      </StyledButton>

      <Suspense fallback={<SuspenseEmpty />}>
        {renderIntegrationForm()}
      </Suspense>
    </>
  );
}
