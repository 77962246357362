import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Text } from '@components/lib/Format';
import { useTranslation } from 'react-i18next';
import { useStoreVerificationFactory } from '@hooks';
import { VerificationInstructionsTabPanel } from './Show';

import {
  AuthLayout,
  HeaderText,
  AuthRouteLayout,
  FullWidthSpace,
} from '../Show';

export function VerificationInstructionsPage(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    disabled,
    seconds,
    loading,
    identifier,
    prevIdentifier,
    handleRegenerationCode,
    initStateFactory,
    updateStateFactory,
  } = useStoreVerificationFactory<{
    identifier: string;
    prevIdentifier: string;
  }>({
    key: location?.state?.verificationStateName,
  });

  useEffect(() => {
    if (loading) {
      initStateFactory();
    }
  }, [initStateFactory, loading]);

  return (
    <AuthLayout>
      <VerificationInstructionsTabPanel
        disabled={disabled}
        seconds={seconds}
        handleRegenerationCode={async () => {
          await updateStateFactory({
            identifier: identifier.includes('@') ? prevIdentifier : identifier,
            prevIdentifier: identifier.includes('@')
              ? identifier
              : prevIdentifier,
          });
          await handleRegenerationCode(
            identifier.includes('@') ? prevIdentifier : identifier,
          );
        }}>
        {({ routes }) => (
          <AuthRouteLayout
            header={
              <FullWidthSpace direction="vertical" size="middle">
                <HeaderText title={t('Instruction')} />
                <Text fontSize={16}>
                  {t('1. You can send the SMS code again.')}
                </Text>
                <Text fontSize={16}>
                  {t(
                    '2. If the SMS code does not arrive, try to receive it by e-mail.',
                  )}
                </Text>
                <Text fontSize={16}>
                  {t(
                    '3. If the previous actions did not help, contact us through our site and we will help you as soon as possible.',
                  )}
                </Text>
              </FullWidthSpace>
            }
            routes={routes}
          />
        )}
      </VerificationInstructionsTabPanel>
    </AuthLayout>
  );
}
