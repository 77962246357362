import * as React from 'react';
import { List } from 'immutable';
import {
  ApiError,
  IEntityProps,
  IListSearchProps,
  PERSONALITY_TYPE,
} from '@services/types';
import {
  IDayOffListDTO,
  DayOffMapper,
  DayOffModel,
  DayOffListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getEmployeeDayOffList } from '@services/api/employeeDayOffServices';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseEmployeeDayOffListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  employeeUuid: string;
  personality?: PERSONALITY_TYPE;
}

export interface IUseEmployeeDayOffListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<DayOffModel> | null;
  refresh: (
    value: Partial<IUseEmployeeDayOffListProps>,
  ) => Promise<DayOffListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  employeeUuid: string;
}

export function useEmployeeDayOffList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    employeeUuid,
  }: IUseEmployeeDayOffListProps = {} as IUseEmployeeDayOffListProps,
): IUseEmployeeDayOffListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    employeeUuid: listEmployeeUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseEmployeeDayOffListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    employeeUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      employeeUuid = listEmployeeUuid,
    }: Partial<IUseEmployeeDayOffListProps> = {}): Promise<DayOffListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          employeeUuid,
        });

        const { dayOff, total } = await cancellablePromise<IDayOffListDTO>(
          getEmployeeDayOffList({ employeeUuid, limit, offset, keywords }),
        );

        const dayOffListModel = DayOffMapper.toDayOffListModel(dayOff, total);

        handleUpdate({
          entityList: dayOffListModel?.days_off,
          total: dayOffListModel?.total,
          loading: false,
        });

        return dayOffListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Day off'),
          `${t('An error occurred during get employee day off list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listEmployeeUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && employeeUuid && !didCancel.current) {
      (async () => {
        await refresh({ employeeUuid });
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, employeeUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    employeeUuid: listEmployeeUuid,
  };
}
