import * as React from 'react';
import {
  SettingsModuleDependencyDTO,
  SettingsModuleDependencyModel,
  SettingsModuleMapper,
  SettingsModuleModel,
} from '@structure';
import {
  getSettingsModuleById,
  linkingModuleDependency,
} from '@services/api/system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import {
  resetSettingsModule as storeResetSettingsModule,
  setSettingsModule as storeSetSettingsModule,
  updateSettingsModule as storeUpdateSettingsModule,
  updateSettingsModuleFromList as storeUpdateSettingsModuleFromList,
} from '@store/actions';
import useStoredCompanies from './useStoredCompanies';
import {
  useStoredSettingsModuleList,
  IUseStoredSettingsModuleListReturnType,
} from './useStoredSettingsModuleList';
import {
  useSettingsModule,
  IUseSettingsModuleProps,
  IUseSettingsModuleReturnType,
} from './useSettingsModule';
import { useInterval } from './useInterval';
import {
  API_CALL_PAYMENT_TIMEOUT_CLEAR,
  API_CALL_PAYMENT_TIMEOUT_DELAY,
} from '@services/const';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStoredSettingsModuleProps
  extends Omit<IUseSettingsModuleProps, 'companyUuid'> {}

export interface IUseStoredSettingsModuleReturnType
  extends Pick<
      IUseStoredSettingsModuleListReturnType,
      | 'handleActivateModule'
      | 'handleDeactivateModule'
      | 'loadingActivateOrDeactivate'
    >,
    Omit<IUseSettingsModuleReturnType, 'entity'> {
  settingsModule: SettingsModuleModel | null;
  handleLinkinModuleDependency: (dependencyUuid: string) => Promise<void>;
  handleResetSettingsModule: () => void;
  handleRefreshSettingsModule: () => Promise<SettingsModuleModel | void>;
  status: REDUX_STATUS;
  loading: boolean;
  loadingLinkingDependency: boolean;
  loadingDependencyUuid: string;
}

export function useStoredSettingsModule(
  {
    loadOnInit = true,
    settingsModuleUuid,
    ...rest
  }: IUseStoredSettingsModuleProps = {} as IUseStoredSettingsModuleProps,
): IUseStoredSettingsModuleReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { defaultCompanyUuid } = useStoredCompanies();
  const [loadingLinkingDependency, setLoadingLinkingDependency] =
    React.useState(false);
  const [loadingDependencyUuid, setLoadingDependencyUuid] = useState('');

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {
    status: storedSettingsModuleStatuses,
    settingsModule,
    ...storedSettingsModuleParams
  } = useSelector(({ settingsModule }: RootState) => settingsModule);

  const { entity, refresh, loading, ...settingsModuleParams } =
    useSettingsModule({
      loadOnInit: loadOnInit,
      settingsModuleUuid,
      ...rest,
      companyUuid: defaultCompanyUuid,
    });

  const {
    handleUpdateSettingsModule,
    handleActivateModule,
    handleDeactivateModule,
    loadingActivateOrDeactivate,
  } = useStoredSettingsModuleList({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
  });

  React.useEffect(() => {
    if (entity && loadOnInit && !once.current) {
      dispatch(storeSetSettingsModule(entity));
      once.current = true;
    }
  }, [dispatch, entity, loadOnInit]);

  const { intervalCallback, handleClearInterval } = useInterval<string>(
    async (settingsModuleDependencyUuid: string) => {
      try {
        const apiSettingsModuleDTO = await getSettingsModuleById(
          defaultCompanyUuid,
          settingsModuleUuid,
        );

        if (apiSettingsModuleDTO) {
          const findById = ({
            uuid,
          }: SettingsModuleDependencyModel | SettingsModuleDependencyDTO) =>
            uuid === settingsModuleDependencyUuid;

          const apiSettingsModuleDependency =
            apiSettingsModuleDTO.dependencies.find(findById);

          const settingsModuleDependency =
            settingsModule?.dependencyListModel.find(findById);

          if (
            apiSettingsModuleDependency?.dep_company_usage?.total !==
            settingsModuleDependency?.dep_company_usage?.total
          ) {
            const apiSettingsModule =
              SettingsModuleMapper.toSettingsModuleModel(apiSettingsModuleDTO);

            handleUpdateSettingsModule(apiSettingsModule);
            setLoadingDependencyUuid('');
            handleClearInterval();
          }
        }
      } catch (error: any) {
        handleClearInterval();
        setLoadingDependencyUuid('');
      }
    },
    API_CALL_PAYMENT_TIMEOUT_DELAY,
    API_CALL_PAYMENT_TIMEOUT_CLEAR,
  );

  const handleLinkinModuleDependency = React.useCallback(
    async (dependencyUuid: string): Promise<void> => {
      try {
        setLoadingLinkingDependency(true);
        const { checkout_url } = await linkingModuleDependency(
          defaultCompanyUuid,
          dependencyUuid,
        );
        setLoadingLinkingDependency(false);
        setLoadingDependencyUuid(dependencyUuid);

        window.open(checkout_url, '_blank');

        intervalCallback(dependencyUuid);
      } catch (error: any) {
        setLoadingLinkingDependency(false);
        setLoadingDependencyUuid('');

        alert(
          'error',
          t('Module dependency'),
          `${t('An error occurred during deactivate module dependency')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, defaultCompanyUuid, intervalCallback, t],
  );

  const handleRefreshSettingsModule =
    React.useCallback(async (): Promise<SettingsModuleModel | void> => {
      const settingsModuleModel = await refresh({
        showLoading: false,
        settingsModuleUuid,
      });

      if (settingsModuleModel) {
        dispatch(storeUpdateSettingsModule(settingsModuleModel));
        dispatch(storeUpdateSettingsModuleFromList(settingsModuleModel));

        return settingsModuleModel;
      }
    }, [dispatch, refresh, settingsModuleUuid]);

  const handleResetSettingsModule = React.useCallback(() => {
    dispatch(storeResetSettingsModule());
  }, [dispatch]);

  return {
    ...storedSettingsModuleParams,
    ...settingsModuleParams,
    settingsModule,
    loading,
    loadingLinkingDependency,
    handleLinkinModuleDependency,
    status: storedSettingsModuleStatuses,
    handleResetSettingsModule,
    handleRefreshSettingsModule,
    handleActivateModule,
    handleDeactivateModule,
    loadingActivateOrDeactivate,
    refresh,
    loadingDependencyUuid,
  };
}
