import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col } from 'antd';
import classNames from 'classnames';
import CalendarEvent from './CalendarEvent';
import {
  MutationScheduleEvent,
  len,
  isThereContent,
  sub,
  lessOrEqualThan,
  greaterThan,
} from '@services/helpers';
import { ILiteralObj } from '@services/types';
import { useOutsideClickHandler } from '@hooks';
import CalendarMonthColModal from './CalendarMonthColModal';

import './CalendarMonthCol.less';

export interface ICalendarMonthColProps {
  events: MutationScheduleEvent[];
  dateNumber: number;
  weekday: string | undefined;
  index: number;
  date: Date;
  isToday: boolean;
  handleChooseDay: (date: Date) => void;
  loading?: boolean;
}

export default function CalendarMonthCol({
  events,
  dateNumber,
  weekday,
  isToday,
  index,
  date,
  handleChooseDay,
  loading,
}: ICalendarMonthColProps): JSX.Element {
  const [isVisibleAllEvents, setIsVisibleAllEvents] = useState<boolean>(false);
  const [visibleShowMore, setVisibleShowMore] = useState<boolean>(false);

  const colRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isThereContent(events) && greaterThan(len(events), 4)) {
      setVisibleShowMore(true);
    }
  }, [events]);

  const getColRect = useCallback(
    (): DOMRect | ILiteralObj => colRef.current?.getBoundingClientRect() || {},
    [],
  );

  const handleShowAllEvents = useCallback(
    (): void => setIsVisibleAllEvents(true),
    [],
  );
  const handleCloseShowAllEvents = useCallback(
    (): void => setIsVisibleAllEvents(false),
    [],
  );

  useOutsideClickHandler(modalRef, handleCloseShowAllEvents);

  const eventsDisplay = (
    numberOfVisibleEvents: number = 3,
    visibleShowMore?: boolean,
    showWeekDay: boolean = false,
  ): JSX.Element => (
    <>
      <div className="month-col-date">
        {(lessOrEqualThan(index, 6) || showWeekDay) && (
          <span className="month-col-date-text month-col-date-text--weekday">
            {weekday}
          </span>
        )}
        <div
          role="button"
          onClick={() => handleChooseDay(date)}
          className={classNames('month-col-date-wrapper-text', {
            'month-col-date-wrapper-text--today': isToday,
          })}>
          <span className="month-col-date-text">{dateNumber}</span>
        </div>
      </div>
      {!loading && (
        <CalendarEvent
          events={events}
          direction="vertical"
          numberOfVisibleEvents={
            lessOrEqualThan(index, 6)
              ? sub(numberOfVisibleEvents, 1)
              : numberOfVisibleEvents
          }
          visibleShowMore={visibleShowMore}
          onShowMoreClick={handleShowAllEvents}
          isToday={isToday}
        />
      )}
    </>
  );

  return (
    <Col className="month-col" flex="14.2%" ref={colRef}>
      {eventsDisplay(visibleShowMore ? 2 : 3, visibleShowMore)}
      {isVisibleAllEvents ? (
        <CalendarMonthColModal
          ref={modalRef}
          parentRect={getColRect() as DOMRect}
          onClick={handleCloseShowAllEvents}>
          {eventsDisplay(len(events) as number, false, true)}
        </CalendarMonthColModal>
      ) : null}
    </Col>
  );
}
