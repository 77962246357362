import { List } from 'immutable';
import { APP_STATE, ABONEMENT_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { AbonementModel } from '@structure';

interface IAbonementListState {
  abonementList: List<AbonementModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
}

interface SetActionList
  extends Pick<
    IAbonementListState,
    'abonementList' | 'keywords' | 'total' | 'page'
  > {
  type: ABONEMENT_LIST.SET_ABONEMENT_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: ABONEMENT_LIST.ADD_ABONEMENT;
  abonement: AbonementModel;
}

interface UpdateAbonementFromList {
  type: ABONEMENT_LIST.UPDATE_ABONEMENT;
  abonement: AbonementModel;
}

interface DeleteActionFromList {
  type: ABONEMENT_LIST.DELETE_ABONEMENT;
  abonementUuid: string;
}

interface LoadingActionInList {
  type: ABONEMENT_LIST.LOADING_ABONEMENT_LIST;
}

interface ErrorActionInList extends Pick<IAbonementListState, 'error'> {
  type: ABONEMENT_LIST.ERROR_ABONEMENT_LIST;
}

interface Handlers {
  [ABONEMENT_LIST.SET_ABONEMENT_LIST]: (
    state: IAbonementListState,
    action: SetActionList,
  ) => IAbonementListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IAbonementListState,
    action: SetInitialStateAction,
  ) => IAbonementListState;

  [ABONEMENT_LIST.LOAD_MORE_ABONEMENT_LIST]: (
    state: IAbonementListState,
    action: SetActionList,
  ) => IAbonementListState;

  [ABONEMENT_LIST.ADD_ABONEMENT]: (
    state: IAbonementListState,
    action: AddActionToList,
  ) => IAbonementListState;

  [ABONEMENT_LIST.UPDATE_ABONEMENT]: (
    state: IAbonementListState,
    action: UpdateAbonementFromList,
  ) => IAbonementListState;

  [ABONEMENT_LIST.DELETE_ABONEMENT]: (
    state: IAbonementListState,
    action: DeleteActionFromList,
  ) => IAbonementListState;

  [ABONEMENT_LIST.ERROR_ABONEMENT_LIST]: (
    state: IAbonementListState,
    value: ErrorActionInList,
  ) => IAbonementListState;

  [ABONEMENT_LIST.LOADING_ABONEMENT_LIST]: (
    state: IAbonementListState,
    value?: LoadingActionInList,
  ) => IAbonementListState;
  DEFAULT: (state: IAbonementListState) => IAbonementListState;
}

const initState: IAbonementListState = {
  abonementList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [ABONEMENT_LIST.SET_ABONEMENT_LIST]: (
    state,
    { abonementList, keywords, total, page },
  ) => ({
    ...state,
    ...{
      abonementList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [ABONEMENT_LIST.LOAD_MORE_ABONEMENT_LIST]: (state, { abonementList }) => ({
    ...state,
    ...{
      abonementList:
        List.isList(state.abonementList) && List.isList(abonementList)
          ? state.abonementList.merge(abonementList)
          : state.abonementList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [ABONEMENT_LIST.ADD_ABONEMENT]: (state, { abonement }) => ({
    ...state,
    ...{
      abonementList: List.isList(state.abonementList)
        ? state.abonementList.pop().unshift(abonement)
        : List([abonement]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [ABONEMENT_LIST.UPDATE_ABONEMENT]: (state, { abonement }) => ({
    ...state,
    ...{
      abonementList: List.isList(state.abonementList)
        ? state.abonementList.map((stateAbonement) => {
            if (stateAbonement.uuid === abonement?.uuid) {
              return abonement;
            }
            return stateAbonement;
          })
        : List([abonement]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [ABONEMENT_LIST.DELETE_ABONEMENT]: (state, { abonementUuid }) => ({
    ...state,
    ...{
      abonementList: List.isList(state.abonementList)
        ? state.abonementList.filter(compose(not, isEqualByUuid(abonementUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [ABONEMENT_LIST.ERROR_ABONEMENT_LIST]: (
    state: IAbonementListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [ABONEMENT_LIST.LOADING_ABONEMENT_LIST]: (state: IAbonementListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IAbonementListState) => state,
};

export default function AbonementList(
  state: any = initState,
  action: any,
): IAbonementListState {
  const handler = handlers[action.type as ABONEMENT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
