export class StatusError extends Error {
  response: { status: number; message: string };

  constructor(name: string, status: number) {
    super();
    Object.setPrototypeOf(this, StatusError.prototype);
    this.message = `${name}`;
    this.response = { status, message: this.message };
  }
}
