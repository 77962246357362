import { produce } from 'immer';
import { EmployeeDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useRef } from 'react';
import { Col, Row, Form, Input, Alert, Tooltip } from 'antd';
import { EMPLOYEE_SHIFT_INITIAL_DATA } from '@services/api/employee';
import { getDiffHours, isFunction, toDateByFormat } from '@services/helpers';

import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { EmployeeShiftFormDTO } from 'structure/dto/EmployeeShiftFormDTO';
import styled from 'styled-components';

export interface IEmployeeCloseShiftFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  employee: EmployeeDTO;
  onCancel?: () => void;
  onSuccess: (value: EmployeeShiftFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  employeeShift?: EmployeeShiftFormDTO;
}

const StyledShiftDataContainer = styled.div`
  text-align: center;
`;

const StyledEmployeeShiftId = styled.span`
  font-size: 20px;
  font-weight: 600;
  padding: 0 5px;
`;

const StyledShiftResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledWorkedHours = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 32px;
  font-weight: bold;
  padding: 0 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  position: relative;
  bottom: 10px;
  margin-left: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blueGrayCrayola};
  cursor: pointer;
`;

const StyledWorkedSuffix = styled.span`
  color: ${({ theme }) => theme.colors.greyBlackberry};
`;

/**
 * @desc Відкриття зміни для співробітника
 **/
export default function EmployeeCloseShiftForm({
  loading,
  editMode,
  employee,
  onSuccess,
  employeeShift = EMPLOYEE_SHIFT_INITIAL_DATA,
  ...rest
}: IEmployeeCloseShiftFormProps) {
  const { alert } = useDropdownAlert();
  const contentRef = useRef(null);
  const { t } = useTranslation();
  const hoursDiff = useMemo(
    () =>
      getDiffHours(
        new Date(),
        new Date(employee?.getActiveShift()?.openedAt || ''),
      ),
    [employee],
  );
  const handleOnSuccess = useCallback(
    (formData: EmployeeShiftFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(formData, (draft) => {
          draft.employeeUuid = employee.uuid;
          draft.uuid = employeeShift.uuid;
        });
        return onSuccess(updatedValue);
      }
    },
    [employee.uuid, employeeShift, onSuccess],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Close employee shift'),
        `${t(`An error occurred during the employee shift manipulation`)}: ${
          apiError?.message
        } `,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm<EmployeeShiftFormDTO, EmployeeShiftFormDTO>
      initialValues={{
        ...employeeShift,
      }}
      notifyError={notifyError}
      showNotify={false}
      editMode={editMode}
      onSuccess={handleOnSuccess}
      submitButtonText={t('Close employee shift')}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={20} ref={contentRef}>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Alert
                showIcon
                type="info"
                message={t('Shift operations')}
                description={t(
                  'Closing the employee shift is fixating the current employee results and calculate appropriated' +
                    ' bonuses',
                )}
              />
            </Col>
            <Col span={24}>
              <StyledShiftDataContainer>
                {t('Work shift #')}
                <StyledEmployeeShiftId>
                  {employee?.getActiveShift()?.num}
                </StyledEmployeeShiftId>
                {t('started at {{shiftDate}}', {
                  shiftDate: toDateByFormat(
                    employee?.getActiveShift()?.openedAt,
                    'DD.MM.YYYY HH:mm',
                  ),
                })}
              </StyledShiftDataContainer>
            </Col>
            <Col span={24}>
              <StyledShiftResultContainer>
                {t('Hours actually worked')}:
                <StyledWorkedHours>{hoursDiff}</StyledWorkedHours>
                <StyledWorkedSuffix>{t('hour(s)')}</StyledWorkedSuffix>
                <Tooltip
                  title={t(
                    'The shift #{{shiftNum}} has been started at {{shiftOpenDate}}',
                    {
                      shiftNum: employee.getActiveShift()?.num,
                      shiftOpenDate: toDateByFormat(
                        employee.getActiveShift()?.openedAt,
                        'DD.MM.YYYY HH:mm',
                      ),
                    },
                  )}>
                  <StyledInfoCircleOutlined />
                </Tooltip>
              </StyledShiftResultContainer>
            </Col>
            <Col span={24}>
              <Form.Item
                tooltip={t('Comment, if needed, the shift close operation')}
                label={t('Comment')}
                name="comment">
                <Input.TextArea
                  data-testid="employee-shift-input-comment"
                  disabled={loading || loadingSubmit}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={t('Enter comment')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
