import { Record, List } from 'immutable';
import { IListProps } from './List';
import { SettingsModuleDTO, SettingsModuleModel } from '../internal';

export interface ISettingsModuleListModelProps extends IListProps {
  settings_modules: SettingsModuleDTO[];
}

export interface ISettingsModuleListModelReturnType extends IListProps {
  settingsModules: List<SettingsModuleModel>;
  total: number;
}

export class SettingsModuleListModel extends Record<ISettingsModuleListModelReturnType>(
  {
    settingsModules: List(),
    total: 0,
  },
) {
  constructor(
    {
      settings_modules,
      ...props
    }: ISettingsModuleListModelProps = {} as ISettingsModuleListModelProps,
  ) {
    super({
      ...props,
      settingsModules: List(
        settings_modules.map((setting) => new SettingsModuleModel(setting)),
      ),
    });
  }
}
