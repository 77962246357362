import { Record, List } from 'immutable';
import { IListProps } from './List';
import { PaymentDTO, PaymentModel, StoreDocumentModel } from '../internal';

export interface IPaymentListModelProps extends IListProps {
  payments: PaymentDTO[];
  document?: StoreDocumentModel;
}

export interface IPaymentListModelReturnType extends IListProps {
  payments: List<PaymentModel>;
  document?: StoreDocumentModel;
}

export class PaymentListModel extends Record<IPaymentListModelReturnType>({
  payments: List(),
  total: 0,
  document: {} as StoreDocumentModel,
}) {
  constructor(
    {
      payments,
      ...props
    }: IPaymentListModelProps = {} as IPaymentListModelProps,
  ) {
    super({
      ...props,
      payments: List(payments.map((payment) => new PaymentModel(payment))),
    });
  }
}
