import React, { Suspense, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SingleLayout } from '@components/lib/Layout';
import { eq, textToUpperCase } from '@services/helpers';
import { DefaultTabPanel, TabItem } from '@components/lib/General';
import {
  useStateGroupPaymentList,
  useStateTemplateList,
  useStateGoogleCalendarList,
  useStoredProfile,
} from '@hooks';
import { ParametersPage } from '../../Parameters';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  RoutesAcl,
  Routes,
  RoutesTitle,
  Routes as RoutesType,
} from '@services/types';
import { useAcl } from '@contex';
import { useNavigate, useParams } from 'react-router';
import { HeaderLeft, SuspenseEmpty } from '@components/lib/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';

const GroupPaymentTabLazy = React.lazy(() => import('../Show/GroupPaymentTab'));
const TemplateTabLazy = React.lazy(() => import('../Show/TemplateTab'));
const GoogleCalendarTabLazy = React.lazy(
  () => import('../Show/GoogleCalendarTab'),
);

export enum SETTING_TABS {
  PARAMETERS = 'parameters',
  GROUP_PAYMENTS = 'group-payments',
  TEMPLATE = 'template',
  CALENDARS = 'calendars',
}

export default withTranslation()(SettingTabPage);

export interface ISettingTabPageProps extends WithTranslation {}

/**
 * @desc Clients page component
 * */
function SettingTabPage({ t }: ISettingTabPageProps) {
  const { settingId } = useParams();
  const navigate = useNavigate();

  const { defaultCompanyUuid, companies, defaultCompanyLoading } =
    useStoredCompanies();
  const { loading: profileLoading } = useStoredProfile();

  const { payment_group, template } = useAcl((acl) => acl);

  const { refresh: groupPaymentsRefresh, ...groupPayments } =
    useStateGroupPaymentList({
      companyUuid: defaultCompanyUuid && defaultCompanyUuid,
      loadOnInit: eq(settingId, SETTING_TABS.GROUP_PAYMENTS),
    });

  const {
    refresh: templateRefresh,
    limit: templateLimit,
    ...templateProps
  } = useStateTemplateList({
    companyUuid: defaultCompanyUuid && defaultCompanyUuid,
    loadOnInit: eq(settingId, SETTING_TABS.TEMPLATE),
  });

  const { refresh: googleCalendarRefresh, ...googleCalendarProps } =
    useStateGoogleCalendarList({
      companyUuid: defaultCompanyUuid,
      loadOnInit: eq(settingId, SETTING_TABS.CALENDARS),
    });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.settings}/${SETTING_TABS.PARAMETERS}`,
      breadcrumbName: 'Settings',
    },
  ]);

  const settingTabs = [
    {
      key: SETTING_TABS.PARAMETERS,
      title: t('Parameters'),
      aclItem: RoutesAcl[Routes.settings],
      disabled: false,
      refresh: () => {},
      renderer: () => <ParametersPage />,
    },
    {
      key: SETTING_TABS.GROUP_PAYMENTS,
      title: t('Group payments'),
      aclItem: RoutesAcl[Routes.payment_group],
      disabled: false,
      refresh: groupPaymentsRefresh,
      renderer: () => (
        <Suspense fallback={<SuspenseEmpty />}>
          <GroupPaymentTabLazy
            disabled={!payment_group?.manage}
            defaultCompanyUuid={defaultCompanyUuid}
            refresh={groupPaymentsRefresh}
            companyList={companies as any}
            {...groupPayments}
          />
        </Suspense>
      ),
    },
    {
      key: SETTING_TABS.TEMPLATE,
      title: t('Templates'),
      aclItem: RoutesAcl[Routes.template],
      disabled: false,
      refresh: templateRefresh,
      renderer: () => (
        <Suspense fallback={<SuspenseEmpty />}>
          <TemplateTabLazy
            refresh={templateRefresh}
            {...templateProps}
            pageSize={templateLimit}
            limit={templateLimit}
            disabled={!template?.manage}
          />
        </Suspense>
      ),
    },
    // {
    //   key: SETTING_TABS.CALENDARS,
    //   title: t('Calendars'),
    //   disabled: false,
    //   refresh: templateRefresh,
    //   renderer: () => (
    //     <Suspense fallback={<SuspenseEmpty />}>
    //       <GoogleCalendarTabLazy
    //         companyUuid={defaultCompanyUuid}
    //         refresh={googleCalendarRefresh}
    //         {...googleCalendarProps}
    //       />
    //     </Suspense>
    //   ),
    // },
  ];

  const handleChangeTab = useCallback(
    ({ key: tabKey }: TabItem = {} as TabItem): void => {
      navigate(`/${Routes.app}/settings/${tabKey}`);
    },
    [navigate],
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <SingleLayout
      loading={defaultCompanyLoading || profileLoading}
      aclItem={RoutesAcl[Routes.settings]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.settings]))}
      routes={routes}>
      <DefaultTabPanel
        tabs={settingTabs}
        defaultActiveKey={settingId}
        activeKey={settingId}
        onTabChange={handleChangeTab}
      />
    </SingleLayout>
  );
}
