import React from 'react';
import EmptyLayout from './EmptyLayout';
import { IModulesMap, useSystemModules } from '@hooks';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export interface ISystemModulesLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  moduleItem?: keyof IModulesMap;
  height?: number;
}

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

export function SystemModulesLayout({
  children,
  moduleItem,
  height,
}: ISystemModulesLayoutProps): JSX.Element {
  const { modulesMap } = useSystemModules();

  return (
    <>
      {modulesMap && moduleItem && modulesMap[moduleItem]?.enabled ? (
        children
      ) : !moduleItem ? (
        children
      ) : (
        <EmptyLayout height={height}>
          {!modulesMap ? <StyledLoadingOutlined /> : null}
        </EmptyLayout>
      )}
    </>
  );
}
