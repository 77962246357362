import { Record, List } from 'immutable';
import {
  SystemSubscriptionDTO,
  RuleLimitationModel,
  RuleLimitationMapper,
} from '../internal';

export interface ISystemSubscriptionModelReturnType {
  subscription_key: string;
  subscription_price: string;
  subscription_title: string;
  subscription_description: string;
  status_txt: string;
  rule_limitations: List<RuleLimitationModel>;
}

/**
 * @class SystemSubscription
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export class SystemSubscriptionModel extends Record<ISystemSubscriptionModelReturnType>(
  {
    subscription_key: '',
    subscription_price: '',
    subscription_title: '',
    subscription_description: '',
    status_txt: '',
    rule_limitations: List(),
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: SystemSubscriptionDTO = {} as SystemSubscriptionDTO) {
    const option: ISystemSubscriptionModelReturnType = {
      ...props,
      rule_limitations: RuleLimitationMapper.toRuleLimitationListModel(
        props?.rule_limitations || [],
      ).rule_limitations,
    };

    super(option);
  }
}
