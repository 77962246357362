import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Label } from '@components/lib/DataDisplay';
import { FlexContainer } from '@components/lib/Styled';

export default withTranslation()(WorkScheduleLabel);

export interface IWorkScheduleLabelProps extends WithTranslation {
  handleChangeView: (setFieldsValue: any) => void;
  isFixedRange: boolean;
  disabled?: boolean;
  label?: string;
}

function WorkScheduleLabel({
  t,
  isFixedRange,
  handleChangeView,
  disabled,
  label = 'Days of the week',
}: IWorkScheduleLabelProps): JSX.Element {
  return (
    <FlexContainer justifyContent="space-between">
      <Label
        label={t(label)}
        tooltipTitle={t(
          'The work schedule according to which the company operates',
        )}
      />
      <Button size="small" onClick={handleChangeView} disabled={disabled}>
        {isFixedRange ? t('Custom range') : t('Fixed range')}
      </Button>
    </FlexContainer>
  );
}
