import { Record, List } from 'immutable';
import { IListProps } from './List';
import { CounterpartyModel } from './CounterpartyModel';
import { CounterpartyDTO } from '../internal';
import { WithoutRecursion } from '../type';

export interface ICounterpartyListModelProps extends IListProps {
  counterparties: CounterpartyDTO[];
}

export interface ICounterpartyListModelReturnType extends IListProps {
  counterparties: List<CounterpartyModel>;
}

export class CounterpartyListModel extends Record<ICounterpartyListModelReturnType>(
  {
    counterparties: List(),
    total: 0,
  },
) {
  constructor(
    {
      counterparties = [],
      total,
    }: ICounterpartyListModelProps = {} as ICounterpartyListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICounterpartyListModelReturnType = {
      counterparties: List(
        counterparties.map(
          (counterparty) =>
            new CounterpartyModel(counterparty, withoutRecursion),
        ),
      ),
      total,
    };

    super(options);
  }
}
