import { EmployeeModel } from '../internal';

export class EmployeeUserDataFormDTO {
  public uuid: string;
  public login: string;
  public password: string;

  constructor(props?: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.login = props?.phone || props?.email || '';
    this.password = '1234567890';
  }
}
