import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { ABONEMENT_INITIAL_PARAM } from '@services/api/abonement';
import { AbonementFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { TariffModelFields } from './TariffModelFields';
import {
  useStateEmployeeList,
  useStateServiceList,
  useStoredCompanies,
} from '@hooks';
import { split } from '@services/helpers';

export interface ITariffModelFormProps
  extends Omit<
    IDefaultFormProps<AbonementFormDTO, AbonementFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  abonement?: AbonementFormDTO;
}

export function TariffModelForm({
  loading,
  editMode,
  abonement = ABONEMENT_INITIAL_PARAM,
  onCancel,
  ...rest
}: ITariffModelFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const {
    services,
    loading: servicesLoading,
    handleSearchServices,
  } = useStateServiceList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const weekdays = useMemo(
    () => ({
      weekdays: split(',', abonement?.timing),
    }),
    [abonement?.timing],
  );

  const groupWeekdays = useMemo(
    () => ({
      group_weekdays: split(',', abonement?.group_schedule),
    }),
    [abonement?.group_schedule],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: abonement?.uuid,
    }),
    [abonement?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Tariff model'),
        `${
          editMode
            ? t('An error occurred during edit tariff model')
            : t('An error occurred during create tariff model')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...abonement,
        ...weekdays,
        ...groupWeekdays,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <TariffModelFields
          services={services}
          servicesLoading={servicesLoading}
          handleSearchServices={handleSearchServices}
          employees={employees}
          employeesLoading={employeesLoading}
          handleSearchEmployees={handleSearchEmployees}
          editMode={!!editMode}
        />
      )}
    </DefaultForm>
  );
}
