import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IStoreDocumentModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IStoreDocumentItemDTOProps,
  IStoreDTOProps,
  IClientDTOProps,
  StoreDTO,
  ClientDTO,
  StoreMapper,
  ClientMapper,
  StoreDocumentItemDTO,
  StoreDocumentMapper,
  IScheduleCalendarDTOProps,
  ScheduleCalendarModel,
  ScheduleCalendarMapper,
  StoreDocumentModel,
  StoreDocumentExtraServiceDTO,
  StoreDocumentExtraServiceDTOProps,
  StoreDocumentMultiCurrencyDTOProps,
  StoreDocumentMultiCurrencyDTO,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
  StoreDocumentProfitStatsCurrency,
  StoreDocumentProfitStatsDTO,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export enum StoreDocumentType {
  IN = 'IN',
  OUT = 'OUT',
  ERASE = 'ERASE',
  RETURN = 'RETURN',
  CRETURN = 'CRETURN',
  MOVE = 'MOVE',
  SALE = 'SALE',
  PREORDER = 'PREORDER',
}

export enum StoreDocumentMappedType {
  SALE_OUT = 'SALE_OUT',
  SALE_OUT_DRAFT = 'SALE_OUT_DRAFT',
  INVOICE_OUT = 'INVOICE_OUT',
  PAYMENT_OUT = 'PAYMENT_OUT',
  SALE_OUT_DRAFT_LIST = 'SALE_OUT_DRAFT_LIST',
}

export const MAPPED_STORE_DOCUMENT_TYPES: {
  [K in StoreDocumentType]?: (StoreDocumentType | StoreDocumentMappedType)[];
} = {
  [StoreDocumentType.OUT]: [
    StoreDocumentType.OUT,
    StoreDocumentMappedType.SALE_OUT,
    StoreDocumentMappedType.SALE_OUT_DRAFT,
    StoreDocumentMappedType.INVOICE_OUT,
    StoreDocumentMappedType.PAYMENT_OUT,
    StoreDocumentMappedType.SALE_OUT_DRAFT_LIST,
  ],
};

export enum StoreDocumentStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ERROR = 'error',
  UNKNOWN = 'unknown',
  CLOSED = 'closed',
  COMMITTED = 'committed',
}

export enum DocTriggerableType {
  ORDER = 'schedule_order',
  SELL = 'sell',
}

export enum StoreDocumentShowOnly {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export interface IStoreDocumentDTOProps {
  uuid: string;
  doc_type: StoreDocumentType;
  doc_date: string;
  doc_local_number: string;
  doc_input_number: string;
  doc_sum_paid: number;
  doc_paid?: number;
  doc_sum_total: number;
  doc_total?: number;
  doc_comment: string;
  doc_status_text: StoreDocumentStatus;
  created_at: string;
  updated_at: string;
  doc_items: IStoreDocumentItemDTOProps[];
  company_store: IStoreDTOProps;
  company_destination_store: IStoreDTOProps;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
  client: IClientDTOProps;
  doc_is_paymentable: boolean;
  doc_is_editable: boolean;
  document: IStoreDocumentDTOProps;
  doc_triggerable_type: DocTriggerableType | null;
  doc_triggerable: IScheduleCalendarDTOProps | IStoreDocumentDTOProps | null;
  doc_is_relatable: boolean;
  count_of_related_documents: number;
  is_add_doc_items?: boolean;
  doc_extra_services?: StoreDocumentExtraServiceDTOProps[];
  doc_multi_currencies?: StoreDocumentMultiCurrencyDTOProps[];
  doc_is_checkable: boolean;
  doc_discount_source?: StorePaymentDocumentDiscountSourceType;
  doc_discount_type?: StorePaymentDocumentDiscountType;
  doc_discount_value?: string;
  doc_sum_total_with_discount: number;
  doc_sum_discount: number;
  doc_sum_services: number;
  stats?: StoreDocumentProfitStatsDTO;
}

export class StoreDocumentDTO {
  public uuid: string;

  public doc_type: StoreDocumentType;
  public doc_date: string;
  public doc_local_number: string;
  public doc_input_number: string;
  public doc_sum_paid: number;
  public doc_sum_total: number;
  public doc_comment: string;
  public doc_status_text: StoreDocumentStatus;
  public created_at: string;
  public updated_at: string;
  public doc_items: StoreDocumentItemDTO[];
  public company_store: StoreDTO;
  public company_destination_store: StoreDTO;
  public created_by: ProfileDTO;
  public company: CompanyDTO;
  public client: ClientDTO;
  public document: StoreDocumentDTO;

  public doc_is_paymentable: boolean;
  public doc_is_editable: boolean;

  public doc_triggerable_type: DocTriggerableType | null;
  public doc_triggerable: ScheduleCalendarModel | StoreDocumentModel | null;

  public doc_is_relatable: boolean;
  public count_of_related_documents: number;
  public is_add_doc_items: boolean;
  public doc_extra_services: StoreDocumentExtraServiceDTO[] | undefined;
  public doc_multi_currencies: StoreDocumentMultiCurrencyDTO[] | undefined;
  public doc_is_checkable: boolean;
  public doc_discount_source?: StorePaymentDocumentDiscountSourceType;
  public doc_discount_type?: StorePaymentDocumentDiscountType;
  public doc_discount_value?: string;
  public doc_sum_total_with_discount: number;
  public doc_sum_discount: number;
  public doc_sum_services: number;
  public stats?: StoreDocumentProfitStatsDTO;

  constructor(
    props: IStoreDocumentModelReturnType | IStoreDocumentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.doc_type = props?.doc_type || '';
    this.doc_date = props?.doc_date || '';
    this.doc_local_number = props?.doc_local_number || '';
    this.doc_input_number = props?.doc_input_number || '';
    this.doc_sum_paid =
      props?.doc_sum_paid || (props as IStoreDocumentDTOProps)?.doc_paid || 0;
    this.doc_sum_total =
      props?.doc_sum_total || (props as IStoreDocumentDTOProps)?.doc_total || 0;
    this.doc_comment = props?.doc_comment || '';
    this.doc_status_text =
      props?.doc_status_text || StoreDocumentStatus.UNKNOWN;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.doc_is_paymentable =
      typeof props?.doc_is_paymentable === 'boolean'
        ? props?.doc_is_paymentable
        : false;

    this.doc_is_editable =
      typeof props?.doc_is_editable === 'boolean'
        ? props?.doc_is_editable
        : false;

    this.doc_is_relatable = props?.doc_is_relatable ?? false;
    this.count_of_related_documents = props?.count_of_related_documents ?? 0;

    this.is_add_doc_items = props?.is_add_doc_items ?? false;

    this.doc_is_checkable = props?.doc_is_checkable ?? false;

    this.doc_discount_source = props?.doc_discount_source ?? undefined;
    this.doc_discount_type = props?.doc_discount_type ?? undefined;
    this.doc_discount_value = props?.doc_discount_value ?? undefined;
    this.doc_sum_total_with_discount = correctPrice(
      props?.doc_sum_total_with_discount || 0,
    );
    this.doc_sum_services = correctPrice(props?.doc_sum_services || 0);
    this.doc_sum_discount = correctPrice(props?.doc_sum_discount);
    this.stats = props?.stats || undefined;

    this.doc_extra_services = (
      Array.isArray(props?.doc_extra_services) ? props?.doc_extra_services : []
    )?.map(
      (service: StoreDocumentExtraServiceDTOProps) =>
        new StoreDocumentExtraServiceDTO(service),
    );

    this.doc_multi_currencies = (
      Array.isArray(props?.doc_multi_currencies)
        ? props?.doc_multi_currencies
        : []
    )?.map(
      (currency: StoreDocumentMultiCurrencyDTOProps) =>
        new StoreDocumentMultiCurrencyDTO(currency),
    );

    this.doc_items =
      withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
        ? (props?.doc_items as StoreDocumentItemDTO[])
        : StoreDocumentMapper.toStoreDocumentItemListDTO(
            props?.doc_items as StoreDocumentItemDTO[],
          ).storeDocumentItems || [];

    this.company_store =
      withoutRecursion.indexOf(WithoutRecursion.store) !== -1
        ? (props?.company_store as StoreDTO)
        : StoreMapper.toStoreDTO(props?.company_store, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);
    this.company_destination_store =
      withoutRecursion.indexOf(WithoutRecursion.store) !== -1
        ? (props?.company_destination_store as StoreDTO)
        : StoreMapper.toStoreDTO(props?.company_destination_store, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.client =
      withoutRecursion.indexOf(WithoutRecursion.client) !== -1
        ? (props?.client as ClientDTO)
        : ClientMapper.toClientDTO(props?.client, true, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.document =
      withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
        ? (props?.document as StoreDocumentDTO)
        : StoreDocumentMapper.toStoreDocumentDTO(props?.document as any, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.doc_triggerable_type = props?.doc_triggerable_type || null;

    this.doc_triggerable = props?.doc_triggerable
      ? this.getTriggerableObject(
          props?.doc_triggerable,
          props?.doc_triggerable_type,
        )
      : null;
  }

  private getTriggerableObject(
    doc_triggerable:
      | ScheduleCalendarModel
      | IScheduleCalendarDTOProps
      | IStoreDocumentDTOProps
      | StoreDocumentModel
      | null,
    doc_triggerable_type: DocTriggerableType | null,
  ) {
    if (doc_triggerable) {
      if (
        doc_triggerable instanceof ScheduleCalendarModel ||
        doc_triggerable instanceof StoreDocumentModel
      ) {
        return doc_triggerable;
      }

      if (doc_triggerable_type === DocTriggerableType.ORDER) {
        return ScheduleCalendarMapper.toScheduleCalendarModel(
          ScheduleCalendarMapper.toScheduleCalendarDTO(
            doc_triggerable as IScheduleCalendarDTOProps,
          ),
        );
      }

      if (doc_triggerable_type === DocTriggerableType.SELL) {
        return StoreDocumentMapper.toStoreDocumentModel(
          StoreDocumentMapper.toStoreDocumentDTO(
            doc_triggerable as IStoreDocumentDTOProps,
          ),
        );
      }

      return null;
    }
    return null;
  }
}
