import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredServiceEmployeeList,
  useStoreEmployee,
  useStoreService,
} from '@hooks';
import { EmployeeModel, ServiceModel } from '@structure';
import { textToUpperCase } from '@services/helpers';
import { EmployeeCreateButton } from '../../Employees';
import { ServiceEmployeeList } from '../List';
import { useAcl } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
  TIME_LIST_SERVICES,
} from '@services/types';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { ListLayout } from '@components/lib/Layout';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import styled from 'styled-components';
import { successButtonStyle } from '@components/lib/Styled';
import { PlusOutlined } from '@ant-design/icons';

const StyledEmployeeCreateButton = styled(EmployeeCreateButton)`
  ${successButtonStyle}
`;

export function ServiceEmployeeListPage(): React.JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const { t } = useTranslation();
  const { manage } = useAcl(({ employee }) => employee);
  const { defaultCompanyUuid } = useStoredCompanies();
  const { ref: listRef } = useKeyboardOpenForm({
    className: 'service-employee-create-event',
    disabled: !manage,
  });

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeModel[]>(
    [],
  );

  const {
    service,
    loading: serviceLoading,
    error: serviceError,
  } = useStoreService({
    companyUuid: defaultCompanyUuid,
    serviceUuid: serviceId!,
  });

  const { handleCreateEmployee } = useStoreEmployee({
    loadOnInit: false,
    employeeUuid: '',
    companyUuid: '',
  });

  const {
    serviceEmployeeList: employees,
    loading: employeeLoading,
    error: employeeError,
    total,
    limit,
    page,
    loadingMore,

    handleSearchServiceEmployees,

    handleRefreshServiceEmployees,
    handleAddEmployeeToListService,
    handleUpdateEmployeeFromListService,
    handleDeleteEmployeesFromListService: onDeleteEmployeesFromListService,
  } = useStoredServiceEmployeeList({
    serviceUuid: serviceId!,
    service,
  });

  useEffect(() => {
    if (List.isList(employees)) {
      const selected = employees.filter(
        ({ employee_service }) => !!employee_service?.updated_at,
      );

      setSelectedEmployees(selected.toArray());
    }
  }, [employees]);

  const loading = useStopLoading({
    loading: employeeLoading || serviceLoading || loadingMore,
    error: employeeError || serviceError,
    message: 'An error occurred during employees loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteEmployeesFromListService = useCallback(
    async (value: EmployeeModel) => {
      await onDeleteEmployeesFromListService(value);
      focus();
    },
    [focus, onDeleteEmployeesFromListService],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedEmployees.map(({ uuid }) => uuid),
      hideSelectAll: true,
      selections: false,
      renderCell: (
        checked: boolean,
        record: ServiceModel[],
        index: number,
        originNode: React.ReactNode,
      ): React.ReactNode => {
        return checked ? originNode : <></>;
      },
    }),
    [selectedEmployees],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.services}`,
      breadcrumbName: 'Services',
    },
    {
      path: `/${Routes.services}/${serviceId}`,
      breadcrumbName: location?.state?.serviceTitle || 'Show Service',
    },
    {
      path: `/${Routes.services}/${serviceId}/${Routes.services}`,
      breadcrumbName: 'Employees',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.services}/${serviceId}`);
  }, [navigate, serviceId]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.services]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.serviceEmployees]))}
      headerRight={
        <EmployeeCreateButton
          disabled={!manage}
          onSuccess={handleCreateEmployee}
          loading={loading}
          className="service-employee-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={handleSearchServiceEmployees}
          inputTooltip={t('Search employees')}
          inputLabel={t('Search employees')}
          withSearchContent={
            <StyledEmployeeCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateEmployee}
              title={t('Create employee')}
              icon={<PlusOutlined />}
              onCancel={daleyFocus}
            />
          }
        />
      }
      empty={
        employees?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any service at the moment.`,
            )}>
            {
              <EmployeeCreateButton
                disabled={!manage}
                key="employee__button--create"
                onSuccess={handleCreateEmployee}
                loading={loading}
                onCancel={daleyFocus}
              />
            }
          </Empty>
        )
      }
      loading={loading && !employees?.size}>
      <ServiceEmployeeList
        price={service?.price}
        period={service?.period || TIME_LIST_SERVICES.MINUTE}
        employees={employees}
        loading={loading}
        handleUpdate={handleUpdateEmployeeFromListService as any}
        handleDelete={handleDeleteEmployeesFromListService}
        handleAdd={handleAddEmployeeToListService}
        rowSelection={rowSelection}
        pageSize={limit}
        total={total}
        refresh={handleRefreshServiceEmployees}
        service={service}
        page={page}
        daleyFocus={daleyFocus}
      />
    </ListLayout>
  );
}
