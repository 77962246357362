import { List } from 'immutable';
import { COMPANIES } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { CompanyModel } from '@structure';

export interface IInitialState {
  companies: List<CompanyModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

export interface SetAction extends Pick<IInitialState, 'companies'> {
  type: COMPANIES.SET_COMPANIES;
}

export interface AddAction {
  type: COMPANIES.ADD_COMPANY;
  company: CompanyModel;
}

export interface UpdateCompany {
  type: COMPANIES.UPDATE_COMPANY;
  company: CompanyModel;
}

export interface DeleteAction {
  type: COMPANIES.DELETE_COMPANY;
  companyUuid: string;
}

export interface LoadingAction {
  type: COMPANIES.LOADING_COMPANIES;
}

export interface ErrorAction extends Pick<IInitialState, 'error'> {
  type: COMPANIES.ERROR_COMPANIES;
}

export interface Handlers {
  [COMPANIES.SET_COMPANIES]: (
    state: IInitialState,
    action: SetAction,
  ) => IInitialState;

  [COMPANIES.ADD_COMPANY]: (
    state: IInitialState,
    action: AddAction,
  ) => IInitialState;

  [COMPANIES.UPDATE_COMPANY]: (
    state: IInitialState,
    action: UpdateCompany,
  ) => IInitialState;

  [COMPANIES.DELETE_COMPANY]: (
    state: IInitialState,
    action: DeleteAction,
  ) => IInitialState;

  [COMPANIES.ERROR_COMPANIES]: (
    state: IInitialState,
    value: ErrorAction,
  ) => IInitialState;

  [COMPANIES.LOADING_COMPANIES]: (
    state: IInitialState,
    value?: LoadingAction,
  ) => IInitialState;
  DEFAULT: (state: IInitialState) => IInitialState;
}

const initState: IInitialState = {
  companies: List(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [COMPANIES.SET_COMPANIES]: (state, { companies }) => ({
    ...state,
    ...{
      companies,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [COMPANIES.ADD_COMPANY]: (state, { company }) => ({
    ...state,
    ...{
      companies: state.companies.push(company),
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [COMPANIES.UPDATE_COMPANY]: (state, { company }) => ({
    ...state,
    ...{
      companies: state.companies.map((stateCompany) => {
        if (stateCompany.uuid === company?.uuid) {
          return company;
        }
        return stateCompany;
      }),
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [COMPANIES.DELETE_COMPANY]: (state, { companyUuid }) => ({
    ...state,
    ...{
      companies: state.companies.filter(
        compose(not, isEqualByUuid(companyUuid)),
      ),
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [COMPANIES.ERROR_COMPANIES]: (
    state: IInitialState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [COMPANIES.LOADING_COMPANIES]: (state: IInitialState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IInitialState) => state,
};

export default function Companies(
  state: any = initState,
  action: any,
): IInitialState {
  const handler = handlers[action.type as COMPANIES] || handlers.DEFAULT;
  return handler(state, action);
}
