import React from 'react';
import { Image } from 'antd';
import styled from 'styled-components';
import { authLogoUrl } from '@services/const';
import { FlexContainer } from '@components/lib/Styled';

const StyledImage = styled(Image)`
  margin-bottom: 25px;
`;

const StyledContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.app};
  width: 100%;
  padding-bottom: 10px;
  height: auto;
`;

export default function AuthHeader(): JSX.Element {
  return (
    <StyledContainer
      justifyContent="center"
      alignItems="center"
      flexDirection="column">
      {authLogoUrl && (
        <StyledImage
          src={authLogoUrl}
          width="20vw"
          alt="logo"
          preview={false}
        />
      )}
    </StyledContainer>
  );
}
