import React from 'react';
import { Image } from 'antd';
import { TextFormat } from '../Format';
import styled from 'styled-components';
import { FlexContainer } from '../Styled';
import { footerAppName } from '@services/const';
import { withTranslation, WithTranslation } from 'react-i18next';

// @ts-ignore
import packagejson from '../../../../package.json';

export default withTranslation()(FooterWithoutLayout);

export interface IFooterWithoutLayoutProps extends WithTranslation {
  className?: string;
  collapsed?: boolean;
}

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const StyledCollapsedVersionWrapper = styled.div`
  font-size: 10px;
`;

const StyledFooter = styled(FlexContainer)`
  background-color: transparent;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledText = styled(TextFormat)`
  color: rgb(109, 110, 116);
`;

function FooterWithoutLayout({
  t,
  className,
  collapsed = false,
}: IFooterWithoutLayoutProps): JSX.Element {
  const year: number = new Date().getFullYear();
  return (
    <StyledFooter
      height="auto"
      justifyContent="center"
      alignItems="center"
      className={className}>
      <StyledText className="footer__text">
        {collapsed ? (
          <StyledLogoWrapper>
            <Image
              src="/servicity-icon.png"
              alt="ServicitY logo"
              width="50px"
              preview={false}
            />
            <StyledCollapsedVersionWrapper>
              v{packagejson?.version}
            </StyledCollapsedVersionWrapper>
          </StyledLogoWrapper>
        ) : (
          <>
            ©{year} {footerAppName} v{packagejson?.version}
            <br />
            {t('All Rights Reserved.')}
          </>
        )}
      </StyledText>
    </StyledFooter>
  );
}
