import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { head } from '../helpers';
import { ApiAnswer, IListSearchProps } from '../types';
import {
  DepartmentDTO,
  DepartmentFormDTO,
  DepartmentMapper,
  IDepartmentDTOProps,
  IDepartmentListDTO,
} from '@structure';
import { contentApiUrl } from '../const';

export const DEPARTMENT_INITIAL_PARAM = new DepartmentFormDTO();

const { uuid, created_at, updated_at, ...DEPARTMENT_INITIAL_PARAM_REST } =
  new DepartmentDTO({} as IDepartmentDTOProps);

export const DEPARTMENT_REQUIRED_FIELD = {
  ...DEPARTMENT_INITIAL_PARAM_REST,
};

export interface IDepartmentAnswer extends ApiAnswer {
  department: IDepartmentDTOProps;
}

export interface IDepartmentListAnswer extends ApiAnswer {
  departments: IDepartmentDTOProps[];
}

export interface IDepartmentListAnswer extends ApiAnswer, IListSearchProps {
  departments: IDepartmentDTOProps[];
}

export interface IDepartmentListProps extends IListSearchProps {
  companyUuid: string;
}

export async function getDepartmentList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: IDepartmentListProps): Promise<IDepartmentListDTO> {
  const { departments, total } = await apiGet<
    IListSearchProps,
    IDepartmentListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/departments`, {
    offset,
    limit,
    keywords,
  });

  return DepartmentMapper.toDepartmentListDTO(departments, total || 0);
}

export async function getDepartmentById(
  departmentUuid: string,
): Promise<DepartmentDTO> {
  const { department } = await apiGet<null, IDepartmentAnswer>(
    `${contentApiUrl}/departments/${departmentUuid}`,
  );

  return DepartmentMapper.toDepartmentDTO(department);
}

export async function createDepartment(
  {
    dep_name,
    dep_description,
    dep_schedule,
    dep_address,
    dep_is_default,
    dep_colour,
    dep_icon,
  }: DepartmentFormDTO,
  companyUuid: string,
): Promise<DepartmentDTO> {
  const { department } = await apiPost<
    Omit<DepartmentFormDTO, 'uuid'>,
    IDepartmentAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/departments`, {
    dep_name,
    dep_description,
    dep_schedule,
    dep_address,
    dep_is_default,
    dep_colour,
    dep_icon,
  });

  return DepartmentMapper.toDepartmentDTO(department);
}

export async function editDepartment({
  uuid: department_uuid,
  dep_name,
  dep_description,
  dep_schedule,
  dep_address,
  dep_is_default,
  dep_colour,
  dep_icon,
}: Omit<DepartmentFormDTO, 'getTimingList'>): Promise<DepartmentDTO> {
  const { department } = await apiPut<
    Omit<DepartmentFormDTO, 'uuid'>,
    IDepartmentAnswer
  >(`${contentApiUrl}/departments/${department_uuid}`, {
    dep_name,
    dep_description,
    dep_schedule,
    dep_address,
    dep_is_default,
    dep_colour,
    dep_icon,
  });

  return DepartmentMapper.toDepartmentDTO(department);
}

export async function deleteDepartment(
  ids: string[] = [],
): Promise<IDepartmentListDTO> {
  const { departments } = await apiDelete<
    { ids: string[] },
    IDepartmentListAnswer
  >(`${contentApiUrl}/departments/${head(ids)}`);

  return DepartmentMapper.toDepartmentListDTO(departments, 0);
}
