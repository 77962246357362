import {
  AlphaNameStatuses,
  CompanyModuleModel,
  CompanyModuleMapper,
  CompanyOrderBonusDTO,
  FinancialSettingsMapper,
  ICompanyOrderBonusDTOProps,
} from '../internal';

import { FINANCIAL_REPORTING, PERIOD_MOVEMENT_FUNDS } from '@services/helpers';

import { List } from 'immutable';

export enum NOTIFICATION_LANGUAGE {
  Ukrainian = 'uk',
  Russian = 'ru',
  English = 'en',
  Unknown = '',
}

export enum CURRENCY_SYMBOL {
  Ukraine = '₴',
  Russia = '₽',
  English = '$',
  Unknown = '',
}

export interface IFinancialSettingsDTOProps {
  period_of_financial_statement: PERIOD_MOVEMENT_FUNDS;
  period_of_payments_movement: FINANCIAL_REPORTING;
  financial_client_uuid: string;
  sms_notification_enabled: boolean;
  email_notification_enabled: boolean;
  personalization_sms_enabled: boolean;
  quantity_round: number;
  price_round: number;
  company_alfa_name: string;
  company_alfa_name_order_id: string;
  company_currency_symbol: string;
  company_notification_language: string;
  company_modules: string | List<CompanyModuleModel>;
  company_alfa_name_order_status: AlphaNameStatuses;
  company_alfa_name_expire_at: string;
  company_order_bonuses: ICompanyOrderBonusDTOProps[];
}

export class FinancialSettingsDTO {
  public period_of_financial_statement: PERIOD_MOVEMENT_FUNDS;
  public period_of_payments_movement: FINANCIAL_REPORTING;
  public financial_client_uuid: string;
  public sms_notification_enabled: boolean;
  public email_notification_enabled: boolean;
  public personalization_sms_enabled: boolean;
  public price_round: number;
  public quantity_round: number;
  public quantity_round_example: number;
  public price_round_example: number;
  public company_alfa_name: string;
  public company_currency_symbol: string;
  public company_notification_language: string;
  public company_alfa_name_order_id: string;
  public company_modules: List<CompanyModuleModel>;
  public company_alfa_name_order_status: AlphaNameStatuses;
  public company_alfa_name_expire_at: string;
  public company_order_bonuses: CompanyOrderBonusDTO[];

  constructor(props?: IFinancialSettingsDTOProps) {
    this.quantity_round = props?.quantity_round || 2;
    this.price_round = props?.price_round || 2;
    this.quantity_round_example = 1000.237439834598798;
    this.price_round_example = 1230.237439834598798;
    this.period_of_financial_statement =
      props?.period_of_financial_statement || PERIOD_MOVEMENT_FUNDS.Two_week;
    this.period_of_payments_movement =
      props?.period_of_payments_movement || FINANCIAL_REPORTING.Day;
    this.financial_client_uuid = props?.financial_client_uuid || '';
    this.company_alfa_name = props?.company_alfa_name || '';
    this.company_currency_symbol =
      props?.company_currency_symbol || CURRENCY_SYMBOL.Ukraine;
    this.company_notification_language =
      props?.company_notification_language || NOTIFICATION_LANGUAGE.Ukrainian;
    this.company_alfa_name_order_id = props?.company_alfa_name_order_id || '';
    this.company_alfa_name_order_status =
      props?.company_alfa_name_order_status || AlphaNameStatuses.Unknown;
    this.company_alfa_name_expire_at = props?.company_alfa_name_expire_at || '';

    this.personalization_sms_enabled =
      props?.personalization_sms_enabled || false;
    this.company_modules = props?.company_modules
      ? List.isList(props?.company_modules)
        ? props?.company_modules
        : CompanyModuleMapper.toCompanyModuleListModel(
            CompanyModuleMapper.toCompanyModuleListDTO(
              JSON.parse(props?.company_modules),
            ).companyModules,
          ).companyModules
      : List();

    this.sms_notification_enabled = this.isSmsNotificationEnabled;
    this.email_notification_enabled = this.isEmailNotificationEnabled;
    this.company_order_bonuses =
      FinancialSettingsMapper.toCompanyOrderBonusListDTO(
        props?.company_order_bonuses || [],
      );
  }

  private get isSmsNotificationEnabled(): boolean {
    const smsModule = this.company_modules.find(
      ({ module }) => module === 'sms',
    );

    if (smsModule) {
      return !!smsModule?.enabled;
    }

    return false;
  }

  private get isEmailNotificationEnabled(): boolean {
    const emailModule = this.company_modules.find(
      ({ module }) => module === 'email',
    );

    if (emailModule) {
      return !!emailModule?.enabled;
    }

    return false;
  }
}
