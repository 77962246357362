import { toDateByFormat } from '@services/helpers';

export enum EEmployeeShiftStatus {
  OPENED = 'opened',
  CLOSED = 'closed',
}

export interface IEmployeeShiftModelProps {
  uuid?: string;
  shift_comment?: string;
  shift_number?: string;
  shift_open_date?: Date;
  shift_status?: EEmployeeShiftStatus;
}

export class EmployeeShiftModel implements IEmployeeShiftModelProps {
  public uuid?: string;
  public shift_number?: string;
  public shift_comment?: string;
  public shift_open_date?: Date;
  public shift_status?: EEmployeeShiftStatus;

  constructor(props: IEmployeeShiftModelProps) {
    this.uuid = props?.uuid;
    this.shift_status = props?.shift_status;
    this.shift_number = props?.shift_number;
    this.shift_comment = props?.shift_comment;
    this.shift_open_date = props?.shift_open_date;
  }

  isOpened(): boolean {
    return this.shift_status === EEmployeeShiftStatus.OPENED;
  }

  isClosed(): boolean {
    return this.shift_status === EEmployeeShiftStatus.CLOSED;
  }

  openedTime(): string {
    return toDateByFormat(this.shift_open_date, 'HH:mm');
  }
}
