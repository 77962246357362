import React, { useCallback, useState } from 'react';
import Empty from 'antd/es/empty';
import { useTranslation } from 'react-i18next';
import { IUseStateServiceListReturnType, useStoredProfile } from '@hooks';
import { AddButton } from '@components/lib/DataDisplay';
import { useDropdownAlert } from '@contex';
import { ServiceFormDTO, ServiceModel } from '@structure';
import { PERSONALITY_TYPE, TIME_LIST_SERVICES } from '@services/types';
import styled from 'styled-components';

export interface IServiceSelectNotFoundContentProps
  extends Pick<
    IUseStateServiceListReturnType,
    'handleCreateService' | 'keywords'
  > {
  size: number;
  firstGroupPaymentUuid: string;
  handleSelectService: (serviceUuid: string, service?: ServiceModel) => void;
  handleOpenServiceForm: () => void;
}

const StyledContainer = styled.div`
  margin-bottom: 20px;
`;

export function ServiceSelectNotFoundContent({
  keywords,
  handleCreateService,
  handleSelectService,
  firstGroupPaymentUuid,
  handleOpenServiceForm,
  size,
}: IServiceSelectNotFoundContentProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { profile } = useStoredProfile();

  const [loading, setLoading] = useState(false);

  const quickCreateService = useCallback(async () => {
    setLoading(true);
    try {
      const value = {
        title: keywords,
        price: '0.00',
        net_price: '0.00',
        period_amount: 1,
        manager_uid: profile?.defaultEmployeeModel?.uuid,
        period: TIME_LIST_SERVICES.HOUR,
        personality: PERSONALITY_TYPE.INDIVIDUAL,
        payment_group_uuid: firstGroupPaymentUuid,
      } as ServiceFormDTO;

      const service = await handleCreateService(value);

      if (service) {
        handleSelectService(service?.uuid, service);
      }
    } catch (error: any) {
      alert(
        'error',
        t('Service'),
        `${t('An error occurred during create service')} : ${error?.message}`,
      );
      setLoading(false);
    }
  }, [
    alert,
    firstGroupPaymentUuid,
    handleCreateService,
    handleSelectService,
    keywords,
    profile?.defaultEmployeeModel?.uuid,
    t,
  ]);

  return (
    <StyledContainer>
      {keywords ? (
        <Empty description={t('No service was found for your request')}>
          <AddButton
            loading={loading}
            tooltipTitle={t('Add as a new service')}
            title={t('Add as a new service')}
            onClick={quickCreateService}
            type="primary"
          />
        </Empty>
      ) : size === 0 ? (
        <Empty
          description={t(
            'It appears that no Service is registered for the current Company',
          )}>
          <AddButton
            loading={loading}
            tooltipTitle={t('Create service')}
            title={t('Create service')}
            onClick={handleOpenServiceForm}
            type="primary"
          />
        </Empty>
      ) : null}
    </StyledContainer>
  );
}
