import React, {
  JSX,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Badge, Empty, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  ProductModel,
  ProductStatus,
  StoreDocumentItemFormDTO,
  StoreDocumentItemSaleType,
  StoreDocumentMapper,
} from '@structure';
import styled from 'styled-components';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useTranslation } from 'react-i18next';
import {
  useKeyboardActions,
  useStateProductList,
  useStoredCompanies,
} from '@hooks';
import { debounce, isValidEAN13 } from '@services/helpers';
import { ProductTableView } from '../../Show';
import { LoadingMore } from '@components/lib/DataDisplay';
import { useStoreSale } from '../Managers';
import { useStateReducer } from '@components/lib/libV2/hooks';
import { List } from 'immutable';
import { ENTER_KEYS } from '@services/const';
import { useDropdownAlert, useKeyboard } from '@contex';
import { produce } from 'immer';
import useOutsideClickElements from '../../../../hooks/useOutsideClickElements';

import '../../Forms/StoreProductListField.less';
import '../../../Orders/List/OrderList.less';
import '../../../Products/Pages/ProductList.less';

export interface IStoreSaleProductListSelectProps {
  disabled: boolean;
  handleSearchSelectDaleyFocus: (ms?: number) => void;
  storeConductFormHandleOnInit: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export interface StoreSaleProductListSelectState {
  selectedProductItem: StoreDocumentItemFormDTO | null;
  selectedProductIndex: number;
  storeProductList: ProductModel[];
}

const StyledTable = styled(Table)`
  margin: 0;
` as any;

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;

  & input {
    font-size: 18px;
  }

  & .ant-select-selection-placeholder {
    white-space: unset !important;
    font-size: 18px;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
`;

const StyledBadge = styled(Badge)`
  width: 100%;
`;

const IGNORED_BLUR_CLASS = [
  'ant-select',
  'ant-select-dropdown',
  'keyboardContainer',
];

export function StoreSaleProductListSelect({
  disabled,
  handleSearchSelectDaleyFocus,
  storeConductFormHandleOnInit,

  children,
}: IStoreSaleProductListSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { alert } = useDropdownAlert();

  const isScannerStartScan = useRef(false);
  const isStartSearchProductList = useRef(true);
  const scannerTimer = useRef<any>(null);

  const {
    document: storeDocument,
    storeDocumentItemList,
    loadingSubmit,
    loadingProductUuid,
    disabledProductUuid,
    concurrentQueue,
    defaultPriceMargin,
    keywords,

    getProductPriceMargin,
    handleAddOrUpdateDocumentItems,
    handleChangeKeywords,
  } = useStoreSale();

  const {
    handleClearKeyboards,
    isFocus,
    inputRef: selectRef,
    keyboardShow,
  } = useKeyboard();

  const [open, setOpen] = useState<boolean | undefined>(
    keyboardShow ? false : undefined,
  );

  useOutsideClickElements(() => {
    if (keyboardShow) {
      if (isFocus) {
        setOpen(undefined);
      } else {
        setOpen(false);
      }

      handleClearKeyboards();
      handleChangeKeywords('');
    }
  }, IGNORED_BLUR_CLASS);

  const {
    productList,
    loading: productListLoading,
    // keywords: initProductListKeywords,
    isLoadingMore: productIsLoadingMore,
    loadingMore: productLoadingMore,

    handleSearchProductList,
    handleLoadingMoreProductList,
    handleSetProductList,
  } = useStateProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
  });

  const {
    storeProductList,
    selectedProductItem,
    selectedProductIndex,

    handleUpdate: updateSaleContentState,
  } = useStateReducer<StoreSaleProductListSelectState>({
    selectedProductItem: null,
    selectedProductIndex: 0,
    storeProductList: [],
  });

  const isDisabledResetSelectedItem = useRef(productLoadingMore);

  const handleAddOrUpdateDocItems = useCallback(
    async (
      item:
        | ProductModel
        | StoreDocumentItemFormDTO
        | null = selectedProductItem,
    ) => {
      const { waiting, inProcess } = concurrentQueue.current;

      if (waiting.length || inProcess) {
        concurrentQueue.current.add({
          keywords: keywords || '',
          store_uuid: storeDocument?.doc_store_uuid,
        });

        return;
      }

      let docItem: any;

      if (item instanceof ProductModel) {
        docItem = StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
          editMode: false,
          price: getProductPriceMargin(item, defaultPriceMargin),
          item_sale_type: StoreDocumentItemSaleType.LOADING,
        });
      } else {
        docItem = produce(item, (draft) => {
          (draft as any).item_sale_type = StoreDocumentItemSaleType.LOADING;
        });
      }

      if (docItem?.product?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      await handleAddOrUpdateDocumentItems(docItem);
    },
    [
      alert,
      concurrentQueue,
      defaultPriceMargin,
      getProductPriceMargin,
      handleAddOrUpdateDocumentItems,
      keywords,
      selectedProductItem,
      storeDocument?.doc_store_uuid,
      t,
    ],
  );

  const handleSelectProductItem = useCallback(
    (product: ProductModel) => {
      const selectedProductItem =
        StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          price: getProductPriceMargin(product, defaultPriceMargin),
        });

      updateSaleContentState({ selectedProductItem });
    },
    [defaultPriceMargin, getProductPriceMargin, updateSaleContentState],
  );

  useEffect(() => {
    let storeProductList: ProductModel[] = [];

    if (List.isList(productList) && keywords?.length >= 3) {
      if (storeDocument?.doc_store_uuid) {
        productList?.forEach((product, index) => {
          if (index === 0 && !isDisabledResetSelectedItem?.current) {
            handleSelectProductItem(product);
          }

          if (Array.isArray(product?.product_balances)) {
            product?.product_balances?.forEach((store) => {
              const updatedProduct = product.update('product_balances', () => [
                store,
              ]);
              storeProductList.push(updatedProduct);
            });
          } else {
            storeProductList.push(product);
          }
        });

        updateSaleContentState({ storeProductList });
        isDisabledResetSelectedItem.current = false;
      }
    }
  }, [
    handleSelectProductItem,
    updateSaleContentState,
    productList,
    keywords?.length,
    storeDocument?.doc_store_uuid,
  ]);

  const scrollToProductItem = useCallback(
    (index: number) => {
      const selected = document.body.querySelector(`.posting-list-td-${index}`);

      if (selected instanceof HTMLElement) {
        selected.scrollIntoView({
          behavior: keyboardShow ? 'auto' : 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    },
    [keyboardShow],
  );

  const resetSelectedProductState = useCallback(() => {
    updateSaleContentState({
      selectedProductIndex: 0,
      selectedProductItem: null,
      storeProductList: [],
    });

    handleSetProductList(List());
  }, [handleSetProductList, updateSaleContentState]);

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        if (!isScannerStartScan.current && isStartSearchProductList.current) {
          await handleSearchProductList({
            ...value,
            companyUuid: defaultCompanyUuid,
          });
        }
      }, 1000),
    [handleSearchProductList, defaultCompanyUuid],
  );

  const onScanBarcode = useCallback(
    (e: any) => {
      if (isValidEAN13(e?.target?.value) && !ENTER_KEYS.includes(e.key)) {
        if (scannerTimer.current) clearTimeout(scannerTimer.current);

        scannerTimer.current = setTimeout(async () => {
          isScannerStartScan.current = false;
        }, 300);

        isScannerStartScan.current = true;
        resetSelectedProductState();
      }

      if (ENTER_KEYS.includes(e.key) && isScannerStartScan.current) {
        if (scannerTimer.current) clearTimeout(scannerTimer.current);

        isStartSearchProductList.current = false;
        handleChangeKeywords('');
        selectRef?.current?.blur();
        selectRef?.current?.focus();

        const select = document.body.querySelector(
          '.store-sell-product-select-popup',
        );

        if (
          select instanceof HTMLElement &&
          !select.className?.includes('hidden')
        ) {
          select.classList?.add('posting-select-popup--hidden');
        }

        setTimeout(() => {
          isScannerStartScan.current = false;
        }, 0);

        concurrentQueue.current.add({
          keywords: e?.target?.value || '',
          store_uuid: storeDocument?.doc_store_uuid,
        });
      }
    },
    [
      concurrentQueue,
      handleChangeKeywords,
      resetSelectedProductState,
      selectRef,
      storeDocument?.doc_store_uuid,
    ],
  );

  const onSearch = useCallback(
    async (keywords: string, withoutClearInput: boolean = false) => {
      if (!withoutClearInput) {
        handleChangeKeywords(keywords);
      }

      isStartSearchProductList.current = true;

      if (keywords?.length < 3) {
        if (keyboardShow) {
          setOpen(false);
        }

        resetSelectedProductState();
        return;
      }

      if (keyboardShow) {
        setOpen(true);
      }

      await debounceHandleSearchProducts({
        keywords,
        store_uuid: storeDocument?.doc_store_uuid,
      });
    },
    [
      debounceHandleSearchProducts,
      handleChangeKeywords,
      keyboardShow,
      resetSelectedProductState,
      storeDocument?.doc_store_uuid,
    ],
  );

  const onTableKeyboardChange = useCallback(
    async (e: any) => {
      const length = storeProductList?.length - 1;

      onScanBarcode(e);

      if (
        keywords?.length >= 3 &&
        storeProductList?.length &&
        !productListLoading
      ) {
        if (e.key === 'ArrowUp' && selectedProductIndex === 0) {
          const product = storeProductList[length];
          if (product) {
            handleSelectProductItem(product);
            updateSaleContentState({ selectedProductIndex: length });
            scrollToProductItem(length);
          }

          return;
        }

        if (e.key === 'ArrowUp' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex - 1];

          if (product) {
            handleSelectProductItem(product);
            updateSaleContentState((prevState) => ({
              selectedProductIndex: prevState.selectedProductIndex - 1,
            }));
            scrollToProductItem(selectedProductIndex - 1);
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex === length) {
          const product = storeProductList[0];
          if (product) {
            handleSelectProductItem(product);
            updateSaleContentState({ selectedProductIndex: 0 });
            scrollToProductItem(0);
          }

          return;
        }

        if (e.key === 'ArrowDown' && selectedProductIndex <= length) {
          const product = storeProductList[selectedProductIndex + 1];
          if (product) {
            handleSelectProductItem(product);
            updateSaleContentState((prevState) => ({
              selectedProductIndex: prevState.selectedProductIndex + 1,
            }));
            scrollToProductItem(selectedProductIndex + 1);
          }

          return;
        }

        if (ENTER_KEYS.includes(e.key)) {
          if (!loadingProductUuid) {
            handleAddOrUpdateDocItems();

            if (selectRef?.current) {
              selectRef?.current?.blur();
              handleSearchSelectDaleyFocus();
              if (keyboardShow) {
                setOpen(false);
                handleClearKeyboards();
                handleChangeKeywords('');
              }
            }
          }
        }
      } else {
        if (
          ENTER_KEYS.includes(e.key) &&
          e?.target instanceof HTMLElement &&
          e?.target?.closest('.store-sell-product-select') &&
          storeDocumentItemList.length &&
          !disabledProductUuid &&
          !loadingProductUuid &&
          !isScannerStartScan.current &&
          !e?.target?.value
        ) {
          e?.preventDefault();
          e?.stopPropagation();
          selectRef?.current?.blur();

          if (keyboardShow) {
            setOpen(false);
            handleClearKeyboards();
            handleChangeKeywords('');
          }

          storeConductFormHandleOnInit();
        }
      }
    },
    [
      storeProductList,
      onScanBarcode,
      keywords?.length,
      productListLoading,
      selectedProductIndex,
      handleSelectProductItem,
      updateSaleContentState,
      scrollToProductItem,
      loadingProductUuid,
      handleAddOrUpdateDocItems,
      selectRef,
      handleSearchSelectDaleyFocus,
      keyboardShow,
      handleClearKeyboards,
      handleChangeKeywords,
      storeDocumentItemList.length,
      disabledProductUuid,
      storeConductFormHandleOnInit,
    ],
  );

  useEffect(() => {
    if (productLoadingMore) {
      isDisabledResetSelectedItem.current = true;
    }
  }, [productLoadingMore]);

  const columns = useMemo(
    () => [
      {
        title: t('name-s'),
        key: 'name',
        className: 'order-list-td',
        render: (product: ProductModel) => (
          <ProductTableView
            product={product}
            price={getProductPriceMargin(product, defaultPriceMargin)}
            loadingProductUuid={loadingProductUuid}
          />
        ),
      },
    ],
    [defaultPriceMargin, getProductPriceMargin, loadingProductUuid, t],
  );

  useKeyboardActions({
    eventListenerCallBack: onSearch,
    keyboardShow,
  });

  useEffect(() => {
    if (!keyboardShow) {
      setOpen(undefined);
    }

    if (typeof open !== 'boolean' && keyboardShow) {
      setOpen(false);
    }
  }, [keyboardShow, open]);

  return (
    <StyledSelectContainer>
      <StyledBadge
        count={concurrentQueue.current.waiting.length}
        title={t('Number of pending requests')}>
        <StyledSelect
          open={open}
          popupClassName={`store-sell-product-select-popup posting-select-popup ${
            (keywords?.length || 0) < 3 ? 'posting-select-popup--hidden' : ''
          }`}
          className="store-sell-product-select"
          loading={productListLoading}
          disabled={disabled || loadingSubmit}
          showSearch
          onKeyUp={onTableKeyboardChange}
          searchValue={keywords}
          onFocus={() => {
            if (keyboardShow) {
              setOpen(false);
            }
          }}
          onBlur={(e) => {
            if (typeof open === 'boolean' && keyboardShow) {
              e.preventDefault();
              e.stopPropagation();
              e?.target?.focus();
              return;
            }
          }}
          value={keywords || undefined}
          dropdownRender={() => (
            <div
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {productListLoading ? (
                <Empty>
                  <LoadingOutlined />
                </Empty>
              ) : productListLoading || !keywords || keywords?.length < 3 ? (
                <Empty
                  description={t(
                    'To select a product, enter the search data, at least 3 characters',
                  )}
                />
              ) : keywords && !storeProductList?.length ? (
                <Empty
                  description={t(
                    'It seems that more than one product was not found for your request',
                  )}
                />
              ) : (
                <>
                  <StyledTable
                    className="posting-list-form"
                    pagination={false}
                    pageSize={Infinity}
                    total={storeProductList?.length}
                    dataSource={storeProductList}
                    columns={columns}
                    onRow={(record: ProductModel, rowIndex: number) => {
                      return {
                        onClick: () => {
                          if (!loadingProductUuid) {
                            handleAddOrUpdateDocItems(record);
                          }

                          if (keyboardShow) {
                            setOpen(false);
                            handleClearKeyboards();
                            handleChangeKeywords('');
                          }

                          if (selectRef?.current) {
                            selectRef?.current?.blur();
                          }
                        },
                        className: `posting-list-td posting-list-td-${rowIndex} ${
                          record?.uuid === selectedProductItem?.product_uuid &&
                          selectedProductIndex === rowIndex
                            ? 'posting-list-td--selected'
                            : ''
                        } ${
                          record?.product_status !== ProductStatus.ENABLE
                            ? 'product-list--disabled'
                            : ''
                        }`,
                      };
                    }}
                  />
                  <LoadingMore
                    loading={productIsLoadingMore}
                    observerCallback={handleLoadingMoreProductList}
                  />
                </>
              )}
            </div>
          )}
          onSearch={async (vale) => {
            await onSearch(vale, keyboardShow);
          }}
          placeholder={t('Enter product details to search for it')}
        />
      </StyledBadge>
      {children}
    </StyledSelectContainer>
  );
}
