import React, {
  forwardRef,
  ChangeEvent,
  useRef,
  useMemo,
  useState,
} from 'react';
import { Input } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';
import { debounce } from '@services/helpers';
import styled, { css } from 'styled-components';

export interface ISearchInputProps extends InputProps {
  placeholder?: string;
  dataTestid?: string;
  loading?: boolean;
  handleSearch?: (value: any) => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  searchTextRef?: React.MutableRefObject<string>;
}

const StyledInput = styled(Input)<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export default forwardRef(
  (
    {
      placeholder = '',
      dataTestid = '',
      size = 'large',
      handleSearch: onSearch = () => {},
      handleKeyDown = () => {},
      loading,
      disabled,
      fullWidth,
      searchTextRef,
      defaultValue,
      ...rest
    }: ISearchInputProps,
    ref: any,
  ) => {
    const emptyRef = useRef<any>(null);
    const [value, setValue] = useState(defaultValue);

    const handleChange = useMemo(
      () =>
        debounce(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          onSearch(value);
        }, 1000),
      [onSearch],
    );

    const { reportNS, tReady, defaultNS, i18nOptions, ...restProps }: any =
      rest;

    return (
      <StyledInput
        ref={ref || emptyRef}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        data-testid={dataTestid}
        placeholder={placeholder}
        prefix={<SearchOutlined />}
        suffix={loading && <LoadingOutlined spin />}
        size={size}
        onChange={async (e) => {
          setValue(e?.target?.value || '');
          if (typeof searchTextRef?.current === 'string') {
            searchTextRef.current = e?.target?.value;
          }
          handleChange(e);
        }}
        $fullWidth={fullWidth}
        value={value}
        {...restProps}
      />
    );
  },
);
