import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceMarginForm, IPriceMarginFormProps } from '../Forms';
import { SideWindow } from '@components/lib/Navigation';
import { PriceMarginFormDTO } from '@structure';

export interface IPriceMarginSideWindowProps extends IPriceMarginFormProps {
  onSuccess: (value: PriceMarginFormDTO) => Promise<void>;
  visible: boolean;
}

export default function PriceMarginSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  ...rest
}: IPriceMarginSideWindowProps) {
  const { t } = useTranslation();

  return (
    <SideWindow title={t('Price margins')} visible={visible} onClose={onCancel}>
      <PriceMarginForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </SideWindow>
  );
}
