import { List } from 'immutable';
import {
  UserOperationDTO,
  IUserOperationDTOProps,
  UserOperationModel,
  UserOperationListModel,
} from '../internal';
import { listToArray } from '@services/helpers';

export interface IUserOperationListDTO {
  operations: UserOperationDTO[];
  total: number;
}

export class UserOperationMapper {
  public static toUserOperationDTO(
    props: UserOperationModel | IUserOperationDTOProps,
  ): UserOperationDTO {
    return new UserOperationDTO({
      uuid: props?.uuid,
      operation_type: props?.operation_type,
      operation_price: props?.operation_price,
      operation_comment: props?.operation_comment,
      created_at: props?.created_at,
      updated_at: props?.updated_at,
    });
  }

  public static toUserOperationListDTO(
    userOperations: List<UserOperationModel> | IUserOperationDTOProps[],
    total: number,
  ): IUserOperationListDTO {
    const userOperationList = List.isList(userOperations)
      ? listToArray<UserOperationModel>(userOperations)
      : userOperations;

    return {
      operations: userOperationList?.map((userOperation) =>
        UserOperationMapper.toUserOperationDTO(userOperation),
      ),
      total,
    };
  }

  public static toUserOperationModel(
    userOperationDTO: UserOperationDTO,
  ): UserOperationModel {
    return new UserOperationModel(userOperationDTO);
  }

  public static toUserOperationListModel(
    userAccountOperationDTOs: UserOperationDTO[],
    total: number,
  ): UserOperationListModel {
    return new UserOperationListModel({
      operations: userAccountOperationDTOs,
      total,
    });
  }
}
