import React from 'react';
import { useTranslation } from 'react-i18next';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import { StoreDocumentExtraServiceDTO, StoreDocumentModel } from '@structure';
import { correctPrice, textToUpperCase } from '@services/helpers';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { DetailsView, TableIndexField } from '@components/lib/DataDisplay';
import { v4 as uuidv4 } from 'uuid';

import '../../Orders/List/OrderList.less';

export interface IStoreDocExtraServicesViewProps {
  document: StoreDocumentModel;
}

const StyledTableContainer = styled.div`
  background-color: ${({ theme }) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledTable = styled(Table)`
  margin: 0;
  width: 100%;
` as React.ComponentType as React.FC<ITableProps<StoreDocumentExtraServiceDTO>>;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 23px;
`;

export function StoreDocExtraServicesView({
  document,
}: IStoreDocExtraServicesViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const { defaultCompany } = useStoredCompanies();

  const services = Array.isArray(document?.doc_extra_services)
    ? document?.doc_extra_services
    : [];

  const total = Array.isArray(document?.doc_extra_services)
    ? document?.doc_extra_services?.reduce((acc, curr) => {
        acc += Number(curr?.price || 0);

        return acc;
      }, 0)
    : 0;

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (
        extraService: StoreDocumentExtraServiceDTO,
        items: any,
        index: number,
      ) => <TableIndexField number={index + 1} />,
    },
    {
      title: t('Title'),
      key: 'title',
      render: (extraService: StoreDocumentExtraServiceDTO) =>
        extraService?.title,
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      align: 'right' as any,
      render: (extraService: StoreDocumentExtraServiceDTO) =>
        correctPrice(extraService?.price),
    },
  ];

  return (
    <>
      {services?.length ? (
        <>
          <StyledTitleContainer>
            <StyledTitle bold>
              {textToUpperCase(t('Additional Services'))}
            </StyledTitle>
          </StyledTitleContainer>

          <StyledTableContainer>
            <StyledTable
              bordered
              dataSource={services}
              rowKey={() => uuidv4()}
              columns={columns}
              total={services?.length}
              pageSize={Infinity}
            />
            {services?.length ? (
              <StyledDetailsView titles={[]}>
                <StyledStatsContainer>
                  <StyledTitle fontSize={15} bold>{`${t('Total services')}, ${
                    defaultCompany?.currency_symbol || ''
                  }: ${correctPrice(total || 0)}`}</StyledTitle>
                </StyledStatsContainer>
              </StyledDetailsView>
            ) : null}
          </StyledTableContainer>
        </>
      ) : null}
    </>
  );
}
