import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeSalaryFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { toDateByFormat } from '@services/helpers';

const RewardSalarySideWindowLazy = React.lazy(
  () => import('../Show/RewardSalarySideWindow'),
);

export interface IRewardSalaryUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: (value: EmployeeSalaryFormDTO) => Promise<void>;
  onCancel?: () => void;
  salary: EmployeeSalaryFormDTO;
  salarySettingsDates: string[];
}

export function RewardSalaryUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  salary,
  salarySettingsDates,
  ...rest
}: IRewardSalaryUpdateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardSalarySideWindowLazy
          editMode
          salary={salary}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          salarySettingsDates={(salarySettingsDates || []).filter(
            (start_date) =>
              start_date !== toDateByFormat(salary?.start_date, 'MM.YYYY'),
          )}
        />
      </Suspense>
    </>
  );
}
