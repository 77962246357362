import * as React from 'react';
import { List } from 'immutable';
import {
  useAbonementGroupList,
  IUseAbonementGroupListProps,
  IUseAbonementGroupListReturnType,
} from './useAbonementGroupList';
import {
  AbonementGroupModel,
  AbonementGroupFormDTO,
  AbonementGroupMapper,
} from '@structure';
import {
  createAbonementGroup,
  editAbonementGroup,
  deleteAbonementGroup,
} from '@services/api/abonementGroup';
import {
  setAbonementGroupList as storeSetAbonementGroupList,
  deleteAbonementGroupFromList as storeDeleteAbonementGroup,
  loadMoreAbonementGroupList as storeLoadMoreAbonementGroupList,
  addAbonementGroupToList as storeAddAbonementGroupToList,
  updateAbonementGroupFromList as storeUpdateAbonementGroupToList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateAbonementGroupListProps
  extends IUseAbonementGroupListProps {}

export interface IAbonementGroupSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  abonementUUid?: string;
}

export interface IUseStateAbonementGroupListReturnType
  extends Omit<IUseAbonementGroupListReturnType, 'entityList'> {
  groupList: List<AbonementGroupModel> | null;
  handleDeleteAbonementGroups: (ids: string[]) => Promise<void>;
  handleSearchAbonementGroups: (
    value: IAbonementGroupSearchProps,
  ) => Promise<void>;
  handleLoadMoreAbonementGroups: () => Promise<void>;
  handleCreateAbonementGroup: (value: AbonementGroupFormDTO) => Promise<void>;
  handleEditAbonementGroup: (value: AbonementGroupFormDTO) => Promise<void>;
  handleRefreshAbonementGroups: (
    value: Partial<IUseAbonementGroupListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredAbonementGroupList(
  {
    abonementUuid: abonUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateAbonementGroupListProps = {} as IUseStateAbonementGroupListProps,
): IUseStateAbonementGroupListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [groupList, setVisitList] = useState<List<AbonementGroupModel> | null>(
    null,
  );
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [abonementUuid, setAbonementUuid] = useState(abonUuid);

  const {
    status: storedAbonementGroupListStatus,
    loading: storedAbonementGroupListLoading,
    cachedAbonementGroupList,
    ...storedAbonementGroupListParams
  } = useSelector(({ abonementGroupList }: RootState) => abonementGroupList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: groupListLoading,
    total,
    ...groupsParams
  } = useAbonementGroupList({
    abonementUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedAbonementGroupList?.size > 0) {
      const groupList = cachedAbonementGroupList.get(abonementUuid);

      if (groupList) {
        setIsStartLoading(false);
        setVisitList(groupList?.groups);
        setStorePage(groupList?.page);
        setStoreKeywords(groupList?.keywords);
        setStoreTotal(groupList?.total || 0);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedAbonementGroupList?.size === 0 && !groupList) {
      setIsStartLoading(true);
    }
  }, [
    dispatch,
    cachedAbonementGroupList,
    abonementUuid,
    storeTotal,
    groupList,
  ]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({ abonementUuid });

        if (entityList) {
          dispatch(storeSetAbonementGroupList(entityList, abonementUuid, ''));
        }
      })();
    }
  }, [abonementUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteAbonementGroups = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteAbonementGroup(ids);

        setTimeout(() => {
          dispatch(storeDeleteAbonementGroup(head(ids), abonementUuid));
        }, 100);
        alert(
          'success',
          t('Tariff model group'),
          t('Tariff model group delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Tariff model group'),
          `${t('An error occurred during delete tariff model groups')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, abonementUuid],
  );

  const handleSearchAbonementGroups = React.useCallback(
    async ({
      limit = 10,
      keywords,
      abonementUUid: abonUuid = abonementUuid,
      showLoading,
    }: IAbonementGroupSearchProps) => {
      setLoadingMore(true);
      const groupListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        abonementUuid: abonUuid,
        showLoading,
      });

      if (groupListModel && List.isList(groupListModel?.groups)) {
        setAbonementUuid(abonUuid);
        dispatch(
          storeSetAbonementGroupList(
            groupListModel,
            abonUuid,
            keywords,
            groupListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [abonementUuid, dispatch, refresh],
  );

  const handleLoadMoreAbonementGroups = useCallback(async () => {
    if (
      List.isList(groupList) &&
      groupList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const groupListModel = await refresh({
        offset: groupList?.size,
        limit: 10,
        showLoading: false,
      });

      if (groupListModel && List.isList(groupListModel?.groups)) {
        dispatch(
          storeLoadMoreAbonementGroupList(groupListModel, abonementUuid),
        );
      }

      setLoadingMore(false);
    }
  }, [groupList, storeTotal, loadingMore, refresh, dispatch, abonementUuid]);

  const handleRefreshAbonementGroups = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      abonementUuid: abonUuid = abonementUuid,
    }: Partial<IUseAbonementGroupListProps> & { page: number }) => {
      setLoadingMore(true);
      const groupListModel = await refresh({
        offset,
        limit,
        showLoading,
        abonementUuid: abonUuid,
      });

      if (groupListModel && List.isList(groupListModel?.groups)) {
        setAbonementUuid(abonUuid);

        dispatch(
          storeSetAbonementGroupList(
            groupListModel,
            abonUuid,
            storeKeywords,
            groupListModel?.total,
            page,
          ),
        );

        setLoadingMore(false);
      }
    },
    [abonementUuid, dispatch, refresh, storeKeywords],
  );

  const handleCreateAbonementGroup = React.useCallback(
    async (value: AbonementGroupFormDTO) => {
      const groupDTO = await createAbonementGroup(value, abonementUuid);

      const groupModel = AbonementGroupMapper.toAbonementGroupModel(groupDTO);

      dispatch(storeAddAbonementGroupToList(groupModel, abonementUuid));
    },
    [abonementUuid, dispatch],
  );

  const handleEditAbonementGroup = React.useCallback(
    async (value: AbonementGroupFormDTO) => {
      const groupDTO = await editAbonementGroup(value);

      const groupModel = AbonementGroupMapper.toAbonementGroupModel(groupDTO);

      dispatch(storeUpdateAbonementGroupToList(groupModel, abonementUuid));
    },
    [abonementUuid, dispatch],
  );

  return {
    ...groupsParams,
    ...storedAbonementGroupListParams,
    groupList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(groupList) && groupListLoading) ||
      !storedAbonementGroupListLoading,
    handleDeleteAbonementGroups,
    handleSearchAbonementGroups,
    handleLoadMoreAbonementGroups,
    status: storedAbonementGroupListStatus,
    handleRefreshAbonementGroups,
    keywords: storeKeywords || '',
    page: storePage || 1,
    handleCreateAbonementGroup,
    handleEditAbonementGroup,
  };
}
