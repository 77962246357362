import { Dispatch } from 'redux';
import { PRODUCT_CATEGORY_PRODUCT_LIST } from '../constants';
import {
  ProductCategoryModel,
  ProductListModel,
  ProductModel,
} from '@structure';
import { ApiError } from '@services/types';

export interface ISetProductCategoryProductListParam {
  type: PRODUCT_CATEGORY_PRODUCT_LIST;
  productList: ProductListModel;
  categoryUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddProductCategoryProductListToListParam {
  type: PRODUCT_CATEGORY_PRODUCT_LIST;
  product: ProductModel;
}

export interface IUpdateProductCategoryProductListFromListParam {
  type: PRODUCT_CATEGORY_PRODUCT_LIST;
  product: ProductModel;
}

export interface IDeleteProductCategoryProductListFromListParam {
  type: PRODUCT_CATEGORY_PRODUCT_LIST;
  productUuid: string;
}

export interface IErrorProductCategoryProductListParam {
  type: PRODUCT_CATEGORY_PRODUCT_LIST;
  error: ApiError | undefined;
}

export function setProductCategoryProductList(
  productList: ProductListModel,
  categoryUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
  category?: ProductCategoryModel,
): (dispatch: Dispatch) => Promise<ISetProductCategoryProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.SET_PRODUCT_CATEGORY_PRODUCT_LIST,
      productList,
      keywords,
      total,
      page,
      categoryUuid,
      category,
    });
  };
}

export function loadMoreProductCategoryProductList(
  productList: ProductListModel,
  categoryUuid: string,
): (dispatch: Dispatch) => Promise<ISetProductCategoryProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.LOADING_PRODUCT_CATEGORY_PRODUCT_LIST,
      productList,
      categoryUuid,
    });
  };
}

export function addProductCategoryProductToList(
  product: ProductModel,
  categoryUuid: string,
): (dispatch: Dispatch) => Promise<IAddProductCategoryProductListToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.ADD_PRODUCT_CATEGORY_PRODUCT,
      product,
      categoryUuid,
    });
  };
}

export function updateProductCategoryProductFromList(
  product: ProductModel,
  categoryUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IUpdateProductCategoryProductListFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.UPDATE_PRODUCT_CATEGORY_PRODUCT,
      product,
      categoryUuid,
    });
  };
}

export function deleteProductCategoryProductFromList(
  productUuid: string,
  categoryUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IDeleteProductCategoryProductListFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.DELETE_PRODUCT_CATEGORY_PRODUCT,
      productUuid,
      categoryUuid,
    });
  };
}

export function errorProductCategoryProductListInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductCategoryProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_PRODUCT_LIST.ERROR_PRODUCT_CATEGORY_PRODUCT_LIST,
      error,
    });
  };
}
