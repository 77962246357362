import { Record } from 'immutable';
import {
  IntegrationCategoryDTO,
  IntegrationCategoryModel,
  IntegrationDTO,
  IntegrationMapper,
  IntegrationService,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IIntegrationModelReturnType {
  uuid: string;
  title: string;
  icon_url: string;
  help_html: string;
  service: IntegrationService;
  description: string;
  created_at: string;
  updated_at: string;
  category: IntegrationCategoryModel | IntegrationCategoryDTO;
}

export class IntegrationModel extends Record<IIntegrationModelReturnType>({
  uuid: '',
  title: '',
  icon_url: '',
  help_html: '',
  service: IntegrationService.CHECKBOX,
  description: '',
  created_at: '',
  updated_at: '',
  category: {} as IntegrationCategoryModel,
}) {
  constructor(
    props: IntegrationDTO = {} as IntegrationDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IIntegrationModelReturnType = {
      ...props,

      category:
        withoutRecursion.indexOf(WithoutRecursion.integrationCategory) !== -1
          ? props?.category
          : IntegrationMapper.toIntegrationCategoryModel(props?.category, [
              WithoutRecursion.integrationCategory,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get categoryModel(): IntegrationCategoryModel {
    return this.category instanceof IntegrationCategoryModel
      ? this.category
      : IntegrationMapper.toIntegrationCategoryModel(this.category);
  }
}
