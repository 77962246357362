import { Dispatch } from 'redux';
import { REPORT } from '../constants';
import { ReportModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetReportParam {
  type: REPORT;
  report: ReportModel | null;
}

export interface IResetReportParam {
  type: REPORT;
}

export interface IErrorReportParam {
  type: REPORT;
  error: ApiError | undefined;
}

export function setReport(
  report: ReportModel | null,
): (dispatch: Dispatch) => Promise<ISetReportParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT.SET_REPORT,
      report,
    });
  };
}

export function resetReport(): (
  dispatch: Dispatch,
) => Promise<IResetReportParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT.RESET_REPORT,
    });
  };
}

export function errorReport(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorReportParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT.ERROR_REPORT,
      error,
    });
  };
}
