import React from 'react';
import { CategoryFormDTO } from '@structure';
import { SideWindow } from '@components/lib/Navigation';
import { CategoryForm, ICategoryFormProps } from '../Forms';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ICON_NAME, IconTittle } from '@components/lib/DataDisplay';

export default withTranslation()(CategorySideWindow);

export interface ICategorySideWindowProps
  extends WithTranslation,
    ICategoryFormProps {
  onSuccess: (value: CategoryFormDTO) => Promise<void>;
  visible: boolean;
}

function CategorySideWindow({
  t,
  onSuccess,
  visible,
  editMode,
  onCancel,
  ...rest
}: ICategorySideWindowProps): JSX.Element {
  return (
    <SideWindow
      title={
        <IconTittle
          title={editMode ? t('Edit Category') : t('Add Category')}
          iconName={ICON_NAME.CATEGORY}
        />
      }
      visible={visible}
      onClose={onCancel}
      data-testid="company-edit-form-side-window">
      <CategoryForm
        onSuccess={onSuccess}
        onCancel={onCancel}
        editMode
        {...rest}
      />
    </SideWindow>
  );
}
