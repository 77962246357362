import React from 'react';
import { Routes, Route } from 'react-router';
import { DepartmentListPage, DepartmentPage } from '../views/Departments';
import { AppRouteLayout } from '@components/lib/Layout';

export function DepartmentRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <DepartmentListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":departmentId"
        element={
          <AppRouteLayout>
            <DepartmentPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
