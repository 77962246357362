import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IServiceListDTO,
  ServiceMapper,
  ServiceModel,
  ServiceListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getEmployeeAttachedServiceList } from '@services/api/employee';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseEmployeeAttachedServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  employeeUuid: string;
}

export interface IUseEmployeeAttachedServiceListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ServiceModel> | null;
  refresh: (
    value: Partial<IUseEmployeeAttachedServiceListProps>,
  ) => Promise<ServiceListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  employeeUuid: string;
}

export function useEmployeeAttachedServiceList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    employeeUuid,
  }: IUseEmployeeAttachedServiceListProps = {} as IUseEmployeeAttachedServiceListProps,
): IUseEmployeeAttachedServiceListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    employeeUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseEmployeeAttachedServiceListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    employeeUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      employeeUuid = listCompanyUuid,
    }: Partial<IUseEmployeeAttachedServiceListProps> = {}): Promise<ServiceListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          employeeUuid,
        });

        const { services, total } = await cancellablePromise<IServiceListDTO>(
          getEmployeeAttachedServiceList({
            employeeUuid,
            limit,
            offset,
            keywords,
          }),
        );

        const employeeAttachedServiceListModel =
          ServiceMapper.toServiceListModel(services, total);

        handleUpdate({
          entityList: employeeAttachedServiceListModel?.services,
          total: employeeAttachedServiceListModel?.total,
          loading: false,
        });

        return employeeAttachedServiceListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Employee services'),
          `${t('An error occurred during get employee service list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && employeeUuid) {
      (async () => {
        await refresh({ employeeUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, employeeUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    employeeUuid: listCompanyUuid,
  };
}
