import React from 'react';
import { AppErrorBoundary } from '../Errors';
import AppLayout from './AppLayout';
import { useStoredCompanies, useStoredProfile } from '@hooks';
import { AclManager } from '@contex';
import { authLogoUrl } from '@services/const';

export interface IRouteMainLayoutProps {
  showSpinner?: boolean;
  children: any;
}

export default function AppRouteLayout({
  showSpinner,
  children,
}: IRouteMainLayoutProps) {
  const { defaultCompanyUuid, defaultCompany, defaultCompanyLoading } =
    useStoredCompanies();

  const { profile, loading } = useStoredProfile();

  return (
    <AclManager>
      <AppErrorBoundary>
        <AppLayout
          headerTitle={defaultCompany?.logo_url || authLogoUrl}
          companyTitle={defaultCompany?.title || ''}
          profile={profile}
          loading={showSpinner || defaultCompanyLoading || loading}
          defaultCompanyUuid={defaultCompanyUuid}>
          {children}
        </AppLayout>
      </AppErrorBoundary>
    </AclManager>
  );
}
