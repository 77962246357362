import { List, Map } from 'immutable';
import { APP_STATE, PRODUCT_CATEGORY_PRODUCT_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  ProductCategoryModel,
  ProductListModel,
  ProductMapper,
  ProductModel,
} from '@structure';

interface IProductCategoryProductListState {
  productList: List<ProductModel> | null;
  cachedProductList: Map<string, ProductListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList
  extends Pick<IProductCategoryProductListState, 'total'> {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.SET_PRODUCT_CATEGORY_PRODUCT_LIST;
  categoryUuid: string;
  productList: ProductListModel;
  keywords?: string;
  page: number;
  category?: ProductCategoryModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.ADD_PRODUCT_CATEGORY_PRODUCT;
  product: ProductModel;
  categoryUuid: string;
}

interface UpdateProductCategoryProductFromList {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.UPDATE_PRODUCT_CATEGORY_PRODUCT;
  product: ProductModel;
  categoryUuid: string;
}

interface DeleteActionFromList {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.DELETE_PRODUCT_CATEGORY_PRODUCT;
  productUuid: string;
  categoryUuid: string;
}

interface LoadingActionInList {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.LOADING_PRODUCT_CATEGORY_PRODUCT_LIST;
}

interface ErrorActionInList
  extends Pick<IProductCategoryProductListState, 'error'> {
  type: PRODUCT_CATEGORY_PRODUCT_LIST.ERROR_PRODUCT_CATEGORY_PRODUCT_LIST;
}

interface Handlers {
  [PRODUCT_CATEGORY_PRODUCT_LIST.SET_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
    action: SetActionList,
  ) => IProductCategoryProductListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductCategoryProductListState,
    action: SetInitialStateAction,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.LOAD_MORE_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
    action: SetActionList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.ADD_PRODUCT_CATEGORY_PRODUCT]: (
    state: IProductCategoryProductListState,
    action: AddActionToList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.UPDATE_PRODUCT_CATEGORY_PRODUCT]: (
    state: IProductCategoryProductListState,
    action: UpdateProductCategoryProductFromList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.DELETE_PRODUCT_CATEGORY_PRODUCT]: (
    state: IProductCategoryProductListState,
    action: DeleteActionFromList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.ERROR_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
    value: ErrorActionInList,
  ) => IProductCategoryProductListState;

  [PRODUCT_CATEGORY_PRODUCT_LIST.LOADING_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
    value?: LoadingActionInList,
  ) => IProductCategoryProductListState;
  DEFAULT: (
    state: IProductCategoryProductListState,
  ) => IProductCategoryProductListState;
}

const initState: IProductCategoryProductListState = {
  productList: null,
  cachedProductList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [PRODUCT_CATEGORY_PRODUCT_LIST.SET_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state,
    { productList, categoryUuid, keywords, page, category },
  ) => {
    const updatedList = productList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1)
      .update('category', () => category);

    return {
      ...state,
      ...{
        productList: productList?.products || null,
        cachedProductList: productList
          ? state.cachedProductList.set(categoryUuid, updatedList)
          : state.cachedProductList,
        status: REDUX_STATUS.SUCCEEDED,
        total: productList?.total! >= 0 ? productList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_CATEGORY_PRODUCT_LIST.LOAD_MORE_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state,
    { productList, categoryUuid },
  ) => {
    const relaterScheduleListById = state.cachedProductList.get(categoryUuid);

    return {
      ...state,
      ...{
        productList:
          List.isList(state.productList) && List.isList(productList)
            ? state.productList.merge(productList.products)
            : state.productList,
        cachedProductList: productList
          ? state.cachedProductList.set(
              categoryUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('products', (products) =>
                    products.merge(productList.products),
                  )
                : productList,
            )
          : state.cachedProductList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRODUCT_CATEGORY_PRODUCT_LIST.ADD_PRODUCT_CATEGORY_PRODUCT]: (
    state,
    { product, categoryUuid },
  ) => {
    const relaterScheduleListById = state.cachedProductList.get(categoryUuid);

    return {
      ...state,
      ...{
        productList: List.isList(state.productList)
          ? state.productList.unshift(product)
          : List([product]),
        cachedProductList: relaterScheduleListById
          ? state.cachedProductList.update(categoryUuid, (productList) => {
              if (productList) {
                return productList
                  .update('products', (products) => products.unshift(product))
                  .update('total', (total) => (total || 0) + 1);
              } else {
                return ProductMapper.toProductListModel([product] as any, 1);
              }
            })
          : Map({
              [categoryUuid]: ProductMapper.toProductListModel(
                [product] as any,
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [PRODUCT_CATEGORY_PRODUCT_LIST.UPDATE_PRODUCT_CATEGORY_PRODUCT]: (
    state: IProductCategoryProductListState,
    { product, categoryUuid }: UpdateProductCategoryProductFromList,
  ) => {
    const relaterScheduleListById = state.cachedProductList.get(categoryUuid);

    return {
      ...state,
      ...{
        productList: List.isList(state.productList)
          ? state.productList.map((stateProductCategoryProduct) => {
              if (stateProductCategoryProduct.uuid === product?.uuid) {
                return stateProductCategoryProduct.merge(product);
              }
              return stateProductCategoryProduct;
            })
          : List([product]),

        cachedProductList: relaterScheduleListById
          ? state.cachedProductList.update(categoryUuid, (productList) => {
              if (productList) {
                return productList.update('products', (products) =>
                  products.map((productModel) => {
                    if (productModel.uuid === product.uuid) {
                      return productModel.merge(product);
                    } else {
                      return productModel;
                    }
                  }),
                );
              } else {
                return ProductMapper.toProductListModel([], 0);
              }
            })
          : state.cachedProductList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRODUCT_CATEGORY_PRODUCT_LIST.DELETE_PRODUCT_CATEGORY_PRODUCT]: (
    state,
    { productUuid },
  ) => {
    return {
      ...state,
      ...{
        productList: List.isList(state.productList)
          ? state.productList.filter(compose(not, isEqualByUuid(productUuid)))
          : null,
        cachedProductList: state.cachedProductList.map((products) =>
          products.update('products', (list) => {
            const index = list.findIndex(({ uuid }) => uuid === productUuid);

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [PRODUCT_CATEGORY_PRODUCT_LIST.ERROR_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT_CATEGORY_PRODUCT_LIST.LOADING_PRODUCT_CATEGORY_PRODUCT_LIST]: (
    state: IProductCategoryProductListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductCategoryProductListState) => state,
};

export default function ProductCategoryProductList(
  state: any = initState,
  action: any,
): IProductCategoryProductListState {
  const handler =
    handlers[action.type as PRODUCT_CATEGORY_PRODUCT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
