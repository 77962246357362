import * as React from 'react';
import { getProductById } from '@services/api/product';
import { ProductModel, ProductMapper, ProductDTO } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { IEntityProps } from '@services/types';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseProductProps extends IEntityProps {
  productUuid: string;
}

export interface IUseProductReturnType {
  error: null | any;
  loading: boolean;
  entity: ProductModel | null;
  refresh: (value: Partial<IUseProductProps>) => Promise<ProductModel | void>;
  productUuid: string;
}

export function useProduct(
  { loadOnInit = true, productUuid }: IUseProductProps = {} as IUseProductProps,
): IUseProductReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    productUuid: entityUuid,
  } = useStateReducer<Omit<IUseProductReturnType, 'refresh'>>({
    entity: null,
    productUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      productUuid = entityUuid,
    }: Partial<IUseProductProps> = {}): Promise<ProductModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null, productUuid });

        const product = await cancellablePromise<ProductDTO>(
          getProductById(productUuid),
        );

        const productModel = ProductMapper.toProductModel(product);

        handleUpdate({
          entity: productModel,
          loading: false,
        });
        return productModel;
      } catch (err: any) {
        alert(
          'error',
          t('Material'),
          `${t('An error occurred during get material')}: ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && productUuid) {
      (async () => {
        await refresh({ productUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, productUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    productUuid,
  };
}
