import * as React from 'react';
import { List } from 'immutable';
import moment from 'moment';
import useCategoryList, {
  IUseCategoryListProps,
  IUseCategoryListReturnType,
} from './useCategoryList';
import { CategoryModel, CategoryFormDTO, CategoryMapper } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  createCategory,
  editCategory,
  deleteCategories,
} from '@services/api/category';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateCategoryListProps extends IUseCategoryListProps {}

export interface IUseStateCategoryListReturnType
  extends Omit<IUseCategoryListReturnType, 'entityList'> {
  categories: List<CategoryModel> | null;
  handleCreateCategory: (value: CategoryFormDTO) => Promise<void>;
  handleUpdateCategory: (value: CategoryFormDTO) => Promise<void>;
  handleDeleteCategories: (ids: string[]) => Promise<void>;
  handleSearchCategories: (value: any) => Promise<void>;
}

export function useStateCategoryList(
  { ...rest }: IUseStateCategoryListProps = {} as IUseStateCategoryListProps,
): IUseStateCategoryListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: categoryListLoading,
    total: categoryListTotal,
    ...clientsParams
  } = useCategoryList({
    ...rest,
  });

  const {
    entityList: categories,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<CategoryModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: categoryListTotal,
  });

  const handleCreateCategory = React.useCallback(
    async (value: CategoryFormDTO) => {
      const category = await createCategory(value);
      const categoryModel = CategoryMapper.toCategoryModel(category);

      const updatedModel = categoryModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);

      alert('success', t('Category'), t('Category created success'));
    },
    [alert, handleCreate, t],
  );

  const handleUpdateCategory = React.useCallback(
    async (value: CategoryFormDTO) => {
      const category = await editCategory(value);
      const categoryModel = CategoryMapper.toCategoryModel(category);

      handleUpdate(categoryModel);

      alert('success', t('Category'), t('Category edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleDeleteCategories = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteCategories(ids);

        handleDelete(ids);

        alert('success', t('Categories'), t('Categories delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Employee'),
          `${t('An error occurred during delete categories')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, t],
  );

  const handleSearchCategories = React.useCallback(
    async (keywords: string) => {
      const counterparty = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        showLoading: false,
      });

      if (counterparty) {
        setEntityList(counterparty);
      }
    },
    [refresh, setEntityList],
  );

  return {
    categories,
    offset,
    limit,
    refresh,
    total,
    loading: categoryListLoading || loading,
    ...clientsParams,
    handleCreateCategory,
    handleUpdateCategory,
    handleDeleteCategories,
    handleSearchCategories,
  };
}
