import { ClientDiscountType, ClientModel, ClientOrgType } from '../internal';

export class ClientLegalFormDTO {
  public uuid: string;
  public client_org_type: ClientOrgType;
  public client_org_name: string;
  public client_org_code: string;
  public client_org_tax_code: string;
  public client_org_address: string;
  public client_org_manager: string;
  public client_org_contact_person: string;
  public client_org_bank_name: string;
  public client_org_bank_code: string;
  public client_org_bank_account_id: string;
  public client_is_provisioner: boolean;
  public client_is_conflict: boolean;
  public client_is_tav_payer: boolean;
  public client_discount: string;
  public client_discount_type: ClientDiscountType;

  public client_discount_goods: string;

  public client_discount_goods_type: ClientDiscountType;
  public client_comment: string;
  public phone: string;
  public email: string;

  constructor(props?: ClientModel) {
    this.uuid = props?.uuid || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.client_comment = props?.client_comment || '';
    this.client_org_type = props?.client_org_type || ClientOrgType.ORGANIZATION;
    this.client_org_name = props?.client_org_name || '';
    this.client_org_code = props?.client_org_code || '';
    this.client_org_contact_person = props?.client_org_contact_person || '';
    this.client_org_tax_code = props?.client_org_tax_code || '';
    this.client_org_address = props?.client_org_address || '';
    this.client_org_manager = props?.client_org_manager || '';
    this.client_org_bank_name = props?.client_org_bank_name || '';
    this.client_org_bank_code = props?.client_org_bank_code || '';
    this.client_org_bank_account_id = props?.client_org_bank_account_id || '';
    this.client_is_provisioner =
      typeof props?.client_is_provisioner === 'boolean'
        ? props?.client_is_provisioner
        : false;
    this.client_is_conflict =
      typeof props?.client_is_conflict === 'boolean'
        ? props?.client_is_conflict
        : false;

    this.client_is_tav_payer =
      typeof props?.client_is_tav_payer === 'boolean'
        ? !!props?.client_is_tav_payer
        : false;

    this.client_discount = props?.client_discount ?? '0';
    this.client_discount_type =
      props?.client_discount_type ?? ClientDiscountType.Fixed;

    this.client_discount_goods = props?.client_discount_goods ?? '0';
    this.client_discount_goods_type =
      props?.client_discount_goods_type ?? ClientDiscountType.Fixed;
  }
}
