import * as React from 'react';
import { List } from 'immutable';
import {
  useStoreDocumentList,
  IUseStoreDocumentListProps,
  IUseStoreDocumentListReturnType,
} from './useStoreDocumentList';
import {
  StoreDocumentListModel,
  StoreDocumentMappedType,
  StoreDocumentModel,
  StoreDocumentShowOnly,
  StoreDocumentStatsDTO,
} from '@structure';
import { deleteStoreDocument } from '@services/api/storeDocument';
import {
  setStoreDocumentList as storeSetStoreDocumentList,
  deleteStoreDocumentFromList as storeDeleteStoreDocumentFromList,
  loadMoreStoreDocumentList as storeLoadMoreStoreDocumentList,
  updateStoreDocumentStatsFromList as storeUpdateStoreDocumentStatsFromList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { dateToIsoString, head, last, isFunction } from '@services/helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { Moment } from 'moment/moment';

export interface IUseStateStoreDocumentListProps
  extends IUseStoreDocumentListProps {
  mappedDocumentType?: StoreDocumentMappedType;
  withoutDates?: boolean;
  alwaysSendRequest?: boolean;
  withShowOnlyUpdate?: boolean;
}

export interface IStoreDocumentSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  start_date?: string | null;
  end_date?: string | null;
  show_only?: StoreDocumentShowOnly;
  show_with_credits_only?: boolean;

  start_important?: any;
  end_important?: any;
}

export interface IUseStateStoreDocumentListReturnType
  extends Omit<IUseStoreDocumentListReturnType, 'entityList'> {
  documentList: List<StoreDocumentModel> | null;
  handleDeleteStoreDocuments: (ids: string[], callback?: any) => Promise<void>;
  handleSearchStoreDocuments: (
    value: IStoreDocumentSearchProps,
  ) => Promise<StoreDocumentListModel | void>;
  handleLoadMoreStoreDocuments: () => Promise<StoreDocumentListModel | void>;
  handleRefreshStoreDocuments: (
    value: Partial<IUseStoreDocumentListProps> & { page: number },
  ) => Promise<void>;
  handleRefreshStoreDocumentsType: (
    value: Partial<IUseStoreDocumentListProps>,
  ) => Promise<void>;
  handlePickStoreDocumentRange: (value: [Moment, Moment]) => Promise<void>;
  loadingMore: boolean;
  page: number;
  stats: StoreDocumentStatsDTO | null;
  handleSetStoreDocumentList: (value: StoreDocumentListModel) => void;
  isLoadingMore: boolean;
}

export function useStoredStoreDocumentList(
  {
    companyUuid,
    documentType,
    mappedDocumentType,
    loadOnInit = true,
    show_only,
    alwaysSendRequest,
    withShowOnlyUpdate = true,
    ...rest
  }: IUseStateStoreDocumentListProps = {} as IUseStateStoreDocumentListProps,
): IUseStateStoreDocumentListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [documentList, setDocumentList] =
    useState<List<StoreDocumentModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [stats, setStats] = useState<StoreDocumentStatsDTO | null>(null);
  const [showOnly, setShowOnly] = useState<StoreDocumentShowOnly | undefined>(
    undefined,
  );

  const once = useRef(false);
  const size = useMemo(() => documentList?.size || 0, [documentList?.size]);
  const isLoadingMore = useMemo(() => size < storeTotal, [size, storeTotal]);

  const {
    status: storedStoreDocumentListStatus,
    loading: storedStoreDocumentListLoading,
    cachedStoreDocumentList,
    ...storedStoreDocumentListParams
  } = useSelector(({ storeDocumentList }: RootState) => storeDocumentList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: documentListLoading,
    start_date,
    end_date,
    ...documentsParams
  } = useStoreDocumentList({
    companyUuid,
    documentType,
    loadOnInit: false,
    show_only,
    ...rest,
  });

  useEffect(() => {
    if (cachedStoreDocumentList?.size > 0) {
      const storeDocumentList = cachedStoreDocumentList.get(
        mappedDocumentType || documentType,
      );

      if (storeDocumentList) {
        setIsStartLoading(false);
        setDocumentList(storeDocumentList?.documents);
        setStorePage(storeDocumentList?.page || 1);
        setStoreKeywords(storeDocumentList?.keywords || '');
        setStoreTotal(storeDocumentList?.total || 0);
        setStats(storeDocumentList?.stats || null);
        setShowOnly(storeDocumentList?.show_only || undefined);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedStoreDocumentList?.size === 0 && !documentList) {
      setIsStartLoading(true);
    }
  }, [
    cachedStoreDocumentList,
    dispatch,
    documentList,
    documentType,
    entityList,
    mappedDocumentType,
    storedStoreDocumentListStatus,
  ]);

  useEffect(() => {
    if (
      (!List.isList(entityList?.documents) &&
        isStarLoading &&
        loadOnInit &&
        companyUuid) ||
      (alwaysSendRequest && loadOnInit && companyUuid && !once?.current)
    ) {
      once.current = true;
      setShowOnly(show_only);

      setIsStartLoading(false);

      (async () => {
        const entityList = await refresh({
          documentType,
          companyUuid,
          show_only,
        });

        if (entityList) {
          dispatch(
            storeSetStoreDocumentList(
              entityList,
              mappedDocumentType || documentType,
            ),
          );
        }
      })();
    }
  }, [
    alwaysSendRequest,
    companyUuid,
    dispatch,
    documentType,
    entityList?.documents,
    isStarLoading,
    loadOnInit,
    mappedDocumentType,
    refresh,
    show_only,
  ]);

  useEffect(() => {
    if (
      List.isList(documentList) &&
      showOnly !== show_only &&
      !loadingMore &&
      companyUuid &&
      withShowOnlyUpdate
    ) {
      setShowOnly(show_only);
      setLoadingMore(true);

      setIsStartLoading(false);

      (async () => {
        const entityList = await refresh({
          documentType,
          companyUuid,
          show_only,
        });

        if (entityList) {
          dispatch(
            storeSetStoreDocumentList(
              entityList,
              mappedDocumentType || documentType,
            ),
          );
        }
      })();
      setLoadingMore(false);
    }
  }, [
    companyUuid,
    dispatch,
    documentList,
    documentType,
    loadingMore,
    mappedDocumentType,
    refresh,
    showOnly,
    show_only,
    withShowOnlyUpdate,
  ]);

  const handleDeleteStoreDocuments = React.useCallback(
    async (ids: string[], callback?: any) => {
      try {
        const { stats } = await deleteStoreDocument(ids);

        setTimeout(() => {
          dispatch(storeDeleteStoreDocumentFromList(head(ids), documentType));
          dispatch(storeUpdateStoreDocumentStatsFromList(stats, documentType));
        }, 100);
        alert(
          'success',
          t('Store document'),
          t('Store document delete success'),
        );

        if (isFunction(callback)) {
          callback();
        }
      } catch (error: any) {
        alert(
          'error',
          t('Store document'),
          `${t('An error occurred during delete store document')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, documentType, t],
  );

  const handleSearchStoreDocuments = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      start_date: start,
      end_date: end,
      start_important,
      end_important,
      ...rest
    }: IStoreDocumentSearchProps) => {
      setLoadingMore(true);
      const documentListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        start_date: start_important
          ? start_important
          : start === null
          ? start
          : start_date,
        end_date: end_important ? end_important : end === null ? end : end_date,
        ...rest,
      });

      if (documentListModel && List.isList(documentListModel?.documents)) {
        const updatedStoreDocumentList = documentListModel.update(
          'keywords',
          () => keywords,
        );

        dispatch(
          storeSetStoreDocumentList(
            updatedStoreDocumentList,
            mappedDocumentType || documentType,
          ),
        );

        setLoadingMore(false);

        return documentListModel;
      }
      setLoadingMore(false);
    },
    [dispatch, documentType, end_date, mappedDocumentType, refresh, start_date],
  );

  const handleLoadMoreStoreDocuments = useCallback(async () => {
    if (
      List.isList(documentList) &&
      documentList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const documentListModel = await refresh({
        offset: documentList?.size,
        limit: 10,
        showLoading: false,
      });

      if (documentListModel && List.isList(documentListModel?.documents)) {
        dispatch(
          storeLoadMoreStoreDocumentList(
            documentListModel,
            mappedDocumentType || documentType,
          ),
        );

        setLoadingMore(false);

        return documentListModel;
      }

      setLoadingMore(false);
    }
  }, [
    documentList,
    storeTotal,
    loadingMore,
    refresh,
    dispatch,
    mappedDocumentType,
    documentType,
  ]);

  const handleRefreshStoreDocumentsType = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      show_only,
      resident_uuid,
    }: Partial<IUseStoreDocumentListProps>) => {
      setLoadingMore(true);

      const documentListModel = await refresh({
        offset,
        limit,
        showLoading,
        show_only,
        resident_uuid,
      });

      if (documentListModel && List.isList(documentListModel?.documents)) {
        dispatch(
          storeSetStoreDocumentList(
            documentListModel,
            mappedDocumentType || documentType,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, documentType, mappedDocumentType, refresh],
  );

  const handleRefreshStoreDocuments = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseStoreDocumentListProps> & { page: number }) => {
      setLoadingMore(true);

      const documentListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (documentListModel && List.isList(documentListModel?.documents)) {
        const updatedStoreDocumentList = documentListModel.update(
          'page',
          () => page,
        );

        dispatch(
          storeSetStoreDocumentList(
            updatedStoreDocumentList,
            mappedDocumentType || documentType,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, documentType, mappedDocumentType, refresh],
  );

  const handlePickStoreDocumentRange = React.useCallback(
    async (value: [Moment, Moment]) => {
      setLoadingMore(true);
      let documentListModel;

      if (value) {
        documentListModel = await refresh({
          offset: 0,
          limit,
          keywords: storeKeywords,
          showLoading: true,
          start_date: dateToIsoString(head(value)),
          end_date: dateToIsoString(last(value)),
        });
      } else {
        documentListModel = await refresh({
          offset: 0,
          limit,
          keywords: storeKeywords,
          showLoading: true,
          start_date: null as any,
          end_date: null as any,
        });
      }

      if (documentListModel && List.isList(documentListModel?.documents)) {
        const updatedStoreDocumentList = documentListModel.update(
          'keywords',
          () => storeKeywords,
        );

        dispatch(
          storeSetStoreDocumentList(
            updatedStoreDocumentList,
            mappedDocumentType || documentType,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, documentType, limit, mappedDocumentType, refresh, storeKeywords],
  );

  const handleSetStoreDocumentList = useCallback(
    (value: StoreDocumentListModel) => {
      dispatch(
        storeSetStoreDocumentList(value, mappedDocumentType || documentType),
      );

      setDocumentList(value?.documents);
    },
    [dispatch, documentType, mappedDocumentType],
  );

  return {
    ...documentsParams,
    ...storedStoreDocumentListParams,
    documentList: documentList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(documentList) && documentListLoading) ||
      !storedStoreDocumentListLoading,
    handleDeleteStoreDocuments,
    handleSearchStoreDocuments,
    handleLoadMoreStoreDocuments,
    handleRefreshStoreDocuments,
    keywords: storeKeywords,
    page: storePage,
    handlePickStoreDocumentRange,
    start_date,
    end_date,
    stats,
    handleRefreshStoreDocumentsType,
    handleSetStoreDocumentList,
    isLoadingMore,
  };
}
