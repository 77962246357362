import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceItemFormDTO, InvoiceModel, InvoiceType } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { InvoiceManagerContext } from '../Managers';

const InvoiceItemModalLazy = React.lazy(
  () => import('../Show/InvoiceItemModal'),
);

export interface InvoiceItemUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: InvoiceManagerContext['handleAddOrUpdateInvoiceItems'];
  onCancel?: () => void;
  invoice: InvoiceModel;
  item: InvoiceItemFormDTO;
}

export function InvoiceItemUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  invoice,
  item,
  ...rest
}: InvoiceItemUpdateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={
          invoice?.inv_type === InvoiceType.IN
            ? t('Update invoice')
            : t('Update input invoice')
        }
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceItemModalLazy
          editMode
          invoice={invoice}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          item={item}
          title={item?.item_title}
        />
      </Suspense>
    </>
  );
}
