import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IClientVisitListDTO,
  ClientVisitMapper,
  ClientVisitModel,
  ClientVisitListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getClientVisitList } from '@services/api/client';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseClientVisitListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  abonementUuid: string;
}

export interface IUseClientVisitListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ClientVisitModel> | null;
  refresh: (
    value: Partial<IUseClientVisitListProps>,
  ) => Promise<ClientVisitListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  abonementUuid: string;
}

export function useClientVisitList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    abonementUuid,
  }: IUseClientVisitListProps = {} as IUseClientVisitListProps,
): IUseClientVisitListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    abonementUuid: listUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseClientVisitListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    abonementUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      abonementUuid = listUuid,
    }: Partial<IUseClientVisitListProps> = {}): Promise<ClientVisitListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          abonementUuid,
        });

        const { visits, abonement, total } =
          await cancellablePromise<IClientVisitListDTO>(
            getClientVisitList({ limit, offset, keywords, abonementUuid }),
          );

        const visitListModel = ClientVisitMapper.toClientVisitListModel(
          visits,
          abonement,
          total,
        );

        handleUpdate({
          entityList: visitListModel?.visits,
          total: visitListModel?.total,
          loading: false,
        });

        return visitListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Client visits'),
          `${t('An error occurred during get client visit list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && abonementUuid) {
      (async () => {
        await refresh({ abonementUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, abonementUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    abonementUuid: listUuid,
  };
}
