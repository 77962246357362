import { List } from 'immutable';
import { PRICE_TAG_LIST, APP_STATE } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import { PriceTagListModel, PriceTagMapper, PriceTagModel } from '@structure';

interface IPriceTagListState {
  priceTagList: PriceTagListModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetActionList {
  type: PRICE_TAG_LIST.SET_PRICE_TAG_LIST;
  priceTagList: PriceTagListModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRICE_TAG_LIST.ADD_PRICE_TAG;
  priceTag: PriceTagModel;
}

interface UpdatePriceTagFromList {
  type: PRICE_TAG_LIST.UPDATE_PRICE_TAG;
  priceTag: PriceTagModel;
}

interface DeleteActionFromList {
  type: PRICE_TAG_LIST.DELETE_PRICE_TAG;
  priceTagUuid: string;
}

interface LoadingActionInList {
  type: PRICE_TAG_LIST.LOADING_PRICE_TAG_LIST;
}

interface ErrorActionInList extends Pick<IPriceTagListState, 'error'> {
  type: PRICE_TAG_LIST.ERROR_PRICE_TAG_LIST;
}

interface Handlers {
  [PRICE_TAG_LIST.SET_PRICE_TAG_LIST]: (
    state: IPriceTagListState,
    action: SetActionList,
  ) => IPriceTagListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IPriceTagListState,
    action: SetInitialStateAction,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.LOAD_MORE_PRICE_TAG_LIST]: (
    state: IPriceTagListState,
    action: SetActionList,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.ADD_PRICE_TAG]: (
    state: IPriceTagListState,
    action: AddActionToList,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.UPDATE_PRICE_TAG]: (
    state: IPriceTagListState,
    action: UpdatePriceTagFromList,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.DELETE_PRICE_TAG]: (
    state: IPriceTagListState,
    action: DeleteActionFromList,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.ERROR_PRICE_TAG_LIST]: (
    state: IPriceTagListState,
    value: ErrorActionInList,
  ) => IPriceTagListState;

  [PRICE_TAG_LIST.LOADING_PRICE_TAG_LIST]: (
    state: IPriceTagListState,
    value?: LoadingActionInList,
  ) => IPriceTagListState;
  DEFAULT: (state: IPriceTagListState) => IPriceTagListState;
}

const initState: IPriceTagListState = {
  priceTagList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [PRICE_TAG_LIST.SET_PRICE_TAG_LIST]: (state, { priceTagList }) => {
    return {
      ...state,
      ...{
        priceTagList: priceTagList || null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRICE_TAG_LIST.LOAD_MORE_PRICE_TAG_LIST]: (state, { priceTagList }) => {
    return {
      ...state,
      ...{
        priceTagList:
          state?.priceTagList &&
          List.isList(state.priceTagList?.items) &&
          List.isList(priceTagList?.items)
            ? state?.priceTagList.update('items', (items) =>
                items.merge(priceTagList.items),
              )
            : state.priceTagList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_TAG_LIST.ADD_PRICE_TAG]: (state, { priceTag }) => {
    return {
      ...state,
      ...{
        priceTagList:
          state.priceTagList && List.isList(state.priceTagList?.items)
            ? state.priceTagList
                .update('items', (d) => d.unshift(priceTag))
                .update('total', (t = 0) => t + 1)
                .update('stats', (stats = {} as any) => ({
                  ...stats,
                  product_price_tags_count:
                    (stats?.product_price_tags_count || 0) + 1,
                }))
            : PriceTagMapper.toPriceTagListModel(
                [PriceTagMapper.toPriceTagDTO(priceTag)],
                1,
              ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_TAG_LIST.UPDATE_PRICE_TAG]: (
    state: IPriceTagListState,
    { priceTag }: UpdatePriceTagFromList,
  ) => {
    return {
      ...state,
      ...{
        priceTagList:
          state.priceTagList && List.isList(state.priceTagList?.items)
            ? state.priceTagList.update('items', (d) =>
                d.map((statePriceTag) => {
                  if (statePriceTag.uuid === priceTag?.uuid) {
                    return statePriceTag.merge(priceTag);
                  }
                  return statePriceTag;
                }),
              )
            : PriceTagMapper.toPriceTagListModel(
                [PriceTagMapper.toPriceTagDTO(priceTag)],
                1,
              ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_TAG_LIST.DELETE_PRICE_TAG]: (state, { priceTagUuid }) => {
    return {
      ...state,
      ...{
        priceTagList:
          state.priceTagList && List.isList(state.priceTagList?.items)
            ? state.priceTagList
                .update('items', (d) =>
                  d.filter(compose(not, isEqualByUuid(priceTagUuid))),
                )
                .update('total', (t = 0) => t - 1)
                .update('stats', (stats = {} as any) => ({
                  ...stats,
                  product_price_tags_count:
                    stats?.product_price_tags_count > 0
                      ? stats?.product_price_tags_count - 1
                      : 0,
                }))
            : null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_TAG_LIST.ERROR_PRICE_TAG_LIST]: (
    state: IPriceTagListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRICE_TAG_LIST.LOADING_PRICE_TAG_LIST]: (state: IPriceTagListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IPriceTagListState) => state,
};

export default function PriceTagList(
  state: any = initState,
  action: any,
): IPriceTagListState {
  const handler = handlers[action.type as PRICE_TAG_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
