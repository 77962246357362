import * as React from 'react';
import { useStoredCompanies } from './index';
import { CompanyModuleModel } from '@structure';

export interface IModulesMap {
  abonement: CompanyModuleModel;
  sms: CompanyModuleModel;
  email: CompanyModuleModel;
  stock: CompanyModuleModel;
  document: CompanyModuleModel;
  sales: CompanyModuleModel;
}

export interface IUseSystemModulesReturnType {
  modulesMap: IModulesMap | null;
}
export function useSystemModules(): IUseSystemModulesReturnType {
  const { settings } = useStoredCompanies();

  const [modulesMap, setModulesMap] = React.useState<IModulesMap | null>(null);

  React.useEffect(() => {
    if (settings?.settings?.company_modules) {
      const modulesMap = settings?.settings?.company_modules?.reduce(
        (acc: any, cur) => {
          acc[cur?.module] = cur;

          return acc;
        },
        {},
      );

      setModulesMap(modulesMap);
    }
  }, [settings?.settings]);

  return {
    modulesMap,
  };
}
