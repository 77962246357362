import React from 'react';
import './FormFooter.less';
import { Col, Row } from 'antd';
import { isFunction } from '@services/helpers';
import styled, { css } from 'styled-components';
import { useDefaultForm } from '@contex';
import { Button } from '../DataDisplay';

export interface IFormFooterProps {
  cancelButtonText?: string;
  submitButtonText?: string;
  cancelButtonProps?: any;
  submitButtonProps?: any;
  onSubmit?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
  showCancelButton?: boolean;
  customButton?: (
    loading?: boolean,
    submit?: () => void,
    handlerUpdateFormState?: (value: any) => void,
  ) => React.ReactNode;
  disabledSubmitButton?: boolean;
  invertFooterButtons?: boolean;
  footerClassName?: string;
  stickyFooter?: boolean;
  asModal?: boolean;
}

const StyledRow = styled(Row)<{ $sticky: boolean; $asModal?: boolean }>`
  position: sticky;
  bottom: -10px;
  z-index: 10;

  background-color: ${({ theme }) => theme.background.primary};

  ${({ $asModal }) =>
    $asModal &&
    css`
      bottom: 0;
      border-top: none;
    `}
`;

const StyledButton = styled(Button)<{
  $background?: string;
  $asModal?: boolean;
}>`
  width: fit-content;

  &&&,
  &&&:hover {
    border: none;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled, $background }) =>
    !disabled &&
    $background &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${$background};
        opacity: 0.7;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      margin-left: auto;
    `}
  
  ${({ $asModal }) =>
    $asModal &&
    css`
      margin-left: unset !important;
    `}
`;

const StyledCancelButton = styled(Button)`
  border: 1px solid rgb(177, 60, 60);
  color: ${({ theme }) => theme.colors.error};
  width: fit-content;
`;

const StyledButtonContainer = styled.div<{
  $background?: string;
  $asModal?: boolean;
}>`
  ${({ $asModal }) =>
    $asModal &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    `}
`;

export default function FormFooter({
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel,
  showCancelButton = true,
  customButton,
  disabledSubmitButton = false,
  footerClassName = '',
  stickyFooter,
  asModal,
}: IFormFooterProps): JSX.Element {
  const { handlerUpdateFormState } = useDefaultForm();

  const background = submitButtonProps?.style?.backgroundColor;

  const { style, ...restButtonProps } = submitButtonProps;

  return (
    <StyledRow
      $asModal={asModal}
      className={`FormFooter ${footerClassName}`}
      gutter={20}
      $sticky={!!stickyFooter}>
      <Col span={24}>
        <StyledButtonContainer
          className="FormFooter_button-wrapper"
          $asModal={asModal}>
          {showCancelButton && (
            <StyledCancelButton
              size={asModal ? 'large' : 'middle'}
              type={asModal ? 'default' : 'text'}
              className="FormFooter_button-cancel"
              onClick={() => onCancel && onCancel()}
              {...cancelButtonProps}>
              {cancelButtonText}
            </StyledCancelButton>
          )}
          {isFunction(customButton)
            ? customButton(
                submitButtonProps?.loading,
                () => onSubmit && onSubmit(),
                handlerUpdateFormState,
              )
            : null}
          <StyledButton
            size={asModal ? 'large' : 'middle'}
            $asModal={asModal}
            $background={background}
            disabled={disabledSubmitButton}
            className="FormFooter_button-submit"
            style={{ marginLeft: 'auto' }}
            type="default"
            htmlType="submit"
            onClick={() => onSubmit && onSubmit()}
            {...restButtonProps}>
            {submitButtonText}
          </StyledButton>
        </StyledButtonContainer>
      </Col>
    </StyledRow>
  );
}
