import moment, { Moment } from 'moment';
import {
  ClientSubscription,
  ListOptions,
  ClientSubscriptionList,
  ClientSubscriptionTransition,
  Payment,
} from '../models';
import Validate from '../validate/Validate';
import { apiPost, apiGet, apiPut } from '../core/api';
import { contentApiUrl } from '../const';
import { ApiAnswer } from '../types';
import {
  IClientSubscriptionProps,
  ListOptionsProps,
  IClientSubscriptionableProps,
  ServicesProps,
  ifElse,
  CLIENT_TYPE,
  head,
  IClientSubscriptionTransitionProps,
  SUBSCRIPTION_STATUS,
  reduce,
  compose,
  sortedByDateV2,
  sortBy,
  map,
  updateByUuid,
} from '../helpers';
import { SubscriptionUndefinedSubscriptionable } from '@components/lib/Errors';

export interface IClientSubscriptionParams
  extends Partial<IClientSubscriptionProps>,
    Partial<IClientSubscriptionTransitionProps> {
  clientUuid?: string;
  service_uuid: string;
  group_session_uuid?: string | undefined;
  cost: number;
  subscription_start_date: string | Moment;
  subscription_number_of_packages: number;
  payment_discount?: number;
  payment_comment?: string;
  payment_date?: string | Moment;
  payment_method?: string;
  payment_is_deferred: boolean;
  total: number;
  status?: SUBSCRIPTION_STATUS;
  t?: any;
}

export interface IClientSubscriptionGrouped {
  [key: string]: IClientSubscriptionProps[];
}

export interface IClientSubscriptionApiParams
  extends Omit<
    IClientSubscriptionParams,
    'cost' | 'total' | 'service_session_uuid'
  > {
  service_session_uuid?: string;
}

export interface IClientSubscription {
  subscription: IClientSubscriptionProps;
}

export interface IClientSubscriptionList extends ApiAnswer {
  subscriptions: ClientSubscription[];
}

export interface IClientSubscriptionTransition extends ApiAnswer {
  info: IClientSubscriptionTransitionProps;
}

export interface IClientSubscriptionTransitionParam {
  subscriptionUuid: string;
  service_uuid: string;
  group_session_uuid: string;
  subscription_start_date: string;
  subscription_number_of_packages: string;
}

export const CLIENT_SUBSCRIPTION_INITIAL_PARAM: IClientSubscriptionParams = {
  service_uuid: '',
  group_session_uuid: '',
  cost: 0,
  payment_discount: 0,
  subscription_start_date: moment().add(1, 'hour'),
  subscription_number_of_packages: 1,
  payment_comment: '',
  payment_date: moment(),
  payment_method: 'CASHBOX',
  total: 0,
  payment_is_deferred: false,
  status: SUBSCRIPTION_STATUS.DRAFT,
};

export const isSubscriptionEditable = (status: SUBSCRIPTION_STATUS): boolean =>
  status === SUBSCRIPTION_STATUS.READY || status === SUBSCRIPTION_STATUS.DRAFT;

export const isSubscriptionTypeGroup = (type: CLIENT_TYPE): boolean =>
  type === CLIENT_TYPE.GROUP;

export const isSubscriptionPayed = (status: SUBSCRIPTION_STATUS): boolean =>
  status === SUBSCRIPTION_STATUS.PAYED;

export const isSubscriptionPayedShowMessage = (
  status: SUBSCRIPTION_STATUS,
  message: string,
) => (isSubscriptionPayed(status) ? message : undefined);

export const buildSubscriptionFormParams = (
  subscription: IClientSubscriptionProps,
) =>
  (({
    uuid,
    start_date,
    payments,
    type,
    period_amount,
    subscriptionable: {
      uuid: group_session_uuid,
      service: { uuid: service_uuid } = {} as ServicesProps,
    } = {} as IClientSubscriptionableProps,
  }: IClientSubscriptionProps): IClientSubscriptionParams => {
    const { payment_comment, payment_date, payment_method, status } =
      head<any>((payments as any).toArray())?.toObject() || {};

    return {
      ...CLIENT_SUBSCRIPTION_INITIAL_PARAM,
      uuid,
      service_uuid,
      group_session_uuid: ifElse(
        isSubscriptionTypeGroup(type),
        group_session_uuid,
        '',
      ),
      payment_discount: 0,
      payment_comment,
      payment_date: moment(payment_date),
      payment_method,
      status,
      payment_is_deferred: false,
      subscription_start_date: moment(start_date),
      subscription_number_of_packages: period_amount || 1,
    };
  })(subscription);

export const getSubscriptionGroupTitle = (
  subscription: IClientSubscriptionProps | ClientSubscription,
): string => subscription?.subscriptionable?.title;

export const getSubscriptionServiceTitle = (
  subscription: IClientSubscriptionProps | ClientSubscription,
): string => subscription?.subscriptionable?.service?.title;

export const addSubscriptionToGroup = (
  callback: (subscription: IClientSubscriptionProps) => string,
  grouped: IClientSubscriptionGrouped,
  subscription: IClientSubscriptionProps,
  isUnshift: boolean = false,
): void | Error => {
  if (callback(subscription) === undefined) {
    throw new SubscriptionUndefinedSubscriptionable(subscription?.uuid);
  }

  grouped[callback(subscription)] = isUnshift
    ? [subscription, ...(grouped[callback(subscription)] || [])]
    : [...(grouped[callback(subscription)] || []), subscription];
};

export const updateGroupedSubscriptions = (
  callback: (
    subscription: IClientSubscriptionProps | ClientSubscription,
  ) => string,
  state: IClientSubscriptionGrouped,
  subscription: ClientSubscription,
) => ({
  ...state,
  [callback(subscription)]: map(
    updateByUuid(subscription?.toObject()),
    state[callback(subscription)],
  ),
});

export const subscriptionsGroupedCreate = (
  grouped: IClientSubscriptionGrouped,
  subscription: ClientSubscription,
  isUnshift: boolean = false,
): IClientSubscriptionGrouped => {
  if (isSubscriptionTypeGroup(subscription?.type)) {
    addSubscriptionToGroup(
      getSubscriptionGroupTitle,
      grouped,
      (subscription as any).toObject(),
      isUnshift,
    );
  }
  if (!isSubscriptionTypeGroup(subscription?.type)) {
    addSubscriptionToGroup(
      getSubscriptionServiceTitle,
      grouped,
      (subscription as any).toObject(),
      isUnshift,
    );
  }
  return grouped;
};

export const subscriptionGroupedUpdate = (
  grouped: IClientSubscriptionGrouped,
  subscription: ClientSubscription,
): IClientSubscriptionGrouped => {
  if (isSubscriptionTypeGroup(subscription?.type)) {
    updateGroupedSubscriptions(
      getSubscriptionGroupTitle,
      grouped,
      (subscription as any).toObject(),
    );
  }
  if (!isSubscriptionTypeGroup(subscription?.type)) {
    updateGroupedSubscriptions(
      getSubscriptionServiceTitle,
      grouped,
      (subscription as any).toObject(),
    );
  }
  return grouped;
};

export const subscriptionsSortAndGroped = (
  subscriptions: ClientSubscription[],
  zero: Object,
): IClientSubscriptionGrouped =>
  compose<IClientSubscriptionGrouped>(
    reduce(subscriptionsGroupedCreate, zero),
    sortBy(sortedByDateV2('updated_at')),
  )(subscriptions);

export const getSubscriptionsPayments = (
  subscription: IClientSubscriptionProps[],
): Payment[] =>
  map(
    (({ payments }: IClientSubscriptionProps) =>
      (payments as any).get(0)) as any,
    subscription,
  );

export async function getClientSubscriptions(
  uuid: string,
  listOptions = new ListOptions(),
): Promise<ClientSubscriptionList> {
  const { offset = 0, limit = 50 } = listOptions.toObject();

  const { subscriptions } = await apiGet<
    Partial<ListOptionsProps>,
    IClientSubscriptionList
  >(`${contentApiUrl}/clients/${uuid}/subscriptions`, {
    offset,
    limit,
  });

  return new ClientSubscriptionList({ subscriptions, total: 0 } as any);
}

export async function createClientSubscription({
  clientUuid,
  service_uuid,
  group_session_uuid,
  subscription_start_date,
  subscription_number_of_packages,
  payment_comment,
  payment_is_deferred,
  payment_date,
  payment_discount,
  payment_method,
  t,
}: IClientSubscriptionParams): Promise<ClientSubscription> {
  Validate.string({ value: clientUuid });
  Validate.string({ value: service_uuid });
  Validate.string({ value: subscription_start_date });
  Validate.number({
    value: subscription_number_of_packages,
    message: '',
    t,
  });

  const { subscription } = await apiPost<
    IClientSubscriptionApiParams,
    IClientSubscription
  >(`${contentApiUrl}/clients/${clientUuid}/subscriptions`, {
    ...{
      service_uuid,
      subscription_start_date,
      subscription_number_of_packages,
      payment_comment,
      group_session_uuid,
      payment_is_deferred,
    },
    ...(!payment_is_deferred
      ? { payment_date, payment_discount, payment_method }
      : {}),
  });

  return new ClientSubscription(subscription);
}

export async function editClientSubscription({
  uuid: service_uuid,
}: IClientSubscriptionParams): Promise<ClientSubscription> {
  // const {service} = await apiPut<IClientSubscriptionParams, IClientService>(
  //   `${contentApiUrl}/clients/services/${service_uuid}`,
  //   {},
  // );
  return new ClientSubscription({} as any);
}

export interface DeleteClient extends ApiAnswer {
  services: ClientSubscriptionList;
}

export async function cancelClientSubscriptions(
  subscriptionUuid: string,
): Promise<ClientSubscription> {
  Validate.string({ value: subscriptionUuid });

  const { subscription } = await apiPut<any, IClientSubscription>(
    `${contentApiUrl}/subscriptions/${subscriptionUuid}/cancel`,
    {},
  );

  return new ClientSubscription(subscription);
}

export async function getClientSubscriptionTransition({
  subscriptionUuid,
  service_uuid,
  group_session_uuid,
  subscription_start_date,
  subscription_number_of_packages,
}: IClientSubscriptionTransitionParam): Promise<ClientSubscriptionTransition> {
  const isServiceUuid = service_uuid ? `&service_uuid=${service_uuid}` : '';
  const isGroupSessionUuid = group_session_uuid
    ? `&group_session_uuid=${group_session_uuid}`
    : '';

  const { info } = await apiGet<null, IClientSubscriptionTransition>(
    `${contentApiUrl}/subscriptions/${subscriptionUuid}/transition?subscription_number_of_packages=${subscription_number_of_packages}&subscription_start_date=${subscription_start_date}${isServiceUuid}${isGroupSessionUuid}`,
  );

  return new ClientSubscriptionTransition(info);
}
