import { StyledTitle } from '@components/lib/Styled';
import { employee } from '@sportix/sportix-common-modules';
import { EmployeeMapper } from '@structure';
import { EmployeeCustomScheduleFormDTO } from '@structure/dto/EmployeeCustomScheduleFormDTO';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmployeeModel } from '@models/EmployeeModel';
import EmployeeCustomShiftScheduleForm from 'views/Employees/Forms/EmployeeCustomShiftScheduleForm';

const StyleEmployeeOverviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledOverviewHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  margin: 10px 0;
  align-items: center;
  justify-content: space-between;
`;

const StyledOverviewContent = styled.div``;

export interface IEmployeeOverviewSectionProps {
  employee?: EmployeeModel | null;
}

export function EmployeeOverviewSection(
  props: IEmployeeOverviewSectionProps,
): JSX.Element {
  const { employee } = props || {};
  const { t } = useTranslation();

  return (
    <StyleEmployeeOverviewContainer>
      {/*<StyledOverviewHeader>*/}
      {/*  <StyledTitle fontSize={15} fontWeight={600}>*/}
      {/*    {`${t('Employee latest activities')}:`}*/}
      {/*  </StyledTitle>*/}
      {/*</StyledOverviewHeader>*/}
      {/*<StyledOverviewContent>table is coming soon...</StyledOverviewContent>*/}

      {/*<StyledOverviewHeader>*/}
      {/*  <StyledTitle fontSize={15} fontWeight={600}>*/}
      {/*    {`${t('Employee work schedule')}:`}*/}
      {/*  </StyledTitle>*/}
      {/*</StyledOverviewHeader>*/}
      {/*<StyledOverviewContent>*/}
      {/*  <EmployeeCustomShiftScheduleForm*/}
      {/*    editMode={false}*/}
      {/*    employee={EmployeeMapper.toEmployeeDTO(employee as EmployeeModel)}*/}
      {/*    onSuccess={(value: EmployeeCustomScheduleFormDTO) =>*/}
      {/*      Promise.resolve()*/}
      {/*    }*/}
      {/*    onCancel={() => {}}*/}
      {/*  />*/}
      {/*</StyledOverviewContent>*/}
    </StyleEmployeeOverviewContainer>
  );
}
