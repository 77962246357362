import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import {
  IUseStateEmployeeReturnType,
  useCompanyOrderBonusFieldParams,
  useStoredCompanies,
} from '@hooks';
import { Col, Form, Row } from 'antd';
import { CompanyOrderBonusDTO, CompanyOrderBonusGroupType } from '@structure';
import { DefaultForm } from '@components/lib/General';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import styled from 'styled-components';

export interface IEmployeeOrderBonusListProps
  extends Pick<IUseStateEmployeeReturnType, 'handleChangeEmployeeBonuses'> {
  bonuses: CompanyOrderBonusDTO[] | null;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<CompanyOrderBonusDTO>>;

export function EmployeeOrderBonusList({
  bonuses,
  handleChangeEmployeeBonuses,
}: IEmployeeOrderBonusListProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { settings } = useStoredCompanies();

  const [instance] = Form.useForm();

  const [employeeCompanyOrderBonuses, setEmployeeCompanyOrderBonuses] =
    useState<CompanyOrderBonusDTO[]>([]);
  const [selectedCompanyOrderBonuses, setSelectedCompanyOrderBonuses] =
    useState<CompanyOrderBonusDTO[] | null>(null);

  const { columns, companyOrderBonuses, components, rowSelection } =
    useCompanyOrderBonusFieldParams({
      companyOrderBonuses: employeeCompanyOrderBonuses || [],
      selectedCompanyOrderBonuses,
      form: instance,
      type: 'bonuses',
      onChange: handleChangeEmployeeBonuses as any,
    });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Bonus'),
        `${t('An error occurred during edit employee bonuses')} : ${
          ApiError?.message
        }`,
      );
    },
    [alert, t],
  );

  useEffect(() => {
    if (settings?.settings?.company_order_bonuses) {
      setEmployeeCompanyOrderBonuses(
        (settings?.settings?.company_order_bonuses || [])?.filter(
          ({ groups }) => groups.includes(CompanyOrderBonusGroupType.Employee),
        ),
      );
    }
  }, [settings?.settings?.company_order_bonuses]);

  useEffect(() => {
    if (
      Array.isArray(bonuses) &&
      bonuses.length &&
      !Array.isArray(selectedCompanyOrderBonuses)
    ) {
      setSelectedCompanyOrderBonuses(bonuses);
    }
  }, [bonuses, selectedCompanyOrderBonuses]);

  return (
    <DefaultForm
      instance={instance}
      initialValues={{ bonuses: [] }}
      notifyError={notifyError}
      showFooter={false}
      showNotify={false}>
      {() => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item name="bonuses">
              <StyledTable
                components={components}
                rowClassName={() => 'editable-row parameters-editable-row'}
                bordered
                dataSource={companyOrderBonuses}
                rowKey="id"
                columns={columns}
                total={companyOrderBonuses?.length}
                pageSize={Infinity}
                rowSelection={rowSelection}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
