import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(RevenueInvoicePage);

export interface IRevenueInvoicePageProps extends WithTranslation {}

function RevenueInvoicePage({}: IRevenueInvoicePageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{ fontSize: 30 }}>Revenue Invoice Page</p>
    </div>
  );
}
