import { List } from 'immutable';
import {
  DepartmentDTO,
  IDepartmentDTOProps,
  DepartmentModel,
  DepartmentListModel,
  DepartmentFormDTO,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IDepartmentListDTO {
  departments: DepartmentDTO[];
  total: number;
  keywords?: string;
  page?: number;
}

export class DepartmentMapper {
  public static toDepartmentDTO(
    department: DepartmentModel | IDepartmentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): DepartmentDTO {
    return new DepartmentDTO(department, [
      WithoutRecursion.department,
      ...withoutRecursion,
    ]);
  }

  public static toDepartmentListDTO(
    departments: List<DepartmentModel> | IDepartmentDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IDepartmentListDTO {
    const departmentList = List.isList(departments)
      ? listToArray<DepartmentModel>(departments)
      : departments;

    return {
      departments: departmentList?.map((department) =>
        DepartmentMapper.toDepartmentDTO(department, [
          WithoutRecursion.department,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toDepartmentModel(
    departmentDTO: DepartmentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): DepartmentModel {
    return new DepartmentModel(departmentDTO, [
      WithoutRecursion.department,
      ...withoutRecursion,
    ]);
  }

  public static toDepartmentListModel(
    departmentDTOs: DepartmentDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): DepartmentListModel {
    return new DepartmentListModel({ departments: departmentDTOs, total }, [
      WithoutRecursion.department,
      ...withoutRecursion,
    ]);
  }

  public static toDepartmentFormDTO(
    departmentModel?: DepartmentModel,
  ): DepartmentFormDTO {
    return new DepartmentFormDTO(departmentModel);
  }
}
