import React, { useCallback, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { SearchSelect, Segmented } from '@components/lib/DataDisplay';
import { isListToArray } from '@services/helpers';
import { REPORT_FROM_PAYMENT_METHOD } from '@services/api/report';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DatePicker } from '@components/lib/General';
import {
  IUseStateEmployeeListReturnType,
  IUseStateCompanyClientListReturnType,
  IUseStateServiceListReturnType,
  IUseStateEmployeeAttachedServiceListReturnType,
} from '@hooks';
import { useDefaultForm } from '@contex';

export interface IReportingFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients'
    >,
    Pick<IUseStateServiceListReturnType, 'services' | 'handleSearchServices'>,
    Pick<
      IUseStateEmployeeAttachedServiceListReturnType,
      | 'employeeAttachedServiceList'
      | 'handleSearchEmployeeAttachedServices'
      | 'handleRefreshStoreEmployeeAttachedServices'
    > {
  setEmployeeUuid: React.Dispatch<string>;
  loading: boolean;
  employeesLoading: boolean;
  clientsLoading: boolean;
  servicesLoading: boolean;
  employeeAttachedServiceListLoading: boolean;
}

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

export function ReportingFields({
  loading,
  setEmployeeUuid,

  employees,
  employeesLoading,
  handleSearchEmployees,

  clients,
  clientsLoading,
  handleSearchClients,

  services,
  servicesLoading,
  handleSearchServices,

  employeeAttachedServiceList,
  employeeAttachedServiceListLoading,
  handleSearchEmployeeAttachedServices,
  handleRefreshStoreEmployeeAttachedServices,
}: IReportingFieldsProps): JSX.Element {
  const { t } = useTranslation();
  const { formData, handlerUpdateFormState, loadingSubmit } = useDefaultForm();

  const handleChangeEmployee = useCallback(
    async (employeeUuid: string) => {
      handlerUpdateFormState({
        report_service_uuid: '',
        report_employee_uuid: employeeUuid,
      });
      setEmployeeUuid(employeeUuid);

      await handleRefreshStoreEmployeeAttachedServices({
        employeeUuid,
        showLoading: true,
      });
    },
    [
      handleRefreshStoreEmployeeAttachedServices,
      setEmployeeUuid,
      handlerUpdateFormState,
    ],
  );

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Interval')}
          label={t('Interval')}
          name="_date"
          rules={[
            {
              required: true,
              message: t('Interval must be specified.'),
            },
          ]}>
          <StyledRangePicker
            showTime
            format="YYYY-MM-DD"
            disabled={loading}
            placeholder={[t('Start'), t('End')]}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Manager')}
          label={t('Manager')}
          name="report_employee_uuid">
          <SearchSelect
            selectFirst={false}
            name="report_employee_uuid"
            getOptionValueTitle="fullName"
            placeholder={t('Select an employee')}
            disable={loading || loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
            onChange={handleChangeEmployee}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Client')}
          label={t('Client')}
          name="report_client_uuid">
          <SearchSelect
            selectFirst={false}
            name="report_client_uuid"
            getOptionValueTitle="fullNameClient"
            placeholder={t('Select an client')}
            disable={loading || loadingSubmit || clientsLoading}
            data={isListToArray(clients as any)}
            onSearch={async (keywords) => {
              await handleSearchClients({
                keywords,
                showLoading: false,
                limit: 100,
              });
            }}
          />
        </Form.Item>
      </Col>

      {!formData?.report_employee_uuid ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Service')}
            label={t('Service')}
            name="report_service_uuid">
            <SearchSelect
              selectFirst={false}
              name="report_service_uuid"
              placeholder={t('Select a service')}
              disable={loading || loadingSubmit || servicesLoading}
              data={isListToArray(services as any)}
              onSearch={(keywords) =>
                handleSearchServices({
                  keywords,
                  showLoading: false,
                  limit: 100,
                }) as any
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      {formData?.report_employee_uuid ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Service')}
            label={t('Service')}
            name="report_service_uuid">
            <SearchSelect
              selectIfOnFirst={false}
              selectFirst={false}
              name="report_service_uuid"
              placeholder={t('Select a service')}
              disable={
                loading || loadingSubmit || employeeAttachedServiceListLoading
              }
              data={isListToArray(employeeAttachedServiceList as any)}
              onSearch={(keywords) =>
                handleSearchEmployeeAttachedServices({
                  keywords,
                  showLoading: false,
                  limit: 100,
                })
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item
          label={t('Type of operation')}
          name="report_operation_type"
          tooltip={{
            title: t('Type of operation'),
          }}>
          <Segmented
            options={REPORT_FROM_PAYMENT_METHOD}
            disabled={loadingSubmit}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
