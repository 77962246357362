import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  ICashBoxListDTO,
  CashBoxMapper,
  CashBoxModel,
  CashBoxListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getCashBoxList } from '@services/api/cashBox';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseCashBoxListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
}

export interface IUseCashBoxListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<CashBoxModel> | null;
  refresh: (
    value: Partial<IUseCashBoxListProps>,
  ) => Promise<CashBoxListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
}

export function useCashBoxList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
  }: IUseCashBoxListProps = {} as IUseCashBoxListProps,
): IUseCashBoxListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseCashBoxListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
    }: Partial<IUseCashBoxListProps> = {}): Promise<CashBoxListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
        });

        const { cashboxes, total } = await cancellablePromise<ICashBoxListDTO>(
          getCashBoxList({ limit, offset, keywords, companyUuid }),
        );

        const cashBoxListModel = CashBoxMapper.toCashBoxListModel(
          cashboxes,
          total,
        );

        handleUpdate({
          entityList: cashBoxListModel?.cashboxes,
          total: cashBoxListModel?.total,
          loading: false,
        });

        return cashBoxListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Cash boxes'),
          `${t('An error occurred during get cash box list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    if (companyUuid) {
      handleUpdate({ companyUuid });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
  };
}
