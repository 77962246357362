import React, { isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Label } from '../../../DataDisplay';
import { TextFormat } from '../../../Format';

export interface IOverviewLayoutDetailsItemProps {
  label: string;
  title: any;
  detailsWidth: number;
  className?: string;
  isFullWidthItems?: string[];
}

const ItemContainer = styled.div<{ $isFullWidthItem?: boolean }>`
  flex: ${({ $isFullWidthItem }) => ($isFullWidthItem ? '100%' : '50%')};
  height: auto;
  margin-bottom: 15px;
  width: 100%;
`;

const StyledLabel = styled(Label)`
  width: 100%;
`;

const StyledTextFormat = styled(TextFormat)`
  width: 100%;
`;

export default function OverviewLayoutDetailsItem({
  label,
  title,
  detailsWidth,
  className,
  isFullWidthItems = [],
}: IOverviewLayoutDetailsItemProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <ItemContainer
      $isFullWidthItem={detailsWidth < 400 || isFullWidthItems?.includes(label)}
      className={`${className} overview-details-item`}>
      <StyledLabel
        label={label.includes('ignore') ? '' : `${t(label)}:`}
        labelProps={{ bold: true }}>
        {isValidElement(title) ? (
          title
        ) : (
          <StyledTextFormat>{t(title)}</StyledTextFormat>
        )}
      </StyledLabel>
    </ItemContainer>
  );
}
