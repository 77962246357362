import React, { useCallback, useState } from 'react';
import { FileModel, FileStatusesText } from '@structure';
import styled, { css } from 'styled-components';
import {
  BsFileEarmark,
  BsFiletypeDoc,
  BsFiletypeJpg,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypeTxt,
  BsFiletypeXls,
} from 'react-icons/bs';
import { StyledDescription } from '@components/lib/Styled';
import Color from 'color';
import { DeleteButton } from '@components/lib/DataDisplay';
import { IUseStateFileListReturnType } from '@hooks';
import { useTranslation } from 'react-i18next';

import './FileGroupListItem.less';
import { TextFormat } from '@components/lib/Format';

export interface FileGroupListProps
  extends Pick<IUseStateFileListReturnType, 'handleDeleteFiles'> {
  file: FileModel;
  disabled?: boolean;
}

const StyledContainer = styled.div<{ $status: FileStatusesText }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  ${({ $status }) =>
    $status === FileStatusesText.Enabled &&
    css`
      cursor: pointer;
    `}
`;

const StyledTitleContainer = styled.div<{ $status: FileStatusesText }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  max-width: 90%;
  
  

  & > * {
    ${({ $status, theme }) =>
      $status === FileStatusesText.Enabled &&
      css`
        color: ${theme.colors.link};
        cursor: pointer;

        &:hover {
          color: ${Color(theme.colors.link).alpha(1).darken(0.3) as any};
        }
      `}

    ${({ $status, theme }) =>
      $status === FileStatusesText.Disabled &&
      css`
        color: ${theme.colors.disabled};
      `}

    ${({ $status, theme }) =>
      $status === FileStatusesText.Deleted &&
      css`
        color: ${theme.colors.disabled};
        text-decoration: line-through;
      `}

    ${({ $status, theme }) =>
      $status === FileStatusesText.Error &&
      css`
        color: ${theme.colors.error};
      `}
`;

const StyledIconContainer = styled.div`
  width: 20px;
`;

export const filesIcons: any = {
  png: <BsFiletypePng fontSize={15} />,
  jpg: <BsFiletypeJpg fontSize={15} />,
  jpeg: <BsFiletypeJpg fontSize={15} />,
  txt: <BsFiletypeTxt fontSize={15} />,
  pdf: <BsFiletypePdf fontSize={15} />,
  doc: <BsFiletypeDoc fontSize={15} />,
  docx: <BsFiletypeDoc fontSize={15} />,
  xls: <BsFiletypeXls fontSize={15} />,
  xlsx: <BsFiletypeXls fontSize={15} />,
};

export function FileGroupListItem({
  file,
  handleDeleteFiles,
  disabled,
}: FileGroupListProps): React.JSX.Element {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [_, fileType] = file?.file_original_name?.split('.');

  const handlePreview = useCallback(() => {
    window.open(file?.file_url, '_blank');
  }, [file?.file_url]);

  const deleteFiles = useCallback(async () => {
    setLoading(true);
    await handleDeleteFiles(file);
    setLoading(false);
  }, [file, handleDeleteFiles]);

  return (
    <StyledContainer $status={file?.file_status_text}>
      <StyledTitleContainer
        $status={file?.file_status_text}
        onClick={
          file?.file_status_text === FileStatusesText.Enabled
            ? handlePreview
            : undefined
        }>
        <StyledIconContainer>
          {filesIcons[fileType] || <BsFileEarmark fontSize={15} />}
        </StyledIconContainer>
        <StyledDescription fontSize={15}>
          {file?.file_original_name}
        </StyledDescription>
      </StyledTitleContainer>
      {file?.file_status_text === FileStatusesText.Enabled ? (
        <DeleteButton
          disabled={disabled}
          tooltipTitle={t('Delete file')}
          confirmTitle={
            <TextFormat breakWord>
              {t(`Are you sure you want to delete `)}
              <b>{t('file').toLowerCase()}</b>
            </TextFormat>
          }
          onConfirm={deleteFiles}
          loading={loading}
          cancelButtonText={t('Cancel')}
        />
      ) : null}
    </StyledContainer>
  );
}
