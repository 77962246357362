import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import notification from 'antd/es/notification';

import './DropdownAlertManager.less';

export interface IDropdownAlertManagerProps {
  children: React.ReactNode;
}

export type DropdownAlertType = 'success' | 'error' | 'info' | 'warn';

export interface IDropdownAlertManagerContext {
  alert: (type: DropdownAlertType, title: string, message: string) => void;
  close: (action?: any, onDone?: () => void) => void;
  setDropdownOptions: (value: any & { init?: boolean }) => void;
  options: any;
}

export const DropdownAlertManagerContext =
  React.createContext<IDropdownAlertManagerContext>({
    alert: () => {},
    close: () => {},
    setDropdownOptions: () => {},
    options: {},
  });

export const useDropdownAlert = () =>
  React.useContext(DropdownAlertManagerContext);

export function DropdownAlertManager({
  children,
}: IDropdownAlertManagerProps): JSX.Element {
  const [api, contextHolder] = notification.useNotification();
  const [options, setDropdownOptions] = React.useState<
    any & { init?: boolean }
  >({});
  const [init, setInit] = useState(false);
  const [queue, setQueue] = useState<any[]>([]);

  const alert = useCallback(
    (type: DropdownAlertType, title: string, message: string) => {
      try {
        if (message.toLowerCase().includes('cancel call promise')) {
          return;
        }

        const { init: optionInit, ...rest } = options;

        if (init) {
          const config: any = {
            message: title,
            description: message,
            className: 'auth-dropdown-alert',
            placement: 'top',
            style: {
              width: '100%',
            },
            ...rest,
          };

          if (type === 'success') {
            api.success({
              ...config,
              duration: 1,
              className: 'auth-dropdown-alert auth-dropdown-alert--success',
            });
          }

          if (type === 'info') {
            api.info({
              ...config,
              duration: 1,
              className: 'auth-dropdown-alert auth-dropdown-alert--info',
            });
          }

          if (type === 'error') {
            api.error({
              ...config,
              className: 'auth-dropdown-alert auth-dropdown-alert--error',
            });
          }

          if (type === 'warn') {
            api.warning({
              ...config,
              duration: 1,
              className: 'auth-dropdown-alert auth-dropdown-alert--warning',
            });
          }
        } else {
          setQueue((prevState) => [...prevState, { type, title, message }]);
        }
      } catch (e) {}
    },
    [api, init, options],
  );

  const close = useCallback((action?: any, onDone?: () => void) => {
    // dropdownRef?.current?.closeAction(action, onDone);
  }, []);

  const handleSetOptions = React.useCallback(
    (value: any & { init?: boolean }) => {
      setDropdownOptions(value);
    },
    [],
  );

  useEffect(() => {
    if (queue.length && init) {
      setQueue((prevState) => {
        if (prevState.length) {
          prevState.forEach(({ type, title, message }) => {
            setTimeout(() => alert(type, title, message), 0);
          });

          return [];
        }
        return prevState;
      });
    }
  }, [alert, init, queue]);

  useEffect(() => {
    if (options?.init && !init) {
      setInit(true);
    }
  }, [init, options]);

  const value = React.useMemo(
    () => ({
      alert,
      close,
      setDropdownOptions: handleSetOptions,
      options,
    }),
    [alert, close, handleSetOptions, options],
  );

  return (
    <DropdownAlertManagerContext.Provider value={value}>
      {contextHolder}
      {children}
    </DropdownAlertManagerContext.Provider>
  );
}
