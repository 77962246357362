import React, { useCallback } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateProductStoreDocumentList,
  useStopLoading,
  useStoredCompanies,
} from '@hooks';
import { head, textToUpperCase } from '@services/helpers';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { ClientModel, StoreDocumentModel, StoreDocumentType } from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { useAcl } from '@contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
  TableIndexField,
} from '@components/lib/DataDisplay';
import { useNavigate, useParams } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import styled, { useTheme } from 'styled-components';
import {
  StoreDetailsView,
  StoreDocumentWithCreditOnlySwitch,
} from '../../Stores';
import { StyledDetailsView } from '@components/lib/Styled/DetailsView';
import {
  ProductStoreDocumentPriceView,
  ProductStoreDocumentStateView,
} from '../Show';
import { StorePostingDetailsView } from '../../Stores/Posting/Show/StorePostingDetailsView';
import { StoreMovingDetailsView } from '../../Stores/Moving/Show/StoreMovingDetailsView';
import { StorePreorederDetailsView } from '../../Stores/Preorder/Show/StorePreorederDetailsView';
import { StoreRealizationDetailsView } from '../../Stores/Realization/Show/StoreRealizationDetailsView';
import { StoreReturnClientDetailsView } from '../../Stores/ReturnClients/Show/StoreReturnClientDetailsView';
import { StoreReturnDetailsView } from '../../Stores/Return/Show/StoreReturnDetailsView';
import { StoreWriteOffDetailsView } from '../../Stores/WriteOff/Show/StoreWriteOffDetailsView';
import { SessionStorageItems } from '@services/const';

import '../../Orders/List/OrderList.less';
import { StyledDescription } from '@components/lib/Styled';

const StyledListActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SegmentedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;

  width: 100%;
`;

const StyledStoreMovingStoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const StyledStoreMovingTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentTypeStrategyView = {
  [StoreDocumentType.IN]: StorePostingDetailsView,
  [StoreDocumentType.OUT]: StoreRealizationDetailsView,
  [StoreDocumentType.MOVE]: StoreMovingDetailsView,
  [StoreDocumentType.ERASE]: StoreWriteOffDetailsView,
  [StoreDocumentType.CRETURN]: StoreReturnClientDetailsView,
  [StoreDocumentType.RETURN]: StoreReturnDetailsView,
  [StoreDocumentType.PREORDER]: StorePreorederDetailsView,
  [StoreDocumentType.SALE]: null,
};

export const documentTypeStrategyUrl = {
  [StoreDocumentType.IN]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storePosting}/${documentUuid}`,
  [StoreDocumentType.OUT]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeRealization}/${documentUuid}`,
  [StoreDocumentType.MOVE]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeMoving}/${documentUuid}`,
  [StoreDocumentType.ERASE]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeWriteOff}/${documentUuid}`,
  [StoreDocumentType.CRETURN]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeReturnClient}/${documentUuid}`,
  [StoreDocumentType.RETURN]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeReturn}/${documentUuid}`,
  [StoreDocumentType.PREORDER]: (productId: string, documentUuid: string) =>
    `/${Routes.app}/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}/${Routes.stores}/${Routes.storeOrderProvider}/${documentUuid}`,
  [StoreDocumentType.SALE]: () => '',
};

export const productStoreDocumentRoutes = (
  productId: string,
  route: (startUrl: string) => any,
  stats?: any,
): any => [
  {
    path: `/${Routes.goods}/${Routes.products}`,
    breadcrumbName: 'Goods and materials values',
  },
  {
    path: `/${Routes.goods}/${Routes.products}/${productId}`,
    breadcrumbName: stats?.product?.product_title || 'Show commodity value',
  },
  {
    path: `/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}`,
    breadcrumbName: 'Movement of goods',
  },
  route(
    `/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}`,
  ),
];

export function ProductStoreDocumentListPage(): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompany } = useStoredCompanies();
  const { manage } = useAcl(({ store }) => store);
  const { t } = useTranslation();
  const { productId } = useParams();
  const theme: any = useTheme();

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'product-document-create-event',
    disabled: !manage,
  });

  const showWithCreditsOnly =
    sessionStorage.getItem(
      SessionStorageItems.productStoreDocumentShowWithCreditsOnly,
    ) || 'false';

  const {
    documentList,
    limit,
    total,
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    keywords,
    stats,
    page,
    doc_type,

    handleSearchProductStoreDocuments,
  } = useStateProductStoreDocumentList({
    productUuid: productId!,
    show_with_credits_only: JSON.parse(showWithCreditsOnly),
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    message: 'An error occurred during movement of goods loading',
  });

  const { inputRef } = useSearchInputFocus({ loading });

  const handleChangeWithCreditOnly = useCallback(
    async (show_with_credits_only: boolean) => {
      sessionStorage.setItem(
        SessionStorageItems.productStoreDocumentShowWithCreditsOnly,
        `${show_with_credits_only}`,
      );

      await handleSearchProductStoreDocuments({
        keywords,
        show_with_credits_only,
      });
    },
    [handleSearchProductStoreDocuments, keywords],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.products}`,
      breadcrumbName: 'Goods and materials values',
    },
    {
      path: `/${Routes.goods}/${Routes.products}/${productId}`,
      breadcrumbName: stats?.product?.product_title || 'Show commodity value',
    },
    {
      path: `/${Routes.goods}/${Routes.products}/${productId}/${Routes.movementGoods}`,
      breadcrumbName: 'Movement of goods',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Documents'),
      key: 'documents',
      render: (document: StoreDocumentModel) => {
        const DocumentView = DocumentTypeStrategyView[document.doc_type];

        return DocumentView ? (
          <DocumentView
            showTag
            showTotal
            to={documentTypeStrategyUrl[document.doc_type](
              productId!,
              document?.uuid,
            )}
            document={document}
          />
        ) : null;
      },
    },
    {
      ellipsis: true,
      title: t('tAmount'),
      key: 'amount',
      align: 'right' as any,
      render: (document: StoreDocumentModel) =>
        head<any>((document?.doc_items as any) || [])?.item_product_amount,
    },
    {
      ellipsis: true,
      title: t('Store'),
      key: 'store',
      render: (document: StoreDocumentModel) => {
        return document?.doc_type === StoreDocumentType.MOVE ? (
          <StyledStoreMovingStoreContainer>
            <StyledStoreMovingTitleContainer>
              <StyledDescription>{`${t('From store')}:`}</StyledDescription>
              <StoreDetailsView
                withoutGap
                store={document?.companyStoreModel}
              />
            </StyledStoreMovingTitleContainer>
            <StyledStoreMovingTitleContainer>
              <StyledDescription>{`${t('In store')}:`}</StyledDescription>
              <StoreDetailsView
                withoutGap
                store={document?.companyDestinationStoreModel}
              />
            </StyledStoreMovingTitleContainer>
          </StyledStoreMovingStoreContainer>
        ) : (
          <StoreDetailsView store={document?.companyStoreModel} />
        );
      },
    },
    {
      ellipsis: true,
      title: t('Counterparty'),
      key: 'provider',
      render: (document: StoreDocumentModel) => (
        <DetailsItemView<ClientModel>
          item={document?.clientModel}
          fields={{
            fullNameClient: {
              link: 'Go to details page',
              path: `/${Routes.app}/${Routes.clients}/${document?.clientModel?.uuid}`,
            },
            phone: { description: '' },
          }}
        />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      render: (document: StoreDocumentModel) => (
        <ProductStoreDocumentPriceView document={document} />
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.goods}/${Routes.products}`);
  }, [navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.movementGoods]))}
      routes={routes}
      empty={
        documentList?.size ? null : (
          <Empty description={t('There are no movement of goods')} />
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                !doc_type,
                doc_type === StoreDocumentType.IN,
                doc_type === StoreDocumentType.OUT,
              ]}>
              <ProductStoreDocumentStateView
                type={undefined}
                color={theme.tabs.link}
                title="All"
                stats={stats}
                onClick={() =>
                  handleSearchProductStoreDocuments({
                    keywords,
                    doc_type: null as any,
                  })
                }
                disabled={loading}
                total={total}
              />
              <ProductStoreDocumentStateView
                color={theme.tabs.success}
                type={StoreDocumentType.IN}
                title={t('Sold out')}
                stats={stats}
                onClick={(doc_type) =>
                  handleSearchProductStoreDocuments({ keywords, doc_type })
                }
                disabled={loading}
              />
              <ProductStoreDocumentStateView
                color={theme.tabs.beige}
                type={StoreDocumentType.OUT}
                title={t('Implemented')}
                stats={stats}
                onClick={(doc_type) =>
                  handleSearchProductStoreDocuments({ keywords, doc_type })
                }
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>
          <ListActions
            withoutPicker
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={(keywords) =>
              handleSearchProductStoreDocuments({ keywords })
            }
            inputTooltip={t('Search movement of goods')}
            inputLabel={t('Search movement of goods')}
            withSearchEndContent={
              <StoreDocumentWithCreditOnlySwitch
                showWithCreditsOnly={JSON.parse(showWithCreditsOnly)}
                loading={loading}
                handleChangeWithCreditOnly={handleChangeWithCreditOnly}
              />
            }
          />
        </StyledListActionsContainer>
      }
      loading={loading && !documentList?.size}>
      <Table<StoreDocumentModel>
        pageSize={limit}
        total={total}
        onChange={handleSearchProductStoreDocuments}
        dataSource={documentList}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
