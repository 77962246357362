import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { DefaultDetailsHeader, IDetailsHeaderProps } from '../General';
import { useNavigate } from 'react-router';

export interface IPageHeaderProps
  extends Pick<IDetailsHeaderProps, 'routes' | 'extra' | 'onBack'> {
  title?: string | React.ReactNode;
}

export default function PageHeader({
  routes,
  extra,
  title = 'No title',
  onBack,
}: IPageHeaderProps): JSX.Element {
  const navigate = useNavigate();

  const handleGoBack = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  return (
    <Row gutter={24} align="middle">
      <Col span={24}>
        <DefaultDetailsHeader
          routes={routes}
          extra={[...(extra as any)]}
          onBack={onBack || handleGoBack}
          title={title}
        />
      </Col>
    </Row>
  );
}
