import querystring from 'query-string';
import { request, ErrorsStatus } from './request';
import { ILiteralObj } from '../types';
import { reduce, notEmptyFields } from '../helpers';

export interface Options {
  ignoreRedirect?: boolean | ErrorsStatus[];
}

export interface ApiOptions extends ILiteralObj, Options {}

export async function apiDelete<T, U>(
  url: string,
  data?: T,
  options: ApiOptions = {},
): Promise<U> {
  return await request<T, U>(url, data, 'DELETE', getIgnoreRedirect(options));
}

export async function apiGet<T, U>(
  url: string,
  data?: T,
  options: ApiOptions = {},
  headers: ILiteralObj = {},
): Promise<U> {
  const params = data && Object.entries(data);
  let urlParams = '';

  if (params && params.length > 0) {
    const correctUtlParams = reduce(
      (acc: any, [key, value]: any[]) => {
        acc = value !== '' ? { ...acc, [key]: value } : acc;

        return acc;
      },
      {},
      Object.entries(notEmptyFields(data)),
    );

    urlParams =
      (url.indexOf('?') >= 0 ? '&' : '?') +
      querystring.stringify({ ...(correctUtlParams as any) });
  }

  return await request<T, U>(
    `${url}${urlParams}`,
    data,
    'GET',
    getIgnoreRedirect(options),
    headers,
  );
}

export async function apiPost<T, U>(
  url: string,
  data: T,
  options: ApiOptions = {},
  headers: ILiteralObj = {},
  isMultipart: boolean = false,
): Promise<U> {
  return await request(
    url,
    notEmptyFields(data),
    'POST',
    getIgnoreRedirect(options),
    headers,
    isMultipart,
  );
}

export async function apiPatch<T, U>(
  url: string,
  data: T,
  options: ApiOptions = {},
  headers: ILiteralObj = {},
  isMultipart: boolean = false,
): Promise<U> {
  return await request(
    url,
    notEmptyFields(data),
    'PATCH',
    getIgnoreRedirect(options),
    headers,
    isMultipart,
  );
}

export async function apiPut<T, U>(
  url: string,
  data: T,
  options: ApiOptions = {},
): Promise<U> {
  return await request(
    url,
    notEmptyFields(data),
    'PUT',
    getIgnoreRedirect(options),
  );
}

function getIgnoreRedirect({
  ignoreRedirect,
}: ApiOptions): boolean | ErrorsStatus[] {
  return Array.isArray(ignoreRedirect)
    ? ignoreRedirect
    : ignoreRedirect === true;
}
