import React from 'react';
import { DefaultForm, IDefaultFormProps } from '../General';
import WizardValidate, { IWizardValidateProps } from './WizardValidate';

export interface IWizardFormV2Props<T, U>
  extends Omit<IWizardValidateProps, 'form' | 'onSubmit'>,
    Omit<
      IDefaultFormProps<T, U>,
      'onCancel' | 'cancelButtonProps' | 'children' | 'onSubmit'
    > {}

WizardFormV2.Step = WizardValidate.Step;

export default function WizardFormV2<T, U>({
  editMode,
  initialValues = {} as any,
  onCancel,
  onSuccess,
  onValuesChange,
  children,
  showFooter = true,
  showNotify = true,
  className,
  additionalValuesRequest = {},
  isResetLoading = false,
  notifyFormName,
  id,
  notifyError,
  instance,
  ...rest
}: IWizardFormV2Props<T, U>) {
  return (
    <DefaultForm<T, U>
      notifyFormName={notifyFormName}
      withContext
      initialValues={initialValues}
      additionalValuesRequest={additionalValuesRequest}
      showCancelButton={false}
      onSuccess={onSuccess}
      className={className}
      showFooter={false}
      id={id}
      showNotify={showNotify}
      notifyError={notifyError}
      onValuesChange={onValuesChange}
      instance={instance}>
      <WizardValidate onCancel={onCancel} {...rest}>
        {children}
      </WizardValidate>
    </DefaultForm>
  );
}
