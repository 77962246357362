import React from 'react';
import { StoreDocumentModel, StoreDocumentStatus } from '@structure';
import styled, { useTheme } from 'styled-components';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import { toDateByFormat } from '@services/helpers';

export interface StoreRealizationInternalNumberFieldProps {
  document: StoreDocumentModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
`;

const StyledClockCircleOutlined = styled(ClockCircleOutlined)<{
  $color: string;
}>`
  color: ${({ theme, $color }) => $color || theme.colors.primary};
  font-size: 12px;
`;

export const SALE_REALIZATION_ROUTES_TITLE = {
  [StoreDocumentStatus.DRAFT]: 'Draft',
  [StoreDocumentStatus.ACTIVE]: 'Conducted',
  [StoreDocumentStatus.CLOSED]: 'Closed',
  [StoreDocumentStatus.UNKNOWN]: 'Unknown',
  [StoreDocumentStatus.ERROR]: 'Error',
  [StoreDocumentStatus.COMPLETED]: 'Completed',
  [StoreDocumentStatus.COMMITTED]: 'Committed',
};

export function StoreRealizationInternalNumberField({
  document,
}: StoreRealizationInternalNumberFieldProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const colors = {
    [StoreDocumentStatus.DRAFT]: theme.colors.warning,
    [StoreDocumentStatus.ACTIVE]: theme.colors.success,
    [StoreDocumentStatus.CLOSED]: theme.colors.primary,
    [StoreDocumentStatus.UNKNOWN]: theme.colors.primary,
    [StoreDocumentStatus.ERROR]: theme.colors.error,
    [StoreDocumentStatus.COMPLETED]: theme.colors.link,
    [StoreDocumentStatus.COMMITTED]: theme.colors.success,
  };

  return (
    <StyledContainer>
      <StyledTag color={colors[document?.doc_status_text]}>
        {t(SALE_REALIZATION_ROUTES_TITLE[document?.doc_status_text])}
      </StyledTag>
      <StyledTitle
        $color={
          document?.doc_status_text === StoreDocumentStatus.DRAFT
            ? theme.colors.warning
            : undefined
        }>
        {`№${document?.doc_local_number}`}
      </StyledTitle>
      <StyledIconContainer>
        <StyledClockCircleOutlined
          $color={
            document?.doc_status_text === StoreDocumentStatus.DRAFT
              ? theme.colors.warning
              : undefined
          }
        />
        <StyledTitle
          $color={
            document?.doc_status_text === StoreDocumentStatus.DRAFT
              ? theme.colors.warning
              : undefined
          }>
          {toDateByFormat(document?.doc_date, 'HH:mm')}
        </StyledTitle>
      </StyledIconContainer>
    </StyledContainer>
  );
}
