import { SIGNUP_PAGE_STATE } from '../constants';
import { ISignupPageState, signupPageInitialState } from '../actions';

interface SetAction {
  type: SIGNUP_PAGE_STATE.SET_STATE;
  state: ISignupPageState;
}

interface UpdateService {
  type: SIGNUP_PAGE_STATE.UPDATE_STATE;
  state: Partial<ISignupPageState>;
}

interface ResetAction {
  type: SIGNUP_PAGE_STATE.RESET_STATE;
  state: ISignupPageState;
}

interface Handlers {
  [SIGNUP_PAGE_STATE.SET_STATE]: (
    state: ISignupPageState,
    action: SetAction,
  ) => ISignupPageState;

  [SIGNUP_PAGE_STATE.RESET_STATE]: (
    state: ISignupPageState,
    action: ResetAction,
  ) => ISignupPageState;

  [SIGNUP_PAGE_STATE.UPDATE_STATE]: (
    state: ISignupPageState,
    action: UpdateService,
  ) => ISignupPageState;

  DEFAULT: (state: ISignupPageState) => ISignupPageState;
}

const initState: ISignupPageState = signupPageInitialState;

const handlers: Handlers = {
  [SIGNUP_PAGE_STATE.SET_STATE]: (state, { state: value }) => ({
    ...state,
    ...value,
    loading: false,
  }),

  [SIGNUP_PAGE_STATE.RESET_STATE]: (state, { state: value }) => ({
    ...state,
    ...value,
  }),

  [SIGNUP_PAGE_STATE.UPDATE_STATE]: (state, { state: value }) => {
    return {
      ...state,
      ...value,
    };
  },

  DEFAULT: (state: ISignupPageState) => state,
};

export default function Service(
  state: any = initState,
  action: any,
): ISignupPageState {
  const handler =
    handlers[action.type as SIGNUP_PAGE_STATE] || handlers.DEFAULT;
  return handler(state, action);
}
