import { ProductModel, ProductMapper, ProductDTO } from '../internal';

export interface IProductStoreDocumentStatsDTOProps {
  sum_in_total: number;
  amount_in_total: number;
  sum_out_total: number;
  amount_out_total: number;
  product: ProductModel;
}

export class ProductStoreDocumentStatsDTO {
  public sum_in_total: number;
  public amount_in_total: number;
  public sum_out_total: number;
  public amount_out_total: number;
  public product: ProductModel;

  constructor(props: IProductStoreDocumentStatsDTOProps) {
    this.sum_in_total = props?.sum_in_total || 0;
    this.amount_in_total = props?.amount_in_total || 0;
    this.sum_out_total = props?.sum_out_total || 0;
    this.amount_out_total = props?.amount_out_total || 0;
    this.product =
      props?.product || ProductMapper.toProductModel({} as ProductDTO);
  }
}
