import * as React from 'react';
import { List } from 'immutable';
import {
  IUseClientBalanceOperationListProps,
  IUseClientBalanceOperationListReturnType,
  useClientBalanceOperationList,
} from './useClientBalanceOperationList';
import {
  ClientMapper,
  ClientModel,
  ClientOperationFormDTO,
  ClientOperationModel,
  IClientOperationListStats,
} from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import { clientAdjustmentOfBalances } from '@services/api/client';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { useEffect, useState } from 'react';
import { correctPrice } from '@sportix/sportix-common-modules';

export interface IUseStateClientBalanceOperationListProps
  extends IUseClientBalanceOperationListProps {}

export interface IClientBalanceOperationSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  clientUuid?: string;
}

export interface IUseStateClientBalanceOperationListReturnType
  extends Omit<IUseClientBalanceOperationListReturnType, 'entityList'> {
  operations: List<ClientOperationModel> | null;
  handleAdjustmentBalance: (value: ClientOperationFormDTO) => Promise<void>;
  handleSearchOperations: (
    value: IClientBalanceOperationSearchProps,
  ) => Promise<List<ClientOperationModel> | void>;
  client: ClientModel | null;
  stats: IClientOperationListStats | null;
}

export function useStateClientBalanceOperationList(
  {
    clientUuid,
    ...rest
  }: IUseStateClientBalanceOperationListProps = {} as IUseStateClientBalanceOperationListProps,
): IUseStateClientBalanceOperationListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [client, setClient] = useState<ClientModel | null>(null);
  const [stats, setStats] = useState<IClientOperationListStats | null>(null);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total: clientListTotal,
    keywords,
    ...operationsParams
  } = useClientBalanceOperationList({
    clientUuid,
    ...rest,
  });

  const {
    entityList: operations,
    loading,
    setEntityList,
    total,
  } = useStateEntityList<ClientOperationModel>({
    entityList: entityList?.operations!,
    refresh,
    limit,
    offset,
    total: clientListTotal,
  });

  const handleAdjustmentBalance = React.useCallback(
    async (value: ClientOperationFormDTO) => {
      const clientDTO = await clientAdjustmentOfBalances(clientUuid, value);

      const clientModel = ClientMapper.toClientModel(clientDTO);
      await refresh({ offset: 0, limit: 10, showLoading: false });

      const prevSaldo = Number(client?.saldo || 0);
      const saldo = Number(clientModel?.saldo || 0);

      setClient(clientModel);

      setStats((prevState) => {
        const total = Number(prevState?.total || 0);

        return {
          total: correctPrice(total - prevSaldo + saldo || 0),
        };
      });

      alert(
        'success',
        t('Client balance'),
        t('Client balance adjustment success'),
      );
    },
    [alert, client?.saldo, clientUuid, refresh, t],
  );

  const handleSearchOperations = React.useCallback(
    async ({
      keywords,
      limit = 10,
      showLoading = true,
      ...rest
    }: IClientBalanceOperationSearchProps) => {
      const operationList = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        clientUuid,
        ...rest,
      });

      if (operations && operationList?.operations) {
        setEntityList(operationList?.operations);
        return operations;
      }
    },
    [clientUuid, operations, refresh, setEntityList],
  );

  useEffect(() => {
    if (entityList?.client) {
      setClient(entityList?.client);
    }
  }, [entityList?.client]);

  useEffect(() => {
    if (entityList?.stats && !stats) {
      setStats(entityList?.stats);
    }
  }, [entityList?.stats, stats]);

  return {
    operations,
    offset,
    limit,
    refresh,
    total,
    loading: clientLoading || loading,
    ...operationsParams,
    handleAdjustmentBalance,
    handleSearchOperations,
    keywords,
    client,
    stats,
  };
}
