import { useNavigate } from 'react-router';
import { SettingsMenu } from './SettingsMenu';
import styled from 'styled-components';
import React, { Suspense, useCallback, useMemo } from 'react';
import { SettingsDropdown } from './SettingsDropdown';
import { MenuItem } from '@components/lib/Layout';
import { useModal } from '@components/lib/libV2/hooks';
import { useSettings, useStoredCompanies } from '@hooks';
import { SuspenseEmpty } from '@components/lib/DataDisplay';
import {
  ItemSideWindow,
  MenuOpenProvider,
  SETTINGS_MENU_ITEMS,
} from '../const';

const PaymentsSideWindowLazy = React.lazy(
  () => import('../../Parameters/Show/PaymentsSideWindow'),
);

const LocalizationSideWindowLazy = React.lazy(
  () => import('../../Parameters/Show/LocalizationSideWindow'),
);

const CompanyOrderBonusSideWindowLazy = React.lazy(
  () => import('../../Settings/Show/CompanyOrderBonusSideWindow'),
);

export enum SettingsMenuRendererType {
  MENU = 'menu',
  DROPDOWN = 'dropdown',
}

export const ItemSideWindowAccess = [
  ItemSideWindow.Localization,
  ItemSideWindow.Payments,
  ItemSideWindow.CompanyOrderBonuses,
];

export interface ISettingsMenuRendererProps {
  menuType: SettingsMenuRendererType;
}

const StyledMenuRendererContainer = styled.div`
  display: block;
`;

export function SettingsMenuRenderer({
  menuType,
}: ISettingsMenuRendererProps): React.JSX.Element {
  const navigate = useNavigate();
  const { settings, defaultCompanyUuid, defaultCompanyLoading } =
    useStoredCompanies();

  const { handleUpdateFinancialReliability, loading } = useSettings({
    companyUuid: defaultCompanyUuid,
  });

  const {
    handleCancel: handleCancelPayments,
    handleOnInit: handleOnInitPayments,
    handleSuccess: handleSuccessPayments,
    visible: visiblePayments,
  } = useModal({
    onSuccess: handleUpdateFinancialReliability,
  });

  const {
    handleCancel: handleCancelLocalization,
    handleOnInit: handleOnInitLocalization,
    handleSuccess: handleSuccessLocalization,
    visible: visibleLocalization,
  } = useModal({
    onSuccess: handleUpdateFinancialReliability,
  });

  const {
    handleCancel: handleCancelCompanyOrderBonuses,
    handleOnInit: handleOnInitCompanyOrderBonuses,
    handleSuccess: handleSuccessCompanyOrderBonuses,
    visible: visibleCompanyOrderBonuses,
  } = useModal({
    onSuccess: handleUpdateFinancialReliability,
  });

  const sideActions = useMemo(
    () => ({
      [ItemSideWindow.Localization]: handleOnInitLocalization,
      [ItemSideWindow.Payments]: handleOnInitPayments,
      [ItemSideWindow.CompanyOrderBonuses]: handleOnInitCompanyOrderBonuses,
    }),
    [
      handleOnInitPayments,
      handleOnInitLocalization,
      handleOnInitCompanyOrderBonuses,
    ],
  );

  const onMenuItemClickHandler = useCallback(
    (item: MenuItem) => {
      if (item?.openWith === MenuOpenProvider.LINK) {
        return navigate(item.link || item.key);
      }

      if (
        item?.openWith === MenuOpenProvider.MODAL &&
        ItemSideWindowAccess.includes(item.key as ItemSideWindow) &&
        typeof sideActions[item.key as ItemSideWindow] === 'function'
      ) {
        return sideActions[item.key as ItemSideWindow]();
      }

      console.error('No handler defined for such kind of event.');
    },
    [sideActions, navigate],
  );

  return (
    <StyledMenuRendererContainer>
      {menuType === SettingsMenuRendererType.MENU ? (
        <SettingsMenu
          loading={loading}
          collapsed={false}
          onClick={onMenuItemClickHandler}
        />
      ) : (
        <SettingsDropdown
          loading={loading}
          items={SETTINGS_MENU_ITEMS}
          onClick={onMenuItemClickHandler}
        />
      )}
      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentsSideWindowLazy
          visible={visiblePayments}
          payments={settings?.settings}
          onSuccess={handleSuccessPayments}
          loading={loading}
          onCancel={handleCancelPayments}
        />
      </Suspense>
      <Suspense fallback={<SuspenseEmpty />}>
        <LocalizationSideWindowLazy
          visible={visibleLocalization}
          localization={settings?.settings}
          onSuccess={handleSuccessLocalization}
          loading={loading}
          onCancel={handleCancelLocalization}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanyOrderBonusSideWindowLazy
          visible={visibleCompanyOrderBonuses}
          settings={settings?.settings!}
          onSuccess={handleSuccessCompanyOrderBonuses}
          loading={loading}
          onCancel={handleCancelCompanyOrderBonuses}
        />
      </Suspense>
    </StyledMenuRendererContainer>
  );
}
