import {
  useClientOrderList,
  IUseClientOrderListProps,
  IUseClientOrderListReturnType,
} from './useClientOrderList';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import { ScheduleCalendarModel } from '@structure';
import useScheduleActions, {
  IUseScheduleActionsReturnType,
} from './useScheduleActions';
import { List } from 'immutable';
import * as React from 'react';
import { Moment } from 'moment/moment';
import { dateToIsoString, debounce, head, last } from '@services/helpers';
import { IUseCompanyScheduleListProps } from './useCompanyScheduleList';
import { useCallback } from 'react';

export interface IUseStateClientOrderListListProps
  extends Omit<Partial<IUseClientOrderListProps>, 'clientUuid'> {
  clientUuid: string;
}

export interface IUseStateClientOrderListListReturnType
  extends Omit<IUseClientOrderListReturnType, 'entityList'>,
    Pick<IUseScheduleActionsReturnType, 'handleCreateSchedule'> {
  schedules: List<ScheduleCalendarModel> | null;
  handlePickScheduleRange: (value: [Moment, Moment]) => Promise<void>;
  handleSearchSchedule: (
    value: Partial<IUseCompanyScheduleListProps>,
  ) => Promise<void>;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

export function useStateClientOrderList(
  {
    ...scheduleListProps
  }: IUseStateClientOrderListListProps = {} as IUseStateClientOrderListListProps,
): IUseStateClientOrderListListReturnType {
  const {
    entityList: schedules,
    loading,
    refresh,
    total: scheduleTotal,
    limit,
    offset,
    ...rest
  } = useClientOrderList({
    ...scheduleListProps,
  });

  const {
    entityList: stateClientOrders,
    loading: stateLoading,
    handleUpdate,
    handleCreate,
    setEntityList,
    total,
  } = useStateEntityList<ScheduleCalendarModel>({
    entityList: schedules,
    total: scheduleTotal,
    limit,
    offset,
  });

  const { handleCreateSchedule } = useScheduleActions({
    handleUpdate,
    handleCreate,
  });

  const handlePickScheduleRange = React.useCallback(
    async (value: [Moment, Moment] | null): Promise<void> => {
      const schedules = await refresh({
        isRangeChanged: true,
        isClearDate: !value,
        end: value ? dateToIsoString(last(value)) : '',
        start: value ? dateToIsoString(head(value)) : '',
      });

      if (List.isList(schedules)) {
        setEntityList(schedules);
      }
    },
    [refresh, setEntityList],
  );

  const handleSearchSchedule = React.useCallback(
    async ({
      keywords,
      limit = 10,
      showLoading = true,
      ...rest
    }: Partial<IUseCompanyScheduleListProps>): Promise<any> => {
      const schedules = await refresh({
        keywords,
        limit,
        showLoading,
        ...rest,
      });

      if (List.isList(schedules)) {
        setEntityList(schedules as any);
      }
    },
    [refresh, setEntityList],
  );

  const handleUpdateScheduleState = useCallback(
    (schedule: ScheduleCalendarModel) => {
      handleUpdate(schedule);
    },
    [handleUpdate],
  );

  return {
    loading: loading || stateLoading,
    schedules: stateClientOrders,
    refresh,
    handleCreateSchedule,
    handlePickScheduleRange,
    handleSearchSchedule,
    limit,
    total,
    offset,
    ...rest,
    handleUpdateScheduleState,
  };
}
