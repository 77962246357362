import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Form } from 'antd';
import { DAY_OFF_INITIAL_PARAM } from '@services/api/employeeDayOffServices';
import {
  DefaultForm,
  IDefaultFormProps,
  DatePicker,
} from '@components/lib/General';
import { DayOffDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import styled from 'styled-components';

export interface IDayOffFormProps
  extends Omit<
    IDefaultFormProps<any, any>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  employeeUuid: string;
  dayOff?: DayOffDTO;
}

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

/**
 * @desc Створенння форми послуги для співробітника
 * */
export default function DayOffForm({
  loading,
  employeeUuid,
  dayOff = DAY_OFF_INITIAL_PARAM,
  editMode,
  ...rest
}: IDayOffFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: dayOff?.uuid,
      employeeUuid,
    }),
    [dayOff?.uuid, employeeUuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Day off'),
        `${
          editMode
            ? t('An error occurred during edit employee')
            : t('An error occurred during create employee')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm
      initialValues={{ ...dayOff }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                tooltip={t('Weekend start date')}
                label={t('Start date')}
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: t('Start date must be specified'),
                  },
                ]}>
                <StyledDatePicker
                  data-testid="day-off-create-input-start_date"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Start date')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                tooltip={t('Weekend end date')}
                label={t('End date')}
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: t('End date must be specified'),
                  },
                ]}>
                <StyledDatePicker
                  data-testid="day-off-create-input-birthday"
                  disabled={loading || loadingSubmit}
                  placeholder={t('End date')}
                />
              </Form.Item>
            </Col>
          </Row>
          {!editMode ? (
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label={t('Comment')}
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: t('Comment must be specified'),
                    },
                    {
                      max: 100,
                      message: t(
                        'Day off comment must contain a maximum of 100 characters',
                      ),
                    },
                  ]}>
                  <Input
                    data-testid="day-off-input-title"
                    disabled={loading || loadingSubmit}
                    placeholder={t('Enter the day off comment')}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </>
      )}
    </DefaultForm>
  );
}
