import React, { useCallback, useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useStoredPriceMarginList,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceMargin,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '@hooks';
import { textToUpperCase } from '@services/helpers';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { PriceMarginMapper, PriceMarginModel } from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { useAcl } from '@contex';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import { PriceMarginDetailsView } from '../Show';
import '../../Orders/List/OrderList.less';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { successButtonStyle } from '@components/lib/Styled';

import {
  PriceMarginCreateButton,
  PriceMarginDeleteButton,
  PriceMarginUpdateButton,
} from '../Buttons';

const StyledPriceMarginCreateButton = styled(PriceMarginCreateButton)`
  ${successButtonStyle}
`;

export function PriceMarginListPage(): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ priceMargin }) => priceMargin);
  const { t } = useTranslation();

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'price-margin-create-event',
    disabled: !manage,
  });

  const [selectedPriceMargin, setSelectedPriceMargin] = useState<
    PriceMarginModel[]
  >([]);

  const {
    priceMarginList,
    limit,
    total,
    loadingMore,
    refresh: refreshPriceMarginList,
    loading: loadingPriceMarginList,
    error: errorPriceMarginList,
    handleDeletePriceMargins: onDeletePriceMargins,
    handleSearchPriceMargins,
  } = useStoredPriceMarginList({ companyUuid: defaultCompanyUuid });

  const isActiveByDefault = total <= 0;
  const isDisabledDelete = total === 1;

  const { handleUpdatePriceMargin, handleCreatePriceMargin } =
    useStoredPriceMargin({
      loadOnInit: false,
      companyUuid: defaultCompanyUuid,
      priceMarginUuid: '',
    });

  const loading = useStopLoading({
    loading: loadingPriceMarginList || loadingMore,
    error: errorPriceMarginList,
    message: 'An error occurred during price margins loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeletePriceMargins = useCallback(
    async (value: string[]) => {
      await onDeletePriceMargins(value);
      focus();
    },
    [focus, onDeletePriceMargins],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.priceMargins}`,
      breadcrumbName: 'Price margins',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedPriceMargin.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: PriceMarginModel[],
      ) => {
        setSelectedPriceMargin(selectedRecords);
      },
    }),
    [selectedPriceMargin],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      width: '80%',
      className: 'order-list-td',
      render: (priceMargin: PriceMarginModel) => (
        <PriceMarginDetailsView
          priceMargin={
            {
              price_name: priceMargin?.price_name,
              uuid: priceMargin?.uuid,
              price_is_default: priceMargin?.price_is_default,
            } as PriceMarginModel
          }
        />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'price_margin_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (priceMargin: PriceMarginModel) => {
        return (
          <TableActionCell>
            <PriceMarginUpdateButton
              isActiveByDefault={isActiveByDefault || isDisabledDelete}
              disabled={!manage}
              priceMargin={PriceMarginMapper.toPriceMarginFormDTO(priceMargin)}
              onSuccess={handleUpdatePriceMargin}
              onCancel={daleyFocus}
            />
            <PriceMarginDeleteButton
              isActiveByDefault={isDisabledDelete}
              disabled={!manage}
              priceMargins={[priceMargin]}
              onSuccess={handleDeletePriceMargins}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.priceMargins]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.priceMargins]))}
      headerRight={
        <>
          {selectedPriceMargin?.length ? (
            <PriceMarginDeleteButton
              isActiveByDefault={isActiveByDefault}
              disabled={!manage}
              priceMargins={selectedPriceMargin}
              onSuccess={handleDeletePriceMargins}
            />
          ) : (
            <StyledPriceMarginCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreatePriceMargin}
              title={t('Create')}
              icon={<PlusOutlined />}
              isActiveByDefault={isActiveByDefault}
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        priceMarginList?.size ? null : (
          <Empty description={t('There are no price margins created')}>
            <StyledPriceMarginCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreatePriceMargin}
              title={t('Create')}
              icon={<PlusOutlined />}
              isActiveByDefault={isActiveByDefault}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchPriceMargins({ keywords })}
          inputTooltip={t('Search price margins')}
          inputLabel={t('Search price margins')}
          withSearchContent={null}
        />
      }
      loading={loading && !priceMarginList?.size}>
      <Table<PriceMarginModel>
        pageSize={limit}
        total={total}
        onChange={refreshPriceMarginList}
        rowSelection={rowSelection}
        dataSource={priceMarginList}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
