import { ValidateTypeError } from './ValidateTypeError';

export interface ValidateType {
  value: any;
  type?: any;
  message?: string;
  checkEmpty?: boolean;
  t?: any;
}

export default class Validate {
  static string({
    value,
    message,
    checkEmpty = true,
    t,
  }: ValidateType): Error | void {
    if (typeof value !== 'string') {
      throw new ValidateTypeError(
        `${value} ${t ? t('is not a string') : 'is not a string'}`,
        message,
      );
    }
    if (checkEmpty && value.trim() === '') {
      throw new ValidateTypeError(
        `"${value}" ${t ? t('cannot be empty') : 'cannot be empty'}`,
        message,
      );
    }
  }

  static type({ value, type, message, t }: ValidateType): Error | void {
    if (!(value instanceof type)) {
      throw new ValidateTypeError(
        `${value} ${t ? t('is not of type') : 'is not of type'} ${type}`,
        message,
      );
    }
    if (typeof value.validate === 'function') {
      try {
        value.validate();
      } catch (err: any) {
        throw new ValidateTypeError(err.message, message);
      }
    }
  }

  static number({ value, message = undefined, t }: ValidateType): Error | void {
    const newVal = Number(value);
    if (typeof newVal !== 'number' && !Number.isNaN(newVal)) {
      throw new ValidateTypeError(
        `${value} ${t ? t('is not a number') : 'is not a number'}`,
        message,
      );
    }
  }

  static array({ value, message = undefined, t }: ValidateType): Error | void {
    if (!Array.isArray(value)) {
      throw new ValidateTypeError(
        `${value} ${t ? t('is not an array') : 'is not an array'}`,
        message,
      );
    }
  }

  static arrayOfStrings({
    value,
    message = undefined,
    t,
  }: ValidateType): Error | void {
    Validate.array({
      value,
      message:
        message ||
        `${value} ${
          t ? t('is not an array of strings') : 'is not an array of strings'
        }`,
    });

    value.forEach((value: any): void => {
      Validate.string({ value });
    });
  }

  static arrayOfNumbers({
    value,
    message = undefined,
    t,
  }: ValidateType): Error | void {
    Validate.array({
      value,
      message:
        message ||
        `${value} ${
          t ? t('is not an array of strings') : 'is not an array of strings'
        }`,
    });

    value.forEach((value: any): void => {
      Validate.string({ value });
    });
  }
}
