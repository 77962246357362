import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Select } from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  DatePicker,
} from '@components/lib/General';
import { EmployeeSalaryFormDTO, IEmployeeSalaryAccrualAt } from '@structure';
import { useDropdownAlert } from '@contex';
import { useStoredCompanies } from '@hooks';
import styled, { css } from 'styled-components';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from '@components/lib/DataDisplay';
import { correctPrice, toMoment, toDateByFormat } from '@services/helpers';
import { Moment } from 'moment';

export interface IRewardSalaryFormProps
  extends Omit<
    IDefaultFormProps<EmployeeSalaryFormDTO, EmployeeSalaryFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  salary: EmployeeSalaryFormDTO;
  salarySettingsDates: string[];
}

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

const StyledDatePicker = styled(DatePicker)<{ isCapitalize: boolean }>`
  ${({ isCapitalize }) =>
    isCapitalize &&
    css`
      & input {
        text-transform: capitalize;
      }
    `}
`;

export function RewardSalaryForm({
  loading,
  editMode,
  salary,
  onCancel,
  salarySettingsDates,
  ...rest
}: IRewardSalaryFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompany } = useStoredCompanies();
  const [instance] = Form.useForm();
  const [daysOfMonth, setDaysOfMonth] = useState<
    { value: string; label: string }[]
  >([]);

  const accrualAt: IEmployeeSalaryAccrualAt[] = Form.useWatch(
    'accrual_at',
    instance,
  );

  const startDate: Moment = Form.useWatch('start_date', instance);

  const additionalValues = useMemo(
    () => ({
      enabled: salary?.enabled,
    }),
    [salary?.enabled],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Salary'),
        `${
          editMode
            ? t('An error occurred during edit salary')
            : t('An error occurred during create salary')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  useEffect(() => {
    if (startDate) {
      const daysInMonth = toMoment(startDate).daysInMonth();
      const days = [];

      if (daysInMonth !== daysOfMonth.length) {
        for (let i = 1; i <= daysInMonth; i++) {
          if (i === 1) {
            days.push({
              value: `${i}`,
              label: t('The first day of the month'),
            });
            continue;
          }

          if (i === daysInMonth) {
            days.push({
              value: `${i}`,
              label: t('The last day of the month'),
            });
            continue;
          }

          days.push({
            value: `${i}`,
            label: `${i}`,
          });
        }

        setDaysOfMonth(days);
      }
    }
  }, [daysOfMonth.length, startDate, t]);

  useEffect(() => {
    if (Array.isArray(accrualAt) && accrualAt?.length) {
      const sum = accrualAt?.reduce((acc, { sum }) => {
        acc += Number(sum);
        return acc;
      }, 0);

      instance.setFieldsValue({ sum: correctPrice(sum || 0) });
    }
  }, [accrualAt, instance]);

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      additionalValuesRequest={additionalValues}
      initialValues={{
        ...salary,
      }}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <Row gutter={12} justify="end">
          <Col span={24}>
            <Form.Item
              label={t('Calculate from')}
              name="start_date"
              rules={[
                {
                  required: true,
                  message: t('Calculate from must be specified'),
                },
              ]}
              tooltip={t('The month from which this salary starts act')}>
              <StyledDatePicker
                isCapitalize={!!startDate}
                disabledDate={(date) => {
                  const formattedDate = toDateByFormat(date, 'MM.YYYY');

                  return salarySettingsDates.includes(formattedDate);
                }}
                picker="month"
                format={'MMMM YYYY'}
                disabled={loading || loadingSubmit}
                placeholder={t('Select the month of the start of the salary')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.List name="accrual_at">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} align="top" key={field?.key}>
                        <Col span={14}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }>
                            {() => (
                              <Form.Item
                                {...field}
                                required
                                label={t('Calculation day')}
                                tooltip={t(
                                  'The day of the month when the system charges the Employee the established salary',
                                )}
                                name={[field.name, 'day_of_month']}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, day) {
                                      if (day === null || day === undefined) {
                                        return Promise.reject(
                                          new Error(
                                            t(
                                              'Calculation day must be specified.',
                                            ),
                                          ),
                                        );
                                      }

                                      const accrualAt: IEmployeeSalaryAccrualAt[] =
                                        getFieldValue('accrual_at');

                                      let countOfDays = 0;

                                      accrualAt.forEach(({ day_of_month }) => {
                                        if (day_of_month === day) {
                                          countOfDays++;
                                        }
                                      });

                                      if (countOfDays > 1) {
                                        return Promise.reject(
                                          new Error(
                                            t(
                                              "Choose another day, it's already busy",
                                            ),
                                          ),
                                        );
                                      }

                                      if (Number(day) < 1) {
                                        return Promise.reject(
                                          new Error(
                                            t(
                                              'Calculation day must be greater than 0',
                                            ),
                                          ),
                                        );
                                      }

                                      if (Number(day) > 31) {
                                        return Promise.reject(
                                          new Error(
                                            t(
                                              'Calculation day must be less than 30',
                                            ),
                                          ),
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}>
                                <Select
                                  disabled={!startDate || loadingSubmit}
                                  showSearch
                                  placeholder={t('Enter a calculation day')}
                                  options={daysOfMonth}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Sum')}
                            tooltip={t('Sum')}
                            name={[field.name, 'sum']}
                            rules={[
                              () => ({
                                validator(_, price) {
                                  const value = price;

                                  if (Number.isNaN(Number(value))) {
                                    return Promise.reject(
                                      new Error(t('Sum must be a number')),
                                    );
                                  }

                                  if (Number(value) < 0) {
                                    return Promise.reject(
                                      new Error(t('Sum must be bigger than 0')),
                                    );
                                  }

                                  if (!Number(value)) {
                                    return Promise.reject(
                                      new Error(t('Sum must be specified')),
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <Input
                              addonBefore={defaultCompany?.currency_symbol}
                              disabled={loadingSubmit}
                            />
                          </Form.Item>
                        </Col>

                        {accrualAt?.length > 1 ? (
                          <StyledDeleteCol span={2}>
                            <StyledDeleteOutlined
                              onClick={() => remove(field.name)}
                            />
                          </StyledDeleteCol>
                        ) : null}
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({ sum: correctPrice(0), day_of_month: undefined })
                      }
                      block
                      icon={<PlusOutlined />}>
                      {t('Add settlement day')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          <Col span={12}>
            <Form.Item label={t('Total')} name="sum">
              <Input disabled addonBefore={defaultCompany?.currency_symbol} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Note')} name="comment">
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 5 }}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
