import { EmployeeModel } from '../internal';

export class EmployeeInviteFormDTO {
  public uuid: string;
  public link: string;

  constructor(props?: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.link = 'https://servicity/app';
  }
}
