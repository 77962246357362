import * as React from 'react';
import useGoogleCalendarList, {
  IUseGoogleCalendarListProps,
  IUseGoogleCalendarListReturnType,
} from './useGoogleCalendarList';
import useDataState from './useDataState';
import { GoogleCalendar } from '@services/models';
import {
  getGoogleCalendarAuthToken,
  IGoogleCalendarParams,
  registerGoogleCalendar,
} from '@services/api/googleCalendar';

export interface IUseStateGoogleCalendarListProps
  extends Omit<Partial<IUseGoogleCalendarListProps>, 'companyUuid'> {
  companyUuid: string;
}

export interface IUseStateGoogleCalendarListReturnType
  extends Omit<IUseGoogleCalendarListReturnType, 'list'> {
  googleCalendars: GoogleCalendar[];
  handleGetGoogleCalendarAuthToken: () => Promise<void>;
  handleGoogleCalendarRegister: (value: IGoogleCalendarParams) => Promise<void>;
}

export default function useStateGoogleCalendarList(
  {
    loadOnInit = true,
    companyUuid,
    ...googleCalendarListProps
  }: IUseStateGoogleCalendarListProps = {} as IUseStateGoogleCalendarListProps,
): IUseStateGoogleCalendarListReturnType {
  const {
    list: googleCalendars,
    loading,
    refresh,
    ...rest
  } = useGoogleCalendarList({
    offset: 0,
    limit: 10,
    loadOnInit,
    companyUuid,
    ...googleCalendarListProps,
  });

  const {
    data: stateGoogleCalendars,
    loading: stateLoading,
    handleCreate,
  } = useDataState<GoogleCalendar>(googleCalendars, null);

  const handleGetGoogleCalendarAuthToken =
    React.useCallback(async (): Promise<void> => {
      const auth_url = await getGoogleCalendarAuthToken();

      window.open(auth_url);
    }, []);

  const handleGoogleCalendarRegister = React.useCallback(
    async (value: IGoogleCalendarParams): Promise<void> => {
      const googleCalendar = await registerGoogleCalendar({
        ...value,
        companyUuid,
      });

      handleCreate(googleCalendar);
    },
    [companyUuid, handleCreate],
  );

  return {
    loading: loading || stateLoading,
    googleCalendars: stateGoogleCalendars,
    refresh,
    handleGetGoogleCalendarAuthToken,
    handleGoogleCalendarRegister,
    ...rest,
  };
}
