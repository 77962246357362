import React from 'react';
import { useTranslation } from 'react-i18next';
import { SideWindow } from '@components/lib/Navigation';
import { IOrderChangeTimeFormProps, OrderChangeTimeForm } from '../Forms';
import { ScheduleChangeTimeFormDTO } from '@structure';
import { ICON_NAME, IconTittle } from '@components/lib/DataDisplay';

export interface IOrderChangeTimeSideWindowProps
  extends IOrderChangeTimeFormProps {
  onSuccess: (value: ScheduleChangeTimeFormDTO) => Promise<void>;
  visible: boolean;
}

export default function OrderChangeTimeSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  schedule,
  ...rest
}: IOrderChangeTimeSideWindowProps) {
  const { t } = useTranslation();

  return (
    <SideWindow
      onClose={onCancel}
      title={
        <IconTittle title={t('Find another time')} iconName={ICON_NAME.ORDER} />
      }
      visible={visible}>
      <OrderChangeTimeForm
        editMode={editMode}
        onSuccess={onSuccess}
        onCancel={onCancel}
        schedule={schedule}
        {...rest}
      />
    </SideWindow>
  );
}
