import { DEPARTMENT_LIST } from '../constants';
import { DepartmentListModel, DepartmentModel } from '@structure';
import { ApiError } from '@services/types';
import { Dispatch } from 'redux';

export interface ISetDepartmentListParam {
  type: DEPARTMENT_LIST;
  departmentList: DepartmentListModel;
}

export interface IAddDepartmentToListParam {
  type: DEPARTMENT_LIST;
  department: DepartmentModel;
}

export interface IUpdateDepartmentFromListParam {
  type: DEPARTMENT_LIST;
  department: DepartmentModel;
}

export interface IDeleteDepartmentFromListParam {
  type: DEPARTMENT_LIST;
  departmentUuid: string;
}

export interface IErrorDepartmentListParam {
  type: DEPARTMENT_LIST;
  error: ApiError | undefined;
}

export function setDepartmentList(
  departmentList: DepartmentListModel,
): (dispatch: Dispatch) => Promise<ISetDepartmentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.SET_DEPARTMENT_LIST,
      departmentList,
    });
  };
}

export function loadMoreDepartmentList(
  departmentList: DepartmentListModel,
): (dispatch: Dispatch) => Promise<ISetDepartmentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.LOAD_MORE_DEPARTMENT_LIST,
      departmentList,
    });
  };
}

export function addDepartmentToList(
  department: DepartmentModel,
): (dispatch: Dispatch) => Promise<IAddDepartmentToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.ADD_DEPARTMENT,
      department,
    });
  };
}

export function updateDepartmentFromList(
  department: DepartmentModel,
): (dispatch: Dispatch) => Promise<IUpdateDepartmentFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.UPDATE_DEPARTMENT,
      department,
    });
  };
}

export function deleteDepartmentFromList(
  departmentUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteDepartmentFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.DELETE_DEPARTMENT,
      departmentUuid,
    });
  };
}

export function errorDepartmentInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorDepartmentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEPARTMENT_LIST.ERROR_DEPARTMENT_LIST,
      error,
    });
  };
}
