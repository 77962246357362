import { Map } from 'immutable';
import { APP_STATE, REWARD } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { RewardModel } from '@structure';

interface IRewardListState {
  reward: RewardModel | null;
  cachedRewardList: Map<string, RewardModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IRewardListState, 'reward'> {
  type: REWARD.SET_REWARD;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateReward {
  type: REWARD.UPDATE_REWARD;
  reward: RewardModel;
}

interface LoadingAction {
  type: REWARD.LOADING_REWARD;
}

interface ResetAction {
  type: REWARD.RESET_REWARD;
}

interface ErrorAction extends Pick<IRewardListState, 'error'> {
  type: REWARD.ERROR_REWARD;
}

interface Handlers {
  [REWARD.SET_REWARD]: (
    state: IRewardListState,
    action: SetAction,
  ) => IRewardListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IRewardListState,
    action: SetInitialStateAction,
  ) => IRewardListState;

  [REWARD.RESET_REWARD]: (
    state: IRewardListState,
    action: ResetAction,
  ) => IRewardListState;

  [REWARD.UPDATE_REWARD]: (
    state: IRewardListState,
    action: UpdateReward,
  ) => IRewardListState;

  [REWARD.ERROR_REWARD]: (
    state: IRewardListState,
    value: ErrorAction,
  ) => IRewardListState;

  [REWARD.LOADING_REWARD]: (
    state: IRewardListState,
    value?: LoadingAction,
  ) => IRewardListState;
  DEFAULT: (state: IRewardListState) => IRewardListState;
}

const initState: IRewardListState = {
  reward: null,
  cachedRewardList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [REWARD.SET_REWARD]: (state, { reward }) => ({
    ...state,
    ...{
      reward,
      cachedRewardList: reward
        ? state.cachedRewardList.set(reward?.uuid, reward)
        : state.cachedRewardList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [REWARD.RESET_REWARD]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [REWARD.UPDATE_REWARD]: (state, { reward }) => ({
    ...state,
    ...{
      reward,
      cachedRewardList: state.cachedRewardList.update(
        reward?.uuid,
        () => reward,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [REWARD.ERROR_REWARD]: (state: IRewardListState, { error }: ErrorAction) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [REWARD.LOADING_REWARD]: (state: IRewardListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IRewardListState) => state,
};

export default function Reward(
  state: any = initState,
  action: any,
): IRewardListState {
  const handler = handlers[action.type as REWARD] || handlers.DEFAULT;
  return handler(state, action);
}
