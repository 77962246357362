import React, { useCallback } from 'react';
import { Text } from '@components/lib/Format';
import { FileUploader } from '@components/lib/Layout/common';
import styled from 'styled-components';
import {
  fileUploadStructure,
  firstLetterToUppercase,
  getFirstLatter,
  ifElse,
} from '@services/helpers';
import { UndoOutlined } from '@ant-design/icons';
// import { CompanyInviteButton } from '../Buttons';
import { CompanyInviteFormDTO } from '@structure';

import './CompanyPictures.less';

export interface ICompanyPicturesProps {
  cover: string | undefined;
  logo: string | undefined;
  qrCode: string | undefined;
  onUploadCoverFile: (file: File) => Promise<string | void>;
  onUploadLogoFile: (file: File) => Promise<string | void>;
  title?: string;
  disabled?: boolean;
  invite: CompanyInviteFormDTO;
}

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 280px;
  perspective: 1000px;
  cursor: pointer;
  overflow: hidden;

  && {
    border-radius: 6px;
  }
`;

const StyledLogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -50px;
  background-color: #e2e2e3;
  border-radius: 50%;
  z-index: 1;
`;

const StyledText = styled(Text)`
  margin-top: -50px;
  font-size: 20px;
`;

const StyledCard = styled.div`
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  transition: all 0.8s ease-in;
`;

const StyledFrontCard = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;

const StyledBackCard = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 0;
  height: 280px;
  background-color: ${({ theme }) => theme.colors.background};
  transform: rotateY(180deg);
  overflow: hidden;
  transition: all 0.8s ease-in;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledUndoOutlined = styled(UndoOutlined)`
  position: absolute;
  left: 10px;
  bottom: 10%;
  font-size: 25px;
`;

export default function CompanyPictures({
  cover,
  qrCode,
  onUploadCoverFile,
  onUploadLogoFile,
  logo,
  title,
  disabled,
  invite,
}: ICompanyPicturesProps): JSX.Element {
  const handleRotate = useCallback((event: Event) => {
    const element = event?.target;

    if (element instanceof HTMLElement || element instanceof SVGElement) {
      const isBack = element.closest('.company-container__back-card');
      const isIcon = element.closest('.company-container__icon');

      if (
        element.classList.contains('company-container__front-card') ||
        isBack ||
        isIcon
      ) {
        const card = document.body.querySelector('.company-container__card');
        const frontCard = document.body.querySelector(
          '.company-container__front-card',
        );

        const backCard = document.body.querySelector(
          '.company-container__back-card',
        );

        if (backCard instanceof HTMLElement) {
          backCard.classList.toggle('company-pictures__back-card');
        }

        if (frontCard instanceof HTMLElement) {
          frontCard.classList.toggle('company-pictures__front-card');
        }

        if (card instanceof HTMLElement) {
          card.classList.toggle('company-pictures');
        }
      }
    }
  }, []);

  return (
    <>
      <StyledContainer onClick={handleRotate as any}>
        <StyledCard className="company-container__card">
          <StyledFrontCard className="company-container__front-card">
            <FileUploader
              isCover
              disabled={disabled}
              onUpload={onUploadCoverFile}
              file={ifElse(!!cover, fileUploadStructure(cover as any), '')}
            />

            <StyledLogoContainer>
              <FileUploader
                title={getFirstLatter(firstLetterToUppercase(title || ''))}
                disabled={disabled}
                onUpload={onUploadLogoFile}
                file={ifElse(!!logo, fileUploadStructure(logo as any), '')}
              />
            </StyledLogoContainer>
            <StyledText bold>{title}</StyledText>
            <StyledUndoOutlined className="company-container__icon" />
          </StyledFrontCard>
          <StyledBackCard className="company-container__back-card">
            <StyledImg src={qrCode} alt="qrcode" />
          </StyledBackCard>
        </StyledCard>
      </StyledContainer>
      {/*<CompanyInviteButton invite={invite} />*/}
    </>
  );
}
