import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { notify } from '@components/lib/Feedback';
import { isFunction, len } from '@services/helpers';
import { User } from '@services/models';
import { DeleteButton } from '@components/lib/DataDisplay';

withTranslation()(DeleteUserButton);

export interface IDeleteUserButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  users: User[];
}

export default function DeleteUserButton({
  children,
  users,
  onSuccess,
  onError,
  title,
  ...rest
}: IDeleteUserButtonProps): JSX.Element {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleUserDeleteClick = async (): Promise<void> => {
    setLoading(true);
    try {
      const list = users.map(({ uuid }) => uuid);

      if (isFunction(onSuccess)) {
        await onSuccess(list);
      }
    } catch (err: any) {
      notify.deleteError(t('User'), err, t);
      setLoading(false);
      if (isFunction(onError)) {
        await onError();
      }
    }
  };

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company user')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(users)} users`}</b>
        </TextFormat>
      }
      onConfirm={handleUserDeleteClick}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
