import { WithoutRecursion } from '../type';

export enum StatusCategoryInternalCode {
  New = 0,
  InProgress = 1,
  Deferred = 2,
  Delivery = 3,
  Completed = 4,
  Canceled = 5,
  Closed = 6,
}

export interface IStatusCategoryDTOProps {
  uuid: string;
  title: string;
  description: string;
  colour: string;
  internal_code: StatusCategoryInternalCode;
  pos: number;
  created_at: string;
  updated_at: string;
}

export class StatusCategoryDTO {
  public uuid: string;
  public title: string;
  public description: string;
  public colour: string;
  public internal_code: StatusCategoryInternalCode;
  public created_at: string;
  public pos: number;
  public updated_at: string;

  constructor(
    props: IStatusCategoryDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.pos = props?.pos || 0;
    this.colour = props?.colour || '';
    this.internal_code = props?.internal_code || StatusCategoryInternalCode.New;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
