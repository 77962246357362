import React from 'react';
import { Routes, Route } from 'react-router';
import {
  ProductCategoryProductListPage,
  ProductPage,
  ProductCategoryPage,
} from '../views/Products';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesTypes } from '@services/types';

export function ProductCategoryProductRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ProductCategoryProductListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":productId"
        element={
          <AppRouteLayout>
            <ProductPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesTypes.categories}/:categoryId`}
        element={
          <AppRouteLayout>
            <ProductCategoryPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
