import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { isListToArray, toDateByFormat } from '@services/helpers';
import {
  Label,
  SearchSelect,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { IUseStateServiceListReturnType, useStoredCompanies } from '@hooks';
import {
  ExpenseCreateFormDTO,
  ExpenseFormDTO,
  ExpenseMapper,
  ScheduleCalendarModel,
  ServiceFormDTO,
  ServiceModel,
  StoreDocumentFormDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentPostActionType,
} from '@structure';
import { ServiceSelectNotFoundContent } from '../../Services';
import './ExpenseHeader.less';
import { useModal } from '@components/lib/libV2/hooks';
import { OrderRealizationCreateButton } from '../Buttons';

const ServicesSideWindowLazy = React.lazy(
  () => import('../../Services/Show/ServicesSideWindow'),
);

export interface IExpenseHeaderProps
  extends Pick<
    IUseStateServiceListReturnType,
    'services' | 'handleSearchServices' | 'handleCreateService'
  > {
  servicesKeywords: string;
  serviceListLoading: boolean;

  handleSetExpenseFormData: (
    value: ExpenseFormDTO | ExpenseCreateFormDTO,
  ) => void;

  schedule: ScheduleCalendarModel | null;

  disabled: boolean;
  resetStateSelect: boolean;

  firstGroupPaymentUuid: string;

  handleCreateExpenseStoreDocument: (
    value: StoreDocumentFormDTO,
  ) => Promise<StoreDocumentModel | void>;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 10px;
  overflow: hidden;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 100%;
  min-width: 150px;
`;
const StyledLabel = styled(Label)`
  flex: 1;
`;

export function ExpenseHeader({
  services: serviceList,
  servicesKeywords,
  serviceListLoading,
  handleCreateService,
  handleSearchServices,
  firstGroupPaymentUuid,

  schedule,

  handleSetExpenseFormData,

  disabled,
  resetStateSelect,
  handleCreateExpenseStoreDocument,
}: IExpenseHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();

  const [selectedService, setSelectedService] = useState<any>(undefined);

  const serviceSelectRef = useRef(null);

  const handleSelectService = useCallback(
    (value: string, serviceModel?: ServiceModel) => {
      setSelectedService(value);

      const service = serviceModel
        ? undefined
        : serviceList?.find(({ uuid }) => uuid === value);

      if (service || serviceModel) {
        const expense = ExpenseMapper.toExpenseCreateFormDTO(
          service || serviceModel!,
          schedule?.employeeModel!,
        );

        handleSetExpenseFormData(expense);
      }
    },
    [handleSetExpenseFormData, schedule?.employeeModel, serviceList],
  );

  const createService = useCallback(
    async (service: ServiceFormDTO) => {
      const serviceModel = await handleCreateService(service);
      if (serviceModel) {
        handleSelectService(serviceModel?.uuid, serviceModel);
      }
    },
    [handleCreateService, handleSelectService],
  );

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onSuccess: createService,
  });

  useEffect(() => {
    if (resetStateSelect) {
      setSelectedService(undefined);
    }
  }, [resetStateSelect]);

  return (
    <>
      {disabled ? null : (
        <StyledContainer>
          <StyledLabel
            label={t('Service')}
            tooltipTitle={t(
              'To select a service from the list, start typing the name of the service to filter the list or enter a new name services and click "Add"',
            )}>
            <StyledSearchSelect
              ref={serviceSelectRef}
              popupClassName="expense-service-popup"
              resetState={resetStateSelect}
              withoutForm
              selectFirst={false}
              selectIfOnFirst={false}
              placeholder={t('Select or create a service')}
              disable={serviceListLoading}
              data={isListToArray(serviceList as any)}
              onSelect={(serviceUuid) => handleSelectService(serviceUuid)}
              onSearch={(keywords) =>
                handleSearchServices({
                  keywords,
                  showLoading: false,
                  limit: 100,
                }) as any
              }
              defaultValue={selectedService}
              notFoundContent={
                <ServiceSelectNotFoundContent
                  size={serviceList?.size || 0}
                  keywords={servicesKeywords}
                  firstGroupPaymentUuid={firstGroupPaymentUuid}
                  handleCreateService={handleCreateService}
                  handleSelectService={handleSelectService}
                  handleOpenServiceForm={handleOnInit}
                />
              }
            />
          </StyledLabel>

          <StyledLabel
            label={t('Commodity value')}
            tooltipTitle={t('Commodity value')}>
            <OrderRealizationCreateButton
              type="primary"
              onSuccess={handleCreateExpenseStoreDocument as any}
              document={StoreDocumentMapper.toStoreDocumentFormDTO(
                {
                  client: schedule?.clientModel,
                  doc_comment: `${t(
                    'Realization of goods and material values for the Order',
                  )} №${schedule?.schedule_number} ${t(
                    'From',
                  )} ${toDateByFormat(
                    schedule?.scheduled_date,
                    'DD.MM.YYYY HH:mm',
                  )}`,
                } as StoreDocumentModel,
                {
                  editMode: true,
                  doc_post_action: {
                    action:
                      StoreDocumentPostActionType.SET_TRIGGEBLE_ORDER_TYPE,
                    value: schedule?.uuid || '',
                  },
                },
              )}
            />
          </StyledLabel>
        </StyledContainer>
      )}
      {visible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ServicesSideWindowLazy
            companyUuid={defaultCompanyUuid}
            onSuccess={handleSuccess}
            visible={visible}
            onCancel={handleCancel}
          />
        </Suspense>
      ) : null}
    </>
  );
}
