import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { List } from 'immutable';
import {
  IUsePlanListProps,
  IUsePlanListReturnType,
  usePlanList,
} from './usePlanList';
import { PlanFormDTO, PlanModel, PlanTariffFormDTO } from '@structure';
import {
  loadMorePlanList as storeLoadMorePlanList,
  setPlanList as storeSetPlanList,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import {
  ISubscriptionPlanRecalculationProps,
  subscriptionActivate,
  subscriptionPlanRecalculation,
  subscriptionTransition,
} from '@services/api/subscriptions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { StatusError } from '@components/lib/Errors';

export interface IUseStatePlanListProps extends IUsePlanListProps {}

export interface IUseStatePlanListReturnType
  extends Omit<IUsePlanListReturnType, 'entityList'> {
  planList: List<PlanModel> | null;
  handleSearchPlans: (keywords: string) => Promise<void>;
  handleLoadMorePlans: () => Promise<void>;
  handleRefreshPlans: () => Promise<List<PlanModel> | void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  handleSubscriptionPlanRecalculation: (
    value: ISubscriptionPlanRecalculationProps,
  ) => Promise<number | void>;
  handleSubscriptionTransition: (value: PlanTariffFormDTO) => Promise<string>;
  handleSubscriptionActivate: (value: PlanFormDTO) => Promise<string>;
  handleRefreshPlanList: (
    value: Partial<IUsePlanListProps>,
  ) => Promise<List<PlanModel> | void>;
}

export function useStoredPlanList(
  {
    loadOnInit = true,
    ...rest
  }: IUseStatePlanListProps = {} as IUseStatePlanListProps,
): IUseStatePlanListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedPlanListStatus,
    loading: storedPlanListLoading,
    planList,
    total: storeTotal,
    ...storedPlanListParams
  } = useSelector(({ planList }: RootState) => planList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: planListLoading,
    total,
    ...plansParams
  } = usePlanList({
    loadOnInit: loadOnInit && storedPlanListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedPlanListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetPlanList(entityList, '', total));
    }
  }, [dispatch, entityList, storedPlanListStatus, total]);

  const handleSearchPlans = React.useCallback(
    async (keywords: string) => {
      const planListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (planListModel && List.isList(planListModel?.plans)) {
        dispatch(
          storeSetPlanList(planListModel.plans, keywords, planListModel.total),
        );
      }
    },
    [dispatch, refresh],
  );

  const handleLoadMorePlans = useCallback(async () => {
    if (List.isList(planList) && planList?.size < storeTotal && !loadingMore) {
      setLoadingMore(true);
      const planListModel = await refresh({
        offset: planList?.size,
        limit: 10,
        showLoading: false,
      });

      if (planListModel && List.isList(planListModel?.plans)) {
        dispatch(storeLoadMorePlanList(planListModel?.plans));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, planList, storeTotal]);

  const handleRefreshPlans = useCallback(async () => {
    if (!planList) {
      const planListModel = await refresh({
        offset: 0,
        limit: 10,
        showLoading: false,
      });

      if (planListModel && List.isList(planListModel?.plans)) {
        dispatch(
          storeSetPlanList(planListModel?.plans, '', planListModel?.total),
        );

        return planListModel?.plans;
      }
    }
  }, [dispatch, planList, refresh]);

  const handleSubscriptionPlanRecalculation = useCallback(
    async (
      value: ISubscriptionPlanRecalculationProps,
    ): Promise<number | void> => {
      try {
        return await subscriptionPlanRecalculation(value);
      } catch (error: any) {
        alert(
          'error',
          t('Tariff change'),
          `${t('An error occurred recalculation tariff plan')}: ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, t],
  );

  const handleSubscriptionTransition = useCallback(
    async (value: PlanTariffFormDTO): Promise<string> => {
      const url = await subscriptionTransition(value);

      alert(
        'success',
        t('Tariff change'),
        t('Tariff plan change successfully'),
      );

      return url;
    },
    [alert, t],
  );

  const handleSubscriptionActivate = useCallback(
    async (value: PlanFormDTO): Promise<string> => {
      const url = await subscriptionActivate(value);

      alert('success', t('Tariff'), t('Prolong plan successfully'));

      return url;
    },
    [alert, t],
  );

  const handleRefreshPlanList = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      ...rest
    }: Partial<IUsePlanListProps>) => {
      const planList = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (planList && List.isList(planList.plans)) {
        dispatch(storeSetPlanList(planList?.plans, '', planList?.total));
        return planList?.plans;
      }
    },
    [dispatch, refresh],
  );

  return {
    ...plansParams,
    ...storedPlanListParams,
    planList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(planList) && planListLoading) || !storedPlanListLoading,
    handleSearchPlans,
    handleLoadMorePlans,
    status: storedPlanListStatus,
    handleRefreshPlans,
    handleSubscriptionPlanRecalculation,
    handleSubscriptionTransition,
    handleSubscriptionActivate,
    handleRefreshPlanList,
  };
}
