import { useSize } from '@hooks';
import { Col, Row, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { EmployeeFormDTO } from '@structure';
import React, { useMemo, useCallback, useRef } from 'react';
import { Segmented } from '@components/lib/DataDisplay';
import { EMPLOYEE_INITIAL_DATA } from '@services/api/employee';
import { GENDER_SEGMENTED, phoneScreen } from '@services/const';

import {
  DefaultForm,
  IDefaultFormProps,
  PhoneInputComponent,
  DatePicker,
} from '@components/lib/General';

export interface IEmployeeFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: EmployeeFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  employee?: EmployeeFormDTO;
}

/**
 * @desc Створенння співробітника
 * */
export default function EmployeeForm({
  loading,
  editMode,
  employee = EMPLOYEE_INITIAL_DATA,
  ...rest
}: IEmployeeFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  /**
   * @desc Обробка сабміту для форми
   * */

  const additionalValues = useMemo(
    () => ({
      uuid: employee?.uuid,
    }),
    [employee?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Employee'),
        `${t(
          `An error occurred during ${editMode ? 'edit' : 'create'} employee`,
        )}: ${apiError?.message} `,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm
      initialValues={{
        ...employee,
      }}
      notifyError={notifyError}
      showNotify={false}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={20} ref={contentRef}>
            <Col span={24}>
              <Form.Item
                label={t('Last Name')}
                name="last_name"
                tooltip={t('Last Name')}
                rules={[
                  {
                    required: true,
                    message: t('Last Name must be specified.'),
                    type: 'string',
                  },
                ]}>
                <Input
                  data-testid="employee-create-input-last-name"
                  disabled={loadingSubmit}
                  placeholder={t('Enter the employee last name')}
                />
              </Form.Item>
            </Col>

            <Col span={isFullWidth}>
              <Form.Item
                label={t('First Name')}
                name="first_name"
                tooltip={t('First Name')}
                rules={[
                  {
                    required: true,
                    message: t('First Name must be specified.'),
                    type: 'string',
                  },
                ]}>
                <Input
                  data-testid="employee-create-input-first-name"
                  disabled={loadingSubmit}
                  placeholder={t('Enter the employee first name')}
                />
              </Form.Item>
            </Col>
            <Col span={isFullWidth}>
              <Form.Item label={t('Middle Name')} name="middle_name">
                <Input
                  data-testid="employee-create-input-middle-name"
                  disabled={loadingSubmit}
                  placeholder={t('Enter the employee middle name')}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('Position')}
                name="title"
                tooltip={t('Position')}
                rules={[
                  {
                    required: true,
                    message: t('Position must be specified.'),
                    type: 'string',
                  },
                ]}>
                <Input
                  data-testid="employee-create-input-title"
                  disabled={loadingSubmit}
                  placeholder={t('Enter the employee position')}
                />
              </Form.Item>
            </Col>

            <Col span={isFullWidth}>
              <Form.Item
                label={t('Phone')}
                name="phone"
                tooltip={t('Phone')}
                rules={[
                  {
                    required: true,
                    message: t('Employee Phone must be specified.'),
                    type: 'string',
                  },
                ]}>
                <PhoneInputComponent
                  dataTestid="employee-data-test-id"
                  placeholder={t('Enter the employee phone')}
                  id="field-phone"
                  containerClass="field-phone-container"
                  searchClass="field-phone-search"
                  inputClass="ant-input field-phone"
                  buttonClass="field-phone-flag"
                  loading={loadingSubmit}
                />
              </Form.Item>
            </Col>

            <Col span={isFullWidth}>
              <Form.Item
                label={t('Email')}
                name="email"
                className="employee-email">
                <Input
                  data-testid="employee-create-input-email"
                  disabled={loadingSubmit}
                  placeholder={t('Enter the employee email')}
                />
              </Form.Item>
            </Col>

            <Col span={isFullWidth}>
              <Form.Item
                label={t('Birthday')}
                name="birthday"
                rules={[
                  {
                    required: true,
                    message: t('Birthday must be specified.'),
                    type: 'date',
                  },
                ]}>
                <DatePicker
                  data-testid="employee-create-input-birthday"
                  disabled={loadingSubmit}
                  placeholder={t('Birthday')}
                />
              </Form.Item>
            </Col>
            <Col span={isFullWidth}>
              <Form.Item
                label={t('Gender')}
                name="gender"
                tooltip={t('Gender')}
                rules={[
                  {
                    required: true,
                    message: t('Gender must be specified.'),
                    type: 'string',
                  },
                ]}>
                <Segmented
                  options={GENDER_SEGMENTED}
                  disabled={loadingSubmit}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
