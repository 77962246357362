import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { List } from 'immutable';
import { DeleteOutlined } from '@ant-design/icons';
import { IUseStateGroupPaymentListReturnType } from '@hooks';
import GroupPaymentSearchInput from './GroupPaymentSearchInput';
import {
  CreateGroupPaymentButton,
  DeleteGroupPaymentButton,
  UpdateGroupPaymentButton,
} from '../Buttons';
import {
  CompanyModel,
  GroupPaymentMapper,
  GroupPaymentModel,
} from '@structure';
import { Table } from '@components/lib/libV2/DataDisplay';
import { TableActionCell } from '@components/lib/libV2/DataDisplay';

export default withTranslation()(GroupPaymentTab);

export interface IGroupPaymentTabProps
  extends IUseStateGroupPaymentListReturnType,
    WithTranslation {
  companyList: List<CompanyModel>;
  defaultCompanyUuid: string;
  disabled?: boolean;
}

function GroupPaymentTab({
  groupPayments,
  companyList,
  handleUpdateGroupPayment,
  loading,
  total,
  refresh,
  defaultCompanyUuid,
  handleSearchGroupPayments,
  handleCreateGroupPayment,
  handleDeleteGroupPayments,
  limit,
  disabled,
  t,
}: IGroupPaymentTabProps): JSX.Element {
  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (groupPayment: GroupPaymentModel) => groupPayment?.title,
    },
    {
      title: t('Description'),
      key: 'description',
      render: (groupPayment: GroupPaymentModel) => groupPayment?.description,
    },
    {
      title: t('Actions'),
      key: 'payment_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (groupPayment: GroupPaymentModel) => (
        <TableActionCell>
          <UpdateGroupPaymentButton
            disabled={disabled}
            defaultCompanyUuid={defaultCompanyUuid}
            companyList={companyList}
            type="text"
            groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO(
              groupPayment,
            )}
            data-testid="payment-edit-button"
            onSuccess={handleUpdateGroupPayment}
          />

          <DeleteGroupPaymentButton
            disabled={disabled}
            data-testid="payment-delete-button"
            groupPayments={[groupPayment]}
            onSuccess={handleDeleteGroupPayments}>
            <DeleteOutlined />
          </DeleteGroupPaymentButton>
        </TableActionCell>
      ),
    },
  ];

  return (
    <Table<GroupPaymentModel>
      underTableContent={[
        <GroupPaymentSearchInput
          handleSearchGroupPayments={(keywords) =>
            handleSearchGroupPayments({ keywords })
          }
          key="payment__input--search"
        />,
        <CreateGroupPaymentButton
          key="create-payment-group"
          groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO({
            defaultCompanyUuid,
          } as any)}
          disabled={loading || disabled}
          companyList={companyList}
          defaultCompanyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreateGroupPayment}
        />,
      ]}
      renderEmpty={
        <Empty description={t('There are no group payment')}>
          <CreateGroupPaymentButton
            groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO({
              defaultCompanyUuid,
            } as any)}
            type="primary"
            disabled={loading || disabled}
            companyList={companyList}
            defaultCompanyUuid={defaultCompanyUuid}
            loading={loading}
            title={t('Create Payment')}
            onSuccess={handleCreateGroupPayment}
          />
        </Empty>
      }
      loading={loading}
      dataSource={groupPayments}
      total={total}
      onChange={refresh}
      pageSize={limit}
      columns={columns}
    />
  );
}
