import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import styled from 'styled-components';
import {
  FlexLabelText,
  FlexContainer,
  StatusContainer,
} from '@components/lib/Styled';
import { TextFormat, DateFormat, FORMAT_DATE } from '@components/lib/Format';
import NestedLayout from '@components/lib/Layout/NestedLayout';
import { Payment } from '@services/models';
import { isThereContent } from '@services/helpers';

const { Panel } = Collapse;

export default withTranslation()(ClientSubscriptionCardCollapse);

export interface IClientSubscriptionCardCollapseProps extends WithTranslation {
  payments: Payment[];
}

const NativeCollapse = styled(Collapse)`
  width: 100%;

  & .ant-collapse-header {
    padding-left: 0 !important;
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const CollapseBody = styled(FlexContainer)`
  width: 90%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
`;

const CollapseBodyText = styled(FlexContainer)`
  padding: 15px;
`;

const StyledNestedLayout = styled(NestedLayout)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledFlexLabelText = styled(FlexLabelText)`
  color: ${({ theme }) => theme.colors.app};
`;

const StyledHeaderTitle = styled(TextFormat)`
  color: ${({ theme }) => theme.colors.link};
`;

const StyledPanel = styled(Panel)`
  & .ant-collapse-header > * {
    color: ${({ theme }) => theme.colors.link};
  }
`;

function ClientSubscriptionCardCollapse({
  t,
  payments,
}: IClientSubscriptionCardCollapseProps): JSX.Element {
  return (
    <NativeCollapse ghost>
      <StyledPanel
        header={<StyledHeaderTitle>{t('Payment history')}</StyledHeaderTitle>}
        key="1">
        <StyledNestedLayout withoutBackground>
          {isThereContent(payments)
            ? payments.map((payment) => (
                <CollapseBody flexDirection="column" key={payment?.uuid}>
                  <StatusContainer
                    status={payment?.payment_status}
                    justifyContent="flex-end"
                    alignItems="center">
                    <TextFormat invert className="status__title">
                      {t(payment?.payment_status)}
                    </TextFormat>
                  </StatusContainer>
                  <CollapseBodyText>
                    <FlexContainer flexWrap="wrap">
                      <StyledFlexLabelText label={`${t('Sum')}:`}>
                        <TextFormat invert>{payment?.payment_sum}</TextFormat>
                      </StyledFlexLabelText>
                      <StyledFlexLabelText
                        label={`${t('Comment')}:`}
                        alignItems="flex-end">
                        <TextFormat invert>
                          {payment?.payment_comment}
                        </TextFormat>
                      </StyledFlexLabelText>
                      <StyledFlexLabelText label={`${t('Payment method')}:`}>
                        <TextFormat invert>
                          {t(payment?.payment_method)}
                        </TextFormat>
                      </StyledFlexLabelText>
                      <StyledFlexLabelText
                        label={`${t('Payment date')}:`}
                        alignItems="flex-end">
                        <DateFormat format={FORMAT_DATE.FULL}>
                          {payment?.payment_date}
                        </DateFormat>
                      </StyledFlexLabelText>
                    </FlexContainer>
                  </CollapseBodyText>
                </CollapseBody>
              ))
            : null}
        </StyledNestedLayout>
      </StyledPanel>
    </NativeCollapse>
  );
}
