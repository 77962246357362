import * as React from 'react';
import { List } from 'immutable';
import {
  useServiceGroupSessionList,
  IUseServiceGroupSessionListProps,
  IUseServiceGroupSessionListReturnType,
} from './useServiceGroupSessionList';
import {
  ServiceGroupSessionModel,
  ServiceGroupSessionFormDTO,
  ServiceGroupSessionMapper,
} from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  createGroupSession,
  editGroupSession,
  deleteSessionById,
} from '@services/api/groupSessions';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateServiceGroupSessionListProps
  extends IUseServiceGroupSessionListProps {}

export interface IUseStateServiceGroupSessionListReturnType
  extends Omit<IUseServiceGroupSessionListReturnType, 'entityList'> {
  groupSessions: List<ServiceGroupSessionModel> | null;
  handleCreateServiceGroupSession: (
    value: ServiceGroupSessionFormDTO,
  ) => Promise<void>;
  handleUpdateServiceGroupSession: (
    value: ServiceGroupSessionFormDTO,
  ) => Promise<void>;
  handleDeleteServiceGroupSessions: (ids: string[]) => Promise<void>;
  handleSearchServiceGroupSessions: (value: any) => Promise<void>;
  handleResetGroupSession: () => void;
}

export function useStateServiceGroupSessionList(
  {
    ...rest
  }: IUseStateServiceGroupSessionListProps = {} as IUseStateServiceGroupSessionListProps,
): IUseStateServiceGroupSessionListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total,
    ...clientsParams
  } = useServiceGroupSessionList({
    ...rest,
  });

  const {
    entityList: groupSessions,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
  } = useStateEntityList<ServiceGroupSessionModel>({
    entityList,
    refresh,
    limit,
    offset,
    total,
  });

  const handleCreateServiceGroupSession = React.useCallback(
    async (value: ServiceGroupSessionFormDTO) => {
      const groupSessionFormDTO =
        ServiceGroupSessionMapper.toServiceGroupSessionFormDTO(value as any);

      const groupSession = await createGroupSession(groupSessionFormDTO);
      const groupSessionModel =
        ServiceGroupSessionMapper.toServiceGroupSessionModel(groupSession);

      handleCreate(groupSessionModel);

      alert('success', t('Group session'), t('Group session created success'));
    },
    [alert, handleCreate, t],
  );

  const handleUpdateServiceGroupSession = React.useCallback(
    async (value: ServiceGroupSessionFormDTO) => {
      const groupSessionFormDTO =
        ServiceGroupSessionMapper.toServiceGroupSessionFormDTO(value as any);

      const groupSession = await editGroupSession(groupSessionFormDTO);
      const groupSessionModel =
        ServiceGroupSessionMapper.toServiceGroupSessionModel(groupSession);

      handleUpdate(groupSessionModel);
      alert('success', t('Group session'), t('Group session edit success'));
    },
    [alert, handleUpdate, t],
  );

  const handleDeleteServiceGroupSessions = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteSessionById(ids);

        handleDelete(ids);
        alert(
          'success',
          t('Group session'),
          t('Group sessions delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Group session'),
          `${t('An error occurred during delete group sessions')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, t],
  );

  const handleSearchServiceGroupSessions = React.useCallback(
    async (keywords: string) => {
      const service = await refresh({ offset: 0, limit: 10, keywords });

      if (service) {
        setEntityList(service);
      }
    },
    [refresh, setEntityList],
  );

  const handleResetGroupSession = React.useCallback(
    () => setEntityList(null),
    [setEntityList],
  );

  return {
    groupSessions,
    offset,
    limit,
    refresh,
    total,
    loading: clientLoading || loading,
    ...clientsParams,
    handleCreateServiceGroupSession,
    handleUpdateServiceGroupSession,
    handleDeleteServiceGroupSessions,
    handleSearchServiceGroupSessions,
    handleResetGroupSession,
  };
}
