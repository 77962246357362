import { Record } from 'immutable';
import { SubscriptionDTO } from '../internal';

export interface ISubscriptionModelReturnType {
  id: string;
  date_start: string;
  date_end: string;
  comment: string;
  created_at: string;
  price: string;
  periods: number;
  discount: number;
  status: number;
}

export class SubscriptionModel extends Record<ISubscriptionModelReturnType>({
  id: '',
  date_start: '',
  date_end: '',
  comment: '',
  created_at: '',
  price: '0.00',
  periods: 0,
  discount: 0,
  status: 0,
}) {
  constructor(props = {} as SubscriptionDTO) {
    const options: ISubscriptionModelReturnType = {
      ...props,
    };
    super(options);
  }
}
