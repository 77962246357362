import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IClientOperationListDTO,
  ClientMapper,
  ClientOperationListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getClientBalanceOperations } from '@services/api/client';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseClientBalanceOperationListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  clientUuid: string;
}

export interface IUseClientBalanceOperationListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: ClientOperationListModel | null;
  refresh: (
    value: Partial<IUseClientBalanceOperationListProps>,
  ) => Promise<ClientOperationListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  clientUuid: string;
}

export function useClientBalanceOperationList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    clientUuid,
  }: IUseClientBalanceOperationListProps = {} as IUseClientBalanceOperationListProps,
): IUseClientBalanceOperationListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    clientUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseClientBalanceOperationListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    clientUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      clientUuid = listCompanyUuid,
    }: Partial<IUseClientBalanceOperationListProps> = {}): Promise<ClientOperationListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          clientUuid,
        });

        const { operations, total, client, stats } =
          await cancellablePromise<IClientOperationListDTO>(
            getClientBalanceOperations({
              limit,
              offset,
              keywords,
              clientUuid,
            }),
          );

        const operationListModel = ClientMapper.toClientOperationListModel(
          operations,
          total,
          [],
          client,
          stats,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: operationListModel,
            total: operationListModel?.total,
            loading: false,
          });

          return operationListModel;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }

        alert(
          'error',
          t('Client operations'),
          `${t('An error occurred during get client operation list')} : ${
            error?.message
          }`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && clientUuid) {
      (async () => {
        await refresh({ clientUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, clientUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    clientUuid: listCompanyUuid,
  };
}
