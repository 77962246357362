import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewLayout } from '@components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredStatus,
  useStoredCompanies,
  useStoredStatusList,
} from '@hooks';
import { STATUS_REQUIRED_FIELD } from '@services/api/status';
import {
  StatusCategoryTag,
  StatusTabPanel,
  StatusExecuteOnSetView,
} from '../Show';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { Routes, RoutesAcl } from '@services/types';
import { StatusMapper, StatusModel, ExecuteOnSet } from '@structure';
import { useLocation, useNavigate, useParams } from 'react-router';
import { StatusEditButton } from '../Buttons';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { Col, Row, Space } from 'antd';
import { StyledTitle } from '@components/lib/Styled';
import { IOrderRequiredFieldsWithFullName } from '@services/api/orders';
import { useAcl } from '@contex';
import { Table } from '@components/lib/libV2/DataDisplay';

export default function StatusPage(): JSX.Element {
  const { statusId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ status }) => status);

  const {
    status,
    loading: statusLoading,
    error: statusError,
    handleUpdateStatus,
  } = useStoredStatus({
    statusUuid: statusId!,
    companyUuid: defaultCompanyUuid,
  });

  const { allowedStatusesFrom, allowedStatusesTo } = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    allowedStatusesTo: status?.allowed_statuses_to,
    allowedStatusesFrom: status?.allowed_statuses_from,
  });

  const loading = useStopLoading({
    loading: statusLoading,
    error: statusError,
    message: 'An error occurred during status loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.customStatuses}`,
      breadcrumbName: 'Statuses',
    },
    {
      path: `/${Routes.customStatuses}/${statusId}`,
      breadcrumbName: status ? status?.title : 'Show status',
    },
  ]);

  const columns = useMemo(
    () => [
      {
        title: t('Title'),
        key: 'title',
        render: (status: StatusModel) => (
          <StatusCategoryTag
            category={status?.category}
            title={status?.title}
          />
        ),
      },
      {
        title: t('Description'),
        key: 'description',
        render: (status: StatusModel) => (
          <StyledTitle>{status?.category?.description}</StyledTitle>
        ),
      },
    ],
    [t],
  );

  const changeFields = useCallback(
    (
      {
        allowed_statuses_to,
        allowed_statuses_from,
        execute_on_set,
        ...rest
      }: typeof STATUS_REQUIRED_FIELD,
      status: StatusModel,
    ): IOrderRequiredFieldsWithFullName & any => {
      return {
        allowed_statuses_to: (
          <Table<StatusModel>
            total={
              Array.isArray(allowedStatusesTo) ? allowedStatusesTo?.length : 0
            }
            pageSize={Infinity}
            dataSource={allowedStatusesTo}
            columns={columns}
            loading={loading}
          />
        ),
        allowed_statuses_from: (
          <Table<StatusModel>
            total={
              Array.isArray(allowedStatusesFrom)
                ? allowedStatusesFrom?.length
                : 0
            }
            pageSize={Infinity}
            dataSource={allowedStatusesFrom}
            columns={columns}
            loading={loading}
          />
        ),
        ...(execute_on_set
          ? {
              execute_on_set: (
                <StatusExecuteOnSetView
                  executeOnSet={execute_on_set as ExecuteOnSet}
                />
              ),
            }
          : {}),
        ...rest,
      };
    },
    [allowedStatusesTo, allowedStatusesFrom, columns, loading],
  );

  const correctStatusRequiredFields = {
    ...STATUS_REQUIRED_FIELD,
  };

  const overviewData = useRequiredFields(
    status as any,
    correctStatusRequiredFields,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack || `/${Routes.app}/${Routes.customStatuses}`,
    );
  }, [location?.state?.goBack, navigate]);

  return (
    <StatusTabPanel status={status}>
      {({ tabs }) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.customStatuses]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <StatusEditButton
              status={StatusMapper.toStatusFormDTO(status!, true)}
              onSuccess={handleUpdateStatus}
            />
          }
          disabled={!manage}
          headerTitle={t('Status')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabs={tabs}
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="horizontal" size="small">
                  <StyledTitle bold fontSize={20}>
                    {status?.title}
                  </StyledTitle>
                  <StatusCategoryTag
                    title={status?.category?.title!}
                    category={status?.category!}
                  />
                </Space>
              </Col>
            </Row>
          }
        />
      )}
    </StatusTabPanel>
  );
}
