import React, { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Form, Input } from 'antd';
import { InputDisabledView } from '@components/lib/Styled';

export default withTranslation()(ClientPriceForm);

export interface IClientPriceFormProps extends WithTranslation {
  loading?: boolean;
  handleChangeDiscount: (value: ChangeEvent<HTMLInputElement>) => void;
}

function ClientPriceForm({
  t,
  loading,
  handleChangeDiscount,
}: IClientPriceFormProps): JSX.Element {
  return (
    <>
      <Col span={12}>
        <Form.Item
          label={t('Cost')}
          name="cost"
          extra={t('Cost of the selected service')}
          rules={[
            {
              required: true,
              message: t('Cost must be specified'),
            },
          ]}
          tooltip={{
            title: t('Cost of service'),
          }}>
          <Input
            data-testid="client-services-cost"
            disabled
            addonAfter={t('UAH')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t('Discount')}
          name="payment_discount"
          extra={t(
            'Will be applied to subscribers, exclusively within the framework of the current plan',
          )}
          rules={[
            {
              transform: (value) => Number(value),
              type: 'number',
              min: 0,
              max: 100,
              message: t(
                'Discount must be a number and bigger than -1 and less 100',
              ),
            },
          ]}
          tooltip={{
            title: t('Service discount'),
          }}>
          <Input
            onChange={handleChangeDiscount}
            data-testid="client-services-discount"
            disabled={loading}
            placeholder={t('Enter the discount')}
            addonAfter="%"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t('Total')}
          name="total"
          extra={t('Final price including discount')}
          rules={[
            {
              required: true,
              message: t('Total must be specified'),
            },
          ]}
          tooltip={{
            title: t('Final price including discount'),
          }}>
          <InputDisabledView
            data-testid="client-services-total"
            disabled
            addonAfter={t('UAH')}
          />
        </Form.Item>
      </Col>
    </>
  );
}
