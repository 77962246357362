import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SideWindow } from '@components/lib/Navigation';
import { DayOffForm, IDayOffFormProps } from '../Forms';
import { ICON_NAME, IconTittle } from '@components/lib/DataDisplay';
import { DayOffDTO } from '@structure';

export default withTranslation()(DaysOffSideWindow);

export interface IDaysOffSideWindowProps
  extends WithTranslation,
    IDayOffFormProps {
  onSuccess: (value: DayOffDTO) => Promise<void>;
  visible: boolean;
}

function DaysOffSideWindow({
  t,
  onSuccess,
  visible,
  onCancel,
  ...rest
}: IDaysOffSideWindowProps) {
  return (
    <SideWindow
      title={
        <IconTittle title={t('Add day off')} iconName={ICON_NAME.EMPLOYEE} />
      }
      visible={visible}
      onClose={onCancel}
      data-testid="add-employee-day-off-form-side-window">
      <DayOffForm onSuccess={onSuccess} onCancel={onCancel} {...rest} />
    </SideWindow>
  );
}
