import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len, eq } from '@services/helpers';
import { StoreDocumentModel } from '@structure';
import { useDelete } from '@components/lib/libV2/hooks';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IStoreRealizationDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  documents: StoreDocumentModel[];
  title?: string;
}

export function StoreRealizationDeleteButton({
  children,
  documents = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IStoreRealizationDeleteButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: documents,
    notifyTitle: 'Sales invoice',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete sales invoice')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(documents)}</b>{' '}
          {eq(len(documents), 1) ? t('sales invoice') : t('sales invoices')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
