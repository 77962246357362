import React, { useCallback, useState } from 'react';
import { ButtonProps } from 'antd';
import { TextFormat } from '@components/lib/Format';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { EmployeeModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { isFunction } from '@services/helpers';

export interface IEmployeeCancelInviteButtonProps extends ButtonProps {
  tooltipTitle: string;
  title?: string;
  employee: EmployeeModel;
  handleCancelEmployeeInvite: (
    employee: EmployeeModel,
  ) => Promise<EmployeeModel | void>;
  handleUpdate?: (employee: EmployeeModel) => void;
}

export function EmployeeCancelInviteButton({
  tooltipTitle,
  type = 'text',
  style,
  title,
  handleCancelEmployeeInvite,
  employee,
  handleUpdate,
  children,
  ...rest
}: IEmployeeCancelInviteButtonProps): JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [loading, setLoading] = useState(false);

  const acceptEmployeeInvite = useCallback(async () => {
    setLoading(true);
    const employeeModel = await handleCancelEmployeeInvite(employee);
    if (employeeModel && isFunction(handleUpdate)) {
      handleUpdate(employeeModel);
    }
    setLoading(false);
  }, [employee, handleCancelEmployeeInvite, handleUpdate]);

  return (
    <DeleteButton
      type={type}
      title={title}
      tooltipTitle={tooltipTitle}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to cancel employee invite')}
        </TextFormat>
      }
      onConfirm={acceptEmployeeInvite}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}>
      {children || <CloseCircleOutlined color={theme.colors.error} />}
    </DeleteButton>
  );
}
