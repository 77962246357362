import { Dispatch } from 'redux';
import { APPROVE_ENTER_PAGE_STATE } from '../constants';
import { LocalStorageItems } from '@services/const';

export interface IApproveEnterState {
  identifier: string | null;
  approveEnter: boolean | null;
  timeWhenCodeWasSend: Date | null;
  loading: boolean;
}

export const approveEnterInitialState = {
  identifier: null,
  approveEnter: null,
  timeWhenCodeWasSend: null,
  loading: true,
};

export interface ISetApproveEnterStateParam {
  type: APPROVE_ENTER_PAGE_STATE;
}

export interface IUpdateApproveEnterStateParam {
  type: APPROVE_ENTER_PAGE_STATE;
  state: Partial<IApproveEnterState>;
}

export interface IResetApproveEnterStateParam {
  type: APPROVE_ENTER_PAGE_STATE;
}

export function setApproveEnterState(): (
  dispatch: Dispatch,
) => Promise<ISetApproveEnterStateParam> {
  return async function (dispatch: Dispatch) {
    const state = localStorage.getItem(LocalStorageItems.approveEnterData);

    return dispatch({
      type: APPROVE_ENTER_PAGE_STATE.SET_STATE,
      state: state ? JSON.parse(state) : approveEnterInitialState,
    });
  };
}

export function updateApproveEnterState(
  state: Partial<IApproveEnterState>,
): (dispatch: Dispatch) => Promise<IUpdateApproveEnterStateParam> {
  return async function (dispatch: Dispatch) {
    const approveEnterPrevState =
      localStorage.getItem(LocalStorageItems.approveEnterData) ||
      JSON.stringify({});

    localStorage.setItem(LocalStorageItems.approveEnterData, {
      ...JSON.parse(approveEnterPrevState),
      ...state,
    });

    return dispatch({
      type: APPROVE_ENTER_PAGE_STATE.UPDATE_STATE,
      state,
    });
  };
}

export function resetApproveEnterState(): (
  dispatch: Dispatch,
) => Promise<IResetApproveEnterStateParam> {
  return async function (dispatch: Dispatch) {
    localStorage.removeItem(LocalStorageItems.approveEnterData);

    return dispatch({
      type: APPROVE_ENTER_PAGE_STATE.RESET_STATE,
      state: approveEnterInitialState,
    });
  };
}
