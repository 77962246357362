import React, { useCallback, useState } from 'react';
import { Upload, UploadProps, UploadFile } from 'antd';
import { Button, IButtonProps } from '@components/lib/DataDisplay';
import { BsPaperclip } from 'react-icons/bs';
import styled, { css } from 'styled-components';
import { IUseStateFileListReturnType } from '@hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce } from '@services/helpers';

export interface IOrderUploadFilesButtonProps
  extends Omit<IButtonProps, 'children'>,
    Pick<IUseStateFileListReturnType, 'handleUploadFiles'> {}

const StyledUpload = styled(Upload)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function OrderUploadFilesButton({
  title,
  handleUploadFiles,
  disabled,
  ...rest
}: IOrderUploadFilesButtonProps): React.JSX.Element {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpload = useCallback(
    debounce(async ({ fileList }: any) => {
      try {
        setUploading(true);
        await handleUploadFiles(fileList);
        setUploading(false);
      } catch (error: any) {
        setUploading(false);
      }
    }, 1000),
    [],
  );

  const props: UploadProps = {
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <StyledUpload
      disabled={uploading || disabled}
      multiple
      showUploadList={false}
      onChange={handleUpload}
      {...props}>
      <StyledButton
        {...rest}
        disabled={uploading || disabled}
        icon={uploading ? <LoadingOutlined /> : <BsPaperclip />}
        size="small">
        {title}
      </StyledButton>
    </StyledUpload>
  );
}
