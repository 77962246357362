import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewLayout } from '@components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredClientAbonement,
  useStateClientAbonementScheduleList,
  useStoredClientAbonementSubscriptionList,
  useStoredClientVisitList,
  useStoredCompanies,
  useInterval,
} from '@hooks';
import { CLIENT_ABONEMENT_REQUIRED_FIELD } from '@services/api/client';
import {
  AbonementLatestStatusView,
  ClientAbonementTabPanel,
  ClientDetailsView,
} from '../Show';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { PERSONALITY_TYPE, Routes, RoutesAcl } from '@services/types';
import {
  ClientAbonementMapper,
  ClientAbonementModel,
  ClientAbonementScheduleFormDTO,
  ClientAbonementStatusesText,
  ClientAbonementSubscriptionStatusesText,
  ScheduleCalendarMapper,
} from '@structure';
import { useAcl } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import { HeaderLeft, Switch } from '@components/lib/DataDisplay';
import { Col, Row, Space } from 'antd';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import {
  correctPrice,
  getDatesInMonthDisplay,
  getMonth,
  getTimesOfDates,
  getYear,
  head,
  textToUpperCase,
  toDateByFormat,
  todayHour,
  todayWeekday,
} from '@services/helpers';
import { IOrderRequiredFieldsWithFullName } from '@services/api/orders';
import { Text } from '@components/lib/Format';
import styled, { useTheme } from 'styled-components';
import OverviewLayoutDetails from '@components/lib/Layout/Overview/Show/OverviewLayoutDetails';
import { EmployeeDetailsView } from '../../Employees';
import {
  TariffModelDetailsView,
  TariffModelGroupView,
} from '../../TariffModels';
import { ClientAbonementScheduleButton, ClientRefundsButton } from '../Buttons';
import moment from 'moment/moment';

export const API_CALL_ABONEMENT_TIMEOUT_DELAY = 20000;
export const API_CALL_ABONEMENT_TIMEOUT_CLEAR = 60001;

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const StyledPrevCost = styled(StyledDescription)`
  text-decoration: line-through;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :nth-child(n + 1) {
    margin-right: 5px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ClientAbonementSubscriptionColors = (theme: any) => ({
  [ClientAbonementSubscriptionStatusesText.Active]: theme.colors.success,
  [ClientAbonementSubscriptionStatusesText.Error]: theme.colors.error,
  [ClientAbonementSubscriptionStatusesText.Deferred]: theme.colors.link,
  [ClientAbonementSubscriptionStatusesText.Terminated]: theme.colors.error,
});

export function ClientAbonementPage(): JSX.Element {
  const {
    abonementId,
    clientId,
    companyId,
    tariffModelId,
    tariffModelGroupId,
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { abonement: abonementAccess } = useAcl((acl) => acl);
  const { defaultCompany, defaultCompanyUuid } = useStoredCompanies();

  const isShowCancelRefund = defaultCompany
    ? todayHour() <
      head(defaultCompany?.work_schedules_list[todayWeekday()]?.max?.split(':'))
    : false;

  const [isSendNotify, setIsSendNotify] = useState<null | boolean>(null);
  const [isSendNotifyLoading, setIsSendNotifyLoading] = useState(false);
  const [formingScheduleListLoading, setFormingScheduleListLoading] =
    useState(false);

  const {
    visitList,
    loading: visitListLoading,
    error: visitListError,
    limit: visitListLimit,
    total: visitTotal,
    page: visitPage,

    handleCreateClientVisit,
    handleEditClientVisit,
    handleDeleteClientVisits,
    handleRefreshClientVisits,
  } = useStoredClientVisitList({
    abonementUuid: abonementId!,
    limit: 5,
  });

  const {
    abonement,
    latestStatus,
    loading: abonementLoading,
    error: abonementError,
    isHasScheduleList,

    handleChangeClientAbonementNotify,
    handleRefundsClientAbonement,
    handleCalculateRefundsClientAbonement,
    updateStoreClientAbonement,
  } = useStoredClientAbonement({
    abonementUuid: abonementId!,
    clientUuid: clientId!,
  });

  const {
    subscriptionList,
    loading: subscriptionListLoading,
    error: subscriptionListError,
    limit: subscriptionListLimit,
    page: subscriptionListPage,

    handleCreateClientAbonementSubscription,
    handleRefreshClientAbonementSubscriptions,
  } = useStoredClientAbonementSubscriptionList({
    abonementUuid: abonementId!,
    limit: 5,
    updateStoreClientAbonement,
  });

  const {
    scheduleList,
    loading: scheduleListLoading,
    error: scheduleListError,
    total,
    isShowClassSchedule,
    setTotal,

    handleFormingClientAbonementSchedule,
    handleRefreshClientAbonementSchedules,
    updateClientAbonementSchedules,
  } = useStateClientAbonementScheduleList({
    abonementUuid: abonementId!,
    clientUuid: clientId!,
    loadOnInit: false,
    isAbonementHasScheduleList: isHasScheduleList,
  });

  const monthDates = getDatesInMonthDisplay({
    month: getMonth(moment(new Date())) + 1,
    year: getYear(moment(new Date())),
  });

  const { start, end } = getTimesOfDates(monthDates, new Date().toString());

  const { intervalCallback, handleClearInterval } = useInterval<string>(
    async () => {
      try {
        const scheduleLitModel = await updateClientAbonementSchedules({
          showLoading: true,
          start,
          end,
        });

        const schedulesTotal = scheduleLitModel?.total || 0;

        if (scheduleLitModel && schedulesTotal > total) {
          handleClearInterval();
          setTotal(schedulesTotal);
          setFormingScheduleListLoading(false);
        }
      } catch (error: any) {
        handleClearInterval();
        setFormingScheduleListLoading(false);
      }
    },
    API_CALL_ABONEMENT_TIMEOUT_DELAY,
    API_CALL_ABONEMENT_TIMEOUT_CLEAR,
    () => {
      setFormingScheduleListLoading(false);
    },
  );

  const formingClientAbonementSchedule = useCallback(
    async (value: ClientAbonementScheduleFormDTO) => {
      setFormingScheduleListLoading(true);
      await handleFormingClientAbonementSchedule(value);
      intervalCallback();
    },
    [handleFormingClientAbonementSchedule, intervalCallback],
  );

  const disabled =
    abonement?.abon_status_text === ClientAbonementStatusesText.ReturnedMoney;

  const isShowSchedules =
    Object.keys(abonement?.abon_schedule_list || {}).length ||
    abonement?.company_abonement?.service?.personality ===
      PERSONALITY_TYPE.GROUP ||
    !abonement?.manager?.uuid;

  const handleChangeNotify = useCallback(
    async (value: boolean) => {
      if (abonement) {
        setIsSendNotifyLoading(true);
        setIsSendNotify(value);

        const abonementModel = await handleChangeClientAbonementNotify(
          abonement?.uuid,
          value,
        );

        if (!(abonementModel instanceof ClientAbonementModel)) {
          setIsSendNotify(!value);
        }

        setIsSendNotifyLoading(false);
      }
    },
    [abonement, handleChangeClientAbonementNotify],
  );

  const loading = useStopLoading({
    loading: abonementLoading,
    error: abonementError,
    message: 'An error occurred during abonement loading',
  });

  const subscriptionsLoading = useStopLoading({
    loading: subscriptionListLoading,
    error: subscriptionListError,
    message: 'An error occurred during abonement subscriptions loading',
  });

  const visitsLoading = useStopLoading({
    loading: visitListLoading,
    error: visitListError,
    message: 'An error occurred during abonement visits loading',
  });

  const schedulesLoading = useStopLoading({
    loading: scheduleListLoading,
    error: scheduleListError,
    message: 'An error occurred during client abonement schedules loading',
  });

  const routes = useBreadcrumb(
    tariffModelGroupId
      ? [
          {
            path: `/${Routes.tariffModels}`,
            breadcrumbName: 'Tariff models',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}`,
            breadcrumbName: 'Show tariff model',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
            breadcrumbName: 'Tariff model groups',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}`,
            breadcrumbName: 'Tariff model group',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}`,
            breadcrumbName: 'Clients abonements',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}/${clientId}`,
            breadcrumbName: 'Client abonement',
          },
        ]
      : tariffModelId
      ? [
          {
            path: `/${Routes.tariffModels}`,
            breadcrumbName: 'Tariff models',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}`,
            breadcrumbName: 'Show tariff model',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.clientAbonements}`,
            breadcrumbName: 'Clients abonements',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.clientAbonements}/${abonementId}`,
            breadcrumbName: 'Client abonement',
          },
        ]
      : companyId
      ? [
          {
            path: `/${Routes.clientAbonements}/${Routes.companies}/${defaultCompanyUuid}`,
            breadcrumbName: 'Clients abonements',
          },
          {
            path: `/${Routes.clientAbonements}/${abonementId}/${Routes.clients}/${clientId}/${Routes.companies}/${defaultCompanyUuid}`,
            breadcrumbName: 'Client abonement',
          },
        ]
      : [
          {
            path: `/${Routes.clients}`,
            breadcrumbName: 'Clients',
          },
          {
            path: `/${Routes.clients}/${clientId}`,
            breadcrumbName: location?.state?.clientFullName || 'Show Client',
          },
          {
            path: `/${Routes.clients}/${clientId}/${Routes.clientAbonements}`,
            breadcrumbName: 'Client abonements',
          },
          {
            path: `/${Routes.clients}/${clientId}/${Routes.clientAbonements}/${abonementId}`,
            breadcrumbName: abonement?.abon_title || 'Client abonement',
          },
        ],
  );

  const changeFields = useCallback(
    (
      {
        client,
        abon_final_price,
        company_abonement,
        abon_start_date,
        abon_discount,
        abon_card_number,
        ...rest
      }: typeof CLIENT_ABONEMENT_REQUIRED_FIELD,
      abonement: ClientAbonementModel,
    ): IOrderRequiredFieldsWithFullName & any => {
      return {
        ...(latestStatus
          ? {
              [t('Status')]: (
                <AbonementLatestStatusView latestStatus={latestStatus} />
              ),
            }
          : []),
        company_abonement: (
          <TariffModelDetailsView
            tariffModel={abonement?.companyAbonementModel}
          />
        ),
        Price: (
          <>
            {abon_final_price ===
            correctPrice(abonement?.companyAbonementModel?.abon_price) ? (
              <StyledTitle fontSize={30} $color={theme.colors.success}>
                {`${
                  defaultCompany ? `${defaultCompany?.currency_symbol} ` : ''
                }${abon_final_price}`}
              </StyledTitle>
            ) : (
              <StyledPriceWrapper>
                <StyledPriceContainer>
                  <StyledTitle fontSize={30} $color={theme.colors.success}>
                    {`${defaultCompany?.currency_symbol} ${
                      abon_final_price || '0.00'
                    } / `}
                  </StyledTitle>
                  <StyledPrevCost fontSize={25}>
                    {correctPrice(
                      abonement?.companyAbonementModel?.abon_price || 0,
                    )}
                  </StyledPrevCost>
                </StyledPriceContainer>
                <StyledDescription $color={theme.colors.success}>
                  {`${t('including discount')} ${
                    abonement?.abon_discount || 0
                  }%`}
                </StyledDescription>
              </StyledPriceWrapper>
            )}
          </>
        ),
        abon_start_date: toDateByFormat(abon_start_date, 'YYYY-MM-DD'),
        ...(abonement?.clientModel?.uuid
          ? {
              client: <ClientDetailsView client={abonement?.clientModel} />,
            }
          : []),
        ...(abonement?.manager?.uuid
          ? {
              manager: (
                <EmployeeDetailsView employee={abonement?.managerModel} />
              ),
            }
          : []),
        ...rest,
        ...(abonement?.abon_card_number
          ? { abon_card_number: abonement?.abon_card_number }
          : {}),
        ...(abonement?.companyAbonementModel?.abon_can_refund_money
          ? []
          : {
              abon_can_refund_money: (
                <StyledTitle $color={theme.colors.error}>
                  {t('Refunds are not provided')}
                </StyledTitle>
              ),
            }),
        ...(abonement?.companyAbonementGroupModel?.uuid &&
        abonement?.companyAbonementModel?.uuid
          ? {
              [t('Tariff model group')]: (
                <TariffModelGroupView
                  group={abonement?.companyAbonementGroupModel}
                  abonementUuid={abonement?.companyAbonementModel?.uuid}
                />
              ),
            }
          : []),
      };
    },
    [defaultCompany, latestStatus, t, theme.colors.error, theme.colors.success],
  );

  const correctClientAbonementRequiredFields = {
    ...CLIENT_ABONEMENT_REQUIRED_FIELD,
    company_abonement: {
      abon_title: '',
      uuid: '',
    },
    client: {
      first_name: '',
      last_name: '',
      middle_name: '',
      saldo: '',
    },
  };

  const overviewData = useRequiredFields(
    abonement as any,
    correctClientAbonementRequiredFields,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack || tariffModelGroupId
        ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}`
        : tariffModelId
        ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.clientAbonements}`
        : companyId
        ? `/${Routes.app}/${Routes.clientAbonements}/${Routes.companies}/${defaultCompanyUuid}`
        : `/${Routes.app}/${Routes.clients}/${clientId}/${Routes?.clientAbonements}`,
    );
  }, [
    clientId,
    companyId,
    defaultCompanyUuid,
    location?.state?.goBack,
    navigate,
    tariffModelGroupId,
    tariffModelId,
  ]);

  useEffect(() => {
    if (abonement && isSendNotify === null) {
      setIsSendNotify(abonement?.abon_send_notification);
    }
  }, [abonement, isSendNotify]);

  return (
    <ClientAbonementTabPanel
      abonement={abonement}
      latestStatus={latestStatus}
      disabled={disabled}
      routes={routes}
      isShowSchedules={!!isShowSchedules}
      subscriptionList={subscriptionList}
      subscriptionListLoading={subscriptionListLoading}
      subscriptionListLimit={subscriptionListLimit}
      subscriptionListPage={subscriptionListPage}
      handleRefreshClientAbonementSubscriptions={
        handleRefreshClientAbonementSubscriptions
      }
      handleCreateClientAbonementSubscription={
        handleCreateClientAbonementSubscription
      }
      visitList={visitList}
      visitListLoading={visitListLoading}
      visitListLimit={visitListLimit}
      visitTotal={visitTotal}
      visitPage={visitPage}
      handleCreateClientVisit={handleCreateClientVisit}
      handleEditClientVisit={handleEditClientVisit}
      handleDeleteClientVisits={handleDeleteClientVisits}
      handleRefreshClientVisits={handleRefreshClientVisits}
      scheduleList={scheduleList}
      schedulesLoading={schedulesLoading}
      formingScheduleListLoading={formingScheduleListLoading}
      isShowClassSchedule={isShowClassSchedule}
      isStartRefreshSchedules={
        !!abonement?.uuid &&
        !!Object.keys(abonement?.abon_schedule_list || {}).length &&
        !!abonement?.manager?.uuid
      }
      handleRefreshClientAbonementSchedules={
        handleRefreshClientAbonementSchedules
      }
      handleFormingClientAbonementSchedule={formingClientAbonementSchedule}>
      {({ tabs, topTabs, headerTabs }) => (
        <OverviewLayout
          isCalendar
          moduleItem="abonement"
          aclItem={RoutesAcl[Routes.clientAbonements]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerTitle={textToUpperCase(t('Client abonement'))}
          disabled={!abonementAccess?.manage}
          loading={loading || subscriptionsLoading || visitsLoading}
          data={null}
          topTabs={topTabs}
          routes={routes}
          tabs={tabs}
          underHeaderContent={
            abonement ? (
              <OverviewLayoutDetails
                data={null}
                tabs={[]}
                topTabs={headerTabs}
              />
            ) : null
          }
          header={
            <Row gutter={10} justify="center">
              <StyledCol span={24}>
                <Space>
                  {abonement?.companyAbonementModel?.abon_can_refund_money &&
                  abonement?.abon_end_date ? (
                    <>
                      {disabled ? (
                        <>
                          {/*isShowCancelRefund ? (*/}
                          {/*<ClientRefundsButton*/}
                          {/*    editMode*/}
                          {/*    title={t('Cancel a refund')}*/}
                          {/*    tooltipTitle={t('Cancel a refund')}*/}
                          {/*    refund={ClientAbonementMapper.toClientRefundFormDTO(*/}
                          {/*        abonement,*/}
                          {/*    )}*/}
                          {/*    onSuccess={handleRefundsClientAbonement}*/}
                          {/*    handleCalculateRefundsClientAbonement={*/}
                          {/*      handleCalculateRefundsClientAbonement*/}
                          {/*    }*/}
                          {/*/>*/}
                          {/*) : null*/}
                          {null}
                        </>
                      ) : (
                        <ClientRefundsButton
                          refund={ClientAbonementMapper.toClientRefundFormDTO(
                            abonement,
                          )}
                          onSuccess={handleRefundsClientAbonement}
                          handleCalculateRefundsClientAbonement={
                            handleCalculateRefundsClientAbonement
                          }
                        />
                      )}
                    </>
                  ) : null}
                  {isShowSchedules ? null : (
                    <ClientAbonementScheduleButton
                      schedule={ScheduleCalendarMapper.toClientAbonementScheduleFormDTO(
                        abonement!,
                      )}
                      onSuccess={formingClientAbonementSchedule}
                    />
                  )}
                </Space>
              </StyledCol>
              <OverviewLayoutDetails
                data={overviewData}
                tabs={[]}
                defaultActiveKey="key1"
                dataContentExtra={
                  <StyledSwitchWrapper>
                    <StyledSwitch
                      onChange={handleChangeNotify}
                      checked={!!isSendNotify}
                      disabled={
                        isSendNotifyLoading ||
                        !abonementAccess?.manage ||
                        disabled
                      }
                    />
                    <Text>
                      {t('Notify the client about changes in the subscription')}
                    </Text>
                  </StyledSwitchWrapper>
                }
              />
            </Row>
          }
        />
      )}
    </ClientAbonementTabPanel>
  );
}
