import React from 'react';
import { useAcl } from '@contex';
import { ClientIcon } from './ClientIcon';
import { ClientTitle } from './ClientTitle';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ClientModel } from '@structure';
import styled from 'styled-components';
import { ClientBalanceView } from './ClientBalanceView';
import { Link } from '@components/lib/DataDisplay';
import { StyledDescription } from '@components/lib/Styled';
import { ClientPersonalityType, Routes } from '@services/types';
import { ClientGroupDetailsView } from './ClientGroupDetailsView';

export interface IClientDetailsViewProps {
  client: ClientModel | null;
  children?: React.ReactNode | React.ReactNode[];
}

const StyledClientDetailsContainer = styled.div``;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  align-items: center;
`;

export function ClientDetailsView({
  client,
}: IClientDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { manage } = useAcl(({ client }) => client);

  return (
    <StyledClientDetailsContainer className="client-details__view">
      {client ? (
        <>
          {client?.client_type === ClientPersonalityType.Individual ? (
            <StyledSpace>
              {manage ? (
                <StyledLink
                  to={`/${Routes.app}/${Routes.clients}/${client?.uuid}`}
                  tooltip={`${t('Go to client details page')}${
                    client?.client_is_conflict
                      ? ' / ' + t('Client is conflicted')
                      : ''
                  }${
                    client?.client_is_tav_payer
                      ? ' / ' + t('A payer of additional value tax')
                      : ''
                  }`}
                  state={{ goBack: location.pathname }}>
                  <ClientTitle client={client} />
                </StyledLink>
              ) : (
                <ClientTitle client={client} />
              )}

              <ClientBalanceView client={client} />
              <StyledDescription>{client?.phone}</StyledDescription>
            </StyledSpace>
          ) : (
            <ClientGroupDetailsView
              client={client}
              icon={<ClientIcon client={client} />}
            />
          )}
        </>
      ) : null}
    </StyledClientDetailsContainer>
  );
}
