/**
 * @module http
 */
import axios, { Method } from 'axios';
import {
  AcceptLanguageItem,
  EventEmitterItems,
  LocalStorageItems,
  userServiceUrl,
} from '../const';
import { tokenHandler } from './token';
import { ILiteralObj, Routes } from '../types';
import { curry, EventEmitter, getCookie, isFunction } from '../helpers';

const { location } = window;

const isDestroyToken = () => {
  const token = tokenHandler.getAccessToken() || getCookie('access_token');
  if (token) {
    tokenHandler.destroyToken();
  }
};

interface ErrorStatusActions {
  status: number;
  error: { message: string; status: number };
  ignoreRedirect: boolean | ErrorsStatus[];
  reject: any;
  withCheckAccessToken?: boolean;
  rejectRequest?: (...arg: any[]) => Promise<any>;
  headers?: ILiteralObj;
  serverErrorRedirect?: boolean;
}

interface IRefreshTokenProps {
  headers?: ILiteralObj;
  errorCallback: (value: Partial<ErrorStatusActions>) => Promise<any>;
  rejectRequest?: (...arg: any[]) => Promise<any>;
}

export const onRefreshToken = async ({
  headers = {},
  errorCallback,
  rejectRequest,
}: IRefreshTokenProps): Promise<void> => {
  try {
    const { refresh_token: refreshToken, ...restHeaders } = headers;

    const refresh_token =
      refreshToken ||
      tokenHandler.getRefreshToken() ||
      getCookie('refresh_token');

    const { data }: any = await axios.post(`${userServiceUrl}/token/refresh`, {
      token: refresh_token,
    });

    tokenHandler.setAccessToken(data.access_token);
    tokenHandler.setRefreshToken(data.refresh_token);

    if (isFunction(rejectRequest)) {
      const updatedHeaders = {
        ...restHeaders,
        ...{
          Authorization: `Bearer ${data.access_token}`,
        },
      };

      await rejectRequest({ headers: updatedHeaders });
    }
  } catch (error: any) {
    const errorMessage = {
      message: error.response?.data.error || error.message,
      status: error.response?.status || error?.status,
    };

    await errorCallback({
      status: error.response?.status,
      error: errorMessage,
      serverErrorRedirect: true,
    });
  }
};

export enum ErrorsStatus {
  Bad_Request = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  Gone = 410,
  Unprocessable_Entity = 422,
  Expired_Invalid_Token = 498,
  Server_Error = 500,
  Unknown = 600,
}

const isIgnoreRedirect = curry<[boolean | number[], number], boolean>(
  (redirect: boolean | number[], status: number): boolean =>
    Array.isArray(redirect) ? !redirect.includes(status) : !redirect,
);

const navigateToLogin = (): void => {
  isDestroyToken();
  location.assign(`${location.origin}/${Routes.auth}/${Routes.authOffice}`);
};

const navigateAccessDeniedPage = (accessDeniedMessage: string): void => {
  localStorage.setItem(
    LocalStorageItems.accessDeniedPageMessage,
    accessDeniedMessage,
  );

  EventEmitter.emit(
    EventEmitterItems.auth_navigate_to_access_denied_page,
    location.pathname,
    accessDeniedMessage,
  );
  EventEmitter.emit(
    EventEmitterItems.app_navigate_to_access_denied_page,
    location.pathname,
    accessDeniedMessage,
  );
};

export const errorStatusActions = async ({
  status,
  error,
  ignoreRedirect,
  reject,
  withCheckAccessToken,
  rejectRequest,
  headers,
  serverErrorRedirect = false,
}: ErrorStatusActions): Promise<any> => {
  const isRedirect = isIgnoreRedirect(ignoreRedirect);

  switch (status) {
    case ErrorsStatus.Unauthorized:
      if (withCheckAccessToken && isRedirect(ErrorsStatus.Unauthorized)) {
        return await onRefreshToken({
          headers,
          rejectRequest,
          errorCallback: async (value: any) =>
            await errorStatusActions({
              reject,
              ignoreRedirect,
              withCheckAccessToken: false,
              ...value,
            }),
        });
      }

      if (isRedirect(ErrorsStatus.Unauthorized)) {
        navigateToLogin();
      }
      return reject(error);

    case ErrorsStatus.Forbidden:
      if (isRedirect(ErrorsStatus.Forbidden)) {
        navigateAccessDeniedPage(error?.message);
      }

      return reject(error);

    case ErrorsStatus.Conflict:
      return reject(error);

    case ErrorsStatus.Expired_Invalid_Token:
      if (isRedirect(ErrorsStatus.Expired_Invalid_Token)) {
        navigateToLogin();
      }
      return reject(error);

    default:
      if (
        (status >= ErrorsStatus.Server_Error &&
          status < ErrorsStatus.Unknown) ||
        !status
      ) {
        if (serverErrorRedirect) {
          navigateToLogin();
        }
        return reject(error);
      }
      break;
  }
};

/**
 * @name request
 * @desc Makes a request to backend api.
 * @param {string} uri
 * @param {{}} [data]
 * @param {string} [method]
 * @param {boolean} [ignoreRedirect]
 * @param {object} inHeaders
 * @param {Boolean} isMultipartData - якщо необхідно передати бінарні дані на сервер
 * @returns {Promise<{}>}
 * @throws {UnauthorizedApiError}
 * @throws {GeneralApiError}
 * @async
 * @public
 */
export async function request<T, U>(
  uri: string,
  data: T | null = null,
  method: Method = 'POST',
  ignoreRedirect: boolean | ErrorsStatus[] = false,
  inHeaders: ILiteralObj = {},
  isMultipartData: boolean = false,
): Promise<U> {
  return new Promise(async (resolve, reject) => {
    let body: any;
    if (data) {
      body = isMultipartData ? data : JSON.stringify(data);
    }
    const appInfo = localStorage.getItem(LocalStorageItems.xAppInfo);
    const deviceInfo = localStorage.getItem(LocalStorageItems.xDeviceInfo);

    const language = localStorage.getItem(LocalStorageItems.language) || 'ua';

    const { refresh_token, ...restHeaders } = inHeaders;

    let headers = {
      ...{
        'Content-Type': 'application/json',
        'Content-Language': 'ua,en,ru',
        'Accept-Language': AcceptLanguageItem[language],
      },
      [LocalStorageItems.xAppInfo]: appInfo,
      [LocalStorageItems.xDeviceInfo]: deviceInfo,
      ...restHeaders,
    };

    const token = tokenHandler.getAccessToken() || getCookie('access_token');

    if (token) {
      headers = {
        ...{
          Authorization: `Bearer ${token}`,
        },
        ...headers,
      };
    }

    if (isMultipartData) {
      // @ts-ignore
      delete headers['Content-Type'];
    }

    const request = async (value: any = {}) => {
      const res = await axios({
        method,
        url: uri,
        headers,
        data: body,
        withCredentials: true,
        ...value,
      });
      return resolve(res?.data);
    };

    try {
      await request();
    } catch (error: any) {
      const errorMessage = {
        message: error.response?.data?.error || error?.message,
        status: error.response?.status || error?.status,
      };

      const updateHeaders = (({ Authorization, ...rest }: any) => ({
        ...rest,
        refresh_token,
      }))(headers);

      await errorStatusActions({
        status: errorMessage?.status,
        error: errorMessage,
        ignoreRedirect,
        reject,
        withCheckAccessToken: true,
        rejectRequest: request,
        headers: updateHeaders,
      });

      return reject(errorMessage);
    }
  });
}
