import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateClientFileList,
  useStopLoading,
} from '@hooks';
import {
  len,
  lessOrEqualThan,
  textToUpperCase,
  toDateByFormat,
} from '@services/helpers';
import {
  ClientFileType,
  EmployeeModel,
  FileModel,
  StoreDocumentModel,
} from '@structure';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  ClientPersonalityType,
  Routes,
  Routes as RoutesType,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useAcl, useDropdownAlert } from '@contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
  ListHeader,
  Segmented,
  TableIndexField,
} from '@components/lib/DataDisplay';
import { useNavigate, useParams } from 'react-router';
import { ClientFileDetailsView, ClientFileListGallery } from '../Show';
import styled from 'styled-components';
import { OrderDetailsView } from '../../Orders';
import { ClientFileView } from '../Show/ClientFileView';
import { StyledDescription } from '@components/lib/Styled';

import '../../Orders/List/OrderList.less';

const StyledSegmentedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const FILE_VIEW = [
  {
    uuid: ClientFileType.ALL,
    title: <ClientFileView title="Table" view={ClientFileType.ALL} />,
  },
  {
    uuid: ClientFileType.IMAGE,
    title: <ClientFileView title="Gallery" view={ClientFileType.IMAGE} />,
  },
];

export function ClientFileListPage() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const { manage } = useAcl(({ client }) => client);
  const { alert } = useDropdownAlert();

  const [selectedClients, setSelectedClients] = React.useState<FileModel[]>([]);

  const [fileType, setFileType] = React.useState(ClientFileType.ALL);

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'file-create-event',
    disabled: !manage,
  });

  const {
    files,
    loading: filesListLoading,
    error: filesListError,
    limit: pageSize,
    total,
    keywords,
    isLoadingMore,
    page,
    client,

    handleSearchClientFiles,
    handleRefreshClientFiles,
    handleLoadingMoreClientFileList,
  } = useStateClientFileList({
    clientUuid: clientId!,
    fileType: ClientFileType.ALL,
  });

  const loading = useStopLoading({
    error: filesListError,
    loading: filesListLoading,
    message: 'An error occurred during files loading',
  });

  const { inputRef } = useSearchInputFocus({ loading });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
      state: {
        type: ClientPersonalityType.Individual,
      },
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: client?.fullNameClient || 'Show Client',
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.files}`,
      breadcrumbName: 'Files',
    },
  ]);

  const rowSelection = React.useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedClients.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: FileModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 3)) {
          setSelectedClients(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 3 at a time'));
        }
      },
    }),
    [alert, selectedClients, t],
  );

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * pageSize + index + 1} />
      ),
    },
    {
      title: t('Title'),
      key: 'file',
      render: (file: FileModel) => <ClientFileDetailsView file={file} />,
    },
    {
      title: t('Created'),
      key: 'created',
      render: (file: FileModel) => {
        return (
          <DetailsItemView<EmployeeModel>
            item={file?.createdByModel}
            fields={{
              fullName: {
                title: '',
              },
              uuid: {
                description: (
                  <StyledDescription>
                    {toDateByFormat(file?.created_at, 'DD.MM.YYYY HH:mm')}
                  </StyledDescription>
                ),
              },
            }}
          />
        );
      },
    },
    {
      title: t('Resource object'),
      key: 'created',
      render: (file: FileModel) => (
        <OrderDetailsView schedule={file?.fileableModel} />
      ),
    },
  ];

  const navigateGoBack = React.useCallback(() => {
    navigate(`/${Routes.app}/${Routes.clients}/${clientId}`);
  }, [clientId, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.clients]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.files]))}
      routes={routes}
      empty={
        files?.size ? null : (
          <Empty
            description={
              fileType === ClientFileType.IMAGE
                ? t("It looks like you don't have any photos at the moment.")
                : t(`It looks like you don't have any files at the moment.`)
            }
          />
        )
      }
      headerExtra={
        <ListHeader>
          <ListActions
            searchText={keywords}
            inputRef={inputRef}
            withoutPicker
            loading={loading}
            handleSearch={(keywords) => handleSearchClientFiles({ keywords })}
            inputTooltip={t('Search files')}
            inputLabel={t('Search files')}
            withSearchEndContent={
              <StyledSegmentedContainer>
                <Segmented
                  options={FILE_VIEW}
                  disabled={loading}
                  value={fileType}
                  onChange={async (fileType: any) => {
                    setFileType(fileType);
                    await handleRefreshClientFiles({
                      fileType,
                      limit: fileType === ClientFileType.IMAGE ? 100 : 10,
                      page: 1,
                    });
                  }}
                />
              </StyledSegmentedContainer>
            }
          />
        </ListHeader>
      }
      loading={loading && !files?.size}>
      {fileType === ClientFileType.IMAGE ? (
        <ClientFileListGallery
          files={files}
          loading={loading}
          isLoadingMore={isLoadingMore}
          handleLoadingMoreClientFileList={handleLoadingMoreClientFileList}
        />
      ) : (
        <Table<FileModel>
          rowSelection={rowSelection}
          total={total}
          pageSize={pageSize}
          dataSource={files}
          onChange={handleRefreshClientFiles}
          columns={columns}
          loading={loading}
          page={page}
        />
      )}
    </ListLayout>
  );
}
