import React from 'react';
import { useTranslation } from 'react-i18next';
import { correctPrice, isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IDefaultTabPanelProps, TabItem } from '@components/lib/General';
import { useLocation, useNavigate } from 'react-router';
import { Routes } from '@services/types';
import {
  IUseStateExpenseListReturnType,
  IUseStateScheduleReturnType,
  useStoredCompanies,
} from '@hooks';
import { useTheme } from 'styled-components';
import { ExpenseList } from '../List';

export enum ORDER_PAGE_TABS {
  RELATED_ORDER_LIST = 'Related orders',
  MATERIALS_AND_EXPENSES = 'Services and materials',
  STATUS = 'Order status',
}

export interface IOrderPageTabPanelProps
  extends Pick<IUseStateScheduleReturnType, 'schedule'> {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  expanseListParams: IUseStateExpenseListReturnType;

  isDisabledScheduleAction: boolean;
}

export default function OrderPageTabPanel({
  children,
  schedule,
  expanseListParams,
  isDisabledScheduleAction,
}: IOrderPageTabPanelProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { defaultCompany } = useStoredCompanies();

  const disabled = schedule?.schedule_has_strict_mode;

  const calendarEntryTabs: TabItem[] = [
    // ...(disabled
    //   ? []
    //   : [
    //       {
    //         defaultActiveKey: ORDER_PAGE_TABS.MATERIALS_AND_EXPENSES,
    //         key: ORDER_PAGE_TABS.MATERIALS_AND_EXPENSES,
    //         title: t(ORDER_PAGE_TABS.MATERIALS_AND_EXPENSES),
    //         description:
    //           Number(expanseListParams?.aggregatedSum?.total_sum) > 0 ||
    //           expanseListParams?.expenseList?.size
    //             ? `${t('In the amount of')}: ${
    //                 defaultCompany?.currency_symbol
    //               } ${correctPrice(
    //                 expanseListParams?.aggregatedSum?.total_sum || 0,
    //               )}`
    //             : {
    //                 text: t('There are no services and materials'),
    //                 color: theme.colors.warning,
    //               },
    //         disabled: false,
    //         renderer: () => (
    //         ),
    //       },
    //     ]),
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({ tabs: calendarEntryTabs })}</>;
}
