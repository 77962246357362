import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import AppFooter from './AppFooter';

withTranslation()(FooterWithoutLayout);

interface IFooterWithoutLayout {
  collapsed?: boolean;
}

const StyledFooter = styled(AppFooter)`
  background-color: transparent;

  & .footer__text {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default function FooterWithoutLayout({
  collapsed = false,
}: IFooterWithoutLayout): JSX.Element {
  return <StyledFooter collapsed={collapsed} />;
}
