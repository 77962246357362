import React, { useCallback, useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import Color from 'color';
import { useTranslation } from 'react-i18next';
import { debounce } from '@services/helpers';
import {
  IUseStateFileListReturnType,
  IUseStateScheduleReturnType,
} from '@hooks';
import { BsPaperclip } from 'react-icons/bs';

const { Dragger } = Upload;

export interface IFileDraggerViewProps
  extends Pick<IUseStateFileListReturnType, 'handleUploadFiles'>,
    Pick<IUseStateScheduleReturnType, 'stats'>,
    UploadProps {
  handleOpenFileList?: () => void;
  hiddeUploadArea?: boolean;
  description?: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 20px;
  background-color: '#87ceeb';
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: fit-content;
  height: fit-content;
  padding: 0;

  &:hover > * {
    color: ${({ theme }) =>
      Color(theme.colors.link).alpha(1).darken(0.3) as any};
  }

  &&&:hover,
  &&&:active,
  &&&:focus {
    background: transparent;
  }

  & > * {
    color: ${({ theme }) => theme.colors.link};
  }
`;

export function FileDraggerView({
  handleUploadFiles,
  handleOpenFileList,
  stats,
  hiddeUploadArea,
  description = 'Files that will be attached to the order. Single or mass download support.',
  multiple = true,
  onChange,
  ...rest
}: IFileDraggerViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpload = useCallback(
    debounce(async (fileList: any) => {
      try {
        setUploading(true);
        await handleUploadFiles(fileList);
        setUploading(false);
      } catch (error: any) {
        setUploading(false);
      }
    }, 1000),
    [],
  );

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange: async (info) => {
      const { status } = info.file;

      if (status !== 'uploading') {
      }

      await handleUpload(multiple ? info.fileList : [info.file]);
    },
    onDrop(e) {},
  };

  return (
    <StyledContainer>
      {hiddeUploadArea ? null : (
        <Dragger
          {...rest}
          {...props}
          multiple={multiple}
          disabled={uploading}
          showUploadList={false}
          customRequest={() => {}}>
          <p className="ant-upload-drag-icon">
            {uploading ? <LoadingOutlined /> : <InboxOutlined />}
          </p>
          <p className="ant-upload-text">
            {t('Click or drag file to this area to upload')}
          </p>
          <p className="ant-upload-hint">{t(description)}</p>
        </Dragger>
      )}

      {Number(stats?.files) > 0 ? (
        <StyledButton type="text" onClick={handleOpenFileList}>
          <BsPaperclip fontSize={15} />
          <StyledTitle>{`${stats?.files} ${t('attached files')}`}</StyledTitle>
        </StyledButton>
      ) : null}
    </StyledContainer>
  );
}
