import React from 'react';
import { Layout } from 'antd';
import { IChild } from '@services/types';
import './WithoutLayout.less';

const { Content } = Layout;

export interface IWithoutLayout extends IChild {}

export default function WithoutLayout({
  children,
}: IWithoutLayout): JSX.Element {
  return (
    <Layout data-testid="app-layout" className="Without-layout">
      <Content data-testid="app-content" className="Without-content">
        {children}
      </Content>
    </Layout>
  );
}
