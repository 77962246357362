import { Dispatch } from 'redux';
import { ABONEMENT_GROUP_LIST } from '../constants';
import { AbonementGroupListModel, AbonementGroupModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetAbonementGroupListParam {
  type: ABONEMENT_GROUP_LIST;
  groupList: AbonementGroupListModel;
  abonementUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddAbonementGroupToListParam {
  type: ABONEMENT_GROUP_LIST;
  group: AbonementGroupModel;
}

export interface IUpdateAbonementGroupFromListParam {
  type: ABONEMENT_GROUP_LIST;
  group: AbonementGroupModel;
}

export interface IDeleteAbonementGroupFromListParam {
  type: ABONEMENT_GROUP_LIST;
  groupUuid: string;
}

export interface IErrorAbonementGroupListParam {
  type: ABONEMENT_GROUP_LIST;
  error: ApiError | undefined;
}

export function setAbonementGroupList(
  groupList: AbonementGroupListModel,
  abonementUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetAbonementGroupListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.SET_ABONEMENT_GROUP_LIST,
      groupList,
      keywords,
      total,
      page,
      abonementUuid,
    });
  };
}

export function loadMoreAbonementGroupList(
  groupList: AbonementGroupListModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<ISetAbonementGroupListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.LOAD_MORE_ABONEMENT_GROUP_LIST,
      groupList,
      abonementUuid,
    });
  };
}

export function addAbonementGroupToList(
  group: AbonementGroupModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IAddAbonementGroupToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.ADD_ABONEMENT_GROUP,
      group,
      abonementUuid,
    });
  };
}

export function updateAbonementGroupFromList(
  group: AbonementGroupModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateAbonementGroupFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.UPDATE_ABONEMENT_GROUP,
      group,
      abonementUuid,
    });
  };
}

export function deleteAbonementGroupFromList(
  groupUuid: string,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteAbonementGroupFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.DELETE_ABONEMENT_GROUP,
      groupUuid,
      abonementUuid,
    });
  };
}

export function errorAbonementGroupInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorAbonementGroupListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_GROUP_LIST.ERROR_ABONEMENT_GROUP_LIST,
      error,
    });
  };
}
