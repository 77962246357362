import * as React from 'react';
import { getServiceById } from '@services/api/services';
import { ServiceModel, ServiceMapper, ServiceDTO } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
  useNotify,
} from '@components/lib/libV2/hooks';
import { IEntityProps } from '@services/types';

export interface IUseServiceProps extends IEntityProps {
  serviceUuid: string;
}

export interface IUseServiceReturnType {
  error: null | any;
  loading: boolean;
  entity: ServiceModel | null;
  refresh: (value: Partial<IUseServiceProps>) => Promise<ServiceModel | void>;
  serviceUuid: string;
}

export default function useService(
  { loadOnInit = true, serviceUuid }: IUseServiceProps = {} as IUseServiceProps,
): IUseServiceReturnType {
  const { notifyErrorMessage } = useNotify();
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    serviceUuid: entityUuid,
  } = useStateReducer<Omit<IUseServiceReturnType, 'refresh'>>({
    entity: null,
    serviceUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      serviceUuid = entityUuid,
    }: Partial<IUseServiceProps> = {}): Promise<ServiceModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null, serviceUuid });

        const service = await cancellablePromise<ServiceDTO>(
          getServiceById(serviceUuid),
        );

        const serviceModel = ServiceMapper.toServiceModel(service);

        if (!didCancel.current) {
          handleUpdate({
            entity: serviceModel,
            loading: false,
          });
          return serviceModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }

        notifyErrorMessage('Service call', error);
      }
    },

    [
      cancellablePromise,
      didCancel,
      entityUuid,
      error,
      handleUpdate,
      notifyErrorMessage,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && serviceUuid) {
      (async () => {
        await refresh({ serviceUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, serviceUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    serviceUuid,
  };
}
