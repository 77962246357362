import { List } from 'immutable';
import { APP_STATE, PRODUCT_LEFTOVERS_LIST } from '../constants';
import { isEqualByUuid, not, compose, head } from '@services/helpers';
import { REDUX_STATUS, ApiError, ProductType } from '@services/types';
import {
  IProductsListStatsProps,
  ProductModel,
  ProductListModel,
} from '@structure';

interface IProductLeftoversListState {
  productList: ProductListModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

interface SetActionList
  extends Pick<
    IProductLeftoversListState,
    'productList' | 'keywords' | 'total' | 'page' | 'stats'
  > {
  type: PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST;
}

interface SetStatsActionList extends Pick<IProductLeftoversListState, 'stats'> {
  type: PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST_STATS;
}

interface StatsActionList {
  type:
    | PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS_LIST_STATS
    | PRODUCT_LEFTOVERS_LIST.SUB_PRODUCT_LEFTOVERS_LIST_STATS;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS;
  product: ProductModel;
}

interface UpdateProductLeftoversFromList {
  type: PRODUCT_LEFTOVERS_LIST.UPDATE_PRODUCT_LEFTOVERS;
  product: ProductModel;
}

interface DeleteActionFromList {
  type: PRODUCT_LEFTOVERS_LIST.DELETE_PRODUCT_LEFTOVERS;
  productUuid: string;
}

interface LoadingActionInList {
  type: PRODUCT_LEFTOVERS_LIST.LOADING_PRODUCT_LEFTOVERS_LIST;
}

interface ErrorActionInList extends Pick<IProductLeftoversListState, 'error'> {
  type: PRODUCT_LEFTOVERS_LIST.ERROR_PRODUCT_LEFTOVERS_LIST;
}

interface Handlers {
  [PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
    action: SetActionList,
  ) => IProductLeftoversListState;
  [PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST_STATS]: (
    state: IProductLeftoversListState,
    action: SetStatsActionList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS_LIST_STATS]: (
    state: IProductLeftoversListState,
    action: StatsActionList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.SUB_PRODUCT_LEFTOVERS_LIST_STATS]: (
    state: IProductLeftoversListState,
    action: StatsActionList,
  ) => IProductLeftoversListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductLeftoversListState,
    action: SetInitialStateAction,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.LOAD_MORE_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
    action: SetActionList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS]: (
    state: IProductLeftoversListState,
    action: AddActionToList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.UPDATE_PRODUCT_LEFTOVERS]: (
    state: IProductLeftoversListState,
    action: UpdateProductLeftoversFromList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.DELETE_PRODUCT_LEFTOVERS]: (
    state: IProductLeftoversListState,
    action: DeleteActionFromList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.ERROR_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
    value: ErrorActionInList,
  ) => IProductLeftoversListState;

  [PRODUCT_LEFTOVERS_LIST.LOADING_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
    value?: LoadingActionInList,
  ) => IProductLeftoversListState;
  DEFAULT: (state: IProductLeftoversListState) => IProductLeftoversListState;
}

const initState: IProductLeftoversListState = {
  productList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
  stats: {} as IProductsListStatsProps,
};

const handlers: Handlers = {
  [PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST]: (
    state,
    { productList, keywords, total, page, stats },
  ) => ({
    ...state,
    ...{
      productList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST_STATS]: (
    state,
    { stats },
  ) => ({
    ...state,
    ...{
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS_LIST_STATS]: (state) => ({
    ...state,
    ...{
      stats: {
        ...state?.stats,
        [ProductType.Category]:
          (Number((state?.stats || {})[ProductType.Category]) || 0) + 1,
      } as any,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.SUB_PRODUCT_LEFTOVERS_LIST_STATS]: (state) => ({
    ...state,
    ...{
      stats: {
        ...state?.stats,
        [ProductType.Category]:
          Number((state?.stats || {})[ProductType.Category]) &&
          Number((state?.stats || {})[ProductType.Category]) > 0
            ? Number((state?.stats || {})[ProductType.Category]) - 1
            : 0,
      } as any,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_LEFTOVERS_LIST.LOAD_MORE_PRODUCT_LEFTOVERS_LIST]: (
    state,
    { productList },
  ) => ({
    ...state,
    ...{
      productList: state.productList
        ? state.productList.update('products', (products) =>
            List.isList(products)
              ? products?.merge(productList?.products || [])
              : products,
          )
        : state.productList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS]: (state, { product }) => ({
    ...state,
    ...{
      productList: state.productList
        ? state.productList.products?.size >= 10
          ? state.productList.update('products', (products) =>
              products.pop().unshift(product),
            )
          : state.productList.update('products', (products) =>
              products.unshift(product),
            )
        : new ProductListModel({ products: [] }),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
      stats: {
        ...state?.stats,
        [ProductType.Product]:
          (Number((state?.stats || {})[ProductType.Product]) || 0) + 1,
      } as any,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.UPDATE_PRODUCT_LEFTOVERS]: (state, { product }) => ({
    ...state,
    ...{
      productList: state.productList
        ? state?.productList?.update('products', (products) =>
            products.map((stateProduct) => {
              if (
                stateProduct.uuid === product?.uuid &&
                head(stateProduct?.product_balances)?.company_store?.uuid ===
                  head(product?.product_balances)?.company_store?.uuid
              ) {
                return product;
              }
              return stateProduct;
            }),
          )
        : new ProductListModel({ products: [] }),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.DELETE_PRODUCT_LEFTOVERS]: (
    state,
    { productUuid },
  ) => ({
    ...state,
    ...{
      productList: state.productList
        ? state.productList?.update('products', (products) =>
            products.filter(compose(not, isEqualByUuid(productUuid))),
          )
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
      stats: {
        ...state?.stats,
        [ProductType.Product]:
          Number((state?.stats || {})[ProductType.Product]) &&
          Number((state?.stats || {})[ProductType.Product]) > 0
            ? Number((state?.stats || {})[ProductType.Product]) - 1
            : 0,
      } as any,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.ERROR_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT_LEFTOVERS_LIST.LOADING_PRODUCT_LEFTOVERS_LIST]: (
    state: IProductLeftoversListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductLeftoversListState) => state,
};

export default function ProductLeftoversList(
  state: any = initState,
  action: any,
): IProductLeftoversListState {
  const handler =
    handlers[action.type as PRODUCT_LEFTOVERS_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
