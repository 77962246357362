import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceStatus } from '@structure';
import { Empty, Select } from 'antd';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { IUseStateInvoiceReturnType } from '@hooks';
import { useDropdownAlert } from '@contex';

import '../../Statuses/Show/StatusListSelect.less';

export interface InvoiceStatusListSelectProps
  extends Pick<
    IUseStateInvoiceReturnType,
    'invoice' | 'handleUpdateInvoiceStatus'
  > {
  loading: boolean;
  className?: string;
  children?: React.ReactNode;
}

export interface IStatusOption {
  label: string | React.ReactNode;
  value: string;
}

const StyledSelect = styled(Select)`
  &&& {
    width: auto !important;

    & > .ant-select-selector {
      border: none;
      box-shadow: none !important;
    }

    & > .ant-select-arrow {
      display: none;
    }
  }
`;

const iconStyle = css`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 10px;
`;

const StyledDownOutlined = styled(DownOutlined)`
  ${iconStyle}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  ${iconStyle}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap: 5px 0;
`;

const INVOICE_STATUS_LIST = [
  InvoiceStatus.DRAFT,
  InvoiceStatus.SENT,
  InvoiceStatus.COMMITTED,
];

export function InvoiceStatusListSelect({
  invoice,
  handleUpdateInvoiceStatus,
  loading: listLoading,

  className,

  children,
}: InvoiceStatusListSelectProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [options, setOptions] = useState<IStatusOption[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IStatusOption | null>(
    null,
  );

  const loading = listLoading || optionsLoading;

  const toOption = useCallback(
    (status: InvoiceStatus) => ({
      value: status,
      label: (
        <InvoiceStatusTag
          status={status}
          icon={loading ? <StyledLoadingOutlined /> : <StyledDownOutlined />}
        />
      ),
    }),
    [loading],
  );

  const handleChangeOption = useCallback(
    async (status: InvoiceStatus) => {
      setOptionsLoading(true);

      try {
        await handleUpdateInvoiceStatus(status);

        if (status) {
          setSelectedOption(toOption(status));
        }
        setOptionsLoading(false);
      } catch (error: any) {
        setOptionsLoading(false);
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during change status')} : ${error?.message}`,
        );
      }
    },
    [alert, handleUpdateInvoiceStatus, t, toOption],
  );

  useEffect(() => {
    if (!options?.length) {
      const options = INVOICE_STATUS_LIST.map((status) => ({
        value: status,
        label: <InvoiceStatusTag status={status} />,
      }));

      setOptions(options);
    }
  }, [invoice, options?.length, toOption]);

  useEffect(() => {
    if (invoice?.inv_status) {
      setSelectedOption(toOption(invoice?.inv_status));
    }
  }, [invoice?.inv_status, toOption]);

  return (
    <StyledContainer>
      <StyledSelect
        className={className}
        optionLabelProp="label"
        popupClassName="status-select"
        size="small"
        value={selectedOption}
        placeholder={t('Status')}
        style={{ width: 200 }}
        onChange={handleChangeOption as any}
        options={options}
        loading={loading}
        disabled={loading}
        notFoundContent={
          <Empty
            description={t(
              'No statuses were found to which the current status can go',
            )}
          />
        }
      />

      {children || null}
    </StyledContainer>
  );
}
