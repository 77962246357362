import {
  ISettingsModuleDependencyDTOProps,
  SettingsModuleDependencyMapper,
  SettingsModuleModel,
  ModuleInputParameterDTO,
  ModuleInputParameterMapper,
} from '../internal';
import { correctPrice } from '@services/helpers';

export enum SettingsModuleStatuses {
  Disabled,
  Active,
}

export interface ISettingsModuleDTOProps {
  uuid: string;
  module_title: string;
  module_markets: string;
  module_description: string;
  module_name: string;
  module_price: string;
  module_period: string;
  module_amount_of_periods: number;
  module_status: SettingsModuleStatuses;
  created_at: string;
  updated_at: string;
  dependencies: ISettingsModuleDependencyDTOProps[];
  module_enabled_for_company: boolean;
  module_input_parameters_json: ModuleInputParameterDTO[];
}

export class SettingsModuleDTO {
  public uuid: string;
  public module_title: string;
  public module_markets: string;
  public module_description: string;
  public module_name: string;
  public module_price: string;
  public module_period: string;
  public module_amount_of_periods: number;
  public module_status: SettingsModuleStatuses;
  public created_at: string;
  public updated_at: string;
  public dependencies: ISettingsModuleDependencyDTOProps[];
  public module_enabled_for_company: boolean;
  public module_input_parameters_json: ModuleInputParameterDTO[];

  constructor(props: ISettingsModuleDTOProps | SettingsModuleModel) {
    this.uuid = props?.uuid || '';
    this.module_title = props?.module_title || '';
    this.module_markets = props?.module_markets || '';
    this.module_description = props?.module_description || '';
    this.module_name = props?.module_name || '';
    this.module_price = correctPrice(props?.module_price || 0);
    this.module_period = props?.module_period || '';
    this.module_amount_of_periods = props?.module_amount_of_periods || 0;
    this.module_status = props?.module_status || SettingsModuleStatuses.Active;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.dependencies =
      SettingsModuleDependencyMapper.toSettingsModuleDependencyListDTO(
        props?.dependencies,
      );
    this.module_enabled_for_company =
      props?.module_enabled_for_company || false;
    this.module_input_parameters_json =
      ModuleInputParameterMapper.toModuleInputParameterListDTO(
        props?.module_input_parameters_json || [],
      );
  }
}
