import React, { useCallback, useRef, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { SearchSelect } from '@components/lib/DataDisplay';
import { useDefaultForm } from '@contex';
import { useTranslation } from 'react-i18next';
import { isListToArray, listToArray } from '@services/helpers';
import {
  IUseStateCashBoxListReturnType,
  IUseStateCompanyClientListReturnType,
  useSize,
} from '@hooks';
import {
  CashBoxModel,
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  PAYMENT_METHOD,
} from '@structure';
import { convertedPaymentMethodToCashBox } from '@services/api/companyPayments';
import { phoneScreen } from '@services/const';
import { CashBoxTitleView } from '../Show/CashBoxTitleView';
import { PlusOutlined } from '@ant-design/icons';
import { ClientCreateButton } from '../../Clients';

export interface IPaymentCashBoxFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients' | 'handleCreateLocalClient'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    > {
  clientsLoading: boolean;
  cashBoxListLoading: boolean;
}

export const FROM_PAYMENT_METHOD = [
  {
    title: 'tCash',
    uuid: PAYMENT_METHOD.Cash,
  },
  {
    title: 'Cashless',
    uuid: PAYMENT_METHOD.Bank,
  },
];

export const FROM_PAYMENT_METHOD_WITH_IN_DEBT = [
  ...FROM_PAYMENT_METHOD,
  {
    title: 'In debt',
    uuid: PAYMENT_METHOD.Account,
  },
];

export function PaymentCashBoxFields({
  clients,
  clientsLoading,
  handleSearchClients,
  handleCreateLocalClient,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,
}: IPaymentCashBoxFieldsProps): JSX.Element {
  const { loadingSubmit, handlerUpdateFormState, formData } = useDefaultForm();
  const { t } = useTranslation();

  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const contentRef = useRef(null);
  const onceClient = useRef(false);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const handleQuicklyCreateClient = useCallback(
    async ({ t, ...value }: any): Promise<void> => {
      const uuid = `create${
        (value as ClientFormDTO)?.first_name ||
        (value as ClientLegalFormDTO)?.client_org_name
      }${value.phone}`;

      await handleCreateLocalClient({
        ...value,
        uuid,
      } as any);

      handlerUpdateFormState({
        payment_agent_uuid: {
          ...value,
          uuid,
        },
      });
    },
    [handleCreateLocalClient, handlerUpdateFormState],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      if (
        formData?.payment_agent_uuid?.uuid !== e ||
        formData?.payment_agent_uuid instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          const client = clients?.find(({ uuid }) => uuid === e);

          if (client) {
            let payment_agent_uuid;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              payment_agent_uuid = ClientMapper.toClientFormDTO(client);
            } else {
              payment_agent_uuid = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({ payment_agent_uuid });
          }
          return;
        }

        handlerUpdateFormState({ payment_agent_uuid: { uuid: e } });
      }
    },
    [clients, formData?.payment_agent_uuid, handlerUpdateFormState],
  );

  const handleChangePaymentMethod = useCallback(
    (payment_method: any) => {
      handlerUpdateFormState({ payment_method });

      if (typeof formData?.payment_method === 'string') {
        handlerUpdateFormState({
          payment_cashbox_uuid: '',
        });

        setResetCashBoxState(true);
        setTimeout(() => {
          setResetCashBoxState(false);
        }, 100);
      }
    },
    [formData?.payment_method, handlerUpdateFormState],
  );

  const handleChangeCashBox = useCallback(
    (payment_cashbox_uuid: string) => {
      handlerUpdateFormState({ payment_cashbox_uuid });
    },
    [handlerUpdateFormState],
  );

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={isFullWidth}>
        <Form.Item
          label={t('Form Payment method')}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t('Payment method must be specified'),
            },
          ]}
          tooltip={t('Payment method')}>
          <SearchSelect
            selectFirst={false}
            name="payment_method"
            placeholder={t('Select a payment method')}
            disable={loadingSubmit || cashBoxListLoading}
            data={FROM_PAYMENT_METHOD_WITH_IN_DEBT}
            onChange={handleChangePaymentMethod}
          />
        </Form.Item>
      </Col>

      {formData?.payment_method === PAYMENT_METHOD.Account ? null : (
        <Col span={isFullWidth}>
          <Form.Item
            label={t('Cash box')}
            name="payment_cashbox_uuid"
            rules={[
              {
                required: true,
                message: t('Cash boxes must be specified'),
              },
            ]}
            tooltip={t('Cash box')}>
            <SearchSelect
              resetState={resetCashBoxState}
              selectFirst={false}
              onSearch={(keywords) =>
                handleSearchCashBoxes({
                  keywords,
                  limit: 100,
                  showLoading: false,
                })
              }
              getOptionValueTitle={(cashBox: CashBoxModel) => (
                <CashBoxTitleView
                  cashBox={cashBox}
                  disable={loadingSubmit || cashBoxListLoading}
                />
              )}
              name="payment_cashbox_uuid"
              placeholder={t('Select a cash box')}
              disable={loadingSubmit || cashBoxListLoading}
              data={isListToArray(
                cashBoxList?.filter(({ box_type, box_can_out_money_from }) => {
                  return (
                    box_type ===
                      convertedPaymentMethodToCashBox[
                        formData?.payment_method as PAYMENT_METHOD
                      ] && box_can_out_money_from
                  );
                }) as any,
              )}
              onChange={handleChangeCashBox}
            />
          </Form.Item>
        </Col>
      )}

      {formData?.payment_method === PAYMENT_METHOD.Account ? (
        <Col span={isFullWidth}>
          <Form.Item
            label={t('Client')}
            name="payment_agent_uuid"
            tooltip={t('Client')}>
            <SearchSelect
              allowClear
              asObject={true}
              selectFirst={clients?.size === 1}
              name="payment_agent_uuid"
              disable={clientsLoading || loadingSubmit}
              placeholder={t('Select a client')}
              data={listToArray(clients as any)}
              valuePropName="uuid"
              getOptionValueTitle="fullNameClient"
              onSearch={(keywords) =>
                handleSearchClients({ keywords, showLoading: false }) as any
              }
              resetState={clientsLoading}
              onChange={handleChangeClient}
              addonAfter={
                <ClientCreateButton
                  provider
                  disabled={loadingSubmit}
                  onSuccess={handleQuicklyCreateClient}
                  autoFocus>
                  <PlusOutlined />
                </ClientCreateButton>
              }
            />
          </Form.Item>
        </Col>
      ) : null}
    </Row>
  );
}
