import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { IAddButtonProps, Button } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import { AiFillPrinter } from 'react-icons/ai';
import { List } from 'immutable';
import { PriceTagModel } from '@structure';

import './ProductListMenuButton.less';

export interface IProductMenuButtonProps extends IAddButtonProps {
  children?: React.ReactNode;
  handleCreatePriceTagList: (
    priceTagList: List<PriceTagModel> | null,
  ) => Promise<void>;
  handleClearPriceTagList: () => Promise<void>;
  priceTagsCount: number;
  priceTagsCountInList: number;
  priceTagList: List<PriceTagModel> | null;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  width: fit-content;
  height: fit-content;

  border-color: #d9d9d9;

  &&& * {
    color: ${({ theme }) => theme.colors.primary};
  }

  &&&:hover,
  &&&:active,
  &&&:focus {
    opacity: 0.7;
  }
`;
const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export default function ProductMenuButton({
  loading,
  handleCreatePriceTagList,
  priceTagsCount,
  priceTagsCountInList,
  handleClearPriceTagList,
  priceTagList,
}: IProductMenuButtonProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [localLoading, setLocalLoading] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: 'price tags',
      label: priceTagsCountInList ? (
        <Popconfirm
          title={`${t(
            'In the list of price tags - there is',
          )} (${priceTagsCountInList}) ${t(
            'product for printing, clear the list',
          )}?`}
          okText={t('Yes')}
          cancelText={t('No')}
          onCancel={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            try {
              setLocalLoading(true);
              await handleCreatePriceTagList(priceTagList);
              setLocalLoading(false);
            } catch (e) {
              setLocalLoading(false);
            }
          }}
          onConfirm={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            try {
              setLocalLoading(true);
              await handleClearPriceTagList();
              await handleCreatePriceTagList(List([]));
              setLocalLoading(false);
            } catch (e) {
              setLocalLoading(false);
            }
          }}>
          <StyledPopconfirmButton
            type="text"
            onClick={(e: any) => {
              if (e) {
                e?.preventDefault();
                e?.stopPropagation();
              }
            }}>
            <AiFillPrinter size={15} />
            <StyledTitle>{`${t(
              'Printing price tags for',
            )} ${priceTagsCount} ${t('boiled')}`}</StyledTitle>
          </StyledPopconfirmButton>
        </Popconfirm>
      ) : (
        <StyledPopconfirmButton
          type="text"
          onClick={async (e: any) => {
            if (e) {
              e?.preventDefault();
              e?.stopPropagation();
            }

            try {
              setLocalLoading(true);
              await handleCreatePriceTagList(List([]));
              setLocalLoading(false);
            } catch (e) {
              setLocalLoading(false);
            }
          }}>
          <AiFillPrinter size={15} />
          <StyledTitle>{`${t('Printing price tags for')} ${priceTagsCount} ${t(
            'boiled',
          )}`}</StyledTitle>
        </StyledPopconfirmButton>
      ),
    },
  ];

  return (
    <>
      <StyledDropdown
        overlayClassName="product-list-menu"
        trigger={['hover']}
        disabled={false}
        menu={{ items }}
        placement="bottomLeft">
        <StyledButton
          type="default"
          icon={
            localLoading || loading ? (
              <LoadingOutlined color={theme.colors.primary} />
            ) : null
          }>
          <StyledTitle>{t('Other actions')}</StyledTitle>
          <DownOutlined />
        </StyledButton>
      </StyledDropdown>
    </>
  );
}
