import { Dispatch } from 'redux';
import { CLIENT_ABONEMENT_LIST } from '../constants';
import {
  AbonementGroupModel,
  AbonementModel,
  ClientAbonementListModel,
  ClientAbonementModel,
  ClientModel,
} from '@structure';
import { ApiError } from '@services/types';

export interface ISetClientAbonementListParam {
  type: CLIENT_ABONEMENT_LIST;
  abonementList: ClientAbonementListModel<
    ClientModel | AbonementModel | AbonementGroupModel | unknown
  >;
  clientUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddClientAbonementToListParam {
  type: CLIENT_ABONEMENT_LIST;
  abonement: ClientAbonementModel;
}

export interface IUpdateClientAbonementFromListParam {
  type: CLIENT_ABONEMENT_LIST;
  abonement: ClientAbonementModel;
}

export interface IDeleteClientAbonementFromListParam {
  type: CLIENT_ABONEMENT_LIST;
  abonementUuid: string;
}

export interface IErrorClientAbonementListParam {
  type: CLIENT_ABONEMENT_LIST;
  error: ApiError | undefined;
}

export function setClientAbonementList(
  abonementList: ClientAbonementListModel<
    ClientModel | AbonementModel | AbonementGroupModel | unknown
  >,
  clientUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetClientAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.SET_CLIENT_ABONEMENT_LIST,
      abonementList,
      keywords,
      total,
      page,
      clientUuid,
    });
  };
}

export function loadMoreClientAbonementList(
  abonementList: ClientAbonementListModel<
    ClientModel | AbonementModel | AbonementGroupModel | unknown
  >,
  clientUuid: string,
): (dispatch: Dispatch) => Promise<ISetClientAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.LOAD_MORE_CLIENT_ABONEMENT_LIST,
      abonementList,
      clientUuid,
    });
  };
}

export function addClientAbonementToList(
  abonement: ClientAbonementModel,
  clientUuid: string,
): (dispatch: Dispatch) => Promise<IAddClientAbonementToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.ADD_CLIENT_ABONEMENT,
      abonement,
      clientUuid,
    });
  };
}

export function updateClientAbonementFromList(
  abonement: ClientAbonementModel,
  clientUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateClientAbonementFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.UPDATE_CLIENT_ABONEMENT,
      abonement,
      clientUuid,
    });
  };
}

export function deleteClientAbonementFromList(
  abonementUuid: string,
  clientUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteClientAbonementFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.DELETE_CLIENT_ABONEMENT,
      abonementUuid,
      clientUuid,
    });
  };
}

export function errorClientAbonementInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorClientAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_LIST.ERROR_CLIENT_ABONEMENT_LIST,
      error,
    });
  };
}
