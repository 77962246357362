import React, { useCallback, useEffect, useState } from 'react';
import { phoneScreen } from '@services/const';
import { Empty, Select } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DocumentSelectList } from '../Pages';
import {
  IStoreDocumentSearchProps,
  IUseSearchInputFocusReturnType,
  useStoredCompanies,
  useStoredStoreDocumentList,
} from '@hooks';
import {
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '@structure';
import { List } from 'immutable';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import {
  correctPrice,
  listToArray,
  toDateByFormat,
  isFunction,
} from '@services/helpers';
import { useStateReducer } from '@components/lib/libV2/hooks';
import { useStoreSale } from '../Managers';

export interface StoreSaleDraftDocumentSelectProps
  extends Partial<Pick<IUseSearchInputFocusReturnType, 'daleyFocus'>> {
  contentWidth: number;
}

export interface StoreSaleDraftDocumentSelectState {
  disabled: boolean;
  loading: boolean;
  draftDocumentValue: string | undefined;
}

const StyledDocContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledSelect = styled(Select)<{ $autoWidth: boolean }>`
  width: 300px;
  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}

  ${({ $autoWidth }) =>
    $autoWidth &&
    css`
      width: auto;
    `}
`;

export function StoreSaleDraftDocumentSelect({
  contentWidth,
  daleyFocus,
}: StoreSaleDraftDocumentSelectProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const theme: any = useTheme();

  const {
    draftDocumentCallbacks,
    handleSelectDaftDocument,
    handleEnableAutoFocus,
    handleDisableAutoFocus,
  } = useStoreSale();

  const [documentSelectList, setDocumentSelectList] = useState<
    DocumentSelectList[] | null
  >(null);

  const { loading, disabled, draftDocumentValue, handleUpdate } =
    useStateReducer<StoreSaleDraftDocumentSelectState>({
      loading: false,
      disabled: true,
      draftDocumentValue: undefined,
    });

  const { refresh: documentListRefresh } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.OUT,
    loadOnInit: false,
    withoutDates: true,
  });

  const toLabelInValue = useCallback(
    ({
      doc_local_number,
      clientModel,
      doc_date,
      uuid,
      doc_sum_total,
    }: StoreDocumentModel) => ({
      label: (
        <div>
          <StyledDocContainer>
            <StyledTitle>
              {`№ ${doc_local_number}, `}
              <StyledTitle $color={theme.colors.error}>
                {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  doc_sum_total || 0,
                )}`}
              </StyledTitle>
            </StyledTitle>
            {clientModel?.uuid ? (
              <StyledTitle $color={theme.colors.secondary}>{` / ${
                clientModel?.fullNameClient?.trim() || ''
              }`}</StyledTitle>
            ) : null}
          </StyledDocContainer>
          <StyledDocContainer>
            <StyledDescription>
              {`${t('From')} ${toDateByFormat(doc_date, 'DD.MM.YYYY HH:mm')}`}
            </StyledDescription>
          </StyledDocContainer>
        </div>
      ),
      value: uuid,
    }),
    [
      defaultCompany?.currency_symbol,
      t,
      theme.colors.error,
      theme.colors.secondary,
    ],
  );

  const documentListToLabelInValueSelect = useCallback(
    (documents: List<StoreDocumentModel>) => {
      if (List.isList(documents)) {
        const documentSelectList = documents?.map(toLabelInValue);

        setDocumentSelectList(listToArray(documentSelectList));
      }
    },
    [toLabelInValue],
  );

  const handleDeleteDocumentList = useCallback((documentUuid: string) => {
    setDocumentSelectList((prevState) =>
      (prevState || [])?.filter(({ value }) => value !== documentUuid),
    );
  }, []);

  const handleUpdateDocumentList = useCallback(
    (document: StoreDocumentModel) => {
      setDocumentSelectList((prevState) =>
        (prevState || [])?.map((item) => {
          if (item?.value === document?.uuid) {
            return toLabelInValue(document);
          }
          return item;
        }),
      );
    },
    [toLabelInValue],
  );

  const handleCreateDocumentList = useCallback(
    (document: StoreDocumentModel) => {
      setDocumentSelectList((prevState) => [
        toLabelInValue(document),
        ...(prevState || []),
      ]);
    },
    [toLabelInValue],
  );

  const handleSearchStoreDocuments = React.useCallback(
    async ({ limit = 10, keywords }: IStoreDocumentSearchProps) => {
      handleUpdate({ loading: true });
      const documentListModel = await documentListRefresh({
        offset: 0,
        limit,
        keywords,
        showLoading: false,
        documentStatus: StoreDocumentStatus.DRAFT,
      });

      if (documentListModel && List.isList(documentListModel?.documents)) {
        documentListToLabelInValueSelect(documentListModel?.documents);
      }
      handleUpdate({ loading: false });
    },
    [documentListRefresh, documentListToLabelInValueSelect, handleUpdate],
  );

  const handleChangeDraftStoreDocument = useCallback(
    async (documentUuid: any) => {
      handleDisableAutoFocus();
      handleUpdate({ loading: true, draftDocumentValue: documentUuid });

      await handleSelectDaftDocument(documentUuid);

      if (isFunction(daleyFocus)) {
        daleyFocus();
        setTimeout(handleEnableAutoFocus, 500);
      }

      handleUpdate({ loading: false });
    },
    [
      daleyFocus,
      handleDisableAutoFocus,
      handleEnableAutoFocus,
      handleSelectDaftDocument,
      handleUpdate,
    ],
  );

  const handleInitProductList = useCallback(async () => {
    if (documentSelectList === null && defaultCompanyUuid) {
      handleUpdate({ loading: true, disabled: true });
      const documentList = await documentListRefresh({
        companyUuid: defaultCompanyUuid,
        documentType: StoreDocumentType.OUT,
        documentStatus: StoreDocumentStatus.DRAFT,
        limit: 50,
      });

      if (documentList && List.isList(documentList?.documents)) {
        documentListToLabelInValueSelect(documentList?.documents);
      }
      handleUpdate({ loading: false, disabled: false });
    }
  }, [
    defaultCompanyUuid,
    documentListRefresh,
    documentListToLabelInValueSelect,
    documentSelectList,
    handleUpdate,
  ]);

  useEffect(() => {
    (async () => {
      await handleInitProductList();
    })();
  }, [handleInitProductList]);

  useEffect(() => {
    draftDocumentCallbacks.current = {
      onCreate: handleCreateDocumentList,
      onDelete: handleDeleteDocumentList,
      onUpdate: handleUpdateDocumentList,
      resetDraftValue: () => handleUpdate({ draftDocumentValue: undefined }),
      isInit: true,
    };
  }, [
    draftDocumentCallbacks,
    handleCreateDocumentList,
    handleDeleteDocumentList,
    handleUpdate,
    handleUpdateDocumentList,
  ]);

  return (
    <StyledSelect
      $autoWidth={contentWidth <= phoneScreen}
      loading={loading}
      disabled={disabled}
      showSearch
      onSearch={async (keywords) =>
        await handleSearchStoreDocuments({
          keywords,
        })
      }
      placeholder={t('Select a draft')}
      options={documentSelectList || []}
      notFoundContent={
        <Empty description={t('There are no draft sales invoices found')} />
      }
      onChange={handleChangeDraftStoreDocument}
      value={draftDocumentValue ? draftDocumentValue : undefined}
    />
  );
}
