import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import { CompanyModel, CompanyRequisiteDTO } from '@structure';

import {
  EditButton,
  SuspenseEmpty,
  IEditButtonProps,
} from '@components/lib/DataDisplay';

const CompanyRequisiteModalLazy = React.lazy(
  () => import('../Show/CompanyRequisiteModal'),
);

export interface ICompanyRequisiteEditButtonProps extends IEditButtonProps {
  requisite: CompanyRequisiteDTO;
  onSuccess: (value: CompanyRequisiteDTO) => Promise<CompanyModel | void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export function CompanyRequisiteEditButton({
  children,
  requisite,
  onSuccess,
  onCancel,
  ...rest
}: ICompanyRequisiteEditButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit requisite')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanyRequisiteModalLazy
          requisite={requisite}
          editMode
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
