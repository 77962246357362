import { Dispatch } from 'redux';
import { List } from 'immutable';
import { STATUS_LIST } from '../constants';
import { StatusModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetStatusListParam {
  type: STATUS_LIST;
  statusList: List<StatusModel>;
  keywords?: string;
  total?: number;
  page?: number;
  category?: string;
}

export interface IAddStatusToListParam {
  type: STATUS_LIST;
  status: StatusModel;
}

export interface IUpdateStatusFromListParam {
  type: STATUS_LIST;
  status: StatusModel;
}

export interface IDeleteStatusFromListParam {
  type: STATUS_LIST;
  statusUuid: string;
}

export interface IErrorStatusListParam {
  type: STATUS_LIST;
  error: ApiError | undefined;
}

export function setStatusList(
  statusList: List<StatusModel>,
  keywords?: string,
  total?: number,
  page?: number,
  category?: string,
): (dispatch: Dispatch) => Promise<ISetStatusListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.SET_STATUS_LIST,
      statusList,
      keywords,
      total,
      page,
      category,
    });
  };
}

export function loadMoreStatusList(
  statusList: List<StatusModel>,
): (dispatch: Dispatch) => Promise<ISetStatusListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.LOAD_MORE_STATUS_LIST,
      statusList,
    });
  };
}

export function addStatusToList(
  status: StatusModel,
): (dispatch: Dispatch) => Promise<IAddStatusToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.ADD_STATUS,
      status,
    });
  };
}

export function updateStatusFromList(
  status: StatusModel,
): (dispatch: Dispatch) => Promise<IUpdateStatusFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.UPDATE_STATUS,
      status,
    });
  };
}

export function deleteStatusFromList(
  statusUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteStatusFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.DELETE_STATUS,
      statusUuid,
    });
  };
}

export function errorStatusInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStatusListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STATUS_LIST.ERROR_STATUS_LIST,
      error,
    });
  };
}
