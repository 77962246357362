import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row, Form, Space, Button } from 'antd';
import styled, { css } from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';

import {
  getPeriodMovementData,
  getFinancialReportingData,
} from '@services/api/setting';
import { SearchSelect } from '@components/lib/DataDisplay';
import { useCompanyClientList } from '@hooks';
import FormCheckBox from '@components/lib/DataDisplay/FormCheckBox';
import { TextFormat } from '@components/lib/Format';
import { FlexContainer } from '@components/lib/Styled';

export default withTranslation()(FinancialReliabilityForm);

const StyledSubText = styled(TextFormat)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 12px;
`;

const StyledBalanceButton = styled(Button)<{ danger: boolean }>`
  margin: 0;
  padding: 0;
  height: fit-content;

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.error} !important;
    `}
`;

export interface IFinancialReliabilityFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<any, any>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  loading?: boolean;
  financialReliability?: any;
  companyUuid: string;
  smsNotificationPrice: string;
  balance: string;
  handleUpdateBalance: () => void;
  disabled?: boolean;
}

function FinancialReliabilityForm({
  t,
  loading,
  editMode,
  financialReliability,
  companyUuid,
  smsNotificationPrice,
  balance,
  handleUpdateBalance,
  disabled,
  ...rest
}: IFinancialReliabilityFormProps) {
  const { entityList: clients } = useCompanyClientList({ companyUuid });

  return (
    <DefaultForm
      initialValues={financialReliability}
      notifyFormName={t('Financial reliability')}
      disabledSubmitButton={disabled}
      {...rest}>
      {({ setFieldsValue }) => (
        <>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Period movement funds')}
                name="period_of_financial_statement"
                rules={[
                  {
                    required: true,
                    message: t('Period_movement_funds must be specified.'),
                  },
                ]}>
                <SearchSelect
                  disable={disabled}
                  name="period_of_financial_statement"
                  placeholder={t('Search a period_of_financial_statement')}
                  data={getPeriodMovementData({ t })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Financial reporting period')}
                name="period_of_payments_movement"
                rules={[
                  {
                    required: true,
                    message: t('Financial reporting period must be specified.'),
                  },
                ]}>
                <SearchSelect
                  disable={disabled}
                  name="period_of_payments_movement"
                  placeholder={t('Search a financial reporting period')}
                  data={getFinancialReportingData({ t })}
                />
              </Form.Item>
            </Col>
            {/*<FinancialEmployeeUuidField*/}
            {/*  clients={clients}*/}
            {/*  tooltip={t(*/}
            {/*    'When generating automatic postings, such as the sale of goods, this Client will be used as a point of communication',*/}
            {/*  )}*/}
            {/*/>*/}
          </Row>
          <FormCheckBox
            disabled={!Number(balance) || disabled}
            label={t('Sms notification')}
            name="sms_notification_enabled"
            isChecked={Boolean(
              Number(financialReliability?.sms_notification_enabled),
            )}
            setFieldsValue={setFieldsValue}
            tooltipTitle={t('Sms notification')}>
            {() => (
              <FlexContainer flexDirection="column">
                <Space direction="horizontal">
                  <TextFormat>{t('Enable sms notification')}</TextFormat>
                  <TextFormat
                    danger={!Number(balance)}
                    success={
                      Number(balance) > Number(smsNotificationPrice)
                    }>{`( ${t('balance')} ${balance} ${t(
                    'UAH',
                  )} )`}</TextFormat>
                </Space>
                <StyledSubText>{`${t(
                  'The cost of one SMS is',
                )} ${smsNotificationPrice} ${t('UAH')}`}</StyledSubText>
                <StyledBalanceButton
                  disabled={disabled}
                  danger={!Number(balance)}
                  type="link"
                  onClick={handleUpdateBalance}>
                  <Space>
                    {`${t('Top up for')} 100 ${t('UAH')}`}
                    <ArrowRightOutlined />
                  </Space>
                </StyledBalanceButton>
              </FlexContainer>
            )}
          </FormCheckBox>
          <FormCheckBox
            disabled={disabled}
            label={t('Email notification')}
            isChecked={
              typeof financialReliability?.email_notification_enabled ===
              'undefined'
                ? true
                : Boolean(
                    Number(financialReliability?.email_notification_enabled),
                  )
            }
            name="email_notification_enabled"
            setFieldsValue={setFieldsValue}
            tooltipTitle={t('Email notification')}>
            {() => t('Enable email notification')}
          </FormCheckBox>
        </>
      )}
    </DefaultForm>
  );
}
