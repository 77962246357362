import React, { forwardRef, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { Button } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { OverviewBottomView } from '@components/lib/Layout';
import { useNavigate } from 'react-router';
import { Routes } from '@services/types';

export interface AppIntroBottomViewProps {}

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyledNavigateButton = styled(Button)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 5px;

  width: fit-content;
  min-width: 44px;
  min-height: 44px;

  background-color: ${({ theme }) => theme.colorsV2.buttonPrimaryColor};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: ${({ theme }) => theme.colorsV2.buttonPrimaryActiveColor};
    opacity: 0.7;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & > button {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 5px;

        width: fit-content;
        min-width: 44px;
        min-height: 44px;
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 1;
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&& > * {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export const AppIntroBottomView = forwardRef(function AppIntroBottomView(
  {}: AppIntroBottomViewProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToRelatedDocument = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.dashboard}`);
  }, [navigate]);

  return (
    <div ref={ref}>
      <OverviewBottomView>
        <StyledSpace direction="horizontal" size="middle">
          <StyledNavigateButton
            type="primary"
            size={'large'}
            onClick={navigateToRelatedDocument}>
            <StyledTitle fontSize={14}>{t('Continue')}</StyledTitle>
          </StyledNavigateButton>
        </StyledSpace>
      </OverviewBottomView>
    </div>
  );
});
