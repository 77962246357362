import * as React from 'react';
import { useMemo } from 'react';
import { List } from 'immutable';
import { useDropdownAlert } from '@contex';
import { toMoment } from '@services/helpers';
import { useTranslation } from 'react-i18next';
import { SessionStorageItems } from '@services/const';
import { getClientOrderList } from '@services/api/client';
import { GetCompanyScheduleState } from '@services/api/company';

import {
  ApiError,
  DateValue,
  IEntityProps,
  IListSearchProps,
} from '@services/types';

import {
  useStateReducer,
  useCancellablePromise,
} from '@components/lib/libV2/hooks';

import {
  ScheduleCalendarModel,
  ScheduleCalendarMapper,
  IScheduleCalendarListDTO,
  ScheduleCalendarStatsDTO,
} from '@structure';

export interface IUseClientOrderListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  clientUuid: string;
  start?: DateValue;
  end?: DateValue;
  sort?: string;
  state?: GetCompanyScheduleState;
}

export interface IUseClientOrderListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ScheduleCalendarModel> | null;
  refresh: (
    value: Partial<IUseClientOrderListProps>,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  clientUuid: string;
  start_date?: DateValue | undefined;
  end_date?: DateValue | undefined;
  sort: string;
  state: GetCompanyScheduleState;
  stats: ScheduleCalendarStatsDTO;
}

export function useClientOrderList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    clientUuid,
    start,
    end,
    sort = '',
    state = GetCompanyScheduleState.ALL,
  }: IUseClientOrderListProps = {} as IUseClientOrderListProps,
): IUseClientOrderListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { cancellablePromise, didCancel } = useCancellablePromise();

  const orderRangeDates = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem(SessionStorageItems.clientOrderRangeDates) ||
          JSON.stringify({}),
      ),
    [],
  );

  const startDate = orderRangeDates?.start
    ? toMoment(orderRangeDates?.start)
    : '';
  const endDate = orderRangeDates?.end ? toMoment(orderRangeDates?.end) : '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    start_date: listStart,
    end_date: listEnd,
    sort: listSort,
    stats: listStats,
    state: listState,
    handleUpdate,
  } = useStateReducer<Omit<IUseClientOrderListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    clientUuid,
    start_date: start || startDate,
    end_date: end || endDate,
    sort,
    stats: {} as ScheduleCalendarStatsDTO,
    state,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      clientUuid = listClientUuid,
      start = listStart,
      end = listEnd,
      sort = listSort,
      isClearDate,
      isRangeChanged,
      state = listState,
    }: Partial<IUseClientOrderListProps> = {}): Promise<List<ScheduleCalendarModel> | void> => {
      try {
        if (isRangeChanged) {
          const orderRangeDates = {
            end,
            start,
            isCleared: !!isClearDate,
          };

          sessionStorage.setItem(
            SessionStorageItems.clientOrderRangeDates,
            JSON.stringify(orderRangeDates),
          );
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          clientUuid,
          start_date: start,
          end_date: end,
          sort,
          state,
        });

        const { schedules, total, stats } =
          await cancellablePromise<IScheduleCalendarListDTO>(
            getClientOrderList({
              clientUuid,
              limit,
              offset,
              start,
              end,
              keywords,
              sort,
              state,
            }),
          );

        const scheduleCalendarList =
          ScheduleCalendarMapper.toScheduleCalendarListModel(schedules, total);

        if (!didCancel.current) {
          handleUpdate({
            entityList: scheduleCalendarList.schedules,
            total,
            loading: false,
            stats,
          });

          return scheduleCalendarList.schedules;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }

        alert(
          'error',
          t('Orders'),
          t('An error occurred during get client order list'),
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listClientUuid,
      listEnd,
      listKeywords,
      listLimit,
      listOffset,
      listSort,
      listStart,
      listState,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && clientUuid) {
      (async () => {
        await refresh({ clientUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, clientUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    end_date: listEnd,
    start_date: listStart,
    sort: listSort,
    stats: listStats,
    state: listState,
  };
}
