import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { textToUpperCase } from '@services/helpers';

export interface ISettingsModuleListHeaderProps {
  title: string;
  disabled?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.lightDisable};
`;

const StyledLine = styled.div<{ disabled?: boolean }>`
  width: auto;
  height: auto;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  border-bottom-style: solid;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-bottom-color: ${theme.colors.disable};
    `}
`;

export function SettingsModuleListHeader({
  title,
  disabled,
}: ISettingsModuleListHeaderProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledContainer>
      <StyledLine disabled={disabled}>
        <StyledTitle
          fontSize={13}
          $color={disabled ? theme.colors.disable : undefined}>
          {textToUpperCase(title)}
        </StyledTitle>
      </StyledLine>
    </StyledContainer>
  );
}
