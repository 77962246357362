import { Tag } from 'antd';
import React from 'react';
import Color from 'color';
import { useLocation } from 'react-router';
import { IoMdDocument } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { MdEditDocument } from 'react-icons/md';
import { Routes } from '@services/types';
import { useStoredCompanies } from '@hooks';
import styled, { useTheme, css } from 'styled-components';
import { Link } from '@components/lib/DataDisplay';
import { StyledDescription } from '@components/lib/Styled';
import { StoreDocumentModel, StoreDocumentStatus } from '@structure';

import {
  toDateByFormat,
  textToUpperCase,
  correctPrice,
} from '@services/helpers';

export interface IStoreRealizationDetailsViewProps {
  document: StoreDocumentModel | null;
  to?: string;
  showTag?: boolean;
  showTotal?: boolean;
  withoutNavigate?: boolean;
  withoutDate?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)<{ $draft: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  justify-content: flex-start;
  gap: 5px;

  ${({ $draft }) =>
    $draft &&
    css`
      color: ${({ theme }) => theme.colors.warning};

      &:hover {
        color: ${({ theme }) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledWithoutContainer = styled.div<{ $draft: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;

  ${({ $draft }) =>
    $draft &&
    css`
      color: ${({ theme }) => theme.colors.warning};

      &:hover {
        color: ${({ theme }) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledTag = styled(Tag)`
  height: 17px;
  line-height: 1.3;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function StoreRealizationDetailsView({
  document,
  to,
  showTag,
  showTotal,
  withoutNavigate,
  withoutDate,
}: IStoreRealizationDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();
  const { defaultCompany } = useStoredCompanies();

  const isDiscount = Number(document?.doc_discount_value || 0) > 0;

  const total =
    (isDiscount
      ? Number(document?.doc_sum_total_with_discount)
      : Number(document?.doc_sum_total)) || 0;

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledSpace>
            <StyledTitleContainer>
              {showTag ? (
                <StyledTag color={theme.tabs.beige}>
                  <StyledDescription
                    fontSize={10}
                    $color={Color(theme.tabs.beige)
                      .alpha(1)
                      .darken(0.65)
                      .toString()}>
                    {textToUpperCase(t('Sales invoice'))}
                  </StyledDescription>
                </StyledTag>
              ) : null}
              {withoutNavigate ? (
                <StyledWithoutContainer
                  $draft={
                    document?.doc_status_text === StoreDocumentStatus.DRAFT
                  }>
                  {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                    <MdEditDocument color={theme.colors.warning} />
                  ) : (
                    <IoMdDocument color={theme.colors.link} />
                  )}
                  {document?.doc_local_number}
                  {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                    <StyledDescription $color={theme.colors.warning}>
                      {` ( ${t('Draft')} )`}
                    </StyledDescription>
                  ) : null}
                </StyledWithoutContainer>
              ) : (
                <StyledLink
                  $draft={
                    document?.doc_status_text === StoreDocumentStatus.DRAFT
                  }
                  to={
                    to ||
                    `/${Routes.app}/${Routes.stores}/${Routes.storeRealization}/${document?.uuid}`
                  }
                  tooltip={t(
                    'Open a detailed description of the sales invoice',
                  )}
                  state={{ goBack: location.pathname }}>
                  {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                    <MdEditDocument color={theme.colors.warning} />
                  ) : (
                    <IoMdDocument color={theme.colors.link} />
                  )}
                  {document?.doc_local_number}
                  {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                    <StyledDescription $color={theme.colors.warning}>
                      {` ( ${t('Draft')} )`}
                    </StyledDescription>
                  ) : null}
                </StyledLink>
              )}
            </StyledTitleContainer>
            <StyledDescriptionContainer>
              {document?.doc_date && !withoutDate ? (
                <StyledDescription>
                  {toDateByFormat(document?.doc_date, 'DD.MM.YYYY HH:mm')}
                </StyledDescription>
              ) : null}
              {showTotal ? (
                <StyledDescription>
                  {`/ ${t('the amount of the document')}: ${
                    defaultCompany?.currency_symbol || ''
                  }${correctPrice(total)}`}
                </StyledDescription>
              ) : null}
            </StyledDescriptionContainer>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
