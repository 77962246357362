import React from 'react';
import { Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { HeaderLogo } from '../common';
import { HEADER_HEIGHT } from '../const';

export interface IAppSiderHeaderProps {
  collapsed?: boolean;
  onCollapse?: () => void;
  headerTitle?: string;
  className?: string;
  showButton?: boolean;
  asClose?: boolean;
  isBigPhone: boolean;
}

const StyledHeader = styled.div<{ $collapsed: boolean; $isBigPhone: boolean }>`
  position: fixed;
  top: 0;
  z-index: 1;

  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 270px;
  height: ${HEADER_HEIGHT}px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.background.sidebar};

  ${({ $isBigPhone }) =>
    $isBigPhone &&
    css`
      max-width: 100%;
      padding: 0 5px;
    `}

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      width: fit-content;
      padding: 0;
    `}
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  color: ${({ theme }) => theme.colorsV2.sidebar.menu};
`;

const StyledMenuOutlined = styled(MenuOutlined)`
  color: ${({ theme }) => theme.colorsV2.sidebar.menu};
`;

const StyledButton = styled(Button)<{ $collapsed: boolean }>`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 5px 10px;

  &&:hover,
  &&:active {
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
  }

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      width: fit-content;
      margin-left: 10px;
    `}
`;

export default function AppSiderHeader({
  collapsed,
  headerTitle = 'Company Name',
  onCollapse,
  showButton = true,
  asClose = false,
  className,
  isBigPhone,
}: IAppSiderHeaderProps): JSX.Element {
  return (
    <StyledHeader
      className={className}
      $collapsed={!!collapsed}
      $isBigPhone={isBigPhone}>
      <HeaderLogo
        headerTitle={headerTitle}
        collapsed={!!collapsed}
        isBigPhone={isBigPhone}
      />
      {showButton ? (
        <StyledButton onClick={onCollapse} $collapsed={!!collapsed}>
          {asClose ? <StyledCloseOutlined /> : <StyledMenuOutlined />}
        </StyledButton>
      ) : null}
    </StyledHeader>
  );
}
