import {
  ISubscriptionDTOProps,
  SubscriptionDTO,
  SubscriptionMapper,
} from '../internal';
import { EPlanStatuses } from '@services/helpers';

export interface IPlanDTOProps {
  id: string;
  identifier: string;
  checkout_url: string;
  subscriptions: ISubscriptionDTOProps[];
  status: number;
  created_at: string;
}

export class PlanDTO {
  public id: string;

  public identifier: string;

  public checkout_url: string;

  public status: EPlanStatuses;

  public created_at: string;

  public subscriptions: SubscriptionDTO[];

  constructor(props?: IPlanDTOProps) {
    this.id = props?.id || '';
    this.identifier = props?.identifier || '';
    this.checkout_url = props?.checkout_url || '';
    this.status = props?.status ?? EPlanStatuses.DISABLED;
    this.created_at = props?.created_at || '';
    this.subscriptions = SubscriptionMapper.toSubscriptionListDTO(
      props?.subscriptions || [],
    ).subscriptions;
  }
}
