import { Dispatch } from 'redux';
import { SETTINGS_MODULE } from '../constants';
import { SettingsModuleModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetSettingsModuleParam {
  type: SETTINGS_MODULE;
  settingsModule: SettingsModuleModel | null;
}

export interface IUpdateSettingsModuleParam {
  type: SETTINGS_MODULE;
  settingsModule: SettingsModuleModel;
}

export interface IResetSettingsModuleParam {
  type: SETTINGS_MODULE;
}

export interface IErrorSettingsModuleParam {
  type: SETTINGS_MODULE;
  error: ApiError | undefined;
}

export function setSettingsModule(
  settingsModule: SettingsModuleModel | null,
): (dispatch: Dispatch) => Promise<ISetSettingsModuleParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE.SET_SETTINGS_MODULE,
      settingsModule,
    });
  };
}

export function updateSettingsModule(
  settingsModule: SettingsModuleModel,
): (dispatch: Dispatch) => Promise<IUpdateSettingsModuleParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE.UPDATE_SETTINGS_MODULE,
      settingsModule,
    });
  };
}

export function resetSettingsModule(): (
  dispatch: Dispatch,
) => Promise<IResetSettingsModuleParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE.RESET_SETTINGS_MODULE,
    });
  };
}

export function errorSettingsModule(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorSettingsModuleParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SETTINGS_MODULE.ERROR_SETTINGS_MODULE,
      error,
    });
  };
}
