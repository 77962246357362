import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import { useCalendar, MODE } from '@contex';
import {
  toUtcDateInFormat,
  buildMonthChange,
  eq,
  compose,
  split,
  head,
} from '@services/helpers';
import CalendarHeaderView from './CalendarHeaderView';
import { ICalendarHeaderSpecificationProps } from '../../../Calendar';
import { useLocation, useNavigate } from 'react-router';
import { Routes } from '@services/types';

export default withTranslation()(CalendarHeader);

export interface ICalendarHeaderProps
  extends WithTranslation,
    ICalendarHeaderSpecificationProps {
  title?: string;
  loading?: boolean;
}

function CalendarHeader({
  title,
  specifications,
  loading,
}: ICalendarHeaderProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>('');

  const {
    handleUpdate,
    monthDates,
    selectedDate,
    mode: currentMode,
    handleUpdateMode,
  } = useCalendar();

  const urlDate = useMemo(
    () => toUtcDateInFormat(selectedDate),
    [selectedDate],
  );

  const handleChangeCalendarMode = useCallback(
    (mode: any) => {
      handleUpdateMode(mode);
      navigate(`${url}/calendar/${mode}/${urlDate}`, {
        state: location?.state,
      });
    },
    [handleUpdateMode, location?.state, navigate, url, urlDate],
  );

  const handleChangeDate = useCallback(
    async (date: Date): Promise<void> => {
      const newDate = moment(date).locale(
        window.localStorage.getItem('language') || 'en',
      );

      await handleUpdate({
        prevDateInMonth: monthDates,
        date: newDate as any,
        forceUpdate: eq(currentMode, MODE.MONTH),
      });

      navigate(`${url}/calendar/${currentMode}/${toUtcDateInFormat(newDate)}`, {
        state: location?.state,
      });
    },
    [handleUpdate, monthDates, currentMode, navigate, url, location?.state],
  );

  const handleSelectToday = useCallback(async (): Promise<void> => {
    await handleUpdate({
      prevDateInMonth: monthDates,
      date: moment().toDate(),
    });
    navigate(`${url}/calendar/${currentMode}/${toUtcDateInFormat(moment())}`, {
      state: location?.state,
    });
  }, [handleUpdate, monthDates, navigate, url, currentMode, location?.state]);

  const { prev, next } = buildMonthChange(selectedDate as Date, currentMode);

  const handleSelectPrevDate = useCallback(async (): Promise<void> => {
    await handleChangeDate(prev);
  }, [handleChangeDate, prev]);

  const handleSelectNextDate = useCallback(async () => {
    await handleChangeDate(next);
  }, [handleChangeDate, next]);

  useEffect(() => {
    if (!url) {
      const url = compose<string>(
        (path) => `${path}${Routes.schedules}`,
        head,
        split(Routes.schedules),
      )(location.pathname || '');
      setUrl(url);
    }
  }, [location.pathname, url]);

  return (
    <CalendarHeaderView
      title={title}
      handleSelectToday={handleSelectToday}
      handleSelectPrevDate={handleSelectPrevDate}
      handleSelectNextDate={handleSelectNextDate}
      selectedDate={selectedDate}
      currentMode={currentMode}
      handleChangeCalendarMode={handleChangeCalendarMode}
      specifications={specifications}
      handleChangeDate={handleChangeDate}
      loading={loading}
    />
  );
}
