import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import classNames from 'classnames';
import styled from 'styled-components';

const DefaultHeader = styled(PageHeader)`
  padding-left: 0;
  padding-right: 0;
  & .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;
  }
`;

export type Route = {
  path: string;
  breadcrumbName: string;
};

export interface IDetailsHeaderProps extends PageHeaderProps {
  routes: Route[];
  title: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

/**
 * @desc Рендерінг заголовка для книги
 * */
export default function DefaultDetailsHeader({
  routes,
  title,
  children,
  className,
  ...rest
}: IDetailsHeaderProps): JSX.Element {
  const itemRender = (
    route: Route,
    params: any,
    routes: Route[],
  ): JSX.Element => {
    const last = routes.indexOf(route) === routes.length - 1;

    return last ? (
      <span dangerouslySetInnerHTML={{ __html: route?.breadcrumbName }} />
    ) : (
      <Link to={route?.path}>{route?.breadcrumbName}</Link>
    );
  };

  return (
    <div className={classNames('default-header', className)}>
      <DefaultHeader
        className="site-page-header"
        breadcrumb={{ items: routes, itemRender } as any}
        title={title}
        {...rest}>
        {children || null}
      </DefaultHeader>
    </div>
  );
}
