import React from 'react';
import { IStoreSettings } from '@structure';
import styled from 'styled-components';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { head } from '@services/helpers';

export interface IStoreSettingsViewProps {
  store_settings: IStoreSettings;
}

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  border: 1px solid rgb(225, 229, 231);
  border-radius: 7px;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;

  &:nth-child(n):not(&:last-child):not(&:nth-last-child(2)) {
    border-bottom: 1px solid rgb(225, 229, 231);
  }

  &:nth-child(2n + 1) {
    border-right: 1px solid rgb(225, 229, 231);
  }
`;

export function StoreSettingsView({
  store_settings,
}: IStoreSettingsViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const settings = [
    ...Object.entries(store_settings?.permissions?.input).map(
      ([key, value]) => ({
        [`${key}_input`]: value,
      }),
    ),
    ...Object.entries(store_settings?.permissions?.output).map(
      ([key, value]) => ({
        [`${key}_output`]: value,
      }),
    ),
  ];

  if (settings?.length % 2 !== 0) {
    settings.push({ ignore: '' } as any);
  }

  return (
    <StyledContainer>
      {settings.map((permission, index) => {
        const [key, value] = head(Object.entries(permission));

        return (
          <StyledItem key={key || String(index)}>
            {key !== 'ignore' ? <StyledTitle>{t(key)}</StyledTitle> : null}
            {typeof value === 'boolean' ? (
              <StyledDescription>
                {t(value ? t('Allowed') : t('Not allowed'))}
              </StyledDescription>
            ) : null}
          </StyledItem>
        );
      })}
    </StyledContainer>
  );
}
