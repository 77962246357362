import Button from './Button';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { StyledDescription } from '../Styled/Text';
import React, { useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export interface LoadingMoreButtonProps {
  isLoadingMore: boolean;
  handleLoadingMore: any;
}

const StyledContainer = styled.div<{ $isLoadingMore: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;

  ${({ $isLoadingMore }) =>
    $isLoadingMore &&
    css`
      justify-content: flex-end;
    `}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)``;

export function LoadingMoreButton({
  isLoadingMore,
  handleLoadingMore: onLoadingMore,
}: LoadingMoreButtonProps): JSX.Element {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleLoadingMore = useCallback(async () => {
    setLoading(true);
    try {
      await onLoadingMore();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [onLoadingMore]);

  return (
    <StyledContainer $isLoadingMore={isLoadingMore && !loading}>
      {loading ? (
        <StyledLoadingOutlined />
      ) : isLoadingMore ? (
        <Button type="text" fitContent onClick={handleLoadingMore}>
          {t('Loading more')}
        </Button>
      ) : (
        <StyledDescription style={{ whiteSpace: 'unset' }}>
          {t('There are no items to download')}
        </StyledDescription>
      )}
    </StyledContainer>
  );
}
