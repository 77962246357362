import React, { useRef } from 'react';
import { Col, Row, Form, Select } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Button } from '@components/lib/DataDisplay';
import { useDefaultForm } from '@contex';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ISendFieldsMappingProps } from '@services/api/client';
import { StyledTitle } from '@components/lib/Styled';
import { useSize } from '@hooks';
import { phoneScreen } from '@services/const';

export interface IClientFileImportSettingFieldsProps
  extends Pick<ISendFieldsMappingProps, 'fields'> {}

const StyledDeleteOutlined = styled(DeleteOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

const StyledSelect = styled(Select)`
  &.ant-select-disabled {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export function ClientFileImportSettingFields({
  fields,
}: IClientFileImportSettingFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { valid, formData, getFieldValue, loadingSubmit } = useDefaultForm();
  const theme: any = useTheme();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 22 : 11;

  const from = formData?.clientsFields?.filter(({ value }: any) => {
    return !~(fields || [])?.findIndex(({ from }: any) => from === value);
  });

  const to = formData?.header?.filter(({ value }: any) => {
    return !~(fields || [])?.findIndex(({ to }: any) => to === value);
  });

  return (
    <>
      {valid ? (
        <Row gutter={20} justify="end" align="bottom" ref={contentRef}>
          <Col span={24}>
            <Form.Item
              label={t(
                'Clients are identified by phone number. If the client with the specified phone number already exists, the update date column will be updated in the database. Otherwise, a new record is created.',
              )}>
              <Form.List name="fields">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        return (
                          <Row gutter={12} align="top" key={field?.key}>
                            <Col span={isFullWidth}>
                              <Form.Item noStyle shouldUpdate>
                                {() => (
                                  <Form.Item
                                    {...field}
                                    required
                                    label={t('Field in the application')}
                                    tooltip={t('Field in the application')}
                                    name={[field.name, 'from']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('Must be specified'),
                                      },
                                    ]}>
                                    <StyledSelect
                                      showSearch
                                      disabled={
                                        getFieldValue([
                                          'fields',
                                          field.name,
                                          'required',
                                        ]) || loadingSubmit
                                      }
                                      placeholder={t(
                                        'Select field in the application',
                                      )}
                                      options={from}
                                    />
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={isFullWidth}>
                              <Form.Item
                                {...field}
                                required
                                label={t('Field in the document')}
                                tooltip={t('Field in the document')}
                                name={[field.name, 'to']}
                                rules={[
                                  {
                                    required: true,
                                    message: t('Must be specified'),
                                  },
                                ]}>
                                <Select
                                  showSearch
                                  placeholder={t(
                                    'Select field in the document',
                                  )}
                                  options={to}
                                  disabled={loadingSubmit}
                                />
                              </Form.Item>
                            </Col>
                            {fields?.length > 1 &&
                            !getFieldValue([
                              'fields',
                              field.name,
                              'required',
                            ]) ? (
                              <StyledDeleteCol span={2}>
                                <StyledDeleteOutlined
                                  $color={
                                    loadingSubmit
                                      ? theme.colors.disable
                                      : theme.colors.error
                                  }
                                  onClick={
                                    loadingSubmit
                                      ? undefined
                                      : () => remove(field.name)
                                  }
                                />
                              </StyledDeleteCol>
                            ) : null}
                          </Row>
                        );
                      })}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add({})}
                          block
                          disabled={loadingSubmit}
                          icon={<PlusOutlined />}>
                          {t('Add field')}
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </Form.Item>
          </Col>
          <Col span={24}>
            <StyledTitle>
              {t('The number of customers is limited within the tariff plan.')}
            </StyledTitle>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
