import { Dispatch } from 'redux';
import { PRODUCT_CATEGORY_SUB_CATEGORY_LIST } from '../constants';
import { ProductCategoryListModel, ProductCategoryModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetProductCategorySubCategoryListParam {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  categoryList: ProductCategoryListModel;
  parentCategoryUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddProductCategorySubCategoryListToListParam {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  category: ProductCategoryModel;
}

export interface IUpdateProductCategorySubCategoryListFromListParam {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  category: ProductCategoryModel;
}

export interface IDeleteProductCategorySubCategoryListFromListParam {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  categoryUuid: string;
  parentCategoryUuid: string;
}

export interface IErrorProductCategorySubCategoryListParam {
  type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST;
  error: ApiError | undefined;
}

export function setProductCategorySubCategoryList(
  categoryList: ProductCategoryListModel,
  parentCategoryUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
  parentCategory?: ProductCategoryModel,
): (dispatch: Dispatch) => Promise<ISetProductCategorySubCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.SET_PRODUCT_CATEGORY_SUB_CATEGORY_LIST,
      categoryList,
      keywords,
      total,
      page,
      parentCategoryUuid,
      parentCategory,
    });
  };
}

export function loadMoreProductCategorySubCategoryList(
  categoryList: ProductCategoryListModel,
  parentCategoryUuid: string,
): (dispatch: Dispatch) => Promise<ISetProductCategorySubCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_SUB_CATEGORY_LIST,
      categoryList,
      parentCategoryUuid,
    });
  };
}

export function addProductCategorySubCategoryToList(
  category: ProductCategoryModel,
  parentCategoryUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IAddProductCategorySubCategoryListToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_SUB_CATEGORY,
      category,
      parentCategoryUuid,
    });
  };
}

export function updateProductCategorySubCategoryFromList(
  category: ProductCategoryModel,
  parentCategoryUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IUpdateProductCategorySubCategoryListFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_SUB_CATEGORY,
      category,
      parentCategoryUuid,
    });
  };
}

export function deleteProductCategorySubCategoryFromList(
  categoryUuid: string,
  parentCategoryUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IDeleteProductCategorySubCategoryListFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_SUB_CATEGORY,
      categoryUuid,
      parentCategoryUuid,
    });
  };
}

export function errorProductCategorySubCategoryListInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductCategorySubCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_SUB_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_SUB_CATEGORY_LIST,
      error,
    });
  };
}
