import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';
import { CashboxNotificationRecipientDTO } from './CashBoxNotificationRecipientDTO';

import {
  CompanyDTO,
  EmployeeDTO,
  DepartmentDTO,
  CompanyMapper,
  EmployeeMapper,
  ICompanyDTOProps,
  DepartmentMapper,
  IProductDTOProps,
  IServiceDTOProps,
  IEmployeeDTOProps,
  IDepartmentDTOProps,
  ICashBoxModelReturnType,
  ClientDiscountType,
} from '../internal';
import { CashboxAutoTransferItemDTO } from './CashboxAutoTransferItemDTO';

export enum CashBoxType {
  Bank = 'bank',
  Cash = 'cash',
}

export const DEFAULT_NOTIFICATION_RECIPIENTS: CashboxNotificationRecipientDTO[] =
  [{ email: '' }];

export const DEFAULT_CASHBOX_MONEY_TRANSFERS: CashboxAutoTransferItemDTO[] = [
  {
    transfer_value: 100,
    transfer_cashbox_uuid: '',
    transfer_type: ClientDiscountType.Percentage,
  },
];

export interface ICashBoxDTOProps {
  uuid: string;

  box_type: CashBoxType.Cash;
  box_title: string;
  box_comment: string;
  box_utc_time_to_closed_at: string;
  box_notification_options?: any[];
  box_auto_transfer_options?: any[];
  box_date_and_time_to_closed_at?: string | null;
  box_pos: number;
  box_can_move_money_from: boolean;
  box_is_closed_automatically: boolean;
  box_is_email_notification_enabled: boolean;
  box_can_move_money_to: boolean;
  box_can_in_money_to: boolean;
  box_can_out_money_from: boolean;
  box_is_default_for_company: boolean;
  box_balance: number;
  created_at: string;
  updated_at: string;
  workable: IProductDTOProps | IServiceDTOProps;
  created_by: IEmployeeDTOProps;
  cashier: IEmployeeDTOProps;
  company: ICompanyDTOProps;
  box_fiscal_cashbox_id: string;
  box_is_virtual: boolean;
  box_is_locational: boolean;
  location: IDepartmentDTOProps;
}

export class CashBoxDTO {
  public uuid: string;
  public box_type: CashBoxType;
  public box_title: string;
  public box_comment: string;
  public box_utc_time_to_closed_at: string;
  public box_date_and_time_to_closed_at?: string | null;
  public box_pos: number;
  public box_can_move_money_from: boolean;
  public box_is_closed_automatically: boolean;
  public box_can_move_money_to: boolean;
  public box_can_in_money_to: boolean;
  public box_notification_options?: any;
  public box_auto_transfer_options?: any;
  public box_is_email_notification_enabled?: boolean;
  public box_can_out_money_from: boolean;
  public box_is_default_for_company: boolean;
  public box_balance: number;
  public created_at: string;
  public updated_at: string;

  public created_by: EmployeeDTO;
  public cashier: EmployeeDTO;
  public company: ICompanyDTOProps;
  public box_fiscal_cashbox_id: string;
  public box_is_virtual: boolean;
  public box_is_locational: boolean;
  public location: DepartmentDTO;

  constructor(
    props: ICashBoxModelReturnType | ICashBoxDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.box_type = props?.box_type || CashBoxType.Cash;
    this.box_title = props?.box_title || '';
    this.box_comment = props?.box_comment || '';
    this.box_utc_time_to_closed_at =
      props?.box_utc_time_to_closed_at || '17:00';
    this.box_pos = props?.box_pos || 0;
    this.box_notification_options = props?.box_notification_options || {};
    this.box_auto_transfer_options = props?.box_auto_transfer_options || {};
    this.box_can_move_money_from = props?.box_can_move_money_from || false;
    this.box_date_and_time_to_closed_at = props?.box_date_and_time_to_closed_at;
    this.box_is_email_notification_enabled =
      props?.box_is_email_notification_enabled;
    this.box_is_closed_automatically =
      props?.box_is_closed_automatically || false;
    this.box_can_move_money_to = props?.box_can_move_money_to || false;
    this.box_can_in_money_to = props?.box_can_in_money_to || false;
    this.box_can_out_money_from = props?.box_can_out_money_from || false;
    this.box_is_default_for_company =
      props?.box_is_default_for_company || false;
    this.box_balance = correctPrice(props?.box_balance || 0);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.box_fiscal_cashbox_id = props?.box_fiscal_cashbox_id || '';
    this.box_is_virtual = props?.box_is_virtual ?? false;
    this.box_is_locational = props?.box_is_locational ?? false;
    this.location =
      withoutRecursion.indexOf(WithoutRecursion.department) !== -1
        ? (props?.location as DepartmentDTO)
        : DepartmentMapper.toDepartmentDTO(props?.location, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.created_by, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);

    this.cashier =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.cashier as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.cashier, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);
  }
}
