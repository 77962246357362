import * as React from 'react';
import { List } from 'immutable';
import moment from 'moment';
import {
  editEmployeeDayOff,
  createEmployeeDayOff,
  deleteEmployeeDayOff,
} from '@services/api/employeeDayOffServices';
import { DayOffModel, DayOffDTO, DayOffMapper } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  useEmployeeDayOffList,
  IUseEmployeeDayOffListReturnType,
} from './useEmployeeDaysOffList';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateEmployeeDaysOffProps {
  employeeUuid: string;
}

export interface IUseStateEmployeeDaysOffReturnType
  extends Omit<IUseEmployeeDayOffListReturnType, 'entityList'> {
  daysOff: List<DayOffModel> | null;
  loading: boolean;
  handleCreateDayOff: (value: DayOffDTO) => Promise<void>;
  handleUpdateDayOff: (value: DayOffDTO) => Promise<void>;
  handleDeleteDaysOff: (uuid: string[]) => Promise<void>;
}

export default function useStateEmployeeDaysOff({
  employeeUuid,
}: IUseStateEmployeeDaysOffProps): IUseStateEmployeeDaysOffReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    total: daysOffTotal,
    loading: daysOffLoading,
    ...rest
  } = useEmployeeDayOffList({
    employeeUuid,
  });

  const {
    entityList: daysOff,
    handleCreate,
    handleDelete,
    handleUpdate,
    loading,
    total,
  } = useStateEntityList<DayOffModel>({ entityList, total: daysOffTotal });

  const handleCreateDayOff = React.useCallback(
    async (value: DayOffDTO): Promise<void> => {
      const dayOffFormDTO = DayOffMapper.toDayOffDTO(value);

      const dayOffDTO = await createEmployeeDayOff(dayOffFormDTO, employeeUuid);

      const dayOffModel = DayOffMapper.toDayOffModel(dayOffDTO);

      const updatedModel = dayOffModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);

      alert('success', t('Day off'), t('Day off created success'));
    },
    [employeeUuid, handleCreate, alert, t],
  );

  const handleDeleteDaysOff = React.useCallback(
    async (uuid: string[]): Promise<void> => {
      try {
        const { deleted } = await deleteEmployeeDayOff(uuid);

        if (deleted) {
          handleDelete(uuid);
        }
        alert('success', t('Day off'), t('Day off delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Days off'),
          `${t('An error occurred during delete days off')}: ${error?.message}`,
        );
      }
    },
    [alert, handleDelete, t],
  );
  const handleUpdateDayOff = React.useCallback(
    async (value: DayOffDTO): Promise<void> => {
      const dayOffFormDTO = DayOffMapper.toDayOffDTO(value as any);

      const dayOffDTO = await editEmployeeDayOff(dayOffFormDTO);

      const dayOffModel = DayOffMapper.toDayOffModel(dayOffDTO);

      handleUpdate(dayOffModel);

      alert('success', t('Day off'), t('Day off edited success'));
    },
    [alert, handleUpdate, t],
  );

  return {
    ...rest,
    daysOff,
    loading: daysOffLoading || loading,
    total,
    handleCreateDayOff,
    handleDeleteDaysOff,
    handleUpdateDayOff,
  };
}
