import { List, Map } from 'immutable';
import { APP_STATE, SERVICE_EMPLOYEE_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { EmployeeListModel, EmployeeMapper, EmployeeModel } from '@structure';

interface IServiceEmployeeListState {
  serviceEmployeeList: List<EmployeeModel> | null;
  cachedServiceEmployeeList: Map<string, EmployeeListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<IServiceEmployeeListState, 'keywords' | 'total'> {
  type: SERVICE_EMPLOYEE_LIST.SET_SERVICE_EMPLOYEE_LIST;
  serviceUuid: string;

  serviceEmployeeList: EmployeeListModel;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type:
    | SERVICE_EMPLOYEE_LIST.ADD_SERVICE_EMPLOYEE
    | SERVICE_EMPLOYEE_LIST.ADD_EMPLOYEE_TO_SERVICE;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

interface UpdateServiceEmployeeFromList {
  type: SERVICE_EMPLOYEE_LIST.UPDATE_SERVICE_EMPLOYEE;

  employeeService: EmployeeModel;
  serviceUuid: string;
}

interface DeleteActionFromList {
  type:
    | SERVICE_EMPLOYEE_LIST.DELETE_SERVICE_EMPLOYEE
    | SERVICE_EMPLOYEE_LIST.DELETE_EMPLOYEE_FROM_SERVICE;
  serviceEmployeeUuid: string;
  serviceUuid: string;
}

interface LoadingActionInList {
  type: SERVICE_EMPLOYEE_LIST.LOADING_SERVICE_EMPLOYEE_LIST;
}

interface ErrorActionInList extends Pick<IServiceEmployeeListState, 'error'> {
  type: SERVICE_EMPLOYEE_LIST.ERROR_SERVICE_EMPLOYEE_LIST;
}

interface Handlers {
  [SERVICE_EMPLOYEE_LIST.SET_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
    action: SetActionList,
  ) => IServiceEmployeeListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IServiceEmployeeListState,
    action: SetInitialStateAction,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.LOAD_MORE_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
    action: SetActionList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.ADD_SERVICE_EMPLOYEE]: (
    state: IServiceEmployeeListState,
    action: AddActionToList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.ADD_EMPLOYEE_TO_SERVICE]: (
    state: IServiceEmployeeListState,
    action: UpdateServiceEmployeeFromList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.UPDATE_SERVICE_EMPLOYEE]: (
    state: IServiceEmployeeListState,
    action: UpdateServiceEmployeeFromList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.UPDATE_EMPLOYEE_FOR_SERVICE]: (
    state: IServiceEmployeeListState,
    action: UpdateServiceEmployeeFromList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.DELETE_SERVICE_EMPLOYEE]: (
    state: IServiceEmployeeListState,
    action: DeleteActionFromList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.DELETE_EMPLOYEE_FROM_SERVICE]: (
    state: IServiceEmployeeListState,
    action: DeleteActionFromList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.ERROR_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
    value: ErrorActionInList,
  ) => IServiceEmployeeListState;

  [SERVICE_EMPLOYEE_LIST.LOADING_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
    value?: LoadingActionInList,
  ) => IServiceEmployeeListState;
  DEFAULT: (state: IServiceEmployeeListState) => IServiceEmployeeListState;
}

const initState: IServiceEmployeeListState = {
  serviceEmployeeList: null,
  cachedServiceEmployeeList: Map(),
  error: null,
  loading: false,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [SERVICE_EMPLOYEE_LIST.SET_SERVICE_EMPLOYEE_LIST]: (
    state,
    { serviceEmployeeList, serviceUuid, keywords, page },
  ) => {
    const updatedList = serviceEmployeeList
      ? serviceEmployeeList
          .update('keywords', () => keywords || '')
          .update('page', () => page || 1)
      : null;

    return {
      ...state,
      ...{
        serviceEmployeeList: serviceEmployeeList?.employees || null,
        cachedServiceEmployeeList: serviceEmployeeList
          ? state.cachedServiceEmployeeList.set(serviceUuid, updatedList as any)
          : state.cachedServiceEmployeeList,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total:
          serviceEmployeeList?.total! >= 0
            ? serviceEmployeeList?.total!
            : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [SERVICE_EMPLOYEE_LIST.LOAD_MORE_SERVICE_EMPLOYEE_LIST]: (
    state,
    { serviceEmployeeList, serviceUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedServiceEmployeeList.get(serviceUuid);

    return {
      ...state,
      ...{
        serviceEmployeeList:
          List.isList(state.serviceEmployeeList) &&
          List.isList(serviceEmployeeList)
            ? state.serviceEmployeeList.merge(serviceEmployeeList.employees)
            : state.serviceEmployeeList,
        cachedServiceEmployeeList: serviceEmployeeList
          ? state.cachedServiceEmployeeList.set(
              serviceUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('employees', (employees) =>
                    employees.merge(serviceEmployeeList.employees),
                  )
                : serviceEmployeeList,
            )
          : state.cachedServiceEmployeeList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.ADD_SERVICE_EMPLOYEE]: (
    state,
    { employeeService, serviceUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedServiceEmployeeList.get(serviceUuid);

    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.unshift(employeeService)
          : List([employeeService]),
        cachedServiceEmployeeList: relaterScheduleListById
          ? state.cachedServiceEmployeeList.update(
              serviceUuid,
              (serviceEmployeeList) => {
                if (serviceEmployeeList) {
                  return serviceEmployeeList.update('employees', (employees) =>
                    employees.push(employeeService),
                  );
                } else {
                  return EmployeeMapper.toEmployeeListModel(
                    [employeeService] as any,
                    1,
                  );
                }
              },
            )
          : Map({
              [serviceUuid]: EmployeeMapper.toEmployeeListModel(
                [employeeService] as any,
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.ADD_EMPLOYEE_TO_SERVICE]: (
    state,
    { employeeService },
  ) => {
    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.unshift(employeeService)
          : List([employeeService]),
        cachedServiceEmployeeList: state.cachedServiceEmployeeList.map((list) =>
          list.update('employees', (employees) =>
            employees.unshift(employeeService),
          ),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.UPDATE_SERVICE_EMPLOYEE]: (
    state: IServiceEmployeeListState,
    { employeeService, serviceUuid }: UpdateServiceEmployeeFromList,
  ) => {
    const relaterScheduleListById =
      state.cachedServiceEmployeeList.get(serviceUuid);

    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.map((stateServiceEmployee) => {
              if (stateServiceEmployee.uuid === employeeService?.uuid) {
                return stateServiceEmployee.merge(employeeService);
              }
              return stateServiceEmployee;
            })
          : List([employeeService]),

        cachedServiceEmployeeList: relaterScheduleListById
          ? state.cachedServiceEmployeeList.update(
              serviceUuid,
              (serviceEmployeeList) => {
                if (serviceEmployeeList) {
                  return serviceEmployeeList.update('employees', (employees) =>
                    employees.map((service) => {
                      if (service.uuid === employeeService.uuid) {
                        return service.merge(employeeService);
                      } else {
                        return service;
                      }
                    }),
                  );
                } else {
                  return EmployeeMapper.toEmployeeListModel([] as any, 0);
                }
              },
            )
          : state.cachedServiceEmployeeList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.UPDATE_EMPLOYEE_FOR_SERVICE]: (
    state: IServiceEmployeeListState,
    { employeeService }: UpdateServiceEmployeeFromList,
  ) => {
    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.map((stateServiceEmployee) => {
              if (stateServiceEmployee.uuid === employeeService?.uuid) {
                return employeeService.update(
                  'employee_service',
                  () => stateServiceEmployee.employee_service,
                );
              }
              return stateServiceEmployee;
            })
          : List([employeeService]),
        cachedServiceEmployeeList: state.cachedServiceEmployeeList.map(
          (employeeList) => {
            return employeeList.update('employees', (list) => {
              const index = list.findIndex(
                ({ uuid }) => uuid === employeeService?.uuid,
              );

              if (~index) {
                return list.update(index, (employee) => {
                  if (employee) {
                    return employeeService.update(
                      'employee_service',
                      () => employee.employee_service,
                    );
                  }

                  return employeeService;
                });
              }

              return list;
            });
          },
        ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.DELETE_EMPLOYEE_FROM_SERVICE]: (
    state,
    { serviceEmployeeUuid, serviceUuid },
  ) => {
    const serviceEmployeeListById =
      state.cachedServiceEmployeeList.get(serviceUuid);

    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.filter(
              compose(not, isEqualByUuid(serviceEmployeeUuid)),
            )
          : null,
        cachedServiceEmployeeList: serviceEmployeeListById
          ? state.cachedServiceEmployeeList.update(
              serviceUuid,
              (employeeScheduleList) => {
                if (employeeScheduleList) {
                  return employeeScheduleList.update('employees', (employees) =>
                    employees.filter(compose(not, isEqualByUuid(serviceUuid))),
                  );
                }

                return EmployeeMapper.toEmployeeListModel([] as any, 0);
              },
            )
          : state.cachedServiceEmployeeList,
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.DELETE_SERVICE_EMPLOYEE]: (
    state,
    { serviceEmployeeUuid },
  ) => {
    return {
      ...state,
      ...{
        serviceEmployeeList: List.isList(state.serviceEmployeeList)
          ? state.serviceEmployeeList.filter(
              compose(not, isEqualByUuid(serviceEmployeeUuid)),
            )
          : null,
        cachedServiceEmployeeList: state.cachedServiceEmployeeList.map(
          (employeeList) => {
            return employeeList.update('employees', (list) => {
              const index = list.findIndex(
                ({ uuid }) => uuid === serviceEmployeeUuid,
              );

              if (~index) {
                return list.delete(index);
              }

              return list;
            });
          },
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [SERVICE_EMPLOYEE_LIST.ERROR_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [SERVICE_EMPLOYEE_LIST.LOADING_SERVICE_EMPLOYEE_LIST]: (
    state: IServiceEmployeeListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IServiceEmployeeListState) => state,
};

export default function ServiceEmployeeList(
  state: any = initState,
  action: any,
): IServiceEmployeeListState {
  const handler =
    handlers[action.type as SERVICE_EMPLOYEE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
