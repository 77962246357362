import { List } from 'immutable';
import Switch from 'antd/es/switch';
import styled from 'styled-components';
import { Col, Row, Form, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useClientListFilter } from '../Managers';
import { useDropdownAlert } from '@contex';
import { FilterButtonProps } from '../../common/Buttons';
import { ClientSettlementType } from '@structure';
import React, { useCallback, useRef, useState } from 'react';
import { StatusError } from '@components/lib/Errors';
import { FilterButton, Segmented } from '@components/lib/DataDisplay';
import { DEFAULT_CLIENT_FILTER } from '@services/api/company';
import { IUseStateCompanyClientListProps } from '@hooks';
import { ClientSettlementSegmentView } from '../Show/ClientSettlementSegmentView';

import '../../Statuses/Show/StatusListSelect.less';

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colors.success};
  }
`;

const StyledSwitch = styled(Switch)`
  :where(.css-dev-only-do-not-override-1jltjcu).ant-switch.ant-switch-checked {
    background: ${({ theme }) => theme.colors.primaryColor.background};
  }
  :where(
      .css-dev-only-do-not-override-1jltjcu
    ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: ${({ theme }) => theme.colors.primaryColor.background};
  }
`;

export interface ClientFilterButtonProps
  extends FilterButtonProps<IUseStateCompanyClientListProps, any> {}

export interface ClientFilterButtonState {
  selectProvisioners?: SelectProps['options'];
  selectSettlement?: SelectProps['options'];
  selectPersonality?: SelectProps['options'];
}

export const CATEGORIES_VIEW = [
  {
    uuid: ClientSettlementType.ALL,
    title: (
      <ClientSettlementSegmentView
        title="All"
        view={ClientSettlementType.ALL}
      />
    ),
  },
  {
    uuid: ClientSettlementType.DEBTOR,
    title: (
      <ClientSettlementSegmentView
        title="Who owes us"
        view={ClientSettlementType.DEBTOR}
      />
    ),
  },
  {
    uuid: ClientSettlementType.CREDITOR,
    title: (
      <ClientSettlementSegmentView
        title="We owe"
        view={ClientSettlementType.CREDITOR}
      />
    ),
  },
];

export function ClientFilterButton({
  loading: initLoading,
  onFilter,
}: ClientFilterButtonProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const contentRef = useRef(null);

  const {
    count,
    loading,
    client_type,
    provisioner_only: provisionerOnlyStored = false,
    settlement: settlementStored = ClientSettlementType.ALL,

    handleUpdateClientListFilter,
    handleApplyClientListFilters,
    handleCancelChangeClientListFilter,
    handleResetClientListFilters,
  } = useClientListFilter();

  const [provisionerOnly, setProvisionerOnly] = useState<boolean>(
    provisionerOnlyStored,
  );

  const [settlement, setSettlement] =
    useState<ClientSettlementType>(settlementStored);

  const handleFilterClientList = useCallback(async () => {
    try {
      const clients = await onFilter(
        {
          settlement,
          client_type,
          provisioner_only: provisionerOnly,
        },
        true,
      );

      if (List.isList(clients)) {
        handleApplyClientListFilters();
      }
    } catch (error: any) {
      alert(
        'error',
        t('Counterparties'),
        `${t('An error occurred during client filter')}: ${error?.message}`,
      );
      throw new StatusError(error?.message, error?.status);
    }
  }, [
    alert,
    provisionerOnly,
    settlement,
    client_type,
    handleApplyClientListFilters,
    onFilter,
    t,
  ]);
  const handleResetClientList = useCallback(async () => {
    try {
      const clients = await onFilter(DEFAULT_CLIENT_FILTER, true);

      if (List.isList(clients)) {
        handleResetClientListFilters();
      }

      Promise.all([
        setProvisionerOnly(false),
        setSettlement(ClientSettlementType.ALL),
      ]).then(() => console.debug('The filters were reset successfully.'));
    } catch (error: any) {
      alert(
        'error',
        t('Counterparties'),
        `${t('An error occurred during client filter')}: ${error?.message}`,
      );
      throw new StatusError(error?.message, error?.status);
    }
  }, [alert, handleResetClientListFilters, onFilter, t]);

  return (
    <FilterButton
      count={count}
      ref={contentRef}
      loading={initLoading}
      disabled={loading}
      onSuccess={handleFilterClientList}
      onCancel={handleCancelChangeClientListFilter}
      onReset={handleResetClientList}>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            label={t('Show provisioners only')}
            tooltip={t(
              'If this option is selected only items with provisioner flag activated will be shown.',
            )}>
            <StyledSwitch
              size="default"
              checked={provisionerOnly === true}
              onChange={async (provisioner_only) => {
                setProvisionerOnly(provisioner_only);
                await handleUpdateClientListFilter({ provisioner_only });
                // await handleSearchClients({
                //   keywords,
                //   provisioner_only,
                // });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('Filter clients by payment criteria')}
            tooltip={t(
              'This component helps to filter out the items by payment criteria.',
            )}>
            <StyledSegmented
              options={CATEGORIES_VIEW}
              disabled={loading}
              value={settlement}
              onChange={async (settlement: any) => {
                await Promise.all([
                  setSettlement(settlement),
                  handleUpdateClientListFilter({ settlement }),
                ]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterButton>
  );
}
