import { ITimeInDay, ScheduleDateProp, IMonthFormat } from '@services/helpers';
import { DateValue } from '@services/types';
import { ScheduleCalendarModel } from '@structure';
import { List } from 'immutable';

export enum MODE {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const SELECT_MODE: MODE[] = [MODE.DAY, MODE.WEEK, MODE.MONTH];

export interface IInitialState {
  selectedDate: string | Date;
  monthDates: IMonthFormat[];
  defaultMonthDates: IMonthFormat[];
  mode: MODE;
  hourInDay: ITimeInDay[];
  scheduleStartProp: ScheduleDateProp;
  scheduleEndProp: ScheduleDateProp;
  defaultScheduleItem: ScheduleCalendarModel;
  withAddingEvents: boolean;
  currentMonthLastDate: DateValue;
  loading: boolean;
  schedules: List<ScheduleCalendarModel> | null;
}

export enum CALENDAR_ACTIONS {
  SET_TODAY = 'set_day',
  SET_MODE = 'set_mode',
  SET_LOADING = 'set_loading',
  SET_MONTH_DATES = 'set_month_dates',
  SET_LAST_MONTH_DATE = 'set_last_month_date',
  SET_DEFAULT_MONTH_DATES = 'set_default_month_dates',
  SET_SELECTED_DATE = 'set_selected_date',
  SET_HOUR_IN_DAY = 'set_hour_in_day',
  SET_FIRST_FINISH_RENDER = 'set_first_finish_render',
  SET_DEFAULT_SCHEDULE_ITEM = 'set_default_schedule_item',
  SET_SCHEDULES = 'set_schedules',
  DEFAULT = 'default',
}

export interface SetModeAction extends Pick<IInitialState, 'mode'> {
  type: CALENDAR_ACTIONS.SET_MODE;
}

export interface SetLoadingAction extends Pick<IInitialState, 'loading'> {
  type: CALENDAR_ACTIONS.SET_LOADING;
}

export interface SetMonthDatesAction extends Pick<IInitialState, 'monthDates'> {
  type: CALENDAR_ACTIONS.SET_MONTH_DATES;
}
export interface SetLastMonthDateAction
  extends Pick<IInitialState, 'currentMonthLastDate'> {
  type: CALENDAR_ACTIONS.SET_LAST_MONTH_DATE;
}
export interface SetDefaultMonthDatesAction
  extends Pick<IInitialState, 'defaultMonthDates'> {
  type: CALENDAR_ACTIONS.SET_DEFAULT_MONTH_DATES;
}
export interface SetDefaultScheduleItem
  extends Pick<IInitialState, 'defaultScheduleItem'> {
  type: CALENDAR_ACTIONS.SET_DEFAULT_SCHEDULE_ITEM;
}

export interface SetSchedules extends Pick<IInitialState, 'schedules'> {
  type: CALENDAR_ACTIONS.SET_SCHEDULES;
}

export interface SetSelectedDateAction
  extends Pick<IInitialState, 'selectedDate'> {
  type: CALENDAR_ACTIONS.SET_SELECTED_DATE;
}

export interface SetHourInDayAction extends Pick<IInitialState, 'hourInDay'> {
  type: CALENDAR_ACTIONS.SET_HOUR_IN_DAY;
}

export type CalendarActions =
  | SetMonthDatesAction
  | SetSelectedDateAction
  | SetHourInDayAction
  | SetDefaultMonthDatesAction
  | SetDefaultScheduleItem
  | SetLastMonthDateAction
  | SetModeAction
  | SetSchedules
  | SetLoadingAction;

export const handlers = {
  [CALENDAR_ACTIONS.SET_MODE]: (
    state: IInitialState,
    { mode }: SetModeAction,
  ): IInitialState => ({
    ...state,
    mode,
  }),
  [CALENDAR_ACTIONS.SET_MONTH_DATES]: (
    state: IInitialState,
    { monthDates }: SetMonthDatesAction,
  ): IInitialState => ({
    ...state,
    monthDates,
  }),
  [CALENDAR_ACTIONS.SET_LOADING]: (
    state: IInitialState,
    { loading }: SetLoadingAction,
  ): IInitialState => ({
    ...state,
    loading,
  }),
  [CALENDAR_ACTIONS.SET_DEFAULT_MONTH_DATES]: (
    state: IInitialState,
    { defaultMonthDates }: SetDefaultMonthDatesAction,
  ): IInitialState => ({
    ...state,
    defaultMonthDates,
  }),
  [CALENDAR_ACTIONS.SET_DEFAULT_SCHEDULE_ITEM]: (
    state: IInitialState,
    { defaultScheduleItem }: SetDefaultScheduleItem,
  ): IInitialState => ({
    ...state,
    defaultScheduleItem,
  }),
  [CALENDAR_ACTIONS.SET_SELECTED_DATE]: (
    state: IInitialState,
    { selectedDate }: SetSelectedDateAction,
  ): IInitialState => ({
    ...state,
    selectedDate,
  }),
  [CALENDAR_ACTIONS.SET_HOUR_IN_DAY]: (
    state: IInitialState,
    { hourInDay }: SetHourInDayAction,
  ): IInitialState => ({
    ...state,
    hourInDay,
  }),
  [CALENDAR_ACTIONS.SET_LAST_MONTH_DATE]: (
    state: IInitialState,
    { currentMonthLastDate }: SetLastMonthDateAction,
  ): IInitialState => ({
    ...state,
    currentMonthLastDate,
  }),
  [CALENDAR_ACTIONS.SET_SCHEDULES]: (
    state: IInitialState,
    { schedules }: SetSchedules,
  ): IInitialState => ({
    ...state,
    schedules,
  }),
  [CALENDAR_ACTIONS.DEFAULT]: (state: IInitialState): IInitialState => state,
};
