import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { PaymentFormDTO } from '@structure';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { Routes } from '@services/types';

const PaymentSideWindowLazy = React.lazy(
  () => import('../Show/PaymentSideWindow'),
);

export interface IUpdatePaymentButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: PaymentFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  payment: PaymentFormDTO;
  companyUuid: string;
  from?: Routes.clients | Routes.orders;
}

export default function UpdatePaymentMenuButton({
  children,
  payment,
  onSuccess,
  onCancel,
  companyUuid,
  from,
  ...rest
}: IUpdatePaymentButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit company payment')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentSideWindowLazy
          from={from}
          companyUuid={companyUuid}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          editMode
          payment={payment}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
