import { List, Map } from 'immutable';
import { APP_STATE, ABONEMENT_GROUP_LIST } from '../constants';
import { compose, isEqualByUuid, not } from '@services/helpers';
import { ApiError, REDUX_STATUS } from '@services/types';
import {
  AbonementGroupListModel,
  AbonementGroupMapper,
  AbonementGroupModel,
} from '@structure';

interface IAbonementGroupListState {
  groupList: List<AbonementGroupModel> | null;
  cachedAbonementGroupList: Map<string, AbonementGroupListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList extends Pick<IAbonementGroupListState, 'total'> {
  type: ABONEMENT_GROUP_LIST.SET_ABONEMENT_GROUP_LIST;
  abonementUuid: string;
  groupList: AbonementGroupListModel;
  keywords?: string;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: ABONEMENT_GROUP_LIST.ADD_ABONEMENT_GROUP;
  group: AbonementGroupModel;
  abonementUuid: string;
}

interface UpdateAbonementGroupFromList {
  type: ABONEMENT_GROUP_LIST.UPDATE_ABONEMENT_GROUP;
  group: AbonementGroupModel;
  abonementUuid: string;
}

interface DeleteActionFromList {
  type: ABONEMENT_GROUP_LIST.DELETE_ABONEMENT_GROUP;
  groupUuid: string;
  abonementUuid: string;
}

interface LoadingActionInList {
  type: ABONEMENT_GROUP_LIST.LOADING_ABONEMENT_GROUP_LIST;
}

interface ErrorActionInList extends Pick<IAbonementGroupListState, 'error'> {
  type: ABONEMENT_GROUP_LIST.ERROR_ABONEMENT_GROUP_LIST;
}

interface Handlers {
  [ABONEMENT_GROUP_LIST.SET_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
    action: SetActionList,
  ) => IAbonementGroupListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IAbonementGroupListState,
    action: SetInitialStateAction,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.LOAD_MORE_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
    action: SetActionList,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.ADD_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: AddActionToList,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.UPDATE_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: UpdateAbonementGroupFromList,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.DELETE_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: DeleteActionFromList,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.ERROR_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
    value: ErrorActionInList,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP_LIST.LOADING_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
    value?: LoadingActionInList,
  ) => IAbonementGroupListState;
  DEFAULT: (state: IAbonementGroupListState) => IAbonementGroupListState;
}

const initState: IAbonementGroupListState = {
  groupList: null,
  cachedAbonementGroupList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [ABONEMENT_GROUP_LIST.SET_ABONEMENT_GROUP_LIST]: (
    state,
    { groupList, abonementUuid, keywords, page },
  ) => {
    const updatedList = groupList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1);

    return {
      ...state,
      ...{
        groupList: groupList?.groups || null,
        cachedAbonementGroupList: groupList
          ? state.cachedAbonementGroupList.set(abonementUuid, updatedList)
          : state.cachedAbonementGroupList,
        status: REDUX_STATUS.SUCCEEDED,
        total: groupList?.total! >= 0 ? groupList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [ABONEMENT_GROUP_LIST.LOAD_MORE_ABONEMENT_GROUP_LIST]: (
    state,
    { groupList, abonementUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedAbonementGroupList.get(abonementUuid);

    return {
      ...state,
      ...{
        groupList:
          List.isList(state.groupList) && List.isList(groupList)
            ? state.groupList.merge(groupList.groups)
            : state.groupList,
        cachedAbonementGroupList: groupList
          ? state.cachedAbonementGroupList.set(
              abonementUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('groups', (groups) =>
                    groups.merge(groupList.groups),
                  )
                : groupList,
            )
          : state.cachedAbonementGroupList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [ABONEMENT_GROUP_LIST.ADD_ABONEMENT_GROUP]: (
    state,
    { group, abonementUuid },
  ) => {
    const relaterScheduleListById =
      state.cachedAbonementGroupList.get(abonementUuid);

    return {
      ...state,
      ...{
        groupList: List.isList(state.groupList)
          ? state.groupList.unshift(group)
          : List([group]),
        cachedAbonementGroupList: relaterScheduleListById
          ? state.cachedAbonementGroupList.update(
              abonementUuid,
              (groupList) => {
                if (groupList) {
                  return groupList
                    .update('groups', (groups) => groups.unshift(group))
                    .update('total', (total) => (total || 0) + 1);
                } else {
                  return AbonementGroupMapper.toAbonementGroupListModel(
                    [group] as any,
                    1,
                  );
                }
              },
            )
          : Map({
              [abonementUuid]: AbonementGroupMapper.toAbonementGroupListModel(
                [group] as any,
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [ABONEMENT_GROUP_LIST.UPDATE_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    { group, abonementUuid }: UpdateAbonementGroupFromList,
  ) => {
    const relaterScheduleListById =
      state.cachedAbonementGroupList.get(abonementUuid);

    return {
      ...state,
      ...{
        groupList: List.isList(state.groupList)
          ? state.groupList.map((stateAbonementGroup) => {
              if (stateAbonementGroup.uuid === group?.uuid) {
                return stateAbonementGroup.merge(group);
              }
              return stateAbonementGroup;
            })
          : List([group]),

        cachedAbonementGroupList: relaterScheduleListById
          ? state.cachedAbonementGroupList.update(
              abonementUuid,
              (groupList) => {
                if (groupList) {
                  return groupList.update('groups', (groups) =>
                    groups.map((groupModel) => {
                      if (groupModel.uuid === group.uuid) {
                        return groupModel.merge(group);
                      } else {
                        return groupModel;
                      }
                    }),
                  );
                } else {
                  return AbonementGroupMapper.toAbonementGroupListModel([], 0);
                }
              },
            )
          : state.cachedAbonementGroupList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [ABONEMENT_GROUP_LIST.DELETE_ABONEMENT_GROUP]: (state, { groupUuid }) => {
    return {
      ...state,
      ...{
        groupList: List.isList(state.groupList)
          ? state.groupList.filter(compose(not, isEqualByUuid(groupUuid)))
          : null,
        cachedAbonementGroupList: state.cachedAbonementGroupList.map((groups) =>
          groups.update('groups', (list) => {
            const index = list.findIndex(({ uuid }) => uuid === groupUuid);

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [ABONEMENT_GROUP_LIST.ERROR_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [ABONEMENT_GROUP_LIST.LOADING_ABONEMENT_GROUP_LIST]: (
    state: IAbonementGroupListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IAbonementGroupListState) => state,
};

export default function AbonementGroupList(
  state: any = initState,
  action: any,
): IAbonementGroupListState {
  const handler =
    handlers[action.type as ABONEMENT_GROUP_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
