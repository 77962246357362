import { Empty } from 'antd';
import { IoMdAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import ClientList from '../List/ClientList';
import styled, { useTheme } from 'styled-components';
import { ClientListFilterManager } from '../Managers';
import { ClientListHeaderPersonality } from '../Show';
import { useLocation, useNavigate } from 'react-router';
import { useAcl, useDropdownAlert } from '@contex';
import { ListLayout } from '@components/lib/Layout';
import React, { useCallback, useMemo, useState } from 'react';
import { ClientFilterButton } from '../Buttons/ClientFilterButton';
import { successButtonStyle } from '@components/lib/Styled';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { ClientModel, ClientMapper, ClientOrgType } from '@structure';
import { StyledDetailsView } from '@components/lib/Styled/DetailsView';
import { len, lessOrEqualThan, textToUpperCase } from '@services/helpers';

import {
  ClientCreateButton,
  ClientsDeleteButton,
  IClientCreateButtonProps,
} from '../Buttons';

import {
  useStopLoading,
  useStoredCompanies,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateCompanyClientList,
} from '@hooks';

import {
  Routes,
  RoutesAcl,
  RoutesTitle,
  ClientPersonalityType,
  Routes as RoutesType,
} from '@services/types';

import {
  HeaderLeft,
  ListHeader,
  ListActions,
} from '@components/lib/DataDisplay';

const StyledClientCreateButton = styled(ClientCreateButton)`
  ${successButtonStyle}
`;

export default function ClientListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ client }) => client);
  const { alert } = useDropdownAlert();
  const theme: any = useTheme();

  const [selectedClients, setSelectedClients] = useState<ClientModel[]>([]);

  const [type, setType] = useState<ClientPersonalityType>(
    location?.state?.type || ClientPersonalityType.Individual,
  );

  const { ref: listRef } = useKeyboardOpenForm({
    className:
      type === ClientPersonalityType.Individual
        ? 'client-create-event'
        : 'group-create-event',
    disabled: !manage,
  });

  const {
    clients,
    loading: clientsListLoading,
    error: clientsListError,
    limit: pageSize,
    total,
    client_type,
    stats,
    keywords,
    provisioner_only,

    refresh,
    handleCreateClient,
    handleCreateClientGroup,
    handleDeleteClients: onDeleteClients,
    handleUpdateClient,
    handleUpdateClientGroup,
    handleSearchClients,
    handleUpdateClientLegal,
    handleCreateClientLegal,
    handleImportClientFields,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    client_type: type,
  });

  const isIndividualType: boolean =
    client_type === ClientPersonalityType.Individual;

  const loading = useStopLoading({
    error: clientsListError,
    loading: clientsListLoading,
    message: 'An error occurred during clients loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteClients = useCallback(
    async (value: string[]) => {
      await onDeleteClients(value);
      focus();
    },
    [focus, onDeleteClients],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: isIndividualType ? 'Counterparties' : 'Clients groups',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      columnWidth: '20px',
      hideSelectAll: true,
      selectedRowKeys: selectedClients.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ClientModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 3)) {
          setSelectedClients(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 3 at a time'));
        }
      },
    }),
    [alert, selectedClients, t],
  );

  const onClientTypeChange = useCallback(
    (client_type: ClientPersonalityType) => {
      setType(client_type);
      window.history.replaceState({}, document.title);
    },
    [],
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const CreateButtonsComponents = (
    client_type: ClientPersonalityType | undefined,
    ClientCreateButton: React.FC<IClientCreateButtonProps>,
    props: Partial<IClientCreateButtonProps> = {},
  ) => (
    <>
      {client_type === ClientPersonalityType.Individual ? (
        <ClientCreateButton
          className="client-create-event"
          type="primary"
          tooltipTitle={t('Create client or provisioner')}
          disabled={!manage}
          loading={loading}
          onSuccess={async (value) => {
            if (value?.client_org_type === ClientOrgType.PERSON) {
              await handleCreateClient(value);
            } else {
              await handleCreateClientLegal(value as any);
            }
            setType(ClientPersonalityType.Individual);
          }}
          title={t('Create')}
          onCancel={daleyFocus}
          client={ClientMapper.toClientFormDTO({
            client_is_provisioner: provisioner_only,
          } as ClientModel)}
          {...props}
        />
      ) : (
        <ClientCreateButton
          className="group-create-event"
          type="primary"
          tooltipTitle={t('Create group')}
          disabled={!manage}
          loading={loading}
          onSuccessGroup={async (value) => {
            await handleCreateClientGroup(value);
            setType(ClientPersonalityType.Group);
          }}
          title={t('Create')}
          onCancel={daleyFocus}
          {...props}
        />
      )}
    </>
  );

  return (
    <ClientListFilterManager>
      <ListLayout
        ref={listRef}
        aclItem={RoutesAcl[Routes.clients]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerTitle={textToUpperCase(
          t(
            RoutesTitle[
              isIndividualType ? RoutesType.clients : RoutesType.clientGroups
            ],
          ),
        )}
        headerRight={
          <>
            {selectedClients.length ? (
              <ClientsDeleteButton
                disabled={!manage}
                clients={selectedClients}
                onSuccess={handleDeleteClients}
              />
            ) : (
              CreateButtonsComponents(
                client_type,
                StyledClientCreateButton,
                {
                  icon: <IoMdAdd fontSize={16} />,
                },
                // {
                //   onSuccessGroup: async (value) => {
                //     await handleCreateClientGroup(value);
                //     setType(ClientPersonalityType.Group);
                //   },
                //   onSuccessImport: handleImportClientFields,
                // },
              )
            )}
          </>
        }
        routes={routes}
        empty={
          clients?.size ? null : (
            <Empty
              description={t(
                isIndividualType
                  ? `It looks like you don't have any clients at the moment.`
                  : `It looks like you don't have any group of clients at the moment.`,
              )}>
              {CreateButtonsComponents(client_type, StyledClientCreateButton, {
                icon: <IoMdAdd fontSize={16} />,
              })}
            </Empty>
          )
        }
        headerExtra={
          <ListHeader>
            <StyledDetailsView
              titles={[]}
              selected={[
                type === ClientPersonalityType.Individual,
                type === ClientPersonalityType.Group,
              ]}>
              <ClientListHeaderPersonality
                color={theme.tabs.link}
                type={ClientPersonalityType.Individual}
                title="My counterparties"
                stats={stats}
                onClick={onClientTypeChange}
                disabled={loading}
              />
              <ClientListHeaderPersonality
                color={theme.tabs.success}
                type={ClientPersonalityType.Group}
                title="Clients groups"
                stats={stats}
                onClick={onClientTypeChange}
                disabled={loading}
              />
            </StyledDetailsView>
            <ListActions
              inputRef={inputRef}
              withoutPicker
              loading={loading}
              handleSearch={(keywords) => handleSearchClients({ keywords })}
              inputTooltip={t(
                isIndividualType ? 'Search clients' : 'Search group of clients',
              )}
              inputLabel={t(
                isIndividualType ? 'Search clients' : 'Search group of clients',
              )}
              withSearchEndContent={
                isIndividualType ? (
                  <ClientFilterButton
                    loading={loading}
                    onFilter={handleSearchClients}
                  />
                ) : null
              }
            />
          </ListHeader>
        }
        loading={loading && !clients?.size}>
        <ClientList
          limit={pageSize}
          clients={clients}
          loading={loading}
          onRefresh={refresh}
          rowSelection={rowSelection}
          total={total}
          handleDeleteClients={handleDeleteClients}
          handleUpdateClientGroup={handleUpdateClientGroup}
          handleUpdateClient={handleUpdateClient}
          handleUpdateClientLegal={handleUpdateClientLegal}
        />
      </ListLayout>
    </ClientListFilterManager>
  );
}
