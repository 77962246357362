import React from 'react';
import { Button } from 'antd';
import { WarningOutlined, RightOutlined } from '@ant-design/icons';
import { Text } from '@components/lib/Format';
import { textToUpperCase } from '@services/helpers';
import styled, { useTheme, css } from 'styled-components';

export interface IWarningNavigateButtonProps {
  onClick: () => void;
  title: string;
  mode?: 'light' | 'dark';
  style?: any;
}

const StyledWarningButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  opacity: 1;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.app};
    opacity: 0.7;
  }
`;

const StyledNavigateContainer = styled.div`
  flex-direction: row;
`;

const iconStyle = css<{ mode?: 'light' | 'dark' }>`
  color: ${({ theme }) => theme.colors.primary};

  ${({ mode }) =>
    mode === 'dark' &&
    css`
      color: ${({ theme }) => theme.colors.warning};
    `}
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  ${iconStyle};
  margin-right: 10px;
`;

const StyledRightOutlined = styled(RightOutlined)`
  ${iconStyle};
`;

export function WarningNavigateButton({
  onClick,
  title,
  mode = 'light',
  style,
}: IWarningNavigateButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledWarningButton type="text" onClick={onClick} style={style}>
      <StyledNavigateContainer>
        <StyledWarningOutlined size={22} mode={mode} />

        <Text
          color={mode === 'light' ? theme.colors.primary : theme.colors.warning}
          style={{ maxWidth: '91%' }}>
          {textToUpperCase(title)}
        </Text>
      </StyledNavigateContainer>

      <StyledRightOutlined mode={mode} size={20} />
    </StyledWarningButton>
  );
}
