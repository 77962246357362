import { Record, List } from 'immutable';
import { IListProps } from './List';
import { CategoryModel, CategoryDTO } from '../internal';
import { WithoutRecursion } from '../type';

export interface CategoryListProps extends IListProps {
  categories: CategoryDTO[];
}

export interface CategoryListReturnType extends IListProps {
  categories: List<CategoryModel>;
}

export class CategoryListModel extends Record<CategoryListReturnType>({
  categories: List(),
  total: 0,
}) {
  constructor(
    { categories = [], total = 0 }: CategoryListProps = {} as CategoryListProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      categories: List(
        categories.map(
          (category) => new CategoryModel(category, withoutRecursion),
        ),
      ),
      total,
    });
  }
}
