import { useEffect, useCallback, useRef } from 'react';
import { getEmployeeScheduleList } from '@services/api/employee';
import { ListOptions } from '@services/models';
import {
  ListOptionsProps,
  START_OF_MONTH,
  END_OF_MONTH,
} from '@services/helpers';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import { ApiError, DateValue } from '@services/types';
import {
  ScheduleCalendarModel,
  ScheduleCalendarMapper,
  IScheduleCalendarListDTO,
} from '@structure';
import { List } from 'immutable';

export interface IUseEmployeeScheduleListProps
  extends Partial<ListOptionsProps> {
  loadOnInit?: boolean;
  showLoading?: boolean;
  employeeUuid: string;
  start?: DateValue;
  end?: DateValue;
}

export interface IUseEmployeeScheduleListReturnType {
  error: ApiError | null;
  loading: boolean;
  list: List<ScheduleCalendarModel>;
  refresh: (
    value: IUseEmployeeScheduleListProps,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  total: number;
  offset: number;
  limit: number;
  order: string;
}

export default function useEmployeeScheduleList({
  employeeUuid,
  start = START_OF_MONTH,
  end = END_OF_MONTH,
  offset = 0,
  order = 'created_at DESC',
  limit = 999,
  filters = [],
  loadOnInit = true,
}: IUseEmployeeScheduleListProps): IUseEmployeeScheduleListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    offset: listOffset,
    order: listOrder,
    limit: listPageSize,
    handleUpdate,
    start: listStart,
    end: listEnd,
    employeeUuid: listEmployeeUuid,
    list,
    ...rest
  } = useStateReducer<
    Omit<IUseEmployeeScheduleListReturnType, 'refresh'> & {
      employeeUuid: string;
      start: DateValue;
      end: DateValue;
    }
  >({
    error: null,
    loading: loadOnInit,
    list: null as any,
    offset,
    limit,
    order,
    total: 0,
    start,
    end,
    employeeUuid,
  });

  const refresh = useCallback(
    async ({
      offset = listOffset,
      order = listOrder,
      limit = listPageSize,
      showLoading = true,
      start = listStart,
      end = listEnd,
      employeeUuid = listEmployeeUuid,
      ...rest
    }: Partial<IUseEmployeeScheduleListProps> = {}): Promise<List<ScheduleCalendarModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          order,
          start,
          end,
          employeeUuid,
        });

        const { schedules, total } =
          await cancellablePromise<IScheduleCalendarListDTO>(
            getEmployeeScheduleList({
              employeeUuid,
              start,
              end,
              limit,
            }),
          );

        const scheduleModel =
          ScheduleCalendarMapper.toScheduleCalendarListModel(schedules, total);

        if (!didCancel.current) {
          handleUpdate({
            list: scheduleModel.schedules,
            loading: false,
          });

          return scheduleModel.schedules;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listEnd, listStart, employeeUuid, listPageSize],
  );

  useEffect(() => {
    didCancel.current = false;

    if (loadOnInit && employeeUuid) {
      (async () => {
        await refresh({ employeeUuid });
      })();
    }
    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, listPageSize]);

  return {
    refresh,
    offset: listOffset,
    limit: listPageSize,
    order: listOrder,
    list,
    ...rest,
  };
}
