import { RuleLimitationDTO, IRuleLimitationDTOProps } from '../internal';
import { correctPrice } from '@services/helpers';

export interface ISystemSubscriptionDTOProps {
  subscription_key: string;
  subscription_price: string;
  subscription_title: string;
  subscription_description: string;
  status_txt: string;
  rule_limitations: IRuleLimitationDTOProps[];
}

export class SystemSubscriptionDTO {
  public subscription_key: string;

  public subscription_price: string;

  public subscription_title: string;

  public subscription_description: string;

  public status_txt: string;

  public rule_limitations: IRuleLimitationDTOProps[];

  constructor(props: ISystemSubscriptionDTOProps) {
    this.subscription_key = props?.subscription_key || '';
    this.subscription_price = correctPrice(props?.subscription_price);
    this.subscription_title = props?.subscription_title || '';
    this.subscription_description = props?.subscription_description || '';
    this.status_txt = props?.status_txt || '';
    this.rule_limitations = (props?.rule_limitations || []).map(
      (rule) => new RuleLimitationDTO(rule),
    );
  }
}
