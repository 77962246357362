import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { List } from 'immutable';
import {
  IUseFileListProps,
  IUseFileListReturnType,
  useFileList,
} from './useFileList';
import { FileMapper, FileModel, FileStatusesText } from '@structure';
import { deleteFiles, uploadFileList } from '@services/api/file';
import {
  loadMoreFileList as storeLoadMoreFileList,
  setFileList as storeSetFileList,
  updateFileFromList as storeUpdateFileFromList,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { isFunction } from '@services/helpers';

export interface IUseStateFileListProps extends IUseFileListProps {
  handleUpdateStats?: (count: number) => void;
}

export interface IUseStateFileListReturnType
  extends Omit<IUseFileListReturnType, 'entityList'> {
  fileList: List<FileModel> | null;
  handleDeleteFiles: (file: FileModel) => Promise<void>;
  handleSearchFiles: (keywords: string) => Promise<void>;
  handleLoadMoreFiles: () => Promise<void>;
  handleRefreshFiles: () => Promise<void>;
  handleUploadFiles: (files: File[]) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
}

export function useStoredFileList(
  {
    scheduleUuid,
    loadOnInit = true,
    handleUpdateStats,
    ...rest
  }: IUseStateFileListProps = {} as IUseStateFileListProps,
): IUseStateFileListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [fileList, setFileList] = useState<List<FileModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedFileListStatus,
    cachedFileList,
    total: storeTotal,
    ...storedFileListParams
  } = useSelector(({ fileList }: RootState) => fileList);

  const dispatch = useDispatch<any>();

  const { entityList, offset, limit, refresh, total, ...filesParams } =
    useFileList({
      scheduleUuid,
      loadOnInit: loadOnInit || isStarLoading,
      ...rest,
    });

  React.useEffect(() => {
    if (cachedFileList?.size > 0) {
      const fileList = cachedFileList.get(scheduleUuid);

      if (fileList) {
        setIsStartLoading(false);
        setFileList(fileList);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedFileList?.size === 0 && !fileList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedFileList, scheduleUuid, fileList]);

  useEffect(() => {
    if (List.isList(entityList) && isStarLoading) {
      dispatch(storeSetFileList(entityList, scheduleUuid, '', total));
    }
  }, [
    dispatch,
    entityList,
    isStarLoading,
    scheduleUuid,
    storedFileListStatus,
    total,
  ]);

  const handleDeleteFiles = React.useCallback(
    async (file: FileModel) => {
      try {
        await deleteFiles([file?.uuid]);

        const updatedFile = file.update(
          'file_status_text',
          () => FileStatusesText.Deleted,
        );

        dispatch(storeUpdateFileFromList(updatedFile, scheduleUuid));

        alert('success', t('Files'), t('Files delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Files'),
          `${t('An error occurred during delete files')}: ${error?.message}`,
        );
      }
    },
    [dispatch, scheduleUuid, alert, t],
  );

  const handleSearchFiles = React.useCallback(
    async (keywords: string) => {
      const fileListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        showLoading: false,
      });

      if (fileListModel && List.isList(fileListModel?.files)) {
        dispatch(
          storeSetFileList(fileListModel?.files, scheduleUuid, keywords),
        );
      }
    },
    [dispatch, refresh, scheduleUuid],
  );

  const handleLoadMoreFiles = useCallback(async () => {
    if (List.isList(fileList) && fileList?.size < storeTotal && !loadingMore) {
      setLoadingMore(true);
      const fileListModel = await refresh({
        offset: fileList?.size,
        limit: 10,
        showLoading: false,
      });

      if (fileListModel && List.isList(fileListModel?.files)) {
        dispatch(storeLoadMoreFileList(fileListModel?.files, scheduleUuid));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, fileList, scheduleUuid, storeTotal]);

  const handleUploadFiles = useCallback(
    async (uploadFiles: File[]) => {
      try {
        const { files, total } = await uploadFileList(
          uploadFiles,
          scheduleUuid,
        );

        const fileListModel = FileMapper.toFileListModel(files, total);

        dispatch(storeLoadMoreFileList(fileListModel.files, scheduleUuid));

        if (isFunction(handleUpdateStats) && uploadFiles?.length) {
          handleUpdateStats(uploadFiles?.length);
        }

        alert('success', t('Files'), t('Files upload success'));
      } catch (error: any) {
        alert(
          'error',
          t('Files'),
          `${t('An error occurred during upload files')}: ${error?.message}`,
        );
      }
    },
    [alert, dispatch, handleUpdateStats, scheduleUuid, t],
  );

  const handleRefreshFiles = useCallback(async () => {
    const fileListModel = await refresh({
      offset: 0,
      limit: 10,
      showLoading: false,
    });

    if (fileListModel && List.isList(fileListModel?.files)) {
      dispatch(
        storeSetFileList(
          fileListModel?.files,
          scheduleUuid,
          '',
          fileListModel?.total,
        ),
      );
    }
  }, [dispatch, refresh, scheduleUuid]);

  return {
    ...filesParams,
    ...storedFileListParams,
    fileList,
    loadingMore,
    total: storeTotal,
    offset,
    limit,
    refresh,
    loading: !fileList,
    handleDeleteFiles,
    handleSearchFiles,
    handleLoadMoreFiles,
    handleUploadFiles,
    status: storedFileListStatus,
    handleRefreshFiles,
  };
}
