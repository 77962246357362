import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { PriceMarginFormDTO } from '@structure';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const PriceMarginSideWindowLazy = React.lazy(
  () => import('../Show/PriceMarginSideWindow'),
);

export interface IPriceMarginCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: PriceMarginFormDTO) => Promise<void>;
  onCancel?: () => void;
  isActiveByDefault: boolean;
}

export function PriceMarginCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  isActiveByDefault,
  ...rest
}: IPriceMarginCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create price margin')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <PriceMarginSideWindowLazy
          isActiveByDefault={isActiveByDefault}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
