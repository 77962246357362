import React from 'react';
import { IDefaultFormProps } from '@components/lib/General';
import {
  CLIENT_INITIAL_PARAM,
  CLIENT_LEGAL_INITIAL_PARAM,
} from '@services/api/client';
import { ClientFormDTO, ClientLegalFormDTO, ClientOrgType } from '@structure';
import ClientForm from './ClientForm';
import { ClientLegalForm } from './ClientLegalForm';
import { ClientLegalSuccess, ClientSuccess } from '../Buttons';
import { Form } from 'antd';

export interface IClientTypeFormProps
  extends Omit<
    IDefaultFormProps<
      ClientFormDTO | ClientLegalFormDTO,
      ClientFormDTO | ClientLegalFormDTO
    >,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  loading?: boolean;
  client?: ClientFormDTO | ClientLegalFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
  onSuccess: ClientSuccess | ClientLegalSuccess;
  provider?: boolean;
}

export function ClientTypeForm({
  client,
  onSuccess,
  provider,
  ...rest
}: IClientTypeFormProps) {
  const [instance] = Form.useForm();

  const clientOrgType = Form.useWatch('client_org_type', instance);

  return (
    <>
      {clientOrgType === ClientOrgType.ORGANIZATION ? (
        <ClientLegalForm
          instance={instance}
          onSuccess={onSuccess as ClientLegalSuccess}
          {...rest}
          client={(client as ClientLegalFormDTO) || CLIENT_LEGAL_INITIAL_PARAM}
          provider={provider}
        />
      ) : (
        <ClientForm
          instance={instance}
          onSuccess={onSuccess as ClientSuccess}
          {...rest}
          client={(client as ClientFormDTO) || CLIENT_INITIAL_PARAM}
          provider={provider}
        />
      )}
    </>
  );
}
