import dayjs from 'dayjs';
import { List } from 'immutable';
import { useWatch } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import styled, { useTheme } from 'styled-components';
import { DepartmentDetailsView } from '../../Departments';
import { isListToArray } from '@services/helpers';
import { TIME_FORMAT } from '@components/lib/const';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Col, Form, Input, Row, TimePicker } from 'antd';
import { StyledDescription } from '@components/lib/Styled';
import { Title } from '../../Stores/Posting/Forms/StorePostingFields';
import { CASH_BOX_INITIAL_PARAM } from '@services/api/cashBox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';

import {
  CashBoxType,
  CashBoxFormDTO,
  DepartmentModel,
  IntegrationCategoryType,
  CheckboxAttributesCashBox,
  DEFAULT_NOTIFICATION_RECIPIENTS,
  IntegrationCheckboxAttributesDTO,
} from '@structure';

import {
  useStoredCompanies,
  useStateEmployeeList,
  useStoredCashBoxList,
  useStoredDepartmentList,
  useStateIntegrationListByCategory,
} from '@hooks';

import {
  Button,
  FormSwitch,
  SearchSelect,
  Segmented,
} from '@components/lib/DataDisplay';
import { CashboxAutoTransferItemDTO } from 'structure/dto/CashboxAutoTransferItemDTO';

export interface ICashBoxFormProps
  extends Omit<
    IDefaultFormProps<CashBoxFormDTO, CashBoxFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  cashBox?: CashBoxFormDTO;
}

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 20px 20px 0 20px;

  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid #d9d9d9;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

export const CASH_BOX_PAYMENT_METHOD = [
  {
    uuid: CashBoxType.Cash,
    title: 'tCash',
  },
  {
    uuid: CashBoxType.Bank,
    title: 'Cashless',
  },
];

export function CashBoxForm({
  loading,
  editMode,
  cashBox = CASH_BOX_INITIAL_PARAM,
  onCancel,
  ...rest
}: ICashBoxFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const theme: any = useTheme();
  const { defaultCompany, defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();
  const [resetCashBoxState] = useState(false);

  const isLocational = useWatch('is_locational', instance);
  const isEmailNotificationSentOnCloseCashbox = useWatch(
    'is_email_notification_enabled',
    instance,
  );
  const isTransferMoneyEnabledOnCloseCashbox = useWatch(
    'is_transfer_money_enabled',
    instance,
  );
  const isAutomatedOperation = useWatch(
    'can_be_closed_automatically',
    instance,
  );
  const notificationRecipientList = useWatch(
    'notification_recipient_list',
    instance,
  );

  const transferCashboxList = useWatch(
    'automation_money_transfer_items',
    instance,
  );

  const [fiscalCashboxes, setFiscalCashboxes] = useState<
    CheckboxAttributesCashBox[] | null
  >(null);

  const additionalValues = useMemo(
    () => ({
      uuid: cashBox?.uuid,
    }),
    [cashBox?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    departmentList,
    keywords: departmentListKeywords,
    loading: departmentListLoading,
    handleSearchDepartments,
  } = useStoredDepartmentList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: editMode,
  });

  const { associations, loading: associationsLoading } =
    useStateIntegrationListByCategory<IntegrationCheckboxAttributesDTO>({
      companyUuid: defaultCompanyUuid,
      categoryType: IntegrationCategoryType.PRRO,
      limit: 100,
    });

  const isShowFiscalCashboxesWarning = !associations?.length;

  const defaultFiscalCashboxes: CheckboxAttributesCashBox[] = useMemo(() => {
    return associations
      ? associations?.flatMap(({ attributes }) => attributes?.cashboxes || [])
      : [];
  }, [associations]);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Cash box'),
        `${
          editMode
            ? t('An error occurred during edit cash box')
            : t('An error occurred during create cash box')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  const handleSearchFiscalCashBoxes = useCallback(
    (keywords: string) => {
      const fiscalCashboxes = (defaultFiscalCashboxes || [])?.filter(
        ({ cashbox_name }: CheckboxAttributesCashBox) =>
          cashbox_name?.toLowerCase()?.includes(keywords),
      );

      setFiscalCashboxes(fiscalCashboxes);
    },
    [defaultFiscalCashboxes],
  );

  const handleChangeCashBox = useCallback((cashbox_uuid: string) => {
    // instance.setFieldValue(
    //     'automation_money_transfer_items',
    //     initValue,
    // );
  }, []);

  useEffect(() => {
    if (fiscalCashboxes === null && defaultFiscalCashboxes?.length) {
      setFiscalCashboxes(defaultFiscalCashboxes);
    }
  }, [defaultFiscalCashboxes, fiscalCashboxes]);

  const initCashboxCloseAt = cashBox?.cashbox_date_to_close_at?.length
    ? dayjs(cashBox.cashbox_date_to_close_at)
    : dayjs('20:00', TIME_FORMAT);

  const filteredCashboxes: any = isListToArray(
    cashBoxList?.filter(({ uuid, box_can_in_money_to }) => {
      return (
        box_can_in_money_to &&
        uuid !== cashBox?.uuid &&
        !transferCashboxList?.some(
          (item: CashboxAutoTransferItemDTO) =>
            item.transfer_cashbox_uuid === uuid,
        )
      );
    }) as any,
  );

  console.log(' --->', transferCashboxList, filteredCashboxes);

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...cashBox,
        cashbox_close_at: initCashboxCloseAt,
        is_email_notification_enabled:
          !!cashBox?.notification_recipient_list?.length,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit, ...rest }) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label={t('Cash box type')}
              name="type"
              rules={[
                {
                  required: true,
                  message: t('Cash box type must be specified'),
                },
              ]}
              tooltip={t('Cash box type')}>
              <Segmented
                options={CASH_BOX_PAYMENT_METHOD}
                disabled={loadingSubmit}
              />
            </Form.Item>
          </Col>

          <FormSwitch
            span={8}
            disabled={loadingSubmit}
            loading={loadingSubmit}
            name="is_virtual"
            label=""
            title={t('Virtual cash box')}
            tooltipTitle={t('Virtual cash box')}
            {...rest}
          />

          <FormSwitch
            span={8}
            disabled={loadingSubmit}
            loading={loadingSubmit}
            name="is_locational"
            label=""
            title={t('Location cash box')}
            tooltipTitle={t('Location cash box')}
            onChange={async (is_default_for_company) => {
              if (
                is_default_for_company &&
                (!List.isList(departmentList) || departmentListKeywords?.length)
              ) {
                await handleSearchDepartments({ keywords: '' });
              } else {
                instance.setFieldValue('location_uuid', '');
              }
            }}
            {...rest}
          />

          {isLocational ? (
            <Col span={8}>
              <Form.Item
                name="location_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Location must be specified'),
                  },
                ]}>
                <SearchSelect
                  selectFirst={false}
                  selectIfOnFirst={false}
                  allowClear
                  onSearch={(keywords) => handleSearchDepartments({ keywords })}
                  getOptionValueTitle={(department: DepartmentModel) => (
                    <DepartmentDetailsView
                      department={
                        {
                          dep_name: department?.dep_name,
                          uuid: department?.uuid,
                          dep_colour: department?.dep_colour,
                          dep_icon: department?.dep_icon,
                        } as DepartmentModel
                      }
                    />
                  )}
                  name="location_uuid"
                  placeholder={t('Select a location')}
                  disable={loadingSubmit || departmentListLoading}
                  data={isListToArray(departmentList as any) || []}
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the title')}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('Cashier')}
              name="cashier_uuid"
              rules={[
                {
                  required: true,
                  message: t('Cashier must be specified'),
                },
              ]}
              tooltip={t('Responsible person')}>
              <SearchSelect
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    limit: 100,
                    showLoading: false,
                  })
                }
                getOptionValueTitle="fullName"
                name="cashier_uuid"
                placeholder={t('Select a cashier')}
                disable={loadingSubmit || employeesLoading}
                data={isListToArray(employees as any)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('Fiscal cash box')}
              name="fiscal_cashbox_id"
              tooltip={t('Fiscal cash box')}
              extra={
                <StyledDescription
                  style={{ whiteSpace: 'unset' }}
                  $color={theme.colors.warning}>
                  {Array.isArray(associations) && isShowFiscalCashboxesWarning
                    ? `${t(
                        'To use this field, you need to enable the integration',
                      )}`
                    : ''}
                </StyledDescription>
              }>
              <SearchSelect
                selectFirst={false}
                selectIfOnFirst={false}
                allowClear
                onSearch={handleSearchFiscalCashBoxes}
                getOptionValueTitle={(cashBox: CheckboxAttributesCashBox) =>
                  cashBox?.cashbox_name
                }
                getOptionValueProps="cashbox_id"
                name="fiscal_cashbox_id"
                placeholder={t('Select a fiscal cash box')}
                disable={
                  loadingSubmit ||
                  associationsLoading ||
                  (Array.isArray(associations) && isShowFiscalCashboxesWarning)
                }
                data={fiscalCashboxes || []}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('note')} name="comment" tooltip={t('note')}>
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 5 }}
                disabled={loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Permitted operations')}
              tooltip={t(
                'The list of operations that are allowed to do with current cashbox',
              )}>
              <Row>
                <FormSwitch
                  span={12}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  label=""
                  tooltipTitle=""
                  name="can_move_money_to"
                  title={t('Transfer funds to the cash box')}
                  {...rest}
                />
                <FormSwitch
                  span={12}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  label=""
                  tooltipTitle=""
                  name="can_move_money_from"
                  title={t('Move funds from the cash box')}
                  {...rest}
                />

                <FormSwitch
                  span={12}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  name="can_in_money_to"
                  label=""
                  tooltipTitle=""
                  title={t('Receipt at the cash box')}
                  {...rest}
                />

                <FormSwitch
                  span={12}
                  disabled={loadingSubmit}
                  name="can_out_money_from"
                  loading={loadingSubmit}
                  label=""
                  tooltipTitle=""
                  title={t('Expenses from the cash box')}
                  {...rest}
                />
              </Row>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Operations with cashbox')}
              tooltip={t(
                'The automatic configuration for the current cashbox',
              )}>
              <Row>
                <Col span={24} style={{ paddingBottom: '10px' }}>
                  <Alert
                    showIcon
                    type="info"
                    message={t('Cashbox operations')}
                    description={t(
                      'Once the cashbox is closed it is not allowed to manage any operations with this cashbox',
                    )}
                  />
                </Col>
                <FormSwitch
                  span={12}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  name="can_be_closed_automatically"
                  label=""
                  title={t('Close automatically at')}
                  tooltipTitle={t(
                    'Cash box closes automatically by the system at specified time.',
                  )}
                  {...rest}
                />

                <Col span={12}>
                  <Form.Item
                    name="cashbox_close_at"
                    tooltip={t('Time when the cashbox is going to be closed')}>
                    <TimePicker
                      placeholder={t('Select time')}
                      format={TIME_FORMAT}
                      minuteStep={15}
                      disabled={!isAutomatedOperation}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Cashbox close notification')}
              tooltip={t(
                'Notifications automatically send to the admin accounts for the selected company as well as configured notifications that are sending using email',
              )}>
              <Row>
                <Col span={24} style={{ paddingBottom: '10px' }}>
                  <Alert
                    showIcon
                    type="info"
                    message={t('The notifications when cashbox is closed')}
                    description={t(
                      'It is possible to configure the email notifications for the close cashbox event just by specify the list of recipients who will receive the email notifications about cashbox status',
                    )}
                  />
                </Col>
                <FormSwitch
                  span={24}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  name="is_email_notification_enabled"
                  label=""
                  onChange={(is_email_notification_enabled) => {
                    let initValue: any[] =
                      !!notificationRecipientList?.length &&
                      notificationRecipientList[0].email
                        ? notificationRecipientList
                        : [];

                    if (
                      is_email_notification_enabled &&
                      !notificationRecipientList?.length
                    ) {
                      initValue = DEFAULT_NOTIFICATION_RECIPIENTS;
                    }

                    instance.setFieldValue(
                      'notification_recipient_list',
                      initValue,
                    );
                  }}
                  title={t('Send email notification on cashbox close')}
                  tooltipTitle={t(
                    'Notification will be sent to specified recipient once the cashbox is closed.',
                  )}
                  {...rest}
                />
              </Row>
            </Form.Item>
          </Col>

          <StyledFieldsContainer>
            <Title>{`${t('Recipients')}:`}</Title>
            <Col span={24}>
              <Form.List name="notification_recipient_list">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => {
                      return (
                        <Row gutter={12} align="top" key={field?.key}>
                          <Col
                            span={
                              notificationRecipientList?.length > 1 ? 22 : 24
                            }>
                            <Form.Item
                              {...field}
                              required
                              label={t('Email')}
                              name={[field.name, 'email']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Email must be specified.'),
                                },
                              ]}>
                              <Input
                                disabled={
                                  loading ||
                                  loadingSubmit ||
                                  !isEmailNotificationSentOnCloseCashbox
                                }
                                placeholder={t('Enter an email')}
                              />
                            </Form.Item>
                          </Col>
                          {notificationRecipientList?.length > 1 ? (
                            <StyledDeleteCol span={2}>
                              <StyledDeleteOutlined
                                onClick={() => remove(field.name)}
                                disabled={
                                  !isEmailNotificationSentOnCloseCashbox
                                }
                              />
                            </StyledDeleteCol>
                          ) : null}
                        </Row>
                      );
                    })}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add({ email: undefined })}
                        block
                        disabled={!isEmailNotificationSentOnCloseCashbox}
                        icon={<PlusOutlined />}>
                        {t('Add another recipient')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </StyledFieldsContainer>

          {/*<Col span={24}>*/}
          {/*  <Form.Item*/}
          {/*    label={t('Automatization')}*/}
          {/*    tooltip={t(*/}
          {/*      'Automatization is aim to solve the manual operations which are applying to the cashbox in daily basis',*/}
          {/*    )}>*/}
          {/*    <Col span={24} style={{paddingBottom: '10px'}}>*/}
          {/*      <Alert*/}
          {/*        showIcon*/}
          {/*        type="info"*/}
          {/*        message={t('Move money from cashbox on its close operation')}*/}
          {/*        description={t(*/}
          {/*          'Possibility to configure the movement operations to other accounts on cashbox close',*/}
          {/*        )}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*    <FormSwitch*/}
          {/*      span={24}*/}
          {/*      disabled={loadingSubmit}*/}
          {/*      loading={loadingSubmit}*/}
          {/*      name="is_transfer_money_enabled"*/}
          {/*      label=""*/}
          {/*      onChange={(is_transfer_money_enabled) => {*/}
          {/*        let initValue: any[] =*/}
          {/*          !!transferCashboxList?.length &&*/}
          {/*          transferCashboxList[0].cashbox_uuid*/}
          {/*            ? transferCashboxList*/}
          {/*            : [];*/}

          {/*        if (*/}
          {/*          is_transfer_money_enabled &&*/}
          {/*          !transferCashboxList?.length*/}
          {/*        ) {*/}
          {/*          initValue = DEFAULT_CASHBOX_MONEY_TRANSFERS;*/}
          {/*        }*/}

          {/*        instance.setFieldValue(*/}
          {/*          'automation_money_transfer_items',*/}
          {/*          initValue,*/}
          {/*        );*/}
          {/*      }}*/}
          {/*      title={t('Transfer money on cashbox close')}*/}
          {/*      tooltipTitle={t(*/}
          {/*        'Configure the money transfer to other cashboxes on close cashbox event',*/}
          {/*      )}*/}
          {/*      {...rest}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}

          {/*<StyledFieldsContainer>*/}
          {/*  <Title>{`${t('Destination cashboxes')}:`}</Title>*/}
          {/*  <Col span={24}>*/}
          {/*    <Form.List name="automation_money_transfer_items">*/}
          {/*      {(fields, {add, remove}) => (*/}
          {/*        <>*/}
          {/*          {fields.map((field) => {*/}
          {/*            return (*/}
          {/*              <Row gutter={24} align="top" key={field?.key}>*/}
          {/*                <Col span={transferCashboxList?.length > 1 ? 13 : 15}>*/}
          {/*                  <Form.Item*/}
          {/*                    label={t('Cashbox')}*/}
          {/*                    name={[field.name, 'transfer_cashbox_uuid']}*/}
          {/*                    rules={[*/}
          {/*                      {*/}
          {/*                        required: true,*/}
          {/*                        message: t('Cash boxes must be specified'),*/}
          {/*                      },*/}
          {/*                    ]}*/}
          {/*                    tooltip={t(*/}
          {/*                      'Cashbox for the specified payment method',*/}
          {/*                    )}>*/}
          {/*                    <Select*/}
          {/*                      showSearch={false}*/}
          {/*                      placeholder={t('Select a cash box')}*/}
          {/*                      options={filteredCashboxes?.map(*/}
          {/*                        (item: any) => ({*/}
          {/*                          value: item.uuid,*/}
          {/*                          label: item.box_title,*/}
          {/*                        }),*/}
          {/*                      )}*/}
          {/*                      // resetState={resetCashBoxState}*/}
          {/*                      // selectFirst={false}*/}
          {/*                      // selectIfOnFirst={false}*/}
          {/*                      // name={`${field.name}_transfer_cashbox_uuid`}*/}
          {/*                      // onSearch={(keywords) =>*/}
          {/*                      //   handleSearchCashBoxes({*/}
          {/*                      //     keywords,*/}
          {/*                      //     limit: 100,*/}
          {/*                      //     showLoading: false,*/}
          {/*                      //   })*/}
          {/*                      // }*/}
          {/*                      // getOptionValueTitle={(*/}
          {/*                      //   cashBox: CashBoxModel,*/}
          {/*                      // ) => (*/}
          {/*                      //   <CashBoxTitleView*/}
          {/*                      //     cashBox={cashBox}*/}
          {/*                      //     disable={*/}
          {/*                      //       loadingSubmit || cashBoxListLoading*/}
          {/*                      //     }*/}
          {/*                      //   />*/}
          {/*                      // )}*/}
          {/*                      // placeholder={t('Select a cash box')}*/}
          {/*                      // disable={loadingSubmit || cashBoxListLoading}*/}
          {/*                      // data={filteredCashboxes}*/}
          {/*                      // onChange={() => {*/}
          {/*                      //   // const items = cashBox?.automation_money_transfer_items || [];*/}
          {/*                      //   //*/}
          {/*                      //   // instance.setFieldValue(*/}
          {/*                      //   //   'automation_money_transfer_items',*/}
          {/*                      //   //   [*/}
          {/*                      //   //       ...,*/}
          {/*                      //   //*/}
          {/*                      //   //   ],*/}
          {/*                      //   // );*/}
          {/*                      //   // console.log([field.name, 'transfer_cashbox_uuid']);*/}
          {/*                      // }}*/}
          {/*                    />*/}
          {/*                  </Form.Item>*/}
          {/*                </Col>*/}
          {/*                <Col span={9}>*/}
          {/*                  <Form.Item*/}
          {/*                    tooltip={t('Transfer value')}*/}
          {/*                    label={t('Transfer Value')}*/}
          {/*                    name={[field.name, 'transfer_value']}*/}
          {/*                    rules={*/}
          {/*                      // formData?.payment_doc_discount_type ===*/}
          {/*                      // ClientDiscountType.Fixed*/}
          {/*                      true*/}
          {/*                        ? [*/}
          {/*                            {*/}
          {/*                              transform: (value) => Number(value),*/}
          {/*                              type: 'number',*/}
          {/*                              required: false,*/}
          {/*                              max: Number(0) || Infinity,*/}
          {/*                              message: Number(0)*/}
          {/*                                ? t(*/}
          {/*                                    'The discount cannot be more than the price',*/}
          {/*                                  )*/}
          {/*                                : t('Discount must be specified.'),*/}
          {/*                            },*/}
          {/*                          ]*/}
          {/*                        : [*/}
          {/*                            {*/}
          {/*                              transform: (value) => Number(value),*/}
          {/*                              type: 'number',*/}
          {/*                              min: 0,*/}
          {/*                              max: 100,*/}
          {/*                              required: false,*/}
          {/*                              message: t(*/}
          {/*                                'Discount must be a number and bigger than -1 and less 100',*/}
          {/*                              ),*/}
          {/*                            },*/}
          {/*                          ]*/}
          {/*                    }*/}
          {/*                    extra={*/}
          {/*                      // discountRemaining > 0 &&*/}
          {/*                      // formData?.is_apply_payment_doc_discount &&*/}
          {/*                      // Number(minSum || 0) >= discountRemaining ? (*/}
          {/*                      //   <StyledPriceDiscountContainer>*/}
          {/*                      //     <StyledDescription*/}
          {/*                      //       bold*/}
          {/*                      //       style={{whiteSpace: 'unset'}}*/}
          {/*                      //       $color={theme.colors.error}>*/}
          {/*                      //       {`${t('The discount total')}: ${correctPrice(*/}
          {/*                      //         discountRemaining.toFixed(2),*/}
          {/*                      //       )}`}*/}
          {/*                      //     </StyledDescription>*/}
          {/*                      //   </StyledPriceDiscountContainer>*/}
          {/*                      // ) : null*/}
          {/*                      null*/}
          {/*                    }>*/}
          {/*                    <Input*/}
          {/*                      autoFocus*/}
          {/*                      onFocus={(e) => e?.target?.select()}*/}
          {/*                      addonAfter={*/}
          {/*                        <Form.Item*/}
          {/*                          noStyle*/}
          {/*                          name={[field.name, 'transfer_type']}>*/}
          {/*                          <Select*/}
          {/*                            showSearch={false}*/}
          {/*                            placeholder={t('Select a type')}>*/}
          {/*                            {STORE_PAYMENT_DISCOUNT_TYPE(*/}
          {/*                              defaultCompany?.currency_symbol!,*/}
          {/*                            )?.map((item: any) => (*/}
          {/*                              <Select.Option*/}
          {/*                                key={item.uuid}*/}
          {/*                                value={item.title}*/}
          {/*                                label={item.description}>*/}
          {/*                                {item.description}*/}
          {/*                              </Select.Option>*/}
          {/*                            ))}*/}
          {/*                          </Select>*/}
          {/*                        </Form.Item>*/}
          {/*                      }*/}
          {/*                      type="number"*/}
          {/*                      disabled={loadingSubmit}*/}
          {/*                      placeholder={t('Enter discount')}*/}
          {/*                      // onChange={handleChangeDiscount}*/}
          {/*                    />*/}
          {/*                  </Form.Item>*/}
          {/*                </Col>*/}
          {/*                {transferCashboxList?.length > 1 ? (*/}
          {/*                  <StyledDeleteCol span={2}>*/}
          {/*                    <StyledDeleteOutlined*/}
          {/*                      onClick={() => remove(field.name)}*/}
          {/*                      disabled={!isTransferMoneyEnabledOnCloseCashbox}*/}
          {/*                    />*/}
          {/*                  </StyledDeleteCol>*/}
          {/*                ) : null}*/}
          {/*              </Row>*/}
          {/*            );*/}
          {/*          })}*/}
          {/*          <Form.Item>*/}
          {/*            <Button*/}
          {/*              type="dashed"*/}
          {/*              onClick={() =>*/}
          {/*                add({*/}
          {/*                  transfer_value: 0,*/}
          {/*                  transfer_type: ClientDiscountType.Fixed,*/}
          {/*                  transfer_cashbox_uuid: !!cashBoxList?.size*/}
          {/*                    ? cashBoxList.get(0)*/}
          {/*                    : undefined,*/}
          {/*                })*/}
          {/*              }*/}
          {/*              block*/}
          {/*              disabled={*/}
          {/*                !isTransferMoneyEnabledOnCloseCashbox ||*/}
          {/*                filteredCashboxes?.length === 0*/}
          {/*              }*/}
          {/*              icon={<PlusOutlined />}>*/}
          {/*              {t('Add another cashbox')}*/}
          {/*            </Button>*/}
          {/*          </Form.Item>*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*    </Form.List>*/}
          {/*  </Col>*/}
          {/*</StyledFieldsContainer>*/}

          <Col span={24}>
            <Form.Item
              label={t('Additional settings')}
              tooltip={t('Additional settings')}>
              <Row>
                <FormSwitch
                  span={24}
                  disabled={loadingSubmit}
                  loading={loadingSubmit}
                  name="is_default_for_company"
                  label=""
                  title={t('Cash box by default')}
                  tooltipTitle={t('Cash box by default')}
                  {...rest}
                />
              </Row>
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
