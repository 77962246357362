import { List } from 'immutable';
import { Dispatch } from 'redux';
import { EMPLOYEE } from '../constants';
import { EmployeeModel, DayOffModel, ServiceModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetEmployeeParam {
  type: EMPLOYEE;
  employee: EmployeeModel | null;
}

export interface IUpdateEmployeeParam {
  type: EMPLOYEE;
  employee: EmployeeModel;
}

interface IUpdateEmployeeServiceListParam {
  type: EMPLOYEE;
  employeeServiceList: List<ServiceModel>;
}

export interface IUpdateEmployeeDayOffParam {
  type: EMPLOYEE;
  dayOff: DayOffModel;
}

export interface ICreateEmployeeDayOffParam {
  type: EMPLOYEE;
  dayOff: DayOffModel;
}

export interface IDeleteEmployeeDayOffParam {
  type: EMPLOYEE;
  dayOffUuid: string;
}

export interface IResetEmployeeParam {
  type: EMPLOYEE;
}

export interface IErrorEmployeeParam {
  type: EMPLOYEE;
  error: ApiError | undefined;
}

export function setEmployee(
  employee: EmployeeModel | null,
): (dispatch: Dispatch) => Promise<ISetEmployeeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.SET_EMPLOYEE,
      employee,
    });
  };
}

export function updateEmployee(
  employee: EmployeeModel,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.UPDATE_EMPLOYEE,
      employee,
    });
  };
}

export function updateEmployeeServiceList(
  employeeServiceList: List<ServiceModel>,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeServiceListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.UPDATE_EMPLOYEE_SERVICES,
      employeeServiceList,
    });
  };
}

export function updateEmployeeDayOff(
  dayOff: DayOffModel,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeDayOffParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.UPDATE_EMPLOYEE_DAY_OFF,
      dayOff,
    });
  };
}

export function createEmployeeDayOff(
  dayOff: DayOffModel,
): (dispatch: Dispatch) => Promise<ICreateEmployeeDayOffParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.CREATE_EMPLOYEE_DAY_OFF,
      dayOff,
    });
  };
}

export function deleteEmployeeDayOff(
  dayOffUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteEmployeeDayOffParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.DELETE_EMPLOYEE_DAY_OFF,
      dayOffUuid,
    });
  };
}

export function resetEmployee(): (
  dispatch: Dispatch,
) => Promise<IResetEmployeeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.RESET_EMPLOYEE,
    });
  };
}

export function errorEmployee(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorEmployeeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE.ERROR_EMPLOYEE,
      error,
    });
  };
}
