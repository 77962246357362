import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { STATUS_INITIAL_PARAM } from '@services/api/status';
import { StatusFormDTO, StatusModel } from '@structure';
import { useDropdownAlert } from '@contex';
import { StatusFields } from './StatusFields';
import {
  IUseStatusListProps,
  useStoredCompanies,
  useStoredStatusCategoryList,
  useStoredStatusList,
} from '@hooks';
import { List } from 'immutable';

export interface IStatusFormProps
  extends Omit<
    IDefaultFormProps<StatusFormDTO, StatusFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  status?: StatusFormDTO;
}

export function StatusForm({
  loading,
  editMode,
  status = STATUS_INITIAL_PARAM,
  onCancel,
  ...rest
}: IStatusFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();

  const [statusListFrom, setStatusListFrom] =
    useState<List<StatusModel> | null>(null);
  const [statusListTo, setStatusListTo] = useState<List<StatusModel> | null>(
    null,
  );
  const [loadingStatusListFrom, setLoadingStatusListFrom] = useState(false);
  const [loadingStatusListTo, setLoadingStatusListTo] = useState(false);

  const additionalValues = useMemo(
    () => ({
      uuid: status?.uuid,
    }),
    [status?.uuid],
  );

  const { categoryList, loading: categoryListLoading } =
    useStoredStatusCategoryList();

  const {
    loading: statusListLoading,
    statusList,
    total,
    limit,
    page,

    handleRefreshFormStatuses,
  } = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const handleRefreshStatusListFrom = useCallback(
    async (value: Partial<IUseStatusListProps> & { page: number }) => {
      setLoadingStatusListFrom(true);
      const statuses = await handleRefreshFormStatuses(value);
      if (statuses) {
        setStatusListFrom(statuses);
      }
      setLoadingStatusListFrom(false);

      return statuses;
    },
    [handleRefreshFormStatuses],
  );

  const handleRefreshStatusListTo = useCallback(
    async (value: Partial<IUseStatusListProps> & { page: number }) => {
      setLoadingStatusListTo(true);
      const statuses = await handleRefreshFormStatuses(value);
      if (statuses) {
        setStatusListTo(statuses);
      }
      setLoadingStatusListTo(false);
    },
    [handleRefreshFormStatuses],
  );

  useEffect(() => {
    if (!statusListFrom) {
      if (page !== 1) {
        (async () => {
          const statuses = await handleRefreshStatusListFrom({
            showLoading: true,
            page: 1,
          });

          if (statuses) {
            setStatusListFrom(statuses);
            setStatusListTo(statuses);
          }
        })();
      } else {
        setStatusListFrom(statusList);
        setStatusListTo(statusList);
      }
    }
  }, [
    handleRefreshStatusListFrom,
    handleRefreshStatusListTo,
    page,
    statusList,
    statusListFrom,
    statusListTo,
  ]);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Status'),
        `${
          editMode
            ? t('An error occurred during edit status')
            : t('An error occurred during create status')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...status,
      }}
      submitButtonText={editMode ? t('Save') : t('Create')}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <StatusFields
          statusListFrom={
            statusListFrom?.filter(({ uuid }) => uuid !== status?.uuid) as any
          }
          statusListTo={
            statusListTo?.filter(({ uuid }) => uuid !== status?.uuid) as any
          }
          statusListLoadingFrom={loadingStatusListFrom}
          statusListLoadingTo={loadingStatusListTo}
          handleRefreshStatusListFrom={handleRefreshStatusListFrom as any}
          handleRefreshStatusListTo={handleRefreshStatusListTo}
          total={total}
          limit={limit}
          editMode={!!editMode}
          loading={!!loading}
          categoryList={categoryList}
          categoryListLoading={categoryListLoading}
        />
      )}
    </DefaultForm>
  );
}
