import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { InvoiceFormDTO, InvoiceType } from '@structure';
import { IUseStateInvoiceReturnType } from '@hooks';

const InvoiceSideWindowLazy = React.lazy(
  () => import('../Show/InvoiceSideWindow'),
);

export interface IInvoiceCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: IUseStateInvoiceReturnType['handleCreateInvoice'];
  onCancel?: () => void;
  invoice: InvoiceFormDTO;
}

export function InvoiceCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  invoice,
  ...rest
}: IInvoiceCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={
          invoice?.inv_type === InvoiceType.IN
            ? t('Create invoice')
            : t('Create input invoice')
        }
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceSideWindowLazy
          invoice={invoice}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
