import { useAcl } from '@contex';
import { useTranslation } from 'react-i18next';
import { StorePreorderTabPanel } from '../Show';
import { HiOutlineDocument } from 'react-icons/hi2';
import styled, { useTheme } from 'styled-components';
import { ClientDetailsView } from '../../../Clients';
import { Col, Popconfirm, Row, MenuProps } from 'antd';
import { Routes, RoutesAcl } from '@services/types';
import { productStoreDocumentRoutes } from '../../../Products';
import { StyledTitle } from '@components/lib/Styled';
import { useLocation, useNavigate, useParams } from 'react-router';
import { OverviewLayout } from '@components/lib/Layout';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { DocumentControlPanel } from '@components/lib/General';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';

import {
  Button,
  HeaderLeft,
  DetailsView,
  CommentField,
  SuspenseEmpty,
  IDetailsViewItemProps,
} from '@components/lib/DataDisplay';

import {
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
  useStateStoreDocumentItemList,
} from '@hooks';

import {
  StoreDocumentMapper,
  StoreDocumentStatus,
  StoreDocumentType,
} from '@structure';

import {
  StoreDetailsView,
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
  StoreDocExtraServicesView,
} from '../../Show';

import '../../OverviewLayoutDetailsItem.less';

const StorePreorderSideWindowLazy = React.lazy(
  () => import('../Show/StorePreorderSideWindow'),
);

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

export function StorePreorderPage() {
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { documentId, productId } = useParams();
  const [deletedLoading, setDeletedLoading] = useState(false);

  const { defaultCompanyUuid } = useStoredCompanies();
  const { store: storeAccess } = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,

    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.PREORDER,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message:
      'An error occurred during invoice for order to the provider loading',
  });

  const { handleDeleteStoreDocuments: onDeleteStoreDocuments } =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.PREORDER,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    setDeletedLoading(true);
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
    setDeletedLoading(false);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeOrderProvider}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeOrderProvider}`,
            breadcrumbName: 'Invoices for order to the provider',
          },
          {
            path: `/${Routes.stores}/${Routes.storeOrderProvider}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show document',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Provider',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];

  const {
    handleCancel: storeEditHandleCancel,
    handleOnInit: storeDocumentEditOnInit,
    handleSuccess: storeDocumentEditSuccess,
    visible: storeDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateStoreDocument,
  });

  // @ts-ignore
  const documentOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'delete-product',
        label: (
          <Popconfirm
            title={t('Are you sure you want to delete the document?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onCancel={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            onConfirm={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();

              await handleDeleteStoreDocuments();
            }}>
            <StyledPopconfirmButton
              type="text"
              onClick={async (e: any) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              }}>
              {deletedLoading ? (
                <StyledLoadingOutlined />
              ) : (
                <StyledDeleteOutlined />
              )}
              <StyledTitle $color={theme.colors.error}>
                {t('Delete draft')}
              </StyledTitle>
            </StyledPopconfirmButton>
          </Popconfirm>
        ),
      },
    ],
    [t, deletedLoading, theme.colors.error, handleDeleteStoreDocuments],
  );

  const printOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'Print document',
        icon: <HiOutlineDocument size={15} color={theme.colors.black} />,
        label: <StyledTitle>{t('Print document')}</StyledTitle>,
        onClick: handlePrintStoreDocument,
      },
    ],
    [handlePrintStoreDocument, t, theme.colors.black],
  );

  return (
    <>
      <StorePreorderTabPanel document={document}>
        {({ tabs }) => (
          <OverviewLayout
            className="overview-details-item-store"
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Invoice for order to the provider')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={storeDocumentEditOnInit}
                isDraft={
                  document?.doc_status_text === StoreDocumentStatus.DRAFT
                }
              />
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader document={document} />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    withEditablePrice
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                // handlePrintStoreDocument={handlePrintStoreDocument}
                handlePaymentStoreDocument={() => {}}
                // handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit invoice for order to the provider')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StorePreorderTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <StorePreorderSideWindowLazy
          editMode
          document={
            StoreDocumentMapper.toStoreDocumentFormDTO(document!, {
              editMode: true,
            }) || null
          }
          onSuccess={storeDocumentEditSuccess}
          visible={storeDocumentVisible}
          onCancel={storeEditHandleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
