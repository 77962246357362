import * as React from 'react';
import { getGoogleCalendarList } from '@services/api/googleCalendar';
import { ListOptions, GoogleCalendar } from '@services/models';
import {
  ListOptionsProps,
  isListToArray,
  IGoogleCalendarListProps,
} from '@services/helpers';

import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseGoogleCalendarListProps extends Partial<ListOptionsProps> {
  loadOnInit?: boolean;
  showLoading?: boolean;
  companyUuid: string;
}

export interface IUseGoogleCalendarListReturnType {
  error: Error | null;
  loading: boolean;
  list: GoogleCalendar[];
  refresh: (
    value: Partial<IUseGoogleCalendarListProps>,
  ) => Promise<GoogleCalendar[] | void>;
  total: number;
  offset: number;
  limit: number;
}

export default function useGoogleCalendarList(
  {
    offset = 0,
    limit = 20,
    loadOnInit = true,
    companyUuid,
  }: IUseGoogleCalendarListProps = {} as IUseGoogleCalendarListProps,
): IUseGoogleCalendarListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = React.useRef<boolean>();

  const {
    offset: listPage,
    limit: listPageSize,
    companyUuid: listCompanyUuid,
    handleUpdate,
    ...rest
  } = useStateReducer<
    Omit<IUseGoogleCalendarListReturnType, 'refresh'> & {
      companyUuid: string;
    }
  >({
    error: null,
    loading: true,
    list: null as any,
    offset,
    limit,
    total: 0,
    companyUuid,
  });

  const refresh = React.useCallback(
    async (
      {
        offset = listPage,
        limit = listPageSize,
        showLoading = true,
        companyUuid = listCompanyUuid,
        ...rest
      }: Partial<IUseGoogleCalendarListProps> = {} as IUseGoogleCalendarListProps,
    ): Promise<GoogleCalendar[] | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          companyUuid,
        });

        const { tokens, total } =
          await cancellablePromise<IGoogleCalendarListProps>(
            getGoogleCalendarList(
              companyUuid,
              new ListOptions({
                offset,
                limit,
                ...rest,
              }),
            ),
          );

        const list = isListToArray(tokens);

        if (!didCancel.current) {
          handleUpdate({
            list,
            total,
            loading: false,
          });

          return list;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listCompanyUuid],
  );

  React.useEffect(() => {
    didCancel.current = false;

    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    if (companyUuid) {
      handleUpdate({ companyUuid });
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    ...rest,
  };
}
