import React, { useState } from 'react';
import { Form, Row, Col } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { Input, Password } from '@components/lib/DataEntry';
import { ResetPasswordDTO } from '@structure';
import { AuthButton } from '../../Buttons';

export default withTranslation()(ResetPasswordForm);

export interface IResetPasswordFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<ResetPasswordDTO, ResetPasswordDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  onCancel?: () => void;
  onSuccess: (value: ResetPasswordDTO) => Promise<void>;
  onError?: () => Promise<void>;
  resetPassword: ResetPasswordDTO;
}

const StyledUserOutlined = styled(UserOutlined)`
  color: ${({ theme }) => theme.colors.disable};
`;

function ResetPasswordForm({
  resetPassword,
  t,
  ...rest
}: IResetPasswordFormProps): JSX.Element {
  const [isRestore, setIsRestore] = useState<boolean>(false);

  return (
    <DefaultForm
      initialValues={{ ...resetPassword }}
      showFooter={false}
      {...rest}>
      {({ loadingSubmit, submit, validateFields }) => (
        <Row gutter={20}>
          <Form.Item
            label={t('New Password')}
            name="password"
            rules={[
              {
                required: true,
                message: t('Please input your password!'),
              },
            ]}>
            <Password
              data-testid="reset-password-form-input-password"
              disabled={loadingSubmit}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t('Type your new password')}
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item
            label={t('Confirm Password')}
            name="confirm_password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('Please confirm your password!'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t('The two passwords that you entered do not match!'),
                    ),
                  );
                },
              }),
            ]}>
            <Input
              data-testid="reset-password-form-input-confirm"
              disabled={loadingSubmit}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t('Confirm your new password')}
            />
          </Form.Item>

          <Col span={24}>
            <AuthButton
              data-testid="reset-password-form-button-submit"
              loading={loadingSubmit}
              type="primary"
              onClick={() => {
                validateFields().then(({ identifier }) => {
                  if (identifier) {
                    setIsRestore(true);
                  }
                });
                submit();
              }}
              block>
              {t('Submit')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
