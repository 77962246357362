import React from 'react';
import { Col, Input, Row, Form } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { ProfileModel } from '@structure';

export default withTranslation()(ProfilePasswordForm);

export interface IProfilePasswordFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<any, any>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  loading?: boolean;
  profile: ProfileModel;
}

/**
 * @desc Зміна паролю
 * */
function ProfilePasswordForm({
  t,
  profile,
  onSuccess,
  editMode,
  loading,
  ...rest
}: IProfilePasswordFormProps): JSX.Element {
  return (
    <DefaultForm
      editMode={editMode}
      notifyFormName={t('Profile')}
      initialValues={{
        ...profile,
      }}
      {...rest}>
      {({ loadingSubmit }) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Current Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Please input your Password!'),
                },
              ]}>
              <Input.Password
                data-testid="profile-form-input-password"
                disabled={loading || loadingSubmit}
                type="password"
                placeholder={t('Current Password')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('New Password')}
              name="new_password"
              rules={[
                {
                  required: true,
                  message: t('Please enter new password!'),
                },
              ]}>
              <Input.Password
                data-testid="profile-form-input-new-password"
                disabled={loading || loadingSubmit}
                type="password"
                placeholder={t('New Password')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Confirm New Password')}
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: t('Please enter new password again!'),
                },
              ]}>
              <Input.Password
                data-testid="profile-form-input-confirm-password"
                disabled={loading || loadingSubmit}
                type="password"
                placeholder={t('Confirm New Password')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
