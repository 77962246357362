import React, { useCallback, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ConfirmActionButton,
  IConfirmActionButtonProps,
} from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { notify } from '@components/lib/Feedback';
import { isFunction, eq } from '@services/helpers';
import { TOGGLE_USER_STATUS } from '@services/api/companyUser';

export default withTranslation()(DisableOrActiveUserButton);

export interface IDisableOrActiveUserButtonProps
  extends WithTranslation,
    Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess?: () => Promise<void>;
  onError?: () => void;
  status: string;
}

function DisableOrActiveUserButton({
  children,
  onSuccess,
  onError,
  t,
  status,
  ...rest
}: IDisableOrActiveUserButtonProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const handleUserDisableOrActive = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      if (isFunction(onSuccess)) {
        await onSuccess();
      }
      notify.notifyMessage(t(`user ${status}d`));
      setLoading(false);
    } catch (err: any) {
      notify.notifyErrorMessage(
        `${t(`user ${status}d`)}: ${err?.response?.message || err?.message}`,
      );
      setLoading(false);
      if (isFunction(onError)) {
        await onError();
      }
    }
  }, [onError, onSuccess, status, t]);

  const isDisabled = eq(status, TOGGLE_USER_STATUS.DISABLE);

  return (
    <ConfirmActionButton
      tooltip={t(`${status} user`)}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to ${status} user`)}
        </TextFormat>
      }
      danger={isDisabled}
      type="primary"
      onConfirm={handleUserDisableOrActive}
      loading={loading}
      cancelButtonText={t('Cancel')}
      data-testid="users__button--disable-enable"
      {...rest}>
      {children || t('Disable')}
    </ConfirmActionButton>
  );
}
