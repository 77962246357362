import { ScheduleCalendarModel } from '../internal';

export class AgendaItemDTO {
  public uuid: string;
  public status_color: string;
  public schedule_number: string;
  public scheduled_date: string;
  public client_full_name: string;
  public employee_full_name: string;
  public client_phone: string;

  constructor(props: ScheduleCalendarModel) {
    this.uuid = props?.uuid || '';
    this.status_color = props?.status_color || '';
    this.schedule_number = props?.schedule_number || '';
    this.scheduled_date = props?.scheduled_date || '';
    this.client_full_name = props?.clientModel?.fullNameClient || '';
    this.client_phone = props?.clientModel?.phone || '';
    this.employee_full_name = props?.employeeModel?.fullName || '';
  }
}
