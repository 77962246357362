import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps, Space } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { Button } from '../DataDisplay';

import {
  MoreOutlined,
  LoadingOutlined,
  PrinterOutlined,
  DownOutlined,
} from '@ant-design/icons';

export interface IStoreRealizationOptionsViewProps {
  items: MenuProps['items'];
  loading?: boolean;
  disabled?: boolean;
  isDraft?: boolean;
  printItems?: MenuProps['items'];
  onEditInit?: (value: any) => any;
}

const StyledDocumentOptionsContainer = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  justify-content: center;

  margin-right: 15px;
  width: 50px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;
const StyledPrintDropdown = styled(Dropdown)<{ disabled?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    color: ${({ theme }) => theme.colors.default.hover.color};
    border-color: ${({ theme }) => theme.colors.default.hover.borderColor};
    opacity: 0.8;
  }
`;

export default function DocumentControlPanel(
  props: IStoreRealizationOptionsViewProps,
): JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { onEditInit, printItems, isDraft, loading, disabled, items } = props;

  return (
    <StyledDocumentOptionsContainer>
      {printItems && printItems?.length > 0 && (
        <StyledPrintDropdown menu={{ items: printItems }} trigger={['click']}>
          <Button type="default" size={'middle'}>
            <Space>
              {loading ? <LoadingOutlined /> : <PrinterOutlined />}
              {t('Print')}
              <DownOutlined style={{ fontSize: '12px' }} />
            </Space>
          </Button>
        </StyledPrintDropdown>
      )}
      {onEditInit && (
        <Button type="default" disabled={!isDraft} onClick={onEditInit}>
          {t('Edit')}
        </Button>
      )}
      {items && items.length > 0 && (
        <StyledDropdown
          trigger={['click']}
          disabled={!!loading || disabled}
          menu={{ items }}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            <MoreOutlined size={20} color={theme.colors.primary} />
          )}
        </StyledDropdown>
      )}
    </StyledDocumentOptionsContainer>
  );
}
