import React, { useCallback, useState } from 'react';
import { ScheduleCalendarModel } from '@structure';
import CalendarEventDisplay from '@components/Calendar/Show/CalendarEventDisplay';
import { buildDisplayLengthOfTime } from '@services/helpers';
import { useCalendar } from '@contex';

export interface IClientCalendarPopoverProps {
  event: ScheduleCalendarModel;
}

export default function ClientCalendarPopover({
  event,
}: IClientCalendarPopoverProps): JSX.Element {
  const { scheduleStartProp, mode, SchedulePopover } = useCalendar();
  const [isOpenCollapse, setIsOpenCollapse] = useState<number | undefined>(
    undefined,
  );

  const handleSetOpenCollapse = useCallback((isOpen: number | undefined) => {
    setIsOpenCollapse(isOpen);
  }, []);

  return (
    <CalendarEventDisplay
      isPopover
      isOpenCollapse={isOpenCollapse}
      handleSetOpenCollapse={handleSetOpenCollapse}
      content={React.createElement(SchedulePopover, { event } as any)}
      direction="horizontal"
      event={event}
      lengthOfTime={buildDisplayLengthOfTime(
        (event as any)[scheduleStartProp as string],
        event?.schedule_amount,
      )}
      mode={mode}
    />
  );
}
