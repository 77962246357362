import React from 'react';
import { DetailsView } from '@components/lib/DataDisplay';
import { StyledTitle, StyledDescription } from '@components/lib/Styled';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { IPaymentScheduleListStats } from '@services/api/orders';
import { isThereContent, correctPrice } from '@services/helpers';
import { ScheduleCalendarModel } from '@structure';

export interface IPaymentScheduleListStatsViewProps {
  stats: IPaymentScheduleListStats | null;
  schedule: ScheduleCalendarModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  padding-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(StyledTitle)<{ $width?: number }>`
  min-width: 100px;
`;

const Description = styled(StyledDescription)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  padding-bottom: 0px;
`;

export function PaymentScheduleListStatsView({
  stats,
  schedule,
}: IPaymentScheduleListStatsViewProps): JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();
  const theme: any = useTheme();

  const amount =
    Number(stats?.totalInSum || 0) - Number(stats?.totalOutSum || 0);

  const paid =
    Number(schedule?.sum_total || 0) - Number(schedule?.sum_paid || 0);

  return (
    <>
      {isThereContent(stats) ? (
        <StyledDetailsView titles={[]}>
          <StyledContainer>
            <StyledTitleContainer>
              <Description fontSize={15} fontWeight={500}>{`${t(
                'Payments for the amount',
              )} ${defaultCompany?.currency_symbol}:`}</Description>
              <Title fontSize={15} fontWeight={500}>
                {correctPrice(amount)}
              </Title>
            </StyledTitleContainer>

            <StyledTitleContainer>
              <Description
                $color={theme.colors.primary}
                fontSize={15}
                bold>{`${t('Balance due')} ${
                defaultCompany?.currency_symbol
              }:`}</Description>
              <Title fontSize={15} bold>
                {correctPrice(paid)}
              </Title>
            </StyledTitleContainer>
          </StyledContainer>
        </StyledDetailsView>
      ) : null}
    </>
  );
}
