import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';
import styled from 'styled-components';

const DatePicker = generatePicker<Moment>(momentGenerateConfig);

const StyledDatePicker = styled(DatePicker)`
  width: 100%;

  &&& * > input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  & {
    &&& input::placeholder {
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

export default StyledDatePicker;
