import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Empty, Select } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IUseSearchInputFocusReturnType } from '@hooks';
import { CashBoxModel } from '@structure';
import { List } from 'immutable';
import { listToArray, isFunction } from '@services/helpers';
import { useStoreSale } from '../Managers';
import { CashBoxTitleView } from '../../../Payments/Show/CashBoxTitleView';
import { LoadingMore } from '@components/lib/DataDisplay';
import { LoadingOutlined } from '@ant-design/icons';
import { StyledDescription } from '@components/lib/Styled';

export interface StoreSaleCashBoxSelectProps
  extends Partial<Pick<IUseSearchInputFocusReturnType, 'daleyFocus'>> {}

const StyledSelect = styled(Select)`
  width: auto;

  &&& > .ant-select-selector {
    border: none;
    cursor: pointer;

    & * {
      color: ${({ theme }) => theme.colors.disable};
    }
  }

  && * > .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.disable} !important;
    cursor: pointer;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
`;

const StyledDropdownRenderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.disable};
`;

export interface CashBoxSelectList {
  value: string;
  label: React.ReactNode;
}

export function StoreSaleCashBoxSelect({
  daleyFocus,
}: StoreSaleCashBoxSelectProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [cashBoxSelectList, setCashBoxSelectList] = useState<
    CashBoxSelectList[] | null
  >(null);

  const onceLoading = useRef(true);

  const {
    cashBoxList,
    cashBoxListLoading,
    cashBoxListIsLoadingMore,
    handleLoadMoreCashBoxes,
    handleSearchCashBoxes,
    handleUpdateSaleCashBox,
    cashBoxValue,
  } = useStoreSale();

  const toLabelInValue = useCallback(
    (cashBox: CashBoxModel) => ({
      label: (
        <CashBoxTitleView cashBox={cashBox} disable={cashBoxListLoading} />
      ),
      value: cashBox?.uuid,
    }),
    [cashBoxListLoading],
  );

  const cashBoxListToLabelInValueSelect = useCallback(
    (cashBoxes: List<CashBoxModel> | null) => {
      if (List.isList(cashBoxes)) {
        const cashBoxSelectList = cashBoxes?.map(toLabelInValue);

        setCashBoxSelectList(listToArray(cashBoxSelectList));
      }
    },
    [toLabelInValue],
  );

  const handleChangeCashBox = useCallback(
    async (value: any) => {
      handleUpdateSaleCashBox(value);

      if (isFunction(daleyFocus)) {
        daleyFocus();
      }
    },
    [daleyFocus, handleUpdateSaleCashBox],
  );

  useEffect(() => {
    cashBoxListToLabelInValueSelect(cashBoxList);
  }, [cashBoxList, cashBoxListToLabelInValueSelect]);

  useEffect(() => {
    if (!cashBoxListLoading && onceLoading.current) {
      onceLoading.current = false;
    }
  }, [cashBoxListLoading]);

  return (
    <>
      {(cashBoxListLoading && onceLoading.current) ||
      !List.isList(cashBoxList) ? (
        <StyledEmptyContainer>
          <StyledLoadingOutlined />
        </StyledEmptyContainer>
      ) : !cashBoxList?.size ? (
        <StyledEmptyContainer>
          <StyledDescription
            $color={theme.colors.error}
            style={{ whiteSpace: 'unset' }}>
            {t(
              'The current user does not have cash registers authorized to operate',
            )}
          </StyledDescription>
        </StyledEmptyContainer>
      ) : (
        <StyledSelect
          defaultActiveFirstOption
          loading={cashBoxListLoading}
          disabled={cashBoxListLoading}
          popupMatchSelectWidth={false}
          showSearch
          filterOption={false}
          onSearch={async (keywords) =>
            await handleSearchCashBoxes({
              limit: 100,
              keywords,
              showLoading: false,
            })
          }
          placeholder={!cashBoxValue ? t('Select a cash box') : ''}
          options={cashBoxSelectList || []}
          notFoundContent={
            <Empty description={t('There are no cash box found')} />
          }
          dropdownRender={(originNode) => {
            return (
              <StyledDropdownRenderContainer>
                {originNode}
                {cashBoxSelectList?.length ? (
                  <LoadingMore
                    loading={cashBoxListIsLoadingMore}
                    observerCallback={handleLoadMoreCashBoxes}
                  />
                ) : null}
              </StyledDropdownRenderContainer>
            );
          }}
          onChange={handleChangeCashBox}
          value={
            cashBoxValue && cashBoxSelectList?.length ? cashBoxValue : undefined
          }
        />
      )}
    </>
  );
}
