import React, { useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import {
  ICompanyUserParams,
  correctUserFormParam,
} from '@services/api/companyUser';
import { User } from '@services/models';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const UserSideWindowLazy = React.lazy(() => import('../Show/UserSideWindow'));

export interface IUpdateUserButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: ICompanyUserParams) => Promise<void>;
  onError?: () => void;
  children?: React.ReactNode;
  user: User;
  defaultCompanyUuid: string;
}

export default function UpdateUserButton({
  children,
  user,
  onSuccess,
  onError,
  defaultCompanyUuid,
  ...rest
}: IUpdateUserButtonProps) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit company user')}
        onClick={() => setVisible(true)}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <UserSideWindowLazy
          onSuccess={onSuccess}
          onCancel={() => setVisible(false)}
          editMode
          user={correctUserFormParam(user, defaultCompanyUuid)}
          visible={visible}
          setVisible={setVisible}
        />
      </Suspense>
    </>
  );
}
