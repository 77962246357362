import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ConfirmActionButton,
  IConfirmActionButtonProps,
} from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { IClientSubscriptionProps, len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';

export default withTranslation()(DeleteClientSubscriptionButton);

export interface IDeleteClientSubscriptionButtonProps
  extends WithTranslation,
    Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  subscriptions: IClientSubscriptionProps[];
}

function DeleteClientSubscriptionButton({
  children,
  subscriptions,
  onSuccess,
  onError,
  t,
  ...rest
}: IDeleteClientSubscriptionButtonProps): JSX.Element {
  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: subscriptions,
    notifyTitle: 'Client',
  });

  return (
    <ConfirmActionButton
      tooltip={t('Delete client service')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(subscriptions)} services`}</b>
        </TextFormat>
      }
      danger
      type="text"
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      data-testid="clients-subscription__button--delete"
      {...rest}>
      {children || t('Delete')}
    </ConfirmActionButton>
  );
}
