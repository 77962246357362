import { IntegrationCategoryDTO } from '../internal';

export enum IntegrationService {
  CHECKBOX = 'checkbox',
}

export interface IntegrationDTO {
  uuid: string;
  title: string;
  icon_url: string;
  help_html: string;
  service: IntegrationService;
  description: string;
  created_at: string;
  updated_at: string;
  category: IntegrationCategoryDTO;
}
