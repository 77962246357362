import React from 'react';
import Color from 'color';
import Button, { IButtonProps } from './Button';
import styled, { css } from 'styled-components';
import { BsCheck2Circle } from 'react-icons/bs';
export interface IDetailsActionItemViewProps extends IButtonProps {
  children: React.ReactNode | React.ReactNode[];
  color: string;
  selected?: boolean;
  $minHeight?: number;
}

const StyledButton = styled(Button)<{
  $color: string;
  $selected?: boolean;
  $minHeight?: number;
  disabled: boolean;
}>`
  position: relative;

  width: 100%;
  min-height: ${({ $minHeight }) => ($minHeight ? `${$minHeight}px` : '80px')};
  height: auto;

  min-width: 200px;
  box-sizing: border-box;

  &&&&&& {
    border: none;

    background-color: ${({ $color, $selected }) =>
      $selected
        ? Color($color).alpha(1).darken(0.1).toString()
        : Color($color).alpha(1).toString()};

    ${({ disabled, $color }) =>
      !disabled &&
      css`
        &:focus,
        &:active,
        &:hover {
          background-color: ${Color($color).darken(0.1).alpha(1).toString()};
          //border: 1px solid ${Color($color).darken(0.3).alpha(1).toString()};
        }
      `}

    ${({ disabled, loading }) =>
      (disabled || loading) &&
      css`
        border-radius: 7px;
        border: 1px solid transparent;

        & .ant-btn {
          height: 100%;
          border: none;
        }
      `}
  }
`;

const StyledSelectedIconContainer = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 2;
`;

export function DetailsActionItemView({
  children,
  color,
  selected,
  disabled,
  $minHeight,
  ...rest
}: IDetailsActionItemViewProps): React.JSX.Element {
  const textColor = Color(color).alpha(1).darken(0.65).toString();

  return (
    <StyledButton
      $minHeight={$minHeight}
      $color={color}
      $selected={selected}
      type="primary"
      ghost
      disabled={!!disabled}
      {...rest}>
      {children}
      {selected ? (
        <StyledSelectedIconContainer>
          <BsCheck2Circle size={15} color={textColor} />
        </StyledSelectedIconContainer>
      ) : null}
    </StyledButton>
  );
}
