import { EmployeeHeader } from '../Show';
import { useAcl } from '@contex';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { EmployeeOrderBonusList } from '../List';
import React, { useCallback, useMemo } from 'react';
import { RewardSalaryList } from '../../Rewards/List';
import { RewardSalaryCreateButton } from '../../Rewards';
import { Routes, RoutesAcl } from '@services/types';
import { dateToIsoString, toDateByFormat } from '@services/helpers';
import { StyledTitle } from '@components/lib/Styled';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { DocumentControlPanel } from '@components/lib/General';
import { OverviewHeader, OverviewLayout } from '@components/lib/Layout';

import {
  EmployeeMapper,
  EmployeeSalaryModel,
  EmployeeSalaryFormDTO,
} from '@structure';

import {
  useStopLoading,
  useStoredReward,
  useStoreEmployee,
  useStoredCompanies,
  useStoredRewardList,
} from '@hooks';

const StyledSalarySettingsContainer = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledSalarySettingsHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRewardSalaryCreateButton = styled(RewardSalaryCreateButton)`
  width: fit-content;
`;

const StyledButtonTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  width: 100%;
  height: 100%;
`;

export default function EmployeeSalarySettingsPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { employeeId } = useParams();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ employee }) => employee);

  const { handleChangeEmployeeBonuses, handleChangeEmployeeSalary } =
    useStoreEmployee({
      companyUuid: defaultCompanyUuid,
      employeeUuid: employeeId!,
      loadOnInit: false,
    });

  const { handleUpdateStoredRewardStats } = useStoredReward({
    employeeUuid: employeeId!,
    rewardUuid: '',
    keywords: '',
    loadOnInit: false,
    month: new Date().getMonth(),
    year: new Date().getTime(),
  });

  const {
    loading: rewardsLoading,
    error: rewardsError,
    stats,
    salarySettingsDates,
  } = useStoredRewardList({
    employeeUuid: employeeId!,
  });

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.employees}`);
  }, [location?.state?.goBack, navigate]);

  const employee = useMemo(() => stats?.employee, [stats?.employee]);
  const loading = useStopLoading({
    loading: rewardsLoading,
    error: rewardsError,
    message: 'An error occurred during employee loading',
  });

  const printOptions: any[] = [];
  const documentOptions: any[] = [];

  const breadcrumbs = useMemo(
    () => [
      {
        path: `/${Routes.employees}`,
        breadcrumbName: 'Employees',
      },
      {
        path: `/${Routes.employees}/${employeeId}`,
        breadcrumbName: employee?.fullName || t('Show Employee'),
      },
      {
        path: '',
        breadcrumbName: t('The salary settings'),
      },
    ],
    [employeeId, employee, t],
  );
  const routes = useBreadcrumb(breadcrumbs);

  const updateSalary = useCallback(
    (value: EmployeeSalaryFormDTO) => {
      const { employee } = stats || {};
      const { salary_settings } = employee || {};

      const salaryFormDTO = {
        ...value,
        start_date: dateToIsoString(value?.start_date),
      };

      const salary = EmployeeMapper.toEmployeeSalaryListDTO(
        salary_settings || [],
        0,
      ).salary;

      return { salary, salaryFormDTO };
    },
    [stats],
  );

  const createEmployeeSalary = useCallback(
    async (value: EmployeeSalaryFormDTO) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);
        salary.push(salaryFormDTO as any);
        const employeeModel = await handleChangeEmployeeSalary(salary);

        handleUpdateStoredRewardStats({ employee: employeeModel });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  const updateEmployeeSalary = useCallback(
    async (value: EmployeeSalaryFormDTO) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);

        const filteredSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        filteredSalary.push(salaryFormDTO as any);

        const employeeModel = await handleChangeEmployeeSalary(filteredSalary);

        handleUpdateStoredRewardStats({ employee: employeeModel });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  const deleteEmployeeSalary = useCallback(
    async (value: EmployeeSalaryModel) => {
      if (stats) {
        const { salary, salaryFormDTO } = updateSalary(value);

        const salaryDTO = EmployeeMapper.toEmployeeSalaryDTO({
          ...salaryFormDTO,
          enabled: false,
        });

        const filteredSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        filteredSalary.push(salaryDTO);

        const employeeModel = await handleChangeEmployeeSalary(filteredSalary);

        const deletedSalary = salary.filter(
          ({ start_date }) =>
            toDateByFormat(start_date, 'MM.YYYY') !==
            toDateByFormat(salaryFormDTO?.start_date, 'MM.YYYY'),
        );

        const updatedEmployee = employeeModel.update(
          'salary_settings',
          () =>
            EmployeeMapper.toEmployeeSalaryListModel(
              deletedSalary,
              deletedSalary?.length,
            ).salary,
        );

        handleUpdateStoredRewardStats({ employee: updatedEmployee });
      }
    },
    [
      handleChangeEmployeeSalary,
      handleUpdateStoredRewardStats,
      stats,
      updateSalary,
    ],
  );

  return (
    <>
      <OverviewLayout
        aclItem={RoutesAcl[Routes.employeeSalarySettings]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerRight={
          <DocumentControlPanel
            printItems={printOptions}
            items={documentOptions}
            isDraft={true}
          />
        }
        disabled={!manage}
        editTooltip={t('Edit Employee')}
        headerTitle={t('The salary settings')}
        tabs={[]}
        data={null}
        routes={routes}
        loading={loading}
        header={
          <OverviewHeader
            withoutIndent
            title={''}
            withoutUpload
            description={
              <EmployeeHeader employee={employee || null} showBalance />
            }
            image={employee?.picture_url}
            handleUploadImage={async () => {
              return '';
            }}
          />
        }
        footerContentExtra={
          <StyledSalarySettingsContainer>
            <StyledSalarySettingsHeader>
              <StyledTitle fontSize={15} fontWeight={500}>
                {`${t('The salary settings')}:`}
              </StyledTitle>
              <StyledRewardSalaryCreateButton
                type="primary"
                onSuccess={createEmployeeSalary}
                salary={EmployeeMapper.toEmployeeSalaryFormDTO(
                  {} as EmployeeSalaryModel,
                )}
                salarySettingsDates={salarySettingsDates}
                title={
                  <StyledButtonTitleContainer>
                    <PlusOutlined />
                    <StyledTitle>{t('New record')}</StyledTitle>
                  </StyledButtonTitleContainer>
                }
              />
            </StyledSalarySettingsHeader>

            <RewardSalaryList
              salary={stats?.employee?.salary_settings!}
              createEmployeeSalary={createEmployeeSalary}
              updateEmployeeSalary={updateEmployeeSalary}
              deleteEmployeeSalary={deleteEmployeeSalary}
              salarySettingsDates={salarySettingsDates}
            />

            <StyledTitle fontSize={15} fontWeight={500}>
              {`${t('Bonuses')}:`}
            </StyledTitle>

            <EmployeeOrderBonusList
              bonuses={stats?.employee?.bonus_list!}
              handleChangeEmployeeBonuses={handleChangeEmployeeBonuses}
            />
          </StyledSalarySettingsContainer>
        }
      />
    </>
  );
}
