import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import {
  ClientModel,
  IClientOperationListStats,
  PaymentModel,
} from '@structure';
import { OverviewBottomView } from '@components/lib/Layout';
import { correctPrice } from '@sportix/sportix-common-modules';

export interface ClientOperationListBottomViewProps {
  stats: IClientOperationListStats | null;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

export const ClientOperationListBottomView = forwardRef(
  function ClientOperationListBottomView(
    { stats }: ClientOperationListBottomViewProps,
    ref: any,
  ): JSX.Element {
    const { t } = useTranslation();
    const { defaultCompany } = useStoredCompanies();

    return (
      <OverviewBottomView>
        <StyledPriceContainer>
          <StyledTitle fontSize={14}>{`${t(
            'Transaction amount',
          )}: `}</StyledTitle>
          <StyledPrice>
            {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
              stats?.total || 0,
            )}`}
          </StyledPrice>
        </StyledPriceContainer>
      </OverviewBottomView>
    );
  },
);
