import React, { useCallback, useMemo, useState } from 'react';
import { Empty, Form } from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import {
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemListModel,
  StoreDocumentModel,
  StoreDocumentType,
} from '@structure';
import { useTranslation } from 'react-i18next';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  EditableCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import { useDefaultForm, useDropdownAlert } from '@contex';
import { correctPrice, isFunction } from '@services/helpers';
import styled, { css } from 'styled-components';
import {
  DeleteButton,
  DetailsView,
  EditButton,
  TableIndexField,
} from '@components/lib/DataDisplay';
import { TextFormat } from '@components/lib/Format';
import { LoadingOutlined } from '@ant-design/icons';
import { ProductDetailsView } from '../../Products';
import { StoreDocItemsPriceView } from '../Show';
import { IUseStoreDocumentItemListProps } from '@hooks';
import { StoreRealizationDocItemsTableTotalView } from '../Realization';

import '../../Orders/List/OrderList.less';

export interface IStoreDocItemsFieldProps {
  handleEditDocItem: (docItem: StoreDocumentItemFormDTO) => void;
  editMode?: boolean;
  handleDeleteItem?: (ids: string[]) => Promise<void>;
  handleEditItem?: (item: StoreDocumentItemFormDTO) => Promise<any>;
  selectedProductItemUuid?: string;
  loading?: boolean;
  storeDocumentItemListRefresh?: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  storeDocumentItemListLimit?: number;
  storeDocumentItemListTotal?: number;
  storeDocumentItemListPage?: number;

  withEditablePrice?: boolean;
  document?: StoreDocumentFormDTO;
  isOrderFields?: boolean;
}

const StyledTable = styled(Table)`
  margin: 0;
  .ant-table-thead {
    .ant-table-cell {
      text-transform: uppercase;
    }
  }
` as React.ComponentType as React.FC<ITableProps<StoreDocumentItemFormDTO>>;

const StyledAmountContainer = styled.div<{ $loading: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $loading }) =>
    $loading ? 'space-between' : 'flex-end'};
  align-items: center;
  gap: 5px;

  width: 100%;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  //margin-bottom: 20px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledTotalContainer = styled.div<{
  $bold?: boolean;
  $isLineThrough: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 600;
    `}

  ${({ $isLineThrough }) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

const StyledLineThroughTitle = styled(StyledTitle)`
  text-decoration: line-through;
`;

export function StoreDocItemsField({
  handleEditDocItem,
  handleDeleteItem,
  handleEditItem,
  editMode,
  selectedProductItemUuid,

  loading: initLoading,
  storeDocumentItemListRefresh,
  storeDocumentItemListLimit,
  storeDocumentItemListTotal,
  storeDocumentItemListPage,
  withEditablePrice,
  document,
  isOrderFields,
}: IStoreDocItemsFieldProps): React.JSX.Element {
  const { t } = useTranslation();
  const { formData, handlerUpdateFormState, loadingSubmit }: any =
    useDefaultForm();
  const { alert } = useDropdownAlert();
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);

  const { defaultCompany } = useStoredCompanies();

  const validateList =
    formData?.doc_items?.map((_: any, index: number) => [
      'doc_items',
      index,
      'product_amount',
    ]) || [];

  const validateListPrice =
    formData?.doc_items?.map((_: any, index: number) => [
      'doc_items',
      index,
      'price',
    ]) || [];

  const total = useMemo(
    () =>
      formData?.doc_items?.reduce(
        (acc: number, curr: StoreDocumentItemFormDTO) => {
          acc += Number(curr?.base_price) * curr?.product_amount;

          return acc;
        },
        0,
      ),
    [formData?.doc_items],
  );

  const handleDeleteDocItem = useCallback(
    async (docItem: StoreDocumentItemFormDTO) => {
      if (isFunction(handleDeleteItem)) {
        try {
          await handleDeleteItem([docItem?.uuid!]);
        } catch (error: any) {
          alert(
            'error',
            t('Store document item'),
            `${t('An error occurred during delete store document item')}: ${
              error?.message
            }`,
          );
          return;
        }
      }

      const filteredDocItems = formData?.doc_items?.filter(
        ({ product_uuid }: StoreDocumentItemFormDTO) =>
          product_uuid !== docItem?.product_uuid,
      );

      handlerUpdateFormState({ doc_items: filteredDocItems });

      if (isFunction(handleDeleteItem)) {
        alert(
          'success',
          t('Store document item'),
          t('Store document item delete success'),
        );
      }
    },
    [alert, formData?.doc_items, handleDeleteItem, handlerUpdateFormState, t],
  );

  const defaultColumns = [
    {
      title: `№`,
      key: 'index',
      width: 10,
      align: 'center' as any,
      className: 'order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField
          number={
            ((storeDocumentItemListPage || 1) - 1) *
              (storeDocumentItemListLimit || 10) +
            index +
            1
          }
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      render: (docItem: StoreDocumentItemFormDTO) =>
        isFunction(handleDeleteDocItem) ? (
          <ProductDetailsView
            product={docItem?.product!}
            item_product_returned_amount={docItem?.item_product_returned_amount}
            product_amount={docItem?.product_amount}
            withTour
            price={docItem?.base_price}
          />
        ) : (
          docItem?.product_title
        ),
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      editable: !loadingSubmit && !editMode,
      validateList: [...validateList, 'product_amount'],
      width: 150,
      rules: (cellIndex?: any) => [
        () => ({
          validator(_: any, amount: string) {
            const value = amount;

            if (Number(value) < 1) {
              return Promise.reject(
                new Error(`${t('Amount must be greater or equal than')} 1`),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('Amount must be a number')));
            }

            if (cellIndex && value > cellIndex) {
              return Promise.reject(
                new Error(
                  `${t('tAmount must be less or equal than')} ${cellIndex}`,
                ),
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => {
        const loadingItem = selectedProductItemUuid
          ? selectedProductItemUuid === docItem?.product_uuid && loading
          : loading;

        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isReturnedAmount = returnedAmount > 0;

        const isLessThen = returnedAmount < docItem?.product_amount;
        const isGrateOrEqualThen = returnedAmount >= docItem?.product_amount;

        return (
          <StyledAmountContainer $loading={loadingItem}>
            {loadingItem ? <LoadingOutlined /> : null}

            {isReturnedAmount ? (
              <>
                {isLessThen ? (
                  <>
                    <StyledLineThroughTitle>
                      {docItem?.product_amount}
                    </StyledLineThroughTitle>
                    <StyledTitle>
                      {docItem?.product_amount - returnedAmount}
                    </StyledTitle>
                  </>
                ) : null}

                {isGrateOrEqualThen ? (
                  <StyledLineThroughTitle>
                    {docItem?.product_amount}
                  </StyledLineThroughTitle>
                ) : null}
              </>
            ) : (
              <StyledTitle>{docItem?.product_amount}</StyledTitle>
            )}
          </StyledAmountContainer>
        );
      },
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      align: 'right' as any,
      editable: !loadingSubmit && !editMode && withEditablePrice,
      validateList: [...validateListPrice, 'price'],
      rules: (cellIndex?: any) => [
        () => ({
          validator(_: any, amount: any) {
            const value = amount;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('The price must be greater than 0')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('The price must be a number')));
            }

            return Promise.resolve();
          },
        }),
      ],
      width: 150,
      render: (docItem: StoreDocumentItemFormDTO) => {
        const loadingItem = selectedProductItemUuid
          ? selectedProductItemUuid === docItem?.product_uuid && loadingPrice
          : loadingPrice;

        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen = returnedAmount >= docItem?.product_amount;

        return (
          <StyledAmountContainer $loading={loadingItem}>
            {loadingItem ? <LoadingOutlined /> : null}
            <StoreDocItemsPriceView
              docItem={docItem}
              isLineThrough={isGrateOrEqualThen}
            />
          </StyledAmountContainer>
        );
      },
    },
    {
      title: `${t('Total')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'total',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen = returnedAmount >= docItem?.product_amount;

        return (
          <StyledTotalContainer $isLineThrough={isGrateOrEqualThen}>
            <StoreDocItemsPriceView
              docItem={docItem}
              multiplier={docItem?.product_amount || 1}
              isLineThrough={isGrateOrEqualThen}
            />
          </StyledTotalContainer>
        );
      },
    },
    ...(editMode
      ? []
      : [
          {
            ellipsis: true,
            title: t('Actions'),
            key: 'store_actions',
            align: 'center' as any,
            fixed: 'right' as any,
            width: 50,
            className: 'order-list--transparent',
            render: (docItem: StoreDocumentItemFormDTO) => {
              return (
                <TableActionCell>
                  <EditButton
                    tooltipTitle={t('Edit material')}
                    onClick={() => handleEditDocItem(docItem)}
                    disabled={(docItem?.item_product_returned_amount || 0) > 0}
                  />
                  <DeleteButton
                    tooltipTitle={t('Delete material')}
                    confirmTitle={
                      <TextFormat breakWord>
                        {t('Are you sure you want to delete')} {t('material')}?
                      </TextFormat>
                    }
                    onConfirm={() => handleDeleteDocItem(docItem)}
                    cancelButtonText={t('Cancel')}
                    disabled={(docItem?.item_product_returned_amount || 0) > 0}
                  />
                </TableActionCell>
              );
            },
          },
        ]),
  ];

  const orderFields: any[] = [
    {
      title: `№`,
      key: 'index',
      width: 10,
      align: 'center' as any,
      className: 'order-list--middle-text',
      render: (
        document: StoreDocumentItemFormDTO,
        items: any,
        index: number,
      ) => (
        <TableIndexField
          number={
            ((storeDocumentItemListPage || 1) - 1) *
              (storeDocumentItemListLimit || 10) +
            index +
            1
          }
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      render: (docItem: StoreDocumentItemFormDTO) =>
        isFunction(handleDeleteDocItem) ? (
          <ProductDetailsView
            product={docItem?.product!}
            item_product_returned_amount={docItem?.item_product_returned_amount}
            product_amount={docItem?.product_amount}
            withTour
            price={docItem?.base_price}
          />
        ) : (
          docItem?.product_title
        ),
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      editable: !loadingSubmit && !editMode,
      validateList: [...validateList, 'product_amount'],
      width: 150,
      rules: (cellIndex?: any) => [
        () => ({
          validator(_: any, amount: string) {
            const value = amount;

            if (Number(value) < 1) {
              return Promise.reject(
                new Error(`${t('Amount must be greater or equal than')} 1`),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('Amount must be a number')));
            }

            if (cellIndex && value > cellIndex) {
              return Promise.reject(
                new Error(
                  `${t('tAmount must be less or equal than')} ${cellIndex}`,
                ),
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => {
        const loadingItem = selectedProductItemUuid
          ? selectedProductItemUuid === docItem?.product_uuid && loading
          : loading;

        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isReturnedAmount = returnedAmount > 0;

        const isLessThen = returnedAmount < docItem?.product_amount;
        const isGrateOrEqualThen = returnedAmount >= docItem?.product_amount;

        return (
          <StyledAmountContainer $loading={loadingItem}>
            {loadingItem ? <LoadingOutlined /> : null}

            {isReturnedAmount ? (
              <>
                {isLessThen ? (
                  <>
                    <StyledLineThroughTitle>
                      {docItem?.product_amount}
                    </StyledLineThroughTitle>
                    <StyledTitle>
                      {docItem?.product_amount - returnedAmount}
                    </StyledTitle>
                  </>
                ) : null}

                {isGrateOrEqualThen ? (
                  <StyledLineThroughTitle>
                    {docItem?.product_amount}
                  </StyledLineThroughTitle>
                ) : null}
              </>
            ) : (
              <StyledTitle>{docItem?.product_amount}</StyledTitle>
            )}
          </StyledAmountContainer>
        );
      },
      fields: (docItem: StoreDocumentItemFormDTO) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen = returnedAmount >= docItem?.product_amount;

        return (
          <>
            <StyledStatsContainer>
              <StyledTitle>x</StyledTitle>
              <StoreDocItemsPriceView
                docItem={docItem}
                isLineThrough={isGrateOrEqualThen}
              />
            </StyledStatsContainer>

            <StyledTotalContainer $bold $isLineThrough={isGrateOrEqualThen}>
              {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                Number(docItem?.base_price || 0) *
                  (docItem?.product_amount || 1),
              )}`}
            </StyledTotalContainer>
          </>
        );
      },
    },
    ...(editMode
      ? []
      : [
          {
            ellipsis: true,
            title: t('Actions'),
            key: 'store_actions',
            align: 'center' as any,
            fixed: 'right' as any,
            width: 50,
            className: 'order-list--transparent',
            render: (docItem: StoreDocumentItemFormDTO) => {
              return (
                <TableActionCell
                  className="store-doc-items-actions"
                  direction="vertical">
                  <EditButton
                    tooltipTitle={t('Edit material')}
                    onClick={() => handleEditDocItem(docItem)}
                    disabled={(docItem?.item_product_returned_amount || 0) > 0}
                  />
                  <DeleteButton
                    tooltipTitle={t('Delete material')}
                    confirmTitle={
                      <TextFormat breakWord>
                        {t('Are you sure you want to delete')} {t('material')}?
                      </TextFormat>
                    }
                    onConfirm={() => handleDeleteDocItem(docItem)}
                    cancelButtonText={t('Cancel')}
                    disabled={(docItem?.item_product_returned_amount || 0) > 0}
                  />
                </TableActionCell>
              );
            },
          },
        ]),
  ];

  const handleSave = useCallback(
    async (row: StoreDocumentItemFormDTO) => {
      const newData = [...(formData?.doc_items || [])];
      const index = newData.findIndex(
        (item) => row.product_uuid === item.product_uuid,
      );

      const item = newData[index];

      const updatedItem = {
        ...item,
        product_amount: row?.product_amount || item?.product_amount,
        price: row?.price || item?.price,
        base_price: row?.price || item?.base_price,
      };

      if (
        isFunction(handleEditItem) &&
        item?.product_amount !== row?.product_amount
      ) {
        setLoading(true);
        await handleEditItem(updatedItem);
        setLoading(false);
      }

      if (isFunction(handleEditItem) && item?.price !== row?.price) {
        setLoadingPrice(true);
        await handleEditItem(updatedItem);
        setLoadingPrice(false);
      }

      newData.splice(index, 1, updatedItem);

      handlerUpdateFormState({ doc_items: [...newData] });
    },
    [formData?.doc_items, handleEditItem, handlerUpdateFormState],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = (isOrderFields ? orderFields : defaultColumns).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: StoreDocumentItemFormDTO) => {
        return {
          record,
          editable:
            (record?.item_product_returned_amount || 0) > 0
              ? false
              : col.editable,
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          alignText: col?.align,
          validateList: col?.validateList,
          cellIndex: record?.max_amount,
          fields: col?.fields,
        };
      },
    };
  });

  const currencies = useMemo(
    () =>
      (document?.stats?.currencies || [])?.filter(
        ({ currency }) => currency !== '₴',
      ),
    [document?.stats?.currencies],
  )
    .map(({ sum, currency }) => `${currency}: ${sum}`)
    .join(', ');

  return (
    <>
      <Form.Item
        name="doc_items"
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: t('List of products be specified.'),
          },
        ]}>
        <StyledTable
          loading={initLoading}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={formData?.doc_items}
          rowKey="product_uuid"
          columns={columns}
          total={storeDocumentItemListTotal || formData?.doc_items?.length}
          pageSize={storeDocumentItemListLimit || Infinity}
          renderEmpty={
            <Empty
              description={t(
                'It looks like you have not selected any products',
              )}
            />
          }
          onRow={(record: StoreDocumentItemFormDTO) => {
            const returnedAmount = record?.item_product_returned_amount || 0;
            const isReturnedAmount = returnedAmount > 0;

            const isLessThen = returnedAmount < record?.product_amount;
            const isGrateOrEqualThen = returnedAmount >= record?.product_amount;

            return {
              className: `${
                isReturnedAmount && isLessThen ? 'order-list--warning' : ''
              } ${
                isReturnedAmount && isGrateOrEqualThen
                  ? 'order-list--error'
                  : ''
              }`,
            };
          }}
          onChange={storeDocumentItemListRefresh}
        />
      </Form.Item>
      {formData?.doc_items?.length ? (
        <>
          {formData?.doc_type === StoreDocumentType.OUT ? (
            <StoreRealizationDocItemsTableTotalView document={document} />
          ) : (
            <StyledDetailsView titles={[]}>
              <StyledStatsContainer>
                <StyledTitle fontSize={15} bold>{`${t('Total')}, ${
                  defaultCompany?.currency_symbol || ''
                }: ${correctPrice(total || 0)}`}</StyledTitle>

                {currencies?.length ? (
                  <StyledDescription>{`${currencies}`}</StyledDescription>
                ) : null}
              </StyledStatsContainer>
            </StyledDetailsView>
          )}
        </>
      ) : null}
    </>
  );
}
