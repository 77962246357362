import * as React from 'react';
import { List } from 'immutable';
import {
  ApiError,
  DateValue,
  IEntityProps,
  IListSearchProps,
} from '@services/types';
import {
  IScheduleCalendarListDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleCalendarListModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getClientAbonementScheduleList } from '@services/api/client';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { END_OF_MONTH, START_OF_MONTH } from '@services/helpers';

export interface IUseClientAbonementScheduleListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  abonementUuid: string;
  date_start?: DateValue;
  date_end?: DateValue;
}

export interface IUseClientAbonementScheduleListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ScheduleCalendarModel> | null;
  refresh: (
    value: Partial<IUseClientAbonementScheduleListProps>,
  ) => Promise<ScheduleCalendarListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  abonementUuid: string;
  date_start: DateValue;
  date_end: DateValue;
}

export function useClientAbonementScheduleList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    abonementUuid,
    date_start = START_OF_MONTH,
    date_end = END_OF_MONTH,
  }: IUseClientAbonementScheduleListProps = {} as IUseClientAbonementScheduleListProps,
): IUseClientAbonementScheduleListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    abonementUuid: listUuid,
    date_start: listStart,
    date_end: listEnd,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseClientAbonementScheduleListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    abonementUuid,
    date_start,
    date_end,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      abonementUuid = listUuid,
      date_start = listStart,
      date_end = listEnd,
    }: Partial<IUseClientAbonementScheduleListProps> = {}): Promise<ScheduleCalendarListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          abonementUuid,
          date_start,
          date_end,
        });

        const { schedules, total } =
          await cancellablePromise<IScheduleCalendarListDTO>(
            getClientAbonementScheduleList({
              limit,
              offset,
              keywords,
              abonementUuid,
              date_start,
              date_end,
            }),
          );

        const scheduleCalendarModel =
          ScheduleCalendarMapper.toScheduleCalendarListModel(schedules, total);

        handleUpdate({
          entityList: scheduleCalendarModel?.schedules,
          total: scheduleCalendarModel?.total,
          loading: false,
        });

        return scheduleCalendarModel;
      } catch (e: any) {
        alert(
          'error',
          t('Client abonement schedules'),
          `${t(
            'An error occurred during get client abonement schedule list',
          )} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listStart,
      listEnd,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && abonementUuid) {
      (async () => {
        await refresh({ abonementUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, abonementUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    abonementUuid: listUuid,
    date_start: listStart,
    date_end: listEnd,
  };
}
