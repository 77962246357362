import React, { memo } from 'react';
import CalendarCol, { ICalendarColProps } from './CalendarCol';

import './CalendarHourCol.less';

export interface ICalendarHourColProps
  extends Pick<ICalendarColProps, 'span' | 'hourInDay'> {
  showTimeLimit?: boolean;
}

export default memo(({ ...rest }: ICalendarHourColProps) => (
  <CalendarCol className="calendar-hour-col" {...rest}>
    {({ time }) => <span className="calendar-hour-col-time">{time}</span>}
  </CalendarCol>
));
