import React from 'react';
import { CalendarHeader } from './common';
import { CALENDAR_ENTRY } from '../const';
import { useCalendarSpecifications } from '@hooks';
import { useLocation } from 'react-router';

export interface IMainCalendarLayoutProps {
  children: React.ReactNode;
  loading?: boolean;
}

export default function MainCalendarLayout({
  children,
  loading,
}: IMainCalendarLayoutProps): JSX.Element {
  const location = useLocation();
  const { allSpecification } = useCalendarSpecifications();

  return (
    <>
      {!location.pathname.includes(CALENDAR_ENTRY) ? (
        <CalendarHeader specifications={allSpecification} loading={loading} />
      ) : null}
      {children}
    </>
  );
}
