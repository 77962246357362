import { List, Record } from 'immutable';
import {
  ClientSubscriptionDTO,
  CLIENT_TYPE,
  SUBSCRIPTION_STATUS,
  PaymentMapper,
  ClientSubscriptionableMapper,
  PaymentModel,
  ClientSubscriptionableModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IClientSubscriptionModelReturnType {
  uuid: string;
  start_date: string;
  end_date: string;
  comment: string;
  period_amount: number;
  price: string;
  created_at: string;
  updated_at: string;
  payments: List<PaymentModel>;
  type: CLIENT_TYPE;
  status: SUBSCRIPTION_STATUS;
  subscriptionable: ClientSubscriptionableModel;
}

export class ClientSubscriptionModel extends Record<IClientSubscriptionModelReturnType>(
  {
    uuid: '',
    start_date: '',
    end_date: '',
    comment: '',
    period_amount: 0,
    price: '',
    created_at: '',
    updated_at: '',
    payments: List(),
    type: '' as any,
    status: '' as any,
    subscriptionable: {} as ClientSubscriptionableModel,
  },
) {
  constructor(
    props: ClientSubscriptionDTO = {} as ClientSubscriptionDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientSubscriptionModelReturnType = {
      ...props,

      payments: PaymentMapper.toPaymentListModel(props?.payments).payments,

      subscriptionable:
        ClientSubscriptionableMapper.toClientSubscriptionableModel(
          props?.subscriptionable,
        ),
    };

    super(options);
  }
}
