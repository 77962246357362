import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'immutable';
import { IActionButtonProps } from '@components/lib/General';
import { RelatedServiceFormDTO, RelatedServiceModel } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const RelatedServicesSideWindowLazy = React.lazy(
  () => import('../Show/RelatedServicesSideWindow'),
);

export interface IRelatedServicesUpdateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: RelatedServiceFormDTO) => Promise<void>;
  onCancel?: () => void;
  companyUuid: string;
  service: RelatedServiceFormDTO;
  relatedServices: List<RelatedServiceModel> | null;
  serviceUuid: string;
}

export default function RelatedServicesUpdateButton({
  children,
  companyUuid,
  onSuccess,
  onCancel,
  loading,
  service,
  relatedServices,
  serviceUuid,
  ...rest
}: IRelatedServicesUpdateButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit related service')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <RelatedServicesSideWindowLazy
          editMode
          service={service}
          companyUuid={companyUuid}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          relatedServices={relatedServices}
          serviceUuid={serviceUuid}
        />
      </Suspense>
    </>
  );
}
