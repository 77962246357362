import {
  PLANS_TRIAL,
  PLANS_STARTER,
  PLANS_BUSINESS,
  PLANS_INDIVIDUAL,
  toDateByFormat,
} from '@services/helpers';
import { ActiveSubscriptionModel } from '../internal';

export const SubscriptionPlans = {
  Trial: PLANS_TRIAL,
  Individual: PLANS_INDIVIDUAL,
  Starter: PLANS_STARTER,
  Business: PLANS_BUSINESS,
  Empty: '',
};

export type SubscriptionName =
  (typeof SubscriptionPlans)[keyof typeof SubscriptionPlans];

export interface IActiveSubscriptionDTOProps {
  name: SubscriptionName;
  expired_at: string;
}

export class ActiveSubscriptionDTO {
  public name: SubscriptionName;

  public expired_at: string;

  constructor(props: IActiveSubscriptionDTOProps | ActiveSubscriptionModel) {
    this.name = props?.name || SubscriptionPlans.Empty;
    this.expired_at = props?.expired_at
      ? toDateByFormat(props?.expired_at, 'YYYY-MM-DD HH:mm')
      : '';
  }
}
