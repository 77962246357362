import React from 'react';
import styled, { css } from 'styled-components';
import { authLogoUrl } from '@services/const';
import { FlexContainer } from '../Styled/FlexContainer';

export interface ILogoProps {
  url: string;
}

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const StyleLogo = styled.img<{ $isCustomLogo: boolean }>`
  max-width: 80%;
  max-height: 70%;
  width: auto;
  height: auto;

  ${({ $isCustomLogo }) =>
    $isCustomLogo &&
    css`
      border-radius: 50%;
    `};
`;

export default function Logo({ url }: ILogoProps): JSX.Element {
  return (
    <Container justifyContent="center" alignItems="center">
      <StyleLogo $isCustomLogo={url !== authLogoUrl} src={url} alt="logo" />
    </Container>
  );
}
