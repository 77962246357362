import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useKeyboardActions,
  useStopLoading,
  useStoredCompanies,
  useStoredProductCategoryList,
} from '@hooks';
import { debounce, textToUpperCase } from '@services/helpers';
import { useBreadcrumb, useStateReducer } from '@components/lib/libV2/hooks';
import {
  ProductCategoryMappedType,
  ProductCategoryType,
  ProductMappedType,
  ProductModel,
  ProductType,
} from '@structure';
import { Routes as RoutesType, RoutesAcl } from '@services/types';
import {
  Button,
  HeaderLeft,
  ListActions,
  LoadingMore,
  LoadingMoreButton,
} from '@components/lib/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import styled, { useTheme, css } from 'styled-components';
import { useKeyboard } from '@contex';
import { TbKeyboard, TbKeyboardOff, TbCategory } from 'react-icons/tb';
import { useStoreSale } from '../Managers';
import { StyledListActionsContainer } from '../../Show';
import useOutsideClickElements from '../../../../hooks/useOutsideClickElements';
import {
  StoreSaleCategoryBottomView,
  StoreSaleCategoryCard,
  StoreSaleProductCard,
} from '../Show';
import { Route, Routes } from 'react-router-dom';
import { Empty, Spin } from 'antd';
import { ProductCategoryPictures } from '../../../Products';
import { useStoredProductListByCategory } from '../../../../hooks/useStoredProductListByCategory';
import { HEADER_HEIGHT } from '@components/lib/Layout/const';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { LocalStorageItems } from '@services/const';

export interface IStoreSaleCategoryGUIPageProps {}

interface StoreSaleGUIState {
  isShowFilters: boolean;
  isShowStoreWarning: boolean;
  documentStoreName: string;
}

const IGNORED_BLUR_CLASS = ['keyboardContainer'];

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
`;

const StyledCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
`;

const StyledCategoryEmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 150px;
`;

const StyledProductEmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledStoreSaleCategoryCard = styled(StoreSaleCategoryCard)`
  max-height: 80px;
`;

const StyledContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledHeaderLeft = styled(HeaderLeft)`
  top: -40px;
  left: -20px;
`;

const ListActionsContainer = styled(StyledListActionsContainer)`
  position: sticky;
  top: ${HEADER_HEIGHT}px;

  z-index: 2;
`;

const StyledEmpty = styled(Empty)<{ $error?: boolean }>`
  color: ${({ theme }) => theme.colors.disabled};

  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.colors.error};
    `}
`;

export function StoreSaleCategoryGUIPage({}: IStoreSaleCategoryGUIPageProps): JSX.Element {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { t } = useTranslation();
  const theme: any = useTheme();
  const location = useLocation();

  const backUrl = location.pathname.split('/').slice(0, -1).join('/');

  const {
    keywords,
    handleChangeKeywords,
    storeDocument,
    concurrentQueueGUI,
    concurrentQueueDeleteGUI,
    storeDocumentItemList,
    handleDeleteStoreDocumentItemGUI,
    onDeleteStoreDocumentFromState,
    handleAddOrUpdateItemGUI,
    onAddStoreDocumentFromState,
  } = useStoreSale();

  const once = useRef(false);
  const onceInitInput = useRef(false);

  const getItemIndex = useMemo(
    () => (product: ProductModel) =>
      storeDocumentItemList?.findIndex(
        ({ product_title }) => product?.product_title === product_title,
      ),
    [storeDocumentItemList],
  );

  const isSelected = useMemo(
    () => (product: ProductModel) => !!~getItemIndex(product),
    [getItemIndex],
  );

  const {
    documentStoreName,
    isShowStoreWarning,

    handleUpdate: updateSaleContentState,
  } = useStateReducer<StoreSaleGUIState>({
    isShowFilters: false,
    isShowStoreWarning: false,
    documentStoreName: '',
  });

  const {
    isFocus,
    handleClearKeyboards,
    initInput,
    focus,
    keyboardShow,
    handleToggleKeyboard,
  } = useKeyboard();

  const [withKeyboard, setWithKeyboard] = useState<boolean | undefined>(
    keyboardShow ? false : undefined,
  );

  useOutsideClickElements(() => {
    if (keyboardShow) {
      if (isFocus) {
        setWithKeyboard(undefined);
      } else {
        setWithKeyboard(false);
      }

      // handleClearKeyboards();
      // handleChangeKeywords('');
    }
  }, IGNORED_BLUR_CLASS);

  const {
    categoryList,
    loading: categoryListLoading,
    error: categoryListError,
    category,
    isLoadingMore: categoryListIsLoadingMore,

    handleLoadMoreProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    productCategoryType: ProductCategoryType.LIST,
    mappedProductCategoryType: ProductCategoryMappedType.SUB_CATEGORY_LIST,
    parent_category_uuid: categoryId,
    show_root_only: true,
  });

  const store = useMemo(() => {
    const serializeStoreData = localStorage.getItem(
      LocalStorageItems.saleFormStoreData,
    );

    return serializeStoreData?.includes('store_name')
      ? JSON.parse(serializeStoreData)
      : null;
  }, []);

  const {
    productList,
    loading: productListLoading,
    loadingMore: productListLoadingMore,
    error: productListError,
    keywords: productListKeywords,
    isLoadingMore: productListIsLoadingMore,

    handleSearchProducts: onSearchProducts,
    handleLoadMoreProducts,
  } = useStoredProductListByCategory({
    categoryUuid: categoryId!,
    productType: ProductType.DEFAULT,
    mappedProductType: ProductMappedType.SALE,
    limit: 10,
    store_uuid: store?.uuid,
    loadOnInit: !!store?.uuid,
  });

  const loading = useStopLoading({
    error: categoryListError || productListError,
    loading: categoryListLoading || (store?.uuid && productListLoading),
    message: 'An error occurred during materials categories loading',
  });

  const handleSearchProducts = useMemo(
    () =>
      debounce(
        async (keywords: string) => onSearchProducts({ keywords }),
        1000,
      ),
    [onSearchProducts],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      handleChangeKeywords(keywords);
      await handleSearchProducts(keywords);
    },
    [handleChangeKeywords, handleSearchProducts],
  );

  const handleAddOrRemoveProduct = useCallback(
    async (product: ProductModel) => {
      focus();

      const waiting = concurrentQueueGUI.current.waiting.findIndex(
        ({ uuid }) => uuid === product?.uuid,
      );

      const index = getItemIndex(product);

      const item = storeDocumentItemList[index];

      const waitingRemove = concurrentQueueDeleteGUI.current.waiting.findIndex(
        ({ uuid }) => uuid === item?.uuid,
      );

      if (~waiting || ~index) {
        if (~waiting) {
          concurrentQueueGUI.current.removeWaitingItem(product?.uuid);
        }

        if (item && item?.uuid === item?.product_title) {
          onDeleteStoreDocumentFromState(item);
        } else if (item && item?.uuid !== item?.product_title) {
          await handleDeleteStoreDocumentItemGUI(item);
        }

        return;
      }

      if (~waitingRemove) {
        concurrentQueueDeleteGUI.current.removeWaitingItem(item?.uuid || '');
        onAddStoreDocumentFromState(item);
      } else {
        handleAddOrUpdateItemGUI(product);
      }
    },
    [
      focus,
      concurrentQueueGUI,
      getItemIndex,
      storeDocumentItemList,
      concurrentQueueDeleteGUI,
      onDeleteStoreDocumentFromState,
      handleDeleteStoreDocumentItemGUI,
      onAddStoreDocumentFromState,
      handleAddOrUpdateItemGUI,
    ],
  );

  useKeyboardActions({
    eventListenerCallBack: onSearch,
    keyboardShow,
  });

  const routes = useBreadcrumb([
    {
      path: `/${RoutesType.stores}/${RoutesType.storeSale}`,
      breadcrumbName: 'Sales',
    },
    {
      path: `/${RoutesType.stores}/${RoutesType.storeSale}/${RoutesType.categories}`,
      breadcrumbName: 'Selection of categories',
    },
    {
      path: `/${RoutesType.stores}/${RoutesType.storeSale}/${RoutesType.categories}/${categoryId}`,
      breadcrumbName: 'Product selection',
    },
  ]);

  const navigateToCategory = useCallback(
    (categoryUuid: string) => {
      navigate(`${location.pathname}/${categoryUuid}`);
    },
    [location.pathname, navigate],
  );

  const navigateToPrevCategory = useCallback(() => {
    navigate(backUrl);
  }, [backUrl, navigate]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${RoutesType.app}/${RoutesType.stores}/${RoutesType.storeSale}`);
  }, [navigate]);

  useEffect(() => {
    if (!once.current) {
      once.current = true;

      handleChangeKeywords(productListKeywords);
    }
  }, [productListKeywords, handleChangeKeywords]);

  useEffect(() => {
    if (
      !onceInitInput.current &&
      !loading &&
      (categoryList?.size || productList?.size)
    ) {
      onceInitInput.current = true;

      initInput('store-sell-product-page');
    }
  }, [categoryList?.size, initInput, loading, productList?.size]);

  return (
    <>
      <Routes>
        <Route
          path={`/`}
          element={
            <ListLayout
              isFocusKeyboard={isFocus}
              withKeyboard={keyboardShow}
              showWarning={false}
              withPaddingBottom
              aclItem={RoutesAcl[RoutesType.stores]}
              headerLeft={
                <HeaderLeft
                  absolute={false}
                  onClick={navigateGoBack}
                  title={textToUpperCase(t('Sales'))}
                />
              }
              headerTitle={
                <StyledPageHeader>
                  <StyledTitle fontSize={20} fontWeight={400}>
                    {textToUpperCase(t('Product selection'))}
                  </StyledTitle>
                  {storeDocument?.uuid ? (
                    <StyledDescription>{`${t('Document')}: №${
                      storeDocument?.doc_local_number
                    }`}</StyledDescription>
                  ) : null}
                  {category ? (
                    <StyledDescription>{`${t('Category')}: ${
                      category?.title
                    }`}</StyledDescription>
                  ) : null}
                </StyledPageHeader>
              }
              routes={routes}
              empty={null}
              loading={loading && (!categoryList?.size || !productList?.size)}
              outsideFooterContent={<StoreSaleCategoryBottomView />}>
              <ProductCategoryPictures
                disabled
                coverHeight={100}
                logo={category?.logo_url}
                cover={category?.cover_url}
                title={category?.title}
              />
              <StyledContent>
                <StyledHeaderLeft
                  absolute
                  onClick={navigateToPrevCategory}
                  title={textToUpperCase(`${t('Back to')} ${t('categoriesT')}`)}
                />

                {categoryList?.size ? (
                  <>
                    <StyledCategoryContainer>
                      {categoryList?.map((category) => (
                        <StyledStoreSaleCategoryCard
                          key={category?.uuid}
                          category={category}
                          navigateToCategory={navigateToCategory}
                        />
                      ))}
                    </StyledCategoryContainer>
                    <LoadingMoreButton
                      isLoadingMore={categoryListIsLoadingMore}
                      handleLoadingMore={handleLoadMoreProductCategories}
                    />
                  </>
                ) : (
                  <StyledCategoryEmptyContainer>
                    <StyledEmpty
                      image={
                        <TbCategory size={100} color={theme.colors.disabled} />
                      }
                      description={t('There are no product category created')}
                    />
                  </StyledCategoryEmptyContainer>
                )}
                <ListActionsContainer>
                  <ListActions
                    disabled={!store}
                    onFocus={() => {
                      if (keyboardShow) {
                        setWithKeyboard(false);
                      }
                    }}
                    onBlur={(e) => {
                      if (typeof withKeyboard === 'boolean' && keyboardShow) {
                        e.preventDefault();
                        e.stopPropagation();
                        e?.target?.focus();
                        return;
                      }
                    }}
                    className="store-sell-product-page"
                    withoutPicker
                    value={keywords}
                    loading={loading}
                    handleSearch={async () => {}}
                    onChange={async (e) => onSearch(e?.target?.value)}
                    inputTooltip={t('Search a product')}
                    inputLabel={t('Search a product')}
                    withSearchEndContent={
                      <Button
                        fitContent
                        disabled={!store}
                        type="text"
                        onClick={() => {
                          handleToggleKeyboard();
                          focus();
                          setWithKeyboard(false);
                        }}>
                        {keyboardShow ? (
                          <TbKeyboard size={20} color={theme.colors.black} />
                        ) : (
                          <TbKeyboardOff size={20} color={theme.colors.black} />
                        )}
                      </Button>
                    }
                  />
                </ListActionsContainer>
                <Spin spinning={productListLoadingMore}>
                  {productList?.size ? (
                    <>
                      <StyledContainer>
                        {productList?.map((product) => (
                          <StoreSaleProductCard
                            key={product?.uuid}
                            product={product}
                            documentItem={
                              storeDocumentItemList[getItemIndex(product)]
                            }
                            onClick={handleAddOrRemoveProduct}
                            selected={isSelected(product)}
                            disabled={
                              concurrentQueueGUI.current.processProductUuid ===
                                product?.uuid ||
                              concurrentQueueDeleteGUI?.current
                                ?.processProductUuid === product?.uuid
                            }
                          />
                        ))}
                      </StyledContainer>

                      <LoadingMore
                        loading={productListIsLoadingMore}
                        observerCallback={handleLoadMoreProducts}
                      />
                    </>
                  ) : (
                    <StyledProductEmptyContainer>
                      <StyledEmpty
                        $error={!store}
                        image={
                          <MdOutlineProductionQuantityLimits
                            size={100}
                            color={theme.colors.disabled}
                          />
                        }
                        description={
                          !store
                            ? t(
                                'To search for goods, you need to select a warehouse',
                              )
                            : `${t(
                                'There are no products created for the store',
                              )}: "${store?.store_name}"`
                        }
                      />
                    </StyledProductEmptyContainer>
                  )}
                </Spin>
              </StyledContent>
            </ListLayout>
          }
        />
        <Route path={`:categoryId/*`} element={<StoreSaleCategoryGUIPage />} />
      </Routes>
    </>
  );
}
