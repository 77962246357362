import React from 'react';
import { Button } from 'antd';
import styled, { useTheme, css } from 'styled-components';
import { RightOutlined, LockOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  StyledTitle,
  StyledDescription,
  StyledEllipsis,
} from '@components/lib/Styled';

export default withTranslation()(AuthRouteItem);

export interface IAuthRouteItemProps extends WithTranslation {
  key: string;
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  description?: string;
  textAlign?: 'left' | 'center' | 'right';
  navigation?: () => void;
  hiddenArrow?: boolean;
  buttonIcon?: React.ReactNode;
  mode?: 'light' | 'dark';
  disabled?: boolean;
}

const StyledContainer = styled(Button)<{
  mode?: 'light' | 'dark';
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 0;

  ${({ disabled }) =>
    !disabled &&
    css`
      &,
      & span {
        color: ${({ theme }) => theme.colorsV2.linkColor};
      }

      &:hover,
      &:active,
      &:focus {
        opacity: 0.7;
        background-color: unset !important;
        &,
        & span {
          color: ${({ theme }) => theme.colorsV2.corporatePrimary};
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `}

  ${({ mode }) =>
    mode === 'light' &&
    css`
      //border-bottom-color: rgba(255, 255, 255, 0.2);

      &:hover,
      &:active,
      &:focus {
        //border-bottom-color: rgba(255, 255, 255, 0.2);
      }
    `}
`;

const StyledRightOutlined = styled(RightOutlined)<{ mode?: 'light' | 'dark' }>`
  color: ${({ theme }) => theme.colors.primary};

  ${({ mode }) =>
    mode === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}
`;

const StyledLockOutlined = styled(LockOutlined)`
  color: ${({ theme }) => theme.colors.disabled};
  font-size: 18px;
`;

const StyledTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`;

const StyledTitleContainer = styled.div<{
  align?: 'left' | 'right' | 'center';
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  width: 100%;
  overflow: hidden;

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;

function AuthRouteItem({
  title,
  icon,
  textAlign,
  description,
  navigation,
  hiddenArrow,
  buttonIcon,
  t,
  mode = 'dark',
  disabled,
}: IAuthRouteItemProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledContainer
      type="text"
      onClick={navigation}
      disabled={!navigation || disabled}
      mode={mode}>
      <StyledTextContent>
        <StyledTitleContainer align={textAlign || 'left'}>
          {icon || null}&nbsp;&nbsp;
          <StyledEllipsis>
            <StyledTitle mode={mode}>
              {typeof title === 'string' ? t(title) : title}
            </StyledTitle>
          </StyledEllipsis>
        </StyledTitleContainer>
        {description ? (
          <StyledEllipsis color={theme.colors.disabled}>
            <StyledDescription mode={mode}>{t(description)}</StyledDescription>
          </StyledEllipsis>
        ) : null}
      </StyledTextContent>
      {hiddenArrow ? null : disabled ? (
        <StyledLockOutlined />
      ) : (
        buttonIcon || <StyledRightOutlined mode={mode} />
      )}
    </StyledContainer>
  );
}
