import { setCookie } from '../helpers';
import { domainUrl } from '../const';

export class tokenHandler {
  // token identifier we use to handle it
  private static ACCESS_TOKEN = 'access_token';

  private static REFRESH_TOKEN = 'refresh_token';

  private static IS_VERIFY_EMAIL = 'is_verify_email';

  private static IS_ACTIVE_PLAN = 'is_active_plan';

  private static RESTORE_FROM_COOKIES_STATUS = 'restore_from_cookies_status';

  /**
   * @desc Set the token value
   * */
  static setAccessToken(token: string): void {
    window.localStorage.setItem(tokenHandler.ACCESS_TOKEN, token);
  }

  static setRefreshToken(token: string): void {
    window.localStorage.setItem(tokenHandler.REFRESH_TOKEN, token);
  }

  static setVerifyEmailStatus(verify: string): void {
    window.localStorage.setItem(tokenHandler.IS_VERIFY_EMAIL, verify);
  }

  static setActivePlan(verify: string): void {
    window.localStorage.setItem(tokenHandler.IS_ACTIVE_PLAN, verify);
  }

  static setRestoreFromCookiesStatus(status: string): void {
    window.localStorage.setItem(
      tokenHandler.RESTORE_FROM_COOKIES_STATUS,
      status,
    );
  }

  static setAuthTokens({
    access,
    refresh,
    is_verify_email,
  }: {
    access: string;
    refresh: string;
    is_verify_email: string;
  }): void {
    tokenHandler.setAccessToken(access);
    tokenHandler.setRefreshToken(refresh);
    tokenHandler.setVerifyEmailStatus(is_verify_email);

    setCookie(tokenHandler.ACCESS_TOKEN, access, {
      domain: domainUrl,
    });
    setCookie(tokenHandler.REFRESH_TOKEN, refresh, {
      domain: domainUrl,
    });

    setCookie(tokenHandler.IS_VERIFY_EMAIL, is_verify_email, {
      domain: domainUrl,
    });
  }

  /**
   * @desc Destroy the token value
   * */
  static destroyToken(): void {
    window.localStorage.removeItem(tokenHandler.ACCESS_TOKEN);
    window.localStorage.removeItem(tokenHandler.REFRESH_TOKEN);
    window.localStorage.removeItem('is_redirect_to_subscription');
    window.localStorage.removeItem('is_verify_email');
    window.localStorage.removeItem('is_active_plan');

    tokenHandler.setRestoreFromCookiesStatus('-1');
  }

  /**
   * @desc Get the token value
   * @return {String}
   * */
  static getAccessToken(): string | null {
    return window.localStorage.getItem(tokenHandler.ACCESS_TOKEN);
  }

  static getRefreshToken(): string | null {
    return window.localStorage.getItem(tokenHandler.REFRESH_TOKEN);
  }

  static getVerifyEmailStatus(): string | null {
    return window.localStorage.getItem(tokenHandler.IS_VERIFY_EMAIL);
  }

  static getActivePlan(): string | null {
    return window.localStorage.getItem(tokenHandler.IS_ACTIVE_PLAN);
  }

  static getRestoreFromCookiesStatus(): string | null {
    return window.localStorage.getItem(
      tokenHandler.RESTORE_FROM_COOKIES_STATUS,
    );
  }
}
