import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { IUseStateServiceListReturnType } from '@hooks';

const ServicesSideWindowLazy = React.lazy(
  () => import('../Show/ServicesSideWindow'),
);

export interface IServicesUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateServiceListReturnType['handleUpdateService'];
  onCancel?: () => void;
  companyUuid: string;
  service: ServiceFormDTO;
}

export default function ServicesUpdateButton({
  children,
  companyUuid,
  onSuccess,
  onCancel,
  loading,
  service,
  ...rest
}: IServicesUpdateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit company service')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      {visible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ServicesSideWindowLazy
            editMode
            service={service}
            companyUuid={companyUuid}
            onSuccess={handleSuccess}
            visible={visible}
            onCancel={handleCancel}
          />
        </Suspense>
      ) : null}
    </>
  );
}
