import { Record, List } from 'immutable';
import { IListProps } from './List';
import { ReportDTO, ReportModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IReportListModelProps extends IListProps {
  reports: ReportDTO[];
}

export interface IReportListModelReturnType extends IListProps {
  reports: List<ReportModel>;
}

export class ReportListModel extends Record<IReportListModelReturnType>({
  reports: List(),
  total: 0,
}) {
  constructor(
    {
      reports = [],
      ...props
    }: IReportListModelProps = {} as IReportListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IReportListModelReturnType = {
      ...props,
      reports: List(
        reports.map((report) => new ReportModel(report, withoutRecursion)),
      ),
    };

    super(options);
  }
}
