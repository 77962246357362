import styled, { css } from 'styled-components';
import { ListSegmented } from '@components/lib/DataDisplay';
import { PaymentScheduleOperationType } from '@services/api/orders';
import { CreatePaymentButton } from '../Buttons';

export const StyledListActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SegmentedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;

  width: 100%;
`;

export const StyledListSegmented = styled(ListSegmented)<{
  value: PaymentScheduleOperationType;
}>`
  ${({ value }) =>
    value === PaymentScheduleOperationType.In &&
    css`
      &&& {
        .ant-segmented-item.ant-segmented-item-selected {
          background-color: ${({ theme }) => theme.colors.success};
        }
      }
    `}

  ${({ value }) =>
    value === PaymentScheduleOperationType.Out &&
    css`
      &&& {
        .ant-segmented-item.ant-segmented-item-selected {
          background-color: ${({ theme }) => theme.colors.error};
        }
      }
    `}
`;

export const StyledCreatePaymentButton = styled(CreatePaymentButton)`
  background-color: ${({ theme }) => theme.colors.success};

  &&& {
    &:focus,
    &:active,
    &:hover {
      background-color: ${({ theme }) => theme.colors.success};
      opacity: 0.7;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
