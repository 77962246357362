import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { useModal } from '@components/lib/libV2/hooks';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StoreDocumentFormDTO } from '@structure';
import { IUseStateStoredDocumentReturnType } from '@hooks';

const StorePreorderSideWindowLazy = React.lazy(
  () => import('../Show/StorePreorderSideWindow'),
);

export interface IStorePreorderCreateButtonProps
  extends Omit<IActionButtonProps, 'children'>,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  children?: React.ReactNode;
  onSuccess:
    | IUseStateStoredDocumentReturnType['handleCreateOrUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
}

export function StorePreorderCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  handlePaymentStoreDocument,
  ...rest
}: IStorePreorderCreateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create invoice for order to the provider')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StorePreorderSideWindowLazy
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
