import React, { useEffect } from 'react';
import { getRootKey, isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IAuthRouteItemProps } from '../../Show';
import { WithTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { IDefaultTabPanelProps } from '@components/lib/General';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface ISubscriptionExpiredTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export enum SUBSCRIPTION_EXPIRED_TABS {
  Entry = 'Entry',
}

export function SubscriptionExpiredTabPanel({
  children,
}: ISubscriptionExpiredTabPanelProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [routes, setRoutes] = React.useState<any>([]);

  useEffect(() => {
    setRoutes([
      // {
      //   key: SUBSCRIPTION_EXPIRED_TABS.Entry,
      //   title: SUBSCRIPTION_EXPIRED_TABS.Entry,
      //   icon: <LoginOutlined size={18} />,
      //   navigation: () => {
      //     navigate(`/${Routes.auth}/${Routes.login}`);
      //   },
      // },
    ]);
  }, [location?.state?.backPageName, location?.state?.from, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('SubscriptionExpiredTabPanel');
  }

  return <>{children({ tabs: routes })}</>;
}
