import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import { ENTER_KEYS } from '@services/const';

export interface IUseKeyboardOpenFormProps {
  className: string;
  disabled: boolean;
}

export interface IUseKeyboardOpenFormReturnType {
  ref: RefObject<any>;
}

export function useKeyboardOpenForm({
  className,
  disabled,
}: IUseKeyboardOpenFormProps): IUseKeyboardOpenFormReturnType {
  const ref = useRef<any>(null);

  const onKeyPress = useCallback((e: KeyboardEvent) => {
    // if (e?.target instanceof HTMLElement && ENTER_KEYS.includes(e?.code) {
    //   const button = document.body.querySelector(`.${className}`);
    //
    //   if (button instanceof HTMLElement) {
    //     const clickEvent = new MouseEvent('click', {
    //       bubbles: true,
    //       cancelable: true,
    //     });
    //
    //     e?.target?.blur();
    //     // button?.dispatchEvent(clickEvent);
    //   }
    // }
  }, []);

  useEffect(() => {
    if (ref?.current && !disabled) {
      document.addEventListener('keydown', onKeyPress);

      return () => {
        document.removeEventListener('keydown', onKeyPress);
      };
    }
  }, [disabled, onKeyPress, ref]);

  return { ref };
}
