import { Dispatch } from 'redux';
import { EMPLOYEE_SIGNUP_PAGE_STATE } from '../constants';
import { LocalStorageItems } from '@services/const';
import { decryptObject, encryptObject } from '@services/helpers';

export interface IEmployeeSignupPageState {
  identifier: string | null;
  approveEnter: boolean | null;
  timeWhenCodeWasSend: Date | null;
  loading: boolean;
  isShowWarning: boolean;
  prevIdentifier: string | null;
}

export const employeeSignupPageInitialState = {
  identifier: null,
  prevIdentifier: null,
  approveEnter: null,
  timeWhenCodeWasSend: null,
  loading: true,
  isShowWarning: true,
};

export interface ISetEmployeeSignupPageStateParam {
  type: EMPLOYEE_SIGNUP_PAGE_STATE;
}

export interface IUpdateEmployeeSignupPageStateParam {
  type: EMPLOYEE_SIGNUP_PAGE_STATE;
  state: Partial<IEmployeeSignupPageState>;
}

export interface IResetEmployeeSignupPageStateParam {
  type: EMPLOYEE_SIGNUP_PAGE_STATE;
}

export function setEmployeeSignupPageState(): (
  dispatch: Dispatch,
) => Promise<ISetEmployeeSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    const cryptoState = localStorage.getItem(
      LocalStorageItems.verificationDataEmployeeSignup,
    );

    let state = {};

    if (cryptoState) {
      state = decryptObject(cryptoState);
    }

    return dispatch({
      type: EMPLOYEE_SIGNUP_PAGE_STATE.SET_STATE,
      state: state
        ? { ...state, isShowWarning: true }
        : employeeSignupPageInitialState,
    });
  };
}

export function updateEmployeeSignupPageState(
  state: Partial<IEmployeeSignupPageState>,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    const encryptEmployeeSignupPrevState = localStorage.getItem(
      LocalStorageItems.verificationDataEmployeeSignup,
    );

    if (encryptEmployeeSignupPrevState) {
      const employeeSignupPrevState = decryptObject(
        encryptEmployeeSignupPrevState,
      );
      if (employeeSignupPrevState) {
        const cryptoNewState = encryptObject({
          ...employeeSignupPrevState,
          ...state,
        });

        localStorage.setItem(
          LocalStorageItems.verificationDataEmployeeSignup,
          cryptoNewState,
        );
      }
    } else {
      const cryptoState = encryptObject(state);

      localStorage.setItem(
        LocalStorageItems.verificationDataEmployeeSignup,
        cryptoState,
      );
    }

    return dispatch({
      type: EMPLOYEE_SIGNUP_PAGE_STATE.UPDATE_STATE,
      state,
    });
  };
}

export function resetEmployeeSignupPageState(): (
  dispatch: Dispatch,
) => Promise<IResetEmployeeSignupPageStateParam> {
  return async function (dispatch: Dispatch) {
    localStorage.removeItem(LocalStorageItems.verificationDataEmployeeSignup);

    return dispatch({
      type: EMPLOYEE_SIGNUP_PAGE_STATE.RESET_STATE,
      state: employeeSignupPageInitialState,
    });
  };
}
