import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { ProductCategoryModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IProductCategoriesDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  categories: ProductCategoryModel[];
}

export default function ProductCategoriesDeleteButton({
  children,
  categories,
  onSuccess,
  onError,
  title,
  ...rest
}: IProductCategoriesDeleteButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: categories,
    notifyTitle: 'Material categories',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete material categories')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(categories)} ${
            len(categories)
              ? t('category').toLowerCase()
              : t('categories').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
