import * as React from 'react';
import { acceptPolicy } from '@services/api/user';
import { useTranslation } from 'react-i18next';
import { useStoredAuthData } from './useStoredAuthData';
import { StatusError } from '@components/lib/Errors';
import { useDropdownAlert } from '@contex';

export interface IUseTermsPolicyReturnType {
  handleAccept: () => Promise<void>;
  notifyError: (error: any) => void;
}

export function useTermsPolicy(): IUseTermsPolicyReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const { authRedirect, authData } = useStoredAuthData();

  const notifyError = React.useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Terms and Policy'),
        `${t('An error occurred during accept Terms and Policy')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  const handleAccept = React.useCallback(async (): Promise<void> => {
    if (authData?.access_token) {
      const authDataDTO = await acceptPolicy(authData?.access_token);

      if (authDataDTO?.success) {
        await authRedirect({
          ...authDataDTO,
        });
        alert(
          'success',
          t('Terms and Policy'),
          `${t('Accept Terms and Policy successful')}`,
        );
      }
    } else {
      throw new StatusError(
        t('Access token not found, try signing in with a new one'),
        404,
      );
    }
  }, [alert, authData?.access_token, authRedirect, t]);

  return {
    handleAccept,
    notifyError,
  };
}
