import React from 'react';
import { StyledTitle } from '@components/lib/Styled';
import { correctPrice } from '@services/helpers';
import styled from 'styled-components';
import { DetailsView } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';

import { StoreDocumentModel } from '@structure';

export interface InvoiceStoreRealizationTotalViewProps {
  document?: StoreDocumentModel;
  className?: string;
}

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  //margin-bottom: 20px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
`;

export function InvoiceStoreRealizationTotalView({
  document,
  className,
}: InvoiceStoreRealizationTotalViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  return (
    <StyledDetailsView titles={[]} className={className}>
      <StyledStatsContainer>
        <StyledTitle fontSize={15} bold>{`${t('Total')}, ${
          defaultCompany?.currency_symbol || ''
        }: ${correctPrice(document?.doc_sum_total || 0)}`}</StyledTitle>
      </StyledStatsContainer>
    </StyledDetailsView>
  );
}
