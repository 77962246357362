import React, { useCallback, useState } from 'react';
import {
  DownOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { tableScreen } from '@services/const';
import { StyledTitle } from '@components/lib/Styled';
import { IUseStateStoredDocumentReturnType } from '@hooks';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import { Button } from '@components/lib/DataDisplay';
import { Dropdown, MenuProps, Space } from 'antd';
import {
  HiOutlineClipboardDocumentList,
  HiOutlineDocument,
} from 'react-icons/hi2';

export interface StoreDocumentPrintButtonProps
  extends Pick<
    IUseStateStoredDocumentReturnType,
    'document' | 'handlePrintStoreDocument' | 'handlePrintStoreDocumentCheck'
  > {
  contentWidth: number;
}

const StyledPrinterOutlined = styled(PrinterOutlined)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  height: fit-content;
  min-width: 44px;

  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({ theme }) => theme.colors.black};
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.black};
`;

const StyledDropdown = styled(Dropdown)<{ disabled?: boolean }>`
  height: fit-content;
  min-width: 44px;
  min-height: 44px;

  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({ theme }) => theme.colors.black};
    opacity: 0.8;
  }
`;

enum StoreDocumentPrintType {
  DOCUMENT = 'document',
  CHECK = 'check',
  UNKNOWN = 'unknown',
}

export function StoreDocumentPrintButton({
  document,
  handlePrintStoreDocument: onPrintStoreDocument,
  handlePrintStoreDocumentCheck: onPrintStoreDocumentCheck,
  contentWidth,
}: StoreDocumentPrintButtonProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [loading, setLoading] = useState<StoreDocumentPrintType>(
    StoreDocumentPrintType.UNKNOWN,
  );

  const handlePrintStoreDocument = useCallback(async () => {
    setLoading(StoreDocumentPrintType.DOCUMENT);
    await onPrintStoreDocument();
    setLoading(StoreDocumentPrintType.UNKNOWN);
  }, [onPrintStoreDocument]);

  const handlePrintStoreDocumentCheck = useCallback(async () => {
    setLoading(StoreDocumentPrintType.CHECK);
    await onPrintStoreDocumentCheck();
    setLoading(StoreDocumentPrintType.UNKNOWN);
  }, [onPrintStoreDocumentCheck]);

  const documentItems: MenuProps['items'] = [
    {
      key: 'Print document',
      icon:
        loading === StoreDocumentPrintType.DOCUMENT ? (
          <LoadingOutlined />
        ) : (
          <HiOutlineDocument size={15} color={theme.colors.black} />
        ),
      label: <StyledTitle>{t('Print document')}</StyledTitle>,
      onClick: onPrintStoreDocument,
    },
    {
      key: 'Print check',
      icon:
        loading === StoreDocumentPrintType.CHECK ? (
          <LoadingOutlined />
        ) : (
          <HiOutlineClipboardDocumentList
            size={15}
            color={theme.colors.black}
          />
        ),
      label: <StyledTitle>{t('Print check')}</StyledTitle>,
      onClick: handlePrintStoreDocumentCheck,
    },
  ];

  return (
    <>
      {document?.doc_is_checkable ? (
        <StyledDropdown
          menu={{ items: documentItems }}
          placement="topLeft"
          trigger={['click']}>
          <Space direction="horizontal" size="middle">
            <Space direction="horizontal" size="small">
              {loading !== StoreDocumentPrintType.UNKNOWN ? (
                <LoadingOutlined />
              ) : (
                <StyledPrinterOutlined />
              )}
              {contentWidth >= tableScreen ? (
                <StyledTitle fontSize={14}>{t('Print')}</StyledTitle>
              ) : null}
            </Space>

            <StyledDownOutlined />
          </Space>
        </StyledDropdown>
      ) : (
        <StyledButton
          onClick={handlePrintStoreDocument}
          icon={
            loading !== StoreDocumentPrintType.UNKNOWN ? (
              <LoadingOutlined />
            ) : (
              <StyledPrinterOutlined />
            )
          }>
          {contentWidth >= tableScreen ? (
            <StyledTitle fontSize={14}>{t('Print document')}</StyledTitle>
          ) : null}
        </StyledButton>
      )}
    </>
  );
}
