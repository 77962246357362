import { List } from 'immutable';
import {
  AbonementGroupDTO,
  AbonementGroupFormDTO,
  IAbonementGroupDTOProps,
  AbonementGroupModel,
  AbonementGroupListModel,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IAbonementGroupListDTO {
  groups: AbonementGroupDTO[];
  total: number;
}

export class AbonementGroupMapper {
  public static toAbonementGroupDTO(
    group: AbonementGroupModel | IAbonementGroupDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementGroupDTO {
    return new AbonementGroupDTO(group, [
      WithoutRecursion.group,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementGroupListDTO(
    groups: List<AbonementGroupModel> | IAbonementGroupDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IAbonementGroupListDTO {
    const groupList = List.isList(groups)
      ? listToArray<AbonementGroupModel>(groups)
      : groups;

    return {
      groups: groupList?.map((group) =>
        AbonementGroupMapper.toAbonementGroupDTO(group, [
          WithoutRecursion.group,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toAbonementGroupModel(
    groupDTO: AbonementGroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementGroupModel {
    return new AbonementGroupModel(groupDTO, [
      WithoutRecursion.group,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementGroupListModel(
    groupDTOs: AbonementGroupDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementGroupListModel {
    return new AbonementGroupListModel({ groups: groupDTOs, total }, [
      WithoutRecursion.group,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementGroupFormDTO(
    groupModel: AbonementGroupModel,
    editMode?: boolean,
  ): AbonementGroupFormDTO {
    return new AbonementGroupFormDTO(groupModel, editMode);
  }
}
