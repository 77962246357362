import { Dispatch } from 'redux';
import { APP_STATE } from '../constants';

export interface ISetInitialAppState {
  type: APP_STATE;
}

export function setInitialAppState(): (
  dispatch: Dispatch,
) => Promise<ISetInitialAppState> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: APP_STATE.SET_INITIAL_STATE,
    });
  };
}
