import { List } from 'immutable';
import { APP_STATE, PLAN_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { PlanModel } from '@structure';

interface IPlanListState {
  planList: List<PlanModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
}

interface SetActionList
  extends Pick<IPlanListState, 'planList' | 'keywords' | 'total'> {
  type: PLAN_LIST.SET_PLAN_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PLAN_LIST.ADD_PLAN;
  plan: PlanModel;
}

interface UpdatePlanFromList {
  type: PLAN_LIST.UPDATE_PLAN;
  plan: PlanModel;
}

interface DeleteActionFromList {
  type: PLAN_LIST.DELETE_PLAN;
  planUuid: string;
}

interface LoadingActionInList {
  type: PLAN_LIST.LOADING_PLAN_LIST;
}

interface ErrorActionInList extends Pick<IPlanListState, 'error'> {
  type: PLAN_LIST.ERROR_PLAN_LIST;
}

interface Handlers {
  [PLAN_LIST.SET_PLAN_LIST]: (
    state: IPlanListState,
    action: SetActionList,
  ) => IPlanListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IPlanListState,
    action: SetInitialStateAction,
  ) => IPlanListState;

  [PLAN_LIST.LOAD_MORE_PLAN_LIST]: (
    state: IPlanListState,
    action: SetActionList,
  ) => IPlanListState;

  [PLAN_LIST.ADD_PLAN]: (
    state: IPlanListState,
    action: AddActionToList,
  ) => IPlanListState;

  [PLAN_LIST.UPDATE_PLAN]: (
    state: IPlanListState,
    action: UpdatePlanFromList,
  ) => IPlanListState;

  [PLAN_LIST.DELETE_PLAN]: (
    state: IPlanListState,
    action: DeleteActionFromList,
  ) => IPlanListState;

  [PLAN_LIST.ERROR_PLAN_LIST]: (
    state: IPlanListState,
    value: ErrorActionInList,
  ) => IPlanListState;

  [PLAN_LIST.LOADING_PLAN_LIST]: (
    state: IPlanListState,
    value?: LoadingActionInList,
  ) => IPlanListState;
  DEFAULT: (state: IPlanListState) => IPlanListState;
}

const initState: IPlanListState = {
  planList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [PLAN_LIST.SET_PLAN_LIST]: (state, { planList, keywords, total }) => ({
    ...state,
    ...{
      planList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PLAN_LIST.LOAD_MORE_PLAN_LIST]: (state, { planList }) => ({
    ...state,
    ...{
      planList:
        List.isList(state.planList) && List.isList(planList)
          ? state.planList.merge(planList)
          : state.planList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PLAN_LIST.ADD_PLAN]: (state, { plan }) => ({
    ...state,
    ...{
      planList: List.isList(state.planList)
        ? state.planList.unshift(plan)
        : List([plan]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [PLAN_LIST.UPDATE_PLAN]: (state, { plan }) => ({
    ...state,
    ...{
      planList: List.isList(state.planList)
        ? state.planList.map((statePlan) => {
            if (statePlan.id === plan?.id) {
              return plan;
            }
            return statePlan;
          })
        : List([plan]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PLAN_LIST.DELETE_PLAN]: (state, { planUuid }) => ({
    ...state,
    ...{
      planList: List.isList(state.planList)
        ? state.planList.filter(compose(not, isEqualByUuid(planUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [PLAN_LIST.ERROR_PLAN_LIST]: (
    state: IPlanListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PLAN_LIST.LOADING_PLAN_LIST]: (state: IPlanListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IPlanListState) => state,
};

export default function PlanList(
  state: any = initState,
  action: any,
): IPlanListState {
  const handler = handlers[action.type as PLAN_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
