import {
  ClientGroupSessionDTO,
  ClientGroupSessionMapper,
  ClientMapper,
  CompanyDTO,
  CompanyMapper,
  EmployeeDTO,
  EmployeeMapper,
  IClientModelReturnType,
  IClientsGroupSessionDTOProps,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
} from '../internal';
import { correctPrice, GENDER, toDateByFormat } from '@services/helpers';
import { WithoutRecursion } from '../type';
import { DATE_SHORT_FORMAT } from '@services/const';
import { ClientPersonalityType } from '@services/types';

export enum ClientOrgType {
  PERSON = 'person',
  ORGANIZATION = 'organization',
}

export enum ClientDiscountType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export enum ClientSettlementType {
  ALL = '',
  CREDITOR = 'creditor',
  DEBTOR = 'debtor',
}

export enum ClientFileType {
  ALL = '',
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export interface IClientDTOProps {
  id: string;
  uuid: string;
  status: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  client_group_name: string;
  saldo: string;
  picture_url: string;
  address: string;
  birthday: string;
  gender: GENDER;
  invitation: any[];
  created_at: string;
  client_strict_mode: boolean;
  client_type: ClientPersonalityType;
  updated_at: string;
  profile: IProfileDTOProps;
  created_by: IEmployeeDTOProps;
  company: ICompanyDTOProps;
  clients_group_sessions: IClientsGroupSessionDTOProps[];
  members: IClientDTOProps[];
  client_org_type: ClientOrgType;
  client_org_name: string;
  client_org_code: string;
  client_org_tax_code: string;
  client_org_contact_person: string;
  client_org_address: string;
  client_org_manager: string;
  client_org_bank_name: string;
  client_org_bank_code: string;
  client_org_bank_account_id: string;
  client_is_provisioner: boolean;
  client_is_conflict: boolean;
  client_is_tav_payer: boolean;
  client_discount: string;
  client_discount_goods: string;
  client_discount_type: ClientDiscountType;
  client_discount_goods_type: ClientDiscountType;
  client_comment: string;
}

export class ClientDTO {
  public id: string;

  public uuid: string;

  public status: number;

  public first_name: string;
  public client_group_name: string;

  public last_name: string;
  public client_type: ClientPersonalityType;

  public middle_name: string;

  public phone: string;

  public email: string;

  public saldo: string;

  public picture_url: string;

  public address: string;

  public birthday: string;

  public gender: GENDER;

  public invitation: any[];

  public created_at: string;

  public updated_at: string;
  public client_strict_mode: boolean;

  public profile: IProfileDTOProps;
  public created_by: EmployeeDTO;

  public company: ICompanyDTOProps;

  public clients_group_sessions: ClientGroupSessionDTO[];

  public members: ClientDTO[];

  public client_org_type: ClientOrgType;

  public client_org_name: string;

  public client_org_code: string;

  public client_org_tax_code: string;

  public client_org_address: string;

  public client_org_manager: string;
  public client_org_contact_person: string;
  public client_org_bank_name: string;
  public client_org_bank_code: string;
  public client_org_bank_account_id: string;
  public client_is_provisioner: boolean;
  public client_is_conflict: boolean;
  public client_is_tav_payer: boolean;
  public client_discount: string;
  public client_discount_goods: string;
  public client_discount_type: ClientDiscountType;
  public client_discount_goods_type: ClientDiscountType;
  public client_comment: string;

  constructor(
    props: IClientModelReturnType | IClientDTOProps,
    withoutMembers: boolean,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.id = props?.id || '';
    this.uuid = props?.uuid || '';
    this.status = props?.status || 0;
    this.first_name = props?.first_name || '';
    this.client_group_name = props?.client_group_name || '';
    this.last_name = props?.last_name || '';
    this.middle_name = props?.middle_name || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.client_type = props?.client_type || ClientPersonalityType.Individual;
    this.saldo = correctPrice(props?.saldo || 0);
    this.picture_url = props?.picture_url || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday
      ? toDateByFormat(props?.birthday, DATE_SHORT_FORMAT)
      : '';
    this.gender = props?.gender || GENDER.MALE;
    this.invitation = props?.invitation || [];
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.client_strict_mode = props?.client_strict_mode || false;
    this.profile =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.profile as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.profile, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.created_by, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);

    this.clients_group_sessions =
      ClientGroupSessionMapper.toClientGroupSessionListDTO(
        props?.clients_group_sessions || [],
      );

    this.members = withoutMembers
      ? []
      : withoutRecursion.indexOf(WithoutRecursion.client) !== -1
      ? (props?.members as ClientDTO[])
      : ClientMapper.toClientListDTO(
          props?.members,
          (props?.members as any)?.length || 0,
          true,
        ).clients;
    this.client_org_type = props?.client_org_type || ClientOrgType.PERSON;
    this.client_org_name = props?.client_org_name || '';
    this.client_org_code = props?.client_org_code || '';
    this.client_org_tax_code = props?.client_org_tax_code || '';
    this.client_org_contact_person = props?.client_org_contact_person || '';
    this.client_org_address = props?.client_org_address || '';
    this.client_org_manager = props?.client_org_manager || '';
    this.client_org_bank_name = props?.client_org_bank_name || '';
    this.client_org_bank_code = props?.client_org_bank_code || '';
    this.client_org_bank_account_id = props?.client_org_bank_account_id || '';
    this.client_is_provisioner =
      typeof props?.client_is_provisioner === 'boolean'
        ? props?.client_is_provisioner
        : false;
    this.client_is_tav_payer =
      typeof props?.client_is_tav_payer === 'boolean'
        ? !!props?.client_is_tav_payer
        : false;
    this.client_is_conflict =
      typeof props?.client_is_conflict === 'boolean'
        ? props?.client_is_conflict
        : false;

    this.client_discount = props?.client_discount ?? '0';
    this.client_discount_goods = props?.client_discount_goods ?? '0';
    this.client_discount_type =
      props?.client_discount_type ?? ClientDiscountType.Fixed;
    this.client_discount_goods_type =
      props?.client_discount_goods_type ?? ClientDiscountType.Fixed;
    this.client_comment = props?.client_comment || '';
  }
}
