import { MenuProps } from 'antd';
import { ClientHeader } from '../Show';
import { FaUsers } from 'react-icons/fa';
import { useAcl } from '@contex';
import styled from 'styled-components';
import React, { Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ClientTabPanel from '../Show/ClientTabPanel';
import { textToUpperCase } from '@services/helpers';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewLayout } from '@components/lib/Layout';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DocumentControlPanel } from '@components/lib/General';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';
import {
  CommentField,
  HeaderLeft,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';

import {
  ClientGroupFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
} from '@structure';

import {
  useRequiredFields,
  useStopLoading,
  useStoredClient,
  useStoredCompanies,
} from '@hooks';

import {
  CLIENT_LEGAL_REQUIRED_FIELD,
  CLIENT_REQUIRED_FIELD,
} from '@services/api/client';

import { ClientPersonalityType, Routes, RoutesAcl } from '@services/types';

const ClientSideWindowLazy = React.lazy(
  () => import('../Show/ClientSideWindow'),
);

const ClientGroupSideWindowLazy = React.lazy(
  () => import('../Show/ClientGroupSideWindow'),
);

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default function ClientPage(): JSX.Element {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ client }) => client);
  const { client: clientAccess } = useAcl((acl) => acl);

  const {
    client,
    error: clientError,
    handleUpdateClient,
    loading: clientLoading,
    handleUploadClientImage,
    handleUpdateClientGroup,
    handleUpdateClientComment,
  } = useStoredClient({
    clientUuid: clientId!,
    companyUuid: defaultCompanyUuid,
  });

  const isIndividualType =
    client?.client_type === ClientPersonalityType.Individual;

  const isGroupType = client?.client_type === ClientPersonalityType.Group;

  const loading = useStopLoading({
    loading: clientLoading,
    error: clientError,
    message: 'An error occurred during client loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: isIndividualType ? 'Counterparties' : 'Clients groups',
      state: {
        type: isIndividualType
          ? ClientPersonalityType.Individual
          : ClientPersonalityType.Group,
      },
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: isIndividualType
        ? client?.fullNameClient || 'Show Client'
        : client?.client_group_name || 'Show clients group',
    },
  ]);

  const changeFields = useCallback(
    (
      {
        client_discount_type,
        client_discount_goods_type,
        client_org_type,
        client_comment,
        client_org_name,
        ...rest
      }: any,
      client: ClientModel | null,
    ) => {
      return {
        ...rest,
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    client,
    client?.client_org_type === ClientOrgType.PERSON
      ? CLIENT_REQUIRED_FIELD
      : CLIENT_LEGAL_REQUIRED_FIELD,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.clients}`, {
      state: {
        type: isIndividualType
          ? ClientPersonalityType.Individual
          : ClientPersonalityType.Group,
      },
    });
  }, [isIndividualType, location?.state?.goBack, navigate]);

  const {
    handleCancel: clientHandleCancel,
    handleOnInit: clientHandleOnInit,
    handleSuccess: clientHandleSuccess,
    visible: clientVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateClient,
  });

  const {
    handleCancel: groupHandleCancel,
    handleOnInit: groupHandleOnInit,
    handleSuccess: groupHandleSuccess,
    visible: groupVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateClientGroup,
  });

  const documentOptions: MenuProps['items'] = [];
  const printOptions: MenuProps['items'] = [];

  return (
    <>
      <ClientTabPanel
        client={client}
        isIndividualType={isIndividualType}
        isGroupType={isGroupType}
        clientFullName={client?.fullNameClient || ''}
        timeRange={client ? client?.company?.work_schedules : ''}
        handleUpdateClientGroup={handleUpdateClientGroup}>
        {({ tabs }) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.clients]}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={
                  client?.client_type! === ClientPersonalityType.Individual
                    ? clientHandleOnInit
                    : groupHandleOnInit
                }
                isDraft={true}
              />
            }
            headerTitle={textToUpperCase(
              isIndividualType ? t('Counterparties') : t('Client group'),
            )}
            disabled={!manage}
            loading={loading}
            data={isIndividualType ? overviewData : null}
            routes={routes}
            tabs={tabs}
            header={
              <>
                {isIndividualType ? (
                  <ClientHeader
                    client={client}
                    handleUploadImage={handleUploadClientImage}
                  />
                ) : (
                  <StyledTitleContainer>
                    <FaUsers size={20} />
                    <StyledTitle bold fontSize={20}>
                      {client?.client_group_name}
                    </StyledTitle>
                  </StyledTitleContainer>
                )}
                <CommentField
                  comment={client?.client_comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateClientComment}
                  disabled={!clientAccess?.manage}
                />
              </>
            }
          />
        )}
      </ClientTabPanel>

      {clientVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ClientSideWindowLazy
            editMode
            onSuccess={clientHandleSuccess}
            onCancel={clientHandleCancel}
            client={
              client?.client_org_type === ClientOrgType.PERSON
                ? ClientMapper.toClientFormDTO(client!)
                : ClientMapper.toClientLegalFormDTO(client as ClientModel)
            }
            visible={clientVisible}
          />
        </Suspense>
      ) : null}

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientGroupSideWindowLazy
          editMode
          onSuccess={groupHandleSuccess}
          onCancel={groupHandleCancel}
          client={
            ClientMapper.toClientGroupFormDTO(
              client!,
              true,
            ) as ClientGroupFormDTO
          }
          visible={groupVisible}
        />
      </Suspense>
    </>
  );
}
