import {
  AbonementModel,
  EmployeeModel,
  IAbonementTimingList,
  MaxPausedPeriodType,
  ServiceModel,
} from '../internal';
import {
  correctPrice,
  fromBooleanToNumber,
  head,
  last,
} from '@services/helpers';

export class AbonementFormDTO {
  public uuid: string;
  public title: string;
  public service_uuid: string | ServiceModel;
  public manager_uuid: string | EmployeeModel;
  public price: string;
  public session_price: string;
  public max_paused_period_type: MaxPausedPeriodType;
  public timing: string;
  public group_schedule: string;
  public comment: string;
  public period_amount: number;
  public visiting_limit: number;
  public can_be_paused: boolean;
  public max_paused_period: number;
  public can_timing_be_limited: boolean;
  public can_visiting_be_limited: boolean;
  public can_move_unused_visiting: boolean;
  public manager_required: boolean;
  public can_refund_money: boolean;

  constructor(props?: AbonementModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.title = props?.abon_title || '';
    this.service_uuid = editMode
      ? props?.serviceModel || ''
      : props?.serviceModel?.uuid || '';
    this.manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : props?.managerModel?.uuid || '';
    this.price = correctPrice(props?.abon_price || 0);
    this.session_price = correctPrice(props?.abon_session_price || 0);
    this.timing = AbonementFormDTO.getTimingList(props?.abon_timing_list);
    this.group_schedule = AbonementFormDTO.getTimingList(
      props?.abon_group_schedule,
    );
    this.comment = props?.abon_comment || '';
    this.max_paused_period_type =
      props?.abon_max_paused_period_type || MaxPausedPeriodType.Day;
    this.period_amount = props?.abon_period_amount || 1;
    this.visiting_limit =
      fromBooleanToNumber(props?.abon_can_visiting_be_limited) || NaN;
    this.can_be_paused = props?.abon_can_be_paused || false;
    this.can_timing_be_limited = props?.abon_can_timing_be_limited || false;
    this.can_visiting_be_limited = props?.abon_can_visiting_be_limited || false;
    this.can_move_unused_visiting =
      props?.abon_can_move_unused_visiting || false;
    this.manager_required = props?.abon_manager_required || false;
    this.can_refund_money =
      typeof props?.abon_can_refund_money === 'boolean'
        ? props?.abon_can_refund_money
        : true;
    this.max_paused_period = props?.abon_max_paused_period || NaN;
  }

  static getTimingWeekList(
    abon_timing_list?: IAbonementTimingList,
  ): string | void {
    const timingMap = Object.entries(abon_timing_list || {});

    if (timingMap.length === 7) {
      const isAllWeek = timingMap
        .map(([_, value]) => [value?.min, value?.max])
        .reduce((acc: any, [min, max]) => {
          acc[min] = (acc[min] || 0) + 1;
          acc[max] = (acc[min] || 0) + 1;

          return acc;
        }, {});

      const isAllWeekMap = Object.entries(isAllWeek);

      if (isAllWeekMap.length === 2) {
        const times = isAllWeekMap.map(([key]) => key);
        const start = head(times);
        const end = last(times);

        return `${start}-${end}`;
      }
    }
  }

  static getTimingList(abon_timing_list?: IAbonementTimingList) {
    const timingMap = Object.entries(abon_timing_list || {});
    const timingList = AbonementFormDTO.getTimingWeekList(abon_timing_list);

    if (timingList) {
      return timingList;
    } else {
      return timingMap.length
        ? timingMap
            .map(([key, value]) => {
              return `${key}|${value?.min}-${value?.max}`;
            })
            .join(',')
        : '';
    }
  }
}
