import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSize,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceTagList,
} from '@hooks';
import { textToUpperCase, isThereContent } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { IProductsListStatsProps, PriceTagMapper } from '@structure';
import { Routes as RoutesType, Routes, RoutesTitle } from '@services/types';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { useLocation, useNavigate } from 'react-router';
import { ListContent, ListEmpty } from '@components/lib/Layout';
import { PriceTagForm } from '../Forms';
import { List } from 'immutable';
import { AppHeader } from '@components/lib/Layout/AppHeader';

export interface IPriceTagListProps {
  onUpdateStats: (stats: IProductsListStatsProps, isPriceTag?: boolean) => void;
  onUpdateLoading: (loading: boolean) => void;
}

export function PriceTagListPage({
  onUpdateLoading,
  onUpdateStats,
}: IPriceTagListProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { t } = useTranslation();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);

  const {
    priceTagList,
    loading: loadingPriceTagList,
    error: errorPriceTagList,
    stats,
    total,

    handleDeletePriceTags,
    handleClearPriceTagList,
    handlePrintPriceTagList,
    handleCreateOrUpdatePriceTag,
  } = useStoredPriceTagList({
    companyUuid: defaultCompanyUuid,
    limit: 150,
  });

  const loading = useStopLoading({
    loading: loadingPriceTagList,
    error: errorPriceTagList,
    message: 'An error occurred during price tags loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${Routes.goods}/${Routes.priceTags}`,
      breadcrumbName: 'Price tags',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  useEffect(() => {
    if (isThereContent(stats)) {
      if (typeof stats?.product_price_tags_count === 'number') {
        onUpdateStats(stats, true);
      } else {
        onUpdateStats({ ...stats, product_price_tags_count: total });
      }
    }
  }, [onUpdateStats, stats, total]);

  useEffect(() => {
    onUpdateLoading(loading);
  }, [loading, onUpdateLoading]);

  useEffect(() => {
    if (location?.state?.autoPrint && priceTagList?.size && !loading) {
      window.history.replaceState({}, document.title);
      const submit = document.body.querySelector('.price-tag-submit');

      if (submit instanceof HTMLElement) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
        });

        submit?.dispatchEvent(clickEvent);
      }
    }
  }, [loading, location?.state?.autoPrint, priceTagList?.size]);

  return (
    <>
      <AppHeader
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.priceTags]))}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        routes={routes}
      />
      <div ref={contentRef}>
        <ListContent>
          <ListEmpty loading={loading}>
            <PriceTagForm
              loading={loading}
              items={
                List.isList(priceTagList)
                  ? PriceTagMapper.toPriceTagFormDTO(
                      priceTagList,
                      stats?.product_price_tags_count || total,
                    )
                  : null
              }
              contentWidth={contentWidth}
              handleDeletePriceTags={handleDeletePriceTags}
              handleClearPriceTagList={handleClearPriceTagList}
              handlePrintPriceTagList={handlePrintPriceTagList}
              handleCreateOrUpdatePriceTag={handleCreateOrUpdatePriceTag}
            />
          </ListEmpty>
        </ListContent>
      </div>
    </>
  );
}
