import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_INITIAL_PARAM } from '@services/api/orders';
import {
  toMomentString,
  isFunction,
  memoizeUnaryArity,
  toMoment,
} from '@services/helpers';
import { useEmployeeScheduleList, useStateCompanyClientList } from '@hooks';
import { ClientPersonalityType } from '@services/types';
import { ScheduleCalendarFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { OrderFormFields } from './OrderFormFields';
import { useNavigate } from 'react-router';

import './OrderFormFields.less';

export interface IOrderFormProps
  extends Omit<
    IDefaultFormProps<ScheduleCalendarFormDTO, ScheduleCalendarFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  order?: ScheduleCalendarFormDTO;
  companyUuid: string;
  disabledClient?: boolean;
  isOpenForm?: boolean;
  isHasStrictMode: boolean;
}

export interface IGetListEmployeeScheduleProps {
  date?: any;
  employeeUuid?: string;
}

export default function OrderForm({
  loading,
  editMode,
  order = ORDER_INITIAL_PARAM,
  companyUuid,
  onCancel,
  disabledClient,
  onSuccess,
  isHasStrictMode,
  ...rest
}: IOrderFormProps): JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const navigate = useNavigate();

  const [employeeScheduleList, setEmployeeScheduleList] = useState<any>(null);

  // const {
  //   employees,
  //   loading: employeesLoading,
  //   handleSearchEmployees,
  // } = useStateEmployeeList({
  //   companyUuid,
  // });

  const {
    refresh: employeeScheduleListRefresh,
    loading: employeeScheduleListLoading,
  } = useEmployeeScheduleList({
    employeeUuid: '',
    loadOnInit: false,
    limit: 100,
  });

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({ loadOnInit: true, companyUuid, limit: 100 });

  // const {
  //   employeeAttachedServiceList,
  //   loading: employeeAttachedServiceListLoading,
  //   loadingMore: employeeAttachedServiceListLoadingMore,
  //   handleRefreshStoreEmployeeAttachedServices,
  //   handleSearchEmployeeAttachedServices,
  // } = useStoredEmployeeAttachedServiceList({
  //   employeeUuid: '',
  //   loadOnInit: false,
  //   isOrderScreen: true,
  // });

  const memoizeEmployeeScheduleListRefresh = memoizeUnaryArity(
    employeeScheduleListRefresh,
    new Map<any, any>(),
  );

  const isLoading: boolean = !!loading;

  const additionalValues = useMemo(
    () => ({
      uuid: order?.uuid,
    }),
    [order?.uuid],
  );

  const scheduledDate = useMemo(() => {
    const [hour, minute] = (order?.time || '').split(':');

    let scheduled_date: any;

    if (hour) {
      scheduled_date = toMoment(order?.scheduled_date).set({
        hour: Number(hour),
        minute: Number(minute),
      });
    } else {
      scheduled_date = order?.scheduled_date
        ? toMoment(order?.scheduled_date)
        : toMoment(new Date());
    }

    if (editMode) {
      scheduled_date.utc();
    }

    return {
      scheduled_date,
    };
  }, [editMode, order?.scheduled_date, order?.time]);

  const handleRefreshEmployeeScheduleList = useCallback(
    async ({
      date,
      employeeUuid,
    }: IGetListEmployeeScheduleProps): Promise<void> => {
      const scheduleDate = date ? toMomentString(date, 'YYYY-MM-DD') : null;

      if (isFunction(employeeScheduleListRefresh) && scheduleDate) {
        const schedules: any = await memoizeEmployeeScheduleListRefresh({
          start: scheduleDate,
          end: scheduleDate,
          employeeUuid,
          limit: 100,
        });

        setEmployeeScheduleList(schedules);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyUuid],
  );

  // const navigateToEmployeeServiceListPage = useCallback(
  //   (employeeUuid: string): void => {
  //     if (isFunction(onCancel)) {
  //       onCancel();
  //     }
  //
  //     navigate(
  //       `/${Routes.app}/${Routes.employees}/${employeeUuid}/${Routes.employeeServices}`,
  //     );
  //   },
  //   [navigate, onCancel],
  // );

  const handleSuccess = useCallback(
    async (data: any) => {
      if (isFunction(onSuccess)) {
        await onSuccess(data);
        localStorage.removeItem('dndEvent');
      }
    },
    [onSuccess],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Oder'),
        `${
          editMode
            ? t('An error occurred during edit order')
            : t('An error occurred during create order')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm
      footerClassName="order-form-footer"
      asModal
      stickyFooter
      initialValues={{
        ...order,
        ...scheduledDate,
      }}
      onCancel={onCancel}
      withContext
      submitButtonText={editMode ? t('Save') : t('Create')}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      onSuccess={async ({ worker_uuid, ...value }: any) => {
        await handleSuccess({ ...value, worker_uuid: worker_uuid?.uuid || '' });
      }}
      {...rest}>
      {() => (
        <OrderFormFields
          loading={isLoading}
          editMode={!!editMode}
          disabled={isHasStrictMode}
          employeeScheduleList={employeeScheduleList}
          employeeScheduleListLoading={employeeScheduleListLoading}
          handleRefreshEmployeeScheduleList={handleRefreshEmployeeScheduleList}
          clients={clients}
          clientsLoading={clientsLoading}
          disabledClient={disabledClient}
          handleSearchClients={({ keywords }: any) =>
            handleSearchClients({ keywords, showLoading: false, limit: 1 })
          }
          handleChangeClientType={async (
            client_type: ClientPersonalityType,
          ) => {
            await handleSearchClients({
              client_type,
              keywords: '',
              showLoading: true,
              limit: 1,
            });
          }}
          order={order}
        />
      )}
    </DefaultForm>
  );
}
