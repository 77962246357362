import * as React from 'react';
import { List } from 'immutable';
import {
  usePriceTagList,
  IUsePriceTagListProps,
  IUsePriceTagListReturnType,
} from './usePriceTagList';
import {
  IProductsListStatsProps,
  PriceTagMapper,
  PriceTagModel,
  PriceTagPrintFormDTO,
} from '@structure';
import {
  deletePriceTag,
  createPriceTagList,
  editPriceTag,
  clearPriceTagList,
  printPriceTagList,
  printPriceTag,
} from '@services/api/priceTag';
import {
  setPriceTagList as storeSetPriceTagList,
  deletePriceTagFromList as storeDeletePriceTag,
  loadMorePriceTagList as storeLoadMorePriceTagList,
  updatePriceTagFromList as storeUpdatePriceTagFromList,
  addPriceTagToList as storeAddPriceTag,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { printScheduleDocument } from '@services/helpers';
import { StatusError } from '@components/lib/Errors';

export interface IUseStatePriceTagListProps extends IUsePriceTagListProps {
  productUuid?: string;
}

export interface IPriceTagSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStatePriceTagListReturnType
  extends Omit<IUsePriceTagListReturnType, 'entityList'> {
  priceTagList: List<PriceTagModel> | null;
  handleDeletePriceTags: (
    item_uuid: string,
    withAlert?: boolean,
  ) => Promise<void>;
  handleClearPriceTagList: () => Promise<void>;
  handlePrintPriceTagList: (value: PriceTagPrintFormDTO) => Promise<void>;
  handlePrintPriceTag: (value: PriceTagPrintFormDTO) => Promise<void>;
  handleCreatePriceTag: (item_uuid: string) => Promise<PriceTagModel>;
  handleCreatePriceTagList: (item_uuids: string[]) => Promise<void>;
  handleUpdatePriceTag: (
    product_uuid: string,
    item_uuid: string,
  ) => Promise<PriceTagModel>;
  handleCreateOrUpdatePriceTag: (
    product_uuid: string,
    item_uuid: string,
  ) => Promise<PriceTagModel>;
  handleSearchPriceTags: (value: IPriceTagSearchProps) => Promise<void>;
  handleLoadMorePriceTags: () => Promise<void>;
  handleRefreshPriceTags: (
    value: Partial<IUsePriceTagListProps> & { page: number },
  ) => Promise<void>;
  loadingMore: boolean;
  page: number;
}

export function useStoredPriceTagList(
  {
    companyUuid,
    loadOnInit,
    productUuid,
    ...rest
  }: IUseStatePriceTagListProps = {} as IUseStatePriceTagListProps,
): IUseStatePriceTagListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedPriceTagListStatus,
    loading: storedPriceTagListLoading,
    priceTagList,
    ...storedPriceTagListParams
  } = useSelector(({ priceTagList }: RootState) => priceTagList);

  const storeTotal = priceTagList?.total || 0;
  const storeKeywords = priceTagList?.keywords || '';
  const storedPage = priceTagList?.page || 1;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: priceTagListLoading,
    error,
    stats,
    ...itemsParams
  } = usePriceTagList({
    companyUuid,
    loadOnInit: loadOnInit,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.items) &&
      storedPriceTagListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetPriceTagList(entityList));
    }
  }, [dispatch, entityList, storedPriceTagListStatus]);

  const handleDeletePriceTags = React.useCallback(
    async (item_uuid: string, withAlert = true) => {
      try {
        await deletePriceTag(item_uuid);

        setTimeout(() => {
          dispatch(storeDeletePriceTag(item_uuid));
        }, 100);

        if (withAlert) {
          alert('success', t('Price tag'), t('Price tag delete success'));
        }
      } catch (error: any) {
        if (withAlert) {
          alert(
            'error',
            t('Price tag'),
            `${t('An error occurred during delete price tag')}: ${
              error?.message
            }`,
          );
        } else {
          throw new StatusError(error?.message, error?.status);
        }
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchPriceTags = React.useCallback(
    async ({ limit = 10, keywords, showLoading }: IPriceTagSearchProps) => {
      setLoadingMore(true);
      const priceTagListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (priceTagListModel && List.isList(priceTagListModel?.items)) {
        const updatedPriceTagList = priceTagListModel.update(
          'keywords',
          () => keywords,
        );

        dispatch(storeSetPriceTagList(updatedPriceTagList));
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMorePriceTags = useCallback(async () => {
    if (
      List.isList(priceTagList) &&
      priceTagList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const priceTagListModel = await refresh({
        offset: priceTagList?.size,
        limit: 10,
        showLoading: false,
      });

      if (priceTagListModel && List.isList(priceTagListModel?.items)) {
        dispatch(storeLoadMorePriceTagList(priceTagListModel));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, priceTagList, storeTotal]);

  const handleRefreshPriceTags = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUsePriceTagListProps> & { page: number }) => {
      const priceTagListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (priceTagListModel && List.isList(priceTagListModel?.items)) {
        const updatedPriceTagList = priceTagListModel.update(
          'page',
          () => page,
        );

        dispatch(storeSetPriceTagList(updatedPriceTagList));
      }
    },
    [dispatch, refresh],
  );

  const handleUpdatePriceTag = React.useCallback(
    async (product_uuid: string, item_uuid: string): Promise<PriceTagModel> => {
      const priceTagDTO = await editPriceTag(product_uuid, item_uuid);

      const priceTagModel = PriceTagMapper.toPriceTagModel(priceTagDTO);

      dispatch(storeUpdatePriceTagFromList(priceTagModel));

      return priceTagModel;
    },
    [dispatch],
  );

  const handleCreatePriceTag = React.useCallback(
    async (product_uuid: string) => {
      const { items, total } = await createPriceTagList(
        [product_uuid],
        companyUuid,
      );

      const priceTagModelList = PriceTagMapper.toPriceTagListModel(
        items,
        total,
      ).items;

      const priceTagModel = priceTagModelList?.first();

      dispatch(storeAddPriceTag(priceTagModel!));

      return priceTagModel!;
    },
    [companyUuid, dispatch],
  );

  const handleCreatePriceTagList = React.useCallback(
    async (product_uuids: string[]) => {
      const { items, total } = await createPriceTagList(
        product_uuids,
        companyUuid,
      );

      const priceTagModelList = PriceTagMapper.toPriceTagListModel(
        items,
        total,
      ).items;

      priceTagModelList?.forEach((priceTagModel) =>
        dispatch(storeAddPriceTag(priceTagModel)),
      );
    },
    [companyUuid, dispatch],
  );

  const handleClearPriceTagList = React.useCallback(async () => {
    try {
      alert('success', t('Price tags'), t('Clear price tags success'));
      await clearPriceTagList(companyUuid);

      dispatch(
        storeSetPriceTagList(
          PriceTagMapper.toPriceTagListModel([], 0, [], {
            product_price_tags_count: 0,
          } as IProductsListStatsProps),
        ),
      );
    } catch (error: any) {
      alert(
        'error',
        t('Price tags'),
        `${t('An error occurred during clear price tags')} : ${error?.message}`,
      );
    }
  }, [alert, companyUuid, dispatch, t]);

  const handlePrintPriceTagList = React.useCallback(
    async (value: PriceTagPrintFormDTO): Promise<void> => {
      try {
        const html = await printPriceTagList(value, companyUuid);

        alert('success', t('Price tag'), t('Get document success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Price tag'),
          `${t('An error occurred during get document')} : ${error?.message}`,
        );
      }
    },
    [alert, companyUuid, t],
  );

  const handlePrintPriceTag = React.useCallback(
    async (value: PriceTagPrintFormDTO): Promise<void> => {
      if (productUuid) {
        try {
          const html = await printPriceTag(value, productUuid);

          alert('success', t('Price tag'), t('Get document success'));
          printScheduleDocument(html);
        } catch (error: any) {
          alert(
            'error',
            t('Price tag'),
            `${t('An error occurred during get document')} : ${error?.message}`,
          );
        }
      }
    },
    [alert, productUuid, t],
  );

  const handleCreateOrUpdatePriceTag = React.useCallback(
    async (product_uuid: string, item_uuid: string) => {
      if (!item_uuid) {
        return await handleCreatePriceTag(product_uuid);
      }
      return handleUpdatePriceTag(product_uuid, item_uuid);
    },
    [handleCreatePriceTag, handleUpdatePriceTag],
  );

  return {
    ...itemsParams,
    ...storedPriceTagListParams,
    error,
    priceTagList: priceTagList?.items || null,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(priceTagList?.items) && priceTagListLoading) ||
      !storedPriceTagListLoading,
    handleDeletePriceTags,
    handleSearchPriceTags,
    handleLoadMorePriceTags,
    handleRefreshPriceTags,
    keywords: storeKeywords,
    page: storedPage,
    handleUpdatePriceTag,
    handleCreatePriceTag,
    handleClearPriceTagList,
    handlePrintPriceTagList,
    handleCreateOrUpdatePriceTag,
    stats: priceTagList?.stats || stats,
    handlePrintPriceTag,
    handleCreatePriceTagList,
  };
}
