import { List } from 'immutable';
import {
  CategoryDTO,
  CategoryFormDTO,
  ICategoryDTOProps,
  CategoryModel,
  CategoryListModel,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface ICategoryListDTO {
  categories: CategoryDTO[];
  total: number;
}

export class CategoryMapper {
  public static toCategoryDTO(
    category: CategoryModel | ICategoryDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): CategoryDTO {
    return new CategoryDTO(
      {
        pos: category?.pos,
        uuid: category?.uuid,
        title: category?.title,
        description: category?.description,
        updated_at: category?.updated_at,
        created_at: category?.created_at,
        status: category?.status,
        created_by: category?.created_by,
      } as ICategoryDTOProps,
      [WithoutRecursion.category, ...withoutRecursion],
    );
  }

  public static toCategoryListDTO(
    categories: List<CategoryModel> | ICategoryDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ICategoryListDTO {
    const categoryList = List.isList(categories)
      ? listToArray<CategoryModel>(categories)
      : categories;

    return {
      categories: categoryList?.map((category) =>
        CategoryMapper.toCategoryDTO(category, [
          WithoutRecursion.category,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toCategoryModel(
    categoryDTO: CategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): CategoryModel {
    return new CategoryModel(categoryDTO, [
      WithoutRecursion.category,
      ...withoutRecursion,
    ]);
  }

  public static toCategoryListModel(
    categoryDTOs: CategoryDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): CategoryListModel {
    return new CategoryListModel({ categories: categoryDTOs, total }, [
      WithoutRecursion.category,
      ...withoutRecursion,
    ]);
  }

  public static toCategoryFormDTO(
    categoryModel: CategoryModel,
  ): CategoryFormDTO {
    return new CategoryFormDTO(categoryModel);
  }
}
