import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  VerificationView,
  AuthLayout,
  HeaderText,
  FullWidthSpace,
} from '../Show';
import { Row, Col } from 'antd';
import { useStoredAuthData, useStoreVerificationFactory } from '@hooks';
import { ReSendButton } from '../Buttons';
import { useDropdownAlert } from '@contex';
import { useLocation, useNavigate } from 'react-router';
import { StatusError } from '@components/lib/Errors';
import { useTheme } from 'styled-components';
import { Routes } from '@services/types';

export default withTranslation()(VerificationForm);

const TIME_WHEN_INSTRUCTION_CHANGE = 30000;

export interface IVerificationFormProps extends WithTranslation {
  codeLength?: number;
}

function VerificationForm({
  t,
  codeLength = 5,
}: IVerificationFormProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const theme: any = useTheme();

  const [loading, setLoading] = useState(false);
  const [instructionColor, setInstructionColor] = useState(
    theme.colors.primary,
  );

  const { alert } = useDropdownAlert();

  const { authRedirect } = useStoredAuthData();

  const {
    loading: verifyLoading,
    identifier,
    disabled,
    seconds,

    apiFunctionFactory,
    initStateFactory,
    handleRegenerationCode,
  } = useStoreVerificationFactory<{
    timeWhenCodeWasSend: null | Date;
    identifier: string;
  }>({
    key: location?.state?.verificationStateName,
  });

  const navigateToInstructionPage = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.verificationInstructions}`, {
      state: {
        ...location?.state,
        from: location.pathname,
        prevState: location.state,
      },
    });
  }, [location.pathname, location?.state, navigate]);

  const handleVerifyCode = React.useCallback(
    async (code: string) => {
      try {
        setLoading(true);
        const authDataDTO = await apiFunctionFactory(code);

        if (authDataDTO?.success) {
          await authRedirect(authDataDTO, { from: location?.state?.from });

          alert('success', t('Verification'), t('Success verification'));
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        alert(
          'error',
          t('Verification'),
          `${t('An error occurred during verification')} : ${error?.message}`,
        );

        throw new StatusError(
          error?.response?.message || error?.message,
          error?.response?.status || error?.status,
        );
      }
    },
    [apiFunctionFactory, authRedirect, location?.state?.from, alert, t],
  );

  useEffect(() => {
    if (verifyLoading) {
      initStateFactory();
    }
  }, [initStateFactory, verifyLoading]);

  useEffect(() => {
    setTimeout(
      setInstructionColor,
      TIME_WHEN_INSTRUCTION_CHANGE,
      theme.colors.accent,
    );
  }, [theme.colors.accent]);

  return (
    <AuthLayout>
      <Row>
        <FullWidthSpace direction="vertical" size="middle">
          <HeaderText title={t('Verify code')} />

          <VerificationView
            identifier={identifier}
            handlerVerifyCode={handleVerifyCode}
            length={codeLength}
            loading={loading}
          />

          {location?.state?.hiddenResendButton ? null : (
            <>
              <Col span={24}>
                <ReSendButton
                  disabled={disabled || loading}
                  loading={loading}
                  seconds={seconds}
                  onClick={handleRegenerationCode}
                  title={t('Did not receive code? Send it again!')}
                />
              </Col>
              <Col span={24}>
                <ReSendButton
                  color={instructionColor}
                  disabled={false}
                  loading={loading}
                  seconds={0}
                  onClick={navigateToInstructionPage}
                  title={t('Did not get the code? Go to the instructions')}
                />
              </Col>
            </>
          )}
        </FullWidthSpace>
      </Row>
    </AuthLayout>
  );
}
