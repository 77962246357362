import * as React from 'react';
import { getOrderById, IScheduleAnswer } from '@services/api/orders';
import {
  IScheduleStatsDTO,
  ScheduleCalendarDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
} from '@structure';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseScheduleDetailsProps {
  scheduleUuid: string;
  loadOnInit?: boolean;
  showLoading?: boolean;
}

export interface IUseScheduleDetailsReturnType {
  error: Error | null;
  refresh: (
    value: Partial<IUseScheduleDetailsProps>,
  ) => Promise<ScheduleCalendarModel | void>;
  schedule: ScheduleCalendarModel;
  loading: boolean;
  stats: IScheduleStatsDTO;
}

export default function useScheduleDetails(
  {
    scheduleUuid,
    loadOnInit = true,
  }: IUseScheduleDetailsProps = {} as IUseScheduleDetailsProps,
): IUseScheduleDetailsReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = React.useRef<boolean>();

  const {
    handleUpdate,
    scheduleUuid: localScheduleUuid,
    stats: listStats,
    ...rest
  } = useStateReducer<
    Omit<IUseScheduleDetailsReturnType, 'refresh'> & { scheduleUuid: string }
  >({
    error: null,
    loading: true,
    schedule: {} as ScheduleCalendarModel,
    scheduleUuid,
    stats: { files: '0' },
  });

  const refresh = React.useCallback(
    async ({
      scheduleUuid = localScheduleUuid,
      showLoading = true,
    }: Partial<IUseScheduleDetailsProps> = {}): Promise<ScheduleCalendarModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          scheduleUuid,
        });

        const { schedule, stats } = await cancellablePromise<IScheduleAnswer>(
          getOrderById(scheduleUuid),
        );

        const scheduleModel =
          ScheduleCalendarMapper.toScheduleCalendarModel(schedule);

        if (!didCancel.current) {
          handleUpdate({
            schedule: scheduleModel,
            loading: false,
            stats,
          });

          return scheduleModel;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localScheduleUuid],
  );

  React.useEffect(() => {
    didCancel.current = false;

    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({ scheduleUuid });
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return { refresh, stats: listStats, ...rest };
}
