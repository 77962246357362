import React, { useCallback, useRef, useState } from 'react';
import { Button, Select, Spin } from 'antd';
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { getCalendarHeaderDate, toMoment } from '@services/helpers';
import { SELECT_MODE } from '@contex';
import { DateValue } from '@services/types';
import ActionButton from '../../General/ActionButton';
import DatePicker from '../../General/DatePicker';
import {
  CalendarHeaderSpecification,
  ICalendarHeaderSpecificationProps,
} from '../../../Calendar';
import { useOutSideElementClickHandler, useSize } from '@hooks';
import { bigPhoneScreen } from '@services/const';

import './CalendarHeader.less';

export default withTranslation()(CalendarHeaderView);

const { Option } = Select;

export interface ICalendarHeaderViewProps
  extends WithTranslation,
    Omit<ICalendarHeaderSpecificationProps, 'isColumnView'> {
  title?: string;
  handleSelectToday: () => Promise<void> | void;
  handleSelectPrevDate: () => Promise<void> | void;
  handleSelectNextDate: () => Promise<void> | void;
  handleChangeDate?: (value: any) => Promise<void> | void;
  handleChangeCalendarMode?: (mode: any) => void;
  selectedDate: DateValue;
  currentMode?: string;
  showCalendarMode?: boolean;
  showTitle?: boolean;
  loading?: boolean;
  danger?: boolean;
  disabled?: boolean;
  showCalendarPicker?: boolean;
}

const HiddenDatePicker = styled(DatePicker)`
  visibility: hidden;
  width: 0;
  position: absolute;
  height: auto;
  left: 0;
`;

const StyledActionButton = styled(ActionButton)`
  height: auto;
  position: relative;
  margin-left: 8px;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`;

const StyledSpinIconLoading = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.app};
`;

const StyledCalendarHeader = styled.div<{
  danger?: boolean;
  $centerContent: boolean;
}>`
  padding: 5px;
  ${({ danger }) =>
    danger &&
    css`
      background-color: ${({ theme }) => theme.colors.error};
    `}

  ${({ $centerContent }) =>
    $centerContent &&
    css`
      justify-content: center;
    `}
`;

const SelectContainer = styled.div<{ $fullWidth: boolean }>`
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledLockOutlined = styled(LockOutlined)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  margin-right: 10px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
`;

function CalendarHeaderView({
  t,
  title,
  handleSelectToday,
  handleSelectPrevDate,
  handleSelectNextDate,
  selectedDate,
  currentMode,
  handleChangeCalendarMode,
  showCalendarMode = true,
  showTitle = true,
  showCalendarPicker = true,
  handleChangeDate,
  loading,
  danger,
  specifications,
  disabled,
}: ICalendarHeaderViewProps): JSX.Element {
  const [openDatePicker, setOpenDatePiker] = useState(false);

  const calendarButtonRef = useRef(null);
  const calendarRef = useRef(null);
  const { width: calendarWidth } = useSize(calendarRef);

  const handleToggleDatePicker = useCallback((e: any) => {
    e?.stopPropagation();

    if (
      e?.target?.parentNode?.parentNode?.className === 'ant-picker-header' ||
      e?.target?.parentNode?.className === 'ant-picker-header'
    ) {
      return;
    }

    setOpenDatePiker((prevState) => !prevState);
  }, []);

  useOutSideElementClickHandler(
    calendarButtonRef,
    () => setOpenDatePiker(false),
    'root',
  );

  return (
    <div id="calendar-header" ref={calendarRef}>
      <StyledCalendarHeader
        $centerContent={calendarWidth < bigPhoneScreen + 5}
        className="calendar_header"
        danger={danger}>
        {showCalendarPicker ? (
          <StyledActionButton
            ref={calendarButtonRef}
            size="middle"
            type="text"
            onClick={handleToggleDatePicker}
            className="calendar_header-sider-button calendar_header--indent">
            <CalendarOutlined className="calendar_header--color calendar_header-icon--calendar" />
            <HiddenDatePicker
              open={openDatePicker}
              data-testid="calendar-header__date-picker"
              onChange={handleChangeDate}
              value={toMoment(selectedDate)}
            />
          </StyledActionButton>
        ) : null}

        {showTitle ? (
          <span className="calendar_header--color calendar_header--indent">
            {title || t('Calendar')}
          </span>
        ) : null}

        <Button
          disabled={disabled}
          type="text"
          onClick={handleSelectToday}
          className="calendar_header-button--sider">
          <span className="calendar_header--color">{t('Today')}</span>
        </Button>
        <div>
          <Button
            disabled={disabled}
            type="text"
            onClick={handleSelectPrevDate}
            className="calendar_header-button--left">
            <LeftOutlined className="calendar_header--color" />
          </Button>
          <Button
            disabled={disabled}
            type="text"
            onClick={handleSelectNextDate}
            className="calendar_header-button--right calendar_header--indent">
            <RightOutlined className="calendar_header--color" />
          </Button>
        </div>
        <span className="calendar_header--color calendar_header-text--date">
          {getCalendarHeaderDate(selectedDate)}
        </span>
        {showCalendarMode ? (
          <SelectContainer
            $fullWidth={calendarWidth < bigPhoneScreen + 5}
            className="calendar_header-mode-change">
            <Select
              size="small"
              value={currentMode}
              style={{
                width: calendarWidth < bigPhoneScreen + 5 ? '100%' : 120,
              }}
              onChange={handleChangeCalendarMode}>
              {SELECT_MODE.map((mode) => (
                <Option value={mode} key={mode}>
                  {t(mode)}
                </Option>
              ))}
            </Select>
          </SelectContainer>
        ) : null}

        <StyledIconContainer>
          {loading ? (
            <StyledSpin
              spinning={loading}
              size="large"
              indicator={<StyledSpinIconLoading />}
            />
          ) : null}
          {disabled ? <StyledLockOutlined /> : null}
        </StyledIconContainer>
      </StyledCalendarHeader>
      <CalendarHeaderSpecification specifications={specifications} />
    </div>
  );
}
