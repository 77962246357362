import { List } from 'immutable';
import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import { IClientAbonementListAnswer } from './client';
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from '../core/api';
import { GalleryItem, GalleryList, ListOptions } from '../models';
import {
  ApiAnswer,
  ClientPersonalityType,
  DateValue,
  DeletePrams,
  IListProps,
  IListSearchProps,
} from '../types';
import {
  buildFormData,
  buildFormDataV2,
  forEach,
  GalleryItemProps,
  GalleryListProps,
  isEqualById,
  notEmptyValue,
  prop,
  ScheduleCalendarProps,
  toMomentString,
} from '../helpers';
import {
  AbonementDTO,
  AbonementMapper,
  AbonementModel,
  AlphaNameAmount,
  AlphaNameFormDTO,
  ClientAbonementListDTO,
  ClientAbonementMapper,
  ClientSettlementType,
  CompanyDTO,
  CompanyFormDTO,
  CompanyMapper,
  CompanyModel,
  CompanyRequisiteDTO,
  CompanySettingsDTO,
  IAbonementDTOProps,
  IAlphaNameDTOProps,
  IClientAbonementDTOProps,
  ICompanyDTOProps,
  ICompanyListDTO,
  ICompanyRequisiteDTOProps,
  IFinancialSettingsDTOProps,
  IScheduleCalendarDTOProps,
  IScheduleCalendarListDTO,
  IScheduleCalendarStatsDTOProps,
  ScheduleCalendarMapper,
} from '@structure';

export interface ICreateCompanyParam extends Pick<CompanyFormDTO, 'title'> {
  phone?: string;
  email?: string;
}

export const COMPANY_INITIAL_DATA = new CompanyFormDTO();
export const COMPANY_REQUISITE_INITIAL_DATA = new CompanyRequisiteDTO(
  {} as ICompanyRequisiteDTOProps,
);

export const ALPHA_NAME_AMOUNTS: { uuid: AlphaNameAmount; title: string }[] = [
  { uuid: AlphaNameAmount.ThreeMonth, title: 'Three month' },
  { uuid: AlphaNameAmount.HalfYear, title: 'Half year' },
  { uuid: AlphaNameAmount.Year, title: 'Year' },
];

export interface ILinkAlphaNameAnswer extends ApiAnswer {
  settings: IFinancialSettingsDTOProps;
  order: IAlphaNameDTOProps;
}

const {
  uuid,
  work_schedules,
  currency_symbol,
  notification_language,
  requisite_list,
  requisites,
  requisites_v2,
  ...rest
} = COMPANY_INITIAL_DATA;

export const REQUIRED_INITIAL_PARAM = {
  ...rest,
};

export interface ICompanyListAnswer extends ApiAnswer {
  companies: ICompanyDTOProps[];
}

export interface ICompanyAnswer extends ApiAnswer {
  company: ICompanyDTOProps;
}

export interface GetStoreCompanyByUuid {
  companies: List<CompanyModel>;
  companyUuid: string;
}

export interface ICompanyClientAbonementListProps extends IListSearchProps {
  companyUuid: string;
}

export interface ICompanyAbonementClientAbonementListProps
  extends IListSearchProps {
  abonementUuid: string;
}

export interface ICompanyAbonementClientAbonementListAnswer extends ApiAnswer {
  abonements: IClientAbonementDTOProps[];
  company_abonement: IAbonementDTOProps;
}

export const getStoreCompanyByUuid = ({
  companies,
  companyUuid,
}: GetStoreCompanyByUuid) =>
  companies.find(
    isEqualById<[(string | undefined)?, any?], boolean>(companyUuid),
  );

export const getCompanyUuid = prop('uuid');

export interface StateGallery
  extends Pick<GalleryItemProps, 'uuid' | 'title' | 'description'> {
  src: string;
  width: number;
  height: number;
}

export const buildGalleryItem = ({
  path_url,
  title,
  uuid,
  description,
}: GalleryItem): StateGallery => ({
  src: path_url,
  width: 4,
  height: 3,
  title,
  uuid,
  description,
});

export async function getCompanyList({
  offset = 0,
  limit = 10,
}: Partial<IListProps>): Promise<ICompanyListDTO> {
  const { companies, total } = await apiGet<IListProps, ICompanyListAnswer>(
    `${contentApiUrl}/companies`,
    {
      offset,
      limit,
    },
  );

  return CompanyMapper.toCompanyListDTO(companies, total);
}

export async function getCompanyById(companyUuid: string): Promise<CompanyDTO> {
  const { company } = await apiGet<null, ICompanyAnswer>(
    `${contentApiUrl}/companies/${companyUuid}`,
  );

  return CompanyMapper.toCompanyDTO(company);
}

export async function createCompany(
  value: Partial<CompanyFormDTO>,
): Promise<CompanyDTO> {
  const { weekdays, requisites, requisite_list, ...data } =
    notEmptyValue(value);

  const { company } = await apiPost<ICreateCompanyParam, ICompanyAnswer>(
    `${contentApiUrl}/companies`,
    {
      ...data,
      requisites_v2: requisite_list?.length
        ? requisite_list
        : (undefined as any),
    },
  );

  return CompanyMapper.toCompanyDTO(company);
}

export async function editCompany({
  uuid,
  title,
  email,
  phone,
  category_uuid,
  description = '',
  address = '',
  work_schedules,
  close_shift_automatically,
  requisite_list,
}: CompanyFormDTO): Promise<CompanyDTO> {
  Validate.string({ value: uuid });
  Validate.string({ value: title });

  const { company } = await apiPut<
    Omit<
      CompanyFormDTO,
      | 'uuid'
      | 'getFirstCategoryUuid'
      | 'currency_symbol'
      | 'notification_language'
      | 'requisites'
    >,
    ICompanyAnswer
  >(`${contentApiUrl}/companies/${uuid}`, {
    title,
    email,
    phone,
    category_uuid,
    description,
    address,
    close_shift_automatically,
    work_schedules,
    requisites_v2: requisite_list?.length ? requisite_list : (undefined as any),
  });

  return CompanyMapper.toCompanyDTO(company);
}

/**
 * @desc Оновлення опису/коментару для Компанії
 * @param {string} comment
 * @param {string} companyUUID
 */
export async function updateCompanyComment(
  comment: string,
  companyUUID: string,
): Promise<CompanyDTO> {
  const { company } = await apiPatch<{ comment: string }, any>(
    `${contentApiUrl}/companies/${companyUUID}/details`,
    { comment },
  );

  return CompanyMapper.toCompanyDTO(company);
}

export async function deleteCompanies(ids: string[]): Promise<CompanyDTO> {
  Validate.arrayOfStrings({ value: ids });

  const { company } = await apiDelete<DeletePrams, ICompanyAnswer>(
    `${contentApiUrl}/companies`,
    {
      ids,
    },
  );
  return CompanyMapper.toCompanyDTO(company);
}

export type ClientSchedule = {
  first_name: string;
  last_name: string;
  phone: string;
};

export interface IMakeAnOrderCompany {
  scheduled_date: string;
  service_uuid: string;
  client: ClientSchedule | { uuid: string };
  worker_uuid: string;
  order_uuid?: string;
}

export interface IEditAnOrderCompany
  extends Omit<IMakeAnOrderCompany, 'client'> {}

export async function editAnOrderCompany({
  order_uuid,
  scheduled_date,
  service_uuid,
  worker_uuid,
}: IEditAnOrderCompany): Promise<ScheduleCalendarProps> {
  Validate.string({ value: service_uuid });
  Validate.string({ value: worker_uuid });
  Validate.string({ value: scheduled_date });
  Validate.string({ value: order_uuid });

  return await apiPut<
    Omit<IEditAnOrderCompany, 'order_uuid'>,
    ScheduleCalendarProps
  >(`${contentApiUrl}/schedules/${order_uuid}`, {
    worker_uuid,
    scheduled_date,
    service_uuid,
  });
}

export interface ISetCompanyImages {
  file: File;
  uuid: string;
}

// TODO: додати тип
export async function setCompanyLogo({
  file,
  uuid,
}: ISetCompanyImages): Promise<CompanyDTO> {
  Validate.string({ value: uuid });

  const data = buildFormData('logo', file);

  const { company } = await apiPost<FormData, ICompanyAnswer>(
    `${contentApiUrl}/upload/company/${uuid}/logo`,
    data,
    {},
    {},
    true,
  );

  return CompanyMapper.toCompanyDTO(company);
}

export async function setCompanyCover({
  file,
  uuid,
}: ISetCompanyImages): Promise<CompanyDTO> {
  Validate.string({ value: uuid });

  const data = buildFormData('cover', file);

  const { company } = await apiPost<FormData, ICompanyAnswer>(
    `${contentApiUrl}/upload/company/${uuid}/cover`,
    data,
    {},
    {},
    true,
  );

  return CompanyMapper.toCompanyDTO(company);
}

export enum GetCompanyScheduleState {
  ALL = '',
  OVERDUE = 'overdue',
  UNPAID = 'unpaid',
  CANCELLED = 'cancelled',
}

export interface IGetCompanySchedule {
  start?: DateValue;
  end?: DateValue;
  companyUuid: string;
  page?: number;
  limit?: number;
  offset?: number;
  keywords?: string;
  activeness?: number;
  sort?: string;
  state: GetCompanyScheduleState;
}

export interface ICompanyScheduleListAnswer extends ApiAnswer {
  schedules: IScheduleCalendarDTOProps[];
  stats: { totals: IScheduleCalendarStatsDTOProps };
}

export interface CompanyScheduleFilter {
  date_start: string | null;
  date_end: string | null;
  status_uuid: string | null;
  client_uuid: string | null;
  manager_uuid: string | null;
  location_uuid: string | null;
}

export const DEFAULT_SCHEDULE_FILTER: CompanyScheduleFilter = {
  date_start: null,
  date_end: null,
  status_uuid: null,
  client_uuid: null,
  manager_uuid: null,
  location_uuid: null,
};

export async function getCompanySchedule({
  companyUuid,
  limit,
  offset = 0,
  keywords,
  activeness,
  sort,
  state,
  client_uuid,
  date_start,
  date_end,
  manager_uuid,
  location_uuid,
  status_uuid,
}: IGetCompanySchedule &
  Partial<CompanyScheduleFilter>): Promise<IScheduleCalendarListDTO> {
  Validate.string({ value: companyUuid });

  const { schedules, total, stats } = await apiGet<
    any,
    ICompanyScheduleListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/schedules`, {
    date_start: date_start ? toMomentString(date_start, 'YYYY-MM-DD') : '',
    date_end: date_end ? toMomentString(date_end, 'YYYY-MM-DD') : '',
    limit,
    keywords,
    activeness: activeness || '',
    sort,
    offset,
    state,
    client_uuid,
    manager_uuid,
    location_uuid,
    status_uuid,
  });

  return ScheduleCalendarMapper.toScheduleCalendarListDTO(
    schedules,
    total,
    [],
    ScheduleCalendarMapper.toScheduleCalendarStatsDT(stats?.totals),
  );
}

export interface CompanyClientFilter {
  provisioner_only?: boolean;
  settlement?: ClientSettlementType;
  client_type?: ClientPersonalityType;
}

export const DEFAULT_CLIENT_FILTER: CompanyClientFilter = {
  provisioner_only: false,
  settlement: ClientSettlementType.ALL,
  client_type: ClientPersonalityType.Individual,
};

/**
 * @name getCompanyGallery
 * @desc Get a company gallery.
 * @param uuid - a company id
 * @param limit - number of selected notes in a gallery, the default limit - 100
 * @param listOptions
 * @throws {ApiError}
 */

export interface IGetCompanyGallery extends Partial<IListProps> {
  listOptions: ListOptions;
  uuid: string;
}

export async function getCompanyGallery({
  uuid,
  offset = 0,
  limit = undefined,
}: IGetCompanyGallery): Promise<GalleryList> {
  Validate.string({ value: uuid });

  const res = await apiGet<Partial<IGetCompanyGallery>, GalleryListProps>(
    `${contentApiUrl}/gallery/company/${uuid}`,
    {
      offset,
      limit,
    },
  );

  return new GalleryList(res || {});
}

export interface ICompanyGalleryPhoto {
  gallery: GalleryItemProps[];
}

export interface CompanyGalleryProps {
  companyUuid: string;
  files: File[];
}

export async function postCompanyGalleryPhoto({
  companyUuid,
  files,
}: CompanyGalleryProps): Promise<GalleryList> {
  Validate.string({ value: companyUuid });

  const form: any = buildFormDataV2();

  forEach(form.func('image') as any, files);

  const res = await apiPost<FormData, ICompanyGalleryPhoto>(
    `${contentApiUrl}/gallery/company/${companyUuid}`,
    form.formData,
    {},
    {},
    true,
  );

  return new GalleryList(res || {});
}

/**
 * @name editCompanyGalleryPhoto
 * @desc Delete photos in a company gallery.
 * @param {String} photoId
 * @param {Object} data
 * @param data contains: title, description, pos, status
 * @throws {ApiError}
 */

export interface IEditCompanyGalleryPhoto {
  photoUuid: string;
  title: string;
  description: string;
}

export interface IEditGalleryPhotoReturnType {
  photo: GalleryItemProps;
}

export async function editCompanyGalleryPhoto({
  photoUuid,
  title,
  description,
}: IEditCompanyGalleryPhoto): Promise<any> {
  Validate.string({ value: photoUuid });
  Validate.string({ value: title });

  const { photo } = await apiPut<
    Omit<IEditCompanyGalleryPhoto, 'photoUuid'>,
    IEditGalleryPhotoReturnType
  >(`${contentApiUrl}/gallery/${photoUuid}`, { title, description });

  return new GalleryItem(photo);
}

/**
 * @name deleteCompanyGalleryPhotos
 * @desc Delete photos in a company gallery.
 * @param {String} companyId
 * @param {String} photoId
 * @param {Array<String>} photoIds
 * @throws {ApiError}
 */

export interface IDeleteCompanyGalleryPhotos {
  companyUuid: string;
  photoUuid?: string;
  photoIds?: string[];
}

export async function deleteCompanyGalleryPhotos({
  companyUuid,
  photoUuid,
  photoIds,
}: IDeleteCompanyGalleryPhotos): Promise<any> {
  if (photoIds) {
    Validate.arrayOfStrings({ value: photoIds });
  } else {
    Validate.string({ value: photoUuid });
  }
  Validate.string({ value: companyUuid });

  return await apiDelete<DeletePrams, any>(
    `${contentApiUrl}/gallery/company/${companyUuid}/photo/${photoUuid || ''}`,
    {
      ids: photoIds || [],
    },
  );
}

export async function linkAlphaName(
  { alfa_name, amount }: AlphaNameFormDTO,
  companyUuid: string,
): Promise<CompanySettingsDTO> {
  const { settings, order } = await apiPost<
    AlphaNameFormDTO,
    ILinkAlphaNameAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/settings/link-alfa-name`, {
    alfa_name,
    amount,
  });

  return CompanyMapper.toCompanySettingsDTO({
    settings,
    alfa_name_order: order,
  });
}

export async function getCompanyClientsAbonementList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: ICompanyClientAbonementListProps): Promise<ClientAbonementListDTO<unknown>> {
  const { abonements, count } = await apiGet<
    IListSearchProps,
    IClientAbonementListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/client-abonements`, {
    offset,
    limit,
    keywords,
  });

  return ClientAbonementMapper.toClientAbonementListDTO(abonements, count || 0);
}

export async function getTariffModelClientsAbonementList({
  offset = 0,
  limit = 10,
  keywords,
  abonementUuid,
}: ICompanyAbonementClientAbonementListProps): Promise<
  ClientAbonementListDTO<AbonementModel>
> {
  const {
    count,
    abonements,
    company_abonement: companyAbonement,
  } = await apiGet<
    IListSearchProps,
    ICompanyAbonementClientAbonementListAnswer
  >(`${contentApiUrl}/abonements/${abonementUuid}/client-abonements`, {
    offset,
    limit,
    keywords,
  });

  return ClientAbonementMapper.toClientAbonementListDTO(
    abonements,
    count || 0,
    AbonementMapper.toAbonementModel(companyAbonement as AbonementDTO),
  );
}
