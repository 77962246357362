import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { OverviewBottomView } from '@components/lib/Layout';
import { correctPrice } from '@services/helpers';
import { useStoreSale } from '../Managers';

export interface IStoreSaleCategoryBottomViewProps {}

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`;

export const StoreSaleCategoryBottomView = forwardRef(
  function StoreSaleCategoryBottomView(
    {}: IStoreSaleCategoryBottomViewProps,
    ref: any,
  ): JSX.Element {
    const { t } = useTranslation();
    const { defaultCompany } = useStoredCompanies();

    const { storeDocument } = useStoreSale();

    return (
      <>
        <div ref={ref}>
          <OverviewBottomView>
            <StyledPriceContainer>
              <StyledTitle fontSize={16}>{`${t(
                'Selected goods for the amount',
              )}: `}</StyledTitle>
              <StyledPrice>
                {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  storeDocument?.doc_sum_total || 0,
                )}`}
              </StyledPrice>
            </StyledPriceContainer>
          </OverviewBottomView>
        </div>
      </>
    );
  },
);
