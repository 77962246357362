import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultTabPanelProps } from '@components/lib/General';
import { Routes } from '@services/types';
import { useNavigate } from 'react-router';

export enum PRODUCT_CATEGORY_TABS {
  GOODS_AND_MATERIALS = 'Goods and materials values',
  SUB_CATEGORIES = 'Sub categories',
}

export interface IProductCategoryTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function ProductCategoryTabPanel({
  children,
}: IProductCategoryTabPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const productTabs: any = [
    {
      key: PRODUCT_CATEGORY_TABS.GOODS_AND_MATERIALS,
      title: t(PRODUCT_CATEGORY_TABS.GOODS_AND_MATERIALS),
      disabled: false,
      navigation: () => {
        navigate(Routes.categoryProducts);
      },
      renderer: () => <></>,
    },
    {
      key: PRODUCT_CATEGORY_TABS.SUB_CATEGORIES,
      title: t(PRODUCT_CATEGORY_TABS.SUB_CATEGORIES),
      disabled: false,
      navigation: () => {
        navigate(Routes.subCategories);
      },
      renderer: () => <></>,
    },
  ];

  return <>{children({ tabs: productTabs })}</>;
}
