import { BsSend } from 'react-icons/bs';
import { Routes } from '@services/types';
import { useTheme } from 'styled-components';
import { authSiteUrl } from '@services/const';
import { isFunction } from '@services/helpers';
import { IAuthRouteItemProps } from '../../Show';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { MdSms, MdEmail, MdWebStories } from 'react-icons/md';
import { useTranslation, WithTranslation } from 'react-i18next';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface IVerificationInstructionsTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
  disabled: boolean;
  seconds: number;
  handleRegenerationCode: () => Promise<void>;
}

export enum VERIFICATION_INSTRUCTION_TABS {
  SEND_PHONE_CODE = 'Send sms code again',
  EMAIL_CODE_FROM = 'Send code to email',
  SITE = 'Contact us',
}

export function VerificationInstructionsTabPanel({
  children,
  disabled,
  seconds,
  handleRegenerationCode,
}: IVerificationInstructionsTabPanelProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [routes, setRoutes] = React.useState<any[]>([]);

  const handleOpenSiteUrl = useCallback(async () => {
    window.open(authSiteUrl, '_blank');
  }, []);

  useEffect(() => {
    setRoutes([
      {
        key: VERIFICATION_INSTRUCTION_TABS.SEND_PHONE_CODE,
        title: disabled
          ? `${VERIFICATION_INSTRUCTION_TABS.SEND_PHONE_CODE} ${seconds} ${t(
              'seconds',
            )}`
          : VERIFICATION_INSTRUCTION_TABS.SEND_PHONE_CODE,
        icon: <MdSms size={20} color={theme.colors.primary} />,
        navigation: async () => {
          await handleRegenerationCode();
          navigate(location?.state?.from, {
            state: location?.state?.prevState,
          });
        },
        buttonIcon: <BsSend size={20} color={theme.colors.primary} />,
        disabled,
      },
      {
        key: VERIFICATION_INSTRUCTION_TABS.EMAIL_CODE_FROM,
        title: VERIFICATION_INSTRUCTION_TABS.EMAIL_CODE_FROM,
        icon: <MdEmail size={20} color={theme.colors.primary} />,
        navigation: () => {
          navigate(`/${Routes.auth}/${Routes.emailVerification}`, {
            state: {
              ...location.state,
              from: location.pathname,
              goBack: location?.state?.from,
              prevState: location.state,
            },
          });
        },
      },
      {
        key: VERIFICATION_INSTRUCTION_TABS.SITE,
        title: VERIFICATION_INSTRUCTION_TABS.SITE,
        icon: <MdWebStories size={22} color={theme.colors.primary} />,
        navigation: handleOpenSiteUrl,
      },
    ]);
  }, [
    disabled,
    handleOpenSiteUrl,
    handleRegenerationCode,
    location.pathname,
    location.state,
    navigate,
    seconds,
    t,
    theme.colors.primary,
  ]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('VerificationInstructions');
  }

  return <>{children({ routes })}</>;
}
