import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Input } from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  PhoneInputComponent,
  DatePicker,
} from '@components/lib/General';
import { PROFILE_INITIAL_DATA } from '@services/api/user';
import { ProfileFormDTO } from '@structure';

import 'react-phone-input-2/lib/style.css';
import './AccountForm.less';
import { useSize } from '@hooks';
import { phoneScreen } from '@services/const';

export interface IAccountFormProps
  extends Omit<
    IDefaultFormProps<any, any>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  user: ProfileFormDTO;
  loading?: boolean;
  overviewMode?: boolean;
}

/**
 * @desc Створенння співробітника
 * */
export default function AccountForm({
  loading,
  user = PROFILE_INITIAL_DATA,
  overviewMode,
  ...rest
}: IAccountFormProps) {
  const { t } = useTranslation();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const additionalValues = useMemo(
    () => ({
      uuid: user?.uuid,
    }),
    [user?.uuid],
  );

  return (
    <DefaultForm
      invertFooterButtons={overviewMode}
      initialValues={user}
      additionalValuesRequest={additionalValues}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={20} ref={contentRef}>
            <Col span={24}>
              <Form.Item
                label={t('Last Name')}
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: t('Last Name must be specified.'),
                  },
                ]}>
                <Input
                  data-testid="user-create-input-last-name"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter the user last name')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('First Name')}
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: t('First Name must be specified.'),
                  },
                ]}>
                <Input
                  data-testid="user-create-input-first-name"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter the user first name')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t('Email')}
                className="user-email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('User email must be specified.'),
                  },
                ]}>
                <Input
                  data-testid="user-create-input-email"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter the user email')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={isFullWidth}>
              <Form.Item
                label={t('Phone')}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('User Phone must be specified.'),
                  },
                ]}>
                <PhoneInputComponent
                  dataTestid="client-create-input-name"
                  placeholder={t('Enter the client phone')}
                  id="field-phone"
                  containerClass="field-phone-container"
                  searchClass="field-phone-search"
                  inputClass="ant-input field-phone"
                  buttonClass="field-phone-flag"
                  loading={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>

            <Col span={isFullWidth}>
              <Form.Item label={t('Birthday')} name="birthday">
                <DatePicker
                  data-testid="user-create-input-birthday"
                  className="user-create-input-birthday"
                  disabled={loading || loadingSubmit}
                  placeholder={t('Birthday')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
