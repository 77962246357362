import { Record } from 'immutable';
import { EmployeeSalaryDTO, IEmployeeSalaryAccrualAt } from '../internal';
import { WithoutRecursion } from '../type';

export interface IEmployeeSalaryModelReturnType {
  sum: string;
  comment: string;
  start_date: string;
  enabled: boolean;
  accrual_at: IEmployeeSalaryAccrualAt[];
}

export class EmployeeSalaryModel extends Record<IEmployeeSalaryModelReturnType>(
  {
    sum: '',
    comment: '',
    start_date: '',
    enabled: true,
    accrual_at: [],
  },
) {
  constructor(
    props: EmployeeSalaryDTO = {} as EmployeeSalaryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IEmployeeSalaryModelReturnType = {
      ...props,
    };

    super(options);
  }
}
