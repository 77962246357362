import * as React from 'react';
import { List } from 'immutable';
import {
  useServiceEmployeeList,
  IUseServiceEmployeeListProps,
  IUseServiceEmployeeListReturnType,
} from './useServiceEmployeeList';
import {
  EmployeeMapper,
  EmployeeModel,
  RewardType,
  ServiceModel,
  ServiceParametersFormDTO,
} from '@structure';
import {
  setServiceEmployeeList as storeSetServiceEmployeeList,
  loadMoreServiceEmployeeList as storeLoadMoreServiceEmployeeList,
  updateServiceEmployeeFromList as storeUpdateServiceEmployeeFromList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { useCallback, useEffect, useState } from 'react';
import { useStoredEmployeeServiceList } from './useStoredEmployeeServiceList';
import { correctPrice } from '@services/helpers';

export interface IUseStateServiceEmployeeListProps
  extends IUseServiceEmployeeListProps {
  service: ServiceModel | null;
}

export interface IUseStateServiceEmployeeListReturnType
  extends Omit<IUseServiceEmployeeListReturnType, 'entityList'> {
  serviceEmployeeList: List<EmployeeModel> | null;
  handleSearchServiceEmployees: (keywords: string) => Promise<void>;
  handleLoadMoreServiceEmployees: () => Promise<void>;
  handleRefreshServiceEmployees: (
    value: Partial<IUseServiceEmployeeListProps> & { page: number },
  ) => Promise<void>;
  handleDeleteEmployeesFromListService: (
    employee: EmployeeModel,
  ) => Promise<void>;
  handleAddEmployeeToListService: (employee: EmployeeModel) => Promise<void>;
  handleUpdateEmployeeFromListService: (
    service: ServiceParametersFormDTO,
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredServiceEmployeeList(
  {
    serviceUuid,
    loadOnInit = true,
    service,
    ...rest
  }: IUseStateServiceEmployeeListProps = {} as IUseStateServiceEmployeeListProps,
): IUseStateServiceEmployeeListReturnType {
  const [loadingMore, setLoadingMore] = useState(false);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [serviceEmployeeList, setServiceEmployeeList] =
    useState<List<EmployeeModel> | null>(null);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);

  const {
    status: storedServiceEmployeeListStatus,
    cachedServiceEmployeeList,
    total: storeTotal,
    keywords,
    ...storedServiceEmployeeListParams
  } = useSelector(({ serviceEmployeeList }: RootState) => serviceEmployeeList);

  const dispatch = useDispatch<any>();

  const { entityList, offset, limit, refresh, ...serviceEmployeesParams } =
    useServiceEmployeeList({
      serviceUuid,
      loadOnInit: false,
      ...rest,
    });

  React.useEffect(() => {
    if (cachedServiceEmployeeList?.size > 0) {
      const cachedEmployeeList = cachedServiceEmployeeList.get(serviceUuid);

      if (!keywords) {
        if (cachedEmployeeList) {
          setIsStartLoading(false);
          dispatch(
            storeSetServiceEmployeeList(cachedEmployeeList, serviceUuid),
          );
          setServiceEmployeeList(cachedEmployeeList.employees);
          setStoreKeywords(cachedEmployeeList?.keywords || '');
          setStorePage(cachedEmployeeList?.page || 1);
        } else {
          setIsStartLoading(true);
        }
      }
    }

    if (cachedServiceEmployeeList?.size === 0) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedServiceEmployeeList, serviceUuid, keywords]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      (async () => {
        const employeeList = await refresh({ serviceUuid });

        if (employeeList) {
          dispatch(storeSetServiceEmployeeList(employeeList, serviceUuid, ''));
        }
      })();
    }
  }, [dispatch, entityList, isStarLoading, loadOnInit, refresh, serviceUuid]);

  useEffect(() => {
    return () => {
      dispatch(storeSetServiceEmployeeList(null, serviceUuid, ''));
    };
  }, [dispatch, serviceUuid]);

  const {
    handleAddServiceToListEmployee,
    handleUpdateServiceFromListEmployee,
    handleDeleteServicesFromListEmployee,
  } = useStoredEmployeeServiceList({
    employeeUuid: '',
    loadOnInit: false,
    employee: null,
  });

  const handleSearchServiceEmployees = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);

      const serviceEmployeeListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        showLoading: false,
      });

      if (
        serviceEmployeeListModel &&
        List.isList(serviceEmployeeListModel?.employees)
      ) {
        dispatch(
          storeSetServiceEmployeeList(
            serviceEmployeeListModel,
            serviceUuid,
            keywords,
          ),
        );
        setServiceEmployeeList(serviceEmployeeListModel.employees);
      }

      setLoadingMore(false);
    },
    [dispatch, refresh, serviceUuid],
  );

  const handleLoadMoreServiceEmployees = useCallback(async () => {
    if (
      List.isList(serviceEmployeeList) &&
      serviceEmployeeList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const serviceEmployeeListModel = await refresh({
        offset: serviceEmployeeList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        serviceEmployeeListModel &&
        List.isList(serviceEmployeeListModel?.employees)
      ) {
        dispatch(
          storeLoadMoreServiceEmployeeList(
            serviceEmployeeListModel,
            serviceUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [
    dispatch,
    loadingMore,
    refresh,
    serviceEmployeeList,
    serviceUuid,
    storeTotal,
  ]);

  const handleRefreshServiceEmployees = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseServiceEmployeeListProps> & { page: number }) => {
      const serviceEmployeeListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (
        serviceEmployeeListModel &&
        List.isList(serviceEmployeeListModel?.employees)
      ) {
        dispatch(
          storeSetServiceEmployeeList(
            serviceEmployeeListModel,
            serviceUuid,
            '',
            serviceEmployeeListModel.total,
            page,
          ),
        );
      }
    },
    [dispatch, refresh, serviceUuid],
  );

  const handleAddEmployeeToListService = useCallback(
    async (employee: EmployeeModel) => {
      if (service) {
        const error = await handleAddServiceToListEmployee(
          service,
          employee?.uuid,
        );

        if (!error) {
          const updatedEmployee = employee.update(
            'employee_service',
            () =>
              ({
                price: service?.price,
                duration: service?.period_amount,
                reward_value: 0,
                updated_at: new Date().toString(),
                reward_type: RewardType.Percentage,
              } as any),
          );

          dispatch(
            storeUpdateServiceEmployeeFromList(updatedEmployee, serviceUuid),
          );
        }
      }
    },
    [dispatch, handleAddServiceToListEmployee, service, serviceUuid],
  );

  const handleUpdateEmployeeFromListService = useCallback(
    async ({
      uuid,
      price,
      reward_value,
      reward_type,
      duration,
    }: ServiceParametersFormDTO) => {
      if (service) {
        const updatedService = service.update(
          'employee_service',
          () =>
            ({
              price: correctPrice(price),
              reward_value,
              duration,
              reward_type,
              updated_at: new Date().toString(),
            } as any),
        );

        const error = await handleUpdateServiceFromListEmployee(
          EmployeeMapper.toEmployeeServiceFormDTO(updatedService),
          uuid,
        );

        if (!error) {
          const employee = serviceEmployeeList?.find(
            (employee) => employee?.uuid === uuid,
          );

          if (employee) {
            dispatch(
              storeUpdateServiceEmployeeFromList(
                employee.update(
                  'employee_service',
                  () =>
                    ({
                      price: correctPrice(price),
                      reward_value,
                      duration,
                      reward_type,
                      updated_at: new Date().toString(),
                    } as any),
                ),
                serviceUuid,
              ),
            );
          }
        }
      }
    },
    [
      dispatch,
      handleUpdateServiceFromListEmployee,
      service,
      serviceEmployeeList,
      serviceUuid,
    ],
  );

  const handleDeleteEmployeesFromListService = React.useCallback(
    async (employee: EmployeeModel) => {
      if (service) {
        const error = await handleDeleteServicesFromListEmployee(
          [service?.uuid],
          employee?.uuid,
        );

        if (!error) {
          dispatch(
            storeUpdateServiceEmployeeFromList(
              employee.update(
                'employee_service',
                () =>
                  ({
                    price: '0.00',
                    duration: 0,
                    reward_value: 0,
                    reward_type: RewardType.Percentage,
                    updated_at: '',
                  } as any),
              ),
              serviceUuid,
            ),
          );
        }
      }
    },
    [dispatch, handleDeleteServicesFromListEmployee, service, serviceUuid],
  );

  return {
    ...serviceEmployeesParams,
    ...storedServiceEmployeeListParams,
    serviceEmployeeList,
    loadingMore,
    total: storeTotal,
    offset,
    limit,
    refresh,
    loading: !serviceEmployeeList,
    status: storedServiceEmployeeListStatus,
    keywords: storeKeywords,
    page: storePage,

    handleSearchServiceEmployees,
    handleLoadMoreServiceEmployees,
    handleAddEmployeeToListService,
    handleUpdateEmployeeFromListService,
    handleDeleteEmployeesFromListService,
    handleRefreshServiceEmployees,
  };
}
