import { Record, List } from 'immutable';
import { IListProps } from './List';
import { WithoutRecursion } from '../type';
import { RelatedServiceModel, RelatedServiceDTO } from '../internal';

export interface IRelatedServiceListModelProps extends IListProps {
  services: RelatedServiceDTO[];
}

export interface IRelatedServiceListModelReturnType extends IListProps {
  services: List<RelatedServiceModel>;
}

export class RelatedServiceListModel extends Record<IRelatedServiceListModelReturnType>(
  {
    services: List(),
    total: 0,
  },
) {
  constructor(
    {
      services = [],
      ...props
    }: IRelatedServiceListModelProps = {} as IRelatedServiceListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      ...props,
      services: List(
        services.map(
          (service) => new RelatedServiceModel(service, withoutRecursion),
        ),
      ),
    });
  }
}
