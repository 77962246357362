import { Record } from 'immutable';
import { RuleLimitationDTO, SystemRuleModel } from '../internal';

export interface IRuleLimitationModelReturnType {
  system_rule_limit: number;
  system_rule: SystemRuleModel;
}

export class RuleLimitationModel extends Record<IRuleLimitationModelReturnType>(
  {
    system_rule_limit: 0,
    system_rule: {} as SystemRuleModel,
  },
) {
  constructor(props: RuleLimitationDTO = {} as RuleLimitationDTO) {
    const option: IRuleLimitationModelReturnType = {
      ...props,
      system_rule: new SystemRuleModel(props?.system_rule),
    };

    super(option);
  }
}
