import React from 'react';
import { Routes, Route } from 'react-router';
import {
  TariffModelListPage,
  TariffModelPage,
  TariffModelGroupPage,
  TariffModelGroupListPage,
  TariffModelClientAbonementCalendarPage,
} from '../views/TariffModels';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';
import { ClientAbonementListPage, ClientAbonementPage } from '../views/Clients';
import { CalendarRoutes } from './CalendarRoutes';

export function TariffModelRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <TariffModelListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":tariffModelId"
        element={
          <AppRouteLayout>
            <TariffModelPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.clientAbonements}`}
        element={
          <AppRouteLayout>
            <ClientAbonementListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.clientAbonements}/:abonementId`}
        element={
          <AppRouteLayout>
            <ClientAbonementPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.clientAbonements}/:abonementId/${RoutesType.schedules}/*`}
        element={
          <CalendarRoutes
            CalendarComponent={TariffModelClientAbonementCalendarPage}
          />
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.tariffModelGroup}`}
        element={
          <AppRouteLayout>
            <TariffModelGroupListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:tariffModelId/${RoutesType.tariffModelGroup}/:tariffModelGroupId`}
        element={
          <AppRouteLayout>
            <TariffModelGroupPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.tariffModelGroup}/:tariffModelGroupId/${RoutesType.clientAbonements}`}
        element={
          <AppRouteLayout>
            <ClientAbonementListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.tariffModelGroup}/:tariffModelGroupId/${RoutesType.clientAbonements}/:abonementId`}
        element={
          <AppRouteLayout>
            <ClientAbonementPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:tariffModelId/${RoutesType.tariffModelGroup}/:tariffModelGroupId/${RoutesType.clientAbonements}/:abonementId/${RoutesType.schedules}/*`}
        element={
          <CalendarRoutes
            CalendarComponent={TariffModelClientAbonementCalendarPage}
          />
        }
      />
    </Routes>
  );
}
