import { produce } from 'immer';
import { EmployeeDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { isFunction } from '@services/helpers';
import React, { useCallback, useRef } from 'react';
import { Col, Row, Form, Input, Alert } from 'antd';
import { EMPLOYEE_SHIFT_INITIAL_DATA } from '@services/api/employee';

import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { EmployeeShiftFormDTO } from 'structure/dto/EmployeeShiftFormDTO';

export interface IEmployeeShiftFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  employee: EmployeeDTO;
  onCancel?: () => void;
  onSuccess: (value: EmployeeShiftFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  employeeShift?: EmployeeShiftFormDTO;
}

/**
 * @desc Відкриття зміни для співробітника
 **/
export default function EmployeeOpenShiftForm({
  loading,
  editMode,
  employee,
  onSuccess,
  employeeShift = EMPLOYEE_SHIFT_INITIAL_DATA,
  ...rest
}: IEmployeeShiftFormProps) {
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const handleOnSuccess = useCallback(
    (formData: EmployeeShiftFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(formData, (draft) => {
          draft.employeeUuid = employee.uuid;
        });
        return onSuccess(updatedValue);
      }
    },
    [employee.uuid, onSuccess],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t(
          employee?.hasOpenedShift()
            ? 'Close employee shift'
            : 'Open employee shift',
        ),
        `${t(`An error occurred during the employee shift manipulation`)}: ${
          apiError?.message
        } `,
      );
    },
    [t, employee, alert],
  );

  return (
    <DefaultForm<EmployeeShiftFormDTO, EmployeeShiftFormDTO>
      initialValues={{
        ...employeeShift,
      }}
      notifyError={notifyError}
      showNotify={false}
      editMode={editMode}
      onSuccess={handleOnSuccess}
      submitButtonText={t('Open employee shift')}
      {...rest}>
      {({ loadingSubmit }) => (
        <>
          <Row gutter={20} ref={contentRef}>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Alert
                showIcon
                type="info"
                message={t('Shift operations')}
                description={t(
                  'Opening the work shift aims to control the employee activities and configure related bonuses',
                )}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                tooltip={t('Comment to the employee shift opening process')}
                label={t('Comment')}
                name="comment">
                <Input.TextArea
                  data-testid="employee-shift-input-comment"
                  disabled={loading || loadingSubmit}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={t('Enter comment')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </DefaultForm>
  );
}
