import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { ClientAbonementSubscriptionFormDTO } from '@structure';
import { useDropdownAlert } from '@contex';
import { ClientAbonementSubscriptionFields } from './ClientAbonementSubscriptionFields';

export interface IClientAbonementSubscriptionFormProps
  extends Omit<
    IDefaultFormProps<
      ClientAbonementSubscriptionFormDTO,
      ClientAbonementSubscriptionFormDTO
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  subscription: ClientAbonementSubscriptionFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
}

export function ClientAbonementSubscriptionForm({
  loading,
  editMode,
  subscription,
  isFullForm = true,
  autoFocus,
  onCancel,
  ...rest
}: IClientAbonementSubscriptionFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client abonement subscription'),
        `${
          editMode
            ? t('An error occurred during extend client abonement subscription')
            : t('An error occurred during add client abonement subscription')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={subscription}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => <ClientAbonementSubscriptionFields />}
    </DefaultForm>
  );
}
