import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientModel } from '@structure';
import { useStoredCompanies } from '@hooks';
import styled, { useTheme } from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { correctPrice } from '@services/helpers';
import { StyledDescription } from '@components/lib/Styled';

export interface IClientBalanceViewProps {
  client: ClientModel | null;
}

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;
const StyledClientBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export function ClientBalanceView(props: IClientBalanceViewProps): JSX.Element {
  const theme: any = useTheme();
  const { client } = props || {};
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  return (
    <StyledClientBalanceContainer>
      <StyledDescription
        $color={
          Number(client?.saldo) >= 0 ? theme.colors.success : theme.colors.error
        }>
        {`${t('Balance')}(${defaultCompany?.currency_symbol}): ${correctPrice(
          client?.saldo,
        )}`}
      </StyledDescription>
      {Number(client?.saldo) > 0 || Number(client?.saldo) < 0 ? (
        <Tooltip
          title={
            Number(client?.saldo) >= 0
              ? t('We are guilty')
              : t('We are to blame')
          }>
          <StyledInfoCircleOutlined
            $color={
              Number(client?.saldo) >= 0
                ? theme.colors.success
                : theme.colors.error
            }
          />
        </Tooltip>
      ) : null}
    </StyledClientBalanceContainer>
  );
}
