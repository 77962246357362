import { Dispatch } from 'redux';
import { CASH_BOX } from '../constants';
import { CashBoxModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetCashBoxParam {
  type: CASH_BOX;
  cashBox: CashBoxModel | null;
}

export interface IUpdateCashBoxParam {
  type: CASH_BOX;
  cashBox: CashBoxModel;
}

export interface IResetCashBoxParam {
  type: CASH_BOX;
}

export interface IErrorCashBoxParam {
  type: CASH_BOX;
  error: ApiError | undefined;
}

export function setCashBox(
  cashBox: CashBoxModel | null,
): (dispatch: Dispatch) => Promise<ISetCashBoxParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX.SET_CASH_BOX,
      cashBox,
    });
  };
}

export function updateCashBox(
  cashBox: CashBoxModel,
): (dispatch: Dispatch) => Promise<IUpdateCashBoxParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX.UPDATE_CASH_BOX,
      cashBox,
    });
  };
}

export function resetCashBox(): (
  dispatch: Dispatch,
) => Promise<IResetCashBoxParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX.RESET_CASH_BOX,
    });
  };
}

export function errorCashBox(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorCashBoxParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX.ERROR_CASH_BOX,
      error,
    });
  };
}
