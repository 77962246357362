import React from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { StoreFormDTO } from '@structure';
import { AddButton } from '@components/lib/DataDisplay';
import { MinusOutlined } from '@ant-design/icons';

export interface ILeftoverMinusProductButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess?: (value: StoreFormDTO) => Promise<void>;
  onCancel?: () => void;
  store?: StoreFormDTO;
}

export function LeftoverMinusProductButton({
  children,
  onSuccess,
  onCancel,
  loading,
  store,
  ...rest
}: ILeftoverMinusProductButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <AddButton
        tooltipTitle={t('Remove from pre-order')}
        loading={loading}
        {...rest}>
        <MinusOutlined />
      </AddButton>
    </>
  );
}
