import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { PRODUCT_CATEGORY_INITIAL_PARAM } from '@services/api/product';
import {
  ProductCategoryFormDTO,
  ProductCategoryMappedType,
  ProductCategoryType,
} from '@structure';
import { useDropdownAlert } from '@contex';
import { ProductCategoryFields } from './ProductCategoryFields';
import {
  useProductCategoryListToSelectTree,
  useStoredCompanies,
  useStoredProductCategoryList,
} from '@hooks';
import { isFunction } from '@services/helpers';

export interface IProductCategoryFormProps
  extends Omit<
    IDefaultFormProps<ProductCategoryFormDTO, ProductCategoryFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  category?: ProductCategoryFormDTO;
  categoryEditRef?: MutableRefObject<ProductCategoryFormDTO>;
}

export function ProductCategoryForm({
  loading,
  editMode,
  category = PRODUCT_CATEGORY_INITIAL_PARAM,
  onCancel,
  onSuccess,
  categoryEditRef,
  ...rest
}: IProductCategoryFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const { defaultCompanyUuid } = useStoredCompanies();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: category?.uuid,
    }),
    [category?.uuid],
  );

  const {
    categoryList,
    loading: categoryListLoading,
    handleSearchProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    productCategoryType: ProductCategoryType.TREE,
    mappedProductCategoryType: ProductCategoryMappedType.FORMS_TREE,
  });

  const { treeData } = useProductCategoryListToSelectTree({
    categories: categoryList,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Materials category'),
        `${
          editMode
            ? t('An error occurred during edit materials category')
            : t('An error occurred during create materials category')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  const handleSuccess = useCallback(
    async (value: ProductCategoryFormDTO) => {
      const updatedValue = (({
        unit_packaging,
        ...rest
      }: ProductCategoryFormDTO) => {
        return {
          unit_packaging: unit_packaging.map(
            ({ uuid, index, ...packaging }) => ({
              ...packaging,
            }),
          ),
          ...rest,
        };
      })(value);

      if (isFunction(onSuccess)) {
        await onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  const onUnitPackagingEnter = useCallback((e: KeyboardEvent) => {
    if (e?.target instanceof HTMLElement) {
      const index = Number(e?.target?.dataset?.itemIndex);
      const cellIndex = Number(e?.target?.dataset?.cellIndex);

      if (!Number.isNaN(cellIndex) && cellIndex < 2) {
        const field = document.body.querySelector(
          `.editable-cell${index}${cellIndex + 1}`,
        );

        if (field instanceof HTMLElement) {
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
          });

          e?.target?.blur();
          field?.dispatchEvent(clickEvent);
        }

        return false;
      }

      return true;
    }

    return true;
  }, []);

  return (
    <DefaultForm<any, any>
      onEnterPress={onUnitPackagingEnter}
      withContext
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...category,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      onSuccess={handleSuccess}
      {...rest}>
      <ProductCategoryFields
        loading={!!loading}
        editMode={!!editMode}
        categoryList={categoryList}
        categoryListLoading={categoryListLoading}
        handleSearchProductCategories={handleSearchProductCategories}
        currentCategoryUuid={category?.uuid}
        disabledCategoryField={!!category?.disabled_category_field}
        categoryEditRef={categoryEditRef}
        treeData={treeData}
      />
    </DefaultForm>
  );
}
