import React, { useCallback } from 'react';
import { ICalendarManagerProps } from '@contex';
import { ScheduleCalendarModel } from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import EmployeeCalendarPopover from '../Show/EmployeeCalendarPopover';
import EmployeeScheduleDetails from '../Show/EmployeeScheduleDetails';
import { SingleLayout } from '@components/lib/Layout';
import {
  useDefaultCalendarScheduleProps,
  useEmployeeScheduleList,
  useStoredCompanies,
} from '@hooks';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { textToUpperCase } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useStateEmployeeScheduleList from '../../../hooks/useStateEmployeeScheduleList';
import { CALENDAR } from '@components/lib/const';

export interface IEmployeeCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export function EmployeeCalendarPage({
  children,
}: IEmployeeCalendarPageProps): JSX.Element {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { defaultCompany } = useStoredCompanies();
  const defaultCalendarProps = useDefaultCalendarScheduleProps();

  const {
    employeeSchedules,
    refresh: employeeSchedulesRefresh,
    loading: employeeSchedulesLoaded,

    handleCreateSchedule,
  } = useStateEmployeeScheduleList({
    employeeUuid: employeeId!,
    loadOnInit: false,
    limit: 100,
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${Routes.employees}/${employeeId}`,
      breadcrumbName: t('Show Employee'),
    },
    {
      path: `/${Routes.employees}/${employeeId}/${Routes.schedules}/${CALENDAR}`,
      breadcrumbName: 'Calendar',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.employees}/${employeeId}`,
    );
  }, [employeeId, location?.state?.goBack, navigate]);

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        defaultCompany?.title || t(RoutesTitle[RoutesType.settings]),
      )}
      routes={routes}>
      {children({
        ...defaultCalendarProps,
        localStorageName: 'employeeCalendarDate',
        schedules: employeeSchedules!,
        onRefresh: employeeSchedulesRefresh,
        SchedulePopover: EmployeeCalendarPopover,
        scheduleDetails: (children: any, schedule) => (
          // @ts-ignore
          <EmployeeScheduleDetails
            routes={routes}
            schedule={schedule}
            employeeFullName={location?.state?.fullName || ''}>
            {({ routes, navigateGoBack }) =>
              children({ routes, navigateGoBack })
            }
          </EmployeeScheduleDetails>
        ),
        customDayTimeRange: (defaultCompany &&
          defaultCompany?.work_schedules) as any,
        loading: employeeSchedulesLoaded,
        withDnDEventsProps: {
          onSuccess: handleCreateSchedule,
          order: { worker_uuid: employeeId },
        },
        isClearDnDCache: true,
      })}
    </SingleLayout>
  );
}
