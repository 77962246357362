import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceStatus } from '@structure';
import styled, { useTheme, css } from 'styled-components';
import { IUseStateInvoiceReturnType } from '@hooks';
import { StyledTitle } from '@components/lib/Styled';
import { DocumentNumber } from '@components/lib/General';
import { InvoiceStatusListSelect } from '../Show/InvoiceStatusListSelect';

export interface InvoicePageHeaderProps
  extends Pick<
    IUseStateInvoiceReturnType,
    'invoice' | 'handleUpdateInvoiceStatus'
  > {
  withoutIndent?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledWrapper = styled.div<{ $withoutIndent: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({ $withoutIndent }) =>
    !$withoutIndent &&
    css`
      padding-left: 24px;
      padding-right: 24px;
    `}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 3px;
`;

const StyledInvoiceStatusListSelect = styled(InvoiceStatusListSelect)`
  &&& {
    .ant-select-selection-item {
      padding: 0 !important;
      padding-inline-end: 0 !important;
    }

    .ant-select-selector {
      padding: 0px !important;
    }
  }
`;

export function InvoicePageHeader({
  invoice,
  handleUpdateInvoiceStatus,
  withoutIndent,
}: InvoicePageHeaderProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  return (
    <StyledWrapper $withoutIndent={!!withoutIndent}>
      <DocumentNumber
        isDraft={invoice?.inv_status === InvoiceStatus.DRAFT}
        number={`${invoice?.inv_internal_number}`}
        date={invoice?.inv_date}>
        <StyledInvoiceStatusListSelect
          loading={false}
          invoice={invoice}
          handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
        />
      </DocumentNumber>
    </StyledWrapper>
  );
}
