import { Record, List } from 'immutable';
import { IListProps } from './List';
import { ExpenseDTO, ExpenseModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IExpenseListModelProps extends IListProps {
  schedule_expenses: ExpenseDTO[];
}

export interface IExpenseListModelReturnType extends IListProps {
  schedule_expenses: List<ExpenseModel>;
}

export class ExpenseListModel extends Record<IExpenseListModelReturnType>({
  schedule_expenses: List(),
  total: 0,
}) {
  constructor(
    {
      schedule_expenses = [],
      ...props
    }: IExpenseListModelProps = {} as IExpenseListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IExpenseListModelReturnType = {
      ...props,
      schedule_expenses: List(
        schedule_expenses.map((expense) => new ExpenseModel(expense)),
      ),
    };

    super(options);
  }
}
