import React from 'react';
import { GroupedIntegrationList } from '../Pages';
import styled from 'styled-components';
import { IntegrationCategoryView } from './IntegrationCategoryView';
import { IntegrationListView } from './IntegrationListView';
import { IUseStateIntegrationListReturnType } from '@hooks';

export interface IntegrationGroupedListProps
  extends Pick<
    IUseStateIntegrationListReturnType,
    'handleAddOrUpdateIntegration' | 'handleGetIntegration'
  > {
  groupedIntegrationList: GroupedIntegrationList;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
`;

export function IntegrationGroupedList({
  groupedIntegrationList,
  ...rest
}: IntegrationGroupedListProps): React.JSX.Element {
  return (
    <>
      {groupedIntegrationList
        ? groupedIntegrationList?.toArray()?.map(([category, integrations]) => (
            <StyledContainer key={category?.uuid}>
              <IntegrationCategoryView category={category} />
              <IntegrationListView integrations={integrations} {...rest} />
            </StyledContainer>
          ))
        : null}
    </>
  );
}
