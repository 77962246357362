import { FORGOT_PASSWORD_PAGE_STATE } from '../constants';
import {
  IForgotPasswordPageState,
  forgotPasswordPageInitialState,
} from '../actions';

interface SetAction extends IForgotPasswordPageState {
  type: FORGOT_PASSWORD_PAGE_STATE.SET_STATE;
  state: IForgotPasswordPageState;
}

interface UpdateService {
  type: FORGOT_PASSWORD_PAGE_STATE.UPDATE_STATE;
  state: Partial<IForgotPasswordPageState>;
}

interface ResetAction {
  type: FORGOT_PASSWORD_PAGE_STATE.RESET_STATE;
  state: IForgotPasswordPageState;
}

interface Handlers {
  [FORGOT_PASSWORD_PAGE_STATE.SET_STATE]: (
    state: IForgotPasswordPageState,
    action: SetAction,
  ) => IForgotPasswordPageState;

  [FORGOT_PASSWORD_PAGE_STATE.RESET_STATE]: (
    state: IForgotPasswordPageState,
    action: ResetAction,
  ) => IForgotPasswordPageState;

  [FORGOT_PASSWORD_PAGE_STATE.UPDATE_STATE]: (
    state: IForgotPasswordPageState,
    action: UpdateService,
  ) => IForgotPasswordPageState;

  DEFAULT: (state: IForgotPasswordPageState) => IForgotPasswordPageState;
}

const initState: IForgotPasswordPageState = forgotPasswordPageInitialState;

const handlers: Handlers = {
  [FORGOT_PASSWORD_PAGE_STATE.SET_STATE]: (state, { state: value }) => ({
    ...state,
    ...value,
    loading: false,
  }),

  [FORGOT_PASSWORD_PAGE_STATE.RESET_STATE]: (state, { state: value }) => ({
    ...state,
    ...value,
  }),

  [FORGOT_PASSWORD_PAGE_STATE.UPDATE_STATE]: (state, { state: value }) => ({
    ...state,
    ...value,
  }),

  DEFAULT: (state: IForgotPasswordPageState) => state,
};

export default function Service(
  state: any = initState,
  action: any,
): IForgotPasswordPageState {
  const handler =
    handlers[action.type as FORGOT_PASSWORD_PAGE_STATE] || handlers.DEFAULT;
  return handler(state, action);
}
