import { Dispatch } from 'redux';
import { List } from 'immutable';
import { FILE_LIST } from '../constants';
import { FileModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetFileListParam {
  type: FILE_LIST;
  fileList: List<FileModel>;
  scheduleUuid: string;
  keywords?: string;
  total?: number;
}

export interface IAddFileToListParam {
  type: FILE_LIST;
  file: FileModel;
  scheduleUuid: string;
}

export interface IUpdateFileFromListParam {
  type: FILE_LIST;
  file: FileModel;
  scheduleUuid: string;
}

export interface IDeleteFileFromListParam {
  type: FILE_LIST;
  fileUuid: string;
  scheduleUuid: string;
}

export interface IErrorFileListParam {
  type: FILE_LIST;
  error: ApiError | undefined;
}

export function setFileList(
  fileList: List<FileModel>,
  scheduleUuid: string,
  keywords?: string,
  total?: number,
): (dispatch: Dispatch) => Promise<ISetFileListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.SET_FILE_LIST,
      fileList,
      scheduleUuid,
      keywords,
      total,
    });
  };
}

export function loadMoreFileList(
  fileList: List<FileModel>,
  scheduleUuid: string,
): (dispatch: Dispatch) => Promise<ISetFileListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.LOAD_MORE_FILE_LIST,
      fileList,
      scheduleUuid,
    });
  };
}

export function addFileToList(
  file: FileModel,
  scheduleUuid: string,
): (dispatch: Dispatch) => Promise<IAddFileToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.ADD_FILE,
      file,
      scheduleUuid,
    });
  };
}

export function updateFileFromList(
  file: FileModel,
  scheduleUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateFileFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.UPDATE_FILE,
      file,
      scheduleUuid,
    });
  };
}

export function deleteFileFromList(
  fileUuid: string,
  scheduleUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteFileFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.DELETE_FILE,
      fileUuid,
      scheduleUuid,
    });
  };
}

export function errorFileInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorFileListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: FILE_LIST.ERROR_FILE_LIST,
      error,
    });
  };
}
