import * as React from 'react';
import { List } from 'immutable';
import {
  useProductList,
  IUseProductListProps,
  IUseProductListReturnType,
} from './useProductList';
import { ProductModel, ProductFormDTO, ProductListModel } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import { useStoredProduct } from './useStoredProduct';
import { useMemo, useState } from 'react';

export interface UseStateProductListProps extends IUseProductListProps {}

export interface UseStateProductListReturnType
  extends Omit<IUseProductListReturnType, 'entityList'> {
  productList: List<ProductModel> | null;
  isLoadingMore: boolean;
  handleCreateProduct: (value: ProductFormDTO) => Promise<void>;
  handleSearchProductList: (
    value: Partial<IUseProductListProps>,
  ) => Promise<any>;
  handleLoadingMoreProductList: (
    value: Partial<IUseProductListProps>,
  ) => Promise<List<ProductModel> | void>;
  loadingMore: boolean;
  handleSetProductList: (value: List<ProductModel>) => void;
}

export function useStateProductList(
  {
    companyUuid,
    ...rest
  }: UseStateProductListProps = {} as UseStateProductListProps,
): UseStateProductListReturnType {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: productLoading,
    total: productListTotal,
    keywords: productKeywords,
    ...productsParams
  } = useProductList({
    companyUuid,
    ...rest,
  });

  const { handleCreateProduct: storeHandleCreateProduct } = useStoredProduct({
    companyUuid,
    productUuid: '',
    loadOnInit: false,
  });

  const {
    entityList: productList,
    loading: stateLoading,
    handleCreate,
    setEntityList,
    total,
  } = useStateEntityList<ProductModel>({
    entityList: entityList?.products!,
    refresh,
    limit,
    offset,
    total: productListTotal,
    once: true,
  });

  const size = useMemo(() => productList?.size || 0, [productList?.size]);
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const loading = productLoading || stateLoading || searchLoading;

  const handleCreateProduct = React.useCallback(
    async (value: ProductFormDTO) => {
      const product = await storeHandleCreateProduct(value);

      if (product) {
        handleCreate(product, true);
      }
    },
    [storeHandleCreateProduct, handleCreate],
  );

  const handleSearchProductList = React.useCallback(
    async ({
      keywords,
      offset = 0,
      limit = 10,
      showLoading = true,
      ...rest
    }: Omit<IUseProductListProps, 'companyUuid'>) => {
      setSearchLoading(true);
      const productList = await refresh({
        offset,
        keywords,
        showLoading,
        limit,
        ...rest,
      });

      if (productList && productList?.products) {
        setEntityList(productList?.products);

        setSearchLoading(false);
        return productList;
      }

      setSearchLoading(false);
    },
    [refresh, setEntityList],
  );

  const handleLoadingMoreProductList = React.useCallback(
    async ({
      keywords = productKeywords,
      showLoading = false,
      limit = 10,
      offset = productList?.size,
      ...rest
    }: Omit<IUseProductListProps, 'companyUuid'> = {}) => {
      if (size < total) {
        setLoadingMore(true);

        const apiProductList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (apiProductList && apiProductList?.products) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiProductList?.products),
          );
          setLoadingMore(false);

          return apiProductList?.products;
        }
        setLoadingMore(false);
      }
    },
    [productKeywords, productList, refresh, setEntityList, size, total],
  );

  const handleSetProductList = React.useCallback(
    (productList: List<ProductModel>) => {
      setEntityList(productList);
    },
    [setEntityList],
  );

  return {
    productList,
    offset,
    limit,
    refresh,
    total,
    loading,
    keywords: productKeywords,
    isLoadingMore,
    ...productsParams,
    handleCreateProduct,
    handleSearchProductList,
    handleLoadingMoreProductList,
    loadingMore,
    handleSetProductList,
  };
}
