import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len } from '@services/helpers';
import { useDelete } from '@components/lib/libV2/hooks';
import { ClientModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IClientsDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  clients: ClientModel[];
}

export default function ClientsDeleteButton({
  children,
  clients,
  onSuccess,
  onError,
  title,
  ...rest
}: IClientsDeleteButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: clients,
    notifyTitle: 'Counterparties',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company client')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(clients)} ${
            len(clients)
              ? t('clienT').toLowerCase()
              : t('clients').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
