import * as React from 'react';
import { getProductCategoryById } from '@services/api/product';
import {
  ProductCategoryModel,
  ProductCategoryMapper,
  ProductCategoryDTO,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { IEntityProps } from '@services/types';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseProductCategoryProps extends IEntityProps {
  categoryUuid: string;
}

export interface IUseProductCategoryReturnType {
  error: null | any;
  loading: boolean;
  entity: ProductCategoryModel | null;
  refresh: (
    value: Partial<IUseProductCategoryProps>,
  ) => Promise<ProductCategoryModel | void>;
  categoryUuid: string;
}

export function useProductCategory(
  {
    loadOnInit = true,
    categoryUuid,
  }: IUseProductCategoryProps = {} as IUseProductCategoryProps,
): IUseProductCategoryReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    categoryUuid: entityUuid,
  } = useStateReducer<Omit<IUseProductCategoryReturnType, 'refresh'>>({
    entity: null,
    categoryUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      categoryUuid = entityUuid,
    }: Partial<IUseProductCategoryProps> = {}): Promise<ProductCategoryModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null, categoryUuid });

        const category = await cancellablePromise<ProductCategoryDTO>(
          getProductCategoryById(categoryUuid),
        );

        const categoryModel =
          ProductCategoryMapper.toProductCategoryModel(category);

        handleUpdate({
          entity: categoryModel,
          loading: false,
        });
        return categoryModel;
      } catch (err: any) {
        alert(
          'error',
          t('Materials category'),
          `${t('An error occurred during get materials category')} : ${
            err?.message
          }`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && categoryUuid) {
      (async () => {
        await refresh({ categoryUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, categoryUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    categoryUuid,
  };
}
