import * as React from 'react';
import { List } from 'immutable';
import moment from 'moment';
import {
  useServiceList,
  IUseServiceListProps,
  IUseServiceListReturnType,
} from './useServiceList';
import { ServiceModel, ServiceFormDTO, ServiceMapper } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';
import {
  createService,
  updateService,
  deleteServices,
} from '@services/api/services';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';
import { SetStateAction, useMemo, useState } from 'react';
import { updateService as storeUpdateService } from '@store/actions';
import { useDispatch } from 'react-redux';

export interface IUseStateServiceListProps extends IUseServiceListProps {}

export interface IServiceSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}
export interface IUseStateServiceListReturnType
  extends Omit<IUseServiceListReturnType, 'entityList'> {
  services: List<ServiceModel> | null;
  handleCreateService: (value: ServiceFormDTO) => Promise<ServiceModel | void>;
  handleUpdateService: (value: ServiceFormDTO) => Promise<ServiceModel>;
  handleDeleteServices: (ids: string[]) => Promise<void>;
  handleSearchServices: (
    value: IServiceSearchProps,
  ) => Promise<List<ServiceModel> | void>;
  handleLoadingMoreServiceList: (
    value: Partial<IUseServiceListProps>,
  ) => Promise<List<ServiceModel> | void>;
  loadingMore: boolean;
  handleSetServiceList: React.Dispatch<
    SetStateAction<List<ServiceModel> | null>
  >;
  isLoadingMore: boolean;
}

export function useStateServiceList(
  {
    companyUuid,
    ...rest
  }: IUseStateServiceListProps = {} as IUseStateServiceListProps,
): IUseStateServiceListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: serviceListLoading,
    total: serviceListTotal,
    keywords: serviceKeywords,
    ...clientsParams
  } = useServiceList({
    companyUuid,
    ...rest,
  });

  const {
    entityList: services,
    loading: stateLoading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<ServiceModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: serviceListTotal,
  });

  const dispatch = useDispatch<any>();

  const size = useMemo(() => services?.size || 0, [services?.size]);
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const [searchLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const loading = serviceListLoading || stateLoading || searchLoading;

  const handleCreateService = React.useCallback(
    async (value: ServiceFormDTO) => {
      const service = await createService(value, companyUuid);
      const serviceModel = ServiceMapper.toServiceModel(service);

      const updatedModel = serviceModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      alert('success', t('Service'), t('Service created success'));

      handleCreate(updatedModel, true);

      return serviceModel;
    },
    [companyUuid, alert, t, handleCreate],
  );

  const handleUpdateService = React.useCallback(
    async (value: ServiceFormDTO) => {
      const service = await updateService(value, companyUuid);
      const serviceModel = ServiceMapper.toServiceModel(service);

      alert('success', t('Service'), t('Service edited success'));

      handleUpdate(serviceModel);

      dispatch(storeUpdateService(serviceModel));

      return serviceModel;
    },
    [alert, companyUuid, dispatch, handleUpdate, t],
  );

  const handleDeleteServices = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteServices(ids);

        handleDelete(ids);
        alert('success', t('Service'), t('Service delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Service'),
          `${t('An error occurred during delete services')}: ${error?.message}`,
        );
      }
    },
    [alert, handleDelete, t],
  );

  const handleSearchServices = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading = true,
    }: IServiceSearchProps) => {
      const services = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (services) {
        setEntityList(services);

        return services;
      }
    },
    [refresh, setEntityList],
  );

  const handleLoadingMoreServiceList = React.useCallback(
    async ({
      keywords = serviceKeywords,
      showLoading = false,
      limit = 10,
      offset = services?.size,
      ...rest
    }: Omit<IUseServiceListProps, 'companyUuid'> = {}) => {
      if (size < total) {
        setLoadingMore(true);

        const apiServiceList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (List.isList(apiServiceList)) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiServiceList),
          );
          setLoadingMore(false);

          return apiServiceList;
        }
        setLoadingMore(false);
      }
    },
    [refresh, serviceKeywords, services?.size, setEntityList, size, total],
  );

  return {
    services,
    offset,
    limit,
    refresh,
    total,
    loading: serviceListLoading || loading,
    keywords: serviceKeywords,
    ...clientsParams,
    handleCreateService,
    handleUpdateService,
    handleDeleteServices,
    handleSearchServices,
    handleLoadingMoreServiceList,
    loadingMore,
    isLoadingMore,
    handleSetServiceList: setEntityList,
  };
}
