import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { DatePicker } from '@components/lib/General';
import {
  Button,
  DetailsView,
  FormSwitch,
  SearchSelect,
} from '@components/lib/DataDisplay';
import { correctPrice, decryptObject, listToArray } from '@services/helpers';
import {
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  DEFAULT_EXTRA_SERVICES,
  DEFAULT_MULTI_CURRENCY,
  StoreDTO,
  StoreMapper,
} from '@structure';
import {
  IUseStateCompanyClientListReturnType,
  IUseStateStoreListReturnType,
  useStoredCompanies,
} from '@hooks';
import { SessionStorageItems } from '@services/const';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import { ClientCreateButton } from '../../../Clients';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { StoreDocumentRefObject } from '../../helpers';

export interface IStorePreorderFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients' | 'handleCreateLocalClient'
    >,
    Pick<IUseStateStoreListReturnType, 'storeList' | 'handleSearchStores'> {
  loading: boolean;
  editMode: boolean;
  clientsLoading: boolean;
  storeListLoading: boolean;
  doc_extra_services: any;
  doc_multi_currencies: any;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 20px 20px 0 20px;

  width: 100%;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid #d9d9d9;
`;

export const Title = styled(StyledTitle)`
  position: absolute;
  top: -14px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: ${({ theme }) => theme.background.primary};

  font-size: 16px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
`;

export function StorePreorderFields({
  loading,
  editMode,

  clients,
  clientsLoading,
  handleSearchClients,

  storeList,
  storeListLoading,
  handleCreateLocalClient,
  handleSearchStores,
  doc_extra_services,
  doc_multi_currencies,

  documentEditRef,
}: IStorePreorderFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const {
    loadingSubmit,
    formData,
    handlerUpdateFormState,
    getFieldValue,
    setFieldsValue,
  }: any = useDefaultForm();
  const { defaultCompany } = useStoredCompanies();

  const onceClient = useRef(false);
  const onceStore = useRef(false);

  const handleQuicklyCreateClient = useCallback(
    async ({ t, ...value }: any): Promise<void> => {
      const uuid = `create${
        (value as ClientFormDTO)?.first_name ||
        (value as ClientLegalFormDTO)?.client_org_name
      }${value.phone}`;

      await handleCreateLocalClient({
        ...value,
        uuid,
      } as any);

      handlerUpdateFormState({
        doc_client: {
          ...value,
          uuid,
        },
      });
    },
    [handleCreateLocalClient, handlerUpdateFormState],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      if (
        formData?.doc_client?.uuid !== e ||
        formData?.doc_client instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          const client = clients?.find(({ uuid }) => uuid === e);

          if (client) {
            let doc_client;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              doc_client = ClientMapper.toClientFormDTO(client);
            } else {
              doc_client = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({ doc_client });
          }
          return;
        }

        handlerUpdateFormState({ doc_client: { uuid: e } });
      }
    },
    [clients, formData?.doc_client, handlerUpdateFormState],
  );

  useEffect(() => {
    const serializeStoreData = sessionStorage.getItem(
      SessionStorageItems.preorderFormStoreData,
    );

    if (!editMode && serializeStoreData && !onceStore?.current) {
      onceStore.current = true;
      const storeDTO: StoreDTO = decryptObject(serializeStoreData);

      const storeModel = StoreMapper.toStoreModel(storeDTO);

      handlerUpdateFormState({ doc_store_uuid: storeModel });
    }
  }, [editMode, handlerUpdateFormState]);

  const totalServices = doc_extra_services?.reduce(
    (acc: number, { price }: any) => {
      acc += Number(price || 0);
      return acc;
    },
    0,
  );

  useEffect(() => {
    if (documentEditRef?.current) {
      const { errorFields, ...rest } = formData;

      documentEditRef.current.document = {
        ...rest,
        doc_extra_services: doc_extra_services || [],
        doc_multi_currencies: doc_multi_currencies || [],
      };
    }
  }, [doc_extra_services, doc_multi_currencies, documentEditRef, formData]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          label={t('Date')}
          name="doc_date"
          rules={[
            {
              required: true,
              message: t('Date be specified.'),
            },
          ]}>
          <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(doc_date) => handlerUpdateFormState({ doc_date })}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          required
          label={t('Provider')}
          name="doc_client"
          tooltip={t('Provider')}
          rules={[
            {
              validator: () => {
                if (!formData?.doc_client?.uuid) {
                  return Promise.reject(new Error(t('Provider be specified.')));
                }
                return Promise.resolve();
              },
            },
          ]}>
          <SearchSelect
            asObject={true}
            selectFirst={clients?.size === 1}
            name="doc_client"
            disable={loading || clientsLoading || loadingSubmit}
            placeholder={t('Select a provider')}
            data={listToArray(clients as any)}
            valuePropName="uuid"
            getOptionValueTitle="fullNameClient"
            onSearch={(keywords) =>
              handleSearchClients({ keywords, showLoading: false }) as any
            }
            resetState={clientsLoading}
            onChange={handleChangeClient}
            addonAfter={
              <ClientCreateButton
                provider
                disabled={loading || loadingSubmit}
                onSuccess={handleQuicklyCreateClient}
                autoFocus>
                <PlusOutlined />
              </ClientCreateButton>
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Store posting')}
          name="doc_store_uuid"
          tooltip={t('Store posting')}
          rules={[
            {
              required: true,
              message: t('Store posting be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_store_uuid"
            disable={loading || storeListLoading || loadingSubmit || editMode}
            placeholder={t('Select a store')}
            data={listToArray(storeList as any)}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({ keywords })}
            resetState={storeListLoading}
            onChange={(doc_store_uuid) =>
              handlerUpdateFormState({ doc_store_uuid })
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit}
        name="is_doc_extra_services"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Add services to the document')}
        onChange={(is_doc_extra_services) => {
          handlerUpdateFormState({ is_doc_extra_services });

          if (is_doc_extra_services) {
            handlerUpdateFormState({
              doc_extra_services: DEFAULT_EXTRA_SERVICES,
            });
          } else {
            handlerUpdateFormState({ doc_extra_services: [] });
          }
        }}
      />

      {formData?.is_doc_extra_services ? (
        <StyledFieldsContainer>
          <Title>{`${t('Additional services')}:`}</Title>
          <Col span={24}>
            <Form.List name="doc_extra_services">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} align="top" key={field?.key}>
                        <Col span={15}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Service title')}
                            name={[field.name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: t('Service title must be specified.'),
                              },
                            ]}>
                            <Input
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a service title')}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Sum')}
                            name={[field.name, 'price']}
                            rules={[
                              () => ({
                                validator(_, sum) {
                                  const value = sum;

                                  if (Number.isNaN(Number(value))) {
                                    return Promise.reject(
                                      new Error(t('Sum must be a number')),
                                    );
                                  }

                                  if (!value) {
                                    return Promise.reject(
                                      new Error(t('Sum must be specified.')),
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <Input
                              onFocus={(e) => e?.target?.select()}
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter sum')}
                              addonBefore={defaultCompany?.currency_symbol}
                            />
                          </Form.Item>
                        </Col>

                        {doc_extra_services?.length > 1 ? (
                          <StyledDeleteCol span={2}>
                            <StyledDeleteOutlined
                              onClick={() => remove(field.name)}
                            />
                          </StyledDeleteCol>
                        ) : null}
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({ title: undefined, price: undefined })
                      }
                      block
                      icon={<PlusOutlined />}>
                      {t('Add another service')}
                    </Button>
                  </Form.Item>

                  {doc_extra_services?.length ? (
                    <Form.Item>
                      <StyledDetailsView titles={[]}>
                        <StyledStatsContainer>
                          <StyledTitle fontSize={15} bold>{`${t(
                            'Amount of services',
                          )}, ${
                            defaultCompany?.currency_symbol || ''
                          }: ${correctPrice(totalServices || 0)}`}</StyledTitle>
                        </StyledStatsContainer>
                      </StyledDetailsView>
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>
          </Col>
        </StyledFieldsContainer>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ doc_comment: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit}
        name="is_doc_multi_currencies"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Multi-currency document')}
        onChange={(is_doc_multi_currencies) => {
          handlerUpdateFormState({ is_doc_multi_currencies });

          if (is_doc_multi_currencies) {
            handlerUpdateFormState({
              doc_multi_currencies: DEFAULT_MULTI_CURRENCY,
            });
          } else {
            handlerUpdateFormState({ doc_multi_currencies: [] });
          }
        }}
      />

      {formData?.is_doc_multi_currencies ? (
        <StyledFieldsContainer>
          <Title>{`${t('Additional currencies')}:`}</Title>
          <Col span={24}>
            <Form.List name="doc_multi_currencies">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} align="top" key={field?.key}>
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Currency')}
                            name={[field.name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: t('Currency must be specified.'),
                              },
                            ]}>
                            <Input
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a currency')}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            required
                            label={t('tRate')}
                            name={[field.name, 'rate']}
                            rules={[
                              () => ({
                                validator(_, sum) {
                                  const value = sum;

                                  if (Number.isNaN(Number(value))) {
                                    return Promise.reject(
                                      new Error(t('Rate must be a number')),
                                    );
                                  }

                                  if (!value) {
                                    return Promise.reject(
                                      new Error(t('Rate must be specified.')),
                                    );
                                  }

                                  if (Number(value) <= 0) {
                                    return Promise.reject(
                                      new Error(
                                        t('Rate must be bigger than 0'),
                                      ),
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <FullWidthInputNumber
                              onFocus={(e) => e?.target?.select()}
                              type="number"
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a rate')}
                            />
                          </Form.Item>
                        </Col>

                        {doc_multi_currencies?.length > 1 ? (
                          <StyledDeleteCol span={2}>
                            <StyledDeleteOutlined
                              onClick={() => remove(field.name)}
                            />
                          </StyledDeleteCol>
                        ) : null}
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({ title: undefined, rate: undefined })}
                      block
                      icon={<PlusOutlined />}>
                      {t('Add currency')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </StyledFieldsContainer>
      ) : null}
    </Row>
  );
}
