import React from 'react';
import moment from 'moment';
import Empty from 'antd/es/empty';
import classNames from 'classnames';
import TableHeader from './TableHeader';
import { useTranslation } from 'react-i18next';
import AntTable, { TableProps } from 'antd/es/table';

import './Table.less';

export type Column<T> = {
  title: string;
  key: string;
  render?: (value: T) => React.ReactNode;
  onHeaderCell?: (value: T) => React.ReactNode;
  align?: string;
  width?: number | string;
  onCell?: any;
};

export interface ITableProps<T extends {}>
  extends Omit<TableProps<T>, 'columns'> {
  columns: Column<T>[];
  className?: string;
  renderEmpty?: React.ReactNode;
  responsive?: boolean;
  rowKey?: string;
  underTableContent?: React.ReactNode;
  underTableContentStyle?: React.CSSProperties;
}

/**
 * @name Table
 * @desc component. Table.
 * @param {{}} param0
 */
export default function Table<T extends {}>({
  className,
  columns,
  dataSource,
  bordered = false,
  expandedRowRender,
  renderEmpty,
  responsive,
  rowKey,
  loading,
  underTableContent,
  underTableContentStyle,
  ...rest
}: ITableProps<T>): any {
  const { t } = useTranslation();

  let responsiveColumns: any = columns;

  let responsiveExpandedRowRender: any = expandedRowRender;

  const wrapRender =
    (column: Column<T>, render: (value: T) => any, key: string) =>
    (...args: any[]) =>
      (
        <span className="Table_data-item" key={key}>
          {column.title ? (
            <span className="Table_title-in-cell">{column.title}:&nbsp;</span>
          ) : null}
          <span className="Table_value-in-cell">
            {/* @ts-ignore */}
            {render(...args)}
          </span>
        </span>
      );
  if (responsive) {
    responsiveColumns = columns.map((c, index) => {
      if (c.render && {}.toString.call(c.render) === '[object Function]') {
        const oldRender = c.render;
        c.render = wrapRender(
          c,
          (...args) => oldRender(...args),
          (c as any) + index,
        );
      } else {
        c.render = wrapRender(c, (data) => data, (c as any) + index);
      }
      return c;
    });
  }

  if (expandedRowRender) {
    responsiveExpandedRowRender = (...args: any[]) => (
      <div className="Table_expanded-row">
        {/* @ts-ignore */}
        {expandedRowRender(...args)}
      </div>
    );
  }

  return (
    <>
      {underTableContent && (
        <TableHeader style={underTableContentStyle} loading={!!loading}>
          {underTableContent}
        </TableHeader>
      )}
      <AntTable
        locale={{
          emptyText: loading ? (
            <Empty description={`...${t('Loading')}`} />
          ) : (
            renderEmpty || <Empty description={t('No Data')} />
          ),
        }}
        rowClassName={(entity: any, index: number) => {
          if (entity?.created_at) {
            const now = moment(new Date());
            const end = moment(entity?.created_at);
            const second = now.diff(end, 'second');

            if (index === 0 && second === 0) {
              return classNames(
                'Table_row list__row--highlight',
                { 'Table_row--with-expandable': expandedRowRender },
                className,
              ) as any;
            }
          }

          return classNames(
            'Table_row',
            { 'Table_row--with-expandable': expandedRowRender },
            className,
          ) as any;
        }}
        rowKey={rowKey}
        className={classNames('Table', {
          'Table--responsive': responsive,
        })}
        bordered={bordered}
        dataSource={dataSource}
        columns={responsiveColumns}
        expandedRowRender={responsiveExpandedRowRender}
        expandedRowClassName={() => 'Table_row-expanded'}
        loading={loading}
        {...rest}
      />
    </>
  );
}
