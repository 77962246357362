import { List } from 'immutable';
import {
  ReportDTO,
  ReportFormDTO,
  IReportDTOProps,
  ReportModel,
  ReportListModel,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IReportListDTO {
  reports: ReportDTO[];
  total: number;
}

export class ReportMapper {
  public static toReportDTO(
    report: ReportModel | IReportDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ReportDTO {
    return new ReportDTO(report, [
      WithoutRecursion.report,
      ...withoutRecursion,
    ]);
  }

  public static toReportListDTO(
    reports: List<ReportModel> | IReportDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IReportListDTO {
    const reportList = List.isList(reports)
      ? listToArray<ReportModel>(reports)
      : reports;

    return {
      reports: reportList?.map((report) =>
        ReportMapper.toReportDTO(report, [
          WithoutRecursion.report,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toReportModel(
    reportDTO: ReportDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ReportModel {
    return new ReportModel(reportDTO, [
      WithoutRecursion.report,
      ...withoutRecursion,
    ]);
  }

  public static toReportListModel(
    reportDTOs: ReportDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ReportListModel {
    return new ReportListModel({ reports: reportDTOs, total }, [
      WithoutRecursion.report,
      ...withoutRecursion,
    ]);
  }

  public static toReportFormDTO(reportModel?: ReportModel): ReportFormDTO {
    return new ReportFormDTO(reportModel);
  }
}
