import styled from 'styled-components';
import { TextFormat } from '@components/lib/Format';

export const StyledContainer = styled.div`
  width: 50vw;
  padding: 20px 0;
  @media (max-width: 1200px) {
    width: 70vw;
  }

  @media (max-width: 767px) {
    width: 80vw;
  }

  @media (max-width: 475px) {
    width: 90vw;
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

export const StyledTextFormat = styled(TextFormat)`
  font-size: 1.3em;
  text-align: center;
  color: black;
`;
