import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { XYCoord } from 'react-dnd';
import { snapToGrid } from '@services/helpers';

export interface ICalendarEventPreviewProps {
  children: React.ReactNode;
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
}

const StyledPreviewContainer = styled.div<{ width: string }>`
  position: fixed;
  pointer-events: none;
  z-index: 99999999999;
  width: 100%;
  top: 0;
  left: 0;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

const StyledSnap = styled.div<Omit<ICalendarEventPreviewProps, 'children'>>`
  ${({ initialOffset, currentOffset }) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    let { y } = currentOffset;
    let { x } = initialOffset;

    y -= initialOffset.y;
    [y] = snapToGrid(y);
    y += initialOffset.y;

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }}
`;

export default function CalendarEventPreview({
  children,
  initialOffset,
  currentOffset,
}: ICalendarEventPreviewProps): JSX.Element {
  const [previewWidth, setPreviewWidth] = useState<string>('');

  // useEffect(() => {
  //   const form = document.getElementById('order-form');
  //   const editForm = document.getElementById('order-form-edit');
  //
  //   if (form) {
  //     setPreviewWidth(window.getComputedStyle(form).width);
  //   }
  //
  //   if (editForm && !form) {
  //     setPreviewWidth(window.getComputedStyle(editForm).width);
  //   }
  // }, []);

  return (
    <StyledPreviewContainer width={previewWidth} id="test-is-drag2">
      <StyledSnap
        initialOffset={initialOffset}
        currentOffset={currentOffset}
        className="test-is-drag">
        {children}
      </StyledSnap>
    </StyledPreviewContainer>
  );
}
