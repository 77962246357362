import React, { useMemo } from 'react';
import { useAcl } from '@contex';
import { useTranslation } from 'react-i18next';
import { MenuProps, theme as antTheme } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { AclDTO, AclMapper } from '@structure';
import { MenuItem } from '@components/lib/Layout';
import { groupByMenuGroupKey, GroupedMenuItems } from '../utils';
import { HeaderDropdown } from '@components/lib/DataDisplay';

import {
  StyledLockOutlined,
  StyledHeaderDropdownMenuButton,
} from '@components/lib/Styled';

const { useToken } = antTheme;

export interface ISettingsDropdownProps {
  items: MenuItem[];
  loading?: boolean;
  onClick: (item: MenuItem) => void;
}

export function SettingsDropdown({
  items,
  onClick,
}: ISettingsDropdownProps): React.JSX.Element {
  const { token } = useToken();
  const { t } = useTranslation();
  const acl = useAcl((acl) => acl);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const userSettingsMenuItems: MenuProps['items'] = useMemo(() => {
    const groupedMenu: GroupedMenuItems = groupByMenuGroupKey(items);

    return Object.keys(groupedMenu).map((itemKey: string) => {
      return {
        key: itemKey,
        type: 'group',
        label: t(itemKey),
        children: groupedMenu[itemKey].map((item: MenuItem) => {
          const { read } = item?.aclItem
            ? acl[item?.aclItem]
            : AclMapper.toAclModel({} as AclDTO);

          let icon: any = item?.icon ? item?.icon : null;

          if (!read && icon) {
            icon = <StyledLockOutlined />;
          }

          return {
            icon,
            key: item.key,
            disabled: !read,
            label: (
              <StyledHeaderDropdownMenuButton
                onClick={() => (read && onClick ? onClick(item) : undefined)}>
                {t(item?.title || '---')}
              </StyledHeaderDropdownMenuButton>
            ),
          };
        }),
      };
    });
  }, [acl, onClick, items, t]);

  return (
    <HeaderDropdown
      items={userSettingsMenuItems}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu as React.ReactElement, {
            style: menuStyle,
          })}
        </div>
      )}>
      <SettingOutlined style={{ fontSize: 18 }} />
    </HeaderDropdown>
  );
}
