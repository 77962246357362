import {
  ClientAbonementModel,
  ClientVisitModel,
  EmployeeModel,
} from '../internal';
import { Moment } from 'moment/moment';
import { toMoment } from '@services/helpers';

export class ClientVisitFormDTO {
  public uuid: string;
  public comment: string;
  public date: string | Date | Moment;
  public manager_uuid: EmployeeModel | string;

  constructor(
    props: ClientVisitModel,
    editMode: boolean,
    abonement?: ClientAbonementModel,
  ) {
    this.uuid = props?.uuid || '';
    this.comment = props?.visit_comment || '';
    this.date = props?.visit_date
      ? toMoment(props?.visit_date)
      : toMoment(new Date());

    this.manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : abonement?.managerModel?.uuid
      ? abonement?.managerModel
      : '';
  }
}
