import { Text } from '../Format';
import Empty from 'antd/es/empty';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useDefaultForm } from '@contex';
import { ILiteralObj } from '@services/types';
import { FlexContainer } from '../Styled/FlexContainer';
import React, { useCallback, forwardRef, useState, useEffect } from 'react';

import {
  isThereContent,
  getObjectProps,
  reduce,
  head,
  isRecordToObject,
  eq,
  isFunction,
  debounce,
  isObject,
} from '@services/helpers';

export interface Data extends ILiteralObj {
  title?: string;
  uuid?: string | number;
}

type Option<T> = (item: T) => string | React.ReactNode;

export interface ISearchSelectProps
  extends Pick<
    SelectProps<any>,
    | 'size'
    | 'defaultValue'
    | 'dropdownMatchSelectWidth'
    | 'onBlur'
    | 'popupClassName'
    | 'onSelect'
    | 'suffixIcon'
    | 'popupMatchSelectWidth'
    | 'onKeyDown'
    | 'allowClear'
    | 'tagRender'
  > {
  placeholder: string;
  data: Data[];
  disable?: boolean;
  onChange?: (value: any, option: any) => void | Promise<void>;
  onSearch?: (value: string) => void | Promise<void>;
  getOptionValueProps?: string | string[] | Option<any>;
  getOptionValueTitle?: string | string[] | Option<any>;
  getOptionValueSecond?: string | string[] | Option<any>;
  name?: string;
  notFoundContent?: React.ReactNode;
  isMultiple?: boolean;
  className?: string;
  valuePropName?: string;
  withOutSearch?: boolean;
  selectFirst?: boolean;
  selectIfOnFirst?: boolean;
  titleContainer?: ({
    children,
    item,
  }: {
    children: React.ReactNode;
    item: any;
  }) => React.ReactNode;
  withoutForm?: boolean;
  itemDisabled?: (item: any) => boolean;
  asObject?: boolean;
  resetState?: boolean;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  containerClassName?: string;
  containerStyle?: any;
  showValue?: any;
  autoFocus?: boolean;
}

export const OptionFlexContainer = styled(FlexContainer)`
  & .search-select__option--second {
    font-size: 80%;
  }
`;

const StyledSelect = styled(Select)<{ disabled: boolean }>`
  ${({ disabled }) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
      & * > .ant-select-item-option-content {
        white-space: break-spaces;
        word-break: break-word;
      }
    `}
}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
`;

export default forwardRef(
  (
    {
      placeholder,
      data,
      onChange = () => {},
      getOptionValueProps,
      getOptionValueTitle,
      getOptionValueSecond,
      disable = false,
      name,
      isMultiple = false,
      notFoundContent,
      onSearch,
      className,
      valuePropName,
      withOutSearch,
      titleContainer,
      selectFirst,
      withoutForm,
      defaultValue,
      itemDisabled,
      asObject = false,
      selectIfOnFirst = true,
      resetState,
      onSelect,
      addonAfter,
      addonBefore,
      suffixIcon,
      containerClassName,
      containerStyle,
      showValue = true,
      autoFocus,
      ...rest
    }: ISearchSelectProps,
    ref: any,
  ): JSX.Element => {
    const {
      valid,
      getFieldValue = () => {},
      setFieldsValue,
    }: any = useDefaultForm();
    const { t } = useTranslation();
    const [localDefaultValue, setLocalDefaultValue] = useState(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const getFieldPropValue = useCallback(
      (
        value: any,
        orValue: string,
        props: string | string[] | undefined | Option<any>,
      ) => {
        const isArrayProp = Array.isArray(props)
          ? reduce<[(acc: any, curr: any) => any, ILiteralObj, string[]], any>(
              getObjectProps,
              isRecordToObject(value),
              props,
            )
          : null;

        return eq(typeof isArrayProp, 'string')
          ? isArrayProp
          : isFunction(props)
          ? props(value)
          : value[typeof props === 'string' ? props : orValue];
      },

      [],
    );

    const filterOption = (input: string, option: any): boolean => {
      let firstOption = (head(option?.label?.props?.children) as any)?.props
        ?.children;

      if (typeof firstOption !== 'string') {
        firstOption =
          (firstOption as any)?.props?.children
            ?.toLowerCase()
            ?.indexOf(input?.toLowerCase()) >= 0;
      } else {
        firstOption =
          firstOption?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
      }

      return !!firstOption;
    };

    const getFirstItem = useCallback(
      (data: any[]) => {
        const item: any = head(data);

        const value = getFieldPropValue(item, 'uuid', getOptionValueProps);

        if (isFunction(onChange)) {
          onChange(value, {} as any);
        }

        setLocalDefaultValue(value);
        if (name) {
          setFieldsValue({ [name]: value });
        }
      },
      [getFieldPropValue, getOptionValueProps, name, onChange, setFieldsValue],
    );

    const handleOnChange = useCallback(
      (value: any, option: any) => {
        if (withoutForm) {
          const select = data.find((item) => item?.uuid === value);

          if (select) {
            setLocalDefaultValue(select as any);
          }

          if (isFunction(onChange)) {
            onChange(value, option);
          }

          return;
        }

        if (!withoutForm && isObject(localDefaultValue)) {
          if (isFunction(onChange)) {
            onChange(value?.value, option);
          }

          setLocalDefaultValue(null);

          return;
        }

        if (isFunction(onChange)) {
          onChange(value, option);
        }
      },
      [data, localDefaultValue, onChange, withoutForm],
    );

    useEffect(() => {
      const value = getFieldValue(name || '');

      if (isObject(value) && !asObject) {
        setLocalDefaultValue(value as any);

        const updated = getFieldPropValue(value, 'uuid', getOptionValueProps);

        if (updated) {
          if (name) {
            setFieldsValue({ [name]: updated });
          }

          if (isFunction(onChange)) {
            onChange(updated, {} as any);
          }
        }
      } else {
        if (
          (data?.length === 1 && !value && selectIfOnFirst) ||
          (selectFirst &&
            (isObject(value) ? !(value as any)[valuePropName || ''] : !value) &&
            data?.length)
        ) {
          getFirstItem(data);
        }
      }

      if (data?.length === 0 && !value) {
        setLocalDefaultValue(null);
      }
    }, [
      data,
      getFieldPropValue,
      getFieldValue,
      getFirstItem,
      getOptionValueProps,
      name,
      onChange,
      selectFirst,
      setFieldsValue,
      valuePropName,
      asObject,
      selectIfOnFirst,
      getOptionValueTitle,
    ]);

    useEffect(() => {
      if (withoutForm) {
        setLocalDefaultValue(defaultValue);
      }
    }, [defaultValue, withoutForm]);

    useEffect(() => {
      if (resetState) {
        setLocalDefaultValue(null);
      }
    }, [resetState]);

    const debounceOnSearch = debounce(async (value: string) => {
      try {
        setLocalLoading(true);
        if (isFunction(onSearch)) {
          await onSearch(value);
        }
        setLocalLoading(false);
      } catch (e) {
        setLocalLoading(false);
      }
    }, 500);

    const handleSearch = useCallback(
      async (value: string): Promise<void> => {
        await debounceOnSearch(value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    const value = showValue
      ? (withoutForm
          ? (localDefaultValue as any)?.title || undefined
          : name
          ? valuePropName
            ? getFieldValue(name)?.[valuePropName]
            : getFieldValue(name) || defaultValue || undefined
          : defaultValue || undefined) || localDefaultValue
      : '';

    return (
      <StyledContainer className={containerClassName} style={containerStyle}>
        {(name && valid) || !name ? (
          <>
            {addonBefore || null}
            <StyledSelect
              {...rest}
              autoFocus={autoFocus}
              suffixIcon={suffixIcon}
              onSelect={onSelect as any}
              defaultValue={defaultValue}
              ref={ref}
              labelInValue={!withoutForm && isObject(localDefaultValue)}
              className={className}
              mode={isMultiple ? 'multiple' : undefined}
              showSearch={!withOutSearch}
              notFoundContent={
                notFoundContent || <Empty description={t('No Data')} />
              }
              optionLabelProp="label"
              loading={!data || localLoading}
              disabled={disable}
              placeholder={placeholder}
              optionFilterProp="children"
              value={
                !withoutForm && isObject(localDefaultValue)
                  ? {
                      value: isObject(value)
                        ? getFieldPropValue(value, 'uuid', getOptionValueProps)
                        : value,
                      label: getFieldPropValue(
                        localDefaultValue,
                        'title',
                        getOptionValueTitle,
                      ),
                    }
                  : value
              }
              filterOption={
                withOutSearch || isFunction(onSearch) ? false : filterOption
              }
              onSearch={
                !withOutSearch
                  ? (value) => {
                      setSearchValue(value);
                      handleSearch(value);
                    }
                  : undefined
              }
              searchValue={searchValue}
              onChange={handleOnChange}
              options={
                isThereContent(data)
                  ? data?.map((value) => ({
                      disabled: isFunction(itemDisabled)
                        ? itemDisabled(value)
                        : false,
                      value: String(
                        getFieldPropValue(value, 'uuid', getOptionValueProps),
                      ),
                      label: (
                        <OptionFlexContainer
                          flexDirection="column"
                          className="search-select__option">
                          {titleContainer ? (
                            titleContainer({
                              children: (
                                <>
                                  {React.isValidElement(
                                    getFieldPropValue(
                                      value,
                                      'title',
                                      getOptionValueTitle,
                                    ),
                                  ) ? (
                                    getFieldPropValue(
                                      value,
                                      'title',
                                      getOptionValueTitle,
                                    )
                                  ) : (
                                    <Text>
                                      {t(
                                        getFieldPropValue(
                                          value,
                                          'title',
                                          getOptionValueTitle,
                                        ),
                                      )}
                                    </Text>
                                  )}
                                </>
                              ),
                              item: value,
                            })
                          ) : (
                            <>
                              {React.isValidElement(
                                getFieldPropValue(
                                  value,
                                  'title',
                                  getOptionValueTitle,
                                ),
                              ) ? (
                                getFieldPropValue(
                                  value,
                                  'title',
                                  getOptionValueTitle,
                                )
                              ) : (
                                <Text>
                                  {t(
                                    getFieldPropValue(
                                      value,
                                      'title',
                                      getOptionValueTitle,
                                    ),
                                  )}
                                </Text>
                              )}
                            </>
                          )}
                          {getOptionValueSecond && (
                            <Text className="search-select__option--second">
                              {getFieldPropValue(
                                value,
                                'price',
                                getOptionValueSecond,
                              )}
                            </Text>
                          )}
                        </OptionFlexContainer>
                      ),
                    }))
                  : []
              }
            />
            {addonAfter || null}
          </>
        ) : null}
      </StyledContainer>
    );
  },
);
