import { List, Record } from 'immutable';
import { IListProps } from './List';
import {
  InvoiceDTO,
  InvoiceItemDTO,
  InvoiceItemModel,
  InvoiceMapper,
  InvoiceModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface InvoiceItemListModelProps extends IListProps {
  items: (InvoiceItemDTO | InvoiceItemModel)[];
  keywords?: string;
  page?: number;
  invoice?: InvoiceModel;
}

export interface InvoiceItemListModelReturnType extends IListProps {
  items: List<InvoiceItemModel>;
  keywords: string;
  page: number;
  invoice: InvoiceModel;
}

export class InvoiceItemListModel extends Record<InvoiceItemListModelReturnType>(
  {
    items: List(),
    total: 0,
    keywords: '',
    page: 1,
    invoice: {} as InvoiceModel,
  },
) {
  constructor(
    {
      items = [],
      ...props
    }: InvoiceItemListModelProps = {} as InvoiceItemListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: InvoiceItemListModelReturnType = {
      ...props,
      keywords: props?.keywords || '',
      page: props?.page || 1,
      items: List(
        items.map((item) =>
          item instanceof InvoiceItemModel
            ? item
            : new InvoiceItemModel(item, withoutRecursion),
        ),
      ),
      invoice: props?.invoice || InvoiceMapper.toInvoiceModel({} as InvoiceDTO),
    };

    super(options);
  }
}
