import { Record, List } from 'immutable';
import { IListProps } from './List';
import { ProfileSubscriptionDTO, ProfileSubscriptionModel } from '../internal';

export interface IProfileSubscriptionListModelProps extends IListProps {
  profile_subscriptions: ProfileSubscriptionDTO[];
}

export interface IProfileSubscriptionListModelReturnType extends IListProps {
  profile_subscriptions: List<ProfileSubscriptionModel>;
}

export class ProfileSubscriptionListModel extends Record<IProfileSubscriptionListModelReturnType>(
  {
    profile_subscriptions: List(),
    total: 0,
  },
) {
  constructor(
    {
      profile_subscriptions = [],
      ...props
    }: IProfileSubscriptionListModelProps = {} as IProfileSubscriptionListModelProps,
  ) {
    const options: IProfileSubscriptionListModelReturnType = {
      ...props,
      profile_subscriptions: List(
        profile_subscriptions.map(
          (subscription) => new ProfileSubscriptionModel(subscription),
        ),
      ),
    };

    super(options);
  }
}
