import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Space } from 'antd';
import { User } from '@services/models';
import { OverViewUnderHeaderContainer } from '@components/lib/Layout';
import {
  IDisableOrEnableUserProps,
  toggleUserAccountStatus,
} from '@services/api/companyUser';
import DisableOrActiveUserButton from './DisableOrActiveUserButton';

export default withTranslation()(AccountActionButtons);

export interface IAccountActionButtonsProps extends WithTranslation {
  user: User;
  children?: React.ReactNode;
  handleDisabledAccount: (value: IDisableOrEnableUserProps) => Promise<void>;
  extra?: React.ReactNode | React.ReactNode[];
}

function AccountActionButtons({
  t,
  user,
  children,
  handleDisabledAccount,
  extra,
}: IAccountActionButtonsProps): JSX.Element {
  const status = toggleUserAccountStatus(user?.status_text);
  return (
    <OverViewUnderHeaderContainer
      title={`${t('Balance')} ${(user as any)?.balance || 0} ${t('UAH')}`}
      extra={
        <Space>
          <DisableOrActiveUserButton
            key="user__button--disabled"
            status={status}
            onSuccess={() =>
              handleDisabledAccount({
                user_uuid: user?.uuid,
                action: status,
              })
            }>
            {t(status)}
          </DisableOrActiveUserButton>
          {extra || null}
        </Space>
      }>
      {children}
    </OverViewUnderHeaderContainer>
  );
}
