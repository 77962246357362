import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import {
  CashBoxModel,
  CheckboxAttributesCashBox,
  INTEGRATION_CASH_BOX_FORM_PARAMS,
  IntegrationCheckboxAttributesDTO,
} from '@structure';
import { useDropdownAlert } from '@contex';
import styled from 'styled-components';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from '@components/lib/DataDisplay';
import { StyledLockOutlined, StyledTitle } from '@components/lib/Styled';
import { useIntegrationCheckboxItemFieldParams, useSize } from '@hooks';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';

export interface IntegrationCheckboxItemFormProps
  extends Omit<
    IDefaultFormProps<CheckboxAttributesCashBox, CheckboxAttributesCashBox>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  cashBox?: CheckboxAttributesCashBox;
  disabledCashBoxes: string[];
  attributes: IntegrationCheckboxAttributesDTO;
}

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  position: absolute;
  top: calc(50% - 10px);
  right: 5px;
  display: flex;
  align-self: center;
`;

const StyledCashiersContainer = styled.div`
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.lightDisable};
  margin: 0 7px 7px 10px;
  border-radius: 7px;
  padding: 5px 0;
`;

const StyledRow = styled(Row)`
  margin: 0 !important;
  padding: 0 5px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightDisable};
  margin: 0 10px 10px 10px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 10px;
`;

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<CashBoxModel>>;

export function IntegrationCheckboxItemForm({
  loading,
  editMode,
  cashBox = INTEGRATION_CASH_BOX_FORM_PARAMS,
  onCancel,
  attributes,
  disabledCashBoxes,
  ...rest
}: IntegrationCheckboxItemFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const [instance] = Form.useForm();

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= 321 ? 24 : 12;

  const cashiers = Form.useWatch('cashiers', instance);

  const cashBoxesFiscalNumbers = attributes?.cashboxes
    ?.filter((attribute) => attribute.cashbox_id !== cashBox.cashbox_id)
    ?.map(({ cashbox_id }: CheckboxAttributesCashBox) =>
      cashbox_id?.toLowerCase(),
    );

  const additionalValues = useMemo(
    () => ({
      uuid: cashBox?.uuid,
    }),
    [cashBox?.uuid],
  );

  const {
    cashBoxList,
    rowSelection,
    loading: cashBoxListLoading,
    columns,
  } = useIntegrationCheckboxItemFieldParams({
    selectedCashBoxes: cashBox?.cashbox_relations,
    form: instance,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Integration'),
        `${
          editMode
            ? t('An error occurred during edit integration')
            : t('An error occurred during create integration')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...cashBox,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({ loadingSubmit }) => (
        <StyledRow gutter={12} justify="start" ref={contentRef}>
          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="cashbox_name"
              tooltip={t('Title')}
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loadingSubmit}
                placeholder={t('Enter the title')}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('License key')}
              name="cashbox_license_key"
              tooltip={t('License key')}
              rules={[
                {
                  required: true,
                  message: t('License key must be specified.'),
                },
              ]}>
              <Input
                disabled={loadingSubmit}
                placeholder={t('Enter the license key')}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              label={t('Fiscal number')}
              name="cashbox_id"
              tooltip={t('Fiscal number')}
              rules={[
                () => ({
                  validator(_, cashbox_id) {
                    const value = cashbox_id;

                    if (!value) {
                      return Promise.reject(
                        new Error(t('Fiscal number must be specified.')),
                      );
                    }

                    if (
                      (cashBoxesFiscalNumbers || []).includes(
                        value?.toLowerCase(),
                      )
                    ) {
                      return Promise.reject(
                        new Error(`${t('The fiscal number is already taken')}`),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <Input
                disabled={
                  loadingSubmit || disabledCashBoxes?.includes(cashBox?.uuid!)
                }
                placeholder={t('Enter the fiscal number')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Cash boxes')}
              tooltip={`${t(
                'Cash registers that will be connected to the cash register with',
              )} Checkbox`}
              name="cashbox_relations">
              <StyledTable
                loading={cashBoxListLoading}
                bordered
                dataSource={cashBoxList}
                columns={columns}
                total={cashBoxList?.size || 0}
                pageSize={Infinity}
                rowSelection={rowSelection}
              />
            </Form.Item>
          </Col>

          <Title>{t('Cashiers')}</Title>

          <StyledCashiersContainer>
            <Col span={24}>
              <Form.List name="cashbox_cashiers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index, items) => {
                      return (
                        <Row
                          gutter={12}
                          align="top"
                          key={field?.key}
                          style={{ position: 'relative' }}>
                          <Col span={22}>
                            <Form.Item
                              {...field}
                              label={t('Cashier title')}
                              name={[field.name, 'cashier_title']}
                              tooltip={t('Cashier title')}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'Cashier title must be specified.',
                                  ),
                                },
                              ]}>
                              <Input
                                disabled={loadingSubmit}
                                placeholder={t('Enter the cashier title')}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={14}>
                            <Form.Item noStyle shouldUpdate>
                              {() => (
                                <Form.Item
                                  {...field}
                                  required
                                  label={t('tLogin')}
                                  tooltip={t('tLogin')}
                                  name={[field.name, 'cashier_login']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('Login must be specified'),
                                    },
                                  ]}>
                                  <Input
                                    disabled={loadingSubmit}
                                    placeholder={t('Enter login')}
                                  />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              required
                              label={t('Password')}
                              tooltip={t('Password')}
                              name={[field.name, 'cashier_password']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Password must be specified.'),
                                },
                              ]}>
                              <Input
                                disabled={loadingSubmit}
                                prefix={<StyledLockOutlined />}
                                type="password"
                                placeholder={t('Enter password')}
                              />
                            </Form.Item>
                          </Col>

                          {cashiers?.length > 1 ? (
                            <StyledDeleteCol span={2}>
                              <StyledDeleteOutlined
                                onClick={() => remove(field.name)}
                              />
                            </StyledDeleteCol>
                          ) : null}

                          {index === items?.length - 1 ? null : (
                            <StyledDivider />
                          )}
                        </Row>
                      );
                    })}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            cashier_login: undefined,
                            cashier_password: undefined,
                          })
                        }
                        block
                        icon={<PlusOutlined />}>
                        {t('Add cashier')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </StyledCashiersContainer>
        </StyledRow>
      )}
    </DefaultForm>
  );
}
