import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { DepartmentFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';
import { IUseStateDepartmentReturnType } from '@hooks';

const DepartmentSideWindowLazy = React.lazy(
  () => import('../Show/DepartmentSideWindow'),
);

export interface IDepartmentUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateDepartmentReturnType['handleUpdateDepartment'];
  onCancel?: () => void;
  department: DepartmentFormDTO;
}

export function DepartmentUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  department,
  ...rest
}: IDepartmentUpdateButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit departments / locations')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <DepartmentSideWindowLazy
          editMode
          department={department}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
