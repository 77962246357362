import { ProductCategoryModel, UnitPackaging } from '../internal';

export interface IProductCategoryAdditional {
  disabledCategoryField?: boolean;
}

export class ProductCategoryFormDTO {
  public uuid: string;
  public title: string;
  public description: string;
  public base_unit: string;
  public unit_packaging: UnitPackaging[];
  public parent_category_uuid: ProductCategoryModel | string | undefined;
  public disabled_category_field?: boolean;

  constructor(
    props?: ProductCategoryModel,
    additional?: IProductCategoryAdditional,
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.base_unit = props?.base_unit || '';
    this.unit_packaging = props?.unit_packaging || [];
    this.parent_category_uuid = props?.parentCategoryModel?.uuid
      ? props?.parentCategoryModel
      : undefined;

    this.disabled_category_field =
      typeof additional?.disabledCategoryField === 'boolean'
        ? additional?.disabledCategoryField
        : false;
  }
}
