import { List } from 'immutable';
import {
  ICompanyModuleDTOProps,
  CompanyModuleModel,
  CompanyModuleListModel,
  CompanyModuleDTO,
} from '../internal';
import { listToArray } from '@services/helpers';

export interface ICompanyModuleListDTO {
  companyModules: CompanyModuleDTO[];
}

export class CompanyModuleMapper {
  public static toCompanyModuleDTO(
    companyModule: CompanyModuleModel | ICompanyModuleDTOProps,
  ): CompanyModuleDTO {
    return new CompanyModuleDTO(companyModule);
  }

  public static toCompanyModuleListDTO(
    companyModules: List<CompanyModuleModel> | ICompanyModuleDTOProps[],
  ): ICompanyModuleListDTO {
    const companyModulesList = List.isList(companyModules)
      ? listToArray<CompanyModuleModel>(companyModules)
      : companyModules;

    return {
      companyModules: companyModulesList?.map((companyModule) =>
        CompanyModuleMapper.toCompanyModuleDTO(companyModule),
      ),
    };
  }

  public static toCompanyModuleModel(
    companyModuleDTO: CompanyModuleDTO,
  ): CompanyModuleModel {
    return new CompanyModuleModel(companyModuleDTO);
  }

  public static toCompanyModuleListModel(
    companyModule: CompanyModuleDTO[],
  ): CompanyModuleListModel {
    return new CompanyModuleListModel({
      company_modules: companyModule,
    });
  }
}
