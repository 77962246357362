import React, { useCallback, useRef, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { DatePicker } from '@components/lib/General';
import { SearchSelect, Segmented } from '@components/lib/DataDisplay';
import { isListToArray, correctPrice } from '@services/helpers';
import { FROM_PAYMENT_METHOD } from '../../Payments/Forms/PaymentScheduleFields';
import { convertedPaymentMethodToCashBox } from '@services/api/companyPayments';
import { CashBoxModel, PAYMENT_METHOD } from '@structure';
import {
  IUseStateCashBoxListReturnType,
  IUseStateEmployeeListReturnType,
  IUseStateGroupPaymentListReturnType,
  useSize,
  useStoredCompanies,
} from '@hooks';
import { phoneScreen } from '@services/const';
import { useTranslation } from 'react-i18next';
import { useDefaultForm } from '@contex';
import { StyledDescription } from '@components/lib/Styled';
import { List } from 'immutable';
import { CashBoxTitleView } from '../../Payments/Show/CashBoxTitleView';

export interface IRewardPaymentFormFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    >,
    Pick<
      IUseStateGroupPaymentListReturnType,
      'groupPayments' | 'handleSearchGroupPayments'
    > {
  loading: boolean;
  employeesLoading: boolean;
  cashBoxListLoading: boolean;
  groupPaymentsLoading: boolean;
}

export function RewardPaymentFormFields({
  loading,

  employees,
  employeesLoading,
  handleSearchEmployees,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,

  groupPayments,
  groupPaymentsLoading,
  handleSearchGroupPayments,
}: IRewardPaymentFormFieldsProps): React.JSX.Element {
  const { t } = useTranslation();
  const { loadingSubmit, formData, handlerUpdateFormState }: any =
    useDefaultForm();
  const { defaultCompany } = useStoredCompanies();

  const [resetEmployeeState, setResetEmployeeState] = useState(false);
  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const once = useRef(false);

  const contentRef = useRef(null);
  const { width: contentWidth } = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const handleChangePaymentMethod = useCallback(
    (payment_method: any) => {
      handlerUpdateFormState({ payment_method });

      if (typeof formData?.payment_method === 'string') {
        handlerUpdateFormState({
          payment_cashbox_uuid: '',
        });

        if (once.current) {
          handlerUpdateFormState({
            payment_cashier_uuid: '',
          });
          setResetEmployeeState(true);
          setResetCashBoxState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
            setResetCashBoxState(false);
          }, 100);
        }
      }
    },
    [formData?.payment_method, handlerUpdateFormState],
  );

  const handleChangeCashBox = useCallback(
    (payment_cashbox_uuid: string) => {
      handlerUpdateFormState({ payment_cashbox_uuid });

      if (List.isList(cashBoxList)) {
        const cashBox = cashBoxList?.find(
          ({ uuid }) => uuid === payment_cashbox_uuid,
        );

        if (cashBox) {
          once.current = true;
          handlerUpdateFormState({
            payment_cashier_uuid: cashBox?.cashier?.uuid || '',
          });
          setResetEmployeeState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
          }, 100);
        }
      }
    },
    [cashBoxList, handlerUpdateFormState],
  );

  return (
    <Row gutter={12} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          label={t('Date and time')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('Date and time must be specified'),
            },
          ]}
          tooltip={t('Date and time')}>
          <DatePicker
            showTime
            format={'DD.MM.YYYY HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(payment_date) =>
              handlerUpdateFormState({ payment_date })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Employee')}
          label={t('Employee')}
          name="employee"
          rules={[
            {
              required: true,
              message: t('Employee must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst
            name="employee"
            getOptionValueTitle="fullName"
            placeholder={t('Pick an employee')}
            disable
            data={isListToArray(employees as any)}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Amount due to')}
          name="payment_price"
          required
          rules={[
            () => ({
              validator(_, price) {
                const value = price;

                if (Number.isNaN(Number(value))) {
                  return Promise.reject(
                    new Error(t('Amount due to must be a number')),
                  );
                }

                if (!Number(value)) {
                  return Promise.reject(
                    new Error(t('Amount due to must be specified')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <StyledDescription style={{ whiteSpace: 'unset' }}>
              {Number(formData?.payment_price) > Number(formData?.sum_total)
                ? `${t('Calculated')} ${
                    defaultCompany?.currency_symbol || ''
                  }, ${formData?.sum_total}. ${t('Paid')} ${
                    defaultCompany?.currency_symbol || ''
                  }, ${formData?.payment_price} (${
                    defaultCompany?.currency_symbol || ''
                  } ${correctPrice(
                    Number(formData?.payment_price) -
                      Number(formData?.sum_total),
                  )} ${t("will be removed from the employee's balance")})`
                : `${t('Calculated')} ${
                    defaultCompany?.currency_symbol || ''
                  }, ${formData?.sum_total}`}
            </StyledDescription>
          }>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit}
            onChange={(e) =>
              handlerUpdateFormState({ payment_price: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Form of payment')}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t('Form of payment must be specified'),
            },
          ]}
          tooltip={t('Form of payment')}>
          <Segmented
            options={FROM_PAYMENT_METHOD}
            disabled={loadingSubmit}
            onChange={handleChangePaymentMethod}
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Cash boxes')}
          name="payment_cashbox_uuid"
          rules={[
            {
              required: true,
              message: t('Cash boxes must be specified'),
            },
          ]}
          tooltip={t('Cash boxes')}>
          <SearchSelect
            resetState={resetCashBoxState}
            selectFirst={false}
            onSearch={(keywords) =>
              handleSearchCashBoxes({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle={(cashBox: CashBoxModel) => (
              <CashBoxTitleView
                cashBox={cashBox}
                disable={loadingSubmit || cashBoxListLoading}
              />
            )}
            name="payment_cashbox_uuid"
            placeholder={t('Select a cash box')}
            disable={loadingSubmit || cashBoxListLoading}
            data={isListToArray(
              cashBoxList?.filter(
                ({ box_type, box_can_out_money_from, box_can_in_money_to }) => {
                  return (
                    box_type ===
                      convertedPaymentMethodToCashBox[
                        formData?.payment_method as PAYMENT_METHOD
                      ] && box_can_out_money_from
                  );
                },
              ) as any,
            )}
            onChange={handleChangeCashBox}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Article of payment')}
          label={t('Article of payment')}
          name="payment_group_uuid"
          rules={[
            {
              required: true,
              message: t('Article of payment must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst
            name="payment_group_uuid"
            placeholder={t('Select an article of payment')}
            data={isListToArray(groupPayments as any)}
            disable={loading || loadingSubmit || groupPaymentsLoading}
            onSearch={(keywords) =>
              handleSearchGroupPayments({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
            onChange={(payment_group_uuid) =>
              handlerUpdateFormState({ payment_group_uuid })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Note')}
          name="payment_comment"
          rules={[
            {
              required: true,
              message: t('Note must be specified'),
            },
          ]}>
          <Input.TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({ payment_comment: e?.target?.value })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Cashier')}
          name="payment_cashier_uuid"
          rules={[
            {
              required: true,
              message: t('Cashier must be specified'),
            },
          ]}
          tooltip={t('Cashier')}>
          <SearchSelect
            resetState={resetEmployeeState}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle="fullName"
            name="payment_cashier_uuid"
            placeholder={t('Select a cashier')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(payment_cashier_uuid) =>
              handlerUpdateFormState({ payment_cashier_uuid })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
