import React from 'react';
import { PriceMarginModel } from '@structure';
import styled, { useTheme } from 'styled-components';
import { StyledDescription } from '@components/lib/Styled';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from '@components/lib/DataDisplay';
import { Routes } from '@services/types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export interface IPriceMarginDetailsViewProps {
  priceMargin: PriceMarginModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.success};

  font-size: 15px;
`;

export function PriceMarginDetailsView({
  priceMargin,
}: IPriceMarginDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  return (
    <>
      {priceMargin ? (
        <StyledContainer>
          <StyledSpace>
            <StyledTitle>
              <Link
                to={`/${Routes.app}/${Routes.priceMargins}/${priceMargin?.uuid}`}
                tooltip={t('Open a detailed description of the price margin')}
                state={{ goBack: location.pathname }}>
                {priceMargin?.price_name}
              </Link>
              {priceMargin?.price_is_default ? (
                <Tooltip
                  title={t(
                    'The current price is used by default for all transactions with goods and services.',
                  )}>
                  <StyledInfoCircleOutlined />
                </Tooltip>
              ) : null}
            </StyledTitle>

            {priceMargin?.price_is_default ? (
              <StyledDescription $color={theme.colors.success}>
                {t('default')}
              </StyledDescription>
            ) : null}

            {priceMargin?.price_margin ? (
              <StyledDescription $color={theme.colors.success}>
                {`% ${priceMargin?.price_margin}`}
              </StyledDescription>
            ) : null}
            {priceMargin?.price_description ? (
              <StyledDescription>
                {priceMargin?.price_description}
              </StyledDescription>
            ) : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
