import { List } from 'immutable';
import {
  ExpenseDTO,
  ExpenseFormDTO,
  IExpenseDTOProps,
  ExpenseModel,
  ExpenseListModel,
  AggregatedSumDTO,
  IAggregatedSumDTOProps,
  ProductModel,
  ServiceModel,
  ExpenseCreateFormDTO,
  EmployeeModel,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IExpenseListDTO {
  schedule_expenses: ExpenseDTO[];
  aggregated_sum: AggregatedSumDTO;
  total: number;
}

export class ExpenseMapper {
  public static toExpenseDTO(
    expense: ExpenseModel | IExpenseDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ExpenseDTO {
    return new ExpenseDTO(expense, [
      WithoutRecursion.expense,
      ...withoutRecursion,
    ]);
  }

  public static toAggregatedSumDTO(
    aggregated_sum: IAggregatedSumDTOProps,
  ): AggregatedSumDTO {
    return new AggregatedSumDTO(aggregated_sum);
  }

  public static toExpenseListDTO(
    schedule_expenses: List<ExpenseModel> | IExpenseDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    aggregated_sum: AggregatedSumDTO = {} as AggregatedSumDTO,
  ): IExpenseListDTO {
    const expenseList = List.isList(schedule_expenses)
      ? listToArray<ExpenseModel>(schedule_expenses)
      : schedule_expenses;

    return {
      schedule_expenses: expenseList?.map((expense) =>
        ExpenseMapper.toExpenseDTO(expense, [
          WithoutRecursion.expense,
          ...withoutRecursion,
        ]),
      ),
      total,
      aggregated_sum,
    };
  }

  public static toExpenseModel(
    expenseDTO: ExpenseDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ExpenseModel {
    return new ExpenseModel(expenseDTO, [
      WithoutRecursion.expense,
      ...withoutRecursion,
    ]);
  }

  public static toExpenseListModel(
    expenseDTOs: ExpenseDTO[],
    total: number,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ExpenseListModel {
    return new ExpenseListModel({ schedule_expenses: expenseDTOs, total }, [
      WithoutRecursion.expense,
      ...withoutRecursion,
    ]);
  }

  public static toExpenseFormDTO(
    expenseModel: ExpenseModel,
    editMode?: boolean,
  ): ExpenseFormDTO {
    return new ExpenseFormDTO(expenseModel, editMode);
  }

  public static toExpenseCreateFormDTO(
    expenseModel: ProductModel | ServiceModel,
    manager: EmployeeModel,
  ): ExpenseCreateFormDTO {
    return new ExpenseCreateFormDTO(expenseModel, manager);
  }
}
