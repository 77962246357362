import { Map } from 'immutable';
import { APP_STATE, CLIENT_VISIT } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ClientVisitModel } from '@structure';

interface IClientVisitListState {
  visit: ClientVisitModel | null;
  cachedClientVisitList: Map<string, ClientVisitModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IClientVisitListState, 'visit'> {
  type: CLIENT_VISIT.SET_CLIENT_VISIT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateClientVisit {
  type: CLIENT_VISIT.UPDATE_CLIENT_VISIT;
  visit: ClientVisitModel;
}

interface LoadingAction {
  type: CLIENT_VISIT.LOADING_CLIENT_VISIT;
}

interface ResetAction {
  type: CLIENT_VISIT.RESET_CLIENT_VISIT;
}

interface ErrorAction extends Pick<IClientVisitListState, 'error'> {
  type: CLIENT_VISIT.ERROR_CLIENT_VISIT;
}

interface Handlers {
  [CLIENT_VISIT.SET_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: SetAction,
  ) => IClientVisitListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientVisitListState,
    action: SetInitialStateAction,
  ) => IClientVisitListState;

  [CLIENT_VISIT.RESET_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: ResetAction,
  ) => IClientVisitListState;

  [CLIENT_VISIT.UPDATE_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: UpdateClientVisit,
  ) => IClientVisitListState;

  [CLIENT_VISIT.ERROR_CLIENT_VISIT]: (
    state: IClientVisitListState,
    value: ErrorAction,
  ) => IClientVisitListState;

  [CLIENT_VISIT.LOADING_CLIENT_VISIT]: (
    state: IClientVisitListState,
    value?: LoadingAction,
  ) => IClientVisitListState;
  DEFAULT: (state: IClientVisitListState) => IClientVisitListState;
}

const initState: IClientVisitListState = {
  visit: null,
  cachedClientVisitList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [CLIENT_VISIT.SET_CLIENT_VISIT]: (state, { visit }) => ({
    ...state,
    ...{
      visit,
      cachedClientVisitList: visit
        ? state.cachedClientVisitList.set(visit?.uuid, visit)
        : state.cachedClientVisitList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_VISIT.RESET_CLIENT_VISIT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [CLIENT_VISIT.UPDATE_CLIENT_VISIT]: (state, { visit }) => ({
    ...state,
    ...{
      visit,
      cachedClientVisitList: state.cachedClientVisitList.update(
        visit?.uuid,
        () => visit,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [CLIENT_VISIT.ERROR_CLIENT_VISIT]: (
    state: IClientVisitListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT_VISIT.LOADING_CLIENT_VISIT]: (state: IClientVisitListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientVisitListState) => state,
};

export default function ClientVisit(
  state: any = initState,
  action: any,
): IClientVisitListState {
  const handler = handlers[action.type as CLIENT_VISIT] || handlers.DEFAULT;
  return handler(state, action);
}
