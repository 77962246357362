import { apiDelete, apiGet, apiPut, apiPost } from '../core/api';
import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import { ApiAnswer, IEntityProps, IListSearchProps } from '../types';
import {
  GroupPaymentDTO,
  GroupPaymentFormDTO,
  GroupPaymentMapper,
  IGroupPaymentDTOProps,
  IGroupPaymentListDTO,
} from '@structure';

export const GROUP_PAYMENT_INITIAL_PARAM = new GroupPaymentFormDTO();

export interface IGroupPaymentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
}

export interface IGroupPaymentListAnswer extends ApiAnswer {
  groups: IGroupPaymentDTOProps[];
}

export interface IGroupPaymentAnswer extends ApiAnswer {
  group: IGroupPaymentDTOProps;
}

export async function getGroupPaymentList({
  keywords,
  offset = 0,
  limit = 10,
  companyUuid,
}: IGroupPaymentListProps): Promise<IGroupPaymentListDTO> {
  Validate.string({ value: companyUuid });

  const isKeywords = keywords ? `&keywords=${keywords}` : '';

  const { groups, total } = await apiGet<null, IGroupPaymentListAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/payment-groups?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return GroupPaymentMapper.toGroupPaymentListDTO(groups, total);
}

export async function createGroupPayment(
  {
    group_title,
    group_description,
    group_payment_type,
    group_is_cost_maker,
  }: GroupPaymentFormDTO,
  companyUuid: string,
): Promise<GroupPaymentDTO> {
  Validate.string({ value: group_title });
  Validate.string({ value: group_description });
  Validate.string({ value: companyUuid });

  const { group } = await apiPost<
    Omit<GroupPaymentFormDTO, 'group_companies' | 'group_is_system' | 'uuid'>,
    IGroupPaymentAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/payment-groups`, {
    group_title,
    group_description,
    group_payment_type,
    group_is_cost_maker,
  });

  return GroupPaymentMapper.toGroupPaymentDTO(group);
}

export async function updateGroupPayment({
  group_companies,
  group_description,
  group_payment_type,
  group_is_cost_maker,
  group_title,
  uuid,
}: GroupPaymentFormDTO): Promise<GroupPaymentDTO> {
  Validate.string({ value: group_title });
  Validate.string({ value: group_description });
  Validate.string({ value: uuid });
  // Validate.arrayOfStrings({value: group_companies});

  const { group } = await apiPut<
    Omit<GroupPaymentFormDTO, 'uuid' | 'group_is_system'>,
    IGroupPaymentAnswer
  >(`${contentApiUrl}/payment-groups/${uuid}`, {
    group_title,
    group_companies,
    group_description,
    group_payment_type,
    group_is_cost_maker,
  });

  return GroupPaymentMapper.toGroupPaymentDTO(group);
}

export async function deleteGroupPayment(
  groupPaymentUuid: string,
  force?: boolean,
): Promise<GroupPaymentDTO> {
  Validate.string({ value: groupPaymentUuid });

  const { group } = await apiDelete<{ force?: boolean }, IGroupPaymentAnswer>(
    `${contentApiUrl}/payment-groups/${groupPaymentUuid}`,
    {
      ...(force ? { force } : {}),
    },
  );

  return GroupPaymentMapper.toGroupPaymentDTO(group);
}
