import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextFormat } from '@components/lib/Format';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { useDelete } from '@components/lib/libV2/hooks';
import { ServiceGroupSessionModel } from '@structure';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IGroupSessionDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children' | 'onError'> {
  groupSessions: ServiceGroupSessionModel[];
  children?: React.ReactNode;
  onSuccess: (id: string[]) => Promise<void>;
  onError?: () => void;
}

/**
 * @desc Вивід та реалізація логіки для кнопки видалення cесії
 * */
export default function GroupSessionDeleteButton({
  groupSessions,
  children,
  onSuccess,
  title,
  ...rest
}: IGroupSessionDeleteButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: groupSessions,
    notifyTitle: 'Session',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company service')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete session?')}
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
