import React, { useCallback, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  IUseInvoiceListProps,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateCompanyClientList,
  useStopLoading,
  useStoredCompanies,
  useStoredInvoice,
  useStoredInvoiceList,
} from '@hooks';
import {
  InvoiceCreateButton,
  InvoiceDeleteButton,
  InvoiceMenuButton,
  InvoiceUpdateButton,
} from '../Buttons';
import { textToUpperCase, toDateByFormat, toMoment } from '@services/helpers';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  ClientModel,
  InvoiceMappedType,
  InvoiceMapper,
  InvoiceModel,
  InvoiceStatus,
  InvoiceType,
  ProfileModel,
} from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { useAcl } from '@contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
  Segmented,
  TableIndexField,
} from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import styled, { useTheme } from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import {
  InvoiceDetailsView,
  InvoiceStatusView,
  InvoiceTypeView,
  InvoicePriceView,
} from '../Show';
import { StyledDescription, successButtonStyle } from '@components/lib/Styled';
import {
  RangeContainer,
  SegmentedContainer,
  StoreDocumentClientSearchView,
  StyledListActionsContainer,
} from '../../Stores';
import ListDateRange from '@components/lib/DataDisplay/ListActions/ListDateRange';
import { SessionStorageItems } from '@services/const';
import { StyledDetailsView } from '@components/lib/Styled/DetailsView';

import '../../Orders/List/OrderList.less';

export interface IInvoiceListPageProps {}

const StyledInvoiceCreateButton = styled(InvoiceCreateButton)`
  ${successButtonStyle}
`;

export const INVOICE_STATUS_VIEW = [
  {
    uuid: InvoiceStatus.UNKNOWN,
    title: <InvoiceStatusView title="All" status={InvoiceStatus.UNKNOWN} />,
  },
  {
    uuid: InvoiceStatus.COMMITTED,
    title: <InvoiceStatusView title="Exposed" status={InvoiceStatus.SENT} />,
  },
  {
    uuid: InvoiceStatus.SENT,
    title: <InvoiceStatusView title="tPaid" status={InvoiceStatus.COMMITTED} />,
  },
];

export function InvoiceListPage({}: IInvoiceListPageProps): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ invoices }) => invoices);
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { defaultCompany } = useStoredCompanies();

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'invoice-create-event',
    disabled: !manage,
  });

  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<ClientModel | null>(
    null,
  );

  const residentUuid =
    sessionStorage.getItem(
      `${SessionStorageItems.invoiceResidentUuid}${InvoiceType.IN}`,
    ) || '';

  const {
    invoiceList,
    limit,
    total,
    loading: loadingInvoiceList,
    loadingMore,
    error: errorInvoiceList,
    keywords,
    date_end,
    date_start,
    page,
    invoiceType,
    invoiceStatus,
    stats,

    handleDeleteInvoices: onDeleteInvoices,
    handleSearchInvoices,
    handlePickInvoiceRange,
    handleRefreshInvoices,
  } = useStoredInvoiceList({
    companyUuid: defaultCompanyUuid,
    invoiceType: InvoiceType.IN,
    resident_uuid: residentUuid,
  });

  const { handleCreateInvoice, handleUpdateInvoice } = useStoredInvoice({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
    invoiceUuid: '',
    invoiceType: InvoiceType.IN,
  });

  const {
    clients,
    loading: clientListLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    provisioner_only: true,
  });

  const loading = useStopLoading({
    loading: loadingInvoiceList || loadingMore || searchLoading,
    error: errorInvoiceList,
    message: 'An error occurred during accounts loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteInvoices = useCallback(
    async (value: string[]) => {
      await onDeleteInvoices(value);
      focus();
    },
    [focus, onDeleteInvoices],
  );

  const handleRefreshInvoicesType = useCallback(
    async (value: Partial<IUseInvoiceListProps>) => {
      await handleSearchInvoices({
        ...value,
        keywords,
        resident_uuid: value?.resident_uuid || residentUuid,
      });
    },
    [handleSearchInvoices, keywords, residentUuid],
  );

  const onClientSelect = useCallback(
    async (resident_uuid: string) => {
      setSearchLoading(true);

      await handleSearchInvoices({
        keywords,
        resident_uuid,
      });

      const client = clients?.find(({ uuid }) => uuid === resident_uuid);

      setSelectedClient(client || null);

      sessionStorage.setItem(
        `${SessionStorageItems.invoiceResidentUuid}${InvoiceType.IN}`,
        resident_uuid,
      );

      setSearchLoading(false);
    },
    [clients, keywords, handleSearchInvoices],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.invoices}`,
      breadcrumbName: 'Bills',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (invoice: InvoiceModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Bills'),
      key: 'bills',
      render: (invoice: InvoiceModel) => (
        <InvoiceDetailsView invoice={invoice} withStatus />
      ),
    },
    {
      ellipsis: true,
      title: t('Created'),
      key: 'create-by',
      render: (invoice: InvoiceModel) => (
        <DetailsItemView<ProfileModel>
          item={invoice?.created_by}
          fields={{
            fullName: {
              title: '',
            },
            uuid: {
              description: (
                <StyledDescription>
                  {toDateByFormat(invoice?.created_at, 'DD.MM.YYYY HH:mm')}
                </StyledDescription>
              ),
            },
          }}
        />
      ),
    },
    {
      ellipsis: true,
      title: t('Client'),
      key: 'client',
      render: (invoice: InvoiceModel) => (
        <DetailsItemView<ClientModel>
          item={invoice?.client}
          fields={{
            fullNameClient: {
              link: 'Go to client details page',
              path: `/${Routes.app}/${Routes.clients}/${invoice?.client?.uuid}`,
            },
            phone: { description: '' },
          }}
        />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      render: (invoice: InvoiceModel) => <InvoicePriceView invoice={invoice} />,
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (invoice: InvoiceModel) => {
        return (
          <TableActionCell>
            {invoice?.inv_status === InvoiceStatus.DRAFT ? (
              <InvoiceUpdateButton
                invoice={InvoiceMapper.toInvoiceFormDTO(invoice!, {
                  editMode: true,
                })}
                onSuccess={handleUpdateInvoice}
                onCancel={daleyFocus}
              />
            ) : null}

            <InvoiceDeleteButton
              disabled={!manage || invoice?.inv_status !== InvoiceStatus.DRAFT}
              invoices={[invoice]}
              onSuccess={handleDeleteInvoices}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.invoices]))}
      headerRight={
        <InvoiceMenuButton
          disabled={!manage}
          loading={loading}
          onSuccess={handleCreateInvoice}
          invoice={InvoiceMapper.toInvoiceFormDTO({} as InvoiceModel, {
            editMode: false,
            client: selectedClient,
          })}
          className="invoice-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        invoiceList?.size ? null : (
          <Empty description={t('There are no accounts created')}>
            <StyledInvoiceCreateButton
              danger={invoiceType === InvoiceType.OUT}
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateInvoice}
              invoice={InvoiceMapper.toInvoiceFormDTO(
                { inv_type: invoiceType } as InvoiceModel,
                {
                  editMode: false,
                  client: selectedClient,
                },
              )}
              title={
                invoiceType === InvoiceType.IN
                  ? t('Create invoice')
                  : t('Create input invoice')
              }
              icon={<PlusOutlined />}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <RangeContainer>
            <ListDateRange
              defaultDateRangeValue={
                date_start && date_end
                  ? [toMoment(date_start), toMoment(date_end)]
                  : undefined
              }
              onChange={handlePickInvoiceRange}
              loading={loading}
            />
          </RangeContainer>

          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                invoiceType === InvoiceType.IN,
                invoiceType === InvoiceType.OUT,
              ]}>
              <InvoiceTypeView
                stats={stats}
                color={theme.tabs.success}
                type={InvoiceType.IN}
                title={t('Outcome')}
                onClick={handleRefreshInvoicesType}
                disabled={loading}
              />
              <InvoiceTypeView
                stats={stats}
                color={theme.tabs.error}
                type={InvoiceType.OUT}
                title={t('Income')}
                onClick={handleRefreshInvoicesType}
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>
          <ListActions
            withoutPicker
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={(keywords) => handleSearchInvoices({ keywords })}
            inputTooltip={t('Search accounts')}
            inputLabel={t('Search accounts')}
            withSearchContent={
              <StyledInvoiceCreateButton
                danger={invoiceType === InvoiceType.OUT}
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateInvoice}
                invoice={InvoiceMapper.toInvoiceFormDTO(
                  { inv_type: invoiceType } as InvoiceModel,
                  {
                    editMode: false,
                    client: selectedClient,
                  },
                )}
                title={
                  invoiceType === InvoiceType.IN
                    ? t('Create invoice')
                    : t('Create input invoice')
                }
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            }
            withSearchEndContent={
              <>
                <StoreDocumentClientSearchView
                  isProvider
                  loading={loading}
                  onClientChange={onClientSelect}
                  clientUuid={residentUuid}
                  clients={clients}
                  clientListLoading={clientListLoading}
                  handleSearchClients={handleSearchClients}
                />
                <Segmented
                  options={INVOICE_STATUS_VIEW}
                  disabled={loading}
                  value={invoiceStatus}
                  onChange={async (invoiceStatus: any) => {
                    await handleSearchInvoices({
                      invoiceStatus: invoiceStatus || null,
                    });
                  }}
                />
              </>
            }
          />
        </StyledListActionsContainer>
      }
      // outsideFooterContent={
      //   isThereContent(stats) ? (
      //     <InvoiceListBottomView stats={stats} invoiceType={InvoiceType.IN} />
      //   ) : null
      // }
      loading={loading && !invoiceList?.size}>
      <Table<InvoiceModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshInvoices}
        dataSource={invoiceList}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
