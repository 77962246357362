import React, { useCallback } from 'react';
import { ScheduleCalendarProps, isFunction } from '@services/helpers';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';
import { IDetailsHeaderProps } from '@components/lib/General';
import { ScheduleCalendarModel } from '@structure';
import { useNavigate } from 'react-router';
import { Route } from '@components/lib/DataDisplay';

export interface IClientAbonementScheduleDetailProps
  extends ScheduleCalendarProps {
  schedule: ScheduleCalendarModel;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & { navigateGoBack: () => void },
  ) => React.ReactNode;
  routes: Route[];
}

export function ClientAbonementScheduleDetail({
  schedule,
  children,
  routes,
}: IClientAbonementScheduleDetailProps): JSX.Element {
  const navigate = useNavigate();

  const clientRoutes = [
    ...(routes || []),
    {
      path: `path`,
      breadcrumbName: `${
        schedule?.schedule_number ? `#${schedule?.schedule_number}` : ''
      }`,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('ClientAbonementScheduleDetail');
  }

  return <>{children({ routes: clientRoutes, navigateGoBack })}</>;
}
