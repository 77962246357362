import { Map } from 'immutable';
import { APP_STATE, PRODUCT } from '../constants';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ProductModel } from '@structure';

interface IProductListState {
  product: ProductModel | null;
  cachedProductList: Map<string, ProductModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IProductListState, 'product'> {
  type: PRODUCT.SET_PRODUCT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateProduct {
  type: PRODUCT.UPDATE_PRODUCT;
  product: ProductModel;
}

interface LoadingAction {
  type: PRODUCT.LOADING_PRODUCT;
}

interface ResetAction {
  type: PRODUCT.RESET_PRODUCT;
}

interface ErrorAction extends Pick<IProductListState, 'error'> {
  type: PRODUCT.ERROR_PRODUCT;
}

interface Handlers {
  [PRODUCT.SET_PRODUCT]: (
    state: IProductListState,
    action: SetAction,
  ) => IProductListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductListState,
    action: SetInitialStateAction,
  ) => IProductListState;

  [PRODUCT.RESET_PRODUCT]: (
    state: IProductListState,
    action: ResetAction,
  ) => IProductListState;

  [PRODUCT.UPDATE_PRODUCT]: (
    state: IProductListState,
    action: UpdateProduct,
  ) => IProductListState;

  [PRODUCT.ERROR_PRODUCT]: (
    state: IProductListState,
    value: ErrorAction,
  ) => IProductListState;

  [PRODUCT.LOADING_PRODUCT]: (
    state: IProductListState,
    value?: LoadingAction,
  ) => IProductListState;
  DEFAULT: (state: IProductListState) => IProductListState;
}

const initState: IProductListState = {
  product: null,
  cachedProductList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [PRODUCT.SET_PRODUCT]: (state, { product }) => ({
    ...state,
    ...{
      product,
      cachedProductList: product
        ? state.cachedProductList.set(product?.uuid, product)
        : state.cachedProductList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT.RESET_PRODUCT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [PRODUCT.UPDATE_PRODUCT]: (state, { product }) => ({
    ...state,
    ...{
      product,
      cachedProductList: state.cachedProductList.update(
        product?.uuid,
        () => product,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT.ERROR_PRODUCT]: (
    state: IProductListState,
    { error }: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT.LOADING_PRODUCT]: (state: IProductListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductListState) => state,
};

export default function Product(
  state: any = initState,
  action: any,
): IProductListState {
  const handler = handlers[action.type as PRODUCT] || handlers.DEFAULT;
  return handler(state, action);
}
