import { List } from 'immutable';
import { APP_STATE, PRODUCT_CATEGORY_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError, ProductType } from '@services/types';
import { IProductsListStatsProps, ProductCategoryModel } from '@structure';

interface IProductCategoryListState {
  categoryList: List<ProductCategoryModel> | null;
  categoryListTree: List<ProductCategoryModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  treeStatus: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

interface SetActionList
  extends Pick<
    IProductCategoryListState,
    'categoryList' | 'keywords' | 'total' | 'page' | 'stats'
  > {
  type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST;
}

interface SetActionTreeList
  extends Pick<
    IProductCategoryListState,
    'categoryListTree' | 'keywords' | 'total' | 'page' | 'stats'
  > {
  type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_TREE;
}

interface SetStatsActionList extends Pick<IProductCategoryListState, 'stats'> {
  type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_STATS;
}

interface StatsActionList {
  type:
    | PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_LIST_STATS
    | PRODUCT_CATEGORY_LIST.SUB_PRODUCT_CATEGORY_LIST_STATS;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY;
  category: ProductCategoryModel;
  stats: IProductsListStatsProps;
}

interface AddActionToListTree extends AddActionToList {
  parentCategoryUuid: string;
  stats: IProductsListStatsProps;
}

interface UpdateProductCategoryFromList {
  type: PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY;
  category: ProductCategoryModel;
}

interface UpdateProductCategoryFromListTree
  extends UpdateProductCategoryFromList {}

interface DeleteActionFromList {
  type: PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY;
  categoryUuid: string;
  stats: IProductsListStatsProps;
}

interface DeleteActionFromListTree extends DeleteActionFromList {
  stats: IProductsListStatsProps;
}

interface LoadingActionInList {
  type: PRODUCT_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_LIST;
}

interface ErrorActionInList extends Pick<IProductCategoryListState, 'error'> {
  type: PRODUCT_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_LIST;
}

interface Handlers {
  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
    action: SetActionList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_TREE]: (
    state: IProductCategoryListState,
    action: SetActionTreeList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_STATS]: (
    state: IProductCategoryListState,
    action: SetStatsActionList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_LIST_STATS]: (
    state: IProductCategoryListState,
    action: StatsActionList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.SUB_PRODUCT_CATEGORY_LIST_STATS]: (
    state: IProductCategoryListState,
    action: StatsActionList,
  ) => IProductCategoryListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductCategoryListState,
    action: SetInitialStateAction,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.LOAD_MORE_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
    action: SetActionList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: AddActionToList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_TREE]: (
    state: IProductCategoryListState,
    action: AddActionToListTree,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: UpdateProductCategoryFromList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_TREE]: (
    state: IProductCategoryListState,
    action: UpdateProductCategoryFromListTree,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: DeleteActionFromList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_TREE]: (
    state: IProductCategoryListState,
    action: DeleteActionFromListTree,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
    value: ErrorActionInList,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
    value?: LoadingActionInList,
  ) => IProductCategoryListState;
  DEFAULT: (state: IProductCategoryListState) => IProductCategoryListState;
}

const initState: IProductCategoryListState = {
  categoryList: null,
  categoryListTree: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  treeStatus: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
  stats: {} as IProductsListStatsProps,
};

const handlers: Handlers = {
  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST]: (
    state,
    { categoryList, keywords, total, page, stats },
  ) => {
    return {
      ...state,
      ...{
        categoryList,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total: total >= 0 ? total : state.total,
        page: page || state?.page,
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_TREE]: (
    state,
    { categoryListTree, keywords, total, page, stats },
  ) => {
    return {
      ...state,
      ...{
        categoryListTree,
        treeStatus: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total: total >= 0 ? total : state.total,
        page: page || state?.page,
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_STATS]: (
    state,
    { stats },
  ) => {
    return {
      ...state,
      ...{
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_LIST_STATS]: (state) => {
    return {
      ...state,
      ...{
        stats: {
          ...state?.stats,
          [ProductType.Product]:
            (Number((state?.stats || {})[ProductType.Product]) || 0) + 1,
        } as any,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.SUB_PRODUCT_CATEGORY_LIST_STATS]: (state) => {
    return {
      ...state,
      ...{
        stats: {
          ...state?.stats,
          [ProductType.Product]:
            Number((state?.stats || {})[ProductType.Product]) &&
            Number((state?.stats || {})[ProductType.Product]) > 0
              ? Number((state?.stats || {})[ProductType.Product]) - 1
              : 0,
        } as any,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_CATEGORY_LIST.LOAD_MORE_PRODUCT_CATEGORY_LIST]: (
    state,
    { categoryList },
  ) => ({
    ...state,
    ...{
      categoryList:
        List.isList(state.categoryList) && List.isList(categoryList)
          ? state.categoryList.merge(categoryList)
          : state.categoryList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY]: (
    state,
    { category, stats },
  ) => {
    return {
      ...state,
      ...{
        categoryList: List.isList(state.categoryList)
          ? state.categoryList.size >= 10
            ? state.categoryList.pop().unshift(category)
            : state.categoryList.unshift(category)
          : List([category]),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_TREE]: (
    state,
    { category, parentCategoryUuid, stats },
  ) => {
    const handleCreate = (categories: List<ProductCategoryModel>): any => {
      if (!parentCategoryUuid) {
        return categories?.size >= 10
          ? categories?.pop()?.unshift(category)
          : categories?.unshift(category);
      }

      return categories.map((stateCategory) => {
        if (
          Array.isArray(stateCategory?.children) &&
          stateCategory?.children?.length &&
          stateCategory?.uuid === parentCategoryUuid
        ) {
          return stateCategory.update('children', (children: any) => [
            ...(children || []),
            category,
          ]);
        }

        return stateCategory;
      });
    };

    return {
      ...state,
      ...{
        categoryListTree: List.isList(state.categoryListTree)
          ? handleCreate(state.categoryListTree)
          : List([category]),
        treeStatus: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY]: (state, { category }) => ({
    ...state,
    ...{
      categoryList: List.isList(state.categoryList)
        ? state.categoryList.map((stateProductCategory) => {
            if (stateProductCategory.uuid === category?.uuid) {
              return category;
            }
            return stateProductCategory;
          })
        : List([category]),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_TREE]: (
    state,
    { category },
  ) => {
    const handleUpdate = (categories: List<ProductCategoryModel>): any => {
      return categories.map((stateCategory) => {
        if (stateCategory?.uuid === category?.uuid) {
          return category;
        }

        if (
          Array.isArray(stateCategory?.children) &&
          stateCategory?.children?.length
        ) {
          return stateCategory.update('children', (children) =>
            handleUpdate(children as any),
          );
        }

        return stateCategory;
      });
    };

    return {
      ...state,
      ...{
        categoryListTree: List.isList(state.categoryListTree)
          ? handleUpdate(state.categoryListTree)
          : List([category]),
        treeStatus: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY]: (
    state,
    { categoryUuid, stats },
  ) => ({
    ...state,
    ...{
      categoryList: List.isList(state.categoryList)
        ? state.categoryList?.filter(compose(not, isEqualByUuid(categoryUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_TREE]: (
    state,
    { categoryUuid, stats },
  ) => {
    const handleDelete = (categories: List<ProductCategoryModel>): any => {
      return categories
        ?.map((category) => {
          if (category?.uuid === categoryUuid) {
            return null;
          }

          if (Array.isArray(category?.children) && category?.children?.length) {
            return category?.update('children', (children) =>
              handleDelete(children as any).filter(Boolean),
            );
          }

          return category;
        })
        ?.filter(Boolean);
    };

    return {
      ...state,
      ...{
        categoryListTree: List.isList(state.categoryListTree)
          ? handleDelete(state.categoryListTree)
          : null,
        treeStatus: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
        stats: stats || state?.stats,
      },
    };
  },

  [PRODUCT_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT_CATEGORY_LIST.LOADING_PRODUCT_CATEGORY_LIST]: (
    state: IProductCategoryListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductCategoryListState) => state,
};

export default function ProductCategoryList(
  state: any = initState,
  action: any,
): IProductCategoryListState {
  const handler =
    handlers[action.type as PRODUCT_CATEGORY_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
