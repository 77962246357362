import * as React from 'react';
import moment, { Moment } from 'moment';
import { List } from 'immutable';
import { getUserOperationList } from '@services/api/userOperation';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import { IEntityProps, IListSearchProps } from '@services/types';
import {
  IUserOperationListDTO,
  UserOperationMapper,
  UserOperationModel,
} from '@structure';

export interface IUseUserOperationListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
}

export interface IUseUserOperationListReturnType {
  error: Error | null;
  loading: boolean;
  entityList: List<UserOperationModel> | null;
  refresh: (
    value: Partial<IUseUserOperationListProps>,
  ) => Promise<List<UserOperationModel> | void>;
  total: number;
  offset: number;
  limit: number;
  date_start: Date | Moment | string;
  date_end?: Date | Moment | string;
}

export default function useUserOperationList(
  {
    offset = 0,
    limit = 10,
    loadOnInit = true,
    date_start = moment().toISOString() as string,
    date_end = moment().add(6, 'day').toISOString() as string,
  }: IUseUserOperationListProps = {} as IUseUserOperationListProps,
): IUseUserOperationListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const didCancel = React.useRef<boolean>();

  const {
    offset: listPage,
    limit: listPageSize,
    date_start: listStartDate,
    date_end: listEndDate,
    handleUpdate,
    ...rest
  } = useStateReducer<Omit<IUseUserOperationListReturnType, 'refresh'>>({
    error: null,
    loading: true,
    entityList: null,
    offset,
    limit,
    total: 0,
    date_start,
    date_end,
  });

  const refresh = React.useCallback(
    async (
      {
        offset = listPage,
        limit = listPageSize,
        showLoading = true,
        date_start = listStartDate,
        date_end = listEndDate,
      }: Partial<IUseUserOperationListProps> = {} as IUseUserOperationListProps,
    ): Promise<List<UserOperationModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          date_start,
          date_end,
        });

        const { operations, total } =
          await cancellablePromise<IUserOperationListDTO>(
            getUserOperationList({
              date_start,
              date_end,
              limit,
              offset,
            }),
          );

        const operationListModel = UserOperationMapper.toUserOperationListModel(
          operations,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: operationListModel.operations,
            total,
            loading: false,
          });

          return operationListModel.operations;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      handleUpdate,
      listEndDate,
      listPage,
      listPageSize,
      listStartDate,
    ],
  );

  React.useEffect(() => {
    didCancel.current = false;

    if (loadOnInit) {
      (async () => {
        await refresh({});
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    date_end: listEndDate,
    date_start: listStartDate,
    ...rest,
  };
}
