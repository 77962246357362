import React, { memo, useCallback, useState, useRef } from 'react';
import { Collapse, Space, Popover, PopoverProps } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  getFirstSplittingString,
  isFunction,
  textToUpperCase,
  COLOR_GREEN,
  head,
  eq,
} from '@services/helpers';
import { EventLayout, Routes } from '@services/types';
import { CALENDAR, CALENDAR_ROW_HEIGHT } from '../../lib/const';
import {
  StyledRightOutlined,
  StyledLockOutlined,
  StyledDescription,
} from '../../lib/Styled';
import { Text } from '../../lib/Format';
import { ScheduleCalendarFormDTO, ScheduleCalendarModel } from '@structure';
import { useAcl, useCalendar } from '@contex';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Button } from '../../lib/DataDisplay';
import { CreateOrderButton } from '../../../views/Orders';
import { ORDER_INITIAL_PARAM } from '@services/api/orders';
import { useSize, useStoredCompanies } from '@hooks';
import { PlusOutlined } from '@ant-design/icons';
import Color from 'color';

import './CalendarEventDisplay.less';

export default memo(CalendarEventDisplay);

const { Panel } = Collapse;

export interface ICalendarEventDisplayProps extends PopoverProps {
  direction?: EventLayout;
  lengthOfTime: string;
  event: ScheduleCalendarModel;
  handleSetOpenCollapse?: (isOpen: any) => void; //string[]
  isDragging?: boolean;
  isOpenPopover?: boolean;
  isPopover?: boolean;
  isOpenCollapse?: any; //boolean
  mode?: string;
  disabled?: boolean;

  onSuccess?: (value: ScheduleCalendarFormDTO) => Promise<void>;

  order?: Partial<ScheduleCalendarFormDTO>;
}

const StyledEventDisplay = styled.div<{ isDragging?: boolean }>`
  overflow: visible;
  height: 100%;

  ${({ isDragging }) =>
    isDragging &&
    css`
      //padding: 10px;
    `}
`;

const StyledCollapse = styled(Collapse)<{
  ispopover: number;
  $isDragging: boolean;
}>`
  width: 100%;
  height: ${CALENDAR_ROW_HEIGHT - 5}px;
  justify-content: flex-start;
  overflow: hidden;
  box-shadow: 9px 12px 10px rgba(0, 0, 0, 0.5);

  & .collapse-right {
    color: ${({ theme }) => theme.colors.white};
  }

  & .ant-collapse-header > * {
    color: ${({ theme }) => theme.colors.white};
  }

  &&& .ant-collapse-header-text {
    height: 100%;
  }

  & .ant-collapse-header {
    height: ${CALENDAR_ROW_HEIGHT - 5}px;
    text-align: left;

    ${({ $isDragging }) =>
      $isDragging &&
      css`
        padding: 0 !important;
      `}
    & .ant-collapse-expand-icon,
    .ant-collapse-extra {
      align-self: center;
    }
  }

  ${({ ispopover }) =>
    ispopover &&
    css`
      box-shadow: none;
      overflow: visible;
    `}
`;

const StyledPanel = styled(Panel)<{
  $collapse: boolean;
  color_schema?: string;
}>`
  position: relative;
  background-color: ${COLOR_GREEN};
  transition: all 1s;

  &&& {
    border-radius: 6px;
  }

  & .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-left: 38px;
    text-align: left;
    background-color: transparent;
  }

  & .ant-collapse-content-active {
    box-shadow: 9px 12px 10px rgba(0, 0, 0, 0.5);
  }

  ${({ color_schema }) =>
    color_schema &&
    css`
      background-color: ${color_schema};
      border: 1px solid ${Color(color_schema).alpha(1).darken(0.4).toString()};
    `}

  ${({ $collapse }) =>
    $collapse &&
    css`
      position: absolute;
      left: 10.2%;
      right: 1.9%;
      transition: all 1s;
    `}
`;

const StyledNavigateContainer = styled(Button)<{ isdisable: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${CALENDAR_ROW_HEIGHT}px;

  &:hover span {
    color: ${({ theme }) => theme.colors.link};
  }

  ${({ isdisable }) =>
    !isdisable &&
    css`
      cursor: not-allowed;

      &:hover span {
        color: ${({ theme }) => theme.colors.disabled};
      }
    `}
`;

const StyledItemContainerButton = styled(Button)<{ isdisable: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${CALENDAR_ROW_HEIGHT / 1.5}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding-left: 25px;
  border-radius: 0;

  & span {
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover span.anticon {
    color: ${({ theme }) => theme.colors.link};
  }

  &:hover,
  &:active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.link};
  }

  ${({ isdisable }) =>
    !isdisable &&
    css`
      cursor: not-allowed;

      &:hover span.anticon {
        color: ${({ theme }) => theme.colors.disabled};
      }

      &:hover,
      &:active {
        border-bottom: 1px solid ${({ theme }) => theme.colors.appLightDisable};
      }
    `}
`;

const dragButton = css<{ $isWrap: boolean }>`
  width: 100%;
  height: 100%;
`;

const StyledContinueButton = styled(CreateOrderButton)`
  ${dragButton};
`;

const StyledDragTextContainer = styled(Space)<{ $isWrap: boolean }>`
  min-height: fit-content;
  white-space: nowrap;

  ${({ $isWrap }) =>
    $isWrap &&
    css`
      white-space: normal;
    `}
`;

const StyledDragContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const StyledDragViewContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100%;
  border: 1.5px dashed #00244b;
  margin-top: 2px;
  border-radius: 5px;
`;

const StyledDragViewTextContainer = styled.div`
  position: absolute;
  left: -65px;
  top: 0;
  background-color: #00244b;
  padding: 5px;
  border-radius: 5px;
`;

const StyledDragText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 10px;
`;

const StyledPlusOutlined = styled(PlusOutlined)`
  color: #00244b;
`;

function CalendarEventDisplay({
  direction = 'horizontal',
  title,
  lengthOfTime,
  isDragging,
  event,
  handleSetOpenCollapse,
  isOpenCollapse,
  isOpenPopover,
  mode = 'day',
  isPopover,
  disabled,
  onSuccess,
  order: orderState = {},
  ...rest
}: ICalendarEventDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();
  const { selectedDate, isEdit } = useCalendar();
  const theme: any = useTheme();
  const dragRef = useRef(null);
  const { width: dragWidth } = useSize(dragRef);

  const isWrap = dragWidth <= 240;

  const [collapse, setCollapse] = useState(false);

  const { employee, service, client, order } = useAcl((acl) => acl);
  const { defaultCompanyUuid } = useStoredCompanies();

  const header = (
    <StyledDragContainer ref={dragRef}>
      {!isOpenPopover || !event?.schedule_number ? (
        <>
          {isDragging ? (
            <StyledContinueButton
              $isWrap={isWrap}
              tooltipTitle={null}
              companyUuid={
                (orderState as any)?.companyUuid
                  ? (orderState as any).companyUuid
                  : defaultCompanyUuid
              }
              onSuccess={onSuccess}
              order={{
                ...ORDER_INITIAL_PARAM,
                ...(orderState as any),
                client: { uuid: clientId || '' },
                time: head(lengthOfTime.split('-')).trim(),
                scheduled_date: selectedDate.toString(),
              }}
              type="text">
              <StyledDragText>
                <StyledPlusOutlined />
                <StyledDragViewTextContainer>
                  <Text color={theme.colors.white}>
                    {`${
                      isDragging
                        ? head(lengthOfTime.split('-')).includes('Invalid')
                          ? '00:00'
                          : head(lengthOfTime.split('-'))
                        : lengthOfTime
                    }`}
                  </Text>
                </StyledDragViewTextContainer>
              </StyledDragText>
            </StyledContinueButton>
          ) : (
            <StyledDragTextContainer
              direction="vertical"
              size={isWrap ? 0 : 'small'}
              $isWrap={isWrap}>
              {event?.schedule_number ? (
                <Text bold>{`№ ${event?.schedule_number}`}</Text>
              ) : (
                <Text bold>
                  {isEdit ? t('Current order time') : t('New order')}
                </Text>
              )}

              <Text color={theme.colors.white}>
                <Text bold>{`${t('Time')}: `}</Text>
                {`${isDragging ? head(lengthOfTime.split('-')) : lengthOfTime}`}
              </Text>
            </StyledDragTextContainer>
          )}
        </>
      ) : (
        <StyledDragTextContainer
          direction="vertical"
          size={isWrap ? 0 : 'small'}
          $isWrap={isWrap}>
          {event?.schedule_number ? (
            <StyledDescription
              $color={theme.colors.white}
              bold>{`№ ${event?.schedule_number}`}</StyledDescription>
          ) : (
            <StyledDescription bold $color={theme.colors.white}>
              {isEdit ? t('Current order time') : t('New order')}
            </StyledDescription>
          )}

          <StyledDescription $color={theme.colors.white}>
            <StyledDescription bold $color={theme.colors.white}>{`${t(
              'Time',
            )}: `}</StyledDescription>
            {`${isDragging ? head(lengthOfTime.split('-')) : lengthOfTime}`}
          </StyledDescription>
        </StyledDragTextContainer>
      )}
    </StyledDragContainer>
  );

  const handleNavigateSchedulePage = useCallback((): void => {
    if (order?.read) {
      let path = getFirstSplittingString(location.pathname, mode);

      if (!path.includes(CALENDAR)) {
        path = `${path}/${Routes.schedules}/${CALENDAR}/`;
      }

      navigate(`${path}entry/${event?.uuid}`, { state: location?.state || {} });
    }
  }, [
    event?.uuid,
    location.pathname,
    location?.state,
    mode,
    navigate,
    order?.read,
  ]);

  const handleNavigateEmployeePage = useCallback(() => {
    if (employee?.read) {
      navigate(
        `/${Routes.app}/${Routes.employees}/${event?.employeeModel?.uuid}`,
      );
    }
  }, [employee?.read, event?.employeeModel?.uuid, navigate]);

  const handleNavigateClientPage = useCallback(() => {
    if (client?.read) {
      navigate(`/${Routes.app}/${Routes.clients}/${event?.clientModel?.uuid}`);
    }
  }, [client?.read, event?.clientModel?.uuid, navigate]);

  return (
    <Popover
      open={isOpenPopover ? undefined : false}
      getPopupContainer={(triggerNode) => {
        const div = document.querySelector('#calendar-event-display');

        if (div instanceof HTMLElement) {
          return div;
        }
        return triggerNode;
      }}
      trigger="click"
      placement="left"
      {...rest}>
      {direction === 'horizontal' ? (
        <StyledEventDisplay
          isDragging={isDragging}
          className="event-display"
          onClick={(event) => {
            event?.stopPropagation();
          }}>
          {isDragging ? (
            <StyledDragViewContainer
              className="drag-event"
              // onMouseDown={(e) => {
              //   const eventView = document.body.querySelector('.dragged-event');
              //   const ball = e?.target;
              //
              //   if (ball instanceof HTMLElement) {
              //     // @ts-ignore
              //     function moveAt(pageX: number, pageY: number, element: any) {
              //       if (ball instanceof HTMLElement) {
              //         const x = pageX - ball.offsetWidth / 2 - 3 + 'px';
              //         const y = pageY - ball.offsetHeight / 2 - 7 + 'px';
              //
              //         element.style.transform = `translate(${x}, ${y})`;
              //       }
              //     }
              //
              //     const dragstartEvent = new DragEvent('dragstart', {
              //       bubbles: true,
              //       cancelable: false,
              //     });
              //
              //     const dragoverEvent = new DragEvent('dragover', {
              //       bubbles: true,
              //       cancelable: false,
              //     });
              //
              //     eventView!.dispatchEvent(dragstartEvent);
              //
              //     // @ts-ignore
              //     function onMouseMove(event: any) {
              //       const test = document.body.querySelector('.test-is-drag');
              //
              //       if (test instanceof HTMLElement) {
              //         moveAt(event.pageX, event.pageY, test);
              //
              //         console.log(event);
              //
              //         const {time: eventTime}: any = snapToGridTime(
              //           {x: 494.96875, y: 331},
              //           {x: event.pageX, y: event.pageY},
              //           480,
              //         );
              //
              //         // console.log(eventTime);
              //
              //         ball.dispatchEvent(dragoverEvent);
              //       } else {
              //         // moveAt(event.pageX, event.pageY, ball);
              //         eventView!.dispatchEvent(dragoverEvent);
              //       }
              //     }
              //
              //     const testsdsd = document.body.querySelector(
              //       '.app-content__observe',
              //     );
              //
              //     if (testsdsd instanceof HTMLElement) {
              //       testsdsd.addEventListener('mousemove', onMouseMove);
              //     }
              //   }
              // }}
            >
              {header}
            </StyledDragViewContainer>
          ) : (
            <StyledCollapse
              $isDragging={!!isDragging}
              ispopover={isPopover ? 1 : 0}
              onChange={(value) => {
                if (
                  isFunction(handleSetOpenCollapse) &&
                  !isOpenPopover &&
                  !(event as any)?.isDragging &&
                  !disabled
                ) {
                  handleSetOpenCollapse(value as any);
                  setCollapse(!!value?.length);
                }
              }}
              accordion
              bordered={false}
              {...(isOpenPopover || (event as any)?.isDragging || disabled
                ? { expandIcon: () => null }
                : {})}>
              <StyledPanel
                id="event-display__ignore-hidden"
                color_schema={theme.colors.success}
                $collapse={
                  eq(mode, 'day') ? collapse : !!(isOpenCollapse && !isPopover)
                }
                header={header}
                key="1"
                extra={
                  isOpenPopover || (event as any)?.isDragging ? null : (
                    <StyledNavigateContainer
                      isdisable={order?.read ? 1 : 0}
                      type="text"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleNavigateSchedulePage();
                      }}>
                      {order?.read ? (
                        <StyledRightOutlined className="collapse-right" />
                      ) : (
                        <StyledLockOutlined style={{ fontSize: 20 }} />
                      )}
                    </StyledNavigateContainer>
                  )
                }>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: '100%' }}>
                  {event?.clientModel ? (
                    <StyledItemContainerButton
                      isdisable={client?.read ? 1 : 0}
                      type="text"
                      onClick={handleNavigateClientPage}>
                      <Text>
                        <Text bold>{`${t('Client')}: `}</Text>
                        {`${textToUpperCase(
                          event?.clientModel.fullNameClient,
                        )}`}
                      </Text>
                      {client?.read ? (
                        <StyledRightOutlined />
                      ) : (
                        <StyledLockOutlined style={{ fontSize: 20 }} />
                      )}
                    </StyledItemContainerButton>
                  ) : null}
                  {event?.employeeModel ? (
                    <StyledItemContainerButton
                      isdisable={employee?.read ? 1 : 0}
                      type="text"
                      onClick={handleNavigateEmployeePage}>
                      <Text>
                        <Text bold>{`${t('Employee')}: `}</Text>
                        {`${textToUpperCase(event?.employeeModel.fullName)}`}
                      </Text>
                      {employee?.read ? (
                        <StyledRightOutlined />
                      ) : (
                        <StyledLockOutlined style={{ fontSize: 20 }} />
                      )}
                    </StyledItemContainerButton>
                  ) : null}
                </Space>
              </StyledPanel>
            </StyledCollapse>
          )}
        </StyledEventDisplay>
      ) : (
        <div className="event-display-vertical">
          <div className="event-display-vertical--dot event-display-vertical--indent" />
          <span className="event-display-vertical-text event-display-vertical--indent">
            {getFirstSplittingString(lengthOfTime)}
          </span>
          <span className="event-display-vertical-text event-display-vertical-text--bold">
            {`№ ${event?.schedule_number}`}
          </span>
        </div>
      )}
    </Popover>
  );
}
