import React, { useMemo } from 'react';
import { StyledTitle } from '@components/lib/Styled';
import { correctPrice } from '@services/helpers';
import styled, { useTheme } from 'styled-components';
import { DetailsView } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import { useStoredCompanies } from '@hooks';
import { useDefaultForm } from '@contex';
import {
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StorePaymentDocumentDiscountType,
} from '@structure';

export interface StoreRealizationDocItemsTableTotalViewProps {
  document?: StoreDocumentFormDTO;
  className?: string;
}

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  //margin-bottom: 20px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
`;

const StyledProfitTitle = styled(StyledTitle)`
  font-size: 14px;
  font-weight: 300;
`;

export function StoreRealizationDocItemsTableTotalView({
  document,
  className,
}: StoreRealizationDocItemsTableTotalViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();
  const { formData }: any = useDefaultForm();
  const theme: any = useTheme();

  const total = useMemo(
    () =>
      formData?.doc_items?.reduce(
        (acc: number, curr: StoreDocumentItemFormDTO) => {
          acc += Number(curr?.base_price) * curr?.product_amount;

          return acc;
        },
        0,
      ),
    [formData?.doc_items],
  );

  const discount =
    document?.doc_discount_type === StorePaymentDocumentDiscountType.FIXED
      ? total - (total - (Number(document?.doc_discount_value) || 0))
      : document?.doc_discount_type ===
        StorePaymentDocumentDiscountType.PERCENTAGE
      ? total * ((Number(document?.doc_discount_value) || 0) / 100)
      : 0;

  const toBePaid = total - discount;

  const profitSum = Number(document?.stats?.profit_sum || 0);

  const profitSumTotal = profitSum > 0 ? profitSum - discount : 0;

  return (
    <StyledDetailsView titles={[]} className={className}>
      <StyledStatsContainer>
        <StyledTitle fontSize={15} bold>{`${t('Total')}, ${
          defaultCompany?.currency_symbol || ''
        }: ${correctPrice(total || 0)}`}</StyledTitle>
        {Number(document?.doc_discount_value || 0) > 0 ? (
          <>
            <StyledTitle $color={theme.colors.link} fontSize={15} bold>{`${t(
              'Discount',
            )}, ${defaultCompany?.currency_symbol || ''}: ${correctPrice(
              discount,
            )}`}</StyledTitle>
            <StyledTitle fontSize={17} bold>{`${t('To be paid')}, ${
              defaultCompany?.currency_symbol || ''
            }: ${correctPrice(toBePaid)}`}</StyledTitle>
          </>
        ) : null}
        {profitSumTotal > 0 ? (
          <StyledProfitTitle
            $color={theme.colors.success}
            fontSize={17}
            bold>{`${t('Estimated profit')}, ${
            defaultCompany?.currency_symbol || ''
          }: ${correctPrice(profitSumTotal)}`}</StyledProfitTitle>
        ) : null}
      </StyledStatsContainer>
    </StyledDetailsView>
  );
}
