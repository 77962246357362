import React from 'react';
import Space from 'antd/es/space';
import { FlexContainer } from '../Styled/FlexContainer';

export interface ITableActionCellProps {
  children: React.ReactNode;
}

export default function TableActionCell({ children }: ITableActionCellProps) {
  return (
    <FlexContainer justifyContent="center">
      <Space size="large">{children}</Space>
    </FlexContainer>
  );
}
