import React, { useCallback, useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useStoredDepartmentList,
  useStopLoading,
  useStoredCompanies,
  useStoredDepartment,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '@hooks';
import {
  DepartmentCreateButton,
  DepartmentDeleteButton,
  DepartmentUpdateButton,
} from '../Buttons';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { DepartmentMapper, DepartmentModel } from '@structure';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { useAcl } from '@contex';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { useNavigate } from 'react-router';
import { ListLayout } from '@components/lib/Layout';
import { DepartmentDetailsView } from '../Show';
import '../../Orders/List/OrderList.less';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { successButtonStyle } from '@components/lib/Styled';

export interface IDepartmentListPageProps {}

const StyledDepartmentCreateButton = styled(DepartmentCreateButton)`
  ${successButtonStyle}
`;

export function DepartmentListPage(): JSX.Element {
  const navigate = useNavigate();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ department }) => department);
  const { t } = useTranslation();

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'department-create-event',
    disabled: !manage,
  });

  const [selectedDepartment, setSelectedDepartment] = useState<
    DepartmentModel[]
  >([]);

  const {
    departmentList,
    limit,
    total,
    refresh: refreshDepartmentList,
    loading: loadingDepartmentList,
    loadingMore,
    error: errorDepartmentList,
    handleDeleteDepartments: onDeleteDepartments,
    handleSearchDepartments,
  } = useStoredDepartmentList({ companyUuid: defaultCompanyUuid });

  const { handleUpdateDepartment, handleCreateDepartment } =
    useStoredDepartment({
      loadOnInit: false,
      companyUuid: defaultCompanyUuid,
      departmentUuid: '',
    });

  const loading = useStopLoading({
    loading: loadingDepartmentList || loadingMore,
    error: errorDepartmentList,
    message: 'An error occurred during departments loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteDepartments = useCallback(
    async (value: string[]) => {
      await onDeleteDepartments(value);
      focus();
    },
    [focus, onDeleteDepartments],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.departments}`,
      breadcrumbName: 'Locations',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedDepartment.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: DepartmentModel[],
      ) => {
        setSelectedDepartment(selectedRecords);
      },
    }),
    [selectedDepartment],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      width: '80%',
      className: 'order-list-td',
      render: (department: DepartmentModel) => (
        <DepartmentDetailsView
          department={
            {
              dep_name: department?.dep_name,
              uuid: department?.uuid,
              dep_colour: department?.dep_colour,
              dep_icon: department?.dep_icon,
            } as DepartmentModel
          }
        />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'department_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (department: DepartmentModel) => {
        return (
          <TableActionCell>
            <DepartmentUpdateButton
              disabled={!manage}
              department={DepartmentMapper.toDepartmentFormDTO(department)}
              onSuccess={handleUpdateDepartment}
              onCancel={daleyFocus}
            />
            <DepartmentDeleteButton
              disabled={!manage}
              departments={[department]}
              onSuccess={handleDeleteDepartments}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.departments]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={t(RoutesTitle[RoutesType.departments])}
      headerRight={
        <>
          {selectedDepartment?.length ? (
            <DepartmentDeleteButton
              disabled={!manage}
              departments={selectedDepartment}
              onSuccess={handleDeleteDepartments}
            />
          ) : (
            <DepartmentCreateButton
              disabled={!manage}
              type="primary"
              loading={loading}
              icon={<PlusOutlined />}
              title={t('Create')}
              onSuccess={handleCreateDepartment}
              className="department-create-event"
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        departmentList?.size ? null : (
          <Empty description={t('There is no any location in the system.')}>
            <DepartmentCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateDepartment}
              title={t('Create')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchDepartments({ keywords })}
          inputTooltip={t('Search departments / locations')}
          inputLabel={t('Search departments / locations')}
          withSearchContent={null}
        />
      }
      loading={loading && !departmentList?.size}>
      <Table<DepartmentModel>
        pageSize={limit}
        total={total}
        onChange={refreshDepartmentList}
        rowSelection={rowSelection}
        dataSource={departmentList}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
