import React from 'react';
import { Routes, Route } from 'react-router';
import { ReportingPanelPage, ReportingListPage } from '../views/Reporting';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesTypes } from '@services/types';

export function ReportingRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ReportingPanelPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={RoutesTypes.reportsArchive}
        element={
          <AppRouteLayout>
            <ReportingListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
