import {
  CompanyDTO,
  CompanyMapper,
  IAbonementTimingList,
  ICompanyDTOProps,
  IDepartmentModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export enum DepartmentStatusesText {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted',
  ERROR = 'error',
}

export enum DepartmentIcon {
  DEFAULT = 'default',
  REGULAR = 'regular',
}

export interface IDepartmentDTOProps {
  uuid: string;
  dep_name: string;
  dep_address: string;
  dep_icon: DepartmentIcon;
  dep_description: string;
  dep_status_text: DepartmentStatusesText;
  created_at: string;
  updated_at: string;
  dep_schedules_list: IAbonementTimingList;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
  dep_is_default: boolean;
  dep_colour: string;
}

export class DepartmentDTO {
  public uuid: string;

  public dep_name: string;

  public dep_address: string;

  public dep_icon: DepartmentIcon;

  public dep_description: string;

  public dep_status_text: DepartmentStatusesText;

  public created_at: string;

  public updated_at: string;

  public dep_schedules_list: IAbonementTimingList;

  public created_by: ProfileDTO;

  public company: ICompanyDTOProps;
  public dep_is_default: boolean;
  public dep_colour: string;

  constructor(
    props: IDepartmentModelReturnType | IDepartmentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.dep_name = props?.dep_name || '';
    this.dep_address = props?.dep_address || '';
    this.dep_icon = props?.dep_icon || DepartmentIcon.REGULAR;
    this.dep_description = props?.dep_description || '';
    this.dep_status_text =
      props?.dep_status_text || DepartmentStatusesText.DISABLED;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.dep_is_default = props?.dep_is_default ?? false;
    this.dep_colour = props?.dep_colour ?? '';
    this.dep_schedules_list =
      props?.dep_schedules_list || ({} as IAbonementTimingList);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.department,
            ...withoutRecursion,
          ]);

    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.department,
            ...withoutRecursion,
          ]);
  }
}
