import { Record, List } from 'immutable';
import { IListProps } from './List';
import { EmployeeServiceDTO, EmployeeServiceModel } from '../internal';
import { WithoutRecursion } from '../type';

export interface IEmployeeServiceListModelProps extends IListProps {
  employeeServices: EmployeeServiceDTO[];
}

export interface IEmployeeServiceListModelReturnType extends IListProps {
  employeeServices: List<EmployeeServiceModel>;
}

export class EmployeeServiceListModel extends Record<IEmployeeServiceListModelReturnType>(
  {
    employeeServices: List(),
    total: 0,
  },
) {
  constructor(
    {
      employeeServices = [],
      total,
    }: IEmployeeServiceListModelProps = {} as IEmployeeServiceListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IEmployeeServiceListModelReturnType = {
      employeeServices: List(
        employeeServices.map(
          (employeeService) =>
            new EmployeeServiceModel(employeeService, withoutRecursion),
        ),
      ),
      total,
    };

    super(options);
  }
}
