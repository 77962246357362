import React, { useCallback, useEffect, useState } from 'react';
import { Gallery, Image } from 'react-grid-gallery';
import { IUseStateClientFileListReturnType } from '@hooks';
import { List } from 'immutable';
import { listToArray } from '@services/helpers';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { LoadingMore } from '@components/lib/DataDisplay';
import { Spin } from 'antd';

export interface ClientFileListGalleryProps
  extends Pick<
    IUseStateClientFileListReturnType,
    'files' | 'loading' | 'isLoadingMore' | 'handleLoadingMoreClientFileList'
  > {}

export function ClientFileListGallery({
  files,
  loading,
  isLoadingMore,
  handleLoadingMoreClientFileList,
}: ClientFileListGalleryProps): React.JSX.Element {
  const [images, setImages] = useState<Image[]>([]);
  const [slides, setSlides] = useState<any[]>([]);
  const [index, setIndex] = useState(-1);

  const handleClick = useCallback((index: number) => setIndex(index), []);

  useEffect(() => {
    if (List.isList(files) && files?.size) {
      const images = files?.map(({ file_url }) => ({
        src: file_url,
        origin: file_url,
        width: 5,
        height: 5,
      }));

      const slides = images?.map(({ origin }) => ({
        src: origin,
      }));

      setImages(listToArray(images));
      setSlides(listToArray(slides));
    }
  }, [files]);

  return (
    <Spin spinning={loading}>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <LoadingMore
        loading={isLoadingMore}
        observerCallback={handleLoadingMoreClientFileList}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </Spin>
  );
}
