import Color from 'color';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import styled, { useTheme } from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ClientBalanceOperationButton } from '../Buttons';
import { ListLayout } from '@components/lib/Layout';
import React, { useCallback, useMemo, useState } from 'react';
import { Table } from '@components/lib/libV2/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { ClientHeader, ClientOperationListBottomView } from '../Show';
import { isThereContent } from '@sportix/sportix-common-modules';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { RELATED_ROUTES_NAME, RELATED_ROUTES_TITLE } from '../../Stores';

import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateClientBalanceOperationList,
  useStopLoading,
  useStoredCompanies,
} from '@hooks';

import {
  len,
  correctPrice,
  toDateByFormat,
  lessOrEqualThan,
  textToUpperCase,
} from '@services/helpers';

import {
  ClientMapper,
  ClientOperationType,
  ClientOperationModel,
} from '@structure';

import {
  Routes,
  Routes as RoutesType,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';

import { HeaderLeft, ListActions, Link } from '@components/lib/DataDisplay';

import './ClientBalanceOperationListPage.less';

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  justify-content: flex-start;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const StyledListActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const StyledCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  font-size: 12px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.warning};
  font-size: 20px;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  background-color: ${({ theme }) =>
    Color(theme.colors.warning).lighten(0.5).string()};
  padding: 10px;
  border-radius: 7px;
`;

export function ClientBalanceOperationListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { alert } = useDropdownAlert();
  const { clientId } = useParams();
  const theme: any = useTheme();

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'client-balance-create-event',
    disabled: false,
  });

  const [selectedOperations, setSelectedOperations] = useState<
    ClientOperationModel[]
  >([]);

  const {
    operations,
    loading: operationsListLoading,
    error: operationsListError,
    limit: pageSize,
    total,
    client,
    stats,

    refresh,
    handleAdjustmentBalance,
    handleSearchOperations,
  } = useStateClientBalanceOperationList({
    clientUuid: clientId!,
  });

  const loading = useStopLoading({
    error: operationsListError,
    loading: operationsListLoading,
    message: 'An error occurred during operations loading',
  });

  const { inputRef, daleyFocus } = useSearchInputFocus({ loading });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName:
        location?.state?.name || client?.fullNameClient || 'Show Client',
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.clientBalanceOperations}`,
      breadcrumbName: 'Balance operations',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedOperations.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ClientOperationModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedOperations(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedOperations, t],
  );

  const columns = [
    {
      title: t('Date'),
      key: 'date',
      className: 'client-operation-list-td',
      render: (operation: ClientOperationModel) =>
        toDateByFormat(operation.created_at, 'DD.MM.YYYY HH:mm'),
    },
    {
      title: t('Comment'),
      key: 'comment',
      render: (operation: ClientOperationModel) => (
        <StyledCommentContainer>
          <StyledTitle>{operation.operation_comment}</StyledTitle>
          {operation?.operation_triggerable_type ===
            ClientOperationType.STORE_DOCUMENT &&
          !!operation?.operation_triggerable_object?.uuid ? (
            <StyledDescriptionContainer>
              <StyledDescription>{`${t(
                'Related document',
              )}: `}</StyledDescription>
              <StyledLink
                tooltip={t('Open a detailed description of the document')}
                to={`/${Routes?.app}/${Routes.stores}/${
                  RELATED_ROUTES_NAME[
                    operation?.operation_triggerable_object?.doc_type
                  ]
                }/${operation?.operation_triggerable_object?.uuid}`}>{`${t(
                RELATED_ROUTES_TITLE[
                  operation?.operation_triggerable_object?.doc_type
                ],
              )} №${
                operation?.operation_triggerable_object?.doc_local_number
              }`}</StyledLink>
            </StyledDescriptionContainer>
          ) : null}
        </StyledCommentContainer>
      ),
    },
    {
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'date',
      className: 'client-operation-list-td',
      align: 'right' as any,
      render: (operation: ClientOperationModel) => (
        <StyledTitle
          $color={
            operation?.operation_sum?.startsWith('-')
              ? theme.colors.error
              : theme.colors.success
          }>
          {correctPrice(operation?.operation_sum || 0)}
        </StyledTitle>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.clients]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        t(RoutesTitle[RoutesType.clientBalanceOperations]),
      )}
      routes={routes}
      empty={
        operations?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any operations at the moment.`,
            )}
          />
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <StyledContainer>
            <ClientHeader withoutUpload client={client} />

            <StyledButtonContainer>
              <ClientBalanceOperationButton
                balance={ClientMapper.toClientOperationFormDTO(client!)}
                type="primary"
                onSuccess={handleAdjustmentBalance}
                title={t('Adjustment')}
                onCancel={daleyFocus}
              />
            </StyledButtonContainer>
          </StyledContainer>
          {Number(client?.saldo || 0) === 0 ? null : (
            <StyledMessage>
              <StyledInfoCircleOutlined />
              <StyledDescription
                $color={theme.colors.black}
                style={{ whiteSpace: 'unset' }}>
                {t(
                  'To repay the debt, you need to go to the page of the debt object and in the Payments section - create a corresponding payment document.',
                )}
              </StyledDescription>
            </StyledMessage>
          )}
          <ListActions
            inputRef={inputRef}
            withoutPicker
            loading={loading}
            handleSearch={(keywords) =>
              handleSearchOperations({ keywords }) as any
            }
            inputTooltip={t('Search for client transactions')}
            inputLabel={t('Search for client transactions')}
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        isThereContent(stats) ? (
          <ClientOperationListBottomView stats={stats} />
        ) : null
      }
      loading={loading && !operations?.size}>
      <Table<ClientOperationModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={operations}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
