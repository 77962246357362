import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  ProductStoreDocumentStatsDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentType,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import {
  getProductStoreDocumentList,
  IProductStoreDocumentListReturnType,
} from '@services/api/product';

export interface IUseProductStoreDocumentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  productUuid: string;
  doc_type?: StoreDocumentType;
  show_with_credits_only?: boolean;
}

export interface IUseProductStoreDocumentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<StoreDocumentModel> | null;
  refresh: (
    value: Partial<IUseProductStoreDocumentListProps>,
  ) => Promise<List<StoreDocumentModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  productUuid: string;
  doc_type?: StoreDocumentType;
  show_with_credits_only?: boolean;
  stats: ProductStoreDocumentStatsDTO;
}

export function useProductStoreDocumentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    productUuid,
    doc_type,
    show_with_credits_only,
  }: IUseProductStoreDocumentListProps = {} as IUseProductStoreDocumentListProps,
): IUseProductStoreDocumentListReturnType {
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    productUuid: listProductUuid,
    doc_type: listDocType,
    stats: listStats,
    show_with_credits_only: listShowWithCreditsOnly,
    handleUpdate,
  } = useStateReducer<Omit<IUseProductStoreDocumentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    productUuid,
    doc_type,
    stats: {} as ProductStoreDocumentStatsDTO,
    show_with_credits_only,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      productUuid = listProductUuid,
      doc_type = listDocType,
      show_with_credits_only = listShowWithCreditsOnly,
    }: Partial<IUseProductStoreDocumentListProps> = {}): Promise<List<StoreDocumentModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          productUuid,
          doc_type,
          show_with_credits_only,
        });

        const { documents: storeDocuments, stats } =
          await cancellablePromise<IProductStoreDocumentListReturnType>(
            getProductStoreDocumentList({
              productUuid,
              limit,
              offset,
              keywords,
              doc_type,
              show_with_credits_only,
            }),
          );

        const { documents, total } = storeDocuments || {};

        const documentListModel = StoreDocumentMapper.toStoreDocumentListModel(
          documents,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: documentListModel?.documents,
            total: documentListModel?.total,
            loading: false,
            stats,
          });

          return documentListModel?.documents;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listProductUuid,
      listDocType,
      listShowWithCreditsOnly,
      handleUpdate,
      cancellablePromise,
      didCancel,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && productUuid) {
      (async () => {
        handleUpdate({ productUuid });

        await refresh({ productUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, productUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    productUuid: listProductUuid,
    stats: listStats,
    doc_type: listDocType,
    show_with_credits_only: listShowWithCreditsOnly,
  };
}
