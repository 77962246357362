import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  SettingsModuleMapper,
  SettingsModuleModel,
  SettingsModuleListModel,
  ISettingsModuleListDTO,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getSettingsModuleList } from '@services/api/system';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseSettingsModuleListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
}

export interface IUseSettingsModuleListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<SettingsModuleModel> | null;
  refresh: (
    value: Partial<IUseSettingsModuleListProps>,
  ) => Promise<SettingsModuleListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
}

export function useSettingsModuleList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
  }: IUseSettingsModuleListProps = {} as IUseSettingsModuleListProps,
): IUseSettingsModuleListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
    companyUuid: listCompanyUuid,
  } = useStateReducer<Omit<IUseSettingsModuleListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
    }: Partial<IUseSettingsModuleListProps> = {}): Promise<SettingsModuleListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const { settingsModules, total } =
          await cancellablePromise<ISettingsModuleListDTO>(
            getSettingsModuleList({
              limit,
              offset,
              keywords,
              companyUuid,
            }),
          );

        const settingsModuleListModel =
          SettingsModuleMapper.toSettingsModuleListModel(
            settingsModules,
            total,
          );

        handleUpdate({
          entityList: settingsModuleListModel?.settingsModules,
          total: settingsModuleListModel?.total,
          loading: false,
        });

        return settingsModuleListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Services'),
          `${t('An error occurred during get service list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
  };
}
