import { Record } from 'immutable';
import {
  IDepCompanyUsageProps,
  SettingsModuleDependencyDTO,
  SettingsModuleDependencyStatus,
} from '../internal';

export interface ISettingsModuleDependencyModelReturnType {
  uuid: string;
  dep_title: string;
  dep_description: string;
  dep_price: string;
  dep_limit: number;
  dep_status: SettingsModuleDependencyStatus;
  created_at: string;
  updated_at: string;
  dep_company_usage: IDepCompanyUsageProps | null;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class SettingsModuleDependencyModel extends Record<ISettingsModuleDependencyModelReturnType>(
  {
    uuid: '',
    dep_title: '',
    dep_description: '',
    dep_price: '',
    dep_limit: 0,
    dep_status: SettingsModuleDependencyStatus.Active,
    created_at: '',
    updated_at: '',
    dep_company_usage: null,
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: SettingsModuleDependencyDTO = {} as SettingsModuleDependencyDTO,
  ) {
    super(props);
  }
}
