import React from 'react';
import CalendarCol, { ICalendarColProps } from './CalendarCol';
import CalendarEvent from './CalendarEvent';
import { EventType, IDayTimeRange } from '@services/helpers';
import { EventLayout } from '@services/types';

export interface ICalendarEventColProps<T>
  extends Pick<
    ICalendarColProps,
    | 'span'
    | 'hourInDay'
    | 'withAddingEvents'
    | 'isPastDate'
    | 'isTodayTimeLimit'
  > {
  events: EventType;
  direction?: EventLayout;
  showTimeLine?: boolean;
  showTimeLimit?: boolean;
  isToday: boolean;
  weekdayInfo?: IDayTimeRange;
  lastTime?: string;
  loading?: boolean;
  disabled?: boolean;
}

export default function CalendarEventCol<T>({
  events,
  direction,
  isToday,
  showTimeLine,
  weekdayInfo,
  lastTime,
  isPastDate,
  loading,
  disabled,
  ...rest
}: ICalendarEventColProps<T>) {
  return (
    <CalendarCol {...rest} isPastDate={isPastDate}>
      {({ time }) => (
        <CalendarEvent
          events={events}
          time={time}
          direction={direction}
          isToday={isToday}
          showTimeLine={showTimeLine}
          weekdayInfo={weekdayInfo}
          lastTime={lastTime}
          isPastDate={isPastDate}
          loading={loading}
          disabled={disabled}
        />
      )}
    </CalendarCol>
  );
}
