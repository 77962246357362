import React, { useCallback, useState } from 'react';
import {
  DownOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { tableScreen } from '@services/const';
import { StyledTitle } from '@components/lib/Styled';
import { IUseStateInvoiceReturnType } from '@hooks';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import { Dropdown, MenuProps, Space } from 'antd';
import { IoDocumentText } from 'react-icons/io5';
import { HiDocumentCheck, HiDocumentArrowDown } from 'react-icons/hi2';

export interface InvoicePrintButtonProps
  extends Pick<
    IUseStateInvoiceReturnType,
    | 'invoice'
    | 'handlePrintInputInvoice'
    | 'handlePrintInvoiceStoreRealization'
    | 'handlePrintInvoice'
  > {
  contentWidth: number;
}

const StyledPrinterOutlined = styled(PrinterOutlined)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.black};
`;

const StyledDropdown = styled(Dropdown)<{ disabled?: boolean }>`
  height: fit-content;
  min-width: 44px;
  min-height: 44px;

  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;

      & * {
        color: ${({ theme }) => theme.colors.disabled};
      }
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        border: 1px solid ${({ theme }) => theme.colors.black};
        opacity: 0.8;
      }
    `}
`;

enum InvoicePrintType {
  INVOICE = 'invoice',
  REALIZATION = 'realization',
  INPUT_INVOICE = 'input invoice',
  UNKNOWN = 'unknown',
}

export function InvoicePrintButton({
  invoice,
  handlePrintInvoice: onPrintInvoice,
  handlePrintInvoiceStoreRealization: onPrintInvoiceStoreRealization,
  handlePrintInputInvoice: onPrintInputInvoice,
  contentWidth,
}: InvoicePrintButtonProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [loading, setLoading] = useState<InvoicePrintType>(
    InvoicePrintType.UNKNOWN,
  );

  const handlePrintInvoice = useCallback(async () => {
    setLoading(InvoicePrintType.INVOICE);
    await onPrintInvoice();
    setLoading(InvoicePrintType.UNKNOWN);
  }, [onPrintInvoice]);

  const handlePrintInvoiceStoreRealization = useCallback(async () => {
    setLoading(InvoicePrintType.REALIZATION);
    await onPrintInvoiceStoreRealization();
    setLoading(InvoicePrintType.UNKNOWN);
  }, [onPrintInvoiceStoreRealization]);

  const handlePrintInputInvoice = useCallback(async () => {
    setLoading(InvoicePrintType.INPUT_INVOICE);
    await onPrintInputInvoice();
    setLoading(InvoicePrintType.UNKNOWN);
  }, [onPrintInputInvoice]);

  const invoiceItems: MenuProps['items'] = [
    {
      key: 'Print invoice',
      icon:
        loading === InvoicePrintType.INVOICE ? (
          <LoadingOutlined />
        ) : (
          <IoDocumentText size={15} color={theme.colors.black} />
        ),
      label: <StyledTitle>{t('Print invoice')}</StyledTitle>,
      onClick: handlePrintInvoice,
    },
    ...(invoice?.has_order_operations
      ? [
          {
            key: 'Print sale invoice',
            icon:
              loading === InvoicePrintType.REALIZATION ? (
                <LoadingOutlined />
              ) : (
                <HiDocumentCheck size={15} color={theme.colors.black} />
              ),
            label: <StyledTitle>{t('Print sale invoice')}</StyledTitle>,
            onClick: handlePrintInvoiceStoreRealization,
          },
        ]
      : []),

    ...(invoice?.has_store_operations
      ? [
          {
            key: 'Print an order letter',
            icon:
              loading === InvoicePrintType.INPUT_INVOICE ? (
                <LoadingOutlined />
              ) : (
                <HiDocumentArrowDown size={15} color={theme.colors.black} />
              ),
            label: <StyledTitle>{t('Print an order letter')}</StyledTitle>,
            onClick: handlePrintInputInvoice,
          },
        ]
      : []),
  ];

  return (
    <StyledDropdown
      menu={{ items: invoiceItems }}
      placement="topLeft"
      disabled={loading !== InvoicePrintType.UNKNOWN}
      trigger={['click']}>
      <Space direction="horizontal" size="middle">
        <Space direction="horizontal" size="small">
          {loading !== InvoicePrintType.UNKNOWN ? (
            <LoadingOutlined />
          ) : (
            <StyledPrinterOutlined />
          )}
          {contentWidth >= tableScreen ? (
            <StyledTitle fontSize={14}>{t('Print')}</StyledTitle>
          ) : null}
        </Space>

        <StyledDownOutlined />
      </Space>
    </StyledDropdown>
  );
}
