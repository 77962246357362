import { IProfileDTOProps } from '../internal';

export enum PhotoType {
  PRODUCT = 'product',
}

export enum PhotoStatus {
  ENABLE = 'enabled',
  DISABLED = 'disabled',
}

export interface PhotoDTO {
  uuid: string;
  file_original_name: string;
  file_url: string;
  fileable_type: PhotoType;
  file_status_text: PhotoStatus;
  created_at: string;
  updated_at: string;
  created_by: IProfileDTOProps;
}

export interface PhotoListDTO {
  invoices: PhotoDTO[];
  total: number;
}
