import { EmployeeModel, IScheduleCalendarModelReturnType } from '../internal';
import { toDateByFormat, toMoment } from '@services/helpers';
import { Moment } from 'moment';

export class ScheduleChangeTimeFormDTO {
  public uuid: string;
  public scheduled_worker_uuid: EmployeeModel | string;
  public scheduled_date: Moment | string;
  public date: Moment | string;
  public scheduled_send_notification: boolean;
  public scheduled_comment: string;

  constructor(
    props?: IScheduleCalendarModelReturnType,
    send_sms_notification?: boolean,
  ) {
    this.uuid = props?.uuid || '';
    this.scheduled_worker_uuid = props?.employee
      ? (props?.employee as EmployeeModel)
      : '';
    this.scheduled_date = toMoment(
      toDateByFormat(props?.scheduled_date as string, 'YYYY-MM-DD HH:mm'),
    );
    this.scheduled_send_notification = send_sms_notification || false;
    this.scheduled_comment = '';
    this.date = '';
  }
}
