import {
  SubscriptionListDTO,
  ISubscriptionDTOProps,
  SubscriptionDTO,
  SubscriptionListModel,
} from '../internal';

export class SubscriptionListMapper {
  public static toSubscriptionListModel(
    subscriptionListDTO: SubscriptionDTO[],
    total: number,
  ): SubscriptionListModel {
    return new SubscriptionListModel({
      subscriptions: subscriptionListDTO,
      total,
    });
  }

  public static toSubscriptionListDTO(
    subscriptions: ISubscriptionDTOProps[],
  ): ISubscriptionDTOProps[] {
    return new SubscriptionListDTO({ subscriptions }).subscriptions;
  }
}
