import * as React from 'react';
import { List } from 'immutable';
import {
  useDepartmentList,
  IUseDepartmentListProps,
  IUseDepartmentListReturnType,
} from './useDepartmentList';
import { DepartmentModel } from '@structure';
import { deleteDepartment } from '@services/api/department';
import {
  setDepartmentList as storeSetDepartmentList,
  deleteDepartmentFromList as storeDeleteDepartment,
  loadMoreDepartmentList as storeLoadMoreDepartmentList,
} from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';

export interface IUseStateDepartmentListProps extends IUseDepartmentListProps {}

export interface IDepartmentSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateDepartmentListReturnType
  extends Omit<IUseDepartmentListReturnType, 'entityList'> {
  departmentList: List<DepartmentModel> | null;
  handleDeleteDepartments: (ids: string[]) => Promise<void>;
  handleSearchDepartments: (value: IDepartmentSearchProps) => Promise<void>;
  handleLoadMoreDepartments: () => Promise<void>;
  handleRefreshDepartments: (
    value: Partial<IUseDepartmentListProps> & { page: number },
  ) => Promise<void>;
  loadingMore: boolean;
  page: number;
}

export function useStoredDepartmentList(
  {
    companyUuid,
    loadOnInit,
    ...rest
  }: IUseStateDepartmentListProps = {} as IUseStateDepartmentListProps,
): IUseStateDepartmentListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedDepartmentListStatus,
    loading: storedDepartmentListLoading,
    departmentList,
    ...storedDepartmentListParams
  } = useSelector(({ departmentList }: RootState) => departmentList);

  const storeTotal = departmentList?.total || 0;
  const storeKeywords = departmentList?.keywords || '';
  const storedPage = departmentList?.page || 1;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: departmentListLoading,
    ...departmentsParams
  } = useDepartmentList({
    companyUuid,
    loadOnInit:
      loadOnInit && storedDepartmentListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.departments) &&
      storedDepartmentListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetDepartmentList(entityList));
    }
  }, [dispatch, entityList, storedDepartmentListStatus]);

  const handleDeleteDepartments = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteDepartment(ids);

        setTimeout(() => {
          dispatch(storeDeleteDepartment(head(ids)));
        }, 100);
        alert('success', t('Department'), t('Department delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Department'),
          `${t('An error occurred during delete department')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchDepartments = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading = true,
    }: IDepartmentSearchProps) => {
      setLoadingMore(true);
      const departmentListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (
        departmentListModel &&
        List.isList(departmentListModel?.departments)
      ) {
        const updatedDepartmentList = departmentListModel.update(
          'keywords',
          () => keywords,
        );

        dispatch(storeSetDepartmentList(updatedDepartmentList));
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreDepartments = useCallback(async () => {
    if (
      List.isList(departmentList) &&
      departmentList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const departmentListModel = await refresh({
        offset: departmentList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        departmentListModel &&
        List.isList(departmentListModel?.departments)
      ) {
        dispatch(storeLoadMoreDepartmentList(departmentListModel));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, departmentList, storeTotal]);

  const handleRefreshDepartments = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseDepartmentListProps> & { page: number }) => {
      const departmentListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (
        departmentListModel &&
        List.isList(departmentListModel?.departments)
      ) {
        const updatedDepartmentList = departmentListModel.update(
          'page',
          () => page,
        );

        dispatch(storeSetDepartmentList(updatedDepartmentList));
      }
    },
    [dispatch, refresh],
  );

  return {
    ...departmentsParams,
    ...storedDepartmentListParams,
    departmentList: departmentList?.departments || null,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(departmentList?.departments) && departmentListLoading) ||
      !storedDepartmentListLoading,
    handleDeleteDepartments,
    handleSearchDepartments,
    handleLoadMoreDepartments,
    handleRefreshDepartments,
    keywords: storeKeywords,
    page: storedPage,
  };
}
