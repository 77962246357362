import { AUTH_DATA } from '../constants';
import { REDUX_STATUS } from '@services/types';
import { AuthDataDTO } from '@structure';

interface IAuthDataState {
  authData: AuthDataDTO | null;
  access_token: string | null;
  refresh_token: string | null;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IAuthDataState, 'authData'> {
  type: AUTH_DATA.SET_AUTH_DATA;
}

interface SetTokensAction
  extends Pick<IAuthDataState, 'access_token' | 'refresh_token'> {
  type: AUTH_DATA.SET_AUTH_DATA;
}

interface ResetAction {
  type: AUTH_DATA.RESET_AUTH_DATA;
}

interface Handlers {
  [AUTH_DATA.SET_AUTH_DATA]: (
    state: IAuthDataState,
    action: SetAction,
  ) => IAuthDataState;

  [AUTH_DATA.SET_AUTH_TOKENS]: (
    state: IAuthDataState,
    action: SetTokensAction,
  ) => IAuthDataState;

  [AUTH_DATA.UPDATE_AUTH_DATA]: (
    state: IAuthDataState,
    action: SetAction,
  ) => IAuthDataState;

  [AUTH_DATA.RESET_AUTH_DATA]: (
    state: IAuthDataState,
    action: ResetAction,
  ) => IAuthDataState;

  DEFAULT: (state: IAuthDataState) => IAuthDataState;
}

const initState: IAuthDataState = {
  authData: null,
  access_token: null,
  refresh_token: null,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [AUTH_DATA.SET_AUTH_DATA]: (state, { authData }) => ({
    ...state,
    ...{
      authData: state?.authData
        ? { ...state?.authData, ...authData }
        : authData,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [AUTH_DATA.SET_AUTH_TOKENS]: (state, { access_token, refresh_token }) => ({
    ...state,
    ...{
      access_token,
      refresh_token,
    },
  }),

  [AUTH_DATA.UPDATE_AUTH_DATA]: (state, { authData }) => ({
    ...state,
    ...{
      authData:
        state?.authData && authData
          ? { ...state?.authData, ...authData }
          : authData,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [AUTH_DATA.RESET_AUTH_DATA]: (state) => ({
    ...state,
    ...{
      ...initState,
      status: REDUX_STATUS.IDLE,
    },
  }),

  DEFAULT: (state: IAuthDataState) => state,
};

export default function Client(
  state: any = initState,
  action: any,
): IAuthDataState {
  const handler = handlers[action.type as AUTH_DATA] || handlers.DEFAULT;
  return handler(state, action);
}
