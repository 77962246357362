import { Record } from 'immutable';
import { AclDTO } from '../internal';

export interface IAclModelReturnType {
  read: boolean;
  manage: boolean;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class AclModel extends Record<IAclModelReturnType>({
  read: true,
  manage: true,
}) {
  constructor({ ...props }: AclDTO = {} as AclDTO) {
    const options: IAclModelReturnType = {
      ...props,
    };

    super(options);
  }
}
