import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { ExpenseFormDTO } from '@structure';
import { useModal } from '@components/lib/libV2/hooks';
import { EditButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const ExpenseSideWindowLazy = React.lazy(
  () => import('../Show/ExpenseSideWindow'),
);

export interface IExpenseUpdateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: ExpenseFormDTO) => Promise<void>;
  onCancel?: () => void;
  expense: ExpenseFormDTO;
}

export function ExpenseUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  expense,
  ...rest
}: IExpenseUpdateButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit services or goods')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ExpenseSideWindowLazy
          editMode
          expense={expense}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
