import React, { useCallback, useRef, useState } from 'react';
import { Empty } from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '@components/lib/libV2/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import {
  InvoiceItemModel,
  InvoiceModel,
  InvoiceMapper,
  InvoiceItemDTO,
  InvoiceStatus,
} from '@structure';
import { useTranslation } from 'react-i18next';
import {
  EditableCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import { useDropdownAlert } from '@contex';
import { correctPrice, listToArray } from '@services/helpers';
import styled, { useTheme, css } from 'styled-components';
import { DeleteButton, TableIndexField } from '@components/lib/DataDisplay';
import { TextFormat } from '@components/lib/Format';
import { DefaultForm } from '@components/lib/General';
import { useInvoiceManager } from '../Managers';
import { ENTER_KEYS } from '@services/const';
import { InvoiceItemUpdateButton } from '../Buttons';
import { InvoiceItemDetailsView } from './InvoiceItemDetailsView';
import { List } from 'immutable';
import { LoadingOutlined } from '@ant-design/icons';

import '../../Orders/List/OrderList.less';
import { produce } from 'immer';

export interface InvoiceItemListTableProps {
  handleSearchSelectFocus: () => void;
  handleSearchSelectDaleyFocus: (ms?: number) => void;
  loading?: boolean;
  invoice: InvoiceModel;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<InvoiceItemModel>>;

const StyledNumberContainer = styled.div<{ $loadingItem?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${({ $loadingItem }) =>
    $loadingItem &&
    css`
      justify-content: space-between;
    `}
`;

const StyledTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export function InvoiceItemListTable({
  handleSearchSelectFocus,
  handleSearchSelectDaleyFocus,
  loading: defaultLoading,
  invoice,
}: InvoiceItemListTableProps): React.JSX.Element {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();
  const theme: any = useTheme();

  const timeoutRef = useRef<any>(null);

  const {
    invoiceItemList,
    invoiceItemsLoading,
    invoiceItemListTotal,
    invoiceItemListLimit,
    invoiceItemListPage,
    loadingSubmit,

    handleDeleteInvoiceItem,
    handleAddOrUpdateInvoiceItems,
    invoiceItemListRefresh,
  } = useInvoiceManager();

  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState<InvoiceItemModel | null>(null);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [loadingAmount, setLoadingAmount] = useState(false);

  const handleDeleteItem = useCallback(
    async (item: InvoiceItemModel) => {
      try {
        await handleDeleteInvoiceItem(item);
      } catch (error: any) {
        alert(
          'error',
          t('Items'),
          `${t('An error occurred during delete item')}: ${error?.message}`,
        );
        return;
      }
    },
    [alert, handleDeleteInvoiceItem, t],
  );

  const validateList =
    listToArray(invoiceItemList || [])?.map((_: any, index: number) => [
      index,
      'item_amount',
    ]) || [];

  const validateListPrice =
    listToArray(invoiceItemList || [])?.map((_: any, index: number) => [
      index,
      'item_price',
    ]) || [];

  const defaultColumns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: InvoiceItemModel, items: any, index: number) => (
        <TableIndexField
          number={(invoiceItemListPage - 1) * invoiceItemListLimit + index + 1}
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      className: 'order-list-td sale-list-td--full',
      render: (item: InvoiceItemModel) => (
        <InvoiceItemDetailsView item={item} withTour price={item?.item_price} />
      ),
    },
    {
      title: t('tAmount'),
      key: 'item_amount',
      editable: (item: InvoiceItemModel) => {
        return (
          !(currentItem?.uuid === item?.uuid) &&
          invoice?.inv_status === InvoiceStatus.DRAFT
        );
      },
      className: 'order-list-td sale-list-td',
      validateList: [...validateList, 'item_amount'],
      autoFocus: false,
      recordUuid: currentItem?.uuid,
      rules: [
        () => ({
          validator(_: any, price: string) {
            const value = price;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('Amount must be greater than 1')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('Amount must be a number')));
            }

            return Promise.resolve();
          },
        }),
      ],
      loading: loadingAmount,
      align: 'right' as any,
      render: (item: InvoiceItemModel) => {
        return <StyledTitle>{item?.item_amount}</StyledTitle>;
      },
    },
    {
      title: t('Price'),
      key: 'item_price',
      className: 'order-list-td sale-list-td',
      align: 'right' as any,
      validateList: [...validateListPrice, 'item_price'],
      recordUuid: currentItem?.uuid,
      editable: (item: InvoiceItemModel) => {
        return (
          !(currentItem?.uuid === item?.uuid) &&
          invoice?.inv_status === InvoiceStatus.DRAFT
        );
      },
      autoFocus: false,
      rules: (cellIndex?: any) => [
        () => ({
          validator(_: any, amount: any) {
            const value = amount;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('The price must be greater than 0')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('The price must be a number')));
            }

            return Promise.resolve();
          },
        }),
      ],
      render: (item: InvoiceItemModel) => {
        const loadingItem = item.uuid === currentItem?.uuid && loadingPrice;

        return (
          <StyledNumberContainer $loadingItem={loadingItem}>
            {loadingItem ? <LoadingOutlined /> : null}
            <StyledTitle
              $color={
                Number(item?.item_price || 0) <= 0
                  ? theme.colors.error
                  : undefined
              }>
              {`${correctPrice(item?.item_price || 0)}`}
            </StyledTitle>
          </StyledNumberContainer>
        );
      },

      fields: (item: InvoiceItemModel) => {
        return (
          <StyledNumberContainer $loadingItem={false}>
            {!(item?.item_currency === '₴' || item?.item_currency === '') ? (
              <StyledDescription>{`${item?.item_currency}`}</StyledDescription>
            ) : null}
          </StyledNumberContainer>
        );
      },
    },
    {
      title: t('Total'),
      key: 'total',
      className: 'order-list-td sale-list-td',
      align: 'right' as any,
      render: (item: InvoiceItemModel) => (
        <StyledNumberContainer>
          <StyledTotalContainer>
            <StyledTitle
              $color={
                Number(item?.item_price || 0) <= 0
                  ? theme.colors.error
                  : undefined
              }>
              {correctPrice(
                Number(item?.item_price || 0) * (item?.item_amount || 1),
              )}
            </StyledTitle>
            {!(item?.item_currency === '₴' || item?.item_currency === '') ? (
              <StyledDescription>{`${item?.item_currency}`}</StyledDescription>
            ) : null}
          </StyledTotalContainer>
        </StyledNumberContainer>
      ),
    },
    ...(invoice?.inv_status === InvoiceStatus.DRAFT
      ? [
          {
            ellipsis: true,
            title: t('Actions'),
            key: 'actions',
            align: 'center' as any,
            fixed: 'right' as any,
            className: 'order-list-td sale-list-td',
            render: (item: InvoiceItemModel) => {
              return (
                <TableActionCell>
                  <InvoiceItemUpdateButton
                    invoice={invoice}
                    item={InvoiceMapper.toInvoiceItemFormDTOFactory(item, {
                      editMode: true,
                    })}
                    onSuccess={handleAddOrUpdateInvoiceItems}
                  />
                  <DeleteButton
                    disabled={
                      // disabledProductUuid === item?.product_uuid ||
                      loadingSubmit
                    }
                    tooltipTitle={t('Delete material')}
                    confirmTitle={
                      <TextFormat breakWord>
                        {t('Are you sure you want to delete')} {t('material')}?
                      </TextFormat>
                    }
                    onConfirm={async () => {
                      await handleDeleteItem(item);
                      handleSearchSelectFocus();
                    }}
                    cancelButtonText={t('Cancel')}
                  />
                </TableActionCell>
              );
            },
          },
        ]
      : []),
  ];

  const handleSave = useCallback(
    async (row: InvoiceItemDTO) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (List.isList(invoiceItemList)) {
        const index = invoiceItemList?.findIndex(
          (item) => row.uuid === item.uuid,
        );

        const item = invoiceItemList.get(index);
        const itemModel = InvoiceMapper.toInvoiceItemModel(row);

        setCurrentItem(itemModel);

        setLoadingPrice(item?.item_price !== itemModel?.item_price);
        setLoadingAmount(item?.item_amount !== itemModel?.item_amount);

        if (
          item?.item_amount !== itemModel?.item_amount ||
          item?.item_price !== itemModel?.item_price
        ) {
          try {
            setLoading(true);

            const invoiceForm = produce(
              InvoiceMapper.toInvoiceItemFormDTOFactory(itemModel, {
                withoutPartialFields: true,
              }),
              (draft) => {
                draft.item_base_price = '';
                draft.item_currency = '';
              },
            );

            await handleAddOrUpdateInvoiceItems(invoiceForm, true);
          } catch (error: any) {
            alert(
              'error',
              t('Item'),
              `${t('An error occurred during edit item')}: ${error?.message}`,
            );
            return;
          } finally {
            setLoading(false);
          }
        }

        setCurrentItem(null);
        setLoadingPrice(false);
        setLoadingAmount(false);

        timeoutRef.current = setTimeout(() => {
          handleSearchSelectDaleyFocus();
        }, 20);
      }
    },
    [
      alert,
      handleAddOrUpdateInvoiceItems,
      handleSearchSelectDaleyFocus,
      invoiceItemList,
      t,
    ],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: InvoiceItemModel) => {
          return {
            record,
            dataIndex: col.key,
          };
        },
      } as any;
    }

    return {
      ...col,
      onCell: (record: InvoiceItemModel) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          alignText: col?.align,
          className: col?.className,
          validateList: col?.validateList,
          autoFocus: col?.autoFocus,
          loading: col?.loading,
          recordUuid: col?.recordUuid,
          fields: col?.fields,
        };
      },
    };
  });

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={[...ENTER_KEYS]}
      withContext
      isResetLoading
      onCancel={() => {}}
      initialValues={{}}
      onSuccess={() => {}}
      showFooter={false}
      showNotify={false}
      showCancelButton={false}>
      <StyledTable
        loading={defaultLoading || invoiceItemsLoading}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={invoiceItemList}
        columns={columns}
        total={invoiceItemListTotal || invoiceItemList?.size || 0}
        pageSize={invoiceItemListLimit || Infinity}
        onChange={invoiceItemListRefresh}
        renderEmpty={
          <Empty
            description={t('It looks like you have not selected any products')}
          />
        }
      />
    </DefaultForm>
  );
}
