import { Record, List } from 'immutable';
import { ListModel, ListProps } from '../helpers';
import UserOperation, { IUserOperationProps } from './UserOperation';

export interface UserOperationListProps extends ListProps {
  userAccountOperations: IUserOperationProps[];
}

export default class UserOperationList extends Record({
  userAccountOperations: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{users:[]}} param0
   */
  constructor({
    userAccountOperations = [],
    ...props
  }: UserOperationListProps) {
    const options: any = {};
    if (Array.isArray(userAccountOperations)) {
      options.userAccountOperations = List(
        userAccountOperations.map((user) => new UserOperation(user)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
