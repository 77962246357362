import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  IProductsListStatsProps,
  MAPPED_PRODUCT_TYPES,
  ProductListModel,
  ProductMappedType,
  ProductMapper,
  ProductModel,
  ProductType,
} from '@structure';
import { ProductType as ProductStatsType, REDUX_STATUS } from '@services/types';
import { RootState } from '../reducers';
import { List, Map } from 'immutable';
import { eq, neq } from '@services/helpers';

export interface ProductListState {
  cachedProductMap: Map<
    ProductType | ProductMappedType,
    Map<string, ProductListModel | null>
  >;
  loading: boolean;
  status: REDUX_STATUS;
  stats: IProductsListStatsProps;
}

export interface SetProductListAction {
  productList: ProductListModel;
  categoryUuid: string;
  productType: ProductType | ProductMappedType;
  keywords: string;
  page?: number;
}

export interface LoadingMoreProductListAction {
  productList: ProductListModel;
  productType: ProductType | ProductMappedType;
  categoryUuid: string;
}

export interface ResetProductCategoryCacheAction {
  productTypes: (ProductType | ProductMappedType)[];
}

export interface SetProductListStatsAction {
  stats: IProductsListStatsProps;
}

export interface AddProductAction {
  product: ProductModel;
  stats: IProductsListStatsProps;
}

export interface UpdateProductAction {
  product: ProductModel;
}

export interface DeleteProductAction {
  productUuid: string;
  stats: IProductsListStatsProps;
}

const initialState: ProductListState = {
  cachedProductMap: Map(),
  loading: true,
  status: REDUX_STATUS.IDLE,
  stats: {
    [ProductStatsType.Product]: '0',
    [ProductStatsType.Category]: '0',
    product_price_tags_count: 0,
  },
};

export const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setProductList: (
      state,
      { payload }: PayloadAction<SetProductListAction>,
    ) => {
      const { productList, page, keywords, productType, categoryUuid } =
        payload;
      const { cachedProductMap, stats } = state;

      if (productList) {
        const updatedProductList: any = productList
          .update('keywords', () => keywords)
          .update('page', (initPage) => page || initPage);

        const productTypeMap = cachedProductMap.get(productType) || Map();

        return {
          ...state,
          cachedProductMap: cachedProductMap.set(
            productType,
            productTypeMap.set(categoryUuid, updatedProductList) as any,
          ),
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
          stats: productList?.stats || stats,
        };
      }
    },

    setProductListStats: (
      state,
      { payload }: PayloadAction<SetProductListStatsAction>,
    ) => {
      const { stats } = payload;

      const { stats: stateStats } = state;

      return {
        ...state,
        stats: stats || stateStats,
      };
    },

    setInitialState: () => {
      return initialState;
    },

    loadMoreProductList: (
      state: any,
      { payload }: PayloadAction<LoadingMoreProductListAction>,
    ) => {
      const { productList, categoryUuid, productType } = payload;

      const { cachedProductMap }: ProductListState = state;

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductMap: cachedProductMap.update(
          productType,
          (productTypeMap) =>
            productTypeMap?.update(categoryUuid, (list) => {
              if (
                list &&
                List.isList(list?.products) &&
                List.isList(productList?.products)
              ) {
                return list.update('products', (prevProductCategories) =>
                  prevProductCategories.merge(productList.products),
                );
              }

              return list;
            }),
        ),
      };
    },

    resetProductListCache: (
      state: any,
      { payload }: PayloadAction<ResetProductCategoryCacheAction>,
    ) => {
      const { productTypes } = payload;
      let { cachedProductMap }: ProductListState = state;

      productTypes.forEach((productType: ProductMappedType | ProductType) => {
        cachedProductMap = cachedProductMap?.remove(productType);
      });

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductMap,
      };
    },

    addProductToList: (
      state: any,
      { payload }: PayloadAction<AddProductAction>,
    ) => {
      const { product, stats } = payload;

      let { cachedProductMap, stats: stateStats }: ProductListState = state;

      MAPPED_PRODUCT_TYPES.forEach(
        (productType: ProductMappedType | ProductType) => {
          cachedProductMap = cachedProductMap?.update(
            productType,
            (productTypeMap) =>
              productTypeMap?.map((list, key) => {
                if (key === '') {
                  if (list && List.isList(list?.products)) {
                    return list
                      .update('products', (products) =>
                        products.unshift(product),
                      )
                      .update('total', (total = 0) => total + 1);
                  }

                  return ProductMapper.toProductListModel([product as any], 1);
                } else {
                  return null;
                }
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductMap,
        stats: stats || stateStats,
      };
    },

    updateProductFromList: (
      state: any,
      { payload }: PayloadAction<UpdateProductAction>,
    ) => {
      const { product } = payload;

      let { cachedProductMap }: ProductListState = state;

      MAPPED_PRODUCT_TYPES.forEach(
        (productType: ProductMappedType | ProductType) => {
          cachedProductMap = cachedProductMap?.update(
            productType,
            (productTypeMap) =>
              productTypeMap?.map((list) => {
                if (list && List.isList(list?.products)) {
                  return list.update('products', (products) =>
                    products?.map((prevProduct) => {
                      if (eq(prevProduct?.uuid, product?.uuid)) {
                        return prevProduct?.merge(product);
                      }
                      return prevProduct;
                    }),
                  );
                }

                return list;
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductMap,
      };
    },

    deleteProductFromList: (
      state: any,
      { payload }: PayloadAction<DeleteProductAction>,
    ) => {
      const { productUuid, stats } = payload;

      let { cachedProductMap, stats: stateStats }: ProductListState = state;

      let isDelete = false;

      MAPPED_PRODUCT_TYPES.forEach(
        (productType: ProductMappedType | ProductType) => {
          cachedProductMap = cachedProductMap?.update(
            productType,
            (productTypeMap) =>
              productTypeMap?.map((list) => {
                if (list && List.isList(list?.products)) {
                  return list
                    .update('products', (products) =>
                      products.filter(({ uuid }) => {
                        if (neq(productUuid, uuid)) {
                          return true;
                        }

                        isDelete = true;

                        return false;
                      }),
                    )
                    .update('total', (total = 0) =>
                      total > 0 && isDelete ? total - 1 : total,
                    );
                }

                return list;
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductMap,
        stats: stats || stateStats,
      };
    },
  },
});

export const {
  setProductList,
  setInitialState,
  loadMoreProductList,
  addProductToList,
  updateProductFromList,
  deleteProductFromList,
  setProductListStats,
  resetProductListCache,
} = productListSlice.actions;

export const selectProductList = ({ productListReducer }: RootState) =>
  productListReducer;

export default productListSlice.reducer;
