import React from 'react';
import { ServiceMultiSessionable } from '@structure';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';

export interface IServiceMultiSessionableViewProps {
  multiSessions: ServiceMultiSessionable[];
}

const StyledTable = styled(Table)`
  margin-bottom: 0;
  width: 95%;
` as React.ComponentType as React.FunctionComponent<
  ITableProps<ServiceMultiSessionable>
>;

export function ServiceMultiSessionableView({
  multiSessions,
}: IServiceMultiSessionableViewProps): React.JSX.Element {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('Session schedule'),
      key: 'session',
      render: (session: ServiceMultiSessionable) => (
        <StyledTitle>{`${session?.amount} ${t(
          session?.period,
        ).toLowerCase()}`}</StyledTitle>
      ),
    },
    {
      title: t('Note'),
      key: 'note',
      alight: 'right' as any,
      render: (session: ServiceMultiSessionable) => session?.comment,
    },
  ];

  return (
    <StyledTable
      total={Infinity}
      pageSize={Infinity}
      dataSource={multiSessions}
      columns={columns}
      loading={false}
      page={1}
      pagination={false}
    />
  );
}
