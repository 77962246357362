import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { ServiceModel } from '@structure';
import React, { Suspense, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { TourProps } from '@components/lib/DataDisplay';
import { SuspenseEmpty, Button, Link } from '@components/lib/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';

const TourLazy = React.lazy(() => import('@components/lib/DataDisplay/Tour'));

export interface ServiceTourViewProps extends Omit<TourProps, 'children'> {
  service: ServiceModel | null;
  price?: string;
}

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: auto;
`;

const Title = styled(StyledTitle)`
  max-width: 90%;
`;

const StyledCloseButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
`;

const StyledDescriptionScroll = styled(StyledDescription)`
  position: relative;
  white-space: unset;

  overflow: auto;
  max-height: 180px;
  height: auto;
`;

export const ServiceTourView = forwardRef(function ServiceTourView(
  { visible, handleCancel, service, price }: ServiceTourViewProps,
  ref: any,
): React.JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme: any = useTheme();

  return (
    <Suspense fallback={<SuspenseEmpty />}>
      <TourLazy visible={visible} handleCancel={handleCancel} ref={ref}>
        <StyledContent>
          <Title fontWeight={500} fontSize={20}>
            {service?.title}
          </Title>
          <Link
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              navigate(`/${Routes.app}/${Routes.services}/${service?.uuid}`, {
                state: { goBack: location.pathname },
              });
            }}
            to={`/${Routes.app}/${Routes.services}/${service?.uuid}`}
            tooltip={t('Go to service details page')}
            state={{ goBack: location.pathname }}>
            {t('Go to the service page')} <ArrowRightOutlined />
          </Link>

          {price ? (
            <Title $color={theme.colors.success} fontWeight={600} fontSize={25}>
              {price}
            </Title>
          ) : null}

          {service?.description ? (
            <>
              <Title fontWeight={500}>{`${t('Characteristics')}:`}</Title>
              <StyledDescriptionScroll fontWeight={400}>
                {service?.description}
              </StyledDescriptionScroll>
            </>
          ) : null}
          <StyledCloseButton onClick={handleCancel} type="text">
            {t('Close')}
          </StyledCloseButton>
        </StyledContent>
      </TourLazy>
    </Suspense>
  );
});
