import React from 'react';
import { Routes, Route } from 'react-router';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';
import {
  ClientAbonementListPage,
  ClientAbonementPage,
  CompanyClientAbonementCalendarPage,
} from '../views/Clients';
import { CalendarRoutes } from './CalendarRoutes';

export function CompanyClientAbonementRoutesNavigator() {
  return (
    <Routes>
      <Route
        path={`/${RoutesType.companies}/:companyId`}
        element={
          <AppRouteLayout>
            <ClientAbonementListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:abonementId/${RoutesType.clients}/:clientId/${RoutesType.companies}/:companyId`}
        element={
          <AppRouteLayout>
            <ClientAbonementPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:abonementId/${RoutesType.clients}/:clientId/${RoutesType.companies}/:companyId/${RoutesType.schedules}/*`}
        element={
          <CalendarRoutes
            CalendarComponent={CompanyClientAbonementCalendarPage}
          />
        }
      />
    </Routes>
  );
}
