import React from 'react';
import {
  StatusCategoryModel,
  StatusCategoryDTO,
  StatusCategoryInternalCode,
} from '@structure';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HistoryOutlined,
  ReloadOutlined,
  CarOutlined,
  ContainerOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';
import styled, { css, useTheme } from 'styled-components';
import Color from 'color';

export interface IStatusCategoryTagProps {
  category: StatusCategoryModel | StatusCategoryDTO | null;
  title: string;
  icon?: React.ReactNode;
}

const StyledTag = styled(Tag)<{ $color: string }>`
  max-width: 350px;
  white-space: normal;

  ${({ $color }) =>
    $color &&
    css`
      border-color: ${Color($color).alpha(0.5).darken(0.3) as any};
      background-color: ${Color($color).alpha(0.9) as any};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export const STATUS_CATEGORY_ICONS = {
  [String(StatusCategoryInternalCode.New)]: <ContainerOutlined />,
  [String(StatusCategoryInternalCode.Completed)]: <CheckCircleOutlined />,
  [String(StatusCategoryInternalCode.Canceled)]: <CloseCircleOutlined />,
  [String(StatusCategoryInternalCode.Deferred)]: <HistoryOutlined />,
  [String(StatusCategoryInternalCode.InProgress)]: <ReloadOutlined />,
  [String(StatusCategoryInternalCode.Delivery)]: <CarOutlined />,
  [String(StatusCategoryInternalCode.Closed)]: <CloseCircleOutlined />,
};

export function StatusCategoryTag({
  category,
  title,
  icon,
  ...rest
}: IStatusCategoryTagProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTag
      {...rest}
      $color={category?.colour || theme.colors.primary}
      icon={
        STATUS_CATEGORY_ICONS[String(category?.internal_code)] || (
          <FileUnknownOutlined />
        )
      }>
      {title}
      {icon || null}
    </StyledTag>
  );
}
