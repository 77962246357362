import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import React, { JSX, useCallback, useMemo, useState } from 'react';
import { DefaultForm, IDefaultFormProps } from '@components/lib/General';
import { EMPLOYEE_SALARY_AND_BONUSES_DATA } from '@services/api/employee';
import { EmployeeSalaryAndBonusesFormDTO } from 'structure/dto/EmployeeSalaryAndBonusesFormDTO';

const StyledSalaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  height: 100%;
`;

export interface IEmployeeSalaryAndBonusesFormProps
  extends Pick<
    IDefaultFormProps<any, any>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onError?: () => Promise<void>;
  employee?: EmployeeSalaryAndBonusesFormDTO;
  onSuccess: (value: EmployeeSalaryAndBonusesFormDTO) => Promise<void>;
}

/**
 * @desc керування даними по заробітній платні співробітника
 * */
export default function EmployeeSalaryAndBonusesForm({
  loading,
  editMode,
  employee = EMPLOYEE_SALARY_AND_BONUSES_DATA,
  ...rest
}: IEmployeeSalaryAndBonusesFormProps): JSX.Element {
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();
  const [isProcessable, setIsProcessable] = useState(false);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Employee salary and bonuses'),
        `${t(
          'An error occurred during configuring the salary and bonuses',
        )} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: employee?.uuid,
    }),
    [employee?.uuid],
  );

  return (
    <DefaultForm
      initialValues={{
        ...employee,
      }}
      notifyError={notifyError}
      showNotify={false}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      disabledSubmitButton={!isProcessable}
      submitButtonText={t('Save settings')}
      {...rest}>
      <StyledSalaryContainer>Hello</StyledSalaryContainer>
    </DefaultForm>
  );
}
