import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import {
  FlexContainer,
  StyledFacebookOutlined,
  StyledTwitterOutlined,
} from '@components/lib/Styled';
import { footerAppName, facebookUrl, twitterUrl } from '@services/const';
import { TextFormat } from '@components/lib/Format';
import { useTranslation } from 'react-i18next';
import { AppInfoTabPanel } from '../Show';
import { FullWidthSpace, HeaderText } from '../../Authorization';
import { AuthLayout, AuthRouteLayout } from '../../Authorization/Show';
import packagejson from '../../../../package.json';

const StyledTextFormat = styled(TextFormat)`
  font-size: 1.2em;
  text-align: center;
`;

export default function AppInfoPage(): JSX.Element {
  const { t } = useTranslation();

  const handleNavigateToFacebook = useCallback(() => {
    window.open(facebookUrl, '_blank');
  }, []);

  const handleNavigateToTwitter = useCallback(() => {
    window.open(twitterUrl, '_blank');
  }, []);

  return (
    <AuthLayout>
      <AppInfoTabPanel>
        {({ routes }) => (
          <AuthRouteLayout
            header={
              <FullWidthSpace direction="vertical" size="small">
                <HeaderText title={t('About')} />
                <StyledTextFormat primary>
                  {t(
                    'Service which aims to organize the provided services and keep all clients informed about booked services.',
                  )}
                </StyledTextFormat>
                <StyledTextFormat primary>
                  {t(
                    'We would love to hear from our users and hope you will be our friend on the internet.',
                  )}
                </StyledTextFormat>
                <StyledTextFormat primary>
                  {`${footerAppName} ${t('App')} 2022.12(${
                    packagejson?.version
                  }) ${t(
                    'was created with love to simplify the service business all across Planet Earth.',
                  )}`}
                </StyledTextFormat>
                <FlexContainer justifyContent="center" alignItems="center">
                  <Space size="middle">
                    <StyledFacebookOutlined
                      onClick={handleNavigateToFacebook}
                    />
                    <StyledTwitterOutlined onClick={handleNavigateToTwitter} />
                  </Space>
                </FlexContainer>
              </FullWidthSpace>
            }
            routes={routes}
          />
        )}
      </AppInfoTabPanel>
    </AuthLayout>
  );
}
