import React from 'react';
import { ClientModel, PaymentModel, StoreDocumentType } from '@structure';
import { FaUserAlt } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StyledDescription } from '@components/lib/Styled';
import { Routes } from '@services/types';
import { Link } from '@components/lib/DataDisplay';

export interface PaymentStoreDocumentCommentViewProps {
  payment: PaymentModel;
  documentType?: StoreDocumentType;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Description = styled(StyledDescription)`
  margin-top: 1px;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
`;

export function PaymentStoreDocumentCommentView({
  payment,
  documentType,
}: PaymentStoreDocumentCommentViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const isContainsPaymentAgentObject = payment?.payment_agent_object?.uuid;

  return (
    <StyledContainer>
      <FaUserAlt size={11} color={theme.colors.disabled} />
      {isContainsPaymentAgentObject ? (
        <Description $color={theme.colors.disabled}>
          {t(
            'The amount of the debt is added to the personal account of the Supplier',
          )}{' '}
          <StyledLink
            to={`/${Routes.app}/${Routes.clients}/${payment?.payment_agent_object?.uuid}`}
            tooltip={t('Open a detailed description of the provider')}
            state={{ goBack: location.pathname }}>
            {(payment?.payment_agent_object as ClientModel)?.fullNameClient}
          </StyledLink>
        </Description>
      ) : (
        <Description $color={theme.colors.disabled}>
          {t('Formed debt cash register posting.')}
        </Description>
      )}
    </StyledContainer>
  );
}
