import { isFunction } from './index';

type Listener = (...args: any[]) => void;

interface IEventEmitterReturnType {
  on: (event: string, listener: Listener) => void;
  off: (event: string, listener: Listener) => void;
  once: (event: string, callback: Listener) => void;
  emit: (event: string, ...args: any[]) => void;
  clear: (event: string) => void;
  destroy: () => void;
}

export const eventEmitter = (): IEventEmitterReturnType => {
  const events = new Map();

  function isValidEvent(event: string): Listener[] | boolean {
    return events.has(event) && events.get(event);
  }

  function on(event: string, listener: Listener): void {
    events.has(event) || events.set(event, []);

    return events.get(event).push(listener);
  }

  function off(event: string, listener: Listener): void {
    const callbackQueue = isValidEvent(event);

    Array.isArray(callbackQueue) &&
      callbackQueue.some((callback: Listener) => {
        return isFunction(callback) && callback === listener;
      }) &&
      events.set(
        event,
        callbackQueue.filter((callback: Listener) => {
          return isFunction(callback) && callback !== listener;
        }),
      );
  }

  function once(event: string, listener: Listener): void {
    const single = (...args: any[]) => {
      args.length ? listener(...args) : listener();
      return clear(event);
    };
    return on(event, single);
  }

  function emit(event: string, ...args: any[]): void {
    const callbackQueue = isValidEvent(event);
    Array.isArray(callbackQueue) &&
      callbackQueue.forEach((listener: Listener) => {
        listener(...args);
      });
  }

  function clear(event: string): void {
    const callbackQueue = isValidEvent(event);

    if (callbackQueue) {
      events.delete(event);
    }
  }

  function destroy(): void {
    return events.clear();
  }

  return Object.freeze({
    on,
    off,
    once,
    emit,
    clear,
    destroy,
  });
};

export const EventEmitter = eventEmitter();
