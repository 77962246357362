import { Record, List } from 'immutable';
import { IListProps } from './List';
import { RuleLimitationDTO, RuleLimitationModel } from '../internal';

export interface RuleLimitationListProps extends IListProps {
  rule_limitations: RuleLimitationDTO[];
}

export interface RuleLimitationListReturnType extends IListProps {
  rule_limitations: List<RuleLimitationModel>;
}

export class RuleLimitationListModel extends Record<RuleLimitationListReturnType>(
  {
    rule_limitations: List(),
    total: 0,
  },
) {
  constructor(
    {
      rule_limitations = [],
      total = 0,
    }: RuleLimitationListProps = {} as RuleLimitationListProps,
  ) {
    super({
      rule_limitations: List(
        rule_limitations.map((limit) => new RuleLimitationModel(limit)),
      ),
      total,
    });
  }
}
