import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import {
  DayOffDTO,
  DayOffMapper,
  IDayOffDTOProps,
  IDayOffListDTO,
} from '@structure';
import { ApiAnswer, IListProps } from '../types';
import { notEmptyValue, head } from '../helpers';

export const DAY_OFF_INITIAL_PARAM = new DayOffDTO();

export interface IEmployeeDayOffListProps extends IListProps {
  employeeUuid: string;
  keywords?: string;
}

export interface IEmployeeDayOffListAnswer extends ApiAnswer {
  days_off: IDayOffDTOProps[];
}

export interface IEmployeeDayOffAnswer {
  dayoff: IDayOffDTOProps;
}

export async function getEmployeeDayOffList({
  employeeUuid,
  offset = 0,
  limit = 20,
  keywords,
}: IEmployeeDayOffListProps): Promise<IDayOffListDTO> {
  const { days_off, total } = await apiGet<
    IListProps,
    IEmployeeDayOffListAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/days-off`, {
    offset,
    limit,
  });
  return DayOffMapper.toDayOffListDTO(days_off, total);
}

/**
 * @desc Створення вихідного дня для співробітника
 * */
export async function createEmployeeDayOff(
  value: DayOffDTO,
  employeeUuid: string,
): Promise<DayOffDTO> {
  const { start_date, end_date, comment } = value;

  Validate.string({ value: employeeUuid });

  const { dayoff } = await apiPost<
    Omit<DayOffDTO, 'uuid' | 'dateToString' | 'created_at'>,
    IEmployeeDayOffAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/days-off`, {
    start_date: value.dateToString(start_date),
    end_date: value.dateToString(end_date),
    comment,
  });

  return DayOffMapper.toDayOffDTO(dayoff);
}

/**
 * @desc Редагування вихідного дня для співробітника
 * */
export async function editEmployeeDayOff(value: DayOffDTO): Promise<DayOffDTO> {
  const { uuid, start_date, end_date, comment } = value;

  const data = notEmptyValue({
    start_date: value.dateToString(start_date),
    end_date: value.dateToString(end_date),
    comment,
  });

  Validate.string({ value: uuid });

  const { dayoff } = await apiPut<
    Omit<DayOffDTO, 'uuid' | 'dateToString'>,
    IEmployeeDayOffAnswer
  >(`${contentApiUrl}/days-off/${uuid}`, data);

  return DayOffMapper.toDayOffDTO(dayoff);
}

export interface DeleteDayOffApiAnswer extends ApiAnswer {
  deleted: boolean;
}

/**
 * @name deleteEmployeeDayOff
 * @desc Видалити вихідний день
 * @param {<String>} dayOffUuid
 * @throws {ApiError}
 */
export async function deleteEmployeeDayOff(
  dayOffUuid: string[],
): Promise<DeleteDayOffApiAnswer> {
  const uuid = head(dayOffUuid);

  return await apiDelete<any, DeleteDayOffApiAnswer>(
    `${contentApiUrl}/days-off/${uuid}`,
    null,
  );
}
