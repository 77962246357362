import React from 'react';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from '@components/lib/General';
import { StoreFormDTO } from '@structure';
import { AddButton } from '@components/lib/DataDisplay';

export interface ILeftoverAddProductButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess?: (value: StoreFormDTO) => Promise<void>;
  onCancel?: () => void;
  store?: StoreFormDTO;
}

export function LeftoverAddProductButton({
  children,
  onSuccess,
  onCancel,
  loading,
  store,
  ...rest
}: ILeftoverAddProductButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <AddButton
        tooltipTitle={t('Add to pre-order')}
        loading={loading}
        {...rest}
      />
    </>
  );
}
