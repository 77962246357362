import classNames from 'classnames';
import React, { Suspense } from 'react';
import { ClientModel } from '@structure';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import { SuspenseEmpty } from '@components/lib/DataDisplay';
import { ServersIcon } from '@components/lib/General/icons';
import { CLIENT_PAYMENT_INITIAL_PARAM } from '@services/api/client';
import { ActionButton, IActionButtonProps } from '@components/lib/General';

const ClientBalanceSideWindowLazy = React.lazy(
  () => import('../Show/ClientBalanceSideWindow'),
);

export interface IBalanceTopUpButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: any) => Promise<void>;
  client: ClientModel | null;
  toolTipTitle?: string;
  children?: any;
  className?: string;
  loading?: boolean;
  onCancel?: () => void;
}

export default function BalanceButton({
  children,
  onSuccess,
  client,
  onCancel,
  loading,
  toolTipTitle,
  className,
  disabled,
  ...rest
}: IBalanceTopUpButtonProps) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <ActionButton
        tooltip={toolTipTitle || t('Balance operations')}
        onClick={handleOnInit}
        disabled={disabled}
        type="default"
        size="middle"
        className={classNames('top-up-action-button', className)}
        {...rest}>
        {children || <ServersIcon />}
      </ActionButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientBalanceSideWindowLazy
          loading={loading}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          balance={
            {
              ...CLIENT_PAYMENT_INITIAL_PARAM,
              ...{
                fullName: client?.fullNameClient,
                validate_payment_sum: client?.saldo || 0,
              },
            } as any
          }
        />
      </Suspense>
    </>
  );
}
