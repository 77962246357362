import { Dispatch } from 'redux';
import { SERVICE } from '../constants';
import { ServiceModel } from '@structure';
import { ApiError } from '@services/types';

export interface ISetServiceParam {
  type: SERVICE;
  service: ServiceModel | null;
}

export interface IUpdateServiceParam {
  type: SERVICE;
  service: ServiceModel;
}

export interface IResetServiceParam {
  type: SERVICE;
}

export interface IErrorServiceParam {
  type: SERVICE;
  error: ApiError | undefined;
}

export function setService(
  service: ServiceModel | null,
): (dispatch: Dispatch) => Promise<ISetServiceParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE.SET_SERVICE,
      service,
    });
  };
}

export function updateService(
  service: ServiceModel,
): (dispatch: Dispatch) => Promise<IUpdateServiceParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE.UPDATE_SERVICE,
      service,
    });
  };
}

export function resetService(): (
  dispatch: Dispatch,
) => Promise<IResetServiceParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE.RESET_SERVICE,
    });
  };
}

export function errorService(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorServiceParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE.ERROR_SERVICE,
      error,
    });
  };
}
