import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import ConfirmActionButton from '@components/lib/General/ConfirmActionButton';
import { TextFormat } from '@components/lib/Format';

export interface ICancelOrderButtonProps extends ButtonProps {
  onSuccess: () => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

const StyledConfirmActionButton = styled(ConfirmActionButton)`
  background-color: ${({ theme }) => theme.colors.warning};
  flex: 1;
`;

export default function CancelOrderButton({
  loading = false,
  onSuccess,
  children,
  ...rest
}: ICancelOrderButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledConfirmActionButton
      danger
      tooltip={t('Cancel')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to cancel order?`)}
        </TextFormat>
      }
      confirmButtonText={t('Yes, cancel')}
      onConfirm={onSuccess}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}>
      {children || t('Cancel')}
    </StyledConfirmActionButton>
  );
}
