import React, { useEffect, useState } from 'react';
import {
  getMonthDayYear,
  find,
  IMonthFormat,
  getHourFromTime,
  isThereContent,
  eq,
  head,
  last,
  buildHourInDay,
  ITimeInDay,
} from '@services/helpers';
import { useCalendar, useCalendarDnd } from '@contex';
import CalendarWeekDayHeader from './CalendarWeekDayHeader';
import CalendarRow from './CalendarRow';
import CalendarWrapper from './CalendarWrapper';
import { useAppLayout } from '../../lib/Layout/AppLayout';

export interface ICalendarWrapperProps {
  calendarWrapperStyle?: any;
  loading?: boolean;
  disabled?: boolean;
}

export default function CalendarDay({
  calendarWrapperStyle,
  loading,
  disabled,
}: ICalendarWrapperProps): JSX.Element {
  const { drop } = useCalendarDnd();
  const { monthDates, hourInDay, selectedDate, withAddingEvents } =
    useCalendar();
  const [selectDay, setSelectDay] = useState<IMonthFormat>({} as IMonthFormat);
  const [localHourInDay, setLocalHourInDay] = useState<ITimeInDay[] | null>(
    null,
  );

  useEffect(() => {
    if (isThereContent(monthDates)) {
      const findDay = (month: IMonthFormat): boolean =>
        eq(getMonthDayYear(month?.date), getMonthDayYear(selectedDate));

      let result =
        find<[(month: IMonthFormat) => boolean, IMonthFormat[]], IMonthFormat>(
          findDay,
          monthDates,
        ) || {};

      if (isThereContent(result)) {
        setLocalHourInDay(hourInDay);

        if (isThereContent(result?.events)) {
          const eventsToTimeArray = Object.keys(result?.events);
          const firstTime = getHourFromTime(head(eventsToTimeArray));
          const lastTime = getHourFromTime(last(eventsToTimeArray));
          const fistHourInDay = getHourFromTime(head(hourInDay)?.time);
          const lastHourInDay = getHourFromTime(last(hourInDay)?.time);

          if (firstTime < fistHourInDay || lastTime > lastHourInDay) {
            const updatedHourInDay = buildHourInDay({
              start: firstTime < fistHourInDay ? firstTime : fistHourInDay,
              end: lastTime > lastHourInDay ? lastTime : lastHourInDay,
            });
            setLocalHourInDay(updatedHourInDay);

            result = {
              ...result,
              weekdayInfo: {
                ...result.weekdayInfo,
                ...{
                  start: `${
                    firstTime < fistHourInDay ? firstTime : fistHourInDay
                  }:00`,
                  end: `${
                    lastTime > lastHourInDay ? lastTime : lastHourInDay
                  }:00`,
                },
              },
            };
          }
        }

        setSelectDay(result);
      }
    }
  }, [selectedDate, monthDates, hourInDay]);

  return (
    <>
      <CalendarWrapper style={calendarWrapperStyle}>
        <CalendarWeekDayHeader dates={[selectDay]} />
        <div
          className="test-drop"
          ref={drop}
          // onMouseUp={(e) => {
          //   const target = e?.target;
          //
          //   if (target instanceof HTMLElement) {
          //     const ball = document.body.querySelector('.test-is-drag');
          //
          //     if (ball instanceof HTMLElement) {
          //       const dropEvent = new DragEvent('drop', {
          //         bubbles: true,
          //         cancelable: true,
          //       });
          //
          //       target.dispatchEvent(dropEvent);
          //     }
          //   }
          // }}
          // onMouseEnter={() => {
          //   const row = document.querySelector('.calendar-event');
          //
          //   if (row instanceof HTMLElement) {
          //     const clickEvent = new MouseEvent('click', {
          //       bubbles: true,
          //       cancelable: true,
          //       composed: true,
          //     });
          //
          //     row.dispatchEvent(clickEvent);
          //
          //     const ball = row.querySelector('.drag-event');
          //
          //     if (ball instanceof HTMLElement) {
          //       const mousedownEvent = new MouseEvent('mousedown', {
          //         bubbles: true,
          //         cancelable: true,
          //         composed: false,
          //       });
          //
          //       ball.dispatchEvent(mousedownEvent);
          //     }
          //   }
          // }}
          //
        >
          {isThereContent(selectDay) &&
            localHourInDay &&
            localHourInDay.map((value) => (
              <React.Fragment key={value?.time}>
                {getHourFromTime(value.time) >=
                  getHourFromTime(selectDay?.weekdayInfo?.start) &&
                getHourFromTime(value.time) <=
                  getHourFromTime(selectDay?.weekdayInfo?.end) ? (
                  <CalendarRow
                    disabled={disabled}
                    dates={[selectDay]}
                    hourInDay={value}
                    showTimeLine
                    withAddingEvents={withAddingEvents}
                    loading={loading}
                  />
                ) : null}
              </React.Fragment>
            ))}
        </div>
      </CalendarWrapper>
    </>
  );
}
