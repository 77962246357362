import { useAcl } from '@contex';
import { IoDuplicate } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { ClientDetailsView } from '../../Clients';
import { LoadingOutlined } from '@ant-design/icons';
import { HiOutlineDocument } from 'react-icons/hi2';
import styled, { useTheme } from 'styled-components';
import { Col, MenuProps, Popconfirm, Row } from 'antd';
import { Routes, RoutesAcl } from '@services/types';
import { textToUpperCase } from '@services/helpers';
import { OverviewLayout } from '@components/lib/Layout';
import { InvoiceManager, useInvoiceManager } from '../Managers';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DocumentControlPanel } from '@components/lib/General';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';
import { InvoiceMapper, InvoiceStatus, InvoiceType } from '@structure';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';

import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

import {
  Button,
  HeaderLeft,
  DetailsView,
  CommentField,
  SuspenseEmpty,
  IDetailsViewItemProps,
} from '@components/lib/DataDisplay';

import {
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredInvoice,
} from '@hooks';

import {
  InvoiceBottomView,
  InvoiceItemListSelect,
  InvoiceItemListTable,
  InvoicePageHeader,
  InvoiceTabPanel,
} from '../Show';

const InvoiceSideWindowLazy = React.lazy(
  () => import('../Show/InvoiceSideWindow'),
);

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledIoDuplicate = styled(IoDuplicate)`
  margin-right: 8px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledTableContainer = styled.div`
  background-color: ${({ theme }) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

function InvoiceContent() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { invoices: invoiceAccess } = useAcl((acl) => acl);

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({
    loading: false,
  });

  const {
    invoice,
    loading: invoiceLoading,
    error: invoiceError,
    handleUpdateInvoice,

    handlePrintInvoice,
    handlePrintInvoiceStoreRealization,
    handlePrintInputInvoice,
    handleUpdateInvoiceComment,
    handleUpdateInvoiceStatus,
    handleDuplicateInvoice: onDuplicateInvoice,

    updateInvoice,
  } = useStoredInvoice({
    invoiceUuid: invoiceId!,
    companyUuid: defaultCompanyUuid,
  });

  const isInvoice = useMemo(
    () => invoice?.inv_type === InvoiceType.IN,
    [invoice?.inv_type],
  );
  const isDraft = useMemo(
    () => invoice?.inv_status === InvoiceStatus.DRAFT,
    [invoice?.inv_status],
  );

  const { invoiceItemList, invoiceCallbacks } = useInvoiceManager();

  const handleDuplicateInvoice = useCallback(async () => {
    const { invoice } = (await onDuplicateInvoice(invoiceId!)) || {};

    if (invoice) {
      const navigateToNewInvoice = () =>
        new Promise((resolve) => {
          setTimeout(() => {
            navigate(`/${Routes.app}/${Routes.invoices}/${invoice?.uuid}`);
            resolve('');
          }, 300);
        });

      await navigateToNewInvoice();
    }
  }, [invoiceId, navigate, onDuplicateInvoice]);

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.invoices}`);
  }, [location?.state?.goBack, navigate]);

  const {
    handleCancel: invoiceEditHandleCancel,
    handleOnInit: storeDocumentEditOnInit,
    handleSuccess: invoiceDocumentEditSuccess,
    visible: invoiceDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateInvoice,
  });

  const loading = useStopLoading({
    loading: invoiceLoading,
    error: invoiceError,
    message: 'An error occurred during invoice posting loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.invoices}`,
      breadcrumbName: 'Bills',
    },
    {
      path: `/${Routes.invoices}/${invoiceId}`,
      breadcrumbName: invoice?.inv_internal_number || t('Show bill'),
    },
  ]);

  const isShowWarningDescription = !!invoiceItemList?.size;

  useEffect(() => {
    invoiceCallbacks.current.setInvoice = updateInvoice;
  }, [invoiceCallbacks, updateInvoice]);

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Resident',
      className: 'client-details__view',
    },
  ];

  // @ts-ignore
  const documentOptions: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];
    if (isInvoice) {
      items.push({
        key: 'duplicate',
        label: (
          <Popconfirm
            title={`${t('Create a new invoice based on the current invoice')}?`}
            okText={t('Yes')}
            cancelText={t('No')}
            onCancel={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            onConfirm={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
              setDuplicateLoading(true);
              await handleDuplicateInvoice();
              setDuplicateLoading(false);
            }}>
            <StyledPopconfirmButton
              type="text"
              onClick={async (e: any) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              }}>
              {duplicateLoading ? (
                <StyledLoadingOutlined />
              ) : (
                <StyledIoDuplicate size={15} />
              )}
              <StyledTitle>{t('Duplicate')}</StyledTitle>
            </StyledPopconfirmButton>
          </Popconfirm>
        ),
      });
    }
    return items;
  }, [isInvoice, duplicateLoading, handleDuplicateInvoice, t]);

  // @ts-ignore
  const printOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'Print document',
        icon: <HiOutlineDocument size={15} color={theme.colors.black} />,
        label: <StyledTitle>{t('Print document')}</StyledTitle>,
        onClick: () => handlePrintInvoice(invoiceId),
      },
    ],
    [handlePrintInvoice, invoiceId, t, theme.colors.black],
  );

  return (
    <>
      <InvoiceTabPanel invoice={invoice}>
        {({ tabs }) => (
          <OverviewLayout
            className="overview-details-item-invoice"
            aclItem={RoutesAcl[Routes.invoices]}
            headerTitle={t('Bill')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={storeDocumentEditOnInit}
                isDraft={isDraft}
              />
            }
            underHeaderContent={
              invoice?.uuid ? (
                <InvoicePageHeader
                  invoice={invoice}
                  handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
                />
              ) : null
            }
            header={
              <Row gutter={10}>
                {invoice?.client?.uuid ? (
                  <Col span={24}>
                    <DetailsView items={detailViewItems}>
                      <ClientDetailsView client={invoice?.client} />
                    </DetailsView>
                  </Col>
                ) : null}
                <Col span={24}>
                  <CommentField
                    withoutIndent={!invoice?.client?.uuid}
                    comment={invoice?.inv_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateInvoiceComment}
                    disabled={!invoiceAccess?.manage}
                  />
                </Col>

                <Col span={24}>
                  <StyledTitleContainer>
                    <StyledTitle bold>
                      {textToUpperCase(t('List of positions'))}
                    </StyledTitle>
                    <StyledDescription
                      $color={
                        isShowWarningDescription
                          ? theme.colors.black
                          : theme.colors.warning
                      }>
                      {isShowWarningDescription
                        ? `${t('In the amount of')}: ${
                            defaultCompany?.currency_symbol || ''
                          } ${invoice?.inv_sum_total}`
                        : t('the list is empty')}
                    </StyledDescription>
                  </StyledTitleContainer>

                  <StyledTableContainer>
                    {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                      <InvoiceItemListSelect
                        disabled={false}
                        selectRef={inputRef}
                        handleSearchSelectDaleyFocus={daleyFocus}
                        invoice={invoice}
                      />
                    ) : null}

                    <InvoiceItemListTable
                      handleSearchSelectFocus={focus}
                      handleSearchSelectDaleyFocus={daleyFocus}
                      invoice={invoice!}
                    />
                  </StyledTableContainer>
                </Col>
              </Row>
            }
            outsideFooterContent={
              <InvoiceBottomView
                invoice={invoice}
                handlePrintInvoice={handlePrintInvoice}
                handlePrintInvoiceStoreRealization={
                  handlePrintInvoiceStoreRealization
                }
                handlePrintInputInvoice={handlePrintInputInvoice}
              />
            }
            disabled={!invoiceAccess?.manage}
            editTooltip={t('Edit bill of lading')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </InvoiceTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceSideWindowLazy
          editMode
          invoice={InvoiceMapper.toInvoiceFormDTO(invoice!, { editMode: true })}
          onSuccess={invoiceDocumentEditSuccess}
          visible={invoiceDocumentVisible}
          onCancel={invoiceEditHandleCancel}
        />
      </Suspense>
    </>
  );
}

export function InvoicePage() {
  return (
    <InvoiceManager>
      <InvoiceContent />
    </InvoiceManager>
  );
}
