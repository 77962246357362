import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import { useStateEmployeeDaysOff, useStopLoading } from '@hooks';
import { eq, ifElse, len, textToUpperCase } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { DayOffMapper, DayOffModel } from '@structure';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DateFormat } from '@components/lib/Format';
import {
  DayOffCreateButton,
  DayOffDeleteButton,
  DayOffEditButton,
} from '../../EmployeeDayOff';
import { DeleteOutlined } from '@ant-design/icons';
import { useAcl } from '@contex';

export function EmployeeDayOffListPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();
  const { t } = useTranslation();
  const { manage } = useAcl(({ employee }) => employee);

  const [selectedDaysOff, setSelectedDaysOff] = useState<DayOffModel[]>([]);

  const {
    daysOff,
    loading: daysOffLoading,
    error: daysOffError,
    handleCreateDayOff,
    handleDeleteDaysOff,
    handleUpdateDayOff,
    total,
    limit: pageSize,
    refresh,
  } = useStateEmployeeDaysOff({
    employeeUuid: employeeId!,
  });

  const loading = useStopLoading({
    loading: daysOffLoading,
    error: daysOffError,
    message: 'An error occurred during employees loading',
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedDaysOff.map(({ uuid }) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: DayOffModel[],
      ): void => {
        setSelectedDaysOff(selectedRecords);
      },
    }),
    [selectedDaysOff],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${Routes.employees}/${employeeId}`,
      breadcrumbName: location?.state?.employeeFullName || 'Show Employee',
    },
    {
      path: `/${Routes.employees}/${employeeId}/${Routes.daysOff}`,
      breadcrumbName: 'Days off',
    },
  ]);

  const columns = [
    {
      title: t('Start date'),
      key: 'start_date',
      render: (dayOff: DayOffModel) => (
        // @ts-ignore
        <DateFormat>{dayOff.start_date}</DateFormat>
      ),
    },
    {
      title: t('End date'),
      key: 'end_date',
      render: (dayOff: DayOffModel) => (
        <DateFormat>{dayOff.end_date}</DateFormat>
      ),
    },
    {
      title: t('Comment'),
      key: 'comment',
      render: (dayOff: DayOffModel) => dayOff.comment,
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (dayOff: DayOffModel) => (
        <TableActionCell>
          <DayOffEditButton
            disabled={false}
            employeeUuid={employeeId!}
            type="text"
            dayOff={DayOffMapper.toDayOffDTO(dayOff)}
            data-testid="employee-day-off-edit-button"
            onSuccess={handleUpdateDayOff}
          />
          <DayOffDeleteButton
            disabled={false}
            data-testid="employee-day-off-actions-delete-button"
            daysOff={[dayOff]}
            onSuccess={handleDeleteDaysOff}>
            <DeleteOutlined />
          </DayOffDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.employees}/${employeeId}`);
  }, [employeeId, navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.daysOff]))}
      headerRight={
        selectedDaysOff?.length ? (
          <DayOffDeleteButton
            disabled={eq(len(selectedDaysOff), 0) || !manage}
            onSuccess={handleDeleteDaysOff}
            daysOff={selectedDaysOff}
            loading={loading}
          />
        ) : (
          <DayOffCreateButton
            disabled={!manage}
            onSuccess={handleCreateDayOff}
            employeeUuid={employeeId!}
            loading={loading}
          />
        )
      }
      routes={routes}
      empty={
        daysOff?.size ? null : (
          <Empty
            description={t(
              `It looks like employee don't have any days off at the moment.`,
            )}>
            <DayOffCreateButton
              type="primary"
              title={t('Add day off')}
              onSuccess={handleCreateDayOff}
              employeeUuid={employeeId!}
              loading={loading}
              disabled={!manage}
            />
          </Empty>
        )
      }
      // headerExtra={
      //   <ListSearchInput
      //     handleSearch={handleSearchEmployees}
      //     tooltipTitle={t('Search employees')}
      //     label={t('Search employees')}
      //   />
      // }
      loading={loading && !daysOff?.size}>
      <Table<DayOffModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={daysOff}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
