import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CompanyOrderBonusDTO,
  CompanyOrderBonusType,
  FinancialSettingsMapper,
} from '@structure';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import { COMPANY_ORDER_BONUS_TYPE } from '@services/api/setting';
import {
  EditableInputSelectCell,
  EditableRow,
} from '@components/lib/DataDisplay/FormEditableTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useStoredCompanies from './useStoredCompanies';
import { FormInstance, Switch } from 'antd';
import { isFunction } from '@sportix/sportix-common-modules';

export interface IUseCompanyOrderBonusFieldParamsProps {
  companyOrderBonuses: CompanyOrderBonusDTO[];
  selectedCompanyOrderBonuses?: CompanyOrderBonusDTO[] | null;
  form: FormInstance;
  type: 'company_order_bonuses' | 'bonuses';

  onChange?: (value: CompanyOrderBonusDTO[]) => Promise<void>;
}

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  min-width: 163px;
`;

const StyledHiddenContainer = styled.div`
  overflow: hidden;
  height: 0;
  width: 22px;
`;

const StyledSwitch = styled(Switch)`
  position: absolute;
  left: 13px;
  top: calc(50% - 10px);
`;

export function useCompanyOrderBonusFieldParams({
  companyOrderBonuses: defaultCompanyOrderBonuses,
  selectedCompanyOrderBonuses: defaultSelectedCompanyOrderBonuses,
  form,
  type,
  onChange,
}: IUseCompanyOrderBonusFieldParamsProps) {
  const { t } = useTranslation();
  const { defaultCompany } = useStoredCompanies();

  const [companyOrderBonuses, setCompanyOrderBonuses] = React.useState(
    defaultCompanyOrderBonuses || [],
  );
  const [selectedCompanyOrderBonuses, setSelectedCompanyOrderBonuses] =
    useState<CompanyOrderBonusDTO[] | null>(null);

  const [bonusLoading, setBonusLoading] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState('');

  const defaultColumns = [
    {
      title: t('Criteria'),
      key: 'сriteria',
      render: (companyOrderBonus: CompanyOrderBonusDTO) => (
        <StyledTitleContainer>
          <StyledTitle>{companyOrderBonus?.title}</StyledTitle>
          {companyOrderBonus?.description ? (
            <StyledDescription $withoutEllipsis>
              {companyOrderBonus?.description}
            </StyledDescription>
          ) : null}
        </StyledTitleContainer>
      ),
    },
    {
      title: t('Value'),
      key: 'value',
      editable: true,
      width: '10%',
      options: COMPANY_ORDER_BONUS_TYPE(defaultCompany?.currency_symbol || ''),
      optionsName: 'type',
      rules: [
        () => ({
          validator(_: any, price: string) {
            const value = price;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('The value must be greater than 0')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('The value must be a number')));
            }

            return Promise.resolve();
          },
        }),
      ],
      fixed: 'right' as any,
      align: 'right' as any,
      render: (companyOrderBonus: CompanyOrderBonusDTO) => (
        <StyledDescriptionContainer>
          <StyledTitle>
            {`${
              companyOrderBonus?.type === CompanyOrderBonusType.Fixed
                ? defaultCompany?.currency_symbol
                : '%'
            } ${companyOrderBonus?.value}`}
          </StyledTitle>
        </StyledDescriptionContainer>
      ),
    },
  ];

  const handleSave = useCallback(
    async (row: CompanyOrderBonusDTO) => {
      setBonusLoading(true);
      setLoadingItemId(row?.id);

      const newData = [...companyOrderBonuses];
      const selectedNewData = [...(selectedCompanyOrderBonuses || [])];
      const index = newData.findIndex((item) => row.id === item.id);
      const selectedIndex = selectedNewData.findIndex(
        (item) => row.id === item.id,
      );
      const item = newData[index];
      const selectedItem = selectedNewData[selectedIndex];

      const rowDTO = FinancialSettingsMapper.toCompanyOrderBonusDTO({
        ...item,
        ...row,
      });

      const selectedRowDTO = FinancialSettingsMapper.toCompanyOrderBonusDTO({
        ...selectedItem,
        ...row,
      });

      newData.splice(index, 1, rowDTO);
      selectedNewData.splice(selectedIndex, 1, selectedRowDTO);

      console.log('NEW DATA --> ', row, newData);

      setCompanyOrderBonuses(newData);
      if (type === 'bonuses') {
        setSelectedCompanyOrderBonuses(selectedNewData);
      }
      form.setFieldsValue({
        [type]: type === 'bonuses' ? selectedNewData : newData,
      });

      if (isFunction(onChange)) {
        if (row?.value !== item?.value || row?.type !== item?.type) {
          await onChange(selectedNewData);
        }
      }

      setBonusLoading(false);
      setLoadingItemId('');
    },
    [companyOrderBonuses, form, onChange, selectedCompanyOrderBonuses, type],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableInputSelectCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: CompanyOrderBonusDTO) => {
        let disabled = false;
        if (Array.isArray(selectedCompanyOrderBonuses)) {
          const item = selectedCompanyOrderBonuses.find(
            ({ id }) => id === record.id,
          );

          disabled = !item;
        }

        return {
          record,
          editable: col.editable,
          dataIndex: col.key,
          title: col.title,
          options: col.options,
          rules: col.rules,
          optionsName: col.optionsName,
          disabled,
          handleSave,
          loading: bonusLoading,
          loadingItemId,
          alignText: col?.align,
        };
      },
    };
  });

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: (selectedCompanyOrderBonuses || []).map(({ id }) => id),
      onChange: async (
        selectedRowKeys: React.Key[],
        selectedRecords: CompanyOrderBonusDTO[],
      ) => {
        setSelectedCompanyOrderBonuses(selectedRecords);
        form.setFieldsValue({ bonuses: selectedRecords });

        if (isFunction(onChange)) {
          await onChange(selectedRecords);
        }
      },
      onSelect: (record: any, selected: boolean) => {
        if (!selected) {
          const newData = [...defaultCompanyOrderBonuses];
          const index = newData.findIndex((item) => record.id === item.id);
          const item = newData[index];
          handleSave(item);
        }
      },
      renderCell: (
        checked: boolean,
        bonus: CompanyOrderBonusDTO,
        index: number,
        originNode: React.ReactNode,
      ) => {
        return (
          <div className="row-selection-container">
            <StyledSwitch
              disabled={bonusLoading && loadingItemId === bonus?.id}
              checked={checked}
              onClick={(_, event) => {
                event.preventDefault();
                const target = event?.target;

                if (target instanceof HTMLElement) {
                  const container = target.closest('.row-selection-container');

                  console.log('!!! CLICK ON !!!!! ');

                  if (container instanceof HTMLElement) {
                    const rowCheckBox = container.querySelector(
                      '.ant-checkbox-input',
                    );

                    const clickEvent = new MouseEvent('click', {
                      bubbles: true,
                      cancelable: true,
                    });
                    if (rowCheckBox instanceof HTMLElement) {
                      rowCheckBox.dispatchEvent(clickEvent);
                    }
                  }
                }
              }}
            />
            <StyledHiddenContainer>
              {React.isValidElement(originNode)
                ? React.cloneElement(originNode, {
                    className: 'row-selection-checkbox',
                  } as any)
                : null}
            </StyledHiddenContainer>
          </div>
        );
      },
    }),
    [
      bonusLoading,
      defaultCompanyOrderBonuses,
      form,
      handleSave,
      loadingItemId,
      onChange,
      selectedCompanyOrderBonuses,
    ],
  );

  React.useEffect(() => {
    if (
      Array.isArray(selectedCompanyOrderBonuses) &&
      selectedCompanyOrderBonuses?.length &&
      companyOrderBonuses?.length
    ) {
      setCompanyOrderBonuses((prevState) => {
        return prevState.map((prev) => {
          const item = selectedCompanyOrderBonuses.find(
            (selected) => selected?.id === prev.id,
          );

          if (item) {
            return item;
          }
          return prev;
        });
      });
    }
  }, [companyOrderBonuses?.length, selectedCompanyOrderBonuses]);

  useEffect(() => {
    if (Array.isArray(defaultSelectedCompanyOrderBonuses)) {
      setSelectedCompanyOrderBonuses(defaultSelectedCompanyOrderBonuses);
    }
  }, [defaultSelectedCompanyOrderBonuses]);

  useEffect(() => {
    if (Array.isArray(defaultCompanyOrderBonuses)) {
      setCompanyOrderBonuses(defaultCompanyOrderBonuses);
    }
  }, [defaultCompanyOrderBonuses]);

  return {
    columns,
    components,
    companyOrderBonuses,
    rowSelection,
  };
}
