import * as React from 'react';
import { getCashBoxById } from '@services/api/cashBox';
import { CashBoxModel, CashBoxMapper, CashBoxDTO } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { IEntityProps } from '@services/types';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseCashBoxProps extends IEntityProps {
  cashBoxUuid: string;
}

export interface IUseCashBoxReturnType {
  error: null | any;
  loading: boolean;
  entity: CashBoxModel | null;
  refresh: (value: Partial<IUseCashBoxProps>) => Promise<CashBoxModel | void>;
  cashBoxUuid: string;
}

export function useCashBox(
  { loadOnInit = true, cashBoxUuid }: IUseCashBoxProps = {} as IUseCashBoxProps,
): IUseCashBoxReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    cashBoxUuid: entityUuid,
  } = useStateReducer<Omit<IUseCashBoxReturnType, 'refresh'>>({
    entity: null,
    cashBoxUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      cashBoxUuid = entityUuid,
    }: Partial<IUseCashBoxProps> = {}): Promise<CashBoxModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null, cashBoxUuid });

        const cashBox = await cancellablePromise<CashBoxDTO>(
          getCashBoxById(cashBoxUuid),
        );

        const cashBoxModel = CashBoxMapper.toCashBoxModel(cashBox);

        handleUpdate({
          entity: cashBoxModel,
          loading: false,
        });
        return cashBoxModel;
      } catch (err: any) {
        alert(
          'error',
          t('Cash box'),
          `${t('An error occurred during get cash box')} : ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && cashBoxUuid) {
      (async () => {
        await refresh({ cashBoxUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, cashBoxUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    cashBoxUuid,
  };
}
