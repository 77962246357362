import { List } from 'immutable';
import { APP_STATE, REPORT_LIST } from '../constants';
import { isEqualByUuid, not, compose } from '@services/helpers';
import { REDUX_STATUS, ApiError } from '@services/types';
import { ReportModel } from '@structure';

interface IReportListState {
  reportList: List<ReportModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  startDate?: string;
  endDate?: string;
  total: number;
  page?: number;
}

interface SetActionList
  extends Pick<
    IReportListState,
    'reportList' | 'keywords' | 'total' | 'startDate' | 'endDate' | 'page'
  > {
  type: REPORT_LIST.SET_REPORT_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: REPORT_LIST.ADD_REPORT;
  report: ReportModel;
}

interface DeleteActionFromList {
  type: REPORT_LIST.DELETE_REPORT;
  reportUuid: string;
}

interface LoadingActionInList {
  type: REPORT_LIST.LOADING_REPORT_LIST;
}

interface ErrorActionInList extends Pick<IReportListState, 'error'> {
  type: REPORT_LIST.ERROR_REPORT_LIST;
}

interface Handlers {
  [REPORT_LIST.SET_REPORT_LIST]: (
    state: IReportListState,
    action: SetActionList,
  ) => IReportListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IReportListState,
    action: SetInitialStateAction,
  ) => IReportListState;

  [REPORT_LIST.LOAD_MORE_REPORT_LIST]: (
    state: IReportListState,
    action: SetActionList,
  ) => IReportListState;

  [REPORT_LIST.ADD_REPORT]: (
    state: IReportListState,
    action: AddActionToList,
  ) => IReportListState;

  [REPORT_LIST.DELETE_REPORT]: (
    state: IReportListState,
    action: DeleteActionFromList,
  ) => IReportListState;

  [REPORT_LIST.ERROR_REPORT_LIST]: (
    state: IReportListState,
    value: ErrorActionInList,
  ) => IReportListState;

  [REPORT_LIST.LOADING_REPORT_LIST]: (
    state: IReportListState,
    value?: LoadingActionInList,
  ) => IReportListState;
  DEFAULT: (state: IReportListState) => IReportListState;
}

const initState: IReportListState = {
  reportList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  startDate: '',
  endDate: '',
  total: 0,
  page: 1,
};

const handlers: Handlers = {
  [REPORT_LIST.SET_REPORT_LIST]: (
    state,
    { reportList, keywords, startDate, endDate, total, page },
  ) => ({
    ...state,
    ...{
      reportList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      startDate: startDate || '',
      endDate: endDate || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [REPORT_LIST.LOAD_MORE_REPORT_LIST]: (state, { reportList }) => ({
    ...state,
    ...{
      reportList:
        List.isList(state.reportList) && List.isList(reportList)
          ? state.reportList.merge(reportList)
          : state.reportList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [REPORT_LIST.ADD_REPORT]: (state, { report }) => ({
    ...state,
    ...{
      reportList: List.isList(state.reportList)
        ? state.reportList.unshift(report)
        : List([report]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
    },
  }),

  [REPORT_LIST.DELETE_REPORT]: (state, { reportUuid }) => ({
    ...state,
    ...{
      reportList: List.isList(state.reportList)
        ? state.reportList.filter(compose(not, isEqualByUuid(reportUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [REPORT_LIST.ERROR_REPORT_LIST]: (
    state: IReportListState,
    { error }: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [REPORT_LIST.LOADING_REPORT_LIST]: (state: IReportListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IReportListState) => state,
};

export default function ReportList(
  state: any = initState,
  action: any,
): IReportListState {
  const handler = handlers[action.type as REPORT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
