import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { contentApiUrl } from '../const';
import Validate from '../validate/Validate';
import { CategoryListProps } from '../helpers';
import { ApiAnswer, DeletePrams, IListProps, IListSearchProps } from '../types';
import { ErrorsStatus } from '../core/request';
import {
  CategoryDTO,
  CategoryMapper,
  CategoryFormDTO,
  ICategoryDTOProps,
  ICategoryListDTO,
} from '@structure';

export const CATEGORY_INITIAL_DATA = new CategoryFormDTO();

export interface ICategoryListAnswer extends ApiAnswer {
  categories: ICategoryDTOProps[];
}

export interface ICategoryListProps extends IListSearchProps {}

export interface ICategoryAnswer extends ApiAnswer {
  category: ICategoryDTOProps;
}

export interface DeleteAnswer extends CategoryListProps {
  deleted: boolean;
}

export async function getCategoryList({
  offset = 0,
  limit = 10,
  keywords,
}: ICategoryListProps): Promise<ICategoryListDTO> {
  const { categories, total } = await apiGet<
    ICategoryListProps,
    ICategoryListAnswer
  >(
    `${contentApiUrl}/categories`,
    {
      offset,
      limit,
      keywords,
    },
    { ignoreRedirect: [ErrorsStatus.Forbidden] },
  );

  return CategoryMapper.toCategoryListDTO(categories, total);
}

export async function createCategory({
  title,
  description,
  status = 1,
  pos = '1',
}: CategoryFormDTO): Promise<CategoryDTO> {
  Validate.string({ value: title });

  const { category } = await apiPost<
    Omit<CategoryFormDTO, 'uuid'>,
    ICategoryAnswer
  >(
    `${contentApiUrl}/categories`,
    {
      title,
      description,
      status: Number(status),
      pos,
    },
    { ignoreRedirect: true },
  );

  return CategoryMapper.toCategoryDTO(category);
}

/**
 * @desc Редагування категорії
 * */
export async function editCategory({
  uuid,
  title,
  description,
  status,
  pos,
}: CategoryFormDTO): Promise<CategoryDTO> {
  Validate.string({ value: title });

  const { category } = await apiPut<
    Omit<CategoryFormDTO, 'uuid'>,
    ICategoryAnswer
  >(`${contentApiUrl}/categories/${uuid}`, {
    title,
    description,
    status: Number(status),
    pos,
  });

  return CategoryMapper.toCategoryDTO(category);
}

export async function deleteCategories(ids: string[]): Promise<DeleteAnswer> {
  Validate.arrayOfStrings({ value: ids });

  return await apiDelete<DeletePrams, DeleteAnswer>(
    `${contentApiUrl}/categories`,
    {
      ids,
    },
  );
}
