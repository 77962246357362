import React from 'react';
import Tooltip from 'antd/es/tooltip';
import styled, { css } from 'styled-components';
import { TextFormat, ITextFormatProps } from '../Format';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { fromBooleanToNumber } from '@services/helpers';
import { FlexContainer, IFlexContainerProps } from '../Styled/FlexContainer';

export interface ILabelProps extends IFlexContainerProps {
  tooltipTitle?: string;
  label?: string;
  children?: React.ReactNode;
  labelProps?: Omit<ITextFormatProps, 'children' | 'ref'>;
  invert?: boolean;
  fullWidth?: boolean;
  required?: boolean;
}

const LabelFlexContainer = styled(FlexContainer)<{
  invert?: number;
  $fullWidth?: boolean;
}>`
  width: fit-content;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  & .label-text__title {
    margin-bottom: 8px;
  }

  & .label-text__label {
    color: black;
    ${({ invert }) =>
      invert &&
      css`
        color: ${({ theme }) => theme.colors.app} !important;
      `}
  }

  & .label-text__tooltip {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.45) !important;
    ${({ invert }) =>
      invert &&
      css`
        color: ${({ theme }) => theme.colors.app} !important;
      `}
  }
  & .label-text__tooltip:hover {
    cursor: help;
  }

  & .label-text {
    margin-bottom: 10px;
  }
`;

const StyledRequired = styled(TextFormat)`
  color: ${({ theme }) => theme.colors.error};
  margin-right: 5px;
`;

export default function Label({
  tooltipTitle,
  label,
  children,
  labelProps = {},
  invert,
  fullWidth,
  required,
  ...rest
}: ILabelProps): JSX.Element {
  return (
    <LabelFlexContainer
      $fullWidth={fullWidth}
      flexDirection="column"
      invert={fromBooleanToNumber(invert)}
      {...rest}>
      <div className="label-text">
        {label && (
          <>
            {required ? (
              <StyledRequired className="label-text__title" {...labelProps}>
                *
              </StyledRequired>
            ) : null}

            <TextFormat className="label-text__title" {...labelProps}>
              {label}
            </TextFormat>
          </>
        )}
        {tooltipTitle && (
          <Tooltip title={tooltipTitle} className="label-text__tooltip">
            <QuestionCircleOutlined size={24} />
          </Tooltip>
        )}
      </div>
      {children}
    </LabelFlexContainer>
  );
}
