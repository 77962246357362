import {
  PlanDTO,
  IPlanDTOProps,
  PlanListModel,
  PlanModel,
  PlanFormDTO,
  PlanTariffFormDTO,
} from '../internal';
import { listToArray, EPlanTypeIdentifier } from '@services/helpers';
import { List } from 'immutable';

export interface IPlanListDTO {
  plans: IPlanDTOProps[];
  total: number;
}

export class PlanMapper {
  public static toPlanListModel(
    planListDTO: PlanDTO[],
    total: number,
  ): PlanListModel {
    return new PlanListModel({ plans: planListDTO, total });
  }

  public static toPlanListDTO(
    planListModel: List<PlanModel> | IPlanDTOProps[],
    total: number,
  ): IPlanListDTO {
    const planList = List.isList(planListModel)
      ? listToArray<PlanModel>(planListModel)
      : planListModel;

    return {
      plans: planList.map((plan) => new PlanDTO(plan as any)),
      total,
    };
  }

  public static toPlanFormDTO(planModel: PlanModel): PlanFormDTO {
    return new PlanFormDTO(planModel);
  }

  public static toPlanTariffFormDTO(
    planModel: PlanModel,
    packageName?: EPlanTypeIdentifier,
  ): PlanTariffFormDTO {
    return new PlanTariffFormDTO(planModel, packageName);
  }
}
