import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(ExpenseInvoicePage);

export interface IExpenseInvoicePageProps extends WithTranslation {}

function ExpenseInvoicePage({}: IExpenseInvoicePageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{ fontSize: 30 }}>Expense Invoice Page</p>
    </div>
  );
}
