import * as React from 'react';
import { getDepartmentById } from '@services/api/department';
import { DepartmentModel, DepartmentMapper, DepartmentDTO } from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { IEntityProps } from '@services/types';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseDepartmentProps extends IEntityProps {
  departmentUuid: string;
}

export interface IUseDepartmentReturnType {
  error: null | any;
  loading: boolean;
  entity: DepartmentModel | null;
  refresh: (
    value: Partial<IUseDepartmentProps>,
  ) => Promise<DepartmentModel | void>;
  departmentUuid: string;
}

export function useDepartment(
  {
    loadOnInit = true,
    departmentUuid,
  }: IUseDepartmentProps = {} as IUseDepartmentProps,
): IUseDepartmentReturnType {
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();
  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    departmentUuid: entityUuid,
  } = useStateReducer<Omit<IUseDepartmentReturnType, 'refresh'>>({
    entity: null,
    departmentUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      departmentUuid = entityUuid,
    }: Partial<IUseDepartmentProps> = {}): Promise<DepartmentModel | void> => {
      try {
        handleUpdate({ loading: showLoading, error: null, departmentUuid });

        const department = await cancellablePromise<DepartmentDTO>(
          getDepartmentById(departmentUuid),
        );

        const departmentModel = DepartmentMapper.toDepartmentModel(department);

        if (!didCancel.current) {
          handleUpdate({
            entity: departmentModel,
            loading: false,
          });
          return departmentModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }
        alert(
          'error',
          t('Department'),
          `${t('An error occurred during get department')} : ${err?.message}`,
        );
      }
    },

    [alert, cancellablePromise, didCancel, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && departmentUuid) {
      (async () => {
        await refresh({ departmentUuid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, departmentUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    departmentUuid,
  };
}
