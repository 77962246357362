import React from 'react';
import { Routes, Route } from 'react-router';
import {
  CompanyListPage,
  CompanyPage,
  CreateCompanyPage,
  SetDefaultCompanyPage,
  WaitingForCompanyCreatePage,
  CompanyCalendarPage,
} from '../views/Company';
import {
  AppRouteLayout,
  AuthRouteLayout,
  WithoutLayout,
} from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';
import { CalendarRoutes } from './CalendarRoutes';

export function CompanyRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <CompanyListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path=":companyId"
        element={
          <AppRouteLayout>
            <CompanyPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:companyId/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={CompanyCalendarPage} />}
      />

      <Route
        path="create"
        element={
          <AuthRouteLayout withBackButton={false}>
            <CreateCompanyPage />
          </AuthRouteLayout>
        }
      />
      <Route
        path={RoutesType.waitingForCompanyCreate}
        element={
          <AuthRouteLayout withBackButton={false}>
            <WaitingForCompanyCreatePage />
          </AuthRouteLayout>
        }
      />

      <Route
        path="set-default"
        element={
          <WithoutLayout>
            <SetDefaultCompanyPage />
          </WithoutLayout>
        }
      />
    </Routes>
  );
}
