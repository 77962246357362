import {
  IScheduleStatusModelReturnType,
  IProfileDTOProps,
  ProfileMapper,
} from '../internal';
import { toDateByFormat } from '@services/helpers';

export interface IScheduleStatusDTOProps {
  reason: string;
  status_text: string;
  status_color: string;
  created_at: string;
  groupBy: string;
  created_by: IProfileDTOProps;
}

export class ScheduleStatusDTO {
  public reason: string;

  public status_text: string;

  public status_color: string;

  public created_at: string;

  public groupBy?: string;

  public created_by: IProfileDTOProps;

  constructor(props: IScheduleStatusModelReturnType | IScheduleStatusDTOProps) {
    this.reason = props?.reason || '';
    this.status_text = props?.status_text || '';
    this.status_color = props?.status_color || '';
    this.created_at = props?.created_at || '';
    this.groupBy = props?.created_at
      ? toDateByFormat(props?.created_at, 'YYYY-MM-DD')
      : '';
    this.created_by = ProfileMapper.toProfileDTO(props?.created_by);
  }
}
