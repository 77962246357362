import React from 'react';
import { Link as NativeLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Color from 'color';
export interface ILinkProps extends LinkProps {
  tooltip?: string;
  children: React.ReactNode;
  withoutRight?: boolean;
}

const StyledLink = styled(NativeLink)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: fit-content !important;
  color: ${({ theme }) => theme.colors.link};
  gap: 5px;

  &:hover {
    color: ${({ theme }) =>
      Color(theme.colors.link).alpha(1).darken(0.3) as any};
  }
`;

export default function Link({
  title,
  tooltip,
  children,
  withoutRight = false,
  ...rest
}: ILinkProps): JSX.Element {
  return (
    <Tooltip title={tooltip}>
      <StyledLink {...rest}>
        {children}
        {/*{withoutRight ? null : <RightOutlined />}*/}
      </StyledLink>
    </Tooltip>
  );
}
