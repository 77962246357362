import * as React from 'react';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  IProductListDTO,
  ProductMapper,
  ProductListModel,
  IProductsListStatsProps,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getProductLeftoversList } from '@services/api/product';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseProductLeftoversListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  store_uuid?: string;
  category_uuid?: string;
  product_uuid?: string;
  show_running_out_products?: boolean;
}

export interface IUseProductLeftoversListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: ProductListModel | null;
  refresh: (
    value: Partial<IUseProductLeftoversListProps>,
  ) => Promise<ProductListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  stats: IProductsListStatsProps;
  store_uuid?: string;
  category_uuid?: string;
  product_uuid?: string;
  show_running_out_products?: boolean;
}

export function useProductLeftoversList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    store_uuid,
    product_uuid,
    category_uuid,
    show_running_out_products,
  }: IUseProductLeftoversListProps = {} as IUseProductLeftoversListProps,
): IUseProductLeftoversListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    stats: listStats,
    store_uuid: listStoreUuid,
    product_uuid: listProductUuid,
    category_uuid: listCategoryUuid,
    show_running_out_products: listshowRunningOutProducts,
    handleUpdate,
  } = useStateReducer<Omit<IUseProductLeftoversListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    stats: {} as IProductsListStatsProps,
    store_uuid,
    product_uuid,
    category_uuid,
    show_running_out_products,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
      store_uuid = listStoreUuid,
      product_uuid = listProductUuid,
      category_uuid = listCategoryUuid,
      show_running_out_products = listshowRunningOutProducts,
    }: Partial<IUseProductLeftoversListProps> = {}): Promise<ProductListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          product_uuid,
          store_uuid,
          category_uuid,
          show_running_out_products,
        });

        const { products, stats, total, product, store, category } =
          await cancellablePromise<IProductListDTO>(
            getProductLeftoversList({
              limit,
              offset,
              keywords,
              companyUuid,
              store_uuid,
              product_uuid,
              category_uuid,
              show_running_out_products,
            }),
          );

        const productListModel = ProductMapper.toProductListModel(
          products,
          total,
          false,
          [],
          stats,
          category,
          store,
          product,
        );

        handleUpdate({
          entityList: productListModel,
          total: productListModel?.total,
          loading: false,
          stats,
        });

        return productListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during get materials list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      listStoreUuid,
      listProductUuid,
      listCategoryUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
      listshowRunningOutProducts,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({ companyUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
    stats: listStats,
    store_uuid: listStoreUuid,
    product_uuid: listProductUuid,
    category_uuid: listCategoryUuid,
    show_running_out_products: listshowRunningOutProducts,
  };
}
