import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Tooltip, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router';

export const TableLinkCell = withTranslation()(LinkCell);

export interface ITableLinkCellProps extends WithTranslation {
  children: React.ReactNode;
  tooltip: string;
  path?: string;
  fullPath?: string;
  state?: any;
  icon?: React.ReactNode;
  disabled?: boolean;
  color?: string;
  className?: string;
  withoutIcon?: boolean;
}

const StyledLink = styled(Link)<{ $color?: string }>`
  display: flex;
  align-items: center;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};

      &&&:hover,
      &&&:active,
      &&&:focus {
        color: ${$color};
        opacity: 0.7;
      }
    `}
`;
const StyledDisabledLink = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.disabled};
`;

const StyledSpace = styled(Space)``;

function LinkCell({
  t,
  children,
  tooltip,
  path,
  fullPath,
  state,
  icon,
  disabled,
  color,
  className,
  withoutIcon,
}: ITableLinkCellProps) {
  const location = useLocation();

  return (
    <Tooltip title={t(tooltip)}>
      {disabled ? (
        <StyledDisabledLink className={className}>
          <StyledSpace>
            {children}
            {icon || <RightOutlined />}
          </StyledSpace>
        </StyledDisabledLink>
      ) : (
        <StyledLink
          className={className}
          $color={color}
          state={state}
          to={{
            pathname: fullPath || `${location.pathname}/${path}`,
          }}>
          <StyledSpace>
            {children}
            {/*{withoutIcon ? null : icon || <RightOutlined />}*/}
          </StyledSpace>
        </StyledLink>
      )}
    </Tooltip>
  );
}
