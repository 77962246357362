import CenterModal from './CenterModal';
import React, { forwardRef } from 'react';
import { useModal } from '../libV2/hooks';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from './Button';
import styled, { css } from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';
import { successButtonStyle } from '../Styled/HeaderActions';
import { isFunction } from '@sportix/sportix-common-modules';

export interface FilterButtonProps extends Omit<IButtonProps, 'children'> {
  children: React.ReactNode | React.ReactNode[];
  onCancel?: () => void;
  onReset?: () => void;
  onSuccess?: () => Promise<void>;
  count?: number;
}

const StyledButton = styled(Button)<{ $count?: number }>`
  width: fit-content;

  &&&,
  &&&:hover {
    border: none;
    background: rgba(5, 181, 130, 0.1);
    color: ${({ theme }) => theme.colors.success};
  }

  &&&:hover,
  &&&:active,
  &&&:focus {
    opacity: 0.7;
  }

  ${({ $count, theme }) => Number($count) > 0 && css``}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 1;
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      &&& {
        display: flex !important;
      }
    `}
`;

const StyledCenterModal = styled(CenterModal)`
  position: relative;

  & > .ant-modal-content {
    overflow: hidden;
  }

  .ant-modal-body {
    min-height: 100px;
    overflow: hidden;
  }
`;

const StyledSuccessButton = styled(Button)`
  width: fit-content;
  ${successButtonStyle}
`;

export const FilterButton = forwardRef(function FilterButton(
  {
    title = 'Filter',
    children,
    onCancel,
    onReset,
    onSuccess,
    count,
    ...props
  }: FilterButtonProps,
  ref: any,
): React.JSX.Element {
  const { t } = useTranslation();

  const {
    visible,
    handleCancel,
    handleReset,
    handleOnInit,
    handleSuccess,
    loading,
  } = useModal({
    onSuccess,
    onCancel,
    onReset,
  });

  return (
    <>
      <StyledButton
        $count={count}
        onClick={handleOnInit}
        icon={<FilterOutlined />}
        {...props}>
        {`${t('Filter')}${Number(count) > 0 ? ` (${count})` : ''}`}
      </StyledButton>

      {visible ? (
        <StyledCenterModal
          ref={ref}
          onCancel={() => {
            try {
              handleCancel();
            } catch (e) {}
          }}
          footer={[
            <StyledButton
              size="large"
              type="primary"
              loading={loading}
              onClick={async () => {
                try {
                  if (isFunction(handleReset)) {
                    await handleReset();
                  }
                } catch (e) {}
              }}>
              {t('Reset filter')}
            </StyledButton>,
            <StyledSuccessButton
              size="large"
              type="primary"
              loading={loading}
              onClick={async (value) => {
                try {
                  await handleSuccess(value);
                } catch (e) {}
              }}>
              {t('Apply filter')}
            </StyledSuccessButton>,
          ]}
          title={t(title)}
          open={visible}>
          {children}
        </StyledCenterModal>
      ) : null}
    </>
  );
});
