import * as React from 'react';
import {
  useIntegrationListByCategory,
  IUseIntegrationListByCategoryProps,
  IUseIntegrationListByCategoryReturnType,
} from './useIntegrationListByCategory';
import { IntegrationAssociationDTO, IntegrationCategoryType } from '@structure';
import { useStateEntityList } from '@components/lib/libV2/hooks';

export interface IUseStateIntegrationListByCategoryProps
  extends IUseIntegrationListByCategoryProps {}

export interface IIntegrationSearchByCategoryProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  companyUuid?: string;
  categoryType?: IntegrationCategoryType;
}
export interface IUseStateIntegrationListByCategoryReturnType<T>
  extends Omit<IUseIntegrationListByCategoryReturnType<T>, 'entityList'> {
  associations: IntegrationAssociationDTO<T>[] | null;
  handleSearchIntegrations: (
    value: IIntegrationSearchByCategoryProps,
  ) => Promise<void>;
}

export function useStateIntegrationListByCategory<T>(
  {
    companyUuid,
    categoryType,
    ...rest
  }: IUseStateIntegrationListByCategoryProps = {} as IUseStateIntegrationListByCategoryProps,
): IUseStateIntegrationListByCategoryReturnType<T> {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: integrationLoading,
    total: integrationListTotal,
    ...integrationsParams
  } = useIntegrationListByCategory<T>({
    companyUuid,
    categoryType,
    ...rest,
  });

  const {
    entityList: associations,
    loading,
    setEntityList,
    total,
  } = useStateEntityList<IntegrationAssociationDTO<T>>({
    entityList: entityList as any,
    refresh,
    limit,
    offset,
    total: integrationListTotal,
    loading: true,
  });

  const handleSearchIntegrations = React.useCallback(
    async ({
      keywords,
      showLoading = true,
      limit = 10,
      companyUuid: listCompanyUuid = companyUuid,
      categoryType: listCategoryType = categoryType,
    }: IIntegrationSearchByCategoryProps) => {
      const associations = await refresh({
        offset: 0,
        keywords,
        showLoading,
        limit,
        companyUuid: listCompanyUuid,
        categoryType: listCategoryType,
      });

      if (associations) {
        setEntityList(associations as any);
      }
    },
    [categoryType, companyUuid, refresh, setEntityList],
  );

  return {
    associations: associations as IntegrationAssociationDTO<T>[] | null,
    offset,
    limit,
    refresh,
    total,
    loading: integrationLoading || loading,
    ...integrationsParams,
    handleSearchIntegrations,
  };
}
