import { Button, Space } from 'antd';
import styled from 'styled-components';
import { useTermsPolicy } from '@hooks';
import { Routes } from '@services/types';
import { TermsPolicyForm } from './Forms';
import React, { useCallback } from 'react';
import { Text } from '@components/lib/Format';
import { AuthLayout, HeaderText } from '../Show';
import { textToUpperCase } from '@services/helpers';
import { useLocation, useNavigate } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(TermsPolicyPage);

export interface ITermsPolicyPageProps extends WithTranslation {}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
`;

const StyledLink = styled(Button)`
  display: inline-block;
  margin: 0;
  padding: 0;
  height: fit-content;
  color: ${({ theme }) => theme.colors.link};
`;

const StyledText = styled(Text)`
  display: block;
`;

function TermsPolicyPage({ t }: ITermsPolicyPageProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleAccept, notifyError } = useTermsPolicy();

  const navigateToTermAndConditionsPage = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.termsAndConditions}`, {
      state: {
        from: location.pathname,
      },
    });
  }, [location.pathname, navigate]);

  const navigateToPrivacyAndGDPRSPage = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.privacyAndGDPRS}`, {
      state: {
        from: location.pathname,
      },
    });
  }, [location.pathname, navigate]);

  return (
    <AuthLayout>
      <StyledContainer>
        <Space direction="vertical" size="small">
          <HeaderText title={textToUpperCase(t('Conditions to use'))} />
          <div>
            <Text>
              {'\t'}
              {t('In order to use')}&nbsp;
              <Text bold>.ServicitY</Text>,&nbsp;
              {t(
                'we need your permission to store your personal data to facilitate your management activities.',
              )}
              &nbsp;
              <Text bold>.ServicitY</Text>&nbsp;
              {t(
                'does not sell your data to third parties; information is collected and stored only to provide the functionality of the platform. In order to comply with the General Data Protection Regulations (GDPRS), it is important for us that you understand and accept the above theses and policies.',
              )}
            </Text>
            <StyledText>
              {t('You can review our')}:&nbsp;&nbsp;
              <StyledLink type="text" onClick={navigateToTermAndConditionsPage}>
                {t('Term and Conditions')}&nbsp;
              </StyledLink>
              , &nbsp;
              <StyledLink type="text" onClick={navigateToPrivacyAndGDPRSPage}>
                {t('Privacy and GDPRS policy')}
              </StyledLink>
              .
            </StyledText>
          </div>
        </Space>
        <TermsPolicyForm
          showNotify={false}
          notifyError={notifyError}
          onSuccess={handleAccept}
          content={
            <StyledText fontSize={13}>
              {t(
                'By checking Accept, you agree to allow .ServicitY to collect and use your data within .ServicitY platform, to receive system maintenance, to the .ServicitY Terms and Conditions and to .ServicitY Privacy Policy.',
              )}
            </StyledText>
          }
        />
      </StyledContainer>
    </AuthLayout>
  );
}
