import React, { Suspense } from 'react';
import { CategoryFormDTO } from '@structure';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';
import { IActionButtonProps } from '@components/lib/General';
import { AddButton, SuspenseEmpty } from '@components/lib/DataDisplay';

const CategorySideWindowLazy = React.lazy(
  () => import('../Show/CategorySideWindow'),
);

export interface ICategoryCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: CategoryFormDTO) => Promise<void>;
  onCancel?: () => void;
}

export default function CategoryCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  ...rest
}: ICategoryCreateButtonProps): JSX.Element {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create company category')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <CategorySideWindowLazy
          visible={visible}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
          data-testid="categories__side-window"
        />
      </Suspense>
    </>
  );
}
