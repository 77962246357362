import React from 'react';
import { useAcl } from '@contex';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ClientModel } from '@structure';
import { Routes } from '@services/types';
import { Link } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';

export interface IClientGroupDetailsViewProps {
  client: ClientModel | null;
  icon?: React.ReactNode;
}

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ClientGroupDetailsView({
  client,
  icon,
}: IClientGroupDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { manage } = useAcl(({ client }) => client);

  return (
    <>
      {client ? (
        <>
          {manage ? (
            <Link
              to={`/${Routes.app}/${Routes.clients}/${client?.uuid}`}
              tooltip={t('Go to client details page')}
              state={{ goBack: location.pathname }}>
              <StyledTitleContainer>
                {icon || null}
                {client?.client_group_name}
              </StyledTitleContainer>
            </Link>
          ) : (
            <StyledTitle>{client?.client_group_name}</StyledTitle>
          )}
        </>
      ) : null}
    </>
  );
}
