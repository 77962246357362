import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseStateClientAbonementSubscriptionListReturnType } from '@hooks';
import { toDateByFormat } from '@services/helpers';
import {
  ClientAbonementSubscriptionModel,
  ClientAbonementStatusesText,
  ClientAbonementMapper,
  ClientAbonementModel,
} from '@structure';
import { Table } from '@components/lib/libV2/DataDisplay';
import styled, { css, useTheme } from 'styled-components';
import { ClientAbonementSubscriptionColors } from '../Pages';
import { ClientAbonementSubscriptionButton } from '../Buttons';
import { Empty } from 'antd';
import { toMoment } from '@sportix/sportix-common-modules';

export interface ClientAbonementSubscriptionListProps
  extends Pick<
    IUseStateClientAbonementSubscriptionListReturnType,
    | 'subscriptionList'
    | 'loading'
    | 'limit'
    | 'page'
    | 'handleRefreshClientAbonementSubscriptions'
    | 'handleCreateClientAbonementSubscription'
  > {
  abonement: ClientAbonementModel | null;
  disabled: boolean;
}

const StyledRowCellContainer = styled.div<{ $bgColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: inherit;

  ${({ $bgColor }) =>
    $bgColor &&
    css`
      background-color: ${$bgColor};
    `}

}
`;

const StyledListLayout = styled.div`
  min-width: auto;
  height: auto;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledTable = styled(Table)<{ $empty: number }>`
  min-width: 200px;

  .ant-empty-image > svg {
    width: 100%;
  }

  ${({ $empty }) =>
    $empty &&
    css`
      &&& {
        .ant-table-cell {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    `}
`;

export function ClientAbonementSubscriptionsList({
  abonement,
  disabled,

  subscriptionList,
  loading,
  limit: pageSize,
  page,
  handleRefreshClientAbonementSubscriptions,
  handleCreateClientAbonementSubscription,
}: ClientAbonementSubscriptionListProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      renderCell: (
        checked: boolean,
        subscription: ClientAbonementSubscriptionModel,
      ) => (
        <StyledRowCellContainer
          $bgColor={
            ClientAbonementSubscriptionColors(theme)[
              subscription?.subscription_status_text ||
                ClientAbonementStatusesText.Initiated
            ]
          }
          className="row-selection--colors"
        />
      ),
    }),
    [theme],
  );

  const columns = [
    {
      title: t('Subscriptions'),
      key: 'Subscriptions',
      render: (subscription: ClientAbonementSubscriptionModel) =>
        `${toDateByFormat(
          subscription?.subscription_start_date,
          'YYYY.MM.DD HH:mm',
        )} - ${toDateByFormat(
          subscription?.subscription_end_date,
          'YYYY.MM.DD HH:mm',
        )}`,
    },
  ];

  return (
    <StyledListLayout>
      <StyledButtonContainer>
        {abonement?.abon_status_text ===
          ClientAbonementStatusesText.Initiated &&
        !abonement?.abon_end_date &&
        subscriptionList?.size ? (
          <ClientAbonementSubscriptionButton
            disabled={disabled}
            onSuccess={handleCreateClientAbonementSubscription}
            subscription={ClientAbonementMapper.toClientAbonementSubscriptionFormDTO(
              abonement,
            )}
          />
        ) : (
          <ClientAbonementSubscriptionButton
            onSuccess={handleCreateClientAbonementSubscription}
            subscription={ClientAbonementMapper.toClientAbonementSubscriptionFormDTO(
              abonement!,
            )}
            title={t('Extend')}
            tooltipTitle={t('Extend abonement')}
            editMode
            disabled={!subscriptionList?.size || disabled}
          />
        )}
      </StyledButtonContainer>
      {/*@ts-ignore*/}
      <StyledTable
        $empty={!subscriptionList?.size}
        renderEmpty={
          <Empty
            description={
              <ClientAbonementSubscriptionButton
                onSuccess={handleCreateClientAbonementSubscription}
                subscription={ClientAbonementMapper.toClientAbonementSubscriptionFormDTO(
                  abonement!,
                )}
                title={t('Activate')}
                disabled={disabled}
              />
            }
          />
        }
        rowSelection={rowSelection}
        total={pageSize}
        pageSize={pageSize}
        dataSource={subscriptionList}
        onChange={handleRefreshClientAbonementSubscriptions}
        columns={columns}
        loading={loading}
        page={page}
      />
    </StyledListLayout>
  );
}
