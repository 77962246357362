import * as React from 'react';
import { List } from 'immutable';
import { ApiError, IEntityProps, IListSearchProps } from '@services/types';
import {
  ClientAbonementListDTO,
  ClientAbonementMapper,
  ClientAbonementModel,
  ClientAbonementListModel,
  AbonementGroupModel,
} from '@structure';
import {
  useCancellablePromise,
  useStateReducer,
} from '@components/lib/libV2/hooks';
import { getTariffModelGroupClientsAbonementList } from '@services/api/abonementGroup';
import { useDropdownAlert } from '@contex';
import { useTranslation } from 'react-i18next';

export interface IUseTariffModelGroupClientsAbonementListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  groupUuid: string;
}

export interface IUseTariffModelGroupClientsAbonementListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ClientAbonementModel> | null;
  refresh: (
    value: Partial<IUseTariffModelGroupClientsAbonementListProps>,
  ) => Promise<ClientAbonementListModel<unknown> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  groupUuid: string;
}

export function useTariffModelGroupClientsAbonementList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    groupUuid,
  }: IUseTariffModelGroupClientsAbonementListProps = {} as IUseTariffModelGroupClientsAbonementListProps,
): IUseTariffModelGroupClientsAbonementListReturnType {
  const { cancellablePromise } = useCancellablePromise();
  const { alert } = useDropdownAlert();
  const { t } = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    groupUuid: listUuid,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseTariffModelGroupClientsAbonementListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    groupUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      groupUuid = listUuid,
    }: Partial<IUseTariffModelGroupClientsAbonementListProps> = {}): Promise<ClientAbonementListModel<unknown> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          groupUuid,
        });

        const { abonements, company_abonement_group, total } =
          await cancellablePromise<ClientAbonementListDTO<AbonementGroupModel>>(
            getTariffModelGroupClientsAbonementList({
              limit,
              offset,
              keywords,
              groupUuid,
            }),
          );

        const abonementListModel =
          ClientAbonementMapper.toClientAbonementListModel<AbonementGroupModel>(
            abonements,
            total,
            company_abonement_group,
          );

        handleUpdate({
          entityList: abonementListModel?.abonements,
          total: abonementListModel?.total,
          loading: false,
        });

        return abonementListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Tariff model group client abonements'),
          `${t(
            'An error occurred during get tariff model group client abonement list',
          )} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && groupUuid) {
      (async () => {
        await refresh({ groupUuid });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, groupUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    groupUuid: listUuid,
  };
}
