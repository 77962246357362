import * as React from 'react';
import { List } from 'immutable';
import {
  useProductCategoryList,
  IUseProductCategoryListProps,
  IUseProductCategoryListReturnType,
} from './useProductCategoryList';
import {
  INVERT_MAPPED_PRODUCT_CATEGORY_TYPES,
  ProductCategoryMappedType,
  ProductCategoryModel,
  ProductCategoryType,
} from '@structure';
import { deleteProductCategory } from '@services/api/product';
import { setProductListStats as storeSetProductListStats } from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_STATUS } from '@services/types';
import { head } from '@services/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownAlert } from '@contex';
import { useStateReducer } from '@components/lib/libV2/hooks';
import {
  selectProductCategoryList,
  setProductCategoryList as storeSetProductCategoryList,
  deleteProductCategoryFromList as storeDeleteProductCategory,
  loadMoreProductCategoryList as storeLoadMoreProductCategoryList,
} from '@store/features/productCategoryListSlice';

export interface IUseStateProductCategoryListProps
  extends IUseProductCategoryListProps {
  mappedProductCategoryType?: ProductCategoryMappedType;
  productCategoryType: ProductCategoryType;
}

export interface IProductCategorySearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  view?: ProductCategoryType;
}

export interface IUseStateProductCategoryListReturnType
  extends Omit<IUseProductCategoryListReturnType, 'entityList'> {
  categoryList: List<ProductCategoryModel> | null;
  handleDeleteProductCategories: (ids: string[]) => Promise<void>;
  handleSearchProductCategories: (
    value: IProductCategorySearchProps,
  ) => Promise<void>;
  handleLoadMoreProductCategories: () => Promise<void>;
  handleRefreshProductCategories: (
    value: Partial<IUseProductCategoryListProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  category: ProductCategoryModel | null;
  parent: ProductCategoryModel | null;
  isLoadingMore: boolean;
}

export interface UseStoreProductListListState {
  loadingMore: boolean;
  storeKeywords: string;
  storePage: number;
  storeTotal: number;
  categoryList: List<ProductCategoryModel> | null;
  isStarLoading: boolean;
  category: ProductCategoryModel | null;
  parent: ProductCategoryModel | null;
}

export function useStoredProductCategoryList(
  {
    companyUuid,
    loadOnInit = true,
    parent_category_uuid = '',
    mappedProductCategoryType,
    productCategoryType,
    ...rest
  }: IUseStateProductCategoryListProps = {} as IUseStateProductCategoryListProps,
): IUseStateProductCategoryListReturnType {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const {
    categoryList,
    loadingMore,
    isStarLoading,
    storePage,
    storeTotal,
    storeKeywords,
    handleUpdate: updateStateProductCategory,
    category,
    parent,
  } = useStateReducer<UseStoreProductListListState>({
    loadingMore: false,
    storeKeywords: '',
    storePage: 1,
    storeTotal: 0,
    categoryList: null,
    isStarLoading: false,
    category: null,
    parent: null,
  });

  const size = useMemo(() => categoryList?.size || 0, [categoryList?.size]);
  const isLoadingMore = useMemo(() => size < storeTotal, [size, storeTotal]);

  const {
    status: storedProductCategoryListStatus,
    loading: storedProductCategoryListLoading,
    cachedProductCategoryMap,
    stats: storeStats,
    ...storedProductCategoryListParams
  } = useSelector(selectProductCategoryList);

  const dispatch = useDispatch<any>();

  const categoryType: any = mappedProductCategoryType || productCategoryType;

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: categoryListLoading,
    total,
    stats,
    view,
    ...categoriesParams
  } = useProductCategoryList({
    companyUuid,
    loadOnInit: false,
    parent_category_uuid,
    view: productCategoryType,
    ...rest,
  });

  useEffect(() => {
    const productCategoryListMap = cachedProductCategoryMap?.get(categoryType);

    if (productCategoryListMap!?.size > 0) {
      const productCategoryList =
        productCategoryListMap?.get(parent_category_uuid);

      if (productCategoryList) {
        updateStateProductCategory({
          isStarLoading: false,
          categoryList: productCategoryList?.categories,
          storePage: productCategoryList?.page || 1,
          storeKeywords: productCategoryList?.keywords || '',
          storeTotal: productCategoryList?.total || 0,
          category: productCategoryList?.parentCategory || null,
        });
      } else {
        updateStateProductCategory({ isStarLoading: true });
      }
    }
    if (cachedProductCategoryMap?.size === 0 || !productCategoryListMap) {
      updateStateProductCategory({ isStarLoading: true });
    }
  }, [
    cachedProductCategoryMap,
    categoryList,
    categoryType,
    mappedProductCategoryType,
    parent_category_uuid,
    productCategoryType,
    updateStateProductCategory,
  ]);

  useEffect(() => {
    if (
      // !List.isList(entityList?.categories) &&
      isStarLoading &&
      loadOnInit &&
      companyUuid
    ) {
      updateStateProductCategory({ isStarLoading: false, loadingMore: true });

      (async () => {
        const entityList = await refresh({
          view: productCategoryType,
          companyUuid,
          keywords: '',
        });

        if (entityList) {
          dispatch(
            storeSetProductCategoryList({
              page: 1,
              productCategoryList: entityList,
              categoryType: categoryType,
              keywords: '',
              parentCategoryUuid: parent_category_uuid,
            }),
          );
        }

        updateStateProductCategory({ loadingMore: false });
      })();
    }
  }, [
    categoryType,
    companyUuid,
    dispatch,
    entityList?.categories,
    isStarLoading,
    loadOnInit,
    parent_category_uuid,
    productCategoryType,
    refresh,
    updateStateProductCategory,
  ]);

  const handleDeleteProductCategories = React.useCallback(
    async (ids: string[]) => {
      try {
        const { stats } = await deleteProductCategory(ids);

        setTimeout(() => {
          dispatch(
            storeDeleteProductCategory({
              productCategoryUuid: head(ids),
              stats,
            }),
          );
        }, 100);

        dispatch(storeSetProductListStats(stats));

        alert(
          'success',
          t('Materials categories'),
          t('Materials categories delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Materials categories'),
          `${t('An error occurred during delete materials categories')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchProductCategories = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      view = productCategoryType,
    }: IProductCategorySearchProps) => {
      updateStateProductCategory({ loadingMore: true });

      const originalType =
        INVERT_MAPPED_PRODUCT_CATEGORY_TYPES[view as ProductCategoryType] ||
        ProductCategoryType.LIST;

      const categoryListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        view: originalType,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeSetProductCategoryList({
            page: 1,
            productCategoryList: categoryListModel,
            categoryType: categoryType,
            keywords,
            parentCategoryUuid: parent_category_uuid,
          }),
        );
      }

      dispatch(storeSetProductListStats(categoryListModel?.stats!));

      updateStateProductCategory({ loadingMore: false });
    },
    [
      categoryType,
      dispatch,
      parent_category_uuid,
      productCategoryType,
      refresh,
      updateStateProductCategory,
    ],
  );

  const handleLoadMoreProductCategories = useCallback(async () => {
    if (
      List.isList(categoryList) &&
      categoryList?.size < storeTotal &&
      !loadingMore
    ) {
      updateStateProductCategory({ loadingMore: true });
      const categoryListModel = await refresh({
        offset: categoryList?.size,
        limit: 10,
        showLoading: false,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeLoadMoreProductCategoryList({
            categoryType,
            productCategoryList: categoryListModel,
            parentCategoryUuid: parent_category_uuid,
          }),
        );
      }

      updateStateProductCategory({ loadingMore: false });
    }
  }, [
    categoryList,
    storeTotal,
    loadingMore,
    updateStateProductCategory,
    refresh,
    dispatch,
    categoryType,
    parent_category_uuid,
  ]);

  const handleRefreshProductCategories = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      view = categoryType,
      ...rest
    }: Partial<IUseProductCategoryListProps> & { page: number }) => {
      updateStateProductCategory({ loadingMore: true });
      const categoryListModel = await refresh({
        offset,
        limit,
        showLoading,
        view,
        ...rest,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeSetProductCategoryList({
            page: 1,
            productCategoryList: categoryListModel,
            categoryType: view as any,
            keywords: '',
            parentCategoryUuid: parent_category_uuid,
          }),
        );
      }
      updateStateProductCategory({ loadingMore: false });
    },
    [
      categoryType,
      dispatch,
      parent_category_uuid,
      refresh,
      updateStateProductCategory,
    ],
  );

  return {
    ...categoriesParams,
    ...storedProductCategoryListParams,
    categoryList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(categoryList) && categoryListLoading) ||
      storedProductCategoryListLoading,
    handleDeleteProductCategories,
    handleSearchProductCategories,
    handleLoadMoreProductCategories,
    status: storedProductCategoryListStatus,
    handleRefreshProductCategories,
    keywords: storeKeywords || '',
    page: storePage || 1,
    stats: storeStats || stats,
    category,
    parent,
    isLoadingMore,
  };
}
