import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export default withTranslation()(CounterpartyPage);

export interface ICounterpartyPageProps extends WithTranslation {}

function CounterpartyPage({}: ICounterpartyPageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{ fontSize: 30 }}>Counterparty Page</p>
    </div>
  );
}
