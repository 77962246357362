import { apiDelete, apiGet, apiPost, apiPut } from '../core/api';
import { head } from '../helpers';
import { ApiAnswer, IListSearchProps } from '../types';
import {
  StoreDTO,
  StoreFormDTO,
  StoreMapper,
  IStoreDTOProps,
  IStoreListDTO,
  StoreType,
} from '@structure';
import { contentApiUrl } from '../const';

const {
  store_description,
  store_settings,
  store_type,
  ...STORE_INITIAL_PARAM_REST
} = new StoreDTO({} as IStoreDTOProps);

export const STORE_REQUIRED_FIELD = {
  store_description,
  store_settings,
};

export interface IStoreAnswer extends ApiAnswer {
  store: IStoreDTOProps;
}

export interface IStoreListAnswer extends ApiAnswer {
  stores: IStoreDTOProps[];
}

export interface IStoreListAnswer extends ApiAnswer, IListSearchProps {
  stores: IStoreDTOProps[];
}

export interface IStoreListProps extends IListSearchProps {
  companyUuid: string;
}

export async function getStoreList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: IStoreListProps): Promise<IStoreListDTO> {
  const { stores, total } = await apiGet<IListSearchProps, IStoreListAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/stores`,
    {
      offset,
      limit,
      keywords,
    },
  );

  return StoreMapper.toStoreListDTO(stores, total || 0);
}

export async function getStoreById(storeUuid: string): Promise<StoreDTO> {
  const { store } = await apiGet<null, IStoreAnswer>(
    `${contentApiUrl}/stores/${storeUuid}`,
  );

  return StoreMapper.toStoreDTO(store);
}

export async function createStore(
  {
    store_description,
    store_settings,
    store_name,
    store_type,
    store_manager_uuid,
    store_department_uuid,
  }: StoreFormDTO,
  companyUuid: string,
): Promise<StoreDTO> {
  const { store } = await apiPost<Omit<StoreFormDTO, 'uuid'>, IStoreAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/stores`,
    {
      store_description,
      store_settings:
        store_type === StoreType.CUSTOM ? store_settings : (undefined as any),
      store_name,
      store_type,
      store_manager_uuid,
      store_department_uuid,
    },
  );

  return StoreMapper.toStoreDTO(store);
}

export async function editStore({
  uuid: store_uuid,
  store_description,
  store_settings,
  store_name,
  store_type,
  store_manager_uuid,
  store_department_uuid,
}: Omit<StoreFormDTO, 'getTimingList'>): Promise<StoreDTO> {
  const { store } = await apiPut<Omit<StoreFormDTO, 'uuid'>, IStoreAnswer>(
    `${contentApiUrl}/stores/${store_uuid}`,
    {
      store_description,
      store_settings:
        store_type === StoreType.CUSTOM ? store_settings : (undefined as any),
      store_name,
      store_type,
      store_manager_uuid,
      store_department_uuid,
    },
  );

  return StoreMapper.toStoreDTO(store);
}

export async function deleteStore(ids: string[] = []): Promise<IStoreListDTO> {
  const { stores } = await apiDelete<{ ids: string[] }, IStoreListAnswer>(
    `${contentApiUrl}/stores/${head(ids)}`,
  );

  return StoreMapper.toStoreListDTO(stores, 0);
}
