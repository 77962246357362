import * as React from 'react';
import { List } from 'immutable';
import {
  useProductListByCategory,
  IUseProductListByCategoryProps,
  IUseProductListByCategoryReturnType,
} from './useProductListByCategory';
import { ProductMappedType, ProductModel, ProductType } from '@structure';
import { setProductListStats as storeSetProductListStats } from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_STATUS } from '@services/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useStateReducer } from '@components/lib/libV2/hooks';
import {
  selectProductList,
  setProductList as storeSetProductList,
  loadMoreProductList as storeLoadMoreProductList,
} from '@store/features/productListSlice';

export interface IUseStateProductCategoryListProps
  extends IUseProductListByCategoryProps {
  mappedProductType?: ProductMappedType;
  productType: ProductType;
}

export interface IProductSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateProductListByCategoryReturnType
  extends Omit<IUseProductListByCategoryReturnType, 'entityList'> {
  productList: List<ProductModel> | null;
  handleSearchProducts: (value: IProductSearchProps) => Promise<void>;
  handleLoadMoreProducts: () => Promise<void>;
  handleRefreshProducts: (
    value: Partial<IUseProductListByCategoryProps> & { page: number },
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  isLoadingMore: boolean;
}

export interface UseStoreProductListListState {
  loadingMore: boolean;
  storeKeywords: string;
  storePage: number;
  storeTotal: number;
  productList: List<ProductModel> | null;
  isStarLoading: boolean;
}

export function useStoredProductListByCategory(
  {
    categoryUuid,
    loadOnInit = true,
    mappedProductType,
    productType: initProductType,
    ...rest
  }: IUseStateProductCategoryListProps = {} as IUseStateProductCategoryListProps,
): IUseStateProductListByCategoryReturnType {
  const {
    productList,
    loadingMore,
    isStarLoading,
    storePage,
    storeTotal,
    storeKeywords,
    handleUpdate: updateStateProductCategory,
  } = useStateReducer<UseStoreProductListListState>({
    loadingMore: false,
    storeKeywords: '',
    storePage: 1,
    storeTotal: 0,
    productList: null,
    isStarLoading: false,
  });

  const size = useMemo(() => productList?.size || 0, [productList?.size]);
  const isLoadingMore = useMemo(() => size < storeTotal, [size, storeTotal]);

  const {
    status: storedProductListStatus,
    loading: storedProductListLoading,
    cachedProductMap,
    stats: storeStats,
    ...storedProductListParams
  } = useSelector(selectProductList);

  const dispatch = useDispatch<any>();

  const productType: any = mappedProductType || initProductType;

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: productListLoading,
    total,
    stats,
    ...categoriesParams
  } = useProductListByCategory({
    categoryUuid,
    loadOnInit: false,
    ...rest,
  });

  useEffect(() => {
    const productCategoryListMap = cachedProductMap?.get(productType);

    if (productCategoryListMap!?.size > 0) {
      const productCategoryList = productCategoryListMap?.get(categoryUuid);

      if (productCategoryList) {
        updateStateProductCategory({
          isStarLoading: false,
          productList: productCategoryList?.products,
          storePage: productCategoryList?.page || 1,
          storeKeywords: productCategoryList?.keywords || '',
          storeTotal: productCategoryList?.total || 0,
        });
      } else {
        updateStateProductCategory({ isStarLoading: true });
      }
    }
    if (cachedProductMap?.size === 0 || !productCategoryListMap) {
      updateStateProductCategory({ isStarLoading: true });
    }
  }, [cachedProductMap, categoryUuid, productType, updateStateProductCategory]);

  useEffect(() => {
    if (
      // !List.isList(entityList?.products) &&
      isStarLoading &&
      loadOnInit &&
      categoryUuid
    ) {
      updateStateProductCategory({ isStarLoading: false, loadingMore: true });

      (async () => {
        const entityList = await refresh({
          categoryUuid,
          keywords: '',
        });

        if (entityList) {
          dispatch(
            storeSetProductList({
              page: 1,
              productList: entityList,
              productType,
              keywords: '',
              categoryUuid,
            }),
          );
        }

        updateStateProductCategory({ loadingMore: false });
      })();
    }
  }, [
    categoryUuid,
    dispatch,
    isStarLoading,
    loadOnInit,
    productType,
    refresh,
    updateStateProductCategory,
  ]);

  const handleSearchProducts = React.useCallback(
    async ({ limit = 10, keywords, showLoading }: IProductSearchProps) => {
      updateStateProductCategory({ loadingMore: true });

      const productListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductList({
            page: 1,
            productList: productListModel,
            productType,
            keywords,
            categoryUuid,
          }),
        );
      }

      dispatch(storeSetProductListStats(productListModel?.stats!));

      updateStateProductCategory({ loadingMore: false });
    },
    [categoryUuid, dispatch, productType, refresh, updateStateProductCategory],
  );

  const handleLoadMoreProducts = useCallback(async () => {
    if (
      List.isList(productList) &&
      productList?.size < storeTotal &&
      !loadingMore
    ) {
      updateStateProductCategory({ loadingMore: true });
      const productListModel = await refresh({
        offset: productList?.size,
        limit: 10,
        showLoading: false,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeLoadMoreProductList({
            productType,
            productList: productListModel,
            categoryUuid,
          }),
        );
      }

      updateStateProductCategory({ loadingMore: false });
    }
  }, [
    categoryUuid,
    dispatch,
    loadingMore,
    productList,
    productType,
    refresh,
    storeTotal,
    updateStateProductCategory,
  ]);

  const handleRefreshProducts = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      ...rest
    }: Partial<IUseProductListByCategoryProps> & { page: number }) => {
      updateStateProductCategory({ loadingMore: true });
      const productListModel = await refresh({
        offset,
        limit,
        showLoading,
        ...rest,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeSetProductList({
            page: 1,
            productList: productListModel,
            productType,
            keywords: '',
            categoryUuid,
          }),
        );
      }
      updateStateProductCategory({ loadingMore: false });
    },
    [categoryUuid, dispatch, productType, refresh, updateStateProductCategory],
  );

  return {
    ...categoriesParams,
    ...storedProductListParams,
    productList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(productList) && productListLoading) ||
      storedProductListLoading,
    handleSearchProducts,
    handleLoadMoreProducts,
    status: storedProductListStatus,
    handleRefreshProducts,
    keywords: storeKeywords || '',
    page: storePage || 1,
    stats: storeStats || stats,
    isLoadingMore,
  };
}
