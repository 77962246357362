import Color from 'color';
import { List } from 'immutable';
import React, { useMemo } from 'react';
import { storeDocumentRoute } from '../type';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { StoreDocumentPageForm } from '../Forms';
import { ProductDetailsView } from '../../Products';
import { InfoCircleOutlined } from '@ant-design/icons';
import { StoreDocItemsPriceDisabledView } from '../Show';
import styled, { css, useTheme } from 'styled-components';
import { StoreDocumentShortSummaryView } from '../Posting';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';
import {
  StoreDocumentType,
  DocTriggerableType,
  StoreDocumentModel,
  StoreDocumentMapper,
  StoreDocumentItemDTO,
  ScheduleCalendarModel,
  StoreDocumentItemModel,
  StoreDocumentItemListModel,
} from '@structure';

import {
  correctPrice,
  listToArray,
  textToUpperCase,
  toDateByFormat,
} from '@services/helpers';

import {
  IUseStoreDocumentItemListProps,
  IUseStateStoredDocumentReturnType,
} from '@hooks';

import {
  StoreRealizationDocItemsTableTotalView,
  StoreRealizationPriceView,
} from '../Realization';

import {
  DetailsView,
  Link,
  TableIndexField,
} from '@components/lib/DataDisplay';

import '../../Orders/List/OrderList.less';

export interface IStoreDocItemsViewProps
  extends Pick<
    IUseStateStoredDocumentReturnType,
    'handleCreateOrUpdateStoreDocumentItem' | 'handleDeleteStoreDocumentItem'
  > {
  document: StoreDocumentModel;
  loading?: boolean;
  storeDocumentItemList: List<StoreDocumentItemModel> | null;
  storeDocumentItemListRefresh: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  storeDocumentItemListLimit: number;
  storeDocumentItemListTotal: number;
  storeDocumentItemListPage: number;
  withEditablePrice?: boolean;
  productWithBalance?: boolean;
}

const StyledTableContainer = styled.div`
  background-color: ${({ theme }) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledTable = styled(Table)`
  margin: 0;
  width: 100%;
` as React.ComponentType as React.FC<ITableProps<StoreDocumentItemDTO>>;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  background-color: ${({ theme }) =>
    Color(theme.colors.warning).lighten(0.5).string()};
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 5px;
`;

const StyledMessageDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.warning};
  font-size: 20px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  //margin-bottom: 20px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 3px;
`;

const StyledAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  width: 100%;
`;

const StyledLineThroughTitle = styled(StyledTitle)`
  text-decoration: line-through;
`;

const StyledTotalContainer = styled.div<{
  $bold?: boolean;
  $isLineThrough: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 600;
    `}

  ${({ $isLineThrough }) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

export function StoreDocItemsView({
  document,
  handleCreateOrUpdateStoreDocumentItem,
  handleDeleteStoreDocumentItem,

  loading,
  storeDocumentItemList,
  storeDocumentItemListRefresh,
  storeDocumentItemListLimit,
  storeDocumentItemListTotal,
  storeDocumentItemListPage,

  withEditablePrice,
  productWithBalance = true,
}: IStoreDocItemsViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { defaultCompany } = useStoredCompanies();

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (docItem: StoreDocumentItemModel, items: any, index: number) => (
        <TableIndexField
          number={
            (storeDocumentItemListPage - 1) * storeDocumentItemListLimit +
            index +
            1
          }
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      render: (docItem: StoreDocumentItemModel) => (
        <ProductDetailsView
          product={docItem?.product as any}
          item_product_returned_amount={docItem?.item_product_returned_amount}
          product_amount={docItem?.item_product_amount}
          withTour
          price={docItem?.item_base_price}
        />
      ),
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isReturnedAmount = returnedAmount > 0;

        const isLessThen = returnedAmount < docItem?.item_product_amount;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <StyledAmountContainer>
            {isReturnedAmount ? (
              <>
                {isLessThen ? (
                  <>
                    <StyledLineThroughTitle>
                      {docItem?.item_product_amount}
                    </StyledLineThroughTitle>
                    <StyledTitle>
                      {docItem?.item_product_amount - returnedAmount}
                    </StyledTitle>
                  </>
                ) : null}

                {isGrateOrEqualThen ? (
                  <StyledLineThroughTitle>
                    {docItem?.item_product_amount}
                  </StyledLineThroughTitle>
                ) : null}
              </>
            ) : (
              <StyledTitle>{docItem?.item_product_amount}</StyledTitle>
            )}
          </StyledAmountContainer>
        );
      },
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <StoreDocItemsPriceDisabledView
            docItem={docItem}
            isLineThrough={isGrateOrEqualThen}
          />
        );
      },
    },
    {
      title: `${t('Total')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'total',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => {
        const returnedAmount = docItem?.item_product_returned_amount || 0;
        const isGrateOrEqualThen =
          returnedAmount >= docItem?.item_product_amount;

        return (
          <>
            <StyledTotalContainer $bold $isLineThrough={isGrateOrEqualThen}>
              {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                Number(docItem?.item_base_price || 0) *
                  (docItem?.item_product_amount || 1),
              )}`}
            </StyledTotalContainer>
          </>
        );
      },
    },
  ];

  const triggerableNumber =
    document?.doc_triggerable instanceof ScheduleCalendarModel
      ? document?.doc_triggerable?.schedule_number
      : document?.doc_triggerable?.doc_local_number;

  const triggerableDate =
    document?.doc_triggerable instanceof ScheduleCalendarModel
      ? document?.doc_triggerable?.scheduled_date
      : document?.doc_triggerable?.doc_date;

  const triggerableType =
    document?.doc_triggerable_type === DocTriggerableType.ORDER
      ? 'Order'
      : 'tSale';

  const triggerableTo =
    document?.doc_triggerable instanceof ScheduleCalendarModel
      ? `/${Routes.app}/${Routes.schedules}/${document?.doc_triggerable?.uuid}`
      : `/${Routes.app}/${Routes.stores}/${
          storeDocumentRoute[document?.doc_type]
        }/${document?.doc_triggerable?.uuid}`;

  const currencies = useMemo(
    () =>
      (document?.stats?.currencies || [])?.filter(
        ({ currency }) => currency !== '₴',
      ),
    [document?.stats?.currencies],
  )
    .map(({ sum, currency }) => `${currency}: ${sum}`)
    .join(', ');
  const total = useMemo(
    () =>
      storeDocumentItemList?.reduce(
        (acc: number, curr: StoreDocumentItemModel) => {
          acc += Number(curr?.item_base_price) * curr?.item_product_amount;

          return acc;
        },
        0,
      ),
    [storeDocumentItemList],
  );

  return (
    <>
      <StyledTitleContainer>
        <StyledTitle bold>{textToUpperCase(t('doc_items'))}</StyledTitle>
        {document?.doc_type === StoreDocumentType.OUT ? (
          <StoreRealizationPriceView document={document} />
        ) : (
          <StoreDocumentShortSummaryView document={document} />
        )}
      </StyledTitleContainer>

      <StyledTableContainer>
        {document?.doc_is_editable && !document?.doc_triggerable ? (
          <StyledContainer>
            <StoreDocumentPageForm
              withEditablePrice={withEditablePrice}
              document={StoreDocumentMapper.toStoreDocumentFormDTO(document, {
                editMode: true,
              })}
              onSuccess={handleCreateOrUpdateStoreDocumentItem}
              handleDeleteStoreDocumentItem={handleDeleteStoreDocumentItem}
              loading={loading}
              storeDocumentItemList={storeDocumentItemList}
              storeDocumentItemListRefresh={storeDocumentItemListRefresh}
              storeDocumentItemListLimit={storeDocumentItemListLimit}
              storeDocumentItemListTotal={storeDocumentItemListTotal}
              storeDocumentItemListPage={storeDocumentItemListPage}
              productWithBalance={productWithBalance}
            />
          </StyledContainer>
        ) : (
          <StyledContainer>
            {document?.doc_is_editable && document?.doc_triggerable ? (
              <StyledMessage>
                <StyledInfoCircleOutlined />
                <StyledMessageDescriptionContainer>
                  <StyledDescription
                    $color={theme.colors.black}
                    style={{ whiteSpace: 'unset' }}>
                    {`${t(
                      'The document is automatically generated based on',
                    )} "${t(triggerableType)}" `}
                  </StyledDescription>
                  <Link
                    to={triggerableTo}
                    tooltip={t('Go to manager details page')}
                    state={{ goBack: location.pathname }}>
                    <StyledDescription
                      $color={theme.colors.link}
                      style={{ whiteSpace: 'unset' }}>
                      {`№${triggerableNumber} ${t(
                        'From',
                      ).toLowerCase()} ${toDateByFormat(
                        triggerableDate,
                        'DD.MM.YYYY',
                      )}`}
                    </StyledDescription>
                  </Link>
                </StyledMessageDescriptionContainer>
              </StyledMessage>
            ) : (
              <StyledMessage>
                <StyledInfoCircleOutlined />
                <StyledDescription
                  $color={theme.colors.black}
                  style={{ whiteSpace: 'unset' }}>
                  {t(
                    'It is possible to change a document carried out in warehouse registers only 3 days after its creation.',
                  )}
                </StyledDescription>
              </StyledMessage>
            )}

            <StyledTable
              loading={loading}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={listToArray(storeDocumentItemList as any)}
              rowKey="uuid"
              columns={columns}
              total={storeDocumentItemListTotal}
              pageSize={storeDocumentItemListLimit}
              onChange={storeDocumentItemListRefresh}
              page={storeDocumentItemListPage}
              onRow={(record: StoreDocumentItemDTO) => {
                const returnedAmount =
                  record?.item_product_returned_amount || 0;
                const isReturnedAmount = returnedAmount > 0;

                const isLessThen = returnedAmount < record?.item_product_amount;
                const isGrateOrEqualThen =
                  returnedAmount >= record?.item_product_amount;

                return {
                  className: `${
                    isReturnedAmount && isLessThen ? 'order-list--warning' : ''
                  } ${
                    isReturnedAmount && isGrateOrEqualThen
                      ? 'order-list--error'
                      : ''
                  }`,
                };
              }}
            />

            {storeDocumentItemList?.size ? (
              <>
                {document?.doc_type === StoreDocumentType.OUT ? (
                  <StoreRealizationDocItemsTableTotalView
                    document={StoreDocumentMapper.toStoreDocumentFormDTO(
                      document,
                      {
                        editMode: true,
                      },
                    )}
                  />
                ) : (
                  <StyledDetailsView titles={[]}>
                    <StyledStatsContainer>
                      <StyledTitle fontSize={15} bold>{`${t('Total')}, ${
                        defaultCompany?.currency_symbol || ''
                      }: ${correctPrice(total || 0)}`}</StyledTitle>

                      {currencies?.length ? (
                        <StyledDescription>{`${currencies}`}</StyledDescription>
                      ) : null}
                    </StyledStatsContainer>
                  </StyledDetailsView>
                )}
              </>
            ) : null}
          </StyledContainer>
        )}
      </StyledTableContainer>
    </>
  );
}
