import React from 'react';
import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IChild } from '@services/types';

export default withTranslation()(Spin);

export interface SpinProps extends Partial<IChild>, WithTranslation {
  size?: number;
  tip?: string;
  showTip?: boolean;
}

const antIcon = (fontSize: number): JSX.Element => (
  <LoadingOutlined style={{ fontSize }} spin />
);

function Spin({
  children,
  size = 24,
  tip,
  showTip = false,
  t,
}: SpinProps): JSX.Element {
  return (
    <AntSpin
      tip={showTip ? tip || t('Loading...}') : undefined}
      indicator={antIcon(size)}>
      {children || null}
    </AntSpin>
  );
}
