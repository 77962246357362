import { GENDER, DateType, toDateByFormat, toMoment } from '@services/helpers';
import { ClientDiscountType, ClientModel, ClientOrgType } from '../internal';
import { DATE_SHORT_FORMAT } from '@services/const';

export class ClientFormDTO {
  public uuid: string;

  public first_name: string;

  public middle_name: string;

  public last_name: string;

  public phone: string;

  public email: string;

  public address: string;

  public birthday: DateType;

  public gender: GENDER;
  public client_org_type: ClientOrgType;
  public client_discount: string;
  public client_discount_goods: string;
  public client_discount_type: ClientDiscountType;
  public client_discount_goods_type: ClientDiscountType;
  public client_comment: string;
  public client_is_provisioner?: boolean;
  public client_is_conflict?: boolean;
  public client_is_tav_payer?: boolean;

  constructor(props?: ClientModel) {
    this.uuid = props?.uuid || '';
    this.client_org_type = props?.client_org_type || ClientOrgType.PERSON;
    this.first_name = props?.first_name || '';
    this.middle_name = props?.middle_name || '';
    this.last_name = props?.last_name || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday ? toMoment(props?.birthday as string) : '';
    this.gender = props?.gender || GENDER.MALE;

    this.client_discount = props?.client_discount ?? '0';
    this.client_discount_type =
      props?.client_discount_type ?? ClientDiscountType.Fixed;

    this.client_discount_goods = props?.client_discount_goods ?? '0';
    this.client_discount_goods_type =
      props?.client_discount_goods_type ?? ClientDiscountType.Fixed;

    this.client_comment = props?.client_comment || '';
    this.client_is_provisioner =
      typeof props?.client_is_provisioner === 'boolean'
        ? props?.client_is_provisioner
        : false;

    this.client_is_conflict =
      typeof props?.client_is_conflict === 'boolean'
        ? props?.client_is_conflict
        : false;

    this.client_is_tav_payer =
      typeof props?.client_is_tav_payer === 'boolean'
        ? !!props?.client_is_tav_payer
        : false;
  }

  birthdayToString(birthday: DateType) {
    return toDateByFormat(birthday, DATE_SHORT_FORMAT);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
