import React, { useCallback } from 'react';
import { ICalendarManagerProps } from '@contex';
import { ScheduleCalendarModel } from '@structure';
import { Routes, RoutesAcl } from '@services/types';
import {
  ClientCalendarPopover,
  ClientAbonementScheduleDetail,
} from '../../Clients';
import { useDefaultCalendarScheduleProps, useStoredCompanies } from '@hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import { HeaderLeft } from '@components/lib/DataDisplay';
import { SingleLayout } from '@components/lib/Layout';
import { useBreadcrumb } from '@components/lib/libV2/hooks';

export interface ITariffModelClientAbonementCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export function TariffModelClientAbonementCalendarPage({
  children,
}: ITariffModelClientAbonementCalendarPageProps): JSX.Element {
  const { tariffModelId, tariffModelGroupId, clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const { defaultCompany } = useStoredCompanies();

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || -1);
  }, [location?.state?.goBack, navigate]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Tariff models',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}`,
      breadcrumbName: 'Show tariff model',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
      breadcrumbName: 'Tariff model groups',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}`,
      breadcrumbName: 'Tariff model group',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}`,
      breadcrumbName: 'Clients abonements',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}/${clientId}`,
      breadcrumbName: 'Client abonement',
    },
  ]);

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.clientAbonements]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      routes={routes}>
      {children({
        ...defaultCalendarProps,
        localStorageName: '',
        withoutStorageDate: '',
        schedules: undefined,
        SchedulePopover: ClientCalendarPopover,
        scheduleDetails: (children, schedule) => (
          //@ts-ignore
          <ClientAbonementScheduleDetail routes={routes} schedule={schedule}>
            {({ routes, navigateGoBack }) =>
              children({ routes, navigateGoBack })
            }
          </ClientAbonementScheduleDetail>
        ),
        customDayTimeRange: defaultCompany?.work_schedules,
      })}
    </SingleLayout>
  );
}
