import React from 'react';
import { Routes, Route } from 'react-router';
import { AppRouteLayout } from '@components/lib/Layout';
import { Routes as RoutesType } from '@services/types';
import { SubscriptionListPage, SubscriptionPage } from '../views/Profile';

export function SubscriptionRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <SubscriptionPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={RoutesType.subscriptionListPage}
        element={
          <AppRouteLayout>
            <SubscriptionListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
