import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { DocumentNumber } from '@components/lib/General';
import { IStatusListSelectProps, StatusListSelect } from '../../Statuses';
import { toUtcDateInFormat, correctPrice } from '@services/helpers';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';

import {
  IUseStateScheduleReturnType,
  IUseStateStatusListReturnType,
  useStoredCompanies,
} from '@hooks';

export interface IOrderTitleViewProps
  extends Pick<
      IUseStateScheduleReturnType,
      'schedule' | 'handleChangeScheduleStatus' | 'handleCloseSchedule'
    >,
    Pick<IUseStateStatusListReturnType, 'allowedStatusesTo'>,
    Pick<IStatusListSelectProps, 'handleCreateSchedulePrepayment'> {
  loading: boolean;
  withSum?: boolean;
}

const StyledStatusListSelect = styled(StatusListSelect)`
  &&& {
    .ant-select-selection-item {
      padding: 0 !important;
      padding-inline-end: 0 !important;
    }
    .ant-select-selector {
      padding: 0px !important;
    }
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

const StyledHeader = styled.div`
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;

  min-width: 200px;

  & > :first-child {
    margin-bottom: 10px;
  }
`;

export function OrderTitleView({
  schedule,
  allowedStatusesTo,
  loading,
  handleChangeScheduleStatus,
  handleCreateSchedulePrepayment,
  handleCloseSchedule,
  withSum,
}: IOrderTitleViewProps): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { defaultCompany } = useStoredCompanies();

  return (
    <StyledHeader>
      {schedule?.parent?.uuid ? (
        <>
          <StyledTitle
            color={theme.colors.text_colors.secondary}
            fontSize={15}>{` ${t('Parent order')} № ${
            schedule?.parent?.schedule_number
          }`}</StyledTitle>
          <StyledTitle
            style={{ marginTop: -8, marginBottom: 5 }}
            fontSize={15}
            $color={theme.colors.text_colors.secondary}>
            {toUtcDateInFormat(
              schedule?.parent?.scheduled_date,
              'YYYY-MM-DD HH:mm',
            )}
          </StyledTitle>
        </>
      ) : null}

      <StyledTitleContainer>
        <DocumentNumber
          number={`${schedule?.schedule_number || ''}`}
          date={schedule?.scheduled_date}>
          {withSum ? (
            <StyledDescription $color={theme.colors.primary}>
              {`${t('in the amount of')} ${
                defaultCompany?.currency_symbol || ''
              } ${correctPrice(schedule?.sum_total || 0)}`}
            </StyledDescription>
          ) : null}
          <StyledStatusListSelect
            schedule={schedule}
            allowedStatusesTo={allowedStatusesTo}
            selectedStatus={schedule?.latestStatusModel?.customStatusModel!}
            loading={loading}
            handleChangeScheduleStatus={handleChangeScheduleStatus}
            handleCreateSchedulePrepayment={handleCreateSchedulePrepayment}
            handleCloseSchedule={handleCloseSchedule}
          />
        </DocumentNumber>
      </StyledTitleContainer>
    </StyledHeader>
  );
}
