import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmActionButtonProps } from '@components/lib/General';
import { TextFormat } from '@components/lib/Format';
import { len, eq } from '@services/helpers';
import { PriceMarginModel } from '@structure';
import { useDelete } from '@components/lib/libV2/hooks';
import { DeleteButton } from '@components/lib/DataDisplay';

export interface IPriceMarginDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  priceMargins: PriceMarginModel[];
  title?: string;
  isActiveByDefault: boolean;
}

export function PriceMarginDeleteButton({
  children,
  priceMargins = [],
  onSuccess,
  onError,
  title,
  disabled,
  isActiveByDefault,
  ...rest
}: IPriceMarginDeleteButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, loading } = useDelete({
    onSuccess,
    entity: priceMargins,
    notifyTitle: 'Price margin',
  });

  return (
    <DeleteButton
      disabled={disabled || isActiveByDefault}
      title={title}
      tooltipTitle={t('Delete price margin')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(priceMargins)}</b>{' '}
          {eq(len(priceMargins), 1) ? t('price margin') : t('price margins')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
