import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultFormProps, DefaultForm } from '@components/lib/General';
import { PlanFormDTO } from '@structure';
import { SUBSCRIPTION_FORM_PARAM } from '@services/api/subscriptions';
import SubscriptionFormFields from './SubscriptionFormFields';
import { useDropdownAlert } from '@contex';

export interface ISubscriptionFormProps
  extends Omit<
    IDefaultFormProps<PlanFormDTO, PlanFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  subscription: PlanFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
}

export function SubscriptionForm({
  loading,
  editMode,
  subscription = SUBSCRIPTION_FORM_PARAM,
  isFullForm = true,
  autoFocus,
  ...rest
}: ISubscriptionFormProps) {
  const { t } = useTranslation();
  const { alert } = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: subscription?.uuid,
    }),
    [subscription?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Tariff'),
        `${t('An error occurred during prolong plan')} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...subscription,
      }}
      notifyError={notifyError}
      submitButtonText={t('Prolong')}
      additionalValuesRequest={additionalValues}
      {...rest}>
      {() => <SubscriptionFormFields loading={!!loading} />}
    </DefaultForm>
  );
}
